import env from '@beam-australia/react-env';
import _get from 'lodash/get';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

import { Assets, Dropdowns } from 'config/formConfig/types';
import { EnvironmentVariable } from 'utils/environment';

const ID_SEPERATOR = '.';
const UNKNOWN_CATEGORY = 'unknown';

const splitCategoryId = (id: string): string[] =>
  id.includes(ID_SEPERATOR)
    ? [id.split(ID_SEPERATOR)[0], id.split(ID_SEPERATOR)[1]]
    : [UNKNOWN_CATEGORY, id];

/*
 * For each merged config use element id as translated values
 */
const mergeCustomizer = (objValue: any, srcValue: { id: string }[]): { id: string }[] =>
  isArray(objValue)
    ? srcValue.map((element) => ({ ...element, translatedLabel: element.id }))
    : mergeWith(objValue, srcValue, mergeCustomizer);

export const mergeDropdownConfigs = (oldConfig: any, config: any): Dropdowns =>
  mergeWith(oldConfig, config, mergeCustomizer);

export const mergeIconsConfig = (oldIcons: any, config = []): Assets => {
  const icons = {
    ...oldIcons,
  };

  if (Array.isArray(config)) {
    config.forEach((item: any) => {
      const { asset, id } = item;

      const [category, newId] = splitCategoryId(id);

      const iconSrc = env(EnvironmentVariable.LOGO_STORAGE_URL);
      const iconCategory = icons[category] || [];
      icons[category] = iconCategory.map((i: any) =>
        i.id === newId ? { ...i, icon: `${iconSrc}${asset}` } : i,
      );
    });
  }

  return icons;
};

interface ProcessConfig {
  dropdowns: Dropdowns;
  assets: Assets;
}

export const mergePartnerConfig = (
  initialConfig: ProcessConfig,
  overrideConfig: any = {},
): ProcessConfig => {
  const overrideDropdowns = _get(overrideConfig, ['partner-config', 'dropdowns'], {});
  const dropdowns = mergeDropdownConfigs(initialConfig.dropdowns, overrideDropdowns);

  const overrideIcons = _get(overrideConfig, ['partner-config', 'assets'], {});
  const assets = mergeIconsConfig(initialConfig.assets, overrideIcons);

  return {
    dropdowns,
    assets,
  };
};
