import _get from 'lodash/get';

import {
  SOURCE_OF_FUNDS_EQUITY_GRANTS,
  SOURCE_OF_FUNDS_EQUITY_RELEASE,
  SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS,
  SOURCE_OF_FUNDS_GAK_LOAN,
  SOURCE_OF_FUNDS_LOAN_PROJECT,
  SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapSourceOfFundsToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const sourceOfFunds = {
    source_of_funds: {
      equity_grants: toFloatPrecision2(unknownToString(_get(data, SOURCE_OF_FUNDS_EQUITY_GRANTS))),
      equity_release: toFloatPrecision2(
        unknownToString(_get(data, SOURCE_OF_FUNDS_EQUITY_RELEASE)),
      ),
      gak_equity_share_buying_costs: toFloatPrecision2(
        unknownToString(_get(data, SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS)),
      ),
      gak_loan: toFloatPrecision2(unknownToString(_get(data, SOURCE_OF_FUNDS_GAK_LOAN))),
      loan_project: toFloatPrecision2(unknownToString(_get(data, SOURCE_OF_FUNDS_LOAN_PROJECT))),
      notes_on_financing_plan: _get(data, SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN),
    },
  };

  return sourceOfFunds;
};

export default mapSourceOfFundsToApi;
