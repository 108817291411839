import { useState, useCallback, useEffect } from 'react';

import { usePartnerConfig } from 'config/partner/hooks';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { fetchDataListItems } from '../../../../../Rentenbank/SubsidyForestryCompanies/SubjectList/service';
import {
  DataListOption,
  mapSuggestionsFromApiToOptions,
} from '../../../../../Rentenbank/SubsidyForestryCompanies/SubjectList/useFetchSubjectList';

export const useFetchDataListItems = (dataListName: string) => {
  const { makeCall, isPending: isLoading } = useDispatchApiCall();
  const [items, setItems] = useState<DataListOption[]>([]);
  const {
    details: { id: partnerName },
  } = usePartnerConfig();

  const fetchDataListItem = useCallback(async () => {
    const { payload } = await makeCall(fetchDataListItems(partnerName!, dataListName));
    setItems(mapSuggestionsFromApiToOptions(payload));
    return;
  }, [dataListName, makeCall, partnerName]);

  useEffect(() => {
    fetchDataListItem();
  }, [fetchDataListItem]);

  return { items, isLoading };
};
