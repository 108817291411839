import { ApiResponse } from 'models/ApiResponse';
import { RiskAnalysisReports } from 'models/CompanyDetails.model';

export enum ACTION {
  GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS',
  GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS',
  GET_RISK_ANALYSIS = 'GET_RISK_ANALYSIS',
  GET_RISK_ANALYSIS_SUCCESS = 'GET_RISK_ANALYSIS_SUCCESS',
  GET_ASSOCIATED_PERSONS = 'GET_ASSOCIATED_PERSONS',
  GET_ASSOCIATED_PERSONS_SUCCESS = 'GET_ASSOCIATED_PERSONS_SUCCESS',
}

export interface IGetCompanyDetailsAction {
  type: ACTION.GET_COMPANY_DETAILS_SUCCESS;
  payload: ApiResponse;
}
export interface IGetRiskAnalysisAction {
  type: ACTION.GET_RISK_ANALYSIS_SUCCESS;
  payload: {
    data: {
      included: RiskAnalysisReports;
    };
  };
}

export interface IGetAssociatedPersonsAction {
  type: ACTION.GET_ASSOCIATED_PERSONS_SUCCESS;
  payload: ApiResponse;
}

export type ActionType =
  | IGetCompanyDetailsAction
  | IGetRiskAnalysisAction
  | IGetAssociatedPersonsAction;
