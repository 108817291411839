import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Asset } from 'components/Asset';
import Condition from 'components/Condition';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import paths from 'constants/paths';
import { UPLOAD_DOCUMENTS_BUTTON } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getInquiryAllRequiredFilesUploaded } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ProgressSectionType } from './types';

const { description, action } =
  translations.pages.inquiryDetails.dashboard.actions.requiredFilesUploaded;

type AllRequiredFilesUploadedProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  disableButton?: boolean;
};

export const ProgressAllRequiredFilesUploaded = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  disableButton,
}: AllRequiredFilesUploadedProps) => {
  const allRequiredFilesUploaded = useSelector(getInquiryAllRequiredFilesUploaded);

  const t = useTranslations();

  const { id: inquiryId } = useParams<{ id: string }>();
  const history = useHistory();

  const submitAction = async () => {
    history.push(paths.customer.inquiryDetails.documentExchange.replace(':id', inquiryId));
  };

  const hideButton = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: progressTotal === progressIncrement,
    default: false,
  });

  const showCheckMark = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: allRequiredFilesUploaded ? true : progressTotal === progressIncrement,
    default: isCompleted,
  });

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={showCheckMark}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t(action)}
          </HeadingComponent>

          <TextComponent mb={6}>
            <MarkdownWrapper text={t(description)} />
          </TextComponent>

          <Condition condition={!hideButton}>
            <ButtonComponent
              data-testid={UPLOAD_DOCUMENTS_BUTTON}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              onClick={submitAction}
              variant="primary"
              disabled={disableButton}
            >
              {t(action)}
            </ButtonComponent>
          </Condition>
        </GridItem>
        <GridItem>
          <Center height="100%">
            <Asset
              type="dashboard"
              value={ProgressSectionType.DOCUMENT_UPLOAD}
              htmlHeight="200"
              alt=""
            />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
