import { useCallback } from 'react';

import { mittweidaFields } from 'mittweida/inquiry/fields';
import { useValueTranslationMap } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/useValueTranslationMap';
import { useTranslations } from 'utils/hooks/useTranslations';

import { fieldValuesMap } from './fieldValuesMap';

export const useInquiryValueTranslator = () => {
  const t = useTranslations();
  const financingProjectT = useTranslations(
    'inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields.financingWholeProject.options',
  );
  const MITTWEIDA_PROJECT_KEY =
    mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject;
  const translatedValueMap = useValueTranslationMap();

  const translateFieldValue = useCallback(
    // custom translation for mittweida financingWholeProject value
    (fieldValue, fieldKey) => {
      if (fieldKey === MITTWEIDA_PROJECT_KEY) {
        return fieldValue === 'true'
          ? financingProjectT('wholeProject')
          : financingProjectT('buyingPhase');
      }

      if (typeof fieldValue === 'boolean') {
        return fieldValue ? t('other.yes') : t('other.no');
      }

      // some legacy components returning string value
      if (fieldValue === 'true' || fieldValue === 'false') {
        return fieldValue === 'true' ? t('other.yes') : t('other.no');
      }

      if (typeof fieldValue === 'undefined' || fieldValue === null) {
        return '';
      }

      if (Array.isArray(fieldValue)) {
        return fieldValue.map((value) => translateFieldValue(value, fieldKey)).join(', ');
      }

      // not all values are translatable. Here we distinguish string values from selected options
      const translatableMap = translatedValueMap[fieldKey];
      if (translatableMap) {
        return translatableMap[fieldValue] || fieldValue.toString();
      }

      const fieldValuesMappingFunction = fieldValuesMap[fieldKey] ?? undefined;

      if (fieldValuesMappingFunction) {
        return fieldValuesMappingFunction(fieldValue, t);
      }

      return fieldValue.toString();
    },
    [t, translatedValueMap, financingProjectT, MITTWEIDA_PROJECT_KEY],
  );

  return translateFieldValue;
};
