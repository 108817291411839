import React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField, INPUT_TYPE_DATE } from 'components/Input';
import { USER_PROFILE_BIRTHDATE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { chooseLaneSpecificComponent } from 'shared/chooseLaneSpecificComponent';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { store } from 'store';
import CalendarIcon from 'theme/components/Icon/CalendarIcon';
import { getCurrentUser } from 'utils/user/getters';

type PropType = {
  showSeparator: boolean;
};

const BirthdateField = (props: PropType) => {
  const user = getCurrentUser(store.getState());
  const { showSeparator } = props;

  if (user?.birthDate) {
    return null;
  }

  return (
    <FormRow separator={showSeparator}>
      <InputWithField
        name={USER_PROFILE_BIRTHDATE}
        sideSymbol={() => <CalendarIcon boxSize={6} />}
        type={INPUT_TYPE_DATE}
      />
    </FormRow>
  );
};

const LaneSpecificBirthdateField = chooseLaneSpecificComponent({
  [InquiryLane.contract]: BirthdateField,
  [InquiryLane.offer]: BirthdateField,
  default: null,
});

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: () => null,
  default: LaneSpecificBirthdateField,
});
