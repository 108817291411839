import styled, { keyframes } from 'styled-components/macro';

import { Optional } from 'components/Text';
import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { borderRadius } from 'theme/themeConstants';

export const StyledFileBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 2rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};
`;

export const StyleFileBlockTitle = styled.h5`
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyleFileBlockCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
  height: 118px;
  margin: 2rem 0 2.5rem;
  border: 1px ${({ dashed }) => (dashed ? 'dashed' : 'solid')} transparent;
  border-radius: ${borderRadius.circle};
  animation: ${/* sc-value */ ({ spinning }) => (spinning ? rotate : '')} 2s ease-in-out infinite;

  svg {
    overflow: visible;
    width: 2.75rem;
    animation: ${/* sc-value */ ({ spinning }) => (spinning ? rotate : '')} 2s ease-in-out infinite
      reverse;
  }

  &.green {
    border-color: ${({ theme }) => theme.colors.status.success};
    color: ${({ theme }) => theme.colors.status.success};
  }

  &.yellow {
    border-color: ${({ theme }) => theme.colors.status.warning};
    color: ${({ theme }) => theme.colors.status.warning};
  }

  &.red {
    border-color: ${({ theme }) => theme.colors.status.error};
    color: ${({ theme }) => theme.colors.status.error};
  }

  &.gray {
    border-color: ${({ theme }) => theme.colors.border.darkGrey};
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`;

export const StyledOptional = styled(Optional)`
  position: absolute;
  top: 1em;
  right: 1em;
`;
