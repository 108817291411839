import {
  all,
  fieldHasValue,
  fieldsAreValid,
  getFieldValidatorIfExist,
} from 'modules/Inquiry/Form/formConditions';
import {
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  HIRE_PURCHASE_AMORTISATION_KIND,
  HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  HIRE_PURCHASE_DESCRIPTION,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  HIRE_PURCHASE_TERM_IN_MONTHS,
} from 'modules/Inquiry/Form/formFields';

export const validateHirePurchaseSection = (form) => {
  const alwaysAvailableFields = fieldsAreValid([
    HIRE_PURCHASE_ADVANCE_PAYMENT,
    HIRE_PURCHASE_TERM_IN_MONTHS,
    HIRE_PURCHASE_AMORTISATION_KIND,
    HIRE_PURCHASE_DESCRIPTION,
    HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  ]);

  const residentialValue = getFieldValidatorIfExist(
    HIRE_PURCHASE_RESIDUAL_VALUE,
    fieldHasValue(
      HIRE_PURCHASE_AMORTISATION_KIND,
      HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
    ),
  );

  return all(alwaysAvailableFields, residentialValue)({ form });
};
