import React from 'react';

import { useSelector } from 'react-redux';

import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import SummaryField from 'modules/SummaryTable/SummaryField';
import { StyledSummaryRows as StyledRows } from 'pages/inquiryFlow/SummaryPage/NewSummaryPage/styles';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';

import {
  MarketInformationInquiryMap,
  marketValueStepFieldsMap,
} from './marketInformationFieldsMap';

interface FieldType {
  name: string;
  value: string | boolean | number;
}

const MarketInformationTableStep = ({ stepName }: { stepName: string }) => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const fieldsData = inquiryDetails.financingPurposeFields;
  const fieldsForStep = marketValueStepFieldsMap[stepName]
    ? MarketInformationInquiryMap(stepName, fieldsData)
    : [];

  return (
    <StyledRows>
      {fieldsForStep.map((field: FieldType) => {
        return (
          field && (
            <SummaryField
              key={stepName + field.name}
              fieldName={field.name}
              fieldValue={field.value}
            />
          )
        );
      })}
    </StyledRows>
  );
};

export default MarketInformationTableStep;
