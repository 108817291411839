import React from 'react';

import { FileKind } from 'models/FileKind.type';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import ProcessSpecificFiles from './ProcessSpecificFiles';

// HOC for hausbank contract process specific files
export const HausbankContractFiles = () => {
  const t = useTranslations();
  const { contractsTitle } =
    translations.pages.inquiryDetails.documentExchange.processSpecificFiles;

  const contractKinds = [FileKind.HAUSBANK_CONTRACT, FileKind.HAUSBANK_SIGNED_CONTRACT];

  return <ProcessSpecificFiles title={t(contractsTitle)} kinds={contractKinds} />;
};
