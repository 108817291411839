import React, { ReactNode } from 'react';

import { ReactComponent as FileHalter } from 'assets/icons/projectFinancingRole/file_halter.svg';
import { ReactComponent as Partition } from 'assets/icons/projectFinancingRole/partition.svg';
import { ReactComponent as PropertyBuyer } from 'assets/icons/projectFinancingRole/property_buyer.svg';
import { ReactComponent as PropertyDeveloper } from 'assets/icons/projectFinancingRole/property_developer.svg';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import {
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__REAL_ESTATE,
  ROLES__FILE_HALTER,
  ROLES__PROPERTY_BUYER,
  ROLES__PARTITION,
  ROLES__PROPERTY_DEVELOPER,
  PURPOSE_KIND,
  PROJECT_FINANCING_ROLE,
} from 'modules/Inquiry/Form/formFields';
import CoronaIcon from 'theme/components/Icon/Purposes/CoronaIcon';
import GoodsIcon from 'theme/components/Icon/Purposes/GoodsIcon';
import GuarantyIcon from 'theme/components/Icon/Purposes/GuarantyIcon';
import MachineIcon from 'theme/components/Icon/Purposes/MachineIcon';
import OtherIcon from 'theme/components/Icon/Purposes/OtherIcon';
import OverdraftLiquidityIcon from 'theme/components/Icon/Purposes/OverdraftLiquidityIcon';
import ProjectFinancingIcon from 'theme/components/Icon/Purposes/ProjectFinancingIcon';
import RealEstateIcon from 'theme/components/Icon/Purposes/RealEstateIcon';
import VehicleIcon from 'theme/components/Icon/Purposes/VehicleIcon';

const featureSpecificIconMap: { [key: string]: { [key: string]: ReactNode } } = {
  [PURPOSE_KIND]: {
    [PURPOSE_KIND__CORONA]: <CoronaIcon />,
    [PURPOSE_KIND__OVERDRAFT_LIQUIDITY]: <OverdraftLiquidityIcon />,
    [PURPOSE_KIND__GUARANTY]: <GuarantyIcon />,
    [PURPOSE_KIND__PROJECT_FINANCING]: <ProjectFinancingIcon />,
    [PURPOSE_KIND__VEHICLE]: <VehicleIcon />,
    [PURPOSE_KIND__MACHINE]: <MachineIcon />,
    [PURPOSE_KIND__OFFICE_EQUIPMENT]: <MachineIcon />,
    [PURPOSE_KIND__GOODS]: <GoodsIcon />,
    [PURPOSE_KIND__OTHER]: <OtherIcon />,
    [PURPOSE_KIND__REAL_ESTATE]: <RealEstateIcon />,
  },
  [PROJECT_FINANCING_ROLE]: {
    [ROLES__FILE_HALTER]: <FileHalter />,
    [ROLES__PROPERTY_BUYER]: <PropertyBuyer />,
    [ROLES__PARTITION]: <Partition />,
    [ROLES__PROPERTY_DEVELOPER]: <PropertyDeveloper />,
  },
  [mittweidaFields.financingNeedPage.roleSection.projectFinancingRole]: {
    stockholder: <FileHalter />,
    developer: <PropertyBuyer />,
    partition: <Partition />,
  },
};

type FeatureIconKeys = keyof typeof featureSpecificIconMap;

export const FeatureIcon = ({ feature, value }: { feature: FeatureIconKeys; value: string }) => {
  const icon = featureSpecificIconMap[feature][value];
  if (!icon) {
    return null;
  }
  return <div>{icon}</div>;
};
