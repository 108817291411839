import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const BfsOverdraftLiquidityIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 81 62" aria-hidden="true" {...props}>
      <defs>
        <polygon id="path-1" points="0 62 81 62 81 0 0 0" />
      </defs>
      <g stroke="none" strokeWidth={1} fill="currentColor" fillRule="evenodd">
        <path
          d="M4.42541436,50.0345102 C2.85082873,50.0345102 1.57458564,51.3018317 1.57458564,52.8654101 C1.57458564,54.4289886 2.85082873,55.6963101 4.42541436,55.6963101 C6,55.6963101 7.27624309,54.4289886 7.27624309,52.8654101 C7.27624309,51.3018317 6,50.0345102 4.42541436,50.0345102 C4.42541436,50.0345102 4.42541436,50.0345102 4.42541436,50.0345102 M4.42541436,57.2598885 C1.98066298,57.2598885 0,55.2930714 0,52.8654101 C0,50.4377489 1.98066298,48.4709318 4.42541436,48.4709318 C6.87016575,48.4709318 8.85082873,50.4377489 8.85082873,52.8654101 L8.85082873,52.8654101 C8.84254144,55.2881338 6.86519337,57.2516591 4.42541436,57.2598885"
          id="Fill-1"
          fill="#E4E4E4"
        />
        <path
          d="M24.1160221,31.0082294 C22.5414365,30.9983541 21.2585635,32.2590921 21.2485784,33.8226706 C21.2403315,35.386249 22.5082873,36.660154 24.0828729,36.6700693 C25.6574586,36.6782586 26.9403315,35.4191664 26.9502762,33.855588 C26.9502762,33.8506504 26.9502762,33.8440669 26.9502762,33.8391293 C26.9502762,32.2821343 25.6839779,31.0181046 24.1160221,31.0082294 L24.1160221,31.0082294 Z M24.1160221,38.2336076 C21.6712707,38.2336076 19.6906077,36.2667905 19.6906077,33.8391293 C19.6906077,31.411468 21.6712707,29.4446509 24.1160221,29.4446509 C26.5607735,29.4446509 28.5414365,31.411468 28.5414365,33.8391293 C28.5414365,36.2667905 26.5607735,38.2336076 24.1160221,38.2336076 Z"
          id="Fill-3"
          fill="#E4E4E4"
        />
        <path
          d="M48.2320442,54.7746217 C46.6574586,54.7746217 45.3812155,56.0419432 45.3812155,57.6055216 C45.3812155,59.1691001 46.6574586,60.4364216 48.2320442,60.4364216 C49.8066298,60.4364216 51.0828729,59.1691001 51.0828729,57.6055216 C51.0828729,56.0419432 49.8066298,54.7746217 48.2320442,54.7746217 M48.2320442,62 C45.7872928,62 43.8066298,60.0331829 43.8066298,57.6055216 C43.8066298,55.1778604 45.7872928,53.2110433 48.2320442,53.2110433 C50.6767956,53.2110433 52.6574586,55.1778604 52.6574586,57.6055216 L52.6574586,57.6055216 C52.6491713,60.0282453 50.6718232,61.9917706 48.2320442,62"
          id="Fill-5"
          fill="#E4E4E4"
        />
        <path
          d="M48.4143646,13.0682241 C49.141989,13.0632864 49.8447514,13.3414388 50.3701657,13.8417839 L56.3535912,19.7504646 C56.5872928,19.9825325 56.718232,20.2952482 56.718232,20.6227767 L56.718232,25.2476772 C56.718232,26.5824794 57.5303867,27.7856119 58.7734807,28.2925405 L58.7734807,20.2277675 C58.7867403,19.6122113 58.5464088,19.0180515 58.1104972,18.5818954 L50.9834254,11.2742235 C50.3254144,10.6175206 49.5099448,10.1352801 48.6132597,9.87523228 L33.3646409,4.93761614 L33.3646409,14.5165915 L35.9337017,19.8821343 L40.0607735,23.9803557 C40.3309392,23.063605 40.0773481,22.07279 39.3977901,21.3963366 L36.7458564,18.7629413 C36.3165746,18.2971595 36.3165746,17.5828511 36.7458564,17.1170693 L39.7790055,13.7101141 C40.0110497,13.4615875 40.3325967,13.313459 40.6740331,13.2986461 L48.4640884,13.0682241 L48.4143646,13.0682241 Z M60.3314917,30.1852933 L59.4530387,30.086541 C56.9867403,29.816618 55.120442,27.7444651 55.1270718,25.2805946 L55.1270718,20.787364 L49.2430939,14.9445182 C49.0243094,14.7387842 48.7325967,14.6268649 48.4309392,14.6318025 L40.7734807,14.8128484 L38.121547,17.8906291 L40.5414365,20.2936023 C42.0198895,21.7666578 42.1823204,24.0955668 40.9226519,25.7578975 L40.3756906,26.4656225 L34.6574586,20.8038227 L31.7900552,14.8128484 L31.7900552,2.84735864 L49.0939227,8.36103 C50.2309392,8.72312185 51.260221,9.35678259 52.0939227,10.2044067 L59.2209945,17.5120786 C59.9320442,18.239554 60.3314917,19.2139103 60.3314917,20.2277675 L60.3314917,30.1852933 Z"
          id="Fill-7"
          fill="#E4E4E4"
        />
        <path
          d="M58.5911602,11.010884 L58.1436464,9.51314043 C59.7314917,9.01114946 60.8104972,7.54796921 60.8121547,5.89222193 L36.2486188,5.89222193 L36.2486188,4.24634988 L61.1104972,4.24634988 C61.7966851,4.24634988 62.3535912,4.79936289 62.3535912,5.48075392 C62.5160221,7.96108309 60.9679558,10.2356783 58.5911602,11.010884"
          id="Fill-9"
          fill="#E4E4E4"
        />
        <path
          d="M60.0331492,17.8247943 C58.6508287,17.8198566 57.3265193,17.2701354 56.3535912,16.2941333 L53.038674,13.0023892 L50.3038674,10.714627 L51.3812155,9.51314043 L54.0662983,11.8502787 L57.3812155,15.1420228 C58.7320442,16.4850544 60.8917127,16.5772233 62.3535912,15.3559862 L56.0220994,9.03583754 C55.6176796,8.62272365 55.0607735,8.39065569 54.480663,8.39394744 L44.1381215,8.39394744 L44.1381215,6.83036899 L54.480663,6.83036899 C55.481768,6.83201487 56.4414365,7.22867003 57.1491713,7.93310327 L63.7790055,14.5165915 C64.2629834,14.998832 64.2629834,15.7789753 63.7790055,16.2612158 C62.7928177,17.261906 61.441989,17.8247943 60.0331492,17.8247943"
          id="Fill-11"
          fill="#E4E4E4"
        />
        <path
          d="M32.121547,17.7754181 L18.4309392,17.7754181 L18.4309392,16.1295461 L31.7900552,16.1295461 L31.7900552,1.64587205 L18.4309392,1.64587205 L18.4309392,0 L32.121547,0 C32.8044199,0.00822936023 33.3563536,0.556304752 33.3646409,1.23440404 L33.3646409,16.5410141 C33.3646409,17.2224051 32.8077348,17.7754181 32.121547,17.7754181 Z"
          id="Fill-13"
          fill="#E4E4E4"
        />
        <path
          d="M48.0662983,21.3963148 C43.901105,21.3864614 40.518232,24.7325192 40.5082653,28.8685957 C40.4983425,33.0046722 43.8679558,36.363897 48.0331492,36.3737874 C52.1983425,36.3820016 55.5812155,33.0375896 55.5911602,28.9015131 C55.5911602,28.8965755 55.5911602,28.889992 55.5911602,28.8850544 C55.5911602,24.7555615 52.2248619,21.4062118 48.0662983,21.3963148 M48.0662983,37.8550751 C43.0309392,37.8649323 38.9436464,33.8193788 38.9336835,28.8192195 C38.9237569,23.8190603 42.9977901,19.7603398 48.0331492,19.7504465 C53.0685083,19.7405893 57.1558011,23.7861428 57.1657459,28.7863021 C57.1657459,28.7978232 57.1657459,28.8076984 57.1657459,28.8192195 C57.1558011,33.8062118 53.0883978,37.8451818 48.0662983,37.8550751"
          id="Fill-15"
          fill="#E4E4E4"
        />
        <polygon
          id="Fill-17"
          fill="#E4E4E4"
          points="6.44850829 49.7529015 20.9893923 35.8238864 22.0833149 36.948017 7.54243094 50.878678"
        />
        <polygon
          id="Fill-19"
          fill="#E4E4E4"
          points="26.131326 36.9430794 27.2385083 35.8321157 46.3887845 54.6625378 45.2816022 55.7735015"
        />
        <polygon
          id="Fill-21"
          fill="#E4E4E4"
          points="50.0348619 54.7280435 79.6934254 25.5236899 80.8022652 26.6330077 51.1437017 55.8390072"
        />
        <polygon
          id="Fill-23"
          fill="#E4E4E4"
          points="81 31.2715689 79.4254144 31.2715689 79.4254144 26.8770905 75 26.8770905 75 25.3135121 81 25.3135121"
        />
        <path
          d="M48.7790055,33.5757898 C46.160221,33.5708521 44.041989,31.4591983 44.0486033,28.8603663 C44.0535912,26.2598885 46.178453,24.156464 48.7972376,24.161393 C49.6226519,24.1630475 50.4331492,24.3786568 51.1491713,24.786833 L50.3535912,26.1529068 C49.8779006,25.8698168 49.3325967,25.7216883 48.7790055,25.7249801 C47.0303867,25.7249801 45.6132597,27.1338466 45.6149171,28.8702416 C45.6149171,30.6049907 47.0320442,32.0122113 48.7790055,32.0122113 C49.2812155,32.0155031 49.7751381,31.8970003 50.2209945,31.6665782 L50.9337017,33.0655694 C50.2640884,33.3980356 49.5265193,33.572498 48.7790055,33.5757898"
          id="Fill-25"
          fill="#E4E4E4"
        />
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <g id="Clip-28" />
        <polygon
          id="Fill-27"
          fill="#E4E4E4"
          mask="url(#mask-2)"
          points="43.4254144 28.6052562 48.1160221 28.6052562 48.1160221 27.5683568 43.4254144 27.5683568"
        />
        <polygon
          id="Fill-29"
          fill="#E4E4E4"
          mask="url(#mask-2)"
          points="43.4254144 30.1523759 48.1160221 30.1523759 48.1160221 29.1154765 43.4254144 29.1154765"
        />
      </g>
    </Icon>
  );
};

export default BfsOverdraftLiquidityIcon;
