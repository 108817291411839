import React from 'react';

import { compose } from 'redux';

import PERMISSIONS from 'constants/user/permissions';
import RevisionChange from 'modules/InquiryDetails/InquiryRevision/RevisionChange';
import RevisionDates from 'modules/InquiryDetails/InquiryRevision/RevisionDates';
import {
  StyledDetailedSection as Section,
  StyledRevisionWrapper as Wrapper,
  StyledRevisionSidebar as Sidebar,
  StyledRevisionTitle as Title,
  StyledRevisionChanges as RevisionChanges,
} from 'modules/InquiryDetails/InquiryRevision/styles';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import withConfigFlagGuard from 'shared/guard/withConfigFlagGuard';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useTranslations } from 'utils/hooks/useTranslations';

import { RevisionProvider } from './RevisionProvider';

const InquiryRevision = () => {
  const t = useTranslations('pages.inquiryDetails.changelogs');

  return (
    <RevisionProvider>
      <Section>
        <Title>{t('title')}</Title>
        <Wrapper>
          <Sidebar>
            <RevisionDates />
          </Sidebar>
          <RevisionChanges>
            <RevisionChange />
          </RevisionChanges>
        </Wrapper>
      </Section>
    </RevisionProvider>
  );
};

export default compose<any>(
  withConfigFlagGuard(CONFIG.IS_CHANGELOG_ON),
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.CHANGELOG),
)(InquiryRevision);
