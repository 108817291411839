import React from 'react';

import { useIntl } from 'react-intl';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import {
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
} from 'modules/Inquiry/Form/formFields';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';

import getAnnualNetProfitOrLossClassOptions from './SubsidiesConsidered/annualNetProfitOrLossClassOptions';

export default function ConsiderSubsidies() {
  const { formatMessage } = useIntl();
  return (
    <Condition isBusinessCondition condition={fieldBusinessConditions.shouldConsiderSubsidies}>
      <FormRow>
        <SelectWithField
          name={USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS}
          options={getAnnualNetProfitOrLossClassOptions(formatMessage)}
          data-testid={'select-field-' + USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS}
        />
        <YesNoRadioGroupWithField name={USER_PROFILE_NEGATIVE_EQUITY_CAPITAL} />
      </FormRow>

      <FormRow>
        <YesNoRadioGroupWithField name={USER_PROFILE_INNOVATIVE_COMPANY} />
        <YesNoRadioGroupWithField name={USER_PROFILE_ENERGY_EFFICIENT} />
      </FormRow>
    </Condition>
  );
}
