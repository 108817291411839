import React from 'react';

import { InputWithField } from 'components/Input';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledFormRow } from './styles';

const CompanyFilter = () => {
  const t = useTranslations();
  const { optional } = useFieldValidators();

  return (
    <StyledFormRow>
      <InputWithField
        name={INQUIRY_FILTERS.COMPANY_NAME}
        validate={optional}
        caption={t('pages.inquiryList.filtersDrawer.fields.companyName.caption')}
        isClearable
      />
    </StyledFormRow>
  );
};

export default CompanyFilter;
