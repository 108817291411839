import React from 'react';

import { Flex } from '@chakra-ui/react';
import { ValidationErrorItem } from '@hapi/joi';
import _isNil from 'lodash/isNil';
import { useForm } from 'react-final-form';

import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import { useInquiryValueTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryValueTranslator';
import { FieldUnitType, unitFormatters, unitTypeSuffixMap } from 'new/form/formatters/units';
import { useFieldValue } from 'store/hooks/useFormValues';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { removeBracketsFromArrayFields } from 'utils/form/getFieldName';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

import { TextWrapper } from './styles';
import { InputCaption, Text } from '../Text';

export const EMPTY_VALUE_FALLBACK = '-';

type StaticFieldProps = {
  caption: string;
  tooltip?: string;
  text: React.ReactNode;
  error?: ValidationErrorItem | string;
  sideSymbol?: () => React.ReactNode;
  isHorizontal?: boolean;
  dataTestId?: string;
  noFlex?: boolean;
};

const StaticField = ({
  caption,
  tooltip,
  text,
  error,
  sideSymbol,
  isHorizontal,
  dataTestId,
  noFlex,
}: StaticFieldProps) => {
  const t = useTranslations();

  const showSideSymbol = sideSymbol && text && text !== EMPTY_VALUE_FALLBACK;

  return (
    <Flex direction={'column'} alignItems={'start'} gap={2}>
      <TextWrapper
        className="wrapper"
        isHorizontal={isHorizontal}
        data-testid={dataTestId}
        as="dl"
        noFlex={noFlex}
      >
        <InputCaption className="caption" as="dt">
          {caption}
          {tooltip && (
            <PopoverComponent
              ml={1}
              transform="translateY(-1px)"
              text={tooltip}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} d="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          )}
        </InputCaption>
        <Text className="value" as="dd">
          {text || '-'}
          {showSideSymbol ? <span className="symbol"> {sideSymbol()}</span> : null}
        </Text>
      </TextWrapper>
      {error && <FieldError message={error} />}
    </Flex>
  );
};

export default StaticField;

type AdapterProps = {
  fieldName: string;
  caption: string;
  errorMessage?: ValidationErrorItem | string;
  tooltip?: string;
  parseValue?(value: any): any;
  sideSymbol?: () => React.ReactNode;
  isHorizontal?: boolean;
  unit?: FieldUnitType;
};

const StaticFieldAdapter = ({
  errorMessage,
  caption,
  tooltip,
  parseValue,
  sideSymbol,
  isHorizontal,
  fieldName,
}: AdapterProps) => {
  const value = useFieldValue(fieldName, '');
  const valueParser = parseValue ? parseValue(value) : value;

  return (
    <StaticField
      caption={caption}
      tooltip={tooltip}
      error={errorMessage}
      text={valueParser}
      sideSymbol={sideSymbol}
      isHorizontal={isHorizontal}
    />
  );
};

export const StaticFieldWithField = withFormField(StaticFieldAdapter);

const NewStaticFieldAdapter = ({
  fieldName,
  caption,
  errorMessage,
  tooltip,
  parseValue,
  sideSymbol,
  isHorizontal,
  unit = 'none',
}: AdapterProps) => {
  const t = useTranslations();
  const form = useForm();
  const value = form.getFieldState(fieldName)?.value;
  const valueTranslator = useInquiryValueTranslator();
  const newFieldName = removeBracketsFromArrayFields(fieldName);
  const unitFormatter = unitFormatters[unit];
  const translatedValue = valueTranslator(value, newFieldName);
  const formattedValue = unitFormatter
    ? unitFormatter(translatedValue, false)
    : translatedValue || EMPTY_VALUE_FALLBACK;
  const suffix = unitTypeSuffixMap[unit](t);

  let text = EMPTY_VALUE_FALLBACK;
  if (!_isNil(value)) {
    const formatted = `${formattedValue} ${suffix || ''}`;
    text = parseValue ? parseValue(value) : formatted;
  }

  return (
    <StaticField
      caption={caption}
      tooltip={tooltip}
      error={errorMessage}
      text={text}
      sideSymbol={sideSymbol}
      isHorizontal={isHorizontal}
      noFlex
    />
  );
};

export const NewStaticFieldWithField = withFormField(NewStaticFieldAdapter, {
  isStaticField: true,
});
