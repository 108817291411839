import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useLoadMarketData, MAX_RETRIES } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import MittweidaMarketComparison from 'mittweida/portals/operations/pages/MarketComparison';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { EditStepWrapper } from 'modules/SummaryTable/EditStepButton/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { AccordionComponent } from 'theme/components/Accordion';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { deepCamelcaseKeys } from 'utils/object';

import mapMarketDetailsToEdit from '../EditMarketValuesReeProfitability/mapMarketDetailsToEdit';
import { PROFI_RECORD_TYPE } from '../SharedComponent/constants';
import { getMarketDataFilter } from '../SharedComponent/filterManagementReducer';
import { LocationAnalysisTiles } from '../SharedComponent/LocationAnalysisTiles';
import MarketDataEvaluationTable from '../SharedComponent/MarketDataEvaluationTable';
import MarketDataTopBoxes from '../SharedComponent/MarketDataTopBoxes';
import ProjectCostingFilters from '../SharedComponent/ProjectCostingFilters';
import ProjectCostingHeader from '../SharedComponent/ProjectCostingHeader';
import useEditFormConfig from '../useEditFormConfig';

const ProfiMarketComparison = () => {
  const { error } = useToasts();
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const errorsT = useTranslations('errors');
  const { data, isLoading } = useLoadMarketData({
    variables: { inquiryId },
    select: (response) => ({
      computedMarketData: deepCamelcaseKeys(response.data.attributes.computed_details),
      updatedAt: response.data.attributes.updated_at,
    }),
    retry: (failureCount, responseError) => {
      if (responseError && failureCount === MAX_RETRIES) {
        error({ description: errorsT('requestFailed') });
        return false;
      }
      return true;
    },
  });
  const tBase = useTranslations();
  const { saleType, usageType } = useSelector(getMarketDataFilter);
  const { editMarketValueDetails: editMarketDataEvaluationPath } =
    paths.operation.inquiryDetails.planningEvaluation;

  const { onEdit } = useEditFormConfig({
    pathToEdit: editMarketDataEvaluationPath(inquiryId),
    recordType: PROFI_RECORD_TYPE.VALUE_API,
    editFormStructure: mapMarketDetailsToEdit(),
  });

  return (
    <Box pb="20">
      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <ProjectCostingHeader
            heading={t('title')}
            subheading={t('subheading')}
            subheadingDate={data?.updatedAt ? formatDateWithTime(data.updatedAt) : '-'}
          />

          <ProjectCostingFilters />
          <MarketDataTopBoxes topBoxes={data?.computedMarketData?.topBoxes[usageType][saleType]} />
          <AccordionComponent headingLevel="h3" mb={8} title={t('crousalHeader')}>
            <MarketDataEvaluationTable
              evaluation={data?.computedMarketData?.evaluation[usageType][saleType]}
            />

            <EditStepWrapper>
              <ButtonComponent
                leftIcon={<EditIcon boxSize={6} display="block" />}
                onClick={onEdit}
                variant="tertiary"
              >
                {tBase('buttons.edit')}
              </ButtonComponent>
            </EditStepWrapper>
          </AccordionComponent>

          <LocationAnalysisTiles
            location={data?.computedMarketData?.location[usageType][saleType]}
          />
        </>
      )}
    </Box>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: ProfiMarketComparison,
  [InquiryType.profiMittweida]: MittweidaMarketComparison,
});
