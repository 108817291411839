import React from 'react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledPageHeading, StyledRightSection, StyledSearch } from './styles';

interface PageHeadingProps {
  onSearchChange: (value: string) => void;
}

const PageHeading = ({ onSearchChange }: PageHeadingProps) => {
  const t = useTranslations('pages.inquiryList');
  const { showSearch } = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: { showSearch: true },
    [InquiryType.bfs]: { showSearch: false },
    [InquiryType.mmv]: { showSearch: false },
    [InquiryType.hausbank]: { showSearch: false },
  });

  return (
    <StyledRightSection>
      <StyledPageHeading as="h2">{t('pageTitle')}</StyledPageHeading>
      {showSearch && <StyledSearch onChange={onSearchChange} />}
    </StyledRightSection>
  );
};

export default PageHeading;
