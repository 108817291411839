import React from 'react';

import { Box } from '@chakra-ui/react';

import { FinancialIncomeStatementTable } from './components/FinancialIncomeStatementTable';
import { useTranslations } from '../../../../utils/hooks/useTranslations';
import { AccordionComponent } from '../../Accordion';

export interface FinancialInformationProps {
  selectedYear: string;
}

export const FinancialIncomeStatement = () => {
  const t = useTranslations('pages.companiesDetails.financialInformation.incomeStatement');

  return (
    <AccordionComponent headingLevel="h2" title={t('headline')} text={t('subline')} mb={2}>
      <Box
        overflowX="auto"
        overflowY="hidden"
        maxWidth="100%"
        sx={{ '-webkit-overflow-scrolling': 'touch' }}
      >
        <FinancialIncomeStatementTable />
      </Box>
    </AccordionComponent>
  );
};
