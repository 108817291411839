import { defineStyleConfig } from '@chakra-ui/react';

const IconButton = defineStyleConfig({
  baseStyle: {
    minWidth: '0',
    height: 'auto',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 'circle',
    padding: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    color: 'brand.default',
    backgroundColor: 'background.white',
    transition:
      'border-color 0.2s ease-in, box-shadow 0.2s ease-in, color 0.2s ease-in, background-color 0.2s ease-in',

    _active: {
      backgroundColor: 'brand.default_L83',
    },

    _disabled: {
      opacity: 1,
      color: 'text.tertiary',

      _hover: {
        borderColor: 'inherit',
        boxShadow: 'none',
        backgroundColor: 'inherit',
      },
    },
  },
});

export default IconButton;
