import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'modules/ConditionalSubform/Condition';
import { requestDetailsConditions } from 'modules/Inquiry/Form/Conditions/default/requestDetails.conditions';
import { isSectionValid } from 'modules/Inquiry/Form/formConditions';
import {
  REQUEST_DETAILS,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  FINANCING_NEED,
  CORONA_FINANCING_OPTION,
} from 'modules/Inquiry/Form/formFields';
import BankSearch from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/BankSearch';
import { DecisionDateSection } from 'modules/Inquiry/Form/Steps/RequestDetails/DecisionDate/DecisionDateSection';
import ProductKind from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKind';
import ProductKindDetailsSection from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/ProductKindDetailsSection';
import SecuritiesSection from 'modules/Inquiry/Form/Steps/RequestDetails/Securities/SecuritiesSection';
import { validateCoronaLoanSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/loanSection.validator';
import { useRequestDetailsSectionValidators } from 'modules/Inquiry/Form/Validations/default/useRequestDetailsSectionValidators';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import FinancingDetailsLeadStep from 'onlinefactoring/financingDetails/lead/FinancingDetailsLeadStep';
import BfsRequestDetails from 'pages/inquiryFlow/RequestDetails/BfsRequestDetails';
import DisabledSections from 'pages/inquiryFlow/RequestDetails/DisabledSections/DisabledSections';
import Heading from 'pages/inquiryFlow/RequestDetails/Heading/Heading';
import SectionWrapper from 'pages/inquiryFlow/RequestDetails/SectionWrapper';
import { useSendLead } from 'pages/inquiryFlow/RequestDetails/useSendLead';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  getIsCoronaSelected,
  getStoredValueSelector,
  getIsBuildingSocietySelected,
} from 'store/progress/selectors';

const RequestDetailsUnwrapped = () => {
  useSendLead();

  const getSectionValidators = useRequestDetailsSectionValidators();

  const { hasSelectedLoan, isProductKindSelected, shouldShowCommonSections } =
    requestDetailsConditions;

  const isBuildingSocietySelected = useSelector(getIsBuildingSocietySelected);
  const isCoronaSelected = useSelector(getIsCoronaSelected);
  const hasSelectedQuickCredit =
    useSelector(getStoredValueSelector([FINANCING_NEED, CORONA_FINANCING_OPTION])) ===
    CORONA_FINANCING_OPTION__QUICK_CREDIT;

  return (
    <InquiryFormNew
      headerComp={<Heading />}
      currentStepName={REQUEST_DETAILS}
      getSectionValidators={getSectionValidators}
    >
      {() => (
        <>
          <SectionWrapper>
            <ProductKind />
          </SectionWrapper>

          <Condition
            condition={isProductKindSelected}
            renderOnFail={() => <DisabledSections numOfSections={3} />}
          >
            <ProductKindDetailsSection />

            <Condition
              condition={
                isCoronaSelected
                  ? isSectionValid(validateCoronaLoanSection(hasSelectedQuickCredit))
                  : shouldShowCommonSections
              }
              onFail={<DisabledSections numOfSections={2} offset={1} />}
            >
              <DecisionDateSection sectionNumber={2} />

              <Condition
                condition={hasSelectedLoan}
                onFail={<BankSearch sectionNumber={isBuildingSocietySelected ? 2 : 3} />}
              >
                <SecuritiesSection />
                <BankSearch sectionNumber={4} />
              </Condition>
            </Condition>
          </Condition>
        </>
      )}
    </InquiryFormNew>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BfsRequestDetails,
  [InquiryType.bfsService]: FinancingDetailsLeadStep,
  default: RequestDetailsUnwrapped,
});
