import React from 'react';

import _times from 'lodash/times';
import PropTypes from 'prop-types';

import DisabledFormSection from 'components/DisabledFormSection';
import { useTranslations } from 'utils/hooks/useTranslations';

const DisabledSections = ({ numOfSections, offset }) => {
  const t = useTranslations('pages.requestDetails.disabledSections');
  const calculateSectionNum = (sectionIndex) => sectionIndex + 1 + offset;
  return (
    <>
      {_times(numOfSections, (num) => (
        <DisabledFormSection
          key={num}
          sectionNumber={calculateSectionNum(num)}
          placeholder={t('email')}
        />
      ))}
    </>
  );
};

DisabledSections.defaultProps = {
  offset: 0,
};

DisabledSections.propTypes = {
  numOfSections: PropTypes.number.isRequired,
  offset: PropTypes.number,
};

export default DisabledSections;
