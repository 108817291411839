import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const WarningIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 15.5a1 1 0 001-.88V9.5a1 1 0 00-2-.12v5.12a1 1 0 001 1z" />
      <circle cx="12" cy="17.5" r="1" />
      <path d="M9.37 3.85L1.75 17.7a3.06 3.06 0 00-.37 1.45 3 3 0 003 3h15.24a3 3 0 002.63-4.45L14.63 3.85a3.18 3.18 0 00-1.18-1.18 3 3 0 00-4.08 1.18zm3.11.57a1 1 0 01.4.4l7.61 13.85a1 1 0 01-.87 1.48H4.38a1 1 0 01-1-1 .91.91 0 01.13-.48l7.61-13.85a1 1 0 011.26-.45z" />
    </Icon>
  );
};

export default WarningIcon;
