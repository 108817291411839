import React from 'react';

import { func, shape, PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import paths from 'constants/paths';
import ButtonsWrapper from 'pages/inquiryFlow/FinalPage/SuccessButtons/ButtonsWrapper';
import intlShape from 'shared/intlShape';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { withIntl } from 'utils/intl';

export class SuccessButtonsUnwrapped extends React.Component {
  state = {
    isRedirecting: false,
  };

  handleContinueButtonClick = () => this.setState({ isRedirecting: true });

  handleNewInquiryButtonClick = () => {
    const { history } = this.props;
    history.push(paths.inquiry.new.root);
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { isRedirecting } = this.state;

    const newInquiryText = formatMessage({ id: 'pages.finalPage.buttons.new' });
    const uploadDocumentsText = formatMessage({ id: 'pages.finalPage.buttons.uploadDocuments' });

    return (
      <ButtonsWrapper>
        <ButtonComponent onClick={this.handleNewInquiryButtonClick} variant="secondary">
          {newInquiryText}
        </ButtonComponent>
        <ButtonComponent
          as="a"
          href={paths.customer.inquiryDetails.documentExchange.replace(':id', this.props.inquiryId)}
          isLoading={isRedirecting}
          leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
          onClick={this.handleContinueButtonClick}
          textDecoration="none"
        >
          {uploadDocumentsText}
        </ButtonComponent>
      </ButtonsWrapper>
    );
  }
}

SuccessButtonsUnwrapped.propTypes = {
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  inquiryId: PropTypes.string.isRequired,
};

export default compose(
  withIntl,
  connect((store) => ({
    inquiryId: getCreatedInquiryId(store),
  })),
)(SuccessButtonsUnwrapped);
