import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const RealEstateIcon = (props: IconProps) => {
  return (
    <Icon
      width="58"
      height="43"
      viewBox="0 0 58 42.53"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M.08 41.67h56.65v.78H.08z" />
      <path d="M56.81 42.53H0v-.94h56.81zM.16 42.38h56.49v-.63H.16zm32.51-17.01V32h11.21v-6.63zm10.43 5.8h-9.64v-5h9.64z" />
      <path d="M44 32H32.6v-6.71H44zm-11.21-.15h11v-6.4h-11zm10.42-.63h-9.83v-5.14h9.79zm-9.63-.16H43v-4.83h-9.46zM7.52 25.37V32h5v-6.63zm4.23 5.8H8.31v-5h3.44z" />
      <path d="M12.62 32H7.44v-6.71h5.18zm-5-.15h4.86v-6.4H7.6zm4.23-.63H8.23v-5.14h3.6zm-3.45-.16h3.3v-4.83H8.38zm-.88-18.93v5.48h21.79v-5.48zm21 4.7H8.31v-3.92h20.21z" />
      <path d="M29.38 17.69H7.44v-5.64h21.94zM7.6 17.54h21.63v-5.33H7.6zm21-.63H8.23v-4.08H28.6zm-20.22-.16h20.06V13H8.38z" />
      <path d="M.08 37.83h56.65v-.78h-5V24.72l.34-.12a7.47 7.47 0 003.78-3.47 17.62 17.62 0 002.08-8.67 17.62 17.62 0 00-2.08-8.67C54.45 1.4 52.58.08 50.59.08s-3.86 1.32-5.24 3.71a17.56 17.56 0 00-2.09 8.68 19.44 19.44 0 001.19 6.82l.26.68H33.46v-11H4.23v28.08H.08zm29.07-.78h-4.31V26.16h4.31zm21.78 0h-3.6V23.6l.78.49a4.63 4.63 0 002.33.73h.49zm-6.87-24.6a17.16 17.16 0 011.84-8c1.25-2.33 2.91-3.6 4.69-3.6S54 2.12 55.28 4.41a17.07 17.07 0 011.87 8c0 5.08-1.93 9.66-4.69 11.12l-.74.4v-6.2l2.72-4.64-.66-.4-2.06 3.5V9.86h-.79v9.69l-4.19-6.83-.66.41 4.85 7.93v3h-.54c-1.74-.11-3.35-1.41-4.55-3.67a17.28 17.28 0 01-1.78-7.94zm.76 8.3a.61.61 0 01.52.34v.05a10.57 10.57 0 001.06 1.48l.12.14v14.29H29.93V25.37h-5.87v11.68h-3.69v-16.3zM5 9.7h27.67V20H15.83v.78h3.76v16.3H5z" />
      <path d="M56.81 37.91H0V37h4.15V8.84h29.39v11.05H44.6l-.22-.58a19.29 19.29 0 01-1.2-6.84 17.74 17.74 0 012.1-8.72C46.67 1.33 48.56 0 50.59 0s3.91 1.33 5.31 3.75a17.6 17.6 0 012.1 8.71 17.65 17.65 0 01-2.09 8.71 7.58 7.58 0 01-3.83 3.51l-.29.1V37h5zM.16 37.75h56.49v-.62h-5V24.66l.39-.13a7.48 7.48 0 003.75-3.44 17.57 17.57 0 002.06-8.63 17.49 17.49 0 00-2.07-8.63C54.39 1.46 52.56.16 50.59.16s-3.81 1.3-5.17 3.67a17.5 17.5 0 00-2.08 8.64 19.46 19.46 0 001.18 6.79l.3.79H33.38V9H4.31v28.13H.16zM51 37.13h-3.75V23.46l.9.57a4.68 4.68 0 002.3.72h.56zM47.41 37h3.44V25h-.41a4.66 4.66 0 01-2.37-.74l-.66-.42zm-.79.16H29.85V25.45h-5.72v11.68h-3.84V20.67h24.53a.67.67 0 01.58.38v.05a10.39 10.39 0 001 1.47l.14.17zM30 37h16.47V22.79l-.11-.12a9.86 9.86 0 01-1.06-1.49v-.05a.52.52 0 00-.45-.3h-24.4V37H24V25.29h6zm-.78.16h-4.46V26.08h4.47zm-4.3-.16h4.15V26.23h-4.15zm-5.25.16H4.94V9.62h27.81V20H15.91v.63h3.76zM5.09 37h14.42V20.83h-3.76v-.94H32.6V9.78H5.09zM51 24.16h-.62C48.62 24 47 22.69 45.77 20.4A17.38 17.38 0 0144 12.45a17.35 17.35 0 011.85-8.08c1.26-2.32 3-3.59 4.76-3.59s3.5 1.27 4.76 3.59a17.12 17.12 0 011.87 8.08c0 5.11-1.94 9.71-4.72 11.19l-.86.46v-6.37l2.69-4.57-.54-.32-2.16 3.68V9.94H51v9.89l-4.3-7-.53.32L51 21zM50.59.93C48.84.93 47.2 2.18 46 4.45a17.11 17.11 0 00-1.83 8 17.21 17.21 0 001.77 7.88c1.18 2.24 2.78 3.53 4.49 3.63h.45v-2.88L46 13.1l.79-.49v.07l4.05 6.59V9.78h.94v6.16l2-3.31.8.47-2.76 4.7v6l.64-.34c2.73-1.45 4.64-6 4.64-11a17 17 0 00-1.85-8C54 2.18 52.33.93 50.59.93z" />
    </Icon>
  );
};

export default RealEstateIcon;
