import styled from 'styled-components/macro';

const WrapperLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({ flexLeft }) => (flexLeft ? 'none' : '1 1 0%')};
  margin-right: 2.5rem;
  hyphens: auto;

  :last-child {
    margin-right: 0;
  }
`;

export default WrapperLabel;
