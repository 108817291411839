import * as React from 'react';

import { Box, chakra, useMultiStyleConfig } from '@chakra-ui/react';

import { VerticalProgressBar } from 'theme/components/VerticalProgressBar';

type ProgressSectionProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  children: React.ReactNode;
  id?: string;
};

export const ProgressSection = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  children,
  id,
}: ProgressSectionProps) => {
  const styles = useMultiStyleConfig('ProgressSection', {});
  return (
    <chakra.section
      sx={styles.container}
      _notLast={isLast ? { ...styles.notLast } : undefined}
      _after={!isLast ? { ...styles.after } : undefined}
      id={id}
    >
      <VerticalProgressBar
        isFirst={isFirst}
        isLast={isLast}
        isCompleted={isCompleted}
        completedValue={completedValue}
        progressIncrement={progressIncrement!}
        progressTotal={progressTotal!}
      />
      <Box>{children}</Box>
    </chakra.section>
  );
};
