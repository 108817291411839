import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { InitialFormStateType } from 'new/form/model';

export const dzbInitialFormState: InitialFormStateType<DzbFieldTypes> = {
  currentStep: 'companyDataPage',
  inquiryMode: 'create',
  pageValidations: {
    companyDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        associationInformationSection: false,
        companyInformationSection: false,
        directDebitMandateSection: false,
        startUpQuestionnaireSection: false,
      },
    },
    retailerDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        retailerInformationSection: false,
        onlineQuestionnaireSection: false,
      },
    },
    personalDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        contactPersonSection: false,
        legalRepresentativesSection: false,
        beneficialOwnersSection: false,
      },
    },
  },
};
