import {
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_CARE_SOCIAL,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_CURA_SOFT,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DEAL_SOFT,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DMRZ,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_HY_CARE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_KEINE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MEDIFOX_DAN,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MULTI_COM,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_NOVENTI_CARE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_SNAP,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_OTHER,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_BEHINDERTEN_VERBAND,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_STARKE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MPC,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TAXI,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_SUE,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TRANSDATA,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_ST_TECHNIC,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TOP_M,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_YOSHTEQ_SOLUTIONS,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_BV_SOZIOTHERAPEUTEN,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_REHA_SPORT,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_AEO_ABRECHNUNGSSSYSTEME,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_ATS_WINDIA,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_PFLEGE_DIGITAL,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND,
} from 'modules/Inquiry/Form/formFields';

const industrySoftwareBillingOptionsMap: { [key: string]: string[] } = {
  [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: [
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_ATS_WINDIA,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_CARE_SOCIAL,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_CURA_SOFT,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DEAL_SOFT,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DMRZ,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_HY_CARE,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MEDIFOX_DAN,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MULTI_COM,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_NOVENTI_CARE,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_PFLEGE_DIGITAL,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_SNAP,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_KEINE,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_OTHER,
  ],
  [PURPOSE_KIND__FACTOMAT]: [
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_AEO_ABRECHNUNGSSSYSTEME,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_BEHINDERTEN_VERBAND,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_BV_SOZIOTHERAPEUTEN,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_DMRZ,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_MPC,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_REHA_SPORT,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_STARKE,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_SUE,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_ST_TECHNIC,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TAXI,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TOP_M,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_TRANSDATA,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_YOSHTEQ_SOLUTIONS,
    BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_OTHER,
  ],
};

const getIndustrySoftwareForBillingOptions = (formValues: { [key: string]: string }) => {
  const purposeKind = formValues[PURPOSE_KIND] || '';
  const options = industrySoftwareBillingOptionsMap[purposeKind] || [];
  return options.map((option) => ({ value: option, label: option }));
};

export default getIndustrySoftwareForBillingOptions;
