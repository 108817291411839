import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM, FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import SingleInformation from 'modules/InquiryDetails/DetailedInformation/InformationRows/SingleInformation';
import { StyledPair } from 'modules/InquiryDetails/DetailedInformation/InformationRows/styles';
import { mqMedium } from 'styles/breakpoints';

export const StyledDetailedSection = styled.section`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 2rem 0;

  ${mqMedium`
    padding: 6rem 0;
  `}

  &:last-child {
    border: 0;
  }
`;

export const StyledRevisionWrapper = styled.div`
  ${mqMedium`
    display: flex;
  `}
`;

export const StyledRevisionChanges = styled.div`
  flex: 3;
`;

export const StyledRevisionSidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  margin-bottom: 3rem;

  ${mqMedium`
    margin-bottom: 0;
  `}
`;

export const StyledRevisionTitle = styled.h4`
  margin-bottom: 2rem;
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledRevisionDateList = styled.ul`
  padding-right: 1rem;

  li {
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const StyledRevisionInfo = styled.dl`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.text.secondary};

  dt {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    font-weight: ${FONT_WEIGHT_BOLD};
  }

  dd {
    font-style: italic;
  }
`;

export const StyledRevisionActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledChangeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;

  ${StyledPair} {
    width: 50%;
  }
`;

export const StyledRevisionChange = styled(SingleInformation)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 3rem;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledOldValue = styled.span`
  margin-right: 1rem;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.status.error};
`;

export const StyledNewValue = styled.span`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.status.success};
`;

export const StyledValueComparision = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding-right: 10rem;
`;
