import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import { IProfitabilityAttributes } from 'api/CompeonReverseApi/operation/types';
import { queryClient } from 'components/App';
import mapBankValuesAdditionalInformationFromApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesAdditionalInformationFromApi';
import mapBankValuesObjectDataFromApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesObjectDataFromApi';
import mapFinancingCriteriaFromApi from 'modules/EditMarketValuesReeProfitability/mapFinancingCriteriaFromApi';
import mapMittweidaProfitabilityFromApi from 'modules/EditMarketValuesReeProfitability/mapMittweidaProfitabilityFromApi';
import { mapRLLProfitabilityFromApi } from 'modules/EditMarketValuesReeProfitability/mapRLLProfitabilityFromAPI';
import {
  BANK_VALUES_RECORD_TYPE,
  FINANCING_CRITERIA_RECORD_TYPE,
  MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { flattenObject } from 'utils/helpers';
import { deepCamelcaseKeys } from 'utils/object';

function isMittweidaProfitabilityCalculationRecordType(
  value: string,
): value is MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE {
  return Object.values(MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE).includes(
    value as MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
  );
}

const loadPlanningEvaluationProfitabilityData = async (inquiryId: string, recordType: string) => {
  const variables = {
    inquiryId,
    recordType,
  };
  const { data }: { data: { attributes: IProfitabilityAttributes } } = await queryClient.fetchQuery(
    useLoadProfitabilityData.getKey(variables),
    useLoadProfitabilityData.queryFn,
  );

  let editFormValues;

  if (recordType === BANK_VALUES_RECORD_TYPE.OBJECT_DATA) {
    editFormValues = mapBankValuesObjectDataFromApi(data);
  }
  if (recordType === BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION) {
    editFormValues = mapBankValuesAdditionalInformationFromApi(data);
  }
  if (recordType === FINANCING_CRITERIA_RECORD_TYPE) {
    editFormValues = mapFinancingCriteriaFromApi(data);
  }
  if (isMittweidaProfitabilityCalculationRecordType(recordType)) {
    editFormValues =
      mapMittweidaProfitabilityFromApi(data)[
        recordType as MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE
      ];
  }

  return flattenObject(editFormValues);
};

export const loadProfitability = async (inquiryId: string, recordType: string) => {
  const variables = {
    inquiryId,
    recordType,
  };
  const { data }: { data: { attributes: IProfitabilityAttributes } } = await queryClient.fetchQuery(
    useLoadProfitabilityData.getKey(variables),
    useLoadProfitabilityData.queryFn,
  );
  const editFormValues = mapRLLProfitabilityFromApi(deepCamelcaseKeys(data.attributes.details));

  return flattenObject(editFormValues);
};

export default loadPlanningEvaluationProfitabilityData;
