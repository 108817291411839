import React from 'react';

import { chakra } from '@chakra-ui/react';

type PresentationProps = {
  isFirst: boolean | undefined;
  isLast: boolean | undefined;
  children: React.ReactChild;
};

const Presentation = (props: PresentationProps) => {
  const { isFirst, isLast, children } = props;
  return (
    <chakra.div
      id={'presentation'}
      position="absolute"
      zIndex="verticalProgressBar.bar"
      top={isLast ? '0' : isFirst ? '4rem' : '0'}
      bottom="0"
      left="50%"
      w="4px"
      h={isLast ? (isFirst ? '0' : '4rem') : undefined}
      transform="translateX(-50%)"
      backgroundColor="border.lightGrey"
      role="presentation"
    >
      {children}
    </chakra.div>
  );
};

export default Presentation;
