import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useSubmitMarketValues,
  useSubmitProfitability,
} from 'api/CompeonReverseApi/operation/queryHooks';
import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import mapBankValuesAdditionalInformationToApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesAdditionalInformationToApi';
import mapBankValuesObjectDataToApi from 'modules/EditMarketValuesReeProfitability/mapBankValuesObjectDataToApi';
import mapFinancingCriteriaToApi from 'modules/EditMarketValuesReeProfitability/mapFinancingCriteriaToApi';
import mapMarketValueToApi from 'modules/EditMarketValuesReeProfitability/mapMarketValueToApi';
import mapMittweidaProfitabilityToApi from 'modules/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToApi';
import mapMwMarketValueToApi from 'modules/EditMarketValuesReeProfitability/mapMwMarketValueToApi';
import {
  mapRLLEvaluationRentToAPI,
  mapRLLEvaluationToAPI,
  mapRLLFinancingDetailsToAPI,
  mapRLLInvestmentCalculationToAPI,
  mapRLLLendingValueToAPI,
  mapRLLPresalesToAPI,
  mapRLLRealEstateExpertToAPI,
} from 'modules/EditMarketValuesReeProfitability/mapRLLProfitabilityToAPI';
import {
  PROFITABILITY_RECORD_TYPE,
  MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
  PROFI_RECORD_TYPE,
  BANK_VALUES_RECORD_TYPE,
  FINANCING_CRITERIA_RECORD_TYPE,
  MW_MARKET_DATA_RECORD_TYPE,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { deepSnakecaseKeys } from 'utils/object';

type LocationState = {
  editFormStructure: Array<EditMarketValue>;
  recordType: string;
};

export const useProjectCostingEditSubmit = () => {
  const location = useLocation();
  const { recordType } = location.state as LocationState;
  const inquiryId = useSelector(getInquiryIdSelector) || '';

  const history = useHistory();

  const profitabilityMutation = useSubmitProfitability({
    onSuccess: () => {
      history.goBack();
    },
  });

  const marketValuesMutation = useSubmitMarketValues({
    onSuccess: () => {
      history.goBack();
    },
  });

  const handleEditMarketValues = async (data: Record<string, unknown>) => {
    try {
      const mappedDataToPayload = mapMarketValueToApi(data);
      await marketValuesMutation.mutateAsync({ data: mappedDataToPayload, inquiryId });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const handleEditMwMarketValues = async (data: Record<string, unknown>) => {
    try {
      const mappedDataToPayload = mapMwMarketValueToApi(data);
      await marketValuesMutation.mutateAsync({ data: mappedDataToPayload, inquiryId });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const handleEditBankValuesObjectData = async (data: Record<string, unknown>) => {
    try {
      const mappedDataToPayload = mapBankValuesObjectDataToApi(data);
      await profitabilityMutation.mutateAsync({
        data: mappedDataToPayload,
        inquiryId,
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const handleEditBankValuesAdditionalInformation = async (data: Record<string, unknown>) => {
    try {
      const mappedDataToPayload = mapBankValuesAdditionalInformationToApi(data);
      await profitabilityMutation.mutateAsync({
        data: mappedDataToPayload,
        inquiryId,
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const handleEditFinancingCriteria = async (data: Record<string, unknown>) => {
    try {
      const mappedDataToPayload = mapFinancingCriteriaToApi(data);
      await profitabilityMutation.mutateAsync({
        data: mappedDataToPayload,
        inquiryId,
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const updateProfitability = async (data: Record<string, unknown>) => {
    try {
      const formattedData = deepSnakecaseKeys(data);
      await profitabilityMutation.mutateAsync({
        data: formattedData as any,
        inquiryId,
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const handleEditMittweidaProfitability = async (
    data: Record<string, unknown>,
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE,
  ) => {
    try {
      const mappedDataToPayload = mapMittweidaProfitabilityToApi(data)[recordType];
      await profitabilityMutation.mutateAsync({
        data: mappedDataToPayload as Record<string, unknown>,
        inquiryId,
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const handleEditFormSubmit = (data: Record<string, unknown>) => {
    switch (recordType) {
      case PROFI_RECORD_TYPE.VALUE_API:
        handleEditMarketValues(data);
        break;
      case PROFI_RECORD_TYPE.REE:
        updateProfitability(mapRLLRealEstateExpertToAPI(data));
        break;
      case BANK_VALUES_RECORD_TYPE.OBJECT_DATA:
        handleEditBankValuesObjectData(data);
        break;
      case BANK_VALUES_RECORD_TYPE.ADDITIONAL_INFORMATION:
        handleEditBankValuesAdditionalInformation(data);
        break;
      case FINANCING_CRITERIA_RECORD_TYPE:
        handleEditFinancingCriteria(data);
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT,
        );
        break;
      case MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS:
        handleEditMittweidaProfitability(
          data,
          MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS,
        );
        break;
      case MW_MARKET_DATA_RECORD_TYPE:
        handleEditMwMarketValues(data);
        break;
      case PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION:
        updateProfitability(mapRLLLendingValueToAPI(data));
        break;
      case PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS:
        updateProfitability(mapRLLFinancingDetailsToAPI(data));
        break;
      case PROFITABILITY_RECORD_TYPE.PRESALES_DATA:
        updateProfitability(mapRLLPresalesToAPI(data));
        break;
      case PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS:
        updateProfitability(mapRLLInvestmentCalculationToAPI(data));
        break;
      case PROFITABILITY_RECORD_TYPE.SALES_COST_RATE:
        updateProfitability(mapRLLEvaluationToAPI(data));
        break;
      case PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS:
        updateProfitability(mapRLLEvaluationRentToAPI(data));
        break;
      default:
        return;
    }
  };

  return { handleEditFormSubmit };
};
