import { Mutator } from 'final-form';

type RevalidateFieldsArgs = [string[]];

export const revalidateFields: Mutator = (args, state) => {
  const [fieldNames] = args as RevalidateFieldsArgs;
  for (const fieldName of fieldNames) {
    // Extract the field object
    const field = state.fields[fieldName];
    if (field) {
      // Workaround for fields that are still validated with conditional validations like fieldValidator.when(), like MMV is.
      // Because of the conditional validations there is no field.validators.
      if (field.lastFieldState?.error) {
        field.focus();
        field.blur();
        continue;
      }

      // Extract the validations getter
      const getValidators = field.validators;
      if (getValidators && typeof getValidators[0] === 'function') {
        // Get the validations
        const validators = getValidators[0]();
        if (validators) {
          // Validate and if there is an error, focus and blur the field to trigger display of error
          const error = validators(field.lastFieldState?.value, state.lastFormState?.values || {});
          if (error) {
            field.focus();
            field.blur();
          }
        }
      }
    }
  }
};
