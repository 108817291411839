import * as React from 'react';

import { chakra, Tooltip } from '@chakra-ui/react';

type Props = {
  label?: string;
  children: React.ReactElement;
};

export const TooltipComponent = ({ label, children, ...props }: Props) => {
  return (
    <Tooltip hasArrow arrowSize={12} gutter={16} closeOnClick={false} label={label} {...props}>
      <chakra.span display="inline-flex">{children}</chakra.span>
    </Tooltip>
  );
};
