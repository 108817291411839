import React from 'react';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { usePartnerConfig } from 'config/partner/hooks';
import { useTranslations } from 'utils/hooks/useTranslations';

import { CardHeader } from './styles';

export const ErrorRequest = () => {
  const t = useTranslations();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  return (
    <>
      <CardHeader>{t('components.smsVerification.errorRequest.header')}</CardHeader>
      <MarkdownWrapper
        text={t('components.smsVerification.errorRequest.description', { partnerName })}
      />
    </>
  );
};
