import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const OverdraftIcon = (props: IconProps) => {
  return (
    <Icon
      width="49"
      height="38"
      viewBox="0 0 48.87 37.67"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g fill="#e4e4e4">
        <path d="M2.67 34.79a2.67 2.67 0 112.67-2.67 2.68 2.68 0 01-2.67 2.67zm0-4.39a1.72 1.72 0 101.72 1.72 1.72 1.72 0 00-1.72-1.72zm11.88-7.17a2.67 2.67 0 112.67-2.67 2.67 2.67 0 01-2.67 2.67zm0-4.39a1.72 1.72 0 101.71 1.72 1.72 1.72 0 00-1.71-1.72zM29.1 37.67A2.67 2.67 0 1131.77 35a2.68 2.68 0 01-2.67 2.67zm0-4.39A1.72 1.72 0 1030.82 35a1.72 1.72 0 00-1.72-1.72zm7.3-14.94l-.53-.06a2.93 2.93 0 01-2.61-2.92v-2.73l-3.55-3.55a.7.7 0 00-.49-.19L24.6 9 23 10.87l1.46 1.46a2.54 2.54 0 01.23 3.32l-.33.43-3.45-3.44L19.18 9V1.73l10.44 3.35a4.42 4.42 0 011.81 1.12l4.3 4.44a2.37 2.37 0 01.67 1.65zm-7.19-10.4a1.69 1.69 0 011.18.47L34 12a.75.75 0 01.22.53v2.81a2 2 0 001.24 1.85v-4.9a1.38 1.38 0 00-.4-1l-4.3-4.44A3.34 3.34 0 0029.33 6l-9.2-3v5.82l1.55 3.26 2.49 2.49a1.59 1.59 0 00-.4-1.57l-1.6-1.6a.74.74 0 010-1L24 8.33a.78.78 0 01.54-.25l4.7-.14z" />
        <path d="M35.35 6.69l-.27-.91a2.31 2.31 0 001.61-2.2H21.87v-1h15a.75.75 0 01.75.75 3.31 3.31 0 01-2.27 3.36z" />
        <path d="M36.22 10.83A3.14 3.14 0 0134 9.9l-2-2-1.65-1.39.65-.73 1.62 1.42 2 2a2.22 2.22 0 003 .13L33.8 5.49a1.29 1.29 0 00-.93-.39h-6.24v-.95h6.24a2.28 2.28 0 011.61.67l4 4a.75.75 0 010 1.06 3.16 3.16 0 01-2.26.95zm-16.84-.03h-8.26v-1h8.06V1h-8.06V0h8.26a.76.76 0 01.75.75v9.3a.75.75 0 01-.75.75zM29 23a5.5 5.5 0 115.49-5.49A5.5 5.5 0 0129 23zm0-10a4.55 4.55 0 104.54 4.55A4.55 4.55 0 0029 13zM3.89 30.229l8.773-8.463.66.683-8.773 8.464zm11.876-7.783l.669-.675 11.553 11.441-.668.675zm14.422 10.806L48.08 15.508l.67.674-17.894 17.744z" />
        <path d="M48.87 19h-.95v-2.67h-2.67v-.95h3.62V19zm-19.44 1.4a2.86 2.86 0 111.43-5.34l-.48.83a1.83 1.83 0 00-.95-.26 1.91 1.91 0 000 3.82 1.86 1.86 0 00.87-.21l.43.85a2.93 2.93 0 01-1.3.31z" />
        <path d="M26.2 16.75h2.83v.63H26.2zm0 .94h2.83v.63H26.2z" />
      </g>
    </Icon>
  );
};

export default OverdraftIcon;
