import React from 'react';

import { useSelector } from 'react-redux';

import { CORONA_LIQUIDITY_NEED } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { CompanySize } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/useFetchKfwProgram';
import {
  LIQUIDITY_MONTHS_FOR_SMALL_COMPANY,
  LIQUIDITY_MONTHS_FOR_BIG_COMPANY,
} from 'modules/Inquiry/inquiry.constants';
import { getKfwProgram } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const LiquidityNeed: React.FC = () => {
  const t = useTranslations('pages.coronaStep.fields.liquidityNeed');

  const { companySize } = useSelector(getKfwProgram);

  const numberOfMonthsForLiquidity =
    companySize === CompanySize.small
      ? LIQUIDITY_MONTHS_FOR_SMALL_COMPANY
      : LIQUIDITY_MONTHS_FOR_BIG_COMPANY;
  return (
    <AmountWithField
      name={CORONA_LIQUIDITY_NEED}
      caption={t('caption', { months: numberOfMonthsForLiquidity })}
    />
  );
};

export default LiquidityNeed;
