import React from 'react';

import { useLogout } from 'api/AuthApi/queryHooks/auth';
import StyledLogoutButton from 'components/LogoutButton/StyledLogoutButton';
import useLogoutRedirect from 'modules/Logout/useLogoutRedirect';
import { useToasts } from 'shared/hooks/useToasts';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getUserScope } from 'utils/user/getters';

const LogoutButton = () => {
  const { success } = useToasts();
  const t = useTranslations('pages.companyDetails.sections.contactPerson.buttons');
  const currentScope = getUserScope();
  const logoutRedirect = useLogoutRedirect(currentScope);

  const logoutSuccessHandler = () => {
    success({ description: t('loggedOut') });
    logoutRedirect();
  };
  const { mutate: logOut } = useLogout(logoutSuccessHandler);
  function logoutUser() {
    logOut();
  }

  return (
    <StyledLogoutButton type="button" onClick={logoutUser}>
      {t('logOut')}
    </StyledLogoutButton>
  );
};

export default LogoutButton;
