import React from 'react';

import { useSelector } from 'react-redux';

import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { translations } from 'new/form/common/types';
import { getCompanyDetails } from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const AssociatedPersonHeading = () => {
  const companyDetails = useSelector(getCompanyDetails);
  const { title } = translations.pages.companiesDetails.associatedPerson.new;
  const t = useTranslations();

  return (
    <HeadingComponent as={'h2'} variant={'primary'} mb={8}>
      {t(title)}

      <TextComponent
        as={'span'}
        display={'block'}
        fontWeight={FONT_WEIGHT_REGULAR}
        color={'text.tertiary'}
      >
        {companyDetails.companyName}
      </TextComponent>
    </HeadingComponent>
  );
};
