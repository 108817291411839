import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  CUSTOMER_GRADE,
  CUSTOMER_TYPE,
  LOCATION_GRADE,
  LOCATION_GRADE_RECOMMENDATION,
  NOTES_ON_FINANCING_CRITERIA,
} from 'modules/Inquiry/Form/formFields';

type DropDownsType = {
  type: string;
  name: string;
  caption: string;
  options: {
    value: string;
    label: string;
  }[];
}[];

type TextsType = {
  type: string;
  name: string;
  caption: string;
  defaultValue?: string;
}[];

type TextAreasType = {
  type: string;
  name: string;
  placeholder: string;
  caption?: string;
}[];

const dropdowns: DropDownsType = [
  {
    type: 'dropdown',
    caption: 'pages.editFinancingCriteriaPage.sections.fields.customerGrade.caption',
    name: CUSTOMER_GRADE,
    options: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
    ],
  },
  {
    type: 'dropdown',
    caption: 'pages.editFinancingCriteriaPage.sections.fields.locationGrade.caption',
    name: LOCATION_GRADE,
    options: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'E', label: 'E' },
    ],
  },
  {
    type: 'dropdown',
    caption: 'pages.editFinancingCriteriaPage.sections.fields.customerType.caption',
    name: CUSTOMER_TYPE,
    options: [
      {
        value: 'new_customer',
        label: 'pages.editFinancingCriteriaPage.sections.fields.customerType.options.newCustomer',
      },
      {
        value: 'existing_customer',
        label:
          'pages.editFinancingCriteriaPage.sections.fields.customerType.options.existingCustomer',
      },
    ],
  },
];

const texts: TextsType = [
  {
    type: 'text',
    name: LOCATION_GRADE_RECOMMENDATION,
    caption: 'pages.editFinancingCriteriaPage.sections.fields.locationGrade.suggestion',
    defaultValue: 'pages.editFinancingCriteriaPage.sections.fields.locationGrade.defaultValue',
  },
];

const textAreas: TextAreasType = [
  {
    type: 'textArea',
    name: NOTES_ON_FINANCING_CRITERIA,
    caption: 'pages.editFinancingCriteriaPage.sections.fields.notesOnFinancingCriteria.caption',
    placeholder:
      'pages.editFinancingCriteriaPage.sections.fields.notesOnFinancingCriteria.placeholder',
  },
];

const mapFinancingCriteriaToEdit = () => {
  const sections = [
    {
      title: 'pages.editFinancingCriteriaPage.sections.title',
      fields: [dropdowns[0], dropdowns[1], texts[0], dropdowns[2], textAreas[0]],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editFinancingCriteriaPage.heading',
      subHeading: 'pages.editFinancingCriteriaPage.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

export default mapFinancingCriteriaToEdit;
