import React from 'react';

import { chakra, useTheme } from '@chakra-ui/react';
import { rgba } from 'polished';

import { transition, zIndex } from 'theme/themeConstants';

import type { TableStyleProps } from '../../types';

interface TableCellProps extends TableStyleProps {
  children: React.ReactNode;
}

export const TableCell = ({
  italic,
  bold,
  highlighted,
  indented,
  color = 'text.secondary',
  children,
  rightAligned,
}: TableCellProps) => {
  const theme = useTheme();
  return (
    <chakra.td
      position="relative"
      lineHeight="shorter"
      whiteSpace="pre-wrap"
      fontSize="md"
      paddingY={8}
      zIndex={zIndex.above}
      paddingX={highlighted ? 4 : 6}
      paddingLeft={indented ? 12 : 6}
      justifyContent={highlighted ? 'center' : 'normal'}
      fontWeight={bold ? 'bold' : 'normal'}
      fontStyle={italic ? 'italic' : 'normal'}
      color={color}
      backgroundColor={highlighted ? 'background.lightGrey' : 'inherit'}
      transition={`color ${transition.default}, background-color ${transition.default}`}
      textAlign={rightAligned ? 'right' : 'inherit'}
      _groupHover={{
        color: 'text.primary',
        backgroundColor: rgba(theme.colors.brand.default, 0.05),
      }}
      _notFirst={{ textAlign: 'end' }}
    >
      {children}
    </chakra.td>
  );
};

export default TableCell;
