import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ErrorIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 2A10 10 0 112 12 10 10 0 0112 2zm6.5 5.33L7.33 18.5A8 8 0 0018.5 7.33zM12 4a8 8 0 00-8 8 7.92 7.92 0 001.87 5.13L17.13 5.87A7.92 7.92 0 0012 4z" />
    </Icon>
  );
};

export default ErrorIcon;
