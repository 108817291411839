import React from 'react';

import { useSelector } from 'react-redux';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/CompanyDetailsSection';
import CoronaDetailsSection from 'modules/InquiryDetails/DetailedInformation/CoronaDetailsSection/CoronaDetailsSection';
import FinancingPurposeSection from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/FinancingPurposeSection';
import InquiryDetailsSection from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection';
import SummarySection from 'modules/InquiryDetails/DetailedInformation/SummarySection';
import UserProfileSection from 'modules/InquiryDetails/DetailedInformation/UserProfileSection/UserProfileSection';
import VbProfileSection from 'modules/InquiryDetails/DetailedInformation/VbProfileSection/index';
import InquiryRevision from 'modules/InquiryDetails/InquiryRevision/InquiryRevision';
import { DEFAULT_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import InquiryOffers from 'modules/Offers/InquiryOffersSection';
import AdditionalFinancingSection from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/ForwardToCompeonAcceptance';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { hasOffersSelector } from 'store/inquiryDetails/selectors';

import FinancingOfferSection from './FinancingOfferSection/FinancingOfferSection';

const DefaultSections = () => {
  const hasOffers = useSelector(hasOffersSelector);
  const coreSections = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: [
      <FinancingPurposeSection key={DEFAULT_SECTIONS.FINANCING_PURPOSE_SECTION} />,
      <CompanyDetailsSection key={DEFAULT_SECTIONS.COMPANY_DETAILS_SECTION} />,
      <FinancingOfferSection key={DEFAULT_SECTIONS.FINANCING_OFFER_SECTION} />,
      <UserProfileSection key={DEFAULT_SECTIONS.USER_PROFILE_SECTION} />,
    ],
    [InquiryType.default]: [
      <FinancingPurposeSection key={DEFAULT_SECTIONS.FINANCING_PURPOSE_SECTION} />,
      <UserProfileSection key={DEFAULT_SECTIONS.USER_PROFILE_SECTION} />,
      <CompanyDetailsSection key={DEFAULT_SECTIONS.COMPANY_DETAILS_SECTION} />,
      <InquiryDetailsSection key={DEFAULT_SECTIONS.INQUIRY_DETAILS_SECTION} />,
    ],
  });

  return (
    <>
      <AdditionalFinancingSection />
      {hasOffers && <InquiryOffers />}
      <SummarySection />
      <CoronaDetailsSection />
      {coreSections}
      <VbProfileSection />
      <InquiryRevision />
    </>
  );
};

export default DefaultSections;
