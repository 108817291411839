import * as React from 'react';

import FormRow from 'components/FormRow';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

export const FinanceWholeProjectRadio = () => {
  const t = useTranslations(
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .financingWholeProject.options.sectionString,
  );

  const financeWholeProjectOptions: Array<{ caption: string; value: 'true' | 'false' }> = [
    { caption: t('buyingPhase'), value: 'false' },
    { caption: t('wholeProject'), value: 'true' },
  ];

  return (
    <FormRow>
      <YesNoRadioGroupWithField
        name={mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject}
        options={financeWholeProjectOptions}
      />
    </FormRow>
  );
};
