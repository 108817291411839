import { hausbankFields } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { isLoggedInUser } from 'utils/user/conditionals';

export const validateTermsAndConditionsSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  if (!isLoggedInUser()) {
    return validateFieldValidationMap({
      validationMap: termsAndConditionsSectionValidationMap,
      form,
      values,
      conditions,
    });
  }
  return true;
};

const { termsAndConditionsAccepted, privacyPolicyAccepted } =
  hausbankFields.companyDataPage.termsAndConditionsSection;

export const termsAndConditionsSectionValidationMap = {
  [termsAndConditionsAccepted]: fieldValidators.equal(true).required(),
  [privacyPolicyAccepted]: fieldValidators.equal(true).required(),
};
