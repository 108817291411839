import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const forwardToInquiryManagerAction = (inquiryId: string, organizationUnitId: string) => {
  return callReverseApi({
    url: endpoints.INQUIRIES.FORWARD.INQUIRY_MANAGER.compose({
      params: { id: inquiryId },
      query: {
        data: {
          attributes: {
            organizational_unit_id: organizationUnitId,
          },
        },
      },
    }),
    method: API_METHODS.PATCH,
  });
};

export default forwardToInquiryManagerAction;
