import {
  FINANCING_DETAILS_FEE_DURATION,
  FINANCING_DETAILS_FEE_RATE,
  FINANCING_DETAILS_FINANCING_DURATION,
  FINANCING_DETAILS_INTEREST_RATE,
  FINANCING_DETAILS_REPAYMENT_RATE,
  INVESTMENT_PLAN_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_CONSTRUCTION_COSTS,
  INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_GAK_BUILDING_COSTS,
  INVESTMENT_PLAN_GAK_BUYING_COSTS,
  INVESTMENT_PLAN_GAK_INTEREST_COSTS,
  INVESTMENT_PLAN_GAK_OTHER,
  INVESTMENT_PLAN_GAK_SELLING_COSTS,
  INVESTMENT_PLAN_INTEREST_COSTS,
  INVESTMENT_PLAN_OTHER_COSTS,
  INVESTMENT_PLAN_SELLING_COSTS,
  LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION,
  LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION,
  RENT_CUSTOMER_NOW_ASSET_SURPLUS,
  RENT_CUSTOMER_NOW_EQUITY_PROOF,
  RENT_CUSTOMER_NOW_FREE_INCOME,
  RENT_CUSTOMER_NOW_OPERATING_COSTS,
  RENT_CUSTOMER_PLAN_OPERATING_COSTS,
  SOURCE_OF_FUNDS_EQUITY_GRANTS,
  SOURCE_OF_FUNDS_EQUITY_RELEASE,
  SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS,
  SOURCE_OF_FUNDS_GAK_LOAN,
  SOURCE_OF_FUNDS_LOAN_PROJECT,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const mittweidaProfitabilityUnits: Record<string, FIELD_UNIT_TYPE | string> = {
  [FINANCING_DETAILS_FEE_DURATION]: FIELD_UNIT_TYPE.MONTHS,
  [FINANCING_DETAILS_FEE_RATE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [FINANCING_DETAILS_FINANCING_DURATION]: FIELD_UNIT_TYPE.MONTHS,
  [FINANCING_DETAILS_INTEREST_RATE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [FINANCING_DETAILS_REPAYMENT_RATE]: FIELD_UNIT_TYPE.PERCENTAGE,

  [INVESTMENT_PLAN_ADDITIONAL_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_CONSTRUCTION_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_GAK_BUILDING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_GAK_BUYING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_GAK_INTEREST_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_GAK_OTHER]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_GAK_SELLING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_INTEREST_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_OTHER_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [INVESTMENT_PLAN_SELLING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,

  [LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT]: FIELD_UNIT_TYPE.PERCENTAGE,
  [LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION]: FIELD_UNIT_TYPE.PERCENTAGE,
  [LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT]: FIELD_UNIT_TYPE.PERCENTAGE,
  [LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT]: FIELD_UNIT_TYPE.PERCENTAGE,
  [LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION]: FIELD_UNIT_TYPE.PERCENTAGE,

  [RENT_CUSTOMER_NOW_ASSET_SURPLUS]: FIELD_UNIT_TYPE.CURRENCY,
  [RENT_CUSTOMER_NOW_EQUITY_PROOF]: FIELD_UNIT_TYPE.CURRENCY,
  [RENT_CUSTOMER_NOW_FREE_INCOME]: FIELD_UNIT_TYPE.CURRENCY,
  [RENT_CUSTOMER_NOW_OPERATING_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [RENT_CUSTOMER_PLAN_OPERATING_COSTS]: FIELD_UNIT_TYPE.CURRENCY,

  [SOURCE_OF_FUNDS_EQUITY_GRANTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [SOURCE_OF_FUNDS_EQUITY_RELEASE]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [SOURCE_OF_FUNDS_GAK_LOAN]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [SOURCE_OF_FUNDS_LOAN_PROJECT]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
};
