import { LabelsMap } from 'new/form/common/types';

import { customerDataSectionLabelsMap } from './sections/bankDetails/labels';
import { dealerSectionLabelsMap } from './sections/dealer/labels';
import { financingConditionsLabelsMap } from './sections/financingConditions/labels';
import { financingObjectLabelsMap } from './sections/financingObject/labels';

export const mmvContractDetailsDataLabelsMap: LabelsMap = {
  ...dealerSectionLabelsMap,
  ...customerDataSectionLabelsMap,
  ...financingConditionsLabelsMap,
  ...financingObjectLabelsMap,
};
