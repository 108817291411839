import * as React from 'react';

import { IconButton, ButtonProps, useToken, useStyleConfig } from '@chakra-ui/react';

interface Props extends ButtonProps {
  icon: React.ReactElement;
  label: string;
}

export const IconButtonComponent = ({ icon, label, ...props }: Props) => {
  const iconButtonStyles = useStyleConfig('IconButton');
  const [boxShadowFocus, boxShadowHover] = useToken('colors', [
    'brand.default_L83',
    'brand.default_HalfS_L80',
  ]);

  const boxShadowStyles = {
    _focus: {
      boxShadow: `0 0 0 3px ${boxShadowFocus}`,
    },
    _hover: {
      borderColor: 'brand.default_L83',
      boxShadow: `0 2px 10px 0 ${boxShadowHover}`,
      backgroundColor: 'brand.default_L93',
    },
  };

  const styles = {
    ...iconButtonStyles,
    ...boxShadowStyles,
  };
  return (
    <IconButton
      aria-label={label}
      icon={icon}
      variant="unstyled"
      size={'full'}
      sx={styles}
      {...props}
    />
  );
};
