import React from 'react';

import FormRow from 'components/FormRow';
import StaticField from 'components/StaticField';
import { useFormConfig } from 'config/formConfig/hooks';
import {
  EXISTING_USER_COMPANY_CITY,
  EXISTING_USER_COMPANY_NAME,
  EXISTING_USER_COMPANY_STREET,
  EXISTING_USER_COMPANY_ZIP_CODE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useTranslations } from 'utils/hooks/useTranslations';

export type StaticCompany = {
  [EXISTING_USER_COMPANY_CITY]: string;
  [EXISTING_USER_COMPANY_NAME]: string;
  [EXISTING_USER_COMPANY_STREET]: string;
  [EXISTING_USER_COMPANY_ZIP_CODE]: string;
};

interface Props {
  company: StaticCompany;
  companyNameCaption?: string;
  companyAddressCaption?: string;
}

const StaticCompanyInformation = ({
  company,
  companyNameCaption: companyNameCaptionProp,
  companyAddressCaption: companyAddressCaptionProp,
}: Props) => {
  const t = useTranslations('pages.userProfile.sections.completingCompanyDetails.fields');

  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  const {
    [EXISTING_USER_COMPANY_CITY]: city,
    [EXISTING_USER_COMPANY_NAME]: name,
    [EXISTING_USER_COMPANY_STREET]: street,
    [EXISTING_USER_COMPANY_ZIP_CODE]: zipCode,
  } = company;
  const companyNameCaption = companyNameCaptionProp || t('userProfileCompanyName.caption');
  const companyAddressCaption = companyAddressCaptionProp || t('userProfileCompanyStreet.caption');

  return (
    <FormRow separator={!isBfsService} alignItems="flex-start">
      <StaticField caption={companyNameCaption} text={name} />
      <StaticField caption={companyAddressCaption} text={`${street}\n${zipCode} ${city}`} />
    </FormRow>
  );
};

export default StaticCompanyInformation;
