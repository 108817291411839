import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { dzbFields } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const {
  erpProvider,
  numberOfEmployees,
  numberOfStores,
  retailSpace,
  enterpriseResourcePlanningSystemInUse,
} = dzbFields.retailerDataPage.retailerInformationSection;

export const RetailerInformationSection = () => {
  const t = useTranslations();
  const { title } = translations.inquiryType.dzb.pages.retailerData.sections.retailerInformation;

  return (
    <FormSection title={t(title)} sectionNumber={1}>
      <FormRow>
        <InputWithField name={numberOfStores} type={'number'} />
        <InputWithField
          name={retailSpace}
          type={'number'}
          sideSymbol={() => t(translations.other.qm)}
          optional={true}
        />
      </FormRow>
      <FormRow>
        <InputWithField name={numberOfEmployees} type={'number'} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={enterpriseResourcePlanningSystemInUse} />
        <InputWithField name={erpProvider} />
      </FormRow>
    </FormSection>
  );
};
