import React, { ReactNode, useEffect } from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';

import { useFetchCompanyDetails } from './helpers/useFetchCompanyDetails';
import { useFetchKpis } from '../../../theme/components/FinancialInformations/hooks/useFetchKpis';

interface IFetchCompanyDetails {
  companyId: string;
  children: ReactNode;
}

const FetchCompanyDetails = ({ companyId, children }: IFetchCompanyDetails) => {
  const { isLoading, fetchCompanyDetails } = useFetchCompanyDetails();
  const { isLoading: isLoadingKpis } = useFetchKpis();

  useEffect(() => {
    fetchCompanyDetails(companyId);
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading && isLoadingKpis) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return <>{children}</>;
};

export default FetchCompanyDetails;
