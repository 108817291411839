export enum compeonExternalStateStepKeys {
  COMPEON_EXTERNAL_STATE_STEP__DEFAULT = 'default',
  COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_OF_FINANCING_REQUEST_CHECKED__POSITIVE = 'Machbarkeit/Umsetzbarkeit der Finanzierungsanfrage geprüft (Ergebnis positiv)',
  COMPEON_EXTERNAL_STATE_STEP__DOCUMENTS_REQUESTED_FROM_CUSTOMER = 'Unterlagen/Informationen beim Kunden angefordert',
  COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_OF_FINANCING_REQUEST_CHECKED__NEGATIVE = 'Machbarkeit/Umsetzbarkeit der Finanzierungsanfrage geprüft (Ergebnis negativ)',
  COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_CHECK_AFTER_DOCUMENT_EVALUATION__POSITIVE = 'Machbarkeitscheck nach Bewertung der Unterlagen/Informationen erfolgt (Ergebnis positiv)',
  COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_CHECK_AFTER_DOCUMENT_EVALUATION__NEGATIVE = 'Machbarkeitscheck nach Bewertung der Unterlagen/Informationen erfolgt (Ergebnis negativ)',
  COMPEON_EXTERNAL_STATE_STEP__TENDER_ACTIVATED = 'Ausschreibung aktiviert',
  COMPEON_EXTERNAL_STATE_STEP__TENDER_OFFERS_AVAILABLE = 'Angebote zur Ausschreibung liegen vor',
  COMPEON_EXTERNAL_STATE_STEP__OFFER_SELECTED_BY_CUSTOMER = 'Angebot durch Kunden ausgewählt',
  COMPEON_EXTERNAL_STATE_STEP__FINAL_CREDIT_CHECK_STARTED = 'Finale Kreditprüfung gestartet',
  COMPEON_EXTERNAL_STATE_STEP__FUNDING_APPROVED = 'Finanzierung genehmigt',
  COMPEON_EXTERNAL_STATE_STEP__CREDIT_CONTRACTS_CREATED = 'Kreditverträge erstellt',
  COMPEON_EXTERNAL_STATE_STEP__CONTRACTS_SIGNED = 'Verträge unterschrieben',
  COMPEON_EXTERNAL_STATE_STEP__PROPERTY_TAKEOVER_TAKES_PLACE = 'Auszahlung/Objektübernahme erfolgt',
}

type CompeonExternalStateStepsType = {
  [key in compeonExternalStateStepKeys]: {
    step: number;
    languageKey: string;
  };
};

export const compeonExternalStateSteps: CompeonExternalStateStepsType = {
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__DEFAULT]: {
    step: 0,
    languageKey: 'pages.inquiryDetails.dashboard.summary.compeonExternalState.notAvailable',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_OF_FINANCING_REQUEST_CHECKED__POSITIVE]:
    {
      step: 1,
      languageKey:
        'pages.inquiryDetails.dashboard.summary.compeonExternalState.exchangeWithCompeon',
    },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__DOCUMENTS_REQUESTED_FROM_CUSTOMER]: {
    step: 1,
    languageKey: 'pages.inquiryDetails.dashboard.summary.compeonExternalState.exchangeWithCompeon',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_OF_FINANCING_REQUEST_CHECKED__NEGATIVE]:
    {
      step: 1,
      languageKey:
        'pages.inquiryDetails.dashboard.summary.compeonExternalState.exchangeWithCompeon',
    },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_CHECK_AFTER_DOCUMENT_EVALUATION__POSITIVE]:
    {
      step: 1,
      languageKey:
        'pages.inquiryDetails.dashboard.summary.compeonExternalState.exchangeWithCompeon',
    },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__FEASIBILITY_CHECK_AFTER_DOCUMENT_EVALUATION__NEGATIVE]:
    {
      step: 1,
      languageKey:
        'pages.inquiryDetails.dashboard.summary.compeonExternalState.exchangeWithCompeon',
    },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__TENDER_ACTIVATED]: {
    step: 2,
    languageKey: 'pages.inquiryDetails.dashboard.summary.compeonExternalState.tenderActivated',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__TENDER_OFFERS_AVAILABLE]: {
    step: 3,
    languageKey: 'pages.inquiryDetails.dashboard.summary.compeonExternalState.offerIsAvailable',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__OFFER_SELECTED_BY_CUSTOMER]: {
    step: 4,
    languageKey:
      'pages.inquiryDetails.dashboard.summary.compeonExternalState.offerSelectedByCustomer',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__FINAL_CREDIT_CHECK_STARTED]: {
    step: 5,
    languageKey:
      'pages.inquiryDetails.dashboard.summary.compeonExternalState.finalCreditCheckStarted',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__FUNDING_APPROVED]: {
    step: 6,
    languageKey: 'pages.inquiryDetails.dashboard.summary.compeonExternalState.financeApproved',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__CREDIT_CONTRACTS_CREATED]: {
    step: 7,
    languageKey:
      'pages.inquiryDetails.dashboard.summary.compeonExternalState.creditContractCreated',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__CONTRACTS_SIGNED]: {
    step: 8,
    languageKey: 'pages.inquiryDetails.dashboard.summary.compeonExternalState.contractsSigned',
  },
  [compeonExternalStateStepKeys.COMPEON_EXTERNAL_STATE_STEP__PROPERTY_TAKEOVER_TAKES_PLACE]: {
    step: 9,
    languageKey:
      'pages.inquiryDetails.dashboard.summary.compeonExternalState.propertyTakeoverTakesPlace',
  },
};
