import React from 'react';

import PageHeading from 'components/PageHeading';
import FastlaneInformationHeading from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/FastlaneInformationHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

const SummaryHeading = () => {
  const t = useTranslations('pages.summaryPage.heading');
  return (
    <>
      <PageHeading heading={t('header')} subheading={t('subheader')} />
      <FastlaneInformationHeading />
    </>
  );
};

export default SummaryHeading;
