import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ChatIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M17 23a1 1 0 01-.71-.29L13.59 20H4.76A2.77 2.77 0 012 17.24V6.76A2.77 2.77 0 014.76 4h14.48A2.77 2.77 0 0122 6.76v10.48A2.77 2.77 0 0119.24 20H18v2a1 1 0 01-.62.92.84.84 0 01-.38.08zM4.76 6a.76.76 0 00-.76.76v10.48a.76.76 0 00.76.76H14a1 1 0 01.71.29l1.29 1.3V19a1 1 0 011-1h2.24a.76.76 0 00.76-.76V6.76a.76.76 0 00-.76-.76z" />
      <path d="M17 11H7a1 1 0 010-2h10a1 1 0 010 2zM12 15H7a1 1 0 010-2h5a1 1 0 010 2z" />
    </Icon>
  );
};

export default ChatIcon;
