import React from 'react';

import { CORONA_REVENUE_OF_2019 } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';

const RevenueOf2019: React.FC = () => {
  return <AmountWithField name={CORONA_REVENUE_OF_2019} />;
};

export default RevenueOf2019;
