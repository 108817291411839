import React, { useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { PasswordInputWithField } from 'components/PasswordInput';
import paths from 'constants/paths';
import { CONFIRM_PASSWORD_FIELD, PASSWORD_FIELD } from 'modules/Inquiry/Form/formFields';
import { FormContainer } from 'modules/Login/FormContainer';
import { translations } from 'new/form/common/types';
import { resetPasswordAction } from 'pages/ChangePassword/service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import { ButtonComponent } from 'theme/components/Button';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { usePartnerSpecificPasswordValidators } from 'utils/hooks/usePartnerSpecificPasswordValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const {
  fields: { confirmPassword, password },
  heading,
  subheading,
  submitLabel,
  submitSuccess,
  submitError,
} = translations.pages.changePassword;

const {
  operation: { login: operationLogin, root: operationRoot },
  customer: { login: customerLogin },
} = paths;

interface ChangePasswordFormProps {
  resetPasswordToken: string;
}

interface ChangePasswordFormValues {
  [PASSWORD_FIELD]: string;
  [CONFIRM_PASSWORD_FIELD]: string;
}

export const ChangePasswordForm = ({ resetPasswordToken }: ChangePasswordFormProps) => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const t = useTranslations();
  const { required, equalTo } = useFieldValidators();
  const passwordValidators = usePartnerSpecificPasswordValidators();
  const { success } = useToasts();
  const history = useHistory();

  const isOperationPortalUser = history.location.pathname.startsWith(operationRoot);

  const redirectOnSuccess = isOperationPortalUser ? operationLogin : customerLogin;

  const { makeCall, isPending } = useDispatchApiCall(dispatchOptions(t(submitError)));

  const handleFormSubmit = async ({ password, confirmPassword }: ChangePasswordFormValues) => {
    const response = await makeCall(
      resetPasswordAction(password, confirmPassword, resetPasswordToken),
    );

    if (response?.payload?.status === 204) {
      setIsPasswordChanged(true);
      success({ description: t(submitSuccess) });

      history.push(redirectOnSuccess);
    }
  };

  return (
    <FinalForm<ChangePasswordFormValues>
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) => (
        <FormContainer>
          <FormContainer.HeadingContainer>
            <FormContainer.Heading>{t(heading)}</FormContainer.Heading>
            <FormContainer.SubHeading>{t(subheading)}</FormContainer.SubHeading>
          </FormContainer.HeadingContainer>
          <FormContainer.Body>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Stack spacing={5}>
                  <PasswordInputWithField
                    name={PASSWORD_FIELD}
                    validate={passwordValidators}
                    caption={t(password.caption)}
                  />
                  <PasswordInputWithField
                    name={CONFIRM_PASSWORD_FIELD}
                    validate={combineValidators(required, equalTo(PASSWORD_FIELD))}
                    caption={t(confirmPassword.caption)}
                  />
                </Stack>
                <Stack spacing={6}>
                  <ButtonComponent
                    data-testid="change-password-button"
                    disabled={isPasswordChanged}
                    isLoading={isPending}
                    type="submit"
                  >
                    {t(submitLabel)}
                  </ButtonComponent>
                </Stack>
              </Stack>
            </form>
          </FormContainer.Body>
        </FormContainer>
      )}
    />
  );
};
