export interface Kpi {
  description: string;
  name: string;
  unit: string;
  value: number;
  timestamp: string;
  benchmark?: string;
  ratio?: string;
  ratioBenchmark?: string;
  accessField: string;
  operator?: string;
}

interface KpiFilterProps {
  information: any;
  selectedYear: string;
  selectedMonth: string;
  selectedKpis: string[];
}

export const useKpiFilter = ({
  information,
  selectedYear,
  selectedKpis,
  selectedMonth,
}: KpiFilterProps) => {
  const kpis: Kpi[] = [];

  if (Object.keys(information).length !== 0) {
    const receivedKpis = information?.[selectedYear]?.[selectedMonth];
    if (receivedKpis) {
      receivedKpis.forEach((element: any) => {
        if (selectedKpis.includes(element.name)) {
          kpis.push(element);
        }
      });
    }

    const sortedKpis: Kpi[] = [];

    selectedKpis.forEach((element) => {
      for (let i = 0; i < kpis.length; i++) {
        if (kpis[i].name === element) {
          sortedKpis.push(kpis[i]);
          break;
        }
      }
    });
    return sortedKpis;
  }
  return undefined;
};

interface KpiFilterWithOperatorProps extends KpiFilterProps {
  ignoredKpis: string[];
  kpisWithEqual: string[];
}

export const useKpiFilterWithOperator = ({
  information,
  selectedMonth = '12',
  selectedYear,
  selectedKpis,
  ignoredKpis,
  kpisWithEqual,
}: KpiFilterWithOperatorProps) => {
  const data: any = [];

  const currentData = useKpiFilter({
    information,
    selectedMonth,
    selectedYear,
    selectedKpis,
  });

  if (currentData) {
    currentData.forEach((element) => {
      let currentElement: Kpi;
      if (ignoredKpis.includes(element.name)) {
        currentElement = {
          ...element,
          operator: '',
        };
      } else if (kpisWithEqual.includes(element.name)) {
        currentElement = {
          ...element,
          operator: '=',
        };
      } else if (element.value < 0) {
        const valueArray = element.value.toString().split('-');
        const newValue = valueArray[1];

        currentElement = {
          ...element,
          value: parseInt(newValue),
          operator: '-',
        };
      } else {
        currentElement = {
          ...element,
          operator: '+',
        };
      }

      data.push(currentElement);
    });
  }
  return data;
};
