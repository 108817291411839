import { AxiosResponse } from 'axios';
import { createQuery } from 'react-query-kit';
import { createMutation } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';
import { IFile } from 'models/File.model';
import { IPredefinedFileRequestType } from 'models/PredefinedFileRequest.model';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';

import endpoints from '../../endpoints';

interface InquiryProcessSpecificUploadedFilesQueryVariables {
  inquiryId: string;
}

export const useInquiryProcessSpecificUploadedFiles = createQuery<
  IFile[],
  InquiryProcessSpecificUploadedFilesQueryVariables
>({
  primaryKey: queryKeys.inquiries.processSpecificUploadedFiles,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.PROCESS_SPECIFIC_UPLOADED_FILES.LIST.compose({
        params: { id: variables.inquiryId },
      }),
    ).then((response) => response.data.data.map(mapApiFile)),
});

export interface FileDownloadMutationVariables {
  fileId: string;
}

export interface FileDownloadMutationResult extends AxiosResponse<Blob> {}

// Here mutations are used for a get request as a conscious decision because the query variables need to be passed for every request to get the file download
export const useFileDownload = createMutation<
  FileDownloadMutationResult,
  FileDownloadMutationVariables
>({
  mutationKey: [queryKeys.file.download],
  mutationFn: ({ fileId }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.FILES.DOWNLOAD.compose({ params: { id: fileId } }),
      { responseType: 'blob' },
    ),
});

// Here mutations are used for a get request as a conscious decision because the query variables need to be passed for every request to get the file download
export const useAssessmentFileDownload = createMutation<
  FileDownloadMutationResult,
  FileDownloadMutationVariables
>({
  mutationKey: [queryKeys.assessmentFile.download],
  mutationFn: ({ fileId }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.ASSESSMENT_FILES.DOWNLOAD.compose({ params: { id: fileId } }),
      { responseType: 'blob' },
    ),
});

// Here mutations are used for a get request as a conscious decision because the query variables need to be passed for every request to get the file download
export const usePrivateFileDownload = createMutation<
  FileDownloadMutationResult,
  FileDownloadMutationVariables
>({
  mutationKey: [queryKeys.privateFile.download],
  mutationFn: ({ fileId }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.PRIVATE_FILES.DOWNLOAD.compose({ params: { id: fileId } }),
      { responseType: 'blob' },
    ),
});

export const useGetPredefinedFileRequestTypes = createQuery<IPredefinedFileRequestType[], void>({
  primaryKey: queryKeys.operation.predefinedRequests,
  queryFn: () =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.FILE_UPLOAD_REQUESTS.PREDEFINED_REQUESTS.compose(),
    ).then((response) => response.data.data),
});
