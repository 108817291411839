import React from 'react';

import { useShowStartupQuestionnaireSection } from 'dzb/inquiry/config/conditions';
import { AssociationInformationSection } from 'dzb/inquiry/steps/companyData/sections/associationInformationSection';
import { CompanyInformationSection } from 'dzb/inquiry/steps/companyData/sections/companyInformationSection';
import { DirectDebitMandateSection } from 'dzb/inquiry/steps/companyData/sections/directDebitMandateSection';
import { StartUpQuestionnaireSection } from 'dzb/inquiry/steps/companyData/sections/startUpQuestionnaireSection';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { Page } from 'new/form/Page';

export const DzbCompanyDataPage = () => {
  const showStartupQuestionnaireSection = useShowStartupQuestionnaireSection();

  return (
    <Page
      translationStringForTitle={translations.inquiryType.dzb.pages.companyData.progressBarTitle}
    >
      <AssociationInformationSection />
      <CompanyInformationSection />
      <DirectDebitMandateSection />
      <Condition validation={showStartupQuestionnaireSection}>
        <StartUpQuestionnaireSection />
      </Condition>
    </Page>
  );
};
