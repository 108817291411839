import React from 'react';

import CompanyDetailsExistingUserCompany from './CompanyDetailsExistingUserCompany';
import { useLoadCompanies } from './useLoadCompanies';

const ExistingUserCompanySelect = ({ tooltip = false }) => {
  const { companies, isFetching } = useLoadCompanies();

  return (
    <CompanyDetailsExistingUserCompany
      companies={companies}
      isFetching={isFetching}
      tooltip={tooltip}
    />
  );
};

export default ExistingUserCompanySelect;
