import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { SpecializedFinalPage } from 'pages/inquiryFlow/FinalPage/SpecializedFinalPage';
import PeopleDetails from 'pages/inquiryFlow/PeopleDetails';

import FinancingDetailsLeadStep from './financingDetails/lead/FinancingDetailsLeadStep';
import paths from '../constants/paths';
import CompanyDetails from '../pages/inquiryFlow/CompanyDetails';
import { FinancingNeedStep } from '../pages/inquiryFlow/FinancingNeed/FinancingNeedStep';
import SummaryPage from '../pages/inquiryFlow/SummaryPage/SummaryPageStep';

export const OnlinefactoringForm = () => {
  return (
    <Switch>
      <Route exact path={paths.financingNeed} component={FinancingNeedStep} />
      <Route exact path={paths.companyDetails} component={CompanyDetails} />
      <Route exact path={paths.requestDetails} component={FinancingDetailsLeadStep} />
      <Route exact path={paths.summaryPage} component={SummaryPage} />
      <Route exact path={paths.peopleDetails} component={PeopleDetails} />
      <Route exact path={paths.finalPage} component={SpecializedFinalPage} />
    </Switch>
  );
};
