import React from 'react';

import PropTypes from 'prop-types';

import { StyledPageHeading } from 'components/PortalPage/styles';
import { ELEMENT_IDS } from 'constants/elementIds';
import { useTranslations } from 'utils/hooks/useTranslations';

import { AdditionalNavigation, NavBar, NavBarHeading, MainNavigation, Wrapper } from './styles';

const NavigationBar = ({ mainNavigation, additionalNavigation }) => {
  const t = useTranslations('pages.inquiryDetails');
  return (
    <header>
      <StyledPageHeading as="h2">{t('heading')}</StyledPageHeading>
      <NavBar aria-labelledby={ELEMENT_IDS.SUB_NAVIGATION}>
        <NavBarHeading id={ELEMENT_IDS.SUB_NAVIGATION}>Subnavigation</NavBarHeading>
        <Wrapper>
          {mainNavigation && <MainNavigation>{mainNavigation}</MainNavigation>}
          {additionalNavigation && (
            <AdditionalNavigation>{additionalNavigation}</AdditionalNavigation>
          )}
        </Wrapper>
      </NavBar>
    </header>
  );
};

NavigationBar.propTypes = {
  mainNavigation: PropTypes.node,
  additionalNavigation: PropTypes.node,
};

NavigationBar.defaultProps = {
  mainNavigation: null,
  additionalNavigation: null,
};
export default NavigationBar;
