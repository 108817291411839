import React from 'react';

import { TrackingTool as TrackingToolEnum, TrackingToolConfig } from 'config/partner/trackingTools';

import { Matomo } from './Matomo';

export const TrackingTool = ({ config }: { config: TrackingToolConfig }) => {
  if (config.tool === TrackingToolEnum.MMV_MATOMO_TAG_MANAGER) {
    return <Matomo url={config.matomoUrl} />;
  }
  return null;
};
