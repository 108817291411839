import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { INVESTMENT_LOAN_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const InvestmentLoanDescription = () => {
  const t = useTranslations();

  return (
    <TextAreaWithField
      name={INVESTMENT_LOAN_DESCRIPTION}
      captionTooltip={t(
        'pages.requestDetails.sections.investmentLoan.fields.investmentLoanDescription.tooltip',
      )}
      optional
    />
  );
};

export default InvestmentLoanDescription;
