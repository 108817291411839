import React from 'react';

import { IconProps } from '@chakra-ui/react';

import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';

import { useTranslations } from '../../utils/hooks/useTranslations';

interface InfoButtonProps {
  text: string;
  boxSize?: IconProps['boxSize'];
}

export const InfoButton = ({ text, boxSize = 6 }: InfoButtonProps) => {
  const t = useTranslations();
  return (
    <PopoverComponent
      text={text}
      ml={2}
      mb={0}
      display="inline-block"
      trigger={
        <IconButtonComponent
          size="xs"
          icon={<InfoIcon boxSize={boxSize} display="block" />}
          label={t('buttons.moreInfos')}
        />
      }
    />
  );
};
