import React from 'react';

import { ErrorTextWrapper } from 'modules/AvailableEmail/EmailNotAvailable/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

import EmailLoginLink from '../EmailLoginLink';

type Props = {
  email: string;
};

const EmailNotAvailable = ({ email }: Props) => {
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail.errors.emailTaken',
  );

  return (
    <ErrorTextWrapper>
      {t('haveAccount')}
      <EmailLoginLink email={email} />
    </ErrorTextWrapper>
  );
};

export default EmailNotAvailable;
