import React from 'react';

import { getDisplayLanguage, useLanguages } from 'components/App/LanguageProvider/useLanguages';
import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import StaticField from 'components/StaticField';
import { IUserData } from 'models/UserData.model';
import { StyledFieldsWrapper, StyledFooter } from 'modules/UserData/styles';
import { translations } from 'new/form/common/types';
import { useSalutationTranslator } from 'shared/hooks/useSalutationTranslator';
import { ButtonComponent } from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useSelectedInquiryTypeSpecificValue } from '../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import { InquiryType } from '../../Inquiry/Inquiry.type';

const {
  fields: { firstName, lastName, email, phoneNumber, salutation, language },
  edit,
} = translations.pages.userData;

const StaticUserDataForm = ({ onEdit, userData }: { onEdit: () => void; userData: IUserData }) => {
  const t = useTranslations();
  const translateSalutation = useSalutationTranslator();
  const { isMultipleLanguagesEnabled } = useLanguages();

  const canEdit = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: false,
    [InquiryType.mmv]: false,
    [InquiryType.default]: true,
  });

  return (
    <StyledFieldsWrapper>
      <FormRow>
        <StaticField
          caption={t(salutation.caption)}
          text={translateSalutation(userData.gender)}
          dataTestId="userdata-salutation"
        />
      </FormRow>

      <FormRow>
        <StaticField
          caption={t(firstName)}
          text={userData.firstName}
          dataTestId="userdata-first-name"
        />
        <StaticField
          caption={t(lastName)}
          text={userData.lastName}
          dataTestId="userdata-last-name"
        />
      </FormRow>

      <FormRow>
        <StaticField caption={t(email)} text={userData.email} dataTestId="userdata-email" />
        <StaticField
          caption={t(phoneNumber)}
          text={userData.phoneNumber}
          dataTestId="userdata-phone-number"
        />
      </FormRow>
      <Condition condition={isMultipleLanguagesEnabled}>
        <FormRow>
          <StaticField
            caption={t(language)}
            text={getDisplayLanguage(userData.language)}
            dataTestId="userdata-language"
          />
        </FormRow>
      </Condition>

      {canEdit ? (
        <StyledFooter>
          <ButtonComponent
            data-testid="userdata-edit-btn"
            onClick={onEdit}
            type="submit"
            variant="tertiary"
            leftIcon={<EditIcon boxSize={6} display="block" />}
          >
            {t(edit)}
          </ButtonComponent>
        </StyledFooter>
      ) : null}
    </StyledFieldsWrapper>
  );
};

export default StaticUserDataForm;
