import React from 'react';

import { useField } from 'react-final-form';

import { InputWithField } from 'components/Input';
import AvailableEmailSymbol from 'modules/AvailableEmail/AvailableEmailSymbol';
import { COMPANY_DETAILS_EMAIL } from 'modules/Inquiry/Form/formFields';
import { EMAIL_FIELD_DEBOUNCE } from 'pages/RegistrationProcess/RegistrationForm/DebounceEmailField';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { combineValidators } from 'utils/validators';

interface Props {
  onChange: (value: string) => any;
}

const CustomerDetailsEmail: React.FC<Props> = ({ onChange }: Props) => {
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail',
  );

  const { meta, input } = useField(COMPANY_DETAILS_EMAIL);
  const { required, isEmail } = useFieldValidators();

  const shouldShowEmailIcon = !!input.value && isEmailRegex.test(input.value);

  return (
    <InputWithField
      name={COMPANY_DETAILS_EMAIL}
      debounce={EMAIL_FIELD_DEBOUNCE}
      validate={combineValidators(required, isEmail)}
      onChange={onChange}
      type="email"
      caption={t('caption')}
      sideSymbol={() =>
        shouldShowEmailIcon && <AvailableEmailSymbol isAllowedToInquiry={meta.valid} />
      }
    />
  );
};

export default CustomerDetailsEmail;
