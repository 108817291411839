import React from 'react';

import { useIntl } from 'react-intl';

import { SelectWithField } from 'components/Selects/Select';
import {
  REAL_ESTATE_USAGE_KIND,
  REAL_ESTATE_USAGE_KIND__OWN_USE,
  REAL_ESTATE_USAGE_KIND__THIRD_PARTY,
  REAL_ESTATE_USAGE_KIND__BOTH,
  REAL_ESTATE_USAGE_KIND__OTHER,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

export const getKindOfUsageOptions = (formatMessage: Function) =>
  [
    {
      id: 'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateUsageKind.options.ownUse',
      value: REAL_ESTATE_USAGE_KIND__OWN_USE,
    },
    {
      id: 'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateUsageKind.options.thirdPartyRental',
      value: REAL_ESTATE_USAGE_KIND__THIRD_PARTY,
    },
    {
      id: 'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateUsageKind.options.both',
      value: REAL_ESTATE_USAGE_KIND__BOTH,
    },
    {
      id: 'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateUsageKind.options.other',
      value: REAL_ESTATE_USAGE_KIND__OTHER,
    },
  ].map(({ id, value }) => ({ value, label: formatMessage({ id }) }));

const RealEstateUsageKind = () => {
  const t = useTranslations();
  const { formatMessage } = useIntl();
  return (
    <SelectWithField
      name={REAL_ESTATE_USAGE_KIND}
      placeholder={t('placeholders.pleaseChoose')}
      options={getKindOfUsageOptions(formatMessage)}
      optional
      data-testid={'select-field-' + REAL_ESTATE_USAGE_KIND}
    />
  );
};

export default RealEstateUsageKind;
