import React from 'react';

import FormRow from 'components/FormRow';
import Separator from 'components/FormRow/Separator';
import Spinner from 'components/Spinner';
import ConditionalSubform from 'modules/ConditionalSubform';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
} from 'modules/Inquiry/Form/formFields';
import ChooseFinancingOption from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/ChooseFinancingOption';
import NeededFinancing from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/NeededFinancing';
import {
  MaximumFinancingAmount,
  ReduceTopSpacing,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/styles';
import { useFetchQuickCreditMaximumAmount } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/useFetchQuickCreditMaximumAmount';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const MaxFinancingAmountFields: React.FC = () => {
  const { isPending, maxAmount } = useFetchQuickCreditMaximumAmount();

  const t = useTranslations('pages.coronaStep.subsections');

  if (isPending) {
    return <Spinner />;
  }
  return (
    <>
      <ReduceTopSpacing />
      <Separator />
      <FormRow>
        <p>
          {t('maxFinancialAmountPrefix', {
            amount: () => (
              <MaximumFinancingAmount key={maxAmount}>
                {formatPrice(maxAmount)}
              </MaximumFinancingAmount>
            ),
          })}
        </p>
      </FormRow>
      <FormRow>
        <ChooseFinancingOption />
      </FormRow>
      <ConditionalSubform
        condition={fieldHasValue(CORONA_FINANCING_OPTION, CORONA_FINANCING_OPTION__QUICK_CREDIT)}
        renderOnSatisfy={() => <NeededFinancing maxFinancing={maxAmount} />}
      />
    </>
  );
};

export default MaxFinancingAmountFields;
