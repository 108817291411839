import { useState, useEffect } from 'react';

import {
  CORONA_REVENUE_OF_2019,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_LIQUIDITY_NEED,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { getMaxFinancingAmountAction } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/coronaStep.service';
import { useFieldValue } from 'store/hooks/useFormValues';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useFetchMaximumKfwFinancingAmount = () => {
  const [maxAmount, setMaxAmount] = useState(0);

  const revenue2019 = useFieldValue<number>(CORONA_REVENUE_OF_2019, 0);
  const numberOfEmployees = useGetFieldNumericValue(CORONA_NUMBER_OF_EMPLOYEES);
  const liquidityNeed = useGetFieldNumericValue(CORONA_LIQUIDITY_NEED);
  const expenditureForEmployees = useGetFieldNumericValue(CORONA_EXPENDITURE_OF_EMPLOYEES);

  const { isPending, makeCall } = useDispatchApiCall();
  useEffect(() => {
    if (revenue2019 && numberOfEmployees && liquidityNeed && expenditureForEmployees) {
      makeCall(
        getMaxFinancingAmountAction({
          isQuickCredit: false,
          revenue2019,
          numberOfEmployees,
          liquidityNeed,
          expenditureForEmployees,
        }),
        ({ payload }: any) =>
          setMaxAmount(+payload.data.data.attributes['max-allowed-credit-amount']),
      );
    }
  }, [expenditureForEmployees, liquidityNeed, makeCall, numberOfEmployees, revenue2019]);

  return { maxAmount, isPending };
};
