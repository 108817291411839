import React from 'react';

import PageHeading from 'components/PageHeading';
import { HausbankContactPersonSection } from 'hausbank/inquiry/steps/personalData/sections/contactPersonSection';
import { HausbankLegalRepresentativesSection } from 'hausbank/inquiry/steps/personalData/sections/legalRepresentativesSection';
import {
  HausbankLoggedInPasswordSection,
  HausbankPasswordSection,
} from 'hausbank/inquiry/steps/personalData/sections/passwordSection';
import { LoggedInContactPersonSection } from 'new/contactPerson';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';
import { scrollTop } from 'utils/scroll';
import { isLoggedInUser } from 'utils/user/conditionals';

const NotLoggedInUser = () => {
  return (
    <>
      <HausbankContactPersonSection />
      <HausbankPasswordSection />
      <HausbankLegalRepresentativesSection />
    </>
  );
};

export const LoggedInUser = () => {
  return (
    <>
      <LoggedInContactPersonSection />
      <HausbankLoggedInPasswordSection />
      <HausbankLegalRepresentativesSection />
    </>
  );
};

export const HausbankPersonalDataPage = () => {
  // As a temporary fix to force scroll to the top after returning back from the summary page
  scrollTop(20);
  const t = useTranslations();
  const {
    heading: { header, subheader },
    progressBarTitle,
  } = translations.inquiryType.hausbank.pages.personalData;
  const isLoggedIn = isLoggedInUser();

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      {isLoggedIn ? <LoggedInUser /> : <NotLoggedInUser />}
    </Page>
  );
};
