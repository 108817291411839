import React from 'react';

import { bool, object, oneOfType, string } from 'prop-types';

import ControlWrapper from 'components/ControlWrapper';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

import StyledTextArea from './StyledTextArea';
import TextAreaWrapper from './TextAreaWrapper';

const TextArea = ({
  caption,
  captionTooltip,
  optional,
  tooltip,
  visited,
  submitFailed,
  ...restProps
}) => {
  const t = useTranslations('buttons');

  return (
    <ControlWrapper
      caption={caption}
      tooltip={captionTooltip}
      optional={optional}
      visited={visited}
      submitFailed={submitFailed}
      errorMessage={restProps.errorMessage}
    >
      <TextAreaWrapper>
        <StyledTextArea data-testid={restProps.name} {...restProps} data-name={restProps.name} />
        {tooltip && (
          <PopoverComponent
            position="absolute"
            zIndex="above"
            top="0"
            right="0"
            transform="translate(-50%, -50%)"
            text={tooltip}
            trigger={
              <IconButtonComponent
                icon={<InfoIcon boxSize={6} display="block" />}
                label={t('moreInfos')}
              />
            }
          />
        )}
      </TextAreaWrapper>
    </ControlWrapper>
  );
};

TextArea.propTypes = {
  caption: string,
  optional: bool,
  tooltip: string,
  captionTooltip: string,
  visited: bool,
  submitFailed: bool,
  errorMessage: oneOfType([object, string]),
};

TextArea.defaultProps = {
  caption: null,
  optional: false,
  visited: false,
  submitFailed: false,
  tooltip: null,
  captionTooltip: null,
  errorMessage: '',
};

const TextAreaAdapter = ({ input, meta, ...rest }) => (
  <TextArea {...input} {...meta} {...rest} onChange={(ev) => rest.onChange(ev.target.value)} />
);

TextAreaAdapter.propTypes = FinalFormFieldPropTypes;

export const TextAreaWithField = withFormField(TextAreaAdapter);

export default TextArea;
