import React from 'react';

import { Flex, VStack } from '@chakra-ui/react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { translations } from './common/types';
import { useValidateAndScroll } from './scrollToError';
import { useMode, useInquiryProcessConfig } from './state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from './state/pageStateReducer';
import paths from '../../constants/paths';
import { InquiryType } from '../../modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from '../../shared/chooseSelectedInquiryTypeSpecificComponent';
import ButtonComponent from '../../theme/components/Button';
import ArrowRightIcon from '../../theme/components/Icon/ArrowRightIcon';

export function useChangeStep() {
  const {
    state: { currentStep, inquiryMode, pageValidations },
    dispatch,
    formStateData: { stepData, routeToPageNameMap, summaryPath: inquirySummaryPath, paths },
  } = useInquiryProcessConfig();
  const history = useHistory();
  const id = useSelector(getInquiryIdSelector);

  const isStepValid = pageValidations[currentStep as any].progress.isSectionValid;
  const hasPreviousStep = !!stepData[currentStep as any].previousStep;
  const nextStep = stepData[currentStep as any].nextStep;
  const hasNextStep = !!(nextStep || inquirySummaryPath);
  const previousPage = inquiryMode === 'create' ? paths.createPath : paths.listPaths[inquiryMode];

  const navigateToPath = (path: string) => {
    if (id && (inquiryMode === 'editOperation' || inquiryMode === 'editCustomer')) {
      history.push(path.replace(':id', id));
    } else {
      history.push(path);
    }
  };

  const moveToNextStep = () => {
    const nextStep = stepData[currentStep as any].nextStep;

    if (nextStep) {
      dispatch({ type: PageStateActionTypes.NEXT_STEP, payload: nextStep });
      const nextPath = routeToPageNameMap[nextStep as any][inquiryMode];
      navigateToPath(nextPath);
    } else {
      const summaryPath = inquirySummaryPath ? inquirySummaryPath[inquiryMode] : '/'; // not all inquiry flow have summary page
      navigateToPath(summaryPath);
    }
  };

  const moveToPreviousStep = () => {
    const previousStep = stepData[currentStep as any].previousStep;

    if (previousStep) {
      dispatch({ type: PageStateActionTypes.NEXT_STEP, payload: previousStep });
      const previousPath = routeToPageNameMap[previousStep as any][inquiryMode];
      navigateToPath(previousPath);
    }
  };

  const moveToPreviousPage = () => {
    if (previousPage) {
      history.push(previousPage);
    }
  };

  return {
    isStepValid,
    moveToNextStep,
    hasPreviousStep,
    moveToPreviousStep,
    hasNextStep,
    moveToPreviousPage,
  };
}

type StepActionProps = {
  children: React.ReactNode;
};

export const StepActions = ({ children }: StepActionProps) => {
  const CancelEditButton = useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]: () => null,
    [InquiryType.dzb]: () => null,
    default: CancelEditButtonImpl,
  });
  return (
    <VStack w={'full'} alignItems={'flex-end'} gap={4}>
      {children}
      <CancelEditButton />
    </VStack>
  );
};

const CancelEditButtonImpl = () => {
  const mode = useMode();
  const t = useTranslations();
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();

  if (mode === 'create') {
    return null;
  }

  const path =
    mode === 'editOperation'
      ? paths.operation.inquiryDetails.details
      : paths.customer.inquiryDetails.details;

  return (
    <ButtonComponent
      variant={'secondary'}
      onClick={() => {
        history.push(path.replace(':id', inquiryId!!));
      }}
    >
      {t(translations.buttons.cancelEdit)}
    </ButtonComponent>
  );
};

export const ChangeStep = () => {
  const { isStepValid, moveToNextStep, hasPreviousStep, moveToPreviousStep, hasNextStep } =
    useChangeStep();
  const { submitting } = useFormState();
  const { submit } = useForm();
  const {
    formStateData: { formName },
  } = useInquiryProcessConfig();
  const t = useTranslations();
  const validateAndScroll = useValidateAndScroll();

  return (
    <StepActions>
      <Flex w={'full'} justifyContent={'flex-end'} gap={4} mt={8}>
        {hasPreviousStep && (
          <ButtonComponent
            onClick={moveToPreviousStep}
            leftIcon={<ArrowLeftIcon boxSize={6} />}
            variant={'tertiary'}
            data-testid="previous-step-button"
          >
            {t(translations.buttons.back)}
          </ButtonComponent>
        )}
        {hasNextStep ? (
          <ButtonComponent
            onClick={isStepValid ? moveToNextStep : validateAndScroll}
            leftIcon={<ArrowRightIcon boxSize={6} />}
            data-testid="next-step-button"
          >
            {t(translations.buttons.continue)}
          </ButtonComponent>
        ) : (
          <ButtonComponent
            form={formName}
            onClick={isStepValid ? submit : validateAndScroll}
            leftIcon={<ArrowRightIcon boxSize={6} />}
            isLoading={submitting}
            data-testid="finalize-inquiry-button"
          >
            {t(translations.buttons.send)}
          </ButtonComponent>
        )}
      </Flex>
    </StepActions>
  );
};
