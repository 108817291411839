import React from 'react';

import { ProcessLog, ProcessLogType } from 'models/ProcessLog.model';
import {
  StyledItemWrapper as Wrapper,
  StyledUserField as UserField,
  StyledStatusField as StatusField,
  StyledDateField as DateField,
} from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/styles';
import StarIcon from 'theme/components/Icon/StarIcon';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateDays, formatDateTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProcessLogItem = ({ log }: { log: ProcessLog }) => {
  const { createdAt: date, activity, user, type } = log;
  const t = useTranslations('pages.inquiryDetails.dashboard.communication.processLog');
  return (
    <Wrapper>
      <UserField>
        <UserIcon boxSize={6} mr={2} />
        {type === ProcessLogType.user && user
          ? `${user.firstName} ${user.lastName}`
          : t('systemType')}
      </UserField>

      <StatusField>
        <StarIcon boxSize={6} mr={2} />
        {t(`activity.${activity}`)}
      </StatusField>

      <DateField>
        <TimeIcon boxSize={6} mr={2} />
        {formatDateDays(date)}; {formatDateTime(date)}
      </DateField>
    </Wrapper>
  );
};

export default ProcessLogItem;
