import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

import { EvaluationData } from './types';

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.profitabilitySale.tableHeaders.${key}`,
    );

  return [
    {
      key: 'type',
      text: translate('type'),
    },
    {
      key: 'customer',
      text: translate('customer'),
    },
    {
      key: 'marketData',
      text: translate('marketData'),
    },
    {
      key: 'realEstateExpert',
      text: translate('realEstateExpert'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  { customer, marketData, realEstateExpert }: EvaluationData,
) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.profitabilitySale.tableRows.${key}`,
    );
  const translateUnit = (unit: string) => t(`other.${unit}`);
  const defaultCurrency = translateUnit('defaultCurrency');

  return [
    {
      type: translate('residentialRevenue'),
      customer: formatDecimal(customer.residentialRevenue, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.residentialRevenue, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.residentialRevenue, {
        unit: defaultCurrency,
      }),
    },
    {
      type: translate('commercialRevenue'),
      customer: formatDecimal(customer.commercialRevenue, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.commercialRevenue, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.commercialRevenue, {
        unit: defaultCurrency,
      }),
    },
    {
      type: translate('parkingRevenue'),
      customer: formatDecimal(customer.parkingRevenue, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.parkingRevenue, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.parkingRevenue, { unit: defaultCurrency }),
    },
    {
      type: translate('garageRevenue'),
      customer: formatDecimal(customer.garageRevenue, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.garageRevenue, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.garageRevenue, { unit: defaultCurrency }),
    },
    {
      type: translate('totalRevenue'),
      customer: formatDecimal(customer.totalRevenue, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.totalRevenue, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.totalRevenue, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      type: translate('totalCosts'),
      customer: formatDecimal(customer.totalCosts, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.totalCosts, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.totalCosts, { unit: defaultCurrency }),
    },
    {
      type: translate('salesCosts'),
      customer: formatDecimal(customer.salesCosts, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.salesCosts, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.salesCosts, { unit: defaultCurrency }),
    },
    {
      type: translate('surplusEuro'),
      customer: formatDecimal(customer.surplusEuro, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.surplusEuro, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.surplusEuro, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      type: translate('surplusPercent'),
      customer: formatDecimal(customer.surplusPercent, { unit: translateUnit('percent') }),
      marketData: formatDecimal(marketData.surplusPercent, { unit: translateUnit('percent') }),
      realEstateExpert: formatDecimal(realEstateExpert.surplusPercent, {
        unit: translateUnit('percent'),
      }),
    },
  ];
};
