import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';

import { bankDetailsValidation } from '../contractDetails/sections/bankDetails/validations';
import { financingObjectValidation } from '../contractDetails/sections/financingObject/validations';

export const contractDetailsStepValidations: ValidationRecord<InquiryType.mmv>['contractDetails'] =
  {
    dealerSection: () => true,
    financingObjectSection: financingObjectValidation,
    financingConditionsSection: () => true,
    bankDetailsSection: bankDetailsValidation,
  };
