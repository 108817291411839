import React from 'react';

import { Heading } from 'components/Text';
import { BankDetailsSection } from 'mmv/inquiry/steps/contractDetails/sections/bankDetails';
import { SupplierSection } from 'mmv/inquiry/steps/contractDetails/sections/dealer';
import { FinancingConditionsSection } from 'mmv/inquiry/steps/contractDetails/sections/financingConditions';
import { FinancingObjectSection } from 'mmv/inquiry/steps/contractDetails/sections/financingObject';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

export const ContractDetails = () => {
  const t = useTranslations();

  return (
    <Page translationStringForTitle={translations.inquiryType.mmv.steps.contractDetails.heading}>
      <Heading>{t(translations.inquiryType.mmv.steps.contractDetails.heading)}</Heading>
      <SupplierSection sectionNumber={1} />
      <FinancingObjectSection sectionNumber={2} />
      <FinancingConditionsSection sectionNumber={3} />
      <BankDetailsSection sectionNumber={4} />
    </Page>
  );
};
