import React from 'react';

import { arrayOf, bool, node, oneOfType, shape, string } from 'prop-types';

import ControlWrapper from 'components/ControlWrapper';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

import RadioGroupComponent from '../../theme/components/RadioGroup';

const RadioGroup = ({
  caption,
  name,
  visited,
  options,
  optional,
  tooltip,
  checked,
  value: currentValue,
  submitFailed,
  popoverPlacement,
  flexLeft,
  radioWidth,
  ...restProps
}) => (
  <ControlWrapper
    caption={caption}
    visited={visited}
    optional={optional}
    tooltip={tooltip}
    submitFailed={submitFailed}
    disableWrapping
    errorMessage={restProps.errorMessage}
    popoverPlacement={popoverPlacement}
    {...restProps}
  >
    <RadioGroupComponent
      name={name}
      options={options}
      currentValue={currentValue}
      onChange={restProps.onChange}
      radioWidth={radioWidth}
    />
  </ControlWrapper>
);

RadioGroup.propTypes = {
  caption: oneOfType([string, node]),
  name: string.isRequired,
  options: arrayOf(
    shape({
      caption: node.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  visited: bool,
  optional: bool,
  tooltip: string,
  checked: bool,
  value: string,
  submitFailed: bool,
  popoverPlacement: string,
  radioWidth: string,
};

RadioGroup.defaultProps = {
  caption: null,
  visited: false,
  optional: false,
  tooltip: null,
  checked: false,
  value: null,
  submitFailed: false,
  popoverPlacement: 'auto',
  radioWidth: '6.5rem',
};

const RadioGroupAdapter = ({ input, meta, ...rest }) => (
  <RadioGroup
    {...input}
    {...meta}
    {...rest}
    onChange={(e) => rest.onChange(e.target ? e.target.value : e)}
  />
);
RadioGroupAdapter.propTypes = FinalFormFieldPropTypes;

const RadioGroupWithField = withFormField(RadioGroupAdapter);

export { RadioGroupWithField };
export default RadioGroup;
