import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { StepData } from 'new/form/model';

const { financingNeed, companyDetails, fundingDetails, userDetails } =
  translations.inquiryType.mittweida.pages;

export const mittweidaStepData: StepData<MittweidaFieldTypes> = {
  financingNeedPage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.financingNeed.progressBarTitle,
    nextStep: 'fundingDetailsPage',
    sectionTitles: {
      objectInformationSection: financingNeed.sections.objectInformation.title,
      roleSection: financingNeed.sections.role.title,
      otherProjectInformation: financingNeed.sections.otherProjectInformation.title,
      projectCompositionSection: financingNeed.sections.projectComposition.title,
    },
  },
  companyDetailPage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.companyDetails.progressBarTitle,
    previousStep: 'userProfilePage',
    sectionTitles: {
      companyDetailsSection: companyDetails.sections.companyDetails.title,
      completingCompanyDetailsSection: companyDetails.sections.completingCompanyDetails.title,
    },
  },
  fundingDetailsPage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.fundingDetails.progressBarTitle,
    previousStep: 'financingNeedPage',
    nextStep: 'userProfilePage',
    sectionTitles: {
      otherFundingSection: fundingDetails.sections.other.title,
    },
  },
  userProfilePage: {
    progressBarTitle: translations.inquiryType.mittweida.pages.userDetails.progressBarTitle,
    previousStep: 'fundingDetailsPage',
    nextStep: 'companyDetailPage',
    sectionTitles: {
      contactPersonSection: userDetails.sections.contactPerson.title,
      passwordSection: userDetails.sections.password.title,
      termsAndConditionsSection: userDetails.sections.tos.title,
    },
  },
};
