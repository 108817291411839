import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { CORONA_TOTAL_ASSETS } from 'modules/Inquiry/Form/formFields';

const TotalAssets: React.FC = () => {
  return <YesNoRadioGroupWithField name={CORONA_TOTAL_ASSETS} />;
};

export default TotalAssets;
