import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import ProjectFinancingInterestedInAlternativeFinancing from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/ProjectFinancing/ProjectFinancingInterestedInAlternativeFinancing';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import ProjectFinancingIcon from 'theme/components/Icon/ProductKind/ProjectFinancingIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProjectFinancing = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.projectFinancing}
      title={t('pages.requestDetails.sections.projectFinancing.title')}
    >
      <FormRow separator>
        <FinancingAmountSummary icon={<ProjectFinancingIcon />} />
      </FormRow>
      <FormRow>
        <ProjectFinancingInterestedInAlternativeFinancing />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default ProjectFinancing;
