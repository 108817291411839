import styled from 'styled-components/macro';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';

export const ContractDetailsWrapper = styled.div`
  .value {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-weight: ${FONT_WEIGHT_BOLD};
  }
`;
