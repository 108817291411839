import React from 'react';

import { InputWithField } from 'components/Input';
import { CORONA_NUMBER_OF_EMPLOYEES } from 'modules/Inquiry/Form/formFields';

const CountOfEmployees: React.FC = () => {
  return <InputWithField name={CORONA_NUMBER_OF_EMPLOYEES} type="number" />;
};

export default CountOfEmployees;
