import React from 'react';

import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ObjectInformationSection } from './sections/objectInformationSection';
import { ProjectInformationSection } from './sections/projectInformationSection';

export const HausbankObjectInformationPage = () => {
  const t = useTranslations();
  const {
    heading: { header, subheader },
  } = translations.inquiryType.hausbank.pages.objectInformation;
  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.hausbank.pages.objectInformation.progressBarTitle
      }
    >
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <ObjectInformationSection />
      <ProjectInformationSection />
    </Page>
  );
};
