import isNil from 'lodash/isNil';
import moment from 'moment';

import { payloadDateFormat } from 'utils/valueFormats';

export const conditionalObject = (condition: boolean, obj: object): object =>
  condition ? obj : {};

export const fieldParsers = {
  toIsoDate: (value: string): string => {
    try {
      const [day, month, year] = value.split('-');
      return new Date(Number(year), Number(month) - 1, Number(day)).toISOString();
    } catch (e) {
      return value;
    }
  },
  toInteger: (value: string): number | undefined => {
    const parsed = parseInt(value, 10);
    return Number.isNaN(parsed) ? undefined : parsed;
  },
  toFloatPrecision2: (value: string): string | undefined => {
    const parsed = parseFloat(value);
    return window.isNaN(parsed) ? undefined : parsed.toFixed(2);
  },
  toFloatPrecision3: (value: string): string | undefined => {
    const parsed = parseFloat(value);
    return window.isNaN(parsed) ? undefined : parsed.toFixed(3);
  },
  toBoolean: (value: string | boolean): boolean | undefined => {
    if (isNil(value)) {
      return undefined;
    }
    if (typeof value === 'boolean') {
      return value;
    }
    return value === 'true';
  },
  toDate: (value: string): string | undefined => {
    return value ? moment(value).format(payloadDateFormat) : undefined;
  },
  mapOptions: (value: string, map: { [key: string]: string }) => {
    return map[value] || null;
  },
  getYearFromDate: (value: Date | string): number | undefined => {
    if (!value) {
      return undefined;
    }
    const date = value instanceof Date ? value : new Date(value);
    return date.getUTCFullYear();
  },
  getMonthAndYearFromDate: (value: Date | string): string | undefined => {
    if (!value) {
      return undefined;
    }

    // date object
    if (value instanceof Date) {
      const month = `0${value.getUTCMonth() + 1}`.slice(-2);
      return `${month}.${value.getUTCFullYear()}`;
    }

    // legacy format ex. 05.2020
    if (window.isNaN(Date.parse(value))) {
      return value;
    }

    // ISO string
    const date = new Date(value);
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    return `${month}.${date.getUTCFullYear()}`;
  },
};

export const END_USER_ATTRIBUTES_KEY = 'end-customer-attributes';
