import jwt from 'jsonwebtoken';
import { get as _get } from 'lodash';

import { USER_ANONYMOUS, UserRole } from 'constants/user/userRoles';
import { COMPANY_DETAILS_EMAIL } from 'modules/Inquiry/Form/formFields';
import { IState } from 'store';
import { USER_KEY, UserAttributes, UserState } from 'types/User';
import { COMPANY_DETAILS } from 'utils/sessionStorage/keys';

import { isUser, userIsCustomer } from './conditionals';
// Importing the current module itself to be able to mock functions from this module being used in this module
import * as thisModule from './getters';
import {
  mapAttributesForLoggedInUser,
  mapAttributesForNewUser,
  MappedUserBase,
  mapUserRoles,
} from './mappers';

export const getUser = (): UserState => JSON.parse(sessionStorage.getItem(USER_KEY) || '{}');
export const getUserAttributes = (): UserAttributes | {} => _get(getUser(), 'attributes', {});
export const getUserScope = () => _get(getUser(), 'scope');
export const getUserRoles = (): UserRole[] => _get(getUserAttributes(), 'roles', [USER_ANONYMOUS]);

export const getAttributesFromToken = (accessToken: string) => {
  const payload = jwt.decode(accessToken);
  if (typeof payload !== 'string' && payload?.user && isUser(payload.user)) {
    const { roles } = payload.user;
    return {
      roles: roles ? mapUserRoles(roles) : [],
    };
  }
  return {};
};

export const getCurrentUser = (state: IState): Partial<MappedUserBase> | undefined => {
  const {
    progress: {
      storedValues: { companyDetails, userProfile },
    },
    inquiryDetails,
  } = state;
  const inquiryId = inquiryDetails.details?.inquiryId;
  const { attributes } = thisModule.getUser();

  const isCustomerRole = userIsCustomer();
  const dataAreFilled = Boolean(_get(companyDetails, COMPANY_DETAILS_EMAIL));
  const editMode = !!inquiryId;

  if (isCustomerRole && (attributes as UserAttributes).id) {
    return mapAttributesForLoggedInUser(attributes as UserAttributes);
  }

  if (dataAreFilled) {
    return mapAttributesForNewUser(companyDetails, userProfile);
  }

  if (editMode && inquiryDetails.editDetails) {
    return mapAttributesForNewUser(inquiryDetails.editDetails[COMPANY_DETAILS]);
  }
};
