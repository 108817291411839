import styled from 'styled-components';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

export const StyledNoFileSection = styled.div`
  width: 100%;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledInnerNoFileBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  border: 3px dotted ${({ theme }) => theme.colors.border.lightGrey};
  padding: 2rem 5rem;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledNoFilesTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;
