import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const StyledCancelBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: none;
  }
`;
