import {
  FIELD_UNIT_TYPE,
  UNIT_TYPE_SUFFIX,
} from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { useTranslations } from 'utils/hooks/useTranslations';

const Unit = ({ type }: { type: FIELD_UNIT_TYPE }) => {
  const t = useTranslations();
  const unit = UNIT_TYPE_SUFFIX[type];
  if (!unit) {
    return null;
  }
  return unit(t);
};

export default Unit;
