import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const UploadIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M20 19H4a1 1 0 000 2h16a1 1 0 000-2zM7.5 8.5a1 1 0 00.71-.29L11 5.42v10.14a1 1 0 002 0V5.41l2.79 2.79a1 1 0 001.41 0 1 1 0 000-1.42l-4.49-4.49a1 1 0 00-1.41 0l-4.5 4.5a1 1 0 00.7 1.71z" />
    </Icon>
  );
};

export default UploadIcon;
