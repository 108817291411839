import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const DebtReschedulingIcon = (props: IconProps) => {
  return (
    <Icon
      width="41"
      height="41"
      viewBox="0 0 40.69 40.83"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M18 32.91h-.1a12.72 12.72 0 010-25 .51.51 0 01.59.4.5.5 0 01-.4.58 11.72 11.72 0 000 23 .51.51 0 01.4.59.5.5 0 01-.49.43zm4.67 0a.49.49 0 01-.49-.4.5.5 0 01.4-.59 11.72 11.72 0 000-23 .49.49 0 01-.4-.58.51.51 0 01.59-.4 12.72 12.72 0 010 25z" />
      <path d="M20.62 25a5.92 5.92 0 01-3.46-1.31.5.5 0 01-.1-.7.48.48 0 01.69-.1c2.32 1.71 3.82 1 4.23.72a1.43 1.43 0 00.74-1.47c-.14-.66-.88-1.13-2-1.3-.75-.11-2.74-.4-3.06-2.16a2.67 2.67 0 011.18-2.64c.67-.43 2.16-.89 4.55.83a.5.5 0 01.11.69.49.49 0 01-.69.12c-1.42-1-2.64-1.3-3.44-.8a1.72 1.72 0 00-.73 1.62c.17.93 1.28 1.21 2.23 1.35 2.24.32 2.74 1.56 2.86 2.07a2.45 2.45 0 01-1.18 2.53 3.4 3.4 0 01-1.93.55z" />
      <path d="M20.34 16.53a.51.51 0 01-.5-.5v-1.38a.51.51 0 01.5-.5.5.5 0 01.5.5V16a.5.5 0 01-.5.53zm0 10.26a.5.5 0 01-.5-.5v-1.83a.51.51 0 01.5-.5.5.5 0 01.5.5v1.83a.5.5 0 01-.5.5zm16.3 5.76H32a.5.5 0 01-.5-.5.51.51 0 01.5-.5h4.66a.51.51 0 01.5.5.5.5 0 01-.52.5z" />
      <path d="M36.64 37.21a.5.5 0 01-.5-.5v-4.66a.5.5 0 01.5-.5.51.51 0 01.5.5v4.66a.51.51 0 01-.5.5zm-16.36 3.62A20.3 20.3 0 01.15 18.05a.51.51 0 01.56-.44.51.51 0 01.44.56 19.28 19.28 0 0032.9 15.88c.39-.4.77-.83 1.13-1.26a.51.51 0 01.71-.06.49.49 0 01.06.7c-.38.45-.78.89-1.19 1.32a20.27 20.27 0 01-14.48 6.08zM8.7 9.28H4.05a.5.5 0 01-.5-.5.5.5 0 01.5-.5H8.7a.5.5 0 01.5.5.5.5 0 01-.5.5z" />
      <path d="M4.05 9.28a.5.5 0 01-.5-.5V4.12a.5.5 0 011 0v4.66a.5.5 0 01-.5.5zM40 23.22a.49.49 0 01-.43-.55A19.29 19.29 0 006.64 6.77c-.39.41-.77.83-1.14 1.23a.49.49 0 01-.7.06.51.51 0 01-.06-.71c.38-.45.77-.88 1.18-1.31a20.28 20.28 0 0134.61 16.7.5.5 0 01-.53.48z" />
    </Icon>
  );
};

export default DebtReschedulingIcon;
