import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import { mmvFields } from '../../../../fields';

const fields = mmvFields.contractDetails.bankDetailsSection;

const translations =
  translationsObject.inquiryType.mmv.steps.contractDetails.sections.bankDetails.fields;

export const customerDataSectionLabelsMap: LabelsMap = {
  [fields.accountHolder]: translations.accountHolder.caption,
  [fields.iban]: translations.iban.caption,
};
