import React from 'react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { chooseLaneSpecificComponent } from 'shared/chooseLaneSpecificComponent';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const OnlinefactoringLeadDescription = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.financingDetails.lead');
  return (
    <TextComponent mb={8} color="brand.default">
      {t('summaryExplanation')}
    </TextComponent>
  );
};

const LaneSpecificValue = chooseLaneSpecificComponent({
  [InquiryLane.lead]: OnlinefactoringLeadDescription,
  default: () => null,
});

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.onlinefactoring]: LaneSpecificValue,
  [InquiryType.bfsService]: LaneSpecificValue,
  default: () => null,
});
