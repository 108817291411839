import React from 'react';

import { useInquiryValueParserForDisplay } from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import InquiryRevisionUser from 'modules/InquiryDetails/InquiryRevision/InquiryRevisionUser';
import { INQUIRY_ATTRIBUTES } from 'shared/inquiry/inquiryFieldsTranslations/inquiryAttributesTranslations/InquiryAttributes.model';

const CUSTOM_COMPONENTS_MAP = {
  [INQUIRY_ATTRIBUTES.inquiryManagerId as string]: (value: any) => (
    <InquiryRevisionUser user={value} />
  ),
};

const InquiryRevisionValue = ({ value, attribute }: { value: any; attribute: string }) => {
  const valueParser = useInquiryValueParserForDisplay();
  const renderer = CUSTOM_COMPONENTS_MAP[attribute];

  if (value && renderer) {
    return renderer(value);
  }

  return valueParser(value, attribute);
};

export default InquiryRevisionValue;
