import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { CUSTOMER_PHONE_NUMBER } from 'modules/Inquiry/Form/formFields';
import { getCustomerData } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const TelephoneNumberStaticField = () => {
  const customerData = useSelector(getCustomerData);
  const t = useTranslations('pages.customerData.sections.contactPerson.fields.telephoneNumber');
  return <StaticField caption={t('caption')} text={customerData[CUSTOMER_PHONE_NUMBER]} />;
};

export default TelephoneNumberStaticField;
