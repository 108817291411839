import styled from 'styled-components/macro';

import { Text } from 'components/Text';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div<{ column?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 1 0%;
  margin-left: 2.5rem;

  .section-number {
    margin-right: 0.5rem;
  }
`;

export const Content = styled.div`
  ${Text} {
    :not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;
