import { useEffect, useState } from 'react';

import jsonData from './countries.json';
import { InquiryType } from '../../../modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from '../../../shared/chooseSelectedInquiryTypeSpecificComponent';

const inquiryTypeSpecificCountriesMapperMap = {
  [InquiryType.default]: (country: ICountryType) => ({
    value: country.nameInEnglish,
    label: country.nameInGerman,
    testId: country.nameInEnglish,
  }),
  [InquiryType.mmv]: (country: ICountryType) => ({
    value: country.nameInGerman,
    label: country.nameInGerman,
    testId: country.nameInEnglish,
  }),
};

interface ICountryType {
  nameInGerman: string;
  nameInEnglish: string;
}

interface ICountryListType {
  value: string;
  label: string;
  testId?: string;
}

const useCountriesData = () => {
  const inquirySpecificCountryMapper = useSelectedInquiryTypeSpecificValue(
    inquiryTypeSpecificCountriesMapperMap,
  );

  const [countryArr, setCountryArr] = useState<ICountryListType[]>([]);
  useEffect(() => {
    const countries: ICountryListType[] = jsonData.map(inquirySpecificCountryMapper);
    setCountryArr(countries);
    return () => {
      setCountryArr([]);
    };
  }, [inquirySpecificCountryMapper]);

  return countryArr;
};
export default useCountriesData;
