import React from 'react';

import { InputWithField } from 'components/Input';
import Unit from 'components/Unit/Unit';
import { REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION } from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { useTranslations } from 'utils/hooks/useTranslations';

const RealEstateHeritableBuildingRightObligation = () => {
  const t = useTranslations();

  return (
    <InputWithField
      type="number"
      name={REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION}
      placeholder={t('placeholders.inThousandEuroPerYear')}
      optional
      separateThousands
      captionTooltip={t(
        'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateHeritableBuildingRightObligation.tooltip',
      )}
      sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.THOUSANDS_CURRENCY} />}
    />
  );
};

export default RealEstateHeritableBuildingRightObligation;
