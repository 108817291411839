import React from 'react';

import { Text } from '@chakra-ui/react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { FormContainer } from 'modules/Login/FormContainer';
import { translations } from 'new/form/common/types';
import { ChangePasswordForm } from 'pages/ChangePassword/ChangePasswordForm';
import { useQueryParamsToken } from 'shared/hooks/useQueryParamToken';
import { useTranslations } from 'utils/hooks/useTranslations';

import { validateResetPasswordTokenAction } from './service';

const { linkExpired } = translations.pages.changePassword;

export const ChangePassword = () => {
  const t = useTranslations();

  const { isTokenValidationPending, token } = useQueryParamsToken(validateResetPasswordTokenAction);

  if (isTokenValidationPending) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (!token) {
    return (
      <FormContainer>
        <Text data-testid="change-password-link-expired" textAlign="center">
          {t(linkExpired)}
        </Text>
      </FormContainer>
    );
  }

  return <ChangePasswordForm resetPasswordToken={token} />;
};
