import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';

import { Store } from 'types/Store';

export class SessionStorageStore<TData extends Record<string, any>> implements Store<TData> {
  constructor(private storeName: string, protected initialData: TData) {}

  public getAllValues(): TData {
    return this.storageObject;
  }

  public getSingleValue<TKey extends keyof TData>(key: TKey): TData[TKey] {
    const data = this.storageObject;
    return data[key];
  }

  public save(data: Partial<TData>, { override = false } = {}): void {
    const dataFromSessionStorage = this.storageObject;

    if (override) {
      this.storageObject = _mergeWith(
        dataFromSessionStorage,
        data,
        (savedValue, newValue) => newValue,
      );
    } else {
      this.storageObject = _merge(dataFromSessionStorage, data);
    }
  }

  public saveBulk(data: TData, force?: boolean) {
    if (force) {
      this.storageObject = data;
    }
    this.save(data);
  }

  public destroy(): void {
    sessionStorage.removeItem(this.storeName);
  }

  private set storageObject(data: TData) {
    sessionStorage.setItem(this.storeName, JSON.stringify(data));
  }

  private get storageObject(): TData {
    const item = sessionStorage.getItem(this.storeName);

    return item ? JSON.parse(item) : this.initialData;
  }
}
