import _get from 'lodash/get';

import {
  RENT_CUSTOMER_NOW_ASSET_SURPLUS,
  RENT_CUSTOMER_NOW_EQUITY_PROOF,
  RENT_CUSTOMER_NOW_FREE_INCOME,
  RENT_CUSTOMER_NOW_OPERATING_COSTS,
  RENT_CUSTOMER_PLAN_OPERATING_COSTS,
  RENT_NOTES_ON_DEBT_SERVICE,
  RENT_CURRENT_OPERATING_COSTS_ESTIMATION_CUSTOMER,
  RENT_CURRENT_OPERATING_COSTS_MIN_CUSTOMER,
  RENT_FUTURE_OPERATING_COSTS_ESTIMATION_CUSTOMER,
  RENT_FUTURE_OPERATING_COSTS_MIN_CUSTOMER,
} from 'modules/Inquiry/Form/formFields';

const mapRentStockholderFromApi = (response: unknown) => {
  const data = _get(response, 'attributes.details.rent');
  const computedData = _get(response, 'attributes.computed_details.rent_additional_information');

  const rentStockholder = {
    [RENT_CUSTOMER_NOW_ASSET_SURPLUS]: _get(data, 'customer_now.asset_surplus', '0,00'),
    [RENT_CUSTOMER_NOW_EQUITY_PROOF]: _get(data, 'customer_now.equity_proof', '0,00'),
    [RENT_CUSTOMER_NOW_FREE_INCOME]: _get(data, 'customer_now.free_income', '0,00'),
    [RENT_CUSTOMER_NOW_OPERATING_COSTS]: _get(data, 'customer_now.operating_costs', '0,00'),
    [RENT_CUSTOMER_PLAN_OPERATING_COSTS]: _get(data, 'customer_plan.operating_costs', '0,00'),
    [RENT_NOTES_ON_DEBT_SERVICE]: _get(data, 'notes_on_debt_service', ''),
    [RENT_CURRENT_OPERATING_COSTS_ESTIMATION_CUSTOMER]: _get(
      computedData,
      'current.operating_costs_estimation_customer',
      '0,00',
    ),
    [RENT_CURRENT_OPERATING_COSTS_MIN_CUSTOMER]: _get(
      computedData,
      'current.operating_costs_min_customer',
      '0,00',
    ),
    [RENT_FUTURE_OPERATING_COSTS_ESTIMATION_CUSTOMER]: _get(
      computedData,
      'future.operating_costs_estimation_customer',
      '0,00',
    ),
    [RENT_FUTURE_OPERATING_COSTS_MIN_CUSTOMER]: _get(
      computedData,
      'future.operating_costs_min_customer',
      '0,00',
    ),
  };

  return rentStockholder;
};

export default mapRentStockholderFromApi;
