import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  erpProvider,
  retailSpace,
  numberOfEmployees,
  numberOfStores,
  enterpriseResourcePlanningSystemInUse,
} = dzbFields.retailerDataPage.retailerInformationSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.retailerData.sections.retailerInformation;

export const retailerInformationSectionLabelsMap: LabelsMap = {
  [erpProvider]: fieldTranslations.erpProvider.caption,
  [retailSpace]: fieldTranslations.retailSpace.caption,
  [numberOfEmployees]: fieldTranslations.numberOfEmployees.caption,
  [numberOfStores]: fieldTranslations.numberOfStores.caption,
  [enterpriseResourcePlanningSystemInUse]:
    fieldTranslations.enterpriseResourcePlanningSystemInUse.caption,
};
