import React from 'react';

import FormRow from 'components/FormRow';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import {
  STRUCTURE_NUMBER,
  ADP_NUMBER,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
  COMPANY_DETAILS_EMAIL,
  USER_PROFILE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import BirthdateField from 'modules/Inquiry/Form/Steps/UserProfile/Birthdate';
import { ContactPersonEmail } from 'new/contactPerson/ContactPersonEmail';

import PhoneNumberField from '../../../../../../../ui/common/phoneNumberField';

const ForNotLoggedInUser = () => {
  return (
    <>
      <FormRow>
        <GenderWithField name={COMPANY_DETAILS_GENDER} />
        <InputWithField name={COMPANY_DETAILS_FIRST_NAME} />
        <InputWithField name={COMPANY_DETAILS_LAST_NAME} />
      </FormRow>
      <FormRow>
        <ContactPersonEmail name={COMPANY_DETAILS_EMAIL} shouldShowEmailIcon />
      </FormRow>
      <FormRow>
        <PhoneNumberField name={USER_PROFILE_PHONE_NUMBER} />
      </FormRow>
      <BirthdateField />
      <FormRow>
        <InputWithField name={STRUCTURE_NUMBER} />
        <InputWithField name={ADP_NUMBER} />
      </FormRow>
    </>
  );
};

export default ForNotLoggedInUser;
