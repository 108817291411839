import React from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import { CONSIDER_SUBSIDIES } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import Collaterals from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection/CollateralsInformation';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useTranslations } from 'utils/hooks/useTranslations';

const InquiryDetailsSection = () => {
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const t = useTranslations('pages.inquiryDetails.detailed');
  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.DETAILS,
    excludedFields: isProfiInquiry ? [CONSIDER_SUBSIDIES] : [],
  });

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('inquiryDetails')}>
      <InformationRows fields={fields} />
      <Collaterals />
    </Section>
  );
};

export default InquiryDetailsSection;
