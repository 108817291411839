import styled from 'styled-components';

import { StyledKeyValueSection } from 'modules/InquiryDetails/DetailedInformation/styles';

interface StyledColleteralProps {
  isLastItem: boolean;
}

export const StyledColleteral = styled.div<StyledColleteralProps>`
  ${StyledKeyValueSection} {
    border-bottom: none;

    &:last-child {
      border-bottom: ${({ theme, isLastItem }) =>
        isLastItem ? 'none' : `1px solid ${theme.colors.border.lightGrey}`};
    }
  }
`;
