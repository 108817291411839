import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

export interface MaximumFinancingParams {
  isQuickCredit: boolean;
  revenue2019: number;
  numberOfEmployees: number;
  liquidityNeed?: number;
  expenditureForEmployees?: number;
}

const maximumFinancingToQueryParams = ({
  isQuickCredit,
  expenditureForEmployees,
  liquidityNeed,
  numberOfEmployees,
  revenue2019,
}: MaximumFinancingParams) => ({
  data: {
    attributes: {
      'use-quick-credit': isQuickCredit,
      'revenue-2019': revenue2019,
      'count-of-employees': numberOfEmployees,
      'liquidity-need': liquidityNeed,
      'expenditure-for-employees': expenditureForEmployees,
    },
  },
});

export const getMaxFinancingAmountAction = (params: MaximumFinancingParams) =>
  callReverseApi({
    url: endpoints.INQUIRIES.CORONA_CALC.compose({ query: maximumFinancingToQueryParams(params) }),
    method: API_METHODS.GET,
  });
