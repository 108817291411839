export const COMPEON_OFFER_PRODUCT_NAME = {
  LOAN: 'loan',
  LEASING: 'leasing',
  HIRE_PURCHASE: 'hirePurchase',
  OTHER: 'other',
  FACTORING: 'factoring',
  INVESTMENT_LOAN: 'investmentLoan',
  MEZZANINE: 'mezzanine',
  PROJECT_FINANCING: 'projectFinancing',
  OVERDRAFT: 'overdraft',
};
