import React from 'react';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import { userIsCustomer } from 'utils/user/conditionals';

export const abortForwardingToCompeonAction = (inquiryId) =>
  callReverseApi({
    url: endpoints.INQUIRIES.FORWARD.COMPEON_ABORT.compose({ params: { id: inquiryId } }),
    method: API_METHODS.PATCH,
  });

export const forwardToCompeonAction = (inquiryId, payload) =>
  callReverseApi({
    url: endpoints.INQUIRIES.FORWARD.COMPEON.compose({ params: { id: inquiryId } }),
    method: API_METHODS.PATCH,
    data: payload && {
      data: {
        attributes: {
          password: payload.password,
          'password-confirmation': payload.confirmPassword,
          'compeon-access-token': payload.accessToken,
        },
      },
    },
  });

export const canConfirmForwardingToCompeon = (Component) => (props) => {
  const isCustomer = userIsCustomer();
  const { canShowAdditionalFinancingPartners } = useInquiryPermissions();

  if (!isCustomer || !canShowAdditionalFinancingPartners) {
    return null;
  }
  return <Component {...props} />;
};
