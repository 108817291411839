import React, { useState } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Condition from 'components/Condition';
import { ErrorText } from 'components/Text';
import { ICompeonOffer } from 'models/CompeonOffer.model';
import OfferBox from 'modules/Offers/InquiryOffersSection/CompeonOffers/OfferBox/OfferBox';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getInquiryAdditionalFields } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  getParsedItemFromSessionStorage,
  saveObjectToSessionStorage,
} from 'utils/sessionStorage/helpers';

import { isPartnerValueAdded } from './OfferBox/OfferBox';
import { useDownloadCompeonFile } from './service';
import { OffersWrapper } from './styles';
import { useCompeonOffers } from './useCompeonOffersForDisplay';

const getStorageName = (id: string): string => `showBasisPartners-${id}`;

const CompeonOffers = () => {
  const { id } = useParams<{ id: string }>();
  const { downloadFile, error } = useDownloadCompeonFile();
  const offers = useCompeonOffers();
  const valueAddedOffers = offers.filter((offer) => isPartnerValueAdded(offer));

  const basisOffers = offers.filter((offer) => !isPartnerValueAdded(offer));

  const isPremiumPartnerEnabled = useConfig(CONFIG.IS_PREMIUM_PARTNER_ENABLED);
  const isSpecialPartnerEnabled = useConfig(CONFIG.IS_SPECIAL_PARTNER_ENABLED);

  const isFastlaneEnabled = useConfig(CONFIG.IS_FASTLANE_ENABLED);
  const { fastlaneEligible } = useSelector(getInquiryAdditionalFields);
  const isFastLane = fastlaneEligible && isFastlaneEnabled;
  const isValueAdded = (isPremiumPartnerEnabled || isSpecialPartnerEnabled) && isFastLane;

  const [showBasisOffers, setShowBasisOffers] = useState<boolean>(() => {
    const isIntrested = basisOffers.some(({ hasShownInterest }) => hasShownInterest);
    const isAlreadyShown = !!getParsedItemFromSessionStorage(getStorageName(id));
    return !isValueAdded || isAlreadyShown || isIntrested;
  });
  const hasBasisOffer = !!basisOffers.length;

  const handleShowBasisOffers = () => {
    setShowBasisOffers(true);
    saveObjectToSessionStorage(getStorageName(id), true);
  };

  return (
    <>
      <OffersWrapper>
        <OffersList offers={valueAddedOffers} onDownload={downloadFile} />
        <Condition condition={showBasisOffers}>
          <OffersList offers={basisOffers} onDownload={downloadFile} />
        </Condition>
      </OffersWrapper>
      <Condition condition={!!error}>
        <ErrorText>{error?.message}</ErrorText>
      </Condition>
      <Condition condition={hasBasisOffer && isValueAdded && !showBasisOffers}>
        <ShowMoreConfirm onConfirm={handleShowBasisOffers} />
      </Condition>
    </>
  );
};

interface OfferstListProps {
  offers: ICompeonOffer[];
  onDownload: () => void;
}

const OffersList = ({ offers, onDownload }: OfferstListProps) => {
  return (
    <>
      {offers.map((offer) => (
        <OfferBox key={offer.id} offer={offer} onDownloadClick={onDownload} />
      ))}
    </>
  );
};

interface ShowMoreConfirmProps {
  onConfirm: () => void;
}

const ShowMoreConfirm = ({ onConfirm }: ShowMoreConfirmProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslations('pages.inquiryDetails.offers.compeon.partner.modal');

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <Box mt={6}>
        <ButtonComponent onClick={onOpen}>{t('openButton')}</ButtonComponent>
      </Box>
      <AlertDialogComponent
        cancelText={t('cancelText')}
        confirmText={t('confirmText')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleConfirm}
        title={t('heading')}
      >
        {t('text')}
      </AlertDialogComponent>
    </>
  );
};

export default CompeonOffers;
