import * as React from 'react';

import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { StyledUnorderedList } from 'pages/inquiryFlow/FinalPage/styles';
import { IInquiryFile, useGetInquiryFiles } from 'pages/inquiryFlow/Rentenbank/useGetInquiryFiles';
import { getInquiryFiles } from 'store/inquiryProcess/selectors';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsBankAdvisor } from 'utils/user/conditionals';

import { FinalPageAction } from './FinalPageAction';

const DocumentUploadAction = () => {
  const t = useTranslations('pages.finalPage.uploadDocuments');
  const fetchInquiryFiles = useGetInquiryFiles();
  const inquiryFiles = useSelector(getInquiryFiles);
  const isBankAdvisorRole = userIsBankAdvisor();
  const inquiryId = useSelector(getCreatedInquiryId);

  const actionUrl = paths[
    isBankAdvisorRole ? 'operation' : 'customer'
  ].inquiryDetails.documentExchange.replace(':id', inquiryId);

  React.useEffect(() => {
    if (inquiryId) fetchInquiryFiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { selectedInquiryType } = useFormConfig();
  const isBFSService = selectedInquiryType === InquiryType.bfsService;

  if (!inquiryFiles.length) return null;

  return (
    <FinalPageAction asset={<Asset type="inquiry" value={InquiryAssetKeys.DOCUMENT_UPLOAD} />}>
      <HeadingComponent as="h3" variant="secondary" mb={4}>
        {t('heading')}
      </HeadingComponent>
      <TextComponent mb={6}>{t('description')}</TextComponent>
      {!isBFSService && (
        <StyledUnorderedList>
          {inquiryFiles.map((item: IInquiryFile) => (
            <li key={item.id}>{item.attributes.title}</li>
          ))}
        </StyledUnorderedList>
      )}
      <ButtonComponent
        as="a"
        href={actionUrl}
        leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
      >
        {t('buttonDescription', { roleSpecific: true })}
      </ButtonComponent>
    </FinalPageAction>
  );
};

export default DocumentUploadAction;
