import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { INVESTMENT_LOAN_AMORTISATION_KIND } from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useTranslations } from 'utils/hooks/useTranslations';

const InvestmentLoanAmortisationKind = () => {
  const options = useInquirySelectOptions(INVESTMENT_LOAN_AMORTISATION_KIND);
  const t = useTranslations(
    'pages.requestDetails.sections.investmentLoan.fields.investmentLoanAmortisationKind',
  );

  return (
    <SelectWithField
      name={INVESTMENT_LOAN_AMORTISATION_KIND}
      options={options}
      tooltip={t('tooltip')}
      data-testid={'select-field-' + INVESTMENT_LOAN_AMORTISATION_KIND}
    />
  );
};

export default InvestmentLoanAmortisationKind;
