import {
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_CUSTOMER,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
} from 'constants/user/userRoles';
import { UserRole, UserRolesFromApi } from 'models/UserData.model';
import {
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
  COMPANY_DETAILS_LAST_NAME,
  GENDER_MAN,
  USER_PROFILE_PHONE_NUMBER,
  VB_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { UserAttributes, UserDetailsApiResponse } from 'types/User';

export const mapUserAttributes = (user: UserDetailsApiResponse) => {
  const {
    id,
    email,
    first_name: firstName,
    last_name: lastName,
    birth_date: birthDate,
    phone_number: phoneNumber,
    salutation: gender,
    vb_number: vbNumber,
    zip_code: zipCode,
    locale: language,
  } = user;
  return {
    id,
    email,
    firstName,
    lastName,
    birthDate,
    phoneNumber,
    gender,
    vbNumber,
    zipCode,
    language,
  };
};

export const mapUserRoles = (roles: UserRolesFromApi): UserRole[] => {
  const mappedRoles = {
    [USER_ROLE_INQUIRY_MANAGER]: roles.inquiry_manager,
    [USER_ROLE_CUSTOMER]: roles.customer,
    [USER_ROLE_CALL_CENTER_AGENT]: roles.call_center_agent,
    [USER_ROLE_BANK_ADVISOR]: roles.bank_advisor,
    [USER_ROLE_REAL_ESTATE_EXPERT]: roles.real_estate_expert,
  };
  return (Object.keys(mappedRoles) as Array<keyof typeof mappedRoles>).filter(
    (role) => mappedRoles[role],
  );
};

export type MappedUserBase = UserAttributes & { name: string };
type MappedLoggedInUser = Omit<MappedUserBase, 'id' | 'language'>;
interface MappedNewUser extends Omit<MappedLoggedInUser, 'zipCode' | 'birthDate' | 'phoneNumber'> {
  phoneNumber?: string;
}
export const mapAttributesForNewUser = (
  companyDetails: Record<string, any>,
  userProfile?: Record<string, any>,
): MappedNewUser => ({
  name: `${companyDetails[COMPANY_DETAILS_FIRST_NAME]} ${companyDetails[COMPANY_DETAILS_LAST_NAME]}`,
  firstName: companyDetails[COMPANY_DETAILS_FIRST_NAME],
  lastName: companyDetails[COMPANY_DETAILS_LAST_NAME],
  gender: companyDetails[COMPANY_DETAILS_GENDER],
  email: companyDetails[COMPANY_DETAILS_EMAIL],
  ...(userProfile && { phoneNumber: userProfile[USER_PROFILE_PHONE_NUMBER] }),
  vbNumber: companyDetails[VB_NUMBER],
});

export const mapAttributesForLoggedInUser = (attributes: UserAttributes): MappedLoggedInUser => ({
  name: `${attributes.firstName} ${attributes.lastName}`,
  firstName: attributes.firstName,
  lastName: attributes.lastName,
  birthDate: attributes.birthDate,
  gender:
    attributes.gender === GENDER_MAN ? COMPANY_DETAILS_GENDER__MAN : COMPANY_DETAILS_GENDER__WOMAN,
  email: attributes.email,
  phoneNumber: attributes.phoneNumber,
  vbNumber: attributes.vbNumber,
  zipCode: attributes.zipCode,
});
