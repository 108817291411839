import React from 'react';

import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import StaticField from 'components/StaticField';
import {
  SALES_PERSON,
  CAR_DEALER_NAME,
  VEHICLE_MANUFACTURER,
  YEARLY_MILEAGE,
  LEASING_DURATION,
  LEASING_INSTALLMENT,
  ACCOUNT_OWNER,
  IBAN,
  VEHICLE_MODEL,
  VEHICLE_DRIVE_TYPE,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useInquiryValueParserForDisplay } from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { FormSections } from 'schema/inquirySchema.models';
import { getContractDetails } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ContractDetailsWrapper } from './styles';

const ContractDetailsSection: React.FC = () => {
  const translateLabelForField = useInquiryLabelTranslator();

  const t = useTranslations('pages.contractDetails.sections');

  const contractDetails = useSelector(getContractDetails);
  const parseFieldValue = useInquiryValueParserForDisplay();

  return (
    <ContractDetailsWrapper>
      <FormSection title={t('dealer.title')} name={FormSections.contractDetails} sectionNumber={1}>
        <FormRow>
          <StaticField
            caption={translateLabelForField(CAR_DEALER_NAME)}
            text={contractDetails[CAR_DEALER_NAME]}
          />
          <StaticField
            caption={translateLabelForField(SALES_PERSON)}
            text={contractDetails[SALES_PERSON]}
          />
        </FormRow>
      </FormSection>
      <FormSection title={t('car.title')} name={FormSections.contractDetails} sectionNumber={2}>
        <FormRow>
          <StaticField
            caption={translateLabelForField(VEHICLE_MANUFACTURER)}
            text={`${contractDetails[VEHICLE_MANUFACTURER]} ${contractDetails[VEHICLE_MODEL]}`}
          />
          <StaticField
            caption={translateLabelForField(VEHICLE_DRIVE_TYPE)}
            text={`${contractDetails[VEHICLE_DRIVE_TYPE]}`}
          />
        </FormRow>
      </FormSection>
      <FormSection
        title={t('leasingConditions.title')}
        name={FormSections.contractDetails}
        sectionNumber={3}
      >
        <FormRow>
          <StaticField
            caption={translateLabelForField(LEASING_DURATION)}
            text={contractDetails[LEASING_DURATION]}
          />
          <StaticField
            caption={translateLabelForField(YEARLY_MILEAGE)}
            text={parseFieldValue(contractDetails[YEARLY_MILEAGE], YEARLY_MILEAGE)}
          />
        </FormRow>
        <StaticField
          caption={translateLabelForField(LEASING_INSTALLMENT)}
          text={parseFieldValue(contractDetails[LEASING_INSTALLMENT], LEASING_INSTALLMENT)}
        />
      </FormSection>
      <FormSection
        title={t('accountInformation.title')}
        name={FormSections.contractDetails}
        sectionNumber={4}
      >
        <FormRow>
          <StaticField caption={translateLabelForField(IBAN)} text={contractDetails[IBAN]} />
          <StaticField
            caption={translateLabelForField(ACCOUNT_OWNER)}
            text={contractDetails[ACCOUNT_OWNER]}
          />
        </FormRow>
      </FormSection>
    </ContractDetailsWrapper>
  );
};

export default ContractDetailsSection;
