import { useCallback } from 'react';

import { MB_SIZE, MAX_FILE_SIZE, ALLOWED_FILE_EXTENSIONS, ALLOWED_PDF_ONLY } from 'constants/file';
import { useTranslations } from 'utils/hooks/useTranslations';

const printReadableFileSize = (fileSize: number) => {
  return `${(fileSize / MB_SIZE).toFixed(0).toString()}MB`;
};

export const validateFileSize =
  (maxFileSize: number) => (file: File, translator: (...args: any) => string) => {
    if (file.size >= maxFileSize) {
      return translator(`tooBigFile`, { size: printReadableFileSize(maxFileSize) });
    }
    return null;
  };

export const validateFileExtension =
  (allowedExtensions: Array<string>) => (file: File, translator: (...args: any) => string) => {
    const ext = file.name.split('.').pop() || '';

    if (!allowedExtensions.includes(ext.toLowerCase())) {
      return translator(`incorrectExtension`, { extensions: allowedExtensions.join(', ') });
    }
    return null;
  };

export const DEFAULT_VALIDATORS = [
  validateFileExtension(ALLOWED_FILE_EXTENSIONS),
  validateFileSize(MAX_FILE_SIZE),
];

export const PDF_VALIDATORS = [
  validateFileExtension(ALLOWED_PDF_ONLY),
  validateFileSize(MAX_FILE_SIZE),
];

export const useFileValidator = (validators = DEFAULT_VALIDATORS) => {
  const t = useTranslations('errors');

  return useCallback(
    (file: File): Promise<File | string | null> => {
      const falsyValidator = validators.find((validation) => validation(file, t));
      if (falsyValidator) {
        return Promise.reject(falsyValidator(file, t));
      }
      return Promise.resolve(file);
    },
    [t, validators],
  );
};
