import normalize from 'json-api-normalizer';
import _values from 'lodash/values';

import { useInquiry } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { mapInquiryDetailsFromApi } from 'modules/InquiryDetails/InquiryDetails.service';

import useParamsSafe from '../useParamsSafe';

const useCurrentInquiryDetails = () => {
  const { id } = useParamsSafe(['id']);
  const { data, isLoading } = useInquiry<any>({ variables: { id }, suspense: true });
  if (isLoading) {
    return { isLoading: true };
  }
  const { data: inquiryDetailsData } = data;
  const inquiryDetails: Partial<IInquiryDetails> = mapInquiryDetailsFromApi(inquiryDetailsData);
  return { isLoading: false, ...inquiryDetails };
};

export const useCurrentCompanyDetails = () => {
  const { id } = useParamsSafe(['id']);
  const { data, isLoading } = useInquiry<any>({ variables: { id }, suspense: true });
  if (isLoading) {
    return { isLoading: true };
  }
  const { data: inquiryDetailsData } = data;

  const normalizedResponse = normalize(inquiryDetailsData, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  });

  const company = _values(normalizedResponse.companies)[0];
  const referenceId: string = company?.attributes?.additional_details?.partner_reference_id;

  return { referenceId };
};

export default useCurrentInquiryDetails;
