import { useState, useEffect } from 'react';

const DEFAULT_DELAY = 3000;
export function useTimeout<TCallback extends Function>(callback: TCallback, delay = DEFAULT_DELAY) {
  const [timerId, saveTimerId] = useState(0);

  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  return () => saveTimerId(setTimeout(callback, delay));
}
