import { useEffect, useRef } from 'react';

import { ConfigController } from 'config/ConfigController';
import { useForceRender } from 'shared/hooks/useForceRender';

import { IFormConfigRepo, InquiryConfig } from './types';

export const useFormConfigRepo = (): IFormConfigRepo => {
  const formConfigRepo = useRef<IFormConfigRepo>(ConfigController.form);
  const forceRender = useForceRender();

  useEffect(() => {
    const subscription = ConfigController.form.subscribe(forceRender);
    return () => ConfigController.form.unsubscribe(subscription);
  }, [forceRender]);

  return formConfigRepo.current;
};

export const useFormConfig = (): InquiryConfig => {
  const formConfigRepo = useFormConfigRepo();
  return formConfigRepo.getConfig();
};
