import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { FormSpy, useForm } from 'react-final-form';

import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { useFormConfig } from 'config/formConfig/hooks';
import { fieldIsValid, fieldHasValue, fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CompanyNoteSection from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyNoteSection/CompanyNoteSection';
import CompanySuggestion from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/CompanySuggestion';
import ExistingUserCompanySelect from 'pages/inquiryFlow/CompanyDetails/sections/Company/ExistingUserCompanySelect';
import { shouldShowCompanySuggestionsSelect } from 'pages/inquiryFlow/CompanyDetails/sections/Company/service';
import { SsoCompanySection } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/SsoCompanySection';
import CompanyInformationForm from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

export const validateExistingUserCompanySection = (form: any) =>
  fieldIsValid(COMPANY_DETAILS_EXISTING_USER_COMPANY)({ form });

const checkIsCompanySearchNotFound = (form: any) =>
  fieldHasValue(COMPANY_DETAILS_COMPANY, COMPANY_DETAILS_COMPANY__NOT_FOUND)({ form });
const checkIsExistingUserNewCompany = (form: any) =>
  fieldHasValue(
    COMPANY_DETAILS_EXISTING_USER_COMPANY,
    COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  )({ form });
const fillCompanyInformationFormIsValid = (form: any) =>
  fieldsAreValid([
    COMPANY_DETAILS_COMPANY,
    USER_PROFILE_COMPANY_NAME,
    USER_PROFILE_COMPANY_STREET,
    USER_PROFILE_COMPANY_ZIP_CODE,
    USER_PROFILE_COMPANY_CITY,
  ])({ form });
const companyDetailsCompanyIsValid = (form: any) => fieldIsValid(COMPANY_DETAILS_COMPANY)({ form });
const companySearchAndExistingUserCompanyIsValid = (form: any) =>
  fieldsAreValid([COMPANY_DETAILS_COMPANY, COMPANY_DETAILS_EXISTING_USER_COMPANY])({
    form,
  });

export const validateCompanySelection =
  (isLoggedIn: boolean) =>
  (form: any): boolean => {
    if (!isLoggedIn) {
      if (checkIsCompanySearchNotFound(form)) {
        return companyDetailsCompanyIsValid(form) && fillCompanyInformationFormIsValid(form);
      }
      return companyDetailsCompanyIsValid(form);
    }
    if (checkIsExistingUserNewCompany(form)) {
      if (checkIsCompanySearchNotFound(form)) {
        return (
          companySearchAndExistingUserCompanyIsValid(form) &&
          fillCompanyInformationFormIsValid(form)
        );
      }
      return companySearchAndExistingUserCompanyIsValid(form);
    }
    return fieldIsValid(COMPANY_DETAILS_EXISTING_USER_COMPANY)({ form });
  };

export const validateCompanyNameFields =
  (isLoggedIn: boolean) =>
  (form: any): boolean => {
    if (!isLoggedIn) {
      return companyDetailsCompanyIsValid(form);
    }
    if (checkIsExistingUserNewCompany(form)) {
      return companySearchAndExistingUserCompanyIsValid(form);
    }
    return fieldIsValid(COMPANY_DETAILS_EXISTING_USER_COMPANY)({ form });
  };

const CompanySelectionSection = ({ tooltip = false }) => {
  const t = useTranslations('pages.companyDetails.sections.company');
  const isLoggedIn = isLoggedInUser();
  const [firstRender, setFirstRender] = useState(true);
  const form = useForm();
  const { change } = form;
  const shouldShowSuggestion = shouldShowCompanySuggestionsSelect({
    isLoggedIn,
    form,
  });

  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  // CRV-2291 Refresh AOS (animation library) to show section if page height change
  useEffect(() => {
    if (!firstRender) {
      change(USER_PROFILE_COMPANY_ALREADY_CUSTOMER, null);
      change(USER_PROFILE_FOUNDING_YEAR, null);
      change(USER_PROFILE_INDUSTRY, null);
      change(USER_PROFILE_LEGAL_FORM, null);
      change(USER_PROFILE_TURNOVER_CLASS, null);
      change(USER_PROFILE_COMPANY_NAME, null);
      change(USER_PROFILE_COMPANY_STREET, null);
      change(USER_PROFILE_COMPANY_ZIP_CODE, null);
      change(USER_PROFILE_COMPANY_CITY, null);
    }
    setFirstRender(false);
    // eslint-disable-next-line
  }, [shouldShowSuggestion]);

  return (
    <FormSpy
      subscription={{ values: true }}
      render={() => (
        <FormSection name={FormSections.companySelection} sectionNumber={1} title={t('title')}>
          {isLoggedIn && <ExistingUserCompanySelect tooltip={tooltip} />}
          {shouldShowSuggestion && (
            <FormRow>
              <CompanySuggestion fieldName={COMPANY_DETAILS_COMPANY} />
            </FormRow>
          )}
          <CompanyNoteSection />

          {isBfsService && (
            <Condition condition={validateCompanyNameFields(isLoggedIn)({ form })}>
              <Box mt={14}>
                <CompanyInformationForm />
              </Box>
            </Condition>
          )}
        </FormSection>
      )}
    />
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_SSO_ENABLED]: SsoCompanySection,
  default: CompanySelectionSection,
});
