import React from 'react';

import { HStack, Icon, Text, VStack } from '@chakra-ui/react';

import { ReactComponent as DocumentIcon } from 'assets/specific-icons/file.svg';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { LoadingComponent } from './components/LoadingComponent';
import { useMultipleFiles } from '../hooks/useMultipleFiles';

export const UploadMultipleFiles = () => {
  const t = useTranslations('components.upload');

  const { getInputProps, getRootProps, isUploading, open } = useMultipleFiles({});

  return (
    <VStack
      padding={8}
      spacing={4}
      marginBottom={8}
      backgroundColor="white"
      boxShadow="lg"
      borderRadius="lg"
      width="full"
      alignItems="normal"
      data-testid="multiple-file-upload-block"
    >
      <HStack {...getRootProps()} spacing={8}>
        <Icon as={DocumentIcon} boxSize={16} />
        <input {...getInputProps()} />
        <VStack alignItems="start">
          <HeadingComponent variant="secondary" color="brand.default" fontWeight="semibold">
            {t('uploadMultipleDocuments.title')}
          </HeadingComponent>
          <Text color="text.secondary">{t('uploadMultipleDocuments.description')}</Text>
        </VStack>
        <LoadingComponent isLoading={isUploading}>
          <ButtonComponent onClick={open} leftIcon={<UploadIcon />} flexShrink="0">
            {t('sendNow')}
          </ButtonComponent>
        </LoadingComponent>
      </HStack>
    </VStack>
  );
};
