import React from 'react';

import { NewStaticFieldWithField } from 'components/StaticField';
import { getFieldProps } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/formFields/getFieldProps';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';
import { useTranslations } from 'utils/hooks/useTranslations';

export type FieldConfig<T> = Record<
  keyof T,
  {
    withStatic: boolean;
    component: React.ComponentType<any>;
    caption: string;
    componentProps?: (translation: any) => any;
    optional?: boolean;
  }
>;

function useGetFieldProps<Fields>(prefix?: string) {
  const t = useTranslations();

  return ({
    field,
    caption,
    optional,
  }: {
    field: keyof Fields;
    caption: string;
    optional?: boolean;
  }) => {
    return getFieldProps({
      field: field as string,
      caption,
      optional,
      t,
      prefix,
    });
  };
}

export function useGenerateField<Model>(
  fieldConfig: FieldConfig<Model>,
  mode: AssociatedPersonMode,
  prefix?: string,
) {
  const getFieldProps = useGetFieldProps<Model>(prefix);
  const t = useTranslations();

  return (fieldName: keyof Model) => {
    const {
      withStatic,
      component: FieldComponent,
      caption,
      optional,
      componentProps,
    } = fieldConfig[fieldName];
    const fieldProps = getFieldProps({
      field: fieldName,
      caption: caption,
      optional: optional,
    });

    const additionalFieldProps = componentProps ? componentProps(t) : {};

    if (withStatic && mode === 'edit') {
      return <NewStaticFieldWithField {...fieldProps} />;
    } else {
      return <FieldComponent {...fieldProps} {...additionalFieldProps} />;
    }
  };
}
