import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import SectionHeading from 'components/Text/SectionHeading';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { TECHNOLOGY_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateMoreTechnologySection = validateSectionFields([TECHNOLOGY_DESCRIPTION]);

export const MoreTechnologiesSection = () => {
  const t = useTranslations(
    'inquiryType.energyEfficiency.steps.purpose.sections.technologyDetails',
  );

  return (
    <FormSection title={t('title')} sectionNumber={3}>
      <SectionHeading>
        <MarkdownWrapper text={t('fields.technologyDescription.heading')} />
      </SectionHeading>
      <FormRow>
        <TextAreaWithField name={TECHNOLOGY_DESCRIPTION} />
      </FormRow>
    </FormSection>
  );
};
