import moment from 'moment';

import { HausbankFieldTypes } from 'hausbank/inquiry/fields';

type HausbankWegDataFieldTypes = HausbankFieldTypes['companyDataPage']['wegDataCompletionSection'];

export const mapHausbankWegData = (values: HausbankWegDataFieldTypes) => ({
  name: values.name,
  street: values.street,
  zip_code: values.zipCode,
  city: values.city,
  legal_form: values.legalForm,
  unit_count_under_control: values.managedUnits,
  founding_year: moment(values.foundingYear).year(),
  already_customer: values.isExistingCustomer,
});
