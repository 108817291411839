import { SummaryFieldsType } from 'new/form/model';

import { FieldTypes } from '../fields';

// the following structure must contain all the steps and fields as per fields;
export const summaryFields: SummaryFieldsType<FieldTypes> = {
  contractDetails: {
    dealerSection: {
      supplier: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 1,
      },
      agent: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 2,
        showEmpty: true,
      },
    },
    financingObjectSection: {
      objectDescription: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 3,
      },
      state: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 4,
      },
      objectAlreadyDelivered: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 5,
      },
      serialNumber: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 6,
        showEmpty: true,
      },
    },
    financingConditionsSection: {
      runtime: {
        unit: 'months',
        showOnSummaryPage: true,
        order: 7,
        label: (inquiry) => inquiry?.inquiryTypeSpecificDetails?.labelRuntime,
      },
      objectValue: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
        order: 8,
        showEmpty: true,
      },
      downPayment: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
        order: 9,
        showEmpty: true,
      },
      ratePerMonth: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
        order: 10,
      },
      remainingValue: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
        order: 11,
        showEmpty: true,
        label: (inquiry) => inquiry?.inquiryTypeSpecificDetails?.labelResidualValue,
      },
    },
    bankDetailsSection: {
      accountHolder: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      iban: {
        unit: 'none',
        showOnSummaryPage: true,
        showEmpty: true,
      },
    },
  },
  customerDataPage: {
    companySection: {
      companyName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      address: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    contactPersonSection: {
      title: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      firstName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lastName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      email: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    authorizationOfRepresentationSection: {},
    beneficialOwnerSection: {
      isFictitiousBeneficialOwner: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
  },
};
