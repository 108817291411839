import {
  LEASING_TAKEOVER__NO,
  LEASING_TAKEOVER__UNDECIDED,
  LEASING_TAKEOVER__YES,
} from 'modules/Inquiry/Form/formFields';

const getLeasingTakeoverOptions = (formatMessage) =>
  [
    {
      id: 'pages.requestDetails.sections.leasing.fields.leasingTakeover.options.yes',
      value: LEASING_TAKEOVER__YES,
    },
    {
      id: 'pages.requestDetails.sections.leasing.fields.leasingTakeover.options.no',
      value: LEASING_TAKEOVER__NO,
    },
    {
      id: 'pages.requestDetails.sections.leasing.fields.leasingTakeover.options.undecided',
      value: LEASING_TAKEOVER__UNDECIDED,
    },
  ].map(({ value, id }) => ({ label: formatMessage({ id }), value }));

export default getLeasingTakeoverOptions;
