import React from 'react';

import { OfferStatusType } from 'models/types/OfferStatus.type';
import { StyledOfferAcceptMsg } from 'modules/Offers/InquiryOffersSection/OfferAcceptedMsg/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

type PossibleStatus = Extract<OfferStatusType, 'accepted' | 'shownInterest'>;

const statusText: Record<PossibleStatus, string> = {
  accepted: 'acceptedOffer',
  shownInterest: 'interestInOffer',
};

interface Props {
  status: PossibleStatus;
  isNeutral?: boolean;
}

const OfferAcceptedMsg = ({ status, isNeutral = false }: Props) => {
  const t = useTranslations('pages.inquiryDetails.offers');

  if (status !== 'accepted' && status !== 'shownInterest') {
    return (
      <StyledOfferAcceptMsg className="neutral">{t('acceptedAnotherOffer')}</StyledOfferAcceptMsg>
    );
  }

  return (
    <StyledOfferAcceptMsg className={isNeutral ? 'neutral' : ''}>
      {t(`${statusText[status]}`)}
    </StyledOfferAcceptMsg>
  );
};

export default OfferAcceptedMsg;
