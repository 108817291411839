import React from 'react';

import YesNoField from 'components/PortalFormFields/YesNoField';
import { FieldTypeComponentProps } from 'modules/FormGenerator/propTypes';

const BooleanTypeField = ({ field: { name, value, hasError }, onChange }) => (
  <YesNoField name={name} value={value} onChange={onChange} hasError={hasError} />
);

BooleanTypeField.propTypes = FieldTypeComponentProps;

export default BooleanTypeField;
