import moment from 'moment';

import {
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_FOUNDING_MONTH,
} from 'modules/Inquiry/Form/formFields';
import { useFieldValue } from 'store/hooks/useFormValues';

const COMPANY_YOUNG_THRESHOLD = 3;
export const useIsCompanyYoung = () => {
  const foundingYear = useFieldValue<string>(USER_PROFILE_FOUNDING_YEAR, '');
  const foundingMonth = useFieldValue<string>(USER_PROFILE_FOUNDING_MONTH, '');
  const foundingDate = moment([foundingYear, foundingMonth, 1]);

  const yearsLong = Math.round(moment().diff(foundingDate, 'years', true) * 10) / 10;
  return yearsLong < COMPANY_YOUNG_THRESHOLD;
};
