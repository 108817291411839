type ActionFunction<T> = (data: T) => void;
type Filter<T> = (data: T) => boolean;

interface Action<T> {
  action: ActionFunction<T>;
  filters?: Filter<T>[];
}
export abstract class Observable<T> {
  private actions: Action<T>[] = [];

  public subscribe(action: ActionFunction<T>, filtersArray?: Filter<T>[]): Action<T> {
    const currentAction = this.actions.find((singleAction) => singleAction.action === action);

    if (!currentAction) {
      const actionObject = { action, filters: filtersArray };
      this.actions.push(actionObject);

      return actionObject;
    } else {
      return currentAction;
    }
  }

  public unsubscribe(action: Action<T>): void {
    const actionIndex = this.actions.findIndex((singleAction) => singleAction === action);

    if (actionIndex > -1) {
      this.actions.splice(actionIndex, 1);
    }
  }

  protected emit(data: T): void {
    this._filterActions(data).forEach(({ action }) => {
      action(data);
    });
  }

  private _filterActions(data: T): Action<T>[] {
    return this.actions.filter((action) => {
      return action.filters?.length ? action.filters.some((filter) => filter(data)) : true;
    });
  }
}
