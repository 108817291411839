import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { password, passwordConfirmation } = hausbankFields.personalDataPage.passwordSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.personalData.sections.password;

export const passwordSectionLabelsMap: LabelsMap = {
  [password]: fieldTranslations.password.caption,
  [passwordConfirmation]: fieldTranslations.passwordConfirmation.caption,
};
