import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFields, ValidateSectionFunction } from 'new/form/useValidateFields';

import { mittweidaFields } from '../../../../fields';

export const validateTermsAndConditionsSection: ValidateSectionFunction<
  InquiryType.profiMittweida
> = (form, values, store, conditions) => {
  return validateFields({
    fields: [mittweidaFields.userProfilePage.termsAndConditionsSection.termsAndConditionsAccepted],
    form,
    values,
    conditions,
  });
};
