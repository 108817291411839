/*
 * This map is for translating all fields that we are sending from inquiry flow.
 * It matches key under which we send data with label so later we can show it on details view
 * ex. respone ==> { amount: 123 } ==> "Finanzierungsbetrag: 123"
 */

// Some options have format { label: 'someLabel', value: 'option_value' }
// here we map it to { option_value: 'label' } format
// see more under `getFactoring...Options` method
export const optionsToTranslationMap = (options) =>
  options.reduce((accumulator, option) => {
    accumulator[option.value] = option.label;
    return accumulator;
  }, {});

export const getIdFromObject = ({ id }) => id;
