import React from 'react';

import { ModalFooter } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import ModalSectionHeader from 'components/Text/ModalSectionHeader';
import { ICompeonOffer } from 'models/CompeonOffer.model';
import FormGenerator from 'modules/FormGenerator';
import { StyledFormGroupTitle } from 'modules/FormGenerator/styles';
import { uploadFiles } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import { StyledDescription } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/styles';
import { getCompeonInquiryId } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Questionnaire } from './Questionnaire';

interface Props {
  offer: ICompeonOffer;
  onAccepted: () => void;
  onClose: () => void;
  modalTitle: string;
  isOpen?: boolean;
}

interface Response {
  name: string;
  value: string;
  hasError: boolean;
}

export type FormValidityState = Record<string, boolean>;

export const CompeonOfferWithQuestionnaire = ({
  offer,
  onAccepted,
  onClose,
  modalTitle,
  isOpen = false,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.offers');
  const compeonInquiryId = useSelector(getCompeonInquiryId);
  const { makeCall } = useDispatchApiCall();

  const [canSubmit, setCanSubmit] = React.useState(false);
  const [formsValidation, setFormsValidation] = React.useState<FormValidityState | undefined>(() =>
    offer.questionnaireIds?.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
  );
  const [formsFilling, setFormsFilling] = React.useState<FormValidityState | undefined>(() =>
    offer.questionnaireIds?.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
  );

  const handleUploadedFiles = React.useCallback(
    async (response: Response) => {
      const document =
        offer.type === 'CompeonOffer'
          ? offer.filesRequiredByBank.find((file) => file.id === response.name)
          : { name: '' };
      const payload = {
        data: {
          type: 'attachments',
          attributes: {
            'virtual-path': document?.name || '',
          },
          relationships: {
            document: {
              data: {
                id: response.value,
                type: 'documents',
              },
            },
          },
        },
      };

      await makeCall(uploadFiles(compeonInquiryId, payload));
    },
    [compeonInquiryId, makeCall, offer.filesRequiredByBank, offer.type],
  );

  const template =
    offer.type === 'CompeonOffer' && offer.filesRequiredByBank
      ? offer.filesRequiredByBank.map((item, index) => ({
          id: item.id,
          kind: 'field',
          name: item.id,
          label: item.name,
          position: index,
          type: 'documents',
        }))
      : [];

  const hasRequiredDocumentsUploaded =
    offer.type === 'CompeonOffer' &&
    typeof offer.filesRequiredByBank !== 'undefined' &&
    offer.filesRequiredByBank.length
      ? offer.filesRequiredByBank.every((item) => item.uploaded)
      : true;

  React.useEffect(() => {
    if (formsValidation && formsFilling) {
      const areFormsValid = Object.values(formsValidation).every((el) => el === true);
      const areFormsFilled = Object.values(formsFilling).every((el) => el === true);

      setCanSubmit(areFormsValid && areFormsFilled && hasRequiredDocumentsUploaded);
    }
  }, [formsValidation, formsFilling, hasRequiredDocumentsUploaded]);

  const canShowFilesUploadForm =
    offer.type === 'CompeonOffer' &&
    typeof offer.filesRequiredByBank !== 'undefined' &&
    !!offer.filesRequiredByBank.length &&
    template.length === offer.filesRequiredByBank.length;

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <StyledDescription>
        {t('fastlane.description', { bankName: offer.bankName })}
      </StyledDescription>
      {canShowFilesUploadForm ? (
        <>
          <ModalSectionHeader>{t('fastlane.documents')}</ModalSectionHeader>
          <FormGenerator fields={template} onFormChange={handleUploadedFiles} />
        </>
      ) : null}
      <StyledFormGroupTitle>{t('fastlane.companyInfo')}</StyledFormGroupTitle>
      {offer.questionnaireIds?.map((questionnaireId) => (
        <Questionnaire
          key={questionnaireId}
          id={questionnaireId}
          setFormsValidation={setFormsValidation}
          setFormsFilling={setFormsFilling}
        />
      ))}
      <ModalFooter pr={-8}>
        <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
          {t('cancel')}
        </ButtonComponent>
        <ButtonComponent form="form-callback" disabled={!canSubmit} onClick={onAccepted}>
          {t('acceptOffer')}
        </ButtonComponent>
      </ModalFooter>
    </ModalComponent>
  );
};
