import React, { createContext, useContext, useState } from 'react';

type RevisionValuesContextType = {
  revisionState: Record<string, any>;
  setRevisionState: (values: Record<string, any>) => void;
};

const RevisionContext = createContext<RevisionValuesContextType>({
  revisionState: {},
  setRevisionState: () => {},
});

export const useRevisionStateContext = () => {
  const context = useContext(RevisionContext);

  if (!context) {
    throw new Error('useRevisionStateContext must be used within a RevisionProvider');
  }

  return context;
};

export const RevisionProvider = ({ children }: { children: React.ReactNode }) => {
  const [revisionState, setRevisionState] = useState({
    revisionList: [],
    selectedRevisionId: '',
  });

  const updateRevisionState = React.useCallback(
    (updatedValues: Partial<typeof revisionState>) => {
      setRevisionState((prevState) => ({
        ...prevState,
        ...updatedValues,
      }));
    },
    [setRevisionState],
  );

  return (
    <RevisionContext.Provider value={{ revisionState, setRevisionState: updateRevisionState }}>
      {children}
    </RevisionContext.Provider>
  );
};
