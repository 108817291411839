import React from 'react';

import PropTypes from 'prop-types';

import ProductKindTile from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKindList/ProductKindTile/ProductKindTile';
import {
  StyledProductKinds,
  StyledSingleProduct,
} from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKindList/styles';

const ProductKindList = ({ products, onProductSelected, selectedProductId, name }) => {
  const selectProduct = (productId) => () => {
    onProductSelected(productId);
  };

  return (
    <StyledProductKinds>
      {products.map(({ id, title, icon, description }, index) => (
        <StyledSingleProduct key={id} delay={index * 100} onlyOnce>
          <ProductKindTile
            id={id}
            name={name}
            selected={selectedProductId === id}
            title={title}
            description={description}
            icon={icon}
            onClick={selectProduct(id)}
          />
        </StyledSingleProduct>
      ))}
    </StyledProductKinds>
  );
};

export default ProductKindList;

ProductKindList.defaultProps = {
  selectedProductId: '',
  products: [],
};

ProductKindList.propTypes = {
  onProductSelected: PropTypes.func.isRequired,
  selectedProductId: PropTypes.string,
  name: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired, icon: PropTypes.node.isRequired }),
  ),
};
