import React from 'react';

import { useUploadStatusText } from 'components/UploadBlock/hooks/useUploadStatusText';
import { UploadStatusType } from 'models/types/UploadStatus.type';
import { BadgeComponent, BadgeStatus } from 'theme/components/Badge';

interface IFileRequestStatusLabel {
  status: UploadStatusType;
}

const BADGE_VARIANTS: Record<string, BadgeStatus> = {
  notUploaded: BadgeStatus.INFO,
  uploaded: BadgeStatus.SUCCESS,
  scanning: BadgeStatus.INFO,
  uploadedAndDownloaded: BadgeStatus.SUCCESS,
  neutral: BadgeStatus.INFO,
};

const FileRequestStatusLabel = ({ status }: IFileRequestStatusLabel) => {
  const statusText = useUploadStatusText(status);

  return (
    <BadgeComponent data-testid="request-status" variant={BADGE_VARIANTS[status]}>
      {statusText}
    </BadgeComponent>
  );
};

export default FileRequestStatusLabel;
