import { FieldTypes } from 'mmv/inquiry/fields';
import { ConditionsType } from 'new/form/model';
import { store } from 'store';
import { getBeneficialOwnerSelector } from 'store/inquiryDetails/selectors';

const BENEFICIARY_OWNER_SECTION = 'customerDataPage.beneficialOwnerSection';

export const conditions: ConditionsType<FieldTypes> = {
  [BENEFICIARY_OWNER_SECTION]: showBeneficialOwnerSection,
};

function showBeneficialOwnerSection() {
  const beneficialOwner = getBeneficialOwnerSelector(store.getState());
  return beneficialOwner || false;
}

export function useShowBeneficialOwnerSection() {
  return showBeneficialOwnerSection();
}
