import React from 'react';

import FormRow from 'components/FormRow';
import { PasswordInputWithField } from 'components/PasswordInput';
import {
  USER_PROFILE_PASSWORD,
  USER_PROFILE_PASSWORD_REPEATED,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, equalTo, isValidPassword, required } from 'utils/validators';

const PasswordFields = () => {
  const tMain = useTranslations();
  const t = useTranslations('pages.userProfile.sections.completingContactData.fields');

  return (
    <FormRow>
      <PasswordInputWithField
        name={USER_PROFILE_PASSWORD}
        validate={combineValidators(
          required(tMain('errors.required')),
          isValidPassword(t('userProfilePassword.errors.isValid')),
        )}
        caption={t('userProfilePassword.caption')}
      />
      <PasswordInputWithField
        name={USER_PROFILE_PASSWORD_REPEATED}
        validate={combineValidators(
          required(tMain('errors.required')),
          equalTo(USER_PROFILE_PASSWORD, t('userProfilePasswordRepeated.errors.equalTo')),
        )}
        caption={t('userProfilePasswordRepeated.caption')}
      />
    </FormRow>
  );
};

export default PasswordFields;
