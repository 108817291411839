import React from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FilesToCompeon from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToCompeon/FilesToCompeon';
import ProcessSpecificFiles from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/ProcessSpecificFiles';
import AdditionalFilesFromCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/AdditionalFilesFromCustomer/AdditionalFilesFromCustomer';
import AssessmentFiles from 'pages/operationPortal/InquiryDetails/DocumentExchange/AssessmentFiles/AssessmentFiles';
import FilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/FilesForCustomer/FilesForCustomer';
import { InternalFiles } from 'pages/operationPortal/InquiryDetails/DocumentExchange/InternalFiles';
import PrivateFilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/PrivateFilesForCustomer/PrivateFilesForCustomer';
import RequestsForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/RequestsForCustomer';
import { useFetchPrivateFiles } from 'shared/documentExchange/useFetchInquiryFiles';

import {
  useOperationDocumentExchangeWebSocket,
  useOperationDocumentExchange,
} from './useOperationDocumentExchange';

export const MittweidaOperationDocumentExchange = () => {
  const { isLoading: isPrivateFilesLoading } = useFetchPrivateFiles();
  const { isLoading } = useOperationDocumentExchange();
  useOperationDocumentExchangeWebSocket();

  if (isLoading || isPrivateFilesLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <article>
      <InquiryDetailsHeader />
      <RequestsForCustomer />
      <ProcessSpecificFiles />
      <InternalFiles />
      <AdditionalFilesFromCustomer />
      <FilesForCustomer />
      <FilesToCompeon />
      <PrivateFilesForCustomer />
      <AssessmentFiles />
    </article>
  );
};
