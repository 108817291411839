import React from 'react';

import { useIntl } from 'react-intl';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  CONTRACTOR_COMPANY_INFORMATION,
} from 'modules/Inquiry/Form/formFields';
import ProfileIndustry from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import AdditionalAssociationCompany from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/AdditionalAssociationCompany';
import AlreadyCustomer from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/AlreadyCustomer/AlreadyCustomer';
import CompanyInformationForm from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm';
import FoundingMonth from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth';
import FoundingYear from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingYear';
import LegalForm from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/LegalForm/LegalForm';
import getAnnualNetProfitOrLossClassOptions from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/SubsidiesConsidered/annualNetProfitOrLossClassOptions';
import TurnoverClass from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/TurnoverClass/TurnoverClass';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import { BfsCompletingCompanyDetails } from './BfsCompletingCompanyDetails';
import { BfsServiceCompletingCompanyDetails } from './BfsServiceCompletingCompanyDetails';
import { CrefoCompletingCompanyDetails } from './CrefoCompletingCompanyDetails';

const CompletingCompanyDetails = () => {
  const { formatMessage } = useIntl();
  const t = useTranslations();
  const showFields = useCanShowField(USER_PROFILE_LEGAL_FORM_DESCRIPTION);

  return (
    <FormSection
      name={FormSections.companyDetails}
      sectionNumber={2}
      title={t('pages.userProfile.sections.completingCompanyDetails.title')}
    >
      <CompanyInformationForm />

      <FormRow>
        <LegalForm />
      </FormRow>

      <Condition condition={() => showFields}>
        <FormRow>
          <InputWithField name={USER_PROFILE_LEGAL_FORM_DESCRIPTION} />
        </FormRow>
      </Condition>

      <ProfileIndustry />

      <FormRow>
        <TurnoverClass />
      </FormRow>
      <FormRow>
        <FoundingYear />

        <Condition condition={fieldIsValid(USER_PROFILE_FOUNDING_YEAR)}>
          <FoundingMonth />
        </Condition>
      </FormRow>
      <FormRow>
        <AlreadyCustomer />
      </FormRow>
      <Condition isBusinessCondition condition={fieldBusinessConditions.isContractor}>
        <FormRow>
          <TextAreaWithField name={CONTRACTOR_COMPANY_INFORMATION} />
        </FormRow>
      </Condition>
      <FormRow>
        <AdditionalAssociationCompany />
      </FormRow>

      <Condition isBusinessCondition condition={fieldBusinessConditions.shouldConsiderSubsidies}>
        <FormRow>
          <SelectWithField
            name={USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS}
            options={getAnnualNetProfitOrLossClassOptions(formatMessage)}
            data-testid={'select-field-' + USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS}
          />
          <YesNoRadioGroupWithField name={USER_PROFILE_NEGATIVE_EQUITY_CAPITAL} />
        </FormRow>

        <FormRow>
          <YesNoRadioGroupWithField name={USER_PROFILE_INNOVATIVE_COMPANY} />
          <YesNoRadioGroupWithField name={USER_PROFILE_ENERGY_EFFICIENT} />
        </FormRow>
      </Condition>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.onlinefactoring]: CrefoCompletingCompanyDetails,
  [InquiryType.bfs]: BfsCompletingCompanyDetails,
  [InquiryType.bfsService]: BfsServiceCompletingCompanyDetails,
  default: CompletingCompanyDetails,
});
