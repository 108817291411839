import * as React from 'react';
import { useEffect } from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import PortalPage from 'components/PortalPage';
import paths from 'constants/paths';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useToasts } from 'shared/hooks/useToasts';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { useTranslations } from 'utils/hooks/useTranslations';

import StyledSpinner from './styles';
import { RegistrationService } from '../registration.service';

const CustomerActivation: React.FC = () => {
  const history = useHistory();
  const { success, error: notifyError } = useToasts();
  const { makeCall, isPending: isLoading } = useDispatchApiCall({
    showErrorNotification: false,
  });
  const t = useTranslations();
  const token = useQueryParams('token') as string;

  useEffect(() => {
    async function confirmToken() {
      const { error } = await makeCall(RegistrationService.confirmUserInApi(token));
      if (error) {
        history.push(paths.registrationExpiredLink);
        notifyError({ description: t('pages.registration.activation.error') });
      } else {
        history.push(paths.customer.login);
        success({ description: t('pages.registration.activation.success') });
      }
    }

    confirmToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PortalPage>{isLoading ? <StyledSpinner /> : <></>}</PortalPage>;
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_REGISTRATION_ENABLED]: CustomerActivation,
  default: () => <Redirect to={paths.customer.login} />,
});
