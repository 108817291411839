import _get from 'lodash/get';

import { useLoadMarketData } from 'api/CompeonReverseApi/operation/queryHooks';
import { TMarketDataAttributes } from 'api/CompeonReverseApi/operation/types';
import { queryClient } from 'components/App';
import mapMarketValueFromApi from 'modules/EditMarketValuesReeProfitability/mapMarketValueFromApi';
import { PROFI_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { flattenObject } from 'utils/helpers';
import { deepCamelcaseKeys } from 'utils/object';

const loadMarketValueDetails = async (inquiryId: string, recordType: string) => {
  const variables = {
    inquiryId,
    recordType,
  };

  const { data }: { data: { attributes: TMarketDataAttributes } } = await queryClient.fetchQuery(
    useLoadMarketData.getKey(variables),
    useLoadMarketData.queryFn,
  );
  let editFormValues;
  if (data && recordType === PROFI_RECORD_TYPE.VALUE_API) {
    editFormValues = mapMarketValueFromApi(deepCamelcaseKeys(_get(data, 'attributes.details')));
  }

  return flattenObject(editFormValues);
};

export default loadMarketValueDetails;
