import { get as _get } from 'lodash';
import { useFormState } from 'react-final-form';

import { dzbFields, dzbFieldsWithSectionString, DzbFieldTypes } from 'dzb/inquiry/fields';
import { setMultipleKeys } from 'new/form/common/setMultipleKeys';
import { ConditionFunction, ConditionsType } from 'new/form/model';
import { parseStringBoolean } from 'utils/helpers';

const {
  companyDataPage: {
    companyInformationSection: { companyOlderThan3Years },
    startUpQuestionnaireSection: { leadershipExperienceYears, formOfLeadershipExperience },
  },
  retailerDataPage: {
    retailerInformationSection: { erpProvider },
    onlineQuestionnaireSection: {
      shareOfForeignLogistics,
      shareOfWarehouse,
      shareOfDistributionChannels,
      othersPercentage,
      zalandoPercentage,
      amazonPercentage,
      ebayPercentage,
      ownOnlineShopPercentage,
      returnRatePercentage,
      averageShoppingBasketAmount,
      ownWarehousePercentage,
      foreignWarehousePercentage,
      foreignLogisticsServiceProviderPercentage,
      foreignFulfillmentByAmazonPercentage,
    },
  },
  personalDataPage: {
    beneficialOwnersSection: {
      beneficialOwners: {
        firstName,
        lastName,
        birthPlace,
        birthDate,
        placeOfResidence,
        countryOfResidence,
        nationality,
        capitalOrVotingRightsPercentage,
      },
    },
  },
} = dzbFields;

const {
  companyDataPage: { startUpQuestionnaireSection },
} = dzbFieldsWithSectionString;

let conditions: ConditionsType<DzbFieldTypes> = {
  [erpProvider]: ({ values }) => {
    return usesErpSystem(values);
  },
  [startUpQuestionnaireSection.sectionString]: ({ values }) => {
    return showStartupQuestionnaireSection(values);
  },
};

conditions = setMultipleKeys<ConditionFunction<DzbFieldTypes>>(conditions, [
  [
    [
      shareOfForeignLogistics,
      shareOfWarehouse,
      shareOfDistributionChannels,
      othersPercentage,
      zalandoPercentage,
      amazonPercentage,
      ebayPercentage,
      ownOnlineShopPercentage,
      returnRatePercentage,
      averageShoppingBasketAmount,
      ownWarehousePercentage,
      foreignWarehousePercentage,
      foreignLogisticsServiceProviderPercentage,
      foreignFulfillmentByAmazonPercentage,
    ],
    ({ values }) => {
      return showOnlineSalesFields(values);
    },
  ],
  [
    [leadershipExperienceYears, formOfLeadershipExperience],
    ({ values }) => {
      return hasLeadershipExperience(values);
    },
  ],
  [
    [
      firstName,
      lastName,
      birthPlace,
      placeOfResidence,
      birthDate,
      countryOfResidence,
      nationality,
      capitalOrVotingRightsPercentage,
    ],
    ({ values }) => {
      return showBeneficialOwnersSection(values);
    },
  ],
  [
    [
      foreignLogisticsServiceProviderPercentage,
      foreignFulfillmentByAmazonPercentage,
      shareOfForeignLogistics,
    ],
    ({ values }) => {
      return showShareOfForeignLogistics(values);
    },
  ],
]);

function hasLeadershipExperience(values: DzbFieldTypes): boolean {
  if (values.companyDataPage.startUpQuestionnaireSection?.alreadyGainedLeadershipExperience) {
    return JSON.parse(
      values.companyDataPage.startUpQuestionnaireSection?.alreadyGainedLeadershipExperience as any,
    );
  }

  return false;
}

function usesErpSystem(values: DzbFieldTypes): boolean {
  const { enterpriseResourcePlanningSystemInUse } =
    values.retailerDataPage.retailerInformationSection;

  if (enterpriseResourcePlanningSystemInUse) {
    return JSON.parse(enterpriseResourcePlanningSystemInUse as any);
  }

  return false;
}

function showStartupQuestionnaireSection(values: DzbFieldTypes) {
  const { companyOlderThan3Years } = values.companyDataPage.companyInformationSection;
  const companyOlderThan3YearsBoolean =
    companyOlderThan3Years !== undefined ? JSON.parse(companyOlderThan3Years as any) : true;

  // this is a bit harder to understand --> a company is a startup if it is younger than 3 years
  // in the inquiry process we are asking if the company is older than 3 years so we have to negate the value
  return !companyOlderThan3YearsBoolean;
}

function showOnlineSalesFields(values: DzbFieldTypes) {
  if (values.retailerDataPage?.onlineQuestionnaireSection?.shareOfOnlineSalesPercentage) {
    const { shareOfOnlineSalesPercentage } = values.retailerDataPage.onlineQuestionnaireSection;
    return shareOfOnlineSalesPercentage > 50 && shareOfOnlineSalesPercentage <= 100;
  }

  return false;
}

export function useShowStartupQuestionnaireSection() {
  const { values } = useFormState<DzbFieldTypes>();
  return showStartupQuestionnaireSection(values);
}

export function useShowOnlineSalesFields() {
  const { values } = useFormState<DzbFieldTypes>();
  return showOnlineSalesFields(values);
}

export function showBeneficialOwnersSection(values: DzbFieldTypes) {
  const allInformationAvailable =
    values.personalDataPage.beneficialOwnersSection.allInformationAvailable;
  if (allInformationAvailable) {
    return JSON.parse(allInformationAvailable as any);
  }

  return false;
}

export function useShowBeneficialOwnersSection() {
  const { values } = useFormState<DzbFieldTypes>();

  return showBeneficialOwnersSection(values);
}

function showShareOfForeignLogistics(values: DzbFieldTypes) {
  const { foreignWarehousePercentage } = values.retailerDataPage.onlineQuestionnaireSection;
  return !!foreignWarehousePercentage;
}

export function useShareOfForeignLogistics() {
  const { values } = useFormState<DzbFieldTypes>();
  return showShareOfForeignLogistics(values);
}

export function requireLastAnnualFinancialStatementReportingDate(values: DzbFieldTypes) {
  const isCompanyOlderThan3Years = parseStringBoolean(_get(values, companyOlderThan3Years));
  return isCompanyOlderThan3Years;
}

export function useRequireLastAnnualFinancialStatementReportingDate() {
  const { values } = useFormState<DzbFieldTypes>();
  return requireLastAnnualFinancialStatementReportingDate(values);
}

export const dzbConditions = conditions;
