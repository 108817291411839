import { useSelector } from 'react-redux';

import PERMISSIONS from 'constants/user/permissions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import {
  getInquiryDetailsSelector,
  getInquiryFormTypeSelector,
} from 'store/inquiryDetails/selectors';
import { hasPermission as hasPermissionCreator, userIsBankAdvisor } from 'utils/user/conditionals';

interface IsForwardableAttributes {
  canForwardToCompeon: boolean;
  hasBankAdvisorRole: boolean;
  isBankAdvisorEnabled: boolean;
  isCompeonForwardingEnabled: boolean;
}

export const isForwardable = ({
  canForwardToCompeon,
  hasBankAdvisorRole,
  isBankAdvisorEnabled,
  isCompeonForwardingEnabled,
}: IsForwardableAttributes) => {
  const isForwardableToCompeon = isCompeonForwardingEnabled && canForwardToCompeon;
  const isForwardableForUser = !hasBankAdvisorRole || isBankAdvisorEnabled;

  return {
    isForwardable: isForwardableToCompeon || isForwardableForUser,
    isForwardableToCompeon,
    isForwardableForUser,
  };
};

export const useInquiryForwarding = () => {
  const hasBankAdvisorRole = userIsBankAdvisor();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const isCompeonForwardingEnabled = useConfig(CONFIG.IS_COMPEON_FORWARDING_ENABLED);
  const isBankAdvisorEnabled = useConfig(CONFIG.IS_BANK_ADVISOR_ENABLED);
  const { canForwardToCompeon } = useInquiryPermissions();
  const hasBeenForwardedToCompeon = inquiryDetails && inquiryDetails.wasForwardToCompeon;
  const inquiryType = useSelector(getInquiryFormTypeSelector);
  const hasPermission = hasPermissionCreator(inquiryType || InquiryType.default);
  const hasForwardToInquiryManagerPermission = hasPermission(
    PERMISSIONS.INQUIRY.FORWARD_TO_INQUIRY_MANAGER,
  );
  const hasForwardToBankAdvisorPermission = hasPermission(
    PERMISSIONS.INQUIRY.FORWARD_TO_BANK_ADVISOR,
  );
  const hasForwardToCustomerPermission = hasPermission(PERMISSIONS.INQUIRY.FORWARD_TO_CUSTOMER);

  const isForwardableResult = isForwardable({
    canForwardToCompeon,
    hasBankAdvisorRole,
    isBankAdvisorEnabled,
    isCompeonForwardingEnabled,
  });
  const { isForwardableForUser } = isForwardableResult;

  const forwardToBankAdvisorVisible = hasForwardToBankAdvisorPermission && isBankAdvisorEnabled;
  const someUserForwardingVisible =
    isForwardableForUser && (hasForwardToInquiryManagerPermission || forwardToBankAdvisorVisible);
  const forwardToCompeonVisible = hasForwardToCustomerPermission && isCompeonForwardingEnabled;
  const someCompeonForwardingVisible = !hasBeenForwardedToCompeon && forwardToCompeonVisible;
  const someForwardingVisible = someUserForwardingVisible || someCompeonForwardingVisible;

  return {
    hasBeenForwardedToCompeon,
    forwardToInquiryManagerVisible: hasForwardToInquiryManagerPermission,
    forwardToBankAdvisorVisible,
    forwardToCompeonVisible,
    someForwardingVisible,
    ...isForwardableResult,
  };
};
