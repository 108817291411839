import { mittweidaFields } from 'mittweida/inquiry/fields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

const { selectedCompanyName, loggedInUserCompanyName } =
  mittweidaFields.companyDetailPage.companyDetailsSection;

export const companyDetailsSectionValidationMap = {
  [selectedCompanyName]: fieldValidators.string().required(),
  [loggedInUserCompanyName]: fieldValidators.string().required(),
};
