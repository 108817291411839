import React from 'react';

import Spinner from 'components/Spinner';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import { useSelectFile } from 'components/UploadBlock/hooks/useSelectFile';
import { UPLOADING_STATE } from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import {
  StyledRequestBlockWrapper as Wrapper,
  StyledRequestContent as Content,
  StyledRequestTitle as Title,
  StyledRequestDescription as Description,
  UnuploadedIcon,
  StyledRequestActions as Actions,
  StyledDroppingText as DroppingText,
  StyledProgressBackground as Progress,
  StyledProgressPercent as Percent,
  StyledScanningInfo as ScanningInfo,
  StyledUploadingIcon as UploadingIcon,
  StyledIconWrapper as IconWrapper,
  StyledUploadedInfo as UploadedInfo,
  StyledUploadedIcon as UploadedIcon,
  StyledUploadedFileName as FileName,
  StyledErrorInfo as ErrorText,
  StyledErrorIcon as ErrorIcon,
} from 'components/UploadBlock/NewFileRequestBlock/styles';
import UploadDropzone from 'components/UploadBlock/UploadIdleState/UploadDropzone';
import type { UploadedFile } from 'models/FileApi.model';
import { ButtonComponent } from 'theme/components/Button';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileRequestStatusLabel from '../components/FileRequestStatusLabel';

interface IUploadAnyFile {
  url: string;
  description?: string;
  onUpload: (file: any) => void;
  isReePage?: boolean;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}

const UploadAnyFile = ({
  url,
  onUpload,
  description,
  isReePage,
  isPrivateFile,
  isAssessmentFile,
}: IUploadAnyFile) => {
  const t = useTranslations('');
  const handleSuccess = (uploadedFile: UploadedFile) => onUpload(uploadedFile);
  const { uploadProgress } = useNewUploadFile(url);

  const { onFileSelected, errorMsg, isDropping, uploadingState, setIsDropping } = useSelectFile({
    onSuccess: handleSuccess,
    url,
    isREE: isReePage,
    isPrivateFile,
    isAssessmentFile,
  });

  const isUploading = [UPLOADING_STATE.UPLOADING, UPLOADING_STATE.SCANNING].includes(
    uploadingState,
  );

  return (
    <div data-testid="upload-any-file">
      <UploadDropzone
        onFileDropping={setIsDropping}
        onFileDropped={onFileSelected}
        disabled={uploadingState !== UPLOADING_STATE.IDLE}
      >
        <Wrapper className={isDropping ? 'dropping' : uploadingState}>
          <Progress progress={uploadProgress}>
            <Percent>
              <ScanningInfo>
                <Spinner />
                {t('components.upload.scanning')}
              </ScanningInfo>
              {uploadProgress}%
            </Percent>
          </Progress>

          <IconWrapper>
            <UploadingIcon />
            <UnuploadedIcon />
            <ErrorIcon />
            <UploadedIcon />
          </IconWrapper>

          <Content>
            <Title>{t('pages.inquiryDetails.documentExchange.selectDocument')}</Title>
            {isReePage && <FileRequestStatusLabel status={'notUploaded'} />}
            <FileName>{isUploading && `${t('components.upload.uploading')}`}</FileName>
            <DroppingText>{t('components.upload.dropFilesHere')}</DroppingText>
            <Description>{description}</Description>
            <UploadedInfo>{t('components.upload.fileUploaded')}</UploadedInfo>
            <ErrorText>{errorMsg}</ErrorText>
          </Content>

          <Actions>
            <ButtonComponent leftIcon={<UploadIcon boxSize={6} display={'block'} />}>
              {t('components.upload.sendNow')}
            </ButtonComponent>
          </Actions>
        </Wrapper>
      </UploadDropzone>
    </div>
  );
};

export default UploadAnyFile;
