import React from 'react';

import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import FieldGroup from 'components/PortalFormFields/FieldGroup';
import { FIELD_KIND_TYPE } from 'modules/FormGenerator/constants';
import { getFieldComponent } from 'modules/FormGenerator/GeneratedField/constants';
import { StyledFieldWrapper } from 'modules/FormGenerator/GeneratedField/styles';
import { fieldPropType } from 'modules/FormGenerator/propTypes';

const GeneratedField = ({ field, onChange }) => {
  const Field = getFieldComponent(field);
  const error = field.hasError ? field.validationMessage : '';

  // usually we get validation as string "/.+/" therefore we need to cut the "/" slashes
  // to correctly parse it as regexp obj, in case we don't we pass the regex itself
  const validate = (newValue) => {
    if (!field.validation) {
      return true;
    }

    const regex = field.validation.startsWith('/')
      ? field.validation.slice(1).slice(0, -1)
      : field.validation.replace('\\\\', '\\');

    return new RegExp(regex).test(newValue);
  };

  const handleChange = (changeValue) => {
    if (changeValue === field.value) {
      return;
    }
    const isValid = validate(changeValue);
    onChange({
      name: field.name,
      value: changeValue,
      hasError: !isValid,
    });
  };

  if (!Field) {
    return null;
  }

  if (field.kind !== FIELD_KIND_TYPE.FIELD) {
    return (
      <StyledFieldWrapper>
        <Field field={field} />
      </StyledFieldWrapper>
    );
  }

  return (
    <StyledFieldWrapper>
      <FieldGroup label={field.label} forField={field.name} error={error}>
        <Field field={field} onChange={handleChange} />
      </FieldGroup>
    </StyledFieldWrapper>
  );
};

GeneratedField.defaultProps = {
  onChange: () => null,
};

GeneratedField.propTypes = {
  field: fieldPropType.isRequired,
  onChange: PropTypes.func,
};

const shouldRerender = (prevProps, nextProps) => {
  return _isEqual(prevProps.field, nextProps.field);
};

export default React.memo(GeneratedField, shouldRerender);
