const currentYear = new Date().getUTCFullYear();

export const FOUNDING_MONTH_PAST_YEARS_TRESHOLD = 1;

export const COMPANY_FIELDS = {
  legalForm: 'legal-form',
  turnoverClass: 'turnover-class',
  foundingYear: 'founding-year',
  alreadyCustomer: 'already-customer',
  industry: 'industry',
  foundingMonth: 'founding-month',
  incomeSurplus: 'revenue-surplus-account',
};

export const getSelectedLabelForOptions = (options, selectedValue, formatMessage) => {
  const optionsArray = formatMessage ? options(formatMessage) : options;
  const option = optionsArray?.find(({ value }) => value === selectedValue);

  return option && option?.label;
};

export const shouldShowFoundingMonth = (foundingYear) =>
  currentYear - foundingYear <= FOUNDING_MONTH_PAST_YEARS_TRESHOLD;
