import React, { useMemo } from 'react';

import paths from 'constants/paths';
import { RLLComputedProfitabilityPresales } from 'models/types/Profitability.type';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getData, getHeaders } from './module';
import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import useEditFormConfig from '../../../../useEditFormConfig';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';

interface PresalesProps {
  inquiryId: string;
  presales: RLLComputedProfitabilityPresales;
}

export const Presales: React.FC<PresalesProps> = ({ inquiryId, presales }) => {
  const { lendingValues } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations();
  const tPage = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.salesInPresalePhase',
  );
  const { hasRoleBankAdvisor, hasRoleRealEstateExpert } = useRoles();

  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(() => getData(t, presales), [t, presales]);

  const { onEdit } = useEditFormConfig({
    pathToEdit: lendingValues(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.PRESALES_DATA,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.PRESALES_DATA](),
  });

  return (
    <AccordionComponent headingLevel="h3" mt={8} title={tPage('title')} defaultIndex={[0]}>
      <TableCardWithEdit
        canEdit={hasRoleBankAdvisor || hasRoleRealEstateExpert}
        tableHeaders={headers}
        tableData={data}
        onEdit={onEdit}
        componentName="evaluation"
      />
    </AccordionComponent>
  );
};
