import { IBlackWhiteReport } from 'models/CompanyDetails.model';

export const useBlackWhiteReport = (report?: IBlackWhiteReport) => {
  if (report?.riskJudgement.color === 'white') {
    return { colors: ['trafficLight.green', 'trafficLight.none'] };
  }
  if (report?.riskJudgement.color === 'black') {
    return { colors: ['trafficLight.none', 'trafficLight.red'] };
  }
  return { colors: ['trafficLight.none', 'trafficLight.none'] };
};
