import { get as _get } from 'lodash';
import { useFormState } from 'react-final-form';

import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';

import { isLoggedInUser } from './user/conditionals';

export const hasExistingCompany = (companyName: string) => {
  return (
    !!companyName &&
    companyName !== COMPANY_DETAILS_COMPANY__NOT_FOUND &&
    companyName !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  );
};

export const useGetValidCompanyNameFieldName = <FieldTypes>(
  loggedInUserCompanyNameFieldName: string,
  selectedCompanyNameFieldName: string,
) => {
  const { values } = useFormState<FieldTypes>();
  return getValidCompanyNameFieldName(
    loggedInUserCompanyNameFieldName,
    selectedCompanyNameFieldName,
    values,
  );
};

// Get the correct company name field name depending on the existing user/new company/existing company logic
export const getValidCompanyNameFieldName = <FieldTypes>(
  loggedInUserCompanyNameFieldName: string,
  selectedCompanyNameFieldName: string,
  values: FieldTypes,
) => {
  if (isLoggedInUser()) {
    const loggedInUserCompanyName = _get(values, loggedInUserCompanyNameFieldName);
    return loggedInUserCompanyName === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
      ? selectedCompanyNameFieldName
      : loggedInUserCompanyNameFieldName;
  }
  return selectedCompanyNameFieldName;
};
