import React from 'react';

import env from '@beam-australia/react-env';
import { Form as FinalForm } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import endpoints from 'api/CompeonOAuthApi/endpoints';
import { CheckboxWithField } from 'components/Checkbox';
import { ErrorText, Text } from 'components/Text';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { ApiActionResponseModel } from 'models/ApiActionResponse.model';
import { abortForwardingToCompeonAction } from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/service';
import {
  StyledConsentDescription as Description,
  StyledButtonsWrapper as Actions,
  StyledSection as Section,
} from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/styles';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { EnvironmentVariable } from 'utils/environment';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

function getCompeonOAuthUrl(inquiryId?: string | null) {
  return (
    env(EnvironmentVariable.COMPEON_OAUTH_API_URL) +
    endpoints.AUTHORIZATION.compose({
      query: {
        client_id: env(EnvironmentVariable.COMPEON_OAUTH_CLIENT_ID),
        response_type: 'code',
        redirect_uri: `${window.location.origin}${paths.customer.oauth}`,
        state: JSON.stringify({
          params: {
            inquiryId,
            destinationUrl: window.location.href,
            redirectUri: `${window.location.origin}${paths.customer.oauth}`,
          },
        }),
      },
    })
  );
}

const ForwardToCompeonExistingCustomer: React.FC = () => {
  const inquiryId = useSelector(getInquiryIdSelector);

  const dispatch = useDispatch();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  const { error, isPending, makeCall } = useDispatchApiCall();

  const { required } = useFieldValidators();
  const t = useTranslations('pages.inquiryDetails.additionalFinancingPartners');

  const mapAndSaveResponse = ({ payload }: ApiActionResponseModel) =>
    dispatch(mapInquiryDetailsApiResponseAction(payload.data));

  const abortForwarding = () =>
    makeCall(abortForwardingToCompeonAction(inquiryId), mapAndSaveResponse);

  /*
   * We don't want to rely on external API in e2e, so data-compeon-auth-url
   * is passed to the form below and checked in tests. For that reason below method
   * is untested yet.
   */
  const handleFormSubmit = () => {
    window.location.href = getCompeonOAuthUrl(inquiryId);
  };

  return (
    <Section>
      <Text>{t('heading')}</Text>
      <Description>
        <p>{t('existingUserInformation', { bankName: partnerName })}</p>
      </Description>
      <FinalForm
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, invalid }) => (
          <form
            onSubmit={handleSubmit}
            data-testid="compeon-outh-form"
            data-compeon-auth-url={getCompeonOAuthUrl(inquiryId)}
          >
            <CheckboxWithField
              name="consentCheck"
              data-testid="give-consent"
              validate={required}
              small
              text={
                <Text inactive>
                  {t('label', {
                    termsAndConditions: <b>{t('consent.termsAndConditions')}</b>,
                    privacyPolicy: <b>{t('consent.privacyPolicy')}</b>,
                  })}
                </Text>
              }
            />

            <Actions>
              <ButtonComponent
                data-testid="offer-accept"
                disabled={invalid}
                isLoading={isPending}
                mr={4}
                type="submit"
              >
                {t('offerAccept')}
              </ButtonComponent>

              <ButtonComponent isLoading={isPending} onClick={abortForwarding} variant="tertiary">
                {t('offerDecline')}
              </ButtonComponent>
            </Actions>
            {error && <ErrorText>{error.message}</ErrorText>}
          </form>
        )}
      />
    </Section>
  );
};

export default ForwardToCompeonExistingCustomer;
