import { mmvBeneficialOwnerValidationMap } from 'mmv/inquiry/steps/customerData/sections/beneficialOwnerSection/fieldValidation';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidateSectionFunction } from 'new/form/useValidateFields';

export const beneficialOwnerSectionValidations: ValidateSectionFunction<InquiryType.mmv> = (
  form,
  values,
) => {
  const { error } = mmvBeneficialOwnerValidationMap.validate(values);
  return error === undefined;
};
