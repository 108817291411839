import { useFormConfig } from 'config/formConfig/hooks';
import { PURPOSE_KIND, PURPOSE_KIND__OVERDRAFT_LIQUIDITY } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useFormValues } from 'store/hooks/useFormValues';

const useAmountTranslations = () => {
  const formValues = useFormValues();
  const selectedPurposeKind = formValues[PURPOSE_KIND];
  const { selectedInquiryType } = useFormConfig();

  let formSectionTitle = 'pages.financingNeed.sections.amount.title';
  let financingAmountCaption = 'pages.financingNeed.sections.amount.fields.financingAmount.caption';
  if (selectedInquiryType === InquiryType.bfs) {
    financingAmountCaption = 'pages.financingNeed.sections.purpose.fields.purposeKind.bfsCaption';
    if (selectedPurposeKind === PURPOSE_KIND__OVERDRAFT_LIQUIDITY) {
      formSectionTitle = 'pages.financingNeed.sections.amount.bfsLiquidityTitle';
      financingAmountCaption =
        'pages.financingNeed.sections.purpose.fields.purposeKind.bfsLiquidityCaption';
    }
  }
  return {
    formSectionTitle,
    financingAmountCaption,
  };
};

export default useAmountTranslations;
