export const getFieldProps = ({
  field,
  caption,
  optional,
  prefix,
  t,
}: {
  field: string;
  t: any;
  caption: string;
  optional?: boolean;
  prefix?: string;
}): {
  name: string;
  caption?: string;
  optional?: boolean;
} => {
  let props = {
    name: prefix ? `${prefix}.${field}` : field,
    optional,
  };

  if (prefix) {
    return props;
  }

  return {
    ...props,
    caption: t(caption),
  };
};
