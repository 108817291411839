import { associationInformationSectionLabelsMap } from 'dzb/inquiry/steps/companyData/sections/associationInformationSection/labels';
import { companyInformationSectionLabelsMap } from 'dzb/inquiry/steps/companyData/sections/companyInformationSection/labels';
import { directDebitMandateSectionLabelsMap } from 'dzb/inquiry/steps/companyData/sections/directDebitMandateSection/labels';
import { LabelsMap } from 'new/form/common/types';

import { startUpQuestionnaireSectionLabelsMap } from './sections/startUpQuestionnaireSection/labels';

export const dzbCompanyDataLabelsMap: LabelsMap = {
  ...associationInformationSectionLabelsMap,
  ...companyInformationSectionLabelsMap,
  ...directDebitMandateSectionLabelsMap,
  ...startUpQuestionnaireSectionLabelsMap,
};
