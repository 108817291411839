import React from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FilesToCompeon from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToCompeon/FilesToCompeon';
import ProcessSpecificFiles from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/ProcessSpecificFiles';
import AdditionalFilesFromCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/AdditionalFilesFromCustomer/AdditionalFilesFromCustomer';
import AssessmentFiles from 'pages/operationPortal/InquiryDetails/DocumentExchange/AssessmentFiles/AssessmentFiles';
import FilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/FilesForCustomer/FilesForCustomer';
import { InternalFiles } from 'pages/operationPortal/InquiryDetails/DocumentExchange/InternalFiles';
import RequestsForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/RequestsForCustomer';
import { useFetchAssessmentFiles } from 'shared/documentExchange/useFetchInquiryFiles';

import {
  useOperationDocumentExchangeWebSocket,
  useOperationDocumentExchange,
} from './useOperationDocumentExchange';

export const RLLOperationDocumentExchange = () => {
  const assessmentFiles = useFetchAssessmentFiles();
  const { isLoading } = useOperationDocumentExchange();
  useOperationDocumentExchangeWebSocket();

  if (isLoading || assessmentFiles?.isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <article>
      <InquiryDetailsHeader />
      <RequestsForCustomer />
      <ProcessSpecificFiles />
      <InternalFiles />
      <AdditionalFilesFromCustomer />
      <FilesForCustomer />
      <FilesToCompeon />
      <AssessmentFiles />
    </article>
  );
};
