import React, { useEffect } from 'react';

import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import useAvailableBankAdvisors from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor/useAvailableBankAdvisorsHook';
import { inquiryDetailsBankAdvisorSelector } from 'store/inquiryDetails/selectors';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { required } from 'utils/validators';

const BANK_ADVISOR_FIELD = 'bankAdvisor';

interface Props {
  onSubmit: (bankAdvisorId: string) => void;
}

interface FormValues {
  [BANK_ADVISOR_FIELD]: string | null | undefined;
}

const ModalContentWithoutOrganizationalUnit = ({ onSubmit }: Props) => {
  const { formatMessage } = useIntl();
  const bankAdvisor = useSelector(inquiryDetailsBankAdvisorSelector);
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.forwardToBankAdvisor');
  const { availableBankAdvisors, fetchAvailableBankAdvisors } = useAvailableBankAdvisors();

  const formattedAvailableBankAdvisors = availableBankAdvisors.map((item) => ({
    label: `${item.attributes.first_name} ${item.attributes.last_name}`,
    value: item.id,
  }));

  useEffect(() => {
    fetchAvailableBankAdvisors();
  }, [fetchAvailableBankAdvisors]);

  const handleSubmitForm = (formValues: FormValues) => {
    const { [BANK_ADVISOR_FIELD]: bankAdvisorId } = formValues;
    if (bankAdvisorId) {
      onSubmit(bankAdvisorId);
    }
  };

  return (
    <>
      <TextComponent mb={8}>{t('modalDescription')}</TextComponent>
      <Form
        onSubmit={handleSubmitForm}
        initialValues={{
          [BANK_ADVISOR_FIELD]: bankAdvisor?.id,
        }}
        render={({ handleSubmit }) => (
          <form id="form-forward-to-bank-advisor" onSubmit={handleSubmit}>
            <FormRow>
              <SelectWithField
                name={BANK_ADVISOR_FIELD}
                placeholder={formatMessage({ id: 'placeholders.pleaseChoose' })}
                validate={required(formatMessage({ id: 'errors.required' }))}
                options={formattedAvailableBankAdvisors}
                caption={t('captionBankadvisor')}
                data-testid={'select-field-' + BANK_ADVISOR_FIELD}
              />
            </FormRow>
          </form>
        )}
      />
    </>
  );
};

export default ModalContentWithoutOrganizationalUnit;
