import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -2rem;
  list-style: none;

  ${mqMedium`
    flex-direction: row;
  `};
`;

export const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  ${mqMedium`
    padding: 0 2rem;

    &:not(:last-child) {
      margin-bottom: 0;
      border-right: 1px solid ${(props) => props.theme.colors.border.darkGrey};
    }
  `};

  a {
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.colors.text.secondary};

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;
