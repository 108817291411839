import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ProjectFinancingIcon = (props: IconProps) => {
  return (
    <Icon
      width="90"
      height="65"
      viewBox="0 0 89.13 64.08"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g fill="none" stroke="#273133" strokeMiterlimit="10" strokeWidth="2">
        <path
          stroke="#273133"
          d="M38.48 54.12c-5.17 0-27.16.26-29.94-.11a9.49 9.49 0 01-7.6-8.92c0-4.84.11-34.46.33-37.21S4.13-.49 15.91 1.27v41.95s-10.46-4.07-14 6.05"
        />
        <path
          stroke="#273133"
          d="M15.8 8.65h54.72v2.97M58.19 26.16C55.1 27.7 39 38.52 42.33 56.65c.66 3.63 5.51 6.5 9.8 6.5S73.38 63 76.24 63 86.7 62.16 88 55.33 82.88 31.4 70.52 26.16c-3.64-1.55-9.25-1.55-12.33 0z"
        />
        <path
          stroke="#273133"
          d="M68.87 25.16c2.09-2.31 7.92-10 2.2-10.23s-10 2.55-16.74 1.32c-1.21-.22 4 2.64 5.18 8.58M62.63 24.42A21.81 21.81 0 0062 21M66 24.79a34.54 34.54 0 012-6.69M37.16 47.95l-13.87.17-.33-31.05 26.42.17M23.62 33.01h11.81M35.09 27.89V17.24"
        />
        <path
          stroke="#273133"
          d="M70.24 51.52a9.45 9.45 0 01-4 .9c-4.89 0-8.85-3.68-8.85-8.21s4-8.21 8.85-8.21a9.38 9.38 0 014.41 1.09M55.39 43.11h9.33M55.39 46.6h9.33"
        />
      </g>
    </Icon>
  );
};

export default ProjectFinancingIcon;
