import React from 'react';

import { Flex } from '@chakra-ui/react';

interface TableFooterProps {
  children: React.ReactNode;
}

export const TableFooter = ({ children }: TableFooterProps) => (
  <Flex
    flexDirection={{ base: 'column-reverse', md: 'row' }}
    alignItems="center"
    justifyContent="space-between"
    marginTop={12}
  >
    {children}
  </Flex>
);

export default TableFooter;
