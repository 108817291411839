import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { PhoneNumberField } from 'components/PhoneNumberField';
import { dzbFields } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { firstName, lastName, email, phoneNumber } = dzbFields.personalDataPage.contactPersonSection;

export const ContactPersonSection = () => {
  const t = useTranslations();
  const { title } = translations.inquiryType.dzb.pages.personalData.sections.contactPerson;

  return (
    <FormSection title={t(title)} sectionNumber={1}>
      <FormRow>
        <InputWithField name={firstName} />
        <InputWithField name={lastName} />
      </FormRow>
      <FormRow>
        <InputWithField disabled name={email} />
        <PhoneNumberField name={phoneNumber} optional />
      </FormRow>
    </FormSection>
  );
};
