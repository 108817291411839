import { mmvFields } from 'mmv/inquiry/fields';
import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

const fields = mmvFields;
const translations =
  translationsObject.inquiryType.mmv.steps.customerDataPage.sections
    .authorizationOfRepresentationSection.fields;

export const authorizationOfRepresentationSectionLabelsMap: LabelsMap = {
  [fields['user-representation']]: translations.authorizedToRepresent.caption,
  [fields['user-shared-representation']]: translations.authorizedToRepresentAlone.caption,
  // representative translations
  [fields['representative-gender']]: translations.representative.fields.gender.caption,
  [fields['representative-first-name']]: translations.representative.fields.firstName.caption,
  [fields['representative-last-name']]: translations.representative.fields.lastName.caption,
  [fields['representative-email']]: translations.representative.fields.email.caption,
  [fields['representative-phone-number']]: translations.representative.fields.phoneNumber.caption,
  [fields['representative-birthdate']]: translations.representative.fields.birthdate.caption,
};
