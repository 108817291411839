import {
  OFFER_FACTORING_LINE,
  OFFER_FACTORING_FEE,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

export const mapKfinanzToApi: InquiryFormMapper = (allFields) => ({
  [OFFER_FACTORING_LINE]: allFields[OFFER_FACTORING_LINE],
  [OFFER_FACTORING_FEE]: allFields[OFFER_FACTORING_FEE],
  [OFFER_PAYOUT_RATIO]: allFields[OFFER_PAYOUT_RATIO],
});
