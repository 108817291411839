import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['th', 'tr', 'td', 'trHeadline']);

const FinancialBalanceSheet = helpers.defineMultiStyleConfig({
  baseStyle: {
    th: {
      p: '4',
      borderBottom: '2px solid',
      borderColor: 'border.darkGrey',
      textStyle: 'sizeM',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      letterSpacing: 'inherit',
      textTransform: 'inherit',
      color: 'text.tertiary',
      textAlign: 'center',

      '&:nth-of-type(4)': {
        borderLeft: `solid 2px`,
        borderLeftColor: 'border.darkGrey',
        textAlign: 'left',
      },

      _first: {
        textAlign: 'left',
      },
    },
    tr: {
      _hover: {
        backgroundColor: 'brand.default_L93',

        td: {
          backgroundColor: 'brand.default_L93',
        },
      },

      _last: {
        backgroundColor: 'background.white',
        borderBottom: '2px solid',
        borderTop: '2px solid',
        borderColor: 'border.darkGrey',

        _hover: {
          backgroundColor: 'brand.default_L93',
        },

        td: {
          _first: {
            paddingLeft: '16px',
          },
          '&:nth-of-type(4)': {
            paddingLeft: '16px',
          },
        },
      },

      _first: {
        borderBottom: 'none',
        borderTop: 'none',
      },
    },
    td: {
      borderBottom: '1px solid',
      borderColor: 'border.lightGrey',
      textStyle: 'sizeM',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      color: 'text.secondary',
      p: 4,
      paddingLeft: '32px',

      '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(6)': {
        paddingLeft: '16px',
      },

      '&:nth-of-type(4)': {
        borderLeft: `solid 2px`,
        borderLeftColor: 'border.darkGrey',
      },
    },
  },
});

export default FinancialBalanceSheet;
