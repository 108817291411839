import _get from 'lodash/get';

import {
  CURRENT_USAGE_OPERATING_COSTS,
  CURRENT_USAGE_OPERATING_COSTS_ESTIMATION,
  CURRENT_USAGE_OPERATING_COSTS_MINIMUM,
  FUTURE_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_OPERATING_COSTS_ESTIMATION,
  FUTURE_USAGE_OPERATING_COSTS_MINIMUM,
  FUTURE_USAGE_VACANCY,
  FUTURE_USAGE_REMAINING_USAGE_DURATION,
  FUTURE_USAGE_PRESALES_RATE,
  FUTURE_USAGE_VISITED_DATE,
  FUTURE_USAGE_VISITED_BY,
} from 'modules/Inquiry/Form/formFields';

const mapBankValuesAdditionalInformationFromApi = (response: unknown) => {
  const current = _get(response, 'attributes.details.bank_values_additional_information.current');
  const future = _get(response, 'attributes.details.bank_values_additional_information.future');

  const bankValue = {
    [CURRENT_USAGE_OPERATING_COSTS]: _get(current, 'operation_costs') || '0,00',
    [CURRENT_USAGE_OPERATING_COSTS_ESTIMATION]:
      _get(current, 'operating_costs_estimation') || '0,00',
    [CURRENT_USAGE_OPERATING_COSTS_MINIMUM]: _get(current, 'operating_costs_minimum') || '0,00',
    [FUTURE_USAGE_OPERATING_COSTS]: _get(future, 'operating_costs') || '0,00',
    [FUTURE_USAGE_OPERATING_COSTS_ESTIMATION]: _get(future, 'operating_costs_estimation') || '0,00',
    [FUTURE_USAGE_OPERATING_COSTS_MINIMUM]: _get(future, 'operating_costs_minimum') || '0,00',
    [FUTURE_USAGE_VACANCY]: _get(future, 'vacancy') || null,
    [FUTURE_USAGE_REMAINING_USAGE_DURATION]: _get(future, 'remaining_usage_duration') || 0,
    [FUTURE_USAGE_PRESALES_RATE]: _get(future, 'presales_rate') || '0,00',
    [FUTURE_USAGE_VISITED_DATE]: _get(future, 'visited_date') || null,
    [FUTURE_USAGE_VISITED_BY]: _get(future, 'visited_by') || null,
  };

  return bankValue;
};

export default mapBankValuesAdditionalInformationFromApi;
