export enum AccessTokenType {
  Bearer = 'Bearer',
}

export enum AccessTokenScope {
  customer = 'customer',
  portal_user = 'portal_user',
}

export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_EXPIRATION_KEY = 'authExpiration';
export const AUTH_REFRESH_TOKEN_KEY = 'authRefreshToken';

export type QuerySuccessCallback<R, V> =
  | ((data: R, variables: V | undefined, context: unknown) => unknown)
  | undefined;
export type QueryErrorCallback<V> =
  | ((error: unknown, variables: V, context: unknown) => unknown)
  | undefined;

export type MutationSuccessCallback<R, V> =
  | ((data: R, variables: V | undefined, context: unknown) => void)
  | undefined;
export type MutationErrorCallback<V> =
  | ((error: unknown, variables: V, context: unknown) => void)
  | undefined;
