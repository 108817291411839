import React from 'react';

import PropTypes from 'prop-types';

import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import PurposeKindList from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/PurposeKindList/PurposeKindList';
import { FinalFormInputPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

const PurposeKindAdapter = ({ input, purposes = [], errorMessage, onChange }) => {
  const handlePurposeSelect = (purposeKey) => {
    onChange(purposeKey);
  };
  return (
    <>
      <PurposeKindList
        name={input.name}
        purposes={purposes}
        onSelectedPurpose={handlePurposeSelect}
        selectedPurpose={input.value}
      />
      <FieldError message={errorMessage} />
    </>
  );
};

PurposeKindAdapter.propTypes = {
  input: FinalFormInputPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  purposes: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PurposeKindAdapter.defaultProps = {
  errorMessage: '',
};

export const PurposeKindAdapterWithField = withFormField(PurposeKindAdapter);
