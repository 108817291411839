import React from 'react';

import FormRow from 'components/FormRow';
import { TextAreaWithField } from 'components/TextArea';
import {
  QUICK_CHECK_INFO_ON_COLLATERAL,
  QUICK_CHECK_PROJECT_DESCRIPTION,
  QUICK_CHECK_WITHDRAWAL_REQUIREMENTS,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProjectInformationSection = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck.sections.projectInformation');

  const fields = [
    {
      name: QUICK_CHECK_PROJECT_DESCRIPTION,
      placeholder: t('fields.projectDescription.placeholder'),
    },
    {
      name: QUICK_CHECK_INFO_ON_COLLATERAL,
      placeholder: t('fields.informationOnCollateral.placeholder'),
    },
    {
      name: QUICK_CHECK_WITHDRAWAL_REQUIREMENTS,
      placeholder: t('fields.withdrawalRequirements.placeholder'),
    },
  ];

  return (
    <>
      {fields.map(({ name, placeholder }) => (
        <FormRow key={name}>
          <TextAreaWithField name={name} placeholder={placeholder} data-testid={name} />
        </FormRow>
      ))}
    </>
  );
};

export default ProjectInformationSection;
