import React, { useEffect } from 'react';

import { Box, Divider, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Content from 'components/FormSection/Content';
import Section from 'components/FormSection/Section';
import Title from 'components/FormSection/Title';
import InquiryTypeCondition from 'components/InquiryTypeCondition';
import Text from 'components/Text/Text';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { offerCalculationAction } from 'store/inquiryDetails/actions';
import {
  getIndicativeOfferCalculations,
  getInquiryDetailsSelector,
  getInquiryLane,
} from 'store/inquiryDetails/selectors';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListDescription,
} from 'theme/components/DescriptionList';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const OfferCalculations = () => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const inquiryLane = useSelector(getInquiryLane);
  const isLead = inquiryLane === InquiryLane.lead;
  const { makeCall } = useDispatchApiCall();
  const calculations = useSelector(getIndicativeOfferCalculations);
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const indicativeOfferCalculations = inquiryDetails?.dashboardActions?.indicativeOfferCalculations;

  useEffect(() => {
    if (isLead && id && !indicativeOfferCalculations) {
      makeCall(offerCalculationAction(id));
    }
  }, [indicativeOfferCalculations, isLead, id, makeCall]);

  return (
    <>
      <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
      <Section>
        <Title>
          <Text as="span">
            {t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.conditions')}
          </Text>
        </Title>

        <Content>
          <Grid templateColumns={['repeat(1, 1fr)', null, null, '1fr 1fr 1fr']} gap={6}>
            {!!calculations &&
              (Object.keys(calculations) as (keyof typeof calculations)[]).map((key) => (
                <GridItem key={key}>
                  <Box
                    border="1px"
                    borderColor="border.lightGrey"
                    p="8"
                    bgColor="background.white"
                    boxShadow="default"
                  >
                    <DescriptionList>
                      <DescriptionListTerm display="flex" alignItems="center">
                        {t(`pages.inquiryDetails.dashboard.actions.nonBindingOffer.${key}`)}
                      </DescriptionListTerm>
                      <DescriptionListDescription>{calculations[key]}</DescriptionListDescription>
                    </DescriptionList>
                  </Box>
                </GridItem>
              ))}
          </Grid>
        </Content>
      </Section>
    </>
  );
};

const DisplayCondition = () => {
  return (
    <InquiryTypeCondition
      portal
      cases={{
        onlinefactoring: <OfferCalculations />,
        bfs_service: <OfferCalculations />,
        default: null,
      }}
    />
  );
};

export default DisplayCondition;
