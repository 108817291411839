import React from 'react';

import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import { HiddenInputWithField } from 'components/HiddenInput';
import LogoutButton from 'components/LogoutButton';
import {
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
} from 'modules/Inquiry/Form/formFields';
import BirthdateField from 'modules/Inquiry/Form/Steps/UserProfile/Birthdate';
import EmailStaticField from 'modules/Inquiry/Form/Steps/UserProfile/Email/EmailStatic';
import NameStaticField from 'modules/Inquiry/Form/Steps/UserProfile/Name/NameStatic';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

const ForLoggedInUser = () => {
  const editMode = Boolean(useSelector(getInquiryIdSelector));

  return (
    <>
      <FormRow alignItems="flex-start">
        <NameStaticField />
        <EmailStaticField />
        {!editMode && <LogoutButton />}
        <HiddenInputWithField name={COMPANY_DETAILS_GENDER} />
        <HiddenInputWithField name={COMPANY_DETAILS_EMAIL} />
        <HiddenInputWithField name={COMPANY_DETAILS_FIRST_NAME} />
        <HiddenInputWithField name={COMPANY_DETAILS_LAST_NAME} />
      </FormRow>
      <BirthdateField showSeparator />
    </>
  );
};

export default ForLoggedInUser;
