import { mittweidaFields } from 'mittweida/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

export const projectCompositionSectionLabels: LabelsMap = {
  [mittweidaFields.financingNeedPage.projectCompositionSection.totalInvestment]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .totalInvestment.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.totalInvestmentInBuyingPhase]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .totalInvestmentInBuyingPhase.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownFunds]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .ownFunds.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownFundsInBuyingPhase]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .ownFundsInBuyingPhase.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.mezzanineCapital]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .mezzanineCapital.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.subordinatedCapital]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .subordinatedCapital.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.ownWork]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .ownWork.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.financeWholeProject]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .financingWholeProject.caption,
  [mittweidaFields.financingNeedPage.projectCompositionSection.financingAmount]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields
      .financingAmount.caption,
};
