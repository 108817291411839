import React from 'react';

import CustomerNavigation from 'modules/Header/PortalNavigation/CustomerNavigation';
import OperationNavigation from 'modules/Header/PortalNavigation/OperationNavigation';
import { userIsAgent } from 'utils/user/conditionals';

const PortalNavigation = () => {
  const isAgent = userIsAgent();
  if (isAgent) {
    return <OperationNavigation />;
  }
  return <CustomerNavigation />;
};

export default PortalNavigation;
