import React from 'react';

import FormSection from 'components/FormSection';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  LOAN_FIXED_INTEREST_RATE,
  LOAN_FIXED_INTEREST_RATE__FIXED,
  LOAN_TYPE,
  LOAN_TYPE__AMORTISABLE,
  LOAN_TERM,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import LoanTypeFields from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import LoanSummary from './LoanSummary';

export const loanConditions = {
  hasSelectedAmmortisable: fieldHasValue(LOAN_TYPE, LOAN_TYPE__AMORTISABLE),
  hasSelectedFixedRate: fieldHasValue(LOAN_FIXED_INTEREST_RATE, LOAN_FIXED_INTEREST_RATE__FIXED),
};

export const loanFields = [
  LOAN_TYPE,
  LOAN_TERM,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_DESCRIPTION,
];

const Loan = () => {
  const t = useTranslations();

  return (
    <FormSection name={FormSections.loan} title={t('pages.requestDetails.sections.loan.title')}>
      <LoanSummary />
      <LoanTypeFields />
      <InterestedInInsurance />
    </FormSection>
  );
};

export default Loan;
