import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getDzbCountryOptions = (formatMessage: FormatMessage): SelectOption[] => {
  const { de, fr } =
    translations.inquiryType.dzb.pages.companyData.sections.companyInformation.fields.country
      .options;

  const options: Array<{ id: string; value: string }> = [
    {
      id: de,
      value: 'de',
    },
    {
      id: fr,
      value: 'fr',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
