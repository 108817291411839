import React from 'react';

import _isNil from 'lodash/isNil';
import { string, number } from 'prop-types';
import { useIntl } from 'react-intl';

import { Icon } from 'components/SuccessPageHeading/styles';
import { Wrapper, TextWrapper, Content } from 'components/SuccessPageStep/styles';
import { Text } from 'components/Text';
import { usePartnerConfig } from 'config/partner/hooks';
import { useTranslations } from 'utils/hooks/useTranslations';

const SuccessPageSteps = ({ src, id, indexNumber, headingId }) => {
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  const { formatMessage } = useIntl();
  const t = useTranslations();

  const withHeading = !_isNil(headingId);

  return (
    <Wrapper>
      <Icon iconList src={src} alt="" />
      <TextWrapper>
        <Text className="section-number" active semibold={withHeading}>
          {indexNumber}.
        </Text>
        <Content>
          {withHeading && (
            <Text active semibold={withHeading}>
              {t(headingId)}
            </Text>
          )}
          <Text active>{formatMessage({ id }, { partnerName })}</Text>
        </Content>
      </TextWrapper>
    </Wrapper>
  );
};

SuccessPageSteps.propTypes = {
  src: string.isRequired,
  id: string.isRequired,
  indexNumber: number.isRequired,
  headingId: string,
};

SuccessPageSteps.defaultProps = {
  headingId: null,
};

export default SuccessPageSteps;
