import styled from 'styled-components/macro';

import { inputStyles, fixInputWidthAndHeight, inputPaddingRight } from 'styles/form';

const InputWrapper = styled.div`
  width: 100%;

  input {
    ${inputStyles()};
    ${fixInputWidthAndHeight()};
    ${inputPaddingRight()};
  }
`;

export default InputWrapper;
