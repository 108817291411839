import { useEffect } from 'react';

import {
  ACCOUNT_UNLOCK_QUERY_KEY,
  AccountUnlockStatus,
} from 'pages/customerPortal/AccountUnlock/AcountUnlock.model';
import { useToasts } from 'shared/hooks/useToasts';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useHandleAccountUnlock = () => {
  const { error, success } = useToasts();
  const unlockStatus = useQueryParams(ACCOUNT_UNLOCK_QUERY_KEY) as AccountUnlockStatus;
  const t = useTranslations();

  useEffect(() => {
    if (unlockStatus) {
      const handleUnlockStatus: { [status in AccountUnlockStatus]: Function } = {
        [AccountUnlockStatus.success]: () =>
          success({ description: t('pages.loginPage.accountUnlocked') }),
        [AccountUnlockStatus.fail]: () =>
          error({ description: t('pages.loginPage.accountUnlockingFailed') }),
      };

      if (handleUnlockStatus[unlockStatus]) {
        handleUnlockStatus[unlockStatus]();
      }
    }
  }, [error, success, t, unlockStatus]);
};
