import { get as _get } from 'lodash';
import { useFormState } from 'react-final-form';

import { StaticCompany } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm/StaticCompanyInformation';

export const useGetCompany = <FieldTypes>(config: StaticCompany) => {
  const { values } = useFormState<FieldTypes>();

  const company = {} as StaticCompany;
  Object.entries(config).forEach(([key, fieldName]) => {
    company[key as keyof StaticCompany] = _get(values, fieldName);
  });

  return company;
};
