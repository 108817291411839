import { useSelector } from 'react-redux';

import {
  IInquiryFinancingDetailsFields,
  IUsageSpaceObject,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__OBJECT_USAGE,
  CONTROL_OBJECT_DATA_STEP,
  FUTURE_USAGE_STEP,
  CURRENT_USAGE_STEP,
  PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
  PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
  PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
  MITTWEIDA_CONTROL_OBJECT_DATA_STEP,
  PROJECT_FINANCING_QUALITY_FURNISHING,
  PROJECT_FINANCING_QUALITY_CONDITION,
  PROJECT_FINANCING_YEAR_OF_MODERNIZATION,
  PROJECT_FINANCING_YEAR_OF_CONSTRUCTIONS,
  PROJECT_FINANCING_BALCONY,
  PROJECT_FINANCING_ADDITIONAL_BATHROOM,
  MITTWEIDA_FUTURE_USAGE_STEP,
  MITTWEIDA_CURRENT_USAGE_STEP,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
} from 'modules/Inquiry/Form/formFields';

import { getPropertyDetails } from '../../../mittweida/portals/operations/store/selectors';

type ValueType = string | boolean | number;

type MarketValueStepFieldsMap = {
  [stepName: string]: string[];
};

type Result = {
  name: string;
  value: ValueType;
};

export interface MarketInformationFields {
  current: {
    commercial_space: number;
    residential_space: number;
    total_parking: number;
    total_space: number;
  };
  future: {
    commercial_space: number;
    residential_space: number;
    total_parking: number;
    total_space: number;
  };
}

// Returns true if project-financing-garages-count is greater than 0
const garageConditionTrue = (name: string, value: ValueType, stepName: string) =>
  stepName === CONTROL_OBJECT_DATA_STEP &&
  name === PROJECT_FINANCING__NUMBER_OF_GARAGES &&
  value > 0;

// Returns false if project-financing-garages-count is 0
const garageConditionFalse = (name: string, value: ValueType, stepName: string) =>
  stepName === CONTROL_OBJECT_DATA_STEP &&
  name === PROJECT_FINANCING__NUMBER_OF_GARAGES &&
  value === 0;

const controlObjectData = (
  fields: string[],
  payloadObject: IInquiryFinancingDetailsFields,
  stepName: string,
  propertyDetails: MarketInformationFields,
) => {
  const result = Object.values(fields).map((x: string) => {
    let name: string = x,
      value: ValueType = payloadObject[x];
    // "project-financing-garages-count" is true if > 0 ,false if = 0
    if (garageConditionTrue(name, value, stepName)) value = true;
    else if (garageConditionFalse(name, value, stepName)) value = false;
    if (
      name === PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS &&
      stepName === MITTWEIDA_CONTROL_OBJECT_DATA_STEP
    ) {
      value = propertyDetails?.future.total_parking > 0;
    } else value = payloadObject[x];

    return { name, value };
  });
  return result;
};

// Returns true if usage is NOT residential
const isResidentialCondition = (
  kindOfUsageKey: string,
  kindOfUsageVal: string,
  typeOfUsageKey: string,
  typeOfUsageVal: string,
) => {
  return kindOfUsageKey === kindOfUsageVal && typeOfUsageKey !== typeOfUsageVal;
};

// Returns true if usage is residential
const isnotResidentialCondition = (
  kindOfUsageKey: string,
  kindOfUsageVal: string,
  typeOfUsageKey: string,
  typeOfUsageVal: string,
) => {
  return kindOfUsageKey === kindOfUsageVal && typeOfUsageKey === typeOfUsageVal;
};

// Calculates the sum of current and future usage spaces objects from financing details
export const calculateUsageSpaceSum = (
  futureUsage: Array<IUsageSpaceObject>,
  kindOfUsage: string,
  typeOfUse: string,
) => {
  let usageSumNotResidential: number = 0,
    usageSumResidential: number = 0;
  for (let i = 0; i < futureUsage.length; i++) {
    if (
      isResidentialCondition(
        futureUsage[i][PROJECT_FINANCING_USAGE_KIND_TYPE],
        kindOfUsage,
        futureUsage[i][PROJECT_FINANCING_TYPE_OF_USAGE],
        typeOfUse,
      )
    ) {
      usageSumNotResidential += futureUsage[i][PROJECT_FINANCING_USAGE_SPACE] || 0;
    } else if (
      isnotResidentialCondition(
        futureUsage[i][PROJECT_FINANCING_USAGE_KIND_TYPE],
        kindOfUsage,
        futureUsage[i][PROJECT_FINANCING_TYPE_OF_USAGE],
        typeOfUse,
      )
    ) {
      usageSumResidential += futureUsage[i][PROJECT_FINANCING_USAGE_SPACE] || 0;
    }
  }
  return [
    { name: PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL, value: usageSumNotResidential },
    { name: PROJECT_USAGE_SPACE_SUM_RESIDENTIAL, value: usageSumResidential } || [],
  ];
};

const projectUsageTypeDataMap = (
  payloadObject: IInquiryFinancingDetailsFields,
  stepName: string,
) => {
  let result: Array<Result> = [];
  const financingUsageObjectsData = payloadObject[PROJECT_FINANCING__OBJECT_USAGE];
  // Calculates SUM for future usage type
  if (stepName === FUTURE_USAGE_STEP) {
    result = calculateUsageSpaceSum(
      financingUsageObjectsData,
      PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
      PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
    );
  }
  // Calculates SUM for current usage type
  else
    result = calculateUsageSpaceSum(
      financingUsageObjectsData,
      PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
      PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
    );
  return result;
};

export const projectUsageTypeDataMittweidaMap = (
  propertyDetails: MarketInformationFields,
  stepName: string,
) => {
  if (stepName === MITTWEIDA_FUTURE_USAGE_STEP) {
    return [
      {
        name: PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
        value: propertyDetails?.future.commercial_space,
      },
      {
        name: PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
        value: propertyDetails?.future.residential_space,
      },
    ];
  } else if (stepName === MITTWEIDA_CURRENT_USAGE_STEP) {
    return [
      {
        name: PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
        value: propertyDetails?.current.commercial_space,
      },
      {
        name: PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
        value: propertyDetails?.current.residential_space,
      },
    ];
  } else {
    return [];
  }
};

const chooseBasedOnSection = (
  fields: string[],
  financingDetailsObject: IInquiryFinancingDetailsFields,
  stepName: string,
  propertyDetails: MarketInformationFields,
) => {
  let result: Array<Result> = [];
  if (stepName === CONTROL_OBJECT_DATA_STEP || stepName === MITTWEIDA_CONTROL_OBJECT_DATA_STEP) {
    // Returns the data for "Control Object Data" step
    result = controlObjectData(fields, financingDetailsObject, stepName, propertyDetails);
  } else if (stepName === FUTURE_USAGE_STEP || stepName === CURRENT_USAGE_STEP) {
    // Returns calculated data for Future and Current usage steps
    result = projectUsageTypeDataMap(financingDetailsObject, stepName);
  } else if (
    stepName === MITTWEIDA_CURRENT_USAGE_STEP ||
    stepName === MITTWEIDA_FUTURE_USAGE_STEP
  ) {
    result = projectUsageTypeDataMittweidaMap(propertyDetails, stepName);
  }
  return result;
};

export const marketValueStepFieldsMap: MarketValueStepFieldsMap = {
  [CONTROL_OBJECT_DATA_STEP]: [
    PROJECT_FINANCING_OBJECT_ADDRESS,
    PROJECT_FINANCING_OBJECT_ZIP_CODE,
    PROJECT_FINANCING_OBJECT_CITY,
    PROJECT_FINANCING__FURNISHING_QUALITY,
    PROJECT_FINANCING__QUALITY_CONDITION,
    PROJECT_FINANCING__MODERNIZATION_YEAR,
    PROJECT_FINANCING__BUILDING_YEAR,
    PROJECT_FINANCING__BALKONY,
    PROJECT_FINANCING__GUEST_BATHROOM,
    PROJECT_FINANCING__LIFT,
    PROJECT_FINANCING__NUMBER_OF_GARAGES,
  ],
  [FUTURE_USAGE_STEP]: [
    PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
    PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
  ],
  [CURRENT_USAGE_STEP]: [
    PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
    PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
  ],
  [MITTWEIDA_CONTROL_OBJECT_DATA_STEP]: [
    PROJECT_FINANCING_OBJECT_ADDRESS,
    PROJECT_FINANCING_OBJECT_ZIP_CODE,
    PROJECT_FINANCING_OBJECT_CITY,
    PROJECT_FINANCING_LOT_SIZE,
    PROJECT_FINANCING_QUALITY_FURNISHING,
    PROJECT_FINANCING_QUALITY_CONDITION,
    PROJECT_FINANCING_YEAR_OF_MODERNIZATION,
    PROJECT_FINANCING_YEAR_OF_CONSTRUCTIONS,
    PROJECT_FINANCING__LIFT,
    PROJECT_FINANCING_BALCONY,
    PROJECT_FINANCING_ADDITIONAL_BATHROOM,
    PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  ],
  [MITTWEIDA_FUTURE_USAGE_STEP]: [
    PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
    PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
  ],
  [MITTWEIDA_CURRENT_USAGE_STEP]: [
    PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
    PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
  ],
};

export const MarketInformationInquiryMap = (
  stepName: string,
  financingDetailsObject: IInquiryFinancingDetailsFields,
) => {
  const propertyDetails = useSelector(getPropertyDetails);
  return chooseBasedOnSection(
    marketValueStepFieldsMap[stepName],
    financingDetailsObject,
    stepName,
    propertyDetails,
  );
};
