import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { StyledWrapper, StyledLabelWrapper, StyledLabel } from 'components/Label/styles';
import { Optional } from 'components/Text';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  GUARANTY_GUARANTY_REQUIREMENT,
  GUARANTY_RENTAL_DEPOSIT,
  GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
  GUARANTY_PERFORMANCE_OF_CONTRACT,
  GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
  GUARANTY_WARRANTY,
  GUARANTY_LITIGATION_BOND,
  GUARANTY_OTHER_TYPE,
  GUARANTY_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { MAX_AMOUNT_VALUE } from 'modules/Inquiry/inquiry.constants';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import GuarantyTypeField from './GuarantyTypeField';

export const validateGuarantySection = [
  validateSectionFields([GUARANTY_DESCRIPTION]),
  validateSectionFields([
    GUARANTY_GUARANTY_REQUIREMENT,
    GUARANTY_RENTAL_DEPOSIT,
    GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
    GUARANTY_PERFORMANCE_OF_CONTRACT,
    GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
    GUARANTY_WARRANTY,
    GUARANTY_LITIGATION_BOND,
    GUARANTY_OTHER_TYPE,
  ]),
];

const Guaranty = () => {
  const t = useTranslations();
  const { required, minPrice, maxPrice } = useFieldValidators();
  return (
    <>
      <FormSection
        title={t('pages.financingNeed.sections.guaranty.sections.guarantyRequirement')}
        name={FormSections.machine}
        sectionNumber={2}
      >
        <FormRow>
          <AmountWithField
            name={GUARANTY_GUARANTY_REQUIREMENT}
            caption={t('pages.financingNeed.sections.guaranty.fields.guarantyRequirement.caption')}
            validate={combineValidators(
              required,
              minPrice(InquiryConstants.MIN_FINANCING_AMOUNT),
              maxPrice(MAX_AMOUNT_VALUE),
            )}
          />
        </FormRow>

        <StyledWrapper>
          <StyledLabelWrapper>
            <StyledLabel>
              {t('pages.financingNeed.sections.guaranty.labels.typeOfGuaranty')}
            </StyledLabel>
            <Optional>{t('other.optional')}</Optional>
          </StyledLabelWrapper>
        </StyledWrapper>

        <GuarantyTypeField />

        <FormRow>
          <InputWithField
            name={GUARANTY_OTHER_TYPE}
            caption={t('pages.financingNeed.sections.guaranty.fields.otherType.caption')}
            placeholder={t('pages.financingNeed.sections.guaranty.fields.otherType.placeholder')}
            optional
          />
        </FormRow>
      </FormSection>
      <FormSection
        title={t('pages.financingNeed.sections.guaranty.sections.description')}
        name={FormSections.machine}
        sectionNumber={3}
      >
        <FormRow>
          <TextAreaWithField
            name={GUARANTY_DESCRIPTION}
            caption={t('pages.financingNeed.sections.guaranty.fields.description.caption')}
            placeholder={t('pages.financingNeed.sections.guaranty.fields.description.placeholder')}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: Guaranty,
  default: null,
});
