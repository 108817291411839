import {
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__FACTORING,
  PURPOSE_KIND__REAL_ESTATE,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__PROJECT_FINANCING,
  PRODUCT_KIND__MEZZANINE,
} from 'modules/Inquiry/Form/formFields';

const intlIds = {
  budgetFiguresIfPossible: 'pages.finalPage.documents.budgetFiguresIfPossible',
  businessEvaluation: 'pages.finalPage.documents.businessEvaluation',
  businessPlan: 'pages.finalPage.documents.businessPlan',
  lastYearFinancialStatement: 'pages.finalPage.documents.lastYearFinancialStatement',
  oposListIfPossible: 'pages.finalPage.documents.oposListIfPossible',
  vendorOffer: 'pages.finalPage.documents.vendorOffer',
  additionalRealEstateDocuments: 'pages.finalPage.documents.additionalRealEstateDocuments',
};

export const productDocuments = {
  [PRODUCT_KIND__LEASING]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
    { id: intlIds.vendorOffer },
  ],
  [PRODUCT_KIND__LOAN]: [
    { id: intlIds.businessEvaluation },
    { id: intlIds.lastYearFinancialStatement },
    {
      id: intlIds.businessPlan,
      condition: ({ companyAge }) => companyAge <= 2,
    },
  ],
  [PRODUCT_KIND__OVERDRAFT]: [
    { id: intlIds.businessEvaluation },
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.oposListIfPossible },
    { id: intlIds.budgetFiguresIfPossible },
  ],
  [PRODUCT_KIND__OTHER]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
  ],
  [PRODUCT_KIND__FACTORING]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
    { id: intlIds.oposListIfPossible },
    { id: intlIds.budgetFiguresIfPossible },
  ],
  [PRODUCT_KIND__HIRE_PURCHASE]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
    { id: intlIds.vendorOffer },
  ],
  [PRODUCT_KIND__INVESTMENT_LOAN]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
    { id: intlIds.vendorOffer },
  ],
  [PRODUCT_KIND__PROJECT_FINANCING]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
  ],
  [PRODUCT_KIND__MEZZANINE]: [
    { id: intlIds.lastYearFinancialStatement },
    { id: intlIds.businessEvaluation },
  ],
};

export const purposeDocuments = {
  [PURPOSE_KIND__REAL_ESTATE]: [{ id: intlIds.additionalRealEstateDocuments }],
};
