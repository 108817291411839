import { any, bool, object, func, number, oneOfType, shape, string } from 'prop-types';

export const FinalFormInputPropTypes = shape({
  name: string.isRequired,
  value: oneOfType([number, string, bool]).isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
});

export const FinalFormMetaPropTypes = shape({
  active: bool.isRequired,
  data: object.isRequired,
  dirty: bool.isRequired,
  dirtySinceLastSubmit: bool.isRequired,
  error: any,
  initial: any,
  invalid: bool.isRequired,
  pristine: bool.isRequired,
  submitError: any,
  submitFailed: bool.isRequired,
  submitSucceeded: bool.isRequired,
  touched: bool.isRequired,
  valid: bool.isRequired,
  visited: bool.isRequired,
});

export const FinalFormFieldPropTypes = {
  input: FinalFormInputPropTypes.isRequired,
  meta: FinalFormMetaPropTypes.isRequired,
};

export const FormPropTypes = shape({
  getState: func.isRequired,
  getFieldState: func.isRequired,
});
