import { Gender } from 'mittweida/inquiry/fields';
import { concatenateKeys } from 'new/form/common/concatenateKeys';
import { getKeyObject, PropertiesToString, SecondLevelKeys } from 'new/form/common/types';
import { EnsureDefined, StringBoolean } from 'types/General';

export type PurposeOptions =
  | 'facade'
  | 'balcony'
  | 'window'
  | 'underground_garage'
  | 'roof'
  | 'heating'
  | 'line'
  | 'other';

export type LoanTermInYears = 5 | 10 | 15 | 20;

export type LegalRepresentative = {
  id: string | undefined;
  salutation: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthDate: Date | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  soleSignatureAuthorized: StringBoolean | undefined;
};

export type HausbankFieldTypes = {
  objectInformationPage: {
    objectInformationSection: {
      wegName: string | undefined;
      street: string | undefined;
      zipCode: string | undefined;
      city: string | undefined;
      country: 'Deutschland';
      livingSpace: number | undefined;
      commercialUsage: number | undefined;
      propertySize: number | undefined;
      constructionYear: string | undefined;
      lastModernization: string | undefined;
    };
    projectInformationSection: {
      residentialUnitsCount: number | undefined;
      commercialUnitsCount: number | undefined;
      hasOwnerWithMoreThanThirdOfShares: StringBoolean | undefined;
      isContractor: StringBoolean | undefined;
      majorityOwnerShares: number | undefined;
      wegIsSubCommunity: StringBoolean | undefined;
      propertyIsLeasehold: StringBoolean | undefined;
      hasMajorityDecision: StringBoolean | undefined;
    };
  };
  companyDataPage: {
    wegSearchSection: {
      selectedCompanyName: string | undefined;
      loggedInUserCompanyName: string | undefined;
      crefoId: string | undefined;
    };
    wegDataCompletionSection: {
      name: string | undefined;
      street: string | undefined;
      zipCode: string | undefined;
      city: string | undefined;
      legalForm: string | undefined;
      managedUnits: number | undefined;
      foundingYear: number | undefined;
      isExistingCustomer: StringBoolean | undefined;
    };
    termsAndConditionsSection: {
      termsAndConditionsAccepted: StringBoolean | undefined;
      privacyPolicyAccepted: StringBoolean | undefined;
    };
  };
  financingNeedPage: {
    financingNeedSection: {
      investmentAmount: number | undefined;
      maintenanceReserve: number | undefined;
      wegContribution: number | undefined;
      constructionSubsidies: number | undefined;
      financingAmount: string | undefined;
      loanTermInYears: LoanTermInYears | undefined;
      ownersMeetingDate: Date | undefined;
    };
    purposeSection: {
      purposeKind: PurposeOptions | undefined;
      projectDescription: string | undefined;
    };
  };
  personalDataPage: {
    contactPersonSection: {
      salutation: Gender;
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
      phoneNumber: string | undefined;
    };
    passwordSection: {
      password: string | undefined;
      passwordConfirmation: string | undefined;
    };
    legalRepresentativesSection: {
      legalRepresentatives: Array<LegalRepresentative>;
    };
  };
};

type HausbankStringFieldTypes = PropertiesToString<EnsureDefined<HausbankFieldTypes>>;

export const hausbankFieldsWithoutParents: HausbankStringFieldTypes = {
  objectInformationPage: {
    objectInformationSection: {
      wegName: 'wegName',
      street: 'street',
      zipCode: 'zipCode',
      city: 'city',
      country: 'country',
      livingSpace: 'livingSpace',
      commercialUsage: 'commercialUsage',
      propertySize: 'propertySize',
      constructionYear: 'constructionYear',
      lastModernization: 'lastModernization',
    },
    projectInformationSection: {
      residentialUnitsCount: 'residentialUnitsCount',
      commercialUnitsCount: 'commercialUnitsCount',
      hasOwnerWithMoreThanThirdOfShares: 'hasOwnerWithMoreThanThirdOfShares',
      isContractor: 'isContractor',
      majorityOwnerShares: 'majorityOwnerShares',
      wegIsSubCommunity: 'wegIsSubCommunity',
      propertyIsLeasehold: 'propertyIsLeasehold',
      hasMajorityDecision: 'hasMajorityDecision',
    },
  },
  companyDataPage: {
    wegSearchSection: {
      selectedCompanyName: 'selectedCompanyName',
      loggedInUserCompanyName: 'loggedInUserCompanyName',
      crefoId: 'crefoId',
    },
    wegDataCompletionSection: {
      name: 'name',
      street: 'street',
      zipCode: 'zipCode',
      city: 'city',
      legalForm: 'legalForm',
      managedUnits: 'managedUnits',
      foundingYear: 'foundingYear',
      isExistingCustomer: 'isExistingCustomer',
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: 'termsAndConditionsAccepted',
      privacyPolicyAccepted: 'privacyPolicyAccepted',
    },
  },
  financingNeedPage: {
    financingNeedSection: {
      investmentAmount: 'investmentAmount',
      maintenanceReserve: 'maintenanceReserve',
      wegContribution: 'investmentAmount',
      constructionSubsidies: 'constructionSubsidies',
      financingAmount: 'financingAmount',
      loanTermInYears: 'loanTermInYears',
      ownersMeetingDate: 'ownersMeetingDate',
    },
    purposeSection: {
      purposeKind: 'purposeKind',
      projectDescription: 'projectDescription',
    },
  },
  personalDataPage: {
    contactPersonSection: {
      salutation: 'salutation',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phoneNumber: 'phoneNumber',
    },
    passwordSection: {
      password: 'password',
      passwordConfirmation: 'passwordConfirmation',
    },
    legalRepresentativesSection: {
      legalRepresentatives: {
        id: 'id',
        salutation: 'salutation',
        firstName: 'firstName',
        lastName: 'lastName',
        birthDate: 'birthDate',
        email: 'email',
        phoneNumber: 'phoneNumber',
        soleSignatureAuthorized: 'soleSignatureAuthorized',
      },
    },
  },
};

export const hausbankFields: HausbankStringFieldTypes = concatenateKeys(
  hausbankFieldsWithoutParents,
);
export const hausbankFieldsWithSectionString = getKeyObject<HausbankFieldTypes>(
  hausbankFieldsWithoutParents,
);

export type HausbankPages = keyof HausbankFieldTypes;
export type HausbankSections = SecondLevelKeys<typeof hausbankFieldsWithoutParents>;
