import React from 'react';

import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useFormConfig } from 'config/formConfig/hooks';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
  PROJECT_FINANCING_HAS_BUILD_PERMIT,
  PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
  PROJECT_FINANCING_PROPERTY_DOCUMENT,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryMapType } from 'modules/Inquiry/InquiryMapType';
import { useCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

const commonFields = [
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
  PROJECT_FINANCING_HAS_BUILD_PERMIT,
];

const inquiryMap: InquiryMapType<string[]> = {
  [InquiryType.profiRLL]: [
    ...commonFields,
    PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
    PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
  ],
  default: commonFields,
};

const PropertyDocumentSection: React.FC = () => {
  const t = useTranslations('pages.financingNeed.sections.propertyDocuments');
  const showPropertyDocumentField = useCanShowField(PROJECT_FINANCING_PROPERTY_DOCUMENT);
  const showEnergyCertificateField = useCanShowField(PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE);
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;

  return (
    <FormSection
      name={FormSections.projectPropertyDocuments}
      sectionNumber={isProfiInquiry ? 4 : 5}
      title={t('title')}
    >
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE} optional />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING_HAS_BUILD_PERMIT} optional />
      </FormRow>
      <Condition condition={showPropertyDocumentField}>
        <FormRow>
          <YesNoRadioGroupWithField name={PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES} />
        </FormRow>
        <Condition condition={showEnergyCertificateField}>
          <FormRow>
            <YesNoRadioGroupWithField name={PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE} />
          </FormRow>
        </Condition>
      </Condition>
    </FormSection>
  );
};

export const validatePropertyDocumentSection = (selectedInquiryType: InquiryType) =>
  validateSectionFields(inquiryMap[selectedInquiryType] || inquiryMap.default);

export default React.memo(PropertyDocumentSection);
