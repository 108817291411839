import React from 'react';

import styled from 'styled-components/macro';

import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

// HiddenInput is not rendered in the DOM. If you need to calculate its position or interact with it's visual representation (even if it has no size), please use the RenderedHiddenInput component.

const HiddenInput = styled.input`
  display: none;
`;

const HiddenInputAdapter = ({ input, meta, ...rest }) => (
  <HiddenInput {...input} {...meta} {...rest} />
);

HiddenInputAdapter.propTypes = FinalFormFieldPropTypes;
export const HiddenInputWithField = withFormField(HiddenInputAdapter);

export default HiddenInput;
