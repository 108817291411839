import { hausbankFields } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateFinancingNeedSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: financingNeedSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  investmentAmount,
  maintenanceReserve,
  wegContribution,
  constructionSubsidies,
  financingAmount,
  loanTermInYears,
  ownersMeetingDate,
} = hausbankFields.financingNeedPage.financingNeedSection;

export const financingNeedSectionValidationMap = {
  [investmentAmount]: fieldValidators.number().min(100000).required(),
  [maintenanceReserve]: fieldValidators.number(),
  [wegContribution]: fieldValidators.number(),
  [constructionSubsidies]: fieldValidators.number(),
  [financingAmount]: fieldValidators.number().min(100000),
  [loanTermInYears]: fieldValidators.string().required(),
  [ownersMeetingDate]: fieldValidators.date().required(),
};
