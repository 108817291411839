import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { REAL_ESTATE_RECONSTRUCTION_COST } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import BFSReconstruction from './BFSRealEstateFields/BFSReconstruction';

export const validateReconstructionSection = (form) =>
  fieldIsValid(REAL_ESTATE_RECONSTRUCTION_COST)({ form });

const Reconstruction = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.realEstateReconstruction}
      sectionNumber={4}
      title={t('pages.financingNeed.sections.reconstruction.title')}
    >
      <FormRow>
        <AmountWithField name={REAL_ESTATE_RECONSTRUCTION_COST} />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BFSReconstruction,
  default: Reconstruction,
});
