import React from 'react';

import FormRow from 'components/FormRow';
import { INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import { hausbankFields } from 'hausbank/inquiry/fields';

function HausbankFillCompanyInformation() {
  const { name, street, city, zipCode } = hausbankFields.companyDataPage.wegDataCompletionSection;

  return (
    <>
      <FormRow>
        <InputWithField name={name} />
      </FormRow>
      <FormRow>
        <InputWithField name={street} />
      </FormRow>
      <FormRow>
        <InputWithField name={city} />
        <InputWithField name={zipCode} type={INPUT_TYPE_NUMBER} separateThousands={false} />
      </FormRow>
    </>
  );
}

export default HausbankFillCompanyInformation;
