import React from 'react';

import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import PortalTable from '../../../components/PortalTable';
import usePortalTable from '../../../hooks/usePortalTable';
import type { MappingProps } from '../../../types';
import { getRowStylesMap, useUnitFormatter } from '../../../utils';

import type { LendingValueItem } from '.';

const stockholderMapping: MappingProps<LendingValueItem> = [
  { key: 'adjusted_base_value', unit: 'currency', styles: { bold: true } },
  { key: 'lending_value', unit: 'currency', styles: { bold: true } },
  { key: 'lending_limit_percent', unit: 'percent', styles: { italic: true } },
  { key: 'lending_limit_euro', unit: 'currency', styles: { bold: true } },
  { key: 'unsecured_portion', unit: 'currency', styles: { bold: true } },
  { key: 'loan_value_ratio', unit: 'percent', styles: { bold: true } },
];

export const LendingValueStockholder = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.lendingValue.stockholder',
  );
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      lendingValue: response.data.attributes.computed_details.lending_value,
    }),
  });
  const formatter = useUnitFormatter();
  const { usageType } = useSelector(getMarketDataFilterLegacy);

  const lendingValueStockholderTable = usePortalTable({
    data: stockholderMapping.map(({ key, unit }) => ({
      id: key,
      position: t(`rows.${key}`),
      indication: formatter[unit](data?.lendingValue[usageType].indication[key]),
    })),
    headers: [
      { key: 'position', text: t('headers.position') },
      { key: 'indication', text: t('headers.indication') },
    ],
  });
  const rowStyles = getRowStylesMap(stockholderMapping);
  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return <PortalTable {...lendingValueStockholderTable} rowStylesMap={{ ...rowStyles }} />;
};
