import {
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_INDUSTRY,
  BFS_USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
  USER_PROFILE_ENERGY_EFFICIENT,
  END_USER_GENDER,
  END_USER_FIRST_NAME,
  END_USER_LAST_NAME,
  END_USER_EMAIL,
  END_USER_PHONE_NUMBER,
  USER_PROFILE_FOUNDING_MONTH,
  INCOME_SURPLUS,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  CORONA_FOUNDING_YEAR,
  CORONA_FOUNDING_MONTH,
  USER_PROFILE_BIRTHDATE,
  PARTNER_SPECIFIC_IBAN,
  USER_PROFILE_BALANCE_SHEET,
  USER_PROFILE_EMPLOYEE_COUNT,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_RATING_KIND,
  USER_PROFILE_COMPANY_INTERNAL_RATING,
  USER_PROFILE_FOUNDING_TWO_YEAR_OLD,
  BANK_DETAILS_IBAN,
  CUSTOMER_BIRTH_DATE,
} from 'modules/Inquiry/Form/formFields';

const getTranslationPath = (section: string, field: string) =>
  `pages.userProfile.sections.${section}.fields.${field}.caption`;

// The order of these labels is relevant for inquiry details page
export const userProfileLabelsMap: { [key: string]: string } = {
  [USER_PROFILE_LEGAL_FORM]: getTranslationPath('completingCompanyDetails', 'userProfileLegalForm'),
  [USER_PROFILE_INDUSTRY]: getTranslationPath('completingCompanyDetails', 'userProfileIndustry'),
  [BFS_USER_PROFILE_INDUSTRY]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileIndustry',
  ),
  [USER_PROFILE_TURNOVER_CLASS]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileTurnoverClass',
  ),
  [USER_PROFILE_FOUNDING_YEAR]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileFoundingYear',
  ),
  [USER_PROFILE_FOUNDING_TWO_YEAR_OLD]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileFoundingTwoYearOld',
  ),
  [CORONA_FOUNDING_YEAR]: getTranslationPath('completingCompanyDetails', 'userProfileFoundingYear'),
  [CORONA_FOUNDING_MONTH]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileFoundingMonth',
  ),
  [USER_PROFILE_FOUNDING_MONTH]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileFoundingMonth',
  ),
  [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileAlreadyCustomer',
  ),
  [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileAdditionalAssociationCompany',
  ),
  [USER_PROFILE_PHONE_NUMBER]: getTranslationPath(
    'completingContactData',
    'userProfilePhoneNumber',
  ),
  [USER_PROFILE_BIRTHDATE]: getTranslationPath('completingContactData', 'userProfileBirthdate'),
  [USER_PROFILE_COMPANY_STREET]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileCompanyStreet',
  ),
  [USER_PROFILE_COMPANY_NAME]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileCompanyName',
  ),
  [USER_PROFILE_COMPANY_CITY]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileCompanyCity',
  ),
  [USER_PROFILE_COMPANY_ZIP_CODE]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileCompanyZipCode',
  ),
  [PARTNER_SPECIFIC_IBAN]: getTranslationPath('completingCompanyDetails', 'partnerSpecificIban'),
  [BANK_DETAILS_IBAN]: getTranslationPath('completingCompanyDetails', 'partnerSpecificIban'),
  [USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: getTranslationPath(
    'completingCompanyDetails',
    'annualNetProfitOrLossClass',
  ),
  [USER_PROFILE_NEGATIVE_EQUITY_CAPITAL]: getTranslationPath(
    'completingCompanyDetails',
    'negativeEquityCapital',
  ),
  [USER_PROFILE_INNOVATIVE_COMPANY]: getTranslationPath(
    'completingCompanyDetails',
    'innovativeCompany',
  ),
  [USER_PROFILE_ENERGY_EFFICIENT]: getTranslationPath(
    'completingCompanyDetails',
    'energyEfficient',
  ),

  [END_USER_GENDER]: getTranslationPath('endUser', 'gender'),
  [END_USER_FIRST_NAME]: getTranslationPath('endUser', 'firstName'),
  [END_USER_LAST_NAME]: getTranslationPath('endUser', 'lastName'),
  [END_USER_EMAIL]: getTranslationPath('endUser', 'email'),
  [END_USER_PHONE_NUMBER]: getTranslationPath('endUser', 'phoneNumber'),
  [INCOME_SURPLUS]: getTranslationPath('completingCompanyDetails', 'incomeSurplus'),
  [USER_PROFILE_COMPANY_RATING_KIND]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileCompanyRatingKind',
  ),
  [USER_PROFILE_COMPANY_INTERNAL_RATING]: getTranslationPath(
    'completingCompanyDetails',
    'userProfileCompanyInternalRating',
  ),
  [USER_PROFILE_LEGAL_FORM_DESCRIPTION]:
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalFormDescription.caption',
  [USER_PROFILE_DETAILED_ANNUAL_TURNOVER]:
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileDetailedAnnualTurnover.caption',
  [USER_PROFILE_BALANCE_SHEET]:
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileBalanceSheet.caption',
  [USER_PROFILE_EMPLOYEE_COUNT]:
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileEmployeeCount.caption',
  [CUSTOMER_BIRTH_DATE]: 'pages.customerData.sections.contactPerson.fields.dateOfBirth.caption',
};
