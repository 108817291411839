import {
  BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
  BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
  BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
  BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
  BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
  BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
} from 'modules/Inquiry/Form/formFields';

export const getFurtherNeedOptions = (formatMessage: any) => {
  const commonLabel =
    'pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.explicitNeedFurtherAdvice.options.';
  return [
    {
      id: commonLabel + 'explicitNeedOtherFinancingSolutions.caption',
      value: BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
    },
    {
      id: commonLabel + 'explicitNeedExpertAdvice.caption',
      value: BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
    },
    {
      id: commonLabel + 'explicitNeedCurrentAccountPayment.caption',
      value: BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
    },
    {
      id: commonLabel + 'explicitNeedBillingSoftware.caption',
      value: BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
    },
    {
      id: commonLabel + 'explicitNeedFactoringExchangeService.caption',
      value: BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
    },
    {
      id: commonLabel + 'explicitNeedSeminars.caption',
      value: BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
    },
  ].map(({ id, value }) => ({ value, label: formatMessage({ id }) }));
};
