import _get from 'lodash/get';
import { AnyAction } from 'redux';

import { LOAD_COMPANIES_FAILURE } from 'api/CompeonReverseApi/actionTypes';
import { UserState } from 'types/User';

const INITIAL_STATE: UserState = {
  attributes: {
    roles: [],
  },
};

const reducer = (state = INITIAL_STATE, action: AnyAction): UserState => {
  const status = _get(action, 'error.response.status');

  switch (action.type) {
    case LOAD_COMPANIES_FAILURE: {
      if (status === 401) {
        return INITIAL_STATE;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
