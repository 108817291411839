import { merge } from 'lodash';

import {
  mapInquiryDetailsFromApi,
  mapNewInquiryDetailsFromApi,
} from 'modules/InquiryDetails/InquiryDetails.service';
import { ToMapFrontendToBackend } from 'new/form/common/types';
import { INQUIRY_VALUE_FORMATTERS_REFS } from 'new/form/formatters';

import { mapMMVInquiryFromApi } from './mapping/mapMMVInquiryFromApi';
import { AllFieldTypes } from '../fields';

export const feToBeMappingKeys: ToMapFrontendToBackend<AllFieldTypes> = (data: any) => {
  // Use v1 to map legacy properties
  const v1InquiryMappingResult = mapInquiryDetailsFromApi(data);
  // Use v2 to map other fields (made in v2)
  const v2InquiryMappingResult = mapNewInquiryDetailsFromApi(data, v2MappingSchema);
  // Use custom function to map beneficiary owners and legal representatives
  const mmvInquiryMappingResult = mapMMVInquiryFromApi(data);

  const isFictitiousBeneficialOwner =
    data.included
      .filter((item: any) => item.type === 'beneficiary_owners')
      ?.some((item: any) => item.attributes?.additional_info?.wb_fictitious) || false;

  const mappingResult = {
    // the order of the spread matters here
    ...v1InquiryMappingResult,
    ...mmvInquiryMappingResult,
    ...v2InquiryMappingResult,
  };

  return merge(mappingResult, {
    customerDataPage: {
      beneficialOwnerSection: {
        isFictitiousBeneficialOwner: String(isFictitiousBeneficialOwner),
      },
    },
  });
};

const v2MappingSchema = {
  contractDetails: {
    dealerSection: {
      supplier: 'supplier',
      agent: 'agent',
    },
    financingObjectSection: {
      objectAlreadyDelivered: 'object-already-delivered',
      objectDescription: 'object-description',
      state: 'state',
      serialNumber: 'serial-number',
    },
    financingConditionsSection: {
      runtime: 'runtime',
      objectValue: 'object-value',
      downPayment: 'down-payment',
      ratePerMonth: 'lease-rate',
      additionalInformation: 'maintenance',
      remainingValue: 'residual-value',
    },
    bankDetailsSection: {
      accountHolder: 'account-holder',
      iban: 'iban',
    },
  },
  customerDataPage: {
    companySection: {
      companyName: 'name',
      address: INQUIRY_VALUE_FORMATTERS_REFS.company,
    },
    contactPersonSection: {
      title: 'salutation',
      firstName: 'first_name',
      lastName: 'last_name',
      email: 'email',
    },
    authorizationOfRepresentationSection: {},
  },
};
