import styled from 'styled-components/macro';

import TextArea from 'components/TextArea';

export const StyledDescription = styled.p`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledTextArea = styled(TextArea)`
  height: 7.5rem;
  margin-bottom: 0.25rem;
`;
