import * as React from 'react';

import _debounce from 'lodash/debounce';

import Spinner from 'components/Spinner';
import FormGenerator from 'modules/FormGenerator';
import { doesFormContainsAllValues, isFormValid } from 'modules/FormGenerator/service';
import { Field } from 'modules/FormGenerator/types';
import { updateCompeonQuestionnaireItem } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import { useFetchOfferFormTemplate } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/useFetchOfferFormTemplate';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { FormValidityState } from './index';

const FORM_DEBOUNCE_DELAY = 300;
interface Props {
  id: string;
  setFormsValidation: React.Dispatch<React.SetStateAction<FormValidityState | undefined>>;
  setFormsFilling: React.Dispatch<React.SetStateAction<FormValidityState | undefined>>;
}

interface UpdateProps {
  name: string;
  value: string;
}

export const Questionnaire = ({ id, setFormsValidation, setFormsFilling }: Props) => {
  const { formTemplate, isLoading, error } = useFetchOfferFormTemplate(id);

  const [formState, setFormState] = React.useState<Field[] | undefined>();

  const { makeCall } = useDispatchApiCall();
  const sendUpdateToCompeon = ({ name, value }: UpdateProps) =>
    makeCall(updateCompeonQuestionnaireItem(id, name, value));

  const onFormChange = _debounce(
    (changes: { name: string; value: string; hasError: boolean }, newFormState: Field[]) => {
      setFormState(newFormState);
      sendUpdateToCompeon({ name: changes.name, value: changes.value });
    },
    FORM_DEBOUNCE_DELAY,
  );

  React.useEffect(() => {
    setFormState(formTemplate);
  }, [formTemplate]);

  React.useEffect(() => {
    if (formState) {
      setFormsValidation((state) => ({ ...state, [id]: isFormValid(formState) }));
      setFormsFilling((state) => ({ ...state, [id]: doesFormContainsAllValues(formState) }));
    }
  }, [formState, setFormsValidation, setFormsFilling, id]);

  if (error) {
    throw new Error(error);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    // FIXME: FormGenerator needs to be typed
    // @ts-ignore
    <FormGenerator fields={formTemplate} onFormChange={onFormChange} />
  );
};
