import styled from 'styled-components/macro';

import { mqSmall } from 'styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0.25rem;
  font-size: 0.75rem;

  ${mqSmall`
    margin: 0.5rem 0.75rem;
  `};
`;

export default Wrapper;
