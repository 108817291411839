import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import BfsOverdraftLiquidityIcon from './BfsOverdraftLiquidityIcon';

const OverdraftLiquidityIcon = (props: IconProps) => {
  return (
    <Icon
      width="65"
      height="45"
      viewBox="0 0 65 45"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M9.6 1v37.2h27.8V1H9.6zm28.8-1v39.2H8.6V0h29.8z" />
      <path d="M20.2 34.5v3.7h6.5v-3.7h-6.5zm7.5-1v5.7h-8.5v-5.7h8.5z" />
      <path d="M1 39.4v1.3h39.5v-1.3H1zm40.5-1v3.3H0v-3.3h41.5zm-37.1 0h-1V9.1h1z" />
      <path d="M4.095 9.803l-.59-.806 5.6-4.1.59.806zM52.9 21.4l.3 1.8 1-.2-.4-2z" />
      <path d="M50.9 21.9l2.7 1.7c.1.1.3.1.4.1.1 0 .2-.1.3-.2l1.7-2.8-.9-.5-1.4 2.4-2.3-1.5-.5.8zm-5.4-9.4c-1.1-.2-2.4-.2-3.6 0l-.5.1.2 1 .5-.1c1.3-.2 2.6-.2 3.8.1-.2-.3-.3-.7-.4-1.1z" />
      <path d="M52.6 17.6h-1.1c.7 1 1.1 2.2 1.3 3.4l.1.5 1-.2-.1-.5c-.2-1.2-.6-2.2-1.2-3.2zm5.914 20.836l.372.928c-.71.284-1.292.436-1.886.436-2.374 0-4.3-1.821-4.3-4.1s1.926-4.1 4.3-4.1c.729 0 1.386.184 2.124.553l-.448.894c-.608-.304-1.119-.447-1.676-.447-1.834 0-3.3 1.386-3.3 3.1 0 1.714 1.466 3.1 3.3 3.1.44 0 .912-.123 1.514-.364z" />
      <path d="M52.2 35.8v-1h4.1v1zm0 1.5v-1h4.1v1z" />
      <path d="M56.5 44.6a8.4 8.4 0 110-16.8 8.4 8.4 0 010 16.8zm0-1a7.4 7.4 0 100-14.8 7.4 7.4 0 000 14.8zM15.3 6.3h2.1V4.2h-2.1v2.1zm3.1-3.1v4.1h-4.1V3.2h4.1zm4.2 3.1h2.1V4.2h-2.1v2.1zm3.1-3.1v4.1h-4.1V3.2h4.1zM30 6.3h2.1V4.2H30v2.1zm3.1-3.1v4.1H29V3.2h4.1zm-17.8 9.2h2.1v-2.1h-2.1v2.1zm3.1-3.1v4.1h-4.1V9.3h4.1zm4.2 3.1h2.1v-2.1h-2.1v2.1zm3.1-3.1v4.1h-4.1V9.3h4.1zm4.3 3.1h2.1v-2.1H30v2.1zm3.1-3.1v4.1H29V9.3h4.1zm-17.8 9.3h2.1v-2.1h-2.1v2.1zm3.1-3.1v4.1h-4.1v-4.1h4.1zm4.2 3.1h2.1v-2.1h-2.1v2.1zm3.1-3.1v4.1h-4.1v-4.1h4.1zm4.3 3.1h2.1v-2.1H30v2.1zm3.1-3.1v4.1H29v-4.1h4.1zm-17.8 9.3h2.1v-2.1h-2.1v2.1zm3.1-3.1v4.1h-4.1v-4.1h4.1zm4.2 3.1h2.1v-2.1h-2.1v2.1zm3.1-3.1v4.1h-4.1v-4.1h4.1zm4.3 3.1h2.1v-2.1H30v2.1zm3.1-3.1v4.1H29v-4.1h4.1zM53.179 16.515l.242.97c-.217.054-.42.086-.635.101a7.468 7.468 0 01-.586.014c-3.876 0-7-3.124-7-7s3.124-7 7-7 7 3.124 7 7c0 .556-.073 1.112-.21 1.798l-.98-.196c.125-.627.19-1.125.19-1.602 0-3.324-2.676-6-6-6s-6 2.676-6 6 2.676 6 6 6c.275 0 .383-.002.514-.011.16-.012.305-.034.465-.074z" />
      <path d="M52.7 10.33l2.374 1.552-.548.836L51.7 10.87V5.6h1z" />
    </Icon>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BfsOverdraftLiquidityIcon,
  default: OverdraftLiquidityIcon,
});
