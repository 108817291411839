import {
  FACTORING_TURNOVER_CLASS__0_TO_10_THOUSAND,
  FACTORING_TURNOVER_CLASS__100_TO_250_THOUSAND,
  FACTORING_TURNOVER_CLASS__10_TO_20_THOUSAND,
  FACTORING_TURNOVER_CLASS__10_TO_25_MILLION,
  FACTORING_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION,
  FACTORING_TURNOVER_CLASS__20_TO_30_THOUSAND,
  FACTORING_TURNOVER_CLASS__250_TO_500_THOUSAND,
  FACTORING_TURNOVER_CLASS__25_TO_50_MILLION,
  FACTORING_TURNOVER_CLASS__2_5_TO_5_MILLION,
  FACTORING_TURNOVER_CLASS__30_TO_40_THOUSAND,
  FACTORING_TURNOVER_CLASS__40_TO_50_THOUSAND,
  FACTORING_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND,
  FACTORING_TURNOVER_CLASS__50_TO_100_MILLION,
  FACTORING_TURNOVER_CLASS__50_TO_75_THOUSAND,
  FACTORING_TURNOVER_CLASS__5_TO_10_MILLION,
  FACTORING_TURNOVER_CLASS__75_TO_100_THOUSAND,
  FACTORING_TURNOVER_CLASS__MORE_THAN_100_MILLION,
} from 'modules/Inquiry/Form/formFields';

const getFactoringTurnoverClassOptions = (formatMessage) => {
  const euroText = formatMessage({ id: 'currency.euro' });
  const teuroText = formatMessage({ id: 'currency.thousandEuro' });
  const milionText = formatMessage({ id: 'other.milion' });
  const above = formatMessage({ id: 'other.above' });

  return [
    {
      label: `0 ${euroText} - 10 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__0_TO_10_THOUSAND,
    },
    {
      label: `10 ${teuroText} - 20 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__10_TO_20_THOUSAND,
    },
    {
      label: `20 ${teuroText} - 30 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__20_TO_30_THOUSAND,
    },
    {
      label: `30 ${teuroText} - 40 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__30_TO_40_THOUSAND,
    },
    {
      label: `40 ${teuroText} - 50 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__40_TO_50_THOUSAND,
    },
    {
      label: `50 ${teuroText} - 75 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__50_TO_75_THOUSAND,
    },
    {
      label: `75 ${teuroText} - 100 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__75_TO_100_THOUSAND,
    },
    {
      label: `100 ${teuroText} - 250 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__100_TO_250_THOUSAND,
    },
    {
      label: `250 ${teuroText} - 500 ${teuroText}`,
      value: FACTORING_TURNOVER_CLASS__250_TO_500_THOUSAND,
    },
    {
      label: `500 ${teuroText} - 1 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND,
    },
    {
      label: `1 - 2,5 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION,
    },
    {
      label: `2,5 - 5 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__2_5_TO_5_MILLION,
    },
    {
      label: `5 - 10 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__5_TO_10_MILLION,
    },
    {
      label: `10 - 25 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__10_TO_25_MILLION,
    },
    {
      label: `25 - 50 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__25_TO_50_MILLION,
    },
    {
      label: `50 - 100 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__50_TO_100_MILLION,
    },
    {
      label: `${above} 100 ${milionText} ${euroText}`,
      value: FACTORING_TURNOVER_CLASS__MORE_THAN_100_MILLION,
    },
  ];
};

export default getFactoringTurnoverClassOptions;
