import React from 'react';

import { Asset } from 'components/Asset';
import {
  BUILDING_PROJECT__NEW,
  BUILDING_PROJECT__RESCHEDULE,
  BUILDING_PROJECT__RECONSTRUCTION,
  BUILDING_PROJECT,
  BUILDING_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__NEW_BUILDING,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
} from 'modules/Inquiry/Form/formFields';

export const buildingSectionOptions = {
  [BUILDING_PROJECT]: [
    {
      key: BUILDING_PROJECT__NEW,
      title:
        'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.newBuilding',
      icon: <Asset type="realEstate" value={REAL_ESTATE_PROJECT__NEW_BUILDING} />,
    },
    {
      key: BUILDING_PROJECT__PURCHASE,
      title: 'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.purchase',
      icon: <Asset type="realEstate" value={REAL_ESTATE_PROJECT__PURCHASE} />,
    },
    {
      key: BUILDING_PROJECT__RESCHEDULE,
      title:
        'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.rescheduling',
      icon: <Asset type="realEstate" value={REAL_ESTATE_PROJECT__FOLLOW_UP} />,
    },
    {
      key: BUILDING_PROJECT__RECONSTRUCTION,
      title:
        'pages.financingNeed.sections.buildingProject.fields.buildingProject.options.reconstruction',
      icon: <Asset type="realEstate" value={REAL_ESTATE_PROJECT__RECONSTRUCTION} />,
    },
  ],
};
