import React from 'react';

import { Box } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { useIntl } from 'react-intl';

import { useGetPredefinedFileRequestTypes } from 'api/CompeonReverseApi/operation/queryHooks/files';
import { HiddenInputWithField } from 'components/HiddenInput';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import { IPredefinedFileRequestType } from 'models/PredefinedFileRequest.model';
import { useMapFileRequestTypesToOptions } from 'shared/documentExchange/documentExchange.service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const DzbFormContent = () => {
  const { change } = useForm();
  const { locale } = useIntl();
  const { required, optional } = useFieldValidators();
  const t = useTranslations('pages.inquiryDetails.documentExchange.requestDocuments');
  const { data: fileRequestTypes } = useGetPredefinedFileRequestTypes();
  const fileRequestTypeOptions = useMapFileRequestTypesToOptions(fileRequestTypes);

  const fileRequestTypeChangeHandler = (value: IPredefinedFileRequestType['id']) => {
    if (fileRequestTypes) {
      change(
        'title',
        fileRequestTypes.find((type) => type.id === value)?.attributes[
          `display_name_${locale}` as keyof IPredefinedFileRequestType['attributes']
        ],
      );
      change(
        'description',
        fileRequestTypes.find((type) => type.id === value)?.attributes[
          `description_${locale}` as keyof IPredefinedFileRequestType['attributes']
        ],
      );
    }
  };

  return (
    <>
      <Box mb="2rem">
        <HiddenInputWithField name="title" />
        <HiddenInputWithField name="description" />
        <SelectWithField
          name="documentType"
          options={fileRequestTypeOptions}
          onChange={fileRequestTypeChangeHandler}
          validate={required}
          caption={t('documentType.caption')}
        />
      </Box>
      <Box mb="2rem">
        <TextAreaWithField
          validate={optional}
          name="description"
          caption={t('description.caption')}
          optional
        />
      </Box>
    </>
  );
};

export default DzbFormContent;
