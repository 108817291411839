import React from 'react';

import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import { IComputedDetailsProfitabilitySales } from 'api/CompeonReverseApi/operation/types';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import usePortalTable from '../../hooks/usePortalTable';
import type { MappingProps } from '../../types';
import { getRowStylesMap, useUnitFormatter } from '../../utils';

interface ProfitabilitySalesBoxItem {
  total_costs: string;
  sqm_construction_costs: string;
  sqm_gak_buying_price_lot: string;
  sqm_gak_buying_price_total_space: string;
}
export interface ProfitabilitySalesBoxes {
  current: ProfitabilitySalesBoxItem;
  future: ProfitabilitySalesBoxItem;
}

interface MarketData {
  margin_euro: string;
  total_costs: string;
  total_price: string;
  price_per_sqm: string;
  margin_percent: string;
  total_price_commercial: string;
  total_price_residential: string;
}
interface BankValues {
  margin_euro: string;
  total_costs: string;
  total_price: string;
  price_per_sqm: string;
  margin_percent: string;
  sales_prices_checks: null;
  total_price_garages: string;
  buying_costs_checked: null;
  total_price_property: string;
  total_price_commercial: string;
  total_price_parking_lot: string;
  total_price_residential: string;
  price_per_sqm_no_parking: string;
  construction_costs_checked: null;
}
interface ProfitabilitySalesItem {
  bank_values: BankValues;
  market_data_unit: MarketData;
  market_data_building: MarketData;
}
export interface ProfitabilitySalesProps {
  future: ProfitabilitySalesItem;
  current: ProfitabilitySalesItem;
  customer_plan: {
    margin_euro: string;
    total_costs: string;
    total_price: string;
    price_per_sqm: string;
    margin_percent: string;
    total_price_garages: string;
    total_price_commercial: number;
    total_price_parking_lot: string;
    total_price_residential: number;
    price_per_sqm_no_parking: string;
  };
}

const mapping: MappingProps<BankValues & MarketData & ProfitabilitySalesProps['customer_plan']> = [
  { key: 'total_price_residential', unit: 'currency', styles: { bold: true } },
  { key: 'total_price_commercial', unit: 'currency', styles: { bold: true } },
  { key: 'total_price_garages', unit: 'currency', styles: { bold: true } },
  { key: 'total_price_parking_lot', unit: 'currency', styles: { bold: true } },
  { key: 'total_price_property', unit: 'currency', styles: { bold: true } },
  { key: 'total_price', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'price_per_sqm', unit: 'currency', styles: { bold: true } },
  { key: 'price_per_sqm_no_parking', unit: 'currency', styles: { bold: true } },
  { key: 'total_costs', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'buying_costs_checked', unit: 'none', styles: { bold: true, italic: true } },
  { key: 'construction_costs_checked', unit: 'none', styles: { bold: true, italic: true } },
  { key: 'sales_prices_checks', unit: 'none', styles: { bold: true, italic: true } },
  { key: 'margin_euro', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'margin_percent', unit: 'percent', styles: { bold: true, highlighted: true } },
];

const useGetProfitabilitySalesTable = (
  profitabilitySalesTableData: IComputedDetailsProfitabilitySales | undefined,
) => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.profitabilitySales',
  );

  const formatter = useUnitFormatter();
  const { usageType } = useSelector(getMarketDataFilterLegacy);

  return usePortalTable({
    data: mapping.map(({ key, unit }) => ({
      id: key,
      position: t(`rows.${key}`),
      planCustomer: profitabilitySalesTableData
        ? formatter[unit](
            profitabilitySalesTableData.customer_plan[
              key as keyof ProfitabilitySalesProps['customer_plan']
            ],
          )
        : '',
      marketDataUnit: formatter[unit](
        profitabilitySalesTableData
          ? profitabilitySalesTableData[usageType].market_data_unit[key as keyof MarketData]
          : '',
      ),
      marketDataBuilding: formatter[unit](
        profitabilitySalesTableData
          ? profitabilitySalesTableData[usageType].market_data_building[key as keyof MarketData]
          : '',
      ),
      bankValues: formatter[unit](
        profitabilitySalesTableData
          ? profitabilitySalesTableData[usageType].bank_values[key as keyof BankValues]
          : '',
      ),
    })),
    headers: [
      {
        key: 'position',
        text: t('headers.position'),
      },
      {
        key: 'planCustomer',
        text: t('headers.planCustomer'),
      },
      {
        key: 'marketDataUnit',
        text: t('headers.marketDataUnit'),
      },
      {
        key: 'marketDataBuilding',
        text: t('headers.marketDataBuilding'),
      },
      {
        key: 'bankValues',
        text: t('headers.bankValues'),
      },
    ],
  });
};

export const ProfitabilitySales = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.profitabilityCalculation');
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      profitabilitySales: response.data.attributes.computed_details.profitability_sales,
      profitabilitySalesBoxes: response.data.attributes.computed_details.profitability_boxes,
    }),
  });

  const { usageType } = useSelector(getMarketDataFilterLegacy);

  const { profitabilitySales } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: profitabilitySales(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[
        MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES
      ](),
  });
  const formatter = useUnitFormatter();

  const profitabilitySalesTable = useGetProfitabilitySalesTable(data?.profitabilitySales);

  const rowStyles = getRowStylesMap(mapping);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <>
      {' '}
      <SummaryCards>
        <SummaryCard
          caption={t('boxes.profitabilitySales.sqmGakBuyingPriceTotalSpace')}
          value={formatter.currency(
            data?.profitabilitySalesBoxes[usageType].sqm_gak_buying_price_total_space,
          )}
        />
        <SummaryCard
          caption={t('boxes.profitabilitySales.sqmGakBuyingPriceLot')}
          value={formatter.currency(
            data?.profitabilitySalesBoxes[usageType].sqm_gak_buying_price_lot,
          )}
        />
        <SummaryCard
          caption={t('boxes.profitabilitySales.sqmConstructionCosts')}
          value={formatter.currency(
            data?.profitabilitySalesBoxes[usageType].sqm_construction_costs,
          )}
        />
        <SummaryCard
          caption={t('boxes.profitabilitySales.totalCosts')}
          value={formatter.currency(data?.profitabilitySalesBoxes[usageType].total_costs)}
        />
      </SummaryCards>
      <Card title={t('tables.profitabilitySales.title')} onEdit={onEdit} isExpandable isExpanded>
        <PortalTable {...profitabilitySalesTable} rowStylesMap={{ ...rowStyles }} />
      </Card>
    </>
  );
};
