import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { StyledDetailedSection } from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection/styles';
import { mqMedium, mqLarge } from 'styles/breakpoints';

export const StyledOffersLead = styled.p`
  margin-bottom: 1rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.625;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledOffersDescription = styled.div`
  line-height: 1.625;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledOffersDescriptionWrapper = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  padding: 1rem 0;
`;

export const StyledInquiryOffers = styled.div`
  ${StyledDetailedSection} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    padding: 2rem 0;
  }
`;

export const StyledOffersList = styled.div`
  display: block;

  ${mqMedium`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  `}

  ${mqLarge`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const SingleOffer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
