import React from 'react';

import { Divider } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { IMessageEvent } from 'websocket';

import { SelectionForDigitization } from 'components/SelectionForDigitization';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import { ProgressSuccess } from 'pages/customerPortal/InquiryDetails/Dashboard/ProgressSuccess';
import SummaryCard from 'pages/customerPortal/InquiryDetails/Dashboard/SummaryCard';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import Actions from 'pages/operationPortal/InquiryDetails/Dashboard/Actions';
import CloseInquiry from 'pages/operationPortal/InquiryDetails/Dashboard/CloseInquiry';
import Communication from 'pages/operationPortal/InquiryDetails/Dashboard/Communication';
import { AutoMergeAction } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/AutoMergeAction';
import CrefoQueryStep from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/CrefoQueryStep';
import { DigitalProcessingStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/DigitalProcessingStep';
import { InconsistencyStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/InconsistencyReportStep';
import { NonBindingOfferStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/NonBindingOfferStep';
import { TransparencyCompanySelectionStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/TransparencyCompanySelectionStep';
import { TriggerKycStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/TriggerKycStep';
import { TriggerMarketResearchStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/TriggerMarketResearchStep';
import { UploadOfferStep } from 'pages/operationPortal/InquiryDetails/Dashboard/Steps/UploadOfferStep';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { Channel, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { useScrollTopOnLoad } from 'utils/hooks/useScrollTopOnLoad';

import ForwardInquiry from './ForwardInquiry';
import { NewOperationsDashboard } from './NewDashboard';
import { AssignInquiryToRealEstateExpertStep } from './Steps/AssignInquiryToRealEstateExpertStep';
import { InviteCustomerStep } from './Steps/InviteCustomerStep';
import { LegalRepresentationCorrectionStep } from './Steps/LegalRepresentationCorrectionStep';
import { ResolveKycDataBankStep } from './Steps/ResolveKycDatBankStep';
import { TriggerProfitabilityCalculationStep } from './Steps/TriggerProfitabilityCalculationStep';
import { TriggerQuickCheckStep } from './Steps/TriggerQuickCkeck';
import { UploadOffer } from './UploadOffer';
import { useOperationProgressSections } from './useOperationProgressSections';
import { useInquirySignees } from '../../../../api/CompeonReverseApi/operation/queryHooks/inquiries';
import { SignContractStep } from '../../../customerPortal/InquiryDetails/Dashboard/SignContractStep/SignContractStep';

export const DashboardRouter = () => {
  const Component = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: Dashboard,
    [InquiryType.hausbank]: NewOperationsDashboard,
    [InquiryType.dzb]: NewOperationsDashboard,
  });

  return (
    <Switch>
      <Route exact path={paths.operation.inquiryDetails.uploadOffer}>
        <UploadOffer />
      </Route>
      <Route>
        <Component />
      </Route>
    </Switch>
  );
};

const Dashboard = () => {
  const { id } = useParams<{ id: string }>();
  const {
    completedValue: sectionsCompletedValue,
    sectionsDone,
    sectionsOpen,
    firstOpenTask,
    sections,
    lastItemIndex,
  } = useOperationProgressSections();

  const dispatch = useDispatch();

  useScrollTopOnLoad();

  const queryClient = useQueryClient();
  useWebSocket({
    channel: [Channel.INQUIRY, Channel.SIGNATURE_PROCESS],
    params: {
      inquiry_id: id,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
        queryClient.invalidateQueries(useInquirySignees.getKey({ id }));
      }
    },
  });

  const canShowProgress = sectionsDone + sectionsOpen > 0;

  return (
    <article>
      <InquiryDetailsHeader />
      <Divider mt={4} mb={8} color="border.darkGrey" />
      <SummaryCard
        completedValue={sectionsCompletedValue}
        progressIncrement={sectionsDone}
        progressTotal={sectionsDone + sectionsOpen}
        isCompleted={sectionsOpen === 0}
        openTaskId={firstOpenTask}
        canShowProgress={canShowProgress}
      />

      {Object.values(sections)
        .filter(({ visible }) => visible)
        .map(({ completedValue, increment, total, type, isPending, data }, index) => {
          const sectionProps = {
            completedValue,
            isFirst: index === 0,
            isLast: sectionsOpen !== 0 && index === lastItemIndex,
            isCompleted: completedValue === 100,
            progressIncrement: increment,
            progressTotal: total,
            id: type,
            data,
            isPending,
          };

          switch (type) {
            case ProgressSectionType.NON_BINDING_OFFER:
              return (
                <NonBindingOfferStep
                  actionUrl={paths.operation.inquiryDetails.nonBindingOffer(id)}
                  {...sectionProps}
                />
              );
            case ProgressSectionType.INVITE_CUSTOMER:
              return <InviteCustomerStep {...sectionProps} />;
            case ProgressSectionType.TRIGGER_KYC:
              return <TriggerKycStep {...sectionProps} />;
            case ProgressSectionType.CREFO_QUERY:
              return <CrefoQueryStep {...sectionProps} />;
            case ProgressSectionType.DIGITIZATION:
              return <SelectionForDigitization {...sectionProps} />;
            case ProgressSectionType.DIGITAL_PROCESSING:
              return <DigitalProcessingStep {...sectionProps} />;
            case ProgressSectionType.INCONSISTENCY_REPORT:
              return <InconsistencyStep {...sectionProps} />;
            case ProgressSectionType.TRANSPARENCY_COMPANY:
              return <TransparencyCompanySelectionStep {...sectionProps} />;
            case ProgressSectionType.UPLOAD_OFFER:
              return (
                <UploadOfferStep
                  actionUrl={paths.operation.inquiryDetails.uploadOffer.replace(':id', id)}
                  {...sectionProps}
                />
              );
            case ProgressSectionType.AUTO_MERGE:
              return <AutoMergeAction {...sectionProps} />;
            case ProgressSectionType.LEGAL_REPRESENTATION_CORRECTION:
              return (
                <LegalRepresentationCorrectionStep
                  actionUrl={paths.operation.inquiryDetails.legalRepresentationCorrection(id)}
                  {...sectionProps}
                />
              );
            case ProgressSectionType.TRIGGER_MARKET_RESEARCH:
              return <TriggerMarketResearchStep {...sectionProps} />;
            case ProgressSectionType.ASSIGN_INQUIRY_TO_REAL_ESTATE_EXPERT:
              return <AssignInquiryToRealEstateExpertStep {...sectionProps} />;
            case ProgressSectionType.TRIGGER_PROFITABILITY_CALCULATION:
              return <TriggerProfitabilityCalculationStep {...sectionProps} />;
            case ProgressSectionType.TRIGGER_QUICK_CHECK:
              return <TriggerQuickCheckStep {...sectionProps} />;
            case ProgressSectionType.RESOLVE_KYC_DATABANK:
              return (
                <ResolveKycDataBankStep
                  actionUrl={paths.operation.inquiryDetails.kycDataBankResolution(id)}
                  {...sectionProps}
                />
              );
            case ProgressSectionType.SIGN_CONTRACT:
              return <SignContractStep {...sectionProps} isReadonly />;
            default:
              return null;
          }
        })}
      {sectionsOpen === 0 && canShowProgress && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}

      <Actions />
      <ForwardInquiry />
      <Communication />
      <CloseInquiry />
    </article>
  );
};
