import * as React from 'react';

import { Box, Flex, Stack, VisuallyHidden } from '@chakra-ui/react';

import { FONT_WEIGHT_BOLD, FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { mapToNumber } from 'pages/operationPortal/CompaniesDetails/helpers/riskClassMap';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { TextComponent } from 'theme/components/Text';
import { textStyle } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';

import { RiskCard, RiskContainer, RiskHeading } from './RiskCard/RiskCard';

type RiskClasses = 'one' | 'two' | 'three' | 'four' | 'five' | 'six';

type SingleClassProps = {
  number: number;
  color: RiskClasses;
};

interface Single extends SingleClassProps {
  isFilled?: boolean;
  level: number;
}

const riskClassValues: Array<SingleClassProps> = [
  { number: 1, color: 'one' },
  { number: 2, color: 'two' },
  { number: 3, color: 'three' },
  { number: 4, color: 'four' },
  { number: 5, color: 'five' },
  { number: 6, color: 'six' },
];

const marketlassValues: Array<SingleClassProps> = [
  { number: 1, color: 'five' },
  { number: 2, color: 'four' },
  { number: 3, color: 'three' },
  { number: 4, color: 'two' },
  { number: 5, color: 'one' },
];

const SingleClass = ({ number, color, isFilled, level }: Single) => {
  const boxColor = isFilled ? `riskClass.${color}` : 'riskClass.none';

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" flex="1">
      <TextComponent
        as="span"
        mb="1"
        color={level === number ? 'text.primary' : 'text.tertiary'}
        fontWeight={level === number ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR}
      >
        {number}
      </TextComponent>
      <Box as="span" w="100%" h="10px" bgColor={boxColor} />
    </Flex>
  );
};

interface Props {
  heading: string;
  isMarketpage?: boolean;
  tooltip?: string[];
  explanationTextList?: string[];
  report: any;
  headerPadding?: number;
  isRiskAnalysisPage?: boolean;
}

export const RiskClass: React.FC<Props> = ({
  isMarketpage,
  explanationTextList,
  heading,
  report,
  tooltip,
  headerPadding = 1,
  isRiskAnalysisPage,
}: Props) => {
  const riskLevel =
    typeof report === 'number' ? report : mapToNumber(report?.solvencyFinanceIndustry?.class);

  const t = useTranslations();

  return (
    <RiskCard
      probabilityOfDefault={report?.solvencyFinanceIndustry?.pdRange}
      timeOfMeasurement={report?.createdAt}
      isMarketpage={isMarketpage}
      isRiskAnalysisPage={isRiskAnalysisPage}
    >
      <RiskHeading>
        <Box pt={headerPadding}>{heading}</Box>
        {tooltip && tooltip.length > 0 && (
          <Box position="absolute" right={1} top={1}>
            <PopoverComponent
              ml={1}
              text={tooltip[riskLevel > 0 ? riskLevel - 1 : 0]}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} display="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          </Box>
        )}

        <VisuallyHidden>{riskLevel > 0 ? riskLevel : '–'}</VisuallyHidden>
      </RiskHeading>
      <RiskContainer>
        <Stack direction="row" spacing="1px" w="100%" role="presentation">
          {isMarketpage
            ? marketlassValues.map(({ number, color }, index) => (
                <SingleClass
                  color={marketlassValues[riskLevel > 0 ? riskLevel - 1 : 0].color}
                  isFilled={riskLevel >= index + 1}
                  key={number}
                  level={riskLevel}
                  number={number}
                />
              ))
            : riskClassValues.map(({ number, color }, index) => (
                <SingleClass
                  color={color}
                  isFilled={riskLevel >= index + 1}
                  key={number}
                  level={riskLevel}
                  number={number}
                />
              ))}
        </Stack>
      </RiskContainer>
      {isMarketpage && (
        <TextComponent
          as="span"
          {...textStyle.sizeS}
          fontWeight={FONT_WEIGHT_BOLD}
          color="text.tertiary"
        >
          {explanationTextList && explanationTextList[riskLevel > 0 ? riskLevel - 1 : 0]}
        </TextComponent>
      )}
    </RiskCard>
  );
};
