import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import { FAVORED_DECISION_DATE } from 'modules/Inquiry/Form/formFields';

const FavoredDecisionDate = () => {
  return (
    <DayPickerWithField
      name={FAVORED_DECISION_DATE}
      minDate={new Date()}
      noCaption
      data-testid={'select-field'}
    />
  );
};

export default FavoredDecisionDate;
