import React, { FocusEventHandler } from 'react';

import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

import CheckmarkIcon from '../Icon/CheckmarkIcon';

type props = {
  icon?: React.ReactElement;
  isChecked?: boolean;
  disabled?: boolean;
  onChange?: Function;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  size?: 'sm' | 'lg';
  children: React.ReactNode | string;
  defaultChecked?: boolean;
  value?: boolean;
  name?: string;
};

export const CheckboxComponent = ({
  icon = <CheckmarkIcon />,
  isChecked,
  defaultChecked,
  disabled = false,
  onChange,
  onBlur,
  size = 'lg',
  children,
  value,
  name,
  ...restProps
}: props) => {
  return (
    <CheckboxGroup size={'3rem'}>
      <Checkbox
        checked={value}
        isChecked={isChecked !== undefined ? isChecked : !!value}
        defaultChecked={!!value}
        onChange={(ev) => (onChange ? onChange(ev.target && ev.target.checked) : undefined)}
        onBlur={onBlur}
        icon={icon}
        size={size}
        isDisabled={disabled}
        spacing={0}
        data-testid={name}
        {...restProps}
      >
        {children}
      </Checkbox>
    </CheckboxGroup>
  );
};
