import styled from 'styled-components';

import FormRowBase from 'components/FormRow';

export const StyledFormRow = styled(FormRowBase)`
  flex-direction: column;
`;

export const StyledHalfRow = styled.div`
  width: 50%;
`;
