import React from 'react';

import { useSelector } from 'react-redux';

import EditInquiryLink from 'components/EditInquiryLink';
import { DetailedInformationWrapper as Wrapper } from 'modules/InquiryDetails/DetailedInformation/styles';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import DetailedInquiryInformationSections from './DetailedInquiryInformationSections';

interface Props {
  pathToEditInquiry: string;
}

const DetailedInquiryInformation = ({ pathToEditInquiry }: Props) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const canEdit = useCanEdit();

  return (
    <article>
      <InquiryDetailsHeader>
        {canEdit && <EditInquiryLink to={pathToEditInquiry.replace(':id', inquiryId!)} />}
      </InquiryDetailsHeader>

      <Wrapper>
        <DetailedInquiryInformationSections />
      </Wrapper>
    </article>
  );
};

export default DetailedInquiryInformation;
