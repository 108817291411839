import * as React from 'react';

import { Box, Divider, VStack, Grid } from '@chakra-ui/react';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { IBlackWhiteReport } from 'models/CompanyDetails.model';
import { TextComponent } from 'theme/components/Text';
import { textStyle } from 'theme/themeConstants';

import { Light } from './Light';
import { useBlackWhiteReport } from './useBlackWhiteReport';
import { RiskCard, RiskContainer, RiskHeading } from '../RiskCard/RiskCard';

interface Props {
  heading: string;
  isRiskAnalysisPage?: boolean;
  report: IBlackWhiteReport;
}

export const BlackWhite: React.FC<Props> = ({ heading, report, isRiskAnalysisPage }: Props) => {
  const { colors } = useBlackWhiteReport(report);

  return (
    <RiskCard
      isRating
      timeOfMeasurement={report?.createdAt}
      isRiskAnalysisPage={isRiskAnalysisPage}
    >
      <RiskHeading>{heading}</RiskHeading>
      <RiskContainer>
        <VStack divider={<Divider borderColor="lightGray" />} w="full" alignItems="flex-start">
          <Grid templateColumns="1fr 1fr" width="full" h={10} gap={5}>
            {colors.map((color, index) => (
              <Light key={index} color={color} />
            ))}
          </Grid>
          <Box>
            <TextComponent>
              {report?.riskJudgement?.meaning ?? (
                <TextComponent {...textStyle.sizeXxl} fontWeight={FONT_WEIGHT_BOLD}>
                  {'–'}
                </TextComponent>
              )}
            </TextComponent>
          </Box>
        </VStack>
      </RiskContainer>
    </RiskCard>
  );
};
