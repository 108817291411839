import { atLeastOne, fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING,
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
} from 'modules/Inquiry/Form/formFields';
import { selectAtLeastOne } from 'utils/validators';

export const validateProjectFinancingSection = (form) =>
  fieldsAreValid([PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING])({ form });

const fieldHasTruthyValue =
  (fieldName) =>
  ({ form }) =>
    Boolean(form && form.getState().values[fieldName]);

export const projectCalculationFields = [
  fieldHasTruthyValue(QUICK_CHECK_ROLES_PROPERTY_DEVELOPER),
  fieldHasTruthyValue(QUICK_CHECK_ROLES_PARTITION),
  fieldHasTruthyValue(QUICK_CHECK_ROLES_FILE_HALTER),
];

export const validateProjectCalculationSection = (form, errorMessage) => {
  const validator = () => atLeastOne(...projectCalculationFields)({ form });
  return selectAtLeastOne(validator, errorMessage)();
};
