import { dzbFields } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateDirectDebitMandateSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: directDebitMandateSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { iban, bic, bankName } = dzbFields.companyDataPage.directDebitMandateSection;

export const directDebitMandateSectionValidationMap = {
  [iban]: fieldValidators.bank().isValidIban().required(),
  [bic]: () => true, // no-op to allow validation immediately on the field to take over
  [bankName]: fieldValidators.string().required(),
};
