import React, { useEffect } from 'react';

import styled from 'styled-components/macro';

import { callReverseApi, API_METHODS } from 'api/apiService';
import { LOAD_INQUIRY_SETTINGS } from 'api/CompeonReverseApi/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';
import Spinner from 'components/Spinner';
import { usePartnerConfig } from 'config/partner/hooks';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const StyledSpinnerWrapper = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
`;

const loadInquirySettingsAction = (partnerId) =>
  callReverseApi({
    url: endpoints.PARTNERS.INQUIRY_SETTINGS.compose({ params: { id: partnerId } }),
    method: API_METHODS.GET,
    actionName: LOAD_INQUIRY_SETTINGS,
  });

const InquirySettingsProvider = ({ children }) => {
  const { isPending, makeCall } = useDispatchApiCall({ isPendingInitially: true });
  const {
    details: { id: partnerId },
  } = usePartnerConfig();

  useEffect(() => {
    if (partnerId) {
      const load = async () => {
        const { error } = await makeCall(loadInquirySettingsAction(partnerId));
        if (error) {
          throw new Error('Inquiry settings loading failed');
        }
      };
      load();
    }
  }, [partnerId, makeCall]);

  if (isPending) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }

  return children;
};

export default InquirySettingsProvider;
