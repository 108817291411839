import React from 'react';

import { ListItem, UnorderedList } from '@chakra-ui/react';

import Condition from 'components/Condition';
import { translations } from 'new/form/common/types';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const translationKeys = translations.pages.inquiryDetails.dashboard.actions.forwardToCompeon.error;

export const ForwardingBlockedReasons = () => {
  const t = useTranslations();

  const { reasons } = useInquiryPermissions();

  return (
    <Condition condition={reasons.length > 0}>
      <TextComponent>{t(translationKeys.title)}</TextComponent>
      <UnorderedList spacing={1} marginTop={2}>
        {reasons.map((reason) => (
          <ListItem key={reason}>
            <TextComponent>{t(translationKeys[reason])}</TextComponent>
          </ListItem>
        ))}
      </UnorderedList>
    </Condition>
  );
};
