import * as React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { inconsistencyReportAction } from 'store/inquiryDetails/actions';
import { getInconsistencyReportUrl } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

type InconsistencyProps = {
  isFirst: boolean;
  isLast: boolean;
  isCompleted: boolean;
  completedValue: number;
  progressIncrement: number;
  progressTotal: number;
  id?: string;
};

export const InconsistencyStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: InconsistencyProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.inconsistency');
  const { makeCall, isPending: isLoading } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: true,
    showErrorNotification: false,
  });
  const actionUrl = useSelector(getInconsistencyReportUrl);
  const { id: inquiryId } = useParams<{ id: string }>();

  const fetchReport = async () => {
    const { error } = await makeCall(inconsistencyReportAction(inquiryId));
    if (error) {
      return;
    }
  };

  React.useEffect(() => {
    fetchReport();
    // eslint-disable-next-line
  }, []);

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>

          <TextComponent mb={6}>
            <MarkdownWrapper text={t('description')} />
          </TextComponent>

          <ButtonComponent
            leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
            variant="primary"
            as="a"
            target="_blank"
            disabled={!actionUrl}
            isLoading={isLoading}
            href={actionUrl}
          >
            {t('action')}
          </ButtonComponent>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
