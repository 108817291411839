import React from 'react';

import MultiselectField from 'components/PortalFormFields/SelectField/Multiselect';
import { FieldTypeComponentProps } from 'modules/FormGenerator/propTypes';

const MultiselectTypeField = ({ field: { options, placeholder, hasError, value }, onChange }) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <MultiselectField
      options={options}
      placeholder={placeholder}
      onSelect={onChange}
      optionOnSelect={(o) => o.value}
      hasError={hasError}
      value={value}
    />
  );
};

MultiselectTypeField.propTypes = FieldTypeComponentProps;

export default MultiselectTypeField;
