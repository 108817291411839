import React from 'react';

import { HIRE_PURCHASE_ADVANCE_PAYMENT } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';

const HirePurchaseAdvancePayment = () => {
  return <AmountWithField name={HIRE_PURCHASE_ADVANCE_PAYMENT} optional />;
};

export default HirePurchaseAdvancePayment;
