import {
  SECURITIES_TYPE__BUILDING_LOAN_CESSION,
  SECURITIES_TYPE__CREDIT_BALANCES_CESSION,
  SECURITIES_TYPE__FINANCING_OBJECT,
  SECURITIES_TYPE__FINANCING_OBJECT_MORTGAGE,
  SECURITIES_TYPE__GUARANTEE,
  SECURITIES_TYPE__LIFE_INSURANCE_CESSION,
  SECURITIES_TYPE__MORTGAGE,
  SECURITIES_TYPE__RECEIVABLES_ASSIGNMENT,
  SECURITIES_TYPE__SECURITIES_TRANSFER,
} from 'modules/Inquiry/Form/formFields';

export default (formatMessage) =>
  [
    {
      value: SECURITIES_TYPE__FINANCING_OBJECT,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.financingObject',
    },
    {
      value: SECURITIES_TYPE__GUARANTEE,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.guarantee',
    },
    {
      value: SECURITIES_TYPE__MORTGAGE,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.mortgage',
    },
    {
      value: SECURITIES_TYPE__LIFE_INSURANCE_CESSION,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.lifeInsuranceCession',
    },
    {
      value: SECURITIES_TYPE__BUILDING_LOAN_CESSION,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.buildingLoanCession',
    },
    {
      value: SECURITIES_TYPE__CREDIT_BALANCES_CESSION,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.creditBalancesCession',
    },
    {
      value: SECURITIES_TYPE__SECURITIES_TRANSFER,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.securitiesTransfer',
    },
    {
      value: SECURITIES_TYPE__RECEIVABLES_ASSIGNMENT,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.receivablesAssignment',
    },
    {
      value: SECURITIES_TYPE__FINANCING_OBJECT_MORTGAGE,
      id: 'pages.requestDetails.sections.securities.fields.securitiesType.options.financingObjectMortgage',
    },
  ].map(({ value, id }) => ({ value, label: formatMessage({ id }) }));
