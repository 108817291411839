import {
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  PROFITABILITY_SAFETY_MARKDOWN,
  PROFITABILITY_EXTRA_MARKDOWN,
  PROFITABILITY_PURCHASE_PRICE,
  PROFITABILITY_LEGAL_FEES,
  PROFITABILITY_AGENT_FEES,
  PROFITABILITY_REAL_ESTATE_TRANSFER_TAX,
  PROFITABILITY_MEASUREMENT_COSTS,
  PROFITABILITY_OTHER_FEES,
  PROFITABILITY_OTHER_PURCHASE_COSTS,
  PROFITABILITY_OTHER_COSTS,
  PROFITABILITY_COMPENSATIONS,
  PROFITABILITY_LAND_DEVELOPMENT_COSTS,
  PROFITABILITY_PUBLIC_DISPOSAL,
  PROFITABILITY_NON_PUBLIC_DISPOSAL,
  PROFITABILITY_MARKETING_SALES_COSTS,
  PROFITABILITY_BUFFER,
  PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL,
  PROFITABILITY_FINANCING_COSTS_FEES_MANUAL,
  PROFITABILITY_THIRD_PARTY_FINANCING_COSTS,
  PROFITABILITY_ADDITIONAL_COSTS,
  PROFITABILITY_ARCHITECT_COSTS,
  PROFITABILITY_OTHER_CONSTRUCTION_COSTS,
  PROFITABILITY_OUTDOOR_FACILITIES_COSTS,
  PROFITABILITY_CONSTRUCTION_COSTS,
  PROFITABILITY_OTHER_DEVELOPMENT_COSTS,
  PROFITABILITY_PRESALES_AREA_RESIDENTIAL,
  PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM,
  PROFITABILITY_PRESALES_AREA_COMMERCIAL,
  PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM,
  PROFITABILITY_NUMBER_OF_PARKING_LOTS,
  PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE,
  PROFITABILITY_NUMBER_OF_GARAGES,
  PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE,
  PROFITABILITY_FINANCING_FEE_BUILDING,
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_INTEREST_RATE_BUILDING,
  PROFITABILITY_FINANCING_FEE_UNIT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_INTEREST_RATE_UNIT,
  PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_INTEREST_RATE_RENT,
  PROFITABILITY_INTEREST_RATE_RENT_SHORT,
  PROFITABILITY_REPAYMENT_RATE_RENT,
  PROFITABILITY_USUAL_SALES_COST_RATE,
  PROFITABILITY_OPERATING_COSTS_RESIDENTIAL,
  PROFITABILITY_OPERATING_COSTS_COMMERCIAL,
  PROFITABILITY_TAX_RATE_IN_PERCENT,
  PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO,
  PROFITABILITY_FUTURE_RENTAL_INCOME_PARKING_UNIT,
  PROFITABILITY_FUTURE_RENTAL_INCOME_GARAGE_PARKING_UNIT,
  PROFITABILITY_CURRENT_RENTAL_INCOME_PARKING_UNIT,
  PROFITABILITY_CURRENT_RENTAL_INCOME_GARAGE_PARKING_UNIT,
  PROFITABILITY_FUTURE_PARKING_SPACE_REVENUE_UNIT,
  PROFITABILITY_FUTURE_GARAGE_PARKING_SPACE_REVENUE_UNIT,
  PROFITABILITY_FUTURE_PARKING_SPACE_REVENUE_BUILDING,
  PROFITABILITY_FUTURE_GARAGE_PARKING_SPACE_REVENUE_BUILDING,
  PROFITABILITY_CURRENT_PARKING_SPACE_REVENUE_UNIT,
  PROFITABILITY_CURRENT_GARAGE_PARKING_SPACE_REVENUE_UNIT,
  PROFITABILITY_CURRENT_PARKING_SPACE_REVENUE_BUILDING,
  PROFITABILITY_CURRENT_GARAGE_PARKING_SPACE_REVENUE_BUILDING,
} from 'modules/Inquiry/Form/formFields';

export const editMarketValueReeProfitabillityLabelsMap: { [key: string]: string } = {
  [CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM]:
    'pages.editMarketResearchValues.sections.rentPrices.fields.commercialRentPerSqm.caption',
  [CURRENT_USAGE_EXISTING_RENT_PER_SQM]:
    'pages.editMarketResearchValues.sections.rentPrices.fields.existingRentPerSqm.caption',
  [CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING]:
    'pages.editMarketResearchValues.sections.salesPrice.fields.salesPricePerSqm.caption',
  [CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT]:
    'pages.editMarketResearchValues.sections.salesPrice.fields.salesPricePerSqm.caption',
  [CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM]:
    'pages.editMarketResearchValues.sections.rentPrices.fields.rentPerSqm.caption',
  [CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.unitsSalesPricesPerSqm.caption',
  [CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.buildingSalesPricesPerSqm.caption',
  [CURRENT_USAGE_REE_PARKING_LOT_VALUE]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.parkingLotMarketValue.caption',
  [CURRENT_USAGE_REE_GARAGE_VALUE]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.garageMarketValue.caption',
  [CURRENT_USAGE_REE_GARAGE_RENT]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.garageRent.caption',
  [CURRENT_USAGE_REE_PARKING_LOT_RENT]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.parkingLotRent.caption',
  [CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.residentialRentPerSqm.caption',
  [CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.commercialRentPerSqm.caption',
  [FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM]:
    'pages.editMarketResearchValues.sections.rentPrices.fields.commercialRentPerSqm.caption',
  [FUTURE_USAGE_EXISTING_RENT_PER_SQM]:
    'pages.editMarketResearchValues.sections.rentPrices.fields.existingRentPerSqm.caption',
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING]:
    'pages.editMarketResearchValues.sections.salesPrice.fields.salesPricePerSqm.caption',
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT]:
    'pages.editMarketResearchValues.sections.salesPrice.fields.salesPricePerSqm.caption',
  [FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM]:
    'pages.editMarketResearchValues.sections.rentPrices.fields.rentPerSqm.caption',
  [FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.unitsSalesPricesPerSqm.caption',
  [FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.buildingSalesPricesPerSqm.caption',
  [FUTURE_USAGE_REE_PARKING_LOT_VALUE]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.parkingLotMarketValue.caption',
  [FUTURE_USAGE_REE_GARAGE_VALUE]:
    'pages.editRealEstateExpertOpinion.sections.salesPrices.fields.garageMarketValue.caption',
  [FUTURE_USAGE_REE_GARAGE_RENT]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.garageRent.caption',
  [FUTURE_USAGE_REE_PARKING_LOT_RENT]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.parkingLotRent.caption',
  [FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.residentialRentPerSqm.caption',
  [FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM]:
    'pages.editRealEstateExpertOpinion.sections.rentPrices.fields.commercialRentPerSqm.caption',
  [PROFITABILITY_SAFETY_MARKDOWN]:
    'pages.editProfitabilityCalculations.lendingValueDetermination.sections.parameterVolume.fields.safetyMarkdown.caption',
  [PROFITABILITY_EXTRA_MARKDOWN]:
    'pages.editProfitabilityCalculations.lendingValueDetermination.sections.parameterVolume.fields.extraMarkdown.caption',
  // investments costs Start
  [PROFITABILITY_PURCHASE_PRICE]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.purchasePrice.caption',
  [PROFITABILITY_LEGAL_FEES]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.legalFee.caption',
  [PROFITABILITY_AGENT_FEES]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.agentFee.caption',
  [PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.realEstateTransferTax.caption',
  [PROFITABILITY_MEASUREMENT_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.measurementCosts.caption',
  [PROFITABILITY_OTHER_FEES]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.otherFees.caption',
  [PROFITABILITY_OTHER_PURCHASE_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.otherPurchaseCosts.caption',
  [PROFITABILITY_OTHER_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.otherCosts.caption',
  [PROFITABILITY_COMPENSATIONS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.compensations.caption',
  [PROFITABILITY_LAND_DEVELOPMENT_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.landDevelopmentCosts.caption',
  [PROFITABILITY_PUBLIC_DISPOSAL]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.publicDisposal.caption',
  [PROFITABILITY_NON_PUBLIC_DISPOSAL]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.nonPuclicDisposal.caption',
  [PROFITABILITY_OTHER_DEVELOPMENT_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingPlotCosts.fields.otherDevelopmentCosts.caption',
  [PROFITABILITY_CONSTRUCTION_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingCosts.fields.constructionCosts.caption',
  [PROFITABILITY_OUTDOOR_FACILITIES_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingCosts.fields.outdoorFacilitiesCosts.caption',
  [PROFITABILITY_OTHER_CONSTRUCTION_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.buildingCosts.fields.otherConstructionCosts.caption',
  [PROFITABILITY_ARCHITECT_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.ancillaryConstructionCosts.fields.architectCosts.caption',
  [PROFITABILITY_ADDITIONAL_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.ancillaryConstructionCosts.fields.additionalCosts.caption',
  [PROFITABILITY_THIRD_PARTY_FINANCING_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.financingCosts.fields.thirdPartyFinancingCosts.caption',
  [PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.financingCosts.fields.financingCostsInterestsManual.caption',
  [PROFITABILITY_FINANCING_COSTS_FEES_MANUAL]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.financingCosts.fields.financingCostsFeesManual.caption',
  [PROFITABILITY_BUFFER]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.otherCosts.fields.buffer.caption',
  [PROFITABILITY_MARKETING_SALES_COSTS]:
    'pages.editProfitabilityCalculations.investmentCosts.sections.otherCosts.fields.marketingSalesCosts.caption',
  // investments costs END
  [PROFITABILITY_PRESALES_AREA_RESIDENTIAL]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleResidential.fields.presaleAreaResidential.caption',
  [PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleResidential.fields.presalesPriceResidentialPerSqm.caption',
  [PROFITABILITY_PRESALES_AREA_COMMERCIAL]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleCommercial.fields.presalesAreaCommercial.caption',
  [PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleCommercial.fields.presalesPriceCommercialPerSqm.caption',
  [PROFITABILITY_NUMBER_OF_PARKING_LOTS]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleParkingLots.fields.numberOfParkingLots.caption',
  [PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleParkingLots.fields.presalesPriceParkingLotPerPiece.caption',
  [PROFITABILITY_NUMBER_OF_GARAGES]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleGarages.fields.numberOfGarages.caption',
  [PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE]:
    'pages.editProfitabilityCalculations.presalesData.sections.presaleGarages.fields.presalesPriceGaragePerPiece.caption',
  // translations for financing parameters START
  [PROFITABILITY_FINANCING_FEE_BUILDING]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asBuilding.fields.financingFeeBuilding.caption',
  [PROFITABILITY_FINANCING_DURATION_BUILDING]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asBuilding.fields.financingDurationBuilding.caption',
  [PROFITABILITY_INTEREST_RATE_BUILDING]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asBuilding.fields.interestRateBuilding.caption',
  [PROFITABILITY_FINANCING_FEE_UNIT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asUnit.fields.financingFeeUnit.caption',
  [PROFITABILITY_FINANCING_DURATION_UNIT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asUnit.fields.financingDurationUnit.caption',
  [PROFITABILITY_INTEREST_RATE_UNIT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asUnit.fields.interestRateUnit.caption',
  [PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asRent.fields.financingFeeInPercentRent.caption',
  [PROFITABILITY_FINANCING_DURATION_RENT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asRent.fields.financingDurationRent.caption',
  [PROFITABILITY_INTEREST_RATE_RENT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asRent.fields.interestRateRent.caption',
  [PROFITABILITY_INTEREST_RATE_RENT_SHORT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asRent.fields.interestRateRentShort.caption',
  [PROFITABILITY_REPAYMENT_RATE_RENT]:
    'pages.editProfitabilityCalculations.financingParameters.sections.asRent.fields.repaymentRateRent.caption',
  // translations for financing parameters END
  [PROFITABILITY_USUAL_SALES_COST_RATE]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.usualSalesCostRate.fields.usualSalesCostsPrecentage.caption',
  [PROFITABILITY_FUTURE_PARKING_SPACE_REVENUE_UNIT]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.futureMarketValuationParkingSales.fields.futureParkingSpaceRevenueUnit.caption',
  [PROFITABILITY_FUTURE_GARAGE_PARKING_SPACE_REVENUE_UNIT]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.futureMarketValuationParkingSales.fields.futureGarageParkingSpaceRevenueUnit.caption',
  [PROFITABILITY_FUTURE_PARKING_SPACE_REVENUE_BUILDING]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.futureMarketValuationParkingSales.fields.futureParkingSpaceRevenueBuilding.caption',
  [PROFITABILITY_FUTURE_GARAGE_PARKING_SPACE_REVENUE_BUILDING]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.futureMarketValuationParkingSales.fields.futureGarageParkingSpaceRevenueBuilding.caption',
  [PROFITABILITY_CURRENT_PARKING_SPACE_REVENUE_UNIT]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.currentMarketValuationParkingSales.fields.currentParkingSpaceRevenueUnit.caption',
  [PROFITABILITY_CURRENT_GARAGE_PARKING_SPACE_REVENUE_UNIT]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.currentMarketValuationParkingSales.fields.currentGarageParkingSpaceRevenueUnit.caption',
  [PROFITABILITY_CURRENT_PARKING_SPACE_REVENUE_BUILDING]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.currentMarketValuationParkingSales.fields.currentParkingSpaceRevenueBuilding.caption',
  [PROFITABILITY_CURRENT_GARAGE_PARKING_SPACE_REVENUE_BUILDING]:
    'pages.editProfitabilityCalculations.salesCostRate.sections.currentMarketValuationParkingSales.fields.currentGarageParkingSpaceRevenueBuilding.caption',
  // translations for profitability rental parameters Start
  [PROFITABILITY_OPERATING_COSTS_RESIDENTIAL]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.calculationParameters.fields.operatingCostsResidential.caption',
  [PROFITABILITY_OPERATING_COSTS_COMMERCIAL]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.calculationParameters.fields.operatingCostsCommercial.caption',
  [PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.calculationParameters.fields.depreciationsPerYearInEuro.caption',
  [PROFITABILITY_TAX_RATE_IN_PERCENT]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.calculationParameters.fields.taxRateInPercent.caption',
  [PROFITABILITY_FUTURE_RENTAL_INCOME_PARKING_UNIT]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.marketValuationParking.fields.futureRentalIncomeParkingUnit.caption',
  [PROFITABILITY_FUTURE_RENTAL_INCOME_GARAGE_PARKING_UNIT]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.marketValuationParking.fields.futureRentalIncomeGarageParkingUnit.caption',
  [PROFITABILITY_CURRENT_RENTAL_INCOME_PARKING_UNIT]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.marketValuationParking.fields.currentRentalIncomeParkingUnit.caption',
  [PROFITABILITY_CURRENT_RENTAL_INCOME_GARAGE_PARKING_UNIT]:
    'pages.editProfitabilityCalculations.rentalParameters.sections.marketValuationParking.fields.currentRentalIncomeGarageParkingUnit.caption',
  // translations for profitability rental parameters End
};
