import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useLoadProfitabilityData, MAX_RETRIES } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import ProjectCostingHeader from 'pages/operationPortal/PlanningEvaluation/SharedComponent/ProjectCostingHeader';
import UsageTypeRadio from 'pages/operationPortal/PlanningEvaluation/SharedComponent/UsageTypeRadio';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import FurtherObjectDetails from './FurtherObjectDetails';
import ObjectEvaluation from './ObjectEvaluation';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';

interface BankValuesTopBoxes {
  commercial_space: number;
  residential_space: number;
  bank_rental_income: string;
  bank_total_sales_revenue: string;
}

export interface BankValuesTopBoxValues {
  current: BankValuesTopBoxes;
  future: BankValuesTopBoxes;
}

const BankValues = () => {
  const { error } = useToasts();
  const filterData = useSelector(getMarketDataFilterLegacy);
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.bankValues');
  const errorsT = useTranslations('errors');
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      bankValuesTopBoxes: response.data.attributes.computed_details.bank_values_top_boxes,
      lastUpdatedAt: response.data.attributes.details.bank_values_updated_at,
    }),
    retry: (failureCount, responseError) => {
      if (responseError && failureCount === MAX_RETRIES) {
        error({ description: errorsT('requestFailed') });
        return false;
      }
      return true;
    },
  });

  const topBoxValues = data?.bankValuesTopBoxes[filterData?.usageType];

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Box>
      <ProjectCostingHeader
        heading={t('title')}
        subheading={
          data?.lastUpdatedAt ? `${t('updatedAt')} ${formatDateWithTime(data.lastUpdatedAt)}` : ''
        }
      />
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={false} />
      </Flex>
      <SummaryCards>
        <SummaryCard
          caption={t('summaryCards.sustainableSalesRevenue')}
          value={formatPrice(topBoxValues?.bank_total_sales_revenue, true)}
        />
        <SummaryCard
          caption={t('summaryCards.sustainableYearlyRent')}
          value={formatPrice(topBoxValues?.bank_rental_income, true)}
        />
        <SummaryCard
          caption={t('summaryCards.livingSpace')}
          value={topBoxValues?.residential_space ? `${topBoxValues?.residential_space} m²` : '0 m²'}
        />
        <SummaryCard
          caption={t('summaryCards.commercialSpace')}
          value={topBoxValues?.commercial_space ? `${topBoxValues?.commercial_space} m²` : '0 m²'}
        />
      </SummaryCards>
      <ObjectEvaluation />
      <FurtherObjectDetails />
    </Box>
  );
};

export default BankValues;
