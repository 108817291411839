import React from 'react';

import { useSelector } from 'react-redux';

import UsageTypeDetails, {
  ProjectFinancingUsageType,
} from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/UsageTypeDetails';
import { getInquiryProjectFinancingTypeOfUse } from 'store/inquiryDetails/selectors';

const ProjectFinancingUsageTypesDetails: React.FC = () => {
  const useTypes = useSelector(getInquiryProjectFinancingTypeOfUse);
  if (!useTypes || !useTypes.length) {
    return null;
  }

  // since this array won't change we can use index as key
  return (
    <div>
      {useTypes.map((useType: ProjectFinancingUsageType, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <UsageTypeDetails
          key={index}
          usageType={useType}
          isLastItem={index === useTypes.length - 1}
        />
      ))}
    </div>
  );
};

export default ProjectFinancingUsageTypesDetails;
