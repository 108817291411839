import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';
import { InquiryProcessValidations } from 'new/form/model';

import { validateTermsAndConditionsSection } from '../steps/companyData/sections/termsAndConditionsSection/validations';
import { validateWegDataCompletionSection } from '../steps/companyData/sections/wegDataCompletionSection/validations';
import { validateWegSerchSection } from '../steps/companyData/sections/wegSearchSection/validations';
import { validateFinancingNeedSection } from '../steps/financingNeed/sections/financingNeedSection/validations';
import { validatePurposeSection } from '../steps/financingNeed/sections/purposeSection/validations';
import { validateObjectInformationSection } from '../steps/objectInformation/sections/objectInformationSection/validations';
import { validateProjectInformationSection } from '../steps/objectInformation/sections/projectInformationSection/validations';
import { validateContactPersonSection } from '../steps/personalData/sections/contactPersonSection/validations';
import { validateLegalRepresentativesSection } from '../steps/personalData/sections/legalRepresentativesSection/validations';
import { validatePasswordSection } from '../steps/personalData/sections/passwordSection/validations';

const hausbankDefaultValidations: ValidationRecord<InquiryType.hausbank> = {
  objectInformationPage: {
    objectInformationSection: validateObjectInformationSection,
    projectInformationSection: validateProjectInformationSection,
  },
  companyDataPage: {
    wegSearchSection: validateWegSerchSection,
    wegDataCompletionSection: validateWegDataCompletionSection,
    termsAndConditionsSection: validateTermsAndConditionsSection,
  },
  financingNeedPage: {
    financingNeedSection: validateFinancingNeedSection,
    purposeSection: validatePurposeSection,
  },
  personalDataPage: {
    contactPersonSection: validateContactPersonSection,
    passwordSection: validatePasswordSection,
    legalRepresentativesSection: validateLegalRepresentativesSection,
  },
};

export const hausbankValidations: InquiryProcessValidations<InquiryType.hausbank> = {
  create: hausbankDefaultValidations,
  editCustomer: hausbankDefaultValidations,
  editOperation: hausbankDefaultValidations,
};
