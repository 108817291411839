import React from 'react';

import { Grid } from '@chakra-ui/react';
import { WrappedComponentProps } from 'react-intl';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import PERMISSIONS from 'constants/user/permissions';
import disableInquiryDraft from 'modules/Inquiry/DraftMode/disableInquiryDraft';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import SubmitOffer from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/SubmitOffer';
import WelcomeCall from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/WelcomeCall';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import { HorizontalSection } from 'theme/components/HorizontalSection';
import { withIntl } from 'utils/intl';
import { userIsBankAdvisor } from 'utils/user/conditionals';

const Actions = ({ intl: { formatMessage } }: WrappedComponentProps) => {
  const t = (msgId: string) =>
    formatMessage({ id: `pages.inquiryDetails.dashboard.actions.${msgId}` });

  /*
  We have to check user role and inquiryType
  because we don't want to show the whole section
  for default inquiries for bank advisors [CRV-3330]
*/

  const inquiryType = useSelector(getInquiryFormTypeSelector);
  const hasBankAdvisorRole = userIsBankAdvisor();
  const isDefaultInquiry = InquiryType.default === inquiryType;

  if (hasBankAdvisorRole && !isDefaultInquiry) {
    return null;
  }

  return (
    <HorizontalSection label={t('title')}>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(auto-fit, minmax(min(40%, 100%), 1fr))',
        }}
        gap={12}
      >
        <WelcomeCall />
        <SubmitOffer />
      </Grid>
    </HorizontalSection>
  );
};

export default compose<React.ComponentType>(
  withIntl,
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.ACTIONS.DISPLAY),
  disableInquiryDraft,
)(Actions);
