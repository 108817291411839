import styled from 'styled-components';

import { StyledDetailedSection } from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection/styles';

export const StyledButtonsWrapper = styled.div`
  display: flex;
  max-width: 80%;
  margin-top: 2.375rem;
`;

export const StyledConsentDescription = styled.div`
  margin: 1.5rem 0;
  line-height: 1.625;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledSection = styled(StyledDetailedSection)`
  padding-top: 0;
`;
