import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import { mmvFields } from '../../../../fields';

const fields = mmvFields.contractDetails.financingConditionsSection;

const translations =
  translationsObject.inquiryType.mmv.steps.contractDetails.sections.financingConditions.fields;

export const financingConditionsLabelsMap: LabelsMap = {
  [fields.runtime]: translations.runtime.caption,
  [fields.objectValue]: translations.objectValue.caption,
  [fields.downPayment]: translations.downPayment.caption,
  [fields.ratePerMonth]: translations.ratePerMonth.caption,
  [fields.remainingValue]: translations.remainingValue.caption,
};
