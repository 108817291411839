import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  CURRENT_USAGE_OPERATING_COSTS,
  CURRENT_USAGE_OPERATING_COSTS_ESTIMATION,
  CURRENT_USAGE_OPERATING_COSTS_MINIMUM,
  FUTURE_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_OPERATING_COSTS_ESTIMATION,
  FUTURE_USAGE_OPERATING_COSTS_MINIMUM,
  FUTURE_USAGE_VACANCY,
  FUTURE_USAGE_REMAINING_USAGE_DURATION,
  FUTURE_USAGE_PRESALES_RATE,
  FUTURE_USAGE_VISITED_DATE,
  FUTURE_USAGE_VISITED_BY,
} from 'modules/Inquiry/Form/formFields';

type DropDownsType = {
  type: string;
  name: string;
  caption: string;
  options: {
    value: string;
    label: string;
  }[];
}[];

type TextsType = {
  type: string;
  name: string;
  caption: string;
}[];

type DateType = {
  type: string;
  name: string;
  caption: string;
}[];

const dropdowns: DropDownsType = [
  {
    type: 'dropdown',
    caption: 'pages.editBankValuesAdditionalInformation.sections.fields.vacancy.caption',
    name: FUTURE_USAGE_VACANCY,
    options: [
      { value: 'structurally', label: 'strukturell' },
      { value: 'temporary', label: 'temporär' },
      { value: 'strategically', label: 'strategisch' },
      { value: 'fully_hired_out', label: 'voll vermietet' },
    ],
  },
];

const texts: TextsType = [
  {
    type: 'text',
    name: FUTURE_USAGE_OPERATING_COSTS_ESTIMATION,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.futureOperatingCostsEstimation.caption',
  },
  {
    type: 'text',
    name: FUTURE_USAGE_OPERATING_COSTS_MINIMUM,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.futureOperatingCostsMinimum.caption',
  },
  {
    type: 'text',
    name: CURRENT_USAGE_OPERATING_COSTS_ESTIMATION,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCostsEstimation.caption',
  },
  {
    type: 'text',
    name: CURRENT_USAGE_OPERATING_COSTS_MINIMUM,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCostsMinimum.caption',
  },
];

const dates: DateType = [
  {
    type: 'date',
    name: FUTURE_USAGE_VISITED_DATE,
    caption: 'pages.editBankValuesAdditionalInformation.sections.fields.visitedDate.caption',
  },
];

const mapBankValuesAdditionalInformationToEdit = () => {
  const sections = [
    {
      title: 'pages.editBankValuesAdditionalInformation.sections.title',
      fields: [
        FUTURE_USAGE_OPERATING_COSTS,
        texts[0],
        texts[1],
        CURRENT_USAGE_OPERATING_COSTS,
        texts[2],
        texts[3],
        dropdowns[0],
        FUTURE_USAGE_REMAINING_USAGE_DURATION,
        FUTURE_USAGE_PRESALES_RATE,
        dates[0],
        FUTURE_USAGE_VISITED_BY,
      ],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editBankValuesAdditionalInformation.heading',
      subHeading: 'pages.editBankValuesAdditionalInformation.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

export default mapBankValuesAdditionalInformationToEdit;
