import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { useFormConfig } from 'config/formConfig/hooks';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { OTHER_PURPOSE_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateOtherPurposeSection = (form) =>
  fieldIsValid(OTHER_PURPOSE_DESCRIPTION)({ form });

const OtherSection = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  return (
    <FormSection
      title={t('pages.financingNeed.sections.other.title')}
      sectionNumber={3}
      name={FormSections.otherPurpose}
    >
      <FormRow>
        <TextAreaWithField name={OTHER_PURPOSE_DESCRIPTION} optional={isBfsService} />
      </FormRow>
    </FormSection>
  );
};

export default OtherSection;
