import React from 'react';

import { Helmet } from 'react-helmet';

import { usePartnerConfig } from 'config/partner/hooks';

interface IPageTitle {
  title?: string;
}
// Helmet requires you to pass string in braces
// https://github.com/nfl/react-helmet/issues/274
const PageTitle = ({ title }: IPageTitle) => {
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  if (!partnerName) {
    return null; // use default title
  }
  return (
    <Helmet>
      <title>{`${partnerName} ${title ? `- ${title}` : ''}`}</title>
    </Helmet>
  );
};

export default PageTitle;
