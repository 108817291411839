import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { NewStaticFieldWithField } from 'components/StaticField';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { objectDescription, state, objectAlreadyDelivered, serialNumber } =
  mmvFields.contractDetails.financingObjectSection;

interface FinancingObjectSectionProps {
  sectionNumber?: number;
}
export const FinancingObjectSection = (props: FinancingObjectSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      title={t(translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.title)}
      name="financingObject"
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField name={objectDescription} />
        <NewStaticFieldWithField name={state} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={objectAlreadyDelivered}
          tooltip={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.fields
              .objectAlreadyDelivered.tooltip,
          )}
        />
        <InputWithField
          name={serialNumber}
          optional
          tooltip={t(
            translations.inquiryType.mmv.steps.contractDetails.sections.financingObject.fields
              .serialNumber.tooltip,
          )}
        />
      </FormRow>
    </FormSection>
  );
};
