import React from 'react';

import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import { Form } from 'react-final-form';

import { useLoadInquiriesStatuses } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import { DayPickerWithField } from 'components/DatePicker';
import FormRowBase from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import Spinner from 'components/Spinner';
import { useInquiriesListContext } from 'modules/InquiriesListProvider';
import { useInquiryStatusTranslator, INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_TABLE_HEADERS, INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { StyledHalfRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import {
  StyledFormRow,
  StyledNumberedSelectRow,
} from 'pages/operationPortal/OperationInquiryList/InquiryStateFilter/styles';
import { InquiryFilters } from 'pages/operationPortal/OperationInquiryList/PageHeading/InquiryFilters';
import { useTranslations } from 'utils/hooks/useTranslations';

const InquiryStateFilter = () => {
  const { data: statuses, isLoading } = useLoadInquiriesStatuses();
  const { tableOptions, setTableOptions } = useInquiriesListContext();
  const t = useTranslations();
  const translateStatus = useInquiryStatusTranslator();
  const orderedStates = _orderBy(statuses, [
    (option) =>
      [INQUIRY_STATUSES.CLOSED, INQUIRY_STATUSES.FULFILLED, INQUIRY_STATUSES.ARCHIVED].indexOf(
        option.status as INQUIRY_STATUSES,
      ),
  ]);

  const stateAllCount = statuses
    ? statuses
        .map((state: { counter: number }) => state.counter)
        .reduce((a: number, b: number) => a + b, 0)
    : [];

  const allState = {
    value: 'all',
    complexLabel: (
      <StyledNumberedSelectRow>
        <div>{translateStatus(INQUIRY_STATUSES.ALL)}</div>
        <span>{stateAllCount}</span>
      </StyledNumberedSelectRow>
    ),
    label: translateStatus(INQUIRY_STATUSES.ALL),
  };

  const statusesOptions = orderedStates.map(({ counter, status }) => ({
    value: status,
    complexLabel: (
      <StyledNumberedSelectRow>
        <div>{translateStatus(status as INQUIRY_STATUSES)}</div>
        <span>{counter}</span>
      </StyledNumberedSelectRow>
    ),
    label: translateStatus(status as INQUIRY_STATUSES),
  }));

  const filteredValues = { ...tableOptions.filterBy };

  const onSubmitFrom = (value: string) =>
    setTableOptions({
      currentPage: 1,
      filterBy: { ...tableOptions.filterBy, createdAtFrom: value },
    });
  const onSubmitTo = (value: string) =>
    setTableOptions({ currentPage: 1, filterBy: { ...tableOptions.filterBy, createdAtTo: value } });

  const onSubmitState = (state: string) => {
    setTableOptions({
      currentPage: 1,
      sortBy: INQUIRY_TABLE_HEADERS.CREATED_AT,
      filterBy: { ...tableOptions.filterBy, status: state },
    });
  };

  const countActiveFilters = (): string => {
    const count = Object.values(filteredValues).filter((filteredValue) => {
      return filteredValue;
    }).length;
    return count > 0 ? count.toString() : '';
  };

  if (isLoading) {
    return (
      <SpinnerWrapper data-testid="spinner">
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Form
      onSubmit={() => {}}
      initialValues={filteredValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <StyledFormRow>
            <FormRowBase alignItems="flex-start">
              <StyledHalfRow>
                <SelectWithField
                  name={INQUIRY_FILTERS.STATUS}
                  caption={t('pages.inquiryList.table.headers.status')}
                  options={[allState, ...statusesOptions]}
                  placeholder={t('placeholders.pleaseChoose')}
                  onChange={onSubmitState}
                  data-testid={'select-field-' + INQUIRY_FILTERS.STATUS}
                  isClearable
                />
              </StyledHalfRow>
              <StyledHalfRow>
                <DayPickerWithField
                  name={INQUIRY_FILTERS.CREATED_AT_FROM}
                  maxDate={
                    _isEmpty(values.createdAtTo)
                      ? new Date()
                      : new Date(values.createdAtTo as string)
                  }
                  caption={t('pages.inquiryList.filtersDrawer.fields.createdAtFrom.caption')}
                  onChange={onSubmitFrom}
                  isClearable
                />
              </StyledHalfRow>
              <StyledHalfRow>
                <DayPickerWithField
                  name={INQUIRY_FILTERS.CREATED_AT_TO}
                  maxDate={new Date()}
                  minDate={new Date(values.createdAtFrom as string)}
                  caption={t('pages.inquiryList.filtersDrawer.fields.createdAtTo.caption')}
                  onChange={onSubmitTo}
                  isClearable
                />
              </StyledHalfRow>
              <StyledHalfRow>
                <InquiryFilters badge={countActiveFilters()} />
              </StyledHalfRow>
            </FormRowBase>
          </StyledFormRow>
        </form>
      )}
    />
  );
};

export default InquiryStateFilter;
