import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import paths from 'constants/paths';
import mapBankValuesObjectDataToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapBankValuesObjectDataToEdit';
import { BANK_VALUES_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { getMarketDataFilterLegacy } from '../../../../../pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { formatPrice } from '../../../../../utils/valueFormats';
import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';

export interface ObjectEvaluationMappingProps {
  loan_value: string;
  fair_market_value: string;
  sqm_price_property: string;
  rent_garage_parking: string;
  sqm_rent_commercial: string;
  price_garage_parking: string;
  rent_outside_parking: string;
  sqm_price_commercial: string;
  sqm_rent_residential: string;
  price_outside_parking: string;
  sqm_price_residential: string;
}

export interface BankValuesTableData {
  id: string;
  label: string;
  value?: string;
  unit: string;
}

type ObjectEvaluationKey = keyof ObjectEvaluationMappingProps;

const orderedArray = [
  'sqm_price_residential',
  'sqm_price_commercial',
  'price_garage_parking',
  'price_outside_parking',
  'sqm_price_property',
  'fair_market_value',
  'loan_value',
  'sqm_rent_residential',
  'sqm_rent_commercial',
  'rent_garage_parking',
  'rent_outside_parking',
];

const mappedUnitObject: Record<ObjectEvaluationKey, string> = {
  sqm_price_residential: '€/m²',
  sqm_price_commercial: '€/m²',
  price_garage_parking: '€/Stück',
  price_outside_parking: '€/Stück',
  sqm_price_property: '€/m²',
  fair_market_value: '€',
  loan_value: '€',
  sqm_rent_residential: '€/m²',
  sqm_rent_commercial: '€/m²',
  rent_garage_parking: '€/Stück',
  rent_outside_parking: '€/Stück',
};

const useObjectEvaluationMapping = (objectData: ObjectEvaluationMappingProps) => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.bankValues');

  const mappedArray: Array<BankValuesTableData> = Object.keys(objectData).map((key) => {
    return {
      id: key,
      label: t(`tables.objectEvaluation.rowNames.${key}`),
      value: formatPrice(objectData[key as ObjectEvaluationKey], false),
      unit: mappedUnitObject[key as ObjectEvaluationKey],
    };
  });

  return orderedArray.map((item) => {
    return mappedArray.find((object) => {
      if (object.id === item) {
        return object;
      }
      return null;
    });
  });
};

const ObjectEvaluation = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.bankValues');
  const { data } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      evaluationData: response.data.attributes.details.bank_values_object_data,
    }),
  });
  const { editBankValueObjectDataDetails } = paths.operation.inquiryDetails.planningEvaluation;
  const { onEdit } = useEditFormConfig({
    pathToEdit: editBankValueObjectDataDetails(inquiryId),
    recordType: BANK_VALUES_RECORD_TYPE.OBJECT_DATA,
    editFormStructure: mapBankValuesObjectDataToEdit(),
  });

  const filterData = useSelector(getMarketDataFilterLegacy);
  const objectData = {
    current: data?.evaluationData.current as ObjectEvaluationMappingProps,
    future: data?.evaluationData.future as ObjectEvaluationMappingProps,
  };
  const mappedData = useObjectEvaluationMapping(objectData?.[filterData?.usageType]);

  const objectEvaluationTable = usePortalTable({
    data: mappedData,
    headers: [
      { key: 'label', text: '' },
      { key: 'value', text: t('tables.objectEvaluation.columNames.value') },
      { key: 'unit', text: t('tables.objectEvaluation.columNames.unit') },
    ],
  });

  return (
    <Box paddingTop={10}>
      <Card onEdit={onEdit} title={t('tables.objectEvaluation.title')}>
        <PortalTable
          {...objectEvaluationTable}
          columnStylesMap={{ value: { rightAligned: true } }}
          headerStylesMap={{
            value: { width: '10%' },
            unit: { width: '10%' },
            label: { width: '80%' },
          }}
        />
      </Card>
    </Box>
  );
};

export default ObjectEvaluation;
