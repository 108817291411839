import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import FileDownloadBlock from 'components/UploadBlock/NewFileRequestBlock/FileDownloadBlock';
import { StyledFileStatusLabel as StatusLabel } from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import { FileType, IFile } from 'models/File.model';
import { FileKind } from 'models/FileKind.type';
import { translations } from 'new/form/common/types';
import { StyledFilesSection as Section } from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/styles';
import {
  getAdditionalOperationFilesWithoutFileRequest,
  getNumOfNewAdditionalFilesUploadedForCustomer,
} from 'store/documentExchange/documentExchange.selectors';
import { getVideoIdentificationFile } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileSectionTitle from '../FileSectionTitle';
import { useProcessSpecificFiles } from '../ProcessSpecificFiles/useProcessSpecificFiles';

const { filesFromBank } = translations.pages.inquiryDetails.documentExchange;
const { newFile } = translations.components.upload;

// Shows the additional files uploaded by the bank to the '/files' endpoint
const FilesFromBank = () => {
  // FIXME
  // @ts-ignore
  const additionalFiles: IFile[] = useSelector(getAdditionalOperationFilesWithoutFileRequest);
  const files = [...additionalFiles];
  const videoIdentificationFile = useSelector(getVideoIdentificationFile);

  const notDownloadedFiles = useSelector(getNumOfNewAdditionalFilesUploadedForCustomer) || 0;

  // FIXME find another solution to show the welcome package for DZB
  // Get the welcome package file for DZB
  const dzbFileKinds = [FileKind.DZB_WELCOME_PACKAGE];
  const welcomePackageFiles = useProcessSpecificFiles(dzbFileKinds);
  files.push(...welcomePackageFiles);

  const t = useTranslations();

  return (
    <Condition condition={files.length || videoIdentificationFile?.downloadUrl}>
      <section>
        <FileSectionTitle>
          {t(filesFromBank)}
          {!!notDownloadedFiles && ` (${notDownloadedFiles} ${t(newFile)})`}
        </FileSectionTitle>
        <Section>
          {files.map((file) => (
            <li key={file.id}>
              <UploadedFileBlock
                file={file}
                type={
                  dzbFileKinds.includes(file.kind as FileKind)
                    ? FileType.ASSESSMENT_FILE
                    : undefined
                }
                canRemove={false}
                label={
                  file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED && (
                    <StatusLabel>{t(newFile)}</StatusLabel>
                  )
                }
              />
            </li>
          ))}
          {videoIdentificationFile?.downloadUrl && (
            <li>
              <FileDownloadBlock file={videoIdentificationFile} />
            </li>
          )}
        </Section>
      </section>
    </Condition>
  );
};

export default FilesFromBank;
