export const CONTACT_SIDEBAR_VISIBILITY = 'CONTACT_SIDEBAR_VISIBILITY';
export const showContactSidebar = (shouldShow) => (dispatch) =>
  dispatch({
    type: CONTACT_SIDEBAR_VISIBILITY,
    payload: shouldShow,
  });

export const RWD_MENU_VISIBILITY = 'RWD_MENU_VISIBILITY';
export const showRwdMenu = (shouldShow) => (dispatch) =>
  dispatch({
    type: RWD_MENU_VISIBILITY,
    payload: shouldShow,
  });
