import React from 'react';

import { useSelector } from 'react-redux';

import { ELEMENT_IDS } from 'constants/elementIds';
import paths from 'constants/paths';
import { StyledCompanyNav } from 'pages/operationPortal/CompaniesDetails/styles';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';

import { NavItem, SubNavigationItem } from './SubNavigationItem';
import { InquiryType } from '../../../../modules/Inquiry/Inquiry.type';
import { translations } from '../../../../new/form/common/types';
import { getInquiryFormTypeSelector } from '../../../../store/inquiryDetails/selectors';

const {
  profitabilityCalculation,
  marketComparison,
  appraiser,
  quickCheck,
  bankValues,
  financingCriteria,
  propertyProfile,
} = paths.operation.inquiryDetails.planningEvaluation;

const { planningEvaluation } = translations.pages;

const profitabilityCalculationNavItem: NavItem = {
  path: profitabilityCalculation,
  labelKey: planningEvaluation.profitabilityCalculation.title,
  featureFlag: CONFIG.IS_PROFITABILITY_CALCULATION_ENABLED,
  testId: 'profitability-calculation-link',
};

const defaultNavItems: NavItem[] = [
  profitabilityCalculationNavItem,
  {
    path: marketComparison,
    labelKey: planningEvaluation.marketComparison.title,
    featureFlag: CONFIG.IS_MARKET_DATA_ENABLED,
    testId: 'market-comparison-link',
  },
  {
    path: appraiser,
    labelKey: planningEvaluation.appraiser.title,
    featureFlag: CONFIG.IS_REAL_ESTATE_EXPERT_ENABLED,
    testId: 'appraiser-link',
  },
  {
    path: quickCheck,
    labelKey: planningEvaluation.quickCheck.title,
    featureFlag: CONFIG.IS_QUICK_CHECK_ENABLED,
    testId: 'quick-check-link',
  },
];

const mittweidaNavItems: NavItem[] = [
  {
    path: bankValues,
    labelKey: planningEvaluation.mittweida.pages.bankValues.title,
    testId: 'bank-values-link',
  },
  profitabilityCalculationNavItem,
  {
    path: marketComparison,
    labelKey: planningEvaluation.marketComparison.title,
    testId: 'market-comparison-link',
  },
  {
    path: financingCriteria,
    labelKey: planningEvaluation.mittweida.pages.financingCriteria.title,
    testId: 'financing-criteria-link',
  },
  {
    path: propertyProfile,
    labelKey: planningEvaluation.mittweida.pages.propertyProfile.title,
    testId: 'property-profile-link',
  },
];

const inquirySpecificNavItems: Partial<Record<InquiryType, NavItem[]>> = {
  [InquiryType.profiMittweida]: mittweidaNavItems,
};

/**
 * We should move away from using all these feature flags to show or hide navigation links. Will be easier to just have
 * a InquiryTypeSpecificNavigation component that renders the correct links for the inquiry type.
 */
const RealEstateSubNavigation = () => {
  const inquiryType = useSelector(getInquiryFormTypeSelector) as InquiryType;
  const navItems = inquirySpecificNavItems[inquiryType] || defaultNavItems;

  return (
    <StyledCompanyNav
      data-testid="real-estate-navigation"
      subNav
      aria-labelledby={ELEMENT_IDS.PLANNING_EVALUATION_NAVIGATION}
    >
      <ul>
        {navItems.map(({ path, labelKey, featureFlag, testId }) => (
          <SubNavigationItem
            testId={testId}
            key={labelKey}
            path={path}
            labelKey={labelKey}
            featureFlag={featureFlag}
          />
        ))}
      </ul>
    </StyledCompanyNav>
  );
};

export default RealEstateSubNavigation;
