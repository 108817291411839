import React from 'react';

import { useShowBeneficialOwnerSection } from 'mmv/inquiry/config/conditions';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { Page } from 'new/form/Page';

import { AuthorizationOfRepresentationSection } from './sections/authorizationOfRepresentationSection';
import { BeneficialOwnerSection } from './sections/beneficialOwnerSection';
import { CompanySection } from './sections/companySection';
import { ContactPersonSection } from './sections/contactPersonSection';

export const MmvCustomerDataStep = () => {
  const showSection = useShowBeneficialOwnerSection();
  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.mmv.steps.customerDataPage.progressBarTitle
      }
    >
      <CompanySection sectionNumber={1} />
      <ContactPersonSection sectionNumber={2} />
      <AuthorizationOfRepresentationSection sectionNumber={3} />
      <Condition validation={showSection}>
        <BeneficialOwnerSection sectionNumber={4} />
      </Condition>
    </Page>
  );
};
