import React from 'react';

import { Box, Divider } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useParams, useHistory } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import AssociatedPersonMergeSections, {
  KYC_OPTION,
  CREFO_OPTION,
  NO_ONE_OPTION,
} from './AssociatedPersonMergeSections';
import { useMergePerson } from './useAssociatedPersons';

const AssociatedPersonMerge: React.FC = () => {
  const { companyId, personId } = useParams<{ companyId: string; personId: string }>();
  const t = useTranslations('pages.companiesDetails.associatedPerson.merge');
  const history = useHistory();
  const callMergePerson = useMergePerson();
  const { success, error: notifyError } = useToasts();

  const submitMerge = async (formValues: any) => {
    const kycNoOne = formValues[KYC_OPTION] === NO_ONE_OPTION;
    const crefoNoOne = formValues[CREFO_OPTION] === NO_ONE_OPTION;
    const kycId = kycNoOne ? null : formValues[KYC_OPTION]?.id;
    const crefoId = crefoNoOne ? null : formValues[CREFO_OPTION]?.id;

    const { error } = await callMergePerson(personId, kycId, crefoId, companyId);
    if (!error) {
      if (kycNoOne) {
        success({ description: t('noOneMerge') });
      } else {
        success({ description: t('successful') });
      }
      history.push(paths.operation.companies.associatedPersons(companyId));
    } else {
      notifyError({ description: t('error') });
    }
  };

  return (
    <Container>
      <Box pt="12" pb="20">
        <Box maxWidth="800px" pl={[null, null, null, '20rem']}>
          <HeadingComponent as="h2" variant="secondary" mb={3}>
            {t('title')}
          </HeadingComponent>

          <TextComponent color="text.tertiary">{t('description')}</TextComponent>
        </Box>

        <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />

        <Form
          onSubmit={submitMerge}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AssociatedPersonMergeSections personId={personId} />
              <StyledActions>
                <ButtonComponent
                  leftIcon={<CloseIcon boxSize={6} display="block" />}
                  mr={4}
                  onClick={() => history.goBack()}
                  variant="tertiary"
                >
                  {t('cancel')}
                </ButtonComponent>
                <ButtonComponent leftIcon={<SaveIcon boxSize={6} display="block" />} type="submit">
                  {t('submit')}
                </ButtonComponent>
              </StyledActions>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(AssociatedPersonMerge);
