import React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { combineValidators } from 'utils/validators';

interface IBeneficiaryTaxIdProps {
  name: string;
}

const BeneficiaryTaxId = ({ name = '' }: IBeneficiaryTaxIdProps) => {
  const { optional } = useFieldValidators();
  return (
    <FormRow>
      <InputWithField name={name} optional validate={combineValidators(optional)} />
    </FormRow>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: BeneficiaryTaxId,
  default: () => null,
});
