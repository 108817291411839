import React from 'react';

import { Redirect, Switch, useParams } from 'react-router-dom';

import paths from 'constants/paths';
import AssociatedPerson from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/AssociatedPerson';
import AssociatedPersonMerge from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/AssociatedPersonMerge';
import { AssociatedPeopleModeProvider } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/AssociatedPeopleModeProvider';
import CompanyOverview from 'pages/operationPortal/CompaniesDetails/CompanyOverview/CompanyOverview';
import FetchCompanyDetails from 'pages/operationPortal/CompaniesDetails/FetchCompanyDetails';
import FinancialInformation from 'pages/operationPortal/CompaniesDetails/FinancialInformation/FinancialInformation';
import RiskAnalysis from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/RiskAnalysis';
import OperationPrivateRoute from 'routes/OperationPrivateRoute';

const { operation: operationPaths } = paths;

export const CompanyRoutes = () => {
  const { companyId, id } = useParams<{ companyId: string; id: string }>();

  return (
    <FetchCompanyDetails companyId={companyId}>
      <Switch>
        <OperationPrivateRoute
          exact
          path={operationPaths.inquiryDetails.companyDetails.root()}
          component={() => (
            <Redirect to={operationPaths.inquiryDetails.companyDetails.overview(id, companyId)} />
          )}
        />
        <OperationPrivateRoute
          exact
          path={operationPaths.inquiryDetails.companyDetails.overview()}
          component={CompanyOverview}
        />
        <OperationPrivateRoute
          exact
          path={operationPaths.inquiryDetails.companyDetails.associatedPersons()}
          render={() => (
            <AssociatedPeopleModeProvider>
              <AssociatedPerson />
            </AssociatedPeopleModeProvider>
          )}
        />
        <OperationPrivateRoute
          exact
          path={operationPaths.inquiryDetails.companyDetails.associatedPersonsMerge()}
          component={AssociatedPersonMerge}
        />
        <OperationPrivateRoute
          exact
          path={operationPaths.inquiryDetails.companyDetails.riskAnalysis()}
          render={() => <RiskAnalysis />}
        />
        <OperationPrivateRoute
          exact
          path={operationPaths.inquiryDetails.companyDetails.financialInformation()}
          component={FinancialInformation}
        />
      </Switch>
    </FetchCompanyDetails>
  );
};
