import React, { ReactNode } from 'react';

import { FormSpy } from 'react-final-form';

import { useFeatureFlags } from 'config/featureFlags/hooks';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import RecalculateProgressBarOnMount from 'modules/InquiryFormNew/RecalculateProgressBarOnMount';
import { useFormValues } from 'store/hooks/useFormValues';
import { getUserRoles } from 'utils/user/getters';

const Condition = ({
  condition,
  children,
  onFail,
  isBusinessCondition = false,
}: {
  condition: Function;
  children: ReactNode;
  onFail?: ReactNode;
  isBusinessCondition?: boolean; // temp property, a simpler condition that takes all field values as a
}) => {
  const featureFlags = useFeatureFlags();
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const formValues = useFormValues();
  const userRoles = getUserRoles();
  const { selectedInquiryType } = useFormConfig();

  return (
    <FormSpy
      render={(state) => {
        const isTruthy = isBusinessCondition
          ? condition({
              formValues,
              formType: selectedInquiryType,
              config: featureFlags,
              partnerName,
              userRoles,
            })
          : condition(state);

        if (isTruthy) {
          return <RecalculateProgressBarOnMount>{children}</RecalculateProgressBarOnMount>;
        }

        if (onFail) {
          return <RecalculateProgressBarOnMount>{onFail}</RecalculateProgressBarOnMount>;
        }
        return null;
      }}
    />
  );
};

export default Condition;
