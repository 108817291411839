import React from 'react';

import StaticField from 'components/StaticField';
import { store } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getCurrentUser } from 'utils/user/getters';

const PhoneNumberStaticField = () => {
  const { phoneNumber } = getCurrentUser(store.getState());
  const t = useTranslations(
    'pages.userProfile.sections.completingContactData.fields.userProfilePhoneNumber',
  );
  return <StaticField caption={t('caption')} text={phoneNumber} />;
};

export default PhoneNumberStaticField;
