import React from 'react';

import { useSelector } from 'react-redux';

import EmptyField from 'components/EmptyField';
import FormRow from 'components/FormRow';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import ConditionalSubform from 'modules/ConditionalSubform';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  LOAN_FIXED_INTEREST_RATE,
  LOAN_FIXED_INTEREST_RATE__FIXED,
  FINANCING_NEED,
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  LOAN_ALREADY_AT_BANK,
} from 'modules/Inquiry/Form/formFields';
import LoanDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanDescription';
import LoanFixedInterestRate from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanFixedInterestRate/LoanFixedInterestRate';
import LoanInterestPeriodInMonths from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanInterestPeriodInMonths/LoanInterestPeriodInMonths';
import LoanConsiderSubsidies from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/RedeemableLoan/LoanConsiderSubsidies';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { getStoredValueSelector } from 'store/progress/selectors';

const RedeemableLoan = () => (
  <>
    <FormRow separator>
      <LoanFixedInterestRate />
      <ConditionalSubform
        condition={fieldHasValue(LOAN_FIXED_INTEREST_RATE, LOAN_FIXED_INTEREST_RATE__FIXED)}
        subscription={{ values: true, visited: true }}
        renderOnSatisfy={() => <LoanInterestPeriodInMonths />}
        renderOnFail={() => <EmptyField />}
      />
    </FormRow>
    <FormRow>
      <LoanConsiderSubsidies />
    </FormRow>

    <FormRow>
      <LoanDescription />
    </FormRow>
  </>
);

const CoronaRedeemableLoan = () => {
  const hasSelectedQuickcredit =
    useSelector(getStoredValueSelector([FINANCING_NEED, CORONA_FINANCING_OPTION])) ===
    CORONA_FINANCING_OPTION__QUICK_CREDIT;
  return (
    <>
      <LoanFixedInterestRate />
      <LoanInterestPeriodInMonths />
      <FormRow>
        {hasSelectedQuickcredit && <YesNoRadioGroupWithField name={LOAN_ALREADY_AT_BANK} />}
      </FormRow>
      <FormRow>
        <LoanConsiderSubsidies />
      </FormRow>
      <FormRow>
        <LoanDescription />
      </FormRow>
    </>
  );
};

export default chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__CORONA,
      component: CoronaRedeemableLoan,
    },
  ],
  RedeemableLoan,
);
