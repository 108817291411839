import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { MACHINE_INTERESTED_IN_INSURANCE } from 'modules/Inquiry/Form/formFields';

const MachineInterestedInInsurance = () => {
  return <YesNoRadioGroupWithField name={MACHINE_INTERESTED_IN_INSURANCE} />;
};

export default MachineInterestedInInsurance;
