import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';

import { authorizationOfRepresentationSectionValidations } from './sections/authorizationOfRepresentationSection/validations';
import { beneficialOwnerSectionValidations } from './sections/beneficialOwnerSection/validations';

export const customerDataPageValidations: ValidationRecord<InquiryType.mmv>['customerDataPage'] = {
  companySection: () => true,
  contactPersonSection: () => true,
  authorizationOfRepresentationSection: authorizationOfRepresentationSectionValidations,
  beneficialOwnerSection: beneficialOwnerSectionValidations,
};
