import styled, { css } from 'styled-components/macro';

import { InputCaption, Text } from 'components/Text';

export const TextWrapper = styled.div<{ noFlex: boolean; isHorizontal: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  position: relative;
  margin: 0;

  ${InputCaption} {
    display: flex;
    align-items: center;
    flex: ${({ noFlex }) => (noFlex ? '' : '1 1 auto')};
  }

  ${Text} {
    margin: 0;
  }

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      ${InputCaption} {
        margin: 0 1rem 0 0;
      }
    `}
` as any;
