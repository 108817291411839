import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CheckmarkIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M10.14 15.1l-2.43-2.43a1 1 0 010-1.34 1 1 0 011.35 0l1.75 1.75L14.94 9a1 1 0 011.35 1.34l-4.81 4.76a.94.94 0 01-1.34 0z"
      />
    </Icon>
  );
};

export default CheckmarkIcon;
