import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
} from 'modules/Inquiry/Form/formFields';

const mapMarketDetailsToEdit = () => {
  const commonAttributes: Record<string, string> = {
    rentPrice: 'pages.editMarketResearchValues.sections.rentPrices.title',
    buildingSalesPrice: 'pages.editMarketResearchValues.sections.salesPrice.building',
    unitSalesPrice: 'pages.editMarketResearchValues.sections.salesPrice.unit',
  };

  const usageSectionsMap = {
    current: [
      {
        title: commonAttributes['rentPrice'],
        fields: [
          CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
          CURRENT_USAGE_EXISTING_RENT_PER_SQM,
          CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
        ],
      },
      {
        title: commonAttributes['buildingSalesPrice'],
        fields: [CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING],
      },
      {
        title: commonAttributes['unitSalesPrice'],
        fields: [CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT],
      },
    ],
    future: [
      {
        title: commonAttributes['rentPrice'],
        fields: [
          FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
          FUTURE_USAGE_EXISTING_RENT_PER_SQM,
          FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
        ],
      },
      {
        title: commonAttributes['buildingSalesPrice'],
        fields: [FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING],
      },
      {
        title: commonAttributes['unitSalesPrice'],
        fields: [FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT],
      },
    ],
  };

  const editMarketValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMarketResearchValues.futureUsage.heading',
      subHeading: 'pages.editMarketResearchValues.futureUsage.subHeading',
      sections: usageSectionsMap['future'],
    },
    {
      heading: 'pages.editMarketResearchValues.currentUsage.heading',
      subHeading: 'pages.editMarketResearchValues.currentUsage.subHeading',
      sections: usageSectionsMap['current'],
    },
  ];

  return editMarketValuesForm;
};

export default mapMarketDetailsToEdit;
