import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const InvestionsIcon = (props: IconProps) => {
  return (
    <Icon
      width="46"
      height="49"
      viewBox="0 0 46 49"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path d="M25.8 30.1c0-5.3-5.3-9.6-11.9-9.6m.5.5c0 5.1 5.2 9.2 11.7 9.2M26 16.2v14.1m.3-.2c0-5.3 5.3-9.6 11.9-9.6m-.4.5c0 5.1-5.2 9.2-11.7 9.2M28.5 11.8c-.5.3-1.1.4-1.7.4-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8c.7 0 1.3.2 1.9.5m-6.6 2.5h4m-4 1.5h4" />
        <circle cx="26" cy="8.4" r="7.9" />
      </g>
      <g fill="#e4e4e4">
        <path d="M29.9 36.8h-8.4v-1h8.4c1.2 0 2.2-1 2.2-2.2s-1-2.3-2.2-2.3v-1c1.8 0 3.2 1.5 3.2 3.3 0 1.8-1.4 3.2-3.2 3.2z" />
        <path d="M7 45.7V33l2.1-2.4c1.9-2.1 4.6-3 7.2-2.5l7.8 1.8c.8.2 1.5.3 2.3.3l3.5.1v1l-3.5-.1c-.8 0-1.7-.1-2.5-.3l-7.7-1.8c-2.3-.5-4.7.3-6.3 2.1L8 33.4v10.7l1.1-.6c2.1-1.1 4.5-1.4 6.8-1l5.5 1.1c2.5.5 5.1.1 7.4-1.1l14-7.4c1.3-.7 2-2 2-3.4 0-.3-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-10.8 4-.3-.9 11-4.1c.6-.1 1.2.1 1.7.6.5.4.7 1 .8 1.6.1 1.8-.9 3.5-2.5 4.3l-14 7.4c-2.5 1.3-5.3 1.7-8 1.2l-5.5-1.1c-2.1-.4-4.3-.1-6.2.9L7 45.7z" />
        <path d="M5.1 48H2.9C1.3 48 0 46.7 0 45.1V31.4c0-1.6 1.3-2.9 2.9-2.9H5c1.6 0 2.9 1.3 2.9 2.9v13.7C8 46.7 6.7 48 5.1 48zM2.9 29.5c-1.1 0-1.9.9-1.9 1.9v13.7c0 1 .9 1.9 1.9 1.9H5c1.1 0 2-.9 2-1.9V31.4c0-1.1-.9-1.9-1.9-1.9H2.9z" />
      </g>
    </Icon>
  );
};

export default InvestionsIcon;
