import React from 'react';

import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';

import { SelectWithField } from 'components/Selects/Select';
import StaticField from 'components/StaticField';
import {
  LOAN_TYPE,
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
  FINANCING_NEED,
  LOAN_TYPE__AMORTISABLE,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
} from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import { coronaLoanTypeOptions } from './coronaLoanTypeOptions';

const DefaultLoanType = () => {
  const options = useInquirySelectOptions(LOAN_TYPE);
  const t = useTranslations('pages.requestDetails.sections.loan.fields.loanType');

  return (
    <SelectWithField
      name={LOAN_TYPE}
      options={options}
      tooltip={t('tooltip')}
      data-testid={'select-field-' + LOAN_TYPE}
    />
  );
};

export const CoronaLoanType = () => {
  const { formatMessage } = useIntl();
  const options = coronaLoanTypeOptions(formatMessage);
  const t = useTranslations('pages.requestDetails.sections.loan.fields.loanType');

  return (
    <SelectWithField
      name={LOAN_TYPE}
      options={options}
      tooltip={t('tooltip')}
      data-testid={'select-field-' + LOAN_TYPE}
    />
  );
};

const AmortisableLoanType = () => {
  const t = useTranslations('pages.requestDetails.sections.loan.fields.loanType');
  const { optional } = useFieldValidators();

  return (
    <div>
      <StaticField caption={t('caption')} text={t('options.amortisable')} />
      <Field
        name={LOAN_TYPE}
        validate={optional}
        render={({ input }) => {
          input.onChange(LOAN_TYPE__AMORTISABLE);
          return null;
        }}
      />
    </div>
  );
};

export default chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__CORONA,
      component: CoronaLoanType,
    },
    {
      step: FINANCING_NEED,
      field: CORONA_FINANCING_OPTION,
      value: CORONA_FINANCING_OPTION__QUICK_CREDIT,
      component: AmortisableLoanType,
    },
  ],
  DefaultLoanType,
);
