import React from 'react';

import { useSelector } from 'react-redux';

import BankOffer from 'modules/Offers/InquiryOffersSection/BankAdvisorOffers/OfferBox/BankOffer';
import { StyledOffersList as List } from 'modules/Offers/InquiryOffersSection/styles';
import { getBankOffersSelector } from 'store/inquiryDetails/selectors';

const BankAdvisorOffers = () => {
  const offers = useSelector(getBankOffersSelector);

  return (
    <List data-testid="bank-advisor-offers">
      {offers.map((offer) => (
        <BankOffer key={offer.id} offer={offer} />
      ))}
    </List>
  );
};

export default BankAdvisorOffers;
