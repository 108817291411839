import React from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { isValidLegalRepresentatives } from 'hausbank/inquiry/steps/personalData/sections/legalRepresentativesSection/validations';
import { InquiryProcessForm } from 'new/form';
import { translations } from 'new/form/common/types';
import { useGetInitialValuesForCreateMode } from 'new/form/persist';
import { LEGAL_REPRESENTATIVE_ERROR } from 'new/legalRepresentatives/LegalRepresentativeCards';
import { SummaryPage } from 'new/summary';
import { createSpecializedInquiryAction } from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { useToasts } from 'shared/hooks/useToasts';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

import { hausbankRouteToPageNameMap } from './config/routeToPageNameMap';
import { HausbankFieldTypes } from './fields';
import { HausbankCompanyDataPage } from './steps/companyData';
import { mapHausbankTermsAndConditions } from './steps/companyData/sections/termsAndConditionsSection/dataMapper';
import { mapHausbankWegData } from './steps/companyData/sections/wegDataCompletionSection/dataMapper';
import { mapHausbankWegSearch } from './steps/companyData/sections/wegSearchSection/dataMapper';
import { HausbankFinalPage } from './steps/final';
import { HausbankFinancingNeedPage } from './steps/financingNeed';
import { mapHausbankFinancingNeed } from './steps/financingNeed/sections/financingNeedSection/dataMapper';
import { mapHausbankPurpose } from './steps/financingNeed/sections/purposeSection/dataMapper';
import { HausbankObjectInformationPage } from './steps/objectInformation';
import { mapHausbankObjectInformation } from './steps/objectInformation/sections/objectInformationSection/dataMapper';
import { mapHausbankProjectInformation } from './steps/objectInformation/sections/projectInformationSection/dataMapper';
import { HausbankPersonalDataPage } from './steps/personalData';
import { mapHausbankContactPerson } from './steps/personalData/sections/contactPersonSection/dataMapper';
import { mapHausbankLegalRepresentatives } from './steps/personalData/sections/legalRepresentativesSection/dataMapper';
import { mapHausbankPassword } from './steps/personalData/sections/passwordSection/dataMapper';

export const HAUSBANK_FORM = 'HAUSBANK_FORM';

const useSendHausbankInquiry = () => {
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.submitInquiry)));

  return async (values: HausbankFieldTypes) => {
    const {
      objectInformationPage: { objectInformationSection, projectInformationSection },
      companyDataPage: { wegSearchSection, wegDataCompletionSection, termsAndConditionsSection },
      personalDataPage: { contactPersonSection, legalRepresentativesSection, passwordSection },
      financingNeedPage: { purposeSection, financingNeedSection },
    } = values;

    const apiData = {
      company_attributes: {
        ...mapHausbankWegSearch(wegSearchSection),
        ...mapHausbankWegData(wegDataCompletionSection),
      },
      homeowner_association: {
        ...mapHausbankObjectInformation(objectInformationSection),
        ...mapHausbankProjectInformation(projectInformationSection),
      },
      ...(!isLoggedInUser()
        ? {
            user_attributes: {
              ...mapHausbankContactPerson(contactPersonSection),
              ...mapHausbankPassword(passwordSection),
              ...mapHausbankTermsAndConditions(termsAndConditionsSection),
            },
          }
        : {}),
      legal_representatives: mapHausbankLegalRepresentatives(
        legalRepresentativesSection.legalRepresentatives,
      ),
      ...mapHausbankPurpose(purposeSection),
      ...mapHausbankFinancingNeed(financingNeedSection),
    };
    const sendHausbankInquiry = () => createSpecializedInquiryAction(apiData, 'hausbank');
    return makeCall(sendHausbankInquiry());
  };
};

export const HausbankCreateForm = () => {
  const initialValues = useGetInitialValuesForCreateMode();
  const t = useTranslations();
  const history = useHistory();
  const { error: errorToast } = useToasts();

  const sendInquiry = useSendHausbankInquiry();

  const handleSubmit = async (values: HausbankFieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.finalPage);
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
    return !error;
  };

  return (
    <InquiryProcessForm<HausbankFieldTypes>
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validate={(values: HausbankFieldTypes) => {
        const errors: any = {};

        if (!isValidLegalRepresentatives(values)) {
          errors[LEGAL_REPRESENTATIVE_ERROR] = t(
            translations.inquiryType.hausbank.pages.personalData.sections.legalRepresentatives
              .errors.atLeastOneSoleRepresentative,
          );
        }

        return errors;
      }}
    >
      <Switch>
        <Route
          exact
          path={hausbankRouteToPageNameMap.objectInformationPage.create}
          component={HausbankObjectInformationPage}
        />
        <Route
          exact
          path={hausbankRouteToPageNameMap.companyDataPage.create}
          component={HausbankCompanyDataPage}
        />
        <Route
          exact
          path={hausbankRouteToPageNameMap.financingNeedPage.create}
          component={HausbankFinancingNeedPage}
        />
        <Route
          exact
          path={hausbankRouteToPageNameMap.personalDataPage.create}
          component={HausbankPersonalDataPage}
        />
        <Route exact path={paths.summaryPage} component={SummaryPage<HausbankFieldTypes>} />
        <Route exact path={paths.finalPage} component={HausbankFinalPage} />
      </Switch>
    </InquiryProcessForm>
  );
};
