import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';

import forwardToInquiryManagerAction from 'api/CompeonReverseApi/operation/forwardToInquiryManager/actions';
import Condition from 'components/Condition';
import paths from 'constants/paths';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import ForwardToInquiryManagerModalContent from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToInquiryManager/ForwardToInquiryManagerModalContent';
import Section from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/Section';
import Button from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/Section/Button';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { useInquiryForwarding } from '../../ForwardInquiry/useInquiryForwarding';

const ForwardToInquiryManagerContainer = ({ history: { push } }) => {
  const { formatMessage } = useIntl();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const isOrganizationalUnitEnabled = useConfig(CONFIG.IS_ORGANIZATIONAL_UNIT_ENABLED);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const { forwardToInquiryManagerVisible } = useInquiryForwarding();

  const t = (msgId, values) =>
    formatMessage(
      {
        id: `pages.inquiryDetails.dashboard.actions.forwardToInquiryManager.${msgId}`,
      },
      values,
    );

  const forward = async (organizationUnitId) => {
    await makeCall(
      isOrganizationalUnitEnabled
        ? forwardToInquiryManagerAction(inquiryDetails.inquiryId, organizationUnitId)
        : forwardToInquiryManagerAction(inquiryDetails.inquiryId),
      () => {
        success({
          description: isOrganizationalUnitEnabled
            ? t('messageAfterActionOrganizationalUnit')
            : t('messageAfterAction'),
        });
        push(paths.operation.inquiryList);
      },
    );
  };

  // multirole users have to have those buttons disabled (CRV-1180)
  const disabled = inquiryDetails.status !== INQUIRY_STATUSES.PENDING;

  const button = (
    <Button
      disabled={disabled}
      isLoading={isPending}
      onClick={isOrganizationalUnitEnabled ? onOpen : forward}
      label={t('buttonConfirm')}
      data-testid="forward-to-inquiry-manager"
    />
  );

  return (
    <Condition condition={forwardToInquiryManagerVisible}>
      <Section
        title={t('title')}
        description={t('description')}
        hasError={Boolean(error)}
        button={button}
      >
        <ModalComponent
          testId="forward-to-organization-unit-modal"
          isOpen={isOpen}
          onClose={onClose}
          title={t('modalHeader')}
          footer={
            <>
              <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
                {t('buttonCancel')}
              </ButtonComponent>
              <ButtonComponent form="form-forward-to-organization-unit" type="submit">
                {t('buttonConfirm')}
              </ButtonComponent>
            </>
          }
        >
          <ForwardToInquiryManagerModalContent onSubmit={forward} />
        </ModalComponent>
      </Section>
    </Condition>
  );
};

ForwardToInquiryManagerContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(withRouter)(ForwardToInquiryManagerContainer);
