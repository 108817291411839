import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'alertDialog',
  'alertDialogContent',
  'alertDialogHeader',
  'alertDialogBody',
  'alertDialogFooter',
  'headingComponent',
]);

const AlertDialog = helpers.defineMultiStyleConfig({
  baseStyle: {
    alertDialogContent: {
      maxWidth: 'xl',
    },
    headingComponent: {
      color: 'brand.default',
      variant: 'secondary',
    },
    alertDialogHeader: {
      pt: 8,
      pr: 16,
      pb: 2,
      pl: 8,
    },
    alertDialogBody: {
      pt: 4,
      pr: 8,
      pb: 4,
      pl: 8,
    },
    alertDialogFooter: {
      pt: 4,
      pr: 8,
      pb: 8,
      pl: 8,
    },
  },
});

export default AlertDialog;
