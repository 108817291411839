import styled from 'styled-components/macro';

export const LegalRepresentativesWrapper = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 1.5rem 0;
`;

export const StyledEntityFormHeading = styled.h3`
  margin-bottom: 1rem;
`;
