import React, {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import PATHS from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fetchInquiryAction, FeToBeMapping } from 'modules/InquiryDetails/InquiryDetails.service';
import useInquiryReset from 'modules/InquiryFlowReset/useInquiryReset';
import { usePortalConfig } from 'new/portals/state/portalConfigContext/hooks';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import {
  mapInquiryDetailsApiResponseAction,
  mapInquiryEditApiResponseAction,
} from 'store/inquiryDetails/actions';
import {
  getInquiryDetailsEditSelector,
  getInquiryDetailsSelector,
} from 'store/inquiryDetails/selectors';
import { PARAM_KEYS } from 'types/Router';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  children: React.ReactNode;
  inquiryId: string;
};

export const LOADING_INDICATOR = (
  <SpinnerWrapper data-testid="spinner">
    <Spinner />
  </SpinnerWrapper>
);

export const FetchInquiryDataContext = createContext<{
  refetchInquiry: () => Promise<void>;
} | null>({ refetchInquiry: () => new Promise(() => ({})) });

export const useFetchInquiryData = () => {
  const context = useContext(FetchInquiryDataContext);
  if (!context) {
    throw new Error('useDataProvider must be used within a DataProvider');
  }
  return context;
};

const FetchInquiryDetails = ({ children, inquiryId }: Props) => {
  /*
   * Local state to track whether the fetched data has been dispatched to Redux.
   * This is necessary because Redux updates are asynchronous and we want to ensure
   * the data is in the store before rendering the children.
   */
  const [hasDataDispatched, setDataDispatched] = useState(false);
  const t = useTranslations();
  const history = useHistory();
  const dispatch = useDispatch();
  const { makeCall, isPending } = useDispatchApiCall({ errorMessage: t('errors.unknownInquiry') });
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const inquiryDetailsForEdit = useSelector(getInquiryDetailsEditSelector);
  const { portalConfig } = usePortalConfig();
  const feToBeMappingKeys = portalConfig?.feToBeMappingKeys as FeToBeMapping;
  const { id } = useParamsSafe([PARAM_KEYS.id]);

  // TODO: Need to change default form-type name to avoid future confusion
  const noopFn = (_isInquiryForEdit: boolean) => {};
  useSelectedInquiryTypeSpecificValue({
    [InquiryType.onlinefactoring]: noopFn,
    [InquiryType.leaseplan]: noopFn,
    [InquiryType.bfs]: noopFn,
    [InquiryType.profiRLL]: noopFn,
    [InquiryType.bfsService]: noopFn,
    default: useInquiryReset,
  })(Boolean(id));

  const fetchData = useCallback(
    // FIXME
    // @ts-ignore
    async (id) => {
      const { error, payload } = await makeCall(fetchInquiryAction(id));
      if (!payload || error) {
        history.push(PATHS.financingNeed);
      } else if (payload) {
        // Dispatch the fetched data to the Redux store.
        dispatch(mapInquiryDetailsApiResponseAction(payload.data, feToBeMappingKeys));
        dispatch(mapInquiryEditApiResponseAction(payload.data));

        /*
         * Once the data has been dispatched, update our local state.
         * This provides an indication that it's safe to render the children
         * since the Redux store should have the updated data.
         */
        setDataDispatched(true);
      }
    },
    [dispatch, history, makeCall, feToBeMappingKeys],
  );

  useEffect(() => {
    fetchData(inquiryId);
  }, [fetchData, inquiryId]);

  const contextValue = useMemo(
    () => ({
      refetchInquiry: () => fetchData(inquiryId),
    }),
    [fetchData, inquiryId],
  );

  /*
   * The rendering condition checks several factors:
   * 1. Whether data fetching is still in progress (`isPending`).
   * 2. If the necessary data is available (`inquiryDetails` and `inquiryDetailsForEdit`).
   * 3. Whether the data has been dispatched to Redux (`hasDataDispatched`).
   * This ensures that the children only render when the data is ready in the Redux store.
   */
  if (isPending || !inquiryDetails || !inquiryDetailsForEdit || !hasDataDispatched) {
    return LOADING_INDICATOR;
  }

  return (
    <FetchInquiryDataContext.Provider value={contextValue}>
      <Suspense fallback={LOADING_INDICATOR}>{children}</Suspense>
    </FetchInquiryDataContext.Provider>
  );
};

export default FetchInquiryDetails;
