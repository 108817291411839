import React, { useContext } from 'react';

import { useFieldArray } from 'react-final-form-arrays';

import { InputWithField, INPUT_TYPE_DATE } from 'components/Input';
import { REPRESENTATIVE_BIRTHDATE, USER_REPRESENTATIVE } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';
import CalendarIcon from 'theme/components/Icon/CalendarIcon';

import { FieldValue } from '../LegalRepresentatives/LegalRepresentatives';

const RepresentativeBirthdate: React.FC = () => {
  const { fieldName, userIndex } = useContext(LegalRepresentativesContext);
  const { fields: fieldsUserRepresentative } = useFieldArray<FieldValue>(USER_REPRESENTATIVE);
  const userRepresentative = fieldsUserRepresentative.value?.[userIndex];

  return (
    <InputWithField
      name={`${fieldName}.${REPRESENTATIVE_BIRTHDATE}`}
      sideSymbol={() => <CalendarIcon boxSize={6} />}
      disabled={!!userRepresentative?.isAssociatedPerson}
      type={INPUT_TYPE_DATE}
    />
  );
};

export default RepresentativeBirthdate;
