import React from 'react';

import { useSelector } from 'react-redux';

import { InputWithField } from 'components/Input';
import { USER_PROFILE_FOUNDING_YEAR, FINANCING_NEED } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import StaticFoundingYearField from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingYear/StaticFoundingYearField';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getStoredValueSelector } from 'store/progress/selectors';

const FoundingYear = () => {
  const yearFromCoronaStep = useSelector(
    getStoredValueSelector([FINANCING_NEED, USER_PROFILE_FOUNDING_YEAR]),
  );

  const companyFoundingYear = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: null,
    default: useGetSelectedCompanyParam(COMPANY_FIELDS.foundingYear),
  });

  const alreadySavedValue = yearFromCoronaStep || companyFoundingYear;
  if (alreadySavedValue) {
    return <StaticFoundingYearField foundingYear={alreadySavedValue} />;
  }

  return (
    <InputWithField name={USER_PROFILE_FOUNDING_YEAR} type="number" separateThousands={false} />
  );
};

export default FoundingYear;
