import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  FACTORING_ACTIVE_CUSTOMERS,
  FACTORING_ALREADY_IN_USE,
  FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_BILLS_RANGE,
  FACTORING_COMMERCIAL_CREDIT_INSURANCE,
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_CLASS,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
} from 'modules/Inquiry/Form/formFields';

export const validateFactoringSection = (form) =>
  fieldsAreValid([
    FACTORING_TURNOVER_CLASS,
    FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
    FACTORING_FOREIGN_SHARE,
    FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
    FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
    FACTORING_COMMERCIAL_CREDIT_INSURANCE,
    FACTORING_ACTIVE_CUSTOMERS,
    FACTORING_BILLS_RANGE,
    FACTORING_ALREADY_IN_USE,
    FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
  ])({ form });
