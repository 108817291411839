import { useParams } from 'react-router-dom';

import { Params } from 'types/Router';

const useParamsSafe = (keys: (keyof Params)[]) => {
  const params = useParams<Params>();
  const pickedParams = {} as Record<keyof Params, string>;
  keys.forEach((key) => {
    if (params[key]) {
      pickedParams[key] = params[key] as string;
    } else {
      throw new Error(
        `The param ${key} does not exist. The location you are trying to access params is probably wrong. Please check.`,
      );
    }
  });
  return pickedParams;
};

export default useParamsSafe;
