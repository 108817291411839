import {
  CORONA_REVENUE_OF_2019,
  CORONA_LIQUIDITY_NEED,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const coronaStepUnits = {
  [CORONA_REVENUE_OF_2019]: FIELD_UNIT_TYPE.CURRENCY,
  [CORONA_LIQUIDITY_NEED]: FIELD_UNIT_TYPE.CURRENCY,
  [CORONA_EXPENDITURE_OF_EMPLOYEES]: FIELD_UNIT_TYPE.CURRENCY,
};
