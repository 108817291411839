export default {
  financingNeed: '/verwendungszweck',
  coronaStep: '/foerdercheck',
  companyDetails: '/unternehmen',
  customerData: '/kundendaten',
  peopleDetails: '/personen',
  requestDetails: '/ausschreibung',
  userProfile: '/nutzer',
  summaryPage: '/uebersichtsseite',
  finalPage: '/erfolg',
  termsAndConditions: '/nutzungsbedingungen',
  privacyPolicy: '/datenschutzerklarung',
  leaseplan: {
    termsAndConditions:
      'https://privacyportal-eu.onetrust.com/webform/396c853c-7bbe-43bb-9c80-0001fd327955/de34c091-9921-4390-8308-1882ceb55b22',
    privacyPolicy: 'https://www.leaseplan.com/de-de/datenschutz',
    impressum: 'https://www.leaseplan.com/de-de/impressum/',
    genderNote: 'https://www.leaseplan.com/de-de/gender-hinweis/',
  },
  subsidyForForestry: '/zuschuss-fur-forstwirtschaftsbetriebe',
  contactPerson: '/gesprachspartner',
  impressum: '/impressum',
  registration: '/anmeldung',
  registrationSuccess: '/anmeldung/erfolg',
  registrationExpiredLink: '/anmeldung/link-abgelaufen',
  agriculturalSubsidy: '/zuschuss-fur-landwirtschaftsbetriebe',
  contractDetails: '/vertragsdetails',
  contactPersonDetails: '/kontaktperson',
  passwordReset: {
    request: '/passwort-zurucksetzen/anfordern',
    change: '/passwort-zurucksetzen/andern',
  },
  error403: '/403',
  error404: '/404',
  error500: '/500',
  errorDefault: '/fehler',
  accountUnblockSuccess: '/freischalten/erfolgreich',
  accountUnblockFail: '/freischalten/fehler',
  operation: {
    root: '/operationen',
    companies: {
      root: '/operationen/unternehmen',
      details: (companyId = ':companyId') => `/operationen/unternehmen/${companyId}`,
      overview: (companyId = ':companyId') => `/operationen/unternehmen/${companyId}/ubersicht`,
      associatedPersons: (companyId = ':companyId') =>
        `/operationen/unternehmen/${companyId}/verbundene-personen`,
      associatedPersonsMerge: (companyId = ':companyId', personId = ':personId') =>
        `/operationen/unternehmen/${companyId}/verbundene-personen/merge/${personId}`,
      riskAnalysis: (companyId = ':companyId') =>
        `/operationen/unternehmen/${companyId}/risikoanalyse`,
      financialInformation: (companyId = ':companyId') =>
        `/operationen/unternehmen/${companyId}/jahresabschlusse`,
    },
    login: '/operationen/login',
    passwordReset: {
      request: '/operationen/passwort-zurucksetzen/anfordern',
      change: '/operationen/passwort-zurucksetzen/andern',
    },
    finishRegistration: '/operationen/einladung-annehmen',
    userData: '/operationen/benutzer',
    inquiryList: '/operationen/anfragen',
    inquiryDetails: {
      root: '/operationen/anfragen/:id',
      dashboard: '/operationen/anfragen/:id/dashboard',
      nonBindingOffer: (id = ':id') => `/operationen/anfragen/${id}/dashboard/non-binding-offer`,
      legalRepresentationCorrection: (id = ':id') =>
        `/operationen/anfragen/${id}/dashboard/vertretungsberechtigung`,
      kycDataBankResolution: (id = ':id') =>
        `/operationen/anfragen/${id}/dashboard/kyc-Datenauflösung`,
      marketInformationDetails: '/operationen/anfragen/:id/dashboard/marktinformationen',
      marketInformationAddress: '/operationen/anfragen/:id/dashboard/marktinformationen/address',
      documentExchange: '/operationen/anfragen/:id/unterlagenaustausch',
      companyDetails: {
        root: (id = ':id', companyId = ':companyId') =>
          `/operationen/anfragen/${id}/unternehmen/${companyId}`,
        overview: (id = ':id', companyId = ':companyId') =>
          `/operationen/anfragen/${id}/unternehmen/${companyId}/uebersicht`,
        associatedPersons: (id = ':id', companyId = ':companyId') =>
          `/operationen/anfragen/${id}/unternehmen/${companyId}/verbundene-personen`,
        associatedPersonsMerge: (id = ':id', companyId = ':companyId', personId = ':personId') =>
          `/operationen/anfragen/${id}/unternehmen/${companyId}/verbundene-personen/merge/${personId}`,
        riskAnalysis: (id = ':id', companyId = ':companyId') =>
          `/operationen/anfragen/${id}/unternehmen/${companyId}/risikoanalyse`,
        financialInformation: (id = ':id', companyId = ':companyId') =>
          `/operationen/anfragen/${id}/unternehmen/${companyId}/jahresabschlusse`,
      },
      planningEvaluation: {
        root: (id = ':id') => `/operationen/anfragen/${id}/bewertung-vorhaben`,
        profitabilityCalculation: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation`,
        marketComparison: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/market-comparison`,
        appraiser: (id = ':id') => `/operationen/anfragen/${id}/bewertung-vorhaben/appraiser`,
        editBankValueObjectDataDetails: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/bank-values/bank-values-object-data/edit`,
        editBankValueAdditionalInformationDetails: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/bank-values/bank-values-additional-information/edit`,
        editFinancingCriteriaDetails: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/financingCriteria/edit`,
        propertyProfile: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/property-profile`,
        editMarketValueDetails: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/market-comparison/edit`,
        editMarketValueDetailsRee: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/appraiser/edit`,
        quickCheck: (id = ':id') => `/operationen/anfragen/${id}/bewertung-vorhaben/quick-check`,
        bankValues: (id = ':id') => `/operationen/anfragen/${id}/bewertung-vorhaben/bank-values`,
        financingCriteria: (id = ':id') =>
          `/operationen/anfragen/${id}/bewertung-vorhaben/financingCriteria`,
        profitabilityCalculationEdit: {
          lendingValues: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/beleihungswertermittlung/edit`,
          investmentCosts: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/investitionskosten-erfassen/edit`,
          presalesData: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/vorverkaufsinformationen-erfassen/edit`,
          financingParameters: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/finanzierungsparameter/edit`,
          salesCostRate: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/vertriebskosten-erfassen/edit`,
          rentalParameters: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/berechnungsparameter-erfassen/edit`,
          saleAsUnit: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/wirtschaftlichkeit-aus-verkauf/edit`,
        },
        profitabilityCalculationMittweidaEdit: {
          financingDetails: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/financing-details/edit`,
          investmentPlan: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/investitionskosten-erfassen/edit`,
          sourceOfFunds: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/source-of-funds/edit`,
          profitabilitySales: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/profitability-sales/edit`,
          rent: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/rent/edit`,
          lendingValue: (id = ':id') =>
            `/operationen/anfragen/${id}/bewertung-vorhaben/profitability-calculation/lending-value/edit`,
        },
      },
      details: '/operationen/anfragen/:id/anfragedetails',
      uploadOffer: '/operationen/anfragen/:id/dashboard/upload',
      edit: {
        root: '/operationen/anfragen/:id/edit',
        subsidyForForestry: '/operationen/anfragen/:id/edit/zuschuss-fur-forstwirtschaftsbetriebe',
        contactPerson: '/operationen/anfragen/:id/edit/gesprachspartner',
        financingNeed: '/operationen/anfragen/:id/edit/verwendungszweck',
        companyDetails: '/operationen/anfragen/:id/edit/unternehmen',
        peopleDetails: '/operationen/anfragen/:id/edit/personen',
        requestDetails: '/operationen/anfragen/:id/edit/ausschreibung',
        userProfile: '/operationen/anfragen/:id/edit/benutzerprofil',
        summaryPage: '/operationen/anfragen/:id/edit/uebersichtsseite',
        cancel: '/operationen/anfragen/:id/edit/stornieren',
        contractDetails: '/operationen/anfragen/:id/edit/vertragsdetails',
        customerData: '/operationen/anfragen/:id/edit/kundendaten',
        contactPersonDetails: '/operationen/anfragen/:id/edit/kontaktperson',
      },
    },
  },
  customer: {
    root: '/kunde',
    login: '/kunde/login',
    activation: '/kunde/aktivierung',
    passwordReset: {
      request: '/kunde/passwort-zurucksetzen/anfordern',
      change: '/kunde/passwort-zurucksetzen/andern',
    },
    finishRegistration: '/kunde/einladung-annehmen',
    userData: '/kunde/benutzer',
    inquiryList: '/kunde/anfragen',
    inquiryDetails: {
      root: '/kunde/anfragen/:id',
      dashboard: '/kunde/anfragen/:id/dashboard',
      additionalFields: '/kunde/anfragen/:id/zusatzinformation',
      documentExchange: '/kunde/anfragen/:id/unterlagenaustausch',
      details: '/kunde/anfragen/:id/anfragedetails',
      proofs: '/kunde/anfragen/:id/verwendungsnachweise',
      objectData: '/kunde/anfragen/:id/objektdaten',
      edit: {
        root: '/kunde/anfragen/:id/edit',
        subsidyForForestry: '/operationen/anfragen/:id/edit/zuschuss-fur-forstwirtschaftsbetriebe',
        contactPerson: '/operationen/anfragen/:id/edit/gesprachspartner',
        financingNeed: '/kunde/anfragen/:id/edit/verwendungszweck',
        companyDetails: '/kunde/anfragen/:id/edit/unternehmen',
        peopleDetails: '/kunde/anfragen/:id/edit/personen',
        requestDetails: '/kunde/anfragen/:id/edit/ausschreibung',
        userProfile: '/kunde/anfragen/:id/edit/benutzerprofil',
        summaryPage: '/kunde/anfragen/:id/edit/uebersichtsseite',
        finalPage: '/kunde/anfragen/:id/edit/erfolg',
        cancel: '/kunde/anfragen/:id/edit/stornieren',
        contractDetails: '/kunde/anfragen/:id/edit/vertragsdetails',
        customerData: '/kunde/anfragen/:id/edit/kundendaten',
        contactPersonDetails: '/kunde/anfragen/:id/edit/kontaktperson',
      },
    },
    oauth: '/kunde/oauth',
  },
  inquiry: {
    new: {
      root: '/anfragen/neue',
    },
    draft: {
      edit: '/anfragen/entwurf/:id',
    },
  },
} as const;
