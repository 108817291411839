import React from 'react';

import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useTranslations } from 'utils/hooks/useTranslations';

const CoronaDetailsSection: React.FC = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');

  const fields = useInquiryDetailsFields({ sectionId: INQUIRY_SECTIONS.CORONA_STEP });
  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('coronaStep')}>
      <InformationRows fields={fields} />
    </Section>
  );
};

export default CoronaDetailsSection;
