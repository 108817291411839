import { useState, useCallback } from 'react';

import {
  loadAvailableOrganizationUnitsAction,
  IOrganizationlUnit,
} from 'api/CompeonReverseApi/operation/loadAvailableOrganizationUnits/loadAvailableOrganizationUnits';
import { ApiActionResponseModel } from 'models/ApiActionResponse.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const useAvailableOrganizationUnits = (
  partnerId: string | undefined,
  mustHaveBankAdvisor: boolean = false,
  mustHaveInquiryManager: boolean = false,
) => {
  const [availableOrganizationalUnits, setAvailableOrganizationalUnits] = useState<
    IOrganizationlUnit[]
  >([]);
  const { makeCall } = useDispatchApiCall();

  const fetchAvailableOrganizationUnits = () => {
    makeCall(
      loadAvailableOrganizationUnitsAction(partnerId!, mustHaveBankAdvisor, mustHaveInquiryManager),
      ({ payload }: ApiActionResponseModel) => {
        setAvailableOrganizationalUnits(payload.data?.data);
      },
    );
  };

  return {
    availableOrganizationalUnits,
    fetchAvailableOrganizationUnits: useCallback(fetchAvailableOrganizationUnits, [
      makeCall,
      mustHaveBankAdvisor,
      mustHaveInquiryManager,
      partnerId,
    ]),
  };
};
export default useAvailableOrganizationUnits;
