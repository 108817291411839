import React, { useCallback } from 'react';

import { useToast, UseToastOptions, BoxProps, Flex, IconProps } from '@chakra-ui/react';

import ErrorIcon from 'theme/components/Icon/ErrorIcon';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import SuccessIcon from 'theme/components/Icon/SuccessIcon';
import WarningIcon from 'theme/components/Icon/WarningIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type ToastProps = {
  description: React.ReactNode;
  icon: React.ReactNode;
} & Pick<BoxProps, 'bg' | 'borderColor' | 'className'>;

const iconProps: IconProps = {
  mr: 3,
  w: 6,
  h: 6,
};

const Toast = ({ description, icon, ...rest }: ToastProps) => {
  return (
    <Flex color="text.primary" border="2px" py={4} pl={4} pr={8} {...rest}>
      {icon}
      <TextComponent>{description}</TextComponent>
    </Flex>
  );
};

const defaultOptions: UseToastOptions = {
  duration: 3000,
  position: 'top-right',
};

export function useToasts() {
  const toast = useToast();
  const t = useTranslations();
  const defaultError = t('errors.connectingToServer');

  const success = useCallback(
    (options: UseToastOptions) =>
      toast({
        ...options,
        ...defaultOptions,
        status: 'success',
        render: () => (
          <Toast
            icon={<SuccessIcon color="status.success" {...iconProps} />}
            className="toast-success"
            description={options.description}
            borderColor="status.success"
            bg="status.success_L93"
          />
        ),
      }),
    [toast],
  );

  const info = useCallback(
    (options: UseToastOptions) =>
      toast({
        ...options,
        ...defaultOptions,
        status: 'info',
        render: () => (
          <Toast
            icon={<InfoIcon color="status.info" {...iconProps} />}
            className="toast-info"
            description={options.description}
            borderColor="status.info"
            bg="status.info_L93"
          />
        ),
      }),
    [toast],
  );

  const warning = useCallback(
    (options: UseToastOptions) =>
      toast({
        ...options,
        ...defaultOptions,
        status: 'warning',
        render: () => (
          <Toast
            icon={<WarningIcon color="status.warning" {...iconProps} />}
            className="toast-warning"
            description={options.description}
            borderColor="status.warning"
            bg="status.warning_L93"
          />
        ),
      }),
    [toast],
  );

  const error = useCallback(
    (options: UseToastOptions) =>
      toast({
        ...options,
        ...defaultOptions,
        description: defaultError,
        status: 'error',
        render: () => (
          <Toast
            icon={<ErrorIcon color="status.error" {...iconProps} />}
            className="toast-error"
            description={options.description ?? defaultError}
            borderColor="status.error"
            bg="status.error_L93"
          />
        ),
      }),
    [toast, defaultError],
  );

  return { success, info, warning, error };
}
