/* This hook can be placed inside of a form section to initialize the section's
form fields with the inital values defined upon form creation. This hook only works
for new inquiries, not on inquiry or draft editing pages*/
import { useLayoutEffect } from 'react';

import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useInquiryValues } from 'components/App/InquiryValuesProvider';
import { usePartnerConfig } from 'config/partner/hooks';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { getInquiryDraftIdSelector } from 'store/inquiryProcess/selectors';

export const useInitNewInquiryForm = (fieldNames: string[]) => {
  const draftId = useSelector(getInquiryDraftIdSelector);
  const inquiryId = useSelector(getInquiryIdSelector);
  const { change } = useForm();
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const initialValues = useInquiryValues(partnerName);

  useLayoutEffect(() => {
    const isNewInquiry = !inquiryId && !draftId;
    if (isNewInquiry) {
      fieldNames.forEach((field) => {
        if (initialValues[field]) {
          change(field, initialValues[field]);
        }
      });
    }
  }, [initialValues, change, fieldNames, inquiryId, draftId]);
};
