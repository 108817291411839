import { LabelsMap } from 'new/form/common/types';

import { mittweidaCompanyLabelsMap } from '../inquiry/steps/company/labels';
import { mittweidaFinancingNeedLabelsMap } from '../inquiry/steps/financingNeed/labels';
import { mittweidaFundingDetailsLabelsMap } from '../inquiry/steps/fundingDetails/labels';
import { mittweidaUserProfileLabelsMap } from '../inquiry/steps/userProfile/labels';

export const mittweidaLabelsMap: LabelsMap = {
  ...mittweidaFinancingNeedLabelsMap,
  ...mittweidaFundingDetailsLabelsMap,
  ...mittweidaCompanyLabelsMap,
  ...mittweidaUserProfileLabelsMap,
};
