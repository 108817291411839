import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { LOAN_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const LoanDescription = () => {
  const t = useTranslations();

  return (
    <TextAreaWithField
      name={LOAN_DESCRIPTION}
      optional
      placeholder={t('pages.requestDetails.sections.loan.fields.loanDescription.placeholder', {
        roleSpecific: true,
      })}
    />
  );
};

export default LoanDescription;
