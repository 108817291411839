import styled from 'styled-components/macro';

import { Optional } from 'components/Text';

const StandaloneOptional = styled(Optional)`
  margin-bottom: 1rem;
  text-align: right;
`;

export default StandaloneOptional;
