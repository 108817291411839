import { useEffect, useState } from 'react';

import { createPersistDecorator } from 'final-form-persist';
import _identity from 'lodash/identity';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _mergeWith from 'lodash/mergeWith';
import _unionBy from 'lodash/unionBy';
import { useSelector } from 'react-redux';

import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';
import { getNewInquiryDetails } from 'store/newInquiryDetails/selector';

export const FORM_VALUES_STORAGE_KEY = 'newFormValues';
export const INQUIRY_PROGRESS_STORAGE_KEY = 'newInquiryProgress';

export const { persistDecorator, clear } = createPersistDecorator({
  name: FORM_VALUES_STORAGE_KEY,
  debounceTime: 50,
  storage: sessionStorage,
});

export function useGetInitialValuesForCreateMode(): any | null {
  const values = sessionStorage.getItem(FORM_VALUES_STORAGE_KEY);
  if (values) {
    return JSON.parse(values);
  }
  return null;
}

type GetInitialValuesForEditModeParams = {
  inquiryDetails: any;
  initialValues: any;
};

/**
 * The inquiryDetails we receive from the backend might not contain all the values that we need to start the inquiry
 * process, so we are deepMerging with the initialValues to make sure that the structure of the form values is
 * correct, and we do not run into any errors. We are also cloning the initialValues to not change the initialValues
 * object.
 */
function getInitialValuesForEditMode({
  inquiryDetails,
  initialValues,
}: GetInitialValuesForEditModeParams) {
  let parsedValues = {};
  const values = sessionStorage.getItem(FORM_VALUES_STORAGE_KEY);
  if (values) {
    parsedValues = JSON.parse(values);
  }

  const mergedValues = _mergeWith({}, initialValues, inquiryDetails, parsedValues, customizer);

  return mergedValues;
}

export function useGetInitialValuesForEditMode() {
  const inquiryDetails = useSelector(getNewInquiryDetails);
  const {
    formStateData: { initialFormValues },
  } = useInquiryProcessConfig();

  const [initialValues, setInitialValues] = useState(initialFormValues);
  useEffect(() => {
    const initialValues = getInitialValuesForEditMode({
      inquiryDetails,
      initialValues: initialFormValues,
    });
    setInitialValues(initialValues);
  }, [inquiryDetails, initialFormValues]);

  return initialValues;
}

const filterArray = (arr: Array<any>): Array<any> => {
  // Step 1: Return the array as-is if it has only one item
  if (arr.length <= 1) {
    return arr;
  }

  // Step 2: Apply filtering criteria
  const filtered = arr.filter((item: any) => {
    if (item === null) return false;
    const keys = Object.keys(item);
    if (keys.length === 0) return false; // Filter out empty objects

    // Filter out objects with all empty or undefined keys
    return keys.some((key) => item[key] !== '' && item[key] !== undefined);
  });

  // Step 3: Ensure at least one empty object
  if (filtered.length === 0) {
    filtered.push({});
  }

  return filtered;
};

const mergeArrays = (objArr: any[], srcArr: any[], key: string): any => {
  // Most of saved data has 'id' property, you can use `key` parameter to differentiate between different arrays
  // for now, this should work for arrays like `self-beneficiary-owner`, `beneficiary-owner`, `user-representative`
  const mergedArray = _unionBy(objArr, srcArr, (obj: any) => {
    return obj?.id || _identity(obj);
  });

  return filterArray(mergedArray);
};

const customizer = (objValue: any, srcValue: any, key: string): any => {
  if (_isArray(objValue) && _isArray(srcValue)) {
    return mergeArrays(objValue, srcValue, key);
  } else if (_isObject(objValue) && _isObject(srcValue)) {
    return _mergeWith({}, objValue, srcValue, customizer);
  }
  return srcValue === undefined ? objValue : srcValue;
};

const resetPersistFormValues = () => {
  sessionStorage.removeItem(FORM_VALUES_STORAGE_KEY);
};

const resetPersistInquiryProgress = () => {
  sessionStorage.removeItem(INQUIRY_PROGRESS_STORAGE_KEY);
};

export const resetPersist = () => {
  resetPersistFormValues();
  resetPersistInquiryProgress();
};
