import React from 'react';

import { Link } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import { ELEMENT_IDS } from 'constants/elementIds';
import paths from 'constants/paths';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledCompanyNav, StyledCompanyHeadline } from './styles';
import { useCompaniesDetailsRoutes } from './useCompaniesDetailsRoutes';

const CompanyNavigation: React.FC = () => {
  const t = useTranslations();
  const routeLinks = useCompaniesDetailsRoutes();
  return (
    <StyledCompanyNav aria-labelledby={ELEMENT_IDS.COMPANY_NAVIGATION}>
      <Container>
        <StyledCompanyHeadline id={ELEMENT_IDS.COMPANY_NAVIGATION}>
          Unternehmensnavigation
        </StyledCompanyHeadline>
        <ul>{routeLinks}</ul>
        <Link to={paths.operation.companies.root}>{t('pages.companiesDetails.close')}</Link>
      </Container>
    </StyledCompanyNav>
  );
};

export default CompanyNavigation;
