import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { AxiosResponseAction } from 'models/types/AppThunk.type';
import { SUMMARY } from 'modules/Inquiry/Form/formFields';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';

const DEFAULT_ERROR_PATH = paths.errorDefault;
const errorStatusToPathMap: { [code: number]: string } = {
  403: paths.error403,
  404: paths.error404,
  500: paths.error500,
};

const getErrorPath = (errorCode: number) => errorStatusToPathMap[errorCode] || DEFAULT_ERROR_PATH;

export const useGetRedirectUrlAfterInquirySending = () => {
  const history = useHistory();
  const { pathToNextStep } = useInquiryNavigation(SUMMARY);

  return (response: AxiosResponseAction) => {
    if (response.error) {
      history.push(getErrorPath(response.payload.status));
    } else if (pathToNextStep) {
      history.push(pathToNextStep);
    }
  };
};
