import React from 'react';

import PropTypes from 'prop-types';

const ID_SHORTCUT_LENGTH_LIMITER = 8;
export const getIdShortcut = (id) => id.substring(0, ID_SHORTCUT_LENGTH_LIMITER);

const InquiryId = ({ id }) => {
  return <span title={id}>{getIdShortcut(id)}</span>;
};

InquiryId.propTypes = {
  id: PropTypes.string.isRequired,
};

export default InquiryId;
