import React from 'react';

import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

import RecalculateProgressBarOnMount from 'modules/InquiryFormNew/RecalculateProgressBarOnMount';

const ConditionalSubform = ({ condition, renderOnFail, renderOnSatisfy, subscription }) => {
  return (
    <FormSpy
      subscription={subscription}
      render={(state) =>
        condition(state) ? (
          <RecalculateProgressBarOnMount>{renderOnSatisfy(state)}</RecalculateProgressBarOnMount>
        ) : (
          <RecalculateProgressBarOnMount>{renderOnFail(state)}</RecalculateProgressBarOnMount>
        )
      }
    />
  );
};

ConditionalSubform.propTypes = {
  condition: PropTypes.func.isRequired,
  renderOnFail: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  renderOnSatisfy: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subscription: PropTypes.shape({}),
};

ConditionalSubform.defaultProps = {
  renderOnFail: () => null,
  renderOnSatisfy: () => null,
  subscription: undefined,
};

export default React.memo(ConditionalSubform);
