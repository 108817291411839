import React, { useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import Content from 'components/FormSection/Content';
import Section from 'components/FormSection/Section';
import { Container } from 'components/PortalPage/styles';
import { StyledActions } from 'components/StyledActions';
import { translations } from 'new/form/common/types';
import { ObjectDataForm } from 'pages/customerPortal/InquiryDetails/Dashboard/ObjectData/ObjectDataForm';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const ObjectData = () => {
  const t = useTranslations();
  const ref = useRef<HTMLDivElement>(null);
  const objectDataTranslations = translations.pages.inquiryDetails.objectData;

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    // disable eslint here, as this is intentional
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <Container>
      <Box pt={12} pb={20} ref={ref} scrollMarginTop="96px">
        <Box maxWidth={'800px'}>
          <HeadingComponent variant={'secondary'} mb={3} color={'brand.default'}>
            {t(objectDataTranslations.heading)}
          </HeadingComponent>
          <TextComponent color={'text.tertiary'}>
            {t(objectDataTranslations.subheading)}
          </TextComponent>
        </Box>
        <Section>
          <Content>
            <ObjectDataForm />
          </Content>
        </Section>
        <StyledActions>
          <ButtonComponent
            variant={'primary'}
            leftIcon={<ArrowRightIcon boxSize={6} />}
            type={'submit'}
            form={'objectDataForm'}
          >
            Speichern
          </ButtonComponent>
        </StyledActions>
      </Box>
    </Container>
  );
};

export default ObjectData;
