import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  residentialUnitsCount,
  commercialUnitsCount,
  hasOwnerWithMoreThanThirdOfShares,
  isContractor,
  majorityOwnerShares,
  wegIsSubCommunity,
  propertyIsLeasehold,
  hasMajorityDecision,
} = hausbankFields.objectInformationPage.projectInformationSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.objectInformation.sections.projectInformation;

export const projectInformationSectionLabelsMap: LabelsMap = {
  [residentialUnitsCount]: fieldTranslations.residentialUnitsCount.caption,
  [commercialUnitsCount]: fieldTranslations.commercialUnitsCount.caption,
  [hasOwnerWithMoreThanThirdOfShares]: fieldTranslations.hasOwnerWithMoreThanThirdOfShares.caption,
  [isContractor]: fieldTranslations.isContractor.caption,
  [majorityOwnerShares]: fieldTranslations.majorityOwnerShares.caption,
  [wegIsSubCommunity]: fieldTranslations.wegIsSubCommunity.caption,
  [propertyIsLeasehold]: fieldTranslations.propertyIsLeasehold.caption,
  [hasMajorityDecision]: fieldTranslations.hasMajorityDecision.caption,
};
