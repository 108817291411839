import { INQUIRY_SUBSTATUSES } from 'modules/Inquiry/inquiryStatuses';

export const mmvBusinessConstraints = {
  isCompleted: (subStatus: INQUIRY_SUBSTATUSES) =>
    [
      INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_DONE,
      INQUIRY_SUBSTATUSES.PAYMENT_PLAN_RECEIVED,
    ].includes(subStatus),

  showInquiryDeadline: (substatus: INQUIRY_SUBSTATUSES) =>
    [
      INQUIRY_SUBSTATUSES.INITIATED_EXTERNALLY,
      INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_IDENT_AND_SIGN,
      INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_START,
    ].includes(substatus),
};
