import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CheckmarkLargeIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M9 18a1 1 0 01-.71-.29l-4-4a1 1 0 011.42-1.42L9 15.59l8.29-8.3a1 1 0 111.42 1.42l-9 9A1 1 0 019 18z" />
    </Icon>
  );
};

export default CheckmarkLargeIcon;
