import normalize from 'json-api-normalizer';
import _get from 'lodash/get';

import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import {
  ICompaniesTableOptions,
  CompaniesTable,
} from 'pages/operationPortal/CompaniesList/CompaniesTable.model';
import {
  COMPANIES_TABLE_HEADERS,
  SORT_DIRECTION,
} from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';

export const COMPANIES_API_HEADERS_TO_APP = {
  id: COMPANIES_TABLE_HEADERS.id,
  created_at: COMPANIES_TABLE_HEADERS.createdAt,
  name: COMPANIES_TABLE_HEADERS.name,
  number_of_inquiries: COMPANIES_TABLE_HEADERS.numOfInquiries,
  zip_code_and_city: COMPANIES_TABLE_HEADERS.zipCodeAndCity,
  bank_advisor: COMPANIES_TABLE_HEADERS.bankAdvisor,
};

const getBankAdvisor = (normalizedResponse: any) => (company: any) => {
  const bankAdvisorId = _get(company, 'relationships.bank_advisor.data.id');
  const bankAdvisor = bankAdvisorId ? normalizedResponse.portalUsers[bankAdvisorId] : null;
  return { ...company, bankAdvisor };
};

const { mapOptions } = fieldParsers;
export const mapCompaniesFromApi = (
  apiResponse: any,
): { companies: CompaniesTable[]; options: ICompaniesTableOptions } => {
  const apiOptions = apiResponse.meta;
  const normalized = normalize(apiResponse);

  const companies = apiResponse.data.map(getBankAdvisor(normalized)).map(
    (apiCompany: any): CompaniesTable => ({
      id: apiCompany.id,
      createdAt: apiCompany.attributes.created_at,
      bankAdvisor: {
        firstName: apiCompany.bankAdvisor?.data ? apiCompany.bankAdvisor.data.firstName : '',
        lastName: apiCompany.bankAdvisor?.data ? apiCompany.bankAdvisor.data.lastName : '',
      },
      zipCodeAndCity: {
        zipCode: apiCompany.attributes.zip_code,
        city: apiCompany.attributes.city,
      },
      name: apiCompany.attributes.name,
      numOfInquiries: apiCompany.attributes.number_of_inquiries,
    }),
  );

  const options: ICompaniesTableOptions = {
    totalPages: apiOptions.pagination.total_pages,
    pageSize: apiOptions.pagination.current_per_page,
    page: apiOptions.pagination.current_page,
    sortBy: mapOptions(
      apiOptions.sort.sort_by,
      COMPANIES_API_HEADERS_TO_APP,
    ) as COMPANIES_TABLE_HEADERS,
    sortDirection: apiOptions.sort.direction,
  };
  return { options, companies };
};

export const getSortDirection = (
  currentOptions: ICompaniesTableOptions,
  sortBy: COMPANIES_TABLE_HEADERS,
) => {
  if (currentOptions.sortBy === sortBy) {
    return currentOptions.sortDirection === SORT_DIRECTION.ASC
      ? SORT_DIRECTION.DESC
      : SORT_DIRECTION.ASC;
  }
  return SORT_DIRECTION.DESC;
};
