import { AssetConfig } from 'config/formConfig/types';
import {
  VEHICLE_KIND__CAR,
  VEHICLE_KIND__UTILITY,
  VEHICLE_KIND__TRAILER,
  VEHICLE_KIND__MOTORCYCLE,
  VEHICLE_KIND__OTHER,
} from 'modules/Inquiry/Form/formFields';

const VehicleIcons: AssetConfig[] = [
  {
    id: VEHICLE_KIND__CAR,
    titleId: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleKind.options.car',
    icon: 'pkw',
  },
  {
    id: VEHICLE_KIND__UTILITY,
    titleId:
      'pages.financingNeed.sections.commercialVehicle.fields.vehicleKind.options.utilityVehicle',
    icon: 'lkw',
  },
  {
    id: VEHICLE_KIND__TRAILER,
    titleId: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleKind.options.trailer',
    icon: 'anhaenger',
  },
  {
    id: VEHICLE_KIND__MOTORCYCLE,
    titleId: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleKind.options.motorcycle',
    icon: 'kraftrad',
  },
  {
    id: VEHICLE_KIND__OTHER,
    titleId: 'pages.financingNeed.sections.commercialVehicle.fields.vehicleKind.options.other',
    icon: 'sonstigesFahrzeug',
  },
];

export default VehicleIcons;
