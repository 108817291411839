import React from 'react';

import NumberInput from 'components/PortalFormFields/InputFields/NumberInput';
import { FieldTypeComponentProps } from 'modules/FormGenerator/propTypes';

const IntegerTypeField = ({ field: { name, value, placeholder, hasError }, onChange }) => (
  <NumberInput
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    hasError={hasError}
  />
);

IntegerTypeField.propTypes = FieldTypeComponentProps;

export default IntegerTypeField;
