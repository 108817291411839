import CONFIG from 'shared/featureFlagConfig/configFromAdmin';

import { FeatureFlagsRecord } from './types';

export class FeatureFlagsMapper {
  constructor(public payload: any) {}

  public resolveFlags(): FeatureFlagsRecord {
    return {
      [CONFIG.IS_BANK_ADVISOR_ENABLED]: this.payload['enable-bank-advisors'],
      [CONFIG.IS_FASTLANE_ENABLED]: this.payload['enable-fastlane'],
      [CONFIG.IS_MULTIPLE_COMPANY_INDUSTRY_ENABLED]:
        this.payload['enable-multiselect-industry-input'],
      [CONFIG.IS_INQUIRY_DRAFT_MODE_ENABLED]: this.payload['enable-drafts'],
      [CONFIG.IS_INQUIRY_EDIT_ALLOWED_FOR_CUSTOMER]:
        !this.payload['disable-inquiry-update-by-customer'],
      [CONFIG.IS_INQUIRY_CUSTOMER_CONFIRMATION_ENABLED]:
        !this.payload['skip-user-confirmation-on-inquiry-forward-to-compeon'],
      [CONFIG.ARE_OFFERS_FOR_OP_VISIBLE]: this.payload['enable-offers-visibility-for-portal-users'],
      [CONFIG.IS_COMPEON_FORWARDING_ENABLED]: this.payload['enable-compeon'],
      [CONFIG.IS_SSO_ENABLED]: this.payload['enable-sso'],
      [CONFIG.IS_ADDITIONAL_ASSOCIATION_COMPANY_ENABLED]:
        this.payload['enable-additional-association-company'],
      [CONFIG.IS_COMPEON_MANAGED]: this.payload['enable-compeon-inquiry-management'],
      [CONFIG.IS_CHANGELOG_ON]: this.payload['show-inquiry-change-history-to-portal-users'],
      [CONFIG.IS_SALES_PERSONS_ENABLED]: this.payload['enable-sales-persons'],
      [CONFIG.IS_ORGANIZATIONAL_UNIT_ENABLED]: this.payload['enable-organizational-units'],
      [CONFIG.IS_HIDDEN_EXCLUDED_BANKS_SECTION]: this.payload['hidden-excluded-banks-section'],
      [CONFIG.IS_REGISTRATION_ENABLED]: this.payload['enable-customers-registration'],
      [CONFIG.IS_AGRICULTURAL_INQUIRY_PROCESS_ENABLED]:
        this.payload['enable-agricultural-inquiry-process'],
      [CONFIG.IS_FORESTRY_INQUIRY_PROCESS_ENABLED]: this.payload['enable-forestry-inquiry-process'],
      [CONFIG.IS_COLLECTING_LEADS_ENABLED]: this.payload['enable-collecting-leads'],
      [CONFIG.IS_SHOW_INDICATIVE_FOR_LEAD]:
        this.payload['show-indicative-conditions-for-lead-lane'],
      [CONFIG.IS_SHOW_INDICATIVE_FOR_OFFER]:
        this.payload['show-indicative-conditions-for-offer-lane'],
      [CONFIG.IS_PREMIUM_PARTNER_ENABLED]: this.payload['enable-premium-partners'],
      [CONFIG.IS_DIGITAL_PROCESSING_ENABLED]: this.payload['enable-digital-processing'],
      [CONFIG.IS_CREFO_MANUAL_REPORT_ENABLED]: this.payload['enable-manual-crefo-report'],
      [CONFIG.IS_SMS_TOKEN_ENABLED]: this.payload['enable-sms-token'],
      [CONFIG.REQUIRE_FINAL_INFO]: this.payload['require-final-info'],
      [CONFIG.CUSTOMER_ACTIVATION_ON_INVITATION]: this.payload['customer-activation-on-invitation'],
      [CONFIG.IS_CANCEL_INQUIRY_IN_COMPEON_ENABLED]:
        this.payload['enable-cancel-inquiry-in-compeon'],
      [CONFIG.IS_AUTOMATIC_CONTRACT_CREATION_IN_LEAD]:
        this.payload['automatic-contract-creation-in-lead'],
      [CONFIG.IS_AUTOMATIC_CONTRACT_CREATION_IN_OFFER]:
        this.payload['automatic-contract-creation-in-offer'],
      [CONFIG.IS_MARKET_DATA_ENABLED]: this.payload['enable-market-data'],
      [CONFIG.IS_PROFITABILITY_CALCULATION_ENABLED]:
        this.payload['enable-profitability-calculation'],
      [CONFIG.IS_REAL_ESTATE_EXPERT_ENABLED]: this.payload['enable-real-estate-expert'],
      [CONFIG.IS_EVALUEIN_ENABLED]: this.payload['enable-evaluein'],
      [CONFIG.IS_QUICK_CHECK_ENABLED]: this.payload['enable-quick-check'],
      [CONFIG.IS_COMPEON_EXTERNAL_STATUS_ENABLED]: this.payload['show-compeon-external-status'],
      [CONFIG.IS_INQUIRY_EDITING_ENABLED]: this.payload['enable-inquiry-editing'],
      [CONFIG.IS_NEW_INQUIRY_BUTTON_ENABLED]: this.payload['enable-new-inquiry-button-in-cp'],
      [CONFIG.SHOW_CUSTOMER_CONFIRMATION_STATUS]: this.payload['show-customer-confirmation-status'],
      [CONFIG.ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST]:
        this.payload['enable-multiple-files-per-upload-request'],
    };
  }
}
