import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { NewStaticFieldWithField } from 'components/StaticField';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import Condition from '../../../../../../modules/ConditionalSubform/Condition';
import { fieldIsNotEmpty } from '../../../../../../modules/Inquiry/Form/formConditions';

const { agent, supplier } = mmvFields.contractDetails.dealerSection;
interface SupplierSectionProps {
  sectionNumber?: number;
}
export const SupplierSection = (props: SupplierSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      title={t(translations.inquiryType.mmv.steps.contractDetails.sections.dealer.title)}
      name="dealer"
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField name={supplier} />
        <Condition condition={fieldIsNotEmpty(agent)}>
          <NewStaticFieldWithField name={agent} />
        </Condition>
      </FormRow>
    </FormSection>
  );
};
