import React, { Component } from 'react';

import { func } from 'prop-types';

import Select from 'components/Selects/Select';

Select.clearValueIfInputIsEmpty = () => null;

class AddTagSelect extends Component {
  overridden = {
    handleBackspaceIfValueIsEmpty: () => null,
  };

  handleChange = (value) => {
    const { onChange, addTag } = this.props;

    onChange(value);
    addTag(value);
  };

  render() {
    return <Select {...this.props} onChange={this.handleChange} overridden={this.overridden} />;
  }
}

AddTagSelect.propTypes = {
  onChange: func,
  addTag: func,
};

AddTagSelect.defaultProps = {
  onChange: () => null,
  addTag: () => null,
};

export default AddTagSelect;
