import styled from 'styled-components/macro';

import { borderRadius } from 'theme/themeConstants';

const TagLabel = styled.div`
  border-radius: ${borderRadius.default};
  padding: 1.5rem;
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${(props) => props.theme.colors.primary};
`;

export default TagLabel;
