import React from 'react';

import { Grid } from '@chakra-ui/react';
import { WrappedComponentProps } from 'react-intl';
import { compose } from 'redux';

import PERMISSIONS from 'constants/user/permissions';
import disableInquiryDraft from 'modules/Inquiry/DraftMode/disableInquiryDraft';
import InternalComments from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/InternalComments';
import ProcessLog from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/ProcessLog';
import withRolesGuard from 'shared/guard/withRolesGuard';
import intlShape from 'shared/intlShape';
import { HorizontalSection } from 'theme/components/HorizontalSection';
import { withIntl } from 'utils/intl';

const Communication = ({ intl: { formatMessage } }: WrappedComponentProps) => {
  const sectionTitle = formatMessage({ id: 'pages.inquiryDetails.dashboard.communication.title' });

  return (
    <HorizontalSection label={sectionTitle}>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: '1fr 1fr' }} gap={12}>
        <ProcessLog />
        <InternalComments />
      </Grid>
    </HorizontalSection>
  );
};

Communication.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.COMMUNICATION.DISPLAY),
  withIntl,
  disableInquiryDraft,
)(Communication);
