import _get from 'lodash/get';

import {
  FINANCING_AMOUNT,
  FINANCING_NEED,
  LOAN_CONSIDER_SUBSIDIES,
  REQUEST_DETAILS,
  PURPOSE_KIND,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__BUILDING,
} from 'modules/Inquiry/Form/formFields';
import { getCompany } from 'store/companies/selectors';
import { formatDecimalWithPrecision2 } from 'utils/valueFormats';

export const getMeta = (store) => store.progress.meta;

export const isFormFilledSelector = (stageName) => (store) => {
  const stage = store.progress.stages[stageName];
  if (!stage) {
    return false;
  }
  return stage.currentStep === stage.stepCount;
};
export const getCompanyIndustrySelector = (state) => {
  const company = getCompany(state);
  return company ? company.industry : null;
};

export const getFinancingAmount = ({ progress: { storedValues } }) => {
  const value = _get(storedValues, `${FINANCING_NEED}.${FINANCING_AMOUNT}`) || 0;
  return formatDecimalWithPrecision2(value);
};

export const isSubsidiesConsideredSelector = (store) =>
  _get(store, ['progress', 'storedValues', REQUEST_DETAILS, LOAN_CONSIDER_SUBSIDIES]) === 'true';

export const storedValuesSelector = (store) => store.progress.storedValues;

export const getStoredValueSelector = (progressValuePath) => (store) =>
  _get(store, ['progress', 'storedValues', ...progressValuePath]);

export const getIsKfinanzSelected = (store) =>
  getStoredValueSelector([FINANCING_NEED, PURPOSE_KIND])(store) === PURPOSE_KIND__KFINANZ;

export const getIsCoronaSelected = (store) =>
  getStoredValueSelector([FINANCING_NEED, PURPOSE_KIND])(store) === PURPOSE_KIND__CORONA;

export const getIsBuildingSocietySelected = (store) =>
  getStoredValueSelector([FINANCING_NEED, PURPOSE_KIND])(store) === PURPOSE_KIND__BUILDING;

export const getSelectedPurposeKind = (store) =>
  getStoredValueSelector([FINANCING_NEED, PURPOSE_KIND])(store);

export const getStagesSelector = (store) => store.progress.stages;
export const getCurrentStageSelector = (store) => store.progress.currentStage;

export const getCreatedInquiryId = (store) =>
  store.progress.createdInquiry || store.inquiryDetails.details?.inquiryId;

export const shouldResetFormSelector = (store) => store.progress.shouldResetForm;
