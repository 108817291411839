import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ProgressSectionType } from './types';

type ProgressAdditionalFieldsProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl?: string;
  id?: string;
};

export const ProgressAdditionalFields = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  actionUrl,
  id,
}: ProgressAdditionalFieldsProps) => {
  const t = useTranslations();
  const history = useHistory();

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('pages.inquiryDetails.additionalFields.header')}
          </HeadingComponent>
          <TextComponent mb={3}>
            {t('pages.inquiryDetails.additionalFields.description')}
          </TextComponent>
          {actionUrl && !isCompleted && (
            <ButtonComponent
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              onClick={() => history.push(actionUrl)}
              variant="primary"
            >
              {t('pages.inquiryDetails.additionalFields.action')}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset
              type="dashboard"
              value={ProgressSectionType.ADDITIONAL_FIELDS}
              htmlHeight="200"
              alt=""
            />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
