import {
  DzbLegalRepresentative,
  LegalRepresentative,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { formatDateDays } from 'utils/date';

export const mapLegalRepresentativeToBackendResponse = (
  legalRepresentative: LegalRepresentative,
) => {
  return {
    id: legalRepresentative.id,
    first_name: legalRepresentative.firstName,
    last_name: legalRepresentative.lastName,
    salutation: legalRepresentative.salutation,
    sole_signature_authorized: legalRepresentative.soleSignatureAuthorized,
    phone_number: legalRepresentative.phoneNumber,
    email: legalRepresentative.email,
    birth_date: formatDateDays(legalRepresentative.birthDate),
  };
};

export const mapDzbLegalRepresentativeToBackendResponse = (
  dzbLegalRepresentative: DzbLegalRepresentative,
) => {
  return {
    ...mapLegalRepresentativeToBackendResponse(dzbLegalRepresentative),
    tax_number: dzbLegalRepresentative.taxIdentificationNumber || null,
  };
};
