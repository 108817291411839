import React from 'react';

import { translations } from 'new/form/common/types';
import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface DownloadSignedContractButtonProps {
  actionUrl: string;
  testId?: string;
}
const { download } = translations.components.upload;

export const DownloadSignedContractButton = ({
  actionUrl,
  testId,
}: DownloadSignedContractButtonProps) => {
  const t = useTranslations();

  return (
    <ButtonComponent
      as="a"
      href={actionUrl}
      target="_blank"
      leftIcon={<DownloadIcon boxSize={6} display="block" />}
      variant="primary"
      testId={testId}
    >
      {t(download)}
    </ButtonComponent>
  );
};
