import {
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
  STRUCTURE_NUMBER,
  ADP_NUMBER,
  VM_NUMBER,
  COMPANY_DETAILS_EMAIL,
  USER_PROFILE_PASSWORD,
  USER_PROFILE_PASSWORD_REPEATED,
} from 'modules/Inquiry/Form/formFields';
import {
  fieldValidators,
  customValidations,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { isOnlyLettersRegex } from 'utils/regexes';
import { isValidPassword, equalTo } from 'utils/validators';

const MAX_BUILDING_SOCIETY_PARAMS_LENGTH = 20;
export const companyDetailsFieldValidations = {
  [COMPANY_DETAILS_GENDER]: fieldValidators.string().required(),
  [COMPANY_DETAILS_FIRST_NAME]: fieldValidators.string().regex(isOnlyLettersRegex).required(),
  [COMPANY_DETAILS_LAST_NAME]: fieldValidators.string().regex(isOnlyLettersRegex).required(),
  [COMPANY_DETAILS_EMAIL]: fieldValidators.string().required().custom(customValidations.isEmail),
  [USER_PROFILE_PASSWORD]: fieldValidators.required().custom(isValidPassword),
  [USER_PROFILE_PASSWORD_REPEATED]: fieldValidators
    .required()
    .custom(equalTo(USER_PROFILE_PASSWORD)),
  [STRUCTURE_NUMBER]: fieldValidators
    .maxLength()
    .number(MAX_BUILDING_SOCIETY_PARAMS_LENGTH)
    .required(),
  [ADP_NUMBER]: fieldValidators.maxLength().number(MAX_BUILDING_SOCIETY_PARAMS_LENGTH).required(),
  [VM_NUMBER]: fieldValidators.number().max(InquiryConstants.MAX_VM_NUMBER).unsafe(true),
};
