import { API_METHODS, callReverseApi } from 'api/apiService';
import { CHECK_EMAIL_AVAILABILITY } from 'api/CompeonReverseApi/customer/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const checkEmailAvailabilityAction = (email: string) =>
  callReverseApi({
    actionName: CHECK_EMAIL_AVAILABILITY,
    url: endpoints.USERS.EMAIL_AVAILABILITY.compose(),
    method: API_METHODS.POST,
    data: { email },
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });
