import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { CUSTOMER_COMPANY_NAME } from 'modules/Inquiry/Form/formFields';
import { getCustomerData } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const BusinessOwnerNameStaticField = () => {
  const customerData = useSelector(getCustomerData);

  const t = useTranslations('pages.customerData.sections.businessDetails.fields.businessOwnerName');
  return <StaticField caption={t('caption')} text={customerData[CUSTOMER_COMPANY_NAME]} />;
};

export default BusinessOwnerNameStaticField;
