import { LabelsMap, translations } from 'new/form/common/types';

import { mittweidaFields } from '../../../../fields';

const { description } = mittweidaFields.fundingDetailsPage.otherFundingSection;
const { productDescription } =
  translations.inquiryType.mittweida.pages.fundingDetails.sections.other;

export const otherFundingSectionLabels: LabelsMap = {
  [description]: productDescription.caption,
};
