import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import { mmvFields } from '../../../../fields';

const fields = mmvFields.contractDetails.dealerSection;

const translations =
  translationsObject.inquiryType.mmv.steps.contractDetails.sections.dealer.fields;

export const dealerSectionLabelsMap: LabelsMap = {
  [fields.supplier]: translations.supplier.caption,
  [fields.agent]: translations.agent.caption,
};
