import {
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  END_USER_GENDER,
  USER_PROFILE_FOUNDING_MONTH,
  BFS_USER_PROFILE_INDUSTRY,
  BFS_SERVICE_USER_PROFILE_LEGAL_FORM,
} from 'modules/Inquiry/Form/formFields';
import {
  profileIndustryOptions,
  bfsIndustryOptions,
} from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/service';
import {
  optionsToTranslationMap,
  getIdFromObject,
} from 'modules/Inquiry/inquiryFieldsTranslations/helpers';
import { months } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/service';
import getBfsServiceLegalFormOptions from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/LegalForm/bfsServiceLegalFormOptions';
import getLegalFormOptions from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/LegalForm/legalFormOptions';
import gender from 'store/partner/config/icons/gender';

const genderOptions = gender.reduce(
  (accumulator, singleOption) => ({ ...accumulator, [singleOption.id]: singleOption.titleId }),
  {},
);

export const userProfileValues = {
  [BFS_USER_PROFILE_INDUSTRY]: {
    ...bfsIndustryOptions,
  },
  [USER_PROFILE_LEGAL_FORM]: {
    ...optionsToTranslationMap(getLegalFormOptions(getIdFromObject)),
  },
  [BFS_SERVICE_USER_PROFILE_LEGAL_FORM]: {
    ...optionsToTranslationMap(getBfsServiceLegalFormOptions(getIdFromObject)),
  },
  [USER_PROFILE_INDUSTRY]: {
    ...profileIndustryOptions,
  },
  [USER_PROFILE_FOUNDING_MONTH]: months.reduce(
    (acc, current) => ({ ...acc, [current.value]: current.id }),
    {},
  ),
  [END_USER_GENDER]: {
    ...genderOptions,
  },
};
