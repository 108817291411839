import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  BENEFICIARY_OWNER,
  CUSTOMER_DATA,
  IS_BENEFICIARY_OWNER,
  USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import BusinessDetails from 'pages/inquiryFlow/CustomerData/BussinessDetails';
import ContactPerson from 'pages/inquiryFlow/CustomerData/ContactPerson';
import { usePeopleDetailsValidators } from 'pages/inquiryFlow/PeopleDetails/usePeopleDetailsValidators';
import { initialState } from 'store/inquiryList/reducer';

import { CustomerDataWrapper } from './styles';
import BeneficiaryOwnersSection from '../PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwnersSection';
import LegalRepresentationSection from '../PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import useSendSpecializedInquiry from '../Rentenbank/useSendSpecializedInquiry';
import StepsHeader from '../StepsHeader';

const initialValues = {
  [USER_REPRESENTATIVE]: [],
  [BENEFICIARY_OWNER]: [],
  [IS_BENEFICIARY_OWNER]: 'false',
};

const CustomerDataUnwrapped = () => {
  const getSectionsValidators = usePeopleDetailsValidators();
  const sendSpecializedInquiry = useSendSpecializedInquiry();
  const { pathToNextStep } = useInquiryNavigation(CUSTOMER_DATA);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const { error } = await sendSpecializedInquiry();
    if (!error) {
      if (pathToNextStep) {
        dispatch(
          getInquiriesAction({
            ...initialState.options,
            filterBy: { ...initialState.options.filterBy },
          }),
        );
        history.push(pathToNextStep);
      }
    }
  };

  const children = useCallback(
    () => (
      <CustomerDataWrapper>
        <BusinessDetails sectionNumber={1} />
        <ContactPerson sectionNumber={2} />
        <LegalRepresentationSection sectionNumber={3} />
        <BeneficiaryOwnersSection sectionNumber={4} />
      </CustomerDataWrapper>
    ),
    [],
  );

  return (
    <InquiryFormNew
      initialValues={initialValues}
      currentStepName={CUSTOMER_DATA}
      headerComp={<StepsHeader header={'pages.customerData.heading.header.customer'} />}
      getSectionValidators={getSectionsValidators}
      onSubmit={handleSubmit}
    >
      {children}
    </InquiryFormNew>
  );
};

export default CustomerDataUnwrapped;
