import React from 'react';

import PropTypes from 'prop-types';

import EmptySection from 'components/EmptySection';
import { Container } from 'components/PortalPage/styles';
import { StyledErrorPage } from 'modules/ErrorBoundary/styles';
import intlShape from 'shared/intlShape';
import { reportError } from 'utils/errorTracking/errorTracking';
import { withIntl } from 'utils/intl';

class ErrorBoundary extends React.Component {
  state = {
    errorMsg: '',
  };

  static getDerivedStateFromError(error) {
    reportError(error);
    return { errorMsg: error };
  }

  render() {
    const { errorMsg } = this.state;
    if (errorMsg) {
      const { intl } = this.props;
      return (
        <StyledErrorPage>
          <Container>
            <EmptySection
              title={intl.formatMessage({ id: 'pages.errorPage.error' })}
              desc={errorMsg.message}
            />
          </Container>
        </StyledErrorPage>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  intl: intlShape.isRequired,
};

export default withIntl(ErrorBoundary);
