import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { useFormConfig } from 'config/formConfig/hooks';
import {
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  PEOPLE_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE_PHONE_NUMBER,
  USER_REPRESENTATIVE,
  USER_PROFILE_BIRTHDATE,
} from 'modules/Inquiry/Form/formFields';
import ContactPerson from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import PasswordSection from 'pages/inquiryFlow/CompanyDetails/PasswordSection/PasswordSection';
import LegalRepresentationSection from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import PeopleDetailsHeading from 'pages/inquiryFlow/PeopleDetails/PeopleDetailsHeading';
import { usePeopleDetailsValidators } from 'pages/inquiryFlow/PeopleDetails/usePeopleDetailsValidators';
import { store } from 'store';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { isLoggedInUser } from 'utils/user/conditionals';
import { getCurrentUser } from 'utils/user/getters';

const PeopleDetailsUnwrapped = () => {
  const getSectionsValidators = usePeopleDetailsValidators();

  // if user is loggedIn, set values from store
  const customer = getCurrentUser(store.getState());
  const isLoggedIn = isLoggedInUser();
  const processLane = useSelector(getProcessLane);

  const phoneNumber = useSelector(
    getStoredValueSelector([REQUEST_DETAILS, USER_PROFILE_PHONE_NUMBER]),
  );
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  const userInitialValues = customer
    ? {
        [COMPANY_DETAILS_EMAIL]: customer.email,
        [COMPANY_DETAILS_FIRST_NAME]: customer.firstName,
        [COMPANY_DETAILS_LAST_NAME]: customer.lastName,
        [COMPANY_DETAILS_GENDER]: customer.gender,
        [USER_PROFILE_PHONE_NUMBER]: phoneNumber,
        [USER_PROFILE_BIRTHDATE]: customer.birthDate,
      }
    : {};

  const initialValues = isLoggedIn
    ? { ...userInitialValues, [USER_REPRESENTATIVE]: [{}] }
    : { [USER_REPRESENTATIVE]: [{}], [USER_PROFILE_PHONE_NUMBER]: phoneNumber };

  return (
    <InquiryFormNew
      initialValues={initialValues}
      currentStepName={PEOPLE_DETAILS}
      headerComp={<PeopleDetailsHeading />}
      getSectionValidators={getSectionsValidators}
    >
      {() => (
        <>
          <ContactPerson sectionNumber={1} />
          <Condition condition={!isBfsService}>
            <LegalRepresentationSection sectionNumber={2} />
          </Condition>
          <Condition condition={!isLoggedIn}>
            <PasswordSection
              sectionNumber={isBfsService ? 2 : processLane === InquiryLane.lead ? 2 : 3}
            />
          </Condition>
        </>
      )}
    </InquiryFormNew>
  );
};

export default PeopleDetailsUnwrapped;
