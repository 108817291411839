import React, { useState } from 'react';

import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { useForm } from 'react-final-form';

import FormRow from 'components/FormRow';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import StaticField from 'components/StaticField';
import CustomerDetailsEmail from 'modules/CustomerDetailsEmail';
import { checkCustomerDataAvailable } from 'modules/CustomerDetailsEmail/service';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_BIRTHDATE,
  COMPANY_DETAILS_EMAIL,
} from 'modules/Inquiry/Form/formFields';
import BirthdateField from 'modules/Inquiry/Form/Steps/UserProfile/Birthdate';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useSalutationTranslator } from 'shared/hooks/useSalutationTranslator';
import { TextComponent } from 'theme/components/Text';
import { isEmptyObject } from 'utils/helpers';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';

import PhoneNumberField from '../../../ui/common/phoneNumberField';

export const validateCustomerDetailsSection = (emailExists: boolean) =>
  emailExists
    ? validateSectionFields([COMPANY_DETAILS_EMAIL])
    : validateSectionFields([
        COMPANY_DETAILS_EMAIL,
        COMPANY_DETAILS_GENDER,
        COMPANY_DETAILS_FIRST_NAME,
        COMPANY_DETAILS_LAST_NAME,
        USER_PROFILE_PHONE_NUMBER,
      ]);

export const CustomerDetails = () => {
  const [customerData, setCustomerData] = useState<Record<string, string>>({});
  const translateLabelForField = useInquiryLabelTranslator();
  const translateSalutation = useSalutationTranslator();
  const t = useTranslations();
  const { batch, change } = useForm();

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  const onEmailChange = async (email: string) => {
    const validEmail = isEmailRegex.test(email);
    if (!validEmail) return;
    const { payload } = await makeCall(checkCustomerDataAvailable(email));
    const customerDetails = _get(payload, 'data.data.attributes');

    if (customerDetails) {
      setCustomerData({
        [COMPANY_DETAILS_GENDER]: customerDetails.salutation,
        [COMPANY_DETAILS_FIRST_NAME]: customerDetails.first_name,
        [COMPANY_DETAILS_LAST_NAME]: customerDetails.last_name,
        [USER_PROFILE_PHONE_NUMBER]: customerDetails.phone_number,
        [USER_PROFILE_BIRTHDATE]: customerDetails.birth_date,
      });
      batch(() => {
        change(COMPANY_DETAILS_GENDER, undefined);
        change(COMPANY_DETAILS_FIRST_NAME, undefined);
        change(COMPANY_DETAILS_LAST_NAME, undefined);
        change(USER_PROFILE_PHONE_NUMBER, undefined);
        change(USER_PROFILE_BIRTHDATE, undefined);
      });
    } else {
      setCustomerData({});
    }
  };

  const debounceOnChange = _debounce(onEmailChange, 1000);

  return (
    <>
      <FormRow>
        <TextComponent>
          {t('inquiryType.onlinefactoring.steps.peopleDetails.descriptionBankAdvisor')}
        </TextComponent>
      </FormRow>
      <FormRow>
        <CustomerDetailsEmail onChange={debounceOnChange} />
      </FormRow>
      {isEmptyObject(customerData) ? (
        // show input fields
        <>
          <FormRow>
            <GenderWithField name={COMPANY_DETAILS_GENDER} />
            <InputWithField name={COMPANY_DETAILS_FIRST_NAME} />
            <InputWithField name={COMPANY_DETAILS_LAST_NAME} />
          </FormRow>
          <FormRow>
            <PhoneNumberField name={USER_PROFILE_PHONE_NUMBER} />
          </FormRow>
          <BirthdateField showSeparator />
        </>
      ) : (
        // show static fields with customer data
        <>
          <FormRow>
            <StaticField
              caption={translateLabelForField(COMPANY_DETAILS_GENDER)}
              text={translateSalutation(customerData[COMPANY_DETAILS_GENDER])}
            />
            <StaticField
              caption={translateLabelForField(COMPANY_DETAILS_FIRST_NAME)}
              text={customerData[COMPANY_DETAILS_FIRST_NAME]}
            />
            <StaticField
              caption={translateLabelForField(COMPANY_DETAILS_LAST_NAME)}
              text={customerData[COMPANY_DETAILS_LAST_NAME]}
            />
          </FormRow>
          <FormRow>
            <StaticField
              caption={translateLabelForField(USER_PROFILE_PHONE_NUMBER)}
              text={customerData[USER_PROFILE_PHONE_NUMBER]}
            />
          </FormRow>
          <FormRow>
            <StaticField
              caption={translateLabelForField(USER_PROFILE_BIRTHDATE)}
              text={customerData[USER_PROFILE_BIRTHDATE]}
            />
          </FormRow>
        </>
      )}
    </>
  );
};
