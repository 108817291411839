import React from 'react';

import { ConfigController } from 'config/ConfigController';
import { useFeatureFlags } from 'config/featureFlags/hooks';

const emptyComponent = () => null;

export const chooseConfigSpecificComponent = (componentMap) => (props) => {
  let Component = componentMap.default || emptyComponent;
  const flags = useFeatureFlags();

  Object.entries(componentMap).forEach(([feature, component]) => {
    if (flags[feature]) {
      Component = component;
    }
  });

  return <Component {...props} />;
};

export const chooseConfigSpecificValue = (valueMap) => {
  let selectedValue = valueMap.default;
  Object.entries(valueMap).forEach(([config, value]) => {
    if (ConfigController.featureFlags.getFlagByName(config)) {
      selectedValue = value;
    }
  });
  return selectedValue;
};
