import React, { useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { Form as FinalForm } from 'react-final-form';

import { InputWithField } from 'components/Input';
import { EMAIL_FIELD } from 'modules/Inquiry/Form/formFields';
import { FormContainer } from 'modules/Login/FormContainer';
import { translations } from 'new/form/common/types';
import { requestPasswordResetAction } from 'pages/RequestPasswordReset/service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import ButtonComponent from 'theme/components/Button';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const {
  fields: { email },
  submitLabel,
  heading,
  subheading,
  submitSuccess,
  submitError,
} = translations.pages.requestPasswordReset;

interface RequestPasswordResetValues {
  email: string;
}
export const RequestPasswordReset = () => {
  const [isSent, setIsSent] = useState(false);
  const t = useTranslations();
  const { makeCall, isPending } = useDispatchApiCall(dispatchOptions(t(submitError)));
  const { success } = useToasts();
  const { required, isEmail } = useFieldValidators();

  const handleForgotPassword = async ({ email }: RequestPasswordResetValues) => {
    const response = await makeCall(requestPasswordResetAction(email));
    if (response?.payload?.status === 204) {
      setIsSent(true);
      success({ description: t(submitSuccess) });
    }
  };

  return (
    <FinalForm<RequestPasswordResetValues>
      onSubmit={handleForgotPassword}
      render={({ handleSubmit }) => (
        <FormContainer>
          <FormContainer.HeadingContainer>
            <FormContainer.Heading>{t(heading)}</FormContainer.Heading>
            <FormContainer.SubHeading>{t(subheading)}</FormContainer.SubHeading>
          </FormContainer.HeadingContainer>
          <FormContainer.Body>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Stack spacing={5}>
                  <InputWithField
                    name={EMAIL_FIELD}
                    preventEnterSubmit={false}
                    validate={combineValidators(required, isEmail)}
                    caption={t(email.caption)}
                    disabled={isSent}
                  />
                </Stack>
                <Stack spacing={6}>
                  <ButtonComponent
                    data-testid="request-password-reset-button"
                    isDisabled={isSent}
                    isLoading={isPending}
                    type="submit"
                  >
                    {t(submitLabel)}
                  </ButtonComponent>
                </Stack>
              </Stack>
            </form>
          </FormContainer.Body>
        </FormContainer>
      )}
    />
  );
};
