import _get from 'lodash/get';

import {
  CUSTOMER_GRADE,
  LOCATION_GRADE,
  CUSTOMER_TYPE,
  LOCATION_GRADE_RECOMMENDATION,
  NOTES_ON_FINANCING_CRITERIA,
} from 'modules/Inquiry/Form/formFields';

const mapFinancingCriteriaFromApi = (response: unknown) => {
  const getResponse = _get(response, 'attributes.details.financing_criteria');

  const financingCriteriaValue = {
    [CUSTOMER_GRADE]: _get(getResponse, 'customer_grade', ''),
    [LOCATION_GRADE]: _get(getResponse, 'location_grade', ''),
    [CUSTOMER_TYPE]: _get(getResponse, 'customer_type', ''),
    [LOCATION_GRADE_RECOMMENDATION]: _get(getResponse, 'location_grade_recommendation', ''),
    [NOTES_ON_FINANCING_CRITERIA]: _get(getResponse, 'notes_on_financing_criteria', ''),
  };

  return financingCriteriaValue;
};

export default mapFinancingCriteriaFromApi;
