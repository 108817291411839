import {
  InquiryValueFormatterKeyToReferenceMap,
  InquiryValueFormatterReference,
  InquiryValueFormatterReferenceToKeyMap,
  ValueFormatters,
} from './types';

export const valueFormatters = {
  company: (data) => `${data.street} \n ${data.zip_code} ${data.city}`,
} satisfies ValueFormatters;

const formattersRefsMap = Object.keys(valueFormatters).map((key) => [key, Symbol(key)]);

const symbolToFormatterMap = Object.fromEntries(
  formattersRefsMap.map((entry) => [...entry].reverse()),
) as InquiryValueFormatterReferenceToKeyMap<InquiryValueFormatterKeys>;

export const INQUIRY_VALUE_FORMATTERS_REFS = Object.fromEntries(
  formattersRefsMap,
) as InquiryValueFormatterKeyToReferenceMap<InquiryValueFormatterKeys>;

export const getInquiryValueFormatter = (reference: InquiryValueFormatterReference) => {
  const key = symbolToFormatterMap[reference];
  return valueFormatters[key];
};

type InquiryValueFormatterKeys = keyof typeof valueFormatters;
