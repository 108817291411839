const getPrefinancingPeriodOptions = (formatMessage: any) =>
  [
    {
      label: 'days45',
      value: '45',
    },
    {
      label: 'days60',
      value: '60',
    },
  ].map(({ value, label }) => ({
    value,
    label: formatMessage({
      id: 'components.onlineFactoringRadioGroup.' + label,
    }),
  }));

export default getPrefinancingPeriodOptions;
