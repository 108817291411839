import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { CORONA_VIABILTIY } from 'modules/Inquiry/Form/formFields';

const Viability: React.FC = () => {
  return <YesNoRadioGroupWithField name={CORONA_VIABILTIY} />;
};

export default Viability;
