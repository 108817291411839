import React from 'react';

import { Box, VStack } from '@chakra-ui/react';

import Condition from 'components/Condition';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { StyledFileStatusLabel as StatusLabel } from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import { FileType } from 'models/File.model';
import { FileKind } from 'models/FileKind.type';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useProcessSpecificFiles } from './useProcessSpecificFiles';
import FileSectionTitle from '../FileSectionTitle';

interface ProcessSpecificFilesProps {
  title?: string;
  kinds?: FileKind[];
}

const ProcessSpecificFiles = ({ title, kinds }: ProcessSpecificFilesProps) => {
  const t = useTranslations(`pages.inquiryDetails.documentExchange.processSpecificFiles`);
  const tUpload = useTranslations('components.upload');
  const files = useProcessSpecificFiles(kinds);

  return (
    <Condition condition={Boolean(files.length)}>
      <Box as="section" mb={8}>
        <FileSectionTitle>{title || t('title')}</FileSectionTitle>
        <VStack spacing={8}>
          {files.map((file) => (
            <UploadedFileBlock
              key={file.id}
              type={FileType.ASSESSMENT_FILE}
              file={file}
              canRemove={false}
              label={
                file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED && (
                  <StatusLabel>{tUpload('newFile')}</StatusLabel>
                )
              }
            />
          ))}
        </VStack>
      </Box>
    </Condition>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.mmv]: ProcessSpecificFiles,
  [InquiryType.dzb]: ProcessSpecificFiles,
  [InquiryType.hausbank]: ProcessSpecificFiles,
  default: null,
});
