import * as React from 'react';

import { CheckCircleIcon, TimeIcon } from '@chakra-ui/icons';
import { Center, CenterProps, Tooltip } from '@chakra-ui/react';

import { CustomerConfirmationStatus } from './constants';
import { useCustomerConfirmationStatus } from './useCustomerConfirmationStatus';

const customerConfirmationStatusStylesMap: Record<
  string,
  {
    centerProps: Pick<CenterProps, 'backgroundColor' | 'color' | 'borderColor'>;
    children: React.ReactNode;
  }
> = {
  [CustomerConfirmationStatus.CONFIRMED]: {
    centerProps: {
      backgroundColor: 'status.success_L93',
      color: 'status.success',
      borderColor: 'status.success',
    },
    children: <CheckCircleIcon />,
  },
  [CustomerConfirmationStatus.CONFIRMATION_PENDING]: {
    centerProps: {
      backgroundColor: 'status.info_L93',
      color: 'status.info',
      borderColor: 'status.info',
    },
    children: <TimeIcon />,
  },
};
interface CustomerConfirmationStatusLabelProps {
  customerConfirmationStatus: CustomerConfirmationStatus;
}
const CustomerConfirmationStatusLabel = ({
  customerConfirmationStatus,
}: CustomerConfirmationStatusLabelProps) => {
  const statusText = useCustomerConfirmationStatus(customerConfirmationStatus);
  const { centerProps, children } = customerConfirmationStatusStylesMap[customerConfirmationStatus];
  return (
    <Tooltip label={statusText}>
      <Center p={2} rounded="full" border="1px" {...centerProps}>
        {children}
      </Center>
    </Tooltip>
  );
};

export default CustomerConfirmationStatusLabel;
