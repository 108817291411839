import moment from 'moment';

export const MAX_DESCRIPTION_LENGTH = 2000;
export const MAX_AMOUNT_LENGTH = 9;
export const MAX_SIZE_LENGTH = 9;
export const MAX_SIZE_VALUE = 999999999.99;
export const MIN_SIZE_VALUE = 10;
export const MAX_AMOUNT_VALUE = 999999999.99;
export const BFS_SERVICE_MIN_AMOUNT_VALUE = 0;
export const BFS_SERVICE_MAX_AMOUNT_VALUE = 9999999.99;
export const MAX_INTEGER_VALUE = 999999999;
// FIXME
// eslint-disable-next-line
export const MAX_COMPANY_NUMBER = 99999999999999999999;
export const MAX_SIZE_OF_LAND = 9999999999;
export const MAX_NUMBER_OF_MEMBERS = 99999;
export const MAX_LENGTH_RESPONSIBLE_COURT = 50;
export const MAX_PERCENT_VALUE = 100;
export const MAX_NUMERIC_VALUE_LENGTH = 9;
export const LIQUIDITY_MONTHS_FOR_SMALL_COMPANY = 18;
export const LIQUIDITY_MONTHS_FOR_BIG_COMPANY = 12;
export const MAX_POSTAL_CODE_LENGTH = 5;
export const MAX_INSTITUTION_NAME_LENGTH = 50;
export const MIN_CONSTRUCTION_YEAR = 1950;
export const RANGE_SEPERATOR = '_to_';
export const MORE_THAN = 'more_than_';
export const MIN_BUILDING_YEAR = 1900;
// UTC representation
export const MIN_BUILDING_YEAR_ISO = moment(`${MIN_BUILDING_YEAR}-01-01T00:00:00`)
  .utc()
  .toISOString();
export const MIN_BUILDING_YEAR_DATE = new Date(MIN_BUILDING_YEAR_ISO);
export const MAX_BUILDING_YEARS_SPAN = 5;
export const MAX_BUILDING_YEARS_SPAN_SHORT = 2;
export const MIN_MODERNIZATION_YEAR = 1900;
// UTC representation
export const MIN_MODERNIZATION_YEAR_DATE = moment(`${MIN_BUILDING_YEAR}-01-01T00:00:00`)
  .utc()
  .toDate();
export const MIN_NUMBER_OF_PARKING_LOTS = 0;
export const MAX_NUMBER_OF_PARKING_LOTS = 999;
export const MIN_NUMBER_OF_GARAGES = 0;
export const MAX_NUMBER_OF_GARAGES = 999;
export const MIN_NUMBER_OF_UNITS = 1;
export const MAX_NUMBER_OF_UNITS = 999;
export const MIN_LOT_SIZE = 20;
export const MAX_STRING_SIZE = 150;
export const MIN_NUMBER_OF_DAYS = 1;
export const MAX_NUMBER_OF_DAYS = 99;
