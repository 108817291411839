import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const SendMessageIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M14.6 17.9l-1.9-5.1 2.7-2.7c.4-.4.4-1.1 0-1.5s-1.1-.4-1.5 0l-2.7 2.7-5.1-1.9 13.1-4.6-4.6 13.1zm7.1-15.6c-.3-.3-.7-.4-1.1-.2L2.7 8.4c-.4.1-.7.5-.7 1 0 .4.3.8.7 1l8 3 3 8c.1.4.6.7 1 .7.5 0 .9-.3 1-.7L22 3.5c.1-.5 0-.9-.3-1.2z"
      />
    </Icon>
  );
};

export default SendMessageIcon;
