import styled from 'styled-components/macro';

export const RightMarkerWrapper = styled.div`
  position: absolute;
  right: 26px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const LeftMarkerWrapper = styled.div`
  position: absolute;
  left: 7px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;
