import { useCallback } from 'react';

import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { getCompanyDetailsAction } from '../store/actions';

export const useFetchCompanyDetails = () => {
  const { makeCall, isPending: isLoading } = useDispatchApiCall();

  const fetchCompanyDetails = useCallback(
    async (companyId: string) => {
      await makeCall(getCompanyDetailsAction(companyId));
    },
    [makeCall],
  );
  return { isLoading, fetchCompanyDetails };
};
