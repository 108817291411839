import React from 'react';

import {
  Box,
  Flex,
  Heading,
  HStack,
  chakra,
  useMultiStyleConfig,
  VisuallyHidden,
} from '@chakra-ui/react';

import { useUnitIndicator } from './hooks/useUnitIndicator';
import { Kpi } from '../FinancialInformations/hooks/useKpiFilter';

interface KpiProps {
  data: Kpi | undefined;
  previousData: Kpi | undefined;
  description: string;
}

export const KpiCard = ({ data, previousData, description }: KpiProps) => {
  const styles = useMultiStyleConfig('KpiCard', {});
  return (
    <Box sx={styles.container}>
      <Flex sx={styles.bulletsContainer}>
        <HStack sx={styles.bullets}>
          <Box
            backgroundColor={data?.benchmark === 'green' ? 'green.400' : 'gray.300'}
            sx={styles.bullet}
          >
            <VisuallyHidden>Status: green</VisuallyHidden>
          </Box>
          <Box
            backgroundColor={data?.benchmark === 'red' ? 'red.400' : 'gray.300'}
            sx={styles.bullet}
          >
            <VisuallyHidden>Status: red</VisuallyHidden>
          </Box>
        </HStack>
      </Flex>
      <Box sx={styles.contentContainer}>
        <Heading sx={styles.caption}>{description}</Heading>
        <Flex sx={styles.content}>
          <Flex sx={styles.selectedYear}>
            <dl>
              <chakra.dt sx={styles.year}>
                {data?.timestamp !== undefined
                  ? new Date(data?.timestamp).getFullYear()
                  : previousData?.timestamp
                  ? new Date(previousData?.timestamp).getFullYear() + 1
                  : ''}
              </chakra.dt>
              <chakra.dd sx={styles.value}>
                {useUnitIndicator({ value: data?.value, unit: data?.unit })}
              </chakra.dd>
            </dl>
          </Flex>
          <Flex sx={styles.previousYear}>
            <dl>
              <chakra.dt sx={styles.year}>
                {data?.timestamp !== undefined
                  ? new Date(data?.timestamp).getFullYear() - 1
                  : previousData?.timestamp
                  ? new Date(previousData?.timestamp).getFullYear()
                  : ''}
              </chakra.dt>
              <chakra.dd sx={styles.value}>
                {useUnitIndicator({ value: previousData?.value, unit: previousData?.unit })}
              </chakra.dd>
            </dl>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
