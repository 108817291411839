import { objectInformationSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/objectInformationSection/fieldValidations';
import { otherProjectInformationSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/otherProjectInformationSection/fieldValidations';
import { projectCompositionSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/projectCompositionSection/fieldValidations';
import { roleSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/roleSection/fieldValidations';

export const mittweidaFinancingNeedFieldValidation = {
  ...roleSectionValidationMap,
  ...projectCompositionSectionValidationMap,
  ...objectInformationSectionValidationMap,
  ...otherProjectInformationSectionValidationMap,
};
