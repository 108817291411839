import React from 'react';

import { Field, Form as FinalForm } from 'react-final-form';
import { WrappedComponentProps } from 'react-intl';

import FieldGroup from 'components/PortalFormFields/FieldGroup';
import { ButtonComponent } from 'theme/components/Button';
import { withIntl } from 'utils/intl';
import { combineValidators, maxLength, required } from 'utils/validators';

import { StyledDescription, StyledTextArea } from './styles';
import { StyledHeading } from '../../styles';

const TEXT_AREA_MAX_LENGTH = 500;

type FormProps = {
  onSubmit: (values: any) => void;
} & WrappedComponentProps;

const Form = ({ onSubmit, intl: { formatMessage } }: FormProps) => {
  const t = (id: string) =>
    formatMessage({ id: `pages.inquiryDetails.dashboard.closeInquiry.archiveInquiry.${id}` });

  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <StyledHeading>{t('title')}</StyledHeading>
            <StyledDescription>{t('description')}</StyledDescription>

            <Field
              name="archivingReason"
              data-testid="Rejection-Reason"
              validate={combineValidators(
                required(formatMessage({ id: 'errors.required' })),
                maxLength(
                  TEXT_AREA_MAX_LENGTH,
                  formatMessage(
                    { id: 'errors.maxLengthTextCharacters' },
                    { number: TEXT_AREA_MAX_LENGTH },
                  ),
                ),
              )}
            >
              {({ input, meta }) => (
                <FieldGroup error={meta.touched && meta.error ? meta.error : null}>
                  <StyledTextArea placeholder={t('placeholder')} {...input} {...meta} />
                </FieldGroup>
              )}
            </Field>

            <ButtonComponent mt={4} type="submit">
              {t('archiveInquiryBtn')}
            </ButtonComponent>
          </form>
        )}
      />
    </>
  );
};

export default withIntl(Form);
