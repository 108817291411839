import { mmvFields } from 'mmv/inquiry/fields';
import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import {
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  FICTITIOUS_BENEFICIARY_OWNER,
} from '../../../../../../modules/Inquiry/Form/formFields';

const fields = mmvFields;
const translations =
  translationsObject.inquiryType.mmv.steps.customerDataPage.sections.beneficialOwnerSection.fields;

export const beneficialOwnerSectionLabelsMap: LabelsMap = {
  [fields['beneficiary-owner.beneficiary-company-share']]: translations.companyShare.caption,
  [fields['beneficiary-owner.beneficiary-tax-id']]: translations.beneficiaryTaxId.caption,
  [fields['beneficiary-owner.beneficiary-private-address']]:
    translations.beneficiaryPrivateAddress.caption,
  [fields['beneficiary-owner.beneficiary-private-city']]:
    translations.beneficiaryPrivateCity.caption,
  [fields['beneficiary-owner.beneficiary-private-country']]:
    translations.beneficiaryPrivateCountry.caption,
  [fields['beneficiary-owner.beneficiary-zip-code']]: translations.beneficiaryZipCode.caption,
  [fields['beneficiary-owner.beneficiary-birth-country']]:
    translations.beneficiaryBirthCountry.caption,
  [fields['beneficiary-owner.beneficiary-birthday']]: translations.beneficiaryBirthday.caption,
  [fields['beneficiary-owner.beneficiary-first-name']]: translations.firstName.caption,
  [fields['beneficiary-owner.beneficiary-last-name']]: translations.lastName.caption,
  [fields['beneficiary-owner.beneficiary-gender']]: translations.gender.caption,

  [fields['self-beneficiary-owner.beneficiary-company-share']]: translations.companyShare.caption,
  [fields['self-beneficiary-owner.beneficiary-tax-id']]: translations.beneficiaryTaxId.caption,
  [fields['self-beneficiary-owner.beneficiary-private-address']]:
    translations.beneficiaryPrivateAddress.caption,
  [fields['self-beneficiary-owner.beneficiary-private-city']]:
    translations.beneficiaryPrivateCity.caption,
  [fields['self-beneficiary-owner.beneficiary-private-country']]:
    translations.beneficiaryPrivateCountry.caption,
  [fields['self-beneficiary-owner.beneficiary-zip-code']]: translations.beneficiaryZipCode.caption,
  [fields['self-beneficiary-owner.beneficiary-birth-country']]:
    translations.beneficiaryBirthCountry.caption,
  [fields['self-beneficiary-owner.beneficiary-birthday']]: translations.beneficiaryBirthday.caption,
  [fields['self-beneficiary-owner.beneficiary-first-name']]: translations.firstName.caption,
  [fields['self-beneficiary-owner.beneficiary-last-name']]: translations.lastName.caption,
  [fields['self-beneficiary-owner.beneficiary-gender']]: translations.gender.caption,
  [fields.customerDataPage.beneficialOwnerSection.isFictitiousBeneficialOwner]:
    translations.isFictitiousBeneficialOwner.caption,

  // for overview page
  [BENEFICIARY_COMPANY_SHARE]: translations.companyShare.caption,
  [BENEFICIARY_TAX_ID]: translations.beneficiaryTaxId.caption,
  [BENEFICIARY_PRIVATE_ADDRESS]: translations.beneficiaryPrivateAddress.caption,
  [BENEFICIARY_PRIVATE_CITY]: translations.beneficiaryPrivateCity.caption,
  [BENEFICIARY_PRIVATE_COUNTRY]: translations.beneficiaryPrivateCountry.caption,
  [BENEFICIARY_ZIP_CODE]: translations.beneficiaryZipCode.caption,
  [BENEFICIARY_BIRTH_COUNTRY]: translations.beneficiaryBirthCountry.caption,
  [BENEFICIARY_BIRTHDAY]: translations.beneficiaryBirthday.caption,
  [BENEFICIARY_FIRST_NAME]: translations.firstName.caption,
  [BENEFICIARY_LAST_NAME]: translations.lastName.caption,
  [BENEFICIARY_GENDER]: translations.gender.caption,
  [FICTITIOUS_BENEFICIARY_OWNER]: translations.isFictitiousBeneficialOwner.caption,
};
