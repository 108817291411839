import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledTile,
  StyledWrapper,
} from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKindList/ProductKindTile/styles';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProductKindTile = ({ icon, title, selected, onClick, id, description, name }) => {
  const t = useTranslations('buttons');

  return (
    <StyledWrapper>
      <StyledTile
        name={name}
        type="button"
        className={selected && 'selected'}
        onClick={onClick}
        data-testid={id}
      >
        <div className="icon">{icon}</div>
        <span className="title">{title}</span>
      </StyledTile>

      {description && (
        <PopoverComponent
          position="absolute"
          zIndex="above"
          top="0"
          right="0"
          transform="translate(-50%, -50%)"
          text={description}
          trigger={
            <IconButtonComponent
              icon={<InfoIcon boxSize={6} display="block" />}
              label={t('moreInfos')}
            />
          }
        />
      )}
    </StyledWrapper>
  );
};

ProductKindTile.defaultProps = {
  selected: false,
  onClick: () => null,
  description: null,
};

ProductKindTile.propTypes = {
  icon: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  description: PropTypes.string,
};

export default ProductKindTile;
