import React from 'react';
import { ComponentType, ReactElement } from 'react';

import { useAssociatedPeopleMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/AssociatedPeopleModeProvider';

export const withAssociatedPeopleEditMode = <P extends object>(
  Component: ComponentType<P>,
): React.FC<P> => {
  return function ModeComponent(props: P): ReactElement | null {
    const { mode } = useAssociatedPeopleMode();

    if (mode === 'view') {
      return null;
    }

    return <Component {...props} />;
  };
};
