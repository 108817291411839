import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { CUSTOMER_FIRST_NAME } from 'modules/Inquiry/Form/formFields';
import { getCustomerData } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const FirstNameStaticField = () => {
  const customerData = useSelector(getCustomerData);
  const t = useTranslations('pages.customerData.sections.contactPerson.fields.firstName');
  return <StaticField caption={t('caption')} text={customerData[CUSTOMER_FIRST_NAME]} />;
};

export default FirstNameStaticField;
