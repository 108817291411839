import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldsAreValid, fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_VIABILTIY,
  CORONA_REVENUE_OF_2019,
  CORONA_FINANCING_OPTION,
  CORONA_FOUNDING_YEAR,
  CORONA_FOUNDING_MONTH,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_FOUNDING_MONTH,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  FINANCING_AMOUNT,
} from 'modules/Inquiry/Form/formFields';
import CoronaFoundingMonth from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/CoronaFoundingMonth';
import CountOfEmployees from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/CountOfEmployees';
import FoundingYear from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/FoundingYear';
import MaxFinancingAmountFields from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/MaxFinancingAmountFields';
import RevenueOf2019 from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/RevenueOf2019';
import Viability from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/Viability';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

const areCommonFieldsValid = fieldsAreValid([
  CORONA_FOUNDING_YEAR,
  CORONA_FOUNDING_MONTH,
  CORONA_VIABILTIY,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_REVENUE_OF_2019,
]);

export const validateQuickCreditSection = (form: any) => {
  const areFieldsValid = fieldsAreValid([
    USER_PROFILE_FOUNDING_YEAR,
    USER_PROFILE_FOUNDING_MONTH,
    CORONA_VIABILTIY,
    CORONA_NUMBER_OF_EMPLOYEES,
    CORONA_REVENUE_OF_2019,
    CORONA_FINANCING_OPTION,
  ])({ form });

  const hasSelectedQuickCredit = fieldHasValue(
    CORONA_FINANCING_OPTION,
    CORONA_FINANCING_OPTION__QUICK_CREDIT,
  )({ form });

  if (hasSelectedQuickCredit) {
    return areFieldsValid && fieldsAreValid([CORONA_FINANCING_OPTION, FINANCING_AMOUNT])({ form });
  }
  return areFieldsValid;
};

const QuickCreditSection: React.FC = () => {
  const t = useTranslations('pages.coronaStep.subsections');
  return (
    <FormSection sectionNumber={2} name={FormSections.quickCredit} title={t('quickCredit')}>
      <FormRow>
        <FoundingYear />
      </FormRow>
      <FormRow>
        <CoronaFoundingMonth />
      </FormRow>
      <FormRow>
        <Viability />
      </FormRow>
      <FormRow>
        <CountOfEmployees />
      </FormRow>
      <FormRow>
        <RevenueOf2019 />
      </FormRow>

      <Condition condition={areCommonFieldsValid}>
        <MaxFinancingAmountFields />
      </Condition>
    </FormSection>
  );
};

export default QuickCreditSection;
