import { LabelsMap, translations } from 'new/form/common/types';

import { mittweidaFields } from '../../../../fields';

const { password, passwordConfirmation } = mittweidaFields.userProfilePage.passwordSection;
const { password: passwordSection } = translations.inquiryType.mittweida.pages.userDetails.sections;

export const passwordSectionLabels: LabelsMap = {
  [password]: passwordSection.password.caption,
  [passwordConfirmation]: passwordSection.passwordConfirmation.caption,
};
