import React, { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { ConditionPropsFor } from 'components/Condition/types';
import { PurposeKind } from 'models/PurposeKind.model';
import { getInquiryPurposeKind } from 'store/inquiryDetails/selectors';
import { getSelectedPurposeKind } from 'store/progress/selectors';

type PurposeCases = {
  [key in PurposeKind]?: ReactNode;
} & {
  default: ReactNode;
};

/*
  This component decides on what to display based on purpose kind
*/
const PurposeCondition = ({ cases, portal }: ConditionPropsFor<PurposeCases>) => {
  const purposeKindInquiry = useSelector(getSelectedPurposeKind);
  const purposeKindPortal = useSelector(getInquiryPurposeKind);

  const purposeKind: PurposeKind = portal ? purposeKindPortal : purposeKindInquiry;
  const condition = !!purposeKind && Object.prototype.hasOwnProperty.call(cases, purposeKind);

  return (
    <Condition condition={condition} fallback={cases.default}>
      {purposeKind && cases[purposeKind]}
    </Condition>
  );
};

export default PurposeCondition;
