import * as React from 'react';

import { useSelector } from 'react-redux';

import EditInquiryLink from 'components/EditInquiryLink';
import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import { StyledSummaryList } from 'modules/InquiryDetails/DetailedInformation/SummarySection/styles';
import SummaryBlock from 'modules/InquiryDetails/DetailedInformation/SummarySection/SummaryBlock';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import InquiryRevision from 'modules/InquiryDetails/InquiryRevision/InquiryRevision';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import { translations } from 'new/form/common/types';
import { PortalInquiryDetails } from 'new/summary/components/SummarySections';
import { SummaryStepProvider } from 'new/summary/SummaryPageContext';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { getNewInquiryDetails } from 'store/newInquiryDetails/selector';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { useSummaryCardData } from '../../Dashboard/useSummaryCardData';

type Props = {
  pathToEditInquiry: string;
};

export const MittweidaDetailedInquiryInformation = ({ pathToEditInquiry }: Props) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const values: MittweidaFieldTypes = useSelector(getNewInquiryDetails);
  const canEdit = useCanEdit();
  const t = useTranslations();
  const data = useSummaryCardData();
  const { title, financingRequirement } = translations.pages.inquiryDetails.detailed.summary;
  const { title: roleTitle } = translations.inquiryType.mittweida.pages.financingNeed.sections.role;

  return (
    <>
      <InquiryDetailsHeader>
        {canEdit && <EditInquiryLink to={pathToEditInquiry.replace(':id', inquiryId!)} />}
      </InquiryDetailsHeader>
      <Section title={t(title)}>
        <StyledSummaryList>
          <SummaryBlock
            name={t(financingRequirement)}
            value={formatPrice(values.financingNeedPage.projectCompositionSection.financingAmount)}
          />
          <SummaryBlock name={t(roleTitle)} value={data.role} />
        </StyledSummaryList>
      </Section>
      <SummaryStepProvider<MittweidaFieldTypes> values={values}>
        <PortalInquiryDetails />
      </SummaryStepProvider>
      <InquiryRevision />
    </>
  );
};
