import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';

const in_process = (status: string | undefined) => {
  return (status = INQUIRY_STATUSES.IN_PROGRESS);
};

export const withCanEditInquiryGuard =
  (Component: React.FC) =>
  (...props: any) => {
    const history = useHistory();
    const canEdit = useCanEdit();
    const { id } = useParams<{ id: string }>();
    const inquiryDetails = useSelector(getInquiryDetailsSelector);

    const inquiryId = inquiryDetails?.inquiryId;

    if (inquiryId !== id) {
      return <Spinner />;
    }

    if (!canEdit) {
      if (!inquiryDetails) {
        history.push(paths.customer.inquiryList);
      } else {
        const { status } = inquiryDetails;

        if (in_process(status)) {
          history.push(paths.customer.inquiryDetails.dashboard);
        }

        history.push(paths.customer.inquiryList);
      }
    }

    return <Component {...props} />;
  };
