import _get from 'lodash/get';

import {
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './mapProfitabilitySectionsToApi/utils';

const mapMwMarketValueToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');
  const currentSharedValues = {
    residential_rent_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM, initialValue)),
    ),
    existing_rent_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, CURRENT_USAGE_EXISTING_RENT_PER_SQM, initialValue)),
    ),
    commercial_rent_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM, initialValue)),
    ),
  };
  const futureSharedValues = {
    residential_rent_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM, initialValue)),
    ),
    existing_rent_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, FUTURE_USAGE_EXISTING_RENT_PER_SQM, initialValue)),
    ),
    commercial_rent_per_sqm: toFloatPrecision2(
      unknownToString(_get(data, FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM, initialValue)),
    ),
  };

  const marketValueData = {
    current: {
      building: {
        comparative_value_per_sqm: toFloatPrecision2(
          unknownToString(_get(data, CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING, initialValue)),
        ),
        ...currentSharedValues,
      },
      unit: {
        comparative_value_per_sqm: toFloatPrecision2(
          unknownToString(_get(data, CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT, initialValue)),
        ),
        ...currentSharedValues,
      },
    },
    future: {
      building: {
        comparative_value_per_sqm: toFloatPrecision2(
          unknownToString(_get(data, FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING, initialValue)),
        ),
        ...futureSharedValues,
      },
      unit: {
        comparative_value_per_sqm: toFloatPrecision2(
          unknownToString(_get(data, FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT, initialValue)),
        ),
        ...futureSharedValues,
      },
    },
  };

  return marketValueData;
};

export default mapMwMarketValueToApi;
