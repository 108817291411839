import React from 'react';

import { useSelector } from 'react-redux';

import { USER_PROFILE_FOUNDING_MONTH, FINANCING_NEED } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import FoundingMonthField from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/FoundingMonthField';
import StaticFoundingMonthField from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/StaticFoundingMonth';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getStoredValueSelector } from 'store/progress/selectors';

const FoundingMonth = () => {
  const monthFromCoronaStep = useSelector(
    getStoredValueSelector([FINANCING_NEED, USER_PROFILE_FOUNDING_MONTH]),
  );

  const foundingMonth = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: null,
    default: useGetSelectedCompanyParam(COMPANY_FIELDS.foundingMonth),
  });

  const alreadySavedValue = monthFromCoronaStep || foundingMonth;
  if (alreadySavedValue) {
    return <StaticFoundingMonthField foundingMonth={alreadySavedValue} />;
  }

  return <FoundingMonthField />;
};

export default FoundingMonth;
