import React from 'react';

import { Divider } from '@chakra-ui/react';

import { PARTNERS } from 'constants/partner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import { choosePartnerSpecificComponent } from 'shared/choosePartnerSpecificComponent';

const SummaryHeader = () => (
  <>
    <InquiryDetailsHeader />
    <Divider mt={4} mb={8} color="border.darkGrey" />
  </>
);

export default choosePartnerSpecificComponent({
  default: SummaryHeader,
  [PARTNERS.BFSS]: null,
});
