export enum AccountUnlockStatus {
  success = 'success',
  fail = 'fail',
}

export type Scope = 'customer' | 'operation';

export const UnlockScope: Record<string, Scope> = {
  customer: 'customer',
  operations: 'operation',
};

export const ACCOUNT_UNLOCK_QUERY_KEY = 'unlock';
export const ACCOUNT_UNLOCK_SCOPE_QUERY_KEY = 'target';
