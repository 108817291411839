import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import LoanIcon from 'theme/components/Icon/ProductKind/LoanIcon';

import LoanDuration from '../LoanDuration/LoanDuration';
import LoanType from '../LoanType/LoanType';

const LoanSummary = () => {
  return (
    <>
      <FormRow separator>
        <FinancingAmountSummary icon={<LoanIcon />} />
      </FormRow>
      <FormRow>
        <LoanType />
        <LoanDuration />
      </FormRow>
    </>
  );
};

export default LoanSummary;
