import { validateBeneficialOwnersSection } from 'dzb/inquiry/steps/personalData/sections/beneficialOwnersSection/validations';
import { validateContactPersonSection } from 'dzb/inquiry/steps/personalData/sections/contactPersonSection/validations';
import { validateLegalRepresentativesSection } from 'dzb/inquiry/steps/personalData/sections/legalRepresentativesSection/validations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';
import { InquiryProcessValidations } from 'new/form/model';

import { validateAssociationInformationSection } from '../steps/companyData/sections/associationInformationSection/validations';
import { validateCompanyInformationSection } from '../steps/companyData/sections/companyInformationSection/validations';
import { validateDirectDebitMandateSection } from '../steps/companyData/sections/directDebitMandateSection/validations';
import { validateStartUpQuestionnaireSection } from '../steps/companyData/sections/startUpQuestionnaireSection/validations';
import { validateOnlineQuestionnaireSection } from '../steps/retailerData/sections/onlineQuestionnaireSection/validations';
import { validateRetailerInformationSection } from '../steps/retailerData/sections/retailerInformationSection/validations';

const dzbDefaultValidations: ValidationRecord<InquiryType.dzb> = {
  companyDataPage: {
    associationInformationSection: validateAssociationInformationSection,
    companyInformationSection: validateCompanyInformationSection,
    directDebitMandateSection: validateDirectDebitMandateSection,
    startUpQuestionnaireSection: validateStartUpQuestionnaireSection,
  },
  retailerDataPage: {
    onlineQuestionnaireSection: validateOnlineQuestionnaireSection,
    retailerInformationSection: validateRetailerInformationSection,
  },
  personalDataPage: {
    contactPersonSection: validateContactPersonSection,
    legalRepresentativesSection: validateLegalRepresentativesSection,
    beneficialOwnersSection: validateBeneficialOwnersSection,
  },
};

export const dzbValidations: InquiryProcessValidations<InquiryType.dzb> = {
  create: dzbDefaultValidations,
  editCustomer: dzbDefaultValidations,
  editOperation: dzbDefaultValidations,
};
