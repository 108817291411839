import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS } from 'modules/Inquiry/Form/formFields';

const InvestmentLoanInterestedInOtherProducts: React.FC = () => {
  return <YesNoRadioGroupWithField name={INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS} />;
};

export default InvestmentLoanInterestedInOtherProducts;
