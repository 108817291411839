const date = new Date();
date.setMonth(date.getMonth() - 24);

export const maxReportingDate = new Date();
export const minReportingDate = date;

export const filterReportingDates = (date: Date) => {
  const currentDate = new Date(date);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  return currentDate.getDate() === lastDayOfMonth;
};
