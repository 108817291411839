import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  OVERDRAFT_TERMINABILITY,
  OVERDRAFT_END_AT,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
} from 'modules/Inquiry/Form/formFields';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { FormSections } from 'schema/inquirySchema.models';
import OverdraftIcon from 'theme/components/Icon/ProductKind/OverdraftIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export const overdraftConditions = {
  isTerminability: fieldHasValue(OVERDRAFT_TERMINABILITY, 'true'),
};

const Overdraft = () => {
  const t = useTranslations('pages.requestDetails.sections.overdraft');

  return (
    <FormSection name={FormSections.overdraft} sectionNumber={1} title={t('title')}>
      <FormRow separator>
        <FinancingAmountSummary icon={<OverdraftIcon />} />
      </FormRow>
      <FormRow separator>
        <YesNoRadioGroupWithField
          name={OVERDRAFT_TERMINABILITY}
          tooltip={t('fields.overdraftTerminability.tooltip')}
        />
        <DayPickerWithField name={OVERDRAFT_END_AT} minDate={InquiryConstants.TOMORROW} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING}
          tooltip={t('fields.overdraftInterestedInAlternativeFinancing.tooltip')}
        />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default Overdraft;
