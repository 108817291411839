import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';

export const InquiryOffersContext = React.createContext([]);

export const InquiryOffersProvider = ({ children }) => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const [bankOffers, updateBankOffers] = useState(inquiryDetails.bankOffers);
  const [compeonOffers, updateCompeonOffers] = useState(inquiryDetails.compeonOffers);

  return (
    <InquiryOffersContext.Provider
      value={{
        bankOffers,
        compeonOffers,
        updateBankOffers,
        updateCompeonOffers,
      }}
    >
      {children}
    </InquiryOffersContext.Provider>
  );
};

InquiryOffersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
