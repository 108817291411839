import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const SaveIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M20 19c0 .6-.4 1-1 1h-1v-6.1c0-1.6-1.2-2.9-2.8-2.9H8.8C7.2 11 6 12.3 6 13.9V20H5c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h11c.2 0 .5.1.7.3l3.1 3.1c.1.1.2.4.2.6v11zm-4 1H8v-6.1c0-.5.3-.9.8-.9h6.4c.5 0 .8.4.8.9V20zm5.1-14L18 2.9c-.5-.6-1.2-.9-2-.9H5C3.3 2 2 3.3 2 5v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V8c0-.8-.3-1.5-.9-2z" />
      <path d="M14.5 6H7c-.6 0-1 .4-1 1s.4 1 1 1h7.5c.6 0 1-.4 1-1s-.4-1-1-1" />
    </Icon>
  );
};

export default SaveIcon;
