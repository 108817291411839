import _get from 'lodash/get';

import paths from 'constants/paths';

export const getPathForError = (statusCode) => {
  const statuses = {
    403: paths.error403,
    404: paths.error404,
    500: paths.error500,
  };
  return statuses[statusCode] ?? paths.errorDefault;
};

export const redirectOnUnauthenticated = (history, response) => {
  const { push } = history;
  const errorStatus = _get(response, 'error.response.status');

  if (errorStatus === 401) {
    push(paths.customer.login);
  }
  return response;
};
