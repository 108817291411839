import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { showRwdMenu } from 'store/layout/actions';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const NewInquiryButton: React.FC = () => {
  const t = useTranslations('components.header');
  const history = useHistory();

  const dispatch = useDispatch();
  const redirectToNewInquiry = () => {
    dispatch(showRwdMenu(false));
    history.push(paths.inquiry.new.root);
  };
  return (
    <ButtonComponent onClick={redirectToNewInquiry} data-testid="new-inquiry-button">
      {t('newInquiry')}
    </ButtonComponent>
  );
};

export default NewInquiryButton;
