import React from 'react';

import Multiselect from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/Multiselect';
import Select from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/Select';
import Static from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/Static';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';

const ProfileIndustry = () => {
  const companyIndustry = useGetSelectedCompanyParam(COMPANY_FIELDS.industry);

  const isMultipleChoice = useConfig(CONFIG.IS_MULTIPLE_COMPANY_INDUSTRY_ENABLED);
  const isEditable = !companyIndustry;

  if (isEditable && isMultipleChoice) {
    return <Multiselect />;
  }
  if (isEditable) {
    return <Select />;
  }

  return <Static />;
};

export default ProfileIndustry;
