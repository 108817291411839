import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  salutation,
  firstName,
  lastName,
  birthDate,
  phoneNumber,
  email,
  soleSignatureAuthorized,
  taxIdentificationNumber,
} = dzbFields.personalDataPage.legalRepresentativesSection.legalRepresentatives;

const { fields: fieldTranslations } =
  translationObject.pages.companiesDetails.associatedPerson.new.legalRepresentatives;

export const legalRepresentativesSectionLabelsMap: LabelsMap = {
  [salutation]: fieldTranslations.salutation.caption,
  [firstName]: fieldTranslations.firstName.caption,
  [lastName]: fieldTranslations.lastName.caption,
  [birthDate]: fieldTranslations.birthDate.caption,
  [email]: fieldTranslations.email.caption,
  [phoneNumber]: fieldTranslations.phoneNumber.caption,
  [soleSignatureAuthorized]: fieldTranslations.soleSignatureAuthorized.inquiryCaption,
  [taxIdentificationNumber]: fieldTranslations.taxIdentificationNumber.caption,
};
