import React from 'react';

import { InputWithField } from 'components/Input';
import { INVESTMENT_LOAN_TERM_IN_MONTHS } from 'modules/Inquiry/Form/formFields';

const InvestmentLoanTermInMonths = () => {
  return <InputWithField name={INVESTMENT_LOAN_TERM_IN_MONTHS} type="number" />;
};

export default InvestmentLoanTermInMonths;
