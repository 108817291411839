import { SET_NEW_INQUIRY_DETAILS_ACTION } from './actions';

type NewInquiryDetailsType = {
  details: any;
};

const initialState: NewInquiryDetailsType = {
  details: undefined,
};

export const newInquiryDetailsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_NEW_INQUIRY_DETAILS_ACTION:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};
