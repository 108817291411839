import { requireLastAnnualFinancialStatementReportingDate } from 'dzb/inquiry/config/conditions';
import { dzbFields, DzbFieldTypes } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateCompanyInformationSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.dzb>({
    validationMap: companyInformationSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  companyName,
  address,
  zipCode,
  city,
  country,
  salesTaxID,
  typeOfFinancialStatements,
  lastAnnualFinancialStatementReportingDate,
  companyOlderThan3Years,
} = dzbFields.companyDataPage.companyInformationSection;

export const companyInformationSectionValidationMap = {
  [companyName]: fieldValidators.string().required(),
  [address]: fieldValidators.string().required(),
  [zipCode]: fieldValidators.string().required(),
  [city]: fieldValidators.string().required(),
  [country]: fieldValidators.string().required(),
  [salesTaxID]: fieldValidators.string().optional(),
  [typeOfFinancialStatements]: fieldValidators.string().required(),
  [lastAnnualFinancialStatementReportingDate]: (value: any, values: DzbFieldTypes) =>
    requireLastAnnualFinancialStatementReportingDate(values)
      ? fieldValidators.date().required()
      : fieldValidators.date().optional(),
  [companyOlderThan3Years]: fieldValidators.boolean().required(),
};
