import { hausbankFeTobeMapping } from 'hausbank/inquiry/config/feToBeMapping';
import { hausbankStepData } from 'hausbank/inquiry/config/stepData';
import { hausbankStepSequence } from 'hausbank/inquiry/config/stepSequence';
import { hausbankSummaryFields } from 'hausbank/inquiry/config/summaryFields';
import { hausbankFields, HausbankFieldTypes } from 'hausbank/inquiry/fields';
import { PortalConfig } from 'new/portals/state/portalConfigContext/types';

export const hausbankPortalConfig: PortalConfig<HausbankFieldTypes> = {
  stepSequence: hausbankStepSequence,
  stepData: hausbankStepData,
  summaryFields: hausbankSummaryFields,
  feToBeMappingKeys: hausbankFeTobeMapping,
  fields: hausbankFields,
};
