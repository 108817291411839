import {
  LOAN_TYPE,
  LOAN_TERM,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_TYPE__ANNUITY,
  LOAN_DESCRIPTION,
  SECURITIES,
  LOAN_ALREADY_AT_BANK,
  INTERESTED_IN_INSURANCE,
  END_USER_CONDITION_AMOUNT,
  END_USER_CONDITION_INTEREST_RATE,
  BANK_DETAILS_IBAN,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger, toBoolean } = fieldParsers;
export const mapLoanToApi: InquiryFormMapper = (allFields) => {
  const isAnnuity = allFields[LOAN_TYPE] === LOAN_TYPE__ANNUITY;
  const hasFixedInterestRate = toBoolean(allFields[LOAN_FIXED_INTEREST_RATE]);

  const shouldSendPeriodInMonths = (!isAnnuity && hasFixedInterestRate) || isAnnuity;

  return {
    'loan-kind': allFields[LOAN_TYPE],
    'loan-term-in-months': toInteger(allFields[LOAN_TERM]),
    'loan-description': allFields[LOAN_DESCRIPTION],
    'loan-already-on-hand-at-bank': toBoolean(allFields[LOAN_ALREADY_AT_BANK]),

    [INTERESTED_IN_INSURANCE]: allFields[INTERESTED_IN_INSURANCE],
    [END_USER_CONDITION_AMOUNT]: allFields[END_USER_CONDITION_AMOUNT],
    [END_USER_CONDITION_INTEREST_RATE]: allFields[END_USER_CONDITION_INTEREST_RATE],
    iban: allFields[BANK_DETAILS_IBAN],

    collaterals: allFields[SECURITIES],
    ...conditionalObject(!isAnnuity, {
      'loan-fixed-interest-rate': toBoolean(allFields[LOAN_FIXED_INTEREST_RATE]),
    }),

    ...conditionalObject(shouldSendPeriodInMonths, {
      'loan-interest-period-in-months': toInteger(allFields[LOAN_INTEREST_PERIOD_IN_MONTHS]),
    }),
  };
};
