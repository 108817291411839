import React from 'react';

import InformationRow from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRow';
import { mapFieldsToRows } from 'modules/InquiryDetails/DetailedInformation/service';
import { StyledKeyValueSection as Row } from 'modules/InquiryDetails/DetailedInformation/styles';

export interface InformationField {
  key: string;
  label: string;
  value: any;
}

const InformationRows = ({ fields }: { fields: InformationField[] }) => {
  const rows = mapFieldsToRows(fields);

  return (
    <>
      {rows.map((row) => (
        <Row key={row[0].key}>
          <InformationRow row={row} />
        </Row>
      ))}
    </>
  );
};

export default InformationRows;
