import styled from 'styled-components/macro';

import { mqSmall, mqLarge, mqExtraLarge } from 'styles/breakpoints';
import { borderRadius } from 'theme/themeConstants';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0.5rem 0.25rem;
  border-radius: ${borderRadius.default};
  color: ${(props) =>
    props.isCurrentStage ? props.theme.colors.text.primary : props.theme.colors.text.tertiary};
  background-color: ${(props) => props.theme.colors.background.white};

  ${mqSmall`
    margin: 0.75rem 0.5rem;
  `};

  ${mqLarge`
    width: 14rem;
  `};

  ${mqExtraLarge`
    width: 18rem;
    margin: 1.25rem 0.5rem;
    padding: 0.25rem 0.5rem;
  `};
`;

export default Wrapper;
