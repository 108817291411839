import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import HirePurchaseIcon from 'theme/components/Icon/ProductKind/HirePurchaseIcon';

import HirePurchaseAdvancePayment from './fields/HirePurchaseAdvancePayment';
import HirePurchaseTermInMonths from './fields/HirePurchaseTermInMonths';

const HirePurchaseSummary = () => {
  return (
    <>
      <FormRow separator>
        <FinancingAmountSummary icon={<HirePurchaseIcon />} />
      </FormRow>
      <FormRow separator>
        <HirePurchaseAdvancePayment />
        <HirePurchaseTermInMonths />
      </FormRow>
    </>
  );
};

export default HirePurchaseSummary;
