import React from 'react';

import { Box } from '@chakra-ui/react';

type ProgressProps = {
  completedValue: number | undefined;
};

const Progress = (props: ProgressProps) => {
  const { completedValue } = props;
  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      left="0"
      maxHeight="100%"
      backgroundColor="brand.default"
      height={`${completedValue}%`}
    />
  );
};

export default Progress;
