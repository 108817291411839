import styled from 'styled-components/macro';

import { mqLarge } from 'styles/breakpoints';

type SectionType = {
  hideBottomBorder?: boolean;
  hideTopBorder?: boolean;
};

const Section = styled.section<SectionType>`
  border-bottom: 1px solid ${(props) => props.theme.colors.border.lightGrey};
  padding: 3.5rem 0;
  ${(props) => props?.hideBottomBorder && 'border-bottom: none; padding-bottom: 0;'};
  ${(props) => props?.hideTopBorder && 'border-top: none; padding-top: 0;'};

  ${mqLarge`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
  `};
`;

export default Section;
