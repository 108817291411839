import {
  USER_PROFILE_LEGAL_FORM__FREIBERUFLICH,
  USER_PROFILE_LEGAL_FORM__GEWERBLICH,
  USER_PROFILE_LEGAL_FORM__GMBH,
  USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENE_GENOSSENSCHAFT,
  USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE,
  USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT_AUF_AKTIEN,
  USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE_AKTIENGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__GMBH_IN_RUNDUNG,
  USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE,
  USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__PARTNERGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN,
  USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_STIFTUNG_DES_PRIVATEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__KORPERSCHAFT_DES_OFFENTLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__ANSTALT_DES_OFFENTLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN,
  USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER,
  USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
  USER_PROFILE_LEGAL_FORM__OTHER,
  USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE,
  USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT,
} from 'modules/Inquiry/Form/formFields';

const defaultLegalFormValues = [
  {
    value: USER_PROFILE_LEGAL_FORM__GEWERBLICH,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gewerblich',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GMBH,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gmbh',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.aktiengesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__EINGETRAGENE_GENOSSENSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.eingetrageneGenossenschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.unternehmergesellschaftHaftungsbeschrankte',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT_AUF_AKTIEN,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.kommanditgesellschaftAufAktien',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE_AKTIENGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gemeinnutzigeAktiengesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GMBH_IN_RUNDUNG,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gmbhInRundung',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.haftungsbeschrankte',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.kommanditgesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.offeneHandelsgesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gesellschaftBurgerlichenRechts',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__PARTNERGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.partnergesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.eingetragenerVerein',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_STIFTUNG_DES_PRIVATEN_RECHTS,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gesellschaftStiftungDesPrivatenRechts',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__KORPERSCHAFT_DES_OFFENTLICHEN_RECHTS,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.korperschaftDesOffentlichenRechts',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__ANSTALT_DES_OFFENTLICHEN_RECHTS,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.anstaltDesOffentlichenRechts',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__FREIBERUFLICH,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.freiberuflich',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.eingetragenerKaufmann',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.naturlichePerson',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.einzelunternehmer',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gemeinnutzige',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.stiftungDesOffentlichenRechts',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.ordensgemeinschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__OTHER,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.other',
  },
];

const getLegalFormOptions = (formatMessage: {
  ({ id }: { id: any }): any;
  ({ id }: { id: any }): any;
  (arg0: { id: string }): any;
}) => {
  return defaultLegalFormValues.map(({ value, id }) => ({ value, label: formatMessage({ id }) }));
};

export default getLegalFormOptions;
