import React, { forwardRef } from 'react';

import { ListItem } from '@chakra-ui/react';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';

interface SelectItemProps {
  itemIndex: number;
  highlightedIndex: number;
  greyBackground?: string;
  isItemBold?: boolean;
}

export const SelectItem = forwardRef<HTMLLIElement, SelectItemProps>(
  ({ itemIndex, highlightedIndex, isItemBold, ...props }: SelectItemProps, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        bg={isActive ? 'brand.default' : props.greyBackground ? 'background.lightGrey' : ''}
        color={!isActive ? 'text.primary' : props.greyBackground ? 'text.secondary' : 'text.white'}
        px={4}
        cursor="pointer"
        ref={ref}
        width="100%"
        fontWeight={isItemBold ? FONT_WEIGHT_BOLD : ''}
        display="flex"
        height="calc(4rem + 4px)"
        alignItems="center"
        {...props}
      />
    );
  },
);
