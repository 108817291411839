import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import BfsVehicleIcon from './BfsVehicleIcon';

const VehicleIcon = (props: IconProps) => {
  return (
    <Icon
      width="50"
      height="48"
      viewBox="0 0 49.85 47.39"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M43.61 38.24a2.95 2.95 0 103 2.95 3 3 0 00-3-2.95zm0 4.88a1.94 1.94 0 111.93-1.93 1.94 1.94 0 01-1.93 1.93zm-11.38-4.88a2.95 2.95 0 102.94 2.95 2.95 2.95 0 00-2.94-2.95zm0 4.88a1.94 1.94 0 111.93-1.93 1.94 1.94 0 01-1.93 1.93zm-20.34-4.88a2.95 2.95 0 102.95 2.95 2.95 2.95 0 00-2.95-2.95zm0 4.88a1.94 1.94 0 111.94-1.93 1.94 1.94 0 01-1.94 1.93z" />
      <path d="M49.81 25.73a2.13 2.13 0 00-2.13-2.13h-3.25a2.14 2.14 0 00-2.14 2.13v1.12h-7.76l1.25-.58a25.79 25.79 0 007.38-5.06 25.73 25.73 0 006.65-12.47v-.22L43 0h-.26a29.72 29.72 0 00-14.06 5A17.12 17.12 0 0022 15.91v.21l1.4 1.79H8.83a4 4 0 00-3.95 3.95V26H3.76A3.77 3.77 0 000 29.8v9.76a2.13 2.13 0 002.13 2.13h3.58v.27A6.19 6.19 0 0018 42v-.27h8V42a6.19 6.19 0 0011.57 2.22l.27-.48.27.48a6.19 6.19 0 0011.62-3zM34.92 2.86a30.58 30.58 0 017.48-1.79h.17l6.19 7.74v.14a26.12 26.12 0 01-3.17 7.59l-.23.37L34.53 3zM23 15.83v-.13a16.41 16.41 0 015.62-9.41l.23-.19L39 18.7l.79-.64-10-12.55.21-.18a24.57 24.57 0 013.34-1.83l.21-.09 11.18 14.37-.13.22a21.43 21.43 0 01-2.16 2.53l-.44.37-1.08-1.35-.79.64 1.11 1.39-.23.19a27.73 27.73 0 01-9.23 4.95l-.2.06zm7.37 11h-6.58v-8.35zM1 29.8a2.74 2.74 0 012.74-2.74H5.9v-5.2a2.94 2.94 0 012.93-2.94h13.94v17.69h-2.23V21.16H10.27a2.14 2.14 0 00-2.14 2.13v13l-.13.06-.18.16-.09.07H1zm18.5 1.32v2.24h-1.1a2.13 2.13 0 00-2.13 2.13v1.26l-.5-.4a6.21 6.21 0 00-6.2-.91l-.42.17v-8.55h10.37v3.05h-3.25v1zm0 3.26v2.23h-2.22v-1.12a1.12 1.12 0 011.12-1.11zM9.15 26v-2.71a1.12 1.12 0 011.12-1.11h9.25V26zm-3.4 14.41v.27H2.13A1.12 1.12 0 011 39.56v-1.93h5.8l-.27.46a6 6 0 00-.78 2.32zm6.14 6a5.19 5.19 0 115.19-5.18 5.19 5.19 0 01-5.19 5.14zm14.19-6v.27h-8v-.27a6.19 6.19 0 00-.78-2.32l-.3-.46h10.13l-.27.46a6.13 6.13 0 00-.78 2.32zm6.15 6a5.19 5.19 0 115.18-5.18 5.19 5.19 0 01-5.18 5.14zm11.38 0a5.19 5.19 0 115.19-5.18 5.18 5.18 0 01-5.19 5.14zm5.19-8.66l-.54-.61a6.19 6.19 0 00-10.07 1.09l-.27.48-.27-.48a6.18 6.18 0 00-9.51-1.65l-.09.07h-4.26v-8.78h7.37l.06.06.22-.05h11.87v-2.15a1.12 1.12 0 011.12-1.11h3.25a1.12 1.12 0 011.12 1.11z" />
    </Icon>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BfsVehicleIcon,
  default: VehicleIcon,
});
