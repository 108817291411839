import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const resetPasswordAction = (password: string, confirmPassword: string, token: string) =>
  callReverseApi({
    url: endpoints.USERS.PASSWORD_RESET.compose(),
    method: API_METHODS.PATCH,
    data: {
      data: {
        password,
        password_confirmation: confirmPassword,
        reset_password_token: token,
      },
    },
  });

export const validateResetPasswordTokenAction = (token: string) =>
  callReverseApi({
    url: endpoints.USERS.PASSWORD_RESET_TOKEN_VALIDATE.compose(),
    method: API_METHODS.POST,
    data: {
      data: {
        token,
        operation: 'reset_password',
      },
    },
  });
