import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

/* eslint-disable camelcase */
export interface IOrganizationlUnit {
  id: string;
  type: 'portal_users';
  attributes: {
    name: string;
    display_name: string;
  };
}
/* eslint-enable camelcase */

export const loadAvailableOrganizationUnitsAction = (
  partnerId: string,
  mustHaveBankAdvisor: boolean,
  mustHaveInquiryManager: boolean,
) =>
  callReverseApi({
    url: endpoints.PARTNERS.UNITS.compose({
      params: { id: partnerId },
      query: {
        filter: {
          must_have_bank_advisors: mustHaveBankAdvisor,
          must_have_inquiry_managers: mustHaveInquiryManager,
        },
      },
    }),
    method: API_METHODS.GET,
  });
