import React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField, INPUT_TYPE_DATE, INPUT_TYPE_DECICMAL_WITH_ZERO } from 'components/Input';
import InternationalZipCodeWithField from 'components/InternationalZipCodeWithField';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  SELF_BENEFICIARY_OWNER,
} from 'modules/Inquiry/Form/formFields';
import {
  StyledSinglePerson,
  StyledSinglePersonFields,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import CalendarIcon from 'theme/components/Icon/CalendarIcon';

import BeneficiaryTaxId from '../../fields/BeneficiaryTaxId';
import CountriesComponent from '../../fields/CountriesComponent/CountriesComponent';

export type FieldValue = {
  [BENEFICIARY_GENDER]: string;
  [BENEFICIARY_FIRST_NAME]: string;
  [BENEFICIARY_LAST_NAME]: string;
  [BENEFICIARY_COMPANY_SHARE]: string;
  [BENEFICIARY_TAX_ID]?: string;
  [BENEFICIARY_PRIVATE_ADDRESS]: string;
  [BENEFICIARY_ZIP_CODE]: string;
  [BENEFICIARY_PRIVATE_CITY]: string;
  [BENEFICIARY_PRIVATE_COUNTRY]: string;
  [BENEFICIARY_BIRTH_COUNTRY]: string;
  [BENEFICIARY_BIRTHDAY]: string;
  id: string;
  isAssociatedPerson: boolean;
};

type Fields = Exclude<keyof FieldValue, 'id' | 'isAssociatedPerson'>;

type SinglePersonProps = {
  fieldName: string;
  shouldBeDisabled: boolean;
  visibleFields?: Fields[];
};

const SelfBeneficiaryOwnerFields = ({
  visibleFields = [
    BENEFICIARY_GENDER,
    BENEFICIARY_FIRST_NAME,
    BENEFICIARY_LAST_NAME,
    BENEFICIARY_COMPANY_SHARE,
    BENEFICIARY_TAX_ID,
    BENEFICIARY_PRIVATE_ADDRESS,
    BENEFICIARY_ZIP_CODE,
    BENEFICIARY_PRIVATE_CITY,
    BENEFICIARY_PRIVATE_COUNTRY,
    BENEFICIARY_BIRTH_COUNTRY,
    BENEFICIARY_BIRTHDAY,
  ],
  fieldName,
  shouldBeDisabled,
}: SinglePersonProps) => {
  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        <FormRow>
          {visibleFields.includes(BENEFICIARY_COMPANY_SHARE) ? (
            <InputWithField
              name={`${fieldName}.${BENEFICIARY_COMPANY_SHARE}`}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
            />
          ) : null}
        </FormRow>
        {visibleFields.includes(BENEFICIARY_TAX_ID) ? (
          <BeneficiaryTaxId name={`${fieldName}.${BENEFICIARY_TAX_ID}`} />
        ) : null}
        <FormRow>
          {visibleFields.includes(BENEFICIARY_PRIVATE_ADDRESS) ? (
            <InputWithField name={`${fieldName}.${BENEFICIARY_PRIVATE_ADDRESS}`} />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(BENEFICIARY_ZIP_CODE) ? (
            <InternationalZipCodeWithField name={`${fieldName}.${BENEFICIARY_ZIP_CODE}`} />
          ) : null}

          {visibleFields.includes(BENEFICIARY_PRIVATE_CITY) ? (
            <InputWithField name={`${fieldName}.${BENEFICIARY_PRIVATE_CITY}`} />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(BENEFICIARY_PRIVATE_COUNTRY) ? (
            <CountriesComponent name={`${fieldName}.${BENEFICIARY_PRIVATE_COUNTRY}`} />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(BENEFICIARY_BIRTH_COUNTRY) ? (
            <CountriesComponent
              name={`${fieldName}.${BENEFICIARY_BIRTH_COUNTRY}`}
              disabled={shouldBeDisabled}
            />
          ) : null}
          {visibleFields.includes(BENEFICIARY_BIRTHDAY) ? (
            <InputWithField
              name={`${fieldName}.${BENEFICIARY_BIRTHDAY}`}
              sideSymbol={() => <CalendarIcon boxSize={6} />}
              disabled={shouldBeDisabled}
              type={INPUT_TYPE_DATE}
            />
          ) : null}
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

export interface SelfBeneficiaryOwnerProps {
  selfBeneficiary?: IAssociatedPerson;
  isLoading?: boolean;
  visibleFields?: Fields[];
}

const SelfBeneficiaryOwner = ({ selfBeneficiary, visibleFields }: SelfBeneficiaryOwnerProps) => {
  return (
    <SelfBeneficiaryOwnerFields
      visibleFields={visibleFields}
      fieldName={`${SELF_BENEFICIARY_OWNER}[0]`}
      shouldBeDisabled={!!selfBeneficiary}
    />
  );
};

export default React.memo(SelfBeneficiaryOwner);
