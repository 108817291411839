import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { IInquiryListElement } from 'models/InquiryList.model';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';

export const useRedirectOnSingleInquiry = () => {
  const history = useHistory();

  const redirect = useCallback(
    (status: INQUIRY_STATUSES, id: string) => {
      if (status === INQUIRY_STATUSES.INITIATED_EXTERNALLY) {
        history.push(paths.customer.inquiryDetails.edit.root.replace(':id', id));
      } else if (status === INQUIRY_STATUSES.DRAFT) {
        history.push(paths.inquiry.draft.edit.replace(':id', id));
      } else {
        history.push(paths.customer.inquiryDetails.root.replace(':id', id));
      }
    },
    [history],
  );

  const checkAfterInquiriesLoad = useCallback(
    (inquiries: IInquiryListElement[], currentPage: number) => {
      if (inquiries && inquiries.length === 1 && currentPage === 1) {
        const inquiryId = inquiries[0].id;
        redirect(inquiries[0].attributes.status as INQUIRY_STATUSES, inquiryId);
      }
    },
    [redirect],
  );
  return {
    redirect,
    checkAfterInquiriesLoad,
  };
};
