import * as _ from 'lodash';

import { useTranslations } from 'utils/hooks/useTranslations';

const GuarantyTypeValuesMap = (type: string) => {
  const t = useTranslations();
  let translatedGuarantyTypeValue = _.camelCase(type.replace('guaranty-', ''));
  return t(`pages.financingNeed.sections.guaranty.fields.${translatedGuarantyTypeValue}.caption`);
};

export default GuarantyTypeValuesMap;
