import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import PERMISSIONS from 'constants/user/permissions';
import withRolesGuard from 'shared/guard/withRolesGuard';
import usePortalPermissions from 'shared/hooks/usePortalPermissions';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { formatDateDays, formatDateTime } from 'utils/date';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../Section';
import Button from '../Section/Button';

const confirmWelcomeCallAction = (inquiryId) =>
  callReverseApi({
    url: `/inquiries/${inquiryId}/welcome_call`,
    method: API_METHODS.PATCH,
  });

const WelcomeCallContainer = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.welcomeCall');
  const { error, isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const dispatch = useDispatch();
  const { canPerformWelcomeCall } = usePortalPermissions();

  const confirm = () => {
    makeCall(confirmWelcomeCallAction(inquiryDetails.inquiryId), ({ payload }) => {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({ description: t('welcomeCallPerformed') });
    });
  };

  const additionalMessage = inquiryDetails.welcomeCallPerformedDate
    ? t('performedAt', {
        date: `${formatDateDays(inquiryDetails.welcomeCallPerformedDate)} ${formatDateTime(
          inquiryDetails.welcomeCallPerformedDate,
        )}`,
      })
    : null;

  const button = (
    <Button
      data-testid="perform-welcome-call"
      isLoading={isPending}
      onClick={confirm}
      disabled={!canPerformWelcomeCall}
      label={t('buttonLabel')}
    />
  );

  return (
    <Section
      data-testid="welcome-call"
      title={t('title')}
      hasError={Boolean(error)}
      button={button}
      additionalMessage={additionalMessage}
    />
  );
};

export default withRolesGuard(PERMISSIONS.INQUIRY.PERFORM_WELCOME_CALL)(WelcomeCallContainer);
