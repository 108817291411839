import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getAllKpis, getFiscalYearStartsAt } from '../../../../../store/kpi/kpis.selectors';
import { useDatePickerContext } from '../../../../../theme/components/FinancialInformations/context/DatePickerContext';
import { useKpiFilter } from '../../../../../theme/components/FinancialInformations/hooks/useKpiFilter';
import { KpiCard } from '../../../../../theme/components/KpiCard';
import { FallbackComponent } from '../../FallbackComponent';

export const KpiGrid = () => {
  const kpiData = useSelector(getAllKpis);
  const selectedKpis = ['EKQ', 'LIQUI3OW', 'CFOW', 'LEVOW', 'DSCR'];

  const { date } = useDatePickerContext();
  const selectedYear = date.getFullYear();
  const fiscalYearStartsAt = useSelector(getFiscalYearStartsAt);
  const month = new Date(fiscalYearStartsAt).getMonth();
  let selectedMonth = '12';

  if (month !== 0) {
    selectedMonth = month.toString();
  }

  const currentYearData = useKpiFilter({
    information: kpiData,
    selectedMonth,
    selectedYear: selectedYear.toString(),
    selectedKpis: selectedKpis,
  });

  const previousYearData = useKpiFilter({
    information: kpiData,
    selectedMonth,
    selectedYear: (selectedYear - 1).toString(),
    selectedKpis: selectedKpis,
  });

  if (currentYearData && previousYearData) {
    if (currentYearData.length === 0 && previousYearData.length === 0) {
      return <FallbackComponent />;
    }
    const longestDataArray =
      Object.keys(currentYearData).length > Object.keys(previousYearData).length
        ? currentYearData
        : previousYearData;

    return (
      <Grid
        templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
        gap={6}
      >
        {longestDataArray.map((_, index) => {
          const currentYearElement = currentYearData?.find(
            (element) => element.name === longestDataArray?.[index].name,
          );
          const previousYearElement = previousYearData?.find(
            (element) => element.name === longestDataArray[index].name,
          );
          return (
            <GridItem key={index} colSpan={longestDataArray[index].name === 'DSCR' ? 2 : 1}>
              <KpiCard
                data={currentYearElement}
                previousData={previousYearElement}
                description={longestDataArray[index].description}
              />
            </GridItem>
          );
        })}
      </Grid>
    );
  }

  return null;
};
