import { mittweidaFields } from 'mittweida/inquiry/fields';
import {
  MAX_AMOUNT_VALUE,
  MAX_DESCRIPTION_LENGTH,
  MIN_LOT_SIZE,
} from 'modules/Inquiry/inquiry.constants';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { ValidationMapType } from 'new/form/common/types';
import { InquiryConstants } from 'schema/inquirySchema.constants';

const {
  address,
  zipCode,
  city,
  description,
  lotSize,
  year,
  yearOfModernization,
  plannedProjectStart,
  plannedProjectEnd,
  furnishingQuality,
  qualityCondition,
  additionalBathroom,
  balcony,
  lift,
  cellar,
  parkingLotsCount,
  parkingLotPrice,
  parkingLotRentNow,
  parkingLotRentFuture,
  garagesCount,
  garagesPrice,
  garageRentNow,
  garageRentFuture,
  futureObjectType,
  futureUsage: { typeOfUse, usageSpace, plannedSalesPrice, numberOfUnits },
  usageDeviation,
  currentObjectType,
  currentUsage: {
    typeOfUse: currentTypeOfUse,
    usageSpace: currentUsageSpace,
    numberOfUnits: currentNumberOfUnits,
  },
  currentTotalRentResidential,
  currentTotalRentCommercial,
  vacancyResidential,
  vacancyCommercial,
  futureTotalRentResidential,
  futureTotalRentCommercial,
  rentalContracts,
  rentalIntends,
} = mittweidaFields.financingNeedPage.objectInformationSection;

export const objectInformationSectionValidationMap: ValidationMapType = {
  [address]: fieldValidators.string().max(InquiryConstants.MAX_OBJECT_ADDRESS_LENGTH).required(),
  [zipCode]: fieldValidators
    .postalCode()
    .isValid()
    .required()
    .max(InquiryConstants.MAX_POSTAL_LENGTH),
  [city]: fieldValidators.string().required().max(InquiryConstants.MAX_OBJECT_CITY_LENGTH),
  [description]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [lotSize]: fieldValidators.number().required().min(MIN_LOT_SIZE).max(MAX_AMOUNT_VALUE),
  [year]: fieldValidators
    .yearFormat()
    .required()
    .minYear(InquiryConstants.MITTWEIDA_MIN_BUILDING_YEAR)
    .maxYear(InquiryConstants.MAX_BUILDING_YEAR),
  [yearOfModernization]: fieldValidators
    .yearFormat()
    .optional()
    .minYear(InquiryConstants.MIN_BUILDING_YEAR)
    .maxYear(InquiryConstants.MAX_BUILDING_YEAR),
  [plannedProjectStart]: fieldValidators
    .date()
    .optional()
    .min(InquiryConstants.MIN_PLANNED_DATE)
    .max(InquiryConstants.MAX_PLANNED_DATE),
  [plannedProjectEnd]: fieldValidators
    .date()
    .optional()
    .min(InquiryConstants.MIN_PLANNED_DATE)
    .max(InquiryConstants.MAX_PLANNED_DATE),
  [furnishingQuality]: fieldValidators.string().required(),
  [qualityCondition]: fieldValidators.string().required(),
  [additionalBathroom]: fieldValidators.boolean().required(),
  [balcony]: fieldValidators.boolean().required(),
  [lift]: fieldValidators.boolean().required(),
  [cellar]: fieldValidators.boolean().required(),
  [parkingLotsCount]: fieldValidators
    .number()
    .required()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOTS),
  [parkingLotPrice]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [parkingLotRentNow]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [parkingLotRentFuture]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [garagesCount]: fieldValidators.number().required().min(0).max(InquiryConstants.MAX_PARKING_LOTS),
  [garagesPrice]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [garageRentNow]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [garageRentFuture]: fieldValidators
    .price()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_PARKING_LOT_PRICE),
  [futureObjectType]: fieldValidators.string().required(),
  [typeOfUse]: fieldValidators.string().required(),
  [usageSpace]: fieldValidators.number().required().min(0).max(InquiryConstants.MAX_USAGE_SPACE),
  [plannedSalesPrice]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_SALES_PRICE),
  [numberOfUnits]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_NUMBER_OF_UNITS),
  [usageDeviation]: fieldValidators.boolean().required(),
  [currentObjectType]: fieldValidators.string().required(),
  [currentTypeOfUse]: fieldValidators.string().required(),
  [currentUsageSpace]: fieldValidators
    .number()
    .required()
    .min(0)
    .max(InquiryConstants.MAX_USAGE_SPACE),
  [currentNumberOfUnits]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_NUMBER_OF_UNITS),
  [currentTotalRentResidential]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [currentTotalRentCommercial]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [vacancyResidential]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [vacancyCommercial]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [futureTotalRentResidential]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [futureTotalRentCommercial]: fieldValidators
    .number()
    .optional()
    .min(0)
    .max(InquiryConstants.MAX_MITTWEIDA_RENT),
  [rentalContracts]: fieldValidators.boolean().required(),
  [rentalIntends]: fieldValidators.boolean().required(),
};
