import React from 'react';

import { Box, Flex, useTheme } from '@chakra-ui/react';
import { rgba } from 'polished';
import { Transition } from 'react-transition-group';

import { transition, zIndex } from 'theme/themeConstants';

interface OverlayProps {
  overlayComponent: React.ReactNode;
  children: React.ReactNode;
  show?: boolean;
  dimmed?: boolean;
}

const Overlay = ({ overlayComponent, children, show = false, dimmed = false }: OverlayProps) => {
  const theme = useTheme();

  return (
    <Box position="relative">
      <Transition in={show} timeout={0} unmountOnExit>
        {(transitionState: string) => (
          <Flex
            opacity={transitionState === 'entered' ? 1 : 0}
            alignItems="center"
            justifyContent="center"
            position="absolute"
            zIndex={zIndex.above}
            width="100%"
            height="100%"
            backgroundColor={dimmed ? rgba(theme.colors.background.white, 0.7) : 'transparent'}
            transition={`opacity ${transition.default}`}
          >
            {overlayComponent}
          </Flex>
        )}
      </Transition>
      {children}
    </Box>
  );
};

export default Overlay;
