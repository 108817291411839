import React from 'react';

import FormSection from 'components/FormSection';
import { PURPOSE_KIND_BFS_SERVICE } from 'modules/Inquiry/Form/formFields';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import PurposeKindDropDown from './PurposeKindDropDown';

const BfsServicePurposeSection = () => {
  const t = useTranslations('pages.financingNeed.sections.purpose');
  return (
    <FormSection title={t('title')} name={FormSections.purposeKind} hideBottomBorder>
      <PurposeKindDropDown name={PURPOSE_KIND_BFS_SERVICE} />
    </FormSection>
  );
};

export default BfsServicePurposeSection;
