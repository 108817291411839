import React, { FormEvent, useEffect, useState } from 'react';

import { DrawerBody } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';

import { DayPickerWithField } from 'components/DatePicker';
import FormRowBase from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { IInquiryListFilterByOptions } from 'models/InquiryList.model';
import { PurposeKind } from 'models/PurposeKind.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import InquiryTypeSpecificFilters from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/InquiryTypeSpecificFilters';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import AmountFilters from './AmountFilters';
import CompanyFilter from './CompanyFilter';
import { StyledFormRow, StyledHalfRow } from './styles';
import { usePurposeProducts } from './usePurposeProducts';

type Props = {
  values: IInquiryListFilterByOptions;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

export const FiltersDrawerBody = ({ handleSubmit, values }: Props) => {
  const t = useTranslations();
  const [formValues, setFormValues] = useState(values);
  const { optional } = useFieldValidators();
  const { formTypes, purposes, products, setSelectedPurpose, setSelectedFormType } =
    usePurposeProducts();

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const setPurpose = (purpose: PurposeKind) => setSelectedPurpose(purpose);

  const setFormtype = (formType: InquiryType) => setSelectedFormType(formType);

  const showAmountFilters = useSelectedInquiryTypeSpecificValue({
    [InquiryType.dzb]: false,
    [InquiryType.hausbank]: false,
    [InquiryType.default]: true,
  });

  const showPurposeSelect =
    useSelectedInquiryTypeSpecificValue({
      [InquiryType.hausbank]: false,
      [InquiryType.default]: true,
    }) && purposes.length > 1;

  const showProductKindSelect =
    useSelectedInquiryTypeSpecificValue({
      [InquiryType.bfsService]: false,
      [InquiryType.default]: true,
    }) && products.length > 1;

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit}>
        <StyledFormRow>
          <InputWithField
            name={INQUIRY_FILTERS.ID}
            validate={optional}
            caption={t('pages.inquiryList.filtersDrawer.fields.id.caption')}
            isClearable
          />
        </StyledFormRow>

        <FormRowBase>
          <StyledHalfRow>
            <DayPickerWithField
              maxDate={
                _isEmpty(formValues.createdAtTo)
                  ? new Date()
                  : new Date(formValues.createdAtTo as string)
              }
              name={INQUIRY_FILTERS.CREATED_AT_FROM}
              caption={t('pages.inquiryList.filtersDrawer.fields.createdAtFrom.caption')}
              isClearable
            />
          </StyledHalfRow>

          <StyledHalfRow>
            <DayPickerWithField
              name={INQUIRY_FILTERS.CREATED_AT_TO}
              maxDate={new Date()}
              minDate={new Date(formValues.createdAtFrom as string)}
              caption={t('pages.inquiryList.filtersDrawer.fields.createdAtTo.caption')}
              isClearable
            />
          </StyledHalfRow>
        </FormRowBase>

        <CompanyFilter />

        {formTypes.length > 1 && (
          <StyledFormRow>
            <SelectWithField
              name={INQUIRY_FILTERS.INQUIRY_TYPE}
              validate={optional}
              caption={t('pages.inquiryList.filtersDrawer.fields.inquiryType.caption')}
              placeholder={t('placeholders.pleaseChoose')}
              options={formTypes}
              isClearable
              onChange={setFormtype}
              data-testid={'select-field-' + INQUIRY_FILTERS.INQUIRY_MANAGER}
            />
          </StyledFormRow>
        )}

        {showPurposeSelect && (
          <StyledFormRow>
            <SelectWithField
              name={INQUIRY_FILTERS.PURPOSE_KIND}
              validate={optional}
              caption={t('pages.inquiryList.filtersDrawer.fields.purposeKind.caption')}
              placeholder={t('placeholders.pleaseChoose')}
              options={purposes}
              isClearable
              onChange={setPurpose}
              data-testid="All-Filters-Labels"
            />
          </StyledFormRow>
        )}
        {showProductKindSelect && (
          <StyledFormRow>
            <SelectWithField
              name={INQUIRY_FILTERS.PRODUCT_KIND}
              validate={optional}
              caption={t('pages.inquiryList.filtersDrawer.fields.productKind.caption')}
              placeholder={t('placeholders.pleaseChoose')}
              options={products}
              isClearable
              data-testid={'select-field-' + INQUIRY_FILTERS.PURPOSE_KIND}
            />
          </StyledFormRow>
        )}

        {showAmountFilters && <AmountFilters />}

        <InquiryTypeSpecificFilters />
      </form>
    </DrawerBody>
  );
};
