import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProjectFinancingInterestedInAlternativeFinancing = () => {
  const t = useTranslations(
    'pages.requestDetails.sections.projectFinancing.fields.projectFinancingInterestedInAlternativeFinancing',
  );

  return (
    <YesNoRadioGroupWithField
      name={PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING}
      tooltip={t('tooltip')}
    />
  );
};

export default ProjectFinancingInterestedInAlternativeFinancing;
