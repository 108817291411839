import { useCallback } from 'react';

import { CompeonReverseClientInstance } from 'api';
import { API_HEADERS } from 'api/apiService';
import useUploadProgress from 'components/UploadBlock/hooks/useUploadProgress';

const api = CompeonReverseClientInstance;

const uploadHeaders = () => ({
  ...API_HEADERS.contentType.multiPart,
});

const objToFormData = (obj: any) => {
  const formData = new FormData();
  if (obj) {
    Object.keys(obj).forEach((singleParam) => formData.append(singleParam, obj[singleParam]));
  }
  return formData;
};

const createFilePayload = (file: File, fileKey: string, meta: any) => {
  const payload = objToFormData(meta);
  payload.append(fileKey, file);
  return payload;
};

const createArrayFilePayload = (files: File[], fileKey: string, meta: any) => {
  const payload = objToFormData(meta);
  files.forEach((file, index) => {
    // Append attribute to form data as a file array
    payload.append(`${fileKey}[][file]`, file);
  });
  return payload;
};

export const useNewUploadFile = (url: string) => {
  const { uploadProgress, handleUploadProgressChange } = useUploadProgress();

  // callback for uploading multiple contracts
  const startContractUpload = useCallback(
    async (contracts: File[], additionalContent: any): Promise<any[]> => {
      const body = createArrayFilePayload(contracts, 'contracts', additionalContent);
      const headers = uploadHeaders();
      return api
        .post(url, body, { headers, onUploadProgress: handleUploadProgressChange })
        .then((response) => response.data.data);
    },
    [handleUploadProgressChange, url],
  );

  // callback for uploading a single file
  const startUpload = useCallback(
    async (file: File, additionalContent: any) => {
      const body = createFilePayload(file, 'file', additionalContent);
      const headers = uploadHeaders();
      return api
        .post(url, body, { headers, onUploadProgress: handleUploadProgressChange })
        .then((response) => response.data.data);
    },
    [handleUploadProgressChange, url],
  );

  return { startUpload, startContractUpload, uploadProgress: +uploadProgress };
};
