import styled from 'styled-components/macro';

export const StyledWrapper = styled.div`
  padding-bottom: 0.125rem;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled.div`
  margin-right: 2rem;

  svg {
    display: block;
    width: auto;
    height: 60px;

    [stroke] {
      fill: none;
    }
  }
`;

export const StyledAmount = styled.div`
  font-size: 1.75rem;
`;
