import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import StaticField, { StaticFieldWithField } from 'components/StaticField';
import {
  END_USER_CONDITION_AMOUNT,
  END_USER_CONDITION_INTEREST_RATE,
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getIndicativeOfferCalculations } from 'store/inquiryDetails/selectors';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import ConditionDescription from './ConditionDescription';
import { CardHeader, ConditionsWrapper, Rate, Unit, ValueBox } from './styles';

// FIXME! This is a workaround will break very soon, as the component's styles will be updated!
const IconPopoverStyleWrapper = styled.div`
  /* stylelint-disable declaration-no-important */
  .css-1bjdup8 {
    background-color: ${({ theme }) => theme.colors.background.blackGrey} !important;
    padding: 1px !important;
  }
  /* stylelint-enable */
`;

export const ShowCondition = () => {
  const t = useTranslations();
  const nonBindingOfferT = translations.components.indicativeConditions.nonBindingOffer;
  const processLane = useSelector(getProcessLane);
  const isOffer = processLane === 'offer';
  const calculations = useSelector(getIndicativeOfferCalculations);

  const texts = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: {
      factoringFeeTooltip: t(nonBindingOfferT.factoringFee.bfssTooltip),
      factoringLineTooltip: t(nonBindingOfferT.factoringLine.bfssTooltip),
      payoutRateTooltip: t(nonBindingOfferT.payoutRate.bfssTooltip),
      payoutRateText: t(nonBindingOfferT.payoutRate.bfssText, {
        payoutRate: calculations?.payoutRate,
      }),
      factoringLineCaption: t(
        translations.pages.inquiryDetails.dashboard.actions.nonBindingOffer.financingLimit,
      ),
    },
    [InquiryType.default]: {
      factoringFeeTooltip: t(nonBindingOfferT.factoringFee.tooltip),
      factoringLineTooltip: t(nonBindingOfferT.factoringLine.tooltip),
      payoutRateTooltip: t(nonBindingOfferT.payoutRate.tooltip),
      payoutRateText: calculations?.payoutRate,
      factoringLineCaption: t(
        translations.pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringLine,
      ),
    },
  });

  return (
    <>
      <IconPopoverStyleWrapper>
        <CardHeader>
          {isOffer
            ? t(translations.components.smsVerification.header3Indicative)
            : t(translations.components.smsVerification.header3)}
        </CardHeader>
        {!calculations && (
          <MarkdownWrapper text={t(translations.components.smsVerification.info3)} />
        )}
        <ConditionsWrapper>
          {calculations ? (
            <>
              <ValueBox>
                <Rate data-testid={OFFER_FACTORING_FEE}>
                  <PopoverComponent
                    ml={1}
                    transform="translateY(-1px)"
                    text={texts.factoringFeeTooltip}
                    trigger={
                      <IconButtonComponent
                        icon={<InfoIcon boxSize={5} display="block" />}
                        label={t(translations.buttons.moreInfos)}
                      />
                    }
                  />
                  <StaticField
                    caption={t(
                      translations.pages.inquiryDetails.dashboard.actions.nonBindingOffer
                        .factoringFee,
                    )}
                    text={calculations.factoringFee}
                  />
                </Rate>
              </ValueBox>
              <ValueBox>
                <Rate data-testid={OFFER_FACTORING_LINE}>
                  <PopoverComponent
                    ml={1}
                    transform="translateY(-1px)"
                    text={texts.factoringLineTooltip}
                    trigger={
                      <IconButtonComponent
                        icon={<InfoIcon boxSize={5} display="block" />}
                        label={t(translations.buttons.moreInfos)}
                      />
                    }
                  />
                  <StaticField
                    caption={texts.factoringLineCaption}
                    text={calculations.factoringLine}
                  />
                </Rate>
              </ValueBox>
              <ValueBox>
                <PopoverComponent
                  ml={1}
                  transform="translateY(-1px)"
                  text={texts.payoutRateTooltip}
                  trigger={
                    <IconButtonComponent
                      icon={<InfoIcon boxSize={5} display="block" />}
                      label={t(translations.buttons.moreInfos)}
                    />
                  }
                />
                <Rate data-testid={OFFER_PAYOUT_RATIO}>
                  <StaticField
                    caption={t(
                      translations.pages.inquiryDetails.dashboard.actions.nonBindingOffer
                        .payoutRate,
                    )}
                    text={texts.payoutRateText}
                  />
                </Rate>
              </ValueBox>
            </>
          ) : (
            <>
              <ValueBox>
                <Rate>
                  <StaticFieldWithField
                    caption={t(translations.components.smsVerification.amountUnitDesc)}
                    name={END_USER_CONDITION_AMOUNT}
                    parseValue={(value: number) => formatPrice(value, false)}
                    sideSymbol={() => (
                      <Unit>{t(translations.components.smsVerification.amountUnit)}</Unit>
                    )}
                  />
                </Rate>
              </ValueBox>
              <ValueBox>
                <Rate>
                  <StaticFieldWithField
                    caption={t(translations.components.smsVerification.rateUnitDesc)}
                    name={END_USER_CONDITION_INTEREST_RATE}
                    parseValue={(value: number) => formatPrice(value, false)}
                    sideSymbol={() => (
                      <Unit>{t(translations.components.smsVerification.rateUnit)}</Unit>
                    )}
                  />
                </Rate>
              </ValueBox>
            </>
          )}
        </ConditionsWrapper>
        <ConditionDescription />
      </IconPopoverStyleWrapper>
    </>
  );
};
