import _isEmpty from 'lodash/isEmpty';

import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { BeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { mapBeneficialOwnerToBackendResponse } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/mappers';
import { DzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { mapDzbLegalRepresentativeToBackendResponse } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/mappers';
import { StringBoolean } from 'types/General';
import { parseStringBoolean } from 'utils/helpers';

export function mapDzbPersonalData(values: DzbFieldTypes) {
  const {
    personalDataPage: {
      contactPersonSection,
      legalRepresentativesSection,
      beneficialOwnersSection,
    },
  } = values;

  return {
    beneficiary_ownership: beneficialOwnersSection.allInformationAvailable,
    user_attributes: {
      first_name: contactPersonSection.firstName,
      last_name: contactPersonSection.lastName,
      email: contactPersonSection.email,
      phone_number: contactPersonSection.phoneNumber || null,
    },
    ...mapLegalRepresentatives(legalRepresentativesSection.legalRepresentatives),
    ...mapBeneficialOwners(
      beneficialOwnersSection.beneficialOwners,
      beneficialOwnersSection.allInformationAvailable,
    ),
  };
}

export function mapLegalRepresentatives(legalRepresentatives: Array<DzbLegalRepresentative>) {
  return {
    legal_representatives: legalRepresentatives.map((legalRepresentative) => {
      return mapDzbLegalRepresentativeToBackendResponse(legalRepresentative);
    }),
  };
}

export function mapBeneficialOwners(
  beneficialOwners: Array<BeneficialOwner>,
  allInformationAvailable: StringBoolean | undefined,
) {
  const isAllInformationAvailable = parseStringBoolean(allInformationAvailable);
  if (!_isEmpty(beneficialOwners?.[0]) && isAllInformationAvailable)
    return {
      beneficiary_owners: beneficialOwners.map((beneficialOwner) => {
        return mapBeneficialOwnerToBackendResponse(beneficialOwner);
      }),
    };
  return { beneficiary_owners: [] };
}
