import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import { getFieldValue } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_LEGAL_FORM,
} from 'modules/Inquiry/Form/formFields';
import { getValidatorCompanyDetailsSection } from 'modules/Inquiry/Form/Validations/default/UserDetails/completingCompanyDetails.validator';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateCompanySelection } from 'pages/inquiryFlow/CompanyDetails/sections/Company';
import { validateConsentSection } from 'pages/inquiryFlow/CompanyDetails/sections/TermsAndConditions';
import { getSelectedCompany } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { getIsKfinanzSelected } from 'store/progress/selectors';
import { isLoggedInUser } from 'utils/user/conditionals';

export const useCompanyDetailsSectionValidators = () => {
  const isLoggedIn = isLoggedInUser();
  const { selectedInquiryType } = useFormConfig();
  const isKfinanzPurpose = useSelector(getIsKfinanzSelected);
  const {
    meta: { excludingQuestionCount },
  } = usePartnerConfig();

  return (form: any) => {
    const company = getSelectedCompany(
      getFieldValue(COMPANY_DETAILS_COMPANY, form),
      getFieldValue(COMPANY_DETAILS_EXISTING_USER_COMPANY, form),
    );

    let excludedFields: Array<{}> = [];
    if (isKfinanzPurpose) {
      excludedFields = [USER_PROFILE_TURNOVER_CLASS, USER_PROFILE_INDUSTRY];
    } else if (selectedInquiryType === InquiryType.bfsService) {
      excludedFields = [
        USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
        USER_PROFILE_TURNOVER_CLASS,
        USER_PROFILE_INDUSTRY,
        USER_PROFILE_FOUNDING_YEAR,
        USER_PROFILE_LEGAL_FORM,
      ];
    }

    const bfssValidateWithConsentSection = [
      validateCompanySelection(isLoggedIn),
      validateConsentSection(selectedInquiryType),
    ];

    const validateWithConsentSection = [
      ...bfssValidateWithConsentSection,
      getValidatorCompanyDetailsSection(
        company,
        selectedInquiryType,
        excludingQuestionCount,
        excludedFields,
      ),
    ];

    const inquiryTypeValidators: any = {
      [InquiryType.onlinefactoring]: validateWithConsentSection,
      [InquiryType.bfsService]: bfssValidateWithConsentSection,
      default: [validateCompanySelection(isLoggedIn), getValidatorCompanyDetailsSection(company)],
    };

    return inquiryTypeValidators[selectedInquiryType] || inquiryTypeValidators.default;
  };
};
