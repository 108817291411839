export interface ITRSearchResult {
  info: string;
  orderId: number;
}

export enum ITransparencyRegisterStatus {
  DOWNLOAD_COMPLETE = 'download_complete',
  SEARCH_FAILED = 'search_failed',
  ORDER_SUBMISSION_FAILED = 'order_submission_failed',
  MULTIPLE_SEARCH_RESULTS = 'multiple_search_results',
  NO_VALID_SEARCH_RESULT = 'no_valid_search_result',
}

export interface ITransparencyRegister {
  searchResults?: ITRSearchResult[];
  status?: ITransparencyRegisterStatus;
  selectedOrderId?: number;
  inconsistencyFoundAt?: any;
}

export interface ITransparencyRegisterAttributes {
  search_results: ITransparencyRegister['searchResults'];
  status: ITransparencyRegister['status'];
  selected_order_id: ITransparencyRegister['selectedOrderId'];
  inconsistency_found_at: ITransparencyRegister['inconsistencyFoundAt'];
}

export interface ITRSearchResultsAttributes {
  info: ITRSearchResult['info'];
  order_id: ITRSearchResult['orderId'];
}
