import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ArchiveIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M10 13h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2Zm9-10H5a3 3 0 0 0-1 5.82V18a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.82A3 3 0 0 0 19 3Zm-1 15a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9h12Zm1-11H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2Z" />
    </Icon>
  );
};

export default ArchiveIcon;
