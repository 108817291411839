import React, { useMemo } from 'react';

import FormSection from 'components/FormSection';
import { SmallTileListWithField } from 'components/SmallTileList/SmallTileList';
import { useFormConfig } from 'config/formConfig/hooks';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { BUILDING_PROJECT } from 'modules/Inquiry/Form/formFields';
import { buildingSectionOptions } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/buildingOptions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateBuildingProjectSection = validateSectionFields([BUILDING_PROJECT]);

const BuildingProjectSection = () => {
  const t = useTranslations();
  const translatedTiles = useMemo(
    () => buildingSectionOptions[BUILDING_PROJECT].map((o) => ({ ...o, title: t(o.title) })),
    [t],
  );
  const { selectedInquiryType } = useFormConfig();
  const isDefaultTypeInquiry = selectedInquiryType === InquiryType.default;

  return (
    <FormSection
      name={FormSections.buildingProject}
      sectionNumber={isDefaultTypeInquiry ? 1 : 2}
      title={t('pages.financingNeed.sections.buildingProject.title')}
    >
      <SmallTileListWithField name={BUILDING_PROJECT} options={translatedTiles} />
    </FormSection>
  );
};

export default React.memo(BuildingProjectSection);
