import React from 'react';

import { Redirect, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import BankValues from 'mittweida/portals/operations/pages/BankValues';
import PropertyProfile from 'mittweida/portals/operations/pages/PropertyProfile';
import { Appraiser } from 'pages/operationPortal/PlanningEvaluation/Appraiser/Appraiser';
import { EditProfitabilityRealStateMarketData } from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/EditProfitabilityRealStateMarketData';
import MarketComparison from 'pages/operationPortal/PlanningEvaluation/MarketComparison/MarketComparison';
import ProfitabilityCalculation from 'pages/operationPortal/PlanningEvaluation/ProfitabilityCalculation';
import QuickCheck from 'pages/operationPortal/PlanningEvaluation/QuickCheck';
import OperationPrivateRoute from 'routes/OperationPrivateRoute';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';

import { useFetchPlanningEvaluationProfitability } from '../../../../mittweida/portals/operations/hooks/useFetchPlanningEvaluationProfitability';
import FinancingCriteria from '../../../../mittweida/portals/operations/pages/FinancingCriteria';

const {
  profitabilityCalculation,
  profitabilityCalculationEdit,
  profitabilityCalculationMittweidaEdit,
  marketComparison,
  appraiser,
  editMarketValueDetails,
  editBankValueObjectDataDetails,
  editBankValueAdditionalInformationDetails,
  editFinancingCriteriaDetails,
  editMarketValueDetailsRee,
  quickCheck,
  bankValues,
  financingCriteria,
  propertyProfile,
} = paths.operation.inquiryDetails.planningEvaluation;

export const PlanningEvaluationRoutes = () => {
  const isProfitabilityCalculationEnabled = useConfig(CONFIG.IS_PROFITABILITY_CALCULATION_ENABLED);
  const isRealEstateExpertEnabled = useConfig(CONFIG.IS_REAL_ESTATE_EXPERT_ENABLED);
  const isQuickCheckEnabled = useConfig(CONFIG.IS_QUICK_CHECK_ENABLED);
  useFetchPlanningEvaluationProfitability();
  return (
    <Switch>
      <OperationPrivateRoute exact path={marketComparison()} component={MarketComparison} />

      {isQuickCheckEnabled && (
        <OperationPrivateRoute exact path={quickCheck()} component={QuickCheck} />
      )}
      <OperationPrivateRoute exact path={bankValues()} component={BankValues} />
      <OperationPrivateRoute exact path={financingCriteria()} component={FinancingCriteria} />

      {isRealEstateExpertEnabled && (
        <OperationPrivateRoute exact path={appraiser()} component={Appraiser} />
      )}
      <OperationPrivateRoute
        path={editMarketValueDetails()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={editMarketValueDetailsRee()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={editBankValueObjectDataDetails()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={editBankValueAdditionalInformationDetails()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={editFinancingCriteriaDetails()}
        component={EditProfitabilityRealStateMarketData}
      />
      {isProfitabilityCalculationEnabled && (
        <OperationPrivateRoute
          exact
          path={profitabilityCalculation()}
          component={ProfitabilityCalculation}
        />
      )}
      <OperationPrivateRoute path={propertyProfile()} component={PropertyProfile} />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.lendingValues()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.investmentCosts()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.presalesData()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.financingParameters()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.salesCostRate()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.rentalParameters()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationEdit.saleAsUnit()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationMittweidaEdit.financingDetails()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationMittweidaEdit.investmentPlan()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationMittweidaEdit.lendingValue()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationMittweidaEdit.rent()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationMittweidaEdit.sourceOfFunds()}
        component={EditProfitabilityRealStateMarketData}
      />
      <OperationPrivateRoute
        path={profitabilityCalculationMittweidaEdit.profitabilitySales()}
        component={EditProfitabilityRealStateMarketData}
      />
      <Redirect to={marketComparison()} />
    </Switch>
  );
};
