import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import {
  INPUT_TYPE_DECICMAL_WITH_ZERO,
  INPUT_TYPE_DECIMAL,
  InputWithField,
} from 'components/Input';
import {
  useShareOfForeignLogistics,
  useShowOnlineSalesFields,
} from 'dzb/inquiry/config/conditions';
import { dzbFields, DzbSections } from 'dzb/inquiry/fields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ShareOfForeignLogistics } from './components/SahreOfForeignLogistics';
import { ShareOfDistributionChannels } from './components/ShareOfDistributionChannels';
import { ShareOfWarehouse } from './components/ShareOfWarehouse';

const {
  returnRatePercentage,
  ebayPercentage,
  ownOnlineShopPercentage,
  ownWarehousePercentage,
  foreignWarehousePercentage,
  foreignFulfillmentByAmazonPercentage,
  amazonPercentage,
  foreignLogisticsServiceProviderPercentage,
  zalandoPercentage,
  shareOfOnlineSalesPercentage,
  othersPercentage,
  averageShoppingBasketAmount,
} = dzbFields.retailerDataPage.onlineQuestionnaireSection;

export const OnlineQuestionnaireSection = () => {
  const t = useTranslations();
  const {
    title,
    subheadings: { distributionChannels, goodsStorage },
  } = translations.inquiryType.dzb.pages.retailerData.sections.onlineQuestionnaire;
  const showOnlineSalesFields = useShowOnlineSalesFields();
  const showShareOfForeignLogistics = useShareOfForeignLogistics();

  return (
    <Condition<DzbSections>
      validation={'retailerInformationSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={2}
          placeholder={t(translations.inquiryType.dzb.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={2}>
        <FormRow>
          <InputWithField
            name={shareOfOnlineSalesPercentage}
            type={INPUT_TYPE_DECICMAL_WITH_ZERO}
            sideSymbol={() => t(translations.other.percent)}
          />
        </FormRow>
        <Condition validation={showOnlineSalesFields}>
          <FormRow>
            <AmountWithField name={averageShoppingBasketAmount} />
            <InputWithField
              name={returnRatePercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
          </FormRow>
          <TextComponent color={'text.tertiary'} mb={4}>
            {t(distributionChannels)}
          </TextComponent>
          <FormRow>
            <InputWithField
              name={ownOnlineShopPercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
          </FormRow>
          <FormRow>
            <InputWithField
              name={amazonPercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
            <InputWithField
              name={ebayPercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
          </FormRow>
          <FormRow>
            <InputWithField
              name={zalandoPercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
            <InputWithField
              name={othersPercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
          </FormRow>
          <FormRow>
            <ShareOfDistributionChannels />
          </FormRow>
          <TextComponent color={'text.tertiary'} mb={4}>
            {t(goodsStorage)}
          </TextComponent>
          <FormRow>
            <InputWithField
              name={ownWarehousePercentage}
              type={INPUT_TYPE_DECIMAL}
              sideSymbol={() => t(translations.other.percent)}
            />
          </FormRow>
          <FormRow>
            <InputWithField
              name={foreignWarehousePercentage}
              type={INPUT_TYPE_DECICMAL_WITH_ZERO}
              sideSymbol={() => t(translations.other.percent)}
            />
          </FormRow>
          <FormRow>
            <ShareOfWarehouse />
          </FormRow>
          <Condition validation={showShareOfForeignLogistics}>
            <FormRow>
              <InputWithField
                name={foreignLogisticsServiceProviderPercentage}
                type={INPUT_TYPE_DECICMAL_WITH_ZERO}
                sideSymbol={() => t(translations.other.percent)}
              />
              <InputWithField
                name={foreignFulfillmentByAmazonPercentage}
                type={INPUT_TYPE_DECICMAL_WITH_ZERO}
                sideSymbol={() => t(translations.other.percent)}
              />
            </FormRow>
            <FormRow>
              <ShareOfForeignLogistics />
            </FormRow>
          </Condition>
        </Condition>
      </FormSection>
    </Condition>
  );
};
