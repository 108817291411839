import React, { useEffect } from 'react';

import { Form } from 'react-final-form';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import { usePartnerConfig } from 'config/partner/hooks';
import useAvailableOrganizationUnits from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor/useAvailableOrganizationUnitsHook';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const ORGANIZATION_UNIT_FIELD = 'organizationUnit';

interface IModalProps {
  onSubmit: (organisationUnitId: string) => void;
}

interface FormValues {
  [ORGANIZATION_UNIT_FIELD]: string;
}

const ForwardToInquiryManagerModalContent = ({ onSubmit }: IModalProps) => {
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const { availableOrganizationalUnits, fetchAvailableOrganizationUnits } =
    useAvailableOrganizationUnits(partnerId, false, true);
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.forwardToInquiryManager');

  const allInquiryManagerValue = {
    label: t('allInquiryManagerPlaceholder'),
    value: '',
  };

  const formattedOrganizationalUnits = [
    allInquiryManagerValue,
    ...availableOrganizationalUnits.map((ou) => ({
      label: ou.attributes.name,
      value: ou.id,
    })),
  ];

  useEffect(() => {
    fetchAvailableOrganizationUnits();
  }, [fetchAvailableOrganizationUnits]);

  const handleSubmitForm = (formValues: FormValues) => {
    const { [ORGANIZATION_UNIT_FIELD]: organisationUnitId } = formValues;
    onSubmit(organisationUnitId);
  };

  return (
    <>
      <TextComponent mb={8}>{t('modalDescription')}</TextComponent>
      <Form
        onSubmit={handleSubmitForm}
        render={({ handleSubmit }) => (
          <form id="form-forward-to-organization-unit" onSubmit={handleSubmit}>
            <FormRow>
              <SelectWithField
                name={ORGANIZATION_UNIT_FIELD}
                placeholder={t('allInquiryManagerPlaceholder')}
                caption={t('modalCaption')}
                options={formattedOrganizationalUnits}
                disabled={availableOrganizationalUnits.length === 0}
                data-testid={'select-field-' + ORGANIZATION_UNIT_FIELD}
              />
            </FormRow>
          </form>
        )}
      />
    </>
  );
};

export default ForwardToInquiryManagerModalContent;
