import { useSelector } from 'react-redux';

import { useInquiryProcessSpecificUploadedFiles } from 'api/CompeonReverseApi/operation/queryHooks/files';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

export const useProcessSpecificFiles = (kinds?: string[]) => {
  const inquiryId = useSelector(getInquiryIdSelector);

  const { data: files = [] } = useInquiryProcessSpecificUploadedFiles({
    variables: { inquiryId },
  });

  return kinds?.length ? files.filter((file) => kinds.includes(file.kind)) : files;
};
