import React from 'react';

import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateDays, formatDateTime } from 'utils/date';

import {
  StyledWrapper as Wrapper,
  StyledHeader as Header,
  StyledColumn as Column,
  StyledComment as Comment,
} from './styles';

interface Props {
  firstName: string;
  lastName: string;
  date: string;
  comment: string;
  testId?: string;
}

const InternalCommentsItem = ({ firstName, lastName, date, comment, testId }: Props) => {
  return (
    <Wrapper data-testid={testId}>
      <Header>
        <Column>
          <UserIcon boxSize={6} mr={2} />
          {firstName} {lastName}
        </Column>
        <Column>
          <TimeIcon boxSize={6} mr={2} />
          {formatDateDays(date)}; {formatDateTime(date)}
        </Column>
      </Header>
      <Comment>{comment}</Comment>
    </Wrapper>
  );
};

export default InternalCommentsItem;
