import endpoints from 'api/CompeonReverseApi/endpoints';

type Files = {
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
  route: 'DETAILS' | 'DOWNLOAD' | 'CLASSIFICATION';
};

export const useFilesEndpoint = ({ isPrivateFile, isAssessmentFile, route }: Files) => {
  return isPrivateFile
    ? endpoints.PRIVATE_FILES[route]
    : isAssessmentFile
    ? endpoints.ASSESSMENT_FILES[route as 'DETAILS' | 'DOWNLOAD' | 'ARCHIVE']
    : endpoints.FILES[route];
};
