import { compeonOfferUnitFormatters } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/unitFormatters';
import { COMPEON_OFFER_PROPS } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/getPropertiesForProduct';

const { monthsUnits, percent, pricePerMonth, price } = compeonOfferUnitFormatters;

export const compeonHirePurchaseUnits = {
  [COMPEON_OFFER_PROPS.amount]: price,
  [COMPEON_OFFER_PROPS.advancePayment]: price,
  [COMPEON_OFFER_PROPS.residualValue]: percent,
  [COMPEON_OFFER_PROPS.termInMonths]: monthsUnits,
  [COMPEON_OFFER_PROPS.value]: pricePerMonth,
  [COMPEON_OFFER_PROPS.leasingFactor]: percent,
  [COMPEON_OFFER_PROPS.processingFee]: price,
};
