import {
  INDUSTRY__AGRICULTURE,
  INDUSTRY__BUILDING,
  INDUSTRY__CRAFT,
  INDUSTRY__ENERGY_AND_WATER_SUPPLY,
  INDUSTRY__FINANCE_AND_INSURANCE,
  INDUSTRY__FOUNDATION,
  INDUSTRY__FUNDRAISER,
  INDUSTRY__HEALTH,
  INDUSTRY__HEALTH_AND_SOCIAL,
  INDUSTRY__HOSPITALITY,
  INDUSTRY__MAINTENANCE,
  INDUSTRY__MANUFACTURING,
  INDUSTRY__MINING,
  INDUSTRY__OTHER,
  INDUSTRY__PROCESSING,
  INDUSTRY__REAL_ESTATE,
  INDUSTRY__RELIGIOUS,
  INDUSTRY__RESIDE,
  INDUSTRY__SERVICE,
  INDUSTRY__SOCIAL,
  INDUSTRY__TRADE,
  INDUSTRY__TRANSPORT,
  INDUSTRY__UPBRINGING,
  USER_PROFILE_INDUSTRY,
} from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useGetMappedPartnerOptions } from 'utils/hooks/useGetMappedOptions';

import { translations } from '../../../../new/form/common/types';
import { FormatMessage } from '../../../../utils/intl';

export function useGetIndustryOptions() {
  const partnerIndustryOptions = useGetMappedPartnerOptions('industry');
  const defaultIndustryOptions = useInquirySelectOptions(USER_PROFILE_INDUSTRY);

  if (partnerIndustryOptions.length !== 0) {
    return partnerIndustryOptions;
  }

  return defaultIndustryOptions;
}

export const getMittweidaIndustryDefaultOptions = (formatMessage: FormatMessage) => {
  const {
    building,
    mining,
    energy_and_water_supply,
    service,
    finance_and_insurance,
    hospitality,
    health_and_social,
    trade,
    craft,
    real_estate,
    agriculture,
    manufacturing,
    processing,
    transport,
    other,
    maintenance,
    social,
    health,
    reside,
    fundraiser,
    foundation,
    religious,
    upbringing,
  } =
    translations.pages.userProfile.sections.completingCompanyDetails.fields.userProfileIndustry
      .selectOptions;

  const options: Array<{ id: string; value: string }> = [
    {
      id: building,
      value: INDUSTRY__BUILDING,
    },
    {
      id: mining,
      value: INDUSTRY__MINING,
    },
    {
      id: energy_and_water_supply,
      value: INDUSTRY__ENERGY_AND_WATER_SUPPLY,
    },
    {
      id: service,
      value: INDUSTRY__SERVICE,
    },
    {
      id: finance_and_insurance,
      value: INDUSTRY__FINANCE_AND_INSURANCE,
    },
    {
      id: hospitality,
      value: INDUSTRY__HOSPITALITY,
    },
    {
      id: health_and_social,
      value: INDUSTRY__HEALTH_AND_SOCIAL,
    },
    {
      id: trade,
      value: INDUSTRY__TRADE,
    },
    {
      id: craft,
      value: INDUSTRY__CRAFT,
    },
    {
      id: real_estate,
      value: INDUSTRY__REAL_ESTATE,
    },
    {
      id: agriculture,
      value: INDUSTRY__AGRICULTURE,
    },
    {
      id: manufacturing,
      value: INDUSTRY__MANUFACTURING,
    },
    {
      id: processing,
      value: INDUSTRY__PROCESSING,
    },
    {
      id: transport,
      value: INDUSTRY__TRANSPORT,
    },
    {
      id: other,
      value: INDUSTRY__OTHER,
    },
    {
      id: maintenance,
      value: INDUSTRY__MAINTENANCE,
    },
    {
      id: social,
      value: INDUSTRY__SOCIAL,
    },
    {
      id: health,
      value: INDUSTRY__HEALTH,
    },
    {
      id: reside,
      value: INDUSTRY__RESIDE,
    },
    {
      id: fundraiser,
      value: INDUSTRY__FUNDRAISER,
    },
    {
      id: foundation,
      value: INDUSTRY__FOUNDATION,
    },
    {
      id: religious,
      value: INDUSTRY__RELIGIOUS,
    },
    {
      id: upbringing,
      value: INDUSTRY__UPBRINGING,
    },
  ];

  return options.map(({ id, value }) => ({
    value,
    label: formatMessage({ id }),
  }));
};
