import styled from 'styled-components/macro';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';

export const StyledPeopleSection = styled.div`
  margin-bottom: 4rem;
`;

export const StyledPeopleInfo = styled.div`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledSinglePerson = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;
`;

export const StyledSinglePersonFields = styled.div`
  position: relative;
  flex: 1 1 0%;
`;

export const CustomerDataWrapper = styled.div`
  .value {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-weight: ${FONT_WEIGHT_BOLD};
  }
`;
