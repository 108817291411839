import React from 'react';

import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

const ContactPersonDetailsHeader = () => {
  const t = useTranslations('pages.companyDetails.sections.contactPerson.heading');
  return <PageHeading heading={t('header')} subheading={t('subheader')} />;
};

export default ContactPersonDetailsHeader;
