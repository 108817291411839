import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';

import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';
import { AnyConditions } from 'new/form/state/types';

export function shouldShowField<FieldTypes>(
  name: string,
  values: FieldTypes,
  form: FormApi,
  conditions: AnyConditions,
): boolean {
  const condition = conditions[name];
  return condition ? condition({ values, form }) : true;
}

export function useCanShowField<FieldTypes = MittweidaFieldTypes>(name: string) {
  const { values } = useFormState<FieldTypes>();
  const {
    formStateData: { conditions },
  } = useInquiryProcessConfig();
  const form = useForm();
  return shouldShowField(name, values, form, conditions);
}
