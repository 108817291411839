import React, { FC } from 'react';

import ReactMarkdown from 'react-markdown';

export interface MarkdownWrapperProps {
  text: string;
  renderers?: any;
}

const MarkdownWrapper: FC<MarkdownWrapperProps> = ({ text, renderers }: MarkdownWrapperProps) => {
  return (
    <ReactMarkdown className="markdown" source={text} renderers={renderers} escapeHtml={false} />
  );
};

export default MarkdownWrapper;
