import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledKey as Key,
  StyledPair as Pair,
  StyledValue as Value,
} from 'modules/InquiryDetails/DetailedInformation/InformationRows/styles';
import { isWideField } from 'modules/InquiryDetails/DetailedInformation/service';

const SingleInformation = ({ value, label, isWide }) => {
  const shouldShowWide = isWide || isWideField({ value });

  return (
    <Pair isWide={shouldShowWide} key={label}>
      <Key>{label}</Key>
      <Value>{value}</Value>
    </Pair>
  );
};

SingleInformation.defaultProps = {
  isWide: false,
};

SingleInformation.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  isWide: PropTypes.bool,
};

export default SingleInformation;
