import { defineStyleConfig } from '@chakra-ui/react';

import { textStyle } from '../../../themeConstants';

const Text = defineStyleConfig({
  baseStyle: {
    ...textStyle.sizeM,
  },
});

export default Text;
