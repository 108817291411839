import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['registerLink']);

const CustomerLogin = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    // FIXME, move to defaultProps once Chakra 3.0 is released
    withoutRegisterLink: {
      registerLink: {
        display: 'none',
      },
    },
  },
  defaultProps: {},
});

export default CustomerLogin;
