import React from 'react';

import Condition from 'modules/ConditionalSubform/Condition';
import BuildingFinancingPlanSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingFinancingPlan';
import BuildingInformationSection, {
  validateBuildingInformationSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingInformationSection';
import BuildingProjectSection, {
  validateBuildingProjectSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingProjectSection';

const BuildingSections: React.FC = () => {
  return (
    <>
      <BuildingProjectSection />

      <Condition condition={validateBuildingProjectSection}>
        <BuildingInformationSection />
      </Condition>

      <Condition condition={validateBuildingInformationSection}>
        <BuildingFinancingPlanSection />
      </Condition>
    </>
  );
};

export default BuildingSections;
