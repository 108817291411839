import { useCallback } from 'react';

import { useTranslations } from 'utils/hooks/useTranslations';

/*
 * Translates objects where values of each property is translation string
 * ex. {
 *   foo: 'pages.financingNeed.caption`
 * }
 * result:
 *  {
 *   foo: 'Hello world'
 * }
 */
export const useObjectValueTranslator = () => {
  const t = useTranslations();
  const translateObjectValues = useCallback(
    (objectToTranslate, variables) =>
      Object.keys(objectToTranslate).reduce((translatedObj, key) => {
        if (typeof objectToTranslate[key] === 'object') {
          return {
            ...translatedObj,
            [key]: translateObjectValues(objectToTranslate[key], variables),
          };
        }
        return { ...translatedObj, [key]: t(objectToTranslate[key], variables) };
      }, {}),
    [t],
  );
  return translateObjectValues;
};
