import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const KaufIcon = (props: IconProps) => {
  return (
    <Icon
      width="42"
      height="39"
      viewBox="0 0 41.75 38.87"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M38.44 38.87H2.93V10l18.39-7.32L38.44 10zm-34.51-1h33.51V10.64L21.31 3.76 3.93 10.65z" />
      <path d="M41.75 11.86h-3.81v-1h2.81V9.38l-19.44-8.3L1 9.14v1.72h2.43v1H0v-3.4L21.32 0l20.43 8.72v3.14zM27.39 38.87h-12V24.29a4 4 0 014-4h4a4 4 0 014 4zm-11-1h10V24.29a3 3 0 00-3-3h-4a3 3 0 00-3 3z" />
      <path d="M24.91 16.94h-7a2.49 2.49 0 01-2.48-2.49v-3.09a2.46 2.46 0 011.32-2.19l3.47-1.85a2.51 2.51 0 012.31 0l3.57 1.86a2.47 2.47 0 011.33 2.2v3.08a2.49 2.49 0 01-2.52 2.48zM21.34 8a1.39 1.39 0 00-.69.18l-3.47 1.84a1.48 1.48 0 00-.79 1.31v3.09a1.49 1.49 0 001.48 1.49h7a1.49 1.49 0 001.48-1.49v-3.05a1.46 1.46 0 00-.8-1.31L22 8.2a1.53 1.53 0 00-.66-.2zm14.59 19.82h-6.71v-6.71h6.71zm-5.71-1h4.71v-4.71h-4.71zm-16.98 1H6.53v-6.71h6.71zm-5.71-1h4.71v-4.71H7.53z" />
      <path d="M20.82 7.53h1v8.9h-1z" />
      <path d="M15.89 11.49H26.9v1H15.89z" />
    </Icon>
  );
};

export default KaufIcon;
