import { authorizationOfRepresentationValidationMap } from 'mmv/inquiry/steps/customerData/sections/authorizationOfRepresentationSection/fieldValidation';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidateSectionFunction } from 'new/form/useValidateFields';

export const authorizationOfRepresentationSectionValidations: ValidateSectionFunction<
  InquiryType.mmv
> = (form, values, store) => {
  const { error } = authorizationOfRepresentationValidationMap.validate(values);
  return error === undefined;
};
