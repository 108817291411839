import styled from 'styled-components/macro';

import RadiosWrapper from 'components/RadioGroup/RadiosWrapper';
import WrapperLabel from 'components/RadioGroup/WrapperLabel';
import { mqMedium } from 'styles/breakpoints';

const RadioGroupSameWidthWrapper = styled.div`
  ${WrapperLabel} {
    justify-content: flex-start;
  }

  ${RadiosWrapper} {
    flex-direction: column;
  }

  ${mqMedium`
    ${RadiosWrapper} {
      flex-direction: row;
    }
  `};
`;

export default RadioGroupSameWidthWrapper;
