import {
  MACHINE_DELIVERY_DATE,
  MACHINE_IS_ORDERED,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_AGE,
  MACHINE_NEW,
  MACHINE_MANUFACTURER,
  MACHINE_KIND,
  MACHINE_PERIOD_OF_USE,
  MACHINE_PRICE,
  MACHINE_CATEGORY,
  MACHINE_COUNT,
  MACHINE_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toFloatPrecision2, toInteger, toBoolean, toDate } = fieldParsers;

export const mapMachineToApi: InquiryFormMapper = (allFields: any) => ({
  'machine-category': allFields[MACHINE_CATEGORY],
  'machine-price': toFloatPrecision2(allFields[MACHINE_PRICE]),
  'machine-period-of-use': toInteger(allFields[MACHINE_PERIOD_OF_USE]),
  'machine-manufacturer': allFields[MACHINE_MANUFACTURER],
  'machine-kind': allFields[MACHINE_KIND],
  'machine-is-new': toBoolean(allFields[MACHINE_NEW]),
  'machine-age': toBoolean(allFields[MACHINE_NEW]) ? undefined : toInteger(allFields[MACHINE_AGE]),
  'machine-original-price': toBoolean(allFields[MACHINE_NEW])
    ? undefined
    : toInteger(allFields[MACHINE_ORIGINAL_PRICE]),
  'machine-is-ordered': toBoolean(allFields[MACHINE_IS_ORDERED]),
  'machine-delivery-date': toDate(allFields[MACHINE_DELIVERY_DATE]),
  'machine-count': toInteger(allFields[MACHINE_COUNT]),
  'machine-description': allFields[MACHINE_DESCRIPTION],
});
