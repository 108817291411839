export const INQUIRY_TABLE_HEADERS = {
  ID: 'id',
  CREATED_AT: 'createdAt',
  COMPANY_NAME: 'companyName',
  COMPANY_COUNTRY: 'companyCountry',
  PURPOSE_KIND: 'purposeKind',
  FINANCING_PRODUCT: 'financingProduct',
  FINANCING_NEED: 'financingNeed',
  FINANCING_ROLE: 'financingRole',
  PROCESSOR: 'processor',
  STATUS: 'status',
  SUB_STATUS: 'substatus',
  VB_NUMBER: 'vbNumber',
  VB_NAME: 'vbName',
  INQUIRY_MANAGER: 'inquiryManager',
  EXPECTED_ELIGIBLE_COSTS: 'expectedEligibleCosts',
  EXPECTED_GRANT_AMOUNT: 'expectedGrantAmount',
  PARTNER_REFERENCE_ID: 'partnerReferenceId',
  ASSOCIATION_NAME: 'association',
  HOMEOWNER_ASSOCIATION_NAME: 'homeownerAssociationName',
  HOMEOWNER_ASSOCIATION_ZIPCODE: 'homeownerAssociationZipcode',
  HOMEOWNER_ASSOCIATION_CITY: 'homeownerAssociationCity',
  EXTERNAL_REFERENCE: 'externalReference',
  VEHICLE_MANUFACTURER: 'vehicleManufacturer',
  VEHICLE_KIND: 'vehicleKind',
  CUSTOMER_VB_NUMBER: 'customerVBNumber',
  VEHICLE_DRIVE_TYPE: 'vehicleDriveType',
  FACTORING_LINE: 'factoringLine',
  CONTRACT_NUMBER: 'contractNumber',
  OBJECT_DESCRIPTION: 'objectDescription',
  OBJECT_VALUE: 'objectValue',
  SUPPLIER: 'supplier',
  AGENT: 'agent',
  ZIP_CODE_AREA: 'zipCodeArea',
  VPP_ID: 'vppId',
};

export const INQUIRY_FILTERS = {
  ID: INQUIRY_TABLE_HEADERS.ID,
  CREATED_AT_FROM: 'createdAtFrom',
  CREATED_AT_TO: 'createdAtTo',
  COMPANY_NAME: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  ASSOCIATION_NAME: 'associationName',
  COMPANY_COUNTRY: 'companyCountry',
  COMPANY_PARTNER_REFERENCE_ID: 'companyPartnerReferenceId',
  BANK_ADVISOR_FULL_NAME: 'bankAdvisorFullName',
  INQUIRY_TYPE: 'inquiryType',
  PURPOSE_KIND: INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
  PRODUCT_KIND: 'productKind',
  AMOUNT_FROM: 'amountFrom',
  AMOUNT_TO: 'amountTo',
  STATUS: INQUIRY_TABLE_HEADERS.STATUS,
  INQUIRY_MANAGER: 'inquiryManagerId',
  CUSTOMER_VB_NUMBER: INQUIRY_TABLE_HEADERS.CUSTOMER_VB_NUMBER,
  ZIP_CODE_AREA: 'zipCodeArea',
  VPP_ID: 'vppId',
};

export const API_TO_HEADERS_MAP = {
  id: INQUIRY_TABLE_HEADERS.ID,
  created_at: INQUIRY_TABLE_HEADERS.CREATED_AT,
  company: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  company_country: INQUIRY_TABLE_HEADERS.COMPANY_COUNTRY,
  financing_purpose: INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
  financing_product: INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT,
  indicative_condition_factoring_line: INQUIRY_TABLE_HEADERS.FACTORING_LINE,
  financing_amount: INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  project_financing_role: INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
  processor: INQUIRY_TABLE_HEADERS.PROCESSOR,
  status: INQUIRY_TABLE_HEADERS.STATUS,
  sub_status: INQUIRY_TABLE_HEADERS.SUB_STATUS,
  customers_last_name: INQUIRY_TABLE_HEADERS.VB_NAME,
  customers_vb_number: INQUIRY_TABLE_HEADERS.VB_NUMBER,
  inquiry_manager_id: INQUIRY_FILTERS.INQUIRY_MANAGER,
  inquiry_manager: INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER,
  expected_eligible_costs_sum: INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS,
  expected_grant_amount_sum: INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT,
  company_partner_reference_id: INQUIRY_TABLE_HEADERS.PARTNER_REFERENCE_ID,
  association_name: INQUIRY_TABLE_HEADERS.ASSOCIATION_NAME,
  homeowner_association_name: INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_NAME,
  homeowner_association_zip_code: INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_ZIPCODE,
  homeowner_association_city: INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_CITY,
  external_reference: INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE,
  vehicle_kind: INQUIRY_TABLE_HEADERS.VEHICLE_KIND,
  vehicle_manufacturer: INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER,
  contract_number: INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER,
  object_description: INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION,
  object_value: INQUIRY_TABLE_HEADERS.OBJECT_VALUE,
  supplier: INQUIRY_TABLE_HEADERS.SUPPLIER,
  agent: INQUIRY_TABLE_HEADERS.AGENT,
  zip_code_area: INQUIRY_TABLE_HEADERS.ZIP_CODE_AREA,
  vpp_id: INQUIRY_TABLE_HEADERS.VPP_ID,
};
