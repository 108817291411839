import { FILE_BLOCK_COLOR } from 'components/FileBlock/constants';
import { UPLOAD_STATUS } from 'components/UploadBlock/constants';

export const UPLOAD_STATUS_TO_COLOR_MAP = {
  [UPLOAD_STATUS.NEUTRAL]: FILE_BLOCK_COLOR.GRAY,
  [UPLOAD_STATUS.NOT_UPLOADED]: FILE_BLOCK_COLOR.RED,
  [UPLOAD_STATUS.UPLOADED]: FILE_BLOCK_COLOR.GREEN,
  [UPLOAD_STATUS.UPLOADED_AND_DOWNLOADED]: FILE_BLOCK_COLOR.GREEN,
};

export const UPLOAD_STATUS_DASHED_MAP = {
  [UPLOAD_STATUS.NEUTRAL]: true,
  [UPLOAD_STATUS.NOT_UPLOADED]: true,
  [UPLOAD_STATUS.UPLOADED]: true,
  [UPLOAD_STATUS.UPLOADED_AND_DOWNLOADED]: false,
};
