import React from 'react';

import { Center, Grid, GridItem, useMultiStyleConfig, chakra } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ProgressSectionType } from './types';

function EmptyProgressSectionsPlaceholder() {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.placeholder');
  const styles = useMultiStyleConfig('ProgressSection', {});

  return (
    <chakra.section sx={styles.container}>
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem width={{ base: '75%', sm: '100%', md: '100%' }}>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{t('text')}</TextComponent>
        </GridItem>
        <GridItem>
          <Center height="100%">
            <Asset type="dashboard" value={ProgressSectionType.PLACEHOLDER} />
          </Center>
        </GridItem>
      </Grid>
    </chakra.section>
  );
}

export default EmptyProgressSectionsPlaceholder;
