import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { not } from 'modules/Inquiry/Form/formConditions';
import { CLIENT_ROLE } from 'modules/Inquiry/Form/formFields';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';

import {
  validateClientRoleSection,
  ClientRoleSection,
} from './ClientRoleSection/ClientRoleSection';
import { MoreTechnologiesSection } from './TechnologiesDetailsSection/MoreTechnologies';
import { OneTechnologySection } from './TechnologiesDetailsSection/OneTechnology';
import {
  TechnologySection,
  validateTechnologySection,
} from './TechnologySection/TechnologySection';

export const EnergyEfficiencySections = () => {
  return (
    <>
      <ClientRoleSection name={CLIENT_ROLE} />
      <Condition
        condition={validateClientRoleSection}
        onFail={<DisabledFormSection sectionNumber={2} />}
      >
        <TechnologySection />
      </Condition>
      <Condition
        condition={validateTechnologySection}
        onFail={<DisabledFormSection sectionNumber={3} />}
      >
        <Condition
          isBusinessCondition
          condition={fieldBusinessConditions.isTechnologyOne}
          onFail={null}
        >
          <OneTechnologySection />
        </Condition>
        <Condition
          isBusinessCondition
          condition={not(fieldBusinessConditions.isTechnologyOne)}
          onFail={null}
        >
          <MoreTechnologiesSection />
        </Condition>
      </Condition>
    </>
  );
};
