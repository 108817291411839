import { PropertiesToNumber } from './types';

/**
 * Converts properties of an object to numbers recursively.
 *
 * @param {T} obj - The object to convert its properties to numbers.
 * @template T
 * @return {PropertiesToNumber<T>} - The object with properties converted to numbers.
 */
function recursiveHelper<T>(obj: T): PropertiesToNumber<T> {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === undefined) {
        (obj as any)[key] = 0;
      } else if (typeof obj[key] === 'object') {
        (obj as any)[key] = recursiveHelper(obj[key]);
      } else {
        (obj as any)[key] = Number(obj[key]);
      }
    }
  }
  return obj as PropertiesToNumber<T>;
}

/**
 * Replaces undefined values in an object with zero and transforms all properties to numbers.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object to replace the undefined values.
 * @returns {PropertiesToNumber<T>} - The object with undefined values replaced by zero.
 */
export function replaceUndefinedWithZero<T>(obj: T): PropertiesToNumber<T> {
  const replaced = recursiveHelper(obj) as unknown;
  return replaced as PropertiesToNumber<T>;
}
