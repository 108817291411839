import { dzbFeTobeMapping } from 'dzb/inquiry/config/feToBeMapping';
import { dzbStepData } from 'dzb/inquiry/config/stepData';
import { dzbStepSequence } from 'dzb/inquiry/config/stepSequence';
import { dzbSummaryFields } from 'dzb/inquiry/config/summaryFields';
import { dzbFields, DzbFieldTypes } from 'dzb/inquiry/fields';
import { PortalConfig } from 'new/portals/state/portalConfigContext/types';

export const dzbPortalConfig: PortalConfig<DzbFieldTypes> = {
  stepSequence: dzbStepSequence,
  stepData: dzbStepData,
  summaryFields: dzbSummaryFields,
  feToBeMappingKeys: dzbFeTobeMapping,
  fields: dzbFields,
};
