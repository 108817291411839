import styled, { css } from 'styled-components/macro';

const Separator = styled.div<{ small?: boolean }>`
  width: 100%;
  height: 1px;
  margin: 3.5rem 0;
  background-color: ${(props) => props.theme.colors.background.darkGrey};

  ${({ small }) =>
    small &&
    css`
      margin: 1.5rem 0;
    `}
`;

export default Separator;
