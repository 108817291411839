import {
  all,
  atLeastOne,
  fieldHasValue,
  fieldIsNotEmpty,
} from 'modules/Inquiry/Form/formConditions';
import {
  PRODUCT_KIND,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__PROJECT_FINANCING,
  PRODUCT_KIND__PROPERTY_LOAN,
} from 'modules/Inquiry/Form/formFields';
import { validatePropertyLoanSection } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/PropertyLoan/PropertyLoanSection';
import { validateFactoringSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/factoringSection.validator';
import { validateHirePurchaseSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/hirePurchaseSection.validator';
import { validateInvestmentLoanSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/investmentLoanSection.validator';
import { validateLeasingSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/leasingSection.validator';
import { validateLoanSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/loanSection.validator';
import { validateOtherProductSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/otherProductSection.validator';
import { validateOverdraftSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/overdraftSection.validator';
import { validateProjectFinancingSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/projectFinancingSection.validator';

const sectionValid = {
  loan: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__LOAN), ({ form }) =>
    validateLoanSection(form),
  ),
  leasing: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__LEASING), ({ form }) =>
    validateLeasingSection(form),
  ),
  overdraft: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__OVERDRAFT), ({ form }) =>
    validateOverdraftSection(form),
  ),
  factoring: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__FACTORING), ({ form }) =>
    validateFactoringSection(form),
  ),
  other: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__OTHER), ({ form }) =>
    validateOtherProductSection(form),
  ),
  projectFinancing: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__PROJECT_FINANCING), ({ form }) =>
    validateProjectFinancingSection(form),
  ),
  investmentLoan: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__INVESTMENT_LOAN), ({ form }) =>
    validateInvestmentLoanSection(form),
  ),
  hirePurchase: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__HIRE_PURCHASE), ({ form }) =>
    validateHirePurchaseSection(form),
  ),
  mezzanine: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__MEZZANINE)),
  propertyLoan: all(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__PROPERTY_LOAN), ({ form }) =>
    validatePropertyLoanSection(form),
  ),
};

export const requestDetailsConditions = {
  hasSelectedLoan: fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__LOAN),
  isProductKindSelected: fieldIsNotEmpty(PRODUCT_KIND),
  shouldShowCommonSections: atLeastOne(
    sectionValid.loan,
    sectionValid.leasing,
    sectionValid.overdraft,
    sectionValid.factoring,
    sectionValid.other,
    sectionValid.mezzanine,
    sectionValid.projectFinancing,
    sectionValid.investmentLoan,
    sectionValid.hirePurchase,
    sectionValid.propertyLoan,
  ),
};
