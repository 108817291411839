import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { QualityCondition } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getMittweidaConditionQualityOptions = (
  formatMessage: FormatMessage,
): SelectOption<QualityCondition>[] => {
  const { simple, good, medium } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .conditionQuality.options;

  const options: Array<{ id: string; value: QualityCondition }> = [
    {
      id: simple,
      value: 'simple',
    },
    {
      id: medium,
      value: 'medium',
    },
    {
      id: good,
      value: 'good',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
