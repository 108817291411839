import { useSelector } from 'react-redux';

import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { mapDzbCompanyData } from 'dzb/inquiry/steps/companyData/dataMapper';
import { mapDzbPersonalData } from 'dzb/inquiry/steps/personalData/dataMapper';
import { mapDzbRetailerData } from 'dzb/inquiry/steps/retailerData/dataMapper';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { createFinalizeSpecializedInquiryAction } from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

export type SendDzbInquiryPayload = {
  status: number;
  data: {
    included: [
      {
        id: string;
        type: 'legal_representatives' | 'beneficiary_owners';
        attributes: {
          potentially_manipulated: boolean;
        };
      },
    ];
  };
};

function useSendInquiry() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.submitInquiry)));

  return async (values: DzbFieldTypes) => {
    const companyData = mapDzbCompanyData(values);
    const retailerData = mapDzbRetailerData(values);
    const personalData = mapDzbPersonalData(values);

    const apiData = {
      ...companyData,
      ...retailerData,
      ...personalData,
    };

    const updateDzbInquiry = (inquiryId: string) =>
      createFinalizeSpecializedInquiryAction(apiData, InquiryType.dzb, inquiryId);

    return makeCall(updateDzbInquiry(inquiryId));
  };
}

export function useSendDzbInquiry() {
  return useSendInquiry();
}
