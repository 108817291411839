import React, { ReactNode } from 'react';

import DownloadFileInRequest from 'components/UploadBlock/NewFileRequestBlock/components/DownloadFileInRequest';
import PreviewFileInRequest from 'components/UploadBlock/NewFileRequestBlock/components/PreviewFileInRequest';
import RemoveFileInRequestButton from 'components/UploadBlock/NewFileRequestBlock/components/RemoveFileInRequestButton';
import {
  StyledIconWrapper as IconWrapper,
  StyledRequestActions,
  UnuploadedIcon as FileIcon,
} from 'components/UploadBlock/NewFileRequestBlock/styles';
import {
  StyledFileBlockWrapper as Wrapper,
  StyledFileContent as Content,
  StyledFileTitle as Title,
  StyledRequestActionListItem as ActionListItem,
  StyledTitleWrapper as TitleWrapper,
  StyledUploadedFileInfo as FileInfo,
} from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import { FileType, IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { formatDateWithTime } from 'utils/date';

import { useIsMobile } from '../../../../utils/hooks/useIsMobile';
import DownloadCompeonFile from '../components/DownloadCompeonFile';
import MobileViewFileInRequest from '../components/MobileViewFileInRequest';

interface IFileBlock {
  file: IFile | CompeonDocument;
  canRemove?: boolean;
  label?: ReactNode;
  onRemove?: Function;
  isCompeonFile?: boolean;
  isReeEnabled?: boolean;
  isReePage?: boolean;
  type?: FileType;
}

const UploadedFileBlock = ({
  file,
  canRemove,
  label,
  onRemove,
  isCompeonFile,
  isReeEnabled,
  isReePage,
  type,
}: IFileBlock) => {
  const isMobile = useIsMobile();
  const isAllowedToDownload = !isReePage || (isReePage && isReeEnabled);
  const isAllowedToDelete = canRemove && isAllowedToDownload;
  return (
    <Wrapper data-testid="file-block">
      <IconWrapper>
        <FileIcon />
      </IconWrapper>

      <Content>
        <TitleWrapper>
          <Title>{file.fileName}</Title>
          {label}
        </TitleWrapper>
        <FileInfo>{file.createdAt ? formatDateWithTime(file.createdAt) : null}</FileInfo>
      </Content>

      <StyledRequestActions>
        {isCompeonFile ? (
          <ActionListItem>
            <DownloadCompeonFile file={file} />
          </ActionListItem>
        ) : (
          <>
            {
              // mobile devices does not support downloading files, but previewing files work differently from desktop
              isMobile ? (
                <ActionListItem>
                  <MobileViewFileInRequest file={file} type={type} />
                </ActionListItem>
              ) : (
                <>
                  <ActionListItem>
                    <PreviewFileInRequest file={file} type={type} />
                  </ActionListItem>
                  {isAllowedToDownload ? (
                    <ActionListItem>
                      <DownloadFileInRequest file={file} type={type} />
                    </ActionListItem>
                  ) : null}
                </>
              )
            }
          </>
        )}
        {isAllowedToDelete ? (
          <ActionListItem>
            <RemoveFileInRequestButton onRemove={onRemove} file={file} />
          </ActionListItem>
        ) : null}
      </StyledRequestActions>
    </Wrapper>
  );
};

export default UploadedFileBlock;
