export enum FIELD_UNIT_TYPE {
  CURRENCY = 'currency',
  DEFAULT_CURRENCY = 'defaultCurrency',
  THOUSANDS_CURRENCY = 'thousandsCurrency',
  SQUARE_METERS = 'squareMeters',
  QUADRA_METERS = 'quadraMeters',
  KILOMETERS = 'kilometers',
  YEARS = 'years',
  MONTHS = 'months',
  PERCENTAGE = 'percentage',
  PERCENTAGE_DECIMAL = 'percentageDecimal',
  PERCENTAGE_DECIMAL_STRING = 'percentageDecimalString',
  DATE = 'date',
  DATEINPUT = 'dateinput',
  from1000 = 'from1000',
  M2M3 = 'm2m3',
  MONTH_AND_YEAR = 'monthAndYear',
  INVOICES = 'invoices',
  DAYS = 'days',
  CURRENCY_PER_MONTH = 'currencyPerMonth',
}

const {
  THOUSANDS_CURRENCY,
  CURRENCY,
  DEFAULT_CURRENCY,
  KILOMETERS,
  MONTHS,
  PERCENTAGE,
  PERCENTAGE_DECIMAL,
  PERCENTAGE_DECIMAL_STRING,
  SQUARE_METERS,
  YEARS,
  DATE,
  DATEINPUT,
  from1000,
  M2M3,
  MONTH_AND_YEAR,
  INVOICES,
  DAYS,
  CURRENCY_PER_MONTH,
  QUADRA_METERS,
} = FIELD_UNIT_TYPE;

export const UNIT_TYPE_SUFFIX: { [key in FIELD_UNIT_TYPE]: (translator: Function) => any } = {
  [CURRENCY]: (translator) => translator('currency.euro'),
  [THOUSANDS_CURRENCY]: (translator) => translator('currency.thousandEuro'),
  [SQUARE_METERS]: (translator) => translator('other.m2'),
  [QUADRA_METERS]: (translator) => translator('other.qm'),
  [KILOMETERS]: (translator) => translator('other.kilometers'),
  [YEARS]: (translator) => translator('other.years'),
  [MONTHS]: (translator) => translator('other.months'),
  [PERCENTAGE]: (translator) => translator('other.percent'),
  [PERCENTAGE_DECIMAL]: (translator) => translator('other.percent'),
  [PERCENTAGE_DECIMAL_STRING]: (translator) => translator('other.percent'),
  [DATE]: () => null,
  [DATEINPUT]: () => null,
  [from1000]: (translator) => translator('other.from1000'),
  [M2M3]: (translator) => translator('other.m2m3'),
  [INVOICES]: (translator) => translator('other.invoices'),
  [DAYS]: (translator) => translator('other.days'),
  [MONTH_AND_YEAR]: () => null,
  [CURRENCY_PER_MONTH]: (translator) => translator('other.currencyPerMonth'),
  [DEFAULT_CURRENCY]: (translator) => translator('other.defaultCurrency'),
};
