import React from 'react';

import { InputWithField, INPUT_TYPE_NUMBER } from 'components/Input';
import { LEASING_ADVANCE_PAYMENT } from 'modules/Inquiry/Form/formFields';

const LeasingAdvancePayment = () => {
  return <InputWithField name={LEASING_ADVANCE_PAYMENT} type={INPUT_TYPE_NUMBER} optional />;
};

export default LeasingAdvancePayment;
