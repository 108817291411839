import React from 'react';

import PropTypes from 'prop-types';

import CallOnMount from 'components/FormSection/CallOnMount';
import { ProgressCalculationContext } from 'modules/FormPage';

const RecalculateProgressBarOnMount = ({ children }) => {
  return (
    <ProgressCalculationContext.Consumer>
      {(recalculate) => <CallOnMount callback={recalculate}>{children}</CallOnMount>}
    </ProgressCalculationContext.Consumer>
  );
};

RecalculateProgressBarOnMount.defaultProps = {
  children: null,
};

RecalculateProgressBarOnMount.propTypes = {
  children: PropTypes.node,
};

export default RecalculateProgressBarOnMount;
