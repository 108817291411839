import React from 'react';

import {
  StyledSummaryBlock as Block,
  StyledSummaryBlockValue as Value,
} from 'modules/InquiryDetails/DetailedInformation/SummarySection/SummaryBlock/styles';

import { TextComponent } from '../../../../../theme/components/Text';

type Props = {
  name: string;
  value: string | undefined;
  testId?: string;
};

const SummaryBlock = ({ name, value, testId }: Props) => {
  return (
    <Block data-testid={testId}>
      <TextComponent mb={4}>{name}</TextComponent>
      <Value>{value}</Value>
    </Block>
  );
};

export default SummaryBlock;
