import * as React from 'react';

import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import FormRow from 'components/FormRow';
import Separator from 'components/FormRow/Separator';
import { INPUT_TYPE_DECIMAL, InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import Unit from 'components/Unit/Unit';
import { mittweidaFields, mittweidaFieldsWithoutParents } from 'mittweida/inquiry/fields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import {
  StyledUsageActions as Actions,
  StyledTypeTitle as Title,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/styles';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { translations } from 'new/form/common/types';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const FutureUsageData = () => {
  const t = useTranslations();
  const { mutators } = useForm();
  const {
    futureObjectType,
    futureUsage: { typeOfUse: typeOfUseWithParent },
  } = mittweidaFields.financingNeedPage.objectInformationSection;
  const { typeOfUse, usageSpace, numberOfUnits, plannedSalesPrice } =
    mittweidaFieldsWithoutParents.financingNeedPage.objectInformationSection.futureUsage;
  const { plannedSalesPrice: plannedSalesPriceTranslations } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.usage;

  const objectTypeOptions = useInquirySelectOptions(futureObjectType);
  const typeOfUseOptions = useInquirySelectOptions(typeOfUseWithParent);

  return (
    <>
      <TextComponent mb={6}>
        {t(
          translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation
            .subheadings.objectUsage,
        )}
      </TextComponent>
      <FormRow>
        <SelectWithField
          name={futureObjectType}
          options={objectTypeOptions}
          placeholder={t(translations.placeholders.pleaseChoose)}
          data-testid={'mittweida-future-object-type-field'}
        />
      </FormRow>
      <FieldArray name={'financingNeedPage.objectInformationSection.futureUsage'}>
        {({ fields }) => {
          return (
            <>
              {fields.map((name, index) => {
                return (
                  <div key={name}>
                    <Title>
                      #{index + 1}{' '}
                      {t(
                        translations.inquiryType.mittweida.pages.financingNeed.sections
                          .objectInformation.fields.usage.typeOfUse.title,
                      )}
                    </Title>
                    <FormRow>
                      <SelectWithField
                        name={`${name}.${typeOfUse}`}
                        options={typeOfUseOptions}
                        placeholder={t(translations.placeholders.pleaseChoose)}
                        data-testid={'mittweida-future-usage-type-field'}
                      />
                    </FormRow>
                    <FormRow>
                      <InputWithField
                        name={`${name}.${usageSpace}`}
                        type={INPUT_TYPE_DECIMAL}
                        sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
                      />
                    </FormRow>
                    <FormRow>
                      <AmountWithField
                        name={`${name}.${plannedSalesPrice}`}
                        separateThousands
                        optional
                        tooltip={t(plannedSalesPriceTranslations.tooltip)}
                      />
                      <InputWithField name={`${name}.${numberOfUnits}`} type={'number'} optional />
                    </FormRow>
                    {index > 0 && (
                      <Actions>
                        <ButtonComponent
                          leftIcon={<DeleteIcon boxSize={6} display={'block'} />}
                          onClick={() => fields.remove(index)}
                          variant={'secondary'}
                        >
                          {t(
                            translations.inquiryType.mittweida.pages.financingNeed.sections
                              .objectInformation.fields.usage.remove,
                          )}
                        </ButtonComponent>
                      </Actions>
                    )}
                    <Separator />
                  </div>
                );
              })}
            </>
          );
        }}
      </FieldArray>

      <ButtonComponent
        leftIcon={<AddIcon boxSize={6} display={'block'} />}
        onClick={() => mutators.push('financingNeedPage.objectInformationSection.futureUsage')}
        variant={'secondary'}
        w={'100%'}
        mb={16}
      >
        {t(
          translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
            .usage.add,
        )}
      </ButtonComponent>
    </>
  );
};
