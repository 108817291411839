import * as React from 'react';

import { Box, Grid, GridItem, VisuallyHidden } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { VEHICLE_MANUFACTURER, VEHICLE_MODEL } from 'modules/Inquiry/Form/formFields';
import { getContractDetails, getExternalReference } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { CircularProgress } from 'theme/components/CircularProgress';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListDescription,
} from 'theme/components/DescriptionList';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useSummaryCardData } from './useSummaryCardData';

type SummaryCardProps = {
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  openTaskId?: string;
};

export const LeasePlanSummaryCard = ({
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  openTaskId,
}: SummaryCardProps) => {
  const t = useTranslations();
  const data = useSummaryCardData();
  const externalRef = useSelector(getExternalReference);
  const contractDetails = useSelector(getContractDetails);

  const notAvailable = t('other.notAvailable');

  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)']}
      gap={12}
      as="section"
      border="1px"
      borderColor="border.lightGrey"
      p={8}
      boxShadow="default"
      bgColor="background.white"
    >
      <GridItem>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          paddingBottom={3}
        >
          <DescriptionList mb={1}>
            <DescriptionListDescription>{data.purpose}</DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="large" mb={8}>
            <DescriptionListDescription>
              {`${contractDetails[VEHICLE_MANUFACTURER]} ${contractDetails[VEHICLE_MODEL]}`}
            </DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="small" mb={8}>
            <DescriptionListTerm>
              <VisuallyHidden>
                {t('pages.inquiryDetails.detailed.summary.creationDate')}
              </VisuallyHidden>
              <TimeIcon boxSize={6} display="block" mr={-1} />
            </DescriptionListTerm>
            <DescriptionListDescription>
              <time dateTime="2020-09-19T11:25:00">
                <TextComponent as="span" display="block">
                  {data.createdAtSince} {t('pages.inquiryDetails.dashboard.summary.sinceCreation')}
                </TextComponent>
                <TextComponent as="span" display="block" color="text.tertiary">
                  ({data.createdAt})
                </TextComponent>
              </time>
            </DescriptionListDescription>
          </DescriptionList>
          <DescriptionList size="small">
            <DescriptionListTerm mr={3}>
              {t('pages.inquiryDetails.dashboard.summary.lpExternalReference')}
            </DescriptionListTerm>
            <DescriptionListDescription>{externalRef || notAvailable}</DescriptionListDescription>
          </DescriptionList>
        </Box>
      </GridItem>

      <GridItem>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          position="relative"
          height="100%"
          paddingBottom={3}
          _before={{
            content: "''",
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '-24px',
            width: '1px',
            backgroundColor: 'background.darkGrey',
          }}
        >
          <DescriptionList mb={1}>
            <DescriptionListDescription>
              {t('pages.inquiryDetails.dashboard.summary.companyName')}
            </DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="large">
            <DescriptionListDescription>{data.companyName}</DescriptionListDescription>
          </DescriptionList>
        </Box>
      </GridItem>

      <GridItem>
        <Box
          display="flex"
          alignItems={['flex-start', null, null, 'center']}
          flexDirection="column"
          justifyContent="flex-end"
          position="relative"
          height="100%"
          _before={{
            content: "''",
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '-24px',
            width: '1px',
            backgroundColor: 'background.darkGrey',
          }}
        >
          <CircularProgress
            progressIncrement={progressIncrement!}
            progressTotal={progressTotal!}
            progressType={t('other.tasks')}
            isCompleted={isCompleted}
            completedValue={isCompleted ? 100 : completedValue}
            size="large"
            mb={4}
          />
          {!isCompleted && (
            <ButtonComponent
              as="a"
              href={`#${openTaskId}`}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="tertiary"
            >
              {t('other.openTasks')}
            </ButtonComponent>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};
