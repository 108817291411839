import { useLoadMarketData } from 'api/CompeonReverseApi/operation/queryHooks';
import { TMarketDataAttributes } from 'api/CompeonReverseApi/operation/types';
import { queryClient } from 'components/App';
import mapMwMarketValueFromApi from 'modules/EditMarketValuesReeProfitability/mapMwMarketValueFromApi';
import { MW_MARKET_DATA_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { flattenObject } from 'utils/helpers';

const loadMwMarketData = async (inquiryId: string, recordType: string) => {
  const variables = {
    inquiryId,
    recordType,
  };
  const { data }: { data: { attributes: TMarketDataAttributes } } = await queryClient.fetchQuery(
    useLoadMarketData.getKey(variables),
    useLoadMarketData.queryFn,
  );
  let editFormValues;

  if (recordType === MW_MARKET_DATA_RECORD_TYPE) {
    editFormValues = mapMwMarketValueFromApi(data);
  }

  return flattenObject(editFormValues);
};

export default loadMwMarketData;
