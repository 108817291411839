import {
  INVESTMENT_LOAN_ADVANCE_PAYMENT,
  INVESTMENT_LOAN_TERM_IN_MONTHS,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  INVESTMENT_LOAN_DESCRIPTION,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger, toBoolean, toFloatPrecision2 } = fieldParsers;
export const mapInvestmentLoanToApi: InquiryFormMapper = (allFields) => {
  const isPartialAmortisation =
    allFields[INVESTMENT_LOAN_AMORTISATION_KIND] ===
    INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION;
  return {
    'investment-loan-advance-payment': toInteger(allFields[INVESTMENT_LOAN_ADVANCE_PAYMENT]),
    'investment-loan-term-in-months': toInteger(allFields[INVESTMENT_LOAN_TERM_IN_MONTHS]),
    'investment-loan-amortisation-kind': allFields[INVESTMENT_LOAN_AMORTISATION_KIND],
    'investment-loan-description': allFields[INVESTMENT_LOAN_DESCRIPTION],
    'investment-loan-interested-in-other-products': toBoolean(
      allFields[INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS],
    ),
    ...conditionalObject(isPartialAmortisation, {
      'investment-loan-residual-value': toFloatPrecision2(
        allFields[INVESTMENT_LOAN_RESIDUAL_VALUE],
      ),
    }),
  };
};
