import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  typeOfFinancialStatements,
  lastAnnualFinancialStatementReportingDate,
  country,
  city,
  zipCode,
  address,
  companyName,
  salesTaxID,
  companyOlderThan3Years,
} = dzbFields.companyDataPage.companyInformationSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.companyData.sections.companyInformation;

export const companyInformationSectionLabelsMap: LabelsMap = {
  [typeOfFinancialStatements]: fieldTranslations.typeOfFinancialStatements.caption,
  [lastAnnualFinancialStatementReportingDate]:
    fieldTranslations.lastAnnualFinancialStatementReportingDate.caption,
  [country]: fieldTranslations.country.caption,
  [city]: fieldTranslations.city.caption,
  [zipCode]: fieldTranslations.zipCode.caption,
  [address]: fieldTranslations.address.caption,
  [companyName]: fieldTranslations.companyName.caption,
  [salesTaxID]: fieldTranslations.salesTaxId.caption,
  [companyOlderThan3Years]: fieldTranslations.companyOlderThan3Years.caption,
};
