import React from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import {
  PRODUCT_KIND,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__PROPERTY_LOAN,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__PROJECT_FINANCING,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__AVAL,
  PRODUCT_KIND__LIQUIDITY,
} from 'modules/Inquiry/Form/formFields';
import Factoring from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/FactoringSection';
import HirePurchase from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/HirePurchase/HirePurchase';
import BfsProductKindSection from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/BfsProductKindSection';
import InvestmentLoan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvestmentLoanSection';
import Leasing from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/LeasingSection';
import Loan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanSection';
import Mezzanine from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Mezzanine/MezzanineSection';
import Other from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Other/OtherSection';
import Overdraft from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Overdraft/OverdraftSection';
import ProjectFinancing from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/ProjectFinancing/ProjectFinancingSection';
import PropertyLoanSection from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/PropertyLoan/PropertyLoanSection';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useFieldValue } from 'store/hooks/useFormValues';

const DEFAULT_PRODUCT_KIND_SECTIONS = {
  [PRODUCT_KIND__LOAN]: <Loan />,
  [PRODUCT_KIND__PROPERTY_LOAN]: <PropertyLoanSection />,
  [PRODUCT_KIND__OVERDRAFT]: <Overdraft />,
  [PRODUCT_KIND__FACTORING]: <Factoring />,
  [PRODUCT_KIND__LEASING]: <Leasing />,
  [PRODUCT_KIND__OTHER]: <Other />,
  [PRODUCT_KIND__MEZZANINE]: <Mezzanine />,
  [PRODUCT_KIND__PROJECT_FINANCING]: <ProjectFinancing />,
  [PRODUCT_KIND__INVESTMENT_LOAN]: <InvestmentLoan />,
  [PRODUCT_KIND__HIRE_PURCHASE]: <HirePurchase />,
};

const BFS_PRODUCT_KIND_SECTIONS = {
  [PRODUCT_KIND__LOAN]: <BfsProductKindSection productKind={PRODUCT_KIND__LOAN} maxYears={30} />,
  [PRODUCT_KIND__INVESTMENT_LOAN]: (
    <BfsProductKindSection productKind={PRODUCT_KIND__INVESTMENT_LOAN} maxYears={10} />
  ),
  [PRODUCT_KIND__AVAL]: <BfsProductKindSection productKind={PRODUCT_KIND__AVAL} maxYears={10} />,
  [PRODUCT_KIND__LIQUIDITY]: <BfsProductKindSection productKind={PRODUCT_KIND__LIQUIDITY} />,
};

const PRODUCT_KIND_SECTIONS_BY_INQUIRY_TYPE = {
  [InquiryType.bfs]: BFS_PRODUCT_KIND_SECTIONS,
  default: DEFAULT_PRODUCT_KIND_SECTIONS,
};

const ProductKindDetailsSection = () => {
  const { selectedInquiryType } = useFormConfig();
  let productKind = useFieldValue(PRODUCT_KIND, '');
  const sectionsByInquiryType =
    PRODUCT_KIND_SECTIONS_BY_INQUIRY_TYPE[selectedInquiryType] ||
    PRODUCT_KIND_SECTIONS_BY_INQUIRY_TYPE.default;
  return sectionsByInquiryType[productKind] || null;
};

export default ProductKindDetailsSection;
