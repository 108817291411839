import {
  FIELD_UNIT_TYPE,
  UNIT_TYPE_SUFFIX,
} from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { useUnitsMap } from 'modules/Inquiry/inquiryFieldsUnits/useGetUnitsForField';
import { extractNestedFieldName } from 'utils/form/getFieldName';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useFieldUnit = (name: string): FIELD_UNIT_TYPE | null => {
  const fieldName = extractNestedFieldName(name);

  const fullUnitsMap = useUnitsMap();

  return fullUnitsMap[fieldName] as FIELD_UNIT_TYPE;
};

export const useFieldUnitTranslation = (name: string): FIELD_UNIT_TYPE | null => {
  const translator = useTranslations();
  const fieldName = extractNestedFieldName(name);
  const unitName = useFieldUnit(fieldName);

  if (!unitName) return null;

  const unitValue = UNIT_TYPE_SUFFIX[unitName];

  return unitValue(translator);
};
