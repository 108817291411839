import styled from 'styled-components/macro';

import { IconProps } from './types';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const Icon = styled.img<IconProps>`
  display: block;
  width: ${(props) => (props.iconList ? '8rem' : '3rem')};
  height: ${(props) => (props.iconList ? '6rem' : '2rem')};
`;
