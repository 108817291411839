import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';

export const StyledDetailedSection = styled.section`
  padding: 2rem 0;

  ${mqMedium`
    padding: 6rem 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledDetailedSectionTitle = styled.h4`
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primary};

  ${mqMedium`
    margin-bottom: 2rem;
  `}
`;
