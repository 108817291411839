import React from 'react';

import { Flex } from '@chakra-ui/react';

import { translations } from 'new/form/common/types';
import WarningIcon from 'theme/components/Icon/WarningIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  potentiallyManipulated: boolean;
};

export const PotentiallyManipulatedWarning = ({ potentiallyManipulated }: Props) => {
  const t = useTranslations();

  if (potentiallyManipulated) {
    return (
      <Flex gap={2} alignItems={'center'}>
        <WarningIcon boxSize={5} display={'block'} color={'status.error'} />
        <TextComponent color={'text.tertiary'}>
          {t(translations.pages.companiesDetails.associatedPerson.new.warning)}
        </TextComponent>
      </Flex>
    );
  }

  return null;
};
