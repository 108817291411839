import React, { ReactNode } from 'react';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { HeadingComponent } from 'theme/components/Heading';

type FileSectionTitleProps = {
  children: ReactNode;
  mb?: number;
};

const FileSectionTitle = ({ children, mb = 6 }: FileSectionTitleProps) => (
  <HeadingComponent variant="secondary" fontWeight={FONT_WEIGHT_BOLD} color="brand.default" mb={mb}>
    {children}
  </HeadingComponent>
);

export default FileSectionTitle;
