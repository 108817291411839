import React from 'react';

import FormRow from 'components/FormRow';
import { useGenerateBeneficialOwnerFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/formFields/useGenerateBeneficialOwnerFields';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

type Props = {
  mode: AssociatedPersonMode;
  prefix?: string;
};

export const BeneficialOwnerFields = ({ mode, prefix }: Props) => {
  const fields = useGenerateBeneficialOwnerFields(mode, prefix);

  return (
    <>
      <FormRow>
        {fields.firstName}
        {fields.lastName}
      </FormRow>
      <FormRow>
        {fields.nationality}
        {fields.capitalOrVotingRightsPercentage}
      </FormRow>
      <FormRow>
        {fields.placeOfResidence}
        {fields.countryOfResidence}
      </FormRow>
      <FormRow>
        {fields.birthPlace}
        {fields.birthDate}
      </FormRow>
      <FormRow>{fields.fictional}</FormRow>
    </>
  );
};
