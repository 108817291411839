import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { translations } from 'new/form/common/types';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import ButtonComponent from 'theme/components/Button';
import { TextComponent } from 'theme/components/Text';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ArchiveDocumentsModal } from './ArchiveDocumentsModal';
import { useArchiveDocumentsButton } from '../hooks/useArchiveDocumentsButton';

export const ArchiveMultipleDocumentsButton = () => {
  const t = useTranslations();
  const { button, lastArchived } =
    translations.pages.inquiryDetails.documentExchange.archiveDocuments;
  const { isOpen, onClose, onOpen } = useArchiveDocumentsButton();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);

  return (
    <>
      <VStack alignItems="end">
        <ButtonComponent variant="secondary" onClick={onOpen}>
          {t(button)}
        </ButtonComponent>
        <Condition condition={Boolean(inquiryDetails?.fileArchivingTriggeredAt)}>
          <TextComponent color="text.tertiary">
            {t(lastArchived, {
              lastArchived: formatDateWithTime(inquiryDetails?.fileArchivingTriggeredAt),
            })}
          </TextComponent>
        </Condition>
      </VStack>
      <ArchiveDocumentsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
