export const months = [
  { id: 'components.monthPicker.months.january', value: 1 },
  { id: 'components.monthPicker.months.february', value: 2 },
  { id: 'components.monthPicker.months.march', value: 3 },
  { id: 'components.monthPicker.months.april', value: 4 },
  { id: 'components.monthPicker.months.may', value: 5 },
  { id: 'components.monthPicker.months.june', value: 6 },
  { id: 'components.monthPicker.months.july', value: 7 },
  { id: 'components.monthPicker.months.august', value: 8 },
  { id: 'components.monthPicker.months.september', value: 9 },
  { id: 'components.monthPicker.months.october', value: 10 },
  { id: 'components.monthPicker.months.november', value: 11 },
  { id: 'components.monthPicker.months.december', value: 12 },
];

export const getMonthsOptions = (translate) =>
  months.map(({ id, value }) => ({ label: translate(id), value }));
