import React from 'react';

import PropTypes from 'prop-types';

import { InputAdapter } from 'components/Input';
import DebouncingValidatingField from 'components/Input/DebouncedValidatingField';

const DebouncedInputField = ({ name, validate, debounce, ...restProps }) => {
  return (
    <DebouncingValidatingField name={name} validate={validate} debounce={debounce}>
      {({ input, meta }) => (
        <InputAdapter
          input={input}
          meta={meta}
          onChange={(value) => {
            input.onChange(value);
          }}
          {...restProps}
        />
      )}
    </DebouncingValidatingField>
  );
};

DebouncedInputField.propTypes = {
  name: PropTypes.string.isRequired,
  debounce: PropTypes.number.isRequired,
  validate: PropTypes.func.isRequired,
};

export default DebouncedInputField;
