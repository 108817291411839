import * as React from 'react';

import { Box } from '@chakra-ui/react';

import {
  StyledKey,
  StyledValue,
} from '../../../../../../modules/InquiryDetails/DetailedInformation/InformationRows/styles';
import { SummaryPageField } from '../../../../../../new/summary/hooks/useBuildFieldsForSummary';

type Props = {
  detailField: SummaryPageField;
};

export const DetailField = ({ detailField }: Props) => {
  return (
    <Box>
      <StyledKey>{detailField.caption}</StyledKey>
      <StyledValue>{detailField.text}</StyledValue>
    </Box>
  );
};
