import React from 'react';

import { ELEMENT_IDS } from 'constants/elementIds';
import { StyledCompanyNav } from 'pages/operationPortal/CompaniesDetails/styles';

import { useInquiryDetailsCompanyDetailsRoutes } from './useInquiryDetailsCompanyDetailsRoutes';

interface Props {
  inquiryId: string;
  companyId: string;
}

const CompanySubnavigation: React.FC<Props> = () => {
  const routeLinks = useInquiryDetailsCompanyDetailsRoutes();

  return (
    <StyledCompanyNav subNav aria-labelledby={ELEMENT_IDS.COMPANY_NAVIGATION}>
      <ul>{routeLinks}</ul>
    </StyledCompanyNav>
  );
};

export default CompanySubnavigation;
