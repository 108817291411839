import { FIELD_KIND_TYPE, FIELD_VALUE_TYPE } from 'modules/FormGenerator/constants';
import {
  BooleanField,
  DecimalField,
  DocumentField,
  IntegerField,
  MultiselectField,
  SelectField,
  StringField,
  TextAreaField,
} from 'modules/FormGenerator/fields';
import StaticFormDescription from 'modules/FormGenerator/fields/staticFormDescription';
import StaticFormTitle from 'modules/FormGenerator/fields/staticFormTitle';

import { Field } from './types';

const { STRING, INTEGER, DECIMAL, BOOLEAN, DOCUMENT, MULTI_SELECT, SELECT, TEXT } =
  FIELD_VALUE_TYPE;
const FIELDS_MAP = {
  [BOOLEAN]: BooleanField,
  [DECIMAL]: DecimalField,
  [INTEGER]: IntegerField,
  [STRING]: StringField,
  [SELECT]: SelectField,
  [MULTI_SELECT]: MultiselectField,
  [DOCUMENT]: DocumentField,
  [TEXT]: TextAreaField,
};

const { DESCRIPTION, FIELD, TITLE } = FIELD_KIND_TYPE;

const FIELD_KIND_MAP = {
  [FIELD]: (field: Field) => FIELDS_MAP[field.type] || null,
  [DESCRIPTION]: () => StaticFormDescription,
  [TITLE]: () => StaticFormTitle,
};

export const getFieldComponent = (field: Field) => {
  const componentGenerator = FIELD_KIND_MAP[field.kind];
  if (!componentGenerator) {
    return null;
  }
  return componentGenerator(field);
};
