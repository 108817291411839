import styled from 'styled-components/macro';

import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';

export const StyledLoadingText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.primary};

  p {
    margin-right: 1rem;
  }

  ${StyledSpinnerWrapper} {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0;
  }
`;

export const StyledFastlaneResultInfo = styled.p<{ isQualified: boolean }>`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  text-align: center;
  color: ${({ theme, isQualified }) =>
    isQualified ? theme.colors.status.success : theme.colors.status.error};
`;

export const StyledErrorDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
  margin: 1rem auto;
  color: ${({ theme }) => theme.colors.primary};

  p {
    margin: 0.25rem 0;
  }

  span {
    display: inline-block;
    width: 100px;
  }
`;

export const StyledAdditionalErrorText = styled.p`
  margin: 1rem 20rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.tertiary};

  a {
    font-weight: ${FONT_WEIGHT_BOLD};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};

    :focus,
    :hover {
      text-decoration: underline;
    }
  }
`;
