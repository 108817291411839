import styled from 'styled-components/macro';

import { borderRadius, zIndex } from 'theme/themeConstants';

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: ${zIndex.default};
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: ${borderRadius.circle};
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.background.white};

  :focus,
  :hover {
    color: ${(props) => props.theme.colors.text.white};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export default CloseButton;
