export const UPLOAD_STATUS = {
  NOT_UPLOADED: 'notUploaded',
  UPLOADED: 'uploaded',
  SCANNING: 'scanning',
  UPLOADED_AND_DOWNLOADED: 'uploadedAndDownloaded',
  NEUTRAL: 'neutral',
};
export const UPLOAD_STATUSES = Object.values(UPLOAD_STATUS);

export const FILE_SCAN_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};
export const FILE_SCAN_STATUSES = Object.values(FILE_SCAN_STATUS);
