import React from 'react';

import { ConfigController } from 'config/ConfigController';
import { useFormConfig } from 'config/formConfig/hooks';
import type { InquiryType } from 'modules/Inquiry/Inquiry.type';

/*
 * Use this inside the inquiry process
 */

type ComponentMap = Partial<Record<InquiryType, any>> & { default?: any };

export const chooseSelectedInquiryTypeSpecificComponent =
  (componentMap: ComponentMap) => (props: any) => {
    const { selectedInquiryType } = useFormConfig();
    const SelectedComp =
      componentMap[selectedInquiryType] === undefined
        ? componentMap.default
        : componentMap[selectedInquiryType];
    if (!SelectedComp) {
      return null;
    }
    return <SelectedComp {...props} />;
  };

type ValueMap<T> = Partial<Record<InquiryType, T>> & { default: T };

export const useSelectedInquiryTypeSpecificValue = <T,>(valueMap: ValueMap<T>): T => {
  const { selectedInquiryType } = useFormConfig();
  return valueMap[selectedInquiryType] ?? valueMap.default;
};

/*
 * Use this inside functions/services etc
 */
export const chooseSelectedInquiryTypeSpecificValue = <T,>(valueMap: ValueMap<T>): T => {
  const selectedInquiryType = ConfigController.form.getSelectedInquiryType();
  return valueMap[selectedInquiryType] ?? valueMap.default;
};
