import styled from 'styled-components/macro';

import Spinner from 'components/Spinner';

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  right: 4.5rem;
  left: auto;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.75rem;
  margin-bottom: 0;
  pointer-events: none;
`;

export const NoEntriesInAutocomplete = styled.div`
  padding: 1.5rem 0;
  text-align: center;
`;
