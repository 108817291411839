import { ProductKindType } from 'models/ProductKind.model';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { SourceType } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/AssociatedPersonMergeSections';
import { Solvency } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/TrafficLight/TrafficLights';

export enum ReportType {
  trafficLight = 'traffic_light_report',
  latestScoringShort = 'short_report',
  latestScoringCompact = 'compact_report',
  latestRating = 'rating_report',
  blackAndWhite = 'black_white_report',
}

export interface ICompanyBaseData {
  companyName: string;
  legalForm: string;
  foundingDate: string;
  registerNumber: string;
  districtCourt: string;
  street: string;
  zipCode: string;
  city: string;
  taxNumber: string;
  registerCity: string;
  taxVatNumber: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  website: string;
  companyStaff: number;
  companyTurnover: number;
  balanceSheet: string;
  iban: string;
  alreadyCustomer: boolean;
  detailedAnnualTurnover: number;
  countOfEmployees: number;
  debtServiceAbility: number;
  businessPurpose: string;
  industry: string[];
  legalEntityIdentifier: string;
}

export interface IAssociatedPerson {
  id: string;
  type: 'legal_representatives' | 'beneficiary_owners';
  firstName: string;
  lastName: string;
  address?: string;
  city?: string;
  zipCode?: string;
  birthDate: Date;
  birthPlace?: string;
  role: string;
  powerOfRepresentation: string;
  source: SourceType;
  successfullyMerged: boolean;
  phoneNumber: string;
  salutation: string;
  email: string;
  taxId?: string;
  ownerType?: string;
  scope?: string;
  companyShare?: string;
  country?: string;
}

export interface ICompanyInquiries {
  id: string;
  createdAt: Date;
  productKind: ProductKindType;
  financingAmount: number;
  status: INQUIRY_STATUSES;
}

interface IRiskAnalysisReport {
  createdAt: string;
}

/* eslint-disable camelcase */

export interface ITrafficLightReportApi {
  type: ReportType.trafficLight;
  attributes: {
    created_at: string;
    data: {
      traffic_light_solvency: {
        color: Solvency;
        probability_of_default_range: string;
      };
    };
  };
}

export interface IShortReportApi {
  type: ReportType.latestScoringShort;
  attributes: {
    created_at: string;
    data: {
      solvency_finance_industry: {
        class: string;
        pd_range: string;
      };
    };
  };
}

export interface ICompactReportApi {
  type: ReportType.latestScoringCompact;
  attributes: {
    created_at: string;
    data: {
      solvency_index: {
        two_zero: number;
        meaning: string;
        due_to: number;
      };
    };
  };
}

export interface IRatingReportApi {
  type: ReportType.latestRating;
  attributes: {
    created_at: string;
    data: {
      rating_class: string;
    };
  };
}

export interface IBlackWhiteReportApi {
  type: ReportType.blackAndWhite;
  attributes: {
    created_at: string;
    data: {
      riskjudgement: {
        color: string;
        meaning: string;
      };
    };
  };
}
/* eslint-enable camelcase */

export type RiskAnalysisReports = Array<
  | ITrafficLightReportApi
  | IShortReportApi
  | ICompactReportApi
  | IRatingReportApi
  | IBlackWhiteReportApi
>;

export type Report =
  | ITrafficLightReport
  | IShortReport
  | ICompactReport
  | IRatingReport
  | IBlackWhiteReport;

export interface ITrafficLightReport extends IRiskAnalysisReport {
  type: ReportType.trafficLight;
  trafficLightSolvency: {
    color: Solvency;
    probabilityOfDefaultRange: string;
  };
}
export interface IShortReport extends IRiskAnalysisReport {
  type: ReportType.latestScoringShort;
  solvencyFinanceIndustry: {
    class: string;
    pdRange: string;
  };
}
export interface ICompactReport extends IRiskAnalysisReport {
  type: ReportType.latestScoringCompact;
  solvencyIndex: {
    solvencyIndexValue: number;
    solvencyIndexExplanation: string;
    dueToSolvencyIndex: number;
  };
}

export interface IRatingReport extends IRiskAnalysisReport {
  type: ReportType.latestRating;
  rating: string;
}

export interface IBlackWhiteReport extends IRiskAnalysisReport {
  type: ReportType.blackAndWhite;
  createdAt: string;
  riskJudgement: {
    color: 'black' | 'white';
    meaning: string;
  };
}

export type Reports = Array<ITrafficLightReport | IShortReport | ICompactReport | IRatingReport>;

export interface ICompanyDetailsState {
  id: string;
  baseData: ICompanyBaseData;
  associatedPersons: IAssociatedPerson[];
  inquiries: ICompanyInquiries[];
  riskAnalysis?: Reports;
}
