import { associationInformationSectionValidationMap } from 'dzb/inquiry/steps/companyData/sections/associationInformationSection/validations';
import { companyInformationSectionValidationMap } from 'dzb/inquiry/steps/companyData/sections/companyInformationSection/validations';
import { directDebitMandateSectionValidationMap } from 'dzb/inquiry/steps/companyData/sections/directDebitMandateSection/validations';
import { startUpQuestionnaireSectionValidationMap } from 'dzb/inquiry/steps/companyData/sections/startUpQuestionnaireSection/validations';

export const dzbCompanyDataFieldValidation = {
  ...associationInformationSectionValidationMap,
  ...companyInformationSectionValidationMap,
  ...directDebitMandateSectionValidationMap,
  ...startUpQuestionnaireSectionValidationMap,
};
