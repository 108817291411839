import skrollTop from 'skrolltop';

import { TOP_BAR_HEIGHT } from 'constants/globalConstants';

export const scrollTop = (duration = 500) => {
  window.onwheel = () => false;

  skrollTop.scrollTo({
    element: window,
    to: 0,
    duration,
    callback: () => {
      window.onwheel = () => true;
    },
  });
};

export const scrollTo = (element, to, duration) => {
  window.onwheel = () => false;

  skrollTop.scrollTo({
    element,
    to,
    duration,
    callback: () => {
      window.onwheel = () => true;
    },
  });
};

export const scrollToRef = (ref) =>
  skrollTop.scrollTo({ to: ref.current.offsetTop - TOP_BAR_HEIGHT });
