import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { salutation, firstName, lastName, email, phoneNumber } =
  hausbankFields.personalDataPage.contactPersonSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.personalData.sections.contactPerson;

export const contactPersonSectionLabelsMap: LabelsMap = {
  [salutation]: fieldTranslations.salutation.caption,
  [firstName]: fieldTranslations.firstName.caption,
  [lastName]: fieldTranslations.lastName.caption,
  [email]: fieldTranslations.email.caption,
  [phoneNumber]: fieldTranslations.phoneNumber.caption,
};
