import React, { useEffect, useState } from 'react';

import {
  Slider as ChakraSlider,
  SliderTrack,
  SliderMark,
  SliderFilledTrack,
  SliderThumb,
  BoxProps,
  Box,
} from '@chakra-ui/react';

import { usePartnerConfig } from 'config/partner/hooks';

import { LeftMarkerWrapper, RightMarkerWrapper } from './styles';
import { SliderProps } from './types';
import FieldWrapper from '../ControlWrapper';

const sliderStyles: BoxProps = {
  marginTop: '20px',
};

const tooltipStyles: BoxProps = {
  position: 'absolute',
  bottom: '17px',
};

export const Slider = ({
  name,
  caption,
  defaultValue = 50,
  colorScheme,
  max = 100,
  min = 0,
  onChange,
  onChangeEnd,
  size,
  focusThumbOnChange,
  variant,
  step,
}: SliderProps) => {
  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
    // This effect only sets initial value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValueChanged = (value: number): void => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };
  const {
    meta: { color },
  } = usePartnerConfig();
  return (
    <FieldWrapper caption={caption}>
      <Box {...sliderStyles}>
        <ChakraSlider
          name={name}
          defaultValue={defaultValue}
          colorScheme={colorScheme}
          max={max}
          min={min}
          onChange={onValueChanged}
          onChangeEnd={onChangeEnd}
          size={size}
          focusThumbOnChange={focusThumbOnChange}
          variant={variant}
          step={step}
        >
          <SliderTrack>
            <SliderFilledTrack bgColor={color} />
          </SliderTrack>
          <SliderThumb
            children={
              <Box {...tooltipStyles}>
                <Box as="span" color="brand.default">
                  {value}
                </Box>
              </Box>
            }
          />
          <RightMarkerWrapper>
            <SliderMark value={25} mt="1" fontSize="medium">
              {max}
            </SliderMark>
          </RightMarkerWrapper>
          <LeftMarkerWrapper>
            <SliderMark value={50} mt="1" ml="-2.5" right="0px" fontSize="medium">
              {min}
            </SliderMark>
          </LeftMarkerWrapper>
        </ChakraSlider>
      </Box>
    </FieldWrapper>
  );
};
