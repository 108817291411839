import { createContext, Dispatch } from 'react';

import {
  AnyInitialStateType,
  AnyInquiryProcessConfig,
  AnyPageStateActions,
} from 'new/form/state/types';

export const InquiryConfigContext = createContext<
  | {
      state: AnyInitialStateType;
      dispatch: Dispatch<AnyPageStateActions>;
      formStateData: AnyInquiryProcessConfig;
    }
  | undefined
>(undefined);
