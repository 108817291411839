import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Container } from 'components/PortalPage/styles';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import PERMISSIONS from 'constants/user/permissions';
import { ICompanyBaseData } from 'models/CompanyDetails.model';
import CompanyOverviewSummary from 'pages/operationPortal/CompaniesDetails/CompanyOverview/CompanyOverviewSummary';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { AccordionComponent } from 'theme/components/Accordion';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getCompanyDetails } from '../store/selectors';

const printCompanyAddress = (baseData?: ICompanyBaseData) =>
  `${baseData?.street} | ${baseData?.zipCode} ${baseData?.city}`;

const CompanyOverview: React.FC = () => {
  const t = useTranslations();
  const companyDetails = useSelector(getCompanyDetails);

  return (
    <Container>
      <Box pt="12" pb="20">
        <HeadingComponent as="h2" variant="primary" mb={8} data-testid="CD-Overview-Heading">
          {t('pages.companiesDetails.overview.title')}

          <TextComponent
            as="span"
            display="block"
            fontWeight={FONT_WEIGHT_REGULAR}
            color="text.tertiary"
          >
            {companyDetails.companyName}
          </TextComponent>
        </HeadingComponent>

        <AccordionComponent
          headingLevel="h3"
          mb={8}
          title={companyDetails.companyName}
          text={printCompanyAddress(companyDetails)}
        >
          <CompanyOverviewSummary />
        </AccordionComponent>
      </Box>
    </Container>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(CompanyOverview);
