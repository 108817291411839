import PropTypes from 'prop-types';

import { FIELD_VALUE_TYPES } from 'modules/FormGenerator/constants';

export const fieldPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(FIELD_VALUE_TYPES),
  validation: PropTypes.string,
  validationMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.any }),
  ),
  placeholder: PropTypes.string,
  value: PropTypes.any,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
});

export const FieldTypeComponentProps = {
  field: fieldPropType.isRequired,
  onChange: PropTypes.func,
};
