import React from 'react';

import PERMISSIONS from 'constants/user/permissions';
import { USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR } from 'constants/user/userRoles';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import BankAdvisorOffers from 'modules/Offers/InquiryOffersSection/BankAdvisorOffers';
import CompeonOffers from 'modules/Offers/InquiryOffersSection/CompeonOffers';
import { InquiryOffersProvider as OffersProvider } from 'modules/Offers/InquiryOffersSection/context';
import {
  StyledInquiryOffers as SectionWrapper,
  StyledOffersDescription as Steps,
  StyledOffersDescriptionWrapper as Description,
  StyledOffersLead as Lead,
} from 'modules/Offers/InquiryOffersSection/styles';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { hasPermission as hasPermissionCreator } from 'utils/user/conditionals';

const useCanSeeInquiryOffers = () => {
  const areOffersVisibleForOP = useConfig(CONFIG.ARE_OFFERS_FOR_OP_VISIBLE);
  const hasPermission = hasPermissionCreator();
  const permissions = areOffersVisibleForOP
    ? [...PERMISSIONS.INQUIRY.DETAILS.OFFERS, USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR]
    : PERMISSIONS.INQUIRY.DETAILS.OFFERS;
  return hasPermission(permissions);
};

const InquiryOffersSection = () => {
  const t = useTranslations('pages.inquiryDetails.offers');
  const { canShowCompeonOffers } = useInquiryPermissions();

  const hasPermissionForOffers = useCanSeeInquiryOffers();
  if (!hasPermissionForOffers) {
    return null;
  }

  return (
    <SectionWrapper>
      <Section title={t('title')}>
        <Description>
          <Lead>{t('lead')}</Lead>
          <Steps>{t('description')}</Steps>
        </Description>
        <OffersProvider>
          {canShowCompeonOffers ? <CompeonOffers /> : <BankAdvisorOffers />}
        </OffersProvider>
      </Section>
    </SectionWrapper>
  );
};

export default InquiryOffersSection;
