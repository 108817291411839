import {
  SALES_PERSON,
  CAR_DEALER_NAME,
  VEHICLE_MANUFACTURER,
  VEHICLE_KIND,
  VEHICLE_DRIVE_TYPE,
  YEARLY_MILEAGE,
  LEASING_DURATION,
  LEASING_INSTALLMENT,
  ACCOUNT_OWNER,
  IBAN,
  VEHICLE_MODEL,
} from 'modules/Inquiry/Form/formFields';

export const contractDetailsLabelsMap: { [key: string]: string } = {
  [SALES_PERSON]: 'pages.contractDetails.sections.dealer.fields.salesPerson.caption',
  [CAR_DEALER_NAME]: 'pages.contractDetails.sections.dealer.fields.dealerName.caption',

  [VEHICLE_MANUFACTURER]: 'pages.contractDetails.sections.car.fields.manufacturerAndModel.caption',
  [VEHICLE_KIND]: 'pages.contractDetails.sections.car.fields.typeOfDrive.caption',
  [LEASING_DURATION]:
    'pages.contractDetails.sections.leasingConditions.fields.durationInMonths.caption',
  [LEASING_INSTALLMENT]:
    'pages.contractDetails.sections.leasingConditions.fields.leasingRate.caption',
  [YEARLY_MILEAGE]: 'pages.contractDetails.sections.leasingConditions.fields.yearlyMileage.caption',

  [ACCOUNT_OWNER]: 'pages.contractDetails.sections.accountInformation.fields.accountOwner.caption',
  [IBAN]: 'pages.contractDetails.sections.accountInformation.fields.iban.caption',
  [VEHICLE_MODEL]: '',
  [VEHICLE_DRIVE_TYPE]: 'pages.contractDetails.sections.car.fields.typeOfDrive.caption',
};
