export enum INQUIRY_ATTRIBUTES {
  status = 'status',
  welcomeCallPerformedAt = 'welcome_call_performed_at',
  updatedAt = 'updated_at',
  substatus = 'substatus',
  sentToCompeonAt = 'sent_to_compeon_at',
  kfwProgram = 'kfw_program',
  insuranceOfferRequestedAt = 'insurance_offer_requested_at',
  externalReference = 'external_reference',
  inquiryManagerId = 'inquiry_manager_id',
  compeonStatus = 'compeon_status',
  compeonId = 'compeon_id',
  customerId = 'customer_id',
  inquiryId = 'id',
  company = 'company_id',
}
