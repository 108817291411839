import { mittweidaFields } from 'mittweida/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

import { roleSelectOptions } from './options/roleOptions';

const { projectFinancingRole } = mittweidaFields.financingNeedPage.roleSection;

export const roleSectionLabels: LabelsMap = {
  [projectFinancingRole]:
    translations.inquiryType.mittweida.pages.financingNeed.sections.role.fields.projectFinancingRole
      .caption,
};

export const roleValueTranslationMap = {
  [projectFinancingRole]: roleSelectOptions,
};
