import styled, { css } from 'styled-components/macro';

import { InputCaption } from 'components/Text';

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const StyledLabelAndFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ horizontalLayout, tooltip, isCheckBox }) => {
    const style = `${InputCaption} {
      margin-right: 1rem;
      margin-bottom: 0;
    }`;

    if (horizontalLayout) {
      return css`
        flex-direction: row;
        ${style}
      `;
    } else if (tooltip && isCheckBox) {
      return css`
        flex-direction: row-reverse;
        justify-content: start;
        ${style}
      `;
    }
  }}
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;
`;

export const StyledCaptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
