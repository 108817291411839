import React from 'react';

import { useLoadInquiriesList } from 'api/CompeonReverseApi/operation/queryHooks';
import { SelectWithField } from 'components/Selects/Select';
import { useInquiriesListContext } from 'modules/InquiriesListProvider';
import { INQUIRY_STATUSES, useInquiryStatusTranslator } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { StyledFormRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const StatusFilter = () => {
  const { tableOptions } = useInquiriesListContext();
  const translateStatus = useInquiryStatusTranslator();
  const { data } = useLoadInquiriesList({
    variables: { options: tableOptions },
  });
  const t = useTranslations();
  const { optional } = useFieldValidators();

  const statusesOptions =
    data?.statuses.map(({ status }: { status: INQUIRY_STATUSES }) => ({
      value: status,
      label: translateStatus(status),
    })) || [];

  const allState = {
    value: 'all',
    label: translateStatus(INQUIRY_STATUSES.ALL),
  };
  return (
    <StyledFormRow>
      <SelectWithField
        name={INQUIRY_FILTERS.STATUS}
        validate={optional}
        caption={t('pages.inquiryList.filtersDrawer.fields.status.caption')}
        options={[allState, ...statusesOptions]}
        placeholder={t('placeholders.pleaseChoose')}
        isClearable
        data-testid={'select-field-' + INQUIRY_FILTERS.STATUS}
      />
    </StyledFormRow>
  );
};

export default StatusFilter;
