import _get from 'lodash/get';

import {
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
} from 'modules/Inquiry/Form/formFields';

const mapMwMarketValueFromApi = (response: unknown) => {
  const current = _get(response, 'attributes.details.current');
  const future = _get(response, 'attributes.details.future');

  const marketValue = {
    [CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM]: _get(current, 'building.residential_rent_per_sqm', 0),
    [CURRENT_USAGE_EXISTING_RENT_PER_SQM]: _get(current, 'building.existing_rent_per_sqm', 0),
    [CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM]: _get(current, 'building.commercial_rent_per_sqm', 0),
    [CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING]: _get(
      current,
      'building.comparative_value_per_sqm',
      0,
    ),
    [CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT]: _get(current, 'unit.comparative_value_per_sqm', 0),
    [FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM]: _get(future, 'building.residential_rent_per_sqm', 0),
    [FUTURE_USAGE_EXISTING_RENT_PER_SQM]: _get(future, 'building.existing_rent_per_sqm', 0),
    [FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM]: _get(future, 'building.commercial_rent_per_sqm', 0),
    [FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING]: _get(
      future,
      'building.comparative_value_per_sqm',
      0,
    ),
    [FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT]: _get(future, 'unit.comparative_value_per_sqm', 0),
  };

  return marketValue;
};

export default mapMwMarketValueFromApi;
