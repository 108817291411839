export const setStatusSingleOfferInList = (offers, acceptedOfferId, status) => {
  return offers.map((offer) => {
    if (offer.id === acceptedOfferId) {
      return {
        ...offer,
        status,
      };
    }
    return offer;
  });
};
