import {
  USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND,
  USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION,
  USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION,
  USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION,
  USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION,
  USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION,
} from 'modules/Inquiry/Form/formFields';

export const getTurnoverClass = (numberOfLifts: number) => {
  const turnoverClassValue = numberOfLifts * 250000;

  switch (true) {
    case turnoverClassValue < 250000:
      return USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND;
    case turnoverClassValue === 250000:
      return USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND;
    case turnoverClassValue === 500000:
      return USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND;
    case turnoverClassValue <= 1000000:
      return USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND;
    case turnoverClassValue <= 2500000:
      return USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION;
    case turnoverClassValue <= 5000000:
      return USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION;
    case turnoverClassValue <= 10000000:
      return USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION;
    case turnoverClassValue <= 25000000:
      return USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION;
    case turnoverClassValue <= 50000000:
      return USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION;
    case turnoverClassValue <= 100000000:
      return USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION;
    case turnoverClassValue > 100000000:
      return USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION;
    default:
      return '';
  }
};
