import React from 'react';

import StaticField from 'components/StaticField';
import { store } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getCurrentUser } from 'utils/user/getters';

const GenderStaticField = () => {
  const user = getCurrentUser(store.getState());
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender',
  );
  return <StaticField caption={t('caption')} text={t(`genders.${user.gender}`)} />;
};

export default GenderStaticField;
