import _isEmpty from 'lodash/isEmpty';

import { removeObjectValues } from 'utils/helpers';
/**
 * In react-final-form array fields are not treated as empty arrays [] but as an array with an empty object [{}].
 * Thus the length of the array is always at least 1 and should never be 0, so we make the check for the length and if the
 * first object is empty as well.
 * @param values
 */

export function isArrayFieldEmpty(arr: any[]): boolean {
  if (arr.length === 0) {
    return true;
  }

  for (const item of arr) {
    const objectWithoutUndefined = removeObjectValues(item, (value: any) => value === undefined);
    if (!_isEmpty(objectWithoutUndefined)) {
      return false;
    }
  }

  return true;
}
