import React, { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { SelectWithField } from 'components/Selects/Select';
import { NewStaticFieldWithField } from 'components/StaticField';
import { dzbFields, DzbFieldTypes } from 'dzb/inquiry/fields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { typeOfFinancialStatements } = dzbFields.companyDataPage.companyInformationSection;

export const TypeOfFinancialStatementSelect = () => {
  const typeOfFinancialStatementsOptions = useInquirySelectOptions(typeOfFinancialStatements);
  const t = useTranslations();
  const {
    values: {
      companyDataPage: {
        companyInformationSection: { country },
      },
    },
  } = useFormState<DzbFieldTypes>();
  const { change } = useForm();
  const isFrenchCompany = country === 'fr';

  useEffect(() => {
    if (isFrenchCompany) {
      change(typeOfFinancialStatements, 'balance');
    }
  }, [isFrenchCompany, country, change]);

  if (isFrenchCompany) {
    return <NewStaticFieldWithField name={typeOfFinancialStatements} value={'balance'} />;
  }

  return (
    <SelectWithField
      name={typeOfFinancialStatements}
      options={typeOfFinancialStatementsOptions}
      placeholder={t(translations.placeholders.pleaseChoose)}
      data-testid={'dzb-type-of-financial-statement'}
    />
  );
};
