import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import {
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__SOURCE,
  FURTHER_NEED,
  FURTHER_NEED_VALUES,
  OFFER_MONTHLY_INSTALLMENT,
  PARTNER_SPECIFIC_IBAN,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { OnlinefactoringLeadDescription } from 'pages/inquiryFlow/SummaryPage/RequestDetailsAdditionalFields';
import {
  getIndicativeConditionCompleted,
  getIndicativeOffer,
} from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../DetailedInformationSection';
import InformationRows, { InformationField } from '../InformationRows/InformationRows';
import useInquiryDetailsFields, {
  useInquiryValueParserForDisplay,
} from '../useInquiryDetailsFields';

// TODO: CREATE A SEPERATE HOOK THAT RETURNS ONLY INCLUDED FIELDS
const filterAllExceptIncluded = (fields: InformationField[], includedFieldKeys: string[]) =>
  includedFieldKeys.reduce((prev: InformationField[], curr) => {
    const foundField = fields.find((field) => field.key === curr);
    if (foundField) {
      return [...prev, foundField];
    }
    return prev;
  }, []);

const FinancingOfferSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');

  const labelTranslator = useInquiryLabelTranslator();
  const formatValue = useInquiryValueParserForDisplay();

  const isOfferRecieved = useSelector(getIndicativeConditionCompleted);

  const offer = useSelector(getIndicativeOffer)! || {};
  delete offer[OFFER_MONTHLY_INSTALLMENT];

  const offerFields = Object.entries(offer)
    .filter(([, fieldValue]) => !(fieldValue == null || fieldValue === ''))
    .map(([fieldKey, fieldValue]) => ({
      label: labelTranslator(fieldKey),
      value: formatValue(fieldValue, fieldKey),
      key: fieldKey,
    }));

  let requestFields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.COMPANY,
    includeFields: [
      FURTHER_NEED,
      FURTHER_NEED_VALUES,
      BFS_SERVICE__FACTORING_OFFER,
      BFS_SERVICE__SOURCE,
    ],
  });

  const requestFieldsToUse = [
    PARTNER_SPECIFIC_IBAN,
    FURTHER_NEED,
    FURTHER_NEED_VALUES,
    BFS_SERVICE__FACTORING_OFFER,
    BFS_SERVICE__SOURCE,
  ];

  requestFields = filterAllExceptIncluded(requestFields, requestFieldsToUse);

  const fields = [...offerFields, ...requestFields];

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('financingOffer')}>
      <Condition condition={!!isOfferRecieved} fallback={<OnlinefactoringLeadDescription />}>
        <InformationRows fields={fields} />
      </Condition>
    </Section>
  );
};

export default FinancingOfferSection;
