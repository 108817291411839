/*
 * Purpose Step --> BE parameters in comments
 */
export const PURPOSE_STEP = 'purpose'; // factoring_sales_gross
export const VOLUME_OF_SALES = 'volume-of-sales'; // factoring_business_customers_share
export const SHARE_OF_SALES_BUSINESS_CUSTOMERS = 'share-of-sales-business-customers'; // factoring_main_customers_share
export const SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP = 'share-of-sales-business-customers-step'; // share_of_sales_business_customers
export const SHARE_OF_SALES_MAIN_CUSTOMERS = 'share-of-sales-main-customers'; // factoring_foreign_customers_share
export const SHARE_OF_SALES_FOREIGN_CUSTOMERS = 'share-of-sales-foreign-customers'; // factoring_foreign_customers_share
export const NUMBER_OF_CUSTOMERS = 'number-of-customers'; // factoring_active_customers
export const NUMBER_OF_INVOICES = 'number-of-invoices'; // factoring_number_of_invoices
export const PAYMENT_TERM = 'payment-term'; // factoring_standard_payment_term
export const ALREADY_FACTORING = 'already-factoring'; // factoring_already_in_use
export const RECEIVABLES_ASSIGNED_ELSEWHERE = 'receivables-assigned-elsewhere'; // factoring_receivables_assigned
export const DOWN_PAYMENT = 'down-payment'; // factoring_issue_payment_or_progress_invoices
export const TRADE_CREDIT_INSURANCE = 'trade-credit-insurance'; // factoring_trade_credit_insurance_taken

export const NUMBER_OF_LIFTS = 'number-of-lifts'; // number_of_lifts
export const MASTER_CRAFTSMAN = 'company-master-craftsman'; // company_master_craftsman
export const PERFORM_TUV_EXAMINATIONS = 'perform-tuv-examinations'; // perform_tuv_examinations
export const SHARE_OF_INSURANCE_CLAIMS = 'share-of-insurance-claims'; // share_of_insurance_claims
export const PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE = 'percentage-of-services-paid-by-invoice'; // percentage_of_services_paid_by_invoice
export const PERCENTAGE_OF_INVOICES_FOR_SELL = 'percentage-of-invoices-for-sell'; // "percentage_of_invoices_for_sell"
export const INVOICES_PER_YEAR = 'invoices-per-year'; // "invoices_per_year"
export const PAYMENT_TERMS = 'payment-terms'; // "payment_terms"
export const BUSINESS_SPECIFICS = 'business-specifics'; // "business_specifics"
export const ALREADY_ASSIGNED = 'already-assigned'; // "receivables_assigned"
export const FACTORING_LINE = 'factoring_line';
