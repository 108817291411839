import React, { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { ConditionPropsFor } from 'components/Condition/types';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';

type InquiryTypeCases = {
  [key in InquiryType]?: ReactNode;
} & {
  default: ReactNode;
};

/*
  This component decides on what to display based on inquiry type
*/
const InquiryTypeCondition = ({ cases, portal }: ConditionPropsFor<InquiryTypeCases>) => {
  const { selectedInquiryType } = useFormConfig(); // Use this inside the inquiry process
  const portalInquiryType = useSelector(getInquiryFormTypeSelector); // Use this inside customer portal or operations portal
  const inquiryType = portal ? portalInquiryType : selectedInquiryType;
  const condition = !!inquiryType && Object.prototype.hasOwnProperty.call(cases, inquiryType);
  return (
    <Condition condition={condition} fallback={cases.default}>
      {inquiryType && cases[inquiryType]}
    </Condition>
  );
};

export default InquiryTypeCondition;
