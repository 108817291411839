import React from 'react';

import { Switch, Redirect, useParams } from 'react-router-dom';

import Condition from 'components/Condition';
import PageTitle from 'components/PageTitle/PageTitle';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import ErrorBoundary from 'modules/ErrorBoundary';
import AssociatedPerson from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/AssociatedPerson';
import AssociatedPersonMerge from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/AssociatedPersonMerge';
import CompanyNavigation from 'pages/operationPortal/CompaniesDetails/CompanyNavigation';
import CompanyOverview from 'pages/operationPortal/CompaniesDetails/CompanyOverview/CompanyOverview';
import FetchCompanyDetails from 'pages/operationPortal/CompaniesDetails/FetchCompanyDetails';
import FinancialInformation from 'pages/operationPortal/CompaniesDetails/FinancialInformation/FinancialInformation';
import RiskAnalysis from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/RiskAnalysis';
import OperationPrivateRoute from 'routes/OperationPrivateRoute';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledCompanyPage } from './styles';

const { operation: operationPaths } = paths;

const CompanyDetails = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const t = useTranslations();

  return (
    <StyledCompanyPage>
      <PageTitle title={t('pages.companiesDetails.title')} />
      <ErrorBoundary>
        <FetchCompanyDetails companyId={companyId}>
          <CompanyNavigation />
          <Switch>
            <OperationPrivateRoute
              exact
              path={operationPaths.companies.overview()}
              component={CompanyOverview}
            />
            <OperationPrivateRoute
              exact
              path={operationPaths.companies.associatedPersons()}
              component={AssociatedPerson}
            />
            <OperationPrivateRoute
              exact
              path={operationPaths.companies.associatedPersonsMerge()}
              component={AssociatedPersonMerge}
            />
            <OperationPrivateRoute
              exact
              path={operationPaths.inquiryDetails.companyDetails.associatedPersonsMerge()}
              component={AssociatedPersonMerge}
            />
            <OperationPrivateRoute
              exact
              path={operationPaths.companies.riskAnalysis()}
              component={RiskAnalysis}
            />
            <OperationPrivateRoute
              exact
              path={operationPaths.companies.financialInformation()}
              component={FinancialInformation}
            />
            <OperationPrivateRoute
              exact
              path={operationPaths.companies.details()}
              component={() => <Redirect to={operationPaths.companies.overview(companyId)} />}
            />
          </Switch>
        </FetchCompanyDetails>
      </ErrorBoundary>
    </StyledCompanyPage>
  );
};

const withCompanies = !!getEnvironmentFeatureFlag(EnvironmentVariable.COMPANIES_LIST);

const DisplayCondition = () => {
  return (
    <Condition condition={withCompanies} fallback={<Redirect to={paths.operation.inquiryList} />}>
      <CompanyDetails />
    </Condition>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(DisplayCondition);
