import { API_METHODS, callCompeonApi } from 'api/apiService';
import endpoints from 'api/CompeonApi/endpoints';
import { success } from 'utils/actionTypesHelpers';

export const LOAD_BANKS = 'LOAD_BANKS';
export const LOAD_BANKS_SUCCESS = success(LOAD_BANKS);
const DEFAULT_BANK_CODES_PER_PAGE = 100;

export const getFilters = (page?: number, name?: string, code?: string) => ({
  page: {
    number: page,
    size: DEFAULT_BANK_CODES_PER_PAGE,
  },
  filter: {
    name,
    code,
  },
});

export const loadBanksAction = (page?: number, name?: string, code?: string) =>
  callCompeonApi({
    url: endpoints.BANKS.compose({
      query: getFilters(page, name, code),
    }),
    method: API_METHODS.GET,
    actionName: LOAD_BANKS,
  });
