import { hausbankFields } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFields, ValidateSectionFunction } from 'new/form/useValidateFields';
import { isLoggedInUser } from 'utils/user/conditionals';

const { password, passwordConfirmation } = hausbankFields.personalDataPage.passwordSection;
export const validatePasswordSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  if (isLoggedInUser()) {
    return true;
  }

  return validateFields({
    fields: [password, passwordConfirmation],
    form,
    values,
    conditions,
  });
};
