import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import paths from 'constants/paths';
import { RLLComputedProfitabilityPropertyDetails } from 'models/types/Profitability.type';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { EDIT_INQUIRY_CALLER_COMPONENT_NAME } from 'store/applicationConfig/types';
import { useTranslations } from 'utils/hooks/useTranslations';
import { saveObjectToSessionStorage } from 'utils/sessionStorage/helpers';
import { APPLICATION_CONFIG } from 'utils/sessionStorage/keys';

import { getData, getHeaders } from './module';
import TableCardWithEdit from '../../TableCardWithEdit';

interface FinancingDetailsProps {
  propertyDetails: RLLComputedProfitabilityPropertyDetails;
  inquiryId: string;
}

export const PropertyDetails: React.FC<FinancingDetailsProps> = ({
  propertyDetails,
  inquiryId,
}) => {
  const t = useTranslations();

  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(() => getData(t, propertyDetails), [t, propertyDetails]);

  const onEdit = () => {
    saveObjectToSessionStorage(APPLICATION_CONFIG, {
      [EDIT_INQUIRY_CALLER_COMPONENT_NAME]: PROFITABILITY_RECORD_TYPE.PROPERTY_DETAILS,
    });
  };

  return (
    <Box
      border="1px"
      borderColor="border.lightGrey"
      p="8"
      bgColor="background.white"
      boxShadow="default"
      h="100%"
    >
      <TableCardWithEdit
        canEdit
        componentName={PROFITABILITY_RECORD_TYPE.PROPERTY_DETAILS}
        editLink={paths.operation.inquiryDetails.edit.root.replace(':id', inquiryId!)}
        onEdit={onEdit}
        tableData={data}
        tableHeaders={headers}
        title={t(
          'pages.planningEvaluation.profitabilityCalculation.sections.propertyDetails.title',
        )}
      />
    </Box>
  );
};
