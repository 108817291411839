import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import LeasingIcon from 'theme/components/Icon/ProductKind/LeasingIcon';

import LeasingAdvancePayment from './fields/LeasingAdvancePayment';
import LeasingTermInMonths from './fields/LeasingTermInMonths';

const LeasingSummary = ({ hideAdvancePayment = false }) => {
  return (
    <>
      <FormRow separator>
        <FinancingAmountSummary icon={<LeasingIcon />} />
      </FormRow>
      <FormRow separator>
        <LeasingAdvancePayment />
        <LeasingTermInMonths />
      </FormRow>
    </>
  );
};

export default LeasingSummary;
