import React, { FC } from 'react';

import {
  DataListLabel,
  StyledCaption,
} from 'pages/inquiryFlow/Rentenbank/SubsidyForestryCompanies/SubjectList/styles';

interface IDataListItem {
  header?: string;
  caption?: string;
  fixedOption?: boolean;
}

const DataListItem: FC<IDataListItem> = ({ header, caption, fixedOption }: IDataListItem) => {
  return (
    <DataListLabel fixedOption={fixedOption}>
      {header && <div>{header}</div>}
      {caption && <StyledCaption>{caption}</StyledCaption>}
    </DataListLabel>
  );
};

export default DataListItem;
