import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { getRealEstateExpertsAction } from 'api/CompeonReverseApi/operation/loadRealEstateExperts/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const mapRealEstateFromAPI = (realEstateExperts: any) =>
  realEstateExperts.map(({ id, type, attributes }: any) => ({
    id,
    type,
    // maps attributes from snake_case to camelCase
    ..._.mapKeys(attributes, (value, key) => _.camelCase(key)),
  }));

const useRealEstateExperts = () => {
  const { makeCall, isPending } = useDispatchApiCall({ isPendingInitially: true });
  const [realEstateExperts, setRealEstateExperts] = useState([]);

  const getRealEstateExperts: any = useCallback(async () => {
    const response = await makeCall(getRealEstateExpertsAction());
    const realEstateExperts = response.payload.data.data;
    return setRealEstateExperts(mapRealEstateFromAPI(realEstateExperts));
  }, [makeCall]);

  useEffect(() => {
    getRealEstateExperts();
  }, [getRealEstateExperts]);

  return { isPending, realEstateExperts };
};

export default useRealEstateExperts;
