import styled from 'styled-components/macro';

import { mqSmall, mqMedium, mqLarge } from 'styles/breakpoints';

const Badges = styled.div`
  display: none;

  ${mqSmall`
    display: flex;

    > :not(:last-child) {
      margin-right: 1rem;
    }
  `};

  ${mqMedium`
    > :not(:last-child) {
      margin-right: 2rem;
    }
  `};

  ${mqLarge`
    > :not(:last-child) {
      margin-right: 3rem;
    }
  `};
`;

export default Badges;
