import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { PermitBasis } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

const {
  surroundingDevelopment,
  approvedBPlanAvailable,
  bPlanArea,
  stockProperty,
  repurposingStillToRequest,
} =
  translations.inquiryType.mittweida.pages.financingNeed.sections.otherProjectInformation.fields
    .permitBasis.options;

export const permitBasisOptions: SelectOption<PermitBasis>[] = [
  {
    label: surroundingDevelopment,
    value: 'peripherial_development',
  },
  {
    label: approvedBPlanAvailable,
    value: 'approved_b_plan',
  },
  {
    label: bPlanArea,
    value: 'b_plan_area',
  },
  {
    label: stockProperty,
    value: 'stock_property',
  },
  {
    label: repurposingStillToRequest,
    value: 'repurposing_still_to_request',
  },
];

export const getMittweidaPermitBasisOptions = (
  formatMessage: FormatMessage,
): SelectOption<PermitBasis>[] => {
  return permitBasisOptions.map(({ label, value }) => {
    return {
      value,
      label: formatMessage({ id: label }),
    };
  });
};
