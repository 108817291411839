import React from 'react';

import { COMPANY_DETAILS_COMPANY__NOT_FOUND } from 'modules/Inquiry/Form/formFields';
import { useGetSelectedCompany } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';

import FillCompanyInformationForm from './FillCompanyInformationForm';
import StaticCompanyInformation from './StaticCompanyInformation';

const CompanyInformationForm = () => {
  const selectedCompany = useGetSelectedCompany();

  if (selectedCompany && selectedCompany !== COMPANY_DETAILS_COMPANY__NOT_FOUND) {
    return <StaticCompanyInformation company={selectedCompany} />;
  }

  return <FillCompanyInformationForm />;
};

export default CompanyInformationForm;
