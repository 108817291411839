import React from 'react';

import { useSelector } from 'react-redux';

import PurposeCondition from 'components/PurposeCondition';
import {
  PURPOSE_KIND,
  PURPOSE_KIND__CREFO_FACTORING,
  PURPOSE_KIND__KFINANZ,
} from 'modules/Inquiry/Form/formFields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows, {
  InformationField,
} from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import {
  ALREADY_FACTORING,
  DOWN_PAYMENT,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  PAYMENT_TERM,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
  TRADE_CREDIT_INSURANCE,
  VOLUME_OF_SALES,
} from 'onlinefactoring/formFields';
import { getInquiryPurposeKind } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import PurposeShareSection from './PurposeShareSection';

const PurposeSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const purposeKind = useSelector(getInquiryPurposeKind);
  const isKfinanzPurpose = purposeKind === PURPOSE_KIND__KFINANZ;

  const defaultFields: InformationField[] = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.FINANCING,
    excludedFields: [PURPOSE_KIND, SHARE_OF_SALES_BUSINESS_CUSTOMERS],
    includeFields: [
      VOLUME_OF_SALES,
      NUMBER_OF_CUSTOMERS,
      NUMBER_OF_INVOICES,
      PAYMENT_TERM,
      ALREADY_FACTORING,
      RECEIVABLES_ASSIGNED_ELSEWHERE,
      DOWN_PAYMENT,
      TRADE_CREDIT_INSURANCE,
    ],
  });

  const additionalFields: InformationField[] = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.ADDITIONAL_FIELDS,
    includeFields: [SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP],
  });

  const fields = isKfinanzPurpose ? [...additionalFields, ...defaultFields] : defaultFields;

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('needs')}>
      <InformationRows fields={fields} />
      <PurposeCondition
        portal
        cases={{
          [PURPOSE_KIND__CREFO_FACTORING]: <PurposeShareSection />,
          default: null,
        }}
      />
    </Section>
  );
};

export default PurposeSection;
