import { mittweidaFields } from 'mittweida/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  demolitionNecessary,
  heritageProtected,
  developmentNecessary,
  ownSalesPlanned,
  energyPass,
  buildingPermit,
  permitBasis,
  permitStatus,
  notesOnPermit,
} = mittweidaFields.financingNeedPage.otherProjectInformation;

const { fields: translations } =
  translationObject.inquiryType.mittweida.pages.financingNeed.sections.otherProjectInformation;

export const otherProjectInformationSectionLabelsMap: LabelsMap = {
  [demolitionNecessary]: translations.demolitionNecessary.caption,
  [heritageProtected]: translations.heritageProtected.caption,
  [developmentNecessary]: translations.developmentNecessary.caption,
  [ownSalesPlanned]: translations.ownSalesPlanned.caption,
  [energyPass]: translations.energyPass.caption,
  [buildingPermit]: translations.buildingPermit.caption,
  [permitBasis]: translations.permitBasis.caption,
  [permitStatus]: translations.permitStatus.caption,
  [notesOnPermit]: translations.notesOnPermit.caption,
};
