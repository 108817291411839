import React from 'react';

import { Form as FinalForm } from 'react-final-form';

import endpoints from 'api/CompeonReverseApi/endpoints';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import { IFile } from 'models/File.model';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  setUploadedFile: (file: IFile) => void;
  onSubmit: (id: string) => void;
  uploadedFile: IFile;
}

const UploadModalContent = ({ onSubmit, uploadedFile, setUploadedFile }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.submitOffer.modalUpload');
  const uploadTranslate = useTranslations('components.upload');

  const handleUpload = (file: IFile) => setUploadedFile(file);

  const handleFormSubmit = async () => {
    await onSubmit(uploadedFile.id);
  };

  return (
    <>
      <TextComponent mb={8}>{t('description')}</TextComponent>
      <FinalForm
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }) => (
          <form id="form-submit-offer" onSubmit={handleSubmit}>
            <UploadAnyFile
              description={uploadTranslate(uploadedFile ? 'fileUploaded' : 'notUploaded')}
              url={endpoints.FILES.LIST.compose()}
              onUpload={handleUpload}
            />
          </form>
        )}
      />
    </>
  );
};

export default UploadModalContent;
