import React from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import Condition from 'modules/ConditionalSubform/Condition';
import { isSectionValid } from 'modules/Inquiry/Form/formConditions';
import ObjectInformationSection, {
  validateObjectInformationSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/ObjectInformationSection';
import ProjectCompositionSection, {
  validateProjectCompositionSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ProjectComposition/ProjectCompositionSection';
import PropertyDocumentSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/PropertyDocuments/PropertDocumentSection';
import RoleSubsection, {
  validateRoleSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/RoleSubsection/RoleSubsection';

const ProjectFinancingDetails: React.FC = () => {
  const { selectedInquiryType } = useFormConfig();

  return (
    <>
      <RoleSubsection />

      <Condition condition={isSectionValid(validateRoleSection)}>
        <ProjectCompositionSection />
      </Condition>

      <Condition condition={isSectionValid(validateProjectCompositionSection)}>
        <ObjectInformationSection />
      </Condition>

      <Condition condition={isSectionValid(validateObjectInformationSection(selectedInquiryType))}>
        <PropertyDocumentSection />
      </Condition>
    </>
  );
};

export default ProjectFinancingDetails;
