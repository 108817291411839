import React from 'react';

import _isEmpty from 'lodash/isEmpty';
import _reject from 'lodash/reject';
import { Field } from 'react-final-form';

import FormRow from 'components/FormRow';
import AddTagSelect from 'components/Selects/AddTagSelect';
import Tag from 'components/Tag';
import TagSelect from 'components/TagSelect';
import { USER_PROFILE_INDUSTRY } from 'modules/Inquiry/Form/formFields';
import TagListWrapper from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/TagListWrapper';
import { useMultiSelectValues } from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/service';
import intlShape from 'shared/intlShape';
import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';
import { withIntl } from 'utils/intl';
import { nonEmptyArray } from 'utils/validators';

const Multiselect = ({ intl: { formatMessage } }) => {
  const multiSelectValues = useMultiSelectValues();

  return (
    <Field
      name={USER_PROFILE_INDUSTRY}
      validate={nonEmptyArray(formatMessage({ id: 'errors.required' }))}
      render={({ input, input: { onChange, value }, meta }) => {
        const selectedValues = Array.isArray(value) ? _reject(value, _isEmpty) : [value];
        return (
          <TagSelect
            onChange={onChange}
            selectedValues={selectedValues}
            options={multiSelectValues}
            loadOptionsOnMount={() => {}}
            render={({ add, remove, selectedOptions, options }) => (
              <div data-testid="industry-select-field">
                <FormRow>
                  <AddTagSelect
                    addTag={add}
                    options={options}
                    caption={formatMessage({
                      id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileIndustry.multiselectCaption',
                    })}
                    placeholder={formatMessage({ id: 'placeholders.pleaseChoose' })}
                    symbol={() => <CaretDownIcon boxSize={6} />}
                    {...input}
                    {...meta}
                  />
                </FormRow>
                <FormRow>
                  <TagListWrapper>
                    {selectedOptions.map((selectedValue) => (
                      <Tag
                        key={selectedValue.value}
                        onRemove={() => remove(selectedValue)}
                        label={selectedValue.label}
                      >
                        {selectedValue.label}
                      </Tag>
                    ))}
                  </TagListWrapper>
                </FormRow>
              </div>
            )}
          />
        );
      }}
    />
  );
};

Multiselect.propTypes = {
  intl: intlShape.isRequired,
};

export default withIntl(Multiselect);
