import { get as _get } from 'lodash';

import { hausbankFields, HausbankFieldTypes } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { getValidCompanyNameFieldName, hasExistingCompany } from 'utils/company';

const {
  selectedCompanyName: selectedCompanyNameFieldName,
  loggedInUserCompanyName: loggedInUserCompanyNameFieldName,
} = hausbankFields.companyDataPage.wegSearchSection;

export const validateWegDataCompletionSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  const companyNameFieldName = getValidCompanyNameFieldName<HausbankFieldTypes>(
    loggedInUserCompanyNameFieldName,
    selectedCompanyNameFieldName,
    values,
  );
  const companyName = _get(values, companyNameFieldName);
  const existingCompany = hasExistingCompany(companyName);

  if (existingCompany) {
    return validateFieldValidationMap({
      validationMap: existingCompanyValidationMap,
      form,
      values,
      conditions,
    });
  }
  return validateFieldValidationMap({
    validationMap: wegDataCompletionSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { legalForm, name, street, city, zipCode, managedUnits, foundingYear, isExistingCustomer } =
  hausbankFields.companyDataPage.wegDataCompletionSection;

const existingCompanyValidationMap = {
  [legalForm]: fieldValidators.string().required(),
  [managedUnits]: fieldValidators.number().required(),
  [foundingYear]: fieldValidators.string().required(),
  [isExistingCustomer]: fieldValidators.stringBoolean().required(),
};

export const wegDataCompletionSectionValidationMap = {
  ...existingCompanyValidationMap,
  [name]: fieldValidators.string().required(),
  [street]: fieldValidators.string().required(),
  [city]: fieldValidators.string().required(),
  [zipCode]: fieldValidators.postalCode().isValid().required(),
};
