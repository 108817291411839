import React from 'react';

import { PURPOSE_KIND } from 'modules/Inquiry/Form/formFields';
import { useFormValues } from 'store/hooks/useFormValues';

interface ComponentMap {
  [key: string]: React.FC;
}

export const choosePurposeKindSpecificComponent = (componentMap: ComponentMap) => (props: any) => {
  const formValues = useFormValues();
  const selectedPurposeKind: string = formValues[PURPOSE_KIND] as string;

  if (componentMap[selectedPurposeKind] === null) {
    return null;
  }
  const SelectedComp = componentMap[selectedPurposeKind] || componentMap.default;
  return <SelectedComp {...props} />;
};

export const usePurposeKindSpecificValue = (valueMap: { [x: string]: any }) => {
  const formValues = useFormValues();
  const selectedPurposeKind: string = formValues[PURPOSE_KIND] as string;
  if (valueMap[selectedPurposeKind] === null) {
    return null;
  }
  return valueMap[selectedPurposeKind] || valueMap.default || null;
};
