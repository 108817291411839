import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import { IComputedDetailsSourceOfFunds } from 'api/CompeonReverseApi/operation/types';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Condition from 'components/Condition';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import mapMittweidaProfitabilityToEdit from 'pages/operationPortal/PlanningEvaluation/EditMarketValuesReeProfitability/mapMittweidaProfitabilityToEdit';
import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import useEditFormConfig from 'pages/operationPortal/PlanningEvaluation/useEditFormConfig';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Card } from '../../components/Card';
import PortalTable from '../../components/PortalTable';
import usePortalTable from '../../hooks/usePortalTable';
import type { MappingProps } from '../../types';
import { getRowStylesMap, useUnitFormatter } from '../../utils';

interface SourceOfFundsItem {
  gak_loan: string;
  gak_equity: string;
  total_funds: string;
  loan_project: string;
  total_equity: string;
  equity_grants: string;
  equity_project: string;
  equity_release: string;
  total_gak_funds: string;
  financing_amount: string;
  total_project_funds: string;
  total_external_funds: string;
  gak_equity_share_buying_costs: string;
}

export interface SourceOfFundsProps {
  current: { sqm_costs: SourceOfFundsItem };
  future: { sqm_costs: SourceOfFundsItem };
  warnings: Array<string>;
  base_values: SourceOfFundsItem;
  percent_costs: SourceOfFundsItem;
  notes_on_financing_plan: string;
}

const mapping: MappingProps<SourceOfFundsItem> = [
  { key: 'gak_loan', unit: 'currency', styles: { bold: true } },
  { key: 'gak_equity', unit: 'currency', styles: { bold: true } },
  { key: 'gak_equity_share_buying_costs', unit: 'currency', styles: { bold: true } },
  { key: 'total_gak_funds', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'loan_project', unit: 'currency', styles: { bold: true } },
  { key: 'equity_release', unit: 'currency', styles: { bold: true } },
  { key: 'equity_grants', unit: 'currency', styles: { bold: true } },
  { key: 'equity_project', unit: 'currency', styles: { bold: true } },
  { key: 'total_project_funds', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'total_funds', unit: 'currency', styles: { bold: true, highlighted: true } },
  { key: 'total_equity', unit: 'currency', styles: { bold: true } },
  { key: 'total_external_funds', unit: 'currency', styles: { bold: true } },
  { key: 'financing_amount', unit: 'currency', styles: { bold: true } },
];

const useGetSourceOfFundsTable = (
  sourceOfFundsTableData: IComputedDetailsSourceOfFunds | undefined,
) => {
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.sourceOfFunds',
  );
  const formatter = useUnitFormatter();
  const { usageType } = useSelector(getMarketDataFilterLegacy);
  return usePortalTable({
    data: mapping.map(({ key }) => ({
      id: key,
      position: t(`rows.${key}`),
      value: sourceOfFundsTableData
        ? formatter.currency(sourceOfFundsTableData.base_values[key])
        : '',
      sqmCosts: sourceOfFundsTableData
        ? formatter.currency(sourceOfFundsTableData[usageType].sqm_costs[key])
        : '',
      percentCosts: sourceOfFundsTableData
        ? formatter.percent(sourceOfFundsTableData.percent_costs[key])
        : '',
    })),
    headers: [
      { key: 'position', text: t('headers.position') },
      { key: 'value', text: t('headers.value') },
      { key: 'sqmCosts', text: t('headers.sqmCosts') },
      { key: 'percentCosts', text: t('headers.percentCosts') },
    ],
  });
};

export const SourceOfFunds = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.sourceOfFunds',
  );
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      investmentPlan: response.data.attributes.computed_details.investment_plan,
      sourceOfFunds: response.data.attributes.computed_details.source_of_funds,
    }),
  });
  const { sourceOfFunds } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationMittweidaEdit;

  const { onEdit } = useEditFormConfig({
    pathToEdit: sourceOfFunds(inquiryId),
    recordType: MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS,
    editFormStructure:
      mapMittweidaProfitabilityToEdit[
        MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS
      ](),
  });

  const sourceOfFundsTable = useGetSourceOfFundsTable(data?.sourceOfFunds);
  const rowStyles = getRowStylesMap(mapping);

  const showInconsitentInvestmentPlanWarning =
    Number(data?.sourceOfFunds.base_values.total_funds) !==
    Number(data?.investmentPlan.base_values.total_costs);

  const showEquityProjectLessThanZeroWarning =
    Number(data?.sourceOfFunds.base_values.equity_project) < 0;

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Card title={t('title')} onEdit={onEdit} isExpandable isExpanded>
      <PortalTable {...sourceOfFundsTable} rowStylesMap={{ ...rowStyles }} />
      <Flex direction="column">
        <Condition condition={showInconsitentInvestmentPlanWarning}>
          <Box>{t('warnings.incosistentInvestmentPlan')}</Box>
        </Condition>
        <Condition condition={showEquityProjectLessThanZeroWarning}>
          <Box>{t('warnings.equityProjectLessThanZero')}</Box>
        </Condition>
      </Flex>
    </Card>
  );
};
