import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { AmbiguousAddress } from 'models/types/MarketData.type';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { marketDataSentAction } from '../../../../store/inquiryDetails/actions';

type Address = Pick<AmbiguousAddress, 'address' | 'city' | 'zipCode'>;

export const fetchMarketData = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.GET,
  });

export const fetchProfitabilityData = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.GET,
  });

const postMarketData = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({ params: { inquiryId } }),
    method: API_METHODS.POST,
  });

export const useRequestMarketData = (inquiryId: string) => {
  const { makeCall, isPending } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });

  const requestMarketData = useCallback(async () => {
    const { payload, error } = await makeCall(postMarketData(inquiryId));
    if (!error) {
      return payload;
    }
    return { error };
  }, [makeCall, inquiryId]);

  return { isPending, requestMarketData };
};
const getMarketData = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({ params: { inquiryId } }),
    method: API_METHODS.GET,
  });

const patchMarketData = (inquiryId: string, addressValue: string) => {
  const address = addressValue.split(';');
  return callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.OBJECT_ADDRESS.compose({ params: { inquiryId } }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: {
          project_financing_object_address: address[0],
          project_financing_object_city: address[1],
          project_financing_object_zip_code: address[2],
        },
      },
    },
  });
};

const patchObjectAddress = (inquiryId: string, address: Address) => {
  return callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.OBJECT_ADDRESS.compose({ params: { inquiryId } }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: {
          project_financing_object_address: address.address,
          project_financing_object_city: address.city,
          project_financing_object_zip_code: address.zipCode,
        },
      },
    },
  });
};

export const useGetMarketData = (inquiryId: string) => {
  const { makeCall } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    makeCall(getMarketData(inquiryId), (response: any) => {
      if (
        response.payload.status === 200 &&
        response.payload.data.data.attributes.status === 'fetched'
      ) {
        dispatch(marketDataSentAction());
      }
    });
  }, [dispatch, inquiryId, makeCall]);
};

export const useUpdateMarketData = (inquiryId: string) => {
  const { makeCall, isPending } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });

  const updatedMarketData = useCallback(
    async (addressValue: string) => {
      const { payload, error } = await makeCall(patchMarketData(inquiryId, addressValue));
      if (!error) {
        return payload;
      }
      return { error };
    },
    [makeCall, inquiryId],
  );

  const updateObjectAddress = useCallback(
    async (address: Address) => {
      const { error, payload } = await makeCall(patchObjectAddress(inquiryId, address));

      if (error) throw error;

      return payload;
    },
    [makeCall, inquiryId],
  );

  return { isPending, updatedMarketData, updateObjectAddress };
};
