import React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import { NO_BENEFICIARY_CHECKBOX } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const ConfirmNoUserBeneficiary = () => {
  const { required, mustBeTrue } = useFieldValidators();
  const t = useTranslations(
    'pages.peopleDetails.sections.beneficiaryOwners.fields.noBeneficiaryCheckbox',
  );

  return (
    <CheckboxWithField
      name={NO_BENEFICIARY_CHECKBOX}
      validate={combineValidators(required, mustBeTrue)}
      text={t('caption')}
    />
  );
};

export default ConfirmNoUserBeneficiary;
