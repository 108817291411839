import React from 'react';

import TextAreaInput from 'components/PortalFormFields/InputFields/TextAreaInput';

interface Props {
  field: {
    id: string;
    value: string;
    placeholder: string;
    name: string;
    hasError: boolean;
  };
  onChange: (value: string) => void;
}

const TextAreaField = ({ field: { id, name, value, placeholder, hasError }, onChange }: Props) => (
  <TextAreaInput
    id={id}
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    hasError={hasError}
  />
);

export default TextAreaField;
