import React from 'react';

import { InputWithField } from 'components/Input';
import { LEASING_TERM_IN_MONTHS } from 'modules/Inquiry/Form/formFields';

const LeasingTermInMonths = () => {
  return <InputWithField name={LEASING_TERM_IN_MONTHS} type="number" />;
};

export default LeasingTermInMonths;
