import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

export const SummaryCardItem = (props: BoxProps) => (
  <Box
    {...props}
    position="relative"
    h="full"
    _before={{
      content: "''",
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '-24px',
      width: '1px',
      backgroundColor: 'background.darkGrey',
    }}
  />
);
