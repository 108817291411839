import _get from 'lodash/get';

import {
  RENT_CUSTOMER_NOW_ASSET_SURPLUS,
  RENT_CUSTOMER_NOW_EQUITY_PROOF,
  RENT_CUSTOMER_NOW_FREE_INCOME,
  RENT_CUSTOMER_NOW_OPERATING_COSTS,
  RENT_CUSTOMER_PLAN_OPERATING_COSTS,
  RENT_NOTES_ON_DEBT_SERVICE,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapRentStockholderToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const rent = {
    rent: {
      customer_now: {
        asset_surplus: toFloatPrecision2(
          unknownToString(_get(data, RENT_CUSTOMER_NOW_ASSET_SURPLUS)),
        ),
        equity_proof: toFloatPrecision2(
          unknownToString(_get(data, RENT_CUSTOMER_NOW_EQUITY_PROOF)),
        ),
        free_income: toFloatPrecision2(unknownToString(_get(data, RENT_CUSTOMER_NOW_FREE_INCOME))),
        operating_costs: toFloatPrecision2(
          unknownToString(_get(data, RENT_CUSTOMER_NOW_OPERATING_COSTS)),
        ),
      },
      customer_plan: {
        operating_costs: toFloatPrecision2(
          unknownToString(_get(data, RENT_CUSTOMER_PLAN_OPERATING_COSTS)),
        ),
      },
      notes_on_debt_service: _get(data, RENT_NOTES_ON_DEBT_SERVICE),
    },
  };

  return rent;
};

export default mapRentStockholderToApi;
