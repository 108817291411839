import React from 'react';

import { Form } from 'react-final-form';

import { IInquiryListFilterByOptions } from 'models/InquiryList.model';
import { useInquiriesListContext } from 'modules/InquiriesListProvider';
import { DrawerComponent } from 'theme/components/Drawer';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FiltersDrawerBody } from './FiltersDrawerBody';
import { FiltersDrawerFooter } from './FiltersDrawerFooter';

function transformFieldToInteger(value: unknown) {
  return value ? Number(value) : undefined;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FiltersDrawer = ({ isOpen, onClose }: Props) => {
  const { tableOptions, setTableOptions, resetTableOptions } = useInquiriesListContext();
  const t = useTranslations();

  const filteredValues = { ...tableOptions.filterBy };
  filteredValues.amountTo = transformFieldToInteger(filteredValues.amountTo);
  filteredValues.amountFrom = transformFieldToInteger(filteredValues.amountFrom);

  const onSubmit = (filterValues: IInquiryListFilterByOptions) =>
    setTableOptions({ currentPage: 1, touched: true, filterBy: { ...filterValues } });

  return (
    <DrawerComponent
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="lg"
      title={t('pages.inquiryList.filtersHeadline')}
      dataTestId={'filters-drawer'}
    >
      <Form<IInquiryListFilterByOptions>
        onSubmit={onSubmit}
        initialValues={filteredValues}
        render={({ handleSubmit, values }) => {
          return (
            <>
              <FiltersDrawerBody values={values} handleSubmit={handleSubmit} />
              <FiltersDrawerFooter
                handleReset={resetTableOptions}
                handleSubmit={handleSubmit}
                onClose={onClose}
              />
            </>
          );
        }}
      />
    </DrawerComponent>
  );
};

export default FiltersDrawer;
