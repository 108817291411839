import { useEffect } from 'react';

import { FieldRenderProps } from 'react-final-form';

interface UseAutoAddRepresentativeParams {
  userRepresentationField: FieldRenderProps<any>;
  userSoleRepresentationField: FieldRenderProps<any>;
  legalRepresentativesCount: number;
  onAdd: () => void;
}

/**
 * Automatically add a legal representative when there's no legal representative added yet
 * - if the user has selected that they are not a legal representative,
 * - if the user has selected that they are a legal representative but can't represent company alone
 */
export const useAutoAddRepresentative = ({
  userRepresentationField,
  userSoleRepresentationField,
  legalRepresentativesCount,
  onAdd,
}: UseAutoAddRepresentativeParams) => {
  const {
    input: { value: userRepresentation },
    meta: { dirty: isUserRepresentationDirty },
  } = userRepresentationField;
  const {
    input: { value: userSharedRepresentation },
    meta: { dirty: isUserSharedRepresentationDirty },
  } = userSoleRepresentationField;

  return useEffect(() => {
    // We check for dirtiness of fields in order to react only on user interaction
    if (!isUserRepresentationDirty || legalRepresentativesCount !== 0) {
      return;
    }

    if (
      // if user is not a legal representative
      userRepresentation === 'false' ||
      // if user is a legal representative but can't represent company alone
      (userRepresentation === 'true' &&
        userSharedRepresentation === 'false' &&
        isUserSharedRepresentationDirty)
    ) {
      onAdd();
    }
  }, [
    onAdd,
    legalRepresentativesCount,
    userRepresentation,
    isUserRepresentationDirty,
    userSharedRepresentation,
    isUserSharedRepresentationDirty,
  ]);
};
