import React from 'react';

import Separator from 'components/FormRow/Separator';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { useFormConfig } from 'config/formConfig/hooks';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_BORROWED_CAPITAL,
} from 'modules/Inquiry/Form/formFields';
import BorrowedCapital from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BorrowedCapital';
import BuildingSourceOfFundsSum from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BuildingSourceOfFunds';
import BuildingUseOfFundsSum from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BuildingUseOfFundsSum';
import {
  StyledFinancingPlanHeadline as Headline,
  SmallRow,
  StyledCalculationTable,
  StyledTableOfUse,
  StyledTableOfSource,
  StyledSumOfUse,
  StyledSumOfSource,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/styles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInitNewInquiryForm } from 'modules/InquiryFormNew/hooks/useInitNewInquiryForm';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

const sectionFields = [
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_BORROWED_CAPITAL,
];

export const validateBuildingFinancingPlanSection = validateSectionFields(sectionFields);

const BuildingFinancingPlanSection: React.FC = () => {
  const t = useTranslations();
  useInitNewInquiryForm(sectionFields);

  const { selectedInquiryType } = useFormConfig();
  const isDefaultTypeInquiry = selectedInquiryType === InquiryType.default;

  return (
    <FormSection
      name={FormSections.buildingFinancingPlan}
      title={t('pages.financingNeed.sections.buildingFinancingPlan.title')}
      sectionNumber={isDefaultTypeInquiry ? 3 : 1}
    >
      <StyledCalculationTable>
        <StyledTableOfUse>
          <Headline>{t('pages.financingNeed.sections.buildingFinancingPlan.useOfFunds')}</Headline>
          <Separator small />
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PURCHASE_PRICE} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PLOT_PRICE} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_ADDITIONAL_COSTS} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField
              type="number"
              name={BUILDING_CONSTRUCTION_MANUFACTURE_COST}
              initial={0}
            />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_MODERNISATION_COSTS} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_DEBT_REDEMPTION} initial={0} />
          </SmallRow>
        </StyledTableOfUse>

        <StyledTableOfSource>
          <Headline>
            {t('pages.financingNeed.sections.buildingFinancingPlan.sourceOfFunds')}
          </Headline>
          <Separator small />
          <SmallRow>
            <InputWithField type="number" name={BUILDING_EQUITY} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PERSONAL_CONTRIBUTION} initial={0} />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_PROPERTY} initial={0} />
          </SmallRow>
          <SmallRow>
            <BorrowedCapital />
          </SmallRow>
          <SmallRow>
            <InputWithField type="number" name={BUILDING_DEBT_CAPITAL_OTHER} initial={0} />
          </SmallRow>
        </StyledTableOfSource>

        <StyledSumOfUse>
          <SmallRow>
            <Separator />
            <BuildingUseOfFundsSum />
          </SmallRow>
        </StyledSumOfUse>

        <StyledSumOfSource>
          <SmallRow>
            <Separator />
            <BuildingSourceOfFundsSum />
          </SmallRow>
        </StyledSumOfSource>
      </StyledCalculationTable>
    </FormSection>
  );
};

export default React.memo(BuildingFinancingPlanSection);
