import React from 'react';

import PageHeading from 'components/PageHeading';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const Heading = () => {
  const translation = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: 'pages.requestDetails.heading.bfs',
    default: 'pages.requestDetails.heading',
  });
  const t = useTranslations(translation);

  return <PageHeading heading={t('header')} subheading={t('subheader', { roleSpecific: true })} />;
};

export default Heading;
