import React from 'react';

import { useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { SectionWrapper } from 'modules/ContactSidebar/styles';
import { contactSidebarOpenSelector } from 'store/layout/selectors';
import { ButtonComponent } from 'theme/components/Button';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListDescription,
} from 'theme/components/DescriptionList';
import ChatIcon from 'theme/components/Icon/ChatIcon';
import MailIcon from 'theme/components/Icon/MailIcon';
import PhoneIcon from 'theme/components/Icon/PhoneIcon';
import SendMessageIcon from 'theme/components/Icon/SendMessageIcon';
import { LinkComponent } from 'theme/components/Link';
import { useTranslations } from 'utils/hooks/useTranslations';

const ContactDetails = () => {
  const isOpen = useSelector(contactSidebarOpenSelector);
  const t = useTranslations('components.contactSidebar');
  const {
    details: { phoneNumber, email },
    meta: { chatToken, widgetId },
  } = usePartnerConfig();

  const openChat = () => {
    const frame = document.getElementById('fc_frame');
    if (frame) frame.style.display = 'block';
    window.fcWidget.open();
  };

  const openMail = () => window.FreshworksWidget && window.FreshworksWidget('open');

  return (
    <SectionWrapper>
      {phoneNumber && (
        <DescriptionList size="small">
          <DescriptionListTerm aria-label={t('phone')}>
            <PhoneIcon boxSize={8} color="text.secondary" />
          </DescriptionListTerm>
          <DescriptionListDescription>
            <LinkComponent
              variant="secondary"
              href={`tel:${phoneNumber}`}
              tabIndex={isOpen ? 0 : -1}
            >
              {phoneNumber}
            </LinkComponent>
          </DescriptionListDescription>
        </DescriptionList>
      )}
      {email && (
        <DescriptionList size="small" mt="4">
          <DescriptionListTerm aria-label={t('email')}>
            <MailIcon boxSize={8} color="text.secondary" />
          </DescriptionListTerm>
          <DescriptionListDescription>
            <LinkComponent variant="secondary" href={`mailto:${email}`} tabIndex={isOpen ? 0 : -1}>
              {email}
            </LinkComponent>
          </DescriptionListDescription>
        </DescriptionList>
      )}
      {widgetId && (
        <DescriptionList size="small" mt="4">
          <DescriptionListTerm aria-label={t('email')}>
            <SendMessageIcon boxSize={8} color="text.secondary" />
          </DescriptionListTerm>
          <DescriptionListDescription>
            <ButtonComponent variant="link" onClick={openMail} tabIndex={isOpen ? 0 : -1}>
              {t('mailText')}
            </ButtonComponent>
          </DescriptionListDescription>
        </DescriptionList>
      )}
      {chatToken && (
        <DescriptionList size="small" mt="4">
          <DescriptionListTerm aria-label={t('chat')}>
            <ChatIcon boxSize={8} color="text.secondary" />
          </DescriptionListTerm>
          <DescriptionListDescription>
            <ButtonComponent variant="link" onClick={openChat} tabIndex={isOpen ? 0 : -1}>
              {t('chatText')}
            </ButtonComponent>
          </DescriptionListDescription>
        </DescriptionList>
      )}
    </SectionWrapper>
  );
};

export default ContactDetails;
