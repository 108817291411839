import { useIntl } from 'react-intl';

import { useFormConfig } from 'config/formConfig/hooks';
import { IntlFormatMessage } from 'models/Intl.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';

export enum INQUIRY_STATUSES {
  ALL = 'all',
  PENDING = 'pending',
  NEW = 'new',
  ASSIGNED = 'assigned',
  IN_PROGRESS = 'in_process',
  FORWARDED_TO_CUSTOMER = 'forwarded_to_customer',
  FORWARDED_TO_COMPEON = 'forwarded_to_compeon',
  FORWARDED_TO_COMPEON_SHORTHAND = 'forwarded_to_compeon_shorthand',
  CLOSED = 'closed',
  FULFILLED = 'fulfilled',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
  INITIATED_EXTERNALLY = 'initiated_externally',
  FAILED = 'failed',
}

export enum MMV_INQUIRY_STATUSES {
  INITIATED_EXTERNALLY = 'initiated_externally',
  IDENT_AND_SIGN = 'ident_and_sign',
  FULFILLED = 'fulfilled',
  OBJECT_CONFIRMATION_OPEN = 'object_confirmation_open',
  OBJECT_CONFIRMATION_DONE = 'object_confirmation_done',
  PAYMENT_PLAN_RECEIVED = 'payment_plan_received',
  ARCHIVED = 'archived',
}

export enum DZB_INQUIRY_STATUSES {
  INITIATED_EXTERNALLY = 'initiated_externally',
  AUDIT_REPRESENTATIVES = 'audit_representatives',
  SIGNING_BY_REPRESENTATIVES_IN_PROGRESS = 'signing_by_representatives_in_progress',
  AWAITING_BANK_APPROVAL = 'awaiting_bank_approval',
  SIGNING_BY_BANK_IN_PROGRESS = 'signing_by_bank_in_progress',
  SIGNED = 'signed',
  FULFILLED = 'fulfilled',
  ARCHIVED = 'archived',
}

export enum INQUIRY_SUBSTATUSES {
  FORWARDED_TO_CUSTOMER = 'waiting_for_accept_to_forward_to_compeon',
  AUTOMATIC_PROCESS_IDENT_AND_SIGN = 'automatic_process_ident_and_sign',
  AUTOMATIC_PROCESS_START = 'automatic_process_start',
  AUTOMATIC_PROCESS_FINAL_INFO_REQUEST = 'automatic_process_final_info_request',
  AUTOMATIC_PROCESS_FINAL_INFO_PROVIDED = 'automatic_process_final_info_provided',
  UNOPENED = 'unopened',
  OPENED = 'opened',
  WELCOME_CALL_PERFORMED = 'welcome_call_performed',
  ASSIGNED = 'assigned',
  WITH_BANK_OFFERS = 'with_bank_offers',
  WITH_COMPEON_OFFERS = 'with_compeon_offers',
  WITH_OFFER_INTERESTED_IN = 'with_offer_interested_in',
  OFFER_ACCEPTED = 'offer_accepted',
  OFFER_EXPIRED = 'offer_expired',
  INITIATED_EXTERNALLY = 'initiated_externally',
  IN_PROCESS = 'in_process',
  FAILED = 'failed',
  OBJECT_CONFIRMATION_DONE = 'object_confirmation_done',
  OBJECT_CONFIRMATION_PENDING = 'object_confirmation_pending',
  OBJECT_CONFIRMATION_DATA_RECEIVED = 'object_confirmation_data_received',
  PAYMENT_PLAN_RECEIVED = 'payment_plan_received',
}

const COMMON_MAP = {
  [INQUIRY_STATUSES.ALL]: 'all',
};

// API -> app
const TRANSLATION_MAP = {
  [INQUIRY_STATUSES.PENDING]: 'pending',
  [INQUIRY_STATUSES.NEW]: 'new',
  [INQUIRY_STATUSES.ASSIGNED]: 'assigned',
  [INQUIRY_STATUSES.IN_PROGRESS]: 'inProgress',
  [INQUIRY_STATUSES.FORWARDED_TO_CUSTOMER]: 'forwardedToCustomer',
  [INQUIRY_STATUSES.FORWARDED_TO_COMPEON]: 'forwardedToCompeon',
  [INQUIRY_STATUSES.FORWARDED_TO_COMPEON_SHORTHAND]: 'forwardedToCompeonShorthand',
  [INQUIRY_STATUSES.CLOSED]: 'closed',
  [INQUIRY_STATUSES.FULFILLED]: 'fulfilled',
  [INQUIRY_STATUSES.ARCHIVED]: 'archived',
  [INQUIRY_STATUSES.DRAFT]: 'draft',
  [INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'initiatedExternally',
  [INQUIRY_SUBSTATUSES.WITH_BANK_OFFERS]: 'withBankOffers',
  [INQUIRY_SUBSTATUSES.WITH_COMPEON_OFFERS]: 'withCompeonOffers',
  [INQUIRY_SUBSTATUSES.WITH_OFFER_INTERESTED_IN]: 'withOfferInterestedIn',
  [INQUIRY_SUBSTATUSES.UNOPENED]: 'unopened',
  [INQUIRY_SUBSTATUSES.OPENED]: 'opened',
  [INQUIRY_SUBSTATUSES.WELCOME_CALL_PERFORMED]: 'welcomeCallPerformed',
  [INQUIRY_SUBSTATUSES.OFFER_ACCEPTED]: 'offerAccepted',
  [INQUIRY_SUBSTATUSES.OFFER_EXPIRED]: 'offerExpired',
  [INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER]: 'forwardedToCustomer',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_IDENT_AND_SIGN]: 'automaticProcessIdentAndSign',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_FINAL_INFO_REQUEST]: 'automaticProcessFinalInfoRequest',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_FINAL_INFO_PROVIDED]: 'automaticProcessFinalInfoProvided',
  [INQUIRY_SUBSTATUSES.FAILED]: 'failed',
  default: 'partial',
};

const MMV_TRANSLATION_MAP = {
  [MMV_INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'mmv.initiatedExternally',
  [MMV_INQUIRY_STATUSES.IDENT_AND_SIGN]: 'mmv.identAndSign',
  [MMV_INQUIRY_STATUSES.FULFILLED]: 'mmv.fulfilled',
  [MMV_INQUIRY_STATUSES.OBJECT_CONFIRMATION_OPEN]: 'mmv.objectConfirmationOpen',
  [MMV_INQUIRY_STATUSES.OBJECT_CONFIRMATION_DONE]: 'mmv.objectConfirmationDone',
  [MMV_INQUIRY_STATUSES.PAYMENT_PLAN_RECEIVED]: 'mmv.paymentPlanReceived',
  [MMV_INQUIRY_STATUSES.ARCHIVED]: 'mmv.archived',
};

const DZB_TRANSLATION_MAP = {
  [DZB_INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'dzb.initiatedExternally',
  [DZB_INQUIRY_STATUSES.AUDIT_REPRESENTATIVES]: 'dzb.auditRepresentatives',
  [DZB_INQUIRY_STATUSES.SIGNING_BY_REPRESENTATIVES_IN_PROGRESS]:
    'dzb.signingByRepresentativesInProgress',
  [DZB_INQUIRY_STATUSES.AWAITING_BANK_APPROVAL]: 'dzb.awaitingBankApproval',
  [DZB_INQUIRY_STATUSES.SIGNING_BY_BANK_IN_PROGRESS]: 'dzb.signingByBankInProgress',
  [DZB_INQUIRY_STATUSES.SIGNED]: 'dzb.signed',
  [DZB_INQUIRY_STATUSES.FULFILLED]: 'dzb.fulfilled',
  [DZB_INQUIRY_STATUSES.ARCHIVED]: 'dzb.archived',
};

const translationMaps: Partial<Record<InquiryType, { [key: string]: string }>> = {
  mmv: MMV_TRANSLATION_MAP,
  dzb: DZB_TRANSLATION_MAP,
  default: TRANSLATION_MAP,
};

export const allStatuses = Object.values({ ...INQUIRY_STATUSES, ...INQUIRY_SUBSTATUSES });

export const translateInquiryStatus = (
  intlFormatMessage: IntlFormatMessage,
  status:
    | INQUIRY_STATUSES
    | INQUIRY_SUBSTATUSES
    | MMV_INQUIRY_STATUSES
    | DZB_INQUIRY_STATUSES
    | undefined,
  inquiryType: InquiryType = InquiryType.default,
) => {
  const inquiryTypeMap = translationMaps[inquiryType] || TRANSLATION_MAP;
  const translationMap = {
    ...COMMON_MAP,
    ...inquiryTypeMap,
  };

  if (!status) {
    return intlFormatMessage({
      id: `inquiry.statuses.${TRANSLATION_MAP.default}`,
    });
  }

  return intlFormatMessage({
    id: `inquiry.statuses.${(translationMap as any)[status] || TRANSLATION_MAP.default}`,
  });
};

export const useInquiryStatusTranslator = () => {
  const { selectedInquiryType } = useFormConfig();
  const { formatMessage } = useIntl();
  return (status: INQUIRY_STATUSES | INQUIRY_SUBSTATUSES | MMV_INQUIRY_STATUSES | undefined) =>
    translateInquiryStatus(formatMessage, status, selectedInquiryType);
};

export const isInquirySubstatus = (
  inquirySubstatus: string,
): inquirySubstatus is INQUIRY_SUBSTATUSES => inquirySubstatus !== undefined;
