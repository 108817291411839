import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from '../../../../constants/globalConstants';
import { textStyle } from '../../../themeConstants';

const helpers = createMultiStyleConfigHelpers(['content', 'header', 'body', 'arrow']);

const Popover = helpers.defineMultiStyleConfig({
  baseStyle: {
    content: {
      borderRadius: '0',
      px: '24px',
      py: '16px',
      boxShadow: 'default',
      color: 'text.white',
      backgroundColor: 'background.blackGrey',
      _focus: {
        outline: '0',
        boxShadow: 'none',
      },
    },
    header: {
      marginBottom: '4',
      borderBottom: '0',
      padding: '0',
      ...textStyle.sizeLTight,
      fontWeight: FONT_WEIGHT_MEDIUM,
    },
    body: {
      padding: '0',
      ...textStyle.sizeM,
      fontWeight: FONT_WEIGHT_REGULAR,
      a: {
        textDecoration: 'underline',
        overflowWrap: 'break-word',

        _focus: {
          textDecoration: 'none',
        },

        _hover: {
          textDecoration: 'none',
        },
      },
    },
  },
});

export default Popover;
