import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { ValidationRecord } from 'new/form/common/types';
import { InitialFormStateType } from 'new/form/model';
import { INQUIRY_PROGRESS_STORAGE_KEY } from 'new/form/persist';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { InquiryConfigContext } from './context';
import { PageStateActionTypes } from '../pageStateReducer';

export function useInquiryProcessConfig() {
  const context = React.useContext(InquiryConfigContext);
  if (context === undefined) {
    throw new Error('useInquiryProcessConfig must be used within a PageStateProvider');
  }
  return context;
}

export function useMode() {
  const { state } = useInquiryProcessConfig();
  return state.inquiryMode;
}

export function useFormValidations() {
  const {
    state: { inquiryMode },
    formStateData: { validations },
  } = useInquiryProcessConfig();

  return validations[inquiryMode];
}

export function useInquiryPathForMode(pageName: any) {
  const {
    state: { inquiryMode },
    formStateData: { routeToPageNameMap },
  } = useInquiryProcessConfig();
  const route = routeToPageNameMap[pageName][inquiryMode];
  const id = useSelector(getInquiryIdSelector);

  if (inquiryMode === 'create') {
    return route;
  } else {
    return route.replace(':id', id);
  }
}

export const useResetInquiryProgress = () => {
  const {
    dispatch,
    formStateData: { validations, initialFormState },
  } = useInquiryProcessConfig();
  const initialStateWithSections = useInitialState(
    validations[initialFormState.inquiryMode],
    initialFormState,
    true,
  );

  const resetFn = useCallback(() => {
    dispatch({
      type: PageStateActionTypes.SET_PROGRESS,
      payload: initialStateWithSections.pageValidations,
    });
  }, [dispatch, initialStateWithSections.pageValidations]);

  return resetFn;
};

export function useInitialState<T>(
  validations: ValidationRecord<T>,
  initialState: InitialFormStateType<T>,
  ignoreLocalStorage: boolean = false,
) {
  const stateFromStorage = window.sessionStorage.getItem(INQUIRY_PROGRESS_STORAGE_KEY);
  const currentPageValidations = initialState.pageValidations;
  if (stateFromStorage && !ignoreLocalStorage) {
    return JSON.parse(stateFromStorage);
  }

  const initialNumberOfSections = Object.keys(validations).reduce((acc, key) => {
    const numberOfSections = Object.keys((validations as any)[key]).length;
    const currentValidations = (currentPageValidations as any)[key].validations;

    return {
      ...acc,
      [key]: {
        progress: {
          ...(currentPageValidations as any)[key].progress,
          numberOfSections,
        },
        validations: currentValidations,
      },
    };
  }, {});

  return {
    inquiryMode: initialState.inquiryMode,
    currentStep: initialState.currentStep,
    pageValidations: initialNumberOfSections,
  };
}
