import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { textStyle } from '../../../themeConstants';

const helpers = createMultiStyleConfigHelpers([
  'innerText',
  'divider',
  'circularProgress',
  'vStack',
]);

const CircularProgress = helpers.defineMultiStyleConfig({
  baseStyle: {
    circularProgress: {
      position: 'relative',
    },
    innerText: {
      display: 'flex',
      justifyContent: 'center',
      color: 'brand.default',
    },
    divider: {
      overflow: 'hidden',
      backgroundColor: 'background.neutral',
    },
    vStack: {
      position: 'absolute',
      zIndex: 'above',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      spacing: 0,
      justifyContent: 'center',
    },
  },
  sizes: {
    small: {
      circularProgress: {
        width: '48px',
        height: '48px',
        ...textStyle.sizeS,
      },
      innerText: {
        ...textStyle.sizeS,
      },
      divider: {
        width: '1px',
        margin: '4px',
      },
    },
    large: {
      circularProgress: {
        width: '152px',
        height: '152px',
        ...textStyle.sizeXxl,
      },
      innerText: {
        ...textStyle.sizeXxl,
        marginBottom: '0.25rem',
      },
      divider: {
        width: '2px',
        margin: '10px',
      },
    },
  },
});

export default CircularProgress;
