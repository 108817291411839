import React from 'react';

import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import paths from 'constants/paths';
import { translations } from 'new/form/common/types';
import { FinalPageAction } from 'pages/inquiryFlow/FinalPage/actions/FinalPageAction';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { getCreatedInquiryId } from 'store/progress/selectors';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsBankAdvisor } from 'utils/user/conditionals';

export const DzbDocumentUploadAction = () => {
  const t = useTranslations();
  const { heading, description } = translations.pages.finalPage.uploadDocuments;
  const isBankAdvisorRole = userIsBankAdvisor();
  const inquiryId = useSelector(getCreatedInquiryId);

  const actionUrl = paths[
    isBankAdvisorRole ? 'operation' : 'customer'
  ].inquiryDetails.documentExchange.replace(':id', inquiryId);

  return (
    <FinalPageAction asset={<Asset type={'inquiry'} value={InquiryAssetKeys.DOCUMENT_UPLOAD} />}>
      <HeadingComponent as={'h3'} variant={'secondary'} mb={4}>
        {t(heading)}
      </HeadingComponent>
      <TextComponent mb={6}>{t(description)}</TextComponent>
      <ButtonComponent
        as={'a'}
        href={actionUrl}
        leftIcon={<ArrowRightIcon boxSize={6} display={'block'} />}
      >
        {t('pages.finalPage.uploadDocuments.buttonDescription', { roleSpecific: true })}
      </ButtonComponent>
    </FinalPageAction>
  );
};
