import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { UsageType } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getMittweidaUsageTypeOptions = (
  formatMessage: FormatMessage,
): SelectOption<UsageType>[] => {
  const { care_social, commercial_office, other, retail_parking_garage, residential } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields.usage
      .typeOfUse.options;

  const options: Array<{ id: string; value: UsageType }> = [
    {
      id: care_social,
      value: 'care_social',
    },
    {
      id: commercial_office,
      value: 'commercial_office',
    },
    {
      id: residential,
      value: 'residential',
    },
    {
      id: retail_parking_garage,
      value: 'retail_parking_garage',
    },
    {
      id: other,
      value: 'other',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
