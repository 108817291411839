import React, { ReactNode } from 'react';

import { LoadingRow, StyledTableRowCell } from 'components/PortalTable/styles';

const DEFAULT_PAGE_SIZE = 5;

const generateArray = (numOfColumns: number, as: Function): ReactNode[] =>
  new Array(numOfColumns).fill(1).map((_, index) => as(index));

const LoadingRows = ({ numOfColumns }: { numOfColumns: number }) => {
  return (
    <tbody>
      {generateArray(DEFAULT_PAGE_SIZE, (rowIndex: number) => (
        <LoadingRow key={rowIndex}>
          {generateArray(numOfColumns, (cellIndex: number) => (
            <StyledTableRowCell key={cellIndex} />
          ))}
        </LoadingRow>
      ))}
    </tbody>
  );
};

export default LoadingRows;
