import styled from 'styled-components/macro';

import { borderRadius } from 'theme/themeConstants';

const StyledLogoutButton = styled.button`
  max-width: 6rem;
  margin-top: 1rem;
  border-radius: ${borderRadius.default};
  padding: 1rem 0.5rem;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${(props) => props.theme.colors.primary};
`;

export default StyledLogoutButton;
