import styled from 'styled-components';

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: -3.5rem -90rem 2rem;
  padding: 2rem 90rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};
`;

export const StyledSmallFormRow = styled(StyledFormRow)`
  width: 30%;
`;

export const StyledSelectBox = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledNumberedSelectRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
