import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { NewStaticFieldWithField } from 'components/StaticField';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { companyName, address } = mmvFields.customerDataPage.companySection;

interface CompanySectionProps {
  sectionNumber?: number;
}

export const CompanySection = (props: CompanySectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      name="company"
      title={t(translations.inquiryType.mmv.steps.customerDataPage.sections.companySection.title)}
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField name={companyName} />
        <NewStaticFieldWithField name={address} />
      </FormRow>
    </FormSection>
  );
};
