import React from 'react';

import { BeneficialOwnersSection } from 'dzb/inquiry/steps/personalData/sections/beneficialOwnersSection';
import { ContactPersonSection } from 'dzb/inquiry/steps/personalData/sections/contactPersonSection';
import { LegalRepresentativesSection } from 'dzb/inquiry/steps/personalData/sections/legalRepresentativesSection';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';

export const DzbPersonalDataPage = () => {
  return (
    <Page
      translationStringForTitle={translations.inquiryType.dzb.pages.personalData.progressBarTitle}
    >
      <ContactPersonSection />
      <LegalRepresentativesSection />
      <BeneficialOwnersSection />
    </Page>
  );
};
