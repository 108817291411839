import { createStructuredSelector } from 'reselect';

import { IFile } from 'models/File.model';
import { IFileRequest } from 'models/FileRequest.model';
import { IState } from 'store';
import {
  fileRequestClassificationOptionsSelector,
  fileRequestSelector,
  fileSelector,
} from 'store/documentExchange/documentExchange.selectors';

interface SelectorProps {
  fileId: string;
  fileRequestId?: string;
}

interface SelectorResult {
  classificationOptions: { label: string; value: string }[];
  file: IFile;
  fileRequest?: IFileRequest;
}

// @ts-ignore
export const FileReclassificationButtonSelector = createStructuredSelector<
  // FIXME
  // @ts-ignore
  IState,
  // FIXME
  // @ts-ignore
  SelectorProps,
  // FIXME
  // @ts-ignore
  SelectorResult
>({
  classificationOptions: fileRequestClassificationOptionsSelector,
  // FIXME
  // @ts-ignore
  file: (state, { fileId }) => fileSelector(state, fileId)!,
  // FIXME
  // @ts-ignore
  fileRequest: (state, { fileRequestId }) => fileRequestSelector(state, fileRequestId),
});
