import React from 'react';

import Condition from 'components/Condition';
import {
  StyledOverviewFields,
  Subtitle,
  BreakLine,
} from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

import CompanyOverviewContact from './CompanyOverviewContact';
import { useCompanyOverviewFields } from './useCompanyOverviewFields';

const CompanyOverviewSummary: React.FC = () => {
  const t = useTranslations();
  const summaryFields = useCompanyOverviewFields();

  return (
    <>
      <Subtitle>{t('pages.companiesDetails.overview.summary')}</Subtitle>
      <StyledOverviewFields>{summaryFields.summary}</StyledOverviewFields>
      <BreakLine />
      <Condition condition={Boolean(summaryFields.contact.length)}>
        <CompanyOverviewContact>{summaryFields.contact}</CompanyOverviewContact>
      </Condition>
    </>
  );
};

export default CompanyOverviewSummary;
