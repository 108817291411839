import React from 'react';

import { Redirect } from 'react-router-dom';

import SuccessPageHeading from 'components/SuccessPageHeading';
import paths from 'constants/paths';
import FormPageLayout from 'modules/FormPage/FormPageLayout/FormPageLayout';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useTranslations } from 'utils/hooks/useTranslations';

const RegistrationSuccess = () => {
  const t = useTranslations();
  return (
    <FormPageLayout withBadges={false}>
      <SuccessPageHeading
        iconType="checkmark"
        heading={t('pages.registration.registrationSuccessful')}
        subheading={t('pages.registration.checkEmail')}
      />
    </FormPageLayout>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_REGISTRATION_ENABLED]: RegistrationSuccess,
  default: () => <Redirect to={paths.customer.login} />,
});
