import React from 'react';

import { FormLabel } from '@chakra-ui/react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import {
  QUICK_CHECK_AMOUNT_IN_EURO,
  QUICK_CHECK_TYPE_AND_GUARANTOR,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useTranslations } from 'utils/hooks/useTranslations';

const CreditValueSection = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck.sections.creditValue');

  return (
    <>
      <FormLabel>{t('subHeading')}</FormLabel>
      <FormRow>
        <InputWithField
          name={QUICK_CHECK_TYPE_AND_GUARANTOR}
          placeholder={t('fields.typeAndGuarantor.placeholder')}
          data-testid={QUICK_CHECK_TYPE_AND_GUARANTOR}
        />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={QUICK_CHECK_AMOUNT_IN_EURO}
          placeholder={t('fields.amountInEuro.placeholder')}
          data-testid={QUICK_CHECK_AMOUNT_IN_EURO}
          optional
        />
      </FormRow>
    </>
  );
};

export default CreditValueSection;
