import React from 'react';

import { ValidationErrorItem } from '@hapi/joi';

import RenderedHiddenInput from 'components/HiddenInput/RenderedHiddenInput';
import { ErrorText } from 'components/Text';
import { StyledFieldError } from 'modules/Inquiry/Form/Field/FieldStyles';
import { useErrorTranslator } from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';

/**
 * Creates a FieldError component that displays an error message. Should not be conditionally rendered to avoid layout shifts.
 * @param {string} message - Error message to display
 * @param {string} [name] - Needed if the error message has no corresponding input field. Used as an anker point for the automatic "scroll to error" behaviour
 */
const FieldError = ({
  message,
  name,
}: {
  message: ValidationErrorItem | string;
  name?: string;
}) => {
  const errorTranslator = useErrorTranslator();

  return (
    <StyledFieldError hasError={Boolean(message)}>
      {name ? <RenderedHiddenInput name={name} /> : null}
      <ErrorText>{errorTranslator(message)}</ErrorText>
    </StyledFieldError>
  );
};

export default FieldError;
