import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import ConditionalSubform from 'modules/ConditionalSubform';
import {
  fieldIsValid,
  fieldsAreValid,
  validateSectionFields,
} from 'modules/Inquiry/Form/formConditions';
import {
  CORONA_TOTAL_ASSETS,
  CORONA_VIABILTIY_2020,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_LIQUIDITY_NEED,
  FINANCING_AMOUNT,
} from 'modules/Inquiry/Form/formFields';
import ExpenditureOfEmployees from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/ExpenditureOfEmployees';
import LiquidityNeed from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/LiquidityNeed';
import KfwProgramFinancing from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/presentKfwProgram/KfwProgramFinancing';
import TotalAssets from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/TotalAssets';
import Viability2020 from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/Viability2020';
import { useFetchKfwProgram } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/useFetchKfwProgram';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateOtherKfwProgramSection = validateSectionFields([
  CORONA_VIABILTIY_2020,
  CORONA_TOTAL_ASSETS,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_LIQUIDITY_NEED,
  FINANCING_AMOUNT,
]);

const OtherKfwProgram: React.FC = () => {
  const t = useTranslations('pages.coronaStep.subsections');
  useFetchKfwProgram();

  return (
    <FormSection sectionNumber={3} name={FormSections.otherKfwProgram} title={t('otherKfwProgram')}>
      <FormRow>
        <Viability2020 />
      </FormRow>
      <FormRow>
        <TotalAssets />
      </FormRow>
      <FormRow>
        <ExpenditureOfEmployees />
      </FormRow>
      <ConditionalSubform
        condition={fieldIsValid(CORONA_TOTAL_ASSETS)}
        renderOnSatisfy={() => (
          <FormRow>
            <LiquidityNeed />
          </FormRow>
        )}
      />

      <ConditionalSubform
        condition={fieldsAreValid([
          CORONA_VIABILTIY_2020,
          CORONA_TOTAL_ASSETS,
          CORONA_EXPENDITURE_OF_EMPLOYEES,
          CORONA_LIQUIDITY_NEED,
        ])}
        renderOnSatisfy={() => <KfwProgramFinancing />}
      />
    </FormSection>
  );
};

export default OtherKfwProgram;
