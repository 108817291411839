import { injectIntl, IntlShape } from 'react-intl';

import { DropdownConfig, AssetConfig } from 'config/formConfig/types';
import { getIconValue } from 'store/partner/config/icons';

export type FormatMessage = IntlShape['formatMessage'];

export const withIntl = injectIntl;

export const decorateLocalizationDropdownOptions = (
  options: DropdownConfig[],
  formatMessage: FormatMessage,
) =>
  options.map(({ value, id, translatedLabel }) => ({
    value,
    label: translatedLabel || formatMessage({ id }),
  }));

export const decorateLocalizationIconOptions = (
  options: AssetConfig[],
  formatMessage: FormatMessage,
) =>
  options.map(({ id, titleId, icon, useStroke }) => ({
    id,
    title: formatMessage({ id: titleId }),
    icon: getIconValue(icon),
    useStroke,
  }));
