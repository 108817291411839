import React, { useContext } from 'react';

import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { REPRESENTATIVE_GENDER } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';

interface RepresentativeGenderProps {
  isDisabled?: boolean;
}

const RepresentativeGender: React.FC<RepresentativeGenderProps> = ({ isDisabled }) => {
  const { fieldName } = useContext(LegalRepresentativesContext);

  return <GenderWithField name={`${fieldName}.${REPRESENTATIVE_GENDER}`} disabled={isDisabled} />;
};

export default RepresentativeGender;
