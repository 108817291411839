import { useState, useCallback } from 'react';

import {
  loadAvailableBankAdvisorsAction,
  IBankAdvisor,
} from 'api/CompeonReverseApi/operation/loadAvailableBankAdvisors/loadAvailableBankAdvisors';
import { ApiActionResponseModel } from 'models/ApiActionResponse.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const useAvailableBankAdvisors = () => {
  const [availableBankAdvisors, setAvailableBankAdvisors] = useState<IBankAdvisor[]>([]);
  const { makeCall } = useDispatchApiCall();

  const fetchAvailableBankAdvisors = (organizationUnitId: string | undefined = undefined) => {
    makeCall(
      loadAvailableBankAdvisorsAction(organizationUnitId),
      ({ payload }: ApiActionResponseModel) => {
        setAvailableBankAdvisors(payload.data?.data);
      },
    );
  };

  return {
    availableBankAdvisors,
    fetchAvailableBankAdvisors: useCallback(fetchAvailableBankAdvisors, [makeCall]),
  };
};
export default useAvailableBankAdvisors;
