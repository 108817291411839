import {
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_GENDER,
  CONTACT_PERSON_LAST_NAME,
  CONTACT_PERSON_PHONE,
} from 'modules/Inquiry/Form/formFields';

// The order of these labels is relevant for inquiry details page
export const bfsContactPersonLabelsMap: { [key: string]: string } = {
  [CONTACT_PERSON_GENDER]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.caption',
  [CONTACT_PERSON_FIRST_NAME]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsFirstName.caption',
  [CONTACT_PERSON_LAST_NAME]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsLastName.caption',
  [CONTACT_PERSON_EMAIL]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail.caption',
  [CONTACT_PERSON_PHONE]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsPhoneNumber.caption',
};
