import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { OTHER_PURPOSE_DESCRIPTION } from 'modules/Inquiry/Form/formFields';

const CoronaDescription: React.FC = () => {
  return <TextAreaWithField name={OTHER_PURPOSE_DESCRIPTION} />;
};

export default CoronaDescription;
