import { GENDER_WOMAN, GENDER_MAN } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const translations: { [key: string]: string } = {
  [GENDER_MAN]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders.man',
  [GENDER_WOMAN]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders.woman',
};

export const useSalutationTranslator = () => {
  const t = useTranslations('');
  return (salutation: string) => (translations[salutation] ? t(translations[salutation]) : '');
};
