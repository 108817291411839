import React from 'react';

import { getDisplayLanguage, useLanguages } from 'components/App/LanguageProvider/useLanguages';
import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { MIN_PHONE_NUMBER_LENGTH, MAX_PHONE_NUMBER_LENGTH } from 'constants/globalConstants';
import { StyledFooter, StyledFieldsWrapper } from 'modules/UserData/styles';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const TEXT_INPUT_MAX_LENGTH = 100;

const {
  fields: { salutation, firstName, lastName, email, phoneNumber, language },
  save,
} = translations.pages.userData;

const NewUserDataForm = ({
  isLoading,
  isEditMode,
}: {
  isLoading: boolean;
  isEditMode: boolean;
}) => {
  const { required, maxLength, hasPhonePrefix, isPhoneNumber, minLength, isEmail } =
    useFieldValidators();
  const t = useTranslations();

  const { availableLanguages, isMultipleLanguagesEnabled } = useLanguages();

  const languageOptions = availableLanguages.map((lang) => ({
    value: lang,
    label: getDisplayLanguage(lang),
  }));

  return (
    <StyledFieldsWrapper>
      <FormRow>
        <GenderWithField
          name="gender"
          caption={t(salutation.caption)}
          data-testid="userdata-salutation"
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name="firstName"
          validate={combineValidators(required, maxLength(TEXT_INPUT_MAX_LENGTH))}
          caption={t(firstName)}
          data-testid="userdata-first-name"
        />

        <InputWithField
          name="lastName"
          validate={combineValidators(required, maxLength(TEXT_INPUT_MAX_LENGTH))}
          caption={t(lastName)}
          data-testid="userdata-last-name"
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name="email"
          validate={combineValidators(required, isEmail)}
          caption={t(email)}
          data-testid="userdata-email"
          disabled={isEditMode}
        />
        <InputWithField
          name="phoneNumber"
          validate={combineValidators(
            required,
            hasPhonePrefix,
            isPhoneNumber,
            minLength(MIN_PHONE_NUMBER_LENGTH),
            maxLength(MAX_PHONE_NUMBER_LENGTH),
          )}
          caption={t(phoneNumber)}
          data-testid="userdata-phone-number"
        />
      </FormRow>
      <Condition condition={isMultipleLanguagesEnabled}>
        <FormRow>
          <SelectWithField
            name="language"
            caption={t(language)}
            data-testid="userdata-language"
            options={languageOptions}
          />
        </FormRow>
      </Condition>
      <StyledFooter>
        <ButtonComponent data-testid="userdata-save-btn" disabled={isLoading} type="submit">
          {t(save)}
        </ButtonComponent>
      </StyledFooter>
    </StyledFieldsWrapper>
  );
};

export default NewUserDataForm;
