import React from 'react';

import { Divider } from '@chakra-ui/react';

import FormRow from 'components/FormRow';
import { useGenerateDzbLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/formFields/useGenerateDzbLegalRepresentativeFields';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

type Props = {
  mode: AssociatedPersonMode;
  prefix?: string;
};

export const DzbLegalRepresentativeFields = ({ mode, prefix }: Props) => {
  const fields = useGenerateDzbLegalRepresentativeFields(mode, prefix);

  return (
    <>
      <FormRow>{fields.salutation}</FormRow>
      <FormRow>
        {fields.firstName}
        {fields.lastName}
      </FormRow>
      <FormRow>{fields.birthDate}</FormRow>
      <FormRow>{fields.email}</FormRow>
      <FormRow>{fields.phoneNumber}</FormRow>
      <FormRow space={'1.5rem'}>{fields.soleSignatureAuthorized}</FormRow>
      <Divider color={'background.darkGrey'} mb={6} />
      <FormRow>{fields.taxIdentificationNumber}</FormRow>
    </>
  );
};
