import React, { useMemo } from 'react';

import {
  RLLComputedProfitabilityEvaluationCustomer,
  RLLComputedProfitabilityEvaluationMarketData,
  RLLComputedProfitabilityEvaluationRealEstateExpert,
} from 'models/types/Profitability.type';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getData, getHeaders } from './module';
import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import useEditFormConfig from '../../../../useEditFormConfig';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';

interface EvaluationProps {
  customer: RLLComputedProfitabilityEvaluationCustomer;
  editPath: string;
  marketData: RLLComputedProfitabilityEvaluationMarketData;
  realEstateExpert: RLLComputedProfitabilityEvaluationRealEstateExpert;
}

export const Evaluation: React.FC<EvaluationProps> = ({
  customer,
  editPath,
  marketData,
  realEstateExpert,
}) => {
  const t = useTranslations();
  const tPage = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.profitabilitySale',
  );
  const { hasRoleBankAdvisor, hasRoleInquiryManager } = useRoles();

  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(
    () => getData(t, { customer, marketData, realEstateExpert }),
    [t, customer, marketData, realEstateExpert],
  );

  const { onEdit } = useEditFormConfig({
    pathToEdit: editPath,
    recordType: PROFITABILITY_RECORD_TYPE.SALES_COST_RATE,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.SALES_COST_RATE](),
  });

  return (
    <AccordionComponent headingLevel="h3" mt={8} title={tPage('title')} defaultIndex={[0]}>
      <TableCardWithEdit
        canEdit={hasRoleBankAdvisor || hasRoleInquiryManager}
        tableHeaders={headers}
        tableData={data}
        onEdit={onEdit}
        componentName="evaluation"
      />
    </AccordionComponent>
  );
};
