import paths from 'constants/paths';
import { mittweidaConditions } from 'mittweida/config/conditions';
import { mittweidaInitialFormState } from 'mittweida/config/initialFormState';
import { mittweidaInitialFormValues } from 'mittweida/config/initialFormValues';
import { mittweidaRouteToPageNameMap } from 'mittweida/config/routeToPageNameMap';
import { mittweidaStepData } from 'mittweida/config/stepData';
import { mittweidaStepSequence } from 'mittweida/config/stepSequence';
import { mittweidaSummaryFields } from 'mittweida/config/summaryFields';
import { mittweidaSummaryPath } from 'mittweida/config/summaryPath';
import { mittweidaValidations } from 'mittweida/config/validations';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

export const mittweidaInquiryProcessConfig: InquiryProcessConfig<InquiryType.profiMittweida> = {
  validations: mittweidaValidations,
  fields: mittweidaFields,
  summaryFields: mittweidaSummaryFields,
  summaryPath: mittweidaSummaryPath,
  stepData: mittweidaStepData,
  stepSequence: mittweidaStepSequence,
  routeToPageNameMap: mittweidaRouteToPageNameMap,
  initialFormState: mittweidaInitialFormState,
  initialFormValues: mittweidaInitialFormValues,
  formName: 'MITTWEIDA_FORM',
  conditions: mittweidaConditions,
  paths: {
    editPaths: {
      editOperation: paths.operation.inquiryDetails.edit.financingNeed,
      editCustomer: paths.customer.inquiryDetails.edit.financingNeed,
    },
    listPaths: {
      editOperation: paths.operation.inquiryList,
      editCustomer: paths.customer.inquiryList,
    },
    createPath: paths.inquiry.new.root,
  },
};
