import React from 'react';

import { useSelector } from 'react-redux';

import {
  CONTACT_PERSON_STEP,
  USER_PROFILE_PHONE_NUMBER,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_LAST_NAME,
  COMPANY_DETAILS_FIRST_NAME,
  CONTACT_PERSON_DETAILS,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_PHONE,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_LAST_NAME,
} from 'modules/Inquiry/Form/formFields';
import BfsPersonDetails from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson/ForNotLoggedInUser/bfsPersonDetails';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { store } from 'store';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { isLoggedInUser, userIsCustomer } from 'utils/user/conditionals';
import { getCurrentUser } from 'utils/user/getters';

import ContactPersonDetailsSection from './ContactPersonDetailsSection/ContactPersonDetailsSection';
import {
  useBfsContactPersonStepValidators,
  useContactPersonStepValidators,
} from './useContactPersonStepValidators';
import ContactPersonDetailsHeader from '../CompanyDetails/ContactPersonDetailsHeader/ContactPersonDetailsHeader';
import TermsAndConditions from '../CompanyDetails/sections/TermsAndConditions';

type InitialValues =
  | {}
  | {
      [USER_PROFILE_PHONE_NUMBER]: string | undefined;
      [COMPANY_DETAILS_EMAIL]: string | undefined;
      [COMPANY_DETAILS_FIRST_NAME]: string | undefined;
      [COMPANY_DETAILS_LAST_NAME]: string | undefined;
    };

const ContactPersonStep: React.FC = () => {
  const getSectionsValidators = useContactPersonStepValidators();
  const user = getCurrentUser(store.getState());

  let initialValues: InitialValues = {};
  if (user) {
    initialValues = {
      [USER_PROFILE_PHONE_NUMBER]: user.phoneNumber,
      [COMPANY_DETAILS_EMAIL]: user.email,
      [COMPANY_DETAILS_FIRST_NAME]: user.firstName,
      [COMPANY_DETAILS_LAST_NAME]: user.lastName,
    };
  }

  return (
    <InquiryFormNew
      initialValues={initialValues}
      headerComp={<ContactPersonDetailsHeader />}
      currentStepName={CONTACT_PERSON_STEP}
      getSectionValidators={() => getSectionsValidators}
      ignoreRedirection
    >
      {() => (
        <div>
          <ContactPersonDetailsSection sectionNumber={1} />
          <TermsAndConditions sectionNumber={2} />
        </div>
      )}
    </InquiryFormNew>
  );
};

const ContactPersonBfs: React.FC = () => {
  const getSectionsValidators = useBfsContactPersonStepValidators();
  const isLoggedIn = isLoggedInUser();
  const isCustomer = userIsCustomer();
  const inquiryId = useSelector(getInquiryIdSelector);

  const user = getCurrentUser(store.getState());

  let initialValues: InitialValues = {};
  if (isLoggedIn && (isCustomer || inquiryId)) {
    if (isCustomer && user) {
      initialValues = {
        [CONTACT_PERSON_PHONE]: user.phoneNumber,
        [CONTACT_PERSON_EMAIL]: user.email,
        [CONTACT_PERSON_FIRST_NAME]: user.firstName,
        [CONTACT_PERSON_LAST_NAME]: user.lastName,
      };
    }

    return (
      <InquiryFormNew
        initialValues={initialValues}
        headerComp={<ContactPersonDetailsHeader />}
        currentStepName={CONTACT_PERSON_DETAILS}
      >
        {() => (
          <div>
            <ContactPersonDetailsSection />
          </div>
        )}
      </InquiryFormNew>
    );
  } else {
    return (
      <InquiryFormNew
        headerComp={<ContactPersonDetailsHeader />}
        currentStepName={CONTACT_PERSON_DETAILS}
        getSectionValidators={() => getSectionsValidators}
      >
        {() => (
          <div>
            <BfsPersonDetails sectionNumber={1} />
            <TermsAndConditions sectionNumber={2} />
          </div>
        )}
      </InquiryFormNew>
    );
  }
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: ContactPersonBfs,
  [InquiryType.profiRLL]: ContactPersonBfs,
  default: ContactPersonStep,
});
