import {
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_ZIP_CODE,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_TAX_ID,
} from 'modules/Inquiry/Form/formFields';

// The order of these labels is relevant for beneficiary details page
export const beneficiaryOwnerDetailsLabelsMap: { [key: string]: string } = {
  [HAS_BENEFICIARY_OWNER]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.hasBeneficiaryOwnerRadio.caption',
  [IS_BENEFICIARY_OWNER]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.isBeneficiaryOwnerRadio.caption',
  [BENEFICIARY_GENDER]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.gender.caption',
  [BENEFICIARY_FIRST_NAME]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.firstName.caption',
  [BENEFICIARY_LAST_NAME]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.lastName.caption',
  [BENEFICIARY_COMPANY_SHARE]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.companyShare.caption',
  [BENEFICIARY_TAX_ID]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryTaxId.caption',
  [BENEFICIARY_PRIVATE_ADDRESS]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryPrivateAddress.caption',
  [BENEFICIARY_ZIP_CODE]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryZipCode.caption',
  [BENEFICIARY_PRIVATE_CITY]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryPrivateCity.caption',
  [BENEFICIARY_PRIVATE_COUNTRY]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryPrivateCountry.caption',
  [BENEFICIARY_BIRTH_COUNTRY]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryBirthCountry.caption',
  [BENEFICIARY_BIRTHDAY]:
    'pages.peopleDetails.sections.beneficiaryOwners.fields.beneficiary.beneficiaryBirthDay.caption',
};
