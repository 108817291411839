import {
  FACTORING_BILLS_RANGE_1001_TO_2500,
  FACTORING_BILLS_RANGE_251_TO_500,
  FACTORING_BILLS_RANGE_501_TO_1000,
  FACTORING_BILLS_RANGE_MORE_THAN_2500,
  FACTORING_BILLS_RANGE_UP_TO_250,
} from 'modules/Inquiry/Form/formFields';

const getFactoringBillsRangeOptions = (formatMessage) => [
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringBillsRange.options.to',
      },
      { to: 250 },
    ),
    value: FACTORING_BILLS_RANGE_UP_TO_250,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringBillsRange.options.fromTo',
      },
      { from: 251, to: 500 },
    ),
    value: FACTORING_BILLS_RANGE_251_TO_500,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringBillsRange.options.fromTo',
      },
      { from: 501, to: 1000 },
    ),
    value: FACTORING_BILLS_RANGE_501_TO_1000,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringBillsRange.options.fromTo',
      },
      { from: 1001, to: 2500 },
    ),
    value: FACTORING_BILLS_RANGE_1001_TO_2500,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringBillsRange.options.above',
      },
      { above: 2500 },
    ),
    value: FACTORING_BILLS_RANGE_MORE_THAN_2500,
  },
];

export default getFactoringBillsRangeOptions;
