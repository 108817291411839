export function translateTargetMet(
  targetMet: boolean | null,
  t: (msgId: any, values?: any) => any,
) {
  switch (targetMet) {
    case true:
      return t('tables.financingCriteriaTable.targetMetValues.yes');
    case false:
      return t('tables.financingCriteriaTable.targetMetValues.no');
    case null:
      return t('tables.financingCriteriaTable.targetMetValues.notRelevant');
    default:
      return t('tables.financingCriteriaTable.targetMetValues.notRelevant');
  }
}
