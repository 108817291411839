import normalize from 'json-api-normalizer';
import _get from 'lodash/get';

import {
  COMPANY_DETAILS,
  COMPANY_DETAILS_CONSENT,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
  COMPANY_DETAILS_LAST_NAME,
  EXISTING_USER_COMPANY_ALREADY_CUSTOMER,
  EXISTING_USER_COMPANY_ID,
  EXISTING_USER_COMPANY_CITY,
  EXISTING_USER_COMPANY_CREFO_ID,
  EXISTING_USER_COMPANY_NAME,
  EXISTING_USER_COMPANY_STREET,
  EXISTING_USER_COMPANY_ZIP_CODE,
  EXISTING_USER_COMPANY_FOUNDING_YEAR,
  EXISTING_USER_COMPANY_FOUNDING_MONTH,
  EXISTING_USER_COMPANY_LEGAL_FORM,
  EXISTING_USER_COMPANY_TURNOVER_CLASS,
  EXISTING_USER_COMPANY_INDUSTRY,
  FINANCING_NEED,
  REQUEST_DETAILS,
  SECURITIES,
  USER_PROFILE,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_TURNOVER_CLASS,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  VB_NUMBER,
  VM_NUMBER,
  END_USER_GENDER,
  END_USER_FIRST_NAME,
  END_USER_LAST_NAME,
  END_USER_EMAIL,
  END_USER_PHONE_NUMBER,
  CORONA_REVENUE_OF_2019,
  CORONA_LIQUIDITY_NEED,
  CORONA_VIABILTIY,
  CORONA_VIABILTIY_2020,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_TOTAL_ASSETS,
  USER_PROFILE_FOUNDING_MONTH,
  FINANCING_AMOUNT,
  EXCLUDED_BANKS,
  INCOME_SURPLUS,
  GENDER_MAN,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_PHONE,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_LAST_NAME,
  CONTACT_PERSON_DETAILS,
  CONTACT_PERSON_SECURITY_CONSENT,
  CONTACT_PERSON_GENDER,
  FURTHER_NEED,
  FURTHER_NEED_VALUES,
  OTHER_FINANCING_SOLUTIONS,
  PUBLIC_FUNDING,
  EXPERT_ADVICE_ON_ALL,
  PAYMENT_TRANSACTIONS,
  INVESTMENT_AND_SECURITIES,
  FUNDRAISING,
  PRODUCT_KIND__LEASING,
  FUNDING_CLOSING_DATE,
  PRODUCT_KIND__FACTORING,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_COMPANY_NAME,
  LOAN_ADVANCE_PAYMENT,
  GOODS__PURCHASE_PRICE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { requestDetailsKeys } from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/requestDetailsLabels';
import { financingNeedKeys } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import {
  filterObjectWithKeys,
  getCompany,
  getCustomer,
  getInquiry,
  getEndCustomer,
} from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';

const parseValuesToString = (rawValues) =>
  Object.keys(rawValues).reduce((result, key) => {
    let value = rawValues[key];
    if (typeof value === 'number' || typeof value === 'boolean') {
      value = String(value);
    }

    return { ...result, [key]: value };
  }, {});

const mapAvalField = (normalizedResponse) => {
  const inquiry = getInquiry(normalizedResponse);
  const guarantyType = _get(inquiry, 'attributes.details.guaranty-type');
  return { [guarantyType]: true };
};

const mapCoronaStep = (normalizedResponse) => {
  const inquiry = getInquiry(normalizedResponse);
  const coronaPackage = _get(inquiry, 'attributes.details.corona-package-attributes');
  if (!coronaPackage) {
    return {};
  }
  const company = getCompany(normalizedResponse);

  return {
    [CORONA_REVENUE_OF_2019]: coronaPackage['revenue-2019'],
    [CORONA_LIQUIDITY_NEED]: coronaPackage['liquidity-need'],
    [CORONA_VIABILTIY]: coronaPackage['viability-2019'],
    [CORONA_VIABILTIY_2020]: coronaPackage['viability-2020'],
    [CORONA_FINANCING_OPTION]: coronaPackage['use-quick-credit']
      ? CORONA_FINANCING_OPTION__QUICK_CREDIT
      : CORONA_FINANCING_OPTION__KFW_PROGRAM,
    [CORONA_NUMBER_OF_EMPLOYEES]: coronaPackage['count-of-employees'],
    [CORONA_EXPENDITURE_OF_EMPLOYEES]: coronaPackage['expenditure-for-employees'],
    [CORONA_TOTAL_ASSETS]: coronaPackage['total-assets-greater-than-43-mil'],
    [USER_PROFILE_FOUNDING_YEAR]: _get(company, 'attributes.founding_year'),
    [USER_PROFILE_FOUNDING_MONTH]: _get(company, 'attributes.founding_month'),
    [FINANCING_AMOUNT]: _get(inquiry, 'attributes.details.amount'),
  };
};

const futureAndCurrentUsageMapper = (inquiryDetails, kind) => {
  const usages = inquiryDetails[PROJECT_FINANCING__OBJECT_USAGE].filter(
    (usage) => usage[PROJECT_FINANCING_USAGE_KIND_TYPE] === kind,
  );

  if (
    kind === PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT &&
    !inquiryDetails[PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE]
  ) {
    return [null];
  }
  if (kind === PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE) {
    usages.forEach(
      (usageObj) =>
        (usageObj[PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE] =
          usageObj[PROJECT_FINANCING_TYPE_OF_USAGE]),
    );
  } else {
    usages.forEach(
      (usageObj) =>
        (usageObj[PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT] =
          usageObj[PROJECT_FINANCING_TYPE_OF_USAGE]),
    );
  }

  return usages;
};

const mapFinancingNeed = (normalizedResponse) => {
  const inquiry = getInquiry(normalizedResponse);
  const { attributes } = inquiry;
  if (attributes.form_type === InquiryType.profiRLL) {
    attributes.details[PROJECT_FINANCING__OBJECT_FUTURE_USAGE] = futureAndCurrentUsageMapper(
      attributes.details,
      PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
    );
    attributes.details[PROJECT_FINANCING__OBJECT_USAGE] = futureAndCurrentUsageMapper(
      attributes.details,
      PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
    );
  }
  return parseValuesToString(
    filterObjectWithKeys(inquiry.attributes.details, financingNeedKeys(attributes?.form_type)),
  );
};

const mapUserProfile = (normalizedResponse) => {
  const customer = getCustomer(normalizedResponse);
  const inquiry = getInquiry(normalizedResponse);

  return {
    [COMPANY_DETAILS_GENDER]:
      customer.attributes.salutation === GENDER_MAN
        ? COMPANY_DETAILS_GENDER__MAN
        : COMPANY_DETAILS_GENDER__WOMAN,
    [COMPANY_DETAILS_FIRST_NAME]: customer.attributes.first_name,
    [COMPANY_DETAILS_LAST_NAME]: customer.attributes.last_name,
    [COMPANY_DETAILS_EMAIL]: customer.attributes.email,
    [VB_NUMBER]: _get(customer, 'attributes.vb_number'),
    [VM_NUMBER]: _get(inquiry, 'attributes.details.vm-number'),
    [COMPANY_DETAILS_CONSENT]: true,
    [CONTACT_PERSON_SECURITY_CONSENT]: true,
    [CONTACT_PERSON_EMAIL]: customer.attributes.email,
    [CONTACT_PERSON_FIRST_NAME]: customer.attributes.first_name,
    [CONTACT_PERSON_LAST_NAME]: customer.attributes.last_name,
    [CONTACT_PERSON_PHONE]: _get(customer, 'attributes.phone_number'),
    [CONTACT_PERSON_GENDER]: customer.attributes.salutation,
    [COMPANY_DETAILS_GENDER]: customer.attributes.salutation,
    [USER_PROFILE_PHONE_NUMBER]: customer.attributes.phone_number,
  };
};

const mapExcludedBanks = (inquiryAttributes) => {
  const banks = _get(inquiryAttributes, ['details', 'excluded-bank-codes']);
  if (!Array.isArray(banks)) {
    return [];
  }
  return banks.map((bank) => ({
    key: bank.name,
    label: bank.name,
    value: bank.value,
  }));
};

const furtherNeeds = (furtherNeedsValues) => {
  const FN_List = [];
  if (furtherNeedsValues) {
    furtherNeedsValues.forEach((value) => {
      if (value === OTHER_FINANCING_SOLUTIONS) {
        FN_List[OTHER_FINANCING_SOLUTIONS] = true;
      }
      if (value === PUBLIC_FUNDING) {
        FN_List[PUBLIC_FUNDING] = true;
      }
      if (value === PRODUCT_KIND__LEASING) {
        FN_List[PRODUCT_KIND__LEASING] = true;
      }
      if (value === FUNDRAISING) {
        FN_List[FUNDRAISING] = true;
      }
      if (value === INVESTMENT_AND_SECURITIES) {
        FN_List[INVESTMENT_AND_SECURITIES] = true;
      }
      if (value === PAYMENT_TRANSACTIONS) {
        FN_List[PAYMENT_TRANSACTIONS] = true;
      }
      if (value === EXPERT_ADVICE_ON_ALL) {
        FN_List[EXPERT_ADVICE_ON_ALL] = true;
      }
      if (value === EXPERT_ADVICE_ON_ALL) {
        FN_List[EXPERT_ADVICE_ON_ALL] = true;
      }
      if (value === PRODUCT_KIND__FACTORING) {
        FN_List[PRODUCT_KIND__FACTORING] = true;
      }
    });
  }
  return FN_List;
};

const mapRequestDetails = (normalizedResponse) => {
  const { attributes } = getInquiry(normalizedResponse);

  return {
    ...filterObjectWithKeys(attributes.details, requestDetailsKeys),
    [LOAN_ADVANCE_PAYMENT]:
      _get(attributes.details, GOODS__PURCHASE_PRICE) - _get(attributes.details, 'amount'),
    [EXCLUDED_BANKS]: mapExcludedBanks(attributes),
    [SECURITIES]: attributes.collaterals.map((item) => parseValuesToString(item)),
    [FURTHER_NEED]: _get(attributes.details, FURTHER_NEED),
    [FUNDING_CLOSING_DATE]: _get(attributes.details, FUNDING_CLOSING_DATE),
    ...furtherNeeds(_get(attributes.details, FURTHER_NEED_VALUES)),
  };
};

const mapCompany = (normalizedResponse) => {
  const inquiry = getInquiry(normalizedResponse);
  const company = getCompany(normalizedResponse);
  const customer = getCustomer(normalizedResponse);
  const { attributes: endCustomerAttributes } = getEndCustomer(normalizedResponse) || {};
  return {
    [COMPANY_DETAILS_EXISTING_USER_COMPANY]: {
      [EXISTING_USER_COMPANY_ALREADY_CUSTOMER]: _get(company, 'attributes.already_customer'),
      [EXISTING_USER_COMPANY_ID]: _get(company, 'id'),
      [EXISTING_USER_COMPANY_CITY]: _get(company, 'attributes.city'),
      [EXISTING_USER_COMPANY_CREFO_ID]: _get(company, 'attributes.crefo_id'),
      [EXISTING_USER_COMPANY_NAME]: _get(company, 'attributes.name'),
      [EXISTING_USER_COMPANY_STREET]: _get(company, 'attributes.street'),
      [EXISTING_USER_COMPANY_ZIP_CODE]: _get(company, 'attributes.zip_code'),
      [EXISTING_USER_COMPANY_FOUNDING_YEAR]: _get(company, 'attributes.founding_year'),
      [EXISTING_USER_COMPANY_FOUNDING_MONTH]: _get(company, 'attributes.founding_month'),
      [EXISTING_USER_COMPANY_LEGAL_FORM]: _get(company, 'attributes.legal_form'),
      [EXISTING_USER_COMPANY_TURNOVER_CLASS]: _get(company, 'attributes.turnover_class'),
      [EXISTING_USER_COMPANY_INDUSTRY]: _get(company, 'attributes.industry'),
      [INCOME_SURPLUS]: _get(company, 'attributes.revenue_surplus_account'),
    },
    [USER_PROFILE_PHONE_NUMBER]: _get(customer, 'attributes.phone_number'),
    [CONTACT_PERSON_PHONE]: _get(customer, 'attributes.phone_number'),
    [USER_PROFILE_FOUNDING_YEAR]: _get(company, 'attributes.founding_year'),
    [USER_PROFILE_FOUNDING_MONTH]: _get(company, 'attributes.founding_month'),
    [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: _get(company, 'attributes.already_customer'),
    [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]: _get(
      inquiry,
      'attributes.details.additional-association-company',
    ),
    [USER_PROFILE_INDUSTRY]:
      inquiry.attributes.details.industry ||
      (_get(company, 'attributes.industry') && _get(company, 'attributes.industry')[0]),

    [USER_PROFILE_COMPANY_STREET]: _get(company, 'attributes.street'),
    [USER_PROFILE_COMPANY_ZIP_CODE]: _get(company, 'attributes.zip_code'),
    [USER_PROFILE_COMPANY_CITY]: _get(company, 'attributes.city'),
    [USER_PROFILE_COMPANY_NAME]: _get(company, 'attributes.name'),

    [USER_PROFILE_LEGAL_FORM]: _get(company, 'attributes.legal_form'),
    [USER_PROFILE_TURNOVER_CLASS]: _get(company, 'attributes.turnover_class'),
    [USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: _get(
      inquiry,
      'attributes.details.annual-net-profit-or-loss-class',
    ),
    [USER_PROFILE_NEGATIVE_EQUITY_CAPITAL]: _get(
      inquiry,
      'attributes.details.negative-equity-capital',
    ),
    [USER_PROFILE_INNOVATIVE_COMPANY]: _get(inquiry, 'attributes.details.innovative-company'),
    [USER_PROFILE_ENERGY_EFFICIENT]: _get(inquiry, 'attributes.details.energy-efficient'),
    [INCOME_SURPLUS]: _get(company, 'attributes.revenue_surplus_account'),

    [END_USER_GENDER]: _get(endCustomerAttributes, 'salutation'),
    [END_USER_FIRST_NAME]: _get(endCustomerAttributes, 'first_name'),
    [END_USER_LAST_NAME]: _get(endCustomerAttributes, 'last_name'),
    [END_USER_EMAIL]: _get(endCustomerAttributes, 'email'),
    [END_USER_PHONE_NUMBER]: _get(endCustomerAttributes, 'phone_number') || undefined,
  };
};

export const mapInquiryEditFromApiInterface = (rawResponse) => {
  const normalizedResponse = normalize(rawResponse, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  });

  return {
    [FINANCING_NEED]: {
      ...mapFinancingNeed(normalizedResponse),
      ...parseValuesToString(mapCoronaStep(normalizedResponse)),
      ...mapAvalField(normalizedResponse),
    },
    [COMPANY_DETAILS]: parseValuesToString(mapCompany(normalizedResponse)),
    [REQUEST_DETAILS]: parseValuesToString(mapRequestDetails(normalizedResponse)),
    [USER_PROFILE]: mapUserProfile(normalizedResponse),
    [CONTACT_PERSON_DETAILS]: mapUserProfile(normalizedResponse),
  };
};
