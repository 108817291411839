import { HausbankFieldTypes } from 'hausbank/inquiry/fields';

type HausbankProjectInformationFieldTypes =
  HausbankFieldTypes['objectInformationPage']['projectInformationSection'];

export const mapHausbankProjectInformation = (values: HausbankProjectInformationFieldTypes) => ({
  residential_number_of_units: values.residentialUnitsCount,
  commercial_number_of_units: values.commercialUnitsCount,
  more_than_one_third_of_shares: values.hasOwnerWithMoreThanThirdOfShares,
  property_developer: values.isContractor,
  owner_shares: values.majorityOwnerShares,
  subcommunity: values.wegIsSubCommunity,
  leasehold_property: values.propertyIsLeasehold,
  majority_decision: values.hasMajorityDecision,
});
