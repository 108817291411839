import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CaretUpIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M16 15a1 1 0 01-.71-.29L12 11.41l-3.29 3.3a1 1 0 11-1.42-1.42l4-4a1 1 0 011.42 0l4 4a1 1 0 010 1.42A1 1 0 0116 15z" />
    </Icon>
  );
};

export default CaretUpIcon;
