import React, { forwardRef } from 'react';

import { bool, func, string } from 'prop-types';

import { SelectContext } from 'components/Selects/shared';

import StyledInput from './StyledInput';

const TextInput = forwardRef(
  ({ sideSymbol, name, preventEnterSubmit, autoComplete, label, ...restProps }, ref) => (
    <StyledInput
      data-testid={name}
      sideSymbol={sideSymbol}
      type="text"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && preventEnterSubmit) e.preventDefault();
      }}
      {...restProps}
      data-name={name}
      autoComplete={autoComplete}
      aria-label={label}
      ref={ref}
      name={name}
    />
  ),
);

TextInput.propTypes = {
  label: string,
  sideSymbol: func,
  name: string,
  autoComplete: string,
  preventEnterSubmit: bool,
};

TextInput.defaultProps = {
  label: null,
  sideSymbol: () => null,
  name: null,
  autoComplete: 'off',
  preventEnterSubmit: true,
};

export default (props) => (
  <SelectContext.Consumer>{(ref) => <TextInput {...props} ref={ref} />}</SelectContext.Consumer>
);
