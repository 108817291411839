import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  COMPANY_DETAILS_COMPANY,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CREFO_ID,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_FOUNDING_MONTH,
  INCOME_SURPLUS,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  PARTNER_SPECIFIC_IBAN,
  CONTRACTOR_COMPANY_INFORMATION,
  CLIENT_ROLE,
  CLIENT_ROLE__CONTRACTOR,
  USER_PROFILE_COMPANY_RATING_KIND,
  USER_PROFILE_COMPANY_INTERNAL_RATING,
  USER_PROFILE_COMPANY_RATING_KIND__INTERNAL,
  USER_PROFILE_FOUNDING_TWO_YEAR_OLD,
  COMPANY_DETAILS_DATA_SECURITY,
  COMPANY_DETAILS_CONSENT,
  USER_PROFILE_LEGAL_FORM__OTHER,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { shouldShowFoundingMonth } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';

const { toInteger, toBoolean } = fieldParsers;
export const mapBfssCompanyDetailsToApi: InquiryFormMapper = (allFields) => {
  const selectedAlreadyAssignedCompany =
    allFields[COMPANY_DETAILS_EXISTING_USER_COMPANY] &&
    allFields[COMPANY_DETAILS_EXISTING_USER_COMPANY] !==
      COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY;

  const shouldSendFoundingMonth = shouldShowFoundingMonth(
    toInteger(allFields[USER_PROFILE_FOUNDING_YEAR]),
  );

  if (selectedAlreadyAssignedCompany) {
    return {
      'company-attributes': {
        id: allFields[COMPANY_DETAILS_EXISTING_USER_COMPANY].id,
        'revenue-surplus-account': toBoolean(allFields[INCOME_SURPLUS]),
        ...conditionalObject(allFields[CLIENT_ROLE] === CLIENT_ROLE__CONTRACTOR, {
          contractor_company_information: allFields[CONTRACTOR_COMPANY_INFORMATION],
        }),
      },
    };
  }

  const alreadyExistingCompany = allFields[COMPANY_DETAILS_COMPANY] || {};
  const isAlreadyCustomer = toBoolean(allFields[USER_PROFILE_COMPANY_ALREADY_CUSTOMER]);
  const industries = [allFields[USER_PROFILE_INDUSTRY]].flat().filter((item) => item !== undefined);

  return {
    'company-attributes': {
      name: alreadyExistingCompany.name || allFields[USER_PROFILE_COMPANY_NAME],
      street: alreadyExistingCompany.street || allFields[USER_PROFILE_COMPANY_STREET],
      'zip-code': alreadyExistingCompany['zip-code'] || allFields[USER_PROFILE_COMPANY_ZIP_CODE],
      'crefo-id': alreadyExistingCompany['crefo-id'] || allFields[USER_PROFILE_COMPANY_CREFO_ID],
      city: alreadyExistingCompany.city || allFields[USER_PROFILE_COMPANY_CITY],
      industry: industries,
      'legal-form': USER_PROFILE_LEGAL_FORM__OTHER,
      'legal-form-description': allFields[USER_PROFILE_LEGAL_FORM_DESCRIPTION],
      'founding-year': toInteger(allFields[USER_PROFILE_FOUNDING_YEAR]),
      'turnover-class': allFields[USER_PROFILE_TURNOVER_CLASS],
      'already-customer': toBoolean(allFields[USER_PROFILE_COMPANY_ALREADY_CUSTOMER]),
      'revenue-surplus-account': toBoolean(allFields[INCOME_SURPLUS]),
      ...conditionalObject(isAlreadyCustomer && allFields[PARTNER_SPECIFIC_IBAN], {
        'account-iban': allFields[PARTNER_SPECIFIC_IBAN] || null,
      }),

      ...conditionalObject(shouldSendFoundingMonth, {
        'founding-month': toInteger(allFields[USER_PROFILE_FOUNDING_MONTH]),
      }),

      ...conditionalObject(allFields[CLIENT_ROLE] === CLIENT_ROLE__CONTRACTOR, {
        contractor_company_information: allFields[CONTRACTOR_COMPANY_INFORMATION],
      }),

      'rating-kind': allFields[USER_PROFILE_COMPANY_RATING_KIND],
      ...conditionalObject(
        allFields[USER_PROFILE_COMPANY_RATING_KIND] === USER_PROFILE_COMPANY_RATING_KIND__INTERNAL,
        {
          'internal-rating': allFields[USER_PROFILE_COMPANY_INTERNAL_RATING],
        },
      ),
      [USER_PROFILE_FOUNDING_TWO_YEAR_OLD]: toBoolean(
        allFields[USER_PROFILE_FOUNDING_TWO_YEAR_OLD],
      ),
      [COMPANY_DETAILS_DATA_SECURITY]: toBoolean(allFields[COMPANY_DETAILS_DATA_SECURITY]),
      [COMPANY_DETAILS_CONSENT]: toBoolean(allFields[COMPANY_DETAILS_CONSENT]),
    },
  };
};
