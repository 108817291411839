import { get as _get } from 'lodash';
import moment from 'moment';

import { HausbankFieldTypes, hausbankFields } from 'hausbank/inquiry/fields';
import { MIN_BUILDING_YEAR_ISO } from 'modules/Inquiry/inquiry.constants';

const { constructionYear } = hausbankFields.objectInformationPage.objectInformationSection;

export const getLastModernizationMin = (values: HausbankFieldTypes) =>
  moment(_get(values, constructionYear, MIN_BUILDING_YEAR_ISO)).utc().toDate();
