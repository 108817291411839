import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { purposeKind, projectDescription } = hausbankFields.financingNeedPage.purposeSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.financingNeed.sections.purpose;

export const purposeSectionLabelsMap: LabelsMap = {
  [purposeKind]: fieldTranslations.purposeKind.caption,
  [projectDescription]: fieldTranslations.projectDescription.caption,
};
