import { FeatureFlagsRepo } from './featureFlags/FeatureFlagsRepo';
import { IFeatureFlagsRepo } from './featureFlags/types';
import { FormConfigRepo } from './formConfig/FormConfigRepo';
import { IFormConfigRepo } from './formConfig/types';
import { PartnerRepo } from './partner/PartnerRepo';
import { IPartnerRepo } from './partner/types';

export class ConfigController {
  public static partner: IPartnerRepo = new PartnerRepo();
  public static featureFlags: IFeatureFlagsRepo = new FeatureFlagsRepo();
  public static form: IFormConfigRepo = new FormConfigRepo();
}
