import { useCallback } from 'react';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const requestValues = (request: any) =>
  callReverseApi({
    url: endpoints.INQUIRIES.INTEREST_RATE.compose(),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          ...request,
        },
      },
    },
  });

export const useRequestInterestRateAndInstallment = () => {
  const { makeCall, isPending } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });
  const requestInterestRateAndInstallment = useCallback(
    async (request: any) => {
      const { payload, error } = await makeCall(requestValues(request));
      if (!error) {
        return payload;
      }
      return error;
    },
    [makeCall],
  );

  return { isPending, requestInterestRateAndInstallment };
};
