import React, { useState } from 'react';

import { chakra, Heading } from '@chakra-ui/react';
import { Form as FinalForm } from 'react-final-form';

import { useMe } from 'api/CompeonReverseApi/customer/queryHooks/users';
import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import PortalPage from 'components/PortalPage';
import { IUserData } from 'models/UserData.model';
import NewUserDataForm from 'modules/UserData/components/NewUserDataForm';
import StaticUserDataForm from 'modules/UserData/components/StaticUserDataForm';
import { useUpdateUserData } from 'modules/UserData/helpers/useUpdateUserData';
import { StyledHr } from 'modules/UserData/styles';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import ResetPasswordSection from './ResetPasswordSection';

const { heading, subheading } = translations.pages.userData;

const UserDataPage = () => {
  const { data } = useMe({
    suspense: true,
    select: (response) => ({
      ...response?.data.data.attributes,
      language: response?.data.data.attributes.locale,
      gender: response?.data.data.attributes.salutation,
    }),
  });

  const [isSending, setIsSending] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const t = useTranslations();
  const { changeLanguage, isMultipleLanguagesEnabled, selectedLanguage } = useLanguages();

  const updateUserData = useUpdateUserData();
  const sendUpdateToApi = async (values: IUserData) => {
    setIsSending(true);
    await updateUserData(values);
    if (isMultipleLanguagesEnabled && selectedLanguage !== values.language) {
      changeLanguage(values.language);
    }
    setIsEditMode((mode) => !mode);
    setIsSending(false);
  };

  if (!data) {
    return null;
  }

  return (
    <PortalPage title={t(heading)} pageTitle={t(heading)}>
      <FinalForm
        onSubmit={sendUpdateToApi}
        initialValues={data}
        render={({ handleSubmit }) => (
          <chakra.section marginBottom={8}>
            <Heading as="h3" marginBottom={7} variant="secondary">
              {t(subheading)}
            </Heading>
            <form onSubmit={handleSubmit}>
              {isEditMode ? (
                <NewUserDataForm isLoading={isSending} isEditMode={isEditMode} />
              ) : (
                <StaticUserDataForm userData={data} onEdit={() => setIsEditMode(true)} />
              )}
            </form>
          </chakra.section>
        )}
      />
      <StyledHr />
      <ResetPasswordSection email={data.email} />
    </PortalPage>
  );
};

export default UserDataPage;
