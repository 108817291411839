import * as React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';

import { translations } from '../../../../../../new/form/common/types';
import { TextComponent } from '../../../../../../theme/components/Text';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';

export const ParkingData = () => {
  const t = useTranslations();
  const { outdoorParkingPlaces, garageParkingPlaces } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.subheadings;

  const {
    parkingLotPrice: parkingLotPriceTranslations,
    parkingLotRentNow: parkingLotRentNowTranslations,
    parkingLotRentFuture: parkingLotRentFutureTranslations,
    garagesPrice: garagesPriceTranslations,
    garageRentNow: garageRentNowTranslations,
    garageRentFuture: garageRentFutureTranslations,
  } = translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields;

  const {
    parkingLotPrice,
    parkingLotsCount,
    parkingLotRentFuture,
    parkingLotRentNow,
    garageRentNow,
    garageRentFuture,
    garagesPrice,
    garagesCount,
  } = mittweidaFields.financingNeedPage.objectInformationSection;

  return (
    <>
      <TextComponent>{t(outdoorParkingPlaces)}</TextComponent>
      <FormRow>
        <InputWithField name={parkingLotsCount} type={'number'} />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={parkingLotPrice}
          tooltip={t(parkingLotPriceTranslations.tooltip)}
          optional
        />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={parkingLotRentNow}
          tooltip={t(parkingLotRentNowTranslations.tooltip)}
          optional
        />
        <AmountWithField
          name={parkingLotRentFuture}
          tooltip={t(parkingLotRentFutureTranslations.tooltip)}
          optional
        />
      </FormRow>
      <TextComponent>{t(garageParkingPlaces)}</TextComponent>
      <FormRow>
        <InputWithField name={garagesCount} type={'number'} />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={garagesPrice}
          tooltip={t(garagesPriceTranslations.tooltip)}
          optional
        />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={garageRentNow}
          tooltip={t(garageRentNowTranslations.tooltip)}
          optional
        />
        <AmountWithField
          name={garageRentFuture}
          tooltip={t(garageRentFutureTranslations.tooltip)}
          optional
        />
      </FormRow>
    </>
  );
};
