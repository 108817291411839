import { useCallback, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  useDownloadPdf,
  useUpdateProfile,
  useDeleteImage,
} from 'api/CompeonReverseApi/operation/queryHooks';
import { IProfitabilityAttributes, IProjectImages } from 'api/CompeonReverseApi/operation/types';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import { MAX_FILE_SIZE } from 'constants/file';
import queryKeys from 'constants/queryKeys';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { PropertyProfileValues } from '../pages/PropertyProfile';
import type { ObjectImage } from '../pages/PropertyProfile/UploadObjectImages';
import type { FinancingRole, UsageType } from '../types';

interface IPlanningEvaluationProfitabilityResponse {
  data: {
    attributes: IProfitabilityAttributes;
  };
  included: IProjectImages[];
}

const useDownloadPropertyProfilePdf = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.propertyProfile.download');
  const { error } = useToasts();
  const downloadPdfMutation = useDownloadPdf({
    onError: () => {
      error({ description: t('error') });
    },
  });

  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  return useCallback(
    async (financingRole: FinancingRole, usageType: UsageType) => {
      const res = await downloadPdfMutation.mutateAsync({
        financingRole,
        usageType,
        inquiryId,
      });

      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      });
      saveAs(blob, `${t('fileName')}.pdf`);
    },
    [inquiryId, downloadPdfMutation, t],
  );
};

const useUpdatePropertyProfile = () => {
  const updateProfileMutation = useUpdateProfile();
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  return useCallback(
    async (values: PropertyProfileValues) =>
      await updateProfileMutation.mutateAsync({
        data: {
          source_of_funds: {
            notes_on_financing_plan: values.notesOnFinancingPlan,
          },
          financing_details: {
            notes_on_financing_parameters: values.notesOnFinancingParameters,
          },
          rent: {
            notes_on_debt_service: values.notesOnDebtService,
          },
          financing_criteria: {
            notes_on_financing_criteria: values.notesOnFinancingCriteria,
          },
          lending_value: {
            notes_on_remaining_risk: values.notesOnLendingValueRemainingRisk,
          },
          additional_notes: {
            notes_on_customer: values.notesOnCustomer,
            description_of_financing_project: values.descriptionOfFinancingProject,
            notes_on_object: values.notesOnObject,
          },
        },
        inquiryId,
      }),
    [inquiryId, updateProfileMutation],
  );
};

const useDeleteObjectImage = () => {
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.propertyProfile.delete');
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';
  const queryClient = useQueryClient();
  const { error } = useToasts();
  const deleteObjectImageMutation = useDeleteImage({
    onMutate: async (variables) => {
      queryClient.setQueryData(
        [queryKeys.operation.profitabilityData, { inquiryId: inquiryId }],
        (prevData: IPlanningEvaluationProfitabilityResponse | undefined) => {
          if (prevData) {
            const filteredIncluded = prevData.included.filter(
              (item) => item.id !== variables.fileId,
            );
            return { ...prevData, included: filteredIncluded };
          }
          return prevData;
        },
      );
    },
    onError: () => {
      error({ description: t('error') });
    },
  });

  return useCallback(
    async (fileId: string) => {
      deleteObjectImageMutation.mutate({
        inquiryId,
        fileId,
      });
    },
    [inquiryId, deleteObjectImageMutation],
  );
};
const useDownloadObjectImage = (image?: ObjectImage) =>
  useCallback(() => {
    if (!image) return;
    saveAs(image.url);
  }, [image]);

export type ObjectImageType = 'object_photo_current' | 'object_visualization';
const ACCEPT_OBJECT_IMAGES = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

const MAX_IMAGE_WIDTH = 500;
const MAX_IMAGE_HEIGHT = 500;

const isImageDimensionsValid = (file: File) =>
  new Promise<boolean>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () =>
      resolve(image.width <= MAX_IMAGE_WIDTH && image.height <= MAX_IMAGE_HEIGHT);
    image.onerror = () => resolve(false);
  });

export const usePropertyProfileObjectImage = (
  imageType: ObjectImageType,
  objectImages: ObjectImage[],
) => {
  const [isUploading, setIsUploading] = useState(false);
  const inquiryId = useSelector(getInquiryIdSelector) ?? '';
  const image = objectImages.find((image) => image.imageType === imageType);
  const { error } = useToasts();
  const queryClient = useQueryClient();
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.propertyProfile.sections.upload',
  );

  const { startUpload } = useNewUploadFile(
    endpoints.INQUIRIES.SPECIALIZED.UPLOAD_OBJECT_IMAGES.compose({ params: { inquiryId } }),
  );
  const deleteImage = useDeleteObjectImage();
  const downloadImage = useDownloadObjectImage(image);
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    accept: ACCEPT_OBJECT_IMAGES,
    maxSize: MAX_FILE_SIZE,
    onDrop: async (acceptedFiles) => {
      if (!acceptedFiles.length) return;
      setIsUploading(true);
      try {
        const [file] = acceptedFiles;
        // unfortunately, we can't use the vaildator prop from useDropzone here because it doesn't work with async functions
        if (!(await isImageDimensionsValid(file))) {
          error({ description: t('error') });
          return;
        }
        const res = await startUpload(file, { image_type: imageType });
        if (res.error) {
          error({ description: t('error') });
          return;
        }
        await queryClient.invalidateQueries([queryKeys.operation.profitabilityData]);
      } catch {
        error({ description: t('error') });
      } finally {
        setIsUploading(false);
      }
    },
  });

  return { getRootProps, getInputProps, open, isUploading, deleteImage, downloadImage, image };
};

export const usePropertyProfile = () => {
  const updateValues = useUpdatePropertyProfile();
  const downloadPdf = useDownloadPropertyProfilePdf();
  return { updateValues, downloadPdf };
};
