import React from 'react';

import {
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  INCOME_SURPLUS,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  COMPANY_DETAILS_COMPANY,
  USER_PROFILE_PHONE_NUMBER,
  BFS_USER_PROFILE_INDUSTRY,
  USER_PROFILE_COMPANY_NAME,
} from 'modules/Inquiry/Form/formFields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useTranslations } from 'utils/hooks/useTranslations';

const PARTNER_EXCLUDED_FIELDS = [
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  INCOME_SURPLUS,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_PHONE_NUMBER,
  BFS_USER_PROFILE_INDUSTRY,
  USER_PROFILE_COMPANY_NAME,
];

const OnlineFactoringCompanyDetailsSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.COMPANY,
    includeFields: [COMPANY_DETAILS_COMPANY],
    excludedFields: PARTNER_EXCLUDED_FIELDS,
  });
  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('companyDetails')}>
      <InformationRows fields={fields} />
    </Section>
  );
};

export default OnlineFactoringCompanyDetailsSection;
