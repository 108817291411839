import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { resetForm, clearStages } from 'store/progress/actions';
import { removeObjectFromSessionStorage } from 'utils/sessionStorage/helpers';
import {
  COMPANY_DETAILS,
  FINANCING_NEED,
  PEOPLE_DETAILS,
  REQUEST_DETAILS,
  INSTALMENT_PROCESS,
  FORM_VALIDITY,
  CONTRACT_DETAILS,
  CUSTOMER_DATA,
  FORM_VALUES,
  CONTACT_PERSON_DETAILS,
  USER_PROFILE,
} from 'utils/sessionStorage/keys';
import { resetUser } from 'utils/user/setters';

// returns function to clear form values
export const useFormCleaner = (isLoggedIn) => {
  const shouldResetForm = useSelector((store) => store.progress.shouldResetForm);
  const dispatch = useDispatch();

  return useCallback(
    (form) => {
      if (shouldResetForm) {
        form.reset();
        dispatch(resetForm(false));
        dispatch(clearStages());
        removeObjectFromSessionStorage(FINANCING_NEED);
        removeObjectFromSessionStorage(COMPANY_DETAILS);
        removeObjectFromSessionStorage(PEOPLE_DETAILS);
        removeObjectFromSessionStorage(REQUEST_DETAILS);
        removeObjectFromSessionStorage(CONTACT_PERSON_DETAILS);
        removeObjectFromSessionStorage(INSTALMENT_PROCESS);
        removeObjectFromSessionStorage(FORM_VALIDITY);
        removeObjectFromSessionStorage(CONTRACT_DETAILS);
        removeObjectFromSessionStorage(CUSTOMER_DATA);
        removeObjectFromSessionStorage(USER_PROFILE);
        removeObjectFromSessionStorage(FORM_VALUES);

        /*
         * BigPicture inquiry has temporary AccessToken which is stored in the user store. This should
         * be reset after inquiry was sent
         */
        if (!isLoggedIn) {
          resetUser();
        }
      }
    },
    [dispatch, isLoggedIn, shouldResetForm],
  );
};
