import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { ICompeonOffer } from 'models/CompeonOffer.model';
import AcceptOfferBtn from 'modules/Offers/InquiryOffersSection/AcceptOffer/AcceptOfferButton';
import { CompeonOfferWithQuestionnaire } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/CompeonOfferWithQuestionnaire';
import { finishQuestionnaireCall } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import { OfferWithoutQuestionnaire } from 'modules/Offers/InquiryOffersSection/CompeonOffers/OfferWithoutQuestionnaire/OfferWithoutQuestionnaire';
import { useToasts } from 'shared/hooks/useToasts';
import { acceptBankOffer, acceptCompeonOfferAction } from 'store/inquiryDetails/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  disabled?: boolean;
  offer: ICompeonOffer;
  bankName: string;
}

const AcceptOffer = ({ offer, bankName, disabled }: Props) => {
  const t = useTranslations('pages.inquiryDetails.offers');
  const modalTitle = `${t('acceptOffer')} – ${bankName}`;

  const questionnaireId = offer.questionnaireIds && offer.questionnaireIds[0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const dispatch = useDispatch();

  const handleOfferAccepted = async () => {
    if (questionnaireId) {
      await makeCall(finishQuestionnaireCall(questionnaireId));
    }

    await makeCall(acceptBankOffer(offer.id), () => {
      success({ description: t('offerAccepted') });
      dispatch(acceptCompeonOfferAction(offer.id));
      onClose();
    });
  };

  return (
    <>
      {!disabled && <AcceptOfferBtn disabled={disabled} onClick={onOpen} />}
      {questionnaireId ? (
        <CompeonOfferWithQuestionnaire
          offer={offer}
          onAccepted={handleOfferAccepted}
          modalTitle={modalTitle}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : (
        <OfferWithoutQuestionnaire
          confirmText={t('acceptOffer')}
          testId="accept-offer-confirm"
          isOpen={isOpen}
          onClose={onClose}
          handleShowInterest={handleOfferAccepted}
          modalTitle={modalTitle}
          offer={offer}
          bankName={bankName}
        />
      )}
    </>
  );
};

export default AcceptOffer;
