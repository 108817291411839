import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { StyledNavLink } from 'modules/Header/NavigationLink/styles';
import { showRwdMenu } from 'store/layout/actions';

const NavigationLink = ({ icon, url, label }) => {
  const dispatch = useDispatch();
  return (
    <StyledNavLink>
      <NavLink activeClassName="active" to={url} onClick={() => dispatch(showRwdMenu(false))}>
        {icon}
        {label}
      </NavLink>
    </StyledNavLink>
  );
};

NavigationLink.defaultProps = {
  icon: null,
};

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default NavigationLink;
