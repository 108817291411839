import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { validateBeneficiaryOwnerSection } from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwnersSection';
import { validateLegalRepresentationSection } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentationSection';
import { isEmailAvailableSelector } from 'store/emailAvailable/selectors';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { isLoggedInUser, userIsBankAdvisor } from 'utils/user/conditionals';

import { getCompanyAssociatedPersonsSelector } from '../../operationPortal/CompaniesDetails/store/selectors';
import { validatePasswordSection } from '../CompanyDetails/PasswordSection/PasswordSection';
import { validateCustomerDetailsSection } from '../CustomerDetails';

export const usePeopleDetailsValidators = () => {
  const isLoggedIn = isLoggedInUser();
  const processLane = useSelector(getProcessLane);
  const associatedPersons = useSelector(getCompanyAssociatedPersonsSelector);
  const customerEmailExists = useSelector(isEmailAvailableSelector);
  const isBA = userIsBankAdvisor();
  const { selectedInquiryType } = useFormConfig();
  const isBFSService = selectedInquiryType === InquiryType.bfsService;

  /*
  If we return an empty array for logged in user the progress bar does not pick it
  up as an additional step, returning a function that returns true adds the step
   */
  const validatePassword = isLoggedIn ? [] : [validatePasswordSection];

  const validateInvatation = isBA
    ? [validateCustomerDetailsSection(customerEmailExists)]
    : isBFSService
    ? [validateCustomerDetailsSection(false)]
    : [() => true];

  const validateLegalRepresentation =
    processLane === InquiryLane.lead
      ? [() => true]
      : selectedInquiryType === InquiryType.leaseplan
      ? [
          validateLegalRepresentationSection(
            associatedPersons.filter((a) => a.type === 'legal_representatives'),
          ),
        ]
      : [validateLegalRepresentationSection(associatedPersons)];

  const validateBeneficiaryOwner =
    selectedInquiryType === InquiryType.leaseplan
      ? [validateBeneficiaryOwnerSection()]
      : [() => true];

  if (isBFSService) return () => [...validatePassword, ...validateInvatation];

  return () => [
    ...validatePassword,
    ...validateLegalRepresentation,
    ...validateInvatation,
    ...validateBeneficiaryOwner,
  ];
};
