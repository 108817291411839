import React from 'react';

import { Text } from '@chakra-ui/react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasValue, fieldIsNotEmpty, fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  NO_BENEFICIARY_CHECKBOX,
} from 'modules/Inquiry/Form/formFields';
import BeneficiaryOwners from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/BeneficiaryOwners/BeneficiaryOwners';
import ConfirmNoUserBeneficiary from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/fields/ConfirmNoUserBeneficiary';
import HasBeneficiaryRadio from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/fields/HasBeneficiaryRadio';
import IsBeneficiaryOwnerRadio from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/fields/IsBeneficiaryOwnerRadio';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

interface PropsTypes {
  sectionNumber: number;
}

export const validateBeneficiaryOwnerSection = () => (form: any) => {
  if (fieldHasValue(HAS_BENEFICIARY_OWNER, 'false')({ form })) {
    return fieldIsValid([NO_BENEFICIARY_CHECKBOX])({
      form,
    });
  }
  if (fieldHasValue(HAS_BENEFICIARY_OWNER, 'true')({ form })) {
    return fieldIsNotEmpty(IS_BENEFICIARY_OWNER)({ form });
  }
};

const BeneficiaryOwnersSection: React.FC<PropsTypes> = (props) => {
  const t = useTranslations();

  const headerTranslation = t('pages.peopleDetails.sections.beneficiaryOwners.header', undefined, {
    defaultMessage: ' ',
  });
  const showHeader = headerTranslation !== ' ';

  return (
    <FormSection
      name={FormSections.beneficiaryOwners}
      title={t('pages.peopleDetails.sections.beneficiaryOwners.title')}
      sectionNumber={props.sectionNumber}
    >
      {showHeader ? (
        <Text mb={4} color="text.tertiary">
          {headerTranslation}
        </Text>
      ) : null}

      <FormRow>
        <HasBeneficiaryRadio />
      </FormRow>

      <Condition condition={fieldHasValue(HAS_BENEFICIARY_OWNER, 'true')}>
        <FormRow>
          <IsBeneficiaryOwnerRadio />
        </FormRow>
      </Condition>

      <Condition condition={fieldHasValue(HAS_BENEFICIARY_OWNER, 'false')}>
        <FormRow>
          <ConfirmNoUserBeneficiary />
        </FormRow>
      </Condition>

      <Condition condition={fieldHasValue(HAS_BENEFICIARY_OWNER, 'true')}>
        <Condition condition={fieldIsNotEmpty(IS_BENEFICIARY_OWNER)}>
          <BeneficiaryOwners />
        </Condition>
      </Condition>
    </FormSection>
  );
};

export default BeneficiaryOwnersSection;
