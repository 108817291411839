import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import Unit from 'components/Unit/Unit';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { useTranslations } from 'utils/hooks/useTranslations';

export const bfsValidatePurchaseSection = validateSectionFields([
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
]);

const BFSPurchase = () => {
  const t = useTranslations();
  return (
    <FormSection title="" showSectionTitle={false}>
      <FormRow>
        <AmountWithField
          name={REAL_ESTATE_PURCHASE_PRICE}
          placeholder={t(
            'pages.financingNeed.sections.purchase.fields.realEstatePurchasePrice.placeholder',
          )}
        />
      </FormRow>

      <FormRow>
        <InputWithField
          name={REAL_ESTATE_LAND_SIZE}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.QUADRA_METERS} />}
          separateThousands
          optional
          type="number"
        />

        <InputWithField
          name={REAL_ESTATE_BUILDING_SIZE}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.QUADRA_METERS} />}
          optional
          separateThousands
          type="number"
        />
      </FormRow>
      <FormRow>
        <TextAreaWithField
          name={REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION}
          placeholder={t(
            `pages.financingNeed.sections.detailsOfBuilding.fields.financialObjectDescription.placeholder.${REAL_ESTATE_PROJECT__PURCHASE}`,
          )}
        />
      </FormRow>
    </FormSection>
  );
};
export default BFSPurchase;
