import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ArrowRightIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M19 11.3l-4.5-4.5c-.4-.4-1.1-.3-1.4.1-.3.4-.3.9 0 1.3l2.8 2.8H5.7c-.6 0-1 .4-1 1s.4 1 1 1h10.1L13 15.8c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l4.5-4.5c.5-.4.5-1 .1-1.4"
      />
    </Icon>
  );
};

export default ArrowRightIcon;
