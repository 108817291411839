import React from 'react';

import { Flex } from '@chakra-ui/react';

import ButtonComponent from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type PropType = {
  isOpen: boolean;
  onClose: () => void;
  handleSpecializedInquiry: () => Promise<void>;
};

const CalculationConfirmationModal = (props: PropType) => {
  const t = useTranslations('pages.summaryPage.profitabilityModal');
  const tButton = useTranslations('buttons');
  const { isOpen, onClose, handleSpecializedInquiry } = props;
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
      footer={
        <Flex>
          <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
            {tButton('cancel')}
          </ButtonComponent>
          <ButtonComponent onClick={handleSpecializedInquiry} mr={4} variant="primary">
            {tButton('accept')}
          </ButtonComponent>
        </Flex>
      }
    >
      <TextComponent mb="2rem" mt="2rem" color="text.tertiary">
        {t('description')}
      </TextComponent>
    </ModalComponent>
  );
};

export default CalculationConfirmationModal;
