import React from 'react';

import { useHistory } from 'react-router-dom';

import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import ButtonsWrapper from 'pages/inquiryFlow/FinalPage/SuccessButtons/ButtonsWrapper';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const Footer: React.FC = () => {
  const history = useHistory();
  const t = useTranslations();

  const handleNewInquiryButtonClick = () => {
    history.push(paths.inquiry.new.root);
  };

  const { selectedInquiryType } = useFormConfig();
  const isBfs = selectedInquiryType === InquiryType.bfs;

  return (
    <ButtonsWrapper>
      <ButtonComponent onClick={handleNewInquiryButtonClick} variant="secondary">
        {t('pages.finalPage.buttons.new')}
      </ButtonComponent>
      {!isBfs && (
        <ButtonComponent as="a" href={paths.customer.inquiryList}>
          {t('pages.finalPage.buttons.inquiryList')}
        </ButtonComponent>
      )}
    </ButtonsWrapper>
  );
};

export default Footer;
