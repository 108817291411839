export const unknownToString = (value: unknown): string => {
  if (typeof value === 'string') return value;
  else if (typeof value === 'number') return value.toString();
  return '';
};

export const formatDecimalForAPI = (value: any) => {
  const parsedNumber = parseFloat(value);
  const numberToFormat = Number.isFinite(parsedNumber) ? parsedNumber : 0;

  return numberToFormat.toFixed(2);
};
