import React from 'react';

import { string } from 'prop-types';
import { Field } from 'react-final-form';

/*
 * Component needed to trigger subscribe function
 * if ConditionalSubform has no fields
 */

const EmptyField = ({ name }) => <Field name={name} render={() => null} />;

EmptyField.propTypes = {
  name: string,
};

EmptyField.defaultProps = {
  name: 'name',
};

export default EmptyField;
