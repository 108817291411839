import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

const Text = styled.div<{
  inactive?: boolean;
  align?: boolean;
  semibold?: boolean;
  active?: boolean;
}>`
  font-size: 1.125rem;
  ${(props) => props.semibold && `font-weight: ${FONT_WEIGHT_MEDIUM};`};
  line-height: 1.5;
  ${(props) => props.align && `text-align: ${props.align};`};
  white-space: pre-line;
  color: ${(props) => {
    switch (true) {
      case props.inactive:
        return props.theme.colors.text.tertiary;
      case props.active:
        return props.theme.colors.text.primary;
      default:
        return props.theme.colors.primary;
    }
  }};
`;

export default Text;
