import React from 'react';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import { COMPANY_DETAILS_COMPANY, CUSTOMER_BIRTH_DATE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import OnlineFactoringUserProfileSection from './OnlineFactoringUserProfileSection';

const UserProfileSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const excludedFields = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: [COMPANY_DETAILS_COMPANY, CUSTOMER_BIRTH_DATE],
    default: [CUSTOMER_BIRTH_DATE],
  });
  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.USER,
    excludedFields,
  });

  const title = t('userDetails', {
    br: <br />,
  });

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={title}>
      <InformationRows fields={fields} />
    </Section>
  );
};

const DisplayCondition = () => {
  return (
    <InquiryTypeCondition
      portal
      cases={{
        onlinefactoring: <OnlineFactoringUserProfileSection />,
        default: <UserProfileSection />,
      }}
    />
  );
};

export default DisplayCondition;
