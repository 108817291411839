import React, { useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { Form as FinalForm } from 'react-final-form';

import Condition from 'components/Condition';
import { PasswordInputWithField } from 'components/PasswordInput';
import {
  COMPANY_DETAILS_CONSENT,
  CONFIRM_PASSWORD_FIELD,
  PASSWORD_FIELD,
} from 'modules/Inquiry/Form/formFields';
import { FormContainer } from 'modules/Login/FormContainer';
import { translations } from 'new/form/common/types';
import TermsAndConditions from 'pages/inquiryFlow/CompanyDetails/sections/TermAndConditionsField';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import ButtonComponent from 'theme/components/Button';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { usePartnerSpecificPasswordValidators } from 'utils/hooks/usePartnerSpecificPasswordValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import {
  useInquiryTypeSpecificTermsAndConditions,
  useRedirectOnRegistrationSuccess,
} from './hooks';
import { acceptInvitationAction } from './service';

const {
  passwordField,
  confirmPassword,
  confirmBtn,
  heading,
  subHeading,
  submitSuccess,
  submitError,
} = translations.pages.finishRegistration;

interface RegistrationFormValues {
  [PASSWORD_FIELD]: string;
  [CONFIRM_PASSWORD_FIELD]: string;
  [COMPANY_DETAILS_CONSENT]?: boolean;
}

interface RegistrationFormProps {
  invitationToken: string;
}

export const RegistrationForm = ({ invitationToken }: RegistrationFormProps) => {
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);
  const t = useTranslations();
  const { success } = useToasts();
  const { required, equalTo } = useFieldValidators();
  const passwordValidators = usePartnerSpecificPasswordValidators();
  const { makeCall, isPending } = useDispatchApiCall(dispatchOptions(t(submitError)));

  const redirectOnRegistrationSuccess = useRedirectOnRegistrationSuccess();
  const { getInquiryTypeSpecificTerms, shouldDisplayTermsAndConditions } =
    useInquiryTypeSpecificTermsAndConditions();

  const handleFormSubmit = async ({ password, ...values }: RegistrationFormValues) => {
    const hasAcceptedTermsAndConditions = getInquiryTypeSpecificTerms(
      values[COMPANY_DETAILS_CONSENT],
    );

    const response = await makeCall(
      acceptInvitationAction({ invitationToken, password, hasAcceptedTermsAndConditions }),
    );

    if (!response.error) {
      setIsRegistrationCompleted(true);
      success({ description: t(submitSuccess) });
      redirectOnRegistrationSuccess();
    }
  };

  return (
    <FinalForm<RegistrationFormValues>
      onSubmit={handleFormSubmit}
      validate={() => undefined}
      render={({ handleSubmit }) => (
        <FormContainer>
          <FormContainer.HeadingContainer>
            <FormContainer.Heading>{t(heading)}</FormContainer.Heading>
            <FormContainer.SubHeading>{t(subHeading)}</FormContainer.SubHeading>
          </FormContainer.HeadingContainer>
          <FormContainer.Body>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Stack spacing={5}>
                  <PasswordInputWithField
                    name={PASSWORD_FIELD}
                    validate={passwordValidators}
                    caption={t(passwordField.caption)}
                  />
                  <PasswordInputWithField
                    name={CONFIRM_PASSWORD_FIELD}
                    validate={combineValidators(required, equalTo(PASSWORD_FIELD))}
                    caption={t(confirmPassword.caption)}
                  />
                  <Condition condition={shouldDisplayTermsAndConditions}>
                    <TermsAndConditions />
                  </Condition>
                </Stack>
                <Stack spacing={6}>
                  <ButtonComponent
                    data-testid="accept-invitation-btn"
                    disabled={isRegistrationCompleted}
                    isLoading={isPending}
                    type="submit"
                  >
                    {t(confirmBtn)}
                  </ButtonComponent>
                </Stack>
              </Stack>
            </form>
          </FormContainer.Body>
        </FormContainer>
      )}
    />
  );
};
