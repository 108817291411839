import React from 'react';

import PropTypes from 'prop-types';

import { StyledFormGroupTitle } from 'modules/FormGenerator/styles';

const StaticFormTitle = ({ field: { label } }) => (
  <StyledFormGroupTitle>{label}</StyledFormGroupTitle>
);

StaticFormTitle.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default StaticFormTitle;
