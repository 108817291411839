import { useMemo } from 'react';

import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { useFieldUnit } from 'modules/Inquiry/useFieldUnit';
import { extractNestedFieldName } from 'utils/form/getFieldName';
import { useTranslations } from 'utils/hooks/useTranslations';

const unitToPlaceholderMap: { [unit in FIELD_UNIT_TYPE]: string | null } = {
  [FIELD_UNIT_TYPE.MONTHS]: null,
  [FIELD_UNIT_TYPE.THOUSANDS_CURRENCY]: null,
  [FIELD_UNIT_TYPE.SQUARE_METERS]: null,
  [FIELD_UNIT_TYPE.QUADRA_METERS]: null,
  [FIELD_UNIT_TYPE.CURRENCY]: null,
  [FIELD_UNIT_TYPE.PERCENTAGE]: null,
  [FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL]: null,
  [FIELD_UNIT_TYPE.PERCENTAGE_DECIMAL_STRING]: null,
  [FIELD_UNIT_TYPE.DATE]: 'placeholders.pleaseChoose',
  [FIELD_UNIT_TYPE.DATEINPUT]: 'placeholders.dateInput',
  [FIELD_UNIT_TYPE.KILOMETERS]: null,
  [FIELD_UNIT_TYPE.YEARS]: null,
  [FIELD_UNIT_TYPE.from1000]: 'placeholders.from1000',
  [FIELD_UNIT_TYPE.M2M3]: 'placeholders.m2m3',
  [FIELD_UNIT_TYPE.INVOICES]: 'placeholders.pleaseChoose',
  [FIELD_UNIT_TYPE.DAYS]: 'placeholders.pleaseChoose',
  [FIELD_UNIT_TYPE.MONTH_AND_YEAR]: null,
  [FIELD_UNIT_TYPE.CURRENCY_PER_MONTH]: null,
  [FIELD_UNIT_TYPE.DEFAULT_CURRENCY]: null,
};

export const useFieldPlaceholder = (name: string) => {
  const fieldName = extractNestedFieldName(name);

  const unitType = useFieldUnit(fieldName);

  const t = useTranslations();
  const selectedUnitPlaceholder = unitType && unitToPlaceholderMap[unitType];

  return useMemo(
    () => selectedUnitPlaceholder && t(selectedUnitPlaceholder),
    [selectedUnitPlaceholder, t],
  );
};
