import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import {
  useUpdateCustomerData,
  useUpdateOperationUserData,
} from 'api/CompeonReverseApi/customer/queryHooks/users';
import queryKeys from 'constants/queryKeys';
import { IUserData } from 'models/UserData.model';
import { translations } from 'new/form/common/types';
import { useToasts } from 'shared/hooks/useToasts';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsAgent } from 'utils/user/conditionals';
import { updateUserAttributes } from 'utils/user/setters';

const mapUserDataToApi = (userData: IUserData): any => ({
  salutation: userData.gender,
  first_name: userData.firstName,
  last_name: userData.lastName,
  phone_number: userData.phoneNumber,
  locale: userData.language,
});

const { profileUpdated, profileUpdateFailed } = translations.pages.userData;

export const useUpdateUserData = () => {
  const queryClient = useQueryClient();
  const { success, error } = useToasts();
  const t = useTranslations();

  const useUpdateUserData = userIsAgent() ? useUpdateOperationUserData : useUpdateCustomerData;

  const updateUserMutation = useUpdateUserData({
    onSuccess: () => {
      success({ description: t(profileUpdated) });
      queryClient.invalidateQueries([queryKeys.users.me]);
    },
  });

  const updateUserData = useCallback(
    async (formValues: IUserData) => {
      try {
        await updateUserMutation.mutateAsync({ apiValues: mapUserDataToApi(formValues) });
        // We still need values from the redux store to get the language state for the whole app
        updateUserAttributes(formValues);
      } catch {
        error({ description: t(profileUpdateFailed) });
      }
    },
    [error, t, updateUserMutation],
  );

  return (formValues: IUserData) => {
    return updateUserData(formValues);
  };
};
