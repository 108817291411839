export function concatenateKeys<T>(obj: any, parentKey: string = ''): T {
  const result: any = {};
  for (const key in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === 'object') {
        result[key] = concatenateKeys(obj[key], newKey);
      } else {
        result[key] = newKey;
      }
    }
  }
  return result;
}
