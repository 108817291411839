import * as React from 'react';

import { useFormState } from 'react-final-form';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import CompanyNoteSection from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyNoteSection/CompanyNoteSection';
import { UseCompanySuggestionConfig } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useCompanySuggestion';
import { useCompanySuggestion } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useCompanySuggestion';
import { isLoggedInUser } from 'utils/user/conditionals';

import CompanySuggestion from './components/companySuggestion';
import { MittweidaExistingUserCompanySelect } from './components/companySuggestion/MittweidaExistingUserCompanySelect';
import { shouldShowCompanySuggestion } from './shouldShowCompanySuggestion';
import { translations } from '../../../../../../new/form/common/types';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';
import {
  mittweidaFields,
  mittweidaFieldsWithSectionString,
  MittweidaFieldTypes,
} from '../../../../fields';

const { name, street, city, country, state, tradeName, zipCode } =
  mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;
const {
  selectedCompanyName: selectedCompanyNameFieldName,
  loggedInUserCompanyName: loggedInUserCompanyNameFieldName,
} = mittweidaFields.companyDetailPage.companyDetailsSection;

export const CompanyDetailsSection = () => {
  const isLoggedIn = isLoggedInUser();
  const {
    values: {
      companyDetailPage: {
        companyDetailsSection: { loggedInUserCompanyName },
      },
    },
  } = useFormState<MittweidaFieldTypes>();
  const shouldShowSuggestion = shouldShowCompanySuggestion(isLoggedIn, loggedInUserCompanyName);
  const t = useTranslations();

  const config: UseCompanySuggestionConfig = {
    companySearchSectionString:
      mittweidaFieldsWithSectionString.companyDetailPage.companyDetailsSection.sectionString,
    companySearchFieldNames: {
      selectedCompanyNameFieldName,
      loggedInUserCompanyNameFieldName,
    },
    companyCompletionFieldNames: {
      name,
      address: street,
      street,
      city,
      country,
      state,
      tradeName,
      zipCode,
    },
  };
  const {
    handleSelect,
    handleExistingUserSelect,
    handleClearCompanyForm,
    handleClearExistingUserCompanyForm,
  } = useCompanySuggestion(config);

  return (
    <FormSection
      title={t(
        translations.inquiryType.mittweida.pages.companyDetails.sections.companyDetails.title,
      )}
      name={'companyDetails'}
      sectionNumber={1}
    >
      {isLoggedIn && (
        <FormRow>
          <MittweidaExistingUserCompanySelect
            fieldName={loggedInUserCompanyNameFieldName}
            onClearCompanyForm={handleClearExistingUserCompanyForm!}
            onSelect={handleExistingUserSelect}
          />
        </FormRow>
      )}
      {shouldShowSuggestion && (
        <FormRow>
          <CompanySuggestion
            fieldName={selectedCompanyNameFieldName}
            onClearCompanyForm={handleClearCompanyForm}
            onSelect={handleSelect}
          />
        </FormRow>
      )}
      <CompanyNoteSection />
    </FormSection>
  );
};
