import {
  LEASING_ADVANCE_PAYMENT,
  LEASING_TERM_IN_MONTHS,
  LEASING_AMORTISATION_KIND,
  LEASING_RESIDUAL_VALUE,
  LEASING_TAKEOVER,
  LEASING_DESCRIPTION,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger, toBoolean, toFloatPrecision2 } = fieldParsers;
export const mapLeasingToApi: InquiryFormMapper = (allFields) => {
  const isPartialAmortisation =
    allFields[LEASING_AMORTISATION_KIND] === LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION;
  return {
    'leasing-advance-payment': toInteger(allFields[LEASING_ADVANCE_PAYMENT]),
    'leasing-term-in-months': toInteger(allFields[LEASING_TERM_IN_MONTHS]),
    'leasing-amortisation-kind': allFields[LEASING_AMORTISATION_KIND],

    'leasing-takeover': allFields[LEASING_TAKEOVER],
    'leasing-description': allFields[LEASING_DESCRIPTION],
    'leasing-interested-in-credit-financing': toBoolean(
      allFields[LEASING_INTERESTED_IN_CREDIT_FINANCING],
    ),
    ...conditionalObject(isPartialAmortisation, {
      'leasing-residual-value': toFloatPrecision2(allFields[LEASING_RESIDUAL_VALUE]),
    }),
  };
};
