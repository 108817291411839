import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export const FacadeIcon = (props: IconProps) => {
  return (
    <Icon
      width={'128'}
      height={'128'}
      viewBox={'0 0 128 128'}
      aria-hidden={'true'}
      fill={'white'}
      fillOpacity={0}
      {...props}
    >
      <path
        d="M38.9042 104.505L9.00732 104.48V39.5213L50.0565 23.3281L87.9317 39.5213V104.48L61.3705 104.468"
        stroke-width="2.08989"
      />
      <path
        d="M37.7355 104.194V70.4015C37.7288 68.7102 38.3935 67.0853 39.5836 65.8836C40.7737 64.6818 42.392 64.0013 44.0833 63.9915H56.2063C57.8976 64.0013 59.5159 64.6818 60.706 65.8836C61.8961 67.0853 62.5608 68.7102 62.5541 70.4015V104.294M88.9536 42.4338L94.9031 42.3965L94.8284 34.692L50.0577 14.603L2.28732 34.1817L2.2002 42.0604L8.3613 41.9733"
        stroke-width="2.08989"
      />
      <path d="M23.1221 40.8779H39.1161V57.32H23.1221V40.8779Z" stroke-width="2.08989" />
      <path
        d="M82.8092 110.549H73.4356C73.1272 110.549 72.8315 110.427 72.6135 110.21C72.3954 109.993 72.2729 109.699 72.2729 109.392V51.1575C72.2729 50.8505 72.3954 50.5561 72.6135 50.339C72.8315 50.1219 73.1272 50 73.4356 50H82.8092C83.1176 50 83.4133 50.1219 83.6313 50.339C83.8494 50.5561 83.9718 50.8505 83.9718 51.1575V109.392C83.9718 109.699 83.8494 109.993 83.6313 110.21C83.4133 110.427 83.1176 110.549 82.8092 110.549ZM74.5982 108.235H81.6466V52.3149H74.5982V108.235Z"
        stroke-width="2.08989"
      />
      <path
        d="M86.0818 117.422H70.1626C69.8543 117.422 69.5586 117.3 69.3405 117.083C69.1225 116.866 69 116.572 69 116.265V109.392C69 109.085 69.1225 108.791 69.3405 108.574C69.5586 108.357 69.8543 108.235 70.1626 108.235H86.0818C86.3902 108.235 86.6859 108.357 86.9039 108.574C87.122 108.791 87.2445 109.085 87.2445 109.392V116.265C87.2445 116.572 87.122 116.866 86.9039 117.083C86.6859 117.3 86.3902 117.422 86.0818 117.422ZM71.3252 115.107H84.9192V110.55H71.3252V115.107Z"
        stroke-width="2.08989"
      />
      <path
        d="M127.838 117.422H111.918C111.61 117.422 111.314 117.3 111.096 117.083C110.878 116.866 110.756 116.572 110.756 116.265V109.392C110.756 109.085 110.878 108.791 111.096 108.574C111.314 108.357 111.61 108.235 111.918 108.235H127.838C128.146 108.235 128.442 108.357 128.66 108.574C128.878 108.791 129 109.085 129 109.392V116.265C129 116.572 128.878 116.866 128.66 117.083C128.442 117.3 128.146 117.422 127.838 117.422ZM113.081 115.107H126.675V110.55H113.081V115.107Z"
        stroke-width="2.08989"
      />
      <path
        d="M124.565 110.549H115.191C114.883 110.549 114.587 110.427 114.369 110.21C114.151 109.993 114.029 109.699 114.029 109.392V51.1575C114.029 50.8505 114.151 50.5561 114.369 50.339C114.587 50.1219 114.883 50 115.191 50H124.565C124.873 50 125.169 50.1219 125.387 50.339C125.605 50.5561 125.728 50.8505 125.728 51.1575V109.392C125.728 109.699 125.605 109.993 125.387 110.21C125.169 110.427 124.873 110.549 124.565 110.549ZM116.354 108.235H123.402V52.3149H116.354V108.235Z"
        stroke-width="2.08989"
      />
      <path
        d="M124.565 85.2705H73.4356C73.1272 85.2705 72.8315 85.1486 72.6135 84.9315C72.3954 84.7145 72.2729 84.4201 72.2729 84.1131V76.4363C72.2729 76.1293 72.3954 75.8349 72.6135 75.6178C72.8315 75.4008 73.1272 75.2788 73.4356 75.2788H124.565C124.873 75.2788 125.169 75.4008 125.387 75.6178C125.605 75.8349 125.727 76.1293 125.727 76.4363V84.1131C125.727 84.4201 125.605 84.7145 125.387 84.9315C125.169 85.1486 124.873 85.2705 124.565 85.2705ZM74.5982 82.9556H123.402V77.5937H74.5982V82.9556Z"
        stroke-width="2.08989"
      />
      <path
        d="M115.19 77.5938C114.93 77.5937 114.678 77.5071 114.474 77.3478L99.0005 65.2683L83.5274 77.3449C83.4073 77.4388 83.2698 77.5081 83.1227 77.5491C82.9756 77.59 82.8219 77.6017 82.6703 77.5835C82.5187 77.5653 82.3721 77.5176 82.239 77.443C82.106 77.3684 81.989 77.2685 81.8947 77.1489C81.8004 77.0293 81.7307 76.8923 81.6896 76.7459C81.6484 76.5995 81.6367 76.4464 81.655 76.2955C81.6733 76.1446 81.7212 75.9987 81.7961 75.8662C81.8711 75.7337 81.9715 75.6172 82.0916 75.5234L97.1112 63.7969L82.0916 52.069C81.9009 51.9204 81.7616 51.7163 81.6931 51.4852C81.6245 51.254 81.6302 51.0073 81.7092 50.7795C81.7883 50.5517 81.9368 50.3542 82.134 50.2144C82.3313 50.0747 82.5674 49.9997 82.8095 50H115.191C115.434 49.9997 115.67 50.0747 115.867 50.2144C116.064 50.3542 116.213 50.5517 116.292 50.7795C116.371 51.0073 116.376 51.254 116.308 51.4852C116.239 51.7163 116.1 51.9204 115.909 52.069L100.89 63.7969L115.915 75.5262C116.106 75.6754 116.245 75.8802 116.313 76.1119C116.381 76.3436 116.374 76.5907 116.294 76.8186C116.214 77.0465 116.065 77.2437 115.866 77.3827C115.668 77.5217 115.431 77.5955 115.189 77.5938H115.19ZM86.1768 52.3149L99.0005 62.3255L111.824 52.3149H86.1768Z"
        stroke-width="2.08989"
      />
      <path
        d="M82.8106 110.549C82.5687 110.55 82.3328 110.475 82.1357 110.335C81.9386 110.196 81.7902 109.998 81.711 109.771C81.6319 109.543 81.6259 109.297 81.6941 109.066C81.7622 108.835 81.901 108.631 82.0912 108.482L114.473 83.203C114.593 83.1092 114.731 83.0398 114.878 82.9988C115.025 82.9579 115.179 82.9462 115.33 82.9644C115.482 82.9826 115.628 83.0303 115.762 83.1049C115.895 83.1795 116.012 83.2795 116.106 83.3991C116.2 83.5187 116.27 83.6556 116.311 83.802C116.352 83.9484 116.364 84.1015 116.346 84.2524C116.327 84.4034 116.279 84.5492 116.204 84.6817C116.13 84.8142 116.029 84.9307 115.909 85.0246L83.527 110.303C83.3225 110.463 83.0703 110.549 82.8106 110.549Z"
        stroke-width="2.08989"
      />
      <path
        d="M115.19 110.549C114.93 110.549 114.678 110.463 114.473 110.303L82.0915 85.0246C81.9713 84.9307 81.8709 84.8142 81.796 84.6817C81.7211 84.5492 81.6731 84.4034 81.6549 84.2524C81.6366 84.1015 81.6483 83.9484 81.6894 83.802C81.7306 83.6556 81.8003 83.5187 81.8945 83.3991C81.9888 83.2795 82.1058 83.1795 82.2389 83.1049C82.372 83.0303 82.5185 82.9826 82.6701 82.9644C82.8218 82.9462 82.9755 82.9579 83.1226 82.9988C83.2696 83.0398 83.4072 83.1092 83.5273 83.203L115.909 108.482C116.1 108.631 116.239 108.836 116.307 109.068C116.375 109.299 116.368 109.546 116.288 109.774C116.208 110.002 116.059 110.199 115.86 110.338C115.662 110.477 115.425 110.551 115.183 110.549H115.19Z"
        stroke-width="2.08989"
      />
    </Icon>
  );
};
