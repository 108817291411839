import React from 'react';

import { MonthPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import { REAL_ESTATE_KIND, REAL_ESTATE_SCHEDULED_DATE } from 'modules/Inquiry/Form/formFields';
import RealEstateKind from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateKind';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { futureMonths } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import BFSNewBuilding from './BFSRealEstateFields/BFSNewBuilding';

export const validateNewBuildingSection = (form) =>
  fieldsAreValid([REAL_ESTATE_KIND, REAL_ESTATE_SCHEDULED_DATE])({ form });

const NewBuilding = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.realEstateNewBuilding}
      sectionNumber={4}
      title={t('pages.financingNeed.sections.newBuilding.title')}
    >
      <FormRow>
        <RealEstateKind />

        <MonthPickerWithField
          name={REAL_ESTATE_SCHEDULED_DATE}
          placeholder={t('placeholders.pleaseChoose')}
          minDate={futureMonths}
        />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BFSNewBuilding,
  default: NewBuilding,
});
