import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const HirePurchaseIcon = (props: IconProps) => {
  return (
    <Icon
      width="39"
      height="38"
      viewBox="0 0 38.12 38"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path d="M25.4 26.8c-.5.3-1.1.4-1.7.4-2 0-3.7-1.6-3.7-3.7s1.6-3.7 3.7-3.7c.7 0 1.3.2 1.8.5m-6.2 2.5h3.8m-3.8 1.4h3.8" />
        <circle cx="23.4" cy="23.6" r="7.6" />
        <path d="M16.1 21.5c-.4.1-.8.1-1.3.1-4.2 0-7.6-3.4-7.6-7.6s3.4-7.6 7.6-7.6 7.6 3.4 7.6 7.6c0 .7-.1 1.4-.3 2" />
        <path d="M14.8 8.2V14l3 2" />
        <path d="M34.4 28.6c5.1-8.2 3-19-4.9-24.7C21.7-1.7 10.8-.3 4.6 7.2c-6.2 7.5-5.4 18.5 1.6 25.1s18.1 6.6 25.2 0l-3.3-.4m3.3.4v3.1" />
      </g>
    </Icon>
  );
};

export default HirePurchaseIcon;
