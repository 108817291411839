import {
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__LOSS,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__ZERO,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_10_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_20_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__20_TO_30_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__30_TO_40_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__40_TO_50_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__50_TO_75_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__75_TO_100_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__100_TO_250_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__250_TO_500_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__500_TO_750_THOUSAND,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__750_THOUSAND_TO_1_MILLION,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_2_5_MILLION,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__2_5_TO_5_MILLION,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__5_TO_10_MILLION,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_50_MILLION,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__MORE_THAN_50_MILLION,
} from 'modules/Inquiry/Form/formFields';

const getAnnualNetProfitOrLossClassOptions = (formatMessage) => {
  const euroText = formatMessage({ id: 'currency.euro' });
  const teuroText = formatMessage({ id: 'currency.thousandEuro' });
  const milionText = formatMessage({ id: 'other.milion' });
  const above = formatMessage({ id: 'other.above' });
  const loss = formatMessage({ id: 'other.loss' });
  const zero = formatMessage({ id: 'other.zero' });

  return [
    {
      label: `${loss}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__LOSS,
    },
    {
      label: `${zero}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__ZERO,
    },
    {
      label: `1 ${euroText} - 10 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_10_THOUSAND,
    },
    {
      label: `10 ${teuroText} - 20 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_20_THOUSAND,
    },
    {
      label: `20 ${teuroText} - 30 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__20_TO_30_THOUSAND,
    },
    {
      label: `30 ${teuroText} - 40 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__30_TO_40_THOUSAND,
    },
    {
      label: `40 ${teuroText} - 50 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__40_TO_50_THOUSAND,
    },
    {
      label: `50 ${teuroText} - 75 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__50_TO_75_THOUSAND,
    },
    {
      label: `75 ${teuroText} - 100 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__75_TO_100_THOUSAND,
    },
    {
      label: `100 ${teuroText} - 250 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__100_TO_250_THOUSAND,
    },
    {
      label: `250 ${teuroText} - 500 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__250_TO_500_THOUSAND,
    },
    {
      label: `500 ${teuroText} - 750 ${teuroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__500_TO_750_THOUSAND,
    },
    {
      label: `750 ${teuroText} - 1 ${milionText} ${euroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__750_THOUSAND_TO_1_MILLION,
    },
    {
      label: `1 - 2,5 ${milionText} ${euroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_2_5_MILLION,
    },
    {
      label: `2,5 - 5 ${milionText} ${euroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__2_5_TO_5_MILLION,
    },
    {
      label: `5 - 10 ${milionText} ${euroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__5_TO_10_MILLION,
    },
    {
      label: `10 - 50 ${milionText} ${euroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_50_MILLION,
    },
    {
      label: `${above} 50 ${milionText} ${euroText}`,
      value: USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__MORE_THAN_50_MILLION,
    },
  ];
};

export default getAnnualNetProfitOrLossClassOptions;
