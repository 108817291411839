import { keyBy, chain } from 'lodash';
import { createSelector } from 'reselect';

import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { USER_SCOPE } from 'constants/userScopes';
import type { IInternalFile, IFile, IPrivateFile, IAssessmentFile } from 'models/File.model';
import type { IFileRequest } from 'models/FileRequest.model';
import type { IState } from 'store';

export const getFileRequestsSelector = (state: IState): Array<IFileRequest> =>
  state.documentExchange.fileRequests;
export const getInternalFiles = (state: IState): Array<IInternalFile> =>
  state.documentExchange.internalFiles;
export const getPrivateFiles = (state: IState): Array<IPrivateFile> =>
  state.documentExchange.privateFiles;
export const getAssessmentFiles = (state: IState): Array<IAssessmentFile> =>
  state.documentExchange.assessmentFiles;
export const getFilesSelector = (state: IState) => state.documentExchange.files;

const filterUploadedBy = (userScope: USER_SCOPE) => (file: IFile) => file.uploadedBy === userScope;
const filterRequestsWithFiles = (req: IFileRequest) => Boolean(req.files);
const filterFilesWithoutFileRequest = (files: Array<IFile>, requests: Array<IFileRequest>) =>
  files.filter(
    (file) => !requests.some((request) => request.files?.map((f) => f.id).includes(file.id)),
  );

export const getFilesFromCustomer = createSelector(getFilesSelector, (files) =>
  files.filter(filterUploadedBy(USER_SCOPE.CUSTOMER)),
);
export const getFilesFromOperation = createSelector(getFilesSelector, (files) =>
  files.filter(filterUploadedBy(USER_SCOPE.OPERATION_PORTAL)),
);

const fileIdsAttachedToFileRequests = createSelector(getFileRequestsSelector, (requests) =>
  requests.filter(filterRequestsWithFiles).map((req) => req.files?.map((file) => file.id)),
);

export const getAdditionalFilesSentByCustomer =
  // FIXME
  // @ts-ignore
  createSelector(
    [getFilesFromCustomer, fileIdsAttachedToFileRequests],
    (filesFromCP, fileIdsFromRequests: Array<string>) =>
      filesFromCP.filter((file: IFile) => !fileIdsFromRequests.includes(file.id)),
  );

export const getAdditionalCustomerFilesWithoutFileRequest =
  // FIXME
  // @ts-ignore
  createSelector(
    [getAdditionalFilesSentByCustomer, getFileRequestsSelector],
    filterFilesWithoutFileRequest,
  );

export const getAdditionalFilesSendByOperation =
  // FIXME
  // @ts-ignore
  createSelector(
    [getFilesFromOperation, fileIdsAttachedToFileRequests],
    (filesFromOP: IFile[], requestFileIds: Array<string>) =>
      filesFromOP.filter((file) => !requestFileIds.includes(file.id)),
  );

export const getAdditionalFilesSentByRealEstateExpert = createSelector(
  getAdditionalFilesSendByOperation,
  (files) => files.filter(({ isRealEstateExpert }) => isRealEstateExpert),
);

export const getAdditionalOperationFilesWithoutFileRequest =
  // FIXME
  // @ts-ignore
  createSelector(
    [getAdditionalFilesSendByOperation, getFileRequestsSelector],
    filterFilesWithoutFileRequest,
  );

export const getNumOfNewFilesUploadedForCustomer = createSelector(
  getAdditionalFilesSendByOperation,
  // FIXME
  // @ts-ignore
  (files) => files.filter((file: IFile) => file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED).length,
);

export const getNumOfNewAdditionalFilesUploadedForCustomer = createSelector(
  getAdditionalOperationFilesWithoutFileRequest,
  (files) => files.filter((file: IFile) => file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED).length,
);

export const getNumOfNewFilesUploadedForOperation = createSelector(
  getAdditionalFilesSentByCustomer,
  // FIXME
  // @ts-ignore
  (files) => files.filter((file: IFile) => file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED).length,
);

export const getNumOfNewAdditionalFilesUploadedForOperation = createSelector(
  getAdditionalCustomerFilesWithoutFileRequest,
  (files) => files.filter((file: IFile) => file.status === DOWNLOAD_STATUS.NOT_DOWNLOADED).length,
);

export const getRequiredFileRequestsSelector = createSelector(getFileRequestsSelector, (requests) =>
  requests.filter((request) => request.required),
);

export const fileRequestsDictionarySelector = createSelector(
  getFileRequestsSelector,
  (fileRequests) => keyBy(fileRequests, ({ id }) => id),
);

export const fileRequestSelector = createSelector(
  fileRequestsDictionarySelector,
  (state: IState, fileRequestId: string | undefined) => fileRequestId,
  (fileRequests, fileRequestId) => (fileRequestId ? fileRequests[fileRequestId] : undefined),
);

export const getFileClassificationMap = createSelector(getFileRequestsSelector, (fileRequests) =>
  chain(fileRequests)
    .filter(({ supportsReclassification }) => supportsReclassification)
    .keyBy(({ classification }) => classification!)
    .mapValues(({ title }) => title)
    .value(),
);

export const fileRequestClassificationOptionsSelector = createSelector(
  getFileClassificationMap,
  (fileClassifications) =>
    Object.entries(fileClassifications).map(([value, label]) => ({ label, value })),
);

export const filesDictionarySelector = createSelector(getFilesSelector, (files: IFile[]) =>
  keyBy(files, ({ id }) => id),
);

export const fileSelector = createSelector(
  filesDictionarySelector,
  (state: IState, fileId: string | undefined) => fileId,
  (files, fileId) => (fileId ? files[fileId] : undefined),
);
