import React from 'react';

import { FILE_BLOCK_COLOR } from 'components/FileBlock/constants';
import CheckmarkLargeIcon from 'theme/components/Icon/CheckmarkLargeIcon';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import UploadIcon from 'theme/components/Icon/UploadIcon';

export const DOWNLOAD_STATUS = {
  NOT_UPLOADED: 'notUploaded',
  NOT_DOWNLOADED: 'notDownloaded',
  DOWNLOADED: 'downloaded',
};

export const FILE_AVAILABLE_STATUSES = [DOWNLOAD_STATUS.NOT_DOWNLOADED, DOWNLOAD_STATUS.DOWNLOADED];

export const DOWNLOAD_STATUSES = Object.values(DOWNLOAD_STATUS);

export const DOWNLOAD_COLOR_MAP = {
  [DOWNLOAD_STATUS.NOT_UPLOADED]: FILE_BLOCK_COLOR.RED,
  [DOWNLOAD_STATUS.NOT_DOWNLOADED]: FILE_BLOCK_COLOR.GREEN,
  [DOWNLOAD_STATUS.DOWNLOADED]: FILE_BLOCK_COLOR.GREEN,
};

export const DOWNLOAD_ICON_MAP = {
  [DOWNLOAD_STATUS.NOT_UPLOADED]: <UploadIcon boxSize={6} />,
  [DOWNLOAD_STATUS.NOT_DOWNLOADED]: <DownloadIcon boxSize={6} />,
  [DOWNLOAD_STATUS.DOWNLOADED]: <CheckmarkLargeIcon boxSize={6} />,
};

export const DOWNLOAD_IS_DASHED_MAP = {
  [DOWNLOAD_STATUS.NOT_UPLOADED]: true,
  [DOWNLOAD_STATUS.NOT_DOWNLOADED]: true,
  [DOWNLOAD_STATUS.DOWNLOADED]: false,
};
