import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { HIRE_PURCHASE_AMORTISATION_KIND } from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useTranslations } from 'utils/hooks/useTranslations';

const HirePurchaseAmortisationKind = () => {
  const options = useInquirySelectOptions(HIRE_PURCHASE_AMORTISATION_KIND);
  const t = useTranslations(
    'pages.requestDetails.sections.hirePurchase.fields.hirePurchaseAmortisationKind',
  );

  return (
    <SelectWithField
      name={HIRE_PURCHASE_AMORTISATION_KIND}
      options={options}
      tooltip={t('tooltip')}
      data-testid={'select-field-' + HIRE_PURCHASE_AMORTISATION_KIND}
    />
  );
};

export default HirePurchaseAmortisationKind;
