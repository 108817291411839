import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { StyledFilesSection as Section } from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/styles';
import { getCompeonDocument, getCompeonInquiryId } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGetCompeonInquiry } from './useGetCompeonInquiry';
import FileSectionTitle from '../FileSectionTitle';

const FilesToCompeon: React.FC = () => {
  const files = useSelector(getCompeonDocument);
  const compeonInquiryId = useSelector(getCompeonInquiryId);
  const fetchCompeonInquiry = useGetCompeonInquiry();

  const t = useTranslations('pages.inquiryDetails.documentExchange');

  useEffect(() => {
    if (compeonInquiryId) {
      fetchCompeonInquiry(compeonInquiryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compeonInquiryId]);

  return (
    <Condition condition={Boolean(compeonInquiryId && files?.length)}>
      <section data-testid="files-to-compeon">
        <FileSectionTitle>{t('filesToCompeon')}</FileSectionTitle>

        <Section>
          {files &&
            files.map((file: CompeonDocument) => (
              <li key={file.id}>
                <UploadedFileBlock file={file} isCompeonFile />
              </li>
            ))}
        </Section>
      </section>
    </Condition>
  );
};

export default FilesToCompeon;
