import React, { useMemo } from 'react';

import paths from 'constants/paths';
import {
  RLLComputedProfitabilityLendingValueBaseValues,
  RLLComputedProfitabilityLendingValueMarkdown,
  RLLComputedProfitabilityLendingValueSalesPrice,
  RLLComputedProfitabilityLendingValueValue,
} from 'models/types/Profitability.type';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { AccordionComponent } from 'theme/components/Accordion';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getData, getHeaders } from './module';
import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import useEditFormConfig from '../../../../useEditFormConfig';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';

interface LendingValueProps {
  baseValues: RLLComputedProfitabilityLendingValueBaseValues;
  inquiryId: string;
  markdown: RLLComputedProfitabilityLendingValueMarkdown;
  salesPrice: RLLComputedProfitabilityLendingValueSalesPrice;
  value: RLLComputedProfitabilityLendingValueValue;
}

export const LendingValue: React.FC<LendingValueProps> = ({
  baseValues,
  inquiryId,
  markdown,
  salesPrice,
  value,
}) => {
  const { lendingValues } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations();
  const tPage = useTranslations(
    'pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.lendingValueDetermination',
  );
  const { hasRoleBankAdvisor, hasRoleRealEstateExpert } = useRoles();

  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(
    () => getData(t, { baseValues, markdown, salesPrice, value }),
    [t, baseValues, markdown, salesPrice, value],
  );

  const { onEdit } = useEditFormConfig({
    pathToEdit: lendingValues(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION,
    editFormStructure:
      mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.LENDING_VALUE_DETERMINATION](),
  });

  return (
    <>
      <AccordionComponent headingLevel="h3" mt={8} title={tPage('accordianTitle')}>
        <TableCardWithEdit
          canEdit={hasRoleBankAdvisor || hasRoleRealEstateExpert}
          componentName={PROFITABILITY_RECORD_TYPE.INVESTMENT_COSTS}
          onEdit={onEdit}
          tableData={data}
          tableHeaders={headers}
        />
      </AccordionComponent>
    </>
  );
};
