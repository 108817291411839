import { WHITE_LABEL_API_CLIENT_NAME } from 'api';
import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { USER_SCOPE } from 'constants/userScopes';
import { IFile, IPrivateFile, IAssessmentFile, IInternalFile } from 'models/File.model';
import { IFileRequest } from 'models/FileRequest.model';
import { AppActionThunk } from 'models/types/AppThunk.type';
import { success } from 'utils/actionTypesHelpers';

export const SET_FILE_REQUESTS = 'SET_FILE_REQUESTS';
export const setFileRequestsAction =
  (requests: IFileRequest[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_FILE_REQUESTS,
      payload: requests,
    });

export const SET_FILES = 'SET_FILES';
export const setFilesAction =
  (files: IFile[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_FILES,
      payload: files,
    });

export const SET_PRIVATE_FILES = 'SET_PRIVATE_FILES';
export const setPrivateFilesAction =
  (files: IPrivateFile[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_PRIVATE_FILES,
      payload: files,
    });

export const SET_ASSESSMENT_FILES = 'SET_ASSESSMENT_FILES';
export const setAssessmentFilesAction =
  (files: IAssessmentFile[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_ASSESSMENT_FILES,
      payload: files,
    });

export const UPDATE_FILE_CLASSIFICATION = 'UPDATE_FILE_CLASSIFICATION';
export const updateFileClassificationAction =
  (file: IFile, requestId?: string): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: UPDATE_FILE_CLASSIFICATION,
      payload: {
        file,
        requestId,
      },
    });

export const UPDATE_PRIVATE_FILE_CLASSIFICATION = 'UPDATE_PRIVATE_FILE_CLASSIFICATION';
export const updatePrivateFileClassificationAction =
  (file: IPrivateFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: UPDATE_PRIVATE_FILE_CLASSIFICATION,
      payload: {
        file,
      },
    });

export const SET_INTERNAL_FILES = 'SET_INTERNAL_FILES';
export const setInternalFilesAction =
  (files: IInternalFile[]): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_INTERNAL_FILES,
      payload: files,
    });

export const ADD_FILE = 'ADD_FILE';
export const addFileAction =
  (file: IFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: ADD_FILE,
      payload: file,
    });

export const ADD_PRIVATE_FILE = 'ADD_PRIVATE_FILE';
export const addPrivateFileAction =
  (file: IPrivateFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: ADD_PRIVATE_FILE,
      payload: file,
    });

export const ADD_ASSESSMENT_FILE = 'ADD_ASSESSMENT_FILE';
export const addAssessmentFileAction =
  (file: IAssessmentFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: ADD_ASSESSMENT_FILE,
      payload: file,
    });

export const ADD_OR_UPDATE_FILE = 'ADD_OR_UPDATE_FILE';
export const updateFileAction =
  (file: IFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: ADD_OR_UPDATE_FILE,
      payload: file,
    });

export const ADD_OR_UPDATE_PRIVATE_FILE = 'ADD_OR_UPDATE_PRIVATE_FILE';
export const updatePrivateFileAction =
  (file: IPrivateFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: ADD_OR_UPDATE_PRIVATE_FILE,
      payload: file,
    });

export const UPLOAD_FILE_TO_FILE_REQUEST = 'UPLOAD_FILE_TO_FILE_REQUEST';
export const uploadFileToFileRequestAction =
  (requestId: string, file: IFile): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: UPLOAD_FILE_TO_FILE_REQUEST,
      payload: {
        requestId,
        file,
      },
    });

export const CREATE_FILE_REQUEST = 'CREATE_FILE_REQUEST';
export const CREATE_FILE_REQUEST_SUCCESS = success(CREATE_FILE_REQUEST);
export const createFileRequestAction = (
  inquiryId: string | undefined | null,
  requestTitle: string,
  requestDesc?: string,
  skipNotification?: boolean,
  selectedLanguage?: string,
  documentType?: string,
) =>
  callReverseApi({
    url: endpoints.INQUIRIES.REQUESTED_DOCS.compose({ params: { id: String(inquiryId) } }),
    actionName: CREATE_FILE_REQUEST,
    method: API_METHODS.POST,
    data: {
      title: requestTitle,
      description: requestDesc,
      skip_notification: skipNotification,
      language: selectedLanguage,
      document_type: documentType,
    },
  });

export const REMOVE_FILE = 'REMOVE_FILE';
export const REMOVE_FILE_SUCCESS = success(REMOVE_FILE);
export const removeFileAction = (fileId: string) =>
  callReverseApi({
    actionName: REMOVE_FILE,
    url: endpoints.FILES.DETAILS.compose({ params: { id: fileId } }),
    method: API_METHODS.DELETE,
    meta: fileId,
  });

export const REMOVE_PRIVATE_FILE = 'REMOVE_PRIVATE_FILE';
export const removePrivateFileAction = (fileId: string) =>
  callReverseApi({
    actionName: REMOVE_PRIVATE_FILE,
    url: endpoints.PRIVATE_FILES.DETAILS.compose({ params: { id: fileId } }),
    method: API_METHODS.DELETE,
    meta: fileId,
  });

export const REMOVE_ASSESSMENT_FILE = 'REMOVE_ASSESSMENT_FILE';
export const removeAssessmentFileAction = (fileId: string) =>
  callReverseApi({
    actionName: REMOVE_ASSESSMENT_FILE,
    url: endpoints.ASSESSMENT_FILES.DETAILS.compose({ params: { id: fileId } }),
    method: API_METHODS.DELETE,
    meta: fileId,
  });

export const getDocumentsToDownloadAction = (
  inquiryID: string,
  uploadedBy: USER_SCOPE,
): AppActionThunk => {
  const queryParams = uploadedBy
    ? {
        filters: {
          uploaded_by: uploadedBy,
        },
      }
    : {};

  return callReverseApi({
    url: endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: inquiryID }, query: queryParams }),
    method: API_METHODS.GET,
  });
};

export const getInternalesFilesToDownloadAction = (inquiryID: string): AppActionThunk =>
  callReverseApi({
    url: endpoints.INQUIRIES.FILES.EXTERNAL.compose({ params: { id: inquiryID } }),
    method: API_METHODS.GET,
  });

export const getPrivateFilesToDownloadAction = (inquiryID: string): AppActionThunk =>
  callReverseApi({
    url: endpoints.INQUIRIES.PRIVATE_FILES.LIST.compose({ params: { id: inquiryID } }),
    method: API_METHODS.GET,
  });
export const getAssessmentFilesToDownloadAction = (inquiryID: string): AppActionThunk =>
  callReverseApi({
    url: endpoints.INQUIRIES.ASSESSMENT_FILES.LIST.compose({ params: { id: inquiryID } }),
    method: API_METHODS.GET,
  });
export const getUpdatedFileAction = (fileID: string): AppActionThunk =>
  callReverseApi({
    url: endpoints.FILES.DETAILS.compose({ params: { id: fileID } }),
    method: API_METHODS.GET,
  });

export const getPrivateUpdatedFileAction = (fileID: string): AppActionThunk =>
  callReverseApi({
    url: endpoints.PRIVATE_FILES.DETAILS.compose({ params: { id: fileID } }),
    method: API_METHODS.GET,
  });

export const getDocumentsToUploadAction = (
  inquiryID: string,
  selectedLanguage?: string,
): AppActionThunk => {
  const queryParams = selectedLanguage
    ? {
        language: selectedLanguage,
      }
    : {};

  return (dispatch, getState) => {
    return dispatch({
      type: 'REQUEST_DOCS_TO_UPLOAD',
      payload: {
        client: WHITE_LABEL_API_CLIENT_NAME,
        request: {
          method: 'get',
          url: endpoints.INQUIRIES.REQUESTED_DOCS.compose({
            params: { id: inquiryID },
            query: queryParams,
          }),
        },
      },
    });
  };
};

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const downloadFileAction =
  (fileId: string): AppActionThunk =>
  (dispatch, getState) => {
    return dispatch({
      type: DOWNLOAD_FILE,
      payload: {
        fileId,
        client: WHITE_LABEL_API_CLIENT_NAME,
        request: {
          responseType: 'arraybuffer',
          method: API_METHODS.GET,
          url: endpoints.FILES.DOWNLOAD.compose({ params: { id: fileId } }),
        },
      },
    });
  };
