import React from 'react';

import { useSelector } from 'react-redux';

import { getInquiryLane } from 'store/inquiryDetails/selectors';

import { getProcessLane } from '../store/inquiryProcess/selectors';

export const chooseLaneSpecificComponent = (componentMap: any) => (props: any) => {
  const processLane = useSelector(getProcessLane); // inquiry process flow
  const inquiryLane = useSelector(getInquiryLane); // customer-/operations-/ portal
  if (componentMap[processLane ?? inquiryLane] === null) {
    return null;
  }

  const SelectedComp: React.FC | null =
    componentMap[processLane ?? inquiryLane] || componentMap.default;

  if (SelectedComp) {
    return <SelectedComp {...props} />;
  }

  return null;
};
