import _get from 'lodash/get';

import { ApiResponse } from 'models/ApiResponse';
import {
  IAssociatedPerson,
  IBlackWhiteReportApi,
  ICompactReportApi,
  ICompanyBaseData,
  ICompanyInquiries,
  IShortReportApi,
  ITrafficLightReportApi,
  ReportType,
} from 'models/CompanyDetails.model';

import { IGetRiskAnalysisAction } from '../store/actionTypes';

export const mapCompanyDetailsFromApi = (apiData: ApiResponse): ICompanyBaseData => ({
  companyName: _get(apiData, 'data.data.attributes.name'),
  street: _get(apiData, 'data.data.attributes.street'),
  city: _get(apiData, 'data.data.attributes.city'),
  zipCode: _get(apiData, 'data.data.attributes.zip_code'),
  districtCourt: _get(apiData, 'data.data.attributes.district_court'),
  foundingDate: _get(apiData, 'data.data.attributes.founding_date'),
  legalForm: _get(apiData, 'data.data.attributes.legal_form'),
  registerNumber: _get(apiData, 'data.data.attributes.register_number'),
  taxNumber: _get(apiData, 'data.data.attributes.tax_number'),
  registerCity: _get(apiData, 'data.data.attributes.register_city'),
  taxVatNumber: _get(apiData, 'data.data.attributes.tax_vat_number'),
  phoneNumber: _get(apiData, 'data.data.attributes.phone_number'),
  faxNumber: _get(apiData, 'data.data.attributes.fax_number'),
  email: _get(apiData, 'data.data.attributes.email'),
  website: _get(apiData, 'data.data.attributes.website'),
  companyStaff: _get(apiData, 'data.data.attributes.company_staff'),
  companyTurnover: _get(apiData, 'data.data.attributes.company_turnover'),
  iban: _get(apiData, 'data.data.attributes.account_iban'),
  alreadyCustomer: _get(apiData, 'data.data.attributes.already_customer'),
  balanceSheet: _get(apiData, 'data.data.attributes.detailed_balance_sheet'),
  detailedAnnualTurnover: _get(apiData, 'data.data.attributes.detailed_annual_turnover'),
  countOfEmployees: _get(apiData, 'data.data.attributes.count_of_employees'),
  debtServiceAbility: _get(apiData, 'data.data.attributes.debt_service_ability'),
  businessPurpose: _get(apiData, 'data.data.attributes.business_purpose'),
  industry: _get(apiData, 'data.data.attributes.industry'),
  legalEntityIdentifier: _get(apiData, 'data.data.attributes.legal_entity_identifier'),
});

export const mapAssociatedPerson = (apiData: ApiResponse): IAssociatedPerson[] => {
  if (!apiData.data.included) {
    return [];
  }

  return apiData.data.included.map((person) => ({
    id: person.id,
    type: person.type,
    firstName: person.attributes.first_name,
    lastName: person.attributes.last_name,
    address: person.attributes.address ?? '',
    zipCode: person.attributes.zip_code ?? '',
    city: person.attributes.city ?? '',
    birthDate: person.attributes.birth_date ?? '',
    birthPlace: person.attributes.birth_place ?? '',
    role: person.attributes.function ?? '-',
    powerOfRepresentation: person.attributes.power_of_representation ?? '-',
    source: person.attributes.source,
    successfullyMerged: person.attributes.successfully_merged,
    salutation: person.attributes.salutation ?? '',
    phoneNumber: person.attributes.phone_number ?? '',
    email: person.attributes.email ?? '',
    taxId: person.attributes.tax_id ?? '',
    ownerType: person.attributes.owner_type ?? '',
    scope: person.attributes.scope ?? '',
    companyShare: person.attributes.company_share ?? '',
    country: person.attributes.country ?? '',
  }));
};

export const mapCompanyInquiries = (apiResponse: ApiResponse): ICompanyInquiries[] => {
  const inquiries = _get(apiResponse, 'data.included', []).filter(
    (includedModels: any) => includedModels.type === 'inquiries',
  );
  if (!inquiries.length) {
    return [];
  }

  return inquiries.map((inquiry: any) => ({
    id: inquiry.id,
    createdAt: inquiry.attributes.created_at,
    productKind: inquiry.attributes.details.financial_product,
    financingAmount: inquiry.attributes.details.financial_amount,
    status: inquiry.attributes.status,
  }));
};

type Payload = Omit<IGetRiskAnalysisAction, 'type'>;

const trafficLightMap = ({ attributes }: ITrafficLightReportApi) => ({
  color: attributes.data.traffic_light_solvency.color,
  probabilityOfDefaultRange: attributes.data.traffic_light_solvency.probability_of_default_range,
});

const solvencyFinanceMap = ({ attributes }: IShortReportApi) => ({
  class: attributes.data.solvency_finance_industry.class,
  pdRange: attributes.data.solvency_finance_industry.pd_range,
});

const solvencyIndexMap = ({ attributes }: ICompactReportApi) => ({
  solvencyIndexValue: attributes.data.solvency_index.two_zero,
  dueToSolvencyIndex: attributes.data.solvency_index.due_to,
  solvencyIndexExplanation: attributes.data.solvency_index.meaning,
});

const riskJudgementMap = ({ attributes }: IBlackWhiteReportApi) => ({
  color: attributes.data.riskjudgement.color,
  meaning: attributes.data.riskjudgement.meaning,
});

export const mapReportsFromApi = (apiResponse: Payload) =>
  apiResponse.payload.data.included.map((report) => ({
    type: report.type,
    trafficLightSolvency:
      report.type === ReportType.trafficLight ? trafficLightMap(report) : undefined,
    solvencyFinanceIndustry:
      report.type === ReportType.latestScoringShort ? solvencyFinanceMap(report) : undefined,
    solvencyIndex:
      report.type === ReportType.latestScoringCompact ? solvencyIndexMap(report) : undefined,
    rating: report.type === ReportType.latestRating ? report.attributes.data.rating_class : '',
    riskJudgement: report.type === ReportType.blackAndWhite ? riskJudgementMap(report) : undefined,
    createdAt: report.attributes.created_at,
  }));
