import React, { useState } from 'react';

import camelcaseKeys from 'camelcase-keys';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormSection from 'components/FormSection';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import { AmbiguousAddress } from 'models/types/MarketData.type';
import {
  CONTROL_OBJECT_DATA_STEP,
  CURRENT_USAGE_STEP,
  FUTURE_USAGE_STEP,
} from 'modules/Inquiry/Form/formFields';
import {
  useRequestMarketData,
  useUpdateMarketData,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import { useToasts } from 'shared/hooks/useToasts';
import {
  mapInquiryDetailsApiResponseAction,
  marketDataSentAction,
} from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import AmbiguousAddressSelection from './AmbiguousAddressSelection';
import MarketInformationHeading from './MarketInformationHeading';
import MarketInformationTableStep from './MarketInformationTableStep';
import { MittweidaMarketInformationInquiryTable } from '../../../mittweida/portals/operations/steps/ReceiveMarketValues/MarketInformationInquiryTable';
import { InquiryType } from '../../../modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from '../../../shared/chooseSelectedInquiryTypeSpecificComponent';

const MarketInformationInquiryTable: React.FC = () => {
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isPending, requestMarketData } = useRequestMarketData(inquiryId!);
  const { updateObjectAddress } = useUpdateMarketData(inquiryId!);
  const [ambiguousAddresses, setAmbiguousAddresses] = useState<AmbiguousAddress[]>([]);

  const InquiryTableSteps = [CONTROL_OBJECT_DATA_STEP, FUTURE_USAGE_STEP, CURRENT_USAGE_STEP];
  const history = useHistory();
  const t = useTranslations();

  const stepTitle = (stepName: string) => {
    const camelStepName = _.camelCase(stepName);
    return t(`pages.marketInformationInquiryPage.steps.${camelStepName}.title`);
  };

  const path = paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId!);
  const redirectToDashboard = () => history.push(path);
  const { error, success } = useToasts();

  const handleSubmit = async () => {
    const res = await requestMarketData();

    if (res.error || res.data.data.attributes.status === 'error') {
      error({
        description: t('pages.inquiryDetails.dashboard.actions.triggerMarketResearch.error'),
      });
      return;
    }

    if (res.data.data.attributes.status === 'ambiguous_address') {
      setAmbiguousAddresses(
        camelcaseKeys(res.data.data.attributes.status_details.ambiguous_addresses),
      );
      return;
    }

    dispatch(marketDataSentAction());
    success({
      description: t('pages.inquiryDetails.dashboard.actions.triggerMarketResearch.success'),
    });
    redirectToDashboard();
  };

  const handleAmbiguousAddressSubmit = async (address: AmbiguousAddress) => {
    try {
      const payload = await updateObjectAddress(address);

      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({
        description: t(
          'pages.inquiryDetails.dashboard.actions.triggerMarketResearch.ambiguousAddress.success',
        ),
      });
      setAmbiguousAddresses([]);
    } catch {
      error({
        description: t(
          'pages.inquiryDetails.dashboard.actions.triggerMarketResearch.ambiguousAddress.error',
        ),
      });
    }
  };

  if (!_.isEmpty(ambiguousAddresses)) {
    return (
      <AmbiguousAddressSelection
        ambiguousAddresses={ambiguousAddresses}
        onCancel={redirectToDashboard}
        onSubmit={handleAmbiguousAddressSubmit}
      />
    );
  }

  return (
    <div>
      <MarketInformationHeading />
      {InquiryTableSteps?.map((stepName, index) => (
        <FormSection key={stepName} sectionNumber={index + 1} title={stepTitle(stepName)}>
          <MarketInformationTableStep stepName={stepName} />
        </FormSection>
      ))}
      <StyledActions>
        <ButtonComponent mr={4} onClick={redirectToDashboard} variant="tertiary">
          {t('buttons.cancel')}
        </ButtonComponent>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
          type="submit"
          onClick={handleSubmit}
          disabled={isPending}
          data-testid="confirm-button"
        >
          {t('pages.finishRegistration.confirmBtn')}
        </ButtonComponent>
      </StyledActions>
    </div>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: MarketInformationInquiryTable,
  [InquiryType.profiMittweida]: MittweidaMarketInformationInquiryTable,
});
