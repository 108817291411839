import React from 'react';

import { Asset } from 'components/Asset';
import { usePartnerConfig } from 'config/partner/hooks';
import { translations } from 'new/form/common/types';
import { FinalPageAction } from 'pages/inquiryFlow/FinalPage/actions/FinalPageAction';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const DzbBankCheckAction = () => {
  const t = useTranslations(translations.pages.finalPage.bankCheck.sectionString);
  const {
    details: { fullName: partnerFullName },
  } = usePartnerConfig();

  return (
    <FinalPageAction asset={<Asset type={'inquiry'} value={InquiryAssetKeys.BANK_CHECK} />}>
      <HeadingComponent as={'h3'} variant={'secondary'} mb={4}>
        {t('heading', { partnerFullName })}
      </HeadingComponent>
      <TextComponent mb={6}>{t('description', { partnerFullName })}</TextComponent>
    </FinalPageAction>
  );
};
