import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export const BalconyIcon = (props: IconProps) => {
  return (
    <Icon
      width={'128'}
      height={'128'}
      viewBox={'0 0 128 128'}
      aria-hidden={'true'}
      fill={'white'}
      fillOpacity={0}
      {...props}
    >
      <path
        d="M20.4473 25.801L14.7931 25.8127L14.7814 20.1617L79.5481 20.0279L79.5598 25.6788L74.4196 25.6895M20.4473 25.801L20.5004 51.4872M20.4473 25.801L47.4334 25.7452M20.5344 67.9264L20.5004 51.4872M20.5344 67.9264L17.9643 67.9317M20.5344 67.9264L27.7307 67.9115M74.4196 25.6895L74.4726 51.3757M74.4196 25.6895L47.4334 25.7452M74.5066 67.8149L74.4726 51.3757M74.5066 67.8149L67.3103 67.8297M74.5066 67.8149L77.0767 67.8095M20.5004 51.4872L74.4726 51.3757M47.4334 25.7452L47.5205 67.8706M47.5205 67.8706L37.6256 67.8911M47.5205 67.8706L47.597 104.859M47.5205 67.8706L57.5439 67.8499M1.01326 73.6177L3.05767 67.9625L7.68386 67.953M94.0511 73.4254L91.9833 67.7787L86.8431 67.7894M7.68386 67.953L7.76031 104.941M7.68386 67.953L17.9643 67.9317M17.9643 67.9317L18.0407 104.92M27.7307 67.9115L27.8071 104.9M27.7307 67.9115L37.6256 67.8911M37.6256 67.8911L37.702 104.879M57.5439 67.8499L57.6204 104.838M57.5439 67.8499L67.3103 67.8297M67.3103 67.8297L67.3868 104.818M77.0767 67.8095L77.1532 104.798M77.0767 67.8095L86.8431 67.7894M86.8431 67.7894L86.9196 104.777M86.9196 104.777L3.13412 104.951L3.14474 110.088L92.0704 109.904L92.0598 104.767L86.9196 104.777Z"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M101.606 64.9092L105.561 65.5122C106.107 65.5956 106.482 66.1058 106.399 66.6515L106.248 67.6401L108.225 67.9416L108.376 66.953C108.512 66.0613 109.219 65.0828 111.817 65.479C116.245 66.1541 124.952 70.4134 125.32 70.5949C125.647 70.755 125.86 71.0787 125.878 71.4417C125.897 71.8036 125.717 72.1484 125.409 72.3403C124.594 72.8492 123.577 73.563 123.363 73.8146C122.691 74.6054 121.337 77.5286 120.641 79.1847C120.464 79.6069 120.022 79.855 119.568 79.7858L116.602 79.3336L115.371 87.4112C116.459 87.995 117.118 89.2203 116.921 90.5094L112.848 117.226C112.598 118.861 111.065 119.988 109.43 119.739L105.475 119.136C103.84 118.887 102.712 117.353 102.962 115.718L107.036 89.003C107.232 87.7139 108.226 86.7406 109.439 86.5078L110.671 78.4301L107.705 77.9779C107.158 77.8946 106.784 77.3843 106.867 76.8386L107.018 75.85L105.041 75.5485L104.89 76.5371C104.807 77.0828 104.297 77.4583 103.751 77.3749L99.7965 76.772C99.2498 76.6886 98.8755 76.1783 98.9587 75.6327L100.466 65.7469C100.55 65.2004 101.06 64.8259 101.606 64.9092ZM110.153 88.4668C109.608 88.3837 109.097 88.7599 109.014 89.3046L104.94 116.02C104.857 116.565 105.233 117.076 105.778 117.159L109.732 117.762C110.277 117.845 110.788 117.47 110.871 116.924L114.945 90.2091C115.028 89.6644 114.652 89.1528 114.107 89.0697C113.562 88.9865 113.186 88.4761 113.269 87.9304L114.626 79.0333L112.649 78.7318L111.292 87.6289C111.209 88.1746 110.699 88.55 110.153 88.4668ZM108.996 76.1517L111.962 76.6039L115.916 77.2069L119.106 77.6933C119.7 76.3333 120.976 73.5357 121.84 72.5203C122.056 72.267 122.447 71.9483 122.864 71.6437C120.144 70.3822 114.571 67.9221 111.516 67.4561C110.83 67.3515 110.482 67.3834 110.328 67.4156L110.052 69.2307L109.147 75.1621L108.996 76.1517ZM107.32 73.873L107.923 69.9187L105.946 69.6173L105.343 73.5715L107.32 73.873ZM101.088 74.9457L103.065 75.2472L103.215 74.2586L104.12 68.3272L104.271 67.3386L102.294 67.0371L101.088 74.9457Z"
        strokeWidth={2}
      />
    </Icon>
  );
};
