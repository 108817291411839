import { useEffect } from 'react';

import { PushType, useTracking } from './useTracking';
import { useScript } from '../hooks/useScripts';

const pushUpdate: PushType = (id: string, pathname: string) => {
  window._paq = window._paq || [];
  window._paq.push(['setCustomUrl', '/' + pathname]);
  window._paq.push(['setDocumentTitle', document.title]);
  window._paq.push(['trackPageView']);
};

const pushStart = () => {
  window._mtm = window._mtm || [];
  window._mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
};

export const Matomo = ({ url }: { url: string }) => {
  useScript(url);

  useTracking({ trackerId: '', update: pushUpdate });

  useEffect(() => {
    pushStart();
  }, []);

  return null;
};
