import { RLLComputedProfitabilityPropertyDetails } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatInteger } from 'utils/valueFormats';

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.propertyDetails.tableHeaders.${key}`,
    );

  return [
    {
      key: 'label',
      text: translate('parameter'),
    },
    {
      key: 'value',
      text: translate('value'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  propertyDetails: RLLComputedProfitabilityPropertyDetails,
) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.propertyDetails.tableRows.${key}`,
    );
  const translateUnit = (key: string) => t(`other.${key}`);

  return [
    {
      label: translate('residentialSpace'),
      value: formatDecimal(propertyDetails.residentialSpace, { unit: translateUnit('m2') }),
    },
    {
      label: translate('commercialSpace'),
      value: formatDecimal(propertyDetails.commercialSpace, { unit: translateUnit('m2') }),
    },
    {
      label: translate('totalSpace'),
      value: formatDecimal(propertyDetails.totalSpace, { unit: translateUnit('m2') }),
      rowHighlight: true,
    },
    {
      label: translate('residentialUnits'),
      value: formatInteger(propertyDetails.residentialUnits, { unit: translateUnit('units') }),
    },
    {
      label: translate('commercialUnits'),
      value: formatInteger(propertyDetails.commercialUnits, { unit: translateUnit('units') }),
    },
    {
      label: translate('totalUnits'),
      value: formatInteger(propertyDetails.totalUnits, { unit: translateUnit('units') }),
      rowHighlight: true,
    },
    {
      label: translate('garageParking'),
      value: formatInteger(propertyDetails.garageParking, { unit: translateUnit('space') }),
    },
    {
      label: translate('parkingLots'),
      value: formatInteger(propertyDetails.parkingLots, { unit: translateUnit('space') }),
    },
    {
      label: translate('totalParking'),
      value: formatInteger(propertyDetails.totalParking, { unit: translateUnit('space') }),
      rowHighlight: true,
    },
  ];
};
