import { useLocation } from 'react-router-dom';

export const useQueryParams = (queryKey) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (typeof queryKey === 'object') {
    return Object.keys(queryKey).reduce((accumulator, currentKey) => {
      // returns object mapped with values from query params
      const queryStringKey = queryKey[currentKey];
      return {
        ...accumulator,
        [queryStringKey]: queryParams.get(queryStringKey),
      };
    }, {});
  }
  return queryParams.get(queryKey);
};
