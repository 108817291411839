import React from 'react';

import { useIntl } from 'react-intl';

import { SelectWithField } from 'components/Selects/Select';
import StaticField from 'components/StaticField';
import { useFormConfig } from 'config/formConfig/hooks';
import { USER_PROFILE_TURNOVER_CLASS } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import {
  getSelectedLabelForOptions,
  COMPANY_FIELDS,
} from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import getTurnoverClassOptions from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/TurnoverClass/turnoverClassOptions';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  getParsedItemFromSessionStorage,
  saveObjectToSessionStorage,
} from 'utils/sessionStorage/helpers';
import { COMPANY_DETAILS } from 'utils/sessionStorage/keys';

import { useGetMappedPartnerOptions } from '../../../../../../utils/hooks/useGetMappedOptions';

interface TurnoverClassProps {
  name?: string;
}

export const TURNOVER_CLASS_OPTIONS = 'turnover-class-options';

const TurnoverClass = ({ name = USER_PROFILE_TURNOVER_CLASS }: TurnoverClassProps) => {
  const tFields = useTranslations('pages.userProfile.sections.completingCompanyDetails.fields');
  const t = useTranslations();

  const defaultOptions = useInquirySelectOptions(USER_PROFILE_TURNOVER_CLASS);
  const { formatMessage } = useIntl();
  const { selectedInquiryType } = useFormConfig();

  const selectedTurnoverClass = useGetSelectedCompanyParam(COMPANY_FIELDS.turnoverClass);
  const partnerOptions = useGetMappedPartnerOptions('revenue_class');
  const options = partnerOptions.length !== 0 ? partnerOptions : defaultOptions;
  const companyDetails = getParsedItemFromSessionStorage(COMPANY_DETAILS);
  saveObjectToSessionStorage(COMPANY_DETAILS, {
    ...companyDetails,
    [TURNOVER_CLASS_OPTIONS]: options,
  });

  if (selectedTurnoverClass) {
    return (
      <StaticField
        caption={tFields('userProfileTurnoverClass.caption')}
        text={getSelectedLabelForOptions(
          getTurnoverClassOptions,
          selectedTurnoverClass,
          formatMessage,
        )}
      />
    );
  }

  return (
    <SelectWithField
      name={name}
      options={options}
      tooltip={
        selectedInquiryType === InquiryType.onlinefactoring &&
        tFields('userProfileTurnoverClass.tooltip')
      }
      placeholder={t('placeholders.pleaseChoose')}
      data-testid={'select-field-' + name}
    />
  );
};

export default TurnoverClass;
