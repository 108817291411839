import React from 'react';

import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { FILTERS_FINANCING_ROLE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';

import { RentPartition } from './RentPartition';
import { RentStockholder } from './RentStockholder';

export interface RentItem {
  dscr: string;
  net_income: string;
  free_income: string;
  equity_proof: string;
  service_year: string;
  surplus_year: string;
  asset_surplus: string;
  rental_income: string;
  operating_costs: string;
  service_percent: string;
  profit_total_funds: string;
  service_utilization: string;
  profit_external_funds: string;
  operating_costs_percent: string;
  surplus_securities_year: string;
  surplus_financing_duration: string;
  surplus_securities_financing_duration: string;
}

export interface RentAdditionalInformation {
  current: {
    max_financing_amount: string;
    additional_equity_required: string;
    operating_costs_min_customer: string;
    operating_costs_estimation_customer: string;
    surplus_equity_proof_financing_duration_partition: string;
  };
  future: {
    max_financing_amount: string;
    additional_equity_required: string;
    operating_costs_min_customer: string;
    operating_costs_estimation_customer: string;
  };
}

export interface RentProps {
  current: { bank_values: RentItem; minimum_rent: RentItem };
  future: { bank_values: RentItem; minimum_rent: RentItem };
  customer_now: RentItem;
  customer_plan: RentItem;
  notes_on_debt_service: string;
  additional_information: RentAdditionalInformation;
}

export const Rent = () => {
  const { financingRole } = useSelector(getMarketDataFilterLegacy);

  return (
    <>
      <Condition condition={financingRole === FILTERS_FINANCING_ROLE.PARTITION}>
        <RentPartition />
      </Condition>
      <Condition condition={financingRole === FILTERS_FINANCING_ROLE.STOCKHOLDER}>
        <RentStockholder />
      </Condition>
    </>
  );
};
