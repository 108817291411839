import styled from 'styled-components/macro';

import Text from 'components/Text/Text';
import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';

const Subheading = styled(Text)`
  margin-bottom: 1.5rem;
  font-size: 1.33rem;
  font-weight: ${FONT_WEIGHT_LIGHT};
  text-align: center;
  color: ${(props) =>
    props.isWhite ? props.theme.colors.text.white : props.theme.colors.text.tertiary};
`;

export default Subheading;
