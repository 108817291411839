import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ErrorText } from 'components/Text';
import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { zIndex } from 'theme/themeConstants';

export const StyledLink = styled(Link).attrs(() => ({
  tabIndex: '0',
}))`
  z-index: ${zIndex.default};
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${(props) => props.theme.colors.primary};

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export const ErrorTextWrapper = styled(ErrorText)`
  position: relative;
  z-index: ${zIndex.above};

  a {
    margin-left: 0.5rem;
  }
`;
