import React from 'react';

import _get from 'lodash/get';
import { Link } from 'react-router-dom';

import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { List, ListItem } from 'modules/Footer/FooterContent/FooterLinkList/List';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';
import { scrollTop } from 'utils/scroll';

const { privacyPolicy, impressum, termsAndConditions, genderNote } = translations.footerLinks;

interface FooterLink {
  href: string;
  text: string;
}

const LinkList = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();

  const footerLinks: FooterLink[] = [
    {
      href: _get(paths, `${selectedInquiryType}.termsAndConditions`) || paths.termsAndConditions,
      text: termsAndConditions,
    },
    {
      href: _get(paths, `${selectedInquiryType}.privacyPolicy`) || paths.privacyPolicy,
      text: privacyPolicy,
    },
    {
      href: _get(paths, `${selectedInquiryType}.impressum`) || paths.impressum,
      text: impressum,
    },
    {
      // @ts-ignore
      // FIXME this is incorrectly typed
      href: _get(paths, `${selectedInquiryType}.genderNote`),
      text: genderNote,
    },
  ];

  return (
    <List>
      {footerLinks.map(({ href, text }) => {
        return (
          href && (
            <ListItem key={href}>
              <Link to={{ pathname: href }} target="_blank" onClick={() => scrollTop()} key={text}>
                {t(text)}
              </Link>
            </ListItem>
          )
        );
      })}
    </List>
  );
};

export default LinkList;
