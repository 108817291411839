import { useEffect, useState } from 'react';

import {
  CORONA_REVENUE_OF_2019,
  CORONA_NUMBER_OF_EMPLOYEES,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { getMaxFinancingAmountAction } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/coronaStep.service';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useFetchQuickCreditMaximumAmount = () => {
  const [maxAmount, setMaxAmount] = useState(0);

  const revenue2019 = useGetFieldNumericValue(CORONA_REVENUE_OF_2019) as number;
  const numberOfEmployees = useGetFieldNumericValue(CORONA_NUMBER_OF_EMPLOYEES) as number;

  const { isPending, makeCall } = useDispatchApiCall();
  useEffect(() => {
    if (revenue2019 && numberOfEmployees) {
      makeCall(
        getMaxFinancingAmountAction({ revenue2019, numberOfEmployees, isQuickCredit: true }),
        ({ payload }: any) =>
          setMaxAmount(+payload.data.data.attributes['max-allowed-credit-amount']),
      );
    }
  }, [makeCall, numberOfEmployees, revenue2019]);

  return { maxAmount, isPending };
};
