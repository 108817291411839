import React from 'react';

import StaticField from 'components/StaticField';
import { store } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getCurrentUser } from 'utils/user/getters';

const EmailStaticField = () => {
  const user = getCurrentUser(store.getState());
  const t = useTranslations(
    'pages.userProfile.sections.completingContactData.fields.userProfileEmail',
  );
  return <StaticField caption={t('caption')} text={user.email} />;
};

export default EmailStaticField;
