import styled from 'styled-components/macro';

const Wrapper = styled.main`
  h1 {
    margin-bottom: 5rem;
    text-align: center;
  }

  h2 {
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.colors.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    line-height: 1.5;
  }

  p,
  ol,
  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ol,
  ul {
    padding-left: 2.5rem;
  }
`;

export default Wrapper;
