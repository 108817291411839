import {
  BUILDING_TYPE,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_COOWNERSHIP,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_TYPE_OF_USE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_CONSTRUCTION_YEAR,
  BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
  BUILDING_STREET_AND_HOUSE_NUMBER,
  BUILDING_POSTAL_CODE,
  BUILDING_PLACE,
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_PROJECT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger } = fieldParsers;
export const mapBuildingPurposeToApi: InquiryFormMapper = (formValues) => ({
  'property-project': formValues[BUILDING_PROJECT],
  'property-kind': formValues[BUILDING_TYPE],
  'property-kind-description': formValues[BUILDING_TYPE_DESCRIPTION],
  'property-co-ownership-share': formValues[BUILDING_COOWNERSHIP],
  'property-condominium-nr': toInteger(formValues[BUILDING_CONDOMINIUM_NUM]),
  'property-number-of-residential-units': toInteger(
    formValues[BUILDING_NUMBER_OF_RESIDENTIAL_UNITS],
  ),
  'property-total-living-and-usable-space': toInteger(formValues[BUILDING_TOTAL_USABLE_SPACE]),
  'property-living-space': toInteger(formValues[BUILDING_LIVING_SPACE]),
  'property-commercial-space': toInteger(formValues[BUILDING_COMMERCIAL_SPACE]),
  'property-type-of-use': formValues[BUILDING_TYPE_OF_USE],
  'property-yearly-cold-rent-commercial': toInteger(
    formValues[BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL],
  ),
  'property-rented-space-commercial': toInteger(formValues[BUILDING_RENTED_SPACE_COMMERCIAL]),
  'property-yearly-cold-rent-residential': toInteger(
    formValues[BUILDING_COLD_RENT_PER_YEAR_LIVING],
  ),
  'property-rented-space-residential': toInteger(formValues[BUILDING_RENTED_SPACE_LIVING]),
  'property-construction-date': toInteger(formValues[BUILDING_CONSTRUCTION_YEAR]),
  'property-last-substantial-modernization-date': toInteger(
    formValues[BUILDING_LATEST_SUBSTENCIAL_MODERNISATION],
  ),
  'property-street-and-number': formValues[BUILDING_STREET_AND_HOUSE_NUMBER],
  'property-zip-code': formValues[BUILDING_POSTAL_CODE],
  'property-city': formValues[BUILDING_PLACE],
  'property-purchase-price': toInteger(formValues[BUILDING_PURCHASE_PRICE]),
  'property-price-of-land': toInteger(formValues[BUILDING_PLOT_PRICE]),
  'property-additional-costs': toInteger(formValues[BUILDING_ADDITIONAL_COSTS]),
  'property-construction-costs': toInteger(formValues[BUILDING_CONSTRUCTION_MANUFACTURE_COST]),
  'property-modernization-costs': toInteger(formValues[BUILDING_MODERNISATION_COSTS]),
  'property-debt-redemption': toInteger(formValues[BUILDING_DEBT_REDEMPTION]),
  'property-equity': toInteger(formValues[BUILDING_EQUITY]),
  'property-personal-contribution': toInteger(formValues[BUILDING_PERSONAL_CONTRIBUTION]),
  'property-building-site-cost': toInteger(formValues[BUILDING_PROPERTY]),
  'property-borrowed-capital-other': toInteger(formValues[BUILDING_DEBT_CAPITAL_OTHER]),
});
