import React, { useContext } from 'react';

import { InputWithField } from 'components/Input';
import { REPRESENTATIVE_PHONE_NUMBER } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { combineValidators } from 'utils/validators';

import { LegalRepresentativesContext } from '../LegalRepresentatives/LegalRepresentatives.context';

const RepresentativePhoneNumber: React.FC = () => {
  const { fieldName } = useContext(LegalRepresentativesContext);
  const { minPhoneLength, maxPhoneLength, isPhoneNumber, hasPhonePrefix, required } =
    useFieldValidators();

  return (
    <InputWithField
      name={`${fieldName}.${REPRESENTATIVE_PHONE_NUMBER}`}
      validate={combineValidators(
        hasPhonePrefix,
        minPhoneLength,
        maxPhoneLength,
        isPhoneNumber,
        required,
      )}
      autoComplete="tel"
      max={20}
    />
  );
};

export default RepresentativePhoneNumber;
