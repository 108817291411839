import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { ICompeonOffer } from 'models/CompeonOffer.model';
import { CompeonOfferWithQuestionnaire } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/CompeonOfferWithQuestionnaire';
import { finishQuestionnaireCall } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import { OfferWithoutQuestionnaire } from 'modules/Offers/InquiryOffersSection/CompeonOffers/OfferWithoutQuestionnaire/OfferWithoutQuestionnaire';
import { useToasts } from 'shared/hooks/useToasts';
import { showInterestInOffer } from 'store/inquiryDetails/actions';
import { ButtonComponent } from 'theme/components/Button';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  disabled?: boolean;
  offer: ICompeonOffer;
  bankName: string;
}

const ShowInterestInOffer = ({ offer, bankName, disabled = false }: Props) => {
  const t = useTranslations('pages');
  const modalTitle = `${t('inquiryDetails.offers.showInterestInOffer')} – ${bankName}`;

  const questionnaireId = offer.questionnaireIds && offer.questionnaireIds[0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { makeCall, error } = useDispatchApiCall();
  const { success, error: showError } = useToasts();

  const handleShowInterest = async () => {
    if (questionnaireId) {
      await makeCall(finishQuestionnaireCall(questionnaireId));
    }

    await makeCall(showInterestInOffer(offer.id), () => {
      success({ description: t('inquiryDetails.offers.interestInOffer') });
    });

    if (error) {
      showError({ description: t('errorPage.statuses.statusDefault') });
    }

    onClose();
  };

  return (
    <>
      {!disabled && (
        <ButtonComponent onClick={onOpen} data-testid="accept-offer-btn" w="100%" mt={4}>
          {t('inquiryDetails.offers.showInterestInOffer')}
        </ButtonComponent>
      )}
      {questionnaireId ? (
        <CompeonOfferWithQuestionnaire
          offer={offer}
          onClose={onClose}
          onAccepted={handleShowInterest}
          modalTitle={modalTitle}
          isOpen={isOpen}
        />
      ) : (
        <OfferWithoutQuestionnaire
          confirmText={t('inquiryDetails.offers.acceptOffer')}
          testId="accept-offer-confirm"
          isOpen={isOpen}
          onClose={onClose}
          handleShowInterest={handleShowInterest}
          modalTitle={modalTitle}
          offer={offer}
          bankName={bankName}
        />
      )}
    </>
  );
};

export default ShowInterestInOffer;
