import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { every as _every } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { useDownloadFiles } from 'components/DownloadBlock/useDownloadFile';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { IProgressSection } from 'pages/customerPortal/InquiryDetails/Dashboard/useProgressSections';
import {
  chooseSelectedInquiryTypeSpecificValue,
  useSelectedInquiryTypeSpecificValue,
} from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  getBankOffersSelector,
  getLegalRepresentativesRequireManualMerging,
  getlegalRepresentativesAccepted,
  isCustomerInvitationAccepted,
  isKycCompleted,
  isKycSkipped,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface UploadOffer extends Partial<IProgressSection> {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl: string;
  id?: string;
}

interface OfferFile {
  fileName: string;
  fileId: string;
  status: string;
  downloadFile?: () => Promise<void>;
}

const canDownload = (offerFiles: OfferFile[]) =>
  offerFiles.length > 0 &&
  _every(offerFiles, (offerFile) => offerFile.status !== DOWNLOAD_STATUS.NOT_UPLOADED);

export const UploadOfferStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  actionUrl,
  id,
}: UploadOffer) => {
  const { selectedInquiryType } = useFormConfig();
  const t = useTranslations();
  const history = useHistory();
  const hasKycSkipped = useSelector(isKycSkipped);
  const hasKycCompleted = useSelector(isKycCompleted);
  const manualMergeRequired = useSelector(getLegalRepresentativesRequireManualMerging);

  // Conditions
  const hasCustomerInvitationAccepted = useSelector(isCustomerInvitationAccepted);
  const legalRepresentativesAccepted = useSelector(getlegalRepresentativesAccepted);

  const kycCompletedAndAcceptedOrNoConflict =
    hasKycCompleted && legalRepresentativesAccepted && !manualMergeRequired;
  const uploadButtonEnabledDefault =
    selectedInquiryType === InquiryType.bfs && hasCustomerInvitationAccepted
      ? true
      : hasKycSkipped || kycCompletedAndAcceptedOrNoConflict;
  const uploadButtonEnabled = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: uploadButtonEnabledDefault,
    [InquiryType.hausbank]: true,
  });

  // Get all bank offers
  const bankOffers = useSelector(getBankOffersSelector);
  // Map bank offers to always be defined and a standardised format
  const offerFiles: OfferFile[] = bankOffers.map((offer) => {
    // Bank offers can be null
    if (offer) {
      return { fileName: offer.bankName, fileId: offer.fileId || '', status: offer.status };
    }
    return { fileName: '', fileId: '', status: DOWNLOAD_STATUS.NOT_UPLOADED };
  });

  // Get download functions for all bank offers
  const useDownloadFilesArgs = offerFiles.map(({ fileId, fileName }) => ({ fileId, fileName }));
  const { fileDownloads } = useDownloadFiles(useDownloadFilesArgs);

  // Map offer files to include download function by index
  const filesWithDownloads: OfferFile[] = offerFiles.map((offer, index) => ({
    ...offer,
    downloadFile: fileDownloads[index],
  }));

  const downloadButtonLabel = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t('pages.inquiryDetails.dashboard.actions.uploadOffer.downloadOffer'),
    [InquiryType.hausbank]: t(
      'pages.inquiryDetails.dashboard.actions.uploadOffer.downloadContract',
    ),
  });

  // Handler to download multiple bank offers
  const handleDownload = async () => {
    filesWithDownloads.forEach((offer) => {
      if (offer.downloadFile && offer.status) {
        offer.downloadFile();
      }
    });
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('pages.inquiryDetails.dashboard.actions.uploadOffer.title')}
          </HeadingComponent>
          <TextComponent mb={3} data-testid={'Upload-Contract-Text'}>
            {t('pages.inquiryDetails.dashboard.actions.uploadOffer.description')}
          </TextComponent>
          {actionUrl && !isCompleted && (
            <ButtonComponent
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              onClick={() => history.push(actionUrl)}
              variant="primary"
              disabled={!uploadButtonEnabled}
              data-testid={'Contract-Upload'}
            >
              {t('pages.inquiryDetails.dashboard.actions.uploadOffer.action')}
            </ButtonComponent>
          )}
          {canDownload(filesWithDownloads) && (
            <ButtonComponent onClick={handleDownload} data-testid={'Contract-Download'}>
              {downloadButtonLabel}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset htmlHeight="200" type="dashboard" value={ProgressSectionType.UPLOAD_OFFER} />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
