import { useState, useCallback, useEffect, ReactNode } from 'react';

import _get from 'lodash/get';

import {
  fetchCompanySuggestions,
  mapApiCompanySuggestions,
  companySuggestionsToOptions,
} from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/service';
import { pipe } from 'utils/helpers';
import { useDebounce } from 'utils/hooks/useDebounce';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const DEBOUNCE_TIME = 300;
const INITIAL_PAGE_NUMBER = 1;

export interface CompanySuggestion {
  address: string;
  city: string;
  country: string;
  'crefo-id': string;
  name: string;
  state: string;
  street: string;
  'trade-name': string;
  'zip-code': string;
  industry: Array<string>;
}

export interface SuggestionOption {
  label: string;
  value: CompanySuggestion;
  component: ReactNode;
}

const mapSuggestionsFromApiToOptions = (payload: unknown) => {
  const data = _get(payload, 'data.data');
  if (!data) {
    return [];
  }
  return pipe(mapApiCompanySuggestions, companySuggestionsToOptions)(data);
};

export const MIN_COMPANY_SUGGESTION_QUERY = 3;

export const useFetchCompanySuggestions = () => {
  const { makeCall, isPending: isLoading } = useDispatchApiCall();
  const [companies, setCompanies] = useState<SuggestionOption[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(INITIAL_PAGE_NUMBER);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, DEBOUNCE_TIME);

  const fetchCompanySuggestionOptions = useCallback(
    async (
      searchQuery: string,
      pageNumber: number = INITIAL_PAGE_NUMBER,
    ): Promise<SuggestionOption[]> => {
      if (searchQuery && searchQuery.length < MIN_COMPANY_SUGGESTION_QUERY) {
        return [];
      }
      const { payload } = await makeCall(fetchCompanySuggestions(searchQuery, pageNumber));
      return mapSuggestionsFromApiToOptions(payload);
    },
    [makeCall],
  );

  const updateSuggestions = useCallback(
    async (searchQuery: string) => {
      const companyOptions = await fetchCompanySuggestionOptions(searchQuery);

      setCurrentPageNumber(INITIAL_PAGE_NUMBER);
      setHasReachedEnd(!companyOptions.length);
      setCompanies(companyOptions);
    },
    [fetchCompanySuggestionOptions],
  );

  const nextPage = useCallback(async () => {
    if (hasReachedEnd) {
      return;
    }
    const nextPageNumber = currentPageNumber + 1;
    const companyOptions = await fetchCompanySuggestionOptions(search, nextPageNumber);

    setCompanies([...companies, ...companyOptions]);
    setCurrentPageNumber(nextPageNumber);

    if (companyOptions.length === 0) {
      setHasReachedEnd(true);
    }
  }, [companies, currentPageNumber, fetchCompanySuggestionOptions, hasReachedEnd, search]);

  useEffect(() => {
    updateSuggestions(debouncedSearch);
  }, [debouncedSearch, updateSuggestions]);

  return { companies, changeQuery: setSearch, nextPage, isLoading };
};
