import React from 'react';

import {
  StyledEmptyLabel as Label,
  StyledEmptyWrapper as Wrapper,
} from 'pages/customerPortal/CustomerInquiryList/NoInquiry/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

const LeasePlanNoInquiry = () => {
  const t = useTranslations('pages.inquiryList');
  return (
    <Wrapper>
      <Label>{t('noInquiries')}</Label>
    </Wrapper>
  );
};

export default LeasePlanNoInquiry;
