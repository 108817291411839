import _get from 'lodash/get';

import {
  FINANCING_DETAILS_FEE_DURATION,
  FINANCING_DETAILS_FEE_RATE,
  FINANCING_DETAILS_FINANCING_DURATION,
  FINANCING_DETAILS_INTEREST_RATE,
  FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS,
  FINANCING_DETAILS_REPAYMENT_RATE,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapFinancingDetailsToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision3 } = fieldParsers;
  const financingDetails = {
    financing_details: {
      fee_duration: Number(unknownToString(_get(data, FINANCING_DETAILS_FEE_DURATION))),
      fee_rate: toFloatPrecision3(unknownToString(_get(data, FINANCING_DETAILS_FEE_RATE))),
      financing_duration: Number(unknownToString(_get(data, FINANCING_DETAILS_FINANCING_DURATION))),
      interest_rate: toFloatPrecision3(
        unknownToString(_get(data, FINANCING_DETAILS_INTEREST_RATE)),
      ),
      notes_on_financing_parameters: _get(data, FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS),
      repayment_rate: toFloatPrecision3(
        unknownToString(_get(data, FINANCING_DETAILS_REPAYMENT_RATE)),
      ),
    },
  };

  return financingDetails;
};

export default mapFinancingDetailsToApi;
