import _get from 'lodash/get';

import {
  CURRENT_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_VACANCY,
  FUTURE_USAGE_REMAINING_USAGE_DURATION,
  FUTURE_USAGE_PRESALES_RATE,
  FUTURE_USAGE_VISITED_DATE,
  FUTURE_USAGE_VISITED_BY,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './mapProfitabilitySectionsToApi/utils';

const mapBankValuesAdditionalInformationToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');
  const bankValueData = {
    bank_values_additional_information: {
      current: {
        operating_costs:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_OPERATING_COSTS))) ||
          initialValue,
        vacancy: _get(data, FUTURE_USAGE_VACANCY) || null,
        remaining_usage_duration: _get(data, FUTURE_USAGE_REMAINING_USAGE_DURATION) || 0,
        presales_rate:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_PRESALES_RATE))) ||
          initialValue,
        visited_date: _get(data, FUTURE_USAGE_VISITED_DATE) || null,
        visited_by: _get(data, FUTURE_USAGE_VISITED_BY) || null,
      },
      future: {
        operating_costs:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_OPERATING_COSTS))) ||
          initialValue,
        vacancy: _get(data, FUTURE_USAGE_VACANCY) || null,
        remaining_usage_duration: _get(data, FUTURE_USAGE_REMAINING_USAGE_DURATION) || 0,
        presales_rate:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_PRESALES_RATE))) ||
          initialValue,
        visited_date: _get(data, FUTURE_USAGE_VISITED_DATE) || null,
        visited_by: _get(data, FUTURE_USAGE_VISITED_BY) || null,
      },
    },
  };

  return bankValueData;
};

export default mapBankValuesAdditionalInformationToApi;
