import React, { useEffect } from 'react';

import { SimpleGrid, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { useLoadProfitabilityData, MAX_RETRIES } from 'api/CompeonReverseApi/operation/queryHooks';
import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import paths from 'constants/paths';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useScrollToTop } from 'shared/hooks/useScrollToTop';
import { useToasts } from 'shared/hooks/useToasts';
import { EDIT_INQUIRY_CALLER_COMPONENT_NAME } from 'store/applicationConfig/types';
import { getInquiryDetailsSelector, hasOnlyFutureUsage } from 'store/inquiryDetails/selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { deepCamelcaseKeys } from 'utils/object';
import {
  removeInnerPropertyFromSessionStorage,
  getParsedItemFromSessionStorage,
} from 'utils/sessionStorage/helpers';
import { APPLICATION_CONFIG } from 'utils/sessionStorage/keys';

import { Evaluation } from './ProfitabilityCalculationComponents/Sections/Evaluation';
import { EvaluationRent } from './ProfitabilityCalculationComponents/Sections/EvaluationRent';
import { FinancingDetails } from './ProfitabilityCalculationComponents/Sections/FinancingDetails';
import { InvestmentCalculation } from './ProfitabilityCalculationComponents/Sections/InvestmentCalculation';
import { LendingValue } from './ProfitabilityCalculationComponents/Sections/LendingValue';
import { Presales } from './ProfitabilityCalculationComponents/Sections/Presales';
import { PresalesTopBoxes } from './ProfitabilityCalculationComponents/Sections/PresalesTopBoxes';
import { PropertyDetails } from './ProfitabilityCalculationComponents/Sections/PropertyDetails';
import { TopBoxes } from './ProfitabilityCalculationComponents/Sections/TopBoxes';
import MittweidaProfitabilityCalculation from '../../../../mittweida/portals/operations/pages/ProfitabilityCalculation';
import { FILTERS_USAGE_TYPES, PROFITABILITY_RECORD_TYPE } from '../SharedComponent/constants';
import {
  getMarketDataFilterLegacy,
  RESET_MARKETDATA_FILTER,
  setMarketDataFilter,
} from '../SharedComponent/filterManagementReducer';
import ProjectCostingFilters from '../SharedComponent/ProjectCostingFilters';
import ProjectCostingHeader from '../SharedComponent/ProjectCostingHeader';

export const ProfitabilityCalculation = () => {
  const { error } = useToasts();
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const t = useTranslations('pages.planningEvaluation.profitabilityCalculation');
  const errorsT = useTranslations('errors');
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      computedProfitability: deepCamelcaseKeys(response.data.attributes.computed_details),
    }),
    retry: (failureCount, responseError) => {
      if (responseError && failureCount === MAX_RETRIES) {
        error({ description: errorsT('requestFailed') });
        return false;
      }
      return true;
    },
  });
  const { salesCostRate } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const disableCurrentUsage = useSelector(hasOnlyFutureUsage);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const filterData = useSelector(getMarketDataFilterLegacy);
  const dispatch = useDispatch();
  useScrollToTop();

  useEffect(() => {
    if (disableCurrentUsage) {
      dispatch(
        setMarketDataFilter({
          usageType: FILTERS_USAGE_TYPES.FUTURE,
          saleType: filterData.saleType,
        }),
      );
    }
  }, [disableCurrentUsage, dispatch, filterData.saleType]);

  useEffect(() => {
    const applicationConfig = getParsedItemFromSessionStorage(APPLICATION_CONFIG);

    if (
      applicationConfig &&
      Object.hasOwnProperty.call(applicationConfig, EDIT_INQUIRY_CALLER_COMPONENT_NAME) &&
      applicationConfig[EDIT_INQUIRY_CALLER_COMPONENT_NAME] !==
        PROFITABILITY_RECORD_TYPE.PROPERTY_DETAILS
    ) {
      dispatch({ type: RESET_MARKETDATA_FILTER });
    } else if (
      applicationConfig &&
      Object.hasOwnProperty.call(applicationConfig, EDIT_INQUIRY_CALLER_COMPONENT_NAME)
    ) {
      removeInnerPropertyFromSessionStorage(APPLICATION_CONFIG, EDIT_INQUIRY_CALLER_COMPONENT_NAME);
    }
  }, [dispatch]);

  if (isLoading || !data?.computedProfitability) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }

  return (
    <>
      <ProjectCostingHeader
        heading={t('mainHeading')}
        subheading={inquiryDetails.company['name']}
      />
      <ProjectCostingFilters hideLabel disableCurrentUsage={disableCurrentUsage} />

      <TopBoxes
        topBoxes={data?.computedProfitability.topBoxes[filterData.usageType][filterData.saleType]}
      />

      <SimpleGrid columns={{ md: 1, lg: 2 }} gap="3rem" mb="2rem">
        <FinancingDetails
          financingDetails={data?.computedProfitability.financingDetails[filterData.saleType]}
          inquiryId={inquiryId}
        />
        <PropertyDetails
          propertyDetails={data?.computedProfitability.propertyDetails[filterData.usageType]}
          inquiryId={inquiryId}
        />
      </SimpleGrid>

      <InvestmentCalculation
        investment={data?.computedProfitability.investment}
        inquiryId={inquiryId}
      />

      {filterData.saleType === 'building' && (
        <LendingValue
          baseValues={data?.computedProfitability.lendingValue.baseValues}
          inquiryId={inquiryId}
          markdown={data?.computedProfitability.lendingValue.markdown[filterData.usageType]}
          salesPrice={data?.computedProfitability.lendingValue.salesPrice[filterData.usageType]}
          value={data?.computedProfitability.lendingValue.value[filterData.usageType]}
        />
      )}

      {filterData.saleType === 'unit' && filterData.usageType === 'future' && (
        <>
          <PresalesTopBoxes topBoxes={data?.computedProfitability.presalesTopBoxes} />
          <Presales inquiryId={inquiryId} presales={data?.computedProfitability.presales} />
        </>
      )}

      {(filterData.saleType === 'building' || filterData.saleType === 'unit') && (
        <Evaluation
          customer={data?.computedProfitability.evaluation.customer[filterData.usageType]}
          editPath={salesCostRate(inquiryId)}
          marketData={
            data?.computedProfitability.evaluation.marketData[filterData.usageType][
              filterData.saleType
            ]
          }
          realEstateExpert={
            data?.computedProfitability.evaluation.realEstateExpert[filterData.usageType][
              filterData.saleType
            ]
          }
        />
      )}

      {filterData.saleType === 'rent' && (
        <EvaluationRent
          baseValues={data?.computedProfitability.evaluationRent.baseValues}
          customerNow={data?.computedProfitability.evaluationRent.customerNow[filterData.usageType]}
          customerPlan={
            data?.computedProfitability.evaluationRent.customerPlan[filterData.usageType]
          }
          inquiryId={inquiryId}
          marketData={data?.computedProfitability.evaluationRent.marketData[filterData.usageType]}
          realEstateExpert={
            data?.computedProfitability.evaluationRent.realEstateExpert[filterData.usageType]
          }
        />
      )}
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiRLL]: ProfitabilityCalculation,
  [InquiryType.profiMittweida]: MittweidaProfitabilityCalculation,
});
