import React, { useState } from 'react';

import { Box, BoxProps, useMultiStyleConfig, VisuallyHidden, VStack } from '@chakra-ui/react';
import _uniqueId from 'lodash/uniqueId';

import { ELEMENT_IDS } from 'constants/elementIds';
import CheckmarkIcon from 'theme/components/Icon/CheckmarkIcon';
import CheckmarkLargeIcon from 'theme/components/Icon/CheckmarkLargeIcon';
import { textStyle } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';

type SizeType = 'small' | 'large';

// CircularProgress
type Props = {
  progressIncrement: number;
  progressTotal: number;
  progressType: string;
  size?: SizeType;
  isCompleted?: boolean;
  completedValue?: number;
  componentStyles?: object;
  isBfsService?: boolean;
} & BoxProps;

export const CircularProgress = ({
  progressIncrement = 0,
  progressTotal = 1,
  progressType,
  size = 'small',
  isCompleted = false,
  completedValue = 0,
  componentStyles,
  isBfsService = false,
  ...props
}: Props) => {
  const styles = useMultiStyleConfig('CircularProgress', { size });

  const ringProps = {
    size,
    ...componentStyles,
    ...props,
  };

  const [id] = useState(_uniqueId(ELEMENT_IDS.CIRCULAR_PROGRESS_BAR));
  const t = useTranslations('pages.inquiryDetails.dashboard.summary.circularProgressCaption');

  return (
    <>
      <Box
        aria-labelledby={id}
        aria-valuenow={completedValue}
        aria-valuemin={0}
        aria-valuemax={100}
        role="progressbar"
        sx={styles.circularProgress}
        {...ringProps}
      >
        <Box
          as="svg"
          viewBox="0 0 160 160"
          position="relative"
          zIndex="default"
          transform="rotate(-90deg)"
          aria-hidden="true"
        >
          <Box
            as="circle"
            cx="80"
            cy="80"
            r="74"
            fill="background.white"
            strokeWidth={size === 'large' ? '2px' : '10px'}
            stroke="border.lightGrey"
          />

          <Box
            as="circle"
            cx="80"
            cy="80"
            r="74"
            fill="none"
            strokeWidth={size === 'large' ? '6px' : '10px'}
            stroke="brand.default"
            strokeDasharray={`${Math.round(completedValue! * 4.65)} 999`}
            strokeDashoffset="0"
          />
        </Box>

        <VStack id={id} sx={styles.vStack}>
          {isCompleted && !isBfsService ? (
            <>
              {size === 'large' ? (
                <>
                  <CheckmarkLargeIcon boxSize={12} fill="brand.default" data-testid={'Ticks'} />
                  <Box px={4} {...textStyle.sizeS} textAlign="center" color="text.tertiary">
                    {t('allCompleted', { progressType })}
                  </Box>
                </>
              ) : (
                <>
                  <CheckmarkIcon boxSize={12} fill="brand.default" data-testid={'Ticks'} />
                  <VisuallyHidden>{t('allCompleted', { progressType })}</VisuallyHidden>
                </>
              )}
            </>
          ) : (
            <>
              <Box sx={styles.innerText}>
                <Box as="span" data-testid={'Progress-Increment'}>
                  {progressIncrement}
                </Box>
                <Box as="span" sx={styles.divider}>
                  <VisuallyHidden>von</VisuallyHidden>
                </Box>
                <Box as="span" data-testid={'Progress-Total'}>
                  {progressTotal}
                </Box>
              </Box>

              {size === 'large' ? (
                <Box px={4} {...textStyle.sizeS} textAlign="center" color="text.tertiary">
                  {t('completed', { progressType })}
                </Box>
              ) : (
                <VisuallyHidden>{t('completed', { progressType })}</VisuallyHidden>
              )}
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};
