import React from 'react';

import { InputWithField } from 'components/Input';
import { USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY } from 'modules/Inquiry/Form/formFields';

const AdditionalAssociationCompany = () => {
  return <InputWithField name={USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY} optional />;
};

export default AdditionalAssociationCompany;
