import React from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Condition from 'components/Condition';
import Spinner from 'components/Spinner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FileRequestsFromBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileRequestsFromBank/FileRequestsFromBank';
import FilesToBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToBank/FilesToBank';

import {
  useCustomerDocumentExchange,
  useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket,
} from './useCustomerDocumentExchange';
import { useShowLatestSignedContract } from './useShowDocExchangeSections';
import InquiryTypeSpecificHeader from '../InquiryDetailsHeaderButton';
import LatestSignedContractFromBank from '../LatestSignedContractFromBank';

export const LeasePlanCustomerDocumentExchange = () => {
  const { isLoading } = useCustomerDocumentExchange();
  useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket();
  const showLatestSignedContract = useShowLatestSignedContract();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <article>
      <InquiryDetailsHeader>
        <InquiryTypeSpecificHeader />
      </InquiryDetailsHeader>
      <Condition condition={showLatestSignedContract}>
        <LatestSignedContractFromBank />
      </Condition>
      <FileRequestsFromBank />
      <FilesToBank />
    </article>
  );
};
