import {
  QUICK_CHECK_AMOUNT_IN_EURO,
  QUICK_CHECK_CURRENT_COLLATERAL_VALUE,
  QUICK_CHECK_CURRENT_TOTAL_COMMITMENT,
  QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER,
  QUICK_CHECK_INFO_ON_COLLATERAL,
  QUICK_CHECK_INITIATOR,
  QUICK_CHECK_JUSTIFICATION_TEMPLATE,
  QUICK_CHECK_NEW_COLLATERAL,
  QUICK_CHECK_NEW_COMMITMENT,
  QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION,
  QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS,
  QUICK_CHECK_PROJECT_DESCRIPTION,
  QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
  QUICK_CHECK_TYPE_AND_GUARANTOR,
  QUICK_CHECK_WITHDRAWAL_REQUIREMENTS,
} from 'modules/Inquiry/Form/formFields';

export const quickCheckLabelsMap: { [key: string]: string } = {
  [QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS]:
    'pages.planningEvaluation.quickCheck.sections.informationAboutCustomer.fields.informationOnPreviousBusinessRelatioship.label',
  [QUICK_CHECK_INITIATOR]:
    'pages.planningEvaluation.quickCheck.sections.informationAboutCustomer.fields.informationAboutInitiator.label',
  [QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE]:
    'pages.planningEvaluation.quickCheck.sections.informationAboutCustomer.fields.statusOfCurrentProjectFinancingInhouse.label',
  [QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER]:
    'pages.planningEvaluation.quickCheck.sections.informationAboutCustomer.fields.financialCurcumstancesOfCustomer.label',
  [QUICK_CHECK_PROJECT_DESCRIPTION]:
    'pages.planningEvaluation.quickCheck.sections.projectInformation.fields.projectDescription.label',
  [QUICK_CHECK_INFO_ON_COLLATERAL]:
    'pages.planningEvaluation.quickCheck.sections.projectInformation.fields.informationOnCollateral.label',
  [QUICK_CHECK_WITHDRAWAL_REQUIREMENTS]:
    'pages.planningEvaluation.quickCheck.sections.projectInformation.fields.withdrawalRequirements.label',
  [QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION]:
    'pages.planningEvaluation.quickCheck.sections.otherInformation.fields.otherNoticesAndInformation.label',
  [QUICK_CHECK_JUSTIFICATION_TEMPLATE]:
    'pages.planningEvaluation.quickCheck.sections.otherInformation.fields.justificationTemplate.label',
  [QUICK_CHECK_TYPE_AND_GUARANTOR]:
    'pages.planningEvaluation.quickCheck.sections.creditValue.fields.typeAndGuarantor.label',
  [QUICK_CHECK_AMOUNT_IN_EURO]:
    'pages.planningEvaluation.quickCheck.sections.creditValue.fields.amountInEuro.label',
  [QUICK_CHECK_CURRENT_TOTAL_COMMITMENT]:
    'pages.planningEvaluation.quickCheck.sections.unsecuredPortion.fields.currentTotalCommitment.label',
  [QUICK_CHECK_NEW_COMMITMENT]:
    'pages.planningEvaluation.quickCheck.sections.unsecuredPortion.fields.newCommitment.label',
  [QUICK_CHECK_CURRENT_COLLATERAL_VALUE]:
    'pages.planningEvaluation.quickCheck.sections.unsecuredPortion.fields.currentCollateralValue.label',
  [QUICK_CHECK_NEW_COLLATERAL]:
    'pages.planningEvaluation.quickCheck.sections.unsecuredPortion.fields.newCollateral.label',
};
