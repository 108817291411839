import React from 'react';

import { Box, chakra, Table, useMultiStyleConfig } from '@chakra-ui/react';
import { useFlexLayout, useTable } from 'react-table';

import { FallbackComponent } from '../../../../../../pages/operationPortal/CompaniesDetails/FallbackComponent';
import { useGetTableData } from '../../hooks/useGetTableData';

export const FinancialIncomeStatementTable = () => {
  const { tableData: data, selectedYear } = useGetTableData();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Art',
        accessor: 'col1',
      },
      {
        Header: '',
        id: 'selectedYearOperator',
        accessor: 'col2',
        width: 10,
      },
      {
        Header: selectedYear,
        accessor: 'col3',
      },
      {
        Header: '',
        id: 'previousYearOperator',
        accessor: 'col4',
        width: 10,
      },
      {
        Header: (parseInt(selectedYear) - 1).toString(),
        accessor: 'col5',
      },
    ],
    [selectedYear],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<any>(
    { columns, data },
    useFlexLayout,
  );

  const styles = useMultiStyleConfig('FinacialsGuV', {});

  return (
    <>
      {data.length === 0 ? (
        <FallbackComponent />
      ) : (
        <Table variant={'unstyled'} {...getTableProps()}>
          <chakra.thead>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                <chakra.tr sx={styles.tr} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <React.Fragment key={column.id}>
                      <chakra.th
                        sx={styles.th}
                        {...column.getHeaderProps()}
                        width={column.width ? `${column.width}px` : undefined}
                      >
                        {column.render('Header') as React.ReactNode}
                      </chakra.th>
                    </React.Fragment>
                  ))}
                </chakra.tr>
              </React.Fragment>
            ))}
          </chakra.thead>
          <chakra.tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <chakra.tr
                    sx={
                      row.allCells[1].value === '=' || row.allCells[3].value === '='
                        ? styles.trHeadline
                        : styles.tr
                    }
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <React.Fragment key={cell.column.id}>
                          <chakra.td
                            sx={{
                              ...styles.td,
                              color:
                                (cell.column.id === 'col3' && row.allCells[1].value === '-') ||
                                (cell.column.id === 'col5' && row.allCells[3].value === '-')
                                  ? 'red.500'
                                  : 'text.secondary',
                            }}
                            {...cell.getCellProps()}
                          >
                            <Box display={'flex'} flexDirection={'row'}>
                              <Box
                                width={'100%'}
                                textAlign={typeof cell.value === 'number' ? 'right' : 'left'}
                              >
                                {cell.render('Cell') as React.ReactNode}
                              </Box>
                              {typeof cell.value === 'number' && (
                                <Box className={'Spacer'} width={'100vh'} />
                              )}
                            </Box>
                          </chakra.td>
                        </React.Fragment>
                      );
                    })}
                  </chakra.tr>
                </React.Fragment>
              );
            })}
          </chakra.tbody>
        </Table>
      )}
    </>
  );
};
