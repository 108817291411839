import React from 'react';

import { API_METHODS, callCompeonApi } from 'api/apiService';
import endpoints from 'api/CompeonApi/endpoints';
import CompanyElement from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/CompanyElement/CompanyElement';

const DEFAULT_COMPANY_SUGGESTIONS_PER_PAGE = 50;

export const fetchCompanySuggestions = (inputValue, page = 1) =>
  callCompeonApi({
    url: endpoints.COMPANY_SUGGESTIONS.compose({
      query: {
        query: inputValue,
        page: {
          number: page,
          size: DEFAULT_COMPANY_SUGGESTIONS_PER_PAGE,
        },
      },
    }),
    method: API_METHODS.GET,
  });

export const mapApiCompanySuggestions = (payload) =>
  payload.map(({ attributes }) => ({ ...attributes }));

export const companySuggestionsToOptions = (companies) =>
  companies.map((company) => ({
    label: company.name,
    value: company,
    component: (
      <CompanyElement name={company.name} city={company.city} zipCode={company['zip-code']} />
    ),
  }));
