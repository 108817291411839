import { fieldIsValid, getFieldValue } from 'modules/Inquiry/Form/formConditions';
import {
  BFS_SERVICE__FACTORING_OFFER,
  USER_PROFILE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';

const offerSectionValidation = (form: any): boolean => {
  return fieldIsValid(USER_PROFILE_PHONE_NUMBER)({ form });
};

const hintSectionValidation = (form: any): boolean => {
  return fieldIsValid(BFS_SERVICE__FACTORING_OFFER)({ form });
};

export const useRequestDetailsContractValidators = () => {
  const smsVerificationEnabled = useConfig(CONFIG.IS_SMS_TOKEN_ENABLED);
  return (form: any) => {
    const validators = [];
    if (getFieldValue(BFS_SERVICE__FACTORING_OFFER, form)) {
      validators.push(hintSectionValidation);
    }
    if (smsVerificationEnabled) {
      validators.push(offerSectionValidation);
    }
    return validators;
  };
};

export const useRequestDetailsLeadValidators = () => () => [() => true];
