import React from 'react';

import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import SectionHeading from 'components/Text/SectionHeading';
import { TextAreaWithField } from 'components/TextArea';
import {
  SECURITIES,
  SECURITIES_DESCRIPTION,
  SECURITIES_TYPE,
  SECURITIES_VALUE_ESTIMATION,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { RemoveButtonWrapper } from 'modules/Inquiry/Form/Steps/RequestDetails/Securities/styles';
import { FormSections } from 'schema/inquirySchema.models';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import getSecurityTypes from './securityTypes';
import StandaloneOptional from './StandaloneOptional';

const renderFields =
  ({ remove, t, formatMessage }) =>
  (name, index) => {
    return (
      <div key={name}>
        <FormRow>
          <SelectWithField
            name={`${name}.${SECURITIES_TYPE}`}
            options={getSecurityTypes(formatMessage)}
            data-testid={'select-field-' + SECURITIES_TYPE}
          />
          <AmountWithField name={`${name}.${SECURITIES_VALUE_ESTIMATION}`} />
        </FormRow>

        <FormRow separator>
          <div>
            <TextAreaWithField name={`${name}.${SECURITIES_DESCRIPTION}`} />
            <RemoveButtonWrapper>
              <ButtonComponent
                leftIcon={<DeleteIcon boxSize={6} display="block" />}
                onClick={() => remove(SECURITIES, index)}
                variant="secondary"
              >
                {t('pages.requestDetails.sections.securities.buttons.remove')}
              </ButtonComponent>
            </RemoveButtonWrapper>
          </div>
        </FormRow>
      </div>
    );
  };

const SecuritiesSection = () => {
  const { formatMessage } = useIntl();
  const t = useTranslations();
  const {
    mutators: { push, remove },
  } = useForm();

  return (
    <FormSection
      name={FormSections.securities}
      sectionNumber={3}
      title={t('pages.requestDetails.sections.securities.title')}
    >
      <SectionHeading>{t('pages.requestDetails.sections.securities.caption')}</SectionHeading>
      <StandaloneOptional>{t('other.optional')}</StandaloneOptional>

      <FieldArray name={SECURITIES}>
        {({ fields }) => fields.map(renderFields({ remove, t, formatMessage }))}
      </FieldArray>

      <ButtonComponent
        data-testid="add-collateral"
        leftIcon={<AddIcon boxSize={6} display="block" />}
        onClick={() => push(SECURITIES)}
        variant="secondary"
        w="100%"
      >
        {t('pages.requestDetails.sections.securities.buttons.add')}
      </ButtonComponent>
    </FormSection>
  );
};

export default SecuritiesSection;
