import { LabelsMap } from 'new/form/common/types';

import { hausbankCompanyDataLabelsMap } from './steps/companyData/labels';
import { hausbankFinancingNeedLabelsMap } from './steps/financingNeed/labels';
import { hausbankObjectInformationLabelsMap } from './steps/objectInformation/labels';
import { hausbankPersonalDataLabelsMap } from './steps/personalData/labels';

export const hausbankLabelsMap: LabelsMap = {
  ...hausbankObjectInformationLabelsMap,
  ...hausbankCompanyDataLabelsMap,
  ...hausbankFinancingNeedLabelsMap,
  ...hausbankPersonalDataLabelsMap,
};
