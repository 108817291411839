import { dzbFields } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const validateRetailerInformationSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: retailerInformationSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  numberOfStores,
  numberOfEmployees,
  retailSpace,
  erpProvider,
  enterpriseResourcePlanningSystemInUse,
} = dzbFields.retailerDataPage.retailerInformationSection;

export const retailerInformationSectionValidationMap = {
  [numberOfStores]: fieldValidators
    .number()
    .required()
    .positive()
    .allow(0)
    .max(InquiryConstants.MAX_DZB_NUMBER_OF_STORES),
  [retailSpace]: fieldValidators.number().optional().positive().allow(0),
  [numberOfEmployees]: fieldValidators
    .number()
    .required()
    .positive()
    .allow(0)
    .max(InquiryConstants.MAX_DZB_NUMBER_OF_EMPLOYEES),
  [erpProvider]: fieldValidators.string().required(),
  [enterpriseResourcePlanningSystemInUse]: fieldValidators.boolean().required(),
};
