import styled from 'styled-components/macro';

export const Text = styled.div`
  font-size: 1rem;
  line-height: 1.75;
  color: ${(props) => props.theme.colors.text.tertiary};

  b {
    color: ${(props) => props.theme.colors.text.secondary};
  }
`;
