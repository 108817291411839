import React from 'react';

import { YourFinanceSection } from './YourFinanceSection/YourFinanceSection';

export const KfinanzSections = () => {
  return (
    <>
      <YourFinanceSection />
    </>
  );
};
