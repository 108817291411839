import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createMutation } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { translations } from 'new/form/common/types';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ASSOCIATED_PEOPLE_QUERY_KEY } from '../useFetchAssociatedPeople';

const useDeleteBeneficialOwnerMutation = createMutation({
  mutationFn: async (variables: { inquiryId: string; beneficialOwnerId: string }) => {
    return EcoBankingAxiosClientAuthedInstance.delete(
      endpoints.INQUIRIES.SPECIALIZED.DZB.BENEFICIAL_OWNERS.DELETE.compose({
        params: { id: variables.inquiryId, beneficialOwnerId: variables.beneficialOwnerId },
      }),
    );
  },
});

export function useDeleteBeneficialOwner(beneficialOwnerId: string) {
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.new.beneficialOwners.actions.delete;
  const queryClient = useQueryClient();

  const mutation = useDeleteBeneficialOwnerMutation({
    onSuccess: () => {
      toasts.success({ description: t(successMessage) });
      queryClient.invalidateQueries([ASSOCIATED_PEOPLE_QUERY_KEY]);
    },
    onError: () => {
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(() => {
    return mutation.mutateAsync({
      inquiryId,
      beneficialOwnerId,
    });
  }, [mutation, inquiryId, beneficialOwnerId]);

  return {
    ...mutation,
    mutateAsync,
  };
}
