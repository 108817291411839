import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { SummaryFieldsType } from 'new/form/model';

export const mittweidaSummaryFields: SummaryFieldsType<MittweidaFieldTypes> = {
  financingNeedPage: {
    roleSection: {
      projectFinancingRole: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    projectCompositionSection: {
      totalInvestment: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      totalInvestmentInBuyingPhase: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      ownFunds: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      ownFundsInBuyingPhase: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      mezzanineCapital: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      subordinatedCapital: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      ownWork: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      financeWholeProject: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      financingAmount: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
    },
    objectInformationSection: {
      address: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      zipCode: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      city: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      description: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lotSize: {
        unit: 'squareMeters',
        showOnSummaryPage: true,
      },
      year: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      yearOfModernization: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      plannedProjectStart: {
        unit: 'date',
        showOnSummaryPage: true,
      },
      plannedProjectEnd: {
        unit: 'date',
        showOnSummaryPage: true,
      },
      furnishingQuality: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      qualityCondition: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      additionalBathroom: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      balcony: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lift: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      cellar: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      parkingLotsCount: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      parkingLotPrice: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      parkingLotRentNow: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      parkingLotRentFuture: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      garagesCount: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      garagesPrice: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      garageRentNow: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      garageRentFuture: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      futureObjectType: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      futureUsage: {
        typeOfUse: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        usageSpace: {
          unit: 'squareMeters',
          showOnSummaryPage: true,
        },
        plannedSalesPrice: {
          unit: 'defaultCurrency',
          showOnSummaryPage: true,
        },
        numberOfUnits: {
          unit: 'none',
          showOnSummaryPage: true,
        },
      },
      usageDeviation: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      currentObjectType: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      currentUsage: {
        typeOfUse: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        usageSpace: {
          unit: 'squareMeters',
          showOnSummaryPage: true,
        },
        numberOfUnits: {
          unit: 'none',
          showOnSummaryPage: true,
        },
      },
      currentTotalRentResidential: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      currentTotalRentCommercial: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      vacancyResidential: {
        unit: 'squareMeters',
        showOnSummaryPage: true,
      },
      vacancyCommercial: {
        unit: 'squareMeters',
        showOnSummaryPage: true,
      },
      futureTotalRentResidential: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      futureTotalRentCommercial: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      rentalContracts: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      rentalIntends: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    otherProjectInformation: {
      demolitionNecessary: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      heritageProtected: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      developmentNecessary: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      ownSalesPlanned: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      energyPass: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      buildingPermit: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      permitBasis: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      permitStatus: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      notesOnPermit: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
  },
  companyDetailPage: {
    companyDetailsSection: {
      loggedInUserCompanyName: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      selectedCompanyName: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
    completingCompanyDetailsSection: {
      company: {
        name: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        address: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        city: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        country: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        state: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        street: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        tradeName: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        zipCode: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        legalForm: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        industry: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        turnoverClass: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        foundingYear: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        foundingMonth: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        alreadyCustomer: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        alreadyContactWithBank: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        nameOfContactPerson: {
          unit: 'none',
          showOnSummaryPage: true,
        },
      },
    },
  },
  fundingDetailsPage: {
    otherFundingSection: {
      description: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
  },
  userProfilePage: {
    contactPersonSection: {
      gender: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      firstName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lastName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      email: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      phone: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    passwordSection: {
      password: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      passwordConfirmation: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
  },
};
