const SummaryCards = {
  baseStyle: {
    display: 'grid',
    'grid-template-columns': ['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)'],
    gap: 6,
    overflow: 'auto',
  },
};

export default SummaryCards;
