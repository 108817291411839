import React from 'react';

import { InputWithField } from 'components/Input';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  name: string;
};

const PhoneNumberField = ({ name }: Props) => {
  const t = useTranslations(
    'pages.userProfile.sections.completingContactData.fields.userProfilePhoneNumber',
  );

  return <InputWithField name={name} caption={t('caption')} autoComplete="tel" max={20} />;
};

export default PhoneNumberField;
