import * as React from 'react';

import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import FormRow from 'components/FormRow';
import Separator from 'components/FormRow/Separator';
import { INPUT_TYPE_DECIMAL, InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import Unit from 'components/Unit/Unit';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useIsUsageDeviation } from 'mittweida/config/conditions';
import { mittweidaFields, mittweidaFieldsWithoutParents } from 'mittweida/inquiry/fields';
import {
  StyledTypeTitle as Title,
  StyledUsageActions as Actions,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/styles';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export const CurrentUsageData = () => {
  const t = useTranslations();
  const { mutators } = useForm();
  const {
    currentObjectType,
    usageDeviation,
    currentUsage: { typeOfUse: typeOfUseWithParent },
  } = mittweidaFields.financingNeedPage.objectInformationSection;

  const { typeOfUse, usageSpace, numberOfUnits } =
    mittweidaFieldsWithoutParents.financingNeedPage.objectInformationSection.currentUsage;

  const objectTypeOptions = useInquirySelectOptions(currentObjectType);
  const typeOfUseOptions = useInquirySelectOptions(typeOfUseWithParent);

  const fieldArraySectionName = 'financingNeedPage.objectInformationSection.currentUsage';

  const showCurrentUsageData = useIsUsageDeviation();

  return (
    <>
      <FormRow>
        <YesNoRadioGroupWithField name={usageDeviation} />
      </FormRow>
      <Condition validation={showCurrentUsageData}>
        <FormRow>
          <SelectWithField
            name={currentObjectType}
            options={objectTypeOptions}
            placeholder={t(translations.placeholders.pleaseChoose)}
            data-testid={'mittweida-current-object-type-field'}
          />
        </FormRow>
        <FieldArray name={fieldArraySectionName}>
          {({ fields }) => {
            return (
              <>
                {fields.map((name, index) => {
                  return (
                    <div key={name}>
                      <Title>
                        #{index + 1}{' '}
                        {t(
                          translations.inquiryType.mittweida.pages.financingNeed.sections
                            .objectInformation.fields.usage.typeOfUse.title,
                        )}
                      </Title>
                      <FormRow>
                        <SelectWithField
                          name={`${name}.${typeOfUse}`}
                          options={typeOfUseOptions}
                          placeholder={t(translations.placeholders.pleaseChoose)}
                          data-testid={'mittweida-future-usage-type-field'}
                        />
                      </FormRow>
                      <FormRow>
                        <InputWithField
                          name={`${name}.${usageSpace}`}
                          type={INPUT_TYPE_DECIMAL}
                          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
                        />
                      </FormRow>
                      <FormRow>
                        <InputWithField
                          name={`${name}.${numberOfUnits}`}
                          type={'number'}
                          optional
                        />
                      </FormRow>
                      {index > 0 && (
                        <Actions>
                          <ButtonComponent
                            leftIcon={<DeleteIcon boxSize={6} display={'block'} />}
                            onClick={() => fields.remove(index)}
                            variant={'secondary'}
                          >
                            {t(
                              translations.inquiryType.mittweida.pages.financingNeed.sections
                                .objectInformation.fields.usage.remove,
                            )}
                          </ButtonComponent>
                        </Actions>
                      )}
                      <Separator />
                    </div>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
        <ButtonComponent
          leftIcon={<AddIcon boxSize={6} display={'block'} />}
          onClick={() => mutators.push('financingNeedPage.objectInformationSection.currentUsage')}
          variant={'secondary'}
          w={'100%'}
        >
          {t(
            translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
              .usage.add,
          )}
        </ButtonComponent>
      </Condition>
    </>
  );
};
