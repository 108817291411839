import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_INSTALLMENT_RATE,
  OFFER_MONTHLY_INSTALLMENT,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';

export const allowDigitalProcessingAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.DIGITAL_PROCESSING.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          'allow-digital-processing': true,
        },
      },
    },
  });

export interface Content {
  [OFFER_INSTALLMENT_RATE]: number;
  [OFFER_MONTHLY_INSTALLMENT]: number;
  [OFFER_FACTORING_LINE]: number;
  [OFFER_FACTORING_FEE]: number;
  [OFFER_PAYOUT_RATIO]: number;
}
