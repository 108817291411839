import { SET_LOADING, SET_EMAIL_AVAILABLE } from './actionTypes';

export const setIsEmailLoading = (isLoading) => (dispatch) =>
  dispatch({
    type: SET_LOADING,
    payload: {
      isLoading,
    },
  });

export const setIsEmailAvailable = (isEmailAvailable) => (dispatch) =>
  dispatch({
    type: SET_EMAIL_AVAILABLE,
    payload: {
      isEmailAvailable,
    },
  });
