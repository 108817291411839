import React from 'react';

import PropTypes from 'prop-types';

const StaticFormDescription = ({ field: { label } }) => <p>{label}</p>;

StaticFormDescription.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default StaticFormDescription;
