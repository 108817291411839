import styled from 'styled-components/macro';

import LinkButton from 'components/LinkButton';
import { borderRadius, transition } from 'theme/themeConstants';

export const StyledDownloadList = styled.ul`
  display: block;
  margin-top: 2rem;
  list-style: none;

  li {
    margin: 0.625rem;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  text-decoration: underline;
  text-transform: none;
  transition: color ${transition.default};

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &::before {
    content: '';
    display: inline-block;
    vertical-align: unset;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: ${borderRadius.default};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
