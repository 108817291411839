import _get from 'lodash/get';

import { compeonHirePurchaseUnits } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/hirePurchaseCompeonOffer.units';
import { compeonInvestmentLoanUnits } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/investmentLoanCompeonOffer.units';
import { compeonLeasingUnits } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/leasingCompeonOffer.units';
import { compeonLoanUnits } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/loanCompeonOffer.units';
import { compeonOtherUnits } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/otherCompeonOffer.units';
import { compeonOfferUnitFormatters } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/unitFormatters';
import { COMPEON_OFFER_PROPS } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/getPropertiesForProduct';
import { COMPEON_OFFER_PRODUCT_NAME } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/productNames.contants';

const { percentPerYear } = compeonOfferUnitFormatters;

const compeonMezzanineUnits = {
  [COMPEON_OFFER_PROPS.value]: percentPerYear,
};

const compeonOverdraftUnits = {
  [COMPEON_OFFER_PROPS.value]: percentPerYear,
};

const compeonProjectFinancingUnits = {
  [COMPEON_OFFER_PROPS.value]: percentPerYear,
};

const productPropertiesUnits = {
  [COMPEON_OFFER_PRODUCT_NAME.LOAN]: compeonLoanUnits,
  [COMPEON_OFFER_PRODUCT_NAME.LEASING]: compeonLeasingUnits,
  [COMPEON_OFFER_PRODUCT_NAME.HIRE_PURCHASE]: compeonHirePurchaseUnits,
  [COMPEON_OFFER_PRODUCT_NAME.OTHER]: compeonOtherUnits,
  [COMPEON_OFFER_PRODUCT_NAME.FACTORING]: {},
  [COMPEON_OFFER_PRODUCT_NAME.INVESTMENT_LOAN]: compeonInvestmentLoanUnits,
  [COMPEON_OFFER_PRODUCT_NAME.MEZZANINE]: compeonMezzanineUnits,
  [COMPEON_OFFER_PRODUCT_NAME.OVERDRAFT]: compeonOverdraftUnits,
  [COMPEON_OFFER_PRODUCT_NAME.PROJECT_FINANCING]: compeonProjectFinancingUnits,
};

export const includePropertyUnits = (translator, productName) => (property) => {
  const formattingFunc = _get(productPropertiesUnits, [productName, property.name]);
  if (formattingFunc && property.value !== null && typeof property.value !== 'undefined') {
    return { ...property, value: formattingFunc(translator)(property.value) };
  }
  return property;
};
