import { useDispatch, useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import {
  useFetchInquiryFiles,
  useFetchUpdatedFile,
} from 'shared/documentExchange/useFetchInquiryFiles';
import { useToasts } from 'shared/hooks/useToasts';
import { Channel, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useCustomerDocumentExchange = () => {
  const { selectedLanguage } = useLanguages();

  const { isLoading: isFilesLoading } = useFetchInquiryFiles({});

  const { isLoading: isFileRequestsLoading } = useFetchInquiryFileRequests({
    selectedLanguage,
  });

  return {
    isLoading: isFilesLoading || isFileRequestsLoading,
  };
};

export const useCustomerDocumentExchangeUpdatedFilesWebSocket = () => {
  const { selectedLanguage } = useLanguages();
  const { error } = useToasts();
  const { fetchUpdatedFile } = useFetchUpdatedFile();
  const { fetchData: fetchFileRequests } = useFetchInquiryFileRequests({
    selectedLanguage,
  });
  const t = useTranslations();

  const inquiryId = useSelector(getInquiryIdSelector);

  useWebSocket({
    channel: Channel.UPLOADABLE_FILE,
    params: {
      inquiry_id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      try {
        const data = JSON.parse(m.data as string);
        const fileId = data.message?.id;
        const relatedMessageType = data.type === 'uploaded_files' && fileId;
        if (relatedMessageType) {
          fetchUpdatedFile(fileId);
          fetchFileRequests(inquiryId);
        }
      } catch (err) {
        error({ description: t('errors.requestFailed') });
      }
    },
  });
};

export const useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket = () => {
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector);

  useWebSocket({
    channel: Channel.INQUIRY,
    params: {
      inquiry_id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });
};
