import styled from 'styled-components/macro';

import { mqLarge } from 'styles/breakpoints';

const Content = styled.div<{ inactive?: boolean }>`
  flex: 1 1 0%;
  padding-top: 0.125rem;
  ${(props) => props.inactive && `color: ${props.theme.colors.text.tertiary}`};

  ${mqLarge`
    width: 70%;
  `};
`;

export default Content;
