import { InitialFormStateType } from 'new/form/model';

import { HausbankFieldTypes } from '../fields';
import { hausbankSteps } from '../steps';

export const hausbankInitialFormState: InitialFormStateType<HausbankFieldTypes> = {
  currentStep: hausbankSteps.objectInformationPage,
  inquiryMode: 'create',
  pageValidations: {
    objectInformationPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        objectInformationSection: false,
        projectInformationSection: false,
      },
    },
    companyDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        wegSearchSection: false,
        wegDataCompletionSection: false,
        termsAndConditionsSection: false,
      },
    },
    financingNeedPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        purposeSection: false,
        financingNeedSection: false,
      },
    },
    personalDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        contactPersonSection: false,
        passwordSection: false,
        legalRepresentativesSection: false,
      },
    },
  },
};
