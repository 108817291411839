import styled from 'styled-components/macro';

import { StyledPageHeading as PortalPageHeading } from 'components/PortalPage/styles';
import Search from 'pages/customerPortal/CustomerInquiryList/SearchInput';

export const StyledPageHeading = styled(PortalPageHeading)`
  flex: 1 1 0%;
`;

export const StyledSearch = styled(Search)`
  width: 19rem;
  margin-left: 2rem;
`;

export const StyledRightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
