import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField, INPUT_TYPE_NUMBER } from 'components/Input';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import { canShowSection, validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  BFS_SERVICE__USE_FACTORING_AS_FINANCING,
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
  BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
} from 'modules/Inquiry/Form/formFields';
import { MAX_NUMBER_OF_DAYS, MIN_NUMBER_OF_DAYS } from 'modules/Inquiry/inquiry.constants';
import { PAYMENT_TERM } from 'onlinefactoring/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

export const validateBfsServiceBusinessSection = validateSectionFields([
  PAYMENT_TERM,
  BFS_SERVICE__USE_FACTORING_AS_FINANCING,
  BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
]);

const BfsServiceBusinessSection = () => {
  const t = useTranslations('pages.bfsService.sections.factomatBusinessSection');
  const tSymbol = useTranslations(
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness',
  );
  const tooltips = useTranslations(
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields',
  );
  const { required, minValue, maxValue } = useFieldValidators();

  return (
    <FormSection title={t('title')} sectionNumber={2}>
      <Condition condition={canShowSection(PAYMENT_TERM)} isBusinessCondition>
        <FormRow>
          <InputWithField
            name={PAYMENT_TERM}
            captionTooltip={tooltips('paymentTerm.tooltip')}
            sideSymbol={() => <>{tSymbol('fields.paymentTerm.sideSymbol')}</>}
            type={INPUT_TYPE_NUMBER}
            validate={combineValidators(
              required,
              minValue(MIN_NUMBER_OF_DAYS),
              maxValue(MAX_NUMBER_OF_DAYS),
            )}
          />
        </FormRow>
      </Condition>
      <FormRow>
        <YesNoRadioGroupWithField
          name={BFS_SERVICE__USE_FACTORING_AS_FINANCING}
          tooltip={t('fields.useFactoringAsFinancing.tooltip')}
          validate={combineValidators(required)}
        />
      </FormRow>
      <Condition
        condition={canShowSection(BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER)}
        isBusinessCondition
      >
        <FormRow>
          <InputWithField
            name={BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER}
            data-test-id={BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER}
            placeholder={t('fields.factoringProviderCustomer.placeholder')}
            optional
          />
        </FormRow>
      </Condition>
      <FormRow>
        <YesNoRadioGroupWithField
          name={BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES}
          tooltip={t('fields.alreadyAssignedReceivables.tooltip')}
          validate={combineValidators(required)}
        />
      </FormRow>
    </FormSection>
  );
};

export default BfsServiceBusinessSection;
