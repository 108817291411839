import React, { useMemo } from 'react';

import { RLLComputedProfitabilityTopBoxes } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getData } from './module';
import ProjectCostingBoxes from '../../../../SharedComponent/ProjectCostingBoxes';

interface TopBoxesProps {
  topBoxes: RLLComputedProfitabilityTopBoxes;
}

export const TopBoxes: React.FC<TopBoxesProps> = ({ topBoxes }) => {
  const t = useTranslations();
  const data = useMemo(() => getData(t, topBoxes), [t, topBoxes]);

  return <ProjectCostingBoxes values={data} />;
};
