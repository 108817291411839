import { RLLComputedProfitabilityPresalesTopBoxes } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

export const getData = (
  t: ReturnType<typeof useTranslations>,
  topBoxes: RLLComputedProfitabilityPresalesTopBoxes,
) => {
  const translate = (key: string) =>
    t(`pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.boxes.${key}`);
  const translateUnit = (unit: string) => t(`other.${unit}`);

  return [
    {
      name: translate('neededCostsPerSqm'),
      value: formatDecimal(topBoxes.neededCostsPerSqm, { unit: translateUnit('defaultCurrency') }),
    },
    {
      name: translate('neededCostsPerSqmWithPresales'),
      value: formatDecimal(topBoxes.neededCostsPerSqmWithPresales, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      name: translate('potentialSalesRevenueUnitReePerSqm'),
      value: formatDecimal(topBoxes.potentialSalesRevenueUnitReePerSqm, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      name: translate('presalesPercent'),
      value: formatDecimal(topBoxes.presalesPercent, { unit: translateUnit('percent') }),
    },
  ];
};
