import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN } from 'modules/Inquiry/Form/formFields';

const HirePurchaseInterestedInInvestmentLoan: React.FC = () => {
  return <YesNoRadioGroupWithField name={HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN} />;
};

export default HirePurchaseInterestedInInvestmentLoan;
