import { COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY } from '../../../../../../modules/Inquiry/Form/formFields';

export function shouldShowCompanySuggestion(
  isLoggedIn: boolean,
  loggedInUserCompanyName: string | undefined,
) {
  if (!isLoggedIn) {
    return true;
  }

  return loggedInUserCompanyName === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY;
}
