import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { StyledUploaderWrapper as Wrapper } from 'components/UploadBlock/styles';

const UploadDropzone = ({
  onFileDropped,
  disabled,
  onFileDropping,
  children,
  multipleFiles = false,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => onFileDropped(multipleFiles ? files : files[0]),
    multiple: multipleFiles,
    disabled,
  });

  useEffect(() => {
    onFileDropping(isDragActive);
  }, [isDragActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps({ autoComplete: undefined })} />
      {children}
    </Wrapper>
  );
};

UploadDropzone.defaultProps = {
  disabled: false,
  children: null,
  onFileDropping: () => {},
};

UploadDropzone.propTypes = {
  onFileDropped: PropTypes.func.isRequired,
  onFileDropping: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default UploadDropzone;
