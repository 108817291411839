import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasAnyValue } from 'modules/Inquiry/Form/formConditions';
import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
  USER_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';
import UserRepresentation from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/UserRepresentation';
import UserSharedRepresentation from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/UserSharedRepresentation';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import LegalRepresentatives from './components/LegalRepresentatives/LegalRepresentatives';

interface AuthorizationOfRepresentationSectionProps {
  sectionNumber: number;
}

const MINIMAL_REPRESENTATIVE_FIELDS = [REPRESENTATIVE_PHONE_NUMBER, REPRESENTATIVE_BIRTHDATE];

export const AuthorizationOfRepresentationSection = ({
  sectionNumber,
}: AuthorizationOfRepresentationSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      name={FormSections.legalRepresentation}
      title={t('pages.peopleDetails.sections.legalRepresentation.title')}
      sectionNumber={sectionNumber}
    >
      <FormRow>
        <UserRepresentation />
      </FormRow>

      <Condition condition={fieldHasAnyValue(USER_REPRESENTATION, ['true', true])}>
        <UserSharedRepresentation />
      </Condition>
      <LegalRepresentatives selfLegalRepresentativeVisibleFields={MINIMAL_REPRESENTATIVE_FIELDS} />
    </FormSection>
  );
};
