import _sortBy from 'lodash/sortBy';

import { LOAD_BANKS_SUCCESS } from 'api/CompeonApi/loadBanks/service';

const getOptionsFromBanks = (payload) => {
  const mappedData = payload.map(({ attributes: { name, code } }) => ({
    complexLabel: `${code} - ${name}`,
    label: name,
    value: code,
  }));

  return _sortBy(mappedData, ({ label }) => label);
};

export default (state = [], { type, payload }) => {
  switch (type) {
    case LOAD_BANKS_SUCCESS: {
      const { data: payloadData } = payload;
      const data = payloadData ? payloadData.data : [];
      const options = getOptionsFromBanks(data);

      return [...options];
    }
    default:
      return state;
  }
};
