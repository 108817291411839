import { AssetConfig } from 'config/formConfig/types';
import {
  REAL_ESTATE_PROJECT__NEW_BUILDING,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
} from 'modules/Inquiry/Form/formFields';

const RealEstateIcons: AssetConfig[] = [
  {
    id: REAL_ESTATE_PROJECT__NEW_BUILDING,
    titleId: 'pages.financingNeed.sections.realEstate.fields.realEstateProject.options.newBuilding',
    icon: 'neubauIcon',
  },
  {
    id: REAL_ESTATE_PROJECT__PURCHASE,
    titleId: 'pages.financingNeed.sections.realEstate.fields.realEstateProject.options.purchase',
    icon: 'kaufIcon',
  },
  {
    id: REAL_ESTATE_PROJECT__FOLLOW_UP,
    titleId: 'pages.financingNeed.sections.realEstate.fields.realEstateProject.options.followUp',
    icon: 'anschlussfinanzierungIcon',
  },
  {
    id: REAL_ESTATE_PROJECT__RECONSTRUCTION,
    titleId:
      'pages.financingNeed.sections.realEstate.fields.realEstateProject.options.reconstruction',
    icon: 'umbauIcon',
  },
  {
    id: REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
    titleId:
      'pages.financingNeed.sections.realEstate.fields.realEstateProject.options.developmentProject',
    icon: 'projectentwicklung',
    useStroke: true,
  },
];

export default RealEstateIcons;
