import React from 'react';

import FormRow from 'components/FormRow';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { USER_SOLE_REPRESENTATION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const UserSharedRepresentation = () => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.userSharedRepresentation',
  );

  const tooltipTranslation = t('tooltip', undefined, { defaultMessage: ' ' });
  const showTooltip = tooltipTranslation !== ' ';

  return (
    <FormRow>
      <YesNoRadioGroupWithField
        name={USER_SOLE_REPRESENTATION}
        tooltip={showTooltip ? tooltipTranslation : undefined}
      />
    </FormRow>
  );
};

export default UserSharedRepresentation;
