import _remove from 'lodash/remove';

import {
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_INSTANT,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_24_HOURS,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_3_DAYS,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_5_DAYS,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_7_DAYS,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_10_DAYS,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_15_DAYS,
  PURPOSE_KIND,
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PRE_FINANCING_PERIOD,
} from 'modules/Inquiry/Form/formFields';

const getPurchasedInvoicesPaidOutOnOptions = (
  formatMessage: any,
  formValues: { [key: string]: string },
) => {
  let options = [
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_INSTANT,
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_24_HOURS,
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_3_DAYS,
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_5_DAYS,
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_7_DAYS,
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_10_DAYS,
    BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_15_DAYS,
  ];

  const isFactomat = formValues[PURPOSE_KIND] === PURPOSE_KIND__FACTOMAT;
  const isBFSOnlineFactoring =
    formValues[PURPOSE_KIND] === PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING;
  // When a pre-financing period cannot be shown to user, preset it to 60 days
  const is60Days = formValues[PRE_FINANCING_PERIOD] === '60';

  if (isBFSOnlineFactoring) {
    _remove(options, (option) =>
      [
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_INSTANT,
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_5_DAYS,
      ].includes(option),
    );
  }

  if (isFactomat) {
    _remove(options, (option) =>
      [
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_24_HOURS,
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_7_DAYS,
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_15_DAYS,
      ].includes(option),
    );
    if (is60Days) options.push(BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_15_DAYS);
  }

  return options.map((option) => ({
    value: option.replace('after_', ''),
    label: formatMessage({
      id: `pages.bfsService.sections.factoringAndFactomatNeeds.fields.purchasedInvoicesPaidOn.options.${option}`,
    }),
  }));
};

export default getPurchasedInvoicesPaidOutOnOptions;
