import '@fontsource/ubuntu';

import { extendTheme } from '@chakra-ui/react';

import defaultTheme from 'styles/defaultTheme';

import { hexToHSL, hslAdjust, reduceLuminosity, setLuminosity } from './functions';
import { theme } from './theme';

export const createBrandedTheme = (brand: string = defaultTheme.colors.primary) =>
  extendTheme(theme, {
    colors: {
      brand: {
        default: brand,
        default_L93: setLuminosity(hexToHSL(brand), 93),
        default_L83: setLuminosity(hexToHSL(brand), 83),
        default_HalfS_L80: hslAdjust(hexToHSL(brand), 80),
        default_HalfS_L70: hslAdjust(hexToHSL(brand), 70),
        default_Minus5L: reduceLuminosity(hexToHSL(brand), 5),
      },
    },
  });

export default theme;
