import React, { useState } from 'react';

import { useDisclosure, ToastId } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { ModalComponent } from 'theme/components/Modal';
import { TooltipComponent } from 'theme/components/Tooltip';
import { useTranslations } from 'utils/hooks/useTranslations';

interface FileReclassificationButtonProps {
  initialClassification?: string;
  isPotentiallyIncorrectlyClassified?: boolean;
  onReclassify: (classification: string) => Promise<void> | Promise<ToastId | undefined> | null;
  options: { label: string; value: string }[];
}

interface FileReclassificationFormValues {
  classification: string;
}

const FileReclassificationButton = ({
  onReclassify,
  initialClassification,
  isPotentiallyIncorrectlyClassified,
  options,
}: FileReclassificationButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslations('components.upload.reclassifyDocumentModal');
  const editButtonTooltip = isPotentiallyIncorrectlyClassified
    ? t('isPotentiallyIncorrectlyClassified')
    : undefined;

  const handleSubmit = async ({ classification }: FileReclassificationFormValues) => {
    setIsLoading(true);

    try {
      await onReclassify(classification);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const footer = (
    <>
      <ButtonComponent type="button" variant="tertiary" mr={4} onClick={onClose}>
        {t('cancel')}
      </ButtonComponent>
      <ButtonComponent isLoading={isLoading} type="submit" form="file-reclassification">
        {t('confirm')}
      </ButtonComponent>
    </>
  );

  return (
    <>
      <TooltipComponent label={editButtonTooltip}>
        <EditIcon
          color={isPotentiallyIncorrectlyClassified ? 'status.error' : 'brand.default'}
          boxSize={4}
          cursor="pointer"
          onClick={onOpen}
        />
      </TooltipComponent>
      <ModalComponent isOpen={isOpen} onClose={onClose} title={t('title')} footer={footer}>
        <Form<FileReclassificationFormValues>
          onSubmit={handleSubmit}
          initialValues={{ classification: initialClassification }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} id="file-reclassification">
              <FormRow>
                <SelectWithField name="classification" options={options} caption={t('caption')} />
              </FormRow>
            </form>
          )}
        />
      </ModalComponent>
    </>
  );
};

export default FileReclassificationButton;
