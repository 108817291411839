import React from 'react';

import { InputWithField } from 'components/Input';
import Unit from 'components/Unit/Unit';
import { REAL_ESTATE_RENTED_OUT_AREA } from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

const RealEstateRenterOutArea = () => {
  return (
    <InputWithField
      name={REAL_ESTATE_RENTED_OUT_AREA}
      type="number"
      sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
      separateThousands
      optional
    />
  );
};

export default RealEstateRenterOutArea;
