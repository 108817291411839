import React from 'react';

import { StyledInputWrapper as InputWrapper } from 'components/PortalFormFields/InputFields/styles';

interface Props {
  id: string;
  value?: string;
  placeholder: string;
  name: string;
  onChange: (value: string) => void;
  hasError?: boolean;
}

const TextAreaInput = ({
  id,
  value = '',
  name,
  placeholder,
  onChange,
  hasError = false,
}: Props) => {
  const handleChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => onChange(ev.target.value);

  return (
    <InputWrapper hasError={hasError}>
      <textarea
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </InputWrapper>
  );
};

export default TextAreaInput;
