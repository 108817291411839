import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import { PhoneNumberField } from 'components/PhoneNumberField';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { translations } from 'new/form/common/types';
import { LegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import {
  FieldConfig,
  useGenerateField,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/formFields/useGenerateField';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

const { fields } = translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;

type LegalRepresentativeFieldsWithoutId = Omit<LegalRepresentative, 'id'>;

type LegalRepresentativeFields = Record<keyof LegalRepresentativeFieldsWithoutId, React.ReactNode>;

export const legalRepresentativeFieldConfig: FieldConfig<LegalRepresentativeFieldsWithoutId> = {
  salutation: {
    withStatic: false,
    component: GenderWithField,
    caption: fields.salutation.caption,
  },
  firstName: {
    withStatic: false,
    component: InputWithField,
    caption: fields.firstName.caption,
  },
  lastName: {
    withStatic: false,
    component: InputWithField,
    caption: fields.lastName.caption,
  },
  birthDate: {
    withStatic: false,
    componentProps: () => ({
      maxDate: Date.now(),
    }),
    component: DayPickerWithField,
    caption: fields.birthDate.caption,
  },
  email: {
    withStatic: false,
    component: InputWithField,
    caption: fields.email.caption,
  },
  phoneNumber: {
    withStatic: false,
    component: PhoneNumberField,
    caption: fields.phoneNumber.caption,
  },
  soleSignatureAuthorized: {
    withStatic: false,
    component: YesNoRadioGroupWithField,
    caption: fields.soleSignatureAuthorized.inquiryCaption,
  },
};

export function useGenerateLegalRepresentativeFields(
  mode: AssociatedPersonMode,
  prefix?: string,
): LegalRepresentativeFields {
  const generateField = useGenerateField<LegalRepresentativeFieldsWithoutId>(
    legalRepresentativeFieldConfig,
    mode,
    prefix,
  );

  return {
    salutation: generateField('salutation'),
    firstName: generateField('firstName'),
    lastName: generateField('lastName'),
    birthDate: generateField('birthDate'),
    email: generateField('email'),
    phoneNumber: generateField('phoneNumber'),
    soleSignatureAuthorized: generateField('soleSignatureAuthorized'),
  };
}
