import React, { useState } from 'react';

import { FieldRenderProps } from 'react-final-form';

import Input from 'components/Input';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

import VisibilityButton from './VisibilityButton';

interface PasswordInputProps {
  onChange: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
  sideSymbol: () => JSX.Element;
  type?: string;
  [key: string]: any;
  shouldShowError: boolean;
  optional: boolean;
  tooltip: string;
  captionTooltip: string;
  visited: boolean;
  customWidth: { numerator: number; denominator: number };
  validationMessage: string;
  additionalLabelElement: () => JSX.Element;
  submitFailed: boolean;
  isClearable: boolean;
  isHorizontal: boolean;
  children: React.ReactNode;
  errorSuffix: React.ReactNode;
  errorMessage: string | Object;
}
const PasswordInput = (props: PasswordInputProps) => {
  const [masked, setMasked] = useState(true);

  return (
    <Input
      {...(masked && { type: 'password' })}
      {...props}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target ? e.target.value : e;
        props.onChange(value);
      }}
      sideSymbol={() => (
        <VisibilityButton
          isVisible={masked}
          data-testid="password-visiblity-button"
          onClick={() => setMasked((masked) => !masked)}
        />
      )}
    />
  );
};

interface PasswordInputAdapterProps
  extends PasswordInputProps,
    FieldRenderProps<string, HTMLInputElement> {}

const PasswordInputAdapter = ({ input, meta, ...rest }: PasswordInputAdapterProps) => (
  <PasswordInput {...input} {...meta} {...rest} />
);

PasswordInputAdapter.propTypes = FinalFormFieldPropTypes;

const PasswordInputWithField = withFormField(PasswordInputAdapter);

export { PasswordInputWithField };

export default PasswordInput;
