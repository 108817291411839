import React, { useEffect } from 'react';

import { string } from 'prop-types';

import Page from 'components/Page/Page';
import PageTitle from 'components/PageTitle/PageTitle';
import PartnersContent from 'modules/PartnersContent';
import { webTrekkPushUpdate } from 'utils/tracker/webtrekk';

const StaticPage = (props) => {
  useEffect(() => {
    webTrekkPushUpdate();
  }, []);

  return (
    <Page>
      <PageTitle title={props.title} />
      <PartnersContent {...props} />
    </Page>
  );
};

StaticPage.propTypes = {
  title: string.isRequired,
};

export default StaticPage;
