import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const TimeIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 3.9a8.1 8.1 0 108.1 8.1A8.12 8.12 0 0012 3.9M12 22a10 10 0 1110-10 10 10 0 01-10 10" />
      <path d="M15 16a1.05 1.05 0 01-.71-.27l-3-2.72a.86.86 0 01-.29-.65V6.91a1 1 0 012 0V12l2.71 2.46a.85.85 0 010 1.28A1.05 1.05 0 0115 16" />
    </Icon>
  );
};

export default TimeIcon;
