import React, { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { NewStaticFieldWithField } from 'components/StaticField';
import { dzbFields, DzbFieldTypes } from 'dzb/inquiry/fields';
import { replaceUndefinedWithZero } from 'new/form/common/replaceUndefinedWithNumber';
import { formatDecimal } from 'utils/valueFormats';

const {
  shareOfWarehouse,
  foreignLogisticsServiceProviderPercentage,
  foreignFulfillmentByAmazonPercentage,
  shareOfForeignLogistics,
} = dzbFields.retailerDataPage.onlineQuestionnaireSection;

function useShareOfWarehouse() {
  const { change } = useForm();
  const {
    values: {
      retailerDataPage: {
        onlineQuestionnaireSection: { ownWarehousePercentage, foreignWarehousePercentage },
      },
    },
  } = useFormState<DzbFieldTypes>();

  useEffect(() => {
    const { own, foreign } = replaceUndefinedWithZero({
      own: ownWarehousePercentage,
      foreign: foreignWarehousePercentage,
    });

    const total = own + foreign;
    change(shareOfWarehouse, total);
  }, [change, ownWarehousePercentage, foreignWarehousePercentage]);

  useEffect(() => {
    const { foreign } = replaceUndefinedWithZero({
      foreign: foreignWarehousePercentage,
    });

    if (foreign === 0) {
      change(foreignLogisticsServiceProviderPercentage, 0);
      change(foreignFulfillmentByAmazonPercentage, 0);
      change(shareOfForeignLogistics, 0);
    }
  }, [change, foreignWarehousePercentage]);
}

export const ShareOfWarehouse = () => {
  useShareOfWarehouse();

  return (
    <NewStaticFieldWithField
      name={shareOfWarehouse}
      parseValue={(value: number) => formatDecimal(value, { digits: 2, unit: '%', locale: 'de' })}
    />
  );
};
