import React from 'react';

import { ModalProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useGetDashboardActions } from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { queryClient } from 'components/App';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'>;

export const WelcomePackageModal = ({ isOpen, onClose }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.welcomePackage');
  const inquiryId = useSelector(getInquiryIdSelector);

  return (
    <ModalComponent
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('title')}
    >
      <TextComponent mb={8}>{t('description')}</TextComponent>
      <UploadAnyFile
        url={endpoints.INQUIRIES.SPECIALIZED.DZB.FILES.UPLOAD_WELCOME_PACKAGE.compose({
          params: { id: String(inquiryId) },
        })}
        onUpload={async () => {
          await queryClient.invalidateQueries(useGetDashboardActions.getKey());
          onClose();
        }}
        // the naming is a bit confusing here, but `isAssessmentFile` only allows PDFs which is the case here
        isAssessmentFile={true}
      />
    </ModalComponent>
  );
};
