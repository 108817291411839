import { mittweidaFields } from 'mittweida/inquiry/fields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

const {
  name,
  city,
  street,
  zipCode,
  legalForm,
  industry,
  turnoverClass,
  foundingYear,
  alreadyCustomer,
  alreadyContactWithBank,
  nameOfContactPerson,
} = mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;

export const completingCompanyDetailsSectionValidationMap = {
  [name]: fieldValidators.string().required().max(InquiryConstants.COMPANY_NAME_MAX_LENGTH),
  [city]: fieldValidators.string().required().max(InquiryConstants.COMPANY_CITY_MAX_LENGTH),
  [street]: fieldValidators.string().required().max(InquiryConstants.COMPANY_STREET_MAX_LENGTH),
  [zipCode]: fieldValidators.postalCode().required().isValid(),
  [legalForm]: fieldValidators.string().required(),
  [industry]: fieldValidators.string().required(),
  [turnoverClass]: fieldValidators.string().required(),
  [foundingYear]: fieldValidators
    .number()
    .required()
    .max(new Date().getUTCFullYear())
    .min(InquiryConstants.MIN_FOUNDING_YEAR),
  [alreadyCustomer]: fieldValidators.boolean().required(),
  [alreadyContactWithBank]: fieldValidators.boolean().required(),
  [nameOfContactPerson]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_CONTACT_PERSON_MAX_LENGTH),
};
