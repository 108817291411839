import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import DateOfBirthStaticField from './DateOfBirthStaticField';
import EmailAddressStaticField from './EmailAddressStaticField';
import FirstNameStaticField from './FirstNameStaticField';
import LastNameStaticField from './LastNameStaticField';
import SalutationStaticField from './SalutationStaticField';
import TelephoneNumberStaticField from './TelephoneNumberStaticField';

interface Props {
  sectionNumber?: number;
}

const ContactPerson = ({ sectionNumber }: Props) => {
  const t = useTranslations('pages.companyDetails.sections.contactPerson');

  return (
    <FormSection
      name={FormSections.contactPerson}
      sectionNumber={sectionNumber ?? 2}
      title={t('title')}
    >
      <FormRow>
        <SalutationStaticField />
      </FormRow>
      <FormRow>
        <FirstNameStaticField />
        <LastNameStaticField />
      </FormRow>
      <FormRow>
        <EmailAddressStaticField />
        <TelephoneNumberStaticField />
      </FormRow>
      <FormRow>
        <DateOfBirthStaticField />
      </FormRow>
    </FormSection>
  );
};

export default ContactPerson;
