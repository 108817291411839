import React from 'react';

import { useSelector } from 'react-redux';

import InquiryRevision from 'modules/InquiryDetails/InquiryRevision/InquiryRevision';
import { PortalInquiryDetails } from 'new/summary/components/SummarySections';
import { SummaryStepProvider } from 'new/summary/SummaryPageContext';
import SummaryHeader from 'pages/customerPortal/InquiryDetails/Dashboard/SummaryHeader';
import { getNewInquiryDetails } from 'store/newInquiryDetails/selector';

export const HausbankDetailedInquiryInformation = () => {
  const values = useSelector(getNewInquiryDetails);

  return (
    <>
      <SummaryHeader />
      <SummaryStepProvider values={values}>
        <PortalInquiryDetails />
      </SummaryStepProvider>
      <InquiryRevision />
    </>
  );
};
