import { ICompanyDetailsState } from 'models/CompanyDetails.model';
import {
  mapAssociatedPerson,
  mapCompanyDetailsFromApi,
  mapReportsFromApi,
} from 'pages/operationPortal/CompaniesDetails/helpers/mapPayload';

import { ActionType, ACTION } from './actionTypes';

const initialState: ICompanyDetailsState = {
  id: '',
  baseData: {
    companyName: '',
    legalForm: '',
    foundingDate: '',
    registerNumber: '',
    districtCourt: '',
    street: '',
    zipCode: '',
    city: '',
    taxNumber: '',
    registerCity: '',
    taxVatNumber: '',
    phoneNumber: '',
    faxNumber: '',
    email: '',
    website: '',
    companyStaff: 0,
    companyTurnover: 0,
    balanceSheet: '',
    iban: '',
    alreadyCustomer: false,
    detailedAnnualTurnover: 0,
    countOfEmployees: 0,
    debtServiceAbility: 0,
    businessPurpose: '',
    industry: [],
    legalEntityIdentifier: '',
  },
  associatedPersons: [],
  inquiries: [],
  riskAnalysis: [],
};

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
    case ACTION.GET_COMPANY_DETAILS_SUCCESS: {
      return { ...state, baseData: mapCompanyDetailsFromApi(action.payload) };
    }

    case ACTION.GET_RISK_ANALYSIS_SUCCESS: {
      return {
        ...state,
        riskAnalysis: action.payload.data.included ? mapReportsFromApi(action) : [],
      };
    }

    case ACTION.GET_ASSOCIATED_PERSONS_SUCCESS: {
      return {
        ...state,
        associatedPersons: mapAssociatedPerson(action.payload),
      };
    }

    default: {
      return state;
    }
  }
};
