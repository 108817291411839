import React from 'react';

import Separator from 'components/FormRow/Separator';
import ConditionalSubform from 'modules/ConditionalSubform';
import { fieldHasValue, fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  LOAN_TYPE,
  LOAN_TYPE__AMORTISABLE,
  LOAN_TYPE__ANNUITY,
  LOAN_TYPE__PAYABLE_AT_TERM,
} from 'modules/Inquiry/Form/formFields';
import AnnuityLoan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/AnnuityLoan';
import MaturityLoan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/MaturityLoan';
import RedeemableLoan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/RedeemableLoan';

const loanTypes = [
  {
    field: LOAN_TYPE__AMORTISABLE,
    component: () => <RedeemableLoan />,
  },
  {
    field: LOAN_TYPE__ANNUITY,
    component: () => <AnnuityLoan />,
  },
  {
    field: LOAN_TYPE__PAYABLE_AT_TERM,
    component: () => <MaturityLoan />,
  },
];

const LoanTypeFields = () => (
  <ConditionalSubform
    condition={fieldIsValid(LOAN_TYPE)}
    subscription={{ values: true, visited: true }}
    renderOnSatisfy={() =>
      loanTypes.map(({ field, component }) => (
        <ConditionalSubform
          key={field}
          condition={fieldHasValue(LOAN_TYPE, field)}
          subscription={{ values: true, visited: true }}
          renderOnSatisfy={() => (
            <>
              <Separator />
              {component()}
            </>
          )}
        />
      ))
    }
  />
);

export default LoanTypeFields;
