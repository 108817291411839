import {
  LEASING_AMORTISATION_KIND__FULL_AMORTISATION,
  LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  LOAN_FIXED_INTEREST_RATE__FIXED,
  LOAN_FIXED_INTEREST_RATE__VARIABLE,
  LOAN_TYPE__AMORTISABLE,
  LOAN_TYPE__ANNUITY,
  LOAN_TYPE__PAYABLE_AT_TERM,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__PROJECT_FINANCING,
  PRODUCT_KIND__KFINANZ,
  PRODUCT_KIND,
  LEASING_AMORTISATION_KIND,
  LEASING_TAKEOVER,
  LOAN_TYPE,
  LOAN_FIXED_INTEREST_RATE,
  SECURITIES_TYPE,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_KIND__CLASSIC,
  PROPERTY_LOAN_KIND__ADVANCED,
  PROPERTY_LOAN_KIND__INTERIM,
  PRODUCT_KIND__PROPERTY_LOAN,
  PRODUCT_KIND__CREFO_FACTORING,
  PRODUCT_KIND__LIQUIDITY,
  PRODUCT_KIND__AVAL,
  FURTHER_NEED_VALUES,
  OTHER_FINANCING_SOLUTIONS,
  PUBLIC_FUNDING,
  EXPERT_ADVICE_ON_ALL,
  FUNDRAISING,
  INVESTMENT_AND_SECURITIES,
  PAYMENT_TRANSACTIONS,
  PRODUCT_KIND__FACTOMAT,
  PRODUCT_KIND__BFSS_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import getInvestmentLoanAmmortisationKindOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/investmentLoanAmmortisationKindOptions';
import getLeasingTakeoverOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/leasingTakeoverOptions';
import getSecurityTypes from 'modules/Inquiry/Form/Steps/RequestDetails/Securities/securityTypes';
import {
  optionsToTranslationMap,
  getIdFromObject,
} from 'modules/Inquiry/inquiryFieldsTranslations/helpers';

const leasingTakeoverValues = optionsToTranslationMap(getLeasingTakeoverOptions(getIdFromObject));

const getProductKindOptionsT = (optionName) =>
  `pages.requestDetails.sections.financialProduct.fields.productKind.options.${optionName}.title`;
const getFieldOptionsT = (sectionName, fieldName, optionName) =>
  `pages.requestDetails.sections.${sectionName}.fields.${fieldName}.options.${optionName}`;

export const requestDetailsValues = {
  [PRODUCT_KIND]: {
    [PRODUCT_KIND__LOAN]: getProductKindOptionsT('loan'),
    [PRODUCT_KIND__PROPERTY_LOAN]: getProductKindOptionsT('propertyLoan'),
    [PRODUCT_KIND__LEASING]: getProductKindOptionsT('leasing'),
    [PRODUCT_KIND__OVERDRAFT]: getProductKindOptionsT('overdraft'),
    [PRODUCT_KIND__FACTORING]: getProductKindOptionsT('factoring'),
    [PRODUCT_KIND__OTHER]: getProductKindOptionsT('other'),
    [PRODUCT_KIND__MEZZANINE]: getProductKindOptionsT('mezzanine'),
    [PRODUCT_KIND__PROJECT_FINANCING]: getProductKindOptionsT('project_financing'),
    [PRODUCT_KIND__INVESTMENT_LOAN]: getProductKindOptionsT('investment_loan'),
    [PRODUCT_KIND__HIRE_PURCHASE]: getProductKindOptionsT('hire_purchase'),
    [PRODUCT_KIND__CREFO_FACTORING]: getProductKindOptionsT('crefo_factoring'),
    [PRODUCT_KIND__KFINANZ]: getProductKindOptionsT(PRODUCT_KIND__KFINANZ),
    [PRODUCT_KIND__LIQUIDITY]: getProductKindOptionsT('liquidity'),
    [PRODUCT_KIND__AVAL]: getProductKindOptionsT(PRODUCT_KIND__AVAL),
    [PRODUCT_KIND__FACTOMAT]: getProductKindOptionsT(PRODUCT_KIND__FACTOMAT),
    [PRODUCT_KIND__BFSS_ONLINE_FACTORING]: getProductKindOptionsT(
      PRODUCT_KIND__BFSS_ONLINE_FACTORING,
    ),
  },

  [LEASING_AMORTISATION_KIND]: {
    [LEASING_AMORTISATION_KIND__FULL_AMORTISATION]: getFieldOptionsT(
      'leasing',
      'leasingAmortisationKind',
      'fullAmortisation',
    ),
    [LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION]: getFieldOptionsT(
      'leasing',
      'leasingAmortisationKind',
      'partialAmortisation',
    ),
  },
  [LEASING_TAKEOVER]: {
    ...leasingTakeoverValues,
  },
  [LOAN_TYPE]: {
    [LOAN_TYPE__AMORTISABLE]: getFieldOptionsT('loan', 'loanType', 'amortisable'),
    [LOAN_TYPE__ANNUITY]: getFieldOptionsT('loan', 'loanType', 'anniuity'),
    [LOAN_TYPE__PAYABLE_AT_TERM]: getFieldOptionsT('loan', 'loanType', 'payableAtTerm'),
  },
  [LOAN_FIXED_INTEREST_RATE]: {
    [LOAN_FIXED_INTEREST_RATE__FIXED]: getFieldOptionsT('loan', 'loanFixedInterestRate', 'fixed'),
    [LOAN_FIXED_INTEREST_RATE__VARIABLE]: getFieldOptionsT(
      'loan',
      'loanFixedInterestRate',
      'variable',
    ),
  },
  [SECURITIES_TYPE]: {
    ...optionsToTranslationMap(getSecurityTypes(getIdFromObject)),
  },
  [INVESTMENT_LOAN_AMORTISATION_KIND]: {
    ...optionsToTranslationMap(getInvestmentLoanAmmortisationKindOptions(getIdFromObject)),
  },
  [PROPERTY_LOAN_KIND]: {
    [PROPERTY_LOAN_KIND__CLASSIC]:
      'pages.requestDetails.sections.propertyLoan.fields.propertyLoan.options.classic',
    [PROPERTY_LOAN_KIND__ADVANCED]:
      'pages.requestDetails.sections.propertyLoan.fields.propertyLoan.options.advanced',
    [PROPERTY_LOAN_KIND__INTERIM]:
      'pages.requestDetails.sections.propertyLoan.fields.propertyLoan.options.interim',
  },
  [FURTHER_NEED_VALUES]: {
    [OTHER_FINANCING_SOLUTIONS]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.otherFinancingSolutions.label',
    [PUBLIC_FUNDING]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.publicFunding.label',
    [EXPERT_ADVICE_ON_ALL]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.expertAdviceOnAll.label',
    [PRODUCT_KIND__LEASING]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.leasing.label',
    [PRODUCT_KIND__FACTORING]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.factoring.label',
    [PAYMENT_TRANSACTIONS]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.paymentTransactions.label',
    [INVESTMENT_AND_SECURITIES]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.investmentAndSecurities.label',
    [FUNDRAISING]:
      'pages.requestDetails.sections.furtherNeed.fields.adviceTopics.fundraising.label',
  },
};
