import styled from 'styled-components/macro';

import { StyledInquiryOffers } from 'modules/Offers/InquiryOffersSection/styles';
import { mqMedium } from 'styles/breakpoints';

export const DetailedInformationWrapper = styled.div`
  ${StyledInquiryOffers} {
    padding: 1rem 0 2rem;
  }
`;

export const StyledKeyValueSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 0;
  word-break: break-word;

  ${mqMedium`
    padding: 2.625rem 0;
  `}
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  }
`;
