import React, { Component } from 'react';

import { bool, func, shape, string } from 'prop-types';

import IconsWrapper from 'components/Selects/shared/styledComponents/IconsWrapper';
import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import { selectValueConfig } from 'utils/propTypes';

export const SelectContext = React.createContext(null);

class Logic extends Component {
  setInputValueFromValue = (value) => {
    const labelToDisplay = this.getLabelToDisplay(value);
    this.setInputValue(labelToDisplay);
  };

  getPlaceholder = () => {
    const { isOpen } = this.state;
    const { value, placeholder } = this.props;

    const placeholderValue = placeholder;

    const labelToDisplay = this.getLabelToDisplay(value);
    return isOpen && value ? labelToDisplay : placeholderValue;
  };

  setInputValue = (inputValue) => {
    this.setState({ inputValue }); // eslint-disable-line react/no-unused-state
  };

  openMenu = () => {
    this.setState({ isOpen: true });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  handleSelect = (selectedItem) => {
    const { onChange } = this.props;

    if (selectedItem) {
      this.setInputValueFromValue(selectedItem.value);
      onChange(selectedItem.value);
    }

    this.closeMenu();
    this.inputRef.current.blur();
  };

  orDefault = (getFunction) => {
    const overriddenFunction = getFunction(this.props.overridden);
    const defaultFunction = getFunction(this);

    return overriddenFunction || defaultFunction;
  };

  preventDefaultOnBackspacePress = (e) => {
    const {
      target: { value: typedValue },
    } = e;
    const { value: selectedValue } = this.props;

    if (!typedValue.length || (selectedValue && !typedValue.length)) {
      e.preventDefault();
    }
  };

  handleKeyDown = (e) => {
    const {
      key,
      target: { value },
    } = e;

    if (key === 'Backspace') {
      this.preventDefaultOnBackspacePress(e);
      this.orDefault((o) => o.handleBackspaceIfValueIsEmpty)({ value });
    } else if (key === 'Escape' || key === 'Tab') {
      this.inputRef.current.blur();
      this.closeMenu();
    }
  };

  handleBackspaceIfValueIsEmpty = ({ value }) => {
    if (!value.length) {
      this.clearValue();
    }
  };

  clearValue = () => {
    const { onChange } = this.props;

    onChange('');
    this.closeMenu();
    this.inputRef.current.blur();
  };

  handleOuterClick = () => {
    const { value } = this.props;

    this.setInputValueFromValue(value);
    this.closeMenu();
    this.inputRef.current.blur();
  };

  handleInputBlur = () => {
    const { onBlur } = this.props;

    onBlur();
  };

  renderSideSymbol = (selectedItem) => {
    const { symbol, isClearable } = this.props;

    return (
      <IconsWrapper onClick={this.openMenu}>
        {isClearable && selectedItem && <CloseIcon boxSize={6} onClick={this.clearValue} />}
        {symbol ? symbol() : <CaretDownIcon boxSize={6} />}
      </IconsWrapper>
    );
  };

  render() {
    return null;
  }
}

Logic.propTypes = {
  value: selectValueConfig.isRequired,
  placeholder: string,
  onChange: func,
  overridden: shape({
    handleBackspaceIfValueIsEmpty: func,
  }),
  onBlur: func,
  isClearable: bool,
  symbol: func,
};

Logic.defaultProps = {
  placeholder: null,
  onChange: () => null,
  overridden: {
    handleBackspaceIfValueIsEmpty: () => null,
  },
  onBlur: func,
  isClearable: false,
  symbol: null,
};

export default Logic;
