import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { CORONA_VIABILTIY_2020 } from 'modules/Inquiry/Form/formFields';

const Viability2020: React.FC = () => {
  return <YesNoRadioGroupWithField name={CORONA_VIABILTIY_2020} />;
};

export default Viability2020;
