import React from 'react';

import { FileType, IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';

import DownloadFileInRequest, { DownloadFileInRequestProps } from './DownloadFileInRequest';
import { previewFileInRequestIcon } from './PreviewFileInRequest';

interface MobileViewFileInRequestProps extends Omit<DownloadFileInRequestProps, 'icon' | 'label'> {
  file: IFile | CompeonDocument;
  type?: FileType;
}

/**
 * Mobile devices does not support downloading files, but previewing files work differently from desktop
 */
const MobileViewFileInRequest = (props: MobileViewFileInRequestProps) => {
  return <DownloadFileInRequest {...props} label="preview" icon={previewFileInRequestIcon} />;
};

export default MobileViewFileInRequest;
