import {
  BANK_DETAILS_IBAN,
  CLIENT_ROLE,
  MACHINE_CUSTOM_CATEGORY,
  NUMBER_OF_TECHNOLOGIES,
  NUMBER_OF_TECHNOLOGIES__ONE,
  PURPOSE_KIND,
  TECHNOLOGY_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { mapMachineToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapMachineToApi';
import {
  ALREADY_ASSIGNED,
  INVOICES_PER_YEAR,
  MASTER_CRAFTSMAN,
  NUMBER_OF_LIFTS,
  PAYMENT_TERMS,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  PERFORM_TUV_EXAMINATIONS,
  SHARE_OF_INSURANCE_CLAIMS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
} from 'onlinefactoring/formFields';

import {
  ALREADY_FACTORING,
  BUSINESS_SPECIFICS,
  DOWN_PAYMENT,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  PAYMENT_TERM,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  TRADE_CREDIT_INSURANCE,
  VOLUME_OF_SALES,
} from '../formFields';

export function mapPurposeToApi(allFields: any) {
  return {
    factoring_sales_gross: allFields[VOLUME_OF_SALES],
    'purpose-kind': allFields[PURPOSE_KIND],
    factoring_business_customers_share: allFields[SHARE_OF_SALES_BUSINESS_CUSTOMERS],
    factoring_main_customers_share: allFields[SHARE_OF_SALES_MAIN_CUSTOMERS],
    factoring_foreign_customers_share: allFields[SHARE_OF_SALES_FOREIGN_CUSTOMERS],
    factoring_active_customers: allFields[NUMBER_OF_CUSTOMERS],
    factoring_number_of_invoices: allFields[NUMBER_OF_INVOICES],
    factoring_standard_payment_term: allFields[PAYMENT_TERM],
    factoring_already_in_use: allFields[ALREADY_FACTORING],
    factoring_receivables_assigned: allFields[RECEIVABLES_ASSIGNED_ELSEWHERE],
    factoring_issue_payment_or_progress_invoices: allFields[DOWN_PAYMENT],
    factoring_trade_credit_insurance_taken: allFields[TRADE_CREDIT_INSURANCE],
    factoring_business_specifics: allFields[BUSINESS_SPECIFICS],
    iban: allFields[BANK_DETAILS_IBAN],

    // kfinanz purpose
    number_of_lifts: allFields[NUMBER_OF_LIFTS],
    company_master_craftsman: allFields[MASTER_CRAFTSMAN],
    perform_tuv_examinations: allFields[PERFORM_TUV_EXAMINATIONS],
    share_of_sales_business_customers: allFields[SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP],
    share_of_insurance_claims: allFields[SHARE_OF_INSURANCE_CLAIMS],
    percentage_of_services_paid_by_invoice: allFields[PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE],
    percentage_of_invoices_for_sell: allFields[PERCENTAGE_OF_INVOICES_FOR_SELL],
    invoices_per_year: allFields[INVOICES_PER_YEAR],
    receivables_assigned: allFields[ALREADY_ASSIGNED],
    payment_terms: allFields[PAYMENT_TERMS],

    // energy efficiency
    company_or_contractor: allFields[CLIENT_ROLE],
    one_or_several_machines: allFields[NUMBER_OF_TECHNOLOGIES],
    several_machines_description: allFields[TECHNOLOGY_DESCRIPTION],
    ...conditionalObject(allFields[NUMBER_OF_TECHNOLOGIES] === NUMBER_OF_TECHNOLOGIES__ONE, {
      ...mapMachineToApi(allFields),
      system_category: allFields[MACHINE_CUSTOM_CATEGORY],
    }),
  };
}
