import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  STRUCTURE_NUMBER,
  ADP_NUMBER,
  EXCLUDING_QUESTION_FIFTH,
  EXCLUDING_QUESTION_FIRST,
  EXCLUDING_QUESTION_FOURTH,
  EXCLUDING_QUESTION_SECOND,
  EXCLUDING_QUESTION_THIRD,
  CONTRACTOR_COMPANY_INFORMATION,
  COMPANY_DETAILS_COMPANY_TAX_NUMBER,
  COMPANY_DETAILS_INSTITUTIONAL_NUMBERS,
  CUSTOMER_COMPANY_ADDRESS,
} from 'modules/Inquiry/Form/formFields';

// The order of these labels is relevant for inquiry details page
export const companyDetailsLabelMap: { [key: string]: string } = {
  [COMPANY_DETAILS_GENDER]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.caption',
  [COMPANY_DETAILS_FIRST_NAME]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsFirstName.caption',
  [COMPANY_DETAILS_LAST_NAME]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsLastName.caption',
  [COMPANY_DETAILS_EMAIL]:
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail.caption',

  [COMPANY_DETAILS_EXISTING_USER_COMPANY]:
    'pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany.caption',
  [COMPANY_DETAILS_COMPANY]:
    'pages.companyDetails.sections.company.fields.companyDetailsCompany.caption',
  [STRUCTURE_NUMBER]: 'pages.companyDetails.sections.contactPerson.fields.structureNumber.caption',
  [ADP_NUMBER]: 'pages.companyDetails.sections.contactPerson.fields.adpNumber.caption',
  [EXCLUDING_QUESTION_FIRST]: 'inquiry.excludingQuestions.first',
  [EXCLUDING_QUESTION_SECOND]: 'inquiry.excludingQuestions.second',
  [EXCLUDING_QUESTION_THIRD]: 'inquiry.excludingQuestions.third',
  [EXCLUDING_QUESTION_FOURTH]: 'inquiry.excludingQuestions.fourth',
  [EXCLUDING_QUESTION_FIFTH]: 'inquiry.excludingQuestions.fifth',
  [CONTRACTOR_COMPANY_INFORMATION]:
    'inquiryType.energyEfficiency.steps.company.fields.contractorCompanyInformation',
  [COMPANY_DETAILS_COMPANY_TAX_NUMBER]:
    'inquiryType.bfsService.steps.companyDetails.sections.fields.companyTaxNumber.caption',
  [COMPANY_DETAILS_INSTITUTIONAL_NUMBERS]:
    'inquiryType.bfsService.steps.companyDetails.sections.fields.institutionalIdentifier.caption',
  [CUSTOMER_COMPANY_ADDRESS]: 'pages.companiesDetails.overview.address',
};
