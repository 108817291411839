import { hausbankFields } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateProjectInformationSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: projectInformationSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  residentialUnitsCount,
  commercialUnitsCount,
  hasOwnerWithMoreThanThirdOfShares,
  isContractor,
  majorityOwnerShares,
  wegIsSubCommunity,
  propertyIsLeasehold,
  hasMajorityDecision,
} = hausbankFields.objectInformationPage.projectInformationSection;

export const projectInformationSectionValidationMap = {
  [residentialUnitsCount]: fieldValidators.number().required(),
  [commercialUnitsCount]: fieldValidators.number().required(),
  [hasOwnerWithMoreThanThirdOfShares]: fieldValidators.boolean().required(),
  [isContractor]: fieldValidators.boolean().required(),
  [majorityOwnerShares]: fieldValidators.number().min(33.33).max(100).required(),
  [wegIsSubCommunity]: fieldValidators.boolean().required(),
  [propertyIsLeasehold]: fieldValidators.boolean().required(),
  [hasMajorityDecision]: fieldValidators.boolean().required(),
};
