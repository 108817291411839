import {
  USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__GMBH,
  BFS_SERVICE_USER_INDIVIDUAL_ENTERPRISE,
  USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE,
  USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN,
  USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE,
  USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN,
  USER_PROFILE_LEGAL_FORM__OTHER,
} from 'modules/Inquiry/Form/formFields';

const defaultLegalFormValues = [
  {
    value: USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.aktiengesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GMBH,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gmbh',
  },
  {
    value: BFS_SERVICE_USER_INDIVIDUAL_ENTERPRISE,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.einzelunternehmung',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.haftungsbeschrankte',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.offeneHandelsgesellschaft',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.eingetragenerVerein',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.unternehmergesellschaftHaftungsbeschrankte',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.gesellschaftBurgerlichenRechts',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.eingetragenerKaufmann',
  },
  {
    value: USER_PROFILE_LEGAL_FORM__OTHER,
    id: 'pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm.options.other',
  },
];

const getBfsServiceLegalFormOptions = (formatMessage: (arg0: { id: string }) => any) => {
  return defaultLegalFormValues.map(({ value, id }) => ({ value, label: formatMessage({ id }) }));
};

export default getBfsServiceLegalFormOptions;
