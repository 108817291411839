import React from 'react';

import Condition from 'modules/ConditionalSubform/Condition';
import { isSectionValid } from 'modules/Inquiry/Form/formConditions';
import {
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__NEW_BUILDING,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
} from 'modules/Inquiry/Form/formFields';
import DetailsOfBuilding from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/DetailsOfBuildingSection';
import FollowUpFinancing from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/FollowUpFinancingSection';
import NewBuilding from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/NewBuildingSection';
import ProjectDevelopment from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/ProjectDevelopmentSection';
import Purchase from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/PurchaseSection';
import RealEstateProjectSection, {
  validateRealEstateProjectSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/RealEstateProjectSection';
import Reconstruction from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/ReconstructionSection';
import { fieldBusinessConditions as businessConditions } from 'schema/inquirySchema.conditions';
import { useFieldValue } from 'store/hooks/useFormValues';

const sectionMap = {
  [REAL_ESTATE_PROJECT__NEW_BUILDING]: <NewBuilding />,
  [REAL_ESTATE_PROJECT__PURCHASE]: <Purchase />,
  [REAL_ESTATE_PROJECT__FOLLOW_UP]: <FollowUpFinancing />,
  [REAL_ESTATE_PROJECT__RECONSTRUCTION]: <Reconstruction />,
  [REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT]: <ProjectDevelopment />,
};

const RealEstateProjectDetails = () => {
  const selectedProject = useFieldValue(REAL_ESTATE_PROJECT, '');
  return sectionMap[selectedProject] ?? null;
};

const RealEstate = () => {
  return (
    <>
      <RealEstateProjectSection />
      <Condition condition={isSectionValid(validateRealEstateProjectSection)}>
        <RealEstateProjectDetails />

        <Condition condition={businessConditions.notRealEstateDevProject} isBusinessCondition>
          <DetailsOfBuilding />
        </Condition>
      </Condition>
    </>
  );
};

export default RealEstate;
