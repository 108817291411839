import * as React from 'react';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import { TextComponent } from '../../../../../../theme/components/Text';

export const QualityData = () => {
  const { furnishingQuality, qualityCondition, additionalBathroom, balcony, lift, cellar } =
    mittweidaFields.financingNeedPage.objectInformationSection;
  const {
    furnishingQuality: furnishingQualityTranslation,
    conditionQuality: conditionQualityTranslation,
  } = translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields;

  const furnishingQualityOptions = useInquirySelectOptions(furnishingQuality);

  const qualityConditionOptions = useInquirySelectOptions(qualityCondition);

  const t = useTranslations();

  return (
    <>
      <TextComponent mb={6}>
        {t(
          translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation
            .subheadings.objectQuality,
        )}
      </TextComponent>
      <FormRow>
        <SelectWithField
          name={furnishingQuality}
          placeholder={t(translations.placeholders.pleaseChoose)}
          options={furnishingQualityOptions}
          data-testid={'mittweida-furnishing-select-field'}
          tooltip={t(furnishingQualityTranslation.tooltip)}
        />
        <SelectWithField
          name={qualityCondition}
          placeholder={t(translations.placeholders.pleaseChoose)}
          options={qualityConditionOptions}
          data-testid={'mittweida-condition-select-field'}
          tooltip={t(conditionQualityTranslation.tooltip)}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={additionalBathroom} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={balcony} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={lift} />
      </FormRow>
      <FormRow separator={true}>
        <YesNoRadioGroupWithField name={cellar} />
      </FormRow>
    </>
  );
};
