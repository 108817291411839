import styled from 'styled-components/macro';

import Spinner from 'components/Spinner';

const StyledSpinner = styled(Spinner).attrs(({ inverted = true }) => ({
  inverted,
}))`
  position: absolute;
  top: 50%;
  left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  transform: translateY(-50%);
`;

export default StyledSpinner;
