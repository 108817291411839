import React, { useState } from 'react';

import { Flex, useDisclosure } from '@chakra-ui/react';

import { Seperator } from 'components/Seperator';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import AddBeneficialOwnerModal from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/AddBeneficialOwnerModal';
import { BeneficialOwnerAccordion } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/BeneficialOwnerAccordion';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import DeleteBeneficialOwnerModal from './DeleteBeneficialOwnerModal';
import EditBeneficialOwnerModal from './EditBeneficialOwnerModal';
import { BeneficialOwner, PortalBeneficialOwner } from './fields';

type Props = {
  beneficialOwners: Array<PortalBeneficialOwner>;
};

export const BeneficialOwners = ({ beneficialOwners }: Props) => {
  const t = useTranslations();
  const { heading, noBeneficialOwners } =
    translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

  const {
    isOpen: isAddModalOpen,
    onClose: onAddModalClose,
    onOpen: onAddModalOpen,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure();

  const [selectedBeneficialOwner, setSelectedBeneficialOwner] = useState<
    BeneficialOwner | undefined
  >(undefined);

  return (
    <>
      <Seperator
        title={`${t(heading)} (${beneficialOwners.length})`}
        action={<EditModeActions onOpen={onAddModalOpen} />}
      >
        {beneficialOwners.length > 0 ? (
          beneficialOwners.map((beneficialOwner) => {
            return (
              <BeneficialOwnerAccordion
                key={beneficialOwner.id}
                beneficialOwner={beneficialOwner}
                setSelectedBeneficialOwner={setSelectedBeneficialOwner}
                onEditModalOpen={onEditModalOpen}
                onDeleteModalOpen={onDeleteModalOpen}
                index={beneficialOwners.indexOf(beneficialOwner)}
              />
            );
          })
        ) : (
          <Flex justifyContent={'center'} w={'full'} p={12} color="text.tertiary">
            {t(noBeneficialOwners)}
          </Flex>
        )}
      </Seperator>
      <AddBeneficialOwnerModal isOpen={isAddModalOpen} onClose={onAddModalClose} />
      <EditBeneficialOwnerModal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        initialValues={selectedBeneficialOwner}
      />
      <DeleteBeneficialOwnerModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        beneficialOwner={selectedBeneficialOwner}
      />
    </>
  );
};

type ActionsProps = {
  onOpen: () => void;
};

const Actions = ({ onOpen }: ActionsProps) => {
  const t = useTranslations();

  return (
    <ButtonComponent
      variant={'link'}
      onClick={(event) => {
        onOpen();
        event.preventDefault();
      }}
      leftIcon={<AddIcon boxSize={6} display={'block'} />}
      testId="addBeneficialOwnerButton"
    >
      {t(
        translations.pages.companiesDetails.associatedPerson.new.beneficialOwners.actions.add
          .caption,
      )}
    </ButtonComponent>
  );
};

const EditModeActions = withAssociatedPeopleEditMode(Actions);
