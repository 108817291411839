export enum INQUIRY_SECTIONS {
  FINANCING = 'financingPurposeFields',
  USER = 'userProfileFields',
  COMPANY = 'companyFields',
  DETAILS = 'inquiryDetailFields',
  END_USER = 'endUserFields',
  ADDITIONAL_FIELDS = 'additionalFields',
  CORONA_STEP = 'coronaStep',
  CONTRACT_DETAILS = 'contractDetails',
  CUSTOMER_DATA = 'customerData',
  LEGAL_REPRESENTATIVE = 'legalRepresentativeDetails',
  INQUIRY_TYPE_SPECIFIC_DETAILS = 'inquiryTypeSpecificDetails',
}

export enum DEFAULT_SECTIONS {
  FINANCING_PURPOSE_SECTION = 'financingPurposeSection',
  COMPANY_DETAILS_SECTION = 'companyDetailsSection',
  FINANCING_OFFER_SECTION = 'financingOfferSection',
  USER_PROFILE_SECTION = 'userProfileSection',
  INQUIRY_DETAILS_SECTION = 'inquiryDetailsSection',
}

export const INQUIRY_SECTION_KEYS = Object.values(INQUIRY_SECTIONS);
