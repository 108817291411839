import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

export const DataListLabel = styled.div<{
  fixedOption?: boolean;
}>`
  ${(props) => props.fixedOption && `font-style: italic`};

  div {
    margin-bottom: 0;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

export const StyledCaption = styled.div`
  margin-top: 0.8rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;
