import React from 'react';

import { Flex } from '@chakra-ui/react';

import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { StyledActions } from 'components/StyledActions';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import { TableComponent } from 'theme/components/Table';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { AddLegalRepresentativeForm } from './AddLegalRepresentativeForm';
import { EditLegalRepresentativeForm } from './EditLegalRepresentativeForm';
import { useLegalRepresentativesTable } from './useLegalRepresentativesTable';

export const LegalRepresentativesTable = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection');

  const {
    modals,
    activeRepresentative,
    representativesTable,
    modifiedRepresentativesTable,
    associatedPersons,
    hasErrors,
    isPending,
    handleEditFormSubmit,
    handleEditFormCancel,
    handleAddFormSubmit,
    handleAddFormCancel,
    handleLegalRepresentativesCancel,
    handleLegalRepresentativesSave,
  } = useLegalRepresentativesTable();

  return (
    <>
      <FormSectionLayout title={t('correctingRepresentation.title')} sectionNumber={2}>
        {t('correctingRepresentation.description')}
        <TableComponent
          colHeaders={[
            t('correctingRepresentation.representatives'),
            t('correctingRepresentation.actions'),
          ]}
          tbodyRows={representativesTable}
          hasActions
        />
        {associatedPersons.length > 0 && (
          <Flex justifyContent="flex-end">
            <ButtonComponent mt="2rem" onClick={modals.add.onOpen} variant="secondary">
              {t('actions.addNew')}
            </ButtonComponent>
            <ModalComponent
              isOpen={modals.add.isOpen}
              onClose={handleAddFormCancel}
              title={t('newLegalForm.headline')}
            >
              <AddLegalRepresentativeForm
                handleAddFormSubmit={handleAddFormSubmit}
                handleAddFormCancel={handleAddFormCancel}
                associatedPersons={associatedPersons}
              />
            </ModalComponent>
          </Flex>
        )}
        <ModalComponent isOpen={modals.edit.isOpen} onClose={handleEditFormCancel}>
          <EditLegalRepresentativeForm
            initialValues={activeRepresentative}
            handleEditFormSubmit={handleEditFormSubmit}
            handleEditFormCancel={handleEditFormCancel}
          />
        </ModalComponent>
      </FormSectionLayout>
      <StyledActions>
        <ButtonComponent mr={4} onClick={handleLegalRepresentativesCancel} variant="tertiary">
          {t('actions.cancel')}
        </ButtonComponent>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
          type="submit"
          disabled={hasErrors}
          onClick={modals.save.onOpen}
        >
          {t('actions.submit')}
        </ButtonComponent>
      </StyledActions>
      <ModalComponent
        isOpen={modals.save.isOpen}
        onClose={modals.save.onClose}
        title={t('submitModal.heading')}
      >
        {modifiedRepresentativesTable[0] && (
          <>
            <TextComponent mb="2rem" color="text.tertiary">
              {t('submitModal.description')}
            </TextComponent>
            <TableComponent
              colHeaders={[t('correctingRepresentation.representatives')]}
              tbodyRows={modifiedRepresentativesTable}
            />
          </>
        )}
        <TextComponent mb="2rem" mt="2rem" color="text.tertiary">
          {t('submitModal.subdescription')}
        </TextComponent>
        <StyledActions>
          <ButtonComponent mr={4} onClick={modals.save.onClose} variant="tertiary">
            {t('actions.cancel')}
          </ButtonComponent>
          <ButtonComponent
            type="submit"
            onClick={handleLegalRepresentativesSave}
            disabled={isPending}
          >
            {t('actions.submit')}
          </ButtonComponent>
        </StyledActions>
      </ModalComponent>
    </>
  );
};
