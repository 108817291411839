import {
  SET_MARKET_DATA,
  SET_OBJECT_IMAGES,
  SET_PLANNING_EVALUATION_PROFITABILITY,
} from './action';
import { MarketInformationFields } from '../../../../pages/customerPortal/MarketInformationPage/marketInformationFieldsMap';
import type { FurtherObjectEvaluationMappingProps } from '../pages/BankValues/FurtherObjectDetails';
import type { BankValuesTopBoxValues } from '../pages/BankValues/index';
import type { ObjectEvaluationMappingProps } from '../pages/BankValues/ObjectEvaluation';
import type { FinancingCriteriaTopBoxValues } from '../pages/FinancingCriteria';
import type { FinancialCriteriaDeveloper } from '../pages/FinancingCriteria/FinancingCriteriaPropertyDeveloper';
import type { FinancialCriteriaPartition } from '../pages/FinancingCriteria/FinancingCriteriaPropertyPartition';
import type { FinancialCriteriaStockholder } from '../pages/FinancingCriteria/FinancingCriteriaPropertyStockholder';
import { MarketComparisonTopBoxValues } from '../pages/MarketComparison';
import { MarketComparisonLocationAnalysisValues } from '../pages/MarketComparison/MarketComparisonLocationAnalysis';
import { MarketComparisonObjectEvaluationValues } from '../pages/MarketComparison/MarketComparisonObjectEvaluation';
import type { ProfitabilityTopBoxes } from '../pages/ProfitabilityCalculation';
import type { FinancingDetailsProps } from '../pages/ProfitabilityCalculation/FinancingDetails';
import type { InvestmentPlanProps } from '../pages/ProfitabilityCalculation/InvestmentPlan';
import type { LendingValueProps } from '../pages/ProfitabilityCalculation/LendingValue';
import type {
  ProfitabilitySalesBoxes,
  ProfitabilitySalesProps,
} from '../pages/ProfitabilityCalculation/ProfitabilitySales';
import type { PropertyDetailsProps } from '../pages/ProfitabilityCalculation/PropertyDetails';
import type { RentProps } from '../pages/ProfitabilityCalculation/Rent';
import type { RentBoxes } from '../pages/ProfitabilityCalculation/Rent/RentStockholder';
import type { RiskBoxes } from '../pages/ProfitabilityCalculation/Risk';
import type { SourceOfFundsProps } from '../pages/ProfitabilityCalculation/SourceOfFunds';
import { ObjectImage } from '../pages/PropertyProfile/UploadObjectImages';
import { mapObjectImageFromApi } from '../utils';

export interface IProfitabilityEvaluationState {
  mittweidaTableData: {
    bankValues: {
      objectData: {
        current: ObjectEvaluationMappingProps;
        future: ObjectEvaluationMappingProps;
      };
      furtherObjectDetails: {
        current: FurtherObjectEvaluationMappingProps;
        future: FurtherObjectEvaluationMappingProps;
      };
      lastUpdatedAt: string;
      topBoxes: BankValuesTopBoxValues;
    };
    financingCriteria: {
      financingCriteriaData: {
        developer: {
          current: FinancialCriteriaDeveloper;
          future: FinancialCriteriaDeveloper;
        };
        stockholder: {
          current: FinancialCriteriaStockholder;
          future: FinancialCriteriaStockholder;
        };
        partition: {
          current: FinancialCriteriaPartition;
          future: FinancialCriteriaPartition;
        };
      };
      topBoxes: FinancingCriteriaTopBoxValues;
      notes: string;
    };
    marketComparison: {
      topBoxes: MarketComparisonTopBoxValues;
      evaluation: MarketComparisonObjectEvaluationValues;
      location: MarketComparisonLocationAnalysisValues;
    };
    profitabilityCalculation: {
      boxes: {
        topBoxes: ProfitabilityTopBoxes;
        rentBoxes: RentBoxes;
        profitabilitySalesBoxes: ProfitabilitySalesBoxes;
        riskBoxes: RiskBoxes;
      };
      tables: {
        financingDetails: FinancingDetailsProps;
        propertyDetails: PropertyDetailsProps;
        sourceOfFunds: SourceOfFundsProps;
        investmentPlan: InvestmentPlanProps;
        profitabilitySales: ProfitabilitySalesProps;
        lendingValue: LendingValueProps;
        rent: RentProps;
      };
      notes: {
        notes_on_object: string;
        notes_on_customer: string;
        description_of_financing_project: string;
      };
      objectImages?: Array<ObjectImage>;
    };
  };
  propertyDetails: MarketInformationFields;
}

export const profitabilityEvaluationReducer = (
  state: IProfitabilityEvaluationState | null = null,
  action: any,
) => {
  switch (action.type) {
    case SET_PLANNING_EVALUATION_PROFITABILITY:
      return {
        ...state,
        mittweidaTableData: {
          ...state?.mittweidaTableData,
          bankValues: {
            objectData: action.payload.data.attributes.details.bank_values_object_data,
            furtherObjectDetails:
              action.payload.data.attributes.details.bank_values_additional_information,
            lastUpdatedAt: action.payload.data.attributes.details.bank_values_updated_at,
            topBoxes: action.payload.data.attributes.computed_details.bank_values_top_boxes,
          },
          financingCriteria: {
            financingCriteriaData: {
              developer: {
                current:
                  action.payload.data.attributes.computed_details.financing_criteria.current
                    .developer,
                future:
                  action.payload.data.attributes.computed_details.financing_criteria.future
                    .developer,
              },
              stockholder: {
                current:
                  action.payload.data.attributes.computed_details.financing_criteria.current
                    .investor,
                future:
                  action.payload.data.attributes.computed_details.financing_criteria.future
                    .investor,
              },
              partition: {
                current:
                  action.payload.data.attributes.computed_details.financing_criteria.current
                    .partitioner,
                future:
                  action.payload.data.attributes.computed_details.financing_criteria.future
                    .partitioner,
              },
            },
            topBoxes: {
              customerGrade:
                action.payload.data.attributes.details.financing_criteria.customer_grade,
              customerType: action.payload.data.attributes.details.financing_criteria.customer_type,
              locationGrade:
                action.payload.data.attributes.details.financing_criteria.location_grade,
              projectGrade: action.payload.data.attributes.details.financing_criteria.project_grade,
            },
            notes:
              action.payload.data.attributes.details.financing_criteria.notes_on_financing_criteria,
          },
          profitabilityCalculation: {
            boxes: {
              topBoxes: action.payload.data.attributes.computed_details.top_boxes,
              rentBoxes: action.payload.data.attributes.computed_details.rent_boxes,
              profitabilitySalesBoxes:
                action.payload.data.attributes.computed_details.profitability_boxes,
              riskBoxes: action.payload.data.attributes.computed_details.remaining_risk_boxes,
            },
            tables: {
              financingDetails: action.payload.data.attributes.computed_details.financing_details,
              propertyDetails: action.payload.data.attributes.computed_details.property_details,
              sourceOfFunds: action.payload.data.attributes.computed_details.source_of_funds,
              investmentPlan: action.payload.data.attributes.computed_details.investment_plan,
              profitabilitySales:
                action.payload.data.attributes.computed_details.profitability_sales,
              lendingValue: action.payload.data.attributes.computed_details.lending_value,
              rent: {
                ...action.payload.data.attributes.computed_details.rent,
                additional_information:
                  action.payload.data.attributes.computed_details.rent_additional_information,
              },
            },
            notes: action.payload.data.attributes.computed_details.additional_notes,
            objectImages: action.payload.included?.map(mapObjectImageFromApi),
          },
        },
        propertyDetails: {
          current: {
            commercial_space:
              action.payload.data.attributes.computed_details.property_details.current
                .commercial_space,
            residential_space:
              action.payload.data.attributes.computed_details.property_details.current
                .residential_space,
            total_parking:
              action.payload.data.attributes.computed_details.property_details.current
                .total_parking,
            total_space:
              action.payload.data.attributes.computed_details.property_details.current.total_space,
          },
          future: {
            commercial_space:
              action.payload.data.attributes.computed_details.property_details.future
                .commercial_space,
            residential_space:
              action.payload.data.attributes.computed_details.property_details.future
                .residential_space,
            total_parking:
              action.payload.data.attributes.computed_details.property_details.future.total_parking,
            total_space:
              action.payload.data.attributes.computed_details.property_details.future.total_space,
          },
        },
      };
    case SET_MARKET_DATA:
      return {
        ...state,
        mittweidaTableData: {
          ...state?.mittweidaTableData,
          marketComparison: {
            location: action.payload.data.attributes.computed_details.location,
            evaluation: action.payload.data.attributes.computed_details.evaluation,
            topBoxes: action.payload.data.attributes.computed_details.top_boxes,
          },
        },
      };
    case SET_OBJECT_IMAGES:
      return {
        ...state,
        mittweidaTableData: {
          ...state?.mittweidaTableData,
          profitabilityCalculation: {
            ...state?.mittweidaTableData?.profitabilityCalculation,
            objectImages: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
