import { getFieldValue, validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  EXISTING_USER_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_FOUNDING_MONTH,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getExcludingQuestionFields } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/ExcludingQuestions';
import {
  COMPANY_FIELDS,
  shouldShowFoundingMonth,
} from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { store } from 'store';
import { isSubsidiesConsideredSelector } from 'store/progress/selectors';

export const isStoreSavedValueEmpty = (fieldValue: string | any[]) => {
  const isEmptyArr = Array.isArray(fieldValue) && !fieldValue.length;
  const isNotBoolean = typeof fieldValue !== 'boolean';
  return isEmptyArr || (isNotBoolean && !fieldValue);
};

export const getStoreValueForField = (fieldName: string, company: { [x: string]: any }) => {
  const savedToStoreKeys: any = {
    [USER_PROFILE_LEGAL_FORM]: COMPANY_FIELDS.legalForm,
    [USER_PROFILE_TURNOVER_CLASS]: COMPANY_FIELDS.turnoverClass,
    [USER_PROFILE_FOUNDING_YEAR]: COMPANY_FIELDS.foundingYear,
    [USER_PROFILE_FOUNDING_MONTH]: COMPANY_FIELDS.foundingMonth,
    [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: COMPANY_FIELDS.alreadyCustomer,
    [USER_PROFILE_INDUSTRY]: COMPANY_FIELDS.industry,
  };
  const storeKey = savedToStoreKeys[fieldName];
  return company[storeKey];
};

export const shouldValidateField = (fieldName: string, company: { [x: string]: any }) => {
  const valueSavedToStore = getStoreValueForField(fieldName, company);
  return isStoreSavedValueEmpty(valueSavedToStore);
};

export const getNotFilledFieldsToValidate = (company: { [x: string]: any }) => {
  const fields = [
    USER_PROFILE_LEGAL_FORM,
    USER_PROFILE_FOUNDING_YEAR,
    USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
    USER_PROFILE_INDUSTRY,
  ];

  return fields.filter((fieldName) => shouldValidateField(fieldName, company));
};

const FIELDS_FOR_NO_COMPANY_SELECTED = [
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
];

export const shouldValidateFoundingMonth = shouldShowFoundingMonth;

export const getValidatorCompanyDetailsSection =
  (
    company: { [x: string]: any },
    inquiryType: string = '',
    excludingQuestionsCount: number = 0,
    excludedFields: string | any[] = [],
  ) =>
  (form: any) => {
    let fields = company
      ? getNotFilledFieldsToValidate(company)
      : [...FIELDS_FOR_NO_COMPANY_SELECTED];

    const foundingYear =
      (company && company[USER_PROFILE_FOUNDING_YEAR]) ||
      getFieldValue(USER_PROFILE_FOUNDING_YEAR, form);

    if (shouldValidateFoundingMonth(foundingYear)) {
      fields.push(USER_PROFILE_FOUNDING_MONTH);

      if (company) {
        fields = fields.filter((fieldName) => shouldValidateField(fieldName, company));
      }
    }
    const isAlreadyCustomer = !!getFieldValue(USER_PROFILE_COMPANY_ALREADY_CUSTOMER, form);
    if (
      isAlreadyCustomer === true ||
      (company && company[EXISTING_USER_COMPANY_ALREADY_CUSTOMER])
    ) {
      return true;
    }

    if (!fields.length) {
      return true;
    }

    if (isSubsidiesConsideredSelector(store)) {
      fields.push(
        USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
        USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
        USER_PROFILE_INNOVATIVE_COMPANY,
        USER_PROFILE_ENERGY_EFFICIENT,
      );
    }

    const inquiryTypeValidationMap: any = {
      [InquiryType.onlinefactoring]: [
        USER_PROFILE_TURNOVER_CLASS,
        ...getExcludingQuestionFields(excludingQuestionsCount),
      ],
      [InquiryType.bfs]: [USER_PROFILE_COMPANY_ALREADY_CUSTOMER],
      default: [USER_PROFILE_TURNOVER_CLASS],
    };

    const inquiryTypeValidations =
      inquiryTypeValidationMap[inquiryType] || inquiryTypeValidationMap.default;

    const filteredFields = excludedFields
      ? [...fields, ...inquiryTypeValidations].filter((field) => !excludedFields.includes(field))
      : [...fields, ...inquiryTypeValidations];

    return validateSectionFields(filteredFields)(form);
  };
