import { PortalBeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';

export const transformBeneficialOwners = (beneficialOwners: any): Array<PortalBeneficialOwner> => {
  return beneficialOwners.map((beneficialOwner: any) => {
    const attributes = beneficialOwner.attributes;

    return {
      id: beneficialOwner.id,
      lastName: attributes['last_name'],
      firstName: attributes['first_name'],
      birthDate: attributes['birth_date'],
      birthPlace: attributes['birth_place'],
      capitalOrVotingRightsPercentage: attributes['company_share'],
      countryOfResidence: attributes['country'],
      nationality: attributes['additional_info']?.nationality,
      placeOfResidence: attributes.city,
      source: attributes['source'],
      fictional: attributes['fictional'],
      scope: attributes['scope'],
      potentiallyManipulated: attributes['potentially_manipulated'],
    } satisfies PortalBeneficialOwner;
  });
};
