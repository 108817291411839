import { Store } from 'types/Store';
import { Observable } from 'utils/Observable';
import { SessionStorageStore } from 'utils/sessionStorage/SessionStorageStore';

import { IPartnerRepo, PartnerConfig, PartnerConfigSaveOptions } from './types';

const initialConfig: PartnerConfig = {
  details: {},
  meta: {},
  copy: {},
};

export class PartnerRepo extends Observable<PartnerConfig> implements IPartnerRepo {
  private _config: Store<PartnerConfig> = new SessionStorageStore<PartnerConfig>(
    'partnerStore',
    initialConfig,
  );

  public save(config: PartnerConfig, { force = false }: PartnerConfigSaveOptions = {}): void {
    if (force) {
      this._config.saveBulk(config, true);
    } else {
      this._config.save(config);
    }
    this.emit(this._config.getAllValues());
  }

  public getConfig(): PartnerConfig {
    return this._config.getAllValues();
  }

  public clear(): void {
    this._config.saveBulk(initialConfig, true);
    this.emit(this._config.getAllValues());
  }
}
