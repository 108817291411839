import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import Condition from 'components/Condition';
import PortalPage from 'components/PortalPage';
import ElementsPerPage from 'components/PortalTable/ElementsPerPage/ElementsPerPage';
import Pagination from 'components/PortalTable/Pagination/Pagination';
import PortalTable from 'components/PortalTable/PortalTable';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import { CompaniesTable } from 'pages/operationPortal/CompaniesList/CompaniesTable.model';
import { getSortDirection } from 'pages/operationPortal/CompaniesList/helpers/companies.service';
import {
  useCompaniesListTableHeaders,
  COMPANIES_TABLE_HEADERS,
} from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';
import { useFetchCompaniesList } from 'pages/operationPortal/CompaniesList/helpers/useFetchCompaniesList';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getCompaniesListSelector, getCompaniesListOptionsSelector } from './store/selectors';

const CompaniesList: React.FC = () => {
  const tableHeaders = useCompaniesListTableHeaders();
  const companies = useSelector(getCompaniesListSelector);
  const options = useSelector(getCompaniesListOptionsSelector);
  const { isLoading, fetchCompanies } = useFetchCompaniesList();

  useEffect(() => {
    // initial load
    fetchCompanies(options);
  }, [fetchCompanies]); // eslint-disable-line react-hooks/exhaustive-deps

  const isInitialized = Boolean(companies.length && !isLoading);

  const onSortChange = async (sortBy: string) =>
    fetchCompanies({
      ...options,
      sortBy: sortBy as COMPANIES_TABLE_HEADERS,
      sortDirection: getSortDirection(options, sortBy as COMPANIES_TABLE_HEADERS),
    });

  const onPageChange = async (newPage: number) =>
    fetchCompanies({
      ...options,
      page: newPage,
    });

  const onPageSizeChange = async (newPageSize: number) =>
    fetchCompanies({
      ...options,
      pageSize: newPageSize,
      page: 1,
    });

  const history = useHistory();
  const navigateToDetails = (company: CompaniesTable) =>
    history.push(paths.operation.companies.details(company.id));

  const t = useTranslations();

  return (
    <PortalPage pageTitle={t('pages.companiesList.title')} biggerHeading>
      <PortalTable
        headers={tableHeaders}
        onRowClick={navigateToDetails}
        tableData={companies}
        isLoading={isLoading}
        isInitialized={isInitialized}
        sortBy={options.sortBy}
        sortDirection={options.sortDirection}
        onSortChange={onSortChange}
      >
        <ElementsPerPage pageSize={options.pageSize} onPageSizeChange={onPageSizeChange} />
        <Pagination
          totalPages={options.totalPages}
          currentPage={options.page}
          onPaginationChange={onPageChange}
        />
      </PortalTable>
    </PortalPage>
  );
};

const withCompanies = !!getEnvironmentFeatureFlag(EnvironmentVariable.COMPANIES_LIST);

const DisplayCondition = () => {
  return (
    <Condition condition={withCompanies} fallback={<Redirect to={paths.operation.inquiryList} />}>
      <CompaniesList />
    </Condition>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(DisplayCondition);
