import React, { createContext, useContext, useState, useCallback } from 'react';

import { IInquiryListOptions } from 'models/InquiryList.model';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';
interface IInquiriesListContextType {
  tableOptions: IInquiryListOptions;
  setTableOptions: (values: Record<string, any>) => void;
  resetTableOptions: () => void;
}

const initialValues = {
  currentPage: 1,
  pageCount: 1,
  pageSize: 5,
  sortDirection: SORT_DIRECTION.DESC,
  sortBy: INQUIRY_TABLE_HEADERS.CREATED_AT,
  touched: false,
  filterBy: {
    status: 'all',
    amountFrom: undefined,
    amountTo: undefined,
    companyName: undefined,
    createdAtFrom: undefined,
    createdAtTo: undefined,
    inquiryType: undefined,
    purposeKind: undefined,
    productKind: undefined,
    id: undefined,
    inquiryManagerId: undefined,
    customerVBNumber: undefined,
    zipCodeArea: undefined,
    vppId: undefined,
  },
};

const InquiriesListContext = createContext<IInquiriesListContextType>({
  tableOptions: initialValues,
  setTableOptions: () => {},
  resetTableOptions: () => {},
});

export const useInquiriesListContext = () => {
  const context = useContext(InquiriesListContext);

  if (!context) {
    throw new Error('useInquiriesListContext must be used within a InquriesListProvider');
  }

  return context;
};

export const InquriesListProvider = ({ children }: { children: React.ReactNode }) => {
  const [tableOptions, setTableOptions] = useState(initialValues);
  const updateTableOptions = useCallback(
    (updatedValues: Partial<typeof tableOptions>) => {
      setTableOptions((prevState) => ({
        ...prevState,
        ...updatedValues,
      }));
    },
    [setTableOptions],
  );

  const resetTableOptions = useCallback(() => {
    setTableOptions({
      ...initialValues,
      touched: true,
      filterBy: { ...initialValues.filterBy, status: '' },
    });
  }, [setTableOptions]);

  return (
    <InquiriesListContext.Provider
      value={{ tableOptions, setTableOptions: updateTableOptions, resetTableOptions }}
    >
      {children}
    </InquiriesListContext.Provider>
  );
};
