import React from 'react';

import { useSelector } from 'react-redux';

import ModalSectionHeader from 'components/Text/ModalSectionHeader';
import { IBankOffer } from 'models/BankOffer.model';
import { ICompeonOffer } from 'models/CompeonOffer.model';
import FormGenerator from 'modules/FormGenerator';
import { StyledDescription as Description } from 'modules/Offers/InquiryOffersSection/BankAdvisorOffers/OfferBox/styles';
import { uploadFiles } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import { getCompeonInquiryId } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

interface OfferProps {
  offer: ICompeonOffer | IBankOffer;
  bankName: string;
  modalTitle: string;
  confirmText: string;
  isOpen: boolean;
  onClose: () => void;
  handleShowInterest: () => void;
  testId: string;
}

interface Response {
  name: string;
  value: string;
  hasError: boolean;
}

export const OfferWithoutQuestionnaire = ({
  offer,
  bankName,
  modalTitle,
  confirmText,
  isOpen,
  onClose,
  handleShowInterest,
  testId,
}: OfferProps) => {
  const t = useTranslations('pages.inquiryDetails.offers');
  const compeonInquiryId = useSelector(getCompeonInquiryId);
  const { makeCall } = useDispatchApiCall();

  const template =
    offer.type === 'CompeonOffer' && offer.filesRequiredByBank
      ? offer.filesRequiredByBank.map((item, index) => ({
          id: item.id,
          kind: 'field',
          name: item.id,
          label: item.name,
          position: index,
          type: 'documents',
        }))
      : [];

  const handleUploadedFiles = async (response: Response) => {
    const document =
      offer.type === 'CompeonOffer'
        ? offer.filesRequiredByBank.find((file) => file.id === response.name)
        : { name: '' };
    const payload = {
      data: {
        type: 'attachments',
        attributes: {
          'virtual-path': document?.name || '',
        },
        relationships: {
          document: {
            data: {
              id: response.value,
              type: 'documents',
            },
          },
        },
      },
    };

    await makeCall(uploadFiles(compeonInquiryId, payload));
  };

  const shouldDisableButton =
    offer.type === 'CompeonOffer' && typeof offer.filesRequiredByBank !== 'undefined'
      ? !!offer.filesRequiredByBank.length &&
        offer.filesRequiredByBank.some((item) => !item.uploaded)
      : false;

  return (offer.type === 'CompeonOffer' && typeof offer.filesRequiredByBank === 'undefined') ||
    (offer.type === 'CompeonOffer' && !offer.filesRequiredByBank.length) ? (
    <AlertDialogComponent
      cancelText={t('cancel')}
      confirmText={confirmText}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleShowInterest}
      title={modalTitle}
    >
      <Description>{t('acceptOfferDesc', { bankName })}</Description>
    </AlertDialogComponent>
  ) : (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      footer={
        <>
          <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
            {t('cancel')}
          </ButtonComponent>
          <ButtonComponent
            form="form-callback"
            type="submit"
            disabled={shouldDisableButton}
            onClick={handleShowInterest}
            data-testid={testId}
          >
            {t('acceptOffer')}
          </ButtonComponent>
        </>
      }
    >
      <ModalSectionHeader>{t('fastlane.documents')}</ModalSectionHeader>
      <FormGenerator fields={template} onFormChange={handleUploadedFiles} />
    </ModalComponent>
  );
};
