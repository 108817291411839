import { API_METHODS, callReverseApi } from '../../api/apiService';
import endpoints from '../../api/CompeonReverseApi/endpoints';
import { AppActionThunk } from '../../models/types/AppThunk.type';

export const SET_KPIS = 'SET_KPIS';
export const setKpis =
  (kpis: any): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_KPIS,
      payload: kpis,
    });

export const getFullKpis = (companyId: string, inquiryId: string): AppActionThunk => {
  return callReverseApi({
    url: endpoints.COMPANIES.KPIS.compose({
      params: { id: companyId },
      query: { data: { attributes: { inquiry_id: inquiryId } } },
    }),
    method: API_METHODS.GET,
  });
};
