import React from 'react';

import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import PortalTable from '../../../components/PortalTable';
import usePortalTable from '../../../hooks/usePortalTable';
import type { MappingProps } from '../../../types';
import { getRowStylesMap, useUnitFormatter } from '../../../utils';

import type { LendingValueItem } from '.';

const PRESALES_RATE_THRESHOLD = 0.5;

const partitionMapping: MappingProps<LendingValueItem> = [
  { key: 'base_value', unit: 'currency', styles: { bold: true } },
  { key: 'cost_transfer', unit: 'currency', styles: { bold: true } },
  { key: 'adjusted_base_value', unit: 'currency', styles: { bold: true } },
  { key: 'risk_deduction', unit: 'percent', styles: { italic: true } },
  { key: 'lending_value', unit: 'currency', styles: { bold: true } },
  { key: 'lending_limit_percent', unit: 'percent', styles: { italic: true } },
  { key: 'lending_limit_euro', unit: 'currency', styles: { bold: true } },
  { key: 'unsecured_portion', unit: 'currency', styles: { bold: true } },
  { key: 'loan_value_ratio', unit: 'percent', styles: { bold: true } },
];

export const LendingValuePartition = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.lendingValue.partition',
  );
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      lendingValue: response.data.attributes.computed_details.lending_value,
      bankValues: response.data.attributes.details.bank_values_additional_information,
    }),
  });
  const formatter = useUnitFormatter();
  const { usageType } = useSelector(getMarketDataFilterLegacy);
  const isPresalesRateBelowThreshold =
    Number(data?.bankValues[usageType].presales_rate) <= PRESALES_RATE_THRESHOLD;
  const lendingValuePartitionTable = usePortalTable({
    data: partitionMapping.map(({ key, unit }) => ({
      id: key,
      position: t(`rows.${key}`),
      buyingPhase: formatter[unit](
        isPresalesRateBelowThreshold
          ? data?.lendingValue.buying_phase[key]
          : data?.lendingValue[usageType].whole_project[key],
      ),
      indication: formatter[unit](data?.lendingValue[usageType].indication[key]),
    })),
    headers: [
      { key: 'position', text: t('headers.position') },
      { key: 'buyingPhase', text: t('headers.buyingPhase') },
      { key: 'indication', text: t('headers.indication') },
    ],
  });
  const rowStyles = getRowStylesMap(partitionMapping);
  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return <PortalTable {...lendingValuePartitionTable} rowStylesMap={{ ...rowStyles }} />;
};
