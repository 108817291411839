import {
  PROJECT_FINANCING_FURNISHING_QUALITY__SIMPLE,
  PROJECT_FINANCING_FURNISHING_QUALITY__NORMAL,
  PROJECT_FINANCING_FURNISHING_QUALITY__GOOD,
  PROJECT_FINANCING_FURNISHING_QUALITY__VERY_GOOD,
  PROJECT_FINANCING_FURNISHING_QUALITY__HIGH_QUALITY,
} from 'modules/Inquiry/Form/formFields';

const getProjectFinancingFurnishingQualityOptions = (formatMessage) =>
  [
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.simple.caption',
      value: PROJECT_FINANCING_FURNISHING_QUALITY__SIMPLE,
    },
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.normal.caption',
      value: PROJECT_FINANCING_FURNISHING_QUALITY__NORMAL,
    },
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.good.caption',
      value: PROJECT_FINANCING_FURNISHING_QUALITY__GOOD,
    },
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.veryGood.caption',
      value: PROJECT_FINANCING_FURNISHING_QUALITY__VERY_GOOD,
    },
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.highQuality.caption',
      value: PROJECT_FINANCING_FURNISHING_QUALITY__HIGH_QUALITY,
    },
  ].map(({ value, id }) => ({ value, label: formatMessage({ id }) }));

export default getProjectFinancingFurnishingQualityOptions;
