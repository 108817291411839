import { Route } from 'utils/paths';

const endpoints = {
  COMPANY_SUGGESTIONS: new Route('/company_suggestions'),
  BANKS: new Route('/bank_codes'),
  DOCUMENTS: new Route('/documents'),
  QUESTIONNAIRES: {
    DETAILS: new Route('/questionnaires/:id'),
    ITEMS: {
      LIST: new Route('/questionnaires/:questionnaireId/items'),
      DETAILS: new Route('/questionnaires/:questionnaireId/items/:itemId'),
    },
  },
  INQUIRY: {
    ATTACHMENTS: new Route('inquiries/:id/attachments'),
    STATE: new Route('inquiries/:id/state'),
  },
} as const;

export default endpoints;
