import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import OtherProductDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Other/OtherProductDescription';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import OtherIcon from 'theme/components/Icon/ProductKind/OtherIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const Other = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.otherProduct}
      sectionNumber={1}
      title={t('pages.requestDetails.sections.other.title')}
    >
      <FormRow separator>
        <FinancingAmountSummary icon={<OtherIcon />} />
      </FormRow>
      <FormRow>
        <OtherProductDescription />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default Other;
