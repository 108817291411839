import React from 'react';

import { useLoadInquiryRevision } from 'api/CompeonReverseApi/operation/queryHooks';
import {
  mapRevisionFromApi,
  isCanGoNextDisabled,
  isCanGoPreviousDisabled,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.service';
import {
  getNextRevisionIdSelector,
  getPreviousRevisionIdSelector,
  RevisionState,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.state';
import InquiryRevisionUser from 'modules/InquiryDetails/InquiryRevision/InquiryRevisionUser';
import RevisionSingleModification from 'modules/InquiryDetails/InquiryRevision/RevisionSingleModification';
import {
  StyledRevisionInfo as Info,
  StyledRevisionActions as Actions,
  StyledChangeWrapper,
} from 'modules/InquiryDetails/InquiryRevision/styles';
import { ButtonComponent } from 'theme/components/Button';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useRevisionStateContext } from './RevisionProvider';

const RevisionChange: React.FC = () => {
  const t = useTranslations('pages.inquiryDetails.changelogs');
  const { revisionState, setRevisionState } = useRevisionStateContext();
  const { selectedRevisionId, revisionList } = revisionState;
  const { data } = useLoadInquiryRevision({
    variables: { revisionId: selectedRevisionId },
    enabled: !!selectedRevisionId,
    select: (response) => ({
      revision: mapRevisionFromApi(response),
    }),
  });

  if (!data) {
    return null;
  }

  const selectNextRevision = () =>
    setRevisionState({
      selectedRevisionId: getNextRevisionIdSelector(revisionState as RevisionState),
    });
  const selectPreviousRevision = () =>
    setRevisionState({
      selectedRevisionId: getPreviousRevisionIdSelector(revisionState as RevisionState),
    });

  return (
    <>
      <Info>
        <dt>
          <UserIcon boxSize={8} mr={2} />
          {t('changesBy')}:
        </dt>
        <dd>
          {data?.revision.owner ? (
            <InquiryRevisionUser user={data.revision.owner} />
          ) : (
            t(`initiator.${data?.revision.initiator}`)
          )}
        </dd>
      </Info>

      <Info>
        <dt>
          <TimeIcon boxSize={8} mr={2} />
          {t('changeDate')}:
        </dt>
        <dd>{formatDateWithTime(data?.revision.createdAt)}</dd>
      </Info>

      <Actions>
        <ButtonComponent
          data-testid="previous-change-btn"
          disabled={isCanGoPreviousDisabled(revisionList, selectedRevisionId)}
          leftIcon={<ArrowLeftIcon boxSize={6} display="block" />}
          onClick={selectPreviousRevision}
          variant="secondary"
        >
          {t('previousChange')}
        </ButtonComponent>
        <ButtonComponent
          data-testid="next-change-btn"
          disabled={isCanGoNextDisabled(revisionList, selectedRevisionId)}
          onClick={selectNextRevision}
          rightIcon={<ArrowRightIcon boxSize={6} display="block" />}
          variant="secondary"
        >
          {t('nextChange')}
        </ButtonComponent>
      </Actions>

      <StyledChangeWrapper>
        {data?.revision.modification.map((modification) => (
          <div data-testid="revision-modification" key={modification.attribute}>
            <RevisionSingleModification key={modification.attribute} modification={modification} />
          </div>
        ))}
      </StyledChangeWrapper>
    </>
  );
};

export default RevisionChange;
