import React from 'react';

import { REQUEST_DETAILS } from 'modules/Inquiry/Form/formFields';
import {
  EXPERT_ADVICE_ON_ALL,
  FUNDRAISING,
  INVESTMENT_AND_SECURITIES,
  OTHER_FINANCING_SOLUTIONS,
  PAYMENT_TRANSACTIONS,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__LEASING,
  PUBLIC_FUNDING,
} from 'modules/Inquiry/Form/formFields';
import FurtherNeed from 'modules/Inquiry/Form/Steps/RequestDetails/FurtherNeed/FurtherNeed';
import ProductKind from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKind';
import ProductKindDetailsSection from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/ProductKindDetailsSection';
import { useRequestDetailsSectionValidators } from 'modules/Inquiry/Form/Validations/default/useRequestDetailsSectionValidators';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import Heading from 'pages/inquiryFlow/RequestDetails/Heading/Heading';
import SectionWrapper from 'pages/inquiryFlow/RequestDetails/SectionWrapper';

const BfsRequestDetails = () => {
  const initialValues = {
    [EXPERT_ADVICE_ON_ALL]: false,
    [FUNDRAISING]: false,
    [INVESTMENT_AND_SECURITIES]: false,
    [OTHER_FINANCING_SOLUTIONS]: false,
    [PAYMENT_TRANSACTIONS]: false,
    [PRODUCT_KIND__FACTORING]: false,
    [PRODUCT_KIND__LEASING]: false,
    [PUBLIC_FUNDING]: false,
  };

  const getSectionValidators = useRequestDetailsSectionValidators();

  return (
    <InquiryFormNew
      headerComp={<Heading />}
      currentStepName={REQUEST_DETAILS}
      getSectionValidators={getSectionValidators}
      initialValues={initialValues}
      validateOnNext={true}
    >
      {() => (
        <>
          <SectionWrapper>
            <ProductKind />
          </SectionWrapper>

          <ProductKindDetailsSection />
          <FurtherNeed sectionNumber={3} />
        </>
      )}
    </InquiryFormNew>
  );
};

export default BfsRequestDetails;
