import styled from 'styled-components/macro';

import { transition } from 'theme/themeConstants';

export const StyledFieldHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const StyledCaptionWrapper = styled.div`
  display: flex;
`;

export const StyledFieldError = styled.div.attrs(({ hasError }: { hasError: boolean }) => ({
  'data-testid': 'error-wrapper',
  className: hasError && 'hasError',
}))<{ hasError: boolean }>`
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity ${transition.default}, transform ${transition.default};

  &.hasError {
    opacity: 1;
    transform: translateY(0%);
  }
`;
