import React from 'react';

import { Box, chakra } from '@chakra-ui/react';
import type { ColumnDef, Row, SortDirection } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Transition } from 'react-transition-group';

import Overlay from 'components/Overlay';
import LoadingRows from 'components/PortalTable/LoadingRows';
import Spinner from 'components/Spinner';

import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import type { TableStyleProps } from '../types';

const TABLE_ROW_SHOW_DELAY = 30;

export interface PortalTableProps<T extends unknown> {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  children?: React.ReactNode;
  onRowClick?: (row: Row<T>) => void;
  onSortChange?: (...args: Array<any>) => void;
  isLoading?: boolean;
  isInitialized?: boolean;
  sortOptions?: {
    sortBy: keyof T;
    sortDirection: SortDirection;
    showSortIcon?: boolean;
  };
  columnStylesMap?: Partial<Record<keyof T, TableStyleProps>>;
  rowStylesMap?: Partial<Record<keyof TableStyleProps, Array<number>>>;
  headerStylesMap?: Partial<Record<keyof T, TableStyleProps>>;
}

export const PortalTable = <T extends unknown>({
  columns,
  data,
  isInitialized = true,
  isLoading,
  children,
  sortOptions,
  columnStylesMap = {},
  rowStylesMap = {},
  headerStylesMap = {},
  onRowClick = () => {},
  onSortChange = () => {},
}: PortalTableProps<T>) => {
  const getCellStyles = React.useCallback(
    (id: string): TableStyleProps => {
      const [row, col] = id.split(/_/, 2);
      return {
        bold: columnStylesMap?.[col as keyof T]?.bold || rowStylesMap?.bold?.includes(Number(row)),
        color: columnStylesMap?.[col as keyof T]?.color,
        italic:
          columnStylesMap?.[col as keyof T]?.italic || rowStylesMap?.italic?.includes(Number(row)),
        highlighted:
          columnStylesMap?.[col as keyof T]?.highlighted ||
          rowStylesMap?.highlighted?.includes(Number(row)),
        indented:
          columnStylesMap?.[col as keyof T]?.indented ||
          rowStylesMap?.indented?.includes(Number(row)),
        rightAligned:
          columnStylesMap?.[col as keyof T]?.rightAligned ||
          rowStylesMap?.rightAligned?.includes(Number(row)),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnStylesMap, rowStylesMap],
  );

  const [sorting, setSorting] = React.useState<SortingState>(
    sortOptions
      ? [{ id: sortOptions.sortBy as string, desc: sortOptions.sortDirection === 'desc' }]
      : [],
  );

  const table = useReactTable<T>({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return (
    <Box position="relative" flexGrow="1">
      <Overlay show={isLoading && isInitialized} overlayComponent={<Spinner />}>
        <Box overflow="auto">
          <chakra.table width="100%" sx={{ borderCollapse: 'collapse' }}>
            <chakra.thead>
              <chakra.tr>
                {table.getLeafHeaders().map(({ id, column, getContext }) => {
                  return (
                    <TableHeader
                      key={id}
                      onSortChange={sortOptions && column.getToggleSortingHandler()}
                      showSortingArrows={sortOptions?.showSortIcon}
                      isSorted={column.getIsSorted()}
                      color={headerStylesMap?.[id as keyof T]?.color}
                      width={headerStylesMap?.[id as keyof T]?.width}
                    >
                      {flexRender(column.columnDef.header, getContext())}
                    </TableHeader>
                  );
                })}
              </chakra.tr>
            </chakra.thead>

            {!isInitialized && <LoadingRows numOfColumns={data.length} />}
            <chakra.tbody>
              {table.getRowModel().rows.map((row, index) => (
                <Transition
                  in
                  timeout={index * TABLE_ROW_SHOW_DELAY}
                  appear
                  mountOnEnter
                  unmountOnExit
                  key={row.id}
                >
                  {(transitionState) => (
                    <TableRow
                      key={row.id}
                      isVisible={transitionState === 'entered'}
                      onRowClick={() => onRowClick(row)}
                    >
                      {row.getVisibleCells().map(({ id, column, getContext }) => {
                        return (
                          <TableCell key={id} {...getCellStyles(id)}>
                            {flexRender(column.columnDef.cell, getContext())}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )}
                </Transition>
              ))}
            </chakra.tbody>
          </chakra.table>
        </Box>

        <TableFooter>{children}</TableFooter>
      </Overlay>
    </Box>
  );
};

export default PortalTable;
