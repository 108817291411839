import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { ErrorText } from 'components/Text';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { PRODUCT_KIND } from 'modules/Inquiry/Form/formFields';
import ProductKindList from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKindList/ProductKindList';
import { useProductKindOptions } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/useProductKindOptions';
import { FinalFormInputPropTypes, FinalFormMetaPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

export const validateFinancingProductSection = (form) => {
  const fieldState = form.getFieldState(PRODUCT_KIND);

  if (fieldState && fieldState.data.onlyOneOption) {
    return undefined;
  }
  return () => fieldIsValid(PRODUCT_KIND)({ form });
};

const ProductKindAdapter = ({ input, meta, products = [], onSelect }) => {
  const handleProductSelect = (productId) => {
    input.onChange(productId);
    onSelect(productId);
  };

  return (
    <>
      <ProductKindList
        name={input.name}
        products={products}
        onProductSelected={handleProductSelect}
        selectedProductId={input.value}
      />
      {meta.touched && <ErrorText>{meta.error}</ErrorText>}
    </>
  );
};

ProductKindAdapter.propTypes = {
  input: FinalFormInputPropTypes.isRequired,
  meta: FinalFormMetaPropTypes.isRequired,
  onSelect: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ProductKindAdapterWithField = withFormField(ProductKindAdapter);

const ProductKind = ({ onSelect }) => {
  const products = useProductKindOptions();

  if (products.length === 1) {
    return <Field name={PRODUCT_KIND} initialValue={products[0].id} render={() => {}} />;
  }

  const handleChange = (newProduct) => {
    onSelect(newProduct);
  };

  return (
    <ProductKindAdapterWithField name={PRODUCT_KIND} products={products} onSelect={handleChange} />
  );
};

ProductKind.defaultProps = {
  onSelect: () => null,
};

ProductKind.propTypes = {
  onSelect: PropTypes.func,
};

export default React.memo(ProductKind);
