import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const LoginIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M17 19.5h2a1.07 1.07 0 001-1.12V4.61a1.06 1.06 0 00-1-1.11h-2a1 1 0 010-2h2a3.06 3.06 0 013 3.11v13.77a3.06 3.06 0 01-3 3.12h-2.5a1 1 0 010-2" />
      <path d="M16.94 11.88a1 1 0 000-.76 1.19 1.19 0 00-.22-.33l-4.5-4.5a1 1 0 00-1.41 1.42l2.79 2.79H3a1 1 0 000 2h10.6l-2.78 2.78a1 1 0 00.7 1.71 1 1 0 00.71-.29l4.49-4.49a1 1 0 00.22-.33" />
    </Icon>
  );
};

export default LoginIcon;
