import {
  MACHINE_CATEGORY__METAL,
  MACHINE_CATEGORY__WOOD,
  MACHINE_CATEGORY__PLASTIC,
  MACHINE_CATEGORY__TOOL,
  MACHINE_CATEGORY__PRINT,
  MACHINE_CATEGORY__CONSTRUCTION,
  MACHINE_CATEGORY__ENERGY,
  MACHINE_CATEGORY__RENEWABLE_ENERGY,
  MACHINE_CATEGORY__FOOD,
  MACHINE_CATEGORY__LOGISTIC,
  MACHINE_CATEGORY__PACKAGING,
  MACHINE_CATEGORY__RECYCLING,
  MACHINE_CATEGORY__AGRICULTURE,
  MACHINE_CATEGORY__HARD_SOFTWARE,
  MACHINE_CATEGORY__TELECOM,
  MACHINE_CATEGORY__OTHER,
  MACHINE_CATEGORY__HEALTH,
} from 'modules/Inquiry/Form/formFields';

export default [
  {
    value: MACHINE_CATEGORY__METAL,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.metal',
  },
  {
    value: MACHINE_CATEGORY__WOOD,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.wood',
  },
  {
    value: MACHINE_CATEGORY__PLASTIC,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.plastic',
  },
  {
    value: MACHINE_CATEGORY__TOOL,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.tool',
  },
  {
    value: MACHINE_CATEGORY__PRINT,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.print',
  },
  {
    value: MACHINE_CATEGORY__CONSTRUCTION,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.construction',
  },
  {
    value: MACHINE_CATEGORY__HEALTH,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.health',
  },
  {
    value: MACHINE_CATEGORY__ENERGY,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.energy',
  },
  {
    value: MACHINE_CATEGORY__RENEWABLE_ENERGY,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.renewableEnergy',
  },
  {
    value: MACHINE_CATEGORY__FOOD,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.food',
  },
  {
    value: MACHINE_CATEGORY__LOGISTIC,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.logistic',
  },
  {
    value: MACHINE_CATEGORY__PACKAGING,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.packaging',
  },
  {
    value: MACHINE_CATEGORY__RECYCLING,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.recycling',
  },
  {
    value: MACHINE_CATEGORY__AGRICULTURE,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.agriculture',
  },
  {
    value: MACHINE_CATEGORY__HARD_SOFTWARE,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.hardAndSoftware',
  },
  {
    value: MACHINE_CATEGORY__TELECOM,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.telecommunication',
  },
  {
    value: MACHINE_CATEGORY__OTHER,
    id: 'pages.financingNeed.sections.machine.fields.machineCategory.options.other',
  },
];
