import { StepSequence } from 'new/form/model';

import { HausbankFieldTypes } from '../fields';
import { hausbankSteps } from '../steps';

export const hausbankStepSequence: StepSequence<HausbankFieldTypes> = [
  hausbankSteps.objectInformationPage,
  hausbankSteps.companyDataPage,
  hausbankSteps.financingNeedPage,
  hausbankSteps.personalDataPage,
];
