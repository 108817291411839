import _set from 'lodash/set';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { mapInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapInquiryToApi';

export const saveLeadAction = (formData: any) => {
  const rawInquiry = mapInquiryToApi(formData);
  const payload = _set(rawInquiry, ['data', 'type'], 'leads');

  return callReverseApi({
    url: endpoints.LEADS.compose(),
    method: API_METHODS.POST,
    data: payload,
  });
};
