import { mmvFinancingObjectValidationMap } from 'mmv/inquiry/steps/contractDetails/sections/financingObject/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const financingObjectValidation: ValidateSectionFunction<InquiryType.mmv> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: mmvFinancingObjectValidationMap,
    form,
    values,
    conditions,
  });
};
