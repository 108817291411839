import { Dispatch } from 'redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  SET_MULTIPLIER_ORGANIZATION_ID,
  SET_REFERRAL_WIDGET_CODE,
  SET_LEAD_ID,
  SET_INQUIRY_TYPE,
  CLEAR_LEAD_ID,
  SET_CAN_SEND_LEAD,
  SET_EXTERNAL_REFERENCE_ID,
  SET_INQUIRY_DRAFT,
  SET_PROCESS_LANE,
  GET_INQUIRY_FILES,
  CLEAR_INQUIRY_DRAFT,
} from 'store/inquiryProcess/actionTypes';

export const setReferralWidgetCode = (code: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_REFERRAL_WIDGET_CODE,
    code,
  });
};

export const setLeadId = (leadId: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_LEAD_ID,
    leadId,
  });

export const setInquiryType = (inquiryType: InquiryType) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_INQUIRY_TYPE,
    inquiryType,
  });

export const setProcessLane = (processLane: InquiryLane) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_PROCESS_LANE,
    processLane,
  });

export const clearLeadId = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_LEAD_ID,
  });

export const setCanSendLead = (flag: boolean) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_CAN_SEND_LEAD,
    canSendLead: flag,
  });

export const setMultiplierOrgIdAction = (id: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_MULTIPLIER_ORGANIZATION_ID,
    midId: id,
  });

export const setExternalReferenceIdAction = (id: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_EXTERNAL_REFERENCE_ID,
    externalReferenceId: id,
  });

export const setInquiryDraftId = (inquiryDraftId: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_INQUIRY_DRAFT,
    inquiryDraftId,
  });

export const clearInquiryDraftId = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_INQUIRY_DRAFT,
  });

export const getInquiryFilesAction = (inquiryId: string, selectedLanguage?: string) => {
  const queryParams = selectedLanguage
    ? {
        language: selectedLanguage,
      }
    : {};
  return callReverseApi({
    url: endpoints.INQUIRIES.REQUESTED_DOCS.compose({
      params: { id: inquiryId },
      query: queryParams,
    }),
    method: API_METHODS.GET,
    actionName: GET_INQUIRY_FILES,
  });
};
