import {
  TECHNOLOGY_DESCRIPTION,
  NUMBER_OF_TECHNOLOGIES,
  CLIENT_ROLE,
  CONTRACTOR_COMPANY_INFORMATION,
} from 'modules/Inquiry/Form/formFields';

import { fieldValidators } from './customValidations';

export const energyEfficiencyFieldValidations = {
  [CLIENT_ROLE]: fieldValidators.required(),
  [CONTRACTOR_COMPANY_INFORMATION]: fieldValidators.string().max(2000).required(),
  [NUMBER_OF_TECHNOLOGIES]: fieldValidators.required(),
  [TECHNOLOGY_DESCRIPTION]: fieldValidators.string().max(2000).required(),
};
