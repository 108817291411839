import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';

import Condition from 'components/Condition';
import { translations } from 'new/form/common/types';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from 'theme/components/DescriptionList';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsAgent } from 'utils/user/conditionals';

import { ProgressItem } from './SummaryCard/ProgressItem';
import { SummaryCardItem } from './SummaryCard/SummaryCardItem';
import { useSummaryCardData } from './useSummaryCardData';

export interface DzbSummaryCardProps {
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  openTaskId?: string;
  canShowProgress?: boolean;
}

export const DzbSummaryCard = (props: DzbSummaryCardProps) => {
  const isAgent = userIsAgent();
  const {
    associatedCompany: tAssociatedCompany,
    status: tStatus,
    reference,
    id: tId,
    customerName: tCompanyName,
  } = translations.pages.inquiryDetails.dashboard.summary;
  const t = useTranslations();
  const { status, associatedCompany, id, referenceId, companyName } = useSummaryCardData();

  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)']}
      gap={12}
      as="section"
      border="1px"
      borderColor="border.lightGrey"
      p={8}
      boxShadow="default"
      bgColor="background.white"
    >
      <GridItem>
        <DescriptionList size="large" mb={4}>
          <DescriptionListTerm>{t(tAssociatedCompany)}</DescriptionListTerm>
          <DescriptionListDescription>{associatedCompany?.name}</DescriptionListDescription>
        </DescriptionList>
        <DescriptionList mb={4}>
          <DescriptionListTerm>{t(tId)}</DescriptionListTerm>
          <DescriptionListDescription>{id}</DescriptionListDescription>
        </DescriptionList>
        <Condition condition={isAgent}>
          <DescriptionList>
            <DescriptionListTerm>{t(reference)}</DescriptionListTerm>
            <DescriptionListDescription>{referenceId}</DescriptionListDescription>
          </DescriptionList>
        </Condition>
      </GridItem>
      <GridItem>
        <SummaryCardItem>
          <Condition condition={isAgent}>
            <DescriptionList mb={4}>
              <DescriptionListTerm>{t(tCompanyName)}</DescriptionListTerm>
              <DescriptionListDescription>{companyName}</DescriptionListDescription>
            </DescriptionList>
          </Condition>
          <DescriptionList mb={4}>
            <DescriptionListTerm>{t(tStatus)}</DescriptionListTerm>
            <DescriptionListDescription>{status}</DescriptionListDescription>
          </DescriptionList>
        </SummaryCardItem>
      </GridItem>
      <ProgressItem {...props} />
    </Grid>
  );
};
