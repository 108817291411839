import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { AccessTokenScope } from 'types/Auth';

const useLogoutRedirect = (currentScope: AccessTokenScope) => {
  const history = useHistory();

  return useCallback(() => {
    switch (currentScope) {
      case AccessTokenScope.customer:
        history.push(paths.customer.login);
        break;
      case AccessTokenScope.portal_user:
        history.push(paths.operation.login);
        break;
      default:
        history.push(paths.customer.login);
    }
  }, [currentScope, history]);
};

export default useLogoutRedirect;
