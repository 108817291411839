import {
  LEAD_ID,
  MULTIPLE_ORGANISATION_ID,
  REFERRAL_CODE,
  EXTERNAL_REFERENCE_ID,
  INQUIRY_DRAFT_ID,
} from 'modules/Inquiry/Form/formFields';
import { clearFormData } from 'shared/inquiry/inquirySessionStorage.helpers';
import { IInquiryProcess } from 'store/inquiryProcess/reducer';

export const addInquiryProcessPropsToPayload = (
  {
    referralWidgetCode,
    leadId,
    multiplierOrganizationId,
    externalReferenceId,
    inquiryDraftId,
  }: Partial<IInquiryProcess>,
  payload: any,
) => {
  const additionalProps = {
    [REFERRAL_CODE]: referralWidgetCode || undefined,
    [LEAD_ID]: leadId || undefined,
    [MULTIPLE_ORGANISATION_ID]: multiplierOrganizationId || undefined,
    [EXTERNAL_REFERENCE_ID]: externalReferenceId || undefined,
    [INQUIRY_DRAFT_ID]: inquiryDraftId || undefined,
  };

  const payloadWithAdditionalProps = {
    data: {
      attributes: {
        ...(payload?.data?.attributes || payload),
        ...additionalProps,
      },
    },
  };
  return payloadWithAdditionalProps;
};

export const resetInquiryProcess = (_history: any, clearStages: any, resetForm: any) => {
  clearFormData();

  if (clearStages) {
    clearStages();
  }

  resetForm(true);
};
