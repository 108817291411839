import styled from 'styled-components/macro';

import { zIndex } from 'theme/themeConstants';

export const StyledFixedOptionBar = styled.aside`
  position: fixed;
  z-index: ${zIndex.sideBar.base};
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
