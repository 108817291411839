import { useCallback } from 'react';

import { ICompaniesTableOptions } from 'pages/operationPortal/CompaniesList/CompaniesTable.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { getCompaniesListAction } from '../store/actions';

export const useFetchCompaniesList = () => {
  const { makeCall, isPending: isLoading } = useDispatchApiCall();
  const fetchCompanies = useCallback(
    async (reqOptions: ICompaniesTableOptions) => {
      await makeCall(getCompaniesListAction(reqOptions));
    },
    [makeCall],
  );
  return { isLoading, fetchCompanies };
};
