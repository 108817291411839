import styled from 'styled-components/macro';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';

export const MaximumFinancingAmount = styled.span`
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${({ theme }) => theme.colors.primary};
`;

export const ReduceTopSpacing = styled.div`
  margin-top: -3.5rem;
`;
