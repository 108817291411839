enum CONFIG {
  IS_BANK_ADVISOR_ENABLED = 'isBankAdvisorEnabled',
  IS_FASTLANE_ENABLED = 'isFastlaneEnabled',
  IS_MULTIPLE_COMPANY_INDUSTRY_ENABLED = 'isMultipleCompanyIndustryEnabled',
  IS_INQUIRY_DRAFT_MODE_ENABLED = 'isInquiryDraftModeEnabled',
  IS_INQUIRY_EDIT_ALLOWED_FOR_CUSTOMER = 'isInquiryEditAllowedForCustomer',
  IS_INQUIRY_CUSTOMER_CONFIRMATION_ENABLED = 'isInquiryConfirmationEnabled',
  ARE_OFFERS_FOR_OP_VISIBLE = 'areOffersForOpVisible',
  IS_COMPEON_FORWARDING_ENABLED = 'isCompeonForwardingEnabled',
  IS_SSO_ENABLED = 'isSSoEnabled',
  IS_ADDITIONAL_ASSOCIATION_COMPANY_ENABLED = 'isAdditionalAssociationCompanyEnabled',
  IS_COMPEON_MANAGED = 'isCompeonManaged',
  IS_CHANGELOG_ON = 'isChangelogOn',
  IS_SALES_PERSONS_ENABLED = 'isSalesPersonsEnabled',
  IS_ORGANIZATIONAL_UNIT_ENABLED = 'isOrganizationalUnitEnabled',
  IS_HIDDEN_EXCLUDED_BANKS_SECTION = 'isHiddenExcludedBanksSection',
  IS_REGISTRATION_ENABLED = 'isRegistrationEnabled',
  IS_AGRICULTURAL_INQUIRY_PROCESS_ENABLED = 'isAgriculturalInquiryProcessEnabled',
  IS_FORESTRY_INQUIRY_PROCESS_ENABLED = 'isForestryInquiryProcessEnabled',
  IS_COLLECTING_LEADS_ENABLED = 'isCollectingLeadsEnabled',
  IS_SHOW_INDICATIVE_FOR_LEAD = 'isShowIndicativeForLead',
  IS_SHOW_INDICATIVE_FOR_OFFER = 'isShowIndicativeForOffer',
  IS_PREMIUM_PARTNER_ENABLED = 'isPremiumPartnerEnabled',
  IS_SPECIAL_PARTNER_ENABLED = 'isSpecialPartnerEnabled',
  IS_DIGITAL_PROCESSING_ENABLED = 'isDigitalProcessingEnabled',
  IS_CREFO_MANUAL_REPORT_ENABLED = 'isCrefoManualReportEnabled',
  IS_SMS_TOKEN_ENABLED = 'isSmsTokenEnabled',
  REQUIRE_FINAL_INFO = 'requireFinalInfo',
  CUSTOMER_ACTIVATION_ON_INVITATION = 'customerActivationOnInvitation',
  IS_CANCEL_INQUIRY_IN_COMPEON_ENABLED = 'isCancelInquiryInCompeonEnabled',
  IS_AUTOMATIC_CONTRACT_CREATION_IN_LEAD = 'isAutomaticContractCreationInLead',
  IS_AUTOMATIC_CONTRACT_CREATION_IN_OFFER = 'isAutomaticContractCreationInOffer',
  IS_MARKET_DATA_ENABLED = 'isMarketDataEnabled',
  IS_REAL_ESTATE_EXPERT_ENABLED = 'isRealEstateExpertEnabled',
  IS_PROFITABILITY_CALCULATION_ENABLED = 'isProfitabilityCalculationEnabled',
  IS_EVALUEIN_ENABLED = 'isEvalueinEnable',
  IS_QUICK_CHECK_ENABLED = 'isQuickCheckEnabled',
  IS_COMPEON_EXTERNAL_STATUS_ENABLED = 'isCompeonExternalStatusEnabled',
  IS_INQUIRY_EDITING_ENABLED = 'enableInquiryEditing',
  IS_NEW_INQUIRY_BUTTON_ENABLED = 'enableNewInquiryButton',
  SHOW_CUSTOMER_CONFIRMATION_STATUS = 'showCustomerConfirmationStatus',
  ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST = 'enableMultipleFilesPerUploadRequest',
}

export type FeatureFlags = `${CONFIG}`;

export default CONFIG;
