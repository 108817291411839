import { useLocation } from 'react-router-dom';

import type { LoginFormValues } from './LoginForm';

interface ILocationState {
  email?: string;
}

export const useInitialValues = (): LoginFormValues => {
  const { state } = useLocation<ILocationState>();
  return { email: state?.email ?? '', password: '' };
};
