import { COMPANY_DETAILS_GENDER, GENDER_MAN, GENDER_WOMAN } from 'modules/Inquiry/Form/formFields';
import gender from 'store/partner/config/icons/gender';

const genderOptions = gender.reduce(
  (accumulator, singleOption) => ({ ...accumulator, [singleOption.id]: singleOption.titleId }),
  {},
);

export const companyDetailsValues = {
  [COMPANY_DETAILS_GENDER]: {
    ...genderOptions,
    [GENDER_MAN]:
      'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders.man',
    [GENDER_WOMAN]:
      'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders.woman',
  },
};
