import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { VEHICLE_INTERESTED_IN_INSURANCE } from 'modules/Inquiry/Form/formFields';

const VehicleInterestedInInsurance = () => {
  return <YesNoRadioGroupWithField name={VEHICLE_INTERESTED_IN_INSURANCE} />;
};

export default VehicleInterestedInInsurance;
