import paths from 'constants/paths';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';

import useCurrentInquiryDetails from './useCurrentInquiryDetails';

const useInquiryEditGate = () => {
  const { isLoading, status, inquiryId } = useCurrentInquiryDetails();
  let allowed = true;
  let path = undefined;

  if (status === INQUIRY_STATUSES.ARCHIVED && !isLoading && inquiryId) {
    allowed = false;
    path = paths.customer.inquiryDetails.dashboard.replace(':id', inquiryId);
  }

  return { allowed, path, isLoading };
};

export default useInquiryEditGate;
