const CustomerInquiryListHeading = {
  parts: ['search'],
  baseStyle: {},
  variants: {
    hideSearch: {
      search: {
        display: 'none',
      },
    },
  },
  defaultProps: {},
};

export default CustomerInquiryListHeading;
