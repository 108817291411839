import { SummaryProps } from 'new/summary/components/SummarySections';

import { SummaryPageField, useBuildFieldsForSummaryPage } from './useBuildFieldsForSummary';

export type FieldGroup = SummaryPageField[];
export type SectionFieldGroup = Array<FieldGroup>;
type GroupedSection = { [sectionName: string]: SectionFieldGroup };
type GroupedPageData = { [pageName: string]: GroupedSection };

type UseGroupFieldsByRowProps = Omit<SummaryProps, 'stepData'>;

/**
 * Custom hook to group non-array fields into a single array until an array field is encountered.
 * If an array field is encountered, it is returned as is, and a new array of non-array fields starts.
 *
 * @returns {GroupedPageData} - The grouped fields organized by page and section.
 */
export const useGroupFieldsByRow = ({
  stepSequence,
  summaryFields,
  fields,
}: UseGroupFieldsByRowProps): GroupedPageData => {
  const data = useBuildFieldsForSummaryPage({
    stepSequence,
    summaryFields,
    fields,
  });

  const newGroupedData: GroupedPageData = {};

  Object.entries(data).forEach(([pageName, sections]) => {
    const newGroupedSections: GroupedSection = {};

    Object.entries(sections).forEach(([sectionName, fields]) => {
      let tempFields: FieldGroup = [];
      const newGroupedFields: SectionFieldGroup = [];

      fields.forEach((fieldOrFields) => {
        if (Array.isArray(fieldOrFields)) {
          // only push to the section if there are values in the array fields
          if (fieldOrFields.length > 0) {
            if (tempFields.length > 0) {
              newGroupedFields.push([...tempFields]);
              tempFields = [];
            }
            newGroupedFields.push(fieldOrFields);
          }
        } else {
          tempFields.push(fieldOrFields);
        }
      });

      if (tempFields.length > 0) {
        newGroupedFields.push([...tempFields]);
      }

      newGroupedSections[sectionName] = newGroupedFields;
    });

    newGroupedData[pageName] = newGroupedSections;
  });

  return newGroupedData;
};
