import React from 'react';

import { FileKind } from 'models/FileKind.type';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import ProcessSpecificFiles from './ProcessSpecificFiles';

// HOC for hausbank video legitimation process specific files
export const HausbankVideoLegitimationFiles = () => {
  const t = useTranslations();
  const { videoIdentificationTitle } =
    translations.pages.inquiryDetails.documentExchange.processSpecificFiles;

  const videoLegitimationKinds = [FileKind.HAUSBANK_VIDEO_LEGITIMATION];

  return (
    <ProcessSpecificFiles title={t(videoIdentificationTitle)} kinds={videoLegitimationKinds} />
  );
};
