import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const UserIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4" />
      <path d="M4 20.1h16c-.7-4.4-4.8-7.5-9.2-6.8-3.5.5-6.3 3.3-6.8 6.8zM22 22H2v-1c0-5.5 4.5-10 10-10s10 4.5 10 10v1z" />
    </Icon>
  );
};

export default UserIcon;
