import { useCallback } from 'react';

import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { getCompanyRiskAnalysis } from '../store/actions';

export const useGetRiskAnalysis = () => {
  const { makeCall, isPending: isLoading } = useDispatchApiCall();
  const getRiskAnalysis = useCallback(
    async (companyId: string) => {
      await makeCall(getCompanyRiskAnalysis(companyId));
    },
    [makeCall],
  );
  return { isLoading, getRiskAnalysis };
};
