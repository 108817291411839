import {
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_ZIP_CODE,
  IS_BENEFICIARY_OWNER,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  SELF_BENEFICIARY_OWNER,
  USER_EXISTING_REPRESENTATIVE,
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
  USER_SOLE_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';

import { commonMapPeopleDetailsToApi } from './commonMapPeopleDetailsToApi';
import { mapDefaultBeneficiaryOwner } from './mapBeneficiaryOwners';
import { mapDefaultLegalRepresentative } from './mapLegalRepresentatives';
import { MultiSelectOption } from '../../Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { fieldParsers } from '../fieldParsers';

const { toBoolean } = fieldParsers;

export const mapPeopleDetailsToApi = (allFields: any, isLead: boolean) => {
  const userLegalRepresentation = toBoolean(allFields[USER_SOLE_REPRESENTATION]);
  let legalRepresentatives: Array<any> = [];
  const userRepresentatives = allFields[USER_REPRESENTATIVE];
  const existingUserRepresentatives = allFields[USER_EXISTING_REPRESENTATIVE];

  if ((userRepresentatives || existingUserRepresentatives) && !userLegalRepresentation) {
    legalRepresentatives = userRepresentatives
      ? userRepresentatives.map((people: any) => ({
          salutation: people[REPRESENTATIVE_GENDER],
          first_name: people[REPRESENTATIVE_FIRST_NAME],
          last_name: people[REPRESENTATIVE_LAST_NAME],
          email: people[REPRESENTATIVE_EMAIL],
          birth_date: people[REPRESENTATIVE_BIRTHDATE],
        }))
      : [];

    const existingLegalRepresentatives = existingUserRepresentatives
      ? existingUserRepresentatives.map((people: MultiSelectOption) => ({
          id: people.key,
        }))
      : [];

    legalRepresentatives = [...legalRepresentatives, ...existingLegalRepresentatives];
  }

  if (isLead) {
    return {};
  }

  return {
    'legal-representation': allFields[USER_REPRESENTATION],
    user_legal_representation: userLegalRepresentation,
    legal_representatives: legalRepresentatives,
  };
};

const mapLegalRepresentative = (allFields: any, representative: any) => {
  const existingRepresentatives = allFields[USER_EXISTING_REPRESENTATIVE] || [];
  const isExistingRepresentative = existingRepresentatives.some(
    (existingUserRepresentative: MultiSelectOption) =>
      existingUserRepresentative.key === representative.id,
  );

  const defaultMapResult = mapDefaultLegalRepresentative(allFields, representative);

  if (isExistingRepresentative) {
    return {
      id: representative.id,
      phone_number: representative[REPRESENTATIVE_PHONE_NUMBER],
    };
  }

  return defaultMapResult;
};

const mapBeneficiaryOwner = (allFields: any, owner: any) => {
  const defaultMapResult = mapDefaultBeneficiaryOwner(allFields, owner);

  if (owner.isAssociatedPerson) {
    return {
      id: owner.id,
      company_share: owner[BENEFICIARY_COMPANY_SHARE],
    };
  }
  return {
    ...defaultMapResult,
    birth_place: owner[BENEFICIARY_BIRTH_COUNTRY],
    company_share: owner[BENEFICIARY_COMPANY_SHARE],
    address: owner[BENEFICIARY_PRIVATE_ADDRESS],
    zip_code: owner[BENEFICIARY_ZIP_CODE],
  };
};

export const mapSelfBeneficiaryOwner = (allFields: any) => {
  const isBeneficiaryOwner = toBoolean(allFields[IS_BENEFICIARY_OWNER]);
  if (isBeneficiaryOwner) {
    return {
      beneficiary_owners: (allFields[SELF_BENEFICIARY_OWNER] || []).map((owner: any) => {
        return mapBeneficiaryOwner(allFields, { ...owner, id: allFields.customerId });
      }),
    };
  }
  return {};
};

export const mapLeasePlanToApi = (allFields: any) => {
  return commonMapPeopleDetailsToApi(allFields, {
    mapLegalRepresentative,
    mapBeneficiaryOwner,
    mapAdditionalData: mapSelfBeneficiaryOwner,
  });
};
