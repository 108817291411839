import { ActionType, ACTION } from './actionTypes';
import { CompaniesTable, ICompaniesTableOptions } from '../CompaniesTable.model';
import { mapCompaniesFromApi } from '../helpers/companies.service';
import { COMPANIES_TABLE_HEADERS, SORT_DIRECTION } from '../helpers/useCompaniesListTableHeaders';

export interface ICompaniesList {
  companies: CompaniesTable[];
  options: ICompaniesTableOptions;
}

const initialState: ICompaniesList = {
  companies: [],
  options: {
    sortBy: COMPANIES_TABLE_HEADERS.createdAt,
    sortDirection: SORT_DIRECTION.DESC,
    page: 1,
    pageSize: 5,
    totalPages: 1,
  },
};

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
    case ACTION.GET_COMPANIES_LIST_SUCCESS: {
      return {
        ...state,
        ...mapCompaniesFromApi(action.payload.data),
      };
    }

    default: {
      return state;
    }
  }
};
