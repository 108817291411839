import React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField, INPUT_TYPE_DECICMAL_WITH_ZERO } from 'components/Input';
import {
  QUICK_CHECK_CURRENT_COLLATERAL_VALUE,
  QUICK_CHECK_CURRENT_TOTAL_COMMITMENT,
  QUICK_CHECK_NEW_COLLATERAL,
  QUICK_CHECK_NEW_COMMITMENT,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const UnsecuredPortionSection = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck.sections.unsecuredPortion');

  return (
    <>
      <FormRow>
        <InputWithField
          name={QUICK_CHECK_CURRENT_TOTAL_COMMITMENT}
          placeholder={t('fields.currentTotalCommitment.placeholder')}
          data-testid={QUICK_CHECK_CURRENT_TOTAL_COMMITMENT}
          type={INPUT_TYPE_DECICMAL_WITH_ZERO}
        />
        <InputWithField
          name={QUICK_CHECK_NEW_COMMITMENT}
          placeholder={t('fields.newCommitment.placeholder')}
          data-testid={QUICK_CHECK_NEW_COMMITMENT}
          type={INPUT_TYPE_DECICMAL_WITH_ZERO}
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name={QUICK_CHECK_CURRENT_COLLATERAL_VALUE}
          placeholder={t('fields.currentCollateralValue.placeholder')}
          data-testid={QUICK_CHECK_CURRENT_COLLATERAL_VALUE}
          type={INPUT_TYPE_DECICMAL_WITH_ZERO}
        />
        <InputWithField
          name={QUICK_CHECK_NEW_COLLATERAL}
          placeholder={t('fields.newCollateral.placeholder')}
          data-testid={QUICK_CHECK_NEW_COLLATERAL}
          type={INPUT_TYPE_DECICMAL_WITH_ZERO}
        />
      </FormRow>
    </>
  );
};

export default UnsecuredPortionSection;
