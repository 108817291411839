import React from 'react';

import PropTypes from 'prop-types';

import { StyledLink } from 'modules/FormPage/styles';
import { SUMMARY } from 'modules/Inquiry/Form/formFields';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import { ButtonComponent } from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { EditStepWrapper } from './styles';

const EditStepButton = ({ stepName }) => {
  const translate = useTranslations('pages.summaryPage.buttons');
  const { getPathToStep } = useInquiryNavigation(SUMMARY);

  return (
    <EditStepWrapper>
      <StyledLink to={getPathToStep(stepName)}>
        <ButtonComponent
          leftIcon={<EditIcon boxSize={6} display="block" />}
          variant="tertiary"
          data-testid="Edit-Section"
        >
          {translate('edit')}
        </ButtonComponent>
      </StyledLink>
    </EditStepWrapper>
  );
};

EditStepButton.propTypes = {
  stepName: PropTypes.string.isRequired,
};

export default EditStepButton;
