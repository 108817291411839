import React from 'react';

import { HStack, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import Condition from 'components/Condition';
import paths from 'constants/paths';
import { translations } from 'new/form/common/types';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const { caption, linkLabel } = translations.pages.loginPage.registration;
const { registration } = paths;

export const RegistrationLink = () => {
  const t = useTranslations();

  const isRegistrationEnabled = useConfig(CONFIG.IS_REGISTRATION_ENABLED);

  return (
    <Condition condition={isRegistrationEnabled}>
      <HStack justify="end">
        <TextComponent color="text.tertiary">{t(caption)}</TextComponent>
        <ChakraLink as={RouterLink} variant="secondary" to={registration}>
          {t(linkLabel)}
        </ChakraLink>
      </HStack>
    </Condition>
  );
};
