import _get from 'lodash/get';

import { useTranslations } from 'utils/hooks/useTranslations';

import { useToasts } from './useToasts';

const API_ERRORS = {
  WRONG_CREDENTIALS: 'invalid_grant',
  ACCOUNT_LOCKED: 'account_locked',
  UNCONFIRMED_ACCOUNT: 'unconfirmed_account',
  UNAUTHORIZED_SCOPED: 'unauthorized_scope',
  UNAUTHORIZED_PARTNER: 'unauthorized_partner',
};
// Returns function to handle login errors
export const useAuthErrorHandler = () => {
  const t = useTranslations();
  const { error: notifyError } = useToasts();

  return (error: any) => {
    const apiError = _get(error, ['response', 'data', 'error']);
    if (apiError || error) {
      const errorHandlers = {
        [API_ERRORS.WRONG_CREDENTIALS]: () =>
          notifyError({ description: t('pages.loginPage.error') }),
        [API_ERRORS.ACCOUNT_LOCKED]: () =>
          notifyError({ description: t('pages.loginPage.accountLocked') }),
        [API_ERRORS.UNAUTHORIZED_SCOPED]: () =>
          notifyError({ description: t('pages.loginPage.unauthorizedScope') }),
        [API_ERRORS.UNAUTHORIZED_PARTNER]: () =>
          notifyError({ description: t('pages.loginPage.unauthorizedScope') }),
        [API_ERRORS.UNCONFIRMED_ACCOUNT]: () =>
          notifyError({ description: t('pages.loginPage.unconfirmedAccount'), duration: 30000 }),
        default: () => notifyError({ description: t('errors.actionFailed') }),
      };
      if (errorHandlers[apiError]) {
        errorHandlers[apiError]();
      } else {
        errorHandlers.default();
      }
    }
  };
};
