import React from 'react';

import { Field } from 'react-final-form';

import FieldGroup from 'components/PortalFormFields/FieldGroup';
import Autocomplete from 'components/PortalFormFields/SelectField/Autocomplete/Autocomplete';
import { COMPANY_DETAILS_COMPANY__NOT_FOUND } from 'modules/Inquiry/Form/formFields';
import NoCompanyElement from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/NoCompanyElement/NoCompanyElement';
import {
  useFetchCompanySuggestions,
  MIN_COMPANY_SUGGESTION_QUERY,
  SuggestionOption,
} from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, required } from 'utils/validators';

export type CompanySuggestionProps = {
  fieldName: string;
  onSelect?: (name: string, companies: SuggestionOption[]) => void;
  onClearCompanyForm: () => void;
  menuShowThreshold?: number;
};

const CompanySuggestion = ({
  fieldName,
  onSelect,
  onClearCompanyForm,
  menuShowThreshold,
}: CompanySuggestionProps) => {
  const t = useTranslations('pages.companyDetails.sections.company.fields.companyDetailsCompany');

  const tErrors = useTranslations('errors');
  const { isLoading, companies, changeQuery, nextPage } = useFetchCompanySuggestions();
  const options = companies.map((company) => ({ label: company.label, value: company.value.name }));

  const handleInputChange = (newValue: string) => {
    changeQuery(newValue);
  };

  const notFoundOption = {
    label: t('additionalOption.labelOnSelected'),
    value: COMPANY_DETAILS_COMPANY__NOT_FOUND,
    component: <NoCompanyElement />,
  };

  const menuShowThresholdProp =
    menuShowThreshold === undefined ? MIN_COMPANY_SUGGESTION_QUERY : menuShowThreshold;

  return (
    <Field
      name={fieldName}
      validate={combineValidators(required(tErrors('required')))}
      render={({ meta, input }) => (
        <FieldGroup label={t('caption')} error={meta.touched && meta.error ? meta.error : null}>
          <Autocomplete
            name={input.name}
            options={options}
            onFocus={input.onFocus}
            onChange={handleInputChange}
            onSelect={(value) => {
              input.onChange(value);
              if (onSelect) {
                onSelect(value, companies);
              }
            }}
            placeholder={t('placeholder', { roleSpecific: true })}
            value={input.value}
            inputInitialValue={input.value}
            loading={isLoading}
            menuShowThreshold={menuShowThresholdProp}
            onScrollEnd={nextPage}
            fixedOptions={[notFoundOption]}
            onBlur={input.onBlur}
            clearCompanyForm={onClearCompanyForm}
            testId={fieldName}
          />
        </FieldGroup>
      )}
    />
  );
};

export default CompanySuggestion;
