import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  wegName,
  street,
  zipCode,
  city,
  country,
  livingSpace,
  commercialUsage,
  propertySize,
  constructionYear,
  lastModernization,
} = hausbankFields.objectInformationPage.objectInformationSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.objectInformation.sections.objectInformation;

export const objectInformationSectionLabelsMap: LabelsMap = {
  [wegName]: fieldTranslations.wegName.caption,
  [street]: fieldTranslations.street.caption,
  [zipCode]: fieldTranslations.zipCode.caption,
  [city]: fieldTranslations.city.caption,
  [country]: fieldTranslations.country.caption,
  [livingSpace]: fieldTranslations.livingSpace.caption,
  [commercialUsage]: fieldTranslations.commercialUsage.caption,
  [propertySize]: fieldTranslations.propertySize.caption,
  [constructionYear]: fieldTranslations.constructionYear.caption,
  [lastModernization]: fieldTranslations.lastModernization.caption,
};
