import normalize from 'json-api-normalizer';
import { createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';
import { ApiResponse } from 'models/ApiResponse';

import { Signee } from '../../../../models/types/Signee.type';
import endpoints from '../../endpoints';

interface InquiryQueryVariables {
  id: string;
}

export const useInquiry = createQuery<ApiResponse, InquiryQueryVariables>({
  primaryKey: queryKeys.inquiries.details,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.DETAILS.compose({
        params: { id: variables.id },
      }),
    ),
});

interface InquirySigneesQueryResponse {
  data: {
    customer: Signee[];
    legalRepresentative: Signee[];
  };
}

export const useInquirySignees = createQuery<InquirySigneesQueryResponse, InquiryQueryVariables>({
  primaryKey: queryKeys.inquiries.signees,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.SIGNEES.compose({
        params: { id: variables.id },
      }),
    ).then((response) => ({
      ...response,
      data: normalize(response.data, {
        camelizeTypeValues: false,
      }),
    })),
});
