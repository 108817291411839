import React from 'react';

import EmptyField from 'components/EmptyField';
import FormRow from 'components/FormRow';
import ConditionalSubform from 'modules/ConditionalSubform';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  LOAN_FIXED_INTEREST_RATE,
  LOAN_FIXED_INTEREST_RATE__FIXED,
  PURPOSE_KIND__CORONA,
  FINANCING_NEED,
  PURPOSE_KIND,
} from 'modules/Inquiry/Form/formFields';
import LoanDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanDescription';
import LoanFixedInterestRate from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanFixedInterestRate/LoanFixedInterestRate';
import LoanInterestPeriodInMonths from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanTypeFields/sections/overlappingFields/LoanInterestPeriodInMonths/LoanInterestPeriodInMonths';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';

const MaturityLoan = () => (
  <>
    <FormRow separator>
      <LoanFixedInterestRate />
      <ConditionalSubform
        condition={fieldHasValue(LOAN_FIXED_INTEREST_RATE, LOAN_FIXED_INTEREST_RATE__FIXED)}
        subscription={{ values: true, visited: true }}
        renderOnSatisfy={() => <LoanInterestPeriodInMonths />}
        renderOnFail={() => <EmptyField />}
      />
    </FormRow>

    <FormRow>
      <LoanDescription />
    </FormRow>
  </>
);

const CoronaMaturityLoan = () => {
  return (
    <>
      <div>
        <LoanFixedInterestRate />
        <LoanInterestPeriodInMonths />
      </div>

      <FormRow>
        <LoanDescription />
      </FormRow>
    </>
  );
};

export default chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__CORONA,
      component: CoronaMaturityLoan,
    },
  ],
  MaturityLoan,
);
