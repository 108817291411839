import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CoronaIcon = (props: IconProps) => {
  return (
    <Icon
      width="110"
      height="130"
      viewBox="0 0 110 130"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
        <path d="M108 36.89v-18A19 19 0 0191.107 2H18.893A19 19 0 012 18.893v42.134C2 117.086 55 128 55 128s53-12.9 53-66.973V43.664" />
        <path d="M94.893 84.271a57.545 57.545 0 004.814-23.765V24.561A18.531 18.531 0 0185.86 10.714H24.929a18.526 18.526 0 01-14.636 14.015V36.89m0 5.985v17.631C10.293 107.794 55 117 55 117s24.9-6.06 37.534-28.093" />
        <path d="M90.2 37.79a6.307 6.307 0 00-11.519 3.1l-5.635 3.813a24.932 24.932 0 00-14.017-7.379v-6.375a6.5 6.5 0 10-8 0v6.368a24.957 24.957 0 00-14.89 8.283l-5.391-3.648A5.927 5.927 0 1026.66 48l5.541 3.75a24.979 24.979 0 003.784 26.475l-3.412 3.852a5.711 5.711 0 105.261 4.661l3.417-3.857a25.023 25.023 0 0023.466 2.164l2.662 5.16a5.711 5.711 0 106.247-3.223l-2.873-5.569a25.007 25.007 0 006.739-30.331l5.542-3.749A6.307 6.307 0 0090.2 37.79z" />
        <circle cx="42.032" cy="58.614" r="4.213" />
        <circle cx="57.869" cy="51.504" r="3.121" />
        <circle cx="55.33" cy="75.038" r="5.702" />
        <path d="M45.17 43.958l-5.057-8.151" />
        <circle cx="37.965" cy="32.721" r="3.595" />
        <path d="M29.894 67.317l-4.142 1.052" />
        <circle cx="22.082" cy="69.191" r="3.595" />
        <path d="M64.312 44.871l6.183-11.493" />
        <circle cx="72.256" cy="30.055" r="3.595" />
        <path d="M72.46 67.483l11.378 3.383" />
        <circle cx="87.464" cy="71.862" r="3.595" />
        <path d="M53.914 87.821l.108 5.112" />
        <circle cx="54.179" cy="96.69" r="3.595" />
      </g>
    </Icon>
  );
};

export default CoronaIcon;
