import * as React from 'react';

import { Alert as ChakraAlert, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';

import ErrorIcon from 'theme/components/Icon/ErrorIcon';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import SuccessIcon from 'theme/components/Icon/SuccessIcon';
import WarningIcon from 'theme/components/Icon/WarningIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

type AlertType = 'info' | 'success' | 'warning' | 'error';

type Props = {
  status: AlertType;
  heading?: string;
  children: string;
};

export const AlertComponent = ({ status, heading, children, ...props }: Props) => {
  const t = useTranslations();

  let icon;
  let headingDefault;

  switch (status) {
    case 'info':
      icon = <InfoIcon boxSize={6} mr={2} fill={`status.${status}`} />;
      headingDefault = t('components.alert.infoHeading');
      break;
    case 'success':
      icon = <SuccessIcon boxSize={6} mr={2} fill={`status.${status}`} />;
      headingDefault = t('components.alert.successHeading');
      break;
    case 'warning':
      icon = <WarningIcon boxSize={6} mr={2} fill={`status.${status}`} />;
      headingDefault = t('components.alert.warningHeading');
      break;
    case 'error':
      icon = <ErrorIcon boxSize={6} mr={2} fill={`status.${status}`} />;
      headingDefault = t('components.alert.errorHeading');
      break;
    default:
      icon = <InfoIcon boxSize={6} mr={2} fill={`status.${status}`} />;
      headingDefault = t('components.alert.infoHeading');
      break;
  }

  return (
    <ChakraAlert
      borderColor={`status.${status}`}
      backgroundColor={`status.${status}_L93`}
      status={status}
      {...props}
    >
      {icon}
      <Box>
        <AlertTitle>{heading ?? headingDefault}</AlertTitle>
        <AlertDescription>{children}</AlertDescription>
      </Box>
    </ChakraAlert>
  );
};
