import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const NeubauIcon = (props: IconProps) => {
  return (
    <Icon
      width="58"
      height="44"
      viewBox="0 0 57.34 43.24"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M50.47 19.73a5.63 5.63 0 113.13-10.3l-.69 1a4.38 4.38 0 00-5.54 6.74 4.48 4.48 0 006.2 0 4.39 4.39 0 00.54-5.54l1-.69a5.62 5.62 0 01-4.67 8.76z" />
      <path d="M54.51 12.31l-.41-.67a3.3 3.3 0 00-.52-.65 2.93 2.93 0 00-.65-.51l-.67-.42 2.25-2.25a1.92 1.92 0 011.34-.57 1.49 1.49 0 011.06.42 1.51 1.51 0 01.43 1.06 1.93 1.93 0 01-.58 1.34zm-.31-2.44l.26.24.24.26 1.18-1.18a.69.69 0 00.22-.47.27.27 0 00-.1-.18c-.05-.06-.17 0-.18-.06a.67.67 0 00-.47.21zm-3.77 7.25a3 3 0 112.11-.87 3 3 0 01-2.11.87zm0-4.73a1.75 1.75 0 101.24.51 1.74 1.74 0 00-1.24-.51zM33.94 43.24l13.33-25 1.1.58-10.7 20.06 13.11-20.27 1.05.67-13.99 21.51-3.9 2.45zM21.23 31.02l2.64-4.09 21.28-13.77.67 1.05-20.23 13.08 20.38-10.87.58 1.1-25.32 13.5z" />
      <path d="M45.37 23.43a5.9 5.9 0 01-4-4l1.2-.32a4.63 4.63 0 003.12 3.13z" />
      <path d="M41.931 21.984l1.768-1.768.877.877-1.768 1.768zM48.14 7.64H46.9V6.5H10.54V5.27h37.6v2.37zm0 29.65h-6.18v-1.24h4.94v-6.67h1.24v7.91zm-12.56 0h-29A6.56 6.56 0 010 30.73V5.89h1.24v24.84a5.33 5.33 0 005.32 5.32h29z" />
      <path d="M1.69 33.26l-1.18-.37a6.56 6.56 0 013.21-4.05 9.67 9.67 0 016.2-.6V1.29c-1.44-.1-5.38-.18-7.42 1.62a3.74 3.74 0 00-1.26 3H0A5 5 0 011.68 2C4.62-.61 10.37.07 10.62.1l.54.07v29.7l-.81-.25c-.28-.09-6.88-2.06-8.66 3.64z" />
    </Icon>
  );
};

export default NeubauIcon;
