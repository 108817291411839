import React, { useMemo } from 'react';

import { useForm } from 'react-final-form';

import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IFormSubmitButton {
  isLoading: boolean;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  continueButtonText: string;
}

const FormSubmitButton = ({ isLoading, onSubmit, continueButtonText }: IFormSubmitButton) => {
  const t = useTranslations();
  const { getState } = useForm();

  const isStepValid = useMemo(() => {
    return getState().valid;
  }, [getState]);

  return (
    <ButtonComponent
      data-testid="next-step-button"
      disabled={!isStepValid}
      isLoading={isLoading}
      leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
      onClick={onSubmit}
    >
      {t(continueButtonText)}
    </ButtonComponent>
  );
};

export default FormSubmitButton;
