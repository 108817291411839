import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { selectedCompanyName, loggedInUserCompanyName } =
  hausbankFields.companyDataPage.wegSearchSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.companyData.sections.wegSearch;

export const wegSearchSectionLabelsMap: LabelsMap = {
  [selectedCompanyName]: fieldTranslations.selectedCompanyName.caption,
  [loggedInUserCompanyName]: fieldTranslations.loggedInUserCompanyName.caption,
};
