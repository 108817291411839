import React, { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { NewStaticFieldWithField } from 'components/StaticField';
import { hausbankFields, HausbankFieldTypes } from 'hausbank/inquiry/fields';
import { replaceUndefinedWithZero } from 'new/form/common/replaceUndefinedWithNumber';
import { formatPrice } from 'utils/valueFormats';

const { financingAmount } = hausbankFields.financingNeedPage.financingNeedSection;

function useFinancingAmount() {
  const { change } = useForm();
  const {
    values: {
      financingNeedPage: {
        financingNeedSection: {
          investmentAmount: formInvestmentAmount,
          maintenanceReserve: formMaintenanceReserve,
          wegContribution: formWegContribution,
          constructionSubsidies: formConstructionSubsidies,
        },
      },
    },
  } = useFormState<HausbankFieldTypes>();

  useEffect(() => {
    const { investmentAmount, maintenanceReserve, wegContribution, constructionSubsidies } =
      replaceUndefinedWithZero({
        investmentAmount: formInvestmentAmount,
        maintenanceReserve: formMaintenanceReserve,
        wegContribution: formWegContribution,
        constructionSubsidies: formConstructionSubsidies,
      });

    const total = investmentAmount - maintenanceReserve - wegContribution - constructionSubsidies;

    change(financingAmount, total);
  }, [
    change,
    formConstructionSubsidies,
    formInvestmentAmount,
    formMaintenanceReserve,
    formWegContribution,
  ]);
}

export const FinancingAmount = () => {
  useFinancingAmount();

  return (
    <NewStaticFieldWithField
      name={financingAmount}
      parseValue={(value: number) => formatPrice(value, true)}
    />
  );
};
