import { Assets, Dropdowns } from 'config/formConfig/types';
import defaultPurposeIcons from 'store/partner/config/icons/purpose';

import purchase from './dropdowns/financingObjects/purchase';
import realEstate from './dropdowns/financingObjects/realEstate';
import machineCategories from './dropdowns/machineCategories';
import dashboardIcons from './icons/dashboard';
import genderIcons from './icons/gender';
import inquiryIcons from './icons/inquiry';
import realEstateIcons from './icons/realEstate';
import vehicleIcons from './icons/vehicles';

interface ProcessConfig {
  dropdowns: Dropdowns;
  assets: Assets;
}

export const defaultPartnerConfig: ProcessConfig = {
  dropdowns: {
    financingObjects: {
      realEstate,
      purchase,
    },
    machineCategories,
  },
  assets: {
    purpose: defaultPurposeIcons,
    realEstate: realEstateIcons,
    vehicle: vehicleIcons,
    gender: genderIcons,
    dashboard: dashboardIcons,
    inquiry: inquiryIcons,
  },
};
