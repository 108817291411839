import * as React from 'react';

import { HeadingProps, Heading } from '@chakra-ui/react';

type VariantType = 'primary' | 'secondary' | 'tertiary' | 'hidden';

type Props = {
  variant?: VariantType;
  children: React.ReactNode;
} & HeadingProps;

export const HeadingComponent = ({ variant = 'primary', children, ...props }: Props) => {
  const headingType: Record<VariantType, React.ElementType<any>> = {
    primary: 'h1',
    secondary: 'h2',
    tertiary: 'h3',
    hidden: 'div',
  };

  return (
    <Heading variant={variant} as={headingType[variant as VariantType]} {...props}>
      {children}
    </Heading>
  );
};
