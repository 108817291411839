import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { clearFormProgress, clearFormData } from 'shared/inquiry/inquirySessionStorage.helpers';
import { resetInquiryDetailsAction } from 'store/inquiryDetails/actions';
import {
  resetForm as resetFormAction,
  clearStages as clearStagesAction,
} from 'store/progress/actions';

const useInquiryReset = (isInquiryForEdit = false) => {
  const [isReset, setIsReset] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // clear sessionStorage
    clearFormData();
    clearFormProgress();
    // clear redux
    dispatch(clearStagesAction());
    if (!isInquiryForEdit) {
      dispatch(resetInquiryDetailsAction());
      dispatch(resetFormAction(true));
    }
    if (isInquiryForEdit) {
      dispatch(resetFormAction(false));
    }

    // clear repos
    setIsReset(true);
  }, [dispatch, isInquiryForEdit]);

  return { isReset };
};

export default useInquiryReset;
