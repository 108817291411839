import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEqual as _isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { DEFAULT_LANGUAGE } from 'constants/globalConstants';
import { UserAttributes } from 'types/User';
import {
  getParsedItemFromSessionStorage,
  saveObjectToSessionStorage,
} from 'utils/sessionStorage/helpers';
import { SELECTED_LANGUAGE } from 'utils/sessionStorage/keys';
import { getUserAttributes } from 'utils/user/getters';

import { changeLanguageAction } from './store/actions';
import { getSelectedLanguageSelector } from './store/selectors';

export const LANGUAGES = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
} as const;

export type Language = keyof typeof LANGUAGES;

export const getDisplayLanguage = (language: Language) => LANGUAGES[language];

const useIsLanguageSupported = (availableLanguages: Array<Language>) =>
  useCallback((language: Language) => availableLanguages.includes(language), [availableLanguages]);

const useChangeLanguage = () => {
  const dispatch = useDispatch();
  return useCallback((language: Language) => dispatch(changeLanguageAction(language)), [dispatch]);
};

const useInitialiseLanguage = (
  isMultipleLanguagesEnabled = false,
  availableLanguages: Array<Language> = [],
) => {
  const changeLanguage = useChangeLanguage();
  const isLanguageSupported = useIsLanguageSupported(availableLanguages);
  const [isLanguageInitialised, setIsLanguageInitialised] = useState(false);

  const savedLanguage = getParsedItemFromSessionStorage(SELECTED_LANGUAGE) as Language;
  const supportedSavedLanguage = isLanguageSupported(savedLanguage) ? savedLanguage : null;

  const browserLanguage = navigator.language.slice(0, 2) as Language;
  const supportedBrowserLanguage = isLanguageSupported(browserLanguage) ? browserLanguage : null;

  const initialLanguage = supportedSavedLanguage ?? supportedBrowserLanguage ?? DEFAULT_LANGUAGE;

  useEffect(() => {
    // set initial language
    if (isMultipleLanguagesEnabled && !isLanguageInitialised) {
      changeLanguage(initialLanguage);
      setIsLanguageInitialised(true);
    }
  }, [changeLanguage, initialLanguage, isLanguageInitialised, isMultipleLanguagesEnabled]);

  if (!isLanguageInitialised) {
    return initialLanguage;
  }
};

export const useLanguages = () => {
  const {
    meta: { enableTranslation = false, translation = {}, languages: availableLanguages = [] },
  } = usePartnerConfig();
  const isMultipleLanguagesEnabled = enableTranslation && availableLanguages.length > 1;
  const initialLanguage = useInitialiseLanguage(isMultipleLanguagesEnabled, availableLanguages);

  const attributes = getUserAttributes();
  const userLanguage = _isEqual(attributes, {})
    ? undefined
    : (attributes as UserAttributes).language;
  const selectedLanguage = useSelector(getSelectedLanguageSelector);
  // current langugage is used here because the savedLanguage inside the store is only updated after the next render so first translations would always be in the default language
  const currentLanguage = initialLanguage ?? selectedLanguage;

  const changeLanguage = useChangeLanguage();
  const isLanguageSupported = useIsLanguageSupported(availableLanguages);

  useEffect(() => {
    // save language to session storage
    if (selectedLanguage) {
      saveObjectToSessionStorage(SELECTED_LANGUAGE, selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    // change language if user language is set
    if (userLanguage && userLanguage !== selectedLanguage && isLanguageSupported(userLanguage)) {
      changeLanguage(userLanguage);
    }
  }, [userLanguage, selectedLanguage, changeLanguage, isLanguageSupported]);

  const partnerTranslation = useMemo(
    () => translation[currentLanguage],
    [currentLanguage, translation],
  );

  return {
    isMultipleLanguagesEnabled,
    availableLanguages,
    partnerTranslation,
    selectedLanguage: currentLanguage,
    changeLanguage,
  };
};
