import React from 'react';

import PageHeading from 'components/PageHeading';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const FinancingNeedHeader = () => {
  const t = useTranslations('pages.financingNeed');
  const heading = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: 'heading.bfsHeader',
    default: 'heading.header',
  });

  const subheading = t('heading.subheader');

  return <PageHeading heading={t(heading, { roleSpecific: true })} subheading={subheading} />;
};

export default FinancingNeedHeader;
