import { IconProps } from '@chakra-ui/react';

import { useFormConfig } from 'config/formConfig/hooks';
import { AssetConfig, Assets } from 'config/formConfig/types';
import { getSvgValue } from 'store/partner/config/icons';

export type AssetType = keyof Assets;

type AssetReturnType = (asset: AssetType, key: string) => React.ElementType<IconProps> | string;

/**
 * For make an asset exchangable on BE, be sure to declare an entry in one of
 * these maps under:
 * {@link store/partner/config/icons}
 * {@link AssetType}
 *
 * Create an entry for a default svg in:
 * {@link store/partner/config/icons/index.ts}
 *
 * *Usage*:
 * ```typescript
 * const assets = useAssets();
 * <Image
 *   src={assets.get(assets.types.INQUIRY, InquiryAssetKeys.OFFER)}
 * />
 * ```
 */
export const useAssets = (): AssetReturnType => {
  const { assets } = useFormConfig();

  /**
   * @param asset AssetType a keyof IPartnerState.partnerConfig.assets
   * @param key   entry of icon map {@link store/partner/config/icons}
   */
  return (assetName: AssetType, typeName: string): React.ElementType<IconProps> | string => {
    const asset = assets[assetName].find((i: AssetConfig) => i.id === typeName);
    return asset ? getSvgValue(asset.icon) : typeName;
  };
};
