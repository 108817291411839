import { translations } from 'new/form/common/types';
import { AssociatedPersonSource } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

export const translateSource = (source: AssociatedPersonSource | undefined, t: any) => {
  const { kycNow, userInput, bankInput } =
    translations.pages.companiesDetails.associatedPerson.new.source.values;

  switch (source) {
    case 'kyc_now':
      return t(kycNow);
    case 'bank_input':
      return t(bankInput);
    case 'user_input':
      return t(userInput);
    default:
      return undefined;
  }
};
