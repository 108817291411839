import { useEffect, useRef } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { scrollToFirstNotValidElement } from 'modules/InquiryFormNew/service';
import { denestErrorObject } from 'utils/helpers';

export type Focusable = {
  name: string;
  focus: () => void;
};

export type FocusableWithDataName = Focusable & { 'data-name'?: string };

export type FocusableInputWithDataName = FocusableWithDataName & HTMLInputElement;

export type GetInputs = () => Focusable[];

const isFocusableInput = (element: any) => !!(element && typeof element.focus === 'function');

const getAllInputs: GetInputs = () => {
  if (typeof document === 'undefined') {
    return [];
  }
  return Array.prototype.slice
    .call(document.forms)
    .reduce(
      (accumulator, form) =>
        accumulator.concat(Array.prototype.slice.call(form).filter(isFocusableInput)),
      [],
    );
};

export const useValidateAndScroll = () => {
  const wasValidated = useRef(false);
  // Get the form errors and denest it
  const { errors, values } = useFormState();
  const { mutators, getRegisteredFields, change } = useForm();
  const inquiryType = useFormConfig().selectedInquiryType;

  // Runs only on the first render for MMV to register all current errors since
  // the validation errors are not visible until a field is changed in MMV
  useEffect(() => {
    if (inquiryType === InquiryType.mmv && !wasValidated.current) {
      change('validationTriggerField', 'value');
      change('validationTriggerField', undefined);
      wasValidated.current = true;
    } else {
      wasValidated.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  if (!errors) return () => {};
  const denestedErrors = denestErrorObject(errors);

  const revalidateFields = mutators.revalidateFields;
  const registeredFieldNames = getRegisteredFields();
  const inputs = getAllInputs();

  return () => {
    revalidateFields(registeredFieldNames);
    scrollToFirstNotValidElement(inputs, denestedErrors);
  };
};
