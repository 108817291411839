import { concatenateKeys } from 'new/form/common/concatenateKeys';
import { getKeyObject, PropertiesToString, SecondLevelKeys } from 'new/form/common/types';
import { StringBoolean } from 'types/General';

export type RoleOptions = 'stockholder' | 'developer' | 'partition' | undefined;

export type FurnishingQuality = 'simple' | 'normal' | 'good' | 'high' | 'luxury' | undefined;
export type QualityCondition = 'simple' | 'medium' | 'good' | undefined;
export type UsageType =
  | 'residential'
  | 'commercial_office'
  | 'care_social'
  | 'retail_parking_garage'
  | 'other'
  | undefined;

export type ObjectType =
  | 'apartment'
  | 'house'
  | 'townhouse'
  | 'apartment_building'
  | 'residential_and_commercial'
  | 'commercial'
  | 'lot'
  | 'expected_lot'
  | undefined;

export type PermitBasis =
  | 'peripherial_development'
  | 'b_plan_area'
  | 'approved_b_plan'
  | 'stock_property'
  | 'repurposing_still_to_request'
  | undefined;

export type PermitStatus =
  | 'critical_with_rest_risk'
  | 'uncritical_no_permit_yet'
  | 'preliminary_inquiry_exists'
  | 'permit_exists'
  | 'permit_unnecessary'
  | undefined;

export type Gender = 'mr' | 'ms' | undefined;

export type CompanySelection = {
  loggedInUserCompanyName: string | undefined;
  selectedCompanyName: string | undefined;
};

export type Company = {
  name: string | undefined;
  address: string | undefined;
  city: string | undefined;
  country: string | undefined;
  state: string | undefined;
  street: string | undefined;
  tradeName: string | undefined;
  zipCode: string | undefined;
  legalForm: string | undefined;
  industry: string | undefined;
  turnoverClass: string | undefined;
  foundingYear: number | undefined;
  foundingMonth: number | undefined;
  alreadyCustomer: boolean | undefined;
  alreadyContactWithBank: boolean | undefined;
  nameOfContactPerson: string | undefined;
};

type FieldTypes = {
  financingNeedPage: {
    roleSection: {
      projectFinancingRole: RoleOptions;
    };
    projectCompositionSection: {
      totalInvestment: number | undefined;
      totalInvestmentInBuyingPhase: number | undefined;
      ownFunds: number | undefined;
      ownFundsInBuyingPhase: number | undefined;
      mezzanineCapital: number | undefined;
      subordinatedCapital: number | undefined;
      ownWork: number | undefined;
      financeWholeProject: boolean | undefined;
      financingAmount: number | undefined;
    };
    objectInformationSection: {
      address: string | undefined;
      zipCode: string | undefined;
      city: string | undefined;
      description: string | undefined;
      lotSize: number | undefined;
      year: Date | undefined;
      yearOfModernization: Date | undefined;
      plannedProjectStart: Date | undefined;
      plannedProjectEnd: Date | undefined;
      furnishingQuality: FurnishingQuality;
      qualityCondition: QualityCondition;
      additionalBathroom: boolean | undefined;
      balcony: boolean | undefined;
      lift: boolean | undefined;
      cellar: boolean | undefined;
      parkingLotsCount: number | undefined;
      parkingLotPrice: number | undefined;
      parkingLotRentNow: number | undefined;
      parkingLotRentFuture: number | undefined;
      garagesCount: number | undefined;
      garagesPrice: number | undefined;
      garageRentNow: number | undefined;
      garageRentFuture: number | undefined;
      futureObjectType: ObjectType;
      futureUsage: Array<{
        typeOfUse: UsageType;
        usageSpace: number | undefined;
        plannedSalesPrice: number | undefined;
        numberOfUnits: number | undefined;
      }>;
      usageDeviation: StringBoolean | undefined;
      currentObjectType: ObjectType;
      currentUsage: Array<{
        typeOfUse: UsageType;
        usageSpace: number | undefined;
        numberOfUnits: number | undefined;
      }>;
      currentTotalRentResidential: number | undefined;
      currentTotalRentCommercial: number | undefined;
      vacancyResidential: number | undefined;
      vacancyCommercial: number | undefined;
      futureTotalRentResidential: number | undefined;
      futureTotalRentCommercial: number | undefined;
      rentalContracts: boolean | undefined;
      rentalIntends: boolean | undefined;
    };
    otherProjectInformation: {
      demolitionNecessary: boolean | undefined;
      heritageProtected: boolean | undefined;
      developmentNecessary: boolean | undefined;
      ownSalesPlanned: boolean | undefined;
      energyPass: boolean | undefined;
      buildingPermit: boolean | undefined;
      permitBasis: PermitBasis;
      permitStatus: PermitStatus;
      notesOnPermit: string | undefined;
    };
  };
  companyDetailPage: {
    companyDetailsSection: CompanySelection;
    completingCompanyDetailsSection: {
      company: Company;
    };
  };
  userProfilePage: {
    contactPersonSection: {
      gender: Gender;
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
      phone: string | undefined;
    };
    passwordSection: {
      password: string | undefined;
      passwordConfirmation: string | undefined;
    };
    termsAndConditionsSection: {
      termsAndConditionsAccepted: boolean | undefined;
    };
  };
  fundingDetailsPage: {
    otherFundingSection: {
      description: string | undefined;
    };
  };
};

export type MittweidaFieldTypes = FieldTypes;

type MittweidaStringFieldTypes = PropertiesToString<FieldTypes>;

export const mittweidaFieldsWithoutParents: MittweidaStringFieldTypes = {
  financingNeedPage: {
    roleSection: {
      projectFinancingRole: 'projectFinancingRole',
    },
    projectCompositionSection: {
      totalInvestment: 'totalInvestment',
      totalInvestmentInBuyingPhase: 'totalInvestmentInBuyingPhase',
      ownFunds: 'ownFunds',
      ownFundsInBuyingPhase: 'ownFundsInBuyingPhase',
      mezzanineCapital: 'mezzanineCapital',
      subordinatedCapital: 'subordinatedCapital',
      ownWork: 'ownWork',
      financeWholeProject: 'financeWholeProject',
      financingAmount: 'financingAmount',
    },
    objectInformationSection: {
      address: 'address',
      zipCode: 'zipCode',
      city: 'city',
      description: 'description',
      lotSize: 'lotSize',
      year: 'year',
      yearOfModernization: 'yearOfModernization',
      plannedProjectStart: 'plannedProjectStart',
      plannedProjectEnd: 'plannedProjectEnd',
      furnishingQuality: 'furnishingQuality',
      qualityCondition: 'qualityCondition',
      additionalBathroom: 'additionalBathroom',
      balcony: 'balcony',
      lift: 'lift',
      cellar: 'cellar',
      parkingLotsCount: 'parkingLotsCount',
      parkingLotPrice: 'parkingLotPrice',
      parkingLotRentNow: 'parkingLotRentNow',
      parkingLotRentFuture: 'parkingLotRentFuture',
      garagesCount: 'garagesCount',
      garagesPrice: 'garagesPrice',
      garageRentNow: 'garageRentNow',
      garageRentFuture: 'garageRentFuture',
      futureObjectType: 'futureObjectType',
      futureUsage: {
        typeOfUse: 'typeOfUse',
        usageSpace: 'usageSpace',
        plannedSalesPrice: 'plannedSalesPrice',
        numberOfUnits: 'numberOfUnits',
      },
      usageDeviation: 'usageDeviation',
      currentObjectType: 'currentObjectType',
      currentUsage: {
        typeOfUse: 'typeOfUse',
        usageSpace: 'usageSpace',
        numberOfUnits: 'numberOfUnits',
      },
      currentTotalRentResidential: 'currentTotalRentResidential',
      currentTotalRentCommercial: 'currentTotalRentCommercial',
      vacancyResidential: 'vacancyResidential',
      vacancyCommercial: 'vacancyCommercial',
      futureTotalRentResidential: 'futureTotalRentResidential',
      futureTotalRentCommercial: 'futureTotalRentCommercial',
      rentalContracts: 'rentalContracts',
      rentalIntends: 'rentalIntends',
    },
    otherProjectInformation: {
      demolitionNecessary: 'demolitionNecessary',
      heritageProtected: 'heritageProtected',
      developmentNecessary: 'developmentNecessary',
      ownSalesPlanned: 'ownSalesPlanned',
      energyPass: 'energyPass',
      buildingPermit: 'buildingPermit',
      permitBasis: 'permitBasis',
      permitStatus: 'permitStatus',
      notesOnPermit: 'notesOnPermit',
    },
  },
  companyDetailPage: {
    companyDetailsSection: {
      loggedInUserCompanyName: 'loggedInUserCompanyName',
      selectedCompanyName: 'selectedCompanyName',
    },
    completingCompanyDetailsSection: {
      company: {
        name: 'name',
        address: 'address',
        city: 'city',
        country: 'country',
        state: 'state',
        street: 'street',
        tradeName: 'tradeName',
        zipCode: 'zipCode',
        legalForm: 'legalForm',
        industry: 'industry',
        turnoverClass: 'turnoverClass',
        foundingYear: 'foundingYear',
        foundingMonth: 'foundingMonth',
        alreadyCustomer: 'alreadyCustomer',
        alreadyContactWithBank: 'alreadyContactWithBank',
        nameOfContactPerson: 'nameOfContactPerson',
      },
    },
  },
  fundingDetailsPage: {
    otherFundingSection: {
      description: 'description',
    },
  },
  userProfilePage: {
    contactPersonSection: {
      gender: 'gender',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phone: 'phone',
    },
    passwordSection: {
      password: 'password',
      passwordConfirmation: 'passwordConfirmation',
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: 'termsAndConditionsAccepted',
    },
  },
};

export const mittweidaFields: MittweidaStringFieldTypes = concatenateKeys(
  mittweidaFieldsWithoutParents,
);

export const mittweidaFieldsWithSectionString = getKeyObject<MittweidaFieldTypes>(
  mittweidaFieldsWithoutParents,
);

export type MittweidaPages = keyof MittweidaFieldTypes;
export type MittweidaSections = SecondLevelKeys<typeof mittweidaFieldsWithoutParents>;
