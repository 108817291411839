import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const LkwIcon = (props: IconProps) => {
  return (
    <Icon
      width="52"
      height="31"
      viewBox="0 0 52 30.78"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M45.53 21.22a3.08 3.08 0 103.07 3.08 3.09 3.09 0 00-3.07-3.08zm0 5.1a2 2 0 112-2 2 2 0 01-2 2zm-11.89-5.1a3.08 3.08 0 103.08 3.08 3.09 3.09 0 00-3.08-3.08zm0 5.1a2 2 0 112-2 2 2 0 01-2 2zm-21.22-5.1a3.08 3.08 0 103.07 3.08 3.09 3.09 0 00-3.07-3.08zm0 5.1a2 2 0 112-2 2 2 0 01-2 2z" />
      <path d="M24.36 0H9.22a4.14 4.14 0 00-4.13 4.13v4.36H3.93A3.93 3.93 0 000 12.42V22.6a2.23 2.23 0 002.23 2.23H6v.28a6.46 6.46 0 0012.83 0v-.28h8.32v.28a6.47 6.47 0 0012.09 2.32l.27-.5.28.5A6.47 6.47 0 0052 24.3V5.62a2.23 2.23 0 00-2.23-2.22h-3.39a2.23 2.23 0 00-2.23 2.22v1.17H24.83V.6M1.06 12.42a2.88 2.88 0 012.87-2.87h2.23V4.13a3.07 3.07 0 013.06-3.07h14.55v18.47h-2.33V3.4H10.72a2.23 2.23 0 00-2.23 2.22v13.54l-.12.1-.19.16-.09.08H1.06zm19.32 1.38v2.33h-1.17A2.23 2.23 0 0017 18.36v1.31l-.52-.41a6.41 6.41 0 00-6.47-1l-.44.17V9.55h10.81v3.19H17v1.06zm0 3.39v2.34H18v-1.17a1.17 1.17 0 011.17-1.17zM9.55 8.49V5.62a1.16 1.16 0 011.17-1.16h9.66v4zM6 23.49v.28H2.23a1.17 1.17 0 01-1.17-1.17v-2h6l-.28.48A6.32 6.32 0 006 23.49zm6.42 6.22a5.41 5.41 0 115.41-5.41 5.42 5.42 0 01-5.41 5.41zm14.8-6.22v.28h-8.35v-.28a6.16 6.16 0 00-.87-2.42l-.27-.48h10.59l-.28.48a6.49 6.49 0 00-.82 2.42zm6.42 6.22a5.41 5.41 0 115.41-5.41 5.41 5.41 0 01-5.41 5.41zm11.89 0a5.41 5.41 0 115.41-5.41 5.42 5.42 0 01-5.41 5.41zm5.41-9l-.56-.71a6.45 6.45 0 00-10.52 1.14l-.28.5-.27-.5a6.46 6.46 0 00-9.94-1.73l-.09.08h-4.45V7.85h7.7l.06.07.23-.06h12.39V5.62a1.16 1.16 0 011.17-1.16h3.39a1.17 1.17 0 011.17 1.16z" />
    </Icon>
  );
};

export default LkwIcon;
