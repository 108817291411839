import {
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__100_TO_250_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_20_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_10_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__20_TO_30_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__250_TO_500_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__30_TO_40_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__40_TO_50_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__500_TO_750_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__50_TO_75_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__75_TO_100_THOUSAND,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__LOSS,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__ZERO,
} from 'modules/Inquiry/Form/formFields';

const getFactoringAnnualNetProfitOrLossClassOptions = (formatMessage) => {
  const euroText = formatMessage({ id: 'currency.euro' });
  const teuroText = formatMessage({ id: 'currency.thousandEuro' });
  const lossText = formatMessage({ id: 'other.loss' });

  return [
    {
      label: `0 ${euroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__ZERO,
    },
    {
      label: `1 ${euroText} - 10 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__1_TO_10_THOUSAND,
    },
    {
      label: `10 ${teuroText} - 20 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__10_TO_20_THOUSAND,
    },
    {
      label: `20 ${teuroText} - 30 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__20_TO_30_THOUSAND,
    },
    {
      label: `30 ${teuroText} - 40 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__30_TO_40_THOUSAND,
    },
    {
      label: `40 ${teuroText} - 50 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__40_TO_50_THOUSAND,
    },
    {
      label: `50 ${teuroText} - 75 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__50_TO_75_THOUSAND,
    },
    {
      label: `75 ${teuroText} - 100 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__75_TO_100_THOUSAND,
    },
    {
      label: `100 ${teuroText} - 250 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__100_TO_250_THOUSAND,
    },
    {
      label: `250 ${teuroText} - 550 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__250_TO_500_THOUSAND,
    },
    {
      label: `500 ${teuroText} - 750 ${teuroText}`,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__500_TO_750_THOUSAND,
    },
    {
      label: lossText,
      value: FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS__LOSS,
    },
  ];
};

export default getFactoringAnnualNetProfitOrLossClassOptions;
