import React, { useState } from 'react';

import FormSection from 'components/FormSection';
import SectionHeading from 'components/Text/SectionHeading';
import { EXCLUDED_BANKS } from 'modules/Inquiry/Form/formFields';
import { MultiSelectWithField } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiSelect';
import { useFetchBankCodes } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/useFetchBankCodes';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useFieldValue } from 'store/hooks/useFormValues';
import { useTranslations } from 'utils/hooks/useTranslations';

interface BankOption {
  key: string;
  value: string;
  label: string;
}

interface BankSearchProps {
  sectionNumber: number;
}

const BankSearch = ({ sectionNumber }: BankSearchProps) => {
  const { banks, changeBankSearch, isLoading, fetchNextPage } = useFetchBankCodes();
  const t = useTranslations();
  const { optional } = useFieldValidators();
  const excludedBanks = useFieldValue<BankOption[]>(EXCLUDED_BANKS, []);

  const [selectedBanks, setSelectedBanks] = useState(excludedBanks);
  const selectedOptionKeys = selectedBanks.map((bank) => bank.key);

  const selectBank = (newBank: BankOption) => {
    setSelectedBanks((sBanks) => [...sBanks, newBank]);
  };

  const unselectBank = (bankCodeToRemove: BankOption['value']) => {
    setSelectedBanks((sBanks) => sBanks.filter((bank) => bank.value !== bankCodeToRemove));
  };

  const toggleBank = (option: BankOption) => {
    if (selectedOptionKeys.includes(option.key)) {
      unselectBank(option.value);
    } else {
      selectBank(option);
    }
  };

  return (
    <FormSection
      name={FormSections.excludedBanks}
      sectionNumber={sectionNumber}
      title={t('pages.requestDetails.sections.bankSearch.title')}
    >
      <SectionHeading>
        {t('pages.requestDetails.sections.bankSearch.fields.excludedBanks.caption')}
      </SectionHeading>

      <MultiSelectWithField
        name={EXCLUDED_BANKS}
        validate={optional}
        optional
        options={banks}
        selectedOptions={selectedBanks}
        isLoading={isLoading}
        onInputChange={changeBankSearch}
        placeholder={t('pages.requestDetails.sections.bankSearch.fields.excludedBanks.placeholder')}
        onRemove={unselectBank}
        onSelect={toggleBank}
        parseLabelForOption={(option: BankOption) => `${option.value} - ${option.label}`}
        onScrollEnd={fetchNextPage}
        noCaption
        data-testid={'select-field-' + EXCLUDED_BANKS}
      />
    </FormSection>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_HIDDEN_EXCLUDED_BANKS_SECTION]: () => null,
  default: BankSearch,
});
