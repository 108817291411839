import React from 'react';

import { IconProps } from '@chakra-ui/react';

import { PurposeOptions } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { BalconyIcon } from 'theme/components/Icon/Purposes/hausbank/BalconyIcon';
import { FacadeIcon } from 'theme/components/Icon/Purposes/hausbank/FacadeIcon';
import { HeatingIcon } from 'theme/components/Icon/Purposes/hausbank/HeatingIcon';
import { LineIcon } from 'theme/components/Icon/Purposes/hausbank/LineIcon';
import { RoofIcon } from 'theme/components/Icon/Purposes/hausbank/RoofIcon';
import { UndergroundGarageIcon } from 'theme/components/Icon/Purposes/hausbank/UndergroundGarageIcon';
import { WindowIcon } from 'theme/components/Icon/Purposes/hausbank/WindowIcon';
import OtherIcon from 'theme/components/Icon/Purposes/OtherIcon';

export type HausbankPurposeKinds = {
  id: PurposeOptions;
  translationString: string;
  icon: React.FunctionComponent<IconProps>;
  order: number;
};

const { other, balcony, facade, heating, line, roof, undergroundGarage, window } =
  translations.inquiryType.hausbank.pages.financingNeed.sections.purpose.purposeKinds;

export const hausbankPurposeKinds: HausbankPurposeKinds[] = [
  {
    id: 'balcony',
    translationString: balcony,
    icon: BalconyIcon,
    order: 1,
  },
  {
    id: 'other',
    translationString: other,
    icon: OtherIcon,
    order: 8,
  },
  {
    id: 'facade',
    translationString: facade,
    icon: FacadeIcon,
    order: 0,
  },
  {
    id: 'heating',
    translationString: heating,
    icon: HeatingIcon,
    order: 5,
  },
  {
    id: 'line',
    translationString: line,
    icon: LineIcon,
    order: 6,
  },
  {
    id: 'roof',
    translationString: roof,
    icon: RoofIcon,
    order: 4,
  },
  {
    id: 'underground_garage',
    translationString: undergroundGarage,
    icon: UndergroundGarageIcon,
    order: 3,
  },
  {
    id: 'window',
    translationString: window,
    icon: WindowIcon,
    order: 2,
  },
];
