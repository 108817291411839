import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { MEZZANINE_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const MezzanineDescription = () => {
  const t = useTranslations();
  return (
    <TextAreaWithField
      name={MEZZANINE_DESCRIPTION}
      captionTooltip={t(
        'pages.requestDetails.sections.mezzanine.fields.mezzanineDescription.tooltip',
      )}
    />
  );
};

export default MezzanineDescription;
