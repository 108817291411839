import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import {
  ALREADY_FACTORING,
  DOWN_PAYMENT,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  PAYMENT_TERM,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  TRADE_CREDIT_INSURANCE,
} from '../../formFields';

export const validateYourBusinessSection = validateSectionFields([
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  PAYMENT_TERM,
  ALREADY_FACTORING,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  DOWN_PAYMENT,
  TRADE_CREDIT_INSURANCE,
]);

export const YourBusinessSection = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness');
  const tooltips = useTranslations(
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields',
  );

  return (
    <FormSection title={t('title')} sectionNumber={2}>
      <FormRow>
        <TextComponent color="text.tertiary">{t('introduction')}</TextComponent>
      </FormRow>
      <FormRow>
        <InputWithField
          name={NUMBER_OF_CUSTOMERS}
          captionTooltip={tooltips('numberOfCustomers.tooltip')}
          sideSymbol={() => <>{t('fields.numberOfCustomers.sideSymbol')}</>}
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name={NUMBER_OF_INVOICES}
          captionTooltip={tooltips('numberOfInvoices.tooltip')}
          sideSymbol={() => <>{t('fields.numberOfInvoices.sideSymbol')}</>}
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name={PAYMENT_TERM}
          captionTooltip={tooltips('paymentTerm.tooltip')}
          sideSymbol={() => <>{t('fields.paymentTerm.sideSymbol')}</>}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={ALREADY_FACTORING}
          tooltip={tooltips('alreadyFactoring.tooltip')}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={RECEIVABLES_ASSIGNED_ELSEWHERE}
          tooltip={tooltips('receivablesAssignedElsewhere.tooltip')}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={DOWN_PAYMENT} tooltip={tooltips('downPayment.tooltip')} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={TRADE_CREDIT_INSURANCE}
          tooltip={tooltips('tradeCreditInsurance.tooltip')}
        />
      </FormRow>
    </FormSection>
  );
};
