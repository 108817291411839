import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { usePartnerConfig } from 'config/partner/hooks';
import { HAS_BENEFICIARY_OWNER } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const HasBeneficiaryRadio = () => {
  const t = useTranslations(
    'pages.peopleDetails.sections.beneficiaryOwners.fields.hasBeneficiaryOwnerRadio',
  );
  const {
    details: { id: partnerName },
  } = usePartnerConfig();

  const tooltip = t('tooltipCaption', { partnerName, br: '\n\n' });

  return <YesNoRadioGroupWithField name={HAS_BENEFICIARY_OWNER} tooltip={tooltip} />;
};

export default HasBeneficiaryRadio;
