import { AssetConfig } from 'config/formConfig/types';
import {
  PURPOSE_KIND__DEBT_RESCHEDULING,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__CREFO_FACTORING,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
} from 'modules/Inquiry/Form/formFields';

const PurposeIcons: AssetConfig[] = [
  {
    id: PURPOSE_KIND__REAL_ESTATE,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.realEstate',
    icon: 'realEstateIcon',
  },
  {
    // TODO: - change title and icon
    id: PURPOSE_KIND__KFINANZ,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.realEstate',
    icon: 'realEstateIcon',
  },
  {
    // TODO: - change title and icon
    id: PURPOSE_KIND__ENERGY_EFFICIENCY,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.energy_efficiency',
    icon: 'realEstateIcon',
  },
  {
    id: PURPOSE_KIND__GOODS,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.goods',
    icon: 'goodsIcon',
  },
  {
    id: PURPOSE_KIND__VEHICLE,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.vehicle',
    icon: 'vehicleIcon',
  },
  {
    id: PURPOSE_KIND__DEBT_RESCHEDULING,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.debtRescheduling',
    icon: 'debtReschedulingIcon',
  },
  {
    id: PURPOSE_KIND__MACHINE,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.machine',
    icon: 'machineIcon',
  },
  {
    id: PURPOSE_KIND__OTHER,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.other',
    icon: 'otherIcon',
  },
  {
    id: PURPOSE_KIND__GUARANTY,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.guaranty',
    icon: 'guarantyIcon',
  },
  {
    id: PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.overdraftLiquidity',
    icon: 'overdraftLiquidity',
  },
  {
    id: PURPOSE_KIND__CREFO_FACTORING,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.other',
    icon: 'otherIcon',
  },
  {
    id: PURPOSE_KIND__OFFICE_EQUIPMENT,
    titleId: 'pages.financingNeed.sections.purpose.fields.purposeKind.options.officeEquipment',
    icon: 'officeEquipmentIcon',
  },
];

export default PurposeIcons;
