import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { LEASING_AMORTISATION_KIND } from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useTranslations } from 'utils/hooks/useTranslations';

const LeasingAmortisationKind = () => {
  const options = useInquirySelectOptions(LEASING_AMORTISATION_KIND);
  const t = useTranslations('pages.requestDetails.sections.leasing.fields.leasingAmortisationKind');

  return (
    <SelectWithField
      name={LEASING_AMORTISATION_KIND}
      options={options}
      tooltip={t('tooltip')}
      data-testid={'select-field-' + LEASING_AMORTISATION_KIND}
    />
  );
};

export default LeasingAmortisationKind;
