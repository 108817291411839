import {
  YEARLY_MILEAGE,
  LEASING_INSTALLMENT,
  BENEFICIARY_COMPANY_SHARE,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const leaseplanUnits = {
  [YEARLY_MILEAGE]: FIELD_UNIT_TYPE.KILOMETERS,
  [LEASING_INSTALLMENT]: FIELD_UNIT_TYPE.CURRENCY_PER_MONTH,
  [BENEFICIARY_COMPANY_SHARE]: FIELD_UNIT_TYPE.PERCENTAGE,
};
