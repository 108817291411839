import React from 'react';

import env from '@beam-australia/react-env';

import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import { LogoImage, LogoLink } from 'modules/Header/PartnerLogo/styles';
import { PARTNER_LOGO } from 'modules/Inquiry/Form/formFields';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { EnvironmentVariable } from 'utils/environment';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';
import { hasPermission as hasPermissionUtility, isLoggedInUser } from 'utils/user/conditionals';

import { InquiryType } from '../../Inquiry/Inquiry.type';

const useHomeUrl = () => {
  const isOperationPortal = useIsUnderOperationPortalPath();
  const isLoggedIn = isLoggedInUser();
  const { selectedInquiryType } = useFormConfig();
  const hasPermission = hasPermissionUtility(selectedInquiryType || InquiryType.default);

  if (isOperationPortal) {
    if (isLoggedIn) {
      return paths.operation.inquiryList;
    }

    return paths.operation.login;
  }

  if (isLoggedIn) {
    return paths.customer.inquiryList;
  }
  return hasPermission(PERMISSIONS.INQUIRY.CREATE) ? paths.inquiry.new.root : paths.customer.login;
};

const PartnerLogo = () => {
  const isScrolledToTop = useIsScrolledToTop();
  const {
    meta: { logoUrl },
  } = usePartnerConfig();

  const homeUrl = useHomeUrl();

  if (!logoUrl) {
    return null;
  }

  return (
    <LogoLink to={homeUrl}>
      <LogoImage
        src={`${env(EnvironmentVariable.LOGO_STORAGE_URL)}${logoUrl}`}
        alt="logo"
        biggerHeader={isScrolledToTop}
        data-testid={PARTNER_LOGO}
      />
    </LogoLink>
  );
};

export default PartnerLogo;
