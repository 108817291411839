import React from 'react';

import { Form } from 'react-final-form';
// import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import FormRow from 'components/FormRow';
import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import PageTitle from 'components/PageTitle/PageTitle';
import SectionHeading from 'components/Text/SectionHeading';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import FormPageLayout from 'modules/FormPage/FormPageLayout/FormPageLayout';
import {
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
  USER_PROFILE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import PasswordFields from 'modules/Inquiry/Form/Steps/UserProfile/Password/Password';
import TermAndConditionsField from 'pages/inquiryFlow/CompanyDetails/sections/TermAndConditionsField';
import { RegistrationService } from 'pages/RegistrationProcess/registration.service';
import RegistrationHeader from 'pages/RegistrationProcess/RegistrationForm/RegistrationHeader';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { ButtonComponent } from 'theme/components/Button';
import PhoneNumberField from 'ui/common/phoneNumberField';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import DebounceEmailField from './DebounceEmailField';

const RegistrationProcess: React.FC = () => {
  const t = useTranslations();
  const { makeCall, isPending: isLoading } = useDispatchApiCall();
  const history = useHistory();
  const {
    details: { id: partnerId },
  } = usePartnerConfig();

  const registerUser = async (values: any) => {
    const { error } = await makeCall(RegistrationService.registerUserInApi(values, partnerId!));

    if (!error) {
      history.push(paths.registrationSuccess);
    }
  };

  return (
    <Form onSubmit={registerUser}>
      {({ valid, handleSubmit }) => (
        <FormPageLayout
          withBadges={false}
          actionButtons={
            <ButtonComponent
              disabled={!valid}
              isLoading={isLoading}
              onClick={handleSubmit}
              type="submit"
            >
              {t('pages.registration.submit')}
            </ButtonComponent>
          }
        >
          <RegistrationHeader />
          <PageTitle title={t('pages.registration.title')} />

          <FormSectionLayout
            sectionNumber={1}
            title={t('pages.registration.sections.userData.title')}
          >
            <FormRow>
              <GenderWithField name={COMPANY_DETAILS_GENDER} />
              <InputWithField name={COMPANY_DETAILS_FIRST_NAME} />
              <InputWithField name={COMPANY_DETAILS_LAST_NAME} />
            </FormRow>
            <FormRow separator>
              <PhoneNumberField name={USER_PROFILE_PHONE_NUMBER} />
            </FormRow>

            <FormRow>
              <DebounceEmailField />
            </FormRow>
          </FormSectionLayout>

          <FormSectionLayout
            sectionNumber={2}
            title={t('pages.registration.sections.password.title')}
          >
            <SectionHeading>
              {t('pages.userProfile.sections.completingContactData.heading')}
            </SectionHeading>
            <PasswordFields />
          </FormSectionLayout>
          <FormSectionLayout
            sectionNumber={2}
            title={t('pages.registration.sections.password.consent')}
          >
            <TermAndConditionsField sectionNumber={3} />
          </FormSectionLayout>
        </FormPageLayout>
      )}
    </Form>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_REGISTRATION_ENABLED]: RegistrationProcess,
  default: () => <Redirect to={paths.financingNeed} />,
});
