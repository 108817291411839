import routePaths from 'constants/paths';
import { IInquiryPath } from 'modules/Inquiry/inquiryNavigation/stepSegments';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { EDIT_INQUIRY_CALLER_COMPONENT_NAME } from 'store/applicationConfig/types';
import { getParsedItemFromSessionStorage } from 'utils/sessionStorage/helpers';
import { APPLICATION_CONFIG } from 'utils/sessionStorage/keys';

const getPathsForUser = (
  paths: IInquiryPath[],
  { isEditing, isCustomer, isAgent }: { isEditing: boolean; isCustomer: boolean; isAgent: boolean },
) => {
  if (!isEditing) {
    return paths.map((pathSegment) => pathSegment.paths.newInquiry);
  }
  if (isCustomer) {
    return paths.map((pathSegment) => pathSegment.paths.customerEdit);
  }
  if (isAgent) {
    return paths.map((pathSegment) => pathSegment.paths.agentEdit);
  }
  return [];
};

const getPathToNextStep = (paths: string[], currentStepIndex: number) => {
  const canGetNextStep = paths.length > currentStepIndex + 1;
  if (canGetNextStep) {
    return paths[currentStepIndex + 1];
  }
  return null;
};

const getPathToPreviousStep = (paths: string[], currentStepIndex: number) => {
  return currentStepIndex > 0 ? paths[currentStepIndex - 1] : null;
};

const getPathToStep = (paths: string[], targetStepIndex: number) => {
  if (targetStepIndex > -1) {
    return paths[targetStepIndex];
  }
  return null;
};

const getPathToCancel = (
  userPermissions: { isCustomer: boolean; isAgent: boolean },
  inquiryId?: string | null,
) => {
  const { isCustomer, isAgent } = userPermissions;
  const applicationConfig = getParsedItemFromSessionStorage(APPLICATION_CONFIG);

  if (!inquiryId) {
    return null;
  }

  if (isCustomer) {
    return routePaths.customer.inquiryDetails.edit.cancel.replace(':id', inquiryId);
  }
  if (isAgent) {
    if (
      applicationConfig &&
      applicationConfig[EDIT_INQUIRY_CALLER_COMPONENT_NAME] ===
        PROFITABILITY_RECORD_TYPE.PROPERTY_DETAILS
    ) {
      return routePaths.operation.inquiryDetails.planningEvaluation.profitabilityCalculation(
        inquiryId,
      );
    }
    return routePaths.operation.inquiryDetails.edit.cancel.replace(':id', inquiryId);
  }
  throw new Error('Can not go further');
};

export const inquiryStepService = {
  getPathToStep,
  getPathToCancel,
  getPathToNextStep,
  getPathToPreviousStep,
  getPathsForUser,
};
