import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const MsFemaleIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 .5A12.13 12.13 0 000 12.75v4.75a3 3 0 003 3h3.55a8.43 8.43 0 0011 0H21a3 3 0 003-3v-4.75A12.13 12.13 0 0012 .5zm.06 20.05a5.81 5.81 0 01-6-5.33L6 12.8v-.73a1 1 0 011-.88h1.18a2 2 0 00.82-.25 2 2 0 001 .25h7.22a1 1 0 01.88 1v3.13a5.81 5.81 0 01-6.04 5.23zM22 17.5a1 1 0 01-1 1h-1.81a7 7 0 00.91-3.45v-.52-2.34a3 3 0 00-3-3H10V7.5a1 1 0 00-2 0v1.69H7a3 3 0 00-3 3v3.09a7.09 7.09 0 00.9 3.22H3a1 1 0 01-1-1v-4.75A10.12 10.12 0 0112 2.5a10.12 10.12 0 0110 10.25z" />
      <path d="M13.09 16.91L13 17a1.66 1.66 0 01-.9.26 1.6 1.6 0 01-1-.33 1 1 0 00-1.4.21 1 1 0 00.21 1.4 3.67 3.67 0 004.43-.05 1 1 0 00-1.22-1.58z" />
      <circle cx="15" cy="13.5" r="1" />
      <circle cx="9" cy="13.5" r="1" />
    </Icon>
  );
};

export default MsFemaleIcon;
