import React from 'react';

import FormRow from 'components/FormRow';
import { TextAreaWithField } from 'components/TextArea';
import {
  QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER,
  QUICK_CHECK_INITIATOR,
  QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS,
  QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const InformationAboutCustomerSection = () => {
  const t = useTranslations(
    'pages.planningEvaluation.quickCheck.sections.informationAboutCustomer',
  );

  const fields = [
    {
      name: QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS,
      placeholder: t('fields.informationOnPreviousBusinessRelatioship.placeholder'),
    },
    {
      name: QUICK_CHECK_INITIATOR,
      placeholder: t('fields.informationAboutInitiator.placeholder'),
    },
    {
      name: QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE,
      placeholder: t('fields.statusOfCurrentProjectFinancingInhouse.placeholder'),
    },
    {
      name: QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER,
      placeholder: t('fields.financialCurcumstancesOfCustomer.placeholder'),
    },
  ];

  return (
    <>
      {fields.map(({ name, placeholder }) => (
        <FormRow key={name}>
          <TextAreaWithField name={name} placeholder={placeholder} data-testid={name} />
        </FormRow>
      ))}
    </>
  );
};

export default InformationAboutCustomerSection;
