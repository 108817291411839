import styled from 'styled-components/macro';

import { TextWrapper } from 'components/StaticField/styles';
import { InputCaption } from 'components/Text';

export const StyledSummaryRows = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3.5rem;
  grid-column-gap: 1.5rem;
  align-items: flex-start;
  margin-bottom: 3.5rem;

  & + & {
    padding-top: 3.5rem;
    border-top: 1px solid ${(props) => props.theme.colors.border.lightGrey};
  }

  ${TextWrapper} {
    ${InputCaption} {
      align-items: flex-start;
    }
  }
`;
