import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

const StyledLogoutButton = styled.button`
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.colors.text.secondary};

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default StyledLogoutButton;
