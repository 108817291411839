import * as React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { SummaryPageField } from 'new/summary/hooks/useBuildFieldsForSummary';

type Props = {
  summaryPageField: SummaryPageField;
};
export const SummaryField = ({ summaryPageField }: Props) => {
  return (
    <Flex flexDirection={'column'} gap={3}>
      <Box color={'text.tertiary'}>{summaryPageField.caption}</Box>
      <Box data-testId={summaryPageField.testId} color={'text.primary'}>
        {summaryPageField.text}
      </Box>
    </Flex>
  );
};
