import React from 'react';

import RadioGroupFullWidthWrapper from 'components/RadioGroupFullWidth/RadioGroupFullWidthWrapper';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { FACTORING_ALREADY_IN_USE } from 'modules/Inquiry/Form/formFields';

const FactoringAlreadyInUse = () => {
  return (
    <RadioGroupFullWidthWrapper>
      <YesNoRadioGroupWithField name={FACTORING_ALREADY_IN_USE} />
    </RadioGroupFullWidthWrapper>
  );
};

export default FactoringAlreadyInUse;
