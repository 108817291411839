import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const BfsVehicleIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 86 68" aria-hidden="true" fill="currentColor" {...props}>
      <path
        d="M60.7974684,60 L25.2025316,60 C24.5383918,60 24,59.5522847 24,59 C24,58.4477153 24.5383918,58 25.2025316,58 L60.7974684,58 C61.4616082,58 62,58.4477153 62,59 C62,59.5522847 61.4616082,60 60.7974684,60 Z"
        id="Path"
      />
      <path
        d="M82.3142857,60 L73.96,60 C73.2814787,60 72.7314286,59.4592203 72.7314286,58.7921348 C72.7314286,58.1250493 73.2814787,57.5842697 73.96,57.5842697 L82.3142857,57.5842697 C82.992807,57.5842697 83.5428571,57.04349 83.5428571,56.3764045 L83.5428571,43.7662921 C83.5357328,42.0650905 82.468829,40.5420439 80.8547429,39.9289045 L76.0633143,38.1303933 C75.8770296,38.0603392 75.7108933,37.9467501 75.5792571,37.7994382 L59.9026857,20.2733146 C59.4153648,19.7283755 58.7131011,19.4159448 57.9750571,19.4157303 L3.68571429,19.4157303 C3.00719302,19.4157303 2.45714286,19.95651 2.45714286,20.6235955 L2.45714286,56.3764045 C2.45714286,57.04349 3.00719302,57.5842697 3.68571429,57.5842697 L12.04,57.5842697 C12.7185213,57.5842697 13.2685714,58.1250493 13.2685714,58.7921348 C13.2685714,59.4592203 12.7185213,60 12.04,60 L3.68571429,60 C1.65015049,60 0,58.377661 0,56.3764045 L0,20.6235955 C0,18.622339 1.65015049,17 3.68571429,17 L57.9762857,17 C59.4204075,17.0016593 60.7943474,17.6126129 61.7492286,18.6777247 L77.2292286,35.9791854 L81.7368571,37.6701966 C84.2985309,38.6460634 85.9903977,41.065354 86,43.7662921 L86,56.3764045 C86,58.377661 84.3498495,60 82.3142857,60 L82.3142857,60 Z"
        id="Path"
      />
      <path
        d="M68,68 C63.581722,68 60,64.418278 60,60 C60,55.581722 63.581722,52 68,52 C72.418278,52 76,55.581722 76,60 C75.9936041,64.415626 72.415626,67.9936041 68,68 L68,68 Z M68,54.5806452 C65.006973,54.5806452 62.5806452,57.006973 62.5806452,60 C62.5806452,62.993027 65.006973,65.4193548 68,65.4193548 C70.993027,65.4193548 73.4193548,62.993027 73.4193548,60 C73.4172247,57.0070197 70.992977,54.5804631 68,54.5754839 L68,54.5806452 Z"
        id="Shape"
      />
      <path
        d="M18,68 C13.581722,68 10,64.418278 10,60 C10,55.581722 13.581722,52 18,52 C22.418278,52 26,55.581722 26,60 C25.9936041,64.415626 22.415626,67.9936041 18,68 L18,68 Z M18,54.5806452 C15.006973,54.5806452 12.5806452,57.006973 12.5806452,60 C12.5806452,62.993027 15.006973,65.4193548 18,65.4193548 C20.993027,65.4193548 23.4193548,62.993027 23.4193548,60 C23.4172247,57.0070197 20.992977,54.5804631 18,54.5754839 L18,54.5806452 Z"
        id="Shape"
      />
      <path
        d="M67.7955454,39 L50.204148,39 C49.5391154,39 49,38.4260493 49,37.7180454 L49,23.2819546 C49,22.5739506 49.5391154,22 50.204148,22 L56.0177743,22 C56.3666942,22.0002337 56.6983634,22.1615841 56.926906,22.4422743 L68.7046771,36.8770831 C69.0141827,37.2561945 69.0872056,37.7928139 68.8914821,38.2498484 C68.6957587,38.7068829 68.2673527,39 67.7955454,39 L67.7955454,39 Z M51.4082959,36.4360908 L65.154849,36.4360908 L55.4686828,24.5639092 L51.4082959,24.5639092 L51.4082959,36.4360908 Z"
        id="Shape"
      />
      <path
        d="M26.427027,44 L20.572973,44 C19.8977213,44 19.3503218,43.4524947 19.3503218,42.7771127 L19.3503218,38.6523138 L15.2226512,38.6523138 C14.5473996,38.6523138 14,38.1048085 14,37.4294265 L14,31.575465 C14,30.900083 14.5473996,30.3525777 15.2226512,30.3525777 L19.3503218,30.3525777 L19.3503218,26.2228873 C19.3503218,25.5475053 19.8977213,25 20.572973,25 L26.427027,25 C27.1022787,25 27.6496782,25.5475053 27.6496782,26.2228873 L27.6496782,30.3525777 L31.7773488,30.3525777 C32.4526004,30.3525777 33,30.900083 33,31.575465 L33,37.4294265 C33,38.1048085 32.4526004,38.6523138 31.7773488,38.6523138 L27.6496782,38.6523138 L27.6496782,42.7771127 C27.6496782,43.4524947 27.1022787,44 26.427027,44 Z M21.7956242,41.5542254 L25.2043758,41.5542254 L25.2043758,37.4294265 C25.2043758,36.7540445 25.7517754,36.2065392 26.427027,36.2065392 L30.5546976,36.2065392 L30.5546976,32.7983523 L26.427027,32.7983523 C25.7517754,32.7983523 25.2043758,32.250847 25.2043758,31.575465 L25.2043758,27.4457746 L21.7956242,27.4457746 L21.7956242,31.575465 C21.7956242,32.250847 21.2482246,32.7983523 20.572973,32.7983523 L16.4453024,32.7983523 L16.4453024,36.2065392 L20.572973,36.2065392 C21.2482246,36.2065392 21.7956242,36.7540445 21.7956242,37.4294265 L21.7956242,41.5542254 Z"
        id="Shape"
      />
      <path
        d="M52.8380408,20 L42.1619592,20 C41.5202269,20 41,19.4227092 41,18.7105849 L41,16.2129879 C41,12.2293647 43.9101491,9 47.5,9 C51.0898509,9 54,12.2293647 54,16.2129879 L54,18.7105849 C54,19.4227092 53.4797731,20 52.8380408,20 L52.8380408,20 Z M43.3239185,17.4211699 L51.6760815,17.4211699 L51.6760815,16.2129879 C51.6760815,13.6536133 49.8063861,11.5788301 47.5,11.5788301 C45.1936139,11.5788301 43.3239185,13.6536133 43.3239185,16.2129879 L43.3239185,17.4211699 Z"
        id="Shape"
      />
      <path
        d="M47,5 C46.4477153,5 46,4.36150135 46,3.57387336 L46,1.42612664 C46,0.638498645 46.4477153,0 47,0 C47.5522847,0 48,0.638498645 48,1.42612664 L48,3.57387336 C48,4.36150135 47.5522847,5 47,5 Z"
        id="Path"
      />
      <path
        d="M53.3474714,8 C52.8364845,8.00209194 52.3685638,7.74082348 52.1407249,7.32618778 C51.9128861,6.91155208 51.96471,6.41558109 52.2743765,6.0471009 L53.5902591,4.47968454 C54.0391944,3.94511086 54.8811672,3.84167124 55.4708578,4.24864568 C56.0605484,4.65562011 56.1746532,5.41889567 55.7257178,5.95346935 L54.4165421,7.52210171 C54.1621203,7.8240664 53.7666184,8.00085985 53.3474714,8 L53.3474714,8 Z"
        id="Path"
      />
      <path
        d="M39.6606096,7.99756915 C39.2402432,7.99929546 38.8433467,7.8219968 38.5886063,7.51869192 L37.2727223,5.95200983 C36.9830862,5.60571194 36.9191356,5.14556605 37.10496,4.74490466 C37.2907844,4.34424327 37.6981527,4.06393638 38.1736133,4.00957288 C38.6490738,3.95520938 39.1203927,4.13504838 39.4100289,4.48134627 L40.7259129,6.04802836 C41.0345244,6.41546464 41.0868338,6.90981483 40.8609065,7.32378489 C40.6349792,7.73775496 40.1698693,7.99978541 39.6606096,8 L39.6606096,7.99756915 Z"
        id="Path"
      />
      <path
        d="M57.3184534,11.9995762 C56.6388311,11.9996286 56.0705376,11.6656385 56.0060227,11.2282515 C55.9415078,10.7908645 56.4019195,10.3934564 57.0693086,10.3104676 L59.4315706,10.015598 C60.1465023,9.92640591 60.8379101,10.2287873 60.975873,10.6909858 C61.1138359,11.1531842 60.6461102,11.6001745 59.9311784,11.6893666 L57.5689165,11.9884973 C57.4862167,11.9976219 57.4023048,12.0013336 57.3184534,11.9995762 L57.3184534,11.9995762 Z"
        id="Path"
      />
      <path
        d="M35.6828634,12 C35.5983534,12.0003349 35.5140026,11.9951947 35.4310822,11.9846399 L33.0688211,11.6897103 C32.3538897,11.6005001 31.8861642,11.1534188 32.024127,10.6911264 C32.1620899,10.2288339 32.8534973,9.92639095 33.5684288,10.0156011 L35.9306899,10.3105308 C36.597832,10.3935049 37.0581932,10.7907426 36.9940404,11.2280774 C36.9298875,11.6654123 36.3622336,11.999597 35.6828634,12 L35.6828634,12 Z"
        id="Path"
      />
    </Icon>
  );
};

export default BfsVehicleIcon;
