import React, { PropsWithChildren } from 'react';

import { StyledOverviewFields, Subtitle } from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyOverviewContact: React.FC<PropsWithChildren> = ({ children }) => {
  const t = useTranslations();

  return (
    <>
      <Subtitle>{t('pages.companiesDetails.overview.contact')}</Subtitle>
      <StyledOverviewFields>{children}</StyledOverviewFields>
    </>
  );
};

export default CompanyOverviewContact;
