import { AnySchema } from '@hapi/joi';

import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNER,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
} from 'modules/Inquiry/Form/formFields';
import { beneficiaryOwnerFieldValidations } from 'modules/Inquiry/inquiryFieldValidation/beneficiaryDetails.fieldValidationMap';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

const isFictitiousBeneficialOwnerSchema = (schema: AnySchema) =>
  fieldValidators
    .object({
      customerDataPage: fieldValidators
        .object({
          beneficialOwnerSection: fieldValidators.object({
            isFictitiousBeneficialOwner: schema.concat(fieldValidators.stringBoolean()),
          }),
        })
        .unknown(),
    })
    .unknown();

const selfBeneficiarySchema = fieldValidators
  .object({
    [BENEFICIARY_PRIVATE_CITY]: fieldValidators.string().exist(),
    [BENEFICIARY_PRIVATE_COUNTRY]:
      beneficiaryOwnerFieldValidations[BENEFICIARY_PRIVATE_COUNTRY].exist(),
    [BENEFICIARY_BIRTHDAY]: beneficiaryOwnerFieldValidations[BENEFICIARY_BIRTHDAY].exist(),
  })
  .unknown();

const beneficiaryOwnerSchema = selfBeneficiarySchema.concat(
  fieldValidators
    .object({
      [BENEFICIARY_FIRST_NAME]: beneficiaryOwnerFieldValidations[BENEFICIARY_FIRST_NAME].exist(),
      [BENEFICIARY_LAST_NAME]: beneficiaryOwnerFieldValidations[BENEFICIARY_LAST_NAME].exist(),
    })
    .unknown(),
);
export const mmvBeneficialOwnerValidationMap = fieldValidators
  .object({
    [BENEFICIARY_OWNER]: fieldValidators.array().min(0).items(beneficiaryOwnerSchema).optional(),
  })
  .concat(isFictitiousBeneficialOwnerSchema(fieldValidators.exist()))
  .unknown();
