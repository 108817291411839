import { RLLComputedProfitabilityTopBoxes } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

export const getData = (
  t: ReturnType<typeof useTranslations>,
  topBoxes: RLLComputedProfitabilityTopBoxes,
) => {
  const translate = (key: string) =>
    t(`pages.planningEvaluation.profitabilityCalculation.boxes.${key}`);
  const translateUnit = (key: string) => t(`other.${key}`);

  return [
    {
      name: translate('totalSpace'),
      value: formatDecimal(topBoxes.totalSpace, { unit: translateUnit('m2') }),
    },
    {
      name: translate('totalCosts'),
      value: formatDecimal(topBoxes.totalCosts, { unit: translateUnit('defaultCurrency') }),
    },
    {
      name: translate('financingCosts'),
      value: formatDecimal(topBoxes.financingCosts, { unit: translateUnit('defaultCurrency') }),
    },
    {
      name: translate('financingAmount'),
      value: formatDecimal(topBoxes.financingAmount, { unit: translateUnit('defaultCurrency') }),
    },
  ];
};
