import React from 'react';

import { Divider, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Page from 'components/Page/Page';
import paths from 'constants/paths';
import { DzbBankCheckAction } from 'dzb/inquiry/steps/final/DzbBankCheckAction';
import { DzbDocumentUploadAction } from 'dzb/inquiry/steps/final/DzbDocumentUploadAction';
import { DzbSignContractAction } from 'dzb/inquiry/steps/final/DzbSignContractAction';
import Header from 'pages/inquiryFlow/FinalPage/SpecializedFinalPage/Header';
import ButtonComponent from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

export const DzbFinalPage = () => {
  const t = useTranslations();
  const location = useLocation<{ status: 'success' | 'error' }>();
  const { status } = location.state || { status: 'error' };

  return (
    <Page>
      <Header />
      <Divider opacity="1" my={16} borderColor="border.lightGrey" />
      <DzbDocumentUploadAction />
      <Divider opacity="1" my={16} borderColor="border.lightGrey" />
      {status === 'success' ? <DzbSignContractAction /> : <DzbBankCheckAction />}
      <Flex justifyContent={'flex-end'}>
        <ButtonComponent as={'a'} href={paths.customer.inquiryList}>
          {t('pages.finalPage.buttons.inquiryList')}
        </ButtonComponent>
      </Flex>
    </Page>
  );
};
