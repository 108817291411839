import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { useTranslations } from 'utils/hooks/useTranslations';

import { BUSINESS_SPECIFICS } from '../../formFields';

export const validateOtherSection = validateSectionFields([BUSINESS_SPECIFICS]);

export const OtherSection = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.purpose.sections.other');

  return (
    <FormSection title={t('title')} sectionNumber={3}>
      <FormRow>
        <TextAreaWithField
          name={BUSINESS_SPECIFICS}
          captionTooltip={t('fields.businessSpecifics.tooltip')}
          optional
        />
      </FormRow>
    </FormSection>
  );
};
