import _get from 'lodash/get';

import {
  INVESTMENT_PLAN_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_CONSTRUCTION_COSTS,
  INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_GAK_BUILDING_COSTS,
  INVESTMENT_PLAN_GAK_BUYING_COSTS,
  INVESTMENT_PLAN_GAK_INTEREST_COSTS,
  INVESTMENT_PLAN_GAK_OTHER,
  INVESTMENT_PLAN_GAK_SELLING_COSTS,
  INVESTMENT_PLAN_INTEREST_COSTS,
  INVESTMENT_PLAN_OTHER_COSTS,
  INVESTMENT_PLAN_SELLING_COSTS,
} from 'modules/Inquiry/Form/formFields';

const mapInvestmentPlanFromApi = (response: unknown) => {
  const data = _get(response, 'attributes.details.investment_plan');

  const investmentValue = {
    [INVESTMENT_PLAN_ADDITIONAL_COSTS]: _get(data, 'additional_costs', '0,00'),
    [INVESTMENT_PLAN_CONSTRUCTION_COSTS]: _get(data, 'construction_costs', '0,00'),
    [INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS]: _get(data, 'gak_additional_costs', '0,00'),
    [INVESTMENT_PLAN_GAK_BUILDING_COSTS]: _get(data, 'gak_building_costs', '0,00'),
    [INVESTMENT_PLAN_GAK_BUYING_COSTS]: _get(data, 'gak_buying_costs', '0,00'),
    [INVESTMENT_PLAN_GAK_INTEREST_COSTS]: _get(data, 'gak_interest_costs', '0,00'),
    [INVESTMENT_PLAN_GAK_OTHER]: _get(data, 'gak_other', '0,00'),
    [INVESTMENT_PLAN_GAK_SELLING_COSTS]: _get(data, 'gak_selling_costs', '0,00'),
    [INVESTMENT_PLAN_INTEREST_COSTS]: _get(data, 'interest_costs', '0,00'),
    [INVESTMENT_PLAN_OTHER_COSTS]: _get(data, 'other_costs', '0,00'),
    [INVESTMENT_PLAN_SELLING_COSTS]: _get(data, 'selling_costs', '0,00'),
  };

  return investmentValue;
};

export default mapInvestmentPlanFromApi;
