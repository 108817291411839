import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import UserDataPage from 'modules/UserData/UserData';
import { ChangePassword } from 'pages/ChangePassword';
import CustomerPortalInquiryList from 'pages/customerPortal/CustomerInquiryList/CustomerInquiryList';
import CustomerInquiryDetails from 'pages/customerPortal/InquiryDetails';
import { CustomerLogin } from 'pages/customerPortal/Login';
import OAuth from 'pages/customerPortal/OAuth';
import { FinishRegistration } from 'pages/operationPortal/FinishRegistration';
import CustomerActivation from 'pages/RegistrationProcess/CustomerActivation/CustomerActivation';
import { RequestPasswordReset } from 'pages/RequestPasswordReset';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';

const { customer: customerPaths } = paths;

const CustomerRoutes = () => (
  <Switch>
    <Route path={customerPaths.login} component={CustomerLogin} />
    <Route path={customerPaths.activation} component={CustomerActivation} />
    <Route path={customerPaths.passwordReset.request} component={RequestPasswordReset} />
    <Route path={customerPaths.passwordReset.change} component={ChangePassword} />
    <Route path={customerPaths.finishRegistration} component={FinishRegistration} />
    <CustomerPrivateRoute path={customerPaths.userData} component={UserDataPage} />
    <CustomerPrivateRoute
      exact
      path={customerPaths.inquiryList}
      component={CustomerPortalInquiryList}
    />
    <CustomerPrivateRoute
      path={customerPaths.inquiryDetails.root}
      component={CustomerInquiryDetails}
    />
    <CustomerPrivateRoute path={customerPaths.oauth} component={OAuth} />
    <Route path="*" render={() => <Redirect to={paths.error404} />} />
  </Switch>
);

export default CustomerRoutes;
