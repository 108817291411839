import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const InquiryDetailsHeaderButton = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const t = useTranslations('pages.inquiryDetails.documentExchange');
  return (
    <ButtonComponent
      onClick={() => history.push(paths.customer.inquiryDetails.dashboard.replace(':id', id))}
    >
      {t('dashboardLink')}
    </ButtonComponent>
  );
};

const InquiryTypeSpecificHeader = chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: InquiryDetailsHeaderButton,
  [InquiryType.onlinefactoring]: InquiryDetailsHeaderButton,
  [InquiryType.bfsService]: InquiryDetailsHeaderButton,
  default: () => null,
});

export default InquiryTypeSpecificHeader;
