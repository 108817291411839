import { format } from 'date-fns';
import _isNil from 'lodash/isNil';
import _pickBy from 'lodash/pickBy';

import { useFeatureFlags } from 'config/featureFlags/hooks';
import { useFormConfig } from 'config/formConfig/hooks';
import { Item } from 'models/InquiryDetails/ForestryInquiryDetails.model';
import {
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_DATE_OF_AUTH,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL_ITEMS,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_ITEMS,
} from 'modules/Inquiry/Form/formFields';
import {
  shouldShowField,
  useFieldConditionsForPartner,
} from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { removeObjectValues } from 'utils/helpers';

const isEmptyObject = (value: any) => typeof value === 'object' && Object.keys(value).length === 0;

export const filterEmptyData = (mappedData: any) =>
  removeObjectValues(
    mappedData,
    (value: any) => _isNil(value) || Number.isNaN(value) || isEmptyObject(value),
  );

export const useFilterDataByBusinessConditions = () => {
  const featureFlags = useFeatureFlags();
  const { selectedInquiryType } = useFormConfig();
  const fieldConditions = useFieldConditionsForPartner();

  return (formValues: any) =>
    _pickBy(formValues, (_value, fieldName) =>
      shouldShowField(
        fieldName,
        { formValues, config: featureFlags, formType: selectedInquiryType },
        fieldConditions,
      ),
    );
};

const mapItem = (item: Item[]): Item[] | undefined => {
  if (!item) {
    return undefined;
  }

  return item.map((element: Item) => ({
    ...element,
    [COMPANY_DETAILS_RECEIVED_SUBSIDIES_DATE_OF_AUTH]: format(
      new Date(element[COMPANY_DETAILS_RECEIVED_SUBSIDIES_DATE_OF_AUTH]),
      'yyyy-MM-dd',
    ),
  }));
};

export const mapDate = (data: any) => {
  const mappedData = {
    ...data,
    [COMPANY_DETAILS_RECEIVED_SUBSIDIES_ITEMS]: mapItem(
      data[COMPANY_DETAILS_RECEIVED_SUBSIDIES_ITEMS],
    ),
    [COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL_ITEMS]: mapItem(
      data[COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL_ITEMS],
    ),
    [COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL_ITEMS]: mapItem(
      data[COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL_ITEMS],
    ),
    [COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING_ITEMS]: mapItem(
      data[COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING_ITEMS],
    ),
    [COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI_ITEMS]: mapItem(
      data[COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI_ITEMS],
    ),
  };

  return mappedData;
};
