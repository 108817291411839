import React from 'react';

import { CORONA_EXPENDITURE_OF_EMPLOYEES } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';

const ExpenditureOfEmployees: React.FC = () => {
  return <AmountWithField name={CORONA_EXPENDITURE_OF_EMPLOYEES} />;
};

export default ExpenditureOfEmployees;
