import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  ADP_NUMBER,
  COMPANY_DETAILS_CONSENT,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
  STRUCTURE_NUMBER,
  USER_PROFILE_PASSWORD,
  USER_PROFILE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';

const mapDataForApi = (formValues: any, partnerId: string) => ({
  salutation: formValues[COMPANY_DETAILS_GENDER],
  'first-name': formValues[COMPANY_DETAILS_FIRST_NAME],
  'last-name': formValues[COMPANY_DETAILS_LAST_NAME],
  email: formValues[COMPANY_DETAILS_EMAIL],
  'terms-and-conditions-accepted': formValues[COMPANY_DETAILS_CONSENT],
  'phone-number': formValues[USER_PROFILE_PHONE_NUMBER],
  password: formValues[USER_PROFILE_PASSWORD],
  'structure-number': formValues[STRUCTURE_NUMBER],
  'adp-root-number': formValues[ADP_NUMBER],
  'compeon-partner': partnerId,
});

export const RegistrationService = {
  registerUserInApi: (values: any, partnerId: string) =>
    callReverseApi({
      url: endpoints.CUSTOMERS.LIST.compose(),
      method: API_METHODS.POST,
      data: { data: { attributes: mapDataForApi(values, partnerId) } },
    }),

  confirmUserInApi: (token: string | null) =>
    callReverseApi({
      url: endpoints.USERS.CONFIRM.compose(),
      method: API_METHODS.PATCH,
      data: { data: { confirmation_token: token } },
    }),
};
