import { useCallback, useMemo, useState } from 'react';
import React from 'react';

import { Field } from 'react-final-form';

import FieldGroup from 'components/PortalFormFields/FieldGroup';
import Autocomplete from 'components/PortalFormFields/SelectField/Autocomplete/Autocomplete';
import { COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY } from 'modules/Inquiry/Form/formFields';
import { MIN_COMPANY_SUGGESTION_QUERY } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { useLoadCompanies } from 'pages/inquiryFlow/CompanyDetails/sections/Company/ExistingUserCompanySelect/useLoadCompanies';
import { mapCompaniesToSelectOptions } from 'pages/inquiryFlow/CompanyDetails/service';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, required } from 'utils/validators';

import { CompanySuggestionProps } from './index';

// FIXME: Refactor to be used globally by all partners in combination with useCompanySuggestion
export const MittweidaExistingUserCompanySelect = ({
  fieldName,
  onSelect,
  onClearCompanyForm,
  menuShowThreshold,
}: CompanySuggestionProps) => {
  const { companies: rawCompanies, isFetching } = useLoadCompanies();

  const t = useTranslations(
    'pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany',
  );
  const tErrors = useTranslations('errors');

  const [inputValue, setInputValue] = useState('');
  const companies = mapCompaniesToSelectOptions(rawCompanies);

  const additionalOption = {
    label: t('additionalOption.labelOnSelected'),
    value: COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  };

  const companiesToOptions = companies
    .filter(({ value }) => !!value.name)
    .map(({ value, label }) => ({
      label: value.name,
      component: label,
      value,
    }));

  const filterOptionsByInput = useCallback(
    // FIXME
    // @ts-ignore
    (option) => {
      if (inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      }
      return true;
    },
    [inputValue],
  );

  const autocompleteOptions = useMemo(
    () => companiesToOptions.filter(filterOptionsByInput),
    [companiesToOptions, filterOptionsByInput],
  );

  const options = autocompleteOptions.map((company) => ({
    label: company.label,
    value: company.value.name,
  }));

  const menuShowThresholdProp =
    menuShowThreshold === undefined ? MIN_COMPANY_SUGGESTION_QUERY : menuShowThreshold;

  return (
    <Field
      name={fieldName}
      validate={combineValidators(required(tErrors('required')))}
      render={({ meta, input }) => {
        return (
          <FieldGroup label={t('caption')} error={meta.touched && meta.error ? meta.error : null}>
            <div data-testid={fieldName}>
              <Autocomplete
                name={input.name}
                options={options}
                onFocus={input.onFocus}
                onChange={setInputValue}
                onSelect={(value) => {
                  input.onChange(value);
                  if (onSelect) {
                    onSelect(value, autocompleteOptions);
                  }
                }}
                placeholder={t('placeholder', { roleSpecific: true })}
                value={input.value}
                inputInitialValue={input.value}
                loading={isFetching}
                menuShowThreshold={menuShowThresholdProp}
                fixedOptions={[additionalOption]}
                onBlur={input.onBlur}
                clearCompanyForm={onClearCompanyForm}
              />
            </div>
          </FieldGroup>
        );
      }}
    />
  );
};
