import styled from 'styled-components/macro';

import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';
import { mqSmall, mqMedium } from 'styles/breakpoints';
import { borderRadius, transition } from 'theme/themeConstants';

export const StyledPurposeKindList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: -1rem -0.75rem;
  list-style: none;
`;

export const StyledPurposeKindListItem = styled.li`
  flex: 0 1 auto;
  width: 100%;
  padding: 1rem 0.75rem;

  ${mqSmall`
    width: 50%;
  `};

  ${mqMedium`
    width: 33.33333333%;
  `};
`;

export const StyledIcon = styled.span`
  display: block;
  width: 100%;
  padding: 1.75rem 0;
  outline: 0;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-radius: ${borderRadius.default};
  background-color: transparent;
  transition: border-color ${transition.default}, background-color ${transition.default};
  cursor: pointer;

  /* sadly react-svg lib needs two wrapper elements */
  /* spans are needed because the SVG is included inside a button element */
  > span {
    display: block;

    > span {
      display: block;
    }
  }

  svg {
    width: 7.5rem;
    height: 7.5rem;
    fill: ${({ theme }) => theme.colors.background.darkGrey};
    transition: fill ${transition.default};

    [stroke] {
      fill: none;
      stroke: ${({ theme }) => theme.colors.border.lightGrey};
      transition: stroke ${transition.default};
    }
  }
`;

export const StyledTitle = styled.span`
  display: block;
  width: 100%;
  height: auto;
  min-height: 2rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_LIGHT};
  text-align: center;
  color: ${({ theme }) => theme.colors.text.tertiary};
  transition: color ${transition.default};
`;

export const StyledPurposeKind = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.background.white};

  &:first-child,
  &:nth-child(2),
  &:nth-child(3) {
    padding-top: 0;
  }

  &.active {
    ${StyledIcon} {
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.primary};
    }

    ${StyledTitle} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:focus&:not(.active),
  &:hover&:not(.active) {
    ${StyledIcon} {
      border-color: ${({ theme }) => theme.colors.primary};

      svg {
        fill: ${({ theme }) => theme.colors.primary};

        [stroke] {
          stroke: ${({ theme }) => theme.colors.primary};
        }
      }
    }

    ${StyledTitle} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
