import { projectCompositionSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/projectCompositionSection/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateProjectCompositionSection: ValidateSectionFunction<
  InquiryType.profiMittweida
> = (form, values, store, conditions) => {
  return validateFieldValidationMap({
    validationMap: projectCompositionSectionValidationMap,
    form,
    values,
    conditions,
  });
};
