import styled from 'styled-components/macro';

import FormRowWrapper from 'components/FormRow/FormRowWrapper';
import { mqMedium } from 'styles/breakpoints';

export const StyledFieldsWrapper = styled.div`
  ${FormRowWrapper} {
    margin-bottom: 0;

    ${mqMedium`
      margin-bottom: 2rem;
    `}

    > * {
      padding-bottom: 2rem;

      ${mqMedium`
        padding-bottom: 0;
      `}
    }
  }
`;

export const StyledHr = styled.hr`
  margin: 0 0 2rem;
  border: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
`;
