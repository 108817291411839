import {
  ALREADY_ASSIGNED,
  BUSINESS_SPECIFICS,
  INVOICES_PER_YEAR,
  MASTER_CRAFTSMAN,
  NUMBER_OF_LIFTS,
  PAYMENT_TERMS,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  PERFORM_TUV_EXAMINATIONS,
  SHARE_OF_INSURANCE_CLAIMS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
} from 'onlinefactoring/formFields';
import { InquiryConstants } from 'schema/inquirySchema.constants';

import { fieldValidators } from './customValidations';

const { MIN_NUMBER_OF_LIFTS, MAX_NUMBER_OF_LIFTS } = InquiryConstants;

export const kfinanzFieldValidations = {
  [NUMBER_OF_LIFTS]: fieldValidators
    .number()
    .min(MIN_NUMBER_OF_LIFTS)
    .max(MAX_NUMBER_OF_LIFTS)
    .required(),
  [MASTER_CRAFTSMAN]: fieldValidators.required(),
  [PERFORM_TUV_EXAMINATIONS]: fieldValidators.required(),
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP]: fieldValidators.required(),
  [SHARE_OF_INSURANCE_CLAIMS]: fieldValidators.required(),
  [PERCENTAGE_OF_INVOICES_FOR_SELL]: fieldValidators.required(),
  [PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE]: fieldValidators.required(),
  [INVOICES_PER_YEAR]: fieldValidators.required(),
  [PAYMENT_TERMS]: fieldValidators.required(),
  [ALREADY_ASSIGNED]: fieldValidators.boolean().required(),
  [BUSINESS_SPECIFICS]: fieldValidators.string().max(2000).optional(),
};
