import React, { Suspense } from 'react';

import { node } from 'prop-types';

import { LOADING_INDICATOR } from 'modules/InquiryDetails/FetchInquiryDetails';

import { MainContainer } from './styles';

const MainContent = ({ children }) => (
  <MainContainer>
    <Suspense fallback={LOADING_INDICATOR}>{children}</Suspense>
  </MainContainer>
);

MainContent.propTypes = {
  children: node,
};

MainContent.defaultProps = {
  children: null,
};

export default MainContent;
