import { RLLComputedProfitabilityPresales } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatInteger } from 'utils/valueFormats';

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.salesInPresalePhase.tableHeaders.${key}`,
    );

  return [
    {
      key: 'type',
      text: translate('type'),
    },
    {
      key: 'baseValues',
      text: translate('baseValues'),
    },
    {
      key: 'sharePercent',
      text: translate('sharePercent'),
    },
    {
      key: 'pricePerShare',
      text: translate('pricePerShare'),
    },
    {
      key: 'revenue',
      text: translate('revenue'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  presales: RLLComputedProfitabilityPresales,
) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.salesInPresalePhase.tableRows.${key}`,
    );
  const translateUnit = (unit: string) => t(`other.${unit}`);
  const defaultCurrency = translateUnit('defaultCurrency');

  return [
    {
      type: translate('totalSpace'),
      baseValues: formatDecimal(presales.baseValues.totalSpace, { unit: translateUnit('m2') }),
      rowHighlight: true,
    },
    {
      type: translate('total'),
      baseValues: formatDecimal(presales.baseValues.total, { unit: translateUnit('m2') }),
      sharePercent: formatDecimal(presales.sharePercent.total, { unit: translateUnit('percent') }),
      revenue: formatDecimal(presales.revenue.total, { unit: defaultCurrency }),
    },
    {
      type: translate('residential'),
      baseValues: formatDecimal(presales.baseValues.residential, { unit: translateUnit('m2') }),
      sharePercent: formatDecimal(presales.sharePercent.residential, {
        unit: translateUnit('percent'),
      }),
      pricePerShare: formatDecimal(presales.pricePerShare.residential, { unit: defaultCurrency }),
      revenue: formatDecimal(presales.revenue.residential, { unit: defaultCurrency }),
    },
    {
      type: translate('commercial'),
      baseValues: formatDecimal(presales.baseValues.commercial, { unit: translateUnit('m2') }),
      sharePercent: formatDecimal(presales.sharePercent.commercial, {
        unit: translateUnit('percent'),
      }),
      pricePerShare: formatDecimal(presales.pricePerShare.commercial, { unit: defaultCurrency }),
      revenue: formatDecimal(presales.revenue.commercial, { unit: defaultCurrency }),
    },
    {
      type: translate('parking'),
      baseValues: formatInteger(presales.baseValues.parking),
      sharePercent: formatDecimal(presales.sharePercent.parking, {
        unit: translateUnit('percent'),
      }),
      pricePerShare: formatDecimal(presales.pricePerShare.parking, { unit: defaultCurrency }),
      revenue: formatDecimal(presales.revenue.parking, { unit: defaultCurrency }),
    },
    {
      type: translate('garage'),
      baseValues: formatInteger(presales.baseValues.garage),
      sharePercent: formatDecimal(presales.sharePercent.garage, { unit: translateUnit('percent') }),
      pricePerShare: formatDecimal(presales.pricePerShare.garage, { unit: defaultCurrency }),
      revenue: formatDecimal(presales.revenue.garage, { unit: defaultCurrency }),
    },
  ];
};
