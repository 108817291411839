import React, { forwardRef } from 'react';

import { List } from '@chakra-ui/react';

interface SelectListProps {
  isOpen: boolean;
  children: React.ReactNode;
}

export const SelectList = forwardRef<HTMLUListElement, SelectListProps>(
  ({ isOpen, children, ...props }: SelectListProps, ref) => (
    <List
      position="absolute"
      bg="background.white"
      display={isOpen ? 'block' : 'none'}
      top="100%"
      width="100%"
      zIndex={20}
      ref={ref}
      data-testid="select-items"
      maxH="22rem"
      boxShadow="default"
      {...props}
    >
      {children}
    </List>
  ),
);
