import React from 'react';

import StaticField from 'components/StaticField';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useInquiryValueParserForDisplay } from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';

const SummaryField = ({ fieldName, fieldValue }: { fieldName: string; fieldValue: any }) => {
  const labelTranslator = useInquiryLabelTranslator();
  const valueTranslator = useInquiryValueParserForDisplay();

  if (typeof fieldValue === 'undefined') {
    return null;
  }

  return (
    <StaticField
      caption={labelTranslator(fieldName)}
      text={valueTranslator(fieldValue, fieldName)}
    />
  );
};

export default SummaryField;
