import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ProjectFinancingIcon = (props: IconProps) => {
  return (
    <Icon
      width="38"
      height="40"
      viewBox="0 0 38 40"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path d="M33.2 22l2.9.7M33 13.3l2.6-1.5m-8.1-6.5l1.6-2.5M18.6.5v3M8 2.8l1.7 2.5m-8.5 6.9L4 13.3M.5 22.8l2.8-1M21.9 22.3c-.7.3-1.4.5-2.2.5-2.7 0-4.9-2.1-4.9-4.7s2.2-4.7 4.9-4.7c.9 0 1.7.2 2.4.6m-9.4 3.5h5.2m-5.2 2h5.2" />
        <path d="M16.1 33.6c-1.5 0-2.6-1.2-2.7-2.7 0-1.5-.7-2.9-1.9-3.8-3.4-2.8-5-7.3-4-11.5.9-4.2 4.2-7.5 8.3-8.5 3.4-.9 7.1-.1 9.9 2.1 2.8 2.2 4.4 5.6 4.4 9.1s-1.6 6.8-4.4 9c-1.1.9-1.7 2.2-1.7 3.7s-1.2 2.7-2.7 2.7h-5.2z" />
        <path d="M20.1 38.5h-2.5c-.6 0-1.1-.2-1.6-.6-.4-.4-.6-1-.6-1.6v-1.9c0-.5.4-.8.8-.8h5.3c.5 0 .8.4.8.8v1.9c0 1.2-1 2.2-2.2 2.2z" />
      </g>
    </Icon>
  );
};

export default ProjectFinancingIcon;
