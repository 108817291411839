import { useEffect } from 'react';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  CORONA_REVENUE_OF_2019,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_TOTAL_ASSETS,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_FOUNDING_MONTH,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { useFieldValue } from 'store/hooks/useFormValues';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

interface KfwProgramParams {
  revenue2019: number;
  numberOfEmployees: number;
  totalAssets: boolean;
  foundingYear: number;
  foundingMonth: number;
}

const kfwProgramParamsToQueryString = (params: KfwProgramParams) => ({
  data: {
    attributes: {
      'revenue-2019': params.revenue2019,
      'count-of-employees': params.numberOfEmployees,
      'total-assets-greater-than-43-mil': Boolean(params.totalAssets),
      'founding-year': params.foundingYear,
      'founding-month': params.foundingMonth,
    },
  },
});

export enum ACTION {
  GET_KFW_PROGRAM = 'GET_KFW_PROGRAM',
  GET_KFW_PROGRAM_SUCCESS = 'GET_KFW_PROGRAM_SUCCESS',
}

export const getKfwProgramAction = (params: KfwProgramParams) =>
  callReverseApi({
    url: endpoints.COMPANIES.CORONA_PROGRAM.compose({
      query: kfwProgramParamsToQueryString(params),
    }),
    method: API_METHODS.GET,
    actionName: ACTION.GET_KFW_PROGRAM,
  });

export enum CompanySize {
  small = 'small',
  big = 'big',
}

export const useFetchKfwProgram = () => {
  const revenue2019 = useFieldValue<number>(CORONA_REVENUE_OF_2019, 0);
  const numberOfEmployees = useGetFieldNumericValue(CORONA_NUMBER_OF_EMPLOYEES) as number;
  const totalAssets = useFieldValue<boolean>(CORONA_TOTAL_ASSETS, false);
  const foundingYear = useGetFieldNumericValue(USER_PROFILE_FOUNDING_YEAR) as number;
  const foundingMonth = useGetFieldNumericValue(USER_PROFILE_FOUNDING_MONTH) as number;
  const selectedOtherKfw =
    useFieldValue<string>(CORONA_FINANCING_OPTION, '') === CORONA_FINANCING_OPTION__KFW_PROGRAM;

  const { isPending, makeCall } = useDispatchApiCall();

  const shouldFetch =
    revenue2019 && numberOfEmployees && foundingYear && foundingMonth && selectedOtherKfw;
  useEffect(() => {
    if (shouldFetch) {
      makeCall(
        getKfwProgramAction({
          revenue2019,
          numberOfEmployees,
          totalAssets,
          foundingYear,
          foundingMonth,
        }),
      );
    }
  }, [
    foundingMonth,
    foundingYear,
    makeCall,
    numberOfEmployees,
    revenue2019,
    shouldFetch,
    totalAssets,
  ]);

  return { isPending };
};
