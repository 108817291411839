import paths from 'constants/paths';
import { conditions } from 'mmv/inquiry/config/conditions';
import { mmvFields } from 'mmv/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

import { initialFormState } from './initialFormState';
import { initialFormValues } from './initialFormValues';
import { routeToPageNameMap } from './routeToPageNameMap';
import { stepData } from './stepData';
import { stepSequence } from './stepSequence';
import { summaryFields } from './summaryFields';
import { validations } from './validations';
import { MMV_FORM } from '../index';

export const inquiryProcessConfig: InquiryProcessConfig<InquiryType.mmv> = {
  validations,
  fields: mmvFields,
  stepData,
  stepSequence,
  routeToPageNameMap,
  initialFormState,
  initialFormValues,
  summaryFields,
  formName: MMV_FORM,
  conditions,
  paths: {
    editPaths: {
      editCustomer: paths.customer.inquiryDetails.edit.contractDetails,
      editOperation: paths.operation.inquiryDetails.edit.contractDetails,
    },
    listPaths: {
      editOperation: paths.operation.inquiryList,
      editCustomer: paths.customer.inquiryList,
    },
  },
};
