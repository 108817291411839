import React from 'react';

import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

type HeadingProps = {
  heading: string;
  subHeading: string;
};

const EditPageHeading = (props: HeadingProps) => {
  const { heading, subHeading } = props;
  const t = useTranslations();
  return <PageHeading heading={t(heading)} subheading={t(subHeading)} />;
};

export default EditPageHeading;
