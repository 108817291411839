import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { PurposeOptions } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

const { facade, balcony, window, undergroundGarage, roof, heating, line, other } =
  translations.inquiryType.hausbank.pages.financingNeed.sections.purpose.fields.purposeKind.options;

export const purposeKindOptions: SelectOption<PurposeOptions>[] = [
  {
    label: facade,
    value: 'facade',
  },
  {
    label: balcony,
    value: 'balcony',
  },
  {
    label: window,
    value: 'window',
  },
  {
    label: undergroundGarage,
    value: 'underground_garage',
  },
  {
    label: roof,
    value: 'roof',
  },
  {
    label: heating,
    value: 'heating',
  },
  {
    label: line,
    value: 'line',
  },
  {
    label: other,
    value: 'other',
  },
];

export const getHausbankPurposeKindOptions = (
  formatMessage: FormatMessage,
): SelectOption<PurposeOptions>[] => {
  return purposeKindOptions.map(({ label, value }) => {
    return {
      value,
      label: formatMessage({ id: label }),
    };
  });
};
