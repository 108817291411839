import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { ObjectType } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getMittweidaObjectTypeOptions = (
  formatMessage: FormatMessage,
): SelectOption<ObjectType>[] => {
  const {
    apartment,
    apartmentBuilding,
    residentialAndCommercialBuilding,
    commercial,
    lot,
    expectedLot,
    house,
    townhouse,
  } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .futureObjectType.options;

  const options: Array<{ id: string; value: ObjectType }> = [
    {
      id: apartment,
      value: 'apartment',
    },
    {
      id: apartmentBuilding,
      value: 'apartment_building',
    },
    {
      id: residentialAndCommercialBuilding,
      value: 'residential_and_commercial',
    },
    {
      id: commercial,
      value: 'commercial',
    },
    {
      id: lot,
      value: 'lot',
    },
    {
      id: expectedLot,
      value: 'expected_lot',
    },
    {
      id: house,
      value: 'house',
    },
    {
      id: townhouse,
      value: 'townhouse',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
