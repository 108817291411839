import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';

interface IsFictitiousBeneficialOwnerProps {
  name: string;
  tooltip?: string;
}

const IsFictitiousBeneficialOwner = ({ name, tooltip }: IsFictitiousBeneficialOwnerProps) => {
  return <YesNoRadioGroupWithField name={name} tooltip={tooltip} />;
};

export default IsFictitiousBeneficialOwner;
