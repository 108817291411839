import styled, { css } from 'styled-components';

import { borderRadius, transition } from 'theme/themeConstants';

// Spinner taken from http://tobiasahlin.com/spinkit/
export const dotClass = 'sk-circle';
export const dots = Array.from(Array(12).keys(), (n) => n + 1);

export const StyledSpinnerWrapper = styled.span`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: block;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1rem auto;
  transition: opacity ${transition.default};

  .${dotClass} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      display: block;
      width: 15%;
      height: 15%;
      margin: 0 auto;
      border-radius: ${borderRadius.circle};
      background-color: ${(props) =>
        props.inverted ? props.theme.colors.background.white : props.theme.colors.primary};
      animation: animation 1.2s infinite ease-in-out both;
    }
  }

  ${dots.map(
    (index) => css`
      .${dotClass + index} {
        transform: rotate(${30 * (index - 1)}deg);
      }
    `,
  )};

  ${dots.map(
    (index) => css`
      .${dotClass + index}:before {
        animation-delay: ${-1.2 + 0.1 * (index - 1)}s;
      }
    `,
  )};

  @keyframes animation {
    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }
`;
