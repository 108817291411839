import { validatebfsPeopleDetailsSection } from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson/ForNotLoggedInUser/bfsPersonDetails';
import { validateConsentSection } from 'pages/inquiryFlow/CompanyDetails/sections/TermsAndConditions';

import { validatebfsConsentSection } from '../CompanyDetails/sections/BfsTermsAndCondions';

export const useContactPersonStepValidators = () => [validateConsentSection()];
export const useBfsContactPersonStepValidators = () => [
  validatebfsPeopleDetailsSection(),
  validatebfsConsentSection(),
];
