import React, { ReactNode, useMemo } from 'react';

import merge from 'lodash/merge';
import { IntlProvider } from 'react-intl';

import '@formatjs/intl-pluralrules/dist/locale-data/de';
import langMessages from 'shared/locale';

import { useLanguages } from './useLanguages';

const canBeAnInteger = (key: string | number) =>
  typeof key === 'number' || !Number.isNaN(parseInt(key, 10));

const getPrefixedKey = (prefix: string, key: string | number) =>
  canBeAnInteger(key) ? `${prefix}[${key}]` : `${prefix}.${key}`;

/*
 * Transforms nested json into flat key-value object
 * from:
 * pages: {
 *   page1: {
 *    title: 'foo',
 *    desc: 'bar',
 * }
 *
 * to:
 * pages.page1.title: 'foo',
 * pages.page1.desc: 'bar',
 */
const flattenMessagesObject = (nestedMessages: any, prefix = '') =>
  Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? getPrefixedKey(prefix, key) : key;
    const messagesRef: any = messages;

    if (typeof value !== 'object') {
      messagesRef[prefixedKey] = value;
    } else {
      Object.assign(messagesRef, flattenMessagesObject(value, prefixedKey));
    }

    return messagesRef;
  }, {});

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const { selectedLanguage, partnerTranslation } = useLanguages();

  const customizedTranslations = useMemo(
    () => flattenMessagesObject(merge(langMessages[selectedLanguage], partnerTranslation)),
    [selectedLanguage, partnerTranslation],
  );

  return (
    <IntlProvider locale={selectedLanguage} messages={customizedTranslations}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
