import React from 'react';

import Condition from 'components/Condition';
import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

import { TermsAndConditionsSection } from './sections/termsAndConditionsSection';
import { WegDataCompletionSection } from './sections/wegDataCompletionSection';
import { WegSearchSection } from './sections/wegSearchSection';

export const HausbankCompanyDataPage = () => {
  const t = useTranslations();

  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.hausbank.pages.companyData;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <WegSearchSection />
      <WegDataCompletionSection />
      <Condition condition={!isLoggedInUser()}>
        <TermsAndConditionsSection />
      </Condition>
    </Page>
  );
};
