import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const isUnderPath = (paths: string[], pathname: string | undefined) =>
  paths.find((path) => pathname?.includes(path) || false);

export const useIsUnderPaths = (paths: string[]) => {
  const location = useLocation();

  return useMemo(() => isUnderPath(paths, location.pathname), [location.pathname, paths]);
};
