import React from 'react';

import RadioGroupFullWidthWrapper from 'components/RadioGroupFullWidth/RadioGroupFullWidthWrapper';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY } from 'modules/Inquiry/Form/formFields';

const FactoringAlreadyOnHandAtFactoringCompany = () => {
  return (
    <RadioGroupFullWidthWrapper>
      <YesNoRadioGroupWithField name={FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY} />
    </RadioGroupFullWidthWrapper>
  );
};

export default FactoringAlreadyOnHandAtFactoringCompany;
