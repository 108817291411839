import { Component } from 'react';

import { any, arrayOf, func, oneOfType, string } from 'prop-types';

import { selectOptionConfig } from 'utils/propTypes';

class TagSelect extends Component {
  getOptionsWithoutSelected = () => {
    const { selectedValues, options } = this.props;

    // prettier-ignore
    return options.filter((option) =>
      !(selectedValues.length && selectedValues.some((value) => value === option.value)));
  };

  getSelectedOptions = () => {
    const {
      props: { selectedValues, options },
    } = this;

    return options.length && selectedValues
      ? selectedValues
          .map((value) => options.find((option) => option.value === value))
          .filter((option) => option !== undefined)
      : [];
  };

  add = (value) => {
    const { onChange, selectedValues } = this.props;
    onChange({ target: { value: [...selectedValues, value] } });
  };

  remove = (option) => {
    const { onChange, selectedValues } = this.props;
    onChange({ target: { value: selectedValues.filter((existing) => existing !== option.value) } });
  };

  render() {
    const {
      props: { render },
      add,
      remove,
    } = this;

    return render({
      add,
      remove,
      selectedOptions: this.getSelectedOptions(),
      options: this.getOptionsWithoutSelected(),
    });
  }
}

TagSelect.propTypes = {
  onChange: func.isRequired,
  render: func.isRequired,
  selectedValues: oneOfType([string, arrayOf(any)]).isRequired,
  options: arrayOf(selectOptionConfig).isRequired,
};

export default TagSelect;
