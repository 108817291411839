import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useQueryParams } from 'utils/hooks/useQueryParams';

// Dispatches query parameter using passed action
export const useDispatchQueryParam = ({ queryParamName, action }) => {
  const queryParam = useQueryParams(queryParamName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (queryParam) {
      dispatch(action(queryParam));
    }
  }, [action, dispatch, queryParam]);
};
