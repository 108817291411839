import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  investmentAmount,
  financingAmount,
  maintenanceReserve,
  wegContribution,
  constructionSubsidies,
  loanTermInYears,
  ownersMeetingDate,
} = hausbankFields.financingNeedPage.financingNeedSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.financingNeed.sections.financingNeed;

export const financingNeedSectionLabelsMap: LabelsMap = {
  [investmentAmount]: fieldTranslations.investmentAmount.caption,
  [financingAmount]: fieldTranslations.financingAmount.caption,
  [maintenanceReserve]: fieldTranslations.maintenanceReserve.caption,
  [wegContribution]: fieldTranslations.wegContribution.caption,
  [constructionSubsidies]: fieldTranslations.constructionSubsidies.caption,
  [loanTermInYears]: fieldTranslations.loanTermInYears.caption,
  [ownersMeetingDate]: fieldTranslations.ownersMeetingDate.caption,
};
