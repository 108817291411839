import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { InputWithField } from 'components/Input';
import { USER_PROFILE_FOUNDING_YEAR, CORONA_FOUNDING_YEAR } from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';

const FoundingYear: React.FC = () => {
  const { change } = useForm();

  const foundingYear = useGetFieldNumericValue(CORONA_FOUNDING_YEAR);

  useEffect(() => {
    change(USER_PROFILE_FOUNDING_YEAR, foundingYear);
  }, [change, foundingYear]);
  return <InputWithField name={CORONA_FOUNDING_YEAR} type="number" separateThousands={false} />;
};

export default FoundingYear;
