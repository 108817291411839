import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export const LineIcon = (props: IconProps) => {
  return (
    <Icon
      width={'42'}
      height={'42'}
      viewBox={'0 0 42 42'}
      aria-hidden={'true'}
      fill={'white'}
      fillOpacity={0}
      {...props}
    >
      <line x1="12.0757" y1="9.60791" x2="12.0757" y2="21.9641" />
      <line x1="12.0757" y1="27.3076" x2="12.0757" y2="42.4547" />
      <line x1="21.125" y1="4.875" x2="21.125" y2="13.875" />
      <line x1="30.5" y1="4.875" x2="30.5" y2="13.875" />
      <line x1="21.248" y1="19.125" x2="21.248" y2="46.125" />
      <line x1="30.623" y1="19.125" x2="30.623" y2="43.5" />
      <line x1="35.873" y1="9.75" x2="35.873" y2="27" />
      <line x1="35.873" y1="32.25" x2="35.873" y2="40.875" />
      <path d="M9.75 24.6357C9.75 23.1601 10.9462 21.9639 12.4218 21.9639H20.9246V27.3074H12.4218C10.9462 27.3074 9.75 26.1112 9.75 24.6357V24.6357Z" />
      <path d="M38.373 29.625C38.373 28.1753 37.1978 27 35.748 27H30.498V32.25H35.748C37.1978 32.25 38.373 31.0747 38.373 29.625V29.625Z" />
      <path d="M19.248 16.5C19.248 15.0503 20.4233 13.875 21.873 13.875H29.748C31.1978 13.875 32.373 15.0503 32.373 16.5V16.5C32.373 17.9497 31.1978 19.125 29.748 19.125H21.873C20.4233 19.125 19.248 17.9497 19.248 16.5V16.5Z" />
      <circle cx="11.5759" cy="24.6359" r="0.665222" />
      <circle
        cx="0.665222"
        cy="0.665222"
        r="0.665222"
        transform="matrix(-1 0 0 1 37.1372 29.0068)"
      />
      <circle cx="21.4133" cy="16.4152" r="0.665222" />
      <circle cx="30.0383" cy="16.4152" r="0.665222" />
    </Icon>
  );
};
