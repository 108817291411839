import * as _ from 'lodash';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const FurtherNeedsValuesMap = (section: string) => {
  const t = useTranslations();
  const translatedFurtherNeedValue = _.camelCase(section);

  const translationPath = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: `pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.explicitNeedFurtherAdvice.options.${translatedFurtherNeedValue}.caption`,
    default: `pages.requestDetails.sections.furtherNeed.fields.adviceTopics.${translatedFurtherNeedValue}.label`,
  });

  return t(translationPath);
};

export default FurtherNeedsValuesMap;
