import { useIntl } from 'react-intl';

import { DOWNLOAD_STATUS } from 'components/DownloadBlock/constants';
import { USER_SCOPE } from 'constants/userScopes';
import type { IAssessmentFile, IFile, IInternalFile, IPrivateFile } from 'models/File.model';
import type {
  ApiAssessmentFile,
  ApiFile,
  ApiInternalFile,
  ApiPrivateFile,
} from 'models/FileApi.model';
import { FileKindType } from 'models/FileKind.type';
import type { ApiFileRequest, IFileRequest } from 'models/FileRequest.model';
import { IPredefinedFileRequestType } from 'models/PredefinedFileRequest.model';
import { DownloadStatusType } from 'models/types/DownloadStatus.type';
import { FileScanStatusType } from 'models/types/FileScanStatus.type';

export const changeFileStatusToDownloaded = (files: Array<IFile>, fileId: string): Array<IFile> =>
  files.map((file) =>
    file.id === fileId ? { ...file, status: DOWNLOAD_STATUS.DOWNLOADED } : file,
  );

export const removeFile = (files: Array<IFile>, fileId: string): Array<IFile> =>
  files.filter((file) => file.id !== fileId);

export const removePrivateFile = (
  files: Array<IPrivateFile>,
  fileId: string,
): Array<IPrivateFile> => files.filter((file) => file.id !== fileId);

export const removeAssessmentFile = (
  files: Array<IAssessmentFile>,
  fileId: string,
): Array<IAssessmentFile> => files.filter((file) => file.id !== fileId);

const fileRequestContainsFile = (request: IFileRequest, fileId: string): boolean =>
  request.files?.map(({ id }) => id).includes(fileId) ?? false;

export const removeFileFromRequest = (
  requests: Array<IFileRequest>,
  fileId: string,
): Array<IFileRequest> =>
  requests.map((request) =>
    fileRequestContainsFile(request, fileId)
      ? {
          ...request,
          status: request.files && request.files.length > 1 ? request.status : 'notUploaded',
          files: request.files?.filter(({ id }) => id !== fileId),
        }
      : request,
  );

export const updateFileClassification = (files: Array<IFile>, file: IFile): Array<IFile> =>
  files.map((f) => (f.id === file.id ? file : f));

export const updatePrivateFileClassification = (
  files: Array<IPrivateFile>,
  file: IPrivateFile,
): Array<IPrivateFile> => files.map((f) => (f.id === file.id ? file : f));

export const updateFileRequestFileClassification = (
  requests: Array<IFileRequest>,
  file: IFile,
  requestId?: string,
): Array<IFileRequest> =>
  requests.map((request) => {
    // file classified into same file request again, break
    if (request.id === requestId && fileRequestContainsFile(request, file.id)) return request;
    // file should be added to file request
    if (request.id === requestId)
      return { ...request, status: 'uploaded', files: [...(request.files ?? []), file] };
    // file should be removed from file request
    if (fileRequestContainsFile(request, file.id))
      return {
        ...request,
        status: request.files && request.files.length > 1 ? 'uploaded' : 'notUploaded',
        files: request.files?.filter(({ id }) => id !== file.id),
      };
    // file request should not be changed
    return request;
  });

export const mapApiFile = (file: ApiFile): IFile => {
  const { id, attributes } = file;

  return {
    id,
    fileName: attributes.filename,
    scanningStatus: attributes.status as FileScanStatusType,
    status:
      Boolean(attributes.downloaded_by_customer_at) ||
      Boolean(attributes.downloaded_by_portal_user_at)
        ? DOWNLOAD_STATUS.DOWNLOADED
        : DOWNLOAD_STATUS.NOT_DOWNLOADED,
    uploadedBy: attributes.uploaded_by as USER_SCOPE,
    createdAt: new Date(attributes.created_at),
    isRealEstateExpert: attributes.is_real_estate_expert ?? null,
    classification: attributes.classification,
    archivedAt: attributes.archived_at ? new Date(attributes.archived_at) : null,
    kind: attributes.kind as FileKindType,
    archivingFailedAt: attributes.archiving_failed_at
      ? new Date(attributes.archiving_failed_at)
      : null,
  };
};

export const mapApiPrivateFile = (file: ApiPrivateFile): IPrivateFile => {
  const { id, attributes } = file;

  return {
    id,
    fileName: attributes.filename,
    scanningStatus: attributes.status as FileScanStatusType,
    createdAt: new Date(attributes.created_at),
    classification: attributes.classification,
    status: attributes.status as DownloadStatusType | string,
    archivedAt: attributes.archived_at ? new Date(attributes.archived_at) : null,
    archivingFailedAt: attributes.archiving_failed_at
      ? new Date(attributes.archiving_failed_at)
      : null,
  };
};

export const mapApiAssessmentFile = (file: ApiAssessmentFile): IAssessmentFile => {
  const { id, attributes } = file;

  return {
    id,
    fileName: attributes.filename,
    scanningStatus: attributes.status as FileScanStatusType,
    createdAt: new Date(attributes.created_at),
    status: attributes.status as DownloadStatusType | string,
    archivedAt: attributes.archived_at ? new Date(attributes.archived_at) : null,
    archivingFailedAt: attributes.archiving_failed_at
      ? new Date(attributes.archiving_failed_at)
      : null,
  };
};

export const mapApiInternalFile = (file: ApiInternalFile): IInternalFile => {
  const { id, attributes } = file;
  return {
    id,
    fileName: attributes.filename,
    downloadUrl: attributes.download_url,
    contentType: attributes.content_type,
  };
};

export const mapApiFileRequest = (
  request: ApiFileRequest,
  files?: Array<ApiFile>,
): IFileRequest => {
  const { id, attributes } = request;

  return {
    id,
    classification: attributes.classification,
    description: attributes.description,
    fileKey: attributes.file_key,
    files: files?.map(mapApiFile) ?? [],
    predefined: attributes.predefined,
    required: attributes.required,
    status: files?.length ? 'uploaded' : 'notUploaded',
    supportsReclassification: attributes.supports_reclassification,
    title: attributes.title,
  };
};

export const updateRequestWithNewFile =
  (requestId: string, file: IFile) =>
  (request: IFileRequest): IFileRequest =>
    request.id === requestId
      ? {
          ...request,
          status: 'uploaded',
          files: [...(request.files ?? []), file],
        }
      : request;

export const useMapFileRequestTypesToOptions = (
  fileRequestTypes: IPredefinedFileRequestType[] | undefined,
) => {
  const { locale } = useIntl();
  if (!fileRequestTypes) return [];

  return fileRequestTypes.reduce((options, fileRequestType) => {
    const label =
      fileRequestType.attributes[
        `display_name_${locale}` as keyof IPredefinedFileRequestType['attributes']
      ];

    // label is not defined means the language has not been added to the file request type yet
    if (label === undefined) {
      throw new Error(
        `No predefined file request type display name for locale ${locale}. Please check the file request types in the BE.`,
      );
    }

    // label is null means the key is added but the text is just missing
    if (label === null) {
      return options;
    }

    return [
      ...options,
      {
        label,
        value: fileRequestType.id,
      },
    ];
  }, [] as Array<{ label: string; value: string }>);
};
