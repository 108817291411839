import React, { useEffect } from 'react';

import _isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { useLoadInquiryRevisions } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { InquiryRevisionListElement } from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import { mapRevisionsListFromApi } from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.service';
import { StyledRevisionDateList } from 'modules/InquiryDetails/InquiryRevision/styles';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { formatDateWithTime } from 'utils/date';

import { useRevisionStateContext } from './RevisionProvider';

const RevisionDates = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { data, isLoading } = useLoadInquiryRevisions({
    variables: { inquiryId },
    enabled: !!inquiryId,
    select: (response) => ({
      revisionList: mapRevisionsListFromApi(response),
    }),
  });

  const { revisionState, setRevisionState } = useRevisionStateContext();

  useEffect(() => {
    if (data && !_isEqual(data.revisionList, revisionState.revisionList)) {
      setRevisionState({ revisionList: data.revisionList });
    }
  }, [data, revisionState.revisionList, setRevisionState]);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <StyledRevisionDateList>
      {data?.revisionList.map((revision: InquiryRevisionListElement) => (
        <li key={revision.id} data-testid="revision-date">
          <ButtonComponent
            data-testid={revisionState.selectedRevisionId === revision.id ? 'active' : null}
            onClick={() => setRevisionState({ selectedRevisionId: revision.id })}
            variant={revisionState.selectedRevisionId === revision.id ? 'primary' : 'secondary'}
          >
            {formatDateWithTime(revision.createdAt)}
          </ButtonComponent>
        </li>
      ))}
    </StyledRevisionDateList>
  );
};

export default RevisionDates;
