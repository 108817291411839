import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { LoanTermInYears } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getHausbankLoanTermInYearsOptions = (
  formatMessage: FormatMessage,
): SelectOption<LoanTermInYears>[] => {
  const { five_years, ten_years, fifteen_years, twenty_years } =
    translations.inquiryType.hausbank.pages.financingNeed.sections.financingNeed.fields
      .loanTermInYears.options;

  const options: Array<{ id: string; value: LoanTermInYears }> = [
    {
      id: five_years,
      value: 5,
    },
    {
      id: ten_years,
      value: 10,
    },
    {
      id: fifteen_years,
      value: 15,
    },
    {
      id: twenty_years,
      value: 20,
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
