import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { formatDateBE } from 'utils/date';
import { parseStringBoolean } from 'utils/helpers';

export function mapDzbCompanyData(values: DzbFieldTypes) {
  const {
    companyDataPage: {
      startUpQuestionnaireSection,
      directDebitMandateSection,
      companyInformationSection,
    },
  } = values;

  const isCompanyOlderThan3Years =
    parseStringBoolean(companyInformationSection.companyOlderThan3Years as any) || false;
  let founderData = {} as Record<string, any>;
  if (!isCompanyOlderThan3Years) {
    founderData.founder = {
      leadership_experience: startUpQuestionnaireSection.alreadyGainedLeadershipExperience,
      leadership_experience_years: startUpQuestionnaireSection.leadershipExperienceYears,
      leadership_experience_form: startUpQuestionnaireSection.formOfLeadershipExperience,
      industry_experience_years: startUpQuestionnaireSection.industryExperienceYears,
      education: startUpQuestionnaireSection.education,
      professional_background: startUpQuestionnaireSection.professionalCareer,
    };
  }

  return {
    company_attributes: {
      company_older_than_3_years: companyInformationSection.companyOlderThan3Years,
      financial_statement_type: companyInformationSection.typeOfFinancialStatements,
      last_annual_financial_statement_available_at:
        formatDateBE(companyInformationSection.lastAnnualFinancialStatementReportingDate) || null,
      tax_vat_number: companyInformationSection.salesTaxID || null,
      sepa: {
        iban: directDebitMandateSection.iban,
        bic: directDebitMandateSection.bic,
        bank_name: directDebitMandateSection.bankName,
      },
      ...founderData,
    },
  };
}
