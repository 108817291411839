import { ICompanyDetailsState } from 'models/CompanyDetails.model';

import { ICompaniesTableOptions } from '../CompaniesTable.model';

export enum ACTION {
  GET_COMPANIES_LIST = 'GET_COMPANIES_LIST',
  GET_COMPANIES_LIST_SUCCESS = 'GET_COMPANIES_LIST_SUCCESS',
}

export interface IGetCompaniesList {
  type: ACTION.GET_COMPANIES_LIST_SUCCESS;
  payload: { data: { data: ICompanyDetailsState[] }; meta: ICompaniesTableOptions };
}

export type ActionType = IGetCompaniesList;
