import React from 'react';

import { useDispatch } from 'react-redux';

import { useLogout } from 'api/AuthApi/queryHooks/auth';
import StyledLogoutButton from 'components/PortalPage/StyledLogoutButton';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import useLogoutRedirect from 'modules/Logout/useLogoutRedirect';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { resetInquiryDetailsAction } from 'store/inquiryDetails/actions';
import { showRwdMenu } from 'store/layout/actions';
import { resetForm as resetFormAction } from 'store/progress/actions';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getUserScope } from 'utils/user/getters';

const PortalLogoutBtn = () => {
  const t = useTranslations();
  const { success } = useToasts();
  const currentScope = getUserScope();
  const logoutRedirect = useLogoutRedirect(currentScope);
  const dispatch = useDispatch();

  const logoutSuccessHandler = () => {
    dispatch(showRwdMenu(false));
    dispatch(resetInquiryDetailsAction());
    success({ description: t('pages.companyDetails.sections.contactPerson.buttons.loggedOut') });
    dispatch(resetFormAction(true));
    logoutRedirect();
  };

  const { mutate: logOut } = useLogout(logoutSuccessHandler);

  const handleLogout = () => {
    logOut();
  };

  const logoutButtonLabel = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t('buttons.logout'),
    [InquiryType.hausbank]: t('buttons.logoutV2'),
  });

  return (
    <StyledLogoutButton type="button" onClick={handleLogout} data-testid="logout-button">
      {logoutButtonLabel}
    </StyledLogoutButton>
  );
};

export default PortalLogoutBtn;
