// Properties of an offer
// set to local const for IDE support, no typos, ease of refactor...

import { COMPEON_OFFER_PRODUCT_NAME } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/productNames.contants';

export const COMPEON_OFFER_PROPS = {
  amount: 'amount',
  considerSubsidies: 'consideredSubsidies',
  termInMonths: 'termInMonths',
  value: 'value',
  amortisationType: 'amortisationType',
  advancePayment: 'advancePayment',
  residualValue: 'residualValue',
  leasingFactor: 'leasingFactor',
  processingFee: 'processingFee',
  prerentalCalculated: 'prerentalCalculated',
  fulfilledFactoringVolume: 'fulfilledFactoringVolume',
  loanGuarantee: 'loanGuarantee',
  indicativeMonthlyRate: 'indicativeMonthlyRate',
};
const {
  advancePayment,
  amortisationType,
  amount,
  fulfilledFactoringVolume,
  leasingFactor,
  prerentalCalculated,
  processingFee,
  residualValue,
  termInMonths,
  value,
  loanGuarantee,
  indicativeMonthlyRate,
} = COMPEON_OFFER_PROPS;

const propertiesForProductOffers = {
  [COMPEON_OFFER_PRODUCT_NAME.LOAN]: [
    amount,
    value,
    indicativeMonthlyRate,
    loanGuarantee,
    termInMonths,
  ],
  [COMPEON_OFFER_PRODUCT_NAME.LEASING]: [
    amortisationType,
    advancePayment,
    residualValue,
    termInMonths,
    value,
    leasingFactor,
    processingFee,
    prerentalCalculated,
  ],
  [COMPEON_OFFER_PRODUCT_NAME.HIRE_PURCHASE]: [
    amortisationType,
    advancePayment,
    residualValue,
    termInMonths,
    value,
    leasingFactor,
    processingFee,
    prerentalCalculated,
  ],
  [COMPEON_OFFER_PRODUCT_NAME.OTHER]: [value],
  [COMPEON_OFFER_PRODUCT_NAME.FACTORING]: [fulfilledFactoringVolume],
  [COMPEON_OFFER_PRODUCT_NAME.INVESTMENT_LOAN]: [
    amortisationType,
    advancePayment,
    residualValue,
    termInMonths,
    value,
    processingFee,
    prerentalCalculated,
  ],
  [COMPEON_OFFER_PRODUCT_NAME.MEZZANINE]: [value],
  [COMPEON_OFFER_PRODUCT_NAME.OVERDRAFT]: [value],
  [COMPEON_OFFER_PRODUCT_NAME.PROJECT_FINANCING]: [value],
};

// Each product have different set of properties
// Therefore we have to pick right set, based on productName
export const getPropertiesForProduct = (offer) => {
  const listOfProperties = propertiesForProductOffers[offer.productName] || [];
  return listOfProperties.reduce(
    (offerProperties, propertyName) => [
      ...offerProperties,
      { name: propertyName, value: offer[propertyName] },
    ],
    [],
  );
};
