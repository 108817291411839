import { WHITE_LABEL_API_CLIENT_NAME } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { includePropertyUnits } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/includePropertyUnits';
import { getPropertiesForProduct } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/getPropertiesForProduct';
import {
  translateCompeonOfferProperty,
  translateCompeonEmptyProperties,
} from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/translateProperty';
import { downloadFileFromAxiosResponse } from 'utils/file';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const getDocumentFileAction = (fileId) => (dispatch, getState) => {
  return dispatch({
    type: 'DOWNLOAD_COMPEON_FILE',
    payload: {
      fileId,
      client: WHITE_LABEL_API_CLIENT_NAME,
      request: {
        responseType: 'arraybuffer',
        method: 'get',
        url: endpoints.FILES.DOWNLOAD.compose({ params: { id: fileId } }),
      },
    },
  });
};

export const useDownloadCompeonFile = () => {
  const { makeCall, error } = useDispatchApiCall();

  const downloadFile = (fileId, fileName) =>
    makeCall(getDocumentFileAction(fileId), ({ payload }) => {
      downloadFileFromAxiosResponse(payload, { filename: fileName });
    });

  return {
    downloadFile,
    error,
  };
};

export const mapCompeonOffersForDisplay = (translator) => (offer) => ({
  id: offer.id,
  status: offer.status,
  bankName: offer.bankName,
  type: offer.type,
  productName: offer.productName,
  fileId: offer.fileId,
  questionnaireIds: offer.questionnaireIds,
  requiredFiles: offer.requiredFiles,
  fromPremiumPartner: offer.fromPremiumPartner,
  fromSpecialPartner: offer.fromSpecialPartner,
  hasShownInterest: offer.hasShownInterest,
  filesRequiredByBank: offer.filesRequiredByBank,
  createdAt: offer.createdAt,
  state: offer.state,
  properties: getPropertiesForProduct(offer)
    .map(translateCompeonOfferProperty(translator, offer.productName))
    .map(includePropertyUnits(translator, offer.productName))
    .map(translateCompeonEmptyProperties(translator)),
});
