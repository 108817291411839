import React, { useContext } from 'react';

import { InputWithField } from 'components/Input';
import { REPRESENTATIVE_EMAIL } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';

interface RepresentativeFirstNameProps {
  isDisabled?: boolean;
}

const RepresentativeFirstName: React.FC<RepresentativeFirstNameProps> = ({ isDisabled }) => {
  const { fieldName } = useContext(LegalRepresentativesContext);

  return <InputWithField name={`${fieldName}.${REPRESENTATIVE_EMAIL}`} disabled={isDisabled} />;
};

export default RepresentativeFirstName;
