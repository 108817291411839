import React from 'react';

type SummaryStepContextProps<T> =
  | {
      values: T;
    }
  | undefined;

const SummaryStepContext = React.createContext<SummaryStepContextProps<any>>(undefined);

export const useSummaryStepContext = () => {
  const context = React.useContext(SummaryStepContext);
  if (!context) {
    throw new Error('useSummaryStepContext must be used within a SummaryStepContext');
  }
  return context;
};

type SummaryStepProviderProps<T> = {
  values: T;
  children: React.ReactNode;
};

export const SummaryStepProvider = <T,>({ children, values }: SummaryStepProviderProps<T>) => {
  return <SummaryStepContext.Provider value={{ values }}>{children}</SummaryStepContext.Provider>;
};
