import { ProductKindType } from 'models/ProductKind.model';
import {
  PRODUCT_KIND,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__PROJECT_FINANCING,
  MEZZANINE_DESCRIPTION,
  OTHER_PRODUCT_DESCRIPTION,
  FAVORED_DECISION_DATE,
  EXCLUDED_BANKS,
  PURPOSE_KIND,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  OFFICE_EQUIPMENT_INTERESTED_IN_INSURANCE,
  PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING,
  LOAN_CONSIDER_SUBSIDIES,
  PRODUCT_KIND__PROPERTY_LOAN,
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  PROPERTY_LOAN_DESCRIPTION,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PRODUCT_KIND__AVAL,
  PRODUCT_KIND__KFINANZ,
  PRODUCT_KIND__LIQUIDITY,
  PRODUCT_KIND__CREFO_FACTORING,
  PRODUCT_KIND__BFSS,
} from 'modules/Inquiry/Form/formFields';
import { MultiSelectOption } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { conditionalObject, fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { mapFactoringToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapFactoringToApi';
import { mapHirePurchaseToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapHirePurchaseToApi';
import { mapInvestmentLoanToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapInvestmentLoanToApi';
import { mapKfinanzToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapKfinanzRequestDetailsToApi';
import { mapLeasingToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapLeasingToApi';
import { mapLoanToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapLoanToApi';
import { mapOverdraftToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapOverdraftToApi';

const { toBoolean, toDate, toInteger } = fieldParsers;

const mapMezzanineToApi: InquiryFormMapper = (allFields) => ({
  'mezzanine-description': allFields[MEZZANINE_DESCRIPTION],
});

const mapOtherToApi: InquiryFormMapper = (allFields) => ({
  'other-product-description': allFields[OTHER_PRODUCT_DESCRIPTION],
});

const mapProjectFinancingToApi: InquiryFormMapper = (allFields) => ({
  'project-financing-interested-in-alternative-financing': toBoolean(
    allFields[PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING],
  ),
});

const mapExcludedBanksToApi: InquiryFormMapper = (allFields) =>
  allFields[EXCLUDED_BANKS].map((option: MultiSelectOption) => ({
    name: option.label,
    value: option.value,
  }));

const mapPropertyLoanToApi: InquiryFormMapper = (allFields) => ({
  'property-loan-kind': allFields[PROPERTY_LOAN_KIND],
  'property-loan-term-in-months': toInteger(allFields[PROPERTY_LOAN_TERM_IN_MONTHS]),
  'property-loan-description': allFields[PROPERTY_LOAN_DESCRIPTION],
});

export const mapProductBasedFieldsToApi: InquiryFormMapper = (allFields) => {
  const selectedProduct: ProductKindType = allFields[PRODUCT_KIND];

  const productMap: { [key in ProductKindType]: InquiryFormMapper } = {
    [PRODUCT_KIND__FACTORING]: mapFactoringToApi,
    [PRODUCT_KIND__HIRE_PURCHASE]: mapHirePurchaseToApi,
    [PRODUCT_KIND__INVESTMENT_LOAN]: mapInvestmentLoanToApi,
    [PRODUCT_KIND__LEASING]: mapLeasingToApi,
    [PRODUCT_KIND__LOAN]: mapLoanToApi,
    [PRODUCT_KIND__KFINANZ]: mapKfinanzToApi,
    [PRODUCT_KIND__MEZZANINE]: mapMezzanineToApi,
    [PRODUCT_KIND__OTHER]: mapOtherToApi,
    [PRODUCT_KIND__LIQUIDITY]: mapOtherToApi,
    [PRODUCT_KIND__AVAL]: mapOtherToApi,
    [PRODUCT_KIND__OVERDRAFT]: mapOverdraftToApi,
    [PRODUCT_KIND__PROJECT_FINANCING]: mapProjectFinancingToApi,
    [PRODUCT_KIND__PROPERTY_LOAN]: mapPropertyLoanToApi,
    [PRODUCT_KIND__CREFO_FACTORING]: () => ({}),
    [PRODUCT_KIND__BFSS]: () => ({}),
  };

  if (!productMap[selectedProduct]) {
    // For sending lead we are parsing the inquiry but we didn't select product yet
    console.warn(`No Api mapper for ${selectedProduct} product`); // eslint-disable-line no-console
    return {};
  }

  const hasVehiclePurpose = allFields[PURPOSE_KIND] === PURPOSE_KIND__VEHICLE;
  const hasMachinePurpose = allFields[PURPOSE_KIND] === PURPOSE_KIND__MACHINE;
  const hasOfficeEquipmentPurpose = allFields[PURPOSE_KIND] === PURPOSE_KIND__OFFICE_EQUIPMENT;
  const isEnegryEfficiencyPurpose = allFields[PURPOSE_KIND] === PURPOSE_KIND__ENERGY_EFFICIENCY;

  return {
    'favored-decision-date': toDate(allFields[FAVORED_DECISION_DATE]),
    'excluded-bank-codes': Array.isArray(allFields[EXCLUDED_BANKS])
      ? mapExcludedBanksToApi(allFields)
      : undefined,
    'product-kind': selectedProduct,
    'consider-subsidies': toBoolean(allFields[LOAN_CONSIDER_SUBSIDIES]) || false,
    ...productMap[selectedProduct](allFields),

    ...conditionalObject(hasVehiclePurpose, {
      'vehicle-interested-in-insurance': toBoolean(allFields[VEHICLE_INTERESTED_IN_INSURANCE]),
    }),
    ...conditionalObject(hasMachinePurpose || isEnegryEfficiencyPurpose, {
      'machine-interested-in-insurance': toBoolean(allFields[MACHINE_INTERESTED_IN_INSURANCE]),
    }),
    ...conditionalObject(hasOfficeEquipmentPurpose, {
      'machine-interested-in-insurance': toBoolean(
        allFields[OFFICE_EQUIPMENT_INTERESTED_IN_INSURANCE],
      ),
    }),
  };
};
