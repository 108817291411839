import _get from 'lodash/get';

import {
  INVESTMENT_PLAN_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_CONSTRUCTION_COSTS,
  INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_GAK_BUILDING_COSTS,
  INVESTMENT_PLAN_GAK_BUYING_COSTS,
  INVESTMENT_PLAN_GAK_INTEREST_COSTS,
  INVESTMENT_PLAN_GAK_OTHER,
  INVESTMENT_PLAN_GAK_SELLING_COSTS,
  INVESTMENT_PLAN_INTEREST_COSTS,
  INVESTMENT_PLAN_OTHER_COSTS,
  INVESTMENT_PLAN_SELLING_COSTS,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapInvestmentPlanToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const investmentPlan = {
    investment_plan: {
      additional_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_ADDITIONAL_COSTS)),
      ),
      construction_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_CONSTRUCTION_COSTS)),
      ),
      gak_additional_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS)),
      ),
      gak_building_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_GAK_BUILDING_COSTS)),
      ),
      gak_buying_costs: _get(data, INVESTMENT_PLAN_GAK_BUYING_COSTS),
      gak_interest_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_GAK_INTEREST_COSTS)),
      ),
      gak_other: toFloatPrecision2(unknownToString(_get(data, INVESTMENT_PLAN_GAK_OTHER))),
      gak_selling_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_GAK_SELLING_COSTS)),
      ),
      interest_costs: toFloatPrecision2(
        unknownToString(_get(data, INVESTMENT_PLAN_INTEREST_COSTS)),
      ),
      other_costs: _get(data, INVESTMENT_PLAN_OTHER_COSTS),
      selling_costs: toFloatPrecision2(unknownToString(_get(data, INVESTMENT_PLAN_SELLING_COSTS))),
    },
  };

  return investmentPlan;
};

export default mapInvestmentPlanToApi;
