import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';

import {
  getInquiryFormTypeSelector,
  getInquiryIdSelector,
} from '../../../../store/inquiryDetails/selectors';
import useDispatchApiCall from '../../../../utils/hooks/useDispatchApiCallHook';
import {
  getPlanningEvaluationProfitability,
  setPlanningEvaluationProfitability,
} from '../store/action';

export const useFetchPlanningEvaluationProfitability = () => {
  const isMittweida = useSelector(getInquiryFormTypeSelector) === InquiryType.profiMittweida;

  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const { makeCall, error, isPending } = useDispatchApiCall();

  const fetchPlanningEvaluationProfitability = useCallback(
    // FIXME
    (inquiryId: any) =>
      makeCall(getPlanningEvaluationProfitability(inquiryId), ({ payload }: any) => {
        dispatch(setPlanningEvaluationProfitability(payload.data));
      }),
    [dispatch, makeCall],
  );

  useEffect(() => {
    if (isMittweida) fetchPlanningEvaluationProfitability(inquiryId);
  }, [inquiryId, fetchPlanningEvaluationProfitability, isMittweida]);

  if (error) {
    throw error;
  }

  return { isLoading: isPending };
};
