import styled from 'styled-components/macro';

const SortingArrowIcon = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 0.25rem;

  svg {
    color: ${({ theme }) => theme.colors.text.tertiary};

    &.active {
      /* stylelint-disable-line selector-no-qualifying-type */
      color: ${({ theme }) => theme.colors.primary};
    }

    &.hidden {
      /* stylelint-disable-line selector-no-qualifying-type */
      visibility: hidden;
    }

    &:first-child {
      margin-bottom: -0.75rem;
    }
  }
`;

export default SortingArrowIcon;
