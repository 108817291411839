import React from 'react';

import CompanyLabel from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyLabel';
import { useTranslations } from 'utils/hooks/useTranslations';

const NoCompanyElement = () => {
  const t = useTranslations(
    'pages.companyDetails.sections.company.fields.companyDetailsCompany.additionalOption.label',
  );
  return (
    <CompanyLabel>
      <div>{t('header')}</div>
      <div>{t('subheader')}</div>
    </CompanyLabel>
  );
};

export default NoCompanyElement;
