import { contactPersonSectionValidationMap } from 'mittweida/inquiry/steps/userProfile/sections/contactPerson/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import {
  isEmailAvailableSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import { isLoggedInUser } from 'utils/user/conditionals';

export const validateContactPersonSection: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  const isEmailAvailable = isEmailAvailableSelector(store.getState());
  const isEmailLoading = isLoadingEmailAvailabilitySelector(store.getState());
  const isLoggedIn = isLoggedInUser();

  if (isLoggedIn) {
    return true;
  }

  return (
    validateFieldValidationMap({
      validationMap: contactPersonSectionValidationMap,
      form,
      values,
      conditions,
    }) &&
    isEmailAvailable &&
    !isEmailLoading
  );
};
