import React from 'react';

import { ReactComponent as FactoringIcon } from 'assets/icons/productKind/factoring.svg';
import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import RadioGroupSameWidthWrapper from 'components/RadioGroupSameWidth/Wrapper';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import {
  FACTORING_TURNOVER_CLASS,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_COMMERCIAL_CREDIT_INSURANCE,
  FACTORING_ACTIVE_CUSTOMERS,
  FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON,
  FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS,
} from 'modules/Inquiry/Form/formFields';
import FactoringAlreadyInUse from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/FactoringAlreadyInUse';
import FactoringAlreadyOnHandAtFactoringCompany from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/FactoringAlreadyOnHandAtFactoringCompany';
import FactoringBillsRange from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/FactoringBillsRange';
import factoringCommercialCreditInsuranceOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/factoringCommercialCreditInsuranceOptions';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

const Factoring = () => {
  const t = useTranslations();

  const turnoverClassOptions = useInquirySelectOptions(FACTORING_TURNOVER_CLASS);
  const annualProfitLossOptions = useInquirySelectOptions(
    FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  );
  const averagePaymentPeriodOptions = useInquirySelectOptions(FACTORING_AVERAGE_PERIOD_OF_PAYMENT);

  return (
    <FormSection
      name={FormSections.factoring}
      title={t('pages.requestDetails.sections.factoring.title')}
    >
      <FormRow separator>
        <FinancingAmountSummary icon={<FactoringIcon />} />
      </FormRow>
      <FormRow>
        <SelectWithField
          name={FACTORING_TURNOVER_CLASS}
          options={turnoverClassOptions}
          data-testid={'select-field-' + FACTORING_TURNOVER_CLASS}
        />
        <SelectWithField
          name={FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS}
          options={annualProfitLossOptions}
          data-testid={'select-field-' + FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS}
        />
      </FormRow>
      <FormRow>
        <InputWithField name={FACTORING_FOREIGN_SHARE} type="number" />
        <InputWithField name={FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER} type="number" />
      </FormRow>
      <FormRow separator>
        <SelectWithField
          name={FACTORING_AVERAGE_PERIOD_OF_PAYMENT}
          options={averagePaymentPeriodOptions}
          data-testid={'select-field-' + FACTORING_AVERAGE_PERIOD_OF_PAYMENT}
        />
      </FormRow>
      <FormRow>
        <RadioGroupSameWidthWrapper>
          <YesNoRadioGroupWithField
            name={FACTORING_COMMERCIAL_CREDIT_INSURANCE}
            options={factoringCommercialCreditInsuranceOptions}
          />
        </RadioGroupSameWidthWrapper>
      </FormRow>
      <FormRow>
        <InputWithField name={FACTORING_ACTIVE_CUSTOMERS} type="number" />
        <FactoringBillsRange />
      </FormRow>
      <FormRow>
        <FactoringAlreadyInUse />
      </FormRow>
      <FormRow>
        <FactoringAlreadyOnHandAtFactoringCompany />
      </FormRow>
      <FormRow>
        <TextAreaWithField name={FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON} optional />
      </FormRow>
      <FormRow>
        <TextAreaWithField name={FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS} optional />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default Factoring;
