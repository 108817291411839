import normalize from 'json-api-normalizer';

import { IInternalCommentsItem } from 'api/CompeonReverseApi/operation/types';

const mapFromApiInterface = (rawData: any): IInternalCommentsItem[] => {
  const { portalUsers } = normalize(rawData);

  const mappedArray = rawData.data.map(
    ({ id, relationships, attributes }: any): IInternalCommentsItem => ({
      id,
      user: portalUsers[relationships.author.data.id].attributes,
      content: attributes.content,
      createdAt: attributes.created_at,
    }),
  );
  return mappedArray;
};

export default mapFromApiInterface;
