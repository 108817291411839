import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledList,
  StyledListItem,
  StyledTitle,
  StyledIconWrapper,
  StyledListElement,
} from 'components/SmallTileList/styles';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

const SmallTileList = ({ options, onSelect, selectedOption, name }) => {
  return (
    <StyledList>
      {options.map(({ key, title, icon }) => (
        <StyledListItem key={key}>
          <StyledListElement
            name={name}
            data-testid={key}
            className={selectedOption === key ? 'active' : ''}
            type="button"
            onClick={() => onSelect(key)}
          >
            <StyledIconWrapper>{icon}</StyledIconWrapper>
            <StyledTitle>{title}</StyledTitle>
          </StyledListElement>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

SmallTileList.defaultProps = {
  selectedOption: '',
};

SmallTileList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  ).isRequired,
  selectedOption: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const SmallTileListAdapter = ({ input, errorMessage, options, onChange }) => (
  <>
    <SmallTileList
      options={options}
      selectedOption={input.value}
      onSelect={onChange}
      name={input.name}
    />
    <FieldError message={errorMessage} />
  </>
);

SmallTileListAdapter.propTypes = FinalFormFieldPropTypes;

export const SmallTileListWithField = withFormField(SmallTileListAdapter);
