import { compile, match, MatchFunction, PathFunction } from 'path-to-regexp';
import qs from 'qs';

import { containsInquiryIdRegex } from './regexes';

export const getObjectFromQueryParamsString = (path: string) => {
  const partOfPathWithQueryStrings = path.slice(path.indexOf('?') + 1);

  return qs.parse(partOfPathWithQueryStrings);
};

export const removeQueryParamsFromUrl = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

type URLParams = Record<string, string | number | undefined>;
type URLQuery = {
  [key: string]:
    | undefined
    | boolean
    | boolean[]
    | string
    | string[]
    | number
    | number[]
    | URLQuery
    | URLQuery[];
};
type URLOptions = {
  qs?: qs.IStringifyOptions;
};

interface QuerySegment {
  query: URLQuery;
}

interface ParamQuerySegment<TParams extends URLParams> extends Partial<QuerySegment> {
  params: TParams;
}

type URLSegments<TParams extends URLParams | undefined> = TParams extends undefined
  ? QuerySegment
  : TParams extends URLParams
  ? ParamQuerySegment<TParams>
  : never;

type ComposeArgs<TParams extends URLParams | undefined> = TParams extends undefined
  ? [segments?: URLSegments<undefined>, options?: URLOptions]
  : TParams extends URLParams
  ? [segments: URLSegments<TParams>, options?: URLOptions]
  : never[];

type ExtractParam<Pattern extends string> = Pattern extends `${infer Head}/${infer Tail}`
  ? Head extends `:${infer Param}`
    ? [Param, ...ExtractParam<Tail>]
    : ExtractParam<Tail>
  : Pattern extends `:${infer LastParam}`
  ? [LastParam]
  : [];

function isParamSegment<T extends URLParams>(
  item: ParamQuerySegment<T> | QuerySegment | undefined,
): item is ParamQuerySegment<T> {
  return typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'params');
}

export class Route<
  TPattern extends string,
  TParams extends URLParams | undefined = ExtractParam<TPattern> extends never[]
    ? undefined
    : Record<ExtractParam<TPattern>[number], string>,
> {
  readonly pattern: TPattern;
  private pathResolver: PathFunction;
  private matcher: MatchFunction<TParams extends URLParams ? TParams : never>;

  constructor(pattern: TPattern) {
    this.pattern = pattern;
    this.pathResolver = compile(pattern);
    this.matcher = match(pattern);
  }

  private resolveQuery<TQuery extends URLQuery>(
    url: string,
    query: TQuery,
    options?: qs.IStringifyOptions,
  ): string {
    const qsOptions: qs.IStringifyOptions = { addQueryPrefix: true, encode: true, ...options };
    return query ? `${url}${qs.stringify(query, qsOptions)}` : url;
  }

  public compose(...args: ComposeArgs<TParams>): string {
    const [segments, options] = args;
    let url: string = this.pattern;
    if (isParamSegment(segments)) {
      url = this.pathResolver(segments.params);
    }
    if (segments?.query) {
      url = this.resolveQuery(url, segments.query, options?.qs);
    }
    return url;
  }

  public match(path: string): TParams | false {
    const matched = this.matcher(path);
    return matched ? matched.params : false;
  }
}

export const pathContainsInquiryId = (path: string) => path.search(containsInquiryIdRegex) >= 0;

export const pathsPortal = (path: string) => path.split('/')[0];
