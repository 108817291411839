import { failure, success } from 'utils/actionTypesHelpers';

export const LOAD_PARTNER_SETTINGS = 'LOAD_PARTNER_SETTINGS';
export const LOAD_PARTNER_SETTINGS_FAILURE = failure(LOAD_PARTNER_SETTINGS);
export const LOAD_PARTNER_SETTINGS_SUCCESS = success(LOAD_PARTNER_SETTINGS);

export const LOAD_INQUIRY_SETTINGS = 'LOAD_INQUIRY_SETTINGS';
export const LOAD_INQUIRY_SETTINGS_SUCCESS = success(LOAD_INQUIRY_SETTINGS);

export const SAVE_INQUIRIES = 'SAVE_INQUIRIES';
export const SAVE_INQUIRIES_FAILURE = failure(SAVE_INQUIRIES);
export const SAVE_INQUIRIES_SUCCESS = success(SAVE_INQUIRIES);

export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_COMPANIES_FAILURE = failure(LOAD_COMPANIES);

export const LOAD_USER_ATTRIBUTES = 'LOAD_USER_ATTRIBUTES';
export const LOAD_USER_ATTRIBUTES_SUCCESS = success(LOAD_USER_ATTRIBUTES);
