import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { ConfigController } from 'config/ConfigController';

export const requestPasswordResetAction = (email: string) =>
  callReverseApi({
    url: endpoints.USERS.PASSWORD_RESET_REQUEST.compose(),
    method: API_METHODS.PATCH,
    data: () => {
      const {
        details: { id: partnerId },
      } = ConfigController.partner.getConfig();
      return {
        data: {
          email,
          partner_name: partnerId,
        },
      };
    },
  });
