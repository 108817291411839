import React from 'react';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FILTERS_USAGE_TYPES } from './constants';
import {
  getMarketDataFilterLegacy,
  setMarketDataFilter as setMarketDataFilterAction,
} from './filterManagementReducer';
import { USAGE_TYPE } from '../../../../modules/Inquiry/Form/formFields';
import { AppDispatch } from '../../../../store';
import RadioGroupComponent from '../../../../theme/components/RadioGroup';
import { useTranslations } from '../../../../utils/hooks/useTranslations';

interface UsageTypeRadioProps {
  disableCurrentUsage?: boolean;
  initialValue?: string;
}

const UsageTypeRadio = ({ disableCurrentUsage, initialValue }: UsageTypeRadioProps) => {
  const dispatch: AppDispatch = useDispatch();
  const filterData = useSelector(getMarketDataFilterLegacy);
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  const [value, setValue] = useState(filterData?.usageType);

  return (
    <RadioGroupComponent
      currentValue={value}
      name={USAGE_TYPE}
      defaultValue={initialValue}
      options={[
        {
          caption: t('usageRadio.futureUsage'),
          value: FILTERS_USAGE_TYPES.FUTURE,
        },
        {
          caption: t('usageRadio.currentUsage'),
          value: FILTERS_USAGE_TYPES.CURRENT,
          isDisabled: disableCurrentUsage,
        },
      ]}
      radioWidth={'15.5rem'}
      onChange={(event) => {
        setValue(event.target.value as FILTERS_USAGE_TYPES);
        dispatch(
          setMarketDataFilterAction({
            ...filterData,
            usageType: event.target.value || event,
          }),
        );
      }}
    />
  );
};

export default UsageTypeRadio;
