import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import Unit from 'components/Unit/Unit';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useFormConfig } from 'config/formConfig/hooks';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import Condition from 'modules/ConditionalSubform/Condition';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING_ADDITIONAL_DETAILS,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  PROJECT_FINANCING_OBJECT_FUTURE_USAGE_FORM,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_FILE_HALTER_PARTITION_FIELDS,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
} from 'modules/Inquiry/Form/formFields';
import ObjectUsageFields from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/ObjectUsageFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { InquiryMapType } from 'modules/Inquiry/InquiryMapType';
import { useCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import SpecialObjectInformationSection from './SpecialObjectInformationSection';

const commonFields = [
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
];
const inquiryMap: InquiryMapType<string[]> = {
  [InquiryType.profiRLL]: [
    ...commonFields,
    PROJECT_FINANCING__BUILDING_YEAR,
    PROJECT_FINANCING__MODERNIZATION_YEAR,
    PROJECT_FINANCING__FURNISHING_QUALITY,
    PROJECT_FINANCING__QUALITY_CONDITION,
    PROJECT_FINANCING__GUEST_BATHROOM,
    PROJECT_FINANCING__BALKONY,
    PROJECT_FINANCING__LIFT,
    PROJECT_FINANCING__CELLAR,
    PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
    PROJECT_FINANCING__NUMBER_OF_GARAGES,
    PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
    PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
    PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
    PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
    PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
    PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
    PROJECT_FINANCING__GARAGES_RENT_NOW,
    PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  ],
  default: commonFields,
};

const ObjectInformationSection: React.FC = () => {
  const t = useTranslations('pages.financingNeed.sections.objectInformation');
  const showSpecialInformationSection = useCanShowField(PROJECT_FINANCING_ADDITIONAL_DETAILS);
  const showFileHalterPartitionFields = useCanShowField(
    PROJECT_FINANCING_FILE_HALTER_PARTITION_FIELDS,
  );
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const showDeviatingFromFutureUsageForm = useCanShowField(
    PROJECT_FINANCING_OBJECT_FUTURE_USAGE_FORM,
  );

  return (
    <FormSection
      name={FormSections.projectFinancingObjectInformation}
      sectionNumber={isProfiInquiry ? 3 : 4}
      title={t('title')}
    >
      <FormRow>
        <InputWithField name={PROJECT_FINANCING_OBJECT_ADDRESS} />
      </FormRow>
      <FormRow>
        <InputWithField
          name={PROJECT_FINANCING_OBJECT_ZIP_CODE}
          type="tel"
          pattern="[0-9]*"
          inputMaxLimit={MAX_ZIP_CODE_LENGTH}
          separateThousands={false}
        />
        <InputWithField name={PROJECT_FINANCING_OBJECT_CITY} />
      </FormRow>
      <FormRow>
        <TextAreaWithField name={PROJECT_FINANCING_OBJECT_DESCRIPTION} />
      </FormRow>
      <FormRow>
        <InputWithField
          name={PROJECT_FINANCING_LOT_SIZE}
          type="number"
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
        />
      </FormRow>

      <Condition condition={() => showSpecialInformationSection}>
        <SpecialObjectInformationSection />
      </Condition>
      {isProfiInquiry ? (
        <ObjectUsageFields
          formSectionName={PROJECT_FINANCING__OBJECT_FUTURE_USAGE}
          usageFieldLabel={PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE}
          isFutureUsage
        />
      ) : (
        <ObjectUsageFields
          formSectionName={PROJECT_FINANCING__OBJECT_USAGE}
          usageFieldLabel={PROJECT_FINANCING_TYPE_OF_USAGE}
        />
      )}

      <Condition condition={() => showSpecialInformationSection}>
        <FormRow>
          <YesNoRadioGroupWithField name={PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE} />
        </FormRow>
        <Condition condition={() => showDeviatingFromFutureUsageForm}>
          <ObjectUsageFields
            formSectionName={PROJECT_FINANCING__OBJECT_USAGE}
            usageFieldLabel={PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT}
          />
        </Condition>
      </Condition>

      <Condition condition={() => showFileHalterPartitionFields}>
        <FormRow>
          <YesNoRadioGroupWithField name={PROJECT_FINANCING_LIST_OF_RENTERS} />
          <InputWithField name={PROJECT_FINANCING_ANCHOR_TENANT} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={PROJECT_FINANCING_RENTAL_CONTRACTS} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={PROJECT_FINANCING_RENOVATION_PLANNED} />
        </FormRow>
        <FormRow>
          <TextAreaWithField name={PROJECT_FINANCING_RENOVATION_DESCRIPTION} />
        </FormRow>
      </Condition>
    </FormSection>
  );
};

export default React.memo(ObjectInformationSection);

export const validateObjectInformationSection = (selectedInquiryType: InquiryType) =>
  validateSectionFields(inquiryMap[selectedInquiryType] || inquiryMap.default);
