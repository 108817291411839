import {
  EXCLUDED_BANKS,
  FACTORING_ACTIVE_CUSTOMERS,
  FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS,
  FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON,
  FACTORING_ALREADY_IN_USE,
  FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_BILLS_RANGE,
  FACTORING_COMMERCIAL_CREDIT_INSURANCE,
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_CLASS,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
  FAVORED_DECISION_DATE,
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  HIRE_PURCHASE_AMORTISATION_KIND,
  HIRE_PURCHASE_DESCRIPTION,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  HIRE_PURCHASE_TERM_IN_MONTHS,
  INVESTMENT_LOAN_ADVANCE_PAYMENT,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  INVESTMENT_LOAN_DESCRIPTION,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  INVESTMENT_LOAN_TERM_IN_MONTHS,
  LEASING_ADVANCE_PAYMENT,
  LEASING_AMORTISATION_KIND,
  LEASING_DESCRIPTION,
  LEASING_INSTRESTED_IN_INSURANCE_HELPER,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_RESIDUAL_VALUE,
  LEASING_TAKEOVER,
  LEASING_TERM_IN_MONTHS,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_DESCRIPTION,
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  INTERESTED_IN_INSURANCE,
  LEASING_VEHICLE_INTERESTED_IN_INSURANCE,
  LEASING_MACHINE_INTERESTED_IN_INSURANCE,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_TERM,
  LOAN_TYPE,
  MEZZANINE_DESCRIPTION,
  OTHER_PRODUCT_DESCRIPTION,
  OVERDRAFT_END_AT,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
  OVERDRAFT_TERMINABILITY,
  PRODUCT_KIND,
  PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING,
  SECURITIES_DESCRIPTION,
  SECURITIES_TYPE,
  SECURITIES_VALUE_ESTIMATION,
  LOAN_ALREADY_AT_BANK,
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  PROPERTY_LOAN_DESCRIPTION,
  INVESTMENT_LOAN_TERM_IN_YEARS,
  FUNDING_CLOSING_DATE,
  FURTHER_NEED_VALUES,
  LOAN_ADVANCE_PAYMENT,
  OFFER_PAYOUT_RATIO,
  OFFER_FACTORING_LINE,
  OFFER_FACTORING_FEE,
  FURTHER_NEED,
} from 'modules/Inquiry/Form/formFields';

const getTranslationPath = (section: string, field: string) =>
  `pages.requestDetails.sections.${section}.fields.${field}.caption`;

// The order of these labels is relevant for inquiry details page
export const requestDetailsLabelsMap: { [key: string]: string } = {
  [PRODUCT_KIND]: getTranslationPath('financialProduct', 'productKind'),

  [EXCLUDED_BANKS]: getTranslationPath('bankSearch', 'excludedBanks'),

  [FAVORED_DECISION_DATE]: getTranslationPath('decisionDate', 'favoredDecisionDate'),

  [FACTORING_TURNOVER_CLASS]: getTranslationPath('factoring', 'factoringTurnoverClass'),
  [FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: getTranslationPath(
    'factoring',
    'factoringAnnualNetProfitOrLossClass',
  ),
  [FACTORING_FOREIGN_SHARE]: getTranslationPath('factoring', 'factoringForeignShare'),
  [FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER]: getTranslationPath(
    'factoring',
    'factoringTurnoverShareWithLargestCustomer',
  ),
  [FACTORING_AVERAGE_PERIOD_OF_PAYMENT]: getTranslationPath(
    'factoring',
    'factoringAveragePeriodOfPayment',
  ),
  [FACTORING_COMMERCIAL_CREDIT_INSURANCE]: getTranslationPath(
    'factoring',
    'factoringCommercialCreditInsurance',
  ),
  [FACTORING_ACTIVE_CUSTOMERS]: getTranslationPath('factoring', 'factoringActiveCustomers'),
  [FACTORING_BILLS_RANGE]: getTranslationPath('factoring', 'factoringBillsRange'),
  [FACTORING_ALREADY_IN_USE]: getTranslationPath('factoring', 'factoringAlreadyInUse'),
  [FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY]: getTranslationPath(
    'factoring',
    'factoringAlreadyOnHandAtFactoringCompany',
  ),
  [FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON]: getTranslationPath(
    'factoring',
    'factoringAdditionalInformationForCompeon',
  ),
  [FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS]: getTranslationPath(
    'factoring',
    'factoringAdditionalInformationForBanks',
  ),

  [LEASING_ADVANCE_PAYMENT]: getTranslationPath('leasing', 'leasingAdvancePayment'),
  [LEASING_TERM_IN_MONTHS]: getTranslationPath('leasing', 'leasingTermInMonths'),
  [LEASING_AMORTISATION_KIND]: getTranslationPath('leasing', 'leasingAmortisationKind'),
  [LEASING_RESIDUAL_VALUE]: getTranslationPath('leasing', 'leasingResidualValue'),
  [LEASING_TAKEOVER]: getTranslationPath('leasing', 'leasingTakeover'),
  [LEASING_DESCRIPTION]: getTranslationPath('leasing', 'leasingDescription'),
  [LEASING_INTERESTED_IN_CREDIT_FINANCING]: getTranslationPath(
    'leasing',
    'leasingInterestedInCreditFinancing',
  ),
  [LEASING_INSTRESTED_IN_INSURANCE_HELPER]: getTranslationPath(
    'leasing',
    'leasingInstrestedInInsuranceHelper',
  ),

  [LOAN_TYPE]: getTranslationPath('loan', 'loanType'),
  [LOAN_ADVANCE_PAYMENT]: getTranslationPath('loan', 'loanAdvancePayment'),
  [LOAN_TERM]: getTranslationPath('loan', 'loanTerm'),
  [LOAN_CONSIDER_SUBSIDIES]: getTranslationPath('loan', 'loanConsiderSubsidies'),
  [LOAN_DESCRIPTION]: getTranslationPath('loan', 'loanDescription'),
  [VEHICLE_INTERESTED_IN_INSURANCE]: getTranslationPath('shared', 'interestedInInsurance'),
  [MACHINE_INTERESTED_IN_INSURANCE]: getTranslationPath('shared', 'interestedInInsurance'),
  [INTERESTED_IN_INSURANCE]: getTranslationPath('shared', 'interestedInInsurance'),
  [LEASING_VEHICLE_INTERESTED_IN_INSURANCE]: getTranslationPath('shared', 'interestedInInsurance'),
  [LEASING_MACHINE_INTERESTED_IN_INSURANCE]: getTranslationPath('shared', 'interestedInInsurance'),
  [LOAN_FIXED_INTEREST_RATE]: getTranslationPath('loan', 'loanFixedInterestRate'),
  [LOAN_INTEREST_PERIOD_IN_MONTHS]: getTranslationPath('loan', 'loanInterestPeriodInMonths'),
  [LOAN_ALREADY_AT_BANK]: getTranslationPath('loan', 'alreadyAtBank'),

  [OTHER_PRODUCT_DESCRIPTION]: getTranslationPath('other', 'otherProductDescription'),

  [OVERDRAFT_TERMINABILITY]: getTranslationPath('overdraft', 'overdraftTerminability'),
  [OVERDRAFT_END_AT]: getTranslationPath('overdraft', 'overdraftEndAt'),
  [OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING]: getTranslationPath(
    'overdraft',
    'overdraftInterestedInAlternativeFinancing',
  ),

  [SECURITIES_TYPE]: getTranslationPath('securities', 'securitiesType'),
  [SECURITIES_VALUE_ESTIMATION]: getTranslationPath('securities', 'securitiesValueEstimation'),
  [SECURITIES_DESCRIPTION]: getTranslationPath('securities', 'securitiesDescription'),

  [MEZZANINE_DESCRIPTION]: getTranslationPath('mezzanine', 'mezzanineDescription'),

  [PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING]: getTranslationPath(
    'projectFinancing',
    'projectFinancingInterestedInAlternativeFinancing',
  ),

  [INVESTMENT_LOAN_ADVANCE_PAYMENT]: getTranslationPath(
    'investmentLoan',
    'investmentLoanAdvancePayment',
  ),
  [INVESTMENT_LOAN_TERM_IN_MONTHS]: getTranslationPath(
    'investmentLoan',
    'investmentLoanTermInMonths',
  ),
  [INVESTMENT_LOAN_AMORTISATION_KIND]: getTranslationPath(
    'investmentLoan',
    'investmentLoanAmortisationKind',
  ),
  [INVESTMENT_LOAN_RESIDUAL_VALUE]: getTranslationPath(
    'investmentLoan',
    'investmentLoanResidualValue',
  ),
  [INVESTMENT_LOAN_DESCRIPTION]: getTranslationPath('investmentLoan', 'investmentLoanDescription'),
  [INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS]: getTranslationPath(
    'investmentLoan',
    'investmentLoanInterestedInOtherProducts',
  ),

  [HIRE_PURCHASE_ADVANCE_PAYMENT]: getTranslationPath('hirePurchase', 'hirePurchaseAdvancePayment'),
  [HIRE_PURCHASE_TERM_IN_MONTHS]: getTranslationPath('hirePurchase', 'hirePurchaseTermInMonths'),
  [HIRE_PURCHASE_AMORTISATION_KIND]: getTranslationPath(
    'hirePurchase',
    'hirePurchaseAmortisationKind',
  ),
  [HIRE_PURCHASE_RESIDUAL_VALUE]: getTranslationPath('hirePurchase', 'hirePurchaseResidualValue'),
  [HIRE_PURCHASE_DESCRIPTION]: getTranslationPath('hirePurchase', 'hirePurchaseDescription'),
  [HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN]: getTranslationPath(
    'hirePurchase',
    'hirePurchaseInterestedInOtherProducts',
  ),
  [PROPERTY_LOAN_KIND]: 'pages.requestDetails.sections.propertyLoan.fields.propertyLoan.caption',
  [PROPERTY_LOAN_TERM_IN_MONTHS]:
    'pages.requestDetails.sections.propertyLoan.fields.termInMonths.caption',
  [PROPERTY_LOAN_DESCRIPTION]:
    'pages.requestDetails.sections.propertyLoan.fields.description.caption',
  [INVESTMENT_LOAN_TERM_IN_YEARS]: 'pages.inquiryDetails.detailed.loanTermsInYears',
  [FURTHER_NEED_VALUES]: 'pages.inquiryDetails.detailed.furtherNeeds',
  [FUNDING_CLOSING_DATE]: getTranslationPath('fundingClosing', 'closingDate'),
  [FURTHER_NEED]:
    'pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.needFurtherAdvice.caption',
};

export const bfsServiceRequestDetailsLabels = {
  [OFFER_PAYOUT_RATIO]:
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.payoutRatio.caption',
  [OFFER_FACTORING_LINE]:
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringLine.caption',
  [OFFER_FACTORING_FEE]:
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringFee.caption',
  [FURTHER_NEED]:
    'pages.bfsService.sections.factoringAndFactomatFurtherNeeds.fields.needFurtherAdvice.caption',
};

export const requestDetailsKeys = Object.keys(requestDetailsLabelsMap);
