import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import {
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PRE_FINANCING_PERIOD,
} from 'modules/Inquiry/Form/formFields';
import getPurchasedInvoicesPaidOutOnOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/purchasedInvoicesPaidOutOnOptions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { reduceToTranslatedMap } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/useValueTranslationMap';
import { usePurposeKindSpecificValue } from 'shared/choosePurposeKindSpecificComponent';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFormValues } from 'store/hooks/useFormValues';
import { getIndicativeOfferCalculations } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { FINANCING_NEED } from 'utils/sessionStorage/keys';

import { Description } from './styles';

export const ConditionDescription = () => {
  const t = useTranslations();
  const { formatMessage } = useIntl();
  const formValues = useFormValues();
  const financingNeedFromStorage = window.sessionStorage.getItem(FINANCING_NEED);
  const calculations = useSelector(getIndicativeOfferCalculations);
  const purposeKind = financingNeedFromStorage
    ? JSON.parse(financingNeedFromStorage)?.[PURPOSE_KIND]
    : null;
  const payoutValue = formValues[BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON] as string;
  const payout = getPurchasedInvoicesPaidOutOnOptions(formatMessage, {}).reduce(
    reduceToTranslatedMap,
    {},
  )[payoutValue];
  const preFinancingPeriodValue = usePurposeKindSpecificValue({
    [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: '60',
    default: formValues[PRE_FINANCING_PERIOD],
  });
  const preFinancingPeriod = `${preFinancingPeriodValue} ${t('other.days')}`;

  const descriptionKey =
    purposeKind === PURPOSE_KIND__FACTOMAT
      ? 'pages.inquiryDetails.dashboard.actions.factoringOffer.factomatDescription'
      : 'pages.inquiryDetails.dashboard.actions.factoringOffer.description';
  const description = t(descriptionKey, {
    payout,
    preFinancingPeriod,
  });

  return (
    <Condition condition={!!calculations}>
      <Description>{description}</Description>
    </Condition>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: ConditionDescription,
  default: () => null,
});
