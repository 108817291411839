import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { StyledDetailedHeaderSubtitle } from 'modules/InquiryDetails/InquiryDetailsHeader/StyledDetailsHeader';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import ProcessingStatusList from './ProcessingStatusList';

const ProcessingStatusModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslations();

  const date: Date = new Date();
  const formattedDate = `${t('pages.inquiryDetails.header.from')} ${formatDateWithTime(date)}`;

  return (
    <>
      <ButtonComponent
        onClick={onOpen}
        variant="primary"
        data-testid={'Processing-Status-Details-Button'}
      >
        {t('pages.inquiryDetails.dashboard.summary.ButtonViewDetails')}
      </ButtonComponent>
      <ModalComponent
        testId="compeon-external-state-modal"
        isOpen={isOpen}
        onClose={onClose}
        title={t('pages.inquiryDetails.dashboard.summary.compeonExternalState.title')}
        footer={
          <ButtonComponent
            onClick={onClose}
            variant="primary"
            data-testid={'Processing-Status-Modal-Close-Button'}
          >
            {t('other.close')}
          </ButtonComponent>
        }
      >
        <StyledDetailedHeaderSubtitle>{formattedDate}</StyledDetailedHeaderSubtitle>
        <ProcessingStatusList />
      </ModalComponent>
    </>
  );
};

export default ProcessingStatusModal;
