import { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { mapInquiryToApi } from 'modules/Inquiry/mapInquiryToApi/mapInquiryToApi';
import FastlaneService from 'pages/inquiryFlow/SummaryPage/Fastlane/fastlane.service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getFastLane, getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { userIsAgent } from 'utils/user/conditionals';

import { ACTIONS } from './actions';
import { useInquiryValues } from '../../../../components/App/InquiryValuesProvider';

const useGetInquiryDataForFastlane = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const fastlaneState = useSelector(getFastLane);
  const formData = useInquiryValues(partnerId);

  return useCallback(
    () => {
      const inquiryData = mapInquiryToApi(formData);
      inquiryData.data['inquiry-id'] = inquiryId;
      inquiryData.data.attributes['file-upload-request-ids'] =
        fastlaneState.fastlaneInquiryFileRequests.map((req) => req.id) || [];
      return inquiryData;
    },
    // need to disabled to not reload on fastlane docs change
    [inquiryId, formData], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const useInquiryFastlineCheck = () => {
  const isFastlaneEnabled = useConfig(CONFIG.IS_FASTLANE_ENABLED);
  const fastlaneState = useSelector(getFastLane);
  const getInquiryData = useGetInquiryDataForFastlane();
  const { makeCall, isPending: isLoading } = useDispatchApiCall({ isPendingInitially: true });
  const dispatch = useDispatch();

  const checkIsInquiryForFastlane = useCallback(async () => {
    const inquiryData = getInquiryData();

    const { error, payload } = await makeCall(
      FastlaneService.fastlaneCheckApiAction({ ...inquiryData }),
    );
    if (error) {
      dispatch({ type: ACTIONS.SET_ERROR_CHECKING_FASTLANE, data: true });
    } else {
      const { fileUploadRequests, fastlaneMode, fastlaneErrors } =
        FastlaneService.mapFromApiInterface(payload);
      dispatch({ type: ACTIONS.SET_FASTLANE_INQUIRY_FILE_REQUESTS, data: fileUploadRequests });
      dispatch({ type: ACTIONS.SET_FASTLANE_ERRORS, data: fastlaneErrors });
      dispatch({ type: ACTIONS.SET_IS_QUALIFIED_FOR_FASTLANE, data: fastlaneMode });
    }
  }, [dispatch, getInquiryData, makeCall]);

  /**
   * We have to remove the dependency for checkIsInquiryForFastlane in this dependency array since the "getInquiryData" property
   * inside the useCallback hook from above change with each rerender and therefore the checkIsInquiryForFastlane function is
   * called indefinitely.
   */
  useEffect(() => {
    if (isFastlaneEnabled) {
      checkIsInquiryForFastlane();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFastlaneEnabled]);

  const isAgent = userIsAgent();

  useEffect(() => {
    const isInquiryQualifiedToFastlane = !isLoading && fastlaneState.isQualifiedForFastlane;

    dispatch({
      type: ACTIONS.SET_ARE_DOCUMENTS_MANDATORY,
      data: !isAgent && isInquiryQualifiedToFastlane,
    });
  }, [dispatch, fastlaneState.isQualifiedForFastlane, isAgent, isLoading]);

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_FASTLANE_LOADING, data: isLoading });
  }, [dispatch, isLoading]);

  useEffect(() => {}, []);
};
export default useInquiryFastlineCheck;
