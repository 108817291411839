import { useCallback, useState } from 'react';

const progressEventToPercent = (onProgressChangeEvent: any) => {
  if (onProgressChangeEvent.lengthComputable) {
    return Math.floor((onProgressChangeEvent.loaded / onProgressChangeEvent.total) * 100);
  }
  return 50;
};

const useUploadProgress = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const handleUploadProgressChange = useCallback((event: any) => {
    void setUploadProgress(progressEventToPercent(event));
  }, []);

  return { uploadProgress, handleUploadProgressChange };
};

export default useUploadProgress;
