import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { USER_PROFILE_FOUNDING_TWO_YEAR_OLD } from 'modules/Inquiry/Form/formFields';

// This field is used for KFINANZ to ask with a radio button if the company is two years older or not
// Caption comes from userProfileLabels.ts
// Validations come from userProfile.fieldValidationMap.ts
const FoundingYearRadioField = () => {
  return (
    <>
      <YesNoRadioGroupWithField name={USER_PROFILE_FOUNDING_TWO_YEAR_OLD} />
    </>
  );
};

export default FoundingYearRadioField;
