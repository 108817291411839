import React from 'react';

import { bool } from 'prop-types';
import debounceRender from 'react-debounce-render';

import CheckmarkIcon from 'theme/components/Icon/CheckmarkIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';

import { StyledSpinner, StyledIcon } from './styles';

const AvailableEmailSymbol = ({ isLoading, isAllowedToInquiry }) => {
  if (isLoading) {
    return <StyledSpinner />;
  }

  return (
    <StyledIcon isSuccess={isAllowedToInquiry}>
      {isAllowedToInquiry ? <CheckmarkIcon boxSize={12} /> : <CloseIcon boxSize={6} />}
    </StyledIcon>
  );
};

AvailableEmailSymbol.defaultProps = {
  isLoading: false,
  isAllowedToInquiry: null,
};

AvailableEmailSymbol.propTypes = {
  isLoading: bool,
  isAllowedToInquiry: bool,
};

export default debounceRender(AvailableEmailSymbol, 300);
