import React from 'react';

import { PageHeadingWrapper } from 'components/PageHeading/styles';
import { Heading, Subheading } from 'components/Text';
import CheckmarkLargeIcon from 'theme/components/Icon/CheckmarkLargeIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';

import { IconWrapper } from './styles';
import { SuccessPageHeadingProps } from './types';
import { InnerPageContainer } from '../Page/Page';

const SuccessPageHeading = ({
  heading,
  subheading,
  iconType,
  success = true,
}: SuccessPageHeadingProps) => (
  <PageHeadingWrapper>
    <InnerPageContainer>
      <Heading as="h2" success={success}>
        {heading}
      </Heading>
      <IconWrapper>
        {iconType === 'checkmark' && <CheckmarkLargeIcon boxSize={16} color="status.success" />}
        {iconType === 'close' && <CloseIcon boxSize={12} color="icon.danger" />}
      </IconWrapper>
      <Subheading as="p">{subheading}</Subheading>
    </InnerPageContainer>
  </PageHeadingWrapper>
);

export default SuccessPageHeading;
