import { StaticField } from 'modules/helpers/useOfferFields';
import { OFFER_FACTORING_LINE, OFFER_PAYOUT_RATIO } from 'modules/Inquiry/Form/formFields';
import {
  formatPriceForFactoringLine,
  replaceDotWithComma,
  formatPercentage,
} from 'utils/valueFormats';

export const getIndicativeOfferFormattedValueMap = (item: StaticField, value: string) => {
  value = value.replace(/\s/g, '');
  switch (item.name) {
    case OFFER_FACTORING_LINE:
      return `${formatPriceForFactoringLine(parseFloat(value))}`;
    case OFFER_PAYOUT_RATIO:
      // see /src/modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers.ts for reference
      return `${replaceDotWithComma(formatPercentage(Number(value) / 100).toString())}${item.unit}`;
    default:
      return `${replaceDotWithComma(value)}${item.unit}`;
  }
};
