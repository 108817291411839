import React from 'react';

import PageHeading from 'components/PageHeading';
import { PurposeSection } from 'hausbank/inquiry/steps/financingNeed/sections/purposeSection';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinancingNeedSection } from './sections/financingNeedSection';

export const HausbankFinancingNeedPage = () => {
  const t = useTranslations();
  const {
    heading: { header, subheader },
    progressBarTitle,
  } = translations.inquiryType.hausbank.pages.financingNeed;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <FinancingNeedSection />
      <PurposeSection />
    </Page>
  );
};
