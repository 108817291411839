import React from 'react';

import paths from 'constants/paths';
import { hausbankRouteToPageNameMap } from 'hausbank/inquiry/config/routeToPageNameMap';
import { StyledLink } from 'modules/AvailableEmail/EmailNotAvailable/styles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const EmailLoginLink = ({ email }: { email: string }) => {
  const redirectPath = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: paths.contactPersonDetails,
    [InquiryType.hausbank]: hausbankRouteToPageNameMap.personalDataPage.create,
    default: paths.userProfile,
  });
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail.errors.emailTaken',
  );

  return (
    <StyledLink
      to={{
        pathname: paths.customer.login,
        state: { redirectTo: redirectPath, email },
      }}
    >
      {t('logIn')}
    </StyledLink>
  );
};

export default EmailLoginLink;
