import { ReactNode } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import paths from 'constants/paths';
import { UserRole } from 'constants/user/userRoles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { mapRouteDeclarationToNavLink } from 'utils/routeUtils';
import { hasPermission as hasPermissionSelector } from 'utils/user/conditionals';

enum CompaniesDetailsRouteName {
  Overview = 'overview',
  AssociatedPersons = 'associatedPersons',
  RiskAnalysis = 'riskAnalysis',
  FinancialInformation = 'financialInformation',
}

export interface CompaniesDetailsRouteDeclaration {
  name: CompaniesDetailsRouteName;
  path: string;
  title: string;
  permission?: UserRole[];
}

const {
  operation: { companies: companiesPaths },
} = paths;

const companiesDetailsRoutes = [
  {
    name: CompaniesDetailsRouteName.Overview,
    path: companiesPaths.overview(),
    title: 'pages.companiesDetails.overview.title',
  },
  {
    name: CompaniesDetailsRouteName.AssociatedPersons,
    path: companiesPaths.associatedPersons(),
    title: 'pages.companiesDetails.associatedPerson.title',
  },
  {
    name: CompaniesDetailsRouteName.RiskAnalysis,
    path: companiesPaths.riskAnalysis(),
    title: 'pages.companiesDetails.riskAnalysis.title',
  },
  {
    name: CompaniesDetailsRouteName.FinancialInformation,
    path: companiesPaths.financialInformation(),
    title: 'pages.companiesDetails.financialInformation.title',
  },
];

const defaultRoutes = [
  CompaniesDetailsRouteName.Overview,
  CompaniesDetailsRouteName.AssociatedPersons,
  CompaniesDetailsRouteName.RiskAnalysis,
  CompaniesDetailsRouteName.FinancialInformation,
];

const inquiryTypeRoutes: { [type in InquiryType]: CompaniesDetailsRouteName[] } = {
  [InquiryType.default]: defaultRoutes,
  [InquiryType.onlinefactoring]: defaultRoutes,
  [InquiryType.leaseplan]: defaultRoutes,
  [InquiryType.bfs]: defaultRoutes,
  [InquiryType.profiRLL]: defaultRoutes,
  [InquiryType.profiMittweida]: defaultRoutes,
  [InquiryType.bfsService]: defaultRoutes,
  [InquiryType.mmv]: defaultRoutes,
  [InquiryType.dzb]: [
    CompaniesDetailsRouteName.Overview,
    CompaniesDetailsRouteName.AssociatedPersons,
  ],
  [InquiryType.hausbank]: [],
};

const getRouteDeclaration = (
  routeName: CompaniesDetailsRouteName,
): CompaniesDetailsRouteDeclaration | undefined =>
  companiesDetailsRoutes.find((route) => route.name === routeName);

export const useCompaniesDetailsRoutes = (): ReactNode[] => {
  const inquiryType = useSelector(getInquiryFormTypeSelector) as InquiryType;
  const t = useTranslations();
  const hasPermission = useSelector(hasPermissionSelector);
  const { companyId, id: inquiryId } = useParams<{ companyId: string; id: string | undefined }>();
  const routes = inquiryTypeRoutes[inquiryType] ?? inquiryTypeRoutes.default;
  const routeDeclarations = routes
    .map(getRouteDeclaration)
    .filter(Boolean) as CompaniesDetailsRouteDeclaration[];

  return mapRouteDeclarationToNavLink(routeDeclarations, t, hasPermission, companyId, inquiryId);
};
