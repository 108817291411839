import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import FormSection from 'components/FormSection';
import { useFormConfig } from 'config/formConfig/hooks';
import {
  BENEFICIARY_OWNER_DETAILS,
  CONTACT_PERSON_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { useInquiryStepData } from 'modules/Inquiry/inquiryNavigation/useInquiryStepData';
import EditStepButton from 'modules/SummaryTable/EditStepButton/EditStepButton';
import { useBeneficiariesForSummaryStep } from 'pages/inquiryFlow/SummaryPage/BeneficiaryOwnerSummaryStep';
import SummaryTableStep from 'pages/inquiryFlow/SummaryPage/NewSummaryPage/SummaryTableStep';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useStepSegmentByInquiryType } from '../Inquiry/inquiryNavigation/stepSegments';

const SummaryTable: React.FC = () => {
  const { stepsForSummary } = useInquiryStepData();
  const { selectedInquiryType } = useFormConfig();
  const { hasBeneficiaries } = useBeneficiariesForSummaryStep();

  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const isBfss = selectedInquiryType === InquiryType.bfsService;

  const inquiryId = useSelector(getInquiryIdSelector);
  const filteredStepsForSummary = stepsForSummary.filter((step) => {
    return !(
      (isProfiInquiry && step === CONTACT_PERSON_DETAILS && !inquiryId) ||
      (isProfiInquiry && step === USER_PROFILE && inquiryId) ||
      (isBfss && step === BENEFICIARY_OWNER_DETAILS && !hasBeneficiaries)
    );
  });
  const inquiryPathSegments = useStepSegmentByInquiryType();
  const processLane = useSelector(getProcessLane);

  const isContract = processLane === InquiryLane.contract;
  const isOnlineFactoring = selectedInquiryType === InquiryType.onlinefactoring;

  const t = useTranslations();

  return (
    <div>
      {filteredStepsForSummary?.map((stepName, index) => {
        const boxVisibility =
          isOnlineFactoring && !isContract && stepName === REQUEST_DETAILS ? 'hidden' : 'visible';

        return (
          <FormSection
            key={stepName}
            sectionNumber={index + 1}
            title={
              t(inquiryPathSegments[stepName].sectionTitle, {
                br: <br />,
              }) || t(inquiryPathSegments[stepName].progressBarTitle)
            }
          >
            <Flex
              justify="space-between"
              alignItems={{ xl: 'flex-end' }}
              direction={{ base: 'column', xl: 'row' }}
            >
              <Box flex={1}>
                <SummaryTableStep stepName={stepName} />
              </Box>
              <Box
                visibility={boxVisibility}
                mb={{ base: 0, xl: '2.8rem' }}
                mr={{ base: '4rem', xl: 0 }}
              >
                <EditStepButton stepName={stepName} />
              </Box>
            </Flex>
          </FormSection>
        );
      })}
    </div>
  );
};

export default SummaryTable;
