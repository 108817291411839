import {
  COMPANY_DETAILS_INSTITUTIONAL_NUMBERS,
  INCOME_SURPLUS,
} from 'modules/Inquiry/Form/formFields';

const customValueTranslationMap = {
  [INCOME_SURPLUS]: (fieldValue: boolean, t: any) => {
    if (fieldValue === true) {
      return t(
        'pages.userProfile.sections.completingCompanyDetails.fields.incomeSurplus.options.euro',
      );
    } else if (fieldValue === false) {
      return t(
        'pages.userProfile.sections.completingCompanyDetails.fields.incomeSurplus.options.balance',
      );
    }
  },
  [COMPANY_DETAILS_INSTITUTIONAL_NUMBERS]: (fieldValue: {} = {}) => {
    return Object.values(fieldValue).join(', ');
  },
};

export default customValueTranslationMap;
