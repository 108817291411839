import React, { ReactNode, useMemo } from 'react';

import { FeatureIcon } from 'components/FeatureIcon/FeatureIcon';
import FormSection from 'components/FormSection';
import { SmallTileListWithField } from 'components/SmallTileList/SmallTileList';
import { useFormConfig } from 'config/formConfig/hooks';
import { FormValidator } from 'models/types/FormValidator.type';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  ROLES__FILE_HALTER,
  ROLES__PROPERTY_DEVELOPER,
  ROLES__PARTITION,
  ROLES__PROPERTY_BUYER,
  PROJECT_FINANCING_ROLE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

interface RoleOption {
  key: string;
  title: string;
  icon: ReactNode;
}

const optionSchema: RoleOption[] = [
  {
    key: ROLES__FILE_HALTER,
    title: 'pages.financingNeed.sections.role.fields.role.options.fileHalter',
    icon: <FeatureIcon feature={PROJECT_FINANCING_ROLE} value={ROLES__FILE_HALTER} />,
  },
  {
    key: ROLES__PROPERTY_DEVELOPER,
    title: 'pages.financingNeed.sections.role.fields.role.options.propertyDeveloper',
    icon: <FeatureIcon feature={PROJECT_FINANCING_ROLE} value={ROLES__PROPERTY_DEVELOPER} />,
  },
  {
    key: ROLES__PARTITION,
    title: 'pages.financingNeed.sections.role.fields.role.options.allocationTable',
    icon: <FeatureIcon feature={PROJECT_FINANCING_ROLE} value={ROLES__PARTITION} />,
  },
  {
    key: ROLES__PROPERTY_BUYER,
    title: 'pages.financingNeed.sections.role.fields.role.options.propertyBuyer',
    icon: <FeatureIcon feature={PROJECT_FINANCING_ROLE} value={ROLES__PROPERTY_BUYER} />,
  },
];

const RoleSubsection: React.FC = () => {
  const t = useTranslations();

  const options = useMemo(
    () => optionSchema.map((option) => ({ ...option, title: t(option.title) })),
    [t],
  );

  const labelTranslator = useInquiryLabelTranslator();
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;

  return (
    <FormSection
      title={labelTranslator(PROJECT_FINANCING_ROLE)}
      name={FormSections.projectFinancingRole}
      sectionNumber={isProfiInquiry ? 1 : 2}
    >
      <SmallTileListWithField noCaption name={PROJECT_FINANCING_ROLE} options={options} />
    </FormSection>
  );
};

export default React.memo(RoleSubsection);

export const validateRoleSection: FormValidator = (form) =>
  fieldIsValid(PROJECT_FINANCING_ROLE)({ form });
