import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { TypeOfFinancialStatements } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getDzbTypeOfFinancialStatementsOptions = (
  formatMessage: FormatMessage,
): SelectOption<TypeOfFinancialStatements>[] => {
  const { balance, excessIncomeStatement } =
    translations.inquiryType.dzb.pages.companyData.sections.companyInformation.fields
      .typeOfFinancialStatements.options;

  const options: Array<{ id: string; value: TypeOfFinancialStatements }> = [
    {
      id: balance,
      value: 'balance',
    },
    {
      id: excessIncomeStatement,
      value: 'income_surplus_account',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
