export enum FileKind {
  MMV_ORDER_CONFIRMATION = 'mmv_order_confirmation',
  MMV_OBJECT_CONFIRMATION = 'mmv_object_confirmation',
  MMV_CONTRACT = 'mmv_contract',
  HAUSBANK_CONTRACT = 'hausbank_contract',
  HAUSBANK_SIGNED_CONTRACT = 'hausbank_signed_contract',
  HAUSBANK_VIDEO_LEGITIMATION = 'hausbank_video_legitimation',
  DZB_CONTRACT = 'dzb_contract',
  DZB_WELCOME_PACKAGE = 'dzb_welcome_package',
  DZB_COMPANY_INFORMATION = 'dzb_company_information',
  DZB_SIGNED_CONTRACT = 'dzb_signed_contract',
  DZB_VIDEO_LEGITIMATION = 'dzb_video_legitimation',
}

export type FileKindType = keyof typeof FileKind | string;
