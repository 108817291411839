import React from 'react';

import { func, bool, node, string } from 'prop-types';

import { Sidebar, IconWrapper, IconButton, ButtonText } from 'components/Sidebar/styles';
import Spinner from 'components/Spinner';
import { Container } from 'modules/ContactSidebar/CallbackSidebar/styles';

const InquiryDraftModeSidebar = ({ onClick, isLoading, icon, disabled, text }) => {
  return (
    <Container>
      <Sidebar>
        <IconWrapper>
          <IconButton disabled={disabled} onClick={onClick}>
            {isLoading ? (
              <Spinner inverted />
            ) : (
              <>
                {icon}
                <ButtonText>{text}</ButtonText>
              </>
            )}
          </IconButton>
        </IconWrapper>
      </Sidebar>
    </Container>
  );
};

InquiryDraftModeSidebar.defaultProps = {
  disabled: false,
  isLoading: false,
};

InquiryDraftModeSidebar.propTypes = {
  onClick: func.isRequired,
  isLoading: bool,
  icon: node.isRequired,
  disabled: bool,
  text: string.isRequired,
};
export default InquiryDraftModeSidebar;
