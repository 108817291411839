import env from '@beam-australia/react-env';

const environmentTypes = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

// If any env is added, please update this list for easier development
export enum EnvironmentVariable {
  COMPANIES_LIST = 'COMPANIES_LIST',
  PORTAL_INQUIRY = 'PORTAL_INQUIRY',
  WS_URL = 'WS_URL',
  ENVIRONMENT = 'ENVIRONMENT',
  SERVER_ENVIRONMENT = 'SERVER_ENVIRONMENT',
  WHITE_LABEL_API_URL = 'WHITE_LABEL_API_URL',
  COMPEON_API_URL = 'COMPEON_API_URL',
  AUTH_API_URL = 'AUTH_API_URL',
  COMPEON_OAUTH_API_URL = 'COMPEON_OAUTH_API_URL',
  COMPEON_OAUTH_CLIENT_ID = 'COMPEON_OAUTH_CLIENT_ID',
  LOGO_STORAGE_URL = 'LOGO_STORAGE_URL',
  BFSS_USERCENTRICS_DATA_SETTINGS_ID = 'BFSS_USERCENTRICS_DATA_SETTINGS_ID',
}

export const isDevelopment = () =>
  env(EnvironmentVariable.ENVIRONMENT) === environmentTypes.DEVELOPMENT;

export const isProduction = () =>
  env(EnvironmentVariable.ENVIRONMENT) === environmentTypes.PRODUCTION;

export const getEnvironmentFeatureFlag = (flagName: EnvironmentVariable) =>
  env(flagName.toString());
