import React from 'react';

import { Grid } from '@chakra-ui/react';
import { useField } from 'react-final-form';

import Content from 'components/FormSection/Content';
import { RenderedHiddenInputWithField } from 'components/HiddenInput/RenderedHiddenInput';
import { hausbankFields } from 'hausbank/inquiry/fields';
import { hausbankPurposeKinds } from 'hausbank/inquiry/steps/financingNeed/sections/purposeSection/purposeKinds/purposeKinds';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';

import { PurposeKindItem } from './PurposeKindItem';

export const HausbankPurposeKind = () => {
  const { purposeKind } = hausbankFields.financingNeedPage.purposeSection;
  const {
    meta: { error: purposeKindError, touched },
  } = useField(purposeKind);

  return (
    <Content>
      <RenderedHiddenInputWithField name={purposeKind} />
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={12}
      >
        {Object.values(hausbankPurposeKinds)
          .sort((a, b) => a.order - b.order)
          .map((purposeKind) => {
            return (
              <PurposeKindItem purposeKindItem={purposeKind} key={purposeKind.translationString} />
            );
          })}
      </Grid>
      <FieldError message={touched ? purposeKindError : undefined} />
    </Content>
  );
};
