import {
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
  FAVORED_DECISION_DATE,
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  HIRE_PURCHASE_TERM_IN_MONTHS,
  INVESTMENT_LOAN_ADVANCE_PAYMENT,
  INVESTMENT_LOAN_TERM_IN_MONTHS,
  LEASING_ADVANCE_PAYMENT,
  LEASING_RESIDUAL_VALUE,
  LEASING_TERM_IN_MONTHS,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_TERM,
  MACHINE_DELIVERY_DATE,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const requestDetailsUnits = {
  [LOAN_TERM]: FIELD_UNIT_TYPE.MONTHS,
  [LOAN_INTEREST_PERIOD_IN_MONTHS]: FIELD_UNIT_TYPE.MONTHS,
  [LEASING_ADVANCE_PAYMENT]: FIELD_UNIT_TYPE.CURRENCY,
  [LEASING_TERM_IN_MONTHS]: FIELD_UNIT_TYPE.MONTHS,
  [LEASING_RESIDUAL_VALUE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [FACTORING_FOREIGN_SHARE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER]: FIELD_UNIT_TYPE.PERCENTAGE,
  [INVESTMENT_LOAN_ADVANCE_PAYMENT]: FIELD_UNIT_TYPE.CURRENCY,
  [INVESTMENT_LOAN_TERM_IN_MONTHS]: FIELD_UNIT_TYPE.MONTHS,
  [HIRE_PURCHASE_ADVANCE_PAYMENT]: FIELD_UNIT_TYPE.CURRENCY,
  [HIRE_PURCHASE_TERM_IN_MONTHS]: FIELD_UNIT_TYPE.MONTHS,
  [HIRE_PURCHASE_RESIDUAL_VALUE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [FAVORED_DECISION_DATE]: FIELD_UNIT_TYPE.DATE,
  [MACHINE_DELIVERY_DATE]: FIELD_UNIT_TYPE.DATE,
  [PROPERTY_LOAN_TERM_IN_MONTHS]: FIELD_UNIT_TYPE.MONTHS,
  [USER_PROFILE_DETAILED_ANNUAL_TURNOVER]: FIELD_UNIT_TYPE.CURRENCY,
};
