import { FieldTypes } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { StepData } from 'new/form/model';

//TODO the next and previous could be resolved by using he stepSequence definition
export const stepData: StepData<FieldTypes> = {
  contractDetails: {
    progressBarTitle: translations.inquiryType.mmv.steps.contractDetails.progressBarTitle,
    nextStep: 'customerDataPage',
  },
  customerDataPage: {
    progressBarTitle: translations.inquiryType.mmv.steps.customerDataPage.progressBarTitle,
    previousStep: 'contractDetails',
  },
};
