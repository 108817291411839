import { useEffect, useState, useCallback } from 'react';

import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

import { loadBanksAction } from 'api/CompeonApi/loadBanks/service';
import { MultiSelectOption } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { pipe } from 'utils/helpers';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { isNumeric } from 'utils/isNumeric';

const INITIAL_PAGE_NUMBER = 1;
const FETCH_DEBOUNCE = 300; // in ms

const sortByLabel = (list: MultiSelectOption[]) => _sortBy(list, ({ label }) => label);
const getDataFromPayload = (payload: any) => _get(payload, 'data.data') || [];
const mapBankCodesFromApi = (apiBankResponse: any): MultiSelectOption[] =>
  apiBankResponse.map(({ attributes: { name, code } }: any) => ({
    key: code,
    label: name,
    value: code,
  }));

export const useFetchBankCodes = () => {
  const [search, setSearch] = useState<string>('');
  const [banks, setBanks] = useState<MultiSelectOption[]>([]);
  const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE_NUMBER);
  const { makeCall, isPending: isLoading } = useDispatchApiCall();

  const fetchNextPage = () => {
    if (!hasReachedEnd) {
      setCurrentPage((page) => page + 1);
    }
  };

  const changeBankSearch = (search: string) => {
    setSearch(search);
    setCurrentPage(INITIAL_PAGE_NUMBER);
    setHasReachedEnd(false);
  };

  const fetchBanks = useCallback(
    async (page: number, name: string, code: string) => {
      if (hasReachedEnd) {
        return;
      }
      const { payload } = await makeCall(loadBanksAction(page, name, code));
      const mappedBanks = pipe(getDataFromPayload, mapBankCodesFromApi, sortByLabel)(payload);

      if (page === INITIAL_PAGE_NUMBER) {
        setBanks(mappedBanks);
      } else {
        setBanks((banks) => [...banks, ...mappedBanks]);
      }
      if (mappedBanks.length === 0) {
        setHasReachedEnd(true);
      }
    },
    [hasReachedEnd, makeCall],
  );

  useEffect(() => {
    const timmer = setTimeout(() => {
      const name = isNumeric(search) ? search : '';
      const code = isNumeric(search) ? '' : search;
      fetchBanks(currentPage, name, code);
    }, FETCH_DEBOUNCE);

    return () => clearTimeout(timmer);
  }, [currentPage, search, fetchBanks]);

  return {
    changeBankSearch,
    fetchNextPage,
    isLoading,
    banks,
  };
};
