import React from 'react';

import { useIntl } from 'react-intl';

import { RadioGroupWithField } from 'components/RadioGroup';
import {
  REAL_ESTATE_TAXATION,
  REAL_ESTATE_TAXATION__NET,
  REAL_ESTATE_TAXATION__GROSS,
} from 'modules/Inquiry/Form/formFields';

const getNettoBruttoOptions = (formatMessage: Function) =>
  [
    {
      id: 'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateTaxation.options.netto',
      value: REAL_ESTATE_TAXATION__NET,
    },
    {
      id: 'pages.financingNeed.sections.detailsOfBuilding.fields.realEstateTaxation.options.brutto',
      value: REAL_ESTATE_TAXATION__GROSS,
    },
  ].map(({ id, value }) => ({ value, caption: formatMessage({ id }) }));

const RealEstateTaxation = () => {
  const { formatMessage } = useIntl();
  return (
    <RadioGroupWithField
      name={REAL_ESTATE_TAXATION}
      optional
      options={getNettoBruttoOptions(formatMessage)}
    />
  );
};

export default RealEstateTaxation;
