import React from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { FileKind } from 'models/FileKind.type';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import { DzbContractFiles } from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/DzbContractFiles';
import { DzbVideoLegitimationFiles } from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/DzbVideoLegitimationFiles';
import ProcessSpecificFiles from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/ProcessSpecificFiles';
import AdditionalFilesFromCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/AdditionalFilesFromCustomer/AdditionalFilesFromCustomer';
import FilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/FilesForCustomer/FilesForCustomer';
import { InternalFiles } from 'pages/operationPortal/InquiryDetails/DocumentExchange/InternalFiles';
import RequestsForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/RequestsForCustomer';

import { useOperationDocumentExchange } from './useOperationDocumentExchange';

export const DZBOperationDocumentExchange = () => {
  const { isLoading } = useOperationDocumentExchange();

  const allowedFileKinds = [
    FileKind.DZB_COMPANY_INFORMATION,
    FileKind.DZB_CONTRACT,
    FileKind.DZB_WELCOME_PACKAGE,
  ];

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <article>
      <InquiryDetailsHeader />
      <DzbContractFiles />
      <DzbVideoLegitimationFiles />
      <RequestsForCustomer />
      <AdditionalFilesFromCustomer />
      <ProcessSpecificFiles kinds={allowedFileKinds} />
      <InternalFiles />
      <FilesForCustomer />
    </article>
  );
};
