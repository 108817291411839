import React, { useEffect, useState, useCallback } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router-dom';

import PortalPage from 'components/PortalPage';
import Spinner from 'components/Spinner';
import { forwardToCompeonAction } from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/service';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { exchangeTokenAction } from './service';

const errorRedirectionTimeout = 4000;

export interface IState {
  params: {
    inquiryId: string;
    destinationUrl: string;
    redirectUri: string;
  };
}

type IRedirectParams = {
  code: string;
  state: string;
};

function OAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const { makeCall } = useDispatchApiCall();
  const { search } = useLocation();
  const { code, state: stringifiedState } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as IRedirectParams;
  const { params }: IState = JSON.parse(stringifiedState);
  const { inquiryId, destinationUrl, redirectUri } = params;

  const redirectToDestination = useCallback(() => {
    window.location.href = destinationUrl;
  }, [destinationUrl]);

  useEffect(() => {
    async function exchangeToken() {
      const { error: exchangeTokenError, payload: exchangeTokenPayload } = await makeCall(
        exchangeTokenAction(code, redirectUri),
      );
      const { error: forwardError } = await makeCall(
        forwardToCompeonAction(inquiryId, { accessToken: exchangeTokenPayload.data.access_token }),
      );

      if (exchangeTokenError || forwardError) {
        setTimeout(redirectToDestination, errorRedirectionTimeout);
      } else {
        redirectToDestination();
      }
    }

    if (isLoading) {
      setIsLoading(false);
      exchangeToken();
    }
  }, [code, inquiryId, isLoading, makeCall, redirectToDestination, redirectUri]);

  return (
    <PortalPage>
      <Spinner />
    </PortalPage>
  );
}

export default OAuth;
