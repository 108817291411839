import React from 'react';

import { WrappedComponentProps } from 'react-intl';

import { ButtonComponent } from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { withIntl } from 'utils/intl';

type Props = {
  to: string;
} & WrappedComponentProps;

const EditInquiryLink = ({ to, intl: { formatMessage } }: Props) => {
  return (
    <ButtonComponent
      as="a"
      data-testid="edit-inquiry-btn"
      href={to}
      leftIcon={<EditIcon boxSize={6} display="block" />}
      variant="secondary"
    >
      {formatMessage({ id: 'components.editInquiryLink.buttonText' })}
    </ButtonComponent>
  );
};

export default withIntl(EditInquiryLink);
