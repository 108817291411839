import styled from 'styled-components/macro';

import { FONT_WEIGHT_BOLD, FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';
import { borderRadius, boxShadow } from 'theme/themeConstants';
// TODO: refactor to chakra components
export const StyledFileContent = styled.div`
  overflow: hidden;
  flex: 2.5 1 0%;
  margin-bottom: 1.5rem;
  padding: 0 2rem;

  ${mqMedium`
    margin-bottom: 0;
  `}
`;

export const StyledTitleWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 0.5rem;

  ${mqMedium`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `}
`;

export const StyledFileTitle = styled.h5`
  max-width: 100%;
  font-size: 1.25rem;
  font-weight: ${FONT_WEIGHT_BOLD};
  line-height: 1.25;
  overflow-wrap: break-word;

  :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const StyledUploadedFileInfo = styled.div`
  font-style: italic;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledRequestActionListItem = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

export const StyledFileBlockWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: ${borderRadius.large};
  padding: 2rem;
  box-shadow: ${boxShadow.default};
  text-align: center;
  background-color: ${(props) => props.theme.colors.background.white};
  width: 100%;

  ${mqMedium`
    display: flex;
    align-items: center;
    text-align: left;
  `}
`;

export const StyledFileStatusLabel = styled.span`
  display: inline-block;
  border-radius: ${borderRadius.default};
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.status.error};
`;
