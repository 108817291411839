import { HausbankFieldTypes } from 'hausbank/inquiry/fields';

type HausbankContactPersonFieldTypes =
  HausbankFieldTypes['personalDataPage']['contactPersonSection'];

export const mapHausbankContactPerson = (values: HausbankContactPersonFieldTypes) => ({
  email: values.email,
  salutation: values.salutation,
  first_name: values.firstName,
  last_name: values.lastName,
  phone_number: values.phoneNumber,
});
