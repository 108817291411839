import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export const RoofIcon = (props: IconProps) => {
  return (
    <Icon
      width={'42'}
      height={'42'}
      viewBox={'0 0 42 42'}
      aria-hidden={'true'}
      fill={'white'}
      fillOpacity={0}
      {...props}
    >
      <path d="M8.68605 10.2388L2.84326 18.7374" />
      <path d="M22.0505 29.3473L9.18586 10.4287H31.3477L44.2124 29.3473H22.0505Z" />
      <line x1="19.0503" y1="24.895" x2="19.0503" y2="40.2899" />
      <path d="M36.6937 15.952H9.57764M41.8131 22.7713L13.8853 22.7713M35.2283 32.4829L20.507 9.39551" />
      <line x1="21.3861" y1="31.1822" x2="7.0442" y2="10.2111" />
      <line x1="44.436" y1="31.0439" x2="21.4327" y2="31.0439" />
      <line x1="42.313" y1="29.8477" x2="42.313" y2="40.2907" />
      <rect x="6.0415" y="20.3882" width="4.39903" height="7.11011" />
    </Icon>
  );
};
