import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { NewStaticFieldWithField } from 'components/StaticField';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useRequireLastAnnualFinancialStatementReportingDate } from 'dzb/inquiry/config/conditions';
import { dzbFields, DzbSections } from 'dzb/inquiry/fields';
import { TypeOfFinancialStatementSelect } from 'dzb/inquiry/steps/companyData/sections/companyInformationSection/components/TypeOfFinancialStatementSelect';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { useTranslations } from 'utils/hooks/useTranslations';

import { filterReportingDates, maxReportingDate, minReportingDate } from './utils';

const {
  companyName,
  address,
  zipCode,
  city,
  country,
  lastAnnualFinancialStatementReportingDate,
  companyOlderThan3Years,
  salesTaxID,
} = dzbFields.companyDataPage.companyInformationSection;

export const CompanyInformationSection = () => {
  const t = useTranslations();
  const {
    title,
    fields: {
      salesTaxId: { tooltip },
      lastAnnualFinancialStatementReportingDate: {
        tooltip: lastAnnualFinancialStatementReportingDateTooltip,
      },
    },
  } = translations.inquiryType.dzb.pages.companyData.sections.companyInformation;
  const requireFinancialStatementReportingDate =
    useRequireLastAnnualFinancialStatementReportingDate();

  return (
    <Condition<DzbSections>
      validation={'associationInformationSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={2}
          placeholder={t(translations.inquiryType.dzb.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={2}>
        <FormRow>
          <NewStaticFieldWithField name={companyName} />
        </FormRow>
        <FormRow>
          <NewStaticFieldWithField name={address} />
          <NewStaticFieldWithField name={zipCode} />
        </FormRow>
        <FormRow>
          <NewStaticFieldWithField name={city} />
          <NewStaticFieldWithField name={country} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={companyOlderThan3Years} />
        </FormRow>
        <FormRow>
          <InputWithField name={salesTaxID} optional tooltip={t(tooltip)} />
        </FormRow>
        <FormRow alignItems={'end'}>
          <TypeOfFinancialStatementSelect />
          <DayPickerWithField
            name={lastAnnualFinancialStatementReportingDate}
            minDate={minReportingDate}
            maxDate={maxReportingDate}
            filterDate={filterReportingDates}
            optional={!requireFinancialStatementReportingDate}
            tooltip={t(lastAnnualFinancialStatementReportingDateTooltip)}
          />
        </FormRow>
      </FormSection>
    </Condition>
  );
};
