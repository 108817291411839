import paths from 'constants/paths';
import { dzbValidations } from 'dzb/inquiry/config/validations';
import { dzbFields } from 'dzb/inquiry/fields';
import { DZB_FORM } from 'dzb/inquiry/index';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

import { dzbConditions } from './conditions';
import { dzbInitialFormState } from './initialFormState';
import { dzbInitialFormValues } from './initialFormValues';
import { dzbRouteToPageNameMap } from './routeToPageNameMap';
import { dzbStepData } from './stepData';
import { dzbStepSequence } from './stepSequence';
import { dzbSummaryFields } from './summaryFields';
import { dzbSummaryPath } from './summaryPath';

export const dzbInquiryProcessConfig: InquiryProcessConfig<InquiryType.dzb> = {
  validations: dzbValidations,
  fields: dzbFields,
  summaryFields: dzbSummaryFields,
  summaryPath: dzbSummaryPath,
  stepData: dzbStepData,
  stepSequence: dzbStepSequence,
  routeToPageNameMap: dzbRouteToPageNameMap,
  initialFormState: dzbInitialFormState,
  initialFormValues: dzbInitialFormValues,
  conditions: dzbConditions,
  formName: DZB_FORM,
  paths: {
    editPaths: {
      editCustomer: paths.customer.inquiryDetails.edit.financingNeed,
      editOperation: paths.operation.inquiryDetails.edit.financingNeed,
    },
    listPaths: {
      editCustomer: paths.customer.inquiryList,
      editOperation: paths.operation.inquiryList,
    },
  },
};
