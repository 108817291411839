import _get from 'lodash/get';

import {
  CUSTOMER_GRADE,
  CUSTOMER_TYPE,
  LOCATION_GRADE,
  NOTES_ON_FINANCING_CRITERIA,
} from 'modules/Inquiry/Form/formFields';

const mapFinancingCriteriaToApi = (data: Record<string, unknown>) => {
  const financingCriteriaData = {
    financing_criteria: {
      customer_grade: _get(data, CUSTOMER_GRADE, ''),
      customer_type: _get(data, CUSTOMER_TYPE, ''),
      location_grade: _get(data, LOCATION_GRADE, ''),
      notes_on_financing_criteria: _get(data, NOTES_ON_FINANCING_CRITERIA, ''),
    },
  };

  return financingCriteriaData;
};

export default mapFinancingCriteriaToApi;
