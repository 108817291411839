import React from 'react';

import { HStack, Icon, VStack, Text, Image } from '@chakra-ui/react';

import { ReactComponent as DocumentIcon } from 'assets/specific-icons/file.svg';
import { LoadingComponent } from 'components/UploadBlock/NewFileRequestBlock/components/LoadingComponent';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { usePropertyProfileObjectImage } from '../../hooks/usePropertyProfile';
import type { ObjectImageType } from '../../hooks/usePropertyProfile';

export interface ObjectImage {
  id: string;
  imageType: ObjectImageType;
  url: string;
}

interface UploadObjectImageProps {
  title: string;
  description: string;
  imageType: ObjectImageType;
  objectImages: ObjectImage[];
}
export const UploadObjectImage = ({
  imageType,
  title,
  description,
  objectImages,
}: UploadObjectImageProps) => {
  const { getRootProps, getInputProps, open, deleteImage, downloadImage, isUploading, image } =
    usePropertyProfileObjectImage(imageType, objectImages);
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.propertyProfile.sections.upload',
  );

  return (
    <VStack
      padding={8}
      spacing={4}
      marginBottom={8}
      backgroundColor="white"
      boxShadow="lg"
      borderRadius="lg"
      width="full"
      alignItems="normal"
      data-testid={`object-image-upload-${imageType}`}
    >
      <HStack {...getRootProps()} spacing={8}>
        {image ? (
          <Image
            boxSize={16}
            borderRadius="md"
            cursor="pointer"
            src={image.url}
            alt={title}
            onClick={downloadImage}
          />
        ) : (
          <Icon as={DocumentIcon} boxSize={16} />
        )}
        <input {...getInputProps()} />
        <VStack alignItems="start">
          <HeadingComponent variant="secondary" color="brand.default" fontWeight="semibold">
            {title}
          </HeadingComponent>
          <Text color="text.secondary">{description}</Text>
        </VStack>
        {image ? (
          <ButtonComponent
            onClick={() => {
              deleteImage(image.id);
            }}
            leftIcon={<DeleteIcon />}
            flexShrink="0"
            variant="tertiary"
          >
            {t('remove')}
          </ButtonComponent>
        ) : (
          <LoadingComponent isLoading={isUploading}>
            <ButtonComponent onClick={open} leftIcon={<UploadIcon />} flexShrink="0">
              {t('send')}
            </ButtonComponent>
          </LoadingComponent>
        )}
      </HStack>
    </VStack>
  );
};
