import React from 'react';

import { HStack } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { Container } from 'components/PortalPage/styles';
import { ELEMENT_IDS } from 'constants/elementIds';
import paths from 'constants/paths';
import NavigationLink from 'modules/Header/NavigationLink';
import PartnerLogo from 'modules/Header/PartnerLogo';
import PortalNavigation from 'modules/Header/PortalNavigation';
import {
  StyledPortalHeader as StyledHeader,
  StyledMenuButton,
  StyledRwdMenu,
  StyledRwdMenuHeadline,
  PageHeadline,
} from 'modules/Header/styles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { showRwdMenu } from 'store/layout/actions';
import { rwdMenuOpenSelector } from 'store/layout/selectors';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

import { LanguageSelector } from './LanguageSelector';

const { toggleNavigation, login, loginV2 } = translations.pages.loginPage;

export const Header = () => {
  const isScrolledToTop = useIsScrolledToTop();
  const isLoggedIn = isLoggedInUser();

  const isRwdMenuOpen = useSelector(rwdMenuOpenSelector);
  const dispatch = useDispatch();

  const loginUrl = useIsUnderOperationPortalPath() ? paths.operation.login : paths.customer.login;

  const t = useTranslations();

  const loginButtonLabel = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t(login),
    [InquiryType.hausbank]: t(loginV2),
  });

  return (
    <StyledHeader biggerHeader={isScrolledToTop}>
      <Container>
        <PageHeadline>eco.banking</PageHeadline>
        <PartnerLogo />
        <HStack spacing={8}>
          {isLoggedIn ? (
            <>
              <StyledMenuButton
                type="button"
                className={isRwdMenuOpen && 'active'}
                onClick={() => dispatch(showRwdMenu(!isRwdMenuOpen))}
                aria-label={t(toggleNavigation)}
              >
                <span className="bars" role="presentation" />
              </StyledMenuButton>

              <StyledRwdMenu
                show={isLoggedIn}
                className={isRwdMenuOpen && 'open'}
                aria-labelledby={ELEMENT_IDS.MAIN_NAVIGATION}
              >
                <StyledRwdMenuHeadline id={ELEMENT_IDS.MAIN_NAVIGATION}>
                  Navigation
                </StyledRwdMenuHeadline>
                <PortalNavigation />
              </StyledRwdMenu>
            </>
          ) : (
            <NavigationLink url={loginUrl} label={loginButtonLabel} />
          )}
          <LanguageSelector />
        </HStack>
      </Container>
    </StyledHeader>
  );
};
