export enum ACTIONS {
  SET_IS_QUALIFIED_FOR_FASTLANE = 'SET_IS_QUALIFIED_FOR_FASTLANE',
  SET_FASTLANE_LOADING = 'SET_FASTLANE_LOADING',
  SET_ARE_DOCUMENTS_MANDATORY = 'SET_ARE_DOCUMENTS_MANDATORY',
  SET_FASTLANE_INQUIRY_FILE_REQUESTS = 'SET_FASTLANE_INQUIRY_FILE_REQUESTS',
  SET_FASTLANE_ERRORS = 'SET_FASTLANE_ERRORS',
  SET_ERROR_CHECKING_FASTLANE = 'SET_ERROR_CHECKING_FASTLANE',
  RESET_STATE = 'RESET_STATE',
  CHECK_FASTLANE = 'CHECK_FASTLANE',
  CHECK_FASTLANE_SUCCESS = 'CHECK_FASTLANE_SUCCESS',
}
