import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNER,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  EXISTING_BENEFICIARY,
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
} from '../../Form/formFields';
import { fieldParsers } from '../fieldParsers';

const { toBoolean } = fieldParsers;

export const mapDefaultBeneficiaryOwner = (allFields: any, beneficiary: any) => {
  return {
    first_name: beneficiary[BENEFICIARY_FIRST_NAME],
    last_name: beneficiary[BENEFICIARY_LAST_NAME],
    birth_date: beneficiary[BENEFICIARY_BIRTHDAY],
    city: beneficiary[BENEFICIARY_PRIVATE_CITY],
    country: beneficiary[BENEFICIARY_PRIVATE_COUNTRY],
  };
};

export interface MapBeneficiaryOwnersOptions {
  mapBeneficiaryOwner: (allFields: any, beneficiary: any) => any;
}

export const mapBeneficiaryOwners = (allFields: any, options: MapBeneficiaryOwnersOptions) => {
  // Beneficiary Owners values definition
  let beneficiaryOwnersList: Array<any> = [];
  const beneficiaryOwners = allFields[BENEFICIARY_OWNER];
  const beneficiaryOwnership = toBoolean(allFields[HAS_BENEFICIARY_OWNER]);
  const userBeneficiaryOwnership = toBoolean(allFields[IS_BENEFICIARY_OWNER]) || false;
  // Passed when user is beneficiary
  const existingBeneficiary = allFields[EXISTING_BENEFICIARY];

  if (existingBeneficiary || beneficiaryOwnership) {
    beneficiaryOwnersList = [...(existingBeneficiary || []), ...(beneficiaryOwners || [])]
      .map((beneficiary: any) => {
        return options.mapBeneficiaryOwner(allFields, beneficiary);
      })
      // remove duplicates
      .filter((owner, index, array) => {
        if (!owner.id) {
          return true;
        }
        const isDuplicate = array.findIndex((item) => item.id === owner.id) !== index;
        return !isDuplicate;
      })
      // remove empty values
      .filter((owner) => owner.id || owner.first_name || owner.last_name);
  }

  return {
    beneficiary_ownership: beneficiaryOwnersList.length > 0,
    user_beneficiary_ownership: beneficiaryOwnership ? userBeneficiaryOwnership : false,
    beneficiary_owners: beneficiaryOwnership ? beneficiaryOwnersList : [],
  };
};
