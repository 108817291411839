import { useSelector } from 'react-redux';

import { ICompeonOffer, ICompeonOfferProperty } from 'models/CompeonOffer.model';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  GOODS__PURCHASE_PRICE,
  MACHINE_PRICE,
  VEHICLE_PURCHASE_PRICE,
} from 'modules/Inquiry/Form/formFields';
import { mapCompeonOffersForDisplay } from 'modules/Offers/InquiryOffersSection/CompeonOffers/service';
import {
  getCompeonOffersSelector,
  getInquiryDetailsSelector,
} from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

// additional fields from inquiry to offer
const additionalFieldsFromInquiry: {
  [field: string]: { label: string; valueFormatter: Function };
} = {
  [GOODS__PURCHASE_PRICE]: {
    label: 'pages.inquiryDetails.offers.compeon.properties.purchasePrice',
    valueFormatter: formatPrice,
  },
  [MACHINE_PRICE]: {
    label: 'pages.inquiryDetails.offers.compeon.properties.purchasePrice',
    valueFormatter: formatPrice,
  },
  [VEHICLE_PURCHASE_PRICE]: {
    label: 'pages.inquiryDetails.offers.compeon.properties.purchasePrice',
    valueFormatter: formatPrice,
  },
};

const additionalFieldKeys = Object.keys(additionalFieldsFromInquiry);

export const useCompeonOffers = (): ICompeonOffer[] => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const offers = useSelector(getCompeonOffersSelector);
  const t = useTranslations();
  const compeonTranslator = useTranslations('pages.inquiryDetails.offers.compeon');

  const additionalProperties = additionalFieldKeys.reduce<ICompeonOfferProperty[]>(
    (acc, fieldName) => {
      const value = inquiryDetails?.financingPurposeFields[fieldName];
      if (!value) {
        return acc;
      }
      const schema = additionalFieldsFromInquiry[fieldName];
      return [
        ...acc,
        {
          label: t(schema.label),
          value: schema.valueFormatter(value),
          name: fieldName,
        },
      ];
    },
    [],
  );

  return offers.map(mapCompeonOffersForDisplay(compeonTranslator)).map((offer) => ({
    ...offer,
    properties: [...additionalProperties, ...offer.properties],
  }));
};
