import { USER_KEY, UserAttributes, UserState } from 'types/User';

import { getAttributesFromToken } from './getters';
import { getUser } from './getters';

export const setUser = (newUser: UserState) =>
  sessionStorage.setItem(USER_KEY, JSON.stringify(newUser));

export const setUserRoles = (accessToken: string) => {
  const roles = getAttributesFromToken(accessToken);
  const newUser = { ...getUser(), roles };
  sessionStorage.setItem(USER_KEY, JSON.stringify(newUser));
};

export const setEmail = (email: string) => {
  const newUser = getUser();
  if (newUser.attributes) {
    (newUser.attributes as UserAttributes).email = email;
  }
  setUser(newUser);
};

export const setBirthdate = (birthDate: string) => {
  const newUser = getUser();
  if (newUser.attributes) {
    (newUser.attributes as UserAttributes).birthDate = birthDate;
  }
  setUser(newUser);
};

export const updateUserAttributes = (attributes: Partial<UserAttributes>) => {
  const currentUser = getUser();
  if (currentUser.attributes) {
    const newUser = {
      ...currentUser,
      attributes: {
        ...currentUser.attributes,
        ...attributes,
      },
    };
    setUser(newUser);
  }
};

export const resetUser = () => {
  setUser({});
};
