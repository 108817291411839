import React from 'react';

import { Flex } from '@chakra-ui/react';

import { TextComponent } from '../../../theme/components/Text';

export const FallbackComponent = () => {
  return (
    <Flex
      width={'100%'}
      height={'100%'}
      minHeight={'300px'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <TextComponent>Aktuell sind keine Daten verfügbar</TextComponent>
    </Flex>
  );
};
