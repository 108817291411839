import { AnyAction } from 'redux';

import {
  SAVE_INQUIRIES,
  SAVE_INQUIRIES_FAILURE,
  SAVE_INQUIRIES_SUCCESS,
} from 'api/CompeonReverseApi/actionTypes';

interface IInquiriesState {
  isSending: boolean;
}

const INITIAL_STATE: IInquiriesState = {
  isSending: false,
};

export default (state: IInquiriesState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SAVE_INQUIRIES: {
      return {
        isSending: true,
      };
    }
    case SAVE_INQUIRIES_SUCCESS: {
      return {
        isSending: false,
      };
    }
    case SAVE_INQUIRIES_FAILURE: {
      return {
        isSending: false,
      };
    }
    default: {
      return state;
    }
  }
};
