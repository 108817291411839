import { dzbFields } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateAssociationInformationSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: associationInformationSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { name, contactPerson, totalPurchaseOfGoods, annualSalesForecast, contractDate } =
  dzbFields.companyDataPage.associationInformationSection;

export const associationInformationSectionValidationMap = {
  [name]: fieldValidators.string().required(),
  [contactPerson]: fieldValidators.string().required(),
  [totalPurchaseOfGoods]: fieldValidators.number().required(),
  [annualSalesForecast]: fieldValidators.number().required(),
  [contractDate]: fieldValidators.date().optional(),
};
