import React from 'react';

import { ComputedMarketDataTopBoxes } from 'models/types/MarketData.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatInteger } from 'utils/valueFormats';

import ProjectCostingBoxes from './ProjectCostingBoxes';

interface MarketDataTopBoxesProps {
  topBoxes: ComputedMarketDataTopBoxes;
  testId?: string;
}

const MarketDataTopBoxes: React.FC<MarketDataTopBoxesProps> = ({ topBoxes, testId }) => {
  const t = useTranslations('pages.planningEvaluation.marketComparison.clientBoxes');
  const tUnit = useTranslations('other');

  return (
    <ProjectCostingBoxes
      values={[
        {
          name: t('potentialSalesRevenue'),
          value: formatDecimal(topBoxes.potentialSalesRevenue, { unit: '€' }),
          testId: 'potential-sales-revenue-box',
        },
        {
          name: t('possibleRentalIncomePerYear'),
          value: formatDecimal(topBoxes.possibleRentalIncomePerYear, { unit: '€' }),
          testId: 'possible-rental-income-box',
        },
        {
          name: t('livingSpace'),
          value: formatInteger(topBoxes.livingSpace, { unit: tUnit('m2') }),
          testId: 'living-space-box',
        },
        {
          name: t('commercialSpace'),
          value: formatInteger(topBoxes.commercialSpace, { unit: tUnit('m2') }),
          testId: 'commercial-space-box',
        },
      ]}
    />
  );
};

export default MarketDataTopBoxes;
