import React from 'react';

import { PURPOSE_KIND } from 'modules/Inquiry/Form/formFields';
import InformationRows, {
  InformationField,
} from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import {
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
} from 'onlinefactoring/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledQuestion } from './styles';

const PurposeShareSection = () => {
  const t = useTranslations();

  const fields: InformationField[] = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.FINANCING,
    excludedFields: [PURPOSE_KIND],
    includeFields: [
      SHARE_OF_SALES_BUSINESS_CUSTOMERS,
      SHARE_OF_SALES_MAIN_CUSTOMERS,
      SHARE_OF_SALES_FOREIGN_CUSTOMERS,
    ],
  });

  if (!fields.length) {
    return null;
  }

  return (
    <>
      <StyledQuestion>
        {t('inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose.sectionHeading')}
      </StyledQuestion>
      <InformationRows fields={fields} />
    </>
  );
};

export default PurposeShareSection;
