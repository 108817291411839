import styled from 'styled-components/macro';

import { mqLarge } from 'styles/breakpoints';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  color: ${(props) => props.theme.colors.text.primary};
  background-color: ${(props) => props.theme.colors.background.darkGrey};

  ${mqLarge`
    padding: 2rem 5rem;
    flex-direction: row;
    justify-content: space-between;
  `};
`;

export const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};

  &:not(:empty) {
    margin-bottom: 1rem;
  }

  ${mqLarge`
    margin-bottom: 0;
  `};
`;
