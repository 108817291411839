import React from 'react';

import axe from '@axe-core/react';
import ReactDOM from 'react-dom';

import App from 'components/App';
import { isProduction } from 'utils/environment';

if (!isProduction()) {
  axe(React, ReactDOM, 1000, {}, undefined);

  if (module.hot) {
    module.hot.accept();
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
