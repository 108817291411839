import * as React from 'react';

import { Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FormDataType, RealEstateExpertType } from './types';

interface Props {
  onSubmit(formData: FormDataType): void;
  realEstateExperts: RealEstateExpertType[];
  onClose(): void;
  isPending: boolean;
  forwardREELoading: boolean;
}

export const AssignRealEstateExpertSelectorForm = ({
  onSubmit,
  realEstateExperts,
  onClose,
  isPending,
  forwardREELoading,
}: Props) => {
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.assignInquiryToRealEstateExpert',
  );
  const tButtons = useTranslations('buttons');

  const { required } = useFieldValidators();

  return (
    <Form
      id="form-forward-inquiry-to-real-estate-expert"
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id="form-forward-inquiry-to-real-estate-expert" onSubmit={handleSubmit}>
          <FormRow>
            <SelectWithField
              name={'realEstateExpertId'}
              options={realEstateExperts.map(({ firstName, lastName, id }: any) => ({
                value: id,
                label: firstName + ' ' + lastName,
              }))}
              validate={required}
              placeholder={t('modal.form.realEstateExpertDropdownPlaceholder')}
              data-testid={'select-field-realEstateExpertId'}
            />
          </FormRow>
          <Flex justifyContent={'flex-end'}>
            <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
              {tButtons('cancel')}
            </ButtonComponent>
            <ButtonComponent
              isLoading={isPending || forwardREELoading}
              form="form-forward-inquiry-to-real-estate-expert"
              type="submit"
            >
              {t('modal.confirmText')}
            </ButtonComponent>
          </Flex>
        </form>
      )}
    />
  );
};
