import _get from 'lodash/get';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { mergePartnerConfig } from 'store/partner/config';
import { defaultPartnerConfig } from 'store/partner/config/defaultPartnerConfig';

import { InquiryConfig } from './types';

export class FormConfigMapper {
  constructor(public payload: any) {}

  public resolveConfig(): InquiryConfig {
    const formsConfig = _get(this.payload, ['process-config', 'forms-config']);
    const inquiryTypes = Object.keys(formsConfig) as InquiryType[];
    const availableInquiryTypes = inquiryTypes.length > 0 ? inquiryTypes : [InquiryType.default];
    const processConfig = this.payload['process-config'];
    const { assets, dropdowns } = mergePartnerConfig(defaultPartnerConfig, processConfig);

    return {
      selectedInquiryType: availableInquiryTypes[0],
      availableInquiryTypes,
      formsConfig,
      assets,
      dropdowns,
    };
  }
}
