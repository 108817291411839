import React from 'react';

import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import Spinner from 'components/Spinner';
import NeededFinancingForKfw from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/presentKfwProgram/NeededFinancingForKfwProgram';
import PresentKfwProgram from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/presentKfwProgram/PresentKfwProgram';
import { useFetchMaximumKfwFinancingAmount } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/fields/presentKfwProgram/useFetchKfwProgramFinancing';
import { MaximumFinancingAmount } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/styles';
import { getKfwProgram } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const KfwProgramFinancing: React.FC = () => {
  const t = useTranslations('pages.coronaStep');

  const { isPending, maxAmount } = useFetchMaximumKfwFinancingAmount();
  const { isLoading } = useSelector(getKfwProgram);

  if (isPending || isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <FormRow>
        <PresentKfwProgram />
      </FormRow>
      <FormRow>
        <p>
          {t('subsections.maxFinancingKfwProgram', {
            amount: () => <MaximumFinancingAmount>{formatPrice(maxAmount)}</MaximumFinancingAmount>,
          })}
        </p>
      </FormRow>
      <FormRow>
        <NeededFinancingForKfw maxFinancing={maxAmount} />
      </FormRow>
    </>
  );
};

export default KfwProgramFinancing;
