import React from 'react';

import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

const MarketInformationHeading = () => {
  const t = useTranslations('pages.marketInformationInquiryPage.heading');
  return <PageHeading heading={t('header')} subheading={t('subheader')} />;
};

export default MarketInformationHeading;
