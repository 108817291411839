import { useLoadInquiriesList } from 'api/CompeonReverseApi/operation/queryHooks';
import { useInquiriesListContext } from 'modules/InquiriesListProvider';

export const useBankAdvisorData = () => {
  const { tableOptions } = useInquiriesListContext();
  const { data } = useLoadInquiriesList({
    variables: { options: tableOptions },
  });

  const bankAdvisorOptions = data?.selectBoxOptions?.bankAdvisors.map((name: string) => ({
    value: name,
    label: name,
  }));

  return {
    bankAdvisorOptions: bankAdvisorOptions || [],
  };
};
