import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';

export const StyledSummaryList = styled.div`
  ${mqMedium`
    display: flex;
    margin: 0 -1.5rem;
  `}

  > * {
    margin: 1rem 0;

    ${mqMedium`
      flex: 1 0 0%;
      margin: 0 1.5rem;
    `}
  }
`;
