import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import PageTitle from 'components/PageTitle/PageTitle';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ChangeStep } from './ChangeStep';

type Props = {
  children: React.ReactNode;
  hero?: React.ReactNode;
  translationStringForTitle?: string;
};

export const Page = ({ children, hero, translationStringForTitle }: Props) => {
  const t = useTranslations();

  useScrollToTop();

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'18rem 4rem 4rem'}
    >
      <PageTitle title={t(translationStringForTitle)} />
      {hero}
      <Box w={'full'} maxWidth={'69rem'} margin={'0 auto'}>
        {children}
        <ChangeStep />
      </Box>
    </Flex>
  );
};
