import _chunk from 'lodash/chunk';

const WIDE_FIELD_CHAR_LENGTH = 80;
export const isWideField = (field) => field.value && field.value.length >= WIDE_FIELD_CHAR_LENGTH;

const splitWideFields = (fields) => {
  const normalField = fields.filter((field) => !isWideField(field));
  const wideFields = fields.filter(isWideField);

  return [normalField, wideFields];
};

export const mapFieldsToRows = (fields) => {
  const [normalFields, wideFields] = splitWideFields(fields);
  const standardRows = _chunk(normalFields, 3); // split fields into group of 3
  const wideRows = wideFields.map((field) => [field]); // each field in a separate row

  return [...standardRows, ...wideRows];
};
