import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  FINANCING_DETAILS_FEE_DURATION,
  FINANCING_DETAILS_FEE_RATE,
  FINANCING_DETAILS_FINANCING_DURATION,
  FINANCING_DETAILS_INTEREST_RATE,
  FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS,
  FINANCING_DETAILS_REPAYMENT_RATE,
  INVESTMENT_PLAN_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_CONSTRUCTION_COSTS,
  INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_GAK_BUILDING_COSTS,
  INVESTMENT_PLAN_GAK_BUYING_COSTS,
  INVESTMENT_PLAN_GAK_INTEREST_COSTS,
  INVESTMENT_PLAN_GAK_OTHER,
  INVESTMENT_PLAN_GAK_SELLING_COSTS,
  INVESTMENT_PLAN_INTEREST_COSTS,
  INVESTMENT_PLAN_OTHER_COSTS,
  INVESTMENT_PLAN_SELLING_COSTS,
  SOURCE_OF_FUNDS_EQUITY_GRANTS,
  SOURCE_OF_FUNDS_EQUITY_RELEASE,
  SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS,
  SOURCE_OF_FUNDS_GAK_LOAN,
  SOURCE_OF_FUNDS_LOAN_PROJECT,
  SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN,
  PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
  PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
  PROFITABILITY_SALES_SALES_PRICES_CHECKS,
  RENT_CUSTOMER_NOW_ASSET_SURPLUS,
  RENT_CUSTOMER_NOW_EQUITY_PROOF,
  RENT_CUSTOMER_NOW_FREE_INCOME,
  RENT_CUSTOMER_NOW_OPERATING_COSTS,
  RENT_CUSTOMER_PLAN_OPERATING_COSTS,
  RENT_NOTES_ON_DEBT_SERVICE,
  RENT_CURRENT_OPERATING_COSTS_ESTIMATION_CUSTOMER,
  RENT_CURRENT_OPERATING_COSTS_MIN_CUSTOMER,
  RENT_FUTURE_OPERATING_COSTS_ESTIMATION_CUSTOMER,
  RENT_FUTURE_OPERATING_COSTS_MIN_CUSTOMER,
  LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION,
  LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION,
  LENDING_VALUE_NOTES_ON_REMAINING_RISK,
} from 'modules/Inquiry/Form/formFields';

import { MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE } from '../SharedComponent/constants';

type TextAreasType = {
  type: string;
  name: string;
  placeholder: string;
  caption?: string;
}[];

type TextsType = {
  type: string;
  name: string;
  caption: string;
  defaultValue?: string;
}[];

const texts: TextsType = [
  {
    type: 'text',
    name: '',
    caption: '',
    defaultValue: 'pages.editMittweidaProfitabilityPages.rent.hint',
  },
  {
    type: 'text',
    name: RENT_CURRENT_OPERATING_COSTS_ESTIMATION_CUSTOMER,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCostsEstimation.caption',
  },
  {
    type: 'text',
    name: RENT_CURRENT_OPERATING_COSTS_MIN_CUSTOMER,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCostsMinimum.caption',
  },
  {
    type: 'text',
    name: RENT_FUTURE_OPERATING_COSTS_ESTIMATION_CUSTOMER,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCostsEstimation.caption',
  },
  {
    type: 'text',
    name: RENT_FUTURE_OPERATING_COSTS_MIN_CUSTOMER,
    caption:
      'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCostsMinimum.caption',
  },
];

const textAreas: TextAreasType = [
  {
    type: 'textArea',
    name: FINANCING_DETAILS_NOTES_ON_FINANCING_PARAMETERS,
    caption:
      'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.notesOnFinancingPlan.caption',
    placeholder:
      'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.notesOnFinancingPlan.placeholder',
  },
  {
    type: 'textArea',
    name: RENT_NOTES_ON_DEBT_SERVICE,
    caption:
      'pages.editMittweidaProfitabilityPages.rent.sections.fields.notesOnDebtService.caption',
    placeholder:
      'pages.editMittweidaProfitabilityPages.rent.sections.fields.notesOnDebtService.placeholder',
  },
  {
    type: 'textArea',
    name: LENDING_VALUE_NOTES_ON_REMAINING_RISK,
    caption:
      'pages.editMittweidaProfitabilityPages.lendingValue.sections.fields.notesOnRemainingRisk.caption',
    placeholder:
      'pages.editMittweidaProfitabilityPages.lendingValue.sections.fields.notesOnRemainingRisk.placeholder',
  },
  {
    type: 'textArea',
    name: SOURCE_OF_FUNDS_NOTES_ON_FINANCING_PLAN,
    caption:
      'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.fields.notesOnFinnancingPlan.caption',
    placeholder:
      'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.fields.notesOnFinnancingPlan.placeholder',
  },
];

const mapFinancingDetailsToEdit = () => {
  const sections = [
    {
      title: 'pages.editMittweidaProfitabilityPages.financingDetails.sections.title',
      fields: [
        FINANCING_DETAILS_INTEREST_RATE,
        FINANCING_DETAILS_REPAYMENT_RATE,
        FINANCING_DETAILS_FEE_RATE,
        FINANCING_DETAILS_FEE_DURATION,
        FINANCING_DETAILS_FINANCING_DURATION,
        textAreas[0],
      ],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMittweidaProfitabilityPages.financingDetails.heading',
      subHeading: 'pages.editMittweidaProfitabilityPages.financingDetails.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapInvestmentPlanToEdit = () => {
  const sections = [
    {
      title: 'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.title',
      fields: [
        INVESTMENT_PLAN_GAK_BUYING_COSTS,
        INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS,
        INVESTMENT_PLAN_GAK_BUILDING_COSTS,
        INVESTMENT_PLAN_GAK_OTHER,
        INVESTMENT_PLAN_GAK_SELLING_COSTS,
        INVESTMENT_PLAN_GAK_INTEREST_COSTS,
      ],
    },
    {
      title: 'pages.editMittweidaProfitabilityPages.investmentPlan.sections.project.title',
      fields: [
        INVESTMENT_PLAN_CONSTRUCTION_COSTS,
        INVESTMENT_PLAN_ADDITIONAL_COSTS,
        INVESTMENT_PLAN_OTHER_COSTS,
        INVESTMENT_PLAN_SELLING_COSTS,
        INVESTMENT_PLAN_INTEREST_COSTS,
      ],
    },
  ];
  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMittweidaProfitabilityPages.investmentPlan.heading',
      subHeading: 'pages.editMittweidaProfitabilityPages.investmentPlan.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapLendingValueToEdit = () => {
  const sections = [
    {
      title: 'pages.editMittweidaProfitabilityPages.lendingValue.sections.buyingPhase.title',
      fields: [
        LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT,
        LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION,
      ],
    },
    {
      title: 'pages.editMittweidaProfitabilityPages.lendingValue.sections.wholeProject.title',
      fields: [
        LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT,
        LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION,
      ],
    },
    {
      title: 'pages.editMittweidaProfitabilityPages.lendingValue.sections.indication.title',
      fields: [LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT, textAreas[2]],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMittweidaProfitabilityPages.lendingValue.heading',
      subHeading: 'pages.editMittweidaProfitabilityPages.lendingValue.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapProfitabilitySalesToEdit = () => {
  const sections = [
    {
      title: 'pages.editMittweidaProfitabilityPages.profitabilitySales.sections.title',
      fields: [
        PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
        PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
        PROFITABILITY_SALES_SALES_PRICES_CHECKS,
      ],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMittweidaProfitabilityPages.profitabilitySales.heading',
      subHeading: 'pages.editMittweidaProfitabilityPages.profitabilitySales.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapRentToEdit = () => {
  const sections = [
    {
      title: 'pages.editMittweidaProfitabilityPages.rent.sections.title',
      fields: [
        RENT_CUSTOMER_NOW_OPERATING_COSTS,
        texts[0],
        texts[1],
        texts[2],
        RENT_CUSTOMER_PLAN_OPERATING_COSTS,
        texts[0],
        texts[3],
        texts[4],
        RENT_CUSTOMER_NOW_FREE_INCOME,
        RENT_CUSTOMER_NOW_ASSET_SURPLUS,
        RENT_CUSTOMER_NOW_EQUITY_PROOF,
        textAreas[1],
      ],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMittweidaProfitabilityPages.rent.heading',
      subHeading: 'pages.editMittweidaProfitabilityPages.rent.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapSourceOfFundsToEdit = () => {
  const sections = [
    {
      title: 'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.buying.title',
      fields: [SOURCE_OF_FUNDS_GAK_LOAN, SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS],
    },
    {
      title: 'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.project.title',
      fields: [
        SOURCE_OF_FUNDS_LOAN_PROJECT,
        SOURCE_OF_FUNDS_EQUITY_RELEASE,
        SOURCE_OF_FUNDS_EQUITY_GRANTS,
        textAreas[3],
      ],
    },
  ];

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editMittweidaProfitabilityPages.sourceOfFunds.heading',
      subHeading: 'pages.editMittweidaProfitabilityPages.sourceOfFunds.subHeading',
      sections: sections,
    },
  ];

  return editValuesForm;
};

const mapMittweidaProfitabilityToEdit: Record<string, any> = {
  [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.FINANCING_DETAILS]: mapFinancingDetailsToEdit,
  [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.INVESTMENT_PLAN]: mapInvestmentPlanToEdit,
  [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.LENDING_VALUE]: mapLendingValueToEdit,
  [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.PROFITABILITY_SALES]:
    mapProfitabilitySalesToEdit,
  [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.SOURCE_OF_FUNDS]: mapSourceOfFundsToEdit,
  [MITTWEIDA_PROFITABILITY_CALCULATION_RECORD_TYPE.RENT]: mapRentToEdit,
};

export default mapMittweidaProfitabilityToEdit;
