import React from 'react';

import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import { USER_SCOPE } from 'constants/userScopes';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import { useFetchInquiryFiles } from 'shared/documentExchange/useFetchInquiryFiles';

import { ConfirmObjectTurnoverStep } from './ConfirmObjectTurnoverStep/ConfirmObjectTurnoverStep';
import LeaseplanCustomerDashboard from './LeaseplanDashboard';
import NewCustomerDashboard from './NewDashboard';
import { ObjectDataStep } from './ObjectData/ObjectDataStep';
import { ProgressAdditionalFields } from './ProgressAdditionalFields';
import { ProgressAllRequiredFilesUploaded } from './ProgressAllRequiredFilesUploaded';
import { ProgressFactoringOffer } from './ProgressFactoringOffer';
import { ProgressIndicativeOffer } from './ProgressIndicativeOffer';
import ProgressSectionsWrapper from './ProgressSectionsWrapper';
import { ProgressSuccess } from './ProgressSuccess';
import { ProgressVideoIdent } from './ProgressVideoIdent';
import { SignContractStep } from './SignContractStep/SignContractStep';
import SummaryCard from './SummaryCard';
import SummaryHeader from './SummaryHeader';
import { ProgressSectionType } from './types';
import { useProgressSections } from './useProgressSections';

export const SectionByType = (props: Record<string, any>) => {
  const { section, sectionProps } = props;
  switch (section.type) {
    case ProgressSectionType.INDICATIVE_OFFER:
      return <ProgressIndicativeOffer {...sectionProps} />;
    case ProgressSectionType.VIDEO_IDENT:
      return <ProgressVideoIdent {...sectionProps} />;
    case ProgressSectionType.DOCUMENT_UPLOAD:
      return <ProgressAllRequiredFilesUploaded {...sectionProps} />;
    case ProgressSectionType.ADDITIONAL_FIELDS:
      return <ProgressAdditionalFields {...sectionProps} />;
    case ProgressSectionType.FACTORING_OFFER:
      return <ProgressFactoringOffer {...sectionProps} />;
    case ProgressSectionType.CONFIRM_OBJECT_TAKEOVER:
      return <ConfirmObjectTurnoverStep {...sectionProps} />;
    case ProgressSectionType.SIGN_CONTRACT:
      return <SignContractStep {...sectionProps} />;
    case ProgressSectionType.OBJECT_DATA:
      return <ObjectDataStep {...sectionProps} />;
    default:
      return null;
  }
};

const CustomerDashboard = () => {
  useFetchInquiryFiles({ uploadedBy: USER_SCOPE.CUSTOMER });
  const { selectedLanguage } = useLanguages();
  useFetchInquiryFileRequests({ selectedLanguage });
  const { firstOpenTask, completedValue, sectionsDone, sectionsOpen, sections, lastItemIndex } =
    useProgressSections();

  const header = (
    <>
      <SummaryHeader />
      <SummaryCard
        completedValue={completedValue}
        progressIncrement={sectionsDone}
        progressTotal={sectionsDone + sectionsOpen}
        isCompleted={sectionsOpen === 0}
        openTaskId={firstOpenTask}
        canShowProgress={sectionsDone + sectionsOpen > 0}
      />
    </>
  );

  const progressSections = (
    <>
      {sections &&
        Object.values(sections)
          .filter((item) => item.visible)
          .map((section, index) => {
            const sectionProps = {
              completedValue: section.completedValue,
              isFirst: index === 0,
              isLast: sectionsOpen !== 0 && index === lastItemIndex,
              isCompleted: completedValue === 100,
              isPending: section.isPending,
              progressIncrement: section.increment,
              actionUrl: section.data?.url,
              data: section.data,
              progressTotal: section.total,
              id: section.type,
            };
            return (
              <SectionByType key={section.type} section={section} sectionProps={sectionProps} />
            );
          })}
      {sectionsOpen === 0 && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}
    </>
  );

  return <ProgressSectionsWrapper header={header} progressSections={progressSections} />;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: LeaseplanCustomerDashboard,
  [InquiryType.default]: CustomerDashboard,
  [InquiryType.dzb]: NewCustomerDashboard,
  [InquiryType.hausbank]: NewCustomerDashboard,
});
