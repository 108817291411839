import React from 'react';

import { useSelector } from 'react-redux';

import { FILE_AVAILABLE_STATUSES } from 'components/DownloadBlock/constants';
import MultipleFilesBlock, {
  UploadButtonProps,
} from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import { translations } from 'new/form/common/types';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import { getAdditionalOperationFilesWithoutFileRequest } from 'store/documentExchange/documentExchange.selectors';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export const MultipleFilesForCustomer: React.FC = () => {
  const files = useSelector(getAdditionalOperationFilesWithoutFileRequest);
  const t = useTranslations();

  const { forClient, uploadOtherFilesDesc, selectDocument } =
    translations.pages.inquiryDetails.documentExchange;

  const { sendNow } = translations.components.upload;

  const uploadButtonProps: UploadButtonProps = {
    text: t(sendNow),
    leftIcon: <UploadIcon boxSize={6} display={'block'} />,
    variant: 'primary',
    textColor: 'text.white',
  };

  const filteredFiles = files.filter((file) => FILE_AVAILABLE_STATUSES.includes(file.status));

  return (
    <section>
      <FileSectionTitle>{t(forClient)}</FileSectionTitle>
      <MultipleFilesBlock
        title={t(selectDocument)}
        description={t(uploadOtherFilesDesc)}
        isFileUploadingEnabled
        files={filteredFiles}
        uploadButtonProps={uploadButtonProps}
      />
    </section>
  );
};
