import React from 'react';

import RadioGroupFullWidthWrapper from 'components/RadioGroupFullWidth/RadioGroupFullWidthWrapper';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { LEASING_INTERESTED_IN_CREDIT_FINANCING } from 'modules/Inquiry/Form/formFields';

const LeasingInterestedInCreditFinancing: React.FC = () => {
  return (
    <RadioGroupFullWidthWrapper>
      <YesNoRadioGroupWithField name={LEASING_INTERESTED_IN_CREDIT_FINANCING} />
    </RadioGroupFullWidthWrapper>
  );
};

export default LeasingInterestedInCreditFinancing;
