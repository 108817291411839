import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import { LIQUIDITY_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateLiquiditySection = validateSectionFields([LIQUIDITY_DESCRIPTION]);

const Liquidity = () => {
  const t = useTranslations();
  return (
    <FormSection
      title={t('pages.financingNeed.sections.liquidity.title')}
      name={FormSections.Liquidity}
      sectionNumber={3}
    >
      <FormRow>
        <TextAreaWithField
          name={LIQUIDITY_DESCRIPTION}
          placeholder={t(
            'pages.financingNeed.sections.liquidity.fields.liquidityDescription.placeholder',
          )}
        />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: Liquidity,
  default: null,
});
