import _invert from 'lodash/invert';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { API_TO_HEADERS_MAP } from 'modules/InquiryTable/constants';
import { FETCH_INQUIRIES_LIST_OPTIONS } from 'store/inquiryList/actions';
import { formatDateBE } from 'utils/date';

export const tableOptionsToQueryParams = (tableOptions) => ({
  data: {
    attributes: {
      page: {
        size: tableOptions.pageSize,
        number: tableOptions.currentPage,
      },
      sort_by: _invert(API_TO_HEADERS_MAP)[tableOptions.sortBy],
      sort_direction: tableOptions.sortDirection.toLowerCase(),
      filters: {
        id: tableOptions.filterBy.id,
        created_at_from: formatDateBE(tableOptions.filterBy.createdAtFrom),
        created_at_to: formatDateBE(tableOptions.filterBy.createdAtTo),
        company_name: tableOptions.filterBy.companyName,
        purpose_kind: tableOptions.filterBy.purposeKind,
        product_kind: tableOptions.filterBy.productKind,
        amount_from: tableOptions.filterBy.amountFrom,
        amount_to: tableOptions.filterBy.amountTo,
        status: tableOptions.filterBy.status,
        inquiry_manager_id: tableOptions.filterBy.inquiryManagerId,
        customer_vb_number: tableOptions.filterBy.customerVBNumber,
        form_type: [tableOptions.filterBy.inquiryType],
        zip_code_area: tableOptions.filterBy.zipCodeArea,
        vpp_id: tableOptions.filterBy.vppId,
      },
    },
  },
});

export const getInquiriesAction = (options) =>
  callReverseApi({
    url: endpoints.INQUIRIES.LIST.compose(
      { query: tableOptionsToQueryParams(options) },
      { qs: { arrayFormat: 'brackets' } },
    ),
    method: API_METHODS.GET,
    actionName: FETCH_INQUIRIES_LIST_OPTIONS,
  });
