import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { NewStaticFieldWithField } from 'components/StaticField';
import Unit from 'components/Unit/Unit';
import { formatCurrency } from 'mittweida/portals/operations/utils';
import { mmvFields } from 'mmv/inquiry/fields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { runtime, objectValue, downPayment, ratePerMonth, remainingValue } =
  mmvFields.contractDetails.financingConditionsSection;

interface SFinancingConditionsSectionProps {
  sectionNumber?: number;
}
export const FinancingConditionsSection = (props: SFinancingConditionsSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      title={t(
        translations.inquiryType.mmv.steps.contractDetails.sections.financingConditions.title,
      )}
      name="financingConditions"
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField
          name={runtime}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.MONTHS} />}
        />
        <NewStaticFieldWithField
          name={objectValue}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.CURRENCY} />}
          parseValue={formatCurrency}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          name={downPayment}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.CURRENCY} />}
          parseValue={formatCurrency}
        />
        <NewStaticFieldWithField
          name={ratePerMonth}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.CURRENCY} />}
          parseValue={formatCurrency}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          name={remainingValue}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.CURRENCY} />}
          parseValue={formatCurrency}
        />
      </FormRow>
    </FormSection>
  );
};
