import React, { useCallback } from 'react';

import { Form, useFormState } from 'react-final-form';
import { createMutation } from 'react-query-kit';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { CheckboxWithField } from 'components/Checkbox';
import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import paths from 'constants/paths';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import {
  getInquiryIdSelector,
  getInquiryTypeSpecificDetailsSelector,
} from 'store/inquiryDetails/selectors';
import { formatDateBE } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

type ObjectFormData = {
  serialNumber: string;
  dateOfObjectTurnover: string;
  finalObjectValue?: number;
  agree?: string;
};

type ObjectMutationData = Omit<ObjectFormData, 'agree'>;

const useSubmitObjectDataMutation = createMutation({
  mutationFn: async (variables: { dataAttributes: ObjectMutationData; inquiryId: string }) => {
    const url = endpoints.INQUIRIES.SPECIALIZED.MMV.OBJECT_CONFIRMATION.DATA.compose({
      params: { inquiryId: variables.inquiryId },
    });

    return EcoBankingAxiosClientAuthedInstance.patch(url, {
      data: {
        attributes: {
          serial_number: variables.dataAttributes.serialNumber,
          date_of_object_turnover: formatDateBE(variables.dataAttributes.dateOfObjectTurnover),
          final_object_value: variables.dataAttributes.finalObjectValue,
        },
      },
    });
  },
});

function useSubmitObjectData() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();
  const toasts = useToasts();
  const t = useTranslations();

  const mutation = useSubmitObjectDataMutation({
    onSuccess: () => {
      history.push(paths.customer.inquiryDetails.dashboard.replace(':id', inquiryId));
      // reload the page
      // FIXME later, providing a unified way to refetch inquiry details
      history.go(0);
    },
    onError: () => {
      toasts.error(t(translations.pages.errorPage.statuses.statusDefault));
    },
  });

  const mutateAsync = useCallback(
    (data: Omit<ObjectMutationData, 'agree'>) => {
      return mutation.mutateAsync({
        inquiryId,
        dataAttributes: data,
      });
    },
    [mutation, inquiryId],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}

export const ObjectDataForm = () => {
  const t = useTranslations();
  const fields = translations.pages.inquiryDetails.objectData.fields;
  const { approvalDate } = useSelector(getInquiryTypeSpecificDetailsSelector);
  const { serialNumber } = useSelector(getInquiryTypeSpecificDetailsSelector);

  const mutation = useSubmitObjectData();

  const { required, minValue, maxValue, maxLength } = useFieldValidators();

  const onSubmit = async (formData: ObjectFormData) => {
    await mutation.mutateAsync({
      dateOfObjectTurnover: formData.dateOfObjectTurnover,
      finalObjectValue: formData.finalObjectValue,
      serialNumber: formData.serialNumber,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        serialNumber,
        dateOfObjectTurnover: undefined,
        finalObjectValue: undefined,
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={'objectDataForm'}>
          <FormRow>
            <InputWithField
              name={'serialNumber'}
              caption={t(fields.serialNumber.caption)}
              validate={combineValidators(required, maxLength(50))}
              tooltip={t(fields.serialNumber.tooltip)}
            />
            <DayPickerWithField
              name={'dateOfObjectTurnover'}
              caption={t(fields.objectTransferDate.caption)}
              tooltip={t(fields.objectTransferDate.tooltip)}
              validate={combineValidators(required)}
              minDate={new Date(approvalDate)}
              maxDate={new Date()}
            />
          </FormRow>
          <FormRow>
            <AmountWithField
              name={'finalObjectValue'}
              caption={t(fields.finalPurchasePrice.caption)}
              placeholder={t(fields.finalPurchasePrice.caption)}
              optional={true}
              validate={(value: any) => {
                if (value) {
                  return combineValidators(minValue(0), maxValue(1000000))(value);
                }

                return false;
              }}
            />
          </FormRow>
          <FormAgreement />
        </form>
      )}
    </Form>
  );
};

const FormAgreement = () => {
  const { values } = useFormState<ObjectFormData>();
  const t = useTranslations();
  const objectDataTranslations = translations.pages.inquiryDetails.objectData;
  const fields = objectDataTranslations.fields;
  const { required } = useFieldValidators();

  if (!values.finalObjectValue) {
    return null;
  }

  return (
    <FormRow>
      <CheckboxWithField
        name={'agree'}
        text={t(fields.agree.caption)}
        validate={combineValidators(required)}
      />
    </FormRow>
  );
};
