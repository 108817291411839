import { AnyInitialStateType, AnyPageStateActions } from './types';

export enum PageStateActionTypes {
  NEXT_STEP = 'NEXT_STEP',
  SET_INQUIRY_MODE = 'SET_INQUIRY_MODE',
  SET_PROGRESS = 'SET_PROGRESS',
}

export function pageStateReducer(
  state: AnyInitialStateType,
  action: AnyPageStateActions,
): AnyInitialStateType {
  switch (action.type) {
    case PageStateActionTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    case PageStateActionTypes.SET_INQUIRY_MODE:
      return {
        ...state,
        inquiryMode: action.payload,
      };

    case PageStateActionTypes.SET_PROGRESS:
      return {
        ...state,
        pageValidations: action.payload,
      };

    default:
      return state;
  }
}
