import { DzbFieldTypes } from 'dzb/inquiry/fields';

export function mapDzbRetailerData(values: DzbFieldTypes) {
  const {
    retailerDataPage: { retailerInformationSection, onlineQuestionnaireSection },
  } = values;

  return {
    retailer_attributes: {
      stores_count: retailerInformationSection.numberOfStores,
      employees_count: retailerInformationSection.numberOfEmployees,
      retail_space: retailerInformationSection.retailSpace || null,
      is_using_erp: retailerInformationSection.enterpriseResourcePlanningSystemInUse,
      erp_name: retailerInformationSection.erpProvider,
      share_of_online_sales: onlineQuestionnaireSection.shareOfOnlineSalesPercentage,
      avg_shopping_basket_total: onlineQuestionnaireSection.averageShoppingBasketAmount,
      return_rate: onlineQuestionnaireSection.returnRatePercentage,
      own_online_store_sales_share: onlineQuestionnaireSection.ownOnlineShopPercentage,
      amazon_sales_share: onlineQuestionnaireSection.amazonPercentage,
      ebay_sales_share: onlineQuestionnaireSection.ebayPercentage,
      zalando_sales_share: onlineQuestionnaireSection.zalandoPercentage,
      other_sales_share: onlineQuestionnaireSection.othersPercentage,
      own_warehouse_share: onlineQuestionnaireSection.ownWarehousePercentage,
      foreign_warehouse_share: onlineQuestionnaireSection.foreignWarehousePercentage,
      logistics_service_provider_share:
        onlineQuestionnaireSection.foreignLogisticsServiceProviderPercentage,
      amazon_fulfillment_share: onlineQuestionnaireSection.foreignFulfillmentByAmazonPercentage,
    },
  };
}
