import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useFormConfig, useFormConfigRepo } from 'config/formConfig/hooks';
import Condition from 'modules/ConditionalSubform/Condition';
import { CurrentFutureUsageType } from 'modules/Inquiry/CurrentFutureUsageType';
import { fieldIsNotEmpty } from 'modules/Inquiry/Form/formConditions';
import {
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PLOT_PRICE,
  BUILDING_PROPERTY,
  BUILDING_PURCHASE_PRICE,
  FINANCING_NEED,
  PRODUCT_KIND,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_USAGE_SPACE,
  PURPOSE_KIND,
  REAL_ESTATE_RESIDUAL_PERCENTAGE,
} from 'modules/Inquiry/Form/formFields';
import AmountSection from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/AmountSection';
import PurposeWithAmount from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/PurposeWithAmount';
import PurposeSection from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/PurposeSection';
import DisableDetailSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/DisableDetailSection';
import PurposeDetailsSection from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/PurposeDetailsSection';
import { useFinancialNeedSectionValidators } from 'modules/Inquiry/Form/Validations/default/useFinancialNeedSectionValidators';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import { getInquiriesAction } from 'modules/InquiryTable/service';
import FinancingNeedHeader from 'pages/inquiryFlow/FinancingNeed/FinancingNeedHeader/FinancingNeedHeader';
import { useToasts } from 'shared/hooks/useToasts';
import { initialState } from 'store/inquiryList/reducer';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getObjectFromQueryParamsString, removeQueryParamsFromUrl } from 'utils/paths';

const hasSelectedPurposeKind = fieldIsNotEmpty(PURPOSE_KIND);

export const FinancingNeedStep = () => {
  const isPurposeWithAmount = PurposeWithAmount();
  const getSectionValidators = useFinancialNeedSectionValidators();
  const tFinancingNeed = useTranslations('pages.financingNeed');

  const { selectedInquiryType } = useFormConfig();
  const isOnlineFactoring = selectedInquiryType === InquiryType.onlinefactoring;
  const isDefault = selectedInquiryType === InquiryType.default;
  const formConfig = useFormConfigRepo();
  const availablePurposes = formConfig.getCurrentPurposes();
  const productKinds = formConfig.getCurrentProducts(availablePurposes[0]);
  const parsedParams: any = getObjectFromQueryParamsString(window.location.search);
  const { pathToNextStep } = useInquiryNavigation(FINANCING_NEED);
  const history = useHistory();

  const { error } = useToasts();

  const initialFormValues = {
    [REAL_ESTATE_RESIDUAL_PERCENTAGE]: '0',
    [PROJECT_FINANCING__OBJECT_USAGE]: [null],
    [PROJECT_FINANCING__OBJECT_FUTURE_USAGE]: [null], // show at least one object initially
    [BUILDING_PURCHASE_PRICE]: 0,
    [BUILDING_PLOT_PRICE]: 0,
    [BUILDING_ADDITIONAL_COSTS]: 0,
    [BUILDING_MODERNISATION_COSTS]: 0,
    [BUILDING_DEBT_REDEMPTION]: 0,
    [BUILDING_EQUITY]: 0,
    [BUILDING_PERSONAL_CONTRIBUTION]: 0,
    [BUILDING_BORROWED_CAPITAL]: 0,
    [BUILDING_DEBT_CAPITAL_OTHER]: 0,
    [BUILDING_CONSTRUCTION_MANUFACTURE_COST]: 0,
    [BUILDING_PROPERTY]: 0,
    ...(isOnlineFactoring && { [PRODUCT_KIND]: productKinds[0] }),
    ...(isDefault && { [PURPOSE_KIND]: availablePurposes[0] }),
    ...parsedParams[FINANCING_NEED],
  };

  const dispatch = useDispatch();

  const validateObjectUsageField = (
    usages: Array<CurrentFutureUsageType>,
    isFuturetypeOfUsage: boolean = false,
  ): Boolean => {
    let usagesSpaceSum = 0,
      usagesUnitsSum = 0;
    let usageSpaceToNumberOfUnitsRatio = undefined;
    let typeOfUsage: keyof CurrentFutureUsageType = isFuturetypeOfUsage
      ? PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE
      : PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT;

    if (usages[0] !== null) {
      usages.forEach((usage: CurrentFutureUsageType) => {
        if (usage[typeOfUsage] === PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY) {
          usagesSpaceSum += Number(usage[PROJECT_FINANCING_USAGE_SPACE]);
          usagesUnitsSum += Number(usage[PROJECT_FINANCING__NUMBER_OF_UNITS]);
        }
      });

      usageSpaceToNumberOfUnitsRatio = usagesSpaceSum / usagesUnitsSum;

      if (usageSpaceToNumberOfUnitsRatio !== undefined && usageSpaceToNumberOfUnitsRatio < 10) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (values: any) => {
    const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
    const futureUsageValues: unknown = values[PROJECT_FINANCING__OBJECT_FUTURE_USAGE];
    const currentUsageValues = values[PROJECT_FINANCING__OBJECT_USAGE];
    if (
      !isProfiInquiry ||
      (Array.isArray(futureUsageValues) &&
        Array.isArray(currentUsageValues) &&
        validateObjectUsageField(futureUsageValues, true) &&
        validateObjectUsageField(currentUsageValues))
    ) {
      dispatch(
        getInquiriesAction({
          ...initialState.options,
          filterBy: { ...initialState.options.filterBy },
        }),
      );
      if (pathToNextStep) history.push(pathToNextStep);
    } else {
      error({
        description: tFinancingNeed('sections.objectInformation.fields.usageSpace.errors.minLimit'),
      });
    }
  };

  useEffect(() => {
    removeQueryParamsFromUrl();
    // This effect removes the query params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InquiryFormNew
      initialValues={initialFormValues}
      headerComp={<FinancingNeedHeader />}
      currentStepName={FINANCING_NEED}
      ignoreRedirection
      getSectionValidators={getSectionValidators}
      onSubmit={handleSubmit}
    >
      {() => (
        <>
          <PurposeSection />

          <Condition condition={isPurposeWithAmount}>
            <AmountSection />
          </Condition>

          <Condition condition={hasSelectedPurposeKind} onFail={<DisableDetailSection />}>
            <PurposeDetailsSection />
          </Condition>
        </>
      )}
    </InquiryFormNew>
  );
};

export default FinancingNeedStep;
