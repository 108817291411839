import { API_METHODS, callReverseApi } from 'api/apiService';
import { CHECK_EMAIL_AVAILABILITY } from 'api/CompeonReverseApi/customer/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const checkCustomerDataAvailable = (email: string) =>
  callReverseApi({
    actionName: CHECK_EMAIL_AVAILABILITY,
    url: endpoints.CUSTOMERS.DATA.compose({ query: { email } }, { qs: { encode: true } }),
    method: API_METHODS.GET,
  });
