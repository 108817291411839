import { roleSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/roleSection/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateRoleSection: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: roleSectionValidationMap,
    form,
    values,
    conditions,
  });
};
