import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { ConfigController } from 'config/ConfigController';
import { COMPEON_PARTNER, INQUIRY_DRAFT_ID } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { CREATE_INQUIRY_DRAFT, REMOVE_INQUIRY_DRAFT } from 'store/inquiryProcess/actionTypes';

const createInquiryDraftAction = (rawInquiry: object, selectedFormType: InquiryType) =>
  callReverseApi({
    actionName: CREATE_INQUIRY_DRAFT,
    url: endpoints.INQUIRIES.DRAFTS.LIST.compose(),
    method: API_METHODS.POST,
    data: () => {
      const {
        details: { id: partnerId },
      } = ConfigController.partner.getConfig();
      return {
        form_type: selectedFormType,
        data: { attributes: { ...rawInquiry, [COMPEON_PARTNER]: partnerId } },
      };
    },
  });

const updateInquiryDraftAction = (draftId: string, rawInquiry: object) =>
  callReverseApi({
    actionName: 'UPDATE_INQUIRY_DRAFT',
    url: endpoints.INQUIRIES.DRAFTS.DETAILS.compose({ params: { draftId } }),
    method: API_METHODS.PUT,
    data: () => {
      const {
        details: { id: partnerId },
      } = ConfigController.partner.getConfig();
      return {
        data: {
          attributes: {
            ...rawInquiry,
            [INQUIRY_DRAFT_ID]: draftId,
            [COMPEON_PARTNER]: partnerId,
          },
        },
      };
    },
  });

export const saveInquiryDraftAction = (
  draftId: string,
  rawInquiry: object,
  selectedFormType: InquiryType,
) =>
  draftId
    ? updateInquiryDraftAction(draftId, rawInquiry)
    : createInquiryDraftAction(rawInquiry, selectedFormType);

export const removeInquiryDraftAction = (draftId: string) =>
  callReverseApi({
    actionName: REMOVE_INQUIRY_DRAFT,
    url: endpoints.INQUIRIES.DRAFTS.DETAILS.compose({ params: { draftId } }),
    method: API_METHODS.DELETE,
  });
