import React from 'react';

import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

import { ContactPersonSection, LoggedInContactPersonSection } from './sections/contactPerson';
import { LoggedInPasswordSection, PasswordSection } from './sections/passwordSection';
import { TermsAndConditionsSection } from './sections/termsAndConditions';

const NotLoggedInUser = () => {
  return (
    <>
      <ContactPersonSection />
      <PasswordSection />
      <TermsAndConditionsSection />
    </>
  );
};

const LoggedInUser = () => {
  return (
    <>
      <LoggedInContactPersonSection />
      <LoggedInPasswordSection />
      <TermsAndConditionsSection />
    </>
  );
};

export const MittweidaUserProfileStep = () => {
  const t = useTranslations();
  const isLoggedIn = isLoggedInUser();
  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.mittweida.pages.userDetails;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      {isLoggedIn ? <LoggedInUser /> : <NotLoggedInUser />}
    </Page>
  );
};
