import _get from 'lodash/get';

import {
  LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION,
  LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION,
  LENDING_VALUE_NOTES_ON_REMAINING_RISK,
} from 'modules/Inquiry/Form/formFields';

const mapLendingValueFromApi = (response: unknown) => {
  const data = _get(response, 'attributes.details.lending_value');

  const lendingValue = {
    [LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT]: _get(
      data,
      'buying_phase.lending_limit_percent',
      '0,00',
    ),
    [LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION]: _get(data, 'buying_phase.risk_deduction', '0,00'),
    [LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT]: _get(
      data,
      'indication.lending_limit_percent',
      '0,00',
    ),
    [LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT]: _get(
      data,
      'whole_project.lending_limit_percent',
      '0,00',
    ),
    [LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION]: _get(
      data,
      'whole_project.risk_deduction',
      '0,00',
    ),
    [LENDING_VALUE_NOTES_ON_REMAINING_RISK]: _get(data, 'notes_on_remaining_risk', ''),
  };

  return lendingValue;
};

export default mapLendingValueFromApi;
