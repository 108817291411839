import React from 'react';

import { useIntl } from 'react-intl';

import { ErrorText, Text } from 'components/Text';

import { StyledTitle as Title, StyledDescription as Description } from './styles';

interface Props {
  title: string;
  description?: React.ReactNode;
  hasError?: boolean;
  additionalMessage?: string;
  button: React.ReactNode;
  children?: React.ReactNode;
}

const Section: React.FC<Props> = ({
  title,
  description,
  hasError,
  additionalMessage,
  button,
  children,
  ...restProps
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <div {...restProps}>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {additionalMessage && <Text data-testid="additional-message">{additionalMessage}</Text>}
      {button}
      {hasError && <ErrorText>{formatMessage({ id: 'errors.actionFailed' })}</ErrorText>}
      {children}
    </div>
  );
};

export default Section;
