import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const EditIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M20 10.54a1 1 0 00-1 1v7a1 1 0 01-1 1H6a1 1 0 01-1-1V8.5a1 1 0 011-1h5a1 1 0 000-2H6a3 3 0 00-3 3v10a3 3 0 003 3h12a3 3 0 003-3v-7a1 1 0 00-1-.96z" />
      <path d="M21.47 5l-2-2a1 1 0 00-1.42 0l-6 6a1 1 0 00-.29.7v2a1 1 0 001 1h2a1 1 0 00.71-.29l6-6a1 1 0 000-1.41zm-6.88 5.5H14v-.59l5-5 .59.59z" />
    </Icon>
  );
};

export default EditIcon;
