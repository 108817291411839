import * as React from 'react';

import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isOpen: boolean;
  onClose(): void;
  children?: React.ReactNode;
}

export const AssignRealEstateExpertModal = ({ isOpen, onClose, children }: Props) => {
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.assignInquiryToRealEstateExpert',
  );

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title={t('modal.title')}>
      <TextComponent mb="2rem" mt="2rem" color="text.tertiary">
        {t('modal.subDescription')}
      </TextComponent>
      {children}
    </ModalComponent>
  );
};
