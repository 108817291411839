import { compeonOfferUnitFormatters } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/compeonOfferUnits/unitFormatters';
import { COMPEON_OFFER_PROPS } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/getPropertiesForProduct';

const { percentPerYear, monthsUnits, price, roundPricePerMonth } = compeonOfferUnitFormatters;

export const compeonLoanUnits = {
  [COMPEON_OFFER_PROPS.amount]: price,
  [COMPEON_OFFER_PROPS.termInMonths]: monthsUnits,
  [COMPEON_OFFER_PROPS.value]: percentPerYear,
  [COMPEON_OFFER_PROPS.indicativeMonthlyRate]: roundPricePerMonth,
};
