import { MORE_THAN, RANGE_SEPERATOR } from 'modules/Inquiry/inquiry.constants';
import {
  INVOICES_PER_YEAR,
  PAYMENT_TERMS,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  SHARE_OF_INSURANCE_CLAIMS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
} from 'onlinefactoring/formFields';

type FieldValuesMap = Record<
  string,
  (value: string, translation: (key: string) => string) => string
>;

const parseStepsToValue = (value: string, translation: (key: string) => string): string => {
  if (typeof value !== 'string') {
    return value;
  }

  if (value.includes(RANGE_SEPERATOR)) {
    const splittedValue = value.split(RANGE_SEPERATOR);
    return `${splittedValue[0]} - ${splittedValue[1]}`;
  }

  if (value.includes(MORE_THAN)) {
    const splittedValue = value.split(MORE_THAN);
    return `${translation('other.moreThan')} ${splittedValue[1]}`;
  }

  return value;
};

export const fieldValuesMap: FieldValuesMap = {
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP]: parseStepsToValue,
  [SHARE_OF_INSURANCE_CLAIMS]: parseStepsToValue,
  [PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE]: parseStepsToValue,
  [INVOICES_PER_YEAR]: parseStepsToValue,
  [PAYMENT_TERMS]: parseStepsToValue,
};
