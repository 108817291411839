import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { offerCalculationAction } from 'store/inquiryDetails/actions';
import { getCreatedInquiryId } from 'store/progress/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useRequestIndicativeConditions = () => {
  const { makeCall, isPending } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });
  const inquiryId = useSelector(getCreatedInquiryId);
  const requestIndicativeConditions = useCallback(
    async (accessToken?: string) => {
      const { payload, error } = await makeCall(offerCalculationAction(inquiryId, accessToken));
      if (!error) {
        return payload;
      }
      return error;
    },
    [makeCall, inquiryId],
  );

  return { isPending, requestIndicativeConditions };
};
