import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import forwardToCustomerAction from 'api/CompeonReverseApi/operation/forwardToCustomer/actions';
import { translations } from 'new/form/common/types';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ForwardingBlockedReasons } from './ForwardingBlockedReasons';
import { useInquiryForwarding } from '../../ForwardInquiry/useInquiryForwarding';
import Section from '../Section';
import Button from '../Section/Button';

const { buttonLabel, description, modal, messageAfterAction, title } =
  translations.pages.inquiryDetails.dashboard.actions.forwardToCompeon;

const ForwardToCompeonWithConfirmation = () => {
  const t = useTranslations();
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const dispatch = useDispatch();
  const { isForwardableToCompeon } = useInquiryForwarding();

  const forward = async () => {
    onClose();
    makeCall(forwardToCustomerAction(inquiryId), ({ payload }) => {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({ description: t(messageAfterAction) });
    });
  };

  const button = (
    <Button
      isLoading={isPending}
      disabled={!isForwardableToCompeon}
      onClick={onOpen}
      label={t(buttonLabel)}
    />
  );

  const sectionDescription = isForwardableToCompeon ? t(description) : <ForwardingBlockedReasons />;

  return (
    <>
      <Section
        title={t(title)}
        hasError={Boolean(error)}
        button={button}
        description={sectionDescription}
      />

      <AlertDialogComponent
        cancelText={t(modal.cancel)}
        confirmText={t(modal.confirm)}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={forward}
        title={t(modal.header)}
      >
        {t(modal.description)}
      </AlertDialogComponent>
    </>
  );
};

export default ForwardToCompeonWithConfirmation;
