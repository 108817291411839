import { useTranslations } from 'utils/hooks/useTranslations';

import { Solvency, TrafficLightProps } from './TrafficLights';

export function useColorFromSolvency(solvency: Solvency | undefined): TrafficLightProps {
  const t = useTranslations('pages.companiesDetails.riskAnalysis.trafficLight');
  const defaultReturn = {
    colors: [undefined, undefined, undefined],
    message: '',
  };

  if (solvency) {
    switch (solvency) {
      case 'green':
        return {
          colors: ['green', undefined, undefined],
          message: t('goodSolvency'),
        };
      case 'yellow':
        return {
          colors: [undefined, 'yellow', undefined],
          message: t('mediocreSolvency'),
        };
      case 'red':
        return {
          colors: [undefined, undefined, 'red'],
          message: t('badSolvency'),
        };
      default: {
        return defaultReturn;
      }
    }
  }

  return defaultReturn;
}
