/* eslint-disable */
import { useTranslations } from 'utils/hooks/useTranslations';
import { useToasts } from 'shared/hooks/useToasts';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import React from 'react';
import paths from 'constants/paths';
import { translations } from 'new/form/common/types';
import { useGetInitialValuesForEditMode } from 'new/form/persist';
import { FieldTypes } from 'mmv/inquiry/fields';
import { useSelector } from 'react-redux';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import {
  createFinalizeSpecializedInquiryAction,
  createSpecializedInquiryAction,
} from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { InquiryProcessForm } from 'new/form';
import { useRootRedirect } from 'new/inquiry';
import { ContractDetails } from './steps/contractDetails';
import { MmvCustomerDataStep } from './steps/customerData';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { mmvPeopleDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/peopleMapper/mmvPeopleDetailsToApi';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';

export const MMV_FORM = 'MMV_FORM';

function mapContractDetailsToApi(values: FieldTypes) {
  const { financingObjectSection, bankDetailsSection } = values.contractDetails;
  return {
    details: {
      serial_number: financingObjectSection.serialNumber || null,
      object_already_delivered: financingObjectSection.objectAlreadyDelivered,
      iban: bankDetailsSection.iban,
    },
  };
}

function mapCustomerDataToApi(values: FieldTypes) {
  return {
    ...mmvPeopleDetailsToApi(values),
  };
}

// chapter: send inquiry
const useSendMmvInquiry = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const t = useTranslations();
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.submitInquiry)));

  return async (values: FieldTypes) => {
    const contractDetails = mapContractDetailsToApi(values);
    const customerData = mapCustomerDataToApi(values);

    const apiData = {
      ...contractDetails,
      ...customerData,
    };
    const sendMmvInquiry = () => createSpecializedInquiryAction(apiData, 'mmv');
    const updateMmvInquiry = (inquiryId: string) =>
      createFinalizeSpecializedInquiryAction(apiData, InquiryType.mmv, inquiryId);
    return makeCall(inquiryId ? updateMmvInquiry(inquiryId) : sendMmvInquiry());
  };
};

const useSendInquiry = () => {
  return useSendMmvInquiry();
};

// chapter: forms

const EditCustomerRoutes = () => {
  return (
    <Switch>
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.contractDetails}
        component={ContractDetails}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.customerData}
        component={MmvCustomerDataStep}
      />
      <Redirect to={paths.customer.login} />
    </Switch>
  );
};

export const MmvEditCustomerPortalForm = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const initialValues = useGetInitialValuesForEditMode();
  const sendInquiry = useSendInquiry();
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();
  useRootRedirect('editCustomer');
  const { refetchInquiry } = useFetchInquiryData();

  const handleSubmit = async (values: FieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      await refetchInquiry();
      history.push(paths.customer.inquiryDetails.dashboard.replace(':id', inquiryId!!));
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
    return !error;
  };

  return (
    <InquiryProcessForm<FieldTypes> initialValues={initialValues} handleSubmit={handleSubmit}>
      <EditCustomerRoutes />
    </InquiryProcessForm>
  );
};
