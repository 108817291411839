import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import FiltersDrawer from 'pages/operationPortal/OperationInquiryList/FiltersDrawer';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledFilterWrapper } from '../styles';

export const InquiryFilters = ({ badge }: { badge: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslations();

  return (
    <StyledFilterWrapper>
      <ButtonComponent badge={badge} data-testid="filter-inquiries-button" onClick={onOpen}>
        {t('pages.inquiryList.filtersButton')}
      </ButtonComponent>

      <FiltersDrawer isOpen={isOpen} onClose={onClose} />
    </StyledFilterWrapper>
  );
};
