import { useFormState } from 'react-final-form';

import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { getMonthsOptions } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/service';
import { useTranslations } from 'utils/hooks/useTranslations';

export function useGetFoundingMonthOptions() {
  const { values } = useFormState<MittweidaFieldTypes>();
  const t = useTranslations();
  const { foundingYear } = values.companyDetailPage.completingCompanyDetailsSection.company;

  if (foundingYear) {
    const currentYear = new Date().getUTCFullYear();
    const currentMonth = new Date().getUTCMonth() + 1;

    return getMonthsOptions(t).filter(
      ({ value }) => !(currentYear === foundingYear && value > currentMonth),
    );
  }

  return [];
}
