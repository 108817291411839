import React, { useContext } from 'react';

import { INPUT_TYPE_DATE, InputWithField } from 'components/Input';
import { REPRESENTATIVE_BIRTHDATE } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';

interface RepresentativeBirthdateProps {
  isDisabled?: boolean;
}

const RepresentativeBirthdate: React.FC<RepresentativeBirthdateProps> = ({ isDisabled }) => {
  const { fieldName } = useContext(LegalRepresentativesContext);

  return (
    <InputWithField
      name={`${fieldName}.${REPRESENTATIVE_BIRTHDATE}`}
      disabled={isDisabled}
      type={INPUT_TYPE_DATE}
    />
  );
};

export default RepresentativeBirthdate;
