import { mmvBankDetailsSectionValidationMap } from 'mmv/inquiry/steps/contractDetails/sections/bankDetails/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const bankDetailsValidation: ValidateSectionFunction<InquiryType.mmv> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: mmvBankDetailsSectionValidationMap,
    form,
    values,
    conditions,
  });
};
