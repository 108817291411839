import { useLoadInquiriesList } from 'api/CompeonReverseApi/operation/queryHooks';
import { useInquiriesListContext } from 'modules/InquiriesListProvider';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useCompanyCountries = () => {
  const t = useTranslations();
  const { tableOptions } = useInquiriesListContext();

  const { data } = useLoadInquiriesList({
    variables: { options: tableOptions },
  });

  const countriesOptions = data?.selectBoxOptions?.companyCountries.map((country: string) => ({
    value: country,
    label: t(`pages.inquiryList.filtersDrawer.fields.selectCountries.${country}`),
  }));

  return {
    countriesOptions: countriesOptions || [],
  };
};
