import { API_METHODS, callReverseApi } from 'api/apiService';
import { SAVE_INQUIRIES } from 'api/CompeonReverseApi/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { addInquiryProcessPropsToPayload } from 'shared/inquiry/inquiryProcess';
import { IState } from 'store';

export const saveInquiriesAction = (rawInquiry: any) =>
  callReverseApi({
    actionName: SAVE_INQUIRIES,
    url: endpoints.INQUIRIES.LIST.compose(),
    method: API_METHODS.POST,
    data: (state: IState) =>
      addInquiryProcessPropsToPayload(state.inquiryProcess || {}, rawInquiry),
  });

export const updateInquiryAction = (inquiryId: string, rawInquiry: any) =>
  callReverseApi({
    actionName: 'UPDATE_INQUIRY',
    url: endpoints.INQUIRIES.DETAILS.compose({ params: { id: inquiryId } }),
    method: API_METHODS.PUT,
    data: (state: IState) =>
      addInquiryProcessPropsToPayload(state.inquiryProcess || {}, rawInquiry),
  });
