import React from 'react';

import { FileUploadAction } from './FileUploadAction';
import ProgressSectionsWrapper from './ProgressSectionsWrapper';
import { ProgressSuccess } from './ProgressSuccess';
import { NewSignContractStep } from './SignContractStep/NewSignContractStep';
import SummaryCard from './SummaryCard';
import SummaryHeader from './SummaryHeader';
import { ProgressSectionType } from './types';
import { mapDashboardActionAttributes, useActionCalculations } from './useDashboardActions';

export type DashboardActionProps = {
  completedValue: number;
  isCompleted: boolean;
  isFirst: boolean;
  isLast: boolean;
  isPending: boolean;
  progressIncrement: number;
  progressTotal: number;
  data: any;
  id: string;
  state: string;
};

export const DashboardAction = ({
  type,
  dashboardActionProps,
}: {
  type: string;
  dashboardActionProps: DashboardActionProps;
}) => {
  switch (type) {
    case ProgressSectionType.FILE_UPLOAD_DASHBOARD_ACTION:
      return <FileUploadAction {...dashboardActionProps} />;
    case ProgressSectionType.LEGAL_REP_SIGNATURE_PROCESS_DASHBOARD_ACTION:
      return <NewSignContractStep {...dashboardActionProps} />;
    default:
      return null;
  }
};

const NewCustomerDashboard = () => {
  const {
    actionsDone,
    firstOpenTask,
    completedValue,
    isLoading: dashboardActionsIsLoading,
    dashboardActions,
    progressTotal,
    isCompleted,
    canShowProgress,
    isLastItem,
  } = useActionCalculations();

  const header = (
    <>
      <SummaryHeader />
      <SummaryCard
        completedValue={completedValue}
        progressIncrement={actionsDone}
        progressTotal={progressTotal}
        isCompleted={isCompleted}
        openTaskId={firstOpenTask}
        canShowProgress={canShowProgress}
      />
    </>
  );

  const progressSections = (
    <>
      {dashboardActions.map((action, index) => (
        <DashboardAction
          key={action.id}
          type={action.type}
          dashboardActionProps={{
            ...mapDashboardActionAttributes(action, index),
            isPending: dashboardActionsIsLoading,
            isLast: isLastItem(index),
          }}
        />
      ))}
      {isCompleted && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}
    </>
  );

  return (
    <ProgressSectionsWrapper
      header={header}
      progressSections={progressSections}
      isLoading={dashboardActionsIsLoading}
    />
  );
};

export default NewCustomerDashboard;
