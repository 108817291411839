import React from 'react';

import { RadioGroupWithField } from 'components/RadioGroup';
import {
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const ChooseFinancingOption: React.FC = () => {
  const t = useTranslations('pages.coronaStep.fields.financingOption');

  return (
    <RadioGroupWithField
      name={CORONA_FINANCING_OPTION}
      options={[
        {
          caption: t('options.quickCredit'),
          value: CORONA_FINANCING_OPTION__QUICK_CREDIT,
        },
        {
          caption: t('options.kfwProgram'),
          value: CORONA_FINANCING_OPTION__KFW_PROGRAM,
        },
      ]}
    />
  );
};

export default ChooseFinancingOption;
