import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import FormRow from 'components/FormRow';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import {
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_GENDER,
} from 'modules/Inquiry/Form/formFields';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

import { EditFormValues } from './types';

interface Props {
  initialValues?: EditFormValues;
  handleEditFormSubmit: (values: EditFormValues) => void;
  handleEditFormCancel: () => void;
}

export const EditLegalRepresentativeForm = ({
  initialValues,
  handleEditFormSubmit,
  handleEditFormCancel,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection');

  return (
    <Form
      onSubmit={handleEditFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormRow space="2rem">
            <GenderWithField name={REPRESENTATIVE_GENDER} caption={t('fieldLabels.gender')} />
          </FormRow>
          <FormRow space="2rem">
            <InputWithField name={REPRESENTATIVE_FIRST_NAME} />
            <InputWithField name={REPRESENTATIVE_LAST_NAME} />
          </FormRow>
          <FormRow space="2rem">
            <InputWithField name={REPRESENTATIVE_EMAIL} />
          </FormRow>
          <FormRow space="2rem">
            <InputWithField name={REPRESENTATIVE_PHONE_NUMBER} />
          </FormRow>
          <Flex justifyContent="flex-end" mt="2rem">
            <ButtonComponent mr={4} onClick={handleEditFormCancel} variant="tertiary">
              {t('actions.cancel')}
            </ButtonComponent>
            <ButtonComponent type="submit" disabled={!valid}>
              {t('actions.submitChanges')}
            </ButtonComponent>
          </Flex>
        </form>
      )}
    />
  );
};
