import {
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  HIRE_PURCHASE_TERM_IN_MONTHS,
  HIRE_PURCHASE_AMORTISATION_KIND,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  HIRE_PURCHASE_DESCRIPTION,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger, toBoolean, toFloatPrecision2 } = fieldParsers;
export const mapHirePurchaseToApi: InquiryFormMapper = (allFields) => {
  const isPartialAmortisation =
    allFields[HIRE_PURCHASE_AMORTISATION_KIND] ===
    HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION;

  return {
    'hire-purchase-advance-payment': toInteger(allFields[HIRE_PURCHASE_ADVANCE_PAYMENT]),
    'hire-purchase-term-in-months': toInteger(allFields[HIRE_PURCHASE_TERM_IN_MONTHS]),
    'hire-purchase-amortisation-kind': allFields[HIRE_PURCHASE_AMORTISATION_KIND],
    'hire-purchase-description': allFields[HIRE_PURCHASE_DESCRIPTION],
    'hire-purchase-interested-in-investment-loan': toBoolean(
      allFields[HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN],
    ),
    ...conditionalObject(isPartialAmortisation, {
      'hire-purchase-residual-value': toFloatPrecision2(allFields[HIRE_PURCHASE_RESIDUAL_VALUE]),
    }),
  };
};
