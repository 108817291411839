import type { AnyAction } from 'redux';

import { DEFAULT_LANGUAGE } from 'constants/globalConstants';

import { CHANGE_LANGUAGE } from './actions';
import { type Language } from '../useLanguages';

export interface LanguageState {
  selectedLanguage: Language;
}

const initialState: LanguageState = {
  selectedLanguage: DEFAULT_LANGUAGE,
};

export const languageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    default:
      return state;
  }
};
