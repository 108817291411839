import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import BfsMachineIcon from './BfsMachineIcon';

const MachineIcon = (props: IconProps) => {
  return (
    <Icon
      width="48"
      height="43"
      viewBox="0 0 48 42.93"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M30.24 34.17H8.73A4.87 4.87 0 003.87 39v3.3a.64.64 0 00.64.63H34.3a.64.64 0 00.64-.63v-3.43a4.71 4.71 0 00-4.7-4.7zm3.43 7.49H5.14V39a3.59 3.59 0 013.59-3.59h21.51a3.44 3.44 0 013.43 3.43z" />
      <path d="M14.77 35.36a.64.64 0 00.23-.87L3.36 14.33v-.11a.72.72 0 00-.08-.32.68.68 0 00-.39-.3h-.16a.62.62 0 00-.32.09l-.12.07a.63.63 0 00-.23.87l11.84 20.5a.65.65 0 00.87.23zm11.83-.87l-12.47-21.6a.67.67 0 00-.39-.29.45.45 0 00-.16 0 .62.62 0 00-.32.09.65.65 0 00-.3.39.63.63 0 00.07.48L25.5 35.13a.66.66 0 00.87.23.6.6 0 00.29-.39.58.58 0 00-.06-.48z" />
      <path d="M7.22 2.22A7.23 7.23 0 000 9.43a7.1 7.1 0 002.29 5.24 7.17 7.17 0 0010.79-1.06 5.72 5.72 0 00.57-.94 7 7 0 00.78-3.24 7.21 7.21 0 00-7.21-7.21zm5.29 9.88a4.39 4.39 0 01-.46.76A5.94 5.94 0 011.27 9.43a5.94 5.94 0 0111.16-2.84 5.8 5.8 0 01.73 2.84 5.7 5.7 0 01-.65 2.67z" />
      <path d="M23.31 6.92a.64.64 0 00.63-.64.63.63 0 00-.63-.63H13a.64.64 0 00-.64.63.64.64 0 00.64.64zm0 6.93a.64.64 0 00.63-.64.63.63 0 00-.63-.63h-9.73a.64.64 0 00-.64.63.64.64 0 00.64.64z" />
      <path d="M30.87 5.11a1.73 1.73 0 00-.79-1.44 1.68 1.68 0 00-.9-.26h-4.81a1.7 1.7 0 00-1.7 1.7v9.2a1.7 1.7 0 001.7 1.69h4.81a1.65 1.65 0 001.4-.75 1.68 1.68 0 00.29-.94zm-1.31 9.62h-5.62v-10h5.32l.27.17v.25z" />
      <path d="M35.89 1.34l11.19 4.23a.66.66 0 00.82-.37.64.64 0 00-.37-.82L36.06 0a.63.63 0 00-.55 0l-6.09 3.66a.63.63 0 00-.29.39.62.62 0 00.06.48.65.65 0 00.87.23zm-6.14 14.09l5.89 3.35a.66.66 0 00.55 0l11.41-4.42A.65.65 0 0048 14a.66.66 0 000-.48.64.64 0 00-.83-.36L36 17.53l-5.63-3.2a.61.61 0 00-.31-.09.64.64 0 00-.32 1.19zM7.21 6.23a3.21 3.21 0 103.21 3.2 3.2 3.2 0 00-3.21-3.2zm0 5.14a1.94 1.94 0 111.94-1.94 1.94 1.94 0 01-1.94 1.94z" />
    </Icon>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BfsMachineIcon,
  default: MachineIcon,
});
