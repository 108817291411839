import {
  GOODS__DESCRIPTION,
  GOODS__PURCHASE_PRICE,
  GOODS__SUPPLIER,
  GOODS__ESTIMATED_DELIVERY_DATE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

export const mapGoodsToApi: InquiryFormMapper = (allFields: any) => ({
  'goods-purchase-price': allFields[GOODS__PURCHASE_PRICE],
  'goods-description': allFields[GOODS__DESCRIPTION],
  'goods-supplier': allFields[GOODS__SUPPLIER],
  'goods-estimated-delivery-date': allFields[GOODS__ESTIMATED_DELIVERY_DATE],
});
