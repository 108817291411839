import { ConfigController } from 'config/ConfigController';
import { getFieldValue } from 'modules/Inquiry/Form/formConditions';
import {
  PURPOSE_KIND,
  PURPOSE_KIND__DEBT_RESCHEDULING,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__CREFO_FACTORING,
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__NEW_BUILDING,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  NUMBER_OF_TECHNOLOGIES,
  NUMBER_OF_TECHNOLOGIES__ONE,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PURPOSE_KIND__FACTOMAT,
} from 'modules/Inquiry/Form/formFields';
import { validateAmountSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/AmountSection';
import { validatePurposeSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/PurposeSection';
import { validateBuildingFinancingPlanSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingFinancingPlan';
import { validateBuildingInformationSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingInformationSection';
import { validateBuildingProjectSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/BuildingProjectSection';
import { validateVehicleSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CommercialVehicle/CommercialVehicleSection';
import { shouldShowOtherKfwProgram } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/CoronaSection';
import { validateOtherKfwProgramSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/OtherKfwProgram';
import { validateQuickCreditSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/QuickCreditSection';
import { validateDebtReschedulingSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/DebtReschedulingSection';
import { validateClientRoleSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/EnergyEfficiencySections/ClientRoleSection/ClientRoleSection';
import { validateMoreTechnologySection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/EnergyEfficiencySections/TechnologiesDetailsSection/MoreTechnologies';
import { validateOneTechnologySection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/EnergyEfficiencySections/TechnologiesDetailsSection/OneTechnology';
import { validateTechnologySection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/EnergyEfficiencySections/TechnologySection/TechnologySection';
import { validateGoodsSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/GoodsSection';
import { validateGuarantySection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/GuarantySection/Guaranty';
import { validateYourCustomerSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/KfinanzSections/YourCustomer/YourCustomerSection';
import { validateYourFinanceSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/KfinanzSections/YourFinanceSection/YourFinanceSection';
import { validateMachineSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/MachineSection';
import { validateOfficeEquipmentSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/OfficeEquipmentSection';
import { validateOtherPurposeSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/OtherSection';
import { validateObjectInformationSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/ObjectInformationSection';
import { validateProjectCompositionSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ProjectComposition/ProjectCompositionSection';
import { validateRoleSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/RoleSubsection/RoleSubsection';
import { validateDetailsOfBuildingSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/DetailsOfBuildingSection';
import { validateFollowUpFinancingSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/FollowUpFinancingSection';
import { validateNewBuildingSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/NewBuildingSection';
import { validateProjectDevelopmentSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/ProjectDevelopmentSection';
import { validatePurchaseSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/PurchaseSection';
import { validateRealEstateProjectSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/RealEstateProjectSection';
import { validateReconstructionSection } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/ReconstructionSection';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFactoringPurposeSection } from 'onlinefactoring/purpose/sections/FacotringPurposeSection';
import { validateOtherSection } from 'onlinefactoring/purpose/sections/OtherSection';
import { validateYourBusinessSection } from 'onlinefactoring/purpose/sections/YourBusinessSection';

import { validateBfsServiceBusinessSection } from '../../Steps/FinancingNeed/PurposeDetails/BfsServiceSections/BfsServiceBusinessSection';
import { validateBFSServiceNeedSection } from '../../Steps/FinancingNeed/PurposeDetails/BfsServiceSections/FactoringNeedSection';
import { validateLiquiditySection } from '../../Steps/FinancingNeed/PurposeDetails/LiquiditySection';
import { validatePropertyDocumentSection } from '../../Steps/FinancingNeed/PurposeDetails/ProjectFinancing/PropertyDocuments/PropertDocumentSection';
import { bfsValidateFollowUpSection } from '../../Steps/FinancingNeed/PurposeDetails/RealEstate/BFSRealEstateFields/BFSFollowUpFinancing';
import { bfsValidateDetailsOfRealEstateSection } from '../../Steps/FinancingNeed/PurposeDetails/RealEstate/BFSRealEstateFields/BFSNewBuilding';
import { bfsValidateProjectDevelopmentSection } from '../../Steps/FinancingNeed/PurposeDetails/RealEstate/BFSRealEstateFields/BFSProjectDevelopment';
import { bfsValidatePurchaseSection } from '../../Steps/FinancingNeed/PurposeDetails/RealEstate/BFSRealEstateFields/BFSPurchase';
import { bfsValidateDetailsOfReconstructionSection } from '../../Steps/FinancingNeed/PurposeDetails/RealEstate/BFSRealEstateFields/BFSReconstruction';

// temp validator for temp state to use length
const tempValidator = () => false;
const getRealEstateValidators = (realEstateProject) => {
  const realEstateSections = {
    [REAL_ESTATE_PROJECT__NEW_BUILDING]: [
      validateNewBuildingSection,
      validateDetailsOfBuildingSection,
    ],
    [REAL_ESTATE_PROJECT__PURCHASE]: [validatePurchaseSection, validateDetailsOfBuildingSection],
    [REAL_ESTATE_PROJECT__RECONSTRUCTION]: [
      validateReconstructionSection,
      validateDetailsOfBuildingSection,
    ],
    [REAL_ESTATE_PROJECT__FOLLOW_UP]: [
      validateFollowUpFinancingSection,
      validateDetailsOfBuildingSection,
    ],
    [REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT]: [validateProjectDevelopmentSection],
  };
  const notSelectedProject = [tempValidator, tempValidator];
  return realEstateSections[realEstateProject] || notSelectedProject;
};

const getBfsRealEstateValidators = (realEstateProject) => {
  const realEstateSections = {
    [REAL_ESTATE_PROJECT__NEW_BUILDING]: [
      bfsValidateDetailsOfRealEstateSection,
      validateDetailsOfBuildingSection,
    ],
    [REAL_ESTATE_PROJECT__PURCHASE]: [bfsValidatePurchaseSection, validateDetailsOfBuildingSection],
    [REAL_ESTATE_PROJECT__RECONSTRUCTION]: [
      bfsValidateDetailsOfReconstructionSection,
      validateDetailsOfBuildingSection,
    ],
    [REAL_ESTATE_PROJECT__FOLLOW_UP]: [
      bfsValidateFollowUpSection,
      validateDetailsOfBuildingSection,
    ],
    [REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT]: [bfsValidateProjectDevelopmentSection],
  };
  const notSelectedProject = [tempValidator];
  return realEstateSections[realEstateProject] || notSelectedProject;
};

const getCoronaPurposeValidators = (form) => {
  if (shouldShowOtherKfwProgram({ form })) {
    return [
      validateQuickCreditSection,
      validateOtherKfwProgramSection,
      validateOtherPurposeSection,
    ];
  }
  return [validateQuickCreditSection, validateOtherPurposeSection];
};

export const useFinancialNeedSectionValidators = () => {
  return (form) => {
    const defaultValidators = [validateAmountSection, validatePurposeSection(form)];
    const selectedPurposeKind = getFieldValue(PURPOSE_KIND, form);
    const selectedRealEstateProject = getFieldValue(REAL_ESTATE_PROJECT, form);
    const isOneTchnology =
      getFieldValue(NUMBER_OF_TECHNOLOGIES, form) === NUMBER_OF_TECHNOLOGIES__ONE;
    const selectedInquiryType = ConfigController.form.getSelectedInquiryType();
    const commonInquiryTypeProjectFinancingValidators = [
      validateRoleSection,
      validateProjectCompositionSection,
      validateObjectInformationSection(selectedInquiryType),
      validatePropertyDocumentSection(selectedInquiryType),
    ];

    const guarantyInquiryTypeMap = {
      [InquiryType.bfs]: [validatePurposeSection(form), ...validateGuarantySection],
      default: defaultValidators,
    };
    const realEstateInquiryTypeMap = {
      [InquiryType.bfs]: [
        ...defaultValidators,
        ...getBfsRealEstateValidators(selectedRealEstateProject),
      ],
      default: [
        ...defaultValidators,
        validateRealEstateProjectSection(form),
        ...getRealEstateValidators(selectedRealEstateProject),
      ],
    };

    const projectFinancingInquiryTypeMap = {
      [InquiryType.profiRLL]: commonInquiryTypeProjectFinancingValidators,
      default: [validatePurposeSection(form), ...commonInquiryTypeProjectFinancingValidators],
    };

    const commonPurposeKindValidators = {
      [PURPOSE_KIND__GOODS]: [...defaultValidators, validateGoodsSection],
      [PURPOSE_KIND__MACHINE]: [...defaultValidators, validateMachineSection],
      [PURPOSE_KIND__OFFICE_EQUIPMENT]: [...defaultValidators, validateOfficeEquipmentSection],
      [PURPOSE_KIND__GUARANTY]:
        guarantyInquiryTypeMap[selectedInquiryType] || guarantyInquiryTypeMap.default,
      [PURPOSE_KIND__OVERDRAFT_LIQUIDITY]: [...defaultValidators, validateLiquiditySection],
      [PURPOSE_KIND__DEBT_RESCHEDULING]: [...defaultValidators, validateDebtReschedulingSection],
      [PURPOSE_KIND__VEHICLE]: [...defaultValidators, validateVehicleSection],
      [PURPOSE_KIND__OTHER]: [...defaultValidators, validateOtherPurposeSection],
      [PURPOSE_KIND__KFINANZ]: [
        validateYourFinanceSection,
        validateYourCustomerSection,
        validateOtherSection,
      ],
      [PURPOSE_KIND__CREFO_FACTORING]: [
        validateFactoringPurposeSection,
        validateYourBusinessSection,
        validateOtherSection,
      ],
      [PURPOSE_KIND__CORONA]: [validatePurposeSection(form), ...getCoronaPurposeValidators(form)],

      [PURPOSE_KIND__REAL_ESTATE]: realEstateInquiryTypeMap[selectedInquiryType],
      [PURPOSE_KIND__PROJECT_FINANCING]:
        projectFinancingInquiryTypeMap[selectedInquiryType] ||
        projectFinancingInquiryTypeMap.default,
      [PURPOSE_KIND__BUILDING]: [
        validatePurposeSection(form),
        validateBuildingProjectSection,
        validateBuildingInformationSection,
        validateBuildingFinancingPlanSection,
      ],
      [PURPOSE_KIND__ENERGY_EFFICIENCY]: [
        validateClientRoleSection,
        validateTechnologySection,
        isOneTchnology ? validateOneTechnologySection : validateMoreTechnologySection,
      ],
      [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: [
        validateBFSServiceNeedSection,
        validateBfsServiceBusinessSection,
      ],
      [PURPOSE_KIND__FACTOMAT]: [
        validateBFSServiceNeedSection,
        validateBfsServiceBusinessSection,
        validateOtherPurposeSection,
      ],
    };

    const validatorsForPurposes =
      commonPurposeKindValidators[selectedPurposeKind] || defaultValidators;
    return validatorsForPurposes.filter((v) => !!v);
  };
};
