import React from 'react';

import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

type StepsHeaderProps = {
  header: string;
  subHeader?: string;
};

const StepsHeader = ({ header, subHeader }: StepsHeaderProps) => {
  const t = useTranslations();
  return (
    <PageHeading
      heading={t(header, { roleSpecific: true })}
      subheading={t(subHeader, { roleSpecific: true })}
    />
  );
};

StepsHeader.defaultProps = {
  roleSpecific: false,
};

export default StepsHeader;
