import * as React from 'react';

import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { useOfferFields } from 'modules/helpers/useOfferFields';
import {
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_24_HOURS,
  BFS_SERVICE__PURPOSE_KIND_OTHER,
} from 'modules/Inquiry/Form/formFields';
import getPurchasedInvoicesPaidOutOnOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/purchasedInvoicesPaidOutOnOptions';
import { reduceToTranslatedMap } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/useValueTranslationMap';
import {
  getFinancingPurposeIndustryType,
  getIndicativeConditionCompleted,
  getIndicativeOffer,
  getPayoutTime,
  getPrefinancingPeriod,
} from 'store/inquiryDetails/selectors';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from 'theme/components/DescriptionList';
import { HeadingComponent } from 'theme/components/Heading';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getIndicativeOfferFormattedValueMap } from './utils';

type ProgressFactoringOfferProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl?: string;
  id?: string;
};

export const ProgressFactoringOffer = ({
  isFirst,
  isLast,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: ProgressFactoringOfferProps) => {
  const tFactoringOffer = useTranslations('pages.inquiryDetails.dashboard.actions.factoringOffer');
  const t = useTranslations();
  const { formatMessage } = useIntl();

  const indicativeOfferCompleted = useSelector(getIndicativeConditionCompleted);
  const indicativeOffer = useSelector(getIndicativeOffer) as Record<string, string>;
  const fields = useOfferFields();
  const purposeKind = useSelector(getFinancingPurposeIndustryType);

  let payoutKey = useSelector(getPayoutTime);

  if (purposeKind === BFS_SERVICE__PURPOSE_KIND_OTHER) {
    payoutKey = BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_AFTER_24_HOURS.replace('after_', '');
  }

  const preFinancingPeriod = useSelector(getPrefinancingPeriod);

  // get payout options with translation
  let payout = getPurchasedInvoicesPaidOutOnOptions(formatMessage, {}).reduce(
    reduceToTranslatedMap,
    {},
  )[payoutKey];

  // lower case first letter
  if (payout) {
    payout = payout[0].toLowerCase() + payout.slice(1);
  }

  // set vars for translation
  const description = tFactoringOffer('description', {
    payout,
    preFinancingPeriod: `${preFinancingPeriod} ${t('other.days')}`,
  });

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={indicativeOfferCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
        {tFactoringOffer('title')}
      </HeadingComponent>
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={6} mb={6}>
        {' '}
        <GridItem>
          <TextComponent>
            <MarkdownWrapper text={description} />
          </TextComponent>
        </GridItem>
      </Grid>
      <Grid templateColumns={['repeat(1, 1fr)', null, null, '1fr 1fr 1fr']} gap={6}>
        {fields.map((item) => (
          <GridItem key={item.name}>
            <Box
              border="1px"
              borderColor="border.lightGrey"
              p="8"
              bgColor="background.white"
              boxShadow="default"
            >
              <DescriptionList>
                <DescriptionListTerm display="flex" alignItems="center">
                  {t(item.title)}
                  {item.info && (
                    <PopoverComponent
                      ml="1px"
                      transform="translateY(-1px)"
                      text={t(item.info)}
                      trigger={
                        <IconButtonComponent
                          icon={<InfoIcon boxSize={6} display="block" />}
                          label={t('buttons.moreInfos')}
                        />
                      }
                    />
                  )}
                </DescriptionListTerm>
                <DescriptionListDescription>
                  {indicativeOfferCompleted
                    ? getIndicativeOfferFormattedValueMap(item, indicativeOffer[item.name])
                    : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.comingSoon')}
                </DescriptionListDescription>
              </DescriptionList>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </ProgressSection>
  );
};
