import React from 'react';

import moment from 'moment';
import { number } from 'prop-types';
import { Field } from 'react-final-form';

import FormSection from 'components/FormSection';
import SectionHeading from 'components/Text/SectionHeading';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import {
  PURPOSE_KIND,
  FINANCING_NEED,
  PURPOSE_KIND__BUILDING,
  FAVORED_DECISION_DATE,
} from 'modules/Inquiry/Form/formFields';
import FavoredDecisionDate from 'modules/Inquiry/Form/Steps/RequestDetails/DecisionDate/FavoredDecisionDate';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateDecisionDateSection = (form) => fieldIsValid(FAVORED_DECISION_DATE)({ form });

const fixedDate = moment().add(90, 'days').format('YYYY-MM-DD');
export const DecisionDateSection = ({ sectionNumber }) => {
  const t = useTranslations();

  return (
    <FormSection
      sectionNumber={sectionNumber}
      name={FormSections.favoredDecisionDate}
      title={t('pages.requestDetails.sections.decisionDate.title')}
    >
      <SectionHeading>
        {t('pages.requestDetails.sections.decisionDate.fields.favoredDecisionDate.caption', {
          roleSpecific: true,
        })}
      </SectionHeading>
      <FavoredDecisionDate />
    </FormSection>
  );
};

const FixedDecisionDate = () => (
  <Field
    name={FAVORED_DECISION_DATE}
    render={({ input }) => {
      input.onChange(fixedDate);
      return null;
    }}
  />
);

DecisionDateSection.propTypes = {
  sectionNumber: number.isRequired,
};

export default chooseFormValueSpecificComponent(
  [
    {
      component: FixedDecisionDate,
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__BUILDING,
      valueFromCurrentStep: false,
    },
  ],
  DecisionDateSection,
);
