import React, { useEffect, useReducer } from 'react';

import { useHistory } from 'react-router-dom';

import { dzbInquiryProcessConfig } from 'dzb/inquiry/config';
import { hausbankInquiryProcessConfig } from 'hausbank/inquiry/config';
import { mittweidaInquiryProcessConfig } from 'mittweida/config';
import { inquiryProcessConfig as mmvInquiryProcessConfig } from 'mmv/inquiry/config';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import { InquiryConfigContext } from './context';
import { useInitialState } from './hooks';
import { INQUIRY_PROGRESS_STORAGE_KEY } from '../../persist';
import { PageStateActionTypes, pageStateReducer } from '../pageStateReducer';
import { AnyInquiryProcessConfig, InquiryMode } from '../types';

type InquiryProcessConfigProps = {
  children: React.ReactNode;
};

export function InquiryProcessConfigProvider({ children }: InquiryProcessConfigProps) {
  const inquiryProcessConfig = useSelectedInquiryTypeSpecificValue<AnyInquiryProcessConfig>({
    [InquiryType.profiMittweida]: mittweidaInquiryProcessConfig,
    [InquiryType.mmv]: mmvInquiryProcessConfig,
    [InquiryType.dzb]: dzbInquiryProcessConfig,
    [InquiryType.hausbank]: hausbankInquiryProcessConfig,
    default: mittweidaInquiryProcessConfig,
  });
  const { validations, routeToPageNameMap, initialFormState } = inquiryProcessConfig;
  const initialStateWithSections = useInitialState(
    validations[initialFormState.inquiryMode],
    initialFormState,
  );
  const [state, dispatch] = useReducer(pageStateReducer, initialStateWithSections);

  const { location } = useHistory();

  useEffect(() => {
    const path = location.pathname;

    let mode: InquiryMode = 'create';

    // FIXME!!!!!!!!
    if ((path.includes('operation') || path.includes('operationen')) && path.includes('edit')) {
      mode = 'editOperation';
    } else if ((path.includes('customer') || path.includes('kunde')) && path.includes('edit')) {
      mode = 'editCustomer';
    }

    dispatch({
      type: PageStateActionTypes.SET_INQUIRY_MODE,
      payload: mode,
    });

    const currentStep = Object.keys(routeToPageNameMap).find((key) => {
      const route = routeToPageNameMap[key];
      if (mode === 'editCustomer' || mode === 'editOperation') {
        const compare = path.split('/').pop();
        if (compare) {
          return (
            route.editCustomer.indexOf(compare) > -1 || route.editOperation.indexOf(compare) > -1
          );
        }
      }

      return route.create === path;
    });

    if (currentStep) {
      dispatch({
        type: PageStateActionTypes.NEXT_STEP,
        payload: currentStep,
      });
    }
  }, [location, dispatch, routeToPageNameMap]);

  useEffect(() => {
    window.sessionStorage.setItem(INQUIRY_PROGRESS_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  return (
    <InquiryConfigContext.Provider value={{ state, dispatch, formStateData: inquiryProcessConfig }}>
      {children}
    </InquiryConfigContext.Provider>
  );
}
