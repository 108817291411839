import { useMemo } from 'react';

import { useFormState } from 'react-final-form';

import { useCheckCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';

export const useSumOfFields = (fieldsToSum: string[]) => {
  const checkCanShowField = useCheckCanShowField();
  const { values } = useFormState();
  const fieldValues = fieldsToSum
    .filter((name) => checkCanShowField(name))
    .map((name) => +values[name] || 0);

  return useMemo(() => fieldValues.reduce((sum, field) => sum + field, 0), [fieldValues]);
};
