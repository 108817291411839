import React from 'react';

import DataListItem from 'pages/inquiryFlow/Rentenbank/SubsidyForestryCompanies/SubjectList/DataListItem';
import {
  IDataListItem,
  DataListOption,
} from 'pages/inquiryFlow/Rentenbank/SubsidyForestryCompanies/SubjectList/useFetchSubjectList';

export const dataListItemToOptions = (listItems: IDataListItem[]): DataListOption[] =>
  listItems.map(({ vendorname = '', type = '', ...rest }: IDataListItem) => ({
    label: `${vendorname} ${type}`,
    value: { vendorname, type, ...rest },
    component: <DataListItem header={rest.vendor} caption={`${vendorname} ${type}`} />,
  }));
