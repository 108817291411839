import React from 'react';

import { useSelector } from 'react-redux';

import {
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__SOURCE,
  PRE_FINANCING_PERIOD,
  PROJECT_FINANCING_CURRENT_USAGE_TYPE,
  PROJECT_FINANCING_FUTURE_USAGE_TYPE,
  PROJECT_FINANCING_MARKET_TRIGGER_CONDITION,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  PROJECT_FINANCING__OBJECT_USAGE,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import ProjectFinancingUsageTypesDetails from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/ProjectFinancingUsageTypesDetails';
import InformationRows, {
  InformationField,
} from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getInquiryProductKind } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const FinancingPurposeSection = () => {
  const t = useTranslations('');
  const productKind = useSelector(getInquiryProductKind);

  const excludedFieldsOnInquiryType = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: [
      BFS_SERVICE__FACTORING_OFFER,
      BFS_SERVICE__SOURCE,
      productKind === PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING && PRE_FINANCING_PERIOD,
    ],
    default: [],
  });

  const excludedFieldsForAll = [
    PROJECT_FINANCING__OBJECT_USAGE,
    PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
    PROJECT_FINANCING_MARKET_TRIGGER_CONDITION,
    PROJECT_FINANCING_FUTURE_USAGE_TYPE,
    PROJECT_FINANCING_CURRENT_USAGE_TYPE,
  ];

  const excludedFields = [...excludedFieldsForAll, ...excludedFieldsOnInquiryType];

  const fields: InformationField[] = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.FINANCING,
    excludedFields,
  });

  if (!fields.length) {
    return null;
  }

  const sectionTitle = t('pages.inquiryDetails.detailed.financingDetails', {
    br: <br />,
  });

  return (
    <Section title={sectionTitle}>
      <InformationRows fields={fields} />
      <ProjectFinancingUsageTypesDetails />
    </Section>
  );
};

export default FinancingPurposeSection;
