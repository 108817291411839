import normalize from 'json-api-normalizer';
import _get from 'lodash/get';

import { ProcessLog, ProcessLogActivity } from 'models/ProcessLog.model';

const mapApiActivity = (apiActivity: string): ProcessLogActivity => {
  const TRANSLATIONS_MAP: { [key: string]: ProcessLogActivity } = {
    open: ProcessLogActivity.open,
    welcome_call_change: ProcessLogActivity.welcomeCallChange,
    forward_to_inquiry_manager: ProcessLogActivity.forwardToInquiryManager,
    forward_to_compeon: ProcessLogActivity.forwardToCompeon,
    mark_as_waiting_for_accept_to_forward_to_compeon: ProcessLogActivity.forwardToCustomer,
    forward_to_bank_advisor: ProcessLogActivity.forwardToBankAdvisor,
    create_bank_offer: ProcessLogActivity.createBankOffer,
    accept_offer: ProcessLogActivity.acceptOffer,
    archive: ProcessLogActivity.archive,
    user_data_cleared: ProcessLogActivity.userArchived,
    enabled_digital_processing: ProcessLogActivity.enabledDigitalProcessing,
    kyc_started: ProcessLogActivity.kycStarted,
    entered_indicative_conditions: ProcessLogActivity.enteredIndicativeConditions,
    enable_manual_crefo_report: ProcessLogActivity.enabledManualCrefoReport,
    uploaded_all_documents: ProcessLogActivity.uploadedAllDocuments,
    completed_idnow_process: ProcessLogActivity.completedIdnowProcess,
    forward_to_real_estate_expert: ProcessLogActivity.forwardToRealEstateExpert,
    digitization_requested: ProcessLogActivity.digitizationRequested,
    marked_for_kpi_calculation: ProcessLogActivity.markedForKPICalculation,
    signature_process_done: ProcessLogActivity.signatureProcessDone,
    kyc_skipped: ProcessLogActivity.kycSkipped,
  };
  const activity = TRANSLATIONS_MAP[apiActivity];
  if (!activity) {
    throw new Error(`Unknown api activity for process log: ${apiActivity}`);
  }
  return TRANSLATIONS_MAP[apiActivity];
};

const getUser = (rawApiResponse: any, relationships: any) => {
  const { users } = normalize(rawApiResponse);
  const userId = _get(relationships, 'user.data.id');
  if (!userId) {
    return undefined;
  }
  return users[relationships.user.data.id].attributes;
};

const mapFromApiInterface = (rawData: any): ProcessLog[] => {
  return rawData.data.map(
    ({ id, relationships, attributes }: any): ProcessLog => ({
      id,
      type: attributes.type,
      user: getUser(rawData, relationships),
      activity: mapApiActivity(attributes.activity),
      createdAt: attributes.created_at,
    }),
  );
};

export default mapFromApiInterface;
