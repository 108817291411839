import { otherProjectInformationSectionValidationMap } from 'mittweida/inquiry/steps/financingNeed/sections/otherProjectInformationSection/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateOtherProjectInformationSection: ValidateSectionFunction<
  InquiryType.profiMittweida
> = (form, values, store, conditions) => {
  return validateFieldValidationMap<InquiryType.profiMittweida>({
    validationMap: otherProjectInformationSectionValidationMap,
    form,
    values,
    conditions,
  });
};
