import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  CURRENT_USAGE_REE_PARKING_LOT_VALUE,
  CURRENT_USAGE_REE_GARAGE_VALUE,
  CURRENT_USAGE_REE_GARAGE_RENT,
  CURRENT_USAGE_REE_PARKING_LOT_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_VALUE,
  FUTURE_USAGE_REE_GARAGE_VALUE,
  FUTURE_USAGE_REE_GARAGE_RENT,
  FUTURE_USAGE_REE_PARKING_LOT_RENT,
  CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
} from 'modules/Inquiry/Form/formFields';

const mapRealEstateValuesToEdit = () => {
  const commonAttributes: Record<string, string> = {
    rentPrice: 'pages.editRealEstateExpertOpinion.sections.rentPrices.title',
    salesPrice: 'pages.editRealEstateExpertOpinion.sections.salesPrices.title',
  };

  const usageSectionsMap = {
    current: [
      {
        title: commonAttributes['rentPrice'],
        fields: [
          CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
          CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
          CURRENT_USAGE_REE_PARKING_LOT_RENT,
          CURRENT_USAGE_REE_GARAGE_RENT,
        ],
      },
      {
        title: commonAttributes['salesPrice'],
        fields: [
          CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING,
          CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT,
          CURRENT_USAGE_REE_PARKING_LOT_VALUE,
          CURRENT_USAGE_REE_GARAGE_VALUE,
        ],
      },
    ],
    future: [
      {
        title: commonAttributes['rentPrice'],
        fields: [
          FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM,
          FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM,
          FUTURE_USAGE_REE_PARKING_LOT_RENT,
          FUTURE_USAGE_REE_GARAGE_RENT,
        ],
      },
      {
        title: commonAttributes['salesPrice'],
        fields: [
          FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING,
          FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT,
          FUTURE_USAGE_REE_PARKING_LOT_VALUE,
          FUTURE_USAGE_REE_GARAGE_VALUE,
        ],
      },
    ],
  };

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editRealEstateExpertOpinion.futureUsage.heading',
      subHeading: 'pages.editRealEstateExpertOpinion.futureUsage.subHeading',
      sections: usageSectionsMap['future'],
    },
    {
      heading: 'pages.editRealEstateExpertOpinion.currentUsage.heading',
      subHeading: 'pages.editRealEstateExpertOpinion.currentUsage.subHeading',
      sections: usageSectionsMap['current'],
    },
  ];

  return editValuesForm;
};

export default mapRealEstateValuesToEdit;
