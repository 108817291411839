import { LabelsMap, translations } from '../../../../../../new/form/common/types';
import { mittweidaFields } from '../../../../fields';

const { selectedCompanyName } = mittweidaFields.companyDetailPage.companyDetailsSection;

const { userProfileCompanyName } =
  translations.pages.userProfile.sections.completingCompanyDetails.fields;

export const mittweidaCompanyDetailsLabelsMap: LabelsMap = {
  [selectedCompanyName]: userProfileCompanyName.caption,
};
