import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { hausbankFields, HausbankSections } from 'hausbank/inquiry/fields';
import { HausbankPurposeKind } from 'hausbank/inquiry/steps/financingNeed/sections/purposeSection/purposeKinds/PurposeKind';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const { projectDescription } = hausbankFields.financingNeedPage.purposeSection;

export const PurposeSection = () => {
  const t = useTranslations();
  const subheading =
    translations.inquiryType.hausbank.pages.financingNeed.sections.purpose.subheadings
      .additionalInformation;
  const { title } = translations.inquiryType.hausbank.pages.financingNeed.sections.purpose;

  return (
    <Condition<HausbankSections>
      validation={'financingNeedSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={2}
          placeholder={t(translations.inquiryType.hausbank.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={2}>
        <TextComponent color={'text.tertiary'} mb={12}>
          {t(subheading)}
        </TextComponent>
        <FormRow>
          <HausbankPurposeKind />
        </FormRow>
        <FormRow>
          <TextAreaWithField name={projectDescription} optional />
        </FormRow>
      </FormSection>
    </Condition>
  );
};
