import { REAL_ESTATE_KIND__LAND_ONLY } from 'modules/Inquiry/Form/formFields';

import common from './common';

export default [
  {
    value: REAL_ESTATE_KIND__LAND_ONLY,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.landOnly',
  },
  ...common,
];
