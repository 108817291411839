import { useCallback, useEffect } from 'react';

import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { saveLeadAction } from 'pages/inquiryFlow/RequestDetails/service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import {
  setCanSendLead as setCanSendLeadAction,
  setLeadId as setLeadIdAction,
} from 'store/inquiryProcess/actions';
import { canSendLeadsSelector } from 'store/inquiryProcess/selectors';

import { useInquiryValues } from '../../../components/App/InquiryValuesProvider';

export const useSendLead = () => {
  const dispatch = useDispatch();
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const enableCollectingLeads = useConfig(CONFIG.IS_COLLECTING_LEADS_ENABLED);
  const canSendLead = useSelector(canSendLeadsSelector);
  const formData = useInquiryValues(partnerName);

  const sendLeadToApi = useCallback(async () => {
    const { error, payload } = await dispatch(saveLeadAction(formData));

    if (!error) {
      const id = get(payload, ['data', 'data', 'id']);
      dispatch(setLeadIdAction(id));
      dispatch(setCanSendLeadAction(false));
    }
  }, [dispatch, formData]);

  useEffect(() => {
    if (enableCollectingLeads && canSendLead) {
      sendLeadToApi();
    }
  }, [canSendLead, enableCollectingLeads, sendLeadToApi]);
};
