import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import InquiryFormDraftInitializer from 'modules/Inquiry/DraftMode/InquiryFormInitializer/InquiryFormDraftInitializer';
import Partners from 'modules/StaticPage';
import AccountUnlockFail from 'pages/customerPortal/AccountUnlock/AccountUnlockFail';
import AccountUnlockSuccess from 'pages/customerPortal/AccountUnlock/AccountUnlockSuccess';
import ErrorPage from 'pages/ErrorPage';
import InquiryCreate from 'pages/inquiryFlow/InquiryCreate';
import InquiryNew from 'pages/InquiryNew';
import RegistrationProcess from 'pages/RegistrationProcess/RegistrationForm/RegistrationProcess';
import RegistrationLinkExpired from 'pages/RegistrationProcess/RegistrationLinkExpired/RegistrationLinkExpired';
import RegistrationSuccess from 'pages/RegistrationProcess/RegistrationSuccess/RegistrationSuccess';
import { getInquiryFlowStartUrl } from 'store/config/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import CustomerRoutes from './CustomerRoutes';
import OperationRoutes from './OperationRoutes';

const createInquiryPaths = [
  paths.financingNeed,
  paths.companyDetails,
  paths.requestDetails,
  paths.summaryPage,
  paths.userProfile,
  paths.finalPage,
  paths.coronaStep,
  paths.subsidyForForestry,
  paths.agriculturalSubsidy,
  paths.contactPerson,
  paths.contactPersonDetails,
  paths.peopleDetails,
  paths.contractDetails,
];

const Routes = () => {
  const inquiryStartPath = useSelector(getInquiryFlowStartUrl);
  const t = useTranslations();
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={({ location }) => <Redirect to={{ ...location, pathname: inquiryStartPath }} />}
      />
      <Route
        exact
        path={paths.termsAndConditions}
        render={(props) => (
          <Partners
            title={t('pages.companyDetails.sections.termsAndConditions.title')}
            name="termsAndConditions"
            {...props}
          />
        )}
      />
      <Route
        exact
        path={paths.privacyPolicy}
        render={(props) => (
          <Partners title={t('footerLinks.privacyPolicy')} name="privacyPolicy" {...props} />
        )}
      />
      <Route
        exact
        path={paths.impressum}
        render={(props) => (
          <Partners title={t('footerLinks.impressum')} name="impressum" {...props} />
        )}
      />
      <Route exact path={paths.error404} render={() => <ErrorPage status={404} />} />
      <Route exact path={paths.error500} render={() => <ErrorPage status={500} />} />
      <Route exact path={paths.error403} render={() => <ErrorPage status={403} />} />
      <Route exact path={paths.errorDefault} render={() => <ErrorPage status="default" />} />
      <Route path={`(${createInquiryPaths.join('|')})`} component={InquiryCreate} />
      <Route path={paths.inquiry.new.root} component={InquiryNew} />
      <Route path={paths.customer.root} component={CustomerRoutes} />
      <Route path={paths.operation.root} component={OperationRoutes} />
      <Route path={paths.inquiry.draft.edit} component={InquiryFormDraftInitializer} />
      <Route exact path={paths.registration} component={RegistrationProcess} />
      <Route path={paths.registrationSuccess} component={RegistrationSuccess} />
      <Route path={paths.registrationExpiredLink} component={RegistrationLinkExpired} />
      <Route exact path={paths.accountUnblockSuccess} component={AccountUnlockSuccess} />
      <Route exact path={paths.accountUnblockFail} component={AccountUnlockFail} />
      <Route path="*" render={() => <Redirect to={paths.error404} />} />
    </Switch>
  );
};

export default Routes;
