import { mittweidaFields } from 'mittweida/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const {
  name,
  city,
  country,
  state,
  street,
  address,
  zipCode,
  legalForm,
  industry,
  turnoverClass,
  foundingYear,
  foundingMonth,
  alreadyCustomer,
  tradeName,
  alreadyContactWithBank,
  nameOfContactPerson,
} = mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;

const {
  userProfileCompanyName,
  userProfileCompanyStreet,
  userProfileCompanyZipCode,
  userProfileCompanyCity,
  userProfileLegalForm,
  userProfileIndustry,
  userProfileTurnoverClass,
  userProfileFoundingYear,
  userProfileFoundingMonth,
  userProfileAlreadyCustomer,
  userProfileCompanyCountry,
  userProfileCompanyState,
  userProfileCompanyTradeName,
  userProfileContactPerson,
  userProfileAlreadyContactWithBank,
} = translations.pages.userProfile.sections.completingCompanyDetails.fields;

export const mittweidaCompletingCompanyDetailsLabelsMap: LabelsMap = {
  [name]: userProfileCompanyName.caption,
  [street]: userProfileCompanyStreet.caption,
  [zipCode]: userProfileCompanyZipCode.caption,
  [legalForm]: userProfileLegalForm.caption,
  [industry]: userProfileIndustry.caption,
  [turnoverClass]: userProfileTurnoverClass.caption,
  [foundingYear]: userProfileFoundingYear.caption,
  [foundingMonth]: userProfileFoundingMonth.caption,
  [alreadyCustomer]: userProfileAlreadyCustomer.caption,
  [city]: userProfileCompanyCity.caption,
  [address]: userProfileCompanyStreet.caption,
  [country]: userProfileCompanyCountry.caption,
  [state]: userProfileCompanyState.caption,
  [tradeName]: userProfileCompanyTradeName.caption,
  [alreadyContactWithBank]: userProfileAlreadyContactWithBank.captionForSummaryPage, //we have a variable inside the caption which we cannot via labels map --> but we cannot set the variable on summaryPage
  [nameOfContactPerson]: userProfileContactPerson.caption,
};
