import React from 'react';

import _values from 'lodash/values';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useInquiryStepData } from 'modules/Inquiry/inquiryNavigation/useInquiryStepData';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

const RootEditRedirect: React.FC = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { stepSegments } = useInquiryStepData();
  const firstStep = _values(stepSegments)[0].paths.customerEdit;

  if (!inquiryId) {
    return null;
  }

  return <Redirect to={firstStep.replace(':id', inquiryId)} />;
};

export default RootEditRedirect;
