import { useCallback, useEffect, useState } from 'react';

import {
  fetchFastlaneQuestionnaireItems,
  mapFromApi,
} from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useFetchOfferFormTemplate = (questionnaireId) => {
  const { isPending, makeCall } = useDispatchApiCall();
  const [formTemplate, setFormTemplate] = useState();
  const t = useTranslations('pages.inquiryDetails.offers');

  const [error, setError] = useState();
  const fetchFormTemplate = useCallback(async () => {
    if (questionnaireId) {
      const response = await makeCall(fetchFastlaneQuestionnaireItems(questionnaireId));
      if (response.error) {
        setError(response.error);
      } else {
        setFormTemplate(mapFromApi(response.payload.data));
      }
    } else {
      setError(t('noQuestionnaireId'));
    }
  }, [makeCall, questionnaireId, t]);

  useEffect(() => {
    fetchFormTemplate();
  }, [fetchFormTemplate]);

  return { formTemplate, isLoading: isPending || !formTemplate, error };
};
