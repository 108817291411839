import { USER_PROFILE_TURNOVER_CLASS } from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useGetMappedPartnerOptions } from 'utils/hooks/useGetMappedOptions';

export function useGetTurnoverClassOptions() {
  const turnoverClassOptions = useGetMappedPartnerOptions('revenue_class');
  const defaultOptions = useInquirySelectOptions(USER_PROFILE_TURNOVER_CLASS);

  if (turnoverClassOptions.length !== 0) {
    return turnoverClassOptions;
  }

  return defaultOptions;
}
