import React from 'react';

import { Route, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import CompanyDetails from 'pages/inquiryFlow/CompanyDetails';
import { SpecializedFinalPage } from 'pages/inquiryFlow/FinalPage/SpecializedFinalPage';
import FinancingNeed from 'pages/inquiryFlow/FinancingNeed/FinancingNeedStep';
import RequestDetails from 'pages/inquiryFlow/RequestDetails/RequestDetailsStep';
import SummaryPage from 'pages/inquiryFlow/SummaryPage/SummaryPageStep';
import UserProfile from 'pages/inquiryFlow/UserProfile';

// Form composition for Profi formtype
export const ProfiForm = () => {
  return (
    <Switch>
      <Route exact path={paths.financingNeed} component={FinancingNeed} />
      <Route exact path={paths.companyDetails} component={CompanyDetails} />
      <Route exact path={paths.requestDetails} component={RequestDetails} />
      <Route exact path={paths.userProfile} component={UserProfile} />
      <Route exact path={paths.summaryPage} component={SummaryPage} />
      <Route exact path={paths.finalPage} component={SpecializedFinalPage} />
    </Switch>
  );
};
