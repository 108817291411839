import React from 'react';

import { useSelector } from 'react-redux';

import { FILE_AVAILABLE_STATUSES } from 'components/DownloadBlock/constants';
import MultipleFilesBlock, {
  UploadButtonProps,
} from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import { translations } from 'new/form/common/types';
import { getAdditionalCustomerFilesWithoutFileRequest } from 'store/documentExchange/documentExchange.selectors';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import FileSectionTitle from '../FileSectionTitle';

const { otherFilesForBank } = translations.pages.inquiryDetails.documentExchange;
const { title, description } = translations.components.upload.otherDocuments;

export const MultipleFilesToBank = () => {
  const files = useSelector(getAdditionalCustomerFilesWithoutFileRequest);
  const t = useTranslations();

  const { sendNow } = translations.components.upload;
  const filteredFiles = files.filter((file) => FILE_AVAILABLE_STATUSES.includes(file.status));

  const uploadButtonProps: UploadButtonProps = {
    text: t(sendNow),
    leftIcon: <UploadIcon boxSize={6} display={'block'} />,
    variant: 'primary',
    textColor: 'text.white',
  };

  return (
    <section data-testid="files-to-bank">
      <FileSectionTitle>{t(otherFilesForBank)}</FileSectionTitle>
      <MultipleFilesBlock
        title={t(title)}
        description={t(description)}
        files={filteredFiles}
        uploadButtonProps={uploadButtonProps}
        isFileUploadingEnabled
      />
    </section>
  );
};
