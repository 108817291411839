import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  DOWNLOAD_COLOR_MAP,
  DOWNLOAD_ICON_MAP,
  DOWNLOAD_IS_DASHED_MAP,
  DOWNLOAD_STATUS,
  DOWNLOAD_STATUSES,
} from 'components/DownloadBlock/constants';
import { useDownloadFile } from 'components/DownloadBlock/useDownloadFile';
import FileBlock from 'components/FileBlock';
import { FILE_SCAN_STATUS, FILE_SCAN_STATUSES } from 'components/UploadBlock/constants';
import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';

const canDownload = (status) => status !== DOWNLOAD_STATUS.NOT_UPLOADED;
const isScanningFile = (scanningStatus) => scanningStatus === FILE_SCAN_STATUS.PENDING;

const DownloadBlock = ({ file, onDownload }) => {
  const { formatMessage } = useIntl();
  const { id, fileName, status, scanningStatus } = file || {};
  const { downloadFile } = useDownloadFile({ fileId: id, fileName });
  const [isLoading, setIsLoading] = useState(false);

  if (!file) {
    return null;
  }

  const handleDownload = async () => {
    setIsLoading(true);
    await downloadFile();
    setIsLoading(false);
    onDownload(id);
  };

  const isDisabled = isScanningFile(scanningStatus);
  const buttonMsg = isDisabled
    ? formatMessage({ id: 'components.download.scanning' })
    : formatMessage({ id: 'components.download.cta' });
  const icon = isDisabled ? null : <DownloadIcon boxSize={6} display="block" />;

  return (
    <FileBlock
      color={DOWNLOAD_COLOR_MAP[status]}
      title={fileName}
      dashed={DOWNLOAD_IS_DASHED_MAP[status]}
      icon={DOWNLOAD_ICON_MAP[status]}
      spinningBorder={isLoading}
    >
      {canDownload && (
        <ButtonComponent
          onClick={handleDownload}
          leftIcon={icon}
          disabled={isDisabled}
          data-testid="Download-Offer-Document"
        >
          {buttonMsg}
        </ButtonComponent>
      )}
    </FileBlock>
  );
};

DownloadBlock.defaultProps = {
  onDownload: () => {},
};

DownloadBlock.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    status: PropTypes.oneOf(DOWNLOAD_STATUSES),
    scanningStatus: PropTypes.oneOf(FILE_SCAN_STATUSES),
  }).isRequired,
  onDownload: PropTypes.func,
};

export default DownloadBlock;
