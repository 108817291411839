import { HausbankFieldTypes } from '../fields';

export const hausbankInitialFormValues: HausbankFieldTypes = {
  objectInformationPage: {
    objectInformationSection: {
      wegName: undefined,
      street: undefined,
      zipCode: undefined,
      city: undefined,
      country: 'Deutschland',
      livingSpace: undefined,
      commercialUsage: undefined,
      propertySize: undefined,
      constructionYear: undefined,
      lastModernization: undefined,
    },
    projectInformationSection: {
      residentialUnitsCount: undefined,
      commercialUnitsCount: undefined,
      hasOwnerWithMoreThanThirdOfShares: undefined,
      isContractor: undefined,
      majorityOwnerShares: undefined,
      wegIsSubCommunity: undefined,
      propertyIsLeasehold: undefined,
      hasMajorityDecision: undefined,
    },
  },
  companyDataPage: {
    wegSearchSection: {
      crefoId: undefined,
      selectedCompanyName: undefined,
      loggedInUserCompanyName: undefined,
    },
    wegDataCompletionSection: {
      name: undefined,
      street: undefined,
      zipCode: undefined,
      city: undefined,
      legalForm: undefined,
      managedUnits: undefined,
      foundingYear: undefined,
      isExistingCustomer: undefined,
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: undefined,
      privacyPolicyAccepted: undefined,
    },
  },
  financingNeedPage: {
    financingNeedSection: {
      investmentAmount: undefined,
      maintenanceReserve: undefined,
      wegContribution: undefined,
      constructionSubsidies: undefined,
      financingAmount: undefined,
      loanTermInYears: undefined,
      ownersMeetingDate: undefined,
    },
    purposeSection: {
      purposeKind: undefined,
      projectDescription: undefined,
    },
  },
  personalDataPage: {
    contactPersonSection: {
      salutation: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    passwordSection: {
      password: undefined,
      passwordConfirmation: undefined,
    },
    legalRepresentativesSection: {
      legalRepresentatives: [
        {
          id: undefined,
          salutation: undefined,
          firstName: undefined,
          lastName: undefined,
          birthDate: undefined,
          email: undefined,
          phoneNumber: undefined,
          soleSignatureAuthorized: undefined,
        },
      ],
    },
  },
};
