import React from 'react';

import SingleSelectableCheckboxField from 'components/SingleSelectableCheckboxField';
import {
  GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
  GUARANTY_LITIGATION_BOND,
  GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
  GUARANTY_PERFORMANCE_OF_CONTRACT,
  GUARANTY_RENTAL_DEPOSIT,
  GUARANTY_WARRANTY,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const GuarantyTypeField = () => {
  const tCheckboxFields = useTranslations('pages.financingNeed.sections.guaranty.fields');

  const guarantyTypeOptions = [
    {
      name: GUARANTY_RENTAL_DEPOSIT,
      caption: tCheckboxFields('rentalDeposit.caption'),
      tooltip: tCheckboxFields('rentalDeposit.tooltip'),
    },
    {
      name: GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
      caption: tCheckboxFields('partialRetirementAccounts.caption'),
      tooltip: tCheckboxFields('partialRetirementAccounts.tooltip'),
    },
    {
      name: GUARANTY_PERFORMANCE_OF_CONTRACT,
      caption: tCheckboxFields('performanceOfContract.caption'),
      tooltip: tCheckboxFields('performanceOfContract.tooltip'),
    },
    {
      name: GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
      caption: tCheckboxFields('depositAndAdvancePayments.caption'),
      tooltip: tCheckboxFields('depositAndAdvancePayments.tooltip'),
    },
    {
      name: GUARANTY_WARRANTY,
      caption: tCheckboxFields('warranty.caption'),
      tooltip: tCheckboxFields('warranty.tooltip'),
    },
    {
      name: GUARANTY_LITIGATION_BOND,
      caption: tCheckboxFields('litigationBond.caption'),
      tooltip: tCheckboxFields('litigationBond.tooltip'),
    },
  ];

  return <SingleSelectableCheckboxField options={guarantyTypeOptions} />;
};

export default GuarantyTypeField;
