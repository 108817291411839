import React from 'react';

import { string, number } from 'prop-types';

import FormSection from 'components/FormSection';
import { useTranslations } from 'utils/hooks/useTranslations';

const DisabledFormSection = ({ placeholder, sectionNumber, title }) => {
  const t = useTranslations();

  return (
    <FormSection disabled title={title} sectionNumber={sectionNumber}>
      {placeholder || t('placeholders.disabledSection')}
    </FormSection>
  );
};

DisabledFormSection.defaultProps = {
  placeholder: '',
  sectionNumber: 1,
  title: '...',
};

DisabledFormSection.propTypes = {
  placeholder: string,
  sectionNumber: number,
  title: string,
};

export default DisabledFormSection;
