/**
 * @deprecated please use InquiryType
 * @see InquiryType
 */
export enum PARTNERS {
  BUILDING_SOCIETY = 'wuestenrot',
  RENTENBANK = 'rentenbank',
  AGRICULTURAL = 'agricultural',
  LEASEPLAN = 'leaseplan',
  KFINANZ = 'kfinanz',
  CREFOFACTORING = 'crefofactoring',
  RLL = 'rll',
  BFSS = 'bfss',
  MMV = 'mmv',
}

export type PartnersName = `${PARTNERS}`;
