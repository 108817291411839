import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import MezzanineDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Mezzanine/MezzanineDescription';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import MezzanineIcon from 'theme/components/Icon/ProductKind/MezzanineIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const Mezzanine = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.mezzanine}
      title={t('pages.requestDetails.sections.mezzanine.title')}
    >
      <FormRow separator>
        <FinancingAmountSummary icon={<MezzanineIcon />} />
      </FormRow>
      <FormRow>
        <MezzanineDescription />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default Mezzanine;
