import React, { useContext } from 'react';

import { useFieldArray } from 'react-final-form-arrays';

import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { BENEFICIARY_GENDER, BENEFICIARY_OWNER } from 'modules/Inquiry/Form/formFields';

import { FieldValue } from '../BeneficiaryOwners';
import { BeneficiaryOwnersContext } from '../BeneficiaryOwners.context';

const BeneficiaryGender: React.FC = () => {
  const { fieldName, userIndex } = useContext(BeneficiaryOwnersContext);
  const { fields } = useFieldArray<FieldValue>(BENEFICIARY_OWNER);
  const shouldBeDisabled = fields.value[userIndex]?.isAssociatedPerson;

  return (
    <GenderWithField name={`${fieldName}.${BENEFICIARY_GENDER}`} disabled={shouldBeDisabled} />
  );
};

export default BeneficiaryGender;
