import React from 'react';

import { Asset } from 'components/Asset';
import FieldGroup from 'components/PortalFormFields/FieldGroup';
import { SmallTileListWithField } from 'components/SmallTileList/SmallTileList';
import { useFormConfigRepo } from 'config/formConfig/hooks';
import { VEHICLE_KIND } from 'modules/Inquiry/Form/formFields';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useTranslations } from 'utils/hooks/useTranslations';

const VehicleKind = () => {
  const t = useTranslations();
  const formConfg = useFormConfigRepo();
  const vehicle = formConfg.getAssetByName('vehicle');

  const options = vehicle.map(({ id, titleId }) => ({
    key: id,
    title: t(titleId),
    icon: <Asset type="vehicle" value={id} />,
  }));
  const translateLabelForField = useInquiryLabelTranslator();

  return (
    <FieldGroup label={translateLabelForField(VEHICLE_KIND)}>
      <SmallTileListWithField name={VEHICLE_KIND} options={options} />
    </FieldGroup>
  );
};

export default VehicleKind;
