import { useCallback, useEffect } from 'react';

import { PushType, useTracking } from './useTracking';

const pushUpdate: PushType = (id: string, pathname: string) => {
  if (window.et_eC_Wrapper) {
    window.et_eC_Wrapper({ et_et: id, et_pagename: pathname });
  }
};

interface Props {
  etrekkId: string;
}

const ETracker = (props: Props) => {
  const etrekkId: string = props.etrekkId;
  useTracking({ trackerId: etrekkId, update: pushUpdate });

  const createEtrekkTag = useCallback((etrekkId: string) => {
    const script = document.createElement('script');
    script.id = '_etLoader';
    script.type = 'text/javascript';
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('data-block-cookies', 'true');
    script.setAttribute('data-respect-dnt', 'true');
    script.setAttribute('data-secure-code', etrekkId);
    script.src = 'https://code.etracker.com/code/e.js';
    script.async = true;

    if (document.head.prepend) {
      document.head.prepend(script);
    } else {
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    createEtrekkTag(etrekkId);
  }, [createEtrekkTag, etrekkId]);

  return null;
};

export default ETracker;
