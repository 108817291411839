import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

type EtrackWrapperProps = {
  et_et: string;
  et_pagename: string;
};

declare global {
  interface Window {
    gtag?: (key: string, trackingId: string, config: { page_path: string }) => void;
    et_eC_Wrapper?: (props: EtrackWrapperProps) => void;
    // Matomo
    _mtm?: any[];
    _paq?: any[];
  }
}

export type PushType = (id: string, pathname: string) => void;

interface TrackingProps {
  trackerId: string;
  update: PushType;
}

export const useTracking = ({ trackerId, update }: TrackingProps) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      update(trackerId, location.pathname);
    });

    return unlisten;
  }, [trackerId, update, listen]);
};
