import React from 'react';

import { node } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  StyledWrapper,
  StyledContentWrapper,
  StyledAmount,
  StyledIcon,
} from 'components/FinancingAmountSummary/styles';
import { InputCaption } from 'components/Text';
import { FINANCING_AMOUNT } from 'modules/Inquiry/Form/formFields';
import { FINANCING_NEED } from 'utils/sessionStorage/keys';
import { loadFormStepStateFromStorage } from 'utils/sessionStorage/loadFormState';
import { formatPrice } from 'utils/valueFormats';

// Sometimes we hide financing amount field from UI but it is filled by another field
// therefore it is save in storage but not in redux
const FinancingAmountSummary = ({ icon }) => {
  const financingAmount = loadFormStepStateFromStorage(FINANCING_NEED)[FINANCING_AMOUNT];
  return (
    <StyledWrapper>
      <InputCaption>
        <FormattedMessage id="pages.requestDetails.financingAmountSummary" />
      </InputCaption>
      <StyledContentWrapper>
        <StyledIcon>{icon}</StyledIcon>
        <StyledAmount>{formatPrice(financingAmount)}</StyledAmount>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

FinancingAmountSummary.propTypes = {
  icon: node.isRequired,
};

export default FinancingAmountSummary;
