import { mmvFields } from 'mmv/inquiry/fields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

export const mmvBankDetailsSectionValidationMap = {
  [mmvFields.contractDetails.bankDetailsSection.iban]: fieldValidators
    .bank()
    .isValidIban()
    .isGermanIban()
    .required(),
};
