import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
  PURPOSE_KIND,
} from 'modules/Inquiry/Form/formFields';

import {
  ALREADY_ASSIGNED,
  ALREADY_FACTORING,
  BUSINESS_SPECIFICS,
  DOWN_PAYMENT,
  INVOICES_PER_YEAR,
  MASTER_CRAFTSMAN,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  NUMBER_OF_LIFTS,
  PAYMENT_TERM,
  PAYMENT_TERMS,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  PERFORM_TUV_EXAMINATIONS,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  SHARE_OF_INSURANCE_CLAIMS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  TRADE_CREDIT_INSURANCE,
  VOLUME_OF_SALES,
} from './formFields';

export const onlineFactoringLabelsMap: Record<string, string> = {
  [PURPOSE_KIND]: 'pages.financingNeed.sections.purpose.fields.purposeKind.caption',
  [VOLUME_OF_SALES]:
    'inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose.fields.volumeOfSales.caption',
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS]:
    'inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose.fields.shareOfSalesBusinessCustomers.caption',
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.shareOfSales.caption',
  [SHARE_OF_SALES_MAIN_CUSTOMERS]:
    'inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose.fields.shareOfSalesMainCustomers.caption',
  [SHARE_OF_SALES_FOREIGN_CUSTOMERS]:
    'inquiryType.onlinefactoring.steps.purpose.sections.factoringPurpose.fields.shareOfSalesForeignCustomers.caption',
  [NUMBER_OF_CUSTOMERS]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.numberOfCustomers.caption',
  [NUMBER_OF_INVOICES]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.numberOfInvoices.caption',
  [PAYMENT_TERM]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.paymentTerm.caption',
  [ALREADY_FACTORING]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.alreadyFactoring.caption',
  [RECEIVABLES_ASSIGNED_ELSEWHERE]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.receivablesAssignedElsewhere.caption',
  [DOWN_PAYMENT]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.downPayment.caption',
  [TRADE_CREDIT_INSURANCE]:
    'inquiryType.onlinefactoring.steps.purpose.sections.yourBusiness.fields.tradeCreditInsurance.caption',
  [BUSINESS_SPECIFICS]:
    'inquiryType.onlinefactoring.steps.purpose.sections.other.fields.businessSpecifics.caption',
  [NUMBER_OF_LIFTS]:
    'inquiryType.kfinanz.steps.purpose.sections.yourBusiness.fields.numberOfLifts.caption',
  [MASTER_CRAFTSMAN]:
    'inquiryType.kfinanz.steps.purpose.sections.yourBusiness.fields.masterCraftsman.caption',
  [PERFORM_TUV_EXAMINATIONS]:
    'inquiryType.kfinanz.steps.purpose.sections.yourBusiness.fields.tuvExaminations.caption',
  [SHARE_OF_INSURANCE_CLAIMS]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.shareOfInsurance.caption',
  [PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.servicesPaidByInvoices.caption',
  [PERCENTAGE_OF_INVOICES_FOR_SELL]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.invoicesForSell.caption',
  [INVOICES_PER_YEAR]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.invoicesPerYear.caption',
  [PAYMENT_TERMS]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.customerTerms.caption',
  [BUSINESS_SPECIFICS]:
    'inquiryType.kfinanz.steps.purpose.sections.others.fields.businessSpecifics.caption',
  [ALREADY_ASSIGNED]:
    'inquiryType.kfinanz.steps.purpose.sections.yourCustomer.fields.alreadyAssigned.caption',
  [OFFER_PAYOUT_RATIO]:
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.payoutRatio.caption',
  [OFFER_FACTORING_LINE]:
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringLine.caption',
  [OFFER_FACTORING_FEE]:
    'pages.inquiryDetails.dashboard.actions.indicativeOffer.factoringFee.caption',
};
