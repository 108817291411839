import { useEffect } from 'react';

import _isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { ReportType } from 'models/CompanyDetails.model';
import {
  IInquiryDetails,
  ReportType as CreditReformProductType,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { ITransparencyRegisterStatus } from 'models/types/TransparencyRegister.types';
import {
  ROLES__FILE_HALTER,
  ROLES__PARTITION,
  ROLES__PROPERTY_BUYER,
  ROLES__PROPERTY_DEVELOPER,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import {
  IProgressSection,
  typeSpecificMapType,
  useDigitalLeadProtect,
  useSectionMeta,
  useSignContract,
} from 'pages/customerPortal/InquiryDetails/Dashboard/useProgressSections';
import { getCompanyRiskAnalysis } from 'pages/operationPortal/CompaniesDetails/store/selectors';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import useCurrentInquiryDetails from 'shared/hooks/inquiry/useCurrentInquiryDetails';
import {
  assignedRealEstateExpert,
  getAllowDigitalProcessingSelector,
  getBankOffersSelector,
  getIndicativeConditionCompleted,
  getInquiryDetailsSelector,
  getInquiryLane,
  getIsQuickCheckStarted,
  getKycCompanies,
  getKycStartedAt,
  getlegalRepresentativesAccepted,
  getLegalRepresentativesRequireManualMerging,
  getProjectFinancingRole,
  getTransparencyResults,
  isCustomerInvitationAccepted,
  isCustomerInvited,
  isKycCompleted,
  isKycFailed,
  isKycSkipped,
  isMarketDataSent,
} from 'store/inquiryDetails/selectors';
import {
  userIsBankAdvisor,
  userIsInquiryManager,
  userIsRealEstateExpert,
} from 'utils/user/conditionals';

import { useSelectedInquiryTypeSpecificValue } from '../../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import { getFilesFromCustomer } from '../../../../store/documentExchange/documentExchange.selectors';
import { useProcessLaneCheck } from '../../../inquiryFlow/useProcessLaneCheck';

const percent = (n: number, max: number) => (n / max) * 100;

const useNonBindingOffer = (): IProgressSection => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const nonBindingOfferCompleted = useSelector(getIndicativeConditionCompleted);
  const inquiryId = inquiryDetails?.inquiryId ?? '';
  const total = 1;
  const hasCorrectRole = userIsBankAdvisor();
  const isLead = inquiryDetails.lane === InquiryLane.lead;
  const isOffer = inquiryDetails.lane === InquiryLane.offer;
  const isContract = inquiryDetails.lane === InquiryLane.contract;
  const isIndicativeOfferEnabledForLead = useConfig(CONFIG.IS_SHOW_INDICATIVE_FOR_LEAD);
  const isIndicativeOfferEnabledForOffer = useConfig(CONFIG.IS_SHOW_INDICATIVE_FOR_OFFER);
  const canShowOffer =
    (isIndicativeOfferEnabledForLead && isLead) ||
    (isIndicativeOfferEnabledForOffer && isOffer) ||
    isContract;
  const visible = canShowOffer && hasCorrectRole;

  const increment = nonBindingOfferCompleted ? 1 : 0;

  return {
    type: ProgressSectionType.NON_BINDING_OFFER,
    visible,
    data: {
      url: paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId),
    },
    increment,
    total,
    completedValue: percent(increment, total),
  };
};

const useCrefoQuery = (): IProgressSection => {
  const { meta } = usePartnerConfig();
  const total = meta.creditReformProducts?.length || 0;
  const inquiryLane = useSelector(getInquiryLane);
  const hasCorrectRole = userIsBankAdvisor();
  const isCrefoManualReportEnabled = useConfig(CONFIG.IS_CREFO_MANUAL_REPORT_ENABLED);
  const visible =
    (inquiryLane === InquiryLane.lead || inquiryLane === InquiryLane.offer) &&
    hasCorrectRole &&
    isCrefoManualReportEnabled &&
    Boolean(total);
  const riskAnalysisReports = useSelector(getCompanyRiskAnalysis);
  const reformProductsToReportsMap: Record<CreditReformProductType, ReportType> = {
    [CreditReformProductType.BLACK_WHITE]: ReportType.blackAndWhite,
    [CreditReformProductType.COMPACT]: ReportType.latestScoringCompact,
    [CreditReformProductType.SHORT]: ReportType.latestScoringShort,
    [CreditReformProductType.RATING]: ReportType.latestRating,
    [CreditReformProductType.TRAFFIC_LIGHT]: ReportType.trafficLight,
  };
  const creditReformProductsToReports = meta.creditReformProducts?.map(
    (item) => reformProductsToReportsMap[item],
  );
  const filteredRiskAnalysisReports = riskAnalysisReports?.filter((report) => {
    return creditReformProductsToReports?.includes(report.type);
  });
  const increment = filteredRiskAnalysisReports ? filteredRiskAnalysisReports?.length : 0;

  return {
    type: ProgressSectionType.CREFO_QUERY,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useDigitalProcessing = (): IProgressSection => {
  const allowDigitalProcessing = useSelector(getAllowDigitalProcessingSelector);
  const isDigitalProcessingEnabled = useConfig(CONFIG.IS_DIGITAL_PROCESSING_ENABLED);
  const increment = allowDigitalProcessing ? 1 : 0;
  const total = 1;
  const inquiryLane = useSelector(getInquiryLane);

  const visible = isDigitalProcessingEnabled && inquiryLane === InquiryLane.lead;

  return {
    type: ProgressSectionType.DIGITAL_PROCESSING,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

export const useMergePeopleAction = (): IProgressSection => {
  // We are separating into required and completed with comparison to true and false because we don't want to mistake an undefined for false
  const manualMergingRequired = useSelector(getLegalRepresentativesRequireManualMerging);
  const isBA = userIsBankAdvisor();
  const visible = isBA && manualMergingRequired;

  // We can't differentiate between "not required" and "completed" here, so we can't mark the step as completed
  const increment = 0;
  const total = 1;

  return {
    type: ProgressSectionType.AUTO_MERGE,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useLegalRepresentationCorrectionSection = (): IProgressSection => {
  const protectLead = useDigitalLeadProtect();
  const areLegalRepresentativesAccepted = useSelector(getlegalRepresentativesAccepted);
  const hasKycCompleted = useSelector(isKycCompleted);
  const isBA = userIsBankAdvisor();
  const lane = useSelector(getInquiryLane);
  const hasKycSkipped = useSelector(isKycSkipped);
  const showAction =
    hasKycCompleted &&
    !hasKycSkipped &&
    isBA &&
    (lane === InquiryLane.lead || lane === InquiryLane.offer) &&
    !areLegalRepresentativesAccepted;
  const visible = protectLead(showAction);
  const increment = areLegalRepresentativesAccepted ? 1 : 0;
  const total = 1;

  return {
    type: ProgressSectionType.LEGAL_REPRESENTATION_CORRECTION,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

export const useResolveKycDataBank = (): IProgressSection => {
  const total = 1;
  const kycCompanies = useSelector(getKycCompanies);
  const hasCorrectRole = userIsBankAdvisor();
  const kycSkipped = useSelector(isKycSkipped);
  let visible = false;
  if (!kycSkipped) {
    if (kycCompanies && kycCompanies.length > 0 && hasCorrectRole) {
      visible = true;
    }
  }

  // Icrement is 0 here because it will be inivisible when completed
  const increment = 0;

  return {
    type: ProgressSectionType.RESOLVE_KYC_DATABANK,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useDigitization = (): IProgressSection => {
  const isEvalueinEnabled = useConfig(CONFIG.IS_EVALUEIN_ENABLED);
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const isContract = inquiryDetails.lane === InquiryLane.contract;
  const visible = isEvalueinEnabled && !isContract;
  const total = 2;
  let increment = 0;
  const customerFiles = useSelector(getFilesFromCustomer);

  if (customerFiles.filter((file: any) => file.digitizationRequestedAt !== null).length !== 0) {
    increment++;
  }

  if (customerFiles.filter((file: any) => file.markedForKpiCalculationAt !== null).length !== 0) {
    increment++;
  }

  return {
    type: ProgressSectionType.DIGITIZATION,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

export const useIsKycPending = () => {
  const kycStartedAt = useSelector(getKycStartedAt);
  const kycCompleted = useSelector(isKycCompleted);
  const kycNoResults = useSelector(isKycFailed);

  return kycStartedAt && !kycCompleted && !kycNoResults;
};

export const useTriggerKyc = (): IProgressSection => {
  const protectLead = useDigitalLeadProtect();
  const inquiryLane = useSelector(getInquiryLane);
  const isOffer = inquiryLane === InquiryLane.offer;
  const isContract = inquiryLane === InquiryLane.contract;
  const laneCheckEnabled = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: true,
    [InquiryType.mmv]: true,
    [InquiryType.default]: false,
  });
  const hasCorrectRole = userIsBankAdvisor();
  const kycStartedAt = useSelector(getKycStartedAt);
  const kycCompleted = useSelector(isKycCompleted);
  const kycSkipped = useSelector(isKycSkipped);
  const isKycPending = useIsKycPending();
  const { inquiryId, isLoading } = useCurrentInquiryDetails();
  const {
    error: processLaneCheckError,
    isLoading: isLoadingLaneCheck,
    mutate: checkProcessLane,
  } = useProcessLaneCheck();

  useEffect(() => {
    if (inquiryId && !isLoading && laneCheckEnabled) {
      checkProcessLane({ id: inquiryId });
    }
  }, [checkProcessLane, inquiryId, isLoading, laneCheckEnabled]);

  // if there's a processLaneCheckError it means that everything is working properly (endpoints return 404 if there's no error with KYC)
  // if there's no processLaneCheckError it means that KYC has an error
  const kycError = laneCheckEnabled && !processLaneCheckError && !isLoadingLaneCheck;
  const total = 1;
  const increment = (kycSkipped || kycCompleted) && !kycError ? 1 : 0;

  const visible = (isOffer && hasCorrectRole) || protectLead(!isContract && hasCorrectRole);

  return {
    type: ProgressSectionType.TRIGGER_KYC,
    increment,
    total,
    data: {
      hasError: kycStartedAt && kycError,
    },
    isPending: (!kycError && isKycPending) || isLoadingLaneCheck,
    completedValue: percent(increment, total),
    visible,
  };
};

const useUploadOffer = (): IProgressSection => {
  const protectLead = useDigitalLeadProtect();
  const inquiryLane = useSelector(getInquiryLane);
  const isOffer = inquiryLane === InquiryLane.offer;
  const isContract = inquiryLane === InquiryLane.contract;
  const hasCorrectRole = userIsBankAdvisor();
  const [offer] = useSelector(getBankOffersSelector);
  const increment = offer ? 1 : 0;
  const total = 1;

  const defaultVisibility =
    (isOffer && hasCorrectRole) || protectLead(!isContract && hasCorrectRole);
  const visible = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: defaultVisibility,
    [InquiryType.hausbank]: hasCorrectRole,
  });

  return {
    type: ProgressSectionType.UPLOAD_OFFER,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useInviteCustomer = (): IProgressSection => {
  const inquiryLane = useSelector(getInquiryLane);
  const isOffer = inquiryLane === InquiryLane.offer;
  const hasCorrectRole = userIsBankAdvisor();

  const { selectedInquiryType } = useFormConfig();

  const hasCustomerInvited = useSelector(isCustomerInvited);
  const hasCustomerInvitationAccepted = useSelector(isCustomerInvitationAccepted);

  const increment = hasCustomerInvited ? 1 : 0;
  const total = 1;

  const visible =
    isOffer &&
    hasCorrectRole &&
    selectedInquiryType === InquiryType.bfs &&
    hasCustomerInvitationAccepted === false;

  return {
    type: ProgressSectionType.INVITE_CUSTOMER,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
    data: {
      disableButton: hasCustomerInvited,
      showButton: hasCustomerInvitationAccepted,
    },
  };
};

const useInconsistencyReport = (): IProgressSection => {
  const transparencyResults = useSelector(getTransparencyResults);
  const increment = 0;
  const total = 1;
  const hasCorrectRole = userIsBankAdvisor();

  const visible = hasCorrectRole && !_isNil(transparencyResults?.inconsistencyFoundAt);

  return {
    type: ProgressSectionType.INCONSISTENCY_REPORT,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useTransparencyCompanySelection = (): IProgressSection => {
  const transparencyResults = useSelector(getTransparencyResults);
  const increment = 0;
  const total = 1;

  const visible =
    transparencyResults?.status === ITransparencyRegisterStatus.MULTIPLE_SEARCH_RESULTS &&
    transparencyResults.selectedOrderId == null;

  return {
    type: ProgressSectionType.TRANSPARENCY_COMPANY,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useMarketResearchTrigger = (): IProgressSection => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const hasMarketDataSent = useSelector(isMarketDataSent);
  const increment = hasMarketDataSent ? 1 : 0;
  const isMarketDataEnabled = useConfig(CONFIG.IS_MARKET_DATA_ENABLED);
  const total = 1;
  const isREE = userIsRealEstateExpert();
  const isBA = userIsBankAdvisor();
  const hasCorrectRole = isREE || isBA;
  const isNotLandBuyer =
    inquiryDetails.financingPurposeFields['project-financing-role'] !== ROLES__PROPERTY_BUYER;

  const visible = isMarketDataEnabled && hasCorrectRole && isNotLandBuyer;

  return {
    type: ProgressSectionType.TRIGGER_MARKET_RESEARCH,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useMarketComparisonTrigger = (): IProgressSection => {
  const hasMarketDataSent = useSelector(isMarketDataSent);
  const increment = hasMarketDataSent ? 1 : 0;
  const total = 1;
  const isBA = userIsBankAdvisor();

  const visible = isBA;

  return {
    type: ProgressSectionType.TRIGGER_MARKET_RESEARCH,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useAssignInquiryToRealEstateExpert = (): IProgressSection => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const isAssignedRealEstateExpert = useSelector(assignedRealEstateExpert);
  const increment = isAssignedRealEstateExpert ? 1 : 0;
  const isRealEstateExpertEnabled = useConfig(CONFIG.IS_REAL_ESTATE_EXPERT_ENABLED);
  const total = 1;
  const hasCorrectRole = userIsBankAdvisor();
  const isNotLandBuyer =
    inquiryDetails.financingPurposeFields['project-financing-role'] !== ROLES__PROPERTY_BUYER;

  const visible = isRealEstateExpertEnabled && hasCorrectRole && isNotLandBuyer;

  return {
    type: ProgressSectionType.ASSIGN_INQUIRY_TO_REAL_ESTATE_EXPERT,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useTriggerProfitabilityCalculation = (): IProgressSection => {
  // Need to increment 1 with some backend flag if the profitability is calculated
  const increment = 0;
  const total = 1;
  const financingRole = useSelector(getProjectFinancingRole);

  const isProfitabilityCalculationEnabled = useConfig(CONFIG.IS_PROFITABILITY_CALCULATION_ENABLED);
  const hasCorrectFinancingRole = [
    ROLES__FILE_HALTER,
    ROLES__PROPERTY_DEVELOPER,
    ROLES__PARTITION,
  ].includes(financingRole);
  const hasCorrectRole = userIsBankAdvisor();

  const visible = isProfitabilityCalculationEnabled && hasCorrectRole && hasCorrectFinancingRole;

  return {
    type: ProgressSectionType.TRIGGER_PROFITABILITY_CALCULATION,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

const useTriggerQuickCheck = (): IProgressSection => {
  const isQuickCheckStarted = useSelector(getIsQuickCheckStarted);
  const increment = isQuickCheckStarted ? 1 : 0;
  const total = 1;
  const isBA = userIsBankAdvisor();
  const isIM = userIsInquiryManager();
  const isREE = userIsRealEstateExpert();
  const isQuickCheckEnabled = useConfig(CONFIG.IS_QUICK_CHECK_ENABLED);
  const hasCorrectRole = isBA || isIM || isREE;

  const visible = isQuickCheckEnabled && hasCorrectRole;

  return {
    type: ProgressSectionType.TRIGGER_QUICK_CHECK,
    increment,
    total,
    completedValue: percent(increment, total),
    visible,
  };
};

export const useOperationProgressSections = () => {
  const { selectedInquiryType } = useFormConfig();

  const nonBindingOffer = useNonBindingOffer();
  const digitalProcessing = useDigitalProcessing();
  const crefoQuery = useCrefoQuery();
  const triggerKyc = useTriggerKyc();
  const uploadOffer = useUploadOffer();
  const mergePeople = useMergePeopleAction();
  const legalRepresentationCorrection = useLegalRepresentationCorrectionSection();
  const inconsistencyReport = useInconsistencyReport();
  const transparencyCompany = useTransparencyCompanySelection();
  const triggerMarketResearch = useMarketResearchTrigger();
  const marketComparison = useMarketComparisonTrigger();
  const inviteCustomer = useInviteCustomer();
  const resolveKycDataBank = useResolveKycDataBank();
  const assignInquiryToRealEstateExpert = useAssignInquiryToRealEstateExpert();
  const digitization = useDigitization();
  const triggerProfitAbilityCalculation = useTriggerProfitabilityCalculation();
  const triggerQuickCheck = useTriggerQuickCheck();
  const signContract = useSignContract();

  // the order here is important as it decides on the order of the tiles in the UI
  const typeSpecificSection: typeSpecificMapType = {
    [InquiryType.bfs]: {
      inviteCustomer,
      uploadOffer,
    },
    [InquiryType.bfsService]: {
      nonBindingOffer,
    },
    [InquiryType.profiMittweida]: {
      marketComparison,
      assignInquiryToRealEstateExpert,
    },
    [InquiryType.profiRLL]: {
      triggerMarketResearch,
      assignInquiryToRealEstateExpert,
      triggerProfitAbilityCalculation,
      triggerQuickCheck,
    },
    [InquiryType.mmv]: {
      signContract,
    },
    default: {
      inviteCustomer,
      nonBindingOffer,
      crefoQuery,
      digitization,
      digitalProcessing,
      triggerKyc,
      resolveKycDataBank,
      mergePeople,
      legalRepresentationCorrection,
      uploadOffer,
      transparencyCompany,
      inconsistencyReport,
    },
  };

  const sections = typeSpecificSection[selectedInquiryType] || typeSpecificSection.default;

  const { firstOpenTask, completedValue, sectionsOpen, sectionsDone, lastItemIndex } =
    useSectionMeta(sections);

  return {
    firstOpenTask,
    sections,
    completedValue,
    sectionsDone,
    sectionsOpen,
    lastItemIndex,
  };
};
