import React from 'react';

import SectionHeading from 'components/Text/SectionHeading';
import { usePartnerConfig } from 'config/partner/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyNoteSection = () => {
  const t = useTranslations();
  const { defaultFieldCaption } =
    translations.pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany
      .notice;

  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  const fieldCaption = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t(defaultFieldCaption),
    [InquiryType.hausbank]: t(
      translations.inquiryType.hausbank.pages.companyData.sections.wegSearch.fields
        .selectedCompanyName.caption,
    ),
  });

  return (
    <SectionHeading as="small">
      {t('pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany.notice', {
        partnerName,
        fieldCaption,
        roleSpecific: true,
      })}
    </SectionHeading>
  );
};

export default CompanyNoteSection;
