import React, { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { NewStaticFieldWithField } from 'components/StaticField';
import { dzbFields, DzbFieldTypes } from 'dzb/inquiry/fields';
import { replaceUndefinedWithZero } from 'new/form/common/replaceUndefinedWithNumber';
import { formatDecimal } from 'utils/valueFormats';

const { shareOfForeignLogistics } = dzbFields.retailerDataPage.onlineQuestionnaireSection;

function useShareOfForeignLogistics() {
  const { change } = useForm();
  const {
    values: {
      retailerDataPage: {
        onlineQuestionnaireSection: {
          foreignLogisticsServiceProviderPercentage,
          foreignFulfillmentByAmazonPercentage,
        },
      },
    },
  } = useFormState<DzbFieldTypes>();

  useEffect(() => {
    const { logistics, amazon } = replaceUndefinedWithZero({
      logistics: foreignLogisticsServiceProviderPercentage,
      amazon: foreignFulfillmentByAmazonPercentage,
    });

    const total = logistics + amazon;
    change(shareOfForeignLogistics, total);
  }, [change, foreignLogisticsServiceProviderPercentage, foreignFulfillmentByAmazonPercentage]);
}

export const ShareOfForeignLogistics = () => {
  useShareOfForeignLogistics();

  return (
    <NewStaticFieldWithField
      name={shareOfForeignLogistics}
      parseValue={(value: number) => formatDecimal(value, { digits: 2, unit: '%', locale: 'de' })}
    />
  );
};
