import { CONTACT_SIDEBAR_VISIBILITY, RWD_MENU_VISIBILITY } from 'store/layout/actions';

const initialState = {
  contactSidebarShow: false,
  rwdMenuShow: false,
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_SIDEBAR_VISIBILITY:
      return {
        ...state,
        contactSidebarShow: action.payload,
      };
    case RWD_MENU_VISIBILITY:
      return {
        ...state,
        rwdMenuShow: action.payload,
      };
    default:
      return state;
  }
};
