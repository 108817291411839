import {
  FURTHER_NEED,
  INVESTMENT_LOAN_TERM_IN_YEARS,
  FUNDING_CLOSING_DATE,
  OTHER_FINANCING_SOLUTIONS,
  PUBLIC_FUNDING,
  EXPERT_ADVICE_ON_ALL,
  PAYMENT_TRANSACTIONS,
  INVESTMENT_AND_SECURITIES,
  FUNDRAISING,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__LEASING,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

import { pushSelectedFlagInArray } from '../utils';

const { toInteger } = fieldParsers;

const getFurtherNeeds = (allFields: any) => {
  const futherNeeds: any[] = [];
  pushSelectedFlagInArray(allFields, futherNeeds, OTHER_FINANCING_SOLUTIONS);
  pushSelectedFlagInArray(allFields, futherNeeds, EXPERT_ADVICE_ON_ALL);
  pushSelectedFlagInArray(allFields, futherNeeds, PUBLIC_FUNDING);
  pushSelectedFlagInArray(allFields, futherNeeds, PAYMENT_TRANSACTIONS);
  pushSelectedFlagInArray(allFields, futherNeeds, INVESTMENT_AND_SECURITIES);
  pushSelectedFlagInArray(allFields, futherNeeds, FUNDRAISING);
  pushSelectedFlagInArray(allFields, futherNeeds, PRODUCT_KIND__FACTORING);
  pushSelectedFlagInArray(allFields, futherNeeds, PRODUCT_KIND__LEASING);
  return futherNeeds;
};

export const mapBFSProductDetailsToApi: InquiryFormMapper = (allFields) => {
  return {
    'further-need-values': getFurtherNeeds(allFields),
    'loan-term-in-years': toInteger(allFields[INVESTMENT_LOAN_TERM_IN_YEARS]),
    'funding-closing-date': allFields[FUNDING_CLOSING_DATE],
    'desired-delivery-date': allFields[FUNDING_CLOSING_DATE],
    further_need: allFields[FURTHER_NEED],
  };
};
