import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';

import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import InquiryDraftModeSidebar from 'modules/Inquiry/DraftMode/Sidebar/InquiryDraftModeSidebar';
import { removeInquiryDraftAction } from 'modules/Inquiry/DraftMode/Sidebar/service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { onlyForRoutesGuard } from 'shared/guard/onlyForRoutesGuard';
import withConfigFlagGuard from 'shared/guard/withConfigFlagGuard';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { clearInquiryDraftId } from 'store/inquiryProcess/actions';
import { getInquiryDraftIdSelector } from 'store/inquiryProcess/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const RemoveInquiryDraftButton = () => {
  const draftId = useSelector(getInquiryDraftIdSelector);

  const t = useTranslations('inquiry.draftMode');
  const history = useHistory();
  const { success } = useToasts();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isPending, makeCall } = useDispatchApiCall();
  const dispatch = useDispatch();

  const removeInquiryDraft = () =>
    makeCall(removeInquiryDraftAction(draftId), () => {
      success({ description: t('remove.draftRemoved') });
      dispatch(clearInquiryDraftId());
      history.push(paths.customer.inquiryList);
    });

  return (
    <>
      <InquiryDraftModeSidebar
        icon={<DeleteIcon boxSize={6} mt={1} mb={1} />}
        onClick={onOpen}
        disabled={!draftId}
        text={t('sidebar.delete')}
      />

      <AlertDialogComponent
        cancelText={t('remove.cancelText')}
        confirmText={t('remove.confirmText')}
        disabled={isPending}
        isLoading={isPending}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={removeInquiryDraft}
        title={t('remove.headline')}
      >
        {t('remove.description')}
      </AlertDialogComponent>
    </>
  );
};

export default compose(
  withConfigFlagGuard(CONFIG.IS_INQUIRY_DRAFT_MODE_ENABLED),
  withRolesGuard(PERMISSIONS.INQUIRY.REMOVE_DRAFT),
  onlyForRoutesGuard([
    paths.financingNeed,
    paths.companyDetails,
    paths.requestDetails,
    paths.userProfile,
    paths.summaryPage,
    paths.contactPerson,
    paths.peopleDetails,
  ]),
)(RemoveInquiryDraftButton);
