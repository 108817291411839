import styled from 'styled-components/macro';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';

export const StyledUsageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -2rem;
`;

export const StyledTypesLead = styled.p`
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledTypeTitle = styled.div`
  margin-top: 2rem;
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
