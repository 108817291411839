import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { success } from 'utils/actionTypesHelpers';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const ADDITIONAL_FIELDS = 'ADDITIONAL_FIELDS';
export const ADDITIONAL_FIELDS_SUCCESS = success(ADDITIONAL_FIELDS);

const additionalFields = (
  inquiryType: InquiryType,
  inquiryId: string,
  turnover: string,
  balance: string,
  employeeCount: number,
) =>
  callReverseApi({
    url: endpoints.INQUIRIES.SPECIALIZED.FINAL_INFO.compose({ params: { inquiryType, inquiryId } }),
    method: API_METHODS.PUT,
    actionName: ADDITIONAL_FIELDS,
    data: {
      data: {
        attributes: {
          detailed_annual_turnover: turnover,
          detailed_balance_sheet: balance,
          count_of_employees: employeeCount,
        },
      },
    },
  });

export const useAdditionalFields = () => {
  const { makeCall } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });
  const { selectedInquiryType } = useFormConfig();

  return async (inquiryId: string, turnover: string, balance: string, employeeCount: number) =>
    makeCall(additionalFields(selectedInquiryType, inquiryId, turnover, balance, employeeCount));
};
