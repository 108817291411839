import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  .markdown {
    p:not(:last-child) {
      margin-bottom: 1rem;
    }

    a {
      color: inherit;
    }
  }
`;
