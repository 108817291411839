import { RealEstateProject } from 'models/RealEstateProject.model';
import {
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_KIND,
  REAL_ESTATE_SCHEDULED_DATE,
  REAL_ESTATE_RESIDUAL_PERCENTAGE,
  REAL_ESTATE_PROJECT__NEW_BUILDING,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_FOLLOW_UP_AMOUNT,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_RECONSTRUCTION_COST,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_ZIP_CODE,
  REAL_ESTATE_PROJECT_DESCRIPTION,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_USAGE_KIND,
  REAL_ESTATE_USAGE_KIND__THIRD_PARTY,
  REAL_ESTATE_USAGE_KIND__BOTH,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_TAXATION,
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_TOTAL_COST,
  REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger, toBoolean, getMonthAndYearFromDate } = fieldParsers;

const mapCommonFields: InquiryFormMapper = (allFields) => {
  const usageKind = allFields[REAL_ESTATE_USAGE_KIND];
  const isRentable = [REAL_ESTATE_USAGE_KIND__THIRD_PARTY, REAL_ESTATE_USAGE_KIND__BOTH].includes(
    usageKind,
  );

  const hasHeritableBuildingRights = !!toBoolean(allFields[REAL_ESTATE_HERITABLE_BUILDING_RIGHT]);

  return {
    'real-estate-project': allFields[REAL_ESTATE_PROJECT],
    'real-estate-land-size': toInteger(allFields[REAL_ESTATE_LAND_SIZE]),
    'real-estate-building-size': toInteger(allFields[REAL_ESTATE_BUILDING_SIZE]),
    'real-estate-zip-code': allFields[REAL_ESTATE_ZIP_CODE],
    'real-estate-project-description': allFields[REAL_ESTATE_PROJECT_DESCRIPTION],
    'real-estate-usage-kind': allFields[REAL_ESTATE_USAGE_KIND],
    'real-estate-heritable-building-right': toBoolean(
      allFields[REAL_ESTATE_HERITABLE_BUILDING_RIGHT],
    ),
    ...conditionalObject(hasHeritableBuildingRights, {
      'real-estate-heritable-building-right-obligation': toInteger(
        allFields[REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION],
      ),
    }),
    ...conditionalObject(isRentable, {
      'real-estate-rented-out-area': toInteger(allFields[REAL_ESTATE_RENTED_OUT_AREA]),
      'real-estate-cold-rent': toInteger(allFields[REAL_ESTATE_COLD_RENT]),
      'real-estate-taxation': allFields[REAL_ESTATE_TAXATION],
    }),

    'real-estate-development-project-description':
      allFields[REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION],
    'real-estate-total-living-and-usable-space': toInteger(allFields[REAL_ESTATE_BUILDING_SIZE]),
  };
};

const mapNewBuilding: InquiryFormMapper = (allFields) => ({
  'real-estate-kind': allFields[REAL_ESTATE_KIND],
  'real-estate-scheduled-date': getMonthAndYearFromDate(allFields[REAL_ESTATE_SCHEDULED_DATE]),
  'real-estate-residual-percentage': toInteger(allFields[REAL_ESTATE_RESIDUAL_PERCENTAGE]),
  'real-estate-total-cost': toInteger(allFields[REAL_ESTATE_TOTAL_COST]),
  'real-estate-construction-date': getMonthAndYearFromDate(
    allFields[REAL_ESTATE_CONSTRUCTION_DATE],
  ),
  'real-estate-planned-start-of-construction': getMonthAndYearFromDate(
    allFields[REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION],
  ),
  ...mapCommonFields(allFields),
});

const mapPurchaseBuilding: InquiryFormMapper = (allFields) => ({
  'real-estate-kind': allFields[REAL_ESTATE_KIND],
  'real-estate-scheduled-date': allFields[REAL_ESTATE_SCHEDULED_DATE],
  'real-estate-residual-percentage': toInteger(allFields[REAL_ESTATE_RESIDUAL_PERCENTAGE]),
  'real-estate-construction-date': getMonthAndYearFromDate(
    allFields[REAL_ESTATE_CONSTRUCTION_DATE],
  ),
  'real-estate-purchase-price': toInteger(allFields[REAL_ESTATE_PURCHASE_PRICE]),
  ...mapCommonFields(allFields),
});

const mapFollowUpFinancing: InquiryFormMapper = (allFields) => ({
  'real-estate-follow-up-amount': toInteger(allFields[REAL_ESTATE_FOLLOW_UP_AMOUNT]),
  'real-estate-residual-percentage': toInteger(allFields[REAL_ESTATE_RESIDUAL_PERCENTAGE]),
  'real-estate-construction-date': getMonthAndYearFromDate(
    allFields[REAL_ESTATE_CONSTRUCTION_DATE],
  ),
  ...mapCommonFields(allFields),
});

const mapReconstruction: InquiryFormMapper = (allFields) => ({
  'real-estate-reconstruction-costs': toInteger(allFields[REAL_ESTATE_RECONSTRUCTION_COST]),
  'real-estate-construction-date': getMonthAndYearFromDate(
    allFields[REAL_ESTATE_CONSTRUCTION_DATE],
  ),
  ...mapCommonFields(allFields),
});

const mapDevelopmentProject: InquiryFormMapper = (allFields) => ({
  'real-estate-development-project-amount': toInteger(
    allFields[REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT],
  ),
  'real-estate-development-project-description':
    allFields[REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION],
});

export const mapRealEstateToApi: InquiryFormMapper = (allFields) => {
  const selectedProject: RealEstateProject = allFields[REAL_ESTATE_PROJECT];

  const projectsMap: { [key in RealEstateProject]: InquiryFormMapper } = {
    [REAL_ESTATE_PROJECT__NEW_BUILDING]: mapNewBuilding,
    [REAL_ESTATE_PROJECT__PURCHASE]: mapPurchaseBuilding,
    [REAL_ESTATE_PROJECT__FOLLOW_UP]: mapFollowUpFinancing,
    [REAL_ESTATE_PROJECT__RECONSTRUCTION]: mapReconstruction,
    [REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT]: mapDevelopmentProject,
  };

  if (!projectsMap[selectedProject]) {
    throw new Error(`No API mapper for ${selectedProject} real estate project`);
  }

  return {
    'real-estate-project': selectedProject,
    ...projectsMap[selectedProject](allFields),
  };
};
