import styled from 'styled-components/macro';

import { borderRadius, transition } from 'theme/themeConstants';

const LoaderVertical = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: ${borderRadius.default};
  background-color: ${({ theme }) => theme.colors.background.neutral};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress}%;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.background.blackGrey};
    transition: width ${transition.default};
  }
`;

export default LoaderVertical;
