import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  USER_EXISTING_REPRESENTATIVE,
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
} from '../../Form/formFields';
import { fieldParsers } from '../fieldParsers';

const { toBoolean } = fieldParsers;

export const mapDefaultLegalRepresentative = (allFields: any, representative: any) => {
  return {
    salutation: representative[REPRESENTATIVE_GENDER],
    first_name: representative[REPRESENTATIVE_FIRST_NAME],
    last_name: representative[REPRESENTATIVE_LAST_NAME],
    email: representative[REPRESENTATIVE_EMAIL],
    birth_date: representative[REPRESENTATIVE_BIRTHDATE],
    phone_number: representative[REPRESENTATIVE_PHONE_NUMBER],
  };
};

export interface MapLegalRepresentativesOptions {
  mapLegalRepresentative: (allFields: any, representative: any) => any;
}

export const mapLegalRepresentatives = (
  allFields: any,
  options: MapLegalRepresentativesOptions,
) => {
  const isAuthorizedToRepresent = toBoolean(allFields[USER_REPRESENTATION]) || false;
  let legalRepresentatives: Array<any> = [];
  const userRepresentatives = allFields[USER_REPRESENTATIVE];
  const existingUserRepresentatives = allFields[USER_EXISTING_REPRESENTATIVE];

  if (userRepresentatives || existingUserRepresentatives) {
    legalRepresentatives = [...(userRepresentatives || []), ...(existingUserRepresentatives || [])]
      .map((representative: any) => {
        return options.mapLegalRepresentative(allFields, representative);
      })
      // remove duplicates
      .filter((representative, index, array) => {
        if (!representative.id) {
          return true;
        }
        const isDuplicate = array.findIndex((item) => item.id === representative.id) !== index;
        return !isDuplicate;
      })
      // remove empty values
      .filter(
        (representative) =>
          representative.id || representative.first_name || representative.last_name,
      );
  }

  return {
    legal_representation: legalRepresentatives.length > 0,
    user_legal_representation: isAuthorizedToRepresent,
    legal_representatives: legalRepresentatives,
  };
};
