import { AppActionThunk } from '../../models/types/AppThunk.type';

export const SET_NEW_INQUIRY_DETAILS_ACTION = 'SET_NEW_INQUIRY_DETAILS_ACTION';

export const setNewInquiryDetailsAction =
  (inquiryDetails: any): AppActionThunk =>
  (dispatch) => {
    return dispatch({
      type: SET_NEW_INQUIRY_DETAILS_ACTION,
      payload: inquiryDetails,
    });
  };
