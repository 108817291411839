import React from 'react';

import PropTypes from 'prop-types';

import FileBlock from 'components/FileBlock';
import { UPLOAD_STATUSES } from 'components/UploadBlock/constants';
import {
  UPLOAD_STATUS_DASHED_MAP,
  UPLOAD_STATUS_TO_COLOR_MAP,
} from 'components/UploadBlock/UploadFileBlock/constants';
import UploadIcon from 'theme/components/Icon/UploadIcon';

const UploadFileBlock = ({ title, status, children }) => {
  const isDashed = UPLOAD_STATUS_DASHED_MAP[status];
  const fileColor = UPLOAD_STATUS_TO_COLOR_MAP[status];
  return (
    <FileBlock title={title} color={fileColor} icon={<UploadIcon boxSize={12} />} dashed={isDashed}>
      {children}
    </FileBlock>
  );
};

UploadFileBlock.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.oneOf(UPLOAD_STATUSES).isRequired,
  children: PropTypes.node.isRequired,
};

export default UploadFileBlock;
