import React from 'react';

import { Flex, ModalProps } from '@chakra-ui/react';

import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { StyledDescription } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/styles';
import { translations } from 'new/form/common/types';
import { BeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { useDeleteBeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/beneficialOwner/deleteBeneficialOwner';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';

import { withAssociatedPeopleEditMode } from '../associatedPeopleMode/withAssociatedPeopleEditMode';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  beneficialOwner: BeneficialOwner | undefined;
};

const DeleteBeneficialOwnerModal = ({ isOpen, onClose, beneficialOwner }: Props) => {
  const { mutateAsync, status } = useDeleteBeneficialOwner(beneficialOwner?.id as string);
  const { refetchInquiry } = useFetchInquiryData();

  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

  const onSubmit = async () => {
    await mutateAsync();
    await refetchInquiry();
    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t(actions.delete.title)}
      footer={
        <Flex gap={4}>
          <ButtonComponent variant={'tertiary'} onClick={onClose} testId="cancelButton">
            {t(actions.delete.cancel)}
          </ButtonComponent>
          <ButtonComponent
            variant={'primary'}
            type="submit"
            onClick={onSubmit}
            isLoading={status === 'loading'}
            testId="confirmButton"
          >
            {t(actions.delete.confirm)}
          </ButtonComponent>
        </Flex>
      }
    >
      <StyledDescription>
        {t(actions.delete.caption, {
          firstName: beneficialOwner?.firstName,
          lastName: beneficialOwner?.lastName,
        })}
      </StyledDescription>
    </ModalComponent>
  );
};

export default withAssociatedPeopleEditMode(DeleteBeneficialOwnerModal);
