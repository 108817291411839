import { RLLProfitability } from 'models/types/Profitability.type';

import { formatDecimalForAPI } from './mapProfitabilitySectionsToApi/utils';
import * as fields from '../Inquiry/Form/formFields';

export const mapRLLFinancingDetailsToAPI = (
  data: Record<string, any>,
): Partial<RLLProfitability> => ({
  financingDetails: {
    building: {
      duration: Number(data[fields.PROFITABILITY_FINANCING_DURATION_BUILDING]),
      financingFee: formatDecimalForAPI(data[fields.PROFITABILITY_FINANCING_FEE_BUILDING])!,
      interestRate: formatDecimalForAPI(data[fields.PROFITABILITY_INTEREST_RATE_BUILDING])!,
    },
    rent: {
      duration: Number(data[fields.PROFITABILITY_FINANCING_DURATION_RENT]),
      financingFee: formatDecimalForAPI(data[fields.PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT])!,
      interestRate: formatDecimalForAPI(data[fields.PROFITABILITY_INTEREST_RATE_RENT])!,
      interestRateShortTerm: formatDecimalForAPI(
        data[fields.PROFITABILITY_INTEREST_RATE_RENT_SHORT],
      )!,
      repaymentRate: formatDecimalForAPI(data[fields.PROFITABILITY_REPAYMENT_RATE_RENT])!,
    },
    unit: {
      duration: Number(data[fields.PROFITABILITY_FINANCING_DURATION_UNIT]),
      financingFee: formatDecimalForAPI(data[fields.PROFITABILITY_FINANCING_FEE_UNIT])!,
      interestRate: formatDecimalForAPI(data[fields.PROFITABILITY_INTEREST_RATE_UNIT])!,
    },
  },
});

export const mapRLLLendingValueToAPI = (data: Record<string, any>): Partial<RLLProfitability> => ({
  lendingValue: {
    extraMarkdown: formatDecimalForAPI(data[fields.PROFITABILITY_EXTRA_MARKDOWN])!,
    safetyMarkdown: formatDecimalForAPI(data[fields.PROFITABILITY_SAFETY_MARKDOWN])!,
  },
});

export const mapRLLPresalesToAPI = (data: Record<string, any>): Partial<RLLProfitability> => ({
  presales: {
    baseValues: {
      areaCommercialPercent: formatDecimalForAPI(
        data[fields.PROFITABILITY_PRESALES_AREA_COMMERCIAL],
      )!,
      areaResidentialPercent: formatDecimalForAPI(
        data[fields.PROFITABILITY_PRESALES_AREA_RESIDENTIAL],
      )!,
      garage: Number(data[fields.PROFITABILITY_NUMBER_OF_GARAGES]),
      parking: Number(data[fields.PROFITABILITY_NUMBER_OF_PARKING_LOTS]),
    },
    pricePerShare: {
      garage: formatDecimalForAPI(data[fields.PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE])!,
      parking: formatDecimalForAPI(
        data[fields.PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE],
      )!,
      commercial: formatDecimalForAPI(
        data[fields.PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM],
      )!,
      residential: formatDecimalForAPI(
        data[fields.PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM],
      )!,
    },
  },
});

export const mapRLLInvestmentCalculationToAPI = (
  data: Record<string, any>,
): Partial<RLLProfitability> => ({
  investment: {
    agentFee: formatDecimalForAPI(data[fields.PROFITABILITY_AGENT_FEES])!,
    legalFee: formatDecimalForAPI(data[fields.PROFITABILITY_LEGAL_FEES])!,
    otherFees: formatDecimalForAPI(data[fields.PROFITABILITY_OTHER_FEES])!,
    otherCosts: formatDecimalForAPI(data[fields.PROFITABILITY_OTHER_COSTS])!,
    compensations: formatDecimalForAPI(data[fields.PROFITABILITY_COMPENSATIONS])!,
    purchasePrice: formatDecimalForAPI(data[fields.PROFITABILITY_PURCHASE_PRICE])!,
    publicDisposal: formatDecimalForAPI(data[fields.PROFITABILITY_PUBLIC_DISPOSAL])!,
    measurementCosts: formatDecimalForAPI(data[fields.PROFITABILITY_MEASUREMENT_COSTS])!,
    nonPublicDisposal: formatDecimalForAPI(data[fields.PROFITABILITY_NON_PUBLIC_DISPOSAL])!,
    otherPurchaseCosts: formatDecimalForAPI(data[fields.PROFITABILITY_OTHER_PURCHASE_COSTS])!,
    landDevelopmentCosts: formatDecimalForAPI(data[fields.PROFITABILITY_LAND_DEVELOPMENT_COSTS])!,
    otherDevelopmentCosts: formatDecimalForAPI(data[fields.PROFITABILITY_OTHER_DEVELOPMENT_COSTS])!,
    realEstateTransferTax: formatDecimalForAPI(
      data[fields.PROFITABILITY_REAL_ESTATE_TRANSFER_TAX],
    )!,
    buffer: formatDecimalForAPI(data[fields.PROFITABILITY_BUFFER])!,
    marketingSalesCosts: formatDecimalForAPI(data[fields.PROFITABILITY_MARKETING_SALES_COSTS])!,
    constructionCosts: formatDecimalForAPI(data[fields.PROFITABILITY_CONSTRUCTION_COSTS])!,
    otherConstructionCosts: formatDecimalForAPI(
      data[fields.PROFITABILITY_OTHER_CONSTRUCTION_COSTS],
    )!,
    outdoorFacilitiesCosts: formatDecimalForAPI(
      data[fields.PROFITABILITY_OUTDOOR_FACILITIES_COSTS],
    )!,
    financingCostsFeesManual: formatDecimalForAPI(
      data[fields.PROFITABILITY_FINANCING_COSTS_FEES_MANUAL],
    )!,
    thirdPartyFinancingCosts: formatDecimalForAPI(
      data[fields.PROFITABILITY_THIRD_PARTY_FINANCING_COSTS],
    )!,
    financingCostsInterestsManual: formatDecimalForAPI(
      data[fields.PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL],
    )!,
    architectCosts: formatDecimalForAPI(data[fields.PROFITABILITY_ARCHITECT_COSTS])!,
    additionalCosts: formatDecimalForAPI(data[fields.PROFITABILITY_ADDITIONAL_COSTS])!,
  },
});

export const mapRLLEvaluationToAPI = (data: Record<string, any>): Partial<RLLProfitability> => ({
  evaluation: {
    usualSalesCostsRate: formatDecimalForAPI(data[fields.PROFITABILITY_USUAL_SALES_COST_RATE]),
    salesRevenue: {
      future: {
        unit: {
          parkingLots: formatDecimalForAPI(
            data[fields.PROFITABILITY_FUTURE_PARKING_SPACE_REVENUE_UNIT],
          ),
          garageParking: formatDecimalForAPI(
            data[fields.PROFITABILITY_FUTURE_GARAGE_PARKING_SPACE_REVENUE_UNIT],
          ),
        },
        building: {
          parkingLots: formatDecimalForAPI(
            data[fields.PROFITABILITY_FUTURE_PARKING_SPACE_REVENUE_BUILDING],
          ),
          garageParking: formatDecimalForAPI(
            data[fields.PROFITABILITY_FUTURE_GARAGE_PARKING_SPACE_REVENUE_BUILDING],
          ),
        },
      },
      current: {
        unit: {
          parkingLots: formatDecimalForAPI(
            data[fields.PROFITABILITY_CURRENT_PARKING_SPACE_REVENUE_UNIT],
          ),
          garageParking: formatDecimalForAPI(
            data[fields.PROFITABILITY_CURRENT_GARAGE_PARKING_SPACE_REVENUE_UNIT],
          ),
        },
        building: {
          parkingLots: formatDecimalForAPI(
            data[fields.PROFITABILITY_CURRENT_PARKING_SPACE_REVENUE_BUILDING],
          ),
          garageParking: formatDecimalForAPI(
            data[fields.PROFITABILITY_CURRENT_GARAGE_PARKING_SPACE_REVENUE_BUILDING],
          ),
        },
      },
    },
  },
});

export const mapRLLEvaluationRentToAPI = (
  data: Record<string, any>,
): Partial<RLLProfitability> => ({
  evaluationRent: {
    residentialOperatingCostsPercent: formatDecimalForAPI(
      data[fields.PROFITABILITY_OPERATING_COSTS_RESIDENTIAL],
    )!,
    commercialOperatingCostsPercent: formatDecimalForAPI(
      data[fields.PROFITABILITY_OPERATING_COSTS_COMMERCIAL],
    )!,
    depreciation: formatDecimalForAPI(data[fields.PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO])!,
    taxRate: formatDecimalForAPI(data[fields.PROFITABILITY_TAX_RATE_IN_PERCENT])!,
    rentRevenue: {
      current: {
        unit: {
          parkingLots: formatDecimalForAPI(
            data[fields.PROFITABILITY_CURRENT_RENTAL_INCOME_PARKING_UNIT],
          )!,
          garageParking: formatDecimalForAPI(
            data[fields.PROFITABILITY_CURRENT_RENTAL_INCOME_GARAGE_PARKING_UNIT],
          )!,
        },
      },
      future: {
        unit: {
          parkingLots: formatDecimalForAPI(
            data[fields.PROFITABILITY_FUTURE_RENTAL_INCOME_PARKING_UNIT],
          )!,
          garageParking: formatDecimalForAPI(
            data[fields.PROFITABILITY_FUTURE_RENTAL_INCOME_GARAGE_PARKING_UNIT],
          )!,
        },
      },
    },
  },
});

export const mapRLLQuickCheckToAPI = (data: Record<string, any>): Partial<RLLProfitability> => ({
  quickCheck: {
    customerInformation: {
      previousBusinessRelationshipInfo: data[fields.QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS],
      initiatorInfo: data[fields.QUICK_CHECK_INITIATOR],
      projectFinancingStatusInhouse:
        data[fields.QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE],
      economicCircumstances: data[fields.QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER],
    },
    projectInformation: {
      projectDescription: data[fields.QUICK_CHECK_PROJECT_DESCRIPTION],
      collateralInfo: data[fields.QUICK_CHECK_INFO_ON_COLLATERAL],
      withdrawalRequirements: data[fields.QUICK_CHECK_WITHDRAWAL_REQUIREMENTS],
    },
    otherInformation: {
      otherNotesInfo: data[fields.QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION],
      justificationTemplate: data[fields.QUICK_CHECK_JUSTIFICATION_TEMPLATE],
    },
    loanValue: {
      securityAndWarrantor: data[fields.QUICK_CHECK_TYPE_AND_GUARANTOR],
      amountInEuros: formatDecimalForAPI(data[fields.QUICK_CHECK_AMOUNT_IN_EURO]),
    },
    unsecuredPortion: {
      currentTotalCommitmentInEuro: formatDecimalForAPI(
        data[fields.QUICK_CHECK_CURRENT_TOTAL_COMMITMENT],
      ),
      newCommitmentInEuro: formatDecimalForAPI(data[fields.QUICK_CHECK_NEW_COMMITMENT]),
      currentTotalCollateralInEuro: formatDecimalForAPI(
        data[fields.QUICK_CHECK_CURRENT_COLLATERAL_VALUE],
      ),
      newCollateralInEuro: formatDecimalForAPI(data[fields.QUICK_CHECK_NEW_COLLATERAL]),
    },
  },
});

export const mapRLLRealEstateExpertToAPI = (
  data: Record<string, any>,
): Partial<RLLProfitability> => ({
  realEstateExpert: {
    current: {
      building: {
        marketValuePerSqm: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING],
        )!,
        marketValueParkingLot: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_PARKING_LOT_VALUE],
        )!,
        commercialRentPerSqm: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM],
        )!,
        rentParkingLot: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_PARKING_LOT_RENT])!,
        marketValueGarage: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_GARAGE_VALUE])!,
        residentialRentPerSqm: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM],
        )!,
        rentGarage: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_GARAGE_RENT])!,
      },
      unit: {
        marketValuePerSqm: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT])!,
        marketValueParkingLot: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_PARKING_LOT_VALUE],
        )!,
        commercialRentPerSqm: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM],
        )!,
        rentParkingLot: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_PARKING_LOT_RENT])!,
        marketValueGarage: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_GARAGE_VALUE])!,
        residentialRentPerSqm: formatDecimalForAPI(
          data[fields.CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM],
        )!,
        rentGarage: formatDecimalForAPI(data[fields.CURRENT_USAGE_REE_GARAGE_RENT])!,
      },
    },
    future: {
      building: {
        marketValuePerSqm: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING],
        )!,
        marketValueParkingLot: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_PARKING_LOT_VALUE],
        )!,
        commercialRentPerSqm: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM],
        )!,
        rentParkingLot: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_PARKING_LOT_RENT])!,
        marketValueGarage: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_GARAGE_VALUE])!,
        residentialRentPerSqm: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM],
        )!,
        rentGarage: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_GARAGE_RENT])!,
      },
      unit: {
        marketValuePerSqm: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT])!,
        marketValueParkingLot: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_PARKING_LOT_VALUE],
        )!,
        commercialRentPerSqm: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM],
        )!,
        rentParkingLot: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_PARKING_LOT_RENT])!,
        marketValueGarage: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_GARAGE_VALUE])!,
        residentialRentPerSqm: formatDecimalForAPI(
          data[fields.FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM],
        )!,
        rentGarage: formatDecimalForAPI(data[fields.FUTURE_USAGE_REE_GARAGE_RENT])!,
      },
    },
  },
});
