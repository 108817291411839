import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

import {
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  VOLUME_OF_SALES,
  INVOICES_PER_YEAR,
  PAYMENT_TERMS,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  SHARE_OF_INSURANCE_CLAIMS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
} from './formFields';

export const onlinefactoringUnits = {
  [VOLUME_OF_SALES]: FIELD_UNIT_TYPE.CURRENCY,
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS]: FIELD_UNIT_TYPE.CURRENCY,
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP]: FIELD_UNIT_TYPE.PERCENTAGE,
  [SHARE_OF_SALES_MAIN_CUSTOMERS]: FIELD_UNIT_TYPE.CURRENCY,
  [SHARE_OF_SALES_FOREIGN_CUSTOMERS]: FIELD_UNIT_TYPE.CURRENCY,
  [PAYMENT_TERMS]: FIELD_UNIT_TYPE.DAYS,
  [INVOICES_PER_YEAR]: FIELD_UNIT_TYPE.INVOICES,
  [SHARE_OF_INSURANCE_CLAIMS]: FIELD_UNIT_TYPE.PERCENTAGE,
  [PERCENTAGE_OF_INVOICES_FOR_SELL]: FIELD_UNIT_TYPE.PERCENTAGE,
  [PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [OFFER_FACTORING_LINE]: FIELD_UNIT_TYPE.CURRENCY,
  [OFFER_FACTORING_FEE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [OFFER_PAYOUT_RATIO]: FIELD_UNIT_TYPE.PERCENTAGE,
};
