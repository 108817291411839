import { otherFundingSectionValidationMap } from 'mittweida/inquiry/steps/fundingDetails/sections/otherFundingSection/fieldValidations';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateOtherFundingSection: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: otherFundingSectionValidationMap,
    form,
    values,
    conditions,
  });
};
