import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { name, contactPerson, totalPurchaseOfGoods, annualSalesForecast, contractDate } =
  dzbFields.companyDataPage.associationInformationSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.companyData.sections.associationInformation;

export const associationInformationSectionLabelsMap: LabelsMap = {
  [name]: fieldTranslations.name.caption,
  [contactPerson]: fieldTranslations.contactPerson.caption,
  [totalPurchaseOfGoods]: fieldTranslations.totalPurchaseOfGoods.caption,
  [annualSalesForecast]: fieldTranslations.annualSalesForecast.caption,
  [contractDate]: fieldTranslations.contractDate.caption,
};
