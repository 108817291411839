import {
  dzbFields,
  dzbFieldsWithoutParents,
  dzbFieldsWithSectionString,
  DzbFieldTypes,
} from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateArrayFields, ValidateSectionFunction } from 'new/form/useValidateFields';
import { dzbLegalRepresentativesValidations } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/validations';

export const validateLegalRepresentativesSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  const {
    salutation,
    firstName,
    lastName,
    birthDate,
    email,
    phoneNumber,
    soleSignatureAuthorized,
    taxIdentificationNumber,
  } = dzbFieldsWithoutParents.personalDataPage.legalRepresentativesSection.legalRepresentatives;

  const isSoleAuthorizationValid = isValidLegalRepresentatives(values);

  if (isSoleAuthorizationValid) {
    return validateArrayFields({
      arrayValues: values.personalDataPage?.legalRepresentativesSection?.legalRepresentatives || [],
      fieldsWithoutParent: [
        salutation,
        firstName,
        lastName,
        birthDate,
        email,
        phoneNumber,
        soleSignatureAuthorized,
        taxIdentificationNumber,
      ],
      parentName:
        dzbFieldsWithSectionString.personalDataPage.legalRepresentativesSection.legalRepresentatives
          .sectionString,
      form,
      values,
      conditions,
    });
  }

  return false;
};

const {
  salutation,
  firstName,
  lastName,
  birthDate,
  email,
  phoneNumber,
  soleSignatureAuthorized,
  taxIdentificationNumber,
} = dzbFields.personalDataPage.legalRepresentativesSection.legalRepresentatives;

export const legalRepresentativesSectionValidationMap = {
  [salutation]: dzbLegalRepresentativesValidations.salutation,
  [firstName]: dzbLegalRepresentativesValidations.firstName,
  [lastName]: dzbLegalRepresentativesValidations.lastName,
  [birthDate]: dzbLegalRepresentativesValidations.birthDate,
  [email]: dzbLegalRepresentativesValidations.email,
  [phoneNumber]: dzbLegalRepresentativesValidations.phoneNumber,
  [soleSignatureAuthorized]: dzbLegalRepresentativesValidations.soleSignatureAuthorized,
  [taxIdentificationNumber]: dzbLegalRepresentativesValidations.taxIdentificationNumber,
};

export const isValidLegalRepresentatives = (values: DzbFieldTypes): boolean => {
  const legalRepresentatives =
    values.personalDataPage?.legalRepresentativesSection?.legalRepresentatives;

  // No legal representatives, assume it's valid (or handle as you see fit)
  if (!legalRepresentatives || legalRepresentatives.length === 0) {
    return true;
  }

  // Only proceed if there is exactly one legal representative
  if (legalRepresentatives.length === 1) {
    const [legalRepresentative] = legalRepresentatives; // Destructuring to get the first element

    // If the legal representative exists
    if (legalRepresentative) {
      const isSoleSignatureAuthorized = Boolean(
        JSON.parse((legalRepresentative.soleSignatureAuthorized as unknown as string) ?? 'false'),
      );

      return isSoleSignatureAuthorized;
    }

    // If the legal representative doesn't exist, it's invalid
    return false;
  }

  return true;
};
