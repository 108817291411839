import React from 'react';

import { HStack, Link as ChakraLink, Stack } from '@chakra-ui/react';
import { Form as FinalForm } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';

import Condition from 'components/Condition';
import { InputWithField } from 'components/Input';
import { PasswordInputWithField } from 'components/PasswordInput';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import { FormContainer } from './FormContainer';
import { RegistrationLink } from './RegistrationLink';
import { useInitialValues } from './useInitialValues';
import { EMAIL_FIELD, PASSWORD_FIELD } from '../Inquiry/Form/formFields';

const {
  login,
  fields: { email, password },
} = translations.pages.loginPage;

export interface LoginFormValues {
  [EMAIL_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

interface LoginFormProps {
  onSubmit: (formValues: LoginFormValues) => void;
  isLoading?: boolean;
  forgotPasswordLink: string;
  showRegistrationLink?: boolean;
  heading: string;
  subheading: string;
}

export const LoginForm = ({
  onSubmit,
  isLoading = false,
  forgotPasswordLink,
  showRegistrationLink = false,
  heading,
  subheading,
}: LoginFormProps) => {
  const t = useTranslations();
  const { required, isEmail } = useFieldValidators();
  const initialValues = useInitialValues();

  const handleFormSubmit = (values: LoginFormValues) => {
    onSubmit(values);
  };

  return (
    <FinalForm<LoginFormValues>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validate={() => undefined}
      render={({ handleSubmit }) => (
        <FormContainer>
          <FormContainer.HeadingContainer>
            <FormContainer.Heading>{heading}</FormContainer.Heading>
            <FormContainer.SubHeading>{subheading}</FormContainer.SubHeading>
          </FormContainer.HeadingContainer>
          <FormContainer.Body>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Stack spacing={5}>
                  <InputWithField
                    name={EMAIL_FIELD}
                    preventEnterSubmit={false}
                    validate={combineValidators(required, isEmail)}
                    caption={t(email.caption)}
                  />
                  <PasswordInputWithField
                    name={PASSWORD_FIELD}
                    preventEnterSubmit={false}
                    validate={required}
                    caption={t(password.caption)}
                    autoFocus={initialValues.email}
                  />
                </Stack>
                <HStack justify="end">
                  <ChakraLink as={RouterLink} variant="primary" to={forgotPasswordLink}>
                    {t(password.forgotPassword)}
                  </ChakraLink>
                </HStack>
                <Stack spacing={6}>
                  <ButtonComponent data-testid="login-button" isLoading={isLoading} type="submit">
                    {t(login)}
                  </ButtonComponent>
                  <Condition condition={showRegistrationLink}>
                    <RegistrationLink />
                  </Condition>
                </Stack>
              </Stack>
            </form>
          </FormContainer.Body>
        </FormContainer>
      )}
    />
  );
};
