import {
  PRODUCT_KIND,
  LOAN_TYPE,
  LOAN_TERM,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_DESCRIPTION,
  LOAN_ALREADY_AT_BANK,
  LOAN_CONSIDER_SUBSIDIES,
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  PROPERTY_LOAN_DESCRIPTION,
  OVERDRAFT_TERMINABILITY,
  OVERDRAFT_END_AT,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
  FACTORING_TURNOVER_CLASS,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_COMMERCIAL_CREDIT_INSURANCE,
  FACTORING_ACTIVE_CUSTOMERS,
  FACTORING_BILLS_RANGE,
  FACTORING_ALREADY_IN_USE,
  FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
  FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON,
  FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS,
  LEASING_ADVANCE_PAYMENT,
  LEASING_TERM_IN_MONTHS,
  LEASING_AMORTISATION_KIND,
  LEASING_RESIDUAL_VALUE,
  LEASING_TAKEOVER,
  LEASING_DESCRIPTION,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  OTHER_PRODUCT_DESCRIPTION,
  MEZZANINE_DESCRIPTION,
  PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING,
  INVESTMENT_LOAN_ADVANCE_PAYMENT,
  INVESTMENT_LOAN_TERM_IN_MONTHS,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  INVESTMENT_LOAN_DESCRIPTION,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  HIRE_PURCHASE_TERM_IN_MONTHS,
  HIRE_PURCHASE_AMORTISATION_KIND,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  HIRE_PURCHASE_DESCRIPTION,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  FAVORED_DECISION_DATE,
  SECURITIES_TYPE,
  SECURITIES_VALUE_ESTIMATION,
  SECURITIES_DESCRIPTION,
  INTERESTED_IN_INSURANCE,
  BANK_DETAILS_IBAN,
} from 'modules/Inquiry/Form/formFields';
import {
  MAX_INTEGER_VALUE,
  MAX_DESCRIPTION_LENGTH,
  MAX_AMOUNT_VALUE,
} from 'modules/Inquiry/inquiry.constants';
import {
  fieldValidators,
  customValidations,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const contractAndOfferRequestDetailsFieldValidations = {
  [LOAN_TERM]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .min(InquiryConstants.CONTRACT_AND_OFFER_MIN_LOAN_DURATION)
    .max(InquiryConstants.CONTRACT_AND_OFFER_MAX_LOAN_DURATION),
  [BANK_DETAILS_IBAN]: fieldValidators.bank().isValidIban().isGermanIban().required(),
};

export const requestDetailsFieldValidations = {
  [PRODUCT_KIND]: fieldValidators.string().required(),
  [LOAN_TYPE]: fieldValidators.string().required(),
  [LOAN_TERM]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(InquiryConstants.MAX_LOAN_DURATION),
  [LOAN_FIXED_INTEREST_RATE]: fieldValidators.string().required(),
  [LOAN_INTEREST_PERIOD_IN_MONTHS]: (fieldValue: any, allFields: any) =>
    fieldValidators
      .number()
      .required()
      .positive()
      .integer()
      .max(MAX_INTEGER_VALUE)
      .custom(customValidations.notHigherThanLoanTerm(allFields[LOAN_TERM])),
  [LOAN_DESCRIPTION]: fieldValidators.string().max(MAX_DESCRIPTION_LENGTH),
  [LOAN_ALREADY_AT_BANK]: fieldValidators.boolean().required(),
  [LOAN_CONSIDER_SUBSIDIES]: fieldValidators.boolean().required(),
  [INTERESTED_IN_INSURANCE]: fieldValidators.boolean().required(),
  [VEHICLE_INTERESTED_IN_INSURANCE]: fieldValidators.boolean().required(),
  [MACHINE_INTERESTED_IN_INSURANCE]: fieldValidators.boolean().required(),
  [PROPERTY_LOAN_KIND]: fieldValidators.string().required(),
  [PROPERTY_LOAN_TERM_IN_MONTHS]: fieldValidators
    .number()
    .max(InquiryConstants.PROPERTY_LOAN_TERM_IN_MONTHS_MAX_LENGTH),
  [PROPERTY_LOAN_DESCRIPTION]: fieldValidators.string().required(),
  [OVERDRAFT_TERMINABILITY]: fieldValidators.boolean().required(),
  [OVERDRAFT_END_AT]: fieldValidators.date().required().min(InquiryConstants.CURRENT_YEAR),
  [OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING]: fieldValidators.boolean().required(),
  [FACTORING_TURNOVER_CLASS]: fieldValidators.string().required(),
  [FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: fieldValidators.string().required(),
  [FACTORING_FOREIGN_SHARE]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [FACTORING_AVERAGE_PERIOD_OF_PAYMENT]: fieldValidators.string().required(),
  [FACTORING_COMMERCIAL_CREDIT_INSURANCE]: fieldValidators.boolean().required(),
  [FACTORING_ACTIVE_CUSTOMERS]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(MAX_INTEGER_VALUE),
  [FACTORING_BILLS_RANGE]: fieldValidators.string().required(),
  [FACTORING_ALREADY_IN_USE]: fieldValidators.boolean().required(),
  [FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY]: fieldValidators.boolean().required(),
  [FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON]: fieldValidators
    .string()
    .max(InquiryConstants.MAX_INFORMATION_FOR_COMPEON_LENGTH),
  [FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS]: fieldValidators
    .string()
    .max(InquiryConstants.MAX_ADDITIONAL_INFO_LENGTH),
  [LEASING_ADVANCE_PAYMENT]: fieldValidators.price().min(0).integer().maxPrice(MAX_AMOUNT_VALUE),
  [LEASING_TERM_IN_MONTHS]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(InquiryConstants.MAX_LEASING_TERM_IN_MONTHS),
  [LEASING_AMORTISATION_KIND]: fieldValidators.string().required(),
  [LEASING_RESIDUAL_VALUE]: fieldValidators
    .number()
    .required()
    .positive()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [LEASING_TAKEOVER]: fieldValidators.string().required(),
  [LEASING_DESCRIPTION]: fieldValidators.string().max(MAX_DESCRIPTION_LENGTH),
  [LEASING_INTERESTED_IN_CREDIT_FINANCING]: fieldValidators.boolean().required(),
  [OTHER_PRODUCT_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [MEZZANINE_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING]: fieldValidators.boolean().required(),
  [INVESTMENT_LOAN_ADVANCE_PAYMENT]: fieldValidators.price().max(MAX_AMOUNT_VALUE),
  [INVESTMENT_LOAN_TERM_IN_MONTHS]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(InquiryConstants.INVESTMENT_LOAN_MAX_TERM_IN_MONTHS),
  [INVESTMENT_LOAN_AMORTISATION_KIND]: fieldValidators.string().required(),
  [INVESTMENT_LOAN_RESIDUAL_VALUE]: fieldValidators
    .number()
    .required()
    .positive()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [INVESTMENT_LOAN_DESCRIPTION]: fieldValidators.string().max(MAX_DESCRIPTION_LENGTH),
  [INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS]: fieldValidators.boolean().required(),
  [HIRE_PURCHASE_ADVANCE_PAYMENT]: fieldValidators.price().max(MAX_AMOUNT_VALUE),
  [HIRE_PURCHASE_TERM_IN_MONTHS]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(InquiryConstants.HIRE_PURCHASE_MAX_TERMS_IN_MONTHS),
  [HIRE_PURCHASE_AMORTISATION_KIND]: fieldValidators.string().required(),
  [HIRE_PURCHASE_RESIDUAL_VALUE]: fieldValidators
    .number()
    .required()
    .positive()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [HIRE_PURCHASE_DESCRIPTION]: fieldValidators.string().max(MAX_DESCRIPTION_LENGTH),
  [HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN]: fieldValidators.boolean().required(),
  [FAVORED_DECISION_DATE]: fieldValidators.date().required(),
  [SECURITIES_TYPE]: fieldValidators.string().required(),
  [SECURITIES_VALUE_ESTIMATION]: fieldValidators
    .price()
    .required()
    .positive()
    .max(MAX_AMOUNT_VALUE),
  [SECURITIES_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
};

export const bfsServiceRequestDetailsFieldValidations = {
  [BANK_DETAILS_IBAN]: fieldValidators.bank().isValidIban().isGermanIban().optional(),
};
