import React from 'react';

import { isEqual, sortBy } from 'lodash';

import { API_METHODS, callReverseApi } from 'api/apiService';
import { LOAD_COMPANIES } from 'api/CompeonReverseApi/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_TURNOVER_CLASS,
} from 'modules/Inquiry/Form/formFields';
import CompanyLabel from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyLabel';

export const loadCompaniesAction = () =>
  callReverseApi({
    url: endpoints.COMPANIES.LIST.compose(),
    method: API_METHODS.GET,
    actionName: LOAD_COMPANIES,
  });

export const loadCustomerCompaniesAction = (customerId) =>
  callReverseApi({
    url: endpoints.CUSTOMERS.COMPANIES.compose({ params: { id: customerId } }),
    method: API_METHODS.GET,
    actionName: LOAD_COMPANIES,
  });

export const hasCompanyChanged = (originalCompanyDetails, newCompanyDetails) => {
  const newCompanyData = newCompanyDetails
    ? {
        [COMPANY_DETAILS_COMPANY]: newCompanyDetails[COMPANY_DETAILS_COMPANY],
        [COMPANY_DETAILS_EXISTING_USER_COMPANY]:
          newCompanyDetails[COMPANY_DETAILS_EXISTING_USER_COMPANY],
      }
    : undefined;

  const originalCompanyData = originalCompanyDetails
    ? {
        [COMPANY_DETAILS_COMPANY]: originalCompanyDetails[COMPANY_DETAILS_COMPANY],
        [COMPANY_DETAILS_EXISTING_USER_COMPANY]:
          originalCompanyDetails[COMPANY_DETAILS_EXISTING_USER_COMPANY],
      }
    : undefined;

  return !isEqual(newCompanyData, originalCompanyData);
};

export const getUserProfileCleanedUpFromCompanyData = (currentUserProfile) => {
  const userProfile = currentUserProfile ? { ...currentUserProfile } : {};
  delete userProfile[USER_PROFILE_INDUSTRY];
  delete userProfile[USER_PROFILE_FOUNDING_YEAR];
  delete userProfile[USER_PROFILE_LEGAL_FORM];
  delete userProfile[USER_PROFILE_TURNOVER_CLASS];
  return userProfile;
};

export const mapCompaniesToSelectOptions = (companies) => {
  const options = companies.map(
    ({ id, attributes, attributes: { name, 'zip-code': zipCode, city } }) => ({
      complexLabel: (
        <CompanyLabel>
          <div>{name}</div>
          <div>{`${zipCode} ${city}`}</div>
        </CompanyLabel>
      ),
      label: name,
      value: { id, ...attributes },
    }),
  );

  return sortBy(options, ({ label }) => label && label.toLowerCase());
};
