import { isNil } from 'lodash';

import { useTranslations } from 'utils/hooks/useTranslations';

import type { ObjectImageType } from '../hooks/usePropertyProfile';
import type { ObjectImage } from '../pages/PropertyProfile/UploadObjectImages';
import type { MappingProps, TableStyleProps, Unit } from '../types';

export const getRowStylesMap = <T>(
  mapping: MappingProps<T>,
): Partial<Record<keyof TableStyleProps, Array<number>>> => {
  return {
    bold: mapping.map(({ styles }, i) => (styles?.bold ? i : -1)).filter((e) => e > -1),
    indented: mapping.map(({ styles }, i) => (styles?.indented ? i : -1)).filter((e) => e > -1),
    highlighted: mapping
      .map(({ styles }, i) => (styles?.highlighted ? i : -1))
      .filter((e) => e > -1),
    italic: mapping.map(({ styles }, i) => (styles?.italic ? i : -1)).filter((e) => e > -1),
  };
};

export const formatCurrency = (value: unknown) => {
  return value ? Number(value).toLocaleString('de-DE', { minimumFractionDigits: 2 }) : '-';
};

export const useUnitFormatter = (): Record<Unit, (value: unknown) => string> => {
  const t = useTranslations('pages.planningEvaluation.mittweida.common.units');

  return {
    currency: (value) => (!isNil(value) ? `${formatCurrency(value)} ${t('currency')}` : ''),
    percent: (value) => (!isNil(value) ? `${String(value).replace('.', ',')} ${t('percent')}` : ''),
    sqm: (value) => (!isNil(value) ? `${value} ${t('sqm')}` : ''),
    months: (value) => (!isNil(value) ? `${value} ${t('months', { n: value })}` : ''),
    units: (value) => (!isNil(value) ? `${value} ${t('units', { n: value })}` : ''),
    places: (value) => (!isNil(value) ? `${value} ${t('places', { n: value })}` : ''),
    none: (value) => (!isNil(value) ? String(value) : ''),
  };
};
interface ApiObjectImage {
  id: string;
  type: string;
  attributes: {
    url: string;
    filename: string;
  };
}
export const mapObjectImageFromApi = (objectImage: ApiObjectImage): ObjectImage => ({
  id: objectImage.id,
  url: objectImage.attributes.url,
  imageType: objectImage.attributes.filename as ObjectImageType,
});
