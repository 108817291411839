import { useEffect } from 'react';

import { IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';

import { getAccessToken } from 'utils/auth';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';

const WS_URL = getEnvironmentFeatureFlag(EnvironmentVariable.WS_URL);

export enum Channel {
  INQUIRY = 'InquiryChannel',
  UPLOADABLE_FILE = 'UploadableFileChannel',
  SIGNATURE_PROCESS = 'SignatureProcessChannel',
}

interface WelcomeMessage {
  type: 'welcome';
}

interface PingMessage {
  type: 'ping';
  message: number;
}

interface ConfirmSubscriptionMessage {
  type: 'confirm_subscription';
  identifier: string;
}

export interface UploadedFilesMessage {
  identifier: string;
  message: {
    data: {
      id: string;
      type: 'uploaded_files';
      links: {
        show: string;
      };
    };
  };
}

export interface PrivateUploadedFilesMessage {
  identifier: string;
  message: {
    data: {
      id: string;
      type: 'private_uploaded_files';
      links: {
        show: string;
      };
    };
  };
}

export type WebSocketMessage =
  | WelcomeMessage
  | PingMessage
  | ConfirmSubscriptionMessage
  | UploadedFilesMessage
  | PrivateUploadedFilesMessage;

type OnMessageCallback = (message: IMessageEvent) => void;

interface WebSocketProps {
  url?: string;
  params?: Record<string, unknown>;
  channel: Channel | Channel[];
  onMessage?: OnMessageCallback;
  onOpen?: () => void;
}

const subscribeMessage = (channel: Channel, customProps: any) => {
  return JSON.stringify({
    command: 'subscribe',
    identifier: JSON.stringify({
      channel,
      ...customProps,
    }),
  });
};

export const useWebSocket = (
  { url, channel, onMessage, params }: WebSocketProps = { channel: Channel.INQUIRY },
) => {
  const userToken = getAccessToken();

  useEffect(
    () => {
      const socketService = url ?? WS_URL;
      const socketUrl = `${socketService}?token=${userToken}`;
      if (!socketService) return;

      const client = new W3CWebSocket(socketUrl);

      client.onopen = () => {
        if (Array.isArray(channel)) {
          channel.forEach((c) => {
            client.send(subscribeMessage(c, params));
          });
        } else {
          client.send(subscribeMessage(channel, params));
        }
      };

      client.onmessage = (message: IMessageEvent) => {
        if (onMessage) {
          onMessage(message);
        }
      };

      return () => {
        client.close();
      };
    },
    // we want to re-run this effect only when the userToken changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userToken],
  );
};
