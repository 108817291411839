import { IInquiryListElement } from 'models/InquiryList.model';
import { INQUIRY_SUBSTATUSES, isInquirySubstatus } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { useInquiryTableHeaders } from 'pages/operationPortal/OperationInquiryList/useInquiryTableHeaders';

import { InquiryType } from '../../../modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from '../../../shared/chooseSelectedInquiryTypeSpecificComponent';

// Show certain substatuses as statuses for CP
// This is business requirement
// More in CRV-1339
export const showSomeSubStatusesForInquiries = (inquiry: IInquiryListElement) => {
  const substatusesToShow = [
    INQUIRY_SUBSTATUSES.WITH_BANK_OFFERS,
    INQUIRY_SUBSTATUSES.WITH_COMPEON_OFFERS,
    INQUIRY_SUBSTATUSES.WITH_OFFER_INTERESTED_IN,
  ];
  if (isInquirySubstatus(inquiry.substatus) && substatusesToShow.includes(inquiry.substatus)) {
    return { ...inquiry, status: inquiry.substatus };
  }
  return inquiry;
};

const DEFAULT_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
  INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT,
  INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  INQUIRY_TABLE_HEADERS.STATUS,
];

const MITTWEIDA_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
  INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.SUB_STATUS,
];

const LEASEPLAN_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE,
  INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER,
  INQUIRY_TABLE_HEADERS.VEHICLE_DRIVE_TYPE,
  INQUIRY_TABLE_HEADERS.STATUS,
];

const PROFI_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
  INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
  INQUIRY_TABLE_HEADERS.SUB_STATUS,
];

const MMV_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER,
  INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION,
  INQUIRY_TABLE_HEADERS.OBJECT_VALUE,
  INQUIRY_TABLE_HEADERS.SUPPLIER,
  INQUIRY_TABLE_HEADERS.STATUS,
];

const DZB_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.ASSOCIATION_NAME,
  INQUIRY_TABLE_HEADERS.STATUS,
];

const HAUSBANK_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_NAME,
  INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_CITY,
  INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_ZIPCODE,
  INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  INQUIRY_TABLE_HEADERS.STATUS,
];

export const useCustomerInquiryTableHeaders = () => {
  const tableHeaders = useInquiryTableHeaders();
  const headersForPartner: Array<string> = useSelectedInquiryTypeSpecificValue({
    [InquiryType.leaseplan]: LEASEPLAN_TABLE_HEADERS,
    [InquiryType.profiRLL]: PROFI_TABLE_HEADERS,
    [InquiryType.mmv]: MMV_TABLE_HEADERS,
    [InquiryType.profiMittweida]: MITTWEIDA_TABLE_HEADERS,
    [InquiryType.dzb]: DZB_TABLE_HEADERS,
    [InquiryType.hausbank]: HAUSBANK_TABLE_HEADERS,
    default: DEFAULT_TABLE_HEADERS,
  });

  return headersForPartner.map((header) => tableHeaders[header]);
};
