import { getCompeonInquiryAction } from 'store/inquiryDetails/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useGetCompeonInquiry = () => {
  const { makeCall } = useDispatchApiCall();

  return async (compeonInquiryId: string) => {
    const { error, payload } = await makeCall(getCompeonInquiryAction(compeonInquiryId));
    return { error, payload };
  };
};
