import React from 'react';

import { INVESTMENT_LOAN_ADVANCE_PAYMENT } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';

const InvestmentLoanAdvancePayment = () => {
  return <AmountWithField name={INVESTMENT_LOAN_ADVANCE_PAYMENT} optional />;
};

export default InvestmentLoanAdvancePayment;
