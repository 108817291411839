import * as React from 'react';

import { Asset } from 'components/Asset';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinalPageAction } from './FinalPageAction';

const IndicativeOfferAction = () => {
  const t = useTranslations('pages.finalPage.indicativeOffer');

  return (
    <FinalPageAction asset={<Asset type="inquiry" value={InquiryAssetKeys.INDICATIVE_OFFER} />}>
      <HeadingComponent as="h3" variant="secondary" mb={4}>
        {t('heading')}
      </HeadingComponent>
      <TextComponent mb={6}>{t('description')}</TextComponent>
    </FinalPageAction>
  );
};

export default IndicativeOfferAction;
