import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { FurnishingQuality } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getMittweidaFurnishingQualityOptions = (
  formatMessage: FormatMessage,
): SelectOption<FurnishingQuality>[] => {
  const { simple, normal, good, veryGood, highQuality } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields
      .furnishingQuality.options;

  const options: Array<{ id: string; value: FurnishingQuality }> = [
    {
      id: simple,
      value: 'simple',
    },
    {
      id: normal,
      value: 'normal',
    },
    {
      id: good,
      value: 'good',
    },
    {
      id: veryGood,
      value: 'high',
    },
    {
      id: highQuality,
      value: 'luxury',
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
