import * as React from 'react';
import { useState } from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ConfirmObjectTurnoverStepModal } from './ConfirmObjectTurnoverStepModal';

const getDescriptionKey = (
  isPending: boolean,
  requireUserAction: boolean,
  isCompleted: boolean,
) => {
  if (isPending) {
    return 'description.pending';
  }
  if (requireUserAction) {
    return 'description.requireUserAction';
  }
  if (isCompleted) {
    return 'description.completed';
  }
  return 'description.default';
};

interface ConfirmObjectTurnoverStepData {
  inquiryId: string;
  isPending: boolean;
  requireUserAction: boolean;
}

interface ConfirmObjectTurnoverStepProps {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  data: ConfirmObjectTurnoverStepData;
}

export const ConfirmObjectTurnoverStep = ({
  isFirst,
  isLast,
  completedValue,
  progressIncrement,
  progressTotal,
  data,
  id,
}: ConfirmObjectTurnoverStepProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.confirmObjectTurnover');

  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const handleClose = (isSuccessful?: boolean) => {
    setIsOpen(false);
    if (isSuccessful) {
      // reload the page
      history.go(0);
    }
  };
  const handleOpen = () => setIsOpen(true);

  // isCompleted prop tell whenever the whole inquiry is completed
  // not a specific section, so we need to check for it manually
  const isCompleted = completedValue === 100;

  return (
    <>
      <ProgressSection
        isFirst={isFirst}
        isLast={isLast}
        isCompleted={isCompleted}
        completedValue={completedValue}
        progressIncrement={progressIncrement}
        progressTotal={progressTotal}
        id={id}
      >
        <Grid templateColumns={['1fr', null, '2fr 1fr']} gap={12}>
          <GridItem>
            <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
              {t('title')}
            </HeadingComponent>
            <TextComponent mb="xl">
              {t(getDescriptionKey(data.isPending, data.requireUserAction, isCompleted))}
            </TextComponent>
            {!isCompleted ? (
              <ButtonComponent
                onClick={handleOpen}
                leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                variant="primary"
                isDisabled={data.isPending}
              >
                {t('action')}
              </ButtonComponent>
            ) : null}
          </GridItem>

          <GridItem>
            <Center height="100%">
              <Asset
                htmlHeight="200"
                type="dashboard"
                value={ProgressSectionType.CONFIRM_OBJECT_TAKEOVER}
              />
            </Center>
          </GridItem>
        </Grid>
      </ProgressSection>
      {!isCompleted && data.requireUserAction ? (
        <ConfirmObjectTurnoverStepModal
          inquiryId={data.inquiryId}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </>
  );
};
