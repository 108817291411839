import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

import { LendingValueData } from './types';

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.lendingValueDetermination.tableHeaders.${key}`,
    );

  return [
    {
      key: 'type',
      text: translate('type'),
    },
    {
      key: 'salesPrice',
      text: translate('salesPrice'),
    },
    {
      key: 'markdown',
      text: translate('markdown'),
    },
    {
      key: 'value',
      text: translate('value'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  { baseValues, markdown, salesPrice, value }: LendingValueData,
) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.saleAsBuilding.lendingValueDetermination.tableRows.${key}`,
    );
  const currencyUnit = t(`other.defaultCurrency`);

  return [
    {
      markdown: formatDecimal(markdown.residential, { unit: currencyUnit }),
      salesPrice: formatDecimal(salesPrice.residential, { unit: currencyUnit }),
      type: translate('residential'),
      value: formatDecimal(value.residential, { unit: currencyUnit }),
    },
    {
      markdown: formatDecimal(markdown.commercial, { unit: currencyUnit }),
      salesPrice: formatDecimal(salesPrice.commercial, { unit: currencyUnit }),
      type: translate('commercial'),
      value: formatDecimal(value.commercial, { unit: currencyUnit }),
    },
    {
      markdown: formatDecimal(markdown.parking, { unit: currencyUnit }),
      salesPrice: formatDecimal(salesPrice.parking, { unit: currencyUnit }),
      type: translate('parking'),
      value: formatDecimal(value.parking, { unit: currencyUnit }),
    },
    {
      markdown: formatDecimal(markdown.garage, { unit: currencyUnit }),
      salesPrice: formatDecimal(salesPrice.garage, { unit: currencyUnit }),
      type: translate('garage'),
      value: formatDecimal(value.garage, { unit: currencyUnit }),
    },
    {
      type: translate('materialValue'),
      value: formatDecimal(value.materialValue, { unit: currencyUnit }),
    },
    {
      type: translate('extraMarkdown'),
      value: formatDecimal(value.extraMarkdown, { unit: currencyUnit }),
    },
    {
      type: translate('roundingMarkdown'),
      value: formatDecimal(value.roundingMarkdown, { unit: currencyUnit }),
    },
    {
      type: translate('lendingValue'),
      value: formatDecimal(value.lendingValue, { unit: currencyUnit }),
    },
    {
      type: translate('lending80'),
      value: formatDecimal(value.lending80, { unit: currencyUnit }),
    },
    {
      type: translate('lending70'),
      value: formatDecimal(value.lending70, { unit: currencyUnit }),
    },
    {
      type: translate('lending60'),
      value: formatDecimal(value.lending60, { unit: currencyUnit }),
    },
    {
      type: translate('unsecuredPortionResidential'),
      value: formatDecimal(value.unsecuredPortionResidential, { unit: currencyUnit }),
    },
    {
      type: translate('unsecuredPortionMixed'),
      value: formatDecimal(value.unsecuredPortionMixed, { unit: currencyUnit }),
    },
    {
      type: translate('unsecuredPortionCommercial'),
      value: formatDecimal(value.unsecuredPortionCommercial, { unit: currencyUnit }),
    },
  ];
};
