import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ProjectentwicklungIcon = (props: IconProps) => {
  return (
    <Icon
      width="39"
      height="39"
      viewBox="0 0 39 39"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#000" stroke-miterlimit="10">
        <g stroke-linecap="round" stroke-linejoin="round">
          <path d="M37.7 5.4v30c0 1.7-1.4 3.1-3.2 3.2h-24c-1.7 0-3.1-1.4-3.2-3.2V3.7C7.3 2 8.7.6 10.5.5H33l4.7 4.9z" />
          <path d="M32.3.5v5.4h5" />
        </g>
        <path d="M.5 5.2h23l3.9 1.9L23.5 9H.5z" />
        <path d="M4 4.8v6.1h6.1M15.6 9V5.1m7.5-.3v3.8" />
        <g>
          <path d="M11.2 13.1l4.1 3.9 18.5 18.1H11.2z" />
          <path d="M15.3 24.2l7.1 6.8h-7.1zm-1.2-6.1l1.2-1.2m.6 2.9l1.2-1.1m.5 2.9l1.2-1.2m.6 3l1.2-1.2m.6 2.9l1.1-1.2m.6 3l1.2-1.2m.6 3l1.1-1.2m.7 2.9l1.1-1.2m.7 3l1.1-1.2" />
        </g>
      </g>
    </Icon>
  );
};

export default ProjectentwicklungIcon;
