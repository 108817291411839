import { HausbankFieldTypes } from 'hausbank/inquiry/fields';

type HausbankFinancingNeedFieldTypes =
  HausbankFieldTypes['financingNeedPage']['financingNeedSection'];

export const mapHausbankFinancingNeed = (values: HausbankFinancingNeedFieldTypes) => ({
  investment_amount: values.investmentAmount,
  financing_amount: values.financingAmount,
  loan_term: values.loanTermInYears,
  date_of_owners_meeting: values.ownersMeetingDate,
  reserved_for_maintenance: values.maintenanceReserve,
  contribution_of_home_association: values.wegContribution,
  construction_subsidies: values.constructionSubsidies,
});
