import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const PhoneIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M14.53 23.46a5.8 5.8 0 01-3.17-.94 33.83 33.83 0 01-9.88-9.88 5.84 5.84 0 01.71-7.24v-.05l.4-.4a2.14 2.14 0 013 0L8 7.32a2.12 2.12 0 010 3l-1.07 1.06a23.3 23.3 0 005.69 5.69L13.69 16a2.12 2.12 0 013 0l2.37 2.37a2.14 2.14 0 010 3l-.35.35a5.8 5.8 0 01-4.18 1.74zM3.67 6.74a3.83 3.83 0 00-.48 4.77 31.47 31.47 0 009.3 9.3 3.83 3.83 0 004.71-.42l.06-.08.09-.07.31-.32a.12.12 0 000-.16l-2.36-2.37a.12.12 0 00-.17 0l-1.65 1.65a1 1 0 01-1.25.13 25.34 25.34 0 01-7.4-7.4 1 1 0 01.13-1.25L6.58 8.9a.12.12 0 000-.17L4.21 6.37a.1.1 0 00-.16 0zM15.5 13a1 1 0 01-1-1A2.49 2.49 0 0012 9.5a1 1 0 010-2 4.51 4.51 0 014.5 4.5 1 1 0 01-1 1z" />
      <path d="M18.5 13a1 1 0 01-1-1A5.5 5.5 0 0012 6.5a1 1 0 010-2 7.51 7.51 0 017.5 7.5 1 1 0 01-1 1z" />
      <path d="M21.5 13a1 1 0 01-1-1A8.5 8.5 0 0012 3.5a1 1 0 010-2A10.52 10.52 0 0122.5 12a1 1 0 01-1 1z" />
    </Icon>
  );
};

export default PhoneIcon;
