import React from 'react';

import { useHistory } from 'react-router-dom';

import { useLogin } from 'api/AuthApi/queryHooks/auth';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { LoginForm, type LoginFormValues } from 'modules/Login/LoginForm';
import { useLoginRedirect } from 'modules/Login/useLoginRedirect';
import { translations } from 'new/form/common/types';
import { AccessTokenScope } from 'types/Auth';
import { useTranslations } from 'utils/hooks/useTranslations';
import { setStorageExpiration } from 'utils/sessionStorage/expiration';
import { userIsAgent } from 'utils/user/conditionals';

const { operationHeading, subheading } = translations.pages.loginPage;
const { inquiryList, passwordReset } = paths.operation;

export const OperationLogin = () => {
  const t = useTranslations();
  const { mutate: logIn, isLoading } = useLogin(loginSuccessHandler);
  const afterLoginUrl = useLoginRedirect(inquiryList);
  const history = useHistory();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  function loginSuccessHandler() {
    if (userIsAgent()) {
      history.push(afterLoginUrl);
    }
  }

  const handleLogin = async ({ email, password }: LoginFormValues) => {
    setStorageExpiration();
    logIn({ email, password, scope: AccessTokenScope.portal_user });
  };

  return (
    <LoginForm
      onSubmit={handleLogin}
      heading={t(operationHeading, { partnerName })}
      subheading={t(subheading)}
      isLoading={isLoading}
      forgotPasswordLink={passwordReset.request}
    />
  );
};
