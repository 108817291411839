import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import {
  StyledClearIcon,
  StyledInputIcon,
  StyledInputWrapper,
} from 'components/PortalFormFields/InputFields/styles';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledSpinner } from './styles';

const AutoCompleteInput = ({
  inputProps,
  onIconClick,
  placeholder,
  symbol,
  onClear,
  readOnly,
  loading,
  onBlur,
  testId,
}) => {
  const { value } = inputProps;
  const shouldShowRemoveIcon = !value || (value && !value.trim().length);

  const inputRef = useRef();
  const handleIconClick = () => {
    inputRef.current.focus();
    onIconClick();
  };

  const t = useTranslations();

  return (
    <StyledInputWrapper hasError={false}>
      <input
        ref={inputRef}
        placeholder={loading ? '' : placeholder}
        {...inputProps}
        readOnly={!!readOnly}
        autoComplete="off"
        onBlur={onBlur}
        data-testid={testId}
      />
      {loading && <StyledSpinner />}
      <StyledClearIcon
        hidden={shouldShowRemoveIcon}
        onClick={onClear}
        type="button"
        data-testid="close-button"
        aria-label={t('buttons.delete')}
      >
        <CloseIcon boxSize={6} />
      </StyledClearIcon>
      <StyledInputIcon onClick={handleIconClick}>{symbol}</StyledInputIcon>
    </StyledInputWrapper>
  );
};

AutoCompleteInput.defaultProps = {
  placeholder: '',
  symbol: null,
  onClear: () => null,
  onBlur: () => null,
  loading: false,
  onIconClick: () => null,
  testId: '',
};

AutoCompleteInput.propTypes = {
  inputProps: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  placeholder: PropTypes.string,
  symbol: PropTypes.node,
  onClear: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onIconClick: PropTypes.func,
  testId: PropTypes.string,
};

export default AutoCompleteInput;
