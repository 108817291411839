import React from 'react';

import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components/macro';

import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

// Very similar to the HiddenInput component, but this one is rendered invisible instead of not being rendered at all which allows for it to be scrolled to

const RenderedHiddenInput = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
`;

const RenderedHiddenInputAdapter = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, HTMLInputElement>) => (
  <RenderedHiddenInput {...input} {...meta} {...rest} />
);

RenderedHiddenInputAdapter.propTypes = FinalFormFieldPropTypes;
export const RenderedHiddenInputWithField = withFormField(RenderedHiddenInputAdapter);

export default RenderedHiddenInput;
