import { useCallback } from 'react';

import { MAX_CONCURRENT_UPLOADS } from 'constants/file';

type RequestToMake = () => Promise<void>;
// I found this snippet here https://blog.logrocket.com/throttling-data-requests-with-react-hooks/
export const useThrottleRequests = () =>
  useCallback(
    async (requestsToMake: Array<RequestToMake>, maxParallelRequests = MAX_CONCURRENT_UPLOADS) => {
      // queue up simultaneous calls
      const queue: Array<Promise<void>> = [];
      for (const requestToMake of requestsToMake) {
        // fire the async function, add its promise to the queue,
        // and remove it from queue when complete
        const promise = requestToMake().then((res) => {
          queue.splice(queue.indexOf(promise), 1);
          return res;
        });
        queue.push(promise);

        // if the number of queued requests matches our limit then
        // wait for one to finish before enqueueing more
        if (queue.length >= maxParallelRequests) {
          await Promise.race(queue);
        }
      }
      // wait for the rest of the calls to finish
      await Promise.all(queue);
    },
    [],
  );
