import { summaryFieldUnits as units } from 'new/form/formatters/units';
import { SummaryFieldsType } from 'new/form/model';

import { HausbankFieldTypes } from '../fields';

export const hausbankSummaryFields: SummaryFieldsType<HausbankFieldTypes> = {
  objectInformationPage: {
    objectInformationSection: {
      wegName: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      street: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      zipCode: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      city: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      country: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      livingSpace: {
        unit: units.squareMeters,
        showOnSummaryPage: true,
      },
      commercialUsage: {
        unit: units.squareMeters,
        showOnSummaryPage: true,
      },
      propertySize: {
        unit: units.squareMeters,
        showOnSummaryPage: true,
      },
      constructionYear: {
        unit: units.year,
        showOnSummaryPage: true,
      },
      lastModernization: {
        unit: units.year,
        showOnSummaryPage: true,
        showEmpty: true,
      },
    },
    projectInformationSection: {
      residentialUnitsCount: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      commercialUnitsCount: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      hasOwnerWithMoreThanThirdOfShares: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      isContractor: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      majorityOwnerShares: {
        unit: units.percentage,
        showOnSummaryPage: true,
      },
      wegIsSubCommunity: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      propertyIsLeasehold: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      hasMajorityDecision: {
        unit: units.none,
        showOnSummaryPage: true,
      },
    },
  },
  companyDataPage: {
    wegSearchSection: {
      crefoId: {
        unit: units.none,
        showOnSummaryPage: false,
      },
      selectedCompanyName: {
        unit: units.none,
        showOnSummaryPage: false,
      },
      loggedInUserCompanyName: {
        unit: units.none,
        showOnSummaryPage: false,
      },
    },
    wegDataCompletionSection: {
      name: {
        unit: units.none,
        showOnSummaryPage: true,
        order: 1,
      },
      street: {
        unit: units.none,
        showOnSummaryPage: true,
        order: 2,
      },
      city: {
        unit: units.none,
        showOnSummaryPage: true,
        order: 3,
      },
      zipCode: {
        unit: units.none,
        showOnSummaryPage: true,
        order: 4,
      },
      legalForm: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      managedUnits: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      foundingYear: {
        unit: units.year,
        showOnSummaryPage: true,
      },
      isExistingCustomer: {
        unit: units.none,
        showOnSummaryPage: true,
      },
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: {
        unit: units.none,
        showOnSummaryPage: false,
      },
      privacyPolicyAccepted: {
        unit: units.none,
        showOnSummaryPage: false,
      },
    },
  },
  financingNeedPage: {
    financingNeedSection: {
      investmentAmount: {
        unit: units.currency,
        showOnSummaryPage: true,
      },
      maintenanceReserve: {
        unit: units.currency,
        showOnSummaryPage: true,
      },
      wegContribution: {
        unit: units.currency,
        showOnSummaryPage: true,
      },
      constructionSubsidies: {
        unit: units.currency,
        showOnSummaryPage: true,
      },
      financingAmount: {
        unit: units.currency,
        showOnSummaryPage: true,
      },
      loanTermInYears: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      ownersMeetingDate: {
        unit: units.date,
        showOnSummaryPage: true,
      },
    },
    purposeSection: {
      purposeKind: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      projectDescription: {
        unit: units.none,
        showOnSummaryPage: true,
      },
    },
  },
  personalDataPage: {
    contactPersonSection: {
      salutation: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      firstName: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      lastName: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      email: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      phoneNumber: {
        unit: units.none,
        showOnSummaryPage: true,
      },
    },
    legalRepresentativesSection: {
      legalRepresentatives: {
        id: {
          unit: units.none,
          showOnSummaryPage: false,
        },
        salutation: {
          unit: units.none,
          showOnSummaryPage: true,
        },
        firstName: {
          unit: units.none,
          showOnSummaryPage: true,
        },
        lastName: {
          unit: units.none,
          showOnSummaryPage: true,
        },
        birthDate: {
          unit: units.date,
          showOnSummaryPage: true,
        },
        email: {
          unit: units.none,
          showOnSummaryPage: true,
        },
        phoneNumber: {
          unit: units.none,
          showOnSummaryPage: true,
        },
        soleSignatureAuthorized: {
          unit: units.none,
          showOnSummaryPage: true,
        },
      },
    },
    passwordSection: {
      password: {
        unit: units.none,
        showOnSummaryPage: false,
      },
      passwordConfirmation: {
        unit: units.none,
        showOnSummaryPage: false,
      },
    },
  },
};
