import { useSelector, useDispatch } from 'react-redux';

import { updateInquiryAction } from 'pages/inquiryFlow/SummaryPage/summaryPage.service';
import { useGetRedirectUrlAfterInquirySending } from 'pages/inquiryFlow/SummaryPage/useGetRedirectUrlAfterInquirySending';
import {
  mapInquiryDetailsApiResponseAction,
  mapInquiryEditApiResponseAction,
} from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { clearLeadId as clearLeadIdAction } from 'store/inquiryProcess/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useSendInquiryUpdateToApi = () => {
  const { makeCall } = useDispatchApiCall();
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const redirectAfterSending = useGetRedirectUrlAfterInquirySending();

  return async (inquiryPayload: any) => {
    if (!inquiryId) {
      throw Error('No inquiry ID to update');
    }

    const response = await makeCall(updateInquiryAction(inquiryId, inquiryPayload));
    if (!response.error) {
      dispatch(clearLeadIdAction());
      dispatch(mapInquiryDetailsApiResponseAction(response.payload.data));
      dispatch(mapInquiryEditApiResponseAction(response.payload.data));
    }
    redirectAfterSending(response);
  };
};
