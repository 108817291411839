import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const AnhaengerIcon = (props: IconProps) => {
  return (
    <Icon
      width="46"
      height="24"
      viewBox="0 0 45.33 23.75"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M18.69 15.28a3 3 0 00-3 3v.06a3 3 0 006 0v-.06a3 3 0 00-3-3zm1.92 3.09a1.92 1.92 0 01-3.83 0v-.06a1.89 1.89 0 01.32-1 1.92 1.92 0 013.21 0 1.89 1.89 0 01.32 1s-.02.04-.02.06z" />
      <path d="M7.24 0H2.82C1.77 0 0 0 0 1.51v3.08C0 5.54.9 6 2.67 6.06a3.91 3.91 0 00-.31 1.75v7.71c0 2.48 1.64 2.85 2.58 2.85h8.26a5.5 5.5 0 0011 0h8.26a2.35 2.35 0 002.54-2.3h9.79a.55.55 0 000-1.1h-2v-1.66a.56.56 0 00-1.11 0V15H35V7.81a3.91 3.91 0 00-.31-1.75c1.77 0 2.67-.52 2.67-1.47V1.51C37.39 0 35.62 0 34.57 0h-2.7m-1.63 0h-16.4m-1.62 0h-2.11m13 18.34a4.39 4.39 0 01-8.77 0v-.12a4.07 4.07 0 01.12-1 4.38 4.38 0 018.55 0 4.07 4.07 0 01.12 1s-.05.11-.05.15zm10.84-2.85c0 1.53-.74 1.74-1.47 1.74H24.1a5.49 5.49 0 00-10.81 0H4.94c-.73 0-1.47-.21-1.47-1.74h8.38a.58.58 0 00.43-.21l2.94-3.65h6.47L25.08 15a.56.56 0 00.39.15h8.45v.38zm0-1.48h-8.26l-3.39-3.3a.54.54 0 00-.39-.15H15a.55.55 0 00-.43.2l-2.94 3.65H3.47v-6.6c0-1.42.27-1.75 1.47-1.75H7v1.78a.87.87 0 00.94.89h1.31a.85.85 0 00.94-.89V6.06h17v1.78a.87.87 0 00.94.89h1.31a.85.85 0 00.94-.89V6.06h2.06c1.2 0 1.47.33 1.47 1.75V14zM8.11 7.63V3.39h1v4.24zm20.2 0V3.39h1v4.24zM32.45 5h-2.06V3.18a.85.85 0 00-.94-.89h-1.31a.87.87 0 00-.94.89V5h-17V3.18a.85.85 0 00-.94-.89H7.94a.87.87 0 00-.94.89V5H1.72c-.61 0-.61-.25-.61-.37V1.51c0-.1-.07-.37.61-.37h34c.64 0 .57.26.57.37v3.08c0 .12.08.37-.57.37z" />
    </Icon>
  );
};

export default AnhaengerIcon;
