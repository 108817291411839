import {
  USER_PROFILE_PHONE_NUMBER,
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
  BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__SOURCE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

export const mapBfsServiceRequestDetailToAPI: InquiryFormMapper = (allFields: any) => ({
  [USER_PROFILE_PHONE_NUMBER]: allFields[USER_PROFILE_PHONE_NUMBER],
  [BFS_SERVICE__EXPLICIT_NEED_SEMINARS]: allFields[BFS_SERVICE__EXPLICIT_NEED_SEMINARS],
  source_of_information: allFields[BFS_SERVICE__FACTORING_OFFER],
  other_source_of_information: allFields[BFS_SERVICE__SOURCE],
  [BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES]: allFields[BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES],
});
