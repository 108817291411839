import { AssetConfig } from 'config/formConfig/types';
import {
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
} from 'modules/Inquiry/Form/formFields';

const GenderIcons: AssetConfig[] = [
  {
    id: COMPANY_DETAILS_GENDER__WOMAN,
    titleId:
      'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders.woman',
    icon: 'womanIcon',
  },
  {
    id: COMPANY_DETAILS_GENDER__MAN,
    titleId: 'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders.man',
    icon: 'manIcon',
  },
];

export default GenderIcons;
