import _ from 'lodash';

import {
  mapBeneficiaryOwners,
  type MapBeneficiaryOwnersOptions,
  mapDefaultBeneficiaryOwner,
} from './mapBeneficiaryOwners';
import {
  mapDefaultLegalRepresentative,
  mapLegalRepresentatives,
  type MapLegalRepresentativesOptions,
} from './mapLegalRepresentatives';

export interface CommonPeopleDetailsToApiOptions
  extends MapBeneficiaryOwnersOptions,
    MapLegalRepresentativesOptions {
  /**
   * The return value of this function will be **merged** with the result of the other mapping functions.
   * This is useful for adding additional data to the mapping or to override the default mappings.
   */
  mapAdditionalData?: (allFields: any) => any;
}

export const commonMapPeopleDetailsToApi = (
  values: any,
  options: CommonPeopleDetailsToApiOptions = {
    mapBeneficiaryOwner: mapDefaultBeneficiaryOwner,
    mapLegalRepresentative: mapDefaultLegalRepresentative,
    mapAdditionalData: () => ({}),
  },
) => {
  const mappedResult = {
    ...mapBeneficiaryOwners(values, options),
    ...mapLegalRepresentatives(values, options),
  };

  return _.merge(mappedResult, options.mapAdditionalData?.(values) || {});
};
