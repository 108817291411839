import React from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { StyledInputWrapper } from 'components/PortalFormFields/InputFields/styles';

const MAX_DECIMAL_PRECISION = 15;

const NumberInput = ({ value, name, placeholder, isDecimal, hasError, onChange }) => {
  const handleChange = ({ floatValue }) => onChange(floatValue);
  const numOfDecimalNumbers = isDecimal ? MAX_DECIMAL_PRECISION : 0;
  return (
    <StyledInputWrapper hasError={hasError}>
      <NumberFormat
        onValueChange={handleChange}
        name={name}
        value={value}
        placeholder={placeholder}
        decimalScale={numOfDecimalNumbers}
      />
    </StyledInputWrapper>
  );
};

NumberInput.defaultProps = {
  value: null,
  placeholder: null,
  isDecimal: false,
  hasError: false,
  onChange: () => null,
};

NumberInput.propTypes = {
  value: PropTypes.number,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  isDecimal: PropTypes.bool,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
};

export default NumberInput;
