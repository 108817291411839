import { mittweidaFields } from 'mittweida/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  address,
  zipCode,
  city,
  description,
  lotSize,
  year,
  yearOfModernization,
  plannedProjectStart,
  plannedProjectEnd,
  furnishingQuality,
  qualityCondition,
  additionalBathroom,
  balcony,
  lift,
  cellar,
  parkingLotsCount,
  parkingLotPrice,
  parkingLotRentNow,
  parkingLotRentFuture,
  garagesCount,
  garagesPrice,
  garageRentNow,
  garageRentFuture,
  futureObjectType,
  usageDeviation,
  currentObjectType,
  currentTotalRentResidential,
  currentTotalRentCommercial,
  vacancyResidential,
  vacancyCommercial,
  futureTotalRentResidential,
  futureTotalRentCommercial,
  rentalContracts,
  rentalIntends,
} = mittweidaFields.financingNeedPage.objectInformationSection;

const { typeOfUse, usageSpace, plannedSalesPrice, numberOfUnits } =
  mittweidaFields.financingNeedPage.objectInformationSection.futureUsage;

const { currentUsage } = mittweidaFields.financingNeedPage.objectInformationSection;

const { fields: translations } =
  translationObject.inquiryType.mittweida.pages.financingNeed.sections.objectInformation;
export const objectSectionLabelsMap: LabelsMap = {
  [address]: translations.address.caption,
  [zipCode]: translations.zipCode.caption,
  [city]: translations.city.caption,
  [description]: translations.description.caption,
  [lotSize]: translations.lotSize.caption,
  [year]: translations.year.caption,
  [yearOfModernization]: translations.yearOfModernization.caption,
  [plannedProjectStart]: translations.plannedProjectStart.caption,
  [plannedProjectEnd]: translations.plannedProjectEnd.caption,
  [furnishingQuality]: translations.furnishingQuality.caption,
  [qualityCondition]: translations.conditionQuality.caption,
  [additionalBathroom]: translations.additionalBathroom.caption,
  [balcony]: translations.balcony.caption,
  [lift]: translations.lift.caption,
  [cellar]: translations.cellar.caption,
  [parkingLotsCount]: translations.parkingLotsCount.caption,
  [parkingLotPrice]: translations.parkingLotPrice.caption,
  [parkingLotRentNow]: translations.parkingLotRentNow.caption,
  [parkingLotRentFuture]: translations.parkingLotRentFuture.caption,
  [garagesCount]: translations.garagesCount.caption,
  [garagesPrice]: translations.garagesPrice.caption,
  [garageRentNow]: translations.garageRentNow.caption,
  [garageRentFuture]: translations.garageRentFuture.caption,
  [futureObjectType]: translations.futureObjectType.caption,
  [typeOfUse]: translations.usage.typeOfUse.caption,
  [usageSpace]: translations.usage.usageSpace.caption,
  [plannedSalesPrice]: translations.usage.plannedSalesPrice.caption,
  [numberOfUnits]: translations.usage.numberOfUnits.caption,
  [usageDeviation]: translations.usageDeviation.caption,
  [currentObjectType]: translations.currentObjectType.caption,
  [currentUsage.typeOfUse]: translations.currentObjectType.typeOfUse.caption,
  [currentUsage.numberOfUnits]: translations.usage.numberOfUnits.caption,
  [currentUsage.usageSpace]: translations.usage.usageSpace.caption,
  [currentTotalRentCommercial]: translations.currentTotalRentCommercial.caption,
  [currentTotalRentResidential]: translations.currentTotalRentResidential.caption,
  [vacancyResidential]: translations.vacancyResidential.caption,
  [vacancyCommercial]: translations.vacancyCommercial.caption,
  [futureTotalRentResidential]: translations.futureTotalRentResidential.caption,
  [futureTotalRentCommercial]: translations.futureTotalRentCommercial.caption,
  [rentalContracts]: translations.rentalContracts.caption,
  [rentalIntends]: translations.rentalIntends.caption,
};
