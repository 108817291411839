import React from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FilesToCompeon from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToCompeon/FilesToCompeon';
import ProcessSpecificFiles from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/ProcessSpecificFiles';
import AdditionalFilesFromCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/AdditionalFilesFromCustomer/AdditionalFilesFromCustomer';
import FilesForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/FilesForCustomer/FilesForCustomer';
import { InternalFiles } from 'pages/operationPortal/InquiryDetails/DocumentExchange/InternalFiles';
import RequestsForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/RequestsForCustomer';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import { DZBOperationDocumentExchange } from './DzbOperationDocumentExchange';
import { HausbankOperationDocumentExchange } from './HausbankOperationDocumentExchange';
import { MittweidaOperationDocumentExchange } from './MittweidaOperationDocumentExchange';
import { RLLOperationDocumentExchange } from './RLLOperationDocumentExchange';
import {
  useOperationDocumentExchange,
  useOperationDocumentExchangeWebSocket,
} from './useOperationDocumentExchange';

const OperationDocumentExchange = () => {
  const { isLoading } = useOperationDocumentExchange();
  useOperationDocumentExchangeWebSocket();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <article>
      <InquiryDetailsHeader />
      <RequestsForCustomer />
      <ProcessSpecificFiles />
      <InternalFiles />
      <AdditionalFilesFromCustomer />
      <FilesForCustomer />
      <FilesToCompeon />
    </article>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dzb]: DZBOperationDocumentExchange,
  [InquiryType.profiRLL]: RLLOperationDocumentExchange,
  [InquiryType.profiMittweida]: MittweidaOperationDocumentExchange,
  [InquiryType.default]: OperationDocumentExchange,
  [InquiryType.hausbank]: HausbankOperationDocumentExchange,
});
