import { AssetConfig } from 'config/formConfig/types';

export enum InquiryAssetKeys {
  OFFER = 'offer',
  DASHBOARD_ACTION = 'dashboardAction',
  VERIFY_ACCOUNT = 'verifyAccount',
  DOCUMENT_UPLOAD = 'documentUpload',
  BANK_CHECK = 'bankCheck',
  VIDEO_LEDIGITIMATION = 'videoLegitimation',
  VIDEO_IDENT = 'videoIdent',
  ACTIVATE_ACCOUNT = 'activateAccount',
  ACTIVATE_ACCOUNT_BFS_SERVICE = 'activateAccountBfsService',
  INDICATIVE_OFFER = 'indicativeOffer',
  NEXT_STEPS = 'nextSteps',
  SIGN_CONTRACT = 'signContract',
  HEADER = 'header',
}

const InquiryIcons: AssetConfig[] = [
  {
    id: InquiryAssetKeys.OFFER,
    icon: 'acceptOfferImage',
  },
  {
    id: InquiryAssetKeys.SIGN_CONTRACT,
    icon: 'signContractImage',
  },
  {
    id: InquiryAssetKeys.BANK_CHECK,
    icon: 'bankCheckImage',
  },
  {
    id: InquiryAssetKeys.DASHBOARD_ACTION,
    icon: 'acceptOfferImage',
  },
  {
    id: InquiryAssetKeys.VERIFY_ACCOUNT,
    icon: 'verifyAccount',
  },
  {
    id: InquiryAssetKeys.DOCUMENT_UPLOAD,
    icon: 'documentUploadImage',
  },
  {
    id: InquiryAssetKeys.VIDEO_LEDIGITIMATION,
    icon: 'videoLegitimationImage',
  },
  {
    id: InquiryAssetKeys.VIDEO_IDENT,
    icon: 'videoIdent',
  },
  {
    id: InquiryAssetKeys.ACTIVATE_ACCOUNT,
    icon: 'activateAccount',
  },
  {
    id: InquiryAssetKeys.ACTIVATE_ACCOUNT_BFS_SERVICE,
    icon: 'activateAccountBfsService',
  },
  {
    id: InquiryAssetKeys.INDICATIVE_OFFER,
    icon: 'indicativeOffer',
  },
  {
    id: InquiryAssetKeys.NEXT_STEPS,
    icon: 'nextSteps',
  },
  {
    id: InquiryAssetKeys.HEADER,
    icon: 'header',
  },
];

export default InquiryIcons;
