import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setProgressCounterToMax } from 'store/progress/actions';
import { getStagesSelector } from 'store/progress/selectors';

export const useSetProgressForEdit = () => {
  const stages = useSelector(getStagesSelector);
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (stages && !isInitialized) {
      setIsInitialized(true);
      Object.keys(stages).forEach((stageKey) => {
        dispatch(setProgressCounterToMax(stageKey));
      });
    }
  }, [dispatch, isInitialized, stages]);
};
