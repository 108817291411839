import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { CONTRACT_DETAILS, CUSTOMER_DATA } from 'modules/Inquiry/Form/formFields';
import ContractDetailsSection from 'modules/Inquiry/Form/Steps/ContractDetails/ContractDetails';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import StepsHeader from 'pages/inquiryFlow/StepsHeader';
import { setProgressCounter } from 'store/progress/actions';

export const ContractDetailsStep = () => {
  const dispatch = useDispatch();
  const current = 2;
  const total = 4;
  const stage = CUSTOMER_DATA;

  useEffect(() => {
    dispatch(setProgressCounter({ current, total, stage }));
  }, [dispatch, stage]);

  return (
    <InquiryFormNew
      headerComp={<StepsHeader header={'pages.contractDetails.heading.header'} />}
      currentStepName={CONTRACT_DETAILS}
      ignoreRedirection
    >
      {() => (
        <>
          <ContractDetailsSection />
        </>
      )}
    </InquiryFormNew>
  );
};

export default ContractDetailsStep;
