import _ from 'lodash';

import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
  SELF_USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';

import { CommonPeopleDetailsToApiOptions } from './commonMapPeopleDetailsToApi';
import { mapDefaultBeneficiaryOwner } from './mapBeneficiaryOwners';
import { mapDefaultLegalRepresentative, mapLegalRepresentatives } from './mapLegalRepresentatives';
import { mmvMapBeneficiaryOwners } from './mmvMapBeneficiaryOwners';
import { fieldParsers } from '../fieldParsers';

const { toBoolean } = fieldParsers;

export const mapSelfLegalRepresentative = (allFields: any) => {
  if (!allFields[SELF_USER_REPRESENTATIVE]) {
    return {};
  }
  return {
    legal_representative_customer: {
      birth_date: allFields[SELF_USER_REPRESENTATIVE][REPRESENTATIVE_BIRTHDATE],
      phone_number: allFields[SELF_USER_REPRESENTATIVE][REPRESENTATIVE_PHONE_NUMBER],
    },
  };
};

export const mapLegalRepresentative = (allFields: any, representative: any) => {
  const defaultMapResult = mapDefaultLegalRepresentative(allFields, representative);
  if (representative.id || representative.isAssociatedPerson) {
    return { id: representative.id, phone_number: representative[REPRESENTATIVE_PHONE_NUMBER] };
  }
  return defaultMapResult;
};

export const mapBeneficiaryOwner = (allFields: any, owner: any) => {
  const defaultMapResult = mapDefaultBeneficiaryOwner(allFields, owner);
  if (owner.isAssociatedPerson) {
    return {
      id: owner.id,
      additional_info: {
        wb_fictitious:
          toBoolean(
            allFields.customerDataPage?.beneficialOwnerSection?.isFictitiousBeneficialOwner,
          ) || false,
      },
    };
  }

  return {
    ...defaultMapResult,
    additional_info: {
      wb_fictitious:
        toBoolean(
          allFields.customerDataPage?.beneficialOwnerSection?.isFictitiousBeneficialOwner,
        ) || false,
    },
  };
};

const mapAdditionalData = (allFields: any) => {
  return {
    ...mapSelfLegalRepresentative(allFields),
  };
};

const mmvMapPeopleDetailsToApi = (
  values: any,
  options: CommonPeopleDetailsToApiOptions = {
    mapBeneficiaryOwner: mapDefaultBeneficiaryOwner,
    mapLegalRepresentative: mapDefaultLegalRepresentative,
    mapAdditionalData: () => ({}),
  },
) => {
  const mappedResult = {
    ...mmvMapBeneficiaryOwners(values, options),
    ...mapLegalRepresentatives(values, options),
  };

  return _.merge(mappedResult, options.mapAdditionalData?.(values) || {});
};

export const mmvPeopleDetailsToApi = (values: any) => {
  return mmvMapPeopleDetailsToApi(values, {
    mapLegalRepresentative,
    mapBeneficiaryOwner,
    mapAdditionalData,
  });
};
