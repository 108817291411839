import styled from 'styled-components/macro';

import { Heading } from 'components/Text';
import { mqMedium, mqExtraLarge } from 'styles/breakpoints';

export const Inner = styled.div`
  width: 100%;
`;

export const StyledPageHeading = styled(Heading)<{ withPadding?: boolean }>`
  margin: ${({ withPadding }) => (withPadding ? '1rem 0 6rem' : '1rem 0')};
  text-align: left;

  ${mqMedium`
    margin: ${({ withPadding }) => (withPadding ? '6rem 0' : '3rem 0')};
  `}
`;

export const Container = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1rem;

  ${mqExtraLarge`
    max-width: max(66%, 1280px);
  `};
`;

export const ContentWrapper = styled.div`
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  background-color: ${(props) => props.theme.colors.background.white};

  ${mqMedium`
    right: 50%;
    left: 50%;
    width: 100vw;
    margin-right: -50vw;
    margin-left: -50vw;
  `};
`;
