import React, { ReactNode } from 'react';

import { NavLink } from 'react-router-dom';

import { CompaniesDetailsRouteDeclaration } from 'pages/operationPortal/CompaniesDetails/useCompaniesDetailsRoutes';

// Types
export interface UnifiedRouteDeclaration extends Omit<CompaniesDetailsRouteDeclaration, 'name'> {
  name: string;
}

type RouteDeclarationMapper = (
  routeDeclarations: UnifiedRouteDeclaration[],
  t: (id: string) => string,
  hasPermission: (availableForRoles: any) => any,
  companyId: string,
  inquiryId?: string,
) => ReactNode[];

// Utils
export const mapRouteDeclarationToNavLink: RouteDeclarationMapper = (
  routeDeclarations,
  t,
  hasPermission,
  companyId,
  inquiryId,
) => {
  return (routeDeclarations as UnifiedRouteDeclaration[])
    .filter(({ permission }) => (permission ? hasPermission(permission) : true))
    .map((route) => (
      <li key={route.path}>
        <NavLink
          activeClassName="active"
          to={route.path.replace(':companyId', companyId).replace(':id', inquiryId as string)}
          data-testid={`${route.name}-link`}
        >
          {t(route.title)}
        </NavLink>
      </li>
    ));
};
