import React, { useContext } from 'react';

import { InputWithField } from 'components/Input';
import { BENEFICIARY_PRIVATE_ADDRESS } from 'modules/Inquiry/Form/formFields';

import { BeneficiaryOwnersContext } from '../BeneficiaryOwners.context';

interface PrivateAddressProps {
  disabled?: boolean;
}

const PrivateAddress: React.FC<PrivateAddressProps> = ({ disabled }) => {
  const { fieldName } = useContext(BeneficiaryOwnersContext);

  return (
    <InputWithField disabled={disabled} name={`${fieldName}.${BENEFICIARY_PRIVATE_ADDRESS}`} />
  );
};

export default PrivateAddress;
