import React from 'react';

import { useHistory } from 'react-router-dom';

import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

type PropType = {
  stepNumber: number;
  editFormStructure: Array<EditMarketValue>;
  setDisplayPage: React.Dispatch<React.SetStateAction<EditMarketValue>>;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
};

type TertiaryButtonMapType = {
  [T in 'startStep' | 'middleAndFinalSteps']: {
    label: string;
    action: () => void;
  };
};

const TertiaryButton: React.FC<PropType> = ({
  stepNumber,
  editFormStructure,
  setDisplayPage,
  setStepNumber,
}) => {
  let step: keyof TertiaryButtonMapType;
  const t = useTranslations();
  const history = useHistory();
  const displayLastPage = () => {
    if (stepNumber >= 0) {
      let stepCount = stepNumber - 1;
      setDisplayPage(editFormStructure[stepCount]);
      setStepNumber(stepCount);
    }
  };

  const tertiaryButtonMap: TertiaryButtonMapType = {
    startStep: {
      label: t('buttons.cancel'),
      action: () => history.goBack(),
    },
    middleAndFinalSteps: { label: t('buttons.back'), action: () => displayLastPage() },
  };

  if (stepNumber === 0) step = 'startStep';
  else if (stepNumber > 0) step = 'middleAndFinalSteps';
  else return null;
  return (
    <ButtonComponent onClick={() => tertiaryButtonMap[step].action()} mr={4} variant="tertiary">
      {t(tertiaryButtonMap[step].label)}
    </ButtonComponent>
  );
};

export default TertiaryButton;
