import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ViewIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 10.2c-1.1 0-2 .8-2 1.9 0 1.1.8 2 1.9 2s2-.8 2-1.9c.1-1.1-.8-2-1.9-2m0 5.8c-2.2 0-3.9-1.7-3.9-3.9S9.8 8.2 12 8.2s3.9 1.7 3.9 3.9S14.2 16 12 16" />
      <path d="M3 12.1c1.2 1.3 4.4 4.4 9 4.4s7.8-3.1 9-4.4c-1.2-1.3-4.4-4.4-9-4.4s-7.8 3.1-9 4.4m9 6.4C5.2 18.5 1.1 13 .9 12.7l-.4-.6.4-.6c.2-.2 4.3-5.7 11.1-5.7s10.9 5.5 11.1 5.8l.4.6-.4.6c-.2.2-4.3 5.7-11.1 5.7" />
    </Icon>
  );
};

export default ViewIcon;
