import { DzbFieldTypes } from 'dzb/inquiry/fields';

export const dzbInitialFormValues: DzbFieldTypes = {
  companyDataPage: {
    associationInformationSection: {
      name: undefined,
      contactPerson: undefined,
      totalPurchaseOfGoods: undefined,
      annualSalesForecast: undefined,
      contractDate: new Date(),
    },
    companyInformationSection: {
      companyName: undefined,
      address: undefined,
      zipCode: undefined,
      city: undefined,
      country: undefined,
      salesTaxID: undefined,
      typeOfFinancialStatements: undefined,
      companyOlderThan3Years: undefined,
      lastAnnualFinancialStatementReportingDate: undefined,
    },
    directDebitMandateSection: {
      iban: undefined,
      bic: undefined,
      bankName: undefined,
    },
    startUpQuestionnaireSection: {
      industryExperienceYears: undefined,
      education: undefined,
      professionalCareer: undefined,
      alreadyGainedLeadershipExperience: undefined,
      leadershipExperienceYears: undefined,
      formOfLeadershipExperience: undefined,
    },
  },
  retailerDataPage: {
    retailerInformationSection: {
      numberOfStores: undefined,
      retailSpace: undefined,
      numberOfEmployees: undefined,
      enterpriseResourcePlanningSystemInUse: undefined,
      erpProvider: undefined,
    },
    onlineQuestionnaireSection: {
      shareOfOnlineSalesPercentage: undefined,
      averageShoppingBasketAmount: undefined,
      returnRatePercentage: undefined,
      amazonPercentage: undefined,
      ebayPercentage: undefined,
      zalandoPercentage: undefined,
      othersPercentage: undefined,
      ownWarehousePercentage: undefined,
      shareOfDistributionChannels: undefined,
      foreignWarehousePercentage: undefined,
      foreignLogisticsServiceProviderPercentage: undefined,
      foreignFulfillmentByAmazonPercentage: undefined,
      shareOfWarehouse: undefined,
      ownOnlineShopPercentage: undefined,
      shareOfForeignLogistics: undefined,
    },
  },
  personalDataPage: {
    contactPersonSection: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    legalRepresentativesSection: {
      legalRepresentatives: [
        {
          id: undefined,
          salutation: undefined,
          firstName: undefined,
          lastName: undefined,
          birthDate: undefined,
          email: undefined,
          phoneNumber: undefined,
          soleSignatureAuthorized: undefined,
          taxIdentificationNumber: undefined,
        },
      ],
    },
    beneficialOwnersSection: {
      allInformationAvailable: undefined,
      beneficialOwners: [
        {
          id: undefined,
          firstName: undefined,
          lastName: undefined,
          nationality: undefined,
          capitalOrVotingRightsPercentage: undefined,
          placeOfResidence: undefined,
          countryOfResidence: undefined,
          birthPlace: undefined,
          birthDate: undefined,
          fictional: undefined,
        },
      ],
    },
  },
};
