import moment from 'moment';

import {
  PURPOSE_KIND,
  OTHER_PURPOSE_DESCRIPTION,
  FINANCING_AMOUNT,
  DEBT_RESCHEDULING_DESCRIPTION,
  GOODS__PURCHASE_PRICE,
  GOODS__DESCRIPTION,
  GOODS__SUPPLIER,
  GOODS__ESTIMATED_DELIVERY_DATE,
  MACHINE_CATEGORY,
  MACHINE_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_MANUFACTURER,
  MACHINE_KIND,
  MACHINE_NEW,
  MACHINE_AGE,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_IS_ORDERED,
  MACHINE_DELIVERY_DATE,
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_COUNT,
  VEHICLE_NEW,
  VEHICLE_YEAR,
  VEHICLE_KIND,
  VEHICLE_OTHER_DESCRIPTION,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_YEARLY_MILEAGE,
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_KIND,
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_SCHEDULED_DATE,
  REAL_ESTATE_FOLLOW_UP_AMOUNT,
  REAL_ESTATE_RECONSTRUCTION_COST,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_USAGE_KIND,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_ZIP_CODE,
  REAL_ESTATE_PROJECT_DESCRIPTION,
  REAL_ESTATE_TAXATION,
  PROJECT_FINANCING_ROLE,
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_KNOWS_ANNUAL_RENT,
  PROJECT_FINANCING_ANNUAL_RENT_AMOUNT,
  PROJECT_FINANCING_PLAN_TO_INCREASE_ANNUAL_RENT,
  PROJECT_FINANCING_DESCRIPTION_OF_THE_MEASURE,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_RENT_FUTURE,
  PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_HAS_BUILD_PERMIT,
  PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  BUILDING_PROJECT,
  BUILDING_TYPE,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_COOWNERSHIP,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_TYPE_OF_USE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_CONSTRUCTION_YEAR,
  BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
  BUILDING_STREET_AND_HOUSE_NUMBER,
  BUILDING_POSTAL_CODE,
  BUILDING_PLACE,
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_USE_OF_FUNDS_SUM,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_SOURCE_OF_FUNDS_SUM,
  CORONA_FOUNDING_MONTH,
  CORONA_FOUNDING_YEAR,
  CORONA_VIABILTIY,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_REVENUE_OF_2019,
  CORONA_VIABILTIY_2020,
  CORONA_TOTAL_ASSETS,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_LIQUIDITY_NEED,
  CORONA_FINANCING_OPTION,
  OFFER_NET_PRICE,
  OFFER_GROSS_PRICE,
  OFFER_PROVIDER_NAME,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  MACHINE_COUNT,
  MACHINE_DESCRIPTION,
  LIQUIDITY_DESCRIPTION,
  VEHICLE_DESCRIPTION,
  OFFICE_EQUIPMENT_PRICE,
  OFFICE_EQUIPMENT_DESCRIPTION,
  GUARANTY_DESCRIPTION,
  REAL_ESTATE_TOTAL_COST,
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
} from 'modules/Inquiry/Form/formFields';
import {
  MAX_AMOUNT_VALUE,
  MAX_DESCRIPTION_LENGTH,
  MAX_NUMERIC_VALUE_LENGTH,
  MIN_SIZE_VALUE,
  MAX_SIZE_VALUE,
  MAX_PERCENT_VALUE,
  MIN_CONSTRUCTION_YEAR,
  MIN_BUILDING_YEAR,
  MAX_BUILDING_YEARS_SPAN,
  MIN_MODERNIZATION_YEAR,
  MAX_NUMBER_OF_GARAGES,
  MAX_NUMBER_OF_PARKING_LOTS,
  MAX_NUMBER_OF_UNITS,
  MIN_NUMBER_OF_GARAGES,
  MIN_NUMBER_OF_PARKING_LOTS,
  MIN_NUMBER_OF_UNITS,
  MIN_LOT_SIZE,
  MAX_STRING_SIZE,
} from 'modules/Inquiry/inquiry.constants';
import {
  fieldValidators,
  customValidations,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const financingNeedFieldValidations = {
  [PURPOSE_KIND]: fieldValidators.string().required(),

  [FINANCING_AMOUNT]: fieldValidators
    .price()
    .minPrice(InquiryConstants.MIN_FINANCING_AMOUNT)
    .maxPrice(MAX_AMOUNT_VALUE)
    .required(),

  [OFFICE_EQUIPMENT_PRICE]: fieldValidators.required(),
  [OFFICE_EQUIPMENT_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),

  [DEBT_RESCHEDULING_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),

  [GOODS__PURCHASE_PRICE]: fieldValidators.number().positive().max(MAX_AMOUNT_VALUE).required(),
  [GOODS__DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [GOODS__SUPPLIER]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.GOODS_SUPPLIER_MAX_LENGTH),
  [GOODS__ESTIMATED_DELIVERY_DATE]: fieldValidators
    .date()
    .required()
    .max(InquiryConstants.GOODS_DELIVERY_MAX_DATE)
    .min(InquiryConstants.GOODS_DELIVERY_MIN_DATE),

  [MACHINE_CATEGORY]: fieldValidators.string().required(),
  [MACHINE_PRICE]: fieldValidators.number().required().positive().max(MAX_AMOUNT_VALUE),
  [MACHINE_COUNT]: fieldValidators
    .number()
    .required()
    .positive()
    .max(InquiryConstants.MAX_MACHINE_COUNT),
  [MACHINE_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [MACHINE_PERIOD_OF_USE]: fieldValidators
    .number()
    .positive()
    .integer()
    .max(InquiryConstants.MAX_PERIOD_OF_USE_LENGTH),
  [MACHINE_MANUFACTURER]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_MACHINE_MANUFACTURER_LENGTH),
  [MACHINE_KIND]: fieldValidators.string().required().max(InquiryConstants.MAX_MACHINE_KIND_LENGTH),
  [MACHINE_NEW]: fieldValidators.bool().required(),
  [MACHINE_AGE]: fieldValidators
    .number()
    .required()
    .positive()
    .integer()
    .max(MAX_NUMERIC_VALUE_LENGTH),
  [MACHINE_ORIGINAL_PRICE]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE),
  [MACHINE_IS_ORDERED]: fieldValidators.required(),
  [MACHINE_DELIVERY_DATE]: fieldValidators.required(),

  [VEHICLE_PURCHASE_PRICE]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE),
  [VEHICLE_COUNT]: fieldValidators
    .number()
    .required()
    .integer()
    .min(InquiryConstants.MIN_VEHICLE_COUNT)
    .max(InquiryConstants.MAX_VEHICLE_COUNT),
  [VEHICLE_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
  [VEHICLE_NEW]: fieldValidators.bool().required(),
  [VEHICLE_YEAR]: fieldValidators
    .number()
    .required()
    .min(InquiryConstants.MIN_VEHICLE_YEAR)
    .max(InquiryConstants.CURRENT_MOMENT_YEAR),
  [VEHICLE_KIND]: fieldValidators.string().required(),
  [VEHICLE_OTHER_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.VEHICLE_MAX_DESCRIPTION),
  [VEHICLE_MANUFACTURER]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.VEHICLE_MANUFACTURER_MAX_LENGTH),
  [VEHICLE_MODEL]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.VEHICLE_MODEL_MAX_LENGTH),
  [VEHICLE_YEARLY_MILEAGE]: fieldValidators
    .number()
    .required()
    .integer()
    .positive()
    .max(MAX_AMOUNT_VALUE),

  [LIQUIDITY_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),

  [REAL_ESTATE_PROJECT]: fieldValidators.required(),
  [REAL_ESTATE_KIND]: fieldValidators.required(),
  [REAL_ESTATE_SCHEDULED_DATE]: fieldValidators
    .date()
    .required()
    .min(InquiryConstants.MIN_REAL_ESTATE_SCHEDULE_DATE)
    .max(InquiryConstants.MAX_REAL_ESTATE_SCHEDULE_DATE),
  [REAL_ESTATE_PURCHASE_PRICE]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_FOLLOW_UP_AMOUNT]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_RECONSTRUCTION_COST]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(MAX_DESCRIPTION_LENGTH),
  [REAL_ESTATE_CONSTRUCTION_DATE]: fieldValidators
    .date()
    .min(InquiryConstants.MIN_REAL_ESTATE_CONSTRUCTION_DATE)
    .max(InquiryConstants.MAX_REAL_ESTATE_CONSTRUCTION_DATE),
  [REAL_ESTATE_LAND_SIZE]: fieldValidators.number().positive().integer().max(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_BUILDING_SIZE]: fieldValidators.number().positive().integer().max(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_HERITABLE_BUILDING_RIGHT]: fieldValidators.bool(),
  [REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION]: fieldValidators
    .number()
    .positive()
    .integer()
    .max(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_USAGE_KIND]: fieldValidators.string(),
  [REAL_ESTATE_RENTED_OUT_AREA]: fieldValidators
    .number()
    .positive()
    .integer()
    .max(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_COLD_RENT]: fieldValidators.price().positive().integer().maxPrice(MAX_AMOUNT_VALUE),
  [REAL_ESTATE_TAXATION]: fieldValidators.string(),
  [REAL_ESTATE_ZIP_CODE]: fieldValidators.postalCode().isValid(),
  [REAL_ESTATE_PROJECT_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(MAX_DESCRIPTION_LENGTH),

  [PROJECT_FINANCING_ROLE]: fieldValidators.string().required(),
  [PROJECT_FINANCING_INVESTMENT_AMOUNT]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [PROJECT_FINANCING_OWN_FUNDS]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [PROJECT_FINANCING_MEZZANINE]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [PROJECT_FINANCING_OWN_WORK]: fieldValidators
    .price()
    .required()
    .positive()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0),
  [PROJECT_FINANCING_SUBORDINATED_CAPITAL]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE)
    .positive()
    .allow(0),
  [PROJECT_FINANCING_OBJECT_ADDRESS]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_OBJECT_ADDRESS_LENGTH),
  [PROJECT_FINANCING_OBJECT_ZIP_CODE]: fieldValidators
    .postalCode()
    .isValid()
    .required()
    .max(InquiryConstants.MAX_POSTAL_LENGTH),
  [PROJECT_FINANCING_OBJECT_CITY]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_OBJECT_CITY_LENGTH),
  [PROJECT_FINANCING_OBJECT_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(MAX_DESCRIPTION_LENGTH),
  [PROJECT_FINANCING_LOT_SIZE]: fieldValidators
    .number()
    .required()
    .min(MIN_LOT_SIZE)
    .max(MAX_AMOUNT_VALUE),
  [PROJECT_FINANCING_LIST_OF_RENTERS]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_ANCHOR_TENANT]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_OBJECT_ANCHOR_TENANT_LENGTH),
  [PROJECT_FINANCING_KNOWS_ANNUAL_RENT]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_ANNUAL_RENT_AMOUNT]: fieldValidators
    .price()
    .required()
    .maxPrice(MAX_AMOUNT_VALUE),
  [PROJECT_FINANCING_PLAN_TO_INCREASE_ANNUAL_RENT]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_DESCRIPTION_OF_THE_MEASURE]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_OBJECT_MEASURE_DESCRIPTION_LENGTH),
  [PROJECT_FINANCING_RENTAL_CONTRACTS]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_RENOVATION_PLANNED]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_RENOVATION_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(MAX_DESCRIPTION_LENGTH),
  [PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT]: fieldValidators.string().required(),
  [PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE]: fieldValidators.string().required(),
  [PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS]: fieldValidators
    .string()
    .required()
    .max(MAX_DESCRIPTION_LENGTH),
  [PROJECT_FINANCING_USAGE_SPACE]: fieldValidators
    .number()
    .required()
    .min(MIN_SIZE_VALUE)
    .max(MAX_SIZE_VALUE)
    .positive(),
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE]: fieldValidators.bool().allow(null),
  [PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_HAS_BUILD_PERMIT]: fieldValidators.bool().allow(null),
  [PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES]: fieldValidators.bool().required(),
  [PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE]: fieldValidators.bool().required(),
  [PROJECT_FINANCING_RENT_NOW]: fieldValidators.price().maxPrice(MAX_AMOUNT_VALUE).allow(null),
  [PROJECT_FINANCING_RENT_FUTURE]: fieldValidators.price().maxPrice(MAX_AMOUNT_VALUE).allow(null),
  [PROJECT_FINANCING__BUILDING_YEAR]: fieldValidators
    .number()
    .required()
    .custom(customValidations.buildingYearsRange(MIN_BUILDING_YEAR, MAX_BUILDING_YEARS_SPAN)),
  [PROJECT_FINANCING__MODERNIZATION_YEAR]: fieldValidators
    .number()
    .custom(customValidations.modernizationYearsRange(MIN_MODERNIZATION_YEAR))
    .allow(null),
  [PROJECT_FINANCING__FURNISHING_QUALITY]: fieldValidators.string().required(),
  [PROJECT_FINANCING__QUALITY_CONDITION]: fieldValidators.string().required(),
  [PROJECT_FINANCING__GUEST_BATHROOM]: fieldValidators.bool().required(),
  [PROJECT_FINANCING__BALKONY]: fieldValidators.bool().required(),
  [PROJECT_FINANCING__LIFT]: fieldValidators.bool().required(),
  [PROJECT_FINANCING__CELLAR]: fieldValidators.bool().required(),
  [PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS]: fieldValidators
    .number()
    .min(MIN_NUMBER_OF_PARKING_LOTS)
    .max(MAX_NUMBER_OF_PARKING_LOTS)
    .required(),
  [PROJECT_FINANCING__NUMBER_OF_GARAGES]: fieldValidators
    .number()
    .min(MIN_NUMBER_OF_GARAGES)
    .max(MAX_NUMBER_OF_GARAGES)
    .required(),
  [PROJECT_FINANCING__NUMBER_OF_UNITS]: fieldValidators
    .number()
    .required()
    .custom(customValidations.numberOfUnitsRange(MIN_NUMBER_OF_UNITS, MAX_NUMBER_OF_UNITS)),
  [PROJECT_FINANCING__GARAGES_RENT_FUTURE]: fieldValidators
    .price()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0, null),
  [PROJECT_FINANCING__GARAGES_RENT_NOW]: fieldValidators
    .price()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0, null),
  [PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT]: fieldValidators
    .price()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0, null),
  [PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE]: fieldValidators
    .price()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0, null),
  [PROJECT_FINANCING__PARKING_LOTS_RENT_NOW]: fieldValidators
    .price()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0, null),
  [PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT]: fieldValidators
    .price()
    .maxPrice(MAX_AMOUNT_VALUE)
    .allow(0, null),
  [PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT]: fieldValidators
    .price()
    .required()
    .minPrice(InquiryConstants.MIN_PLANNED_SALES_PRICE)
    .maxPrice(InquiryConstants.MAX_PLANNED_SALES_PRICE)
    .allow(0),
  [PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE]: fieldValidators.bool().required(),
  [BUILDING_PROJECT]: fieldValidators.string().required(),
  [BUILDING_TYPE]: fieldValidators.string().required(),
  [BUILDING_TYPE_DESCRIPTION]: fieldValidators
    .string()
    .max(InquiryConstants.MAX_BUILDING_TYPE_DESCRIPTION)
    .required(),
  [BUILDING_COOWNERSHIP]: fieldValidators.number(),
  [BUILDING_CONDOMINIUM_NUM]: fieldValidators.number(),
  [BUILDING_NUMBER_OF_RESIDENTIAL_UNITS]: fieldValidators.number().required(),
  [BUILDING_TOTAL_USABLE_SPACE]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_BUILDING_USAGE_SPACE),
  [BUILDING_LIVING_SPACE]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_BUILDING_LIVING_SPACE),
  [BUILDING_COMMERCIAL_SPACE]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_BUILDING_LIVING_SPACE),
  [BUILDING_SHARE_OF_COMMERCIAL_SPACE]: fieldValidators.number().positive().max(MAX_PERCENT_VALUE),
  [BUILDING_TYPE_OF_USE]: fieldValidators.string().required(),
  [BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL]: fieldValidators
    .number()
    .required()
    .max(MAX_AMOUNT_VALUE),
  [BUILDING_RENTED_SPACE_COMMERCIAL]: fieldValidators.number().required().max(MAX_SIZE_VALUE),
  [BUILDING_COLD_RENT_PER_YEAR_LIVING]: fieldValidators.number().required().max(MAX_AMOUNT_VALUE),
  [BUILDING_RENTED_SPACE_LIVING]: fieldValidators.number().required().max(MAX_SIZE_VALUE),
  [BUILDING_CONSTRUCTION_YEAR]: fieldValidators
    .number()
    .required()
    .min(MIN_CONSTRUCTION_YEAR)
    .max(moment().add(2, 'years').year()),
  [BUILDING_LATEST_SUBSTENCIAL_MODERNISATION]: fieldValidators
    .number()
    .min(MIN_CONSTRUCTION_YEAR)
    .max(moment().year()),
  [BUILDING_STREET_AND_HOUSE_NUMBER]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_BUILDING_STREET_LENGTH),
  [BUILDING_POSTAL_CODE]: fieldValidators.postalCode().isValid().required(),
  [BUILDING_PLACE]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_BUILDING_PLACE_LENGTH),
  [BUILDING_PURCHASE_PRICE]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_PLOT_PRICE]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_ADDITIONAL_COSTS]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_CONSTRUCTION_MANUFACTURE_COST]: fieldValidators
    .number()
    .min(0)
    .max(MAX_AMOUNT_VALUE)
    .required(),
  [BUILDING_MODERNISATION_COSTS]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_DEBT_REDEMPTION]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_USE_OF_FUNDS_SUM]: fieldValidators.number(),
  [BUILDING_EQUITY]: fieldValidators.number().required(),
  [BUILDING_PERSONAL_CONTRIBUTION]: fieldValidators
    .number()
    .min(0)
    .max(MAX_AMOUNT_VALUE)
    .required(),
  [BUILDING_PROPERTY]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_BORROWED_CAPITAL]: fieldValidators
    .number()
    .min(InquiryConstants.MIN_BUILDING_BORROWED_CAPITAL)
    .max(MAX_AMOUNT_VALUE)
    .required(),
  [BUILDING_DEBT_CAPITAL_OTHER]: fieldValidators.number().min(0).max(MAX_AMOUNT_VALUE).required(),
  [BUILDING_SOURCE_OF_FUNDS_SUM]: fieldValidators.number(),
  [CORONA_FOUNDING_YEAR]: fieldValidators
    .number()
    .required()
    .min(InquiryConstants.MIN_FOUNDING_YEAR)
    .custom(customValidations.coronaFoundingYearBefore2019),
  [OFFER_PROVIDER_NAME]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_AGRICULTURAL_OFFER_PROVIDER_NAME),
  [OFFER_GROSS_PRICE]: fieldValidators.number().required().positive().max(MAX_AMOUNT_VALUE),
  [OFFER_NET_PRICE]: fieldValidators.number().required().positive().max(MAX_AMOUNT_VALUE),
  [CORONA_FOUNDING_MONTH]: (fieldValue: any, allFields: any) =>
    fieldValidators
      .number()
      .required()
      .custom(
        customValidations.coronaFoundingMonthBeforeJan2019(
          parseInt(allFields[CORONA_FOUNDING_YEAR], 10),
        ),
      ),
  [CORONA_VIABILTIY]: fieldValidators
    .boolean()
    .required()
    .custom(customValidations.coronaViabilityTrue),
  [CORONA_FINANCING_OPTION]: (fieldValue: any, allFields: any) =>
    fieldValidators
      .string()
      .required()
      .custom(
        customValidations.coronaFinancingOption(
          allFields[CORONA_FOUNDING_MONTH],
          allFields[CORONA_FOUNDING_YEAR],
        ),
      ),
  [CORONA_NUMBER_OF_EMPLOYEES]: (fieldValue: any, allFields: any) =>
    fieldValidators
      .number()
      .required()
      .max(InquiryConstants.MAX_NUMBER_OF_EMPLOYEES)
      .custom(
        customValidations.coronaEmployeesNumber(
          allFields[CORONA_FOUNDING_MONTH],
          allFields[CORONA_FOUNDING_YEAR],
        ),
      ),
  [CORONA_REVENUE_OF_2019]: fieldValidators.price().required().maxPrice(MAX_AMOUNT_VALUE),
  [CORONA_VIABILTIY_2020]: fieldValidators
    .boolean()
    .required()
    .custom(customValidations.coronaViability2020True),
  [CORONA_TOTAL_ASSETS]: fieldValidators.boolean().required(),
  [CORONA_EXPENDITURE_OF_EMPLOYEES]: fieldValidators.price().required().maxPrice(MAX_AMOUNT_VALUE),
  [CORONA_LIQUIDITY_NEED]: fieldValidators.price().required().maxPrice(MAX_AMOUNT_VALUE),
  [OTHER_PURPOSE_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),
};

export const bfsFinancingNeedFieldValidations = {
  ...financingNeedFieldValidations,

  [GUARANTY_DESCRIPTION]: fieldValidators.string().required().max(MAX_DESCRIPTION_LENGTH),

  [REAL_ESTATE_TOTAL_COST]: fieldValidators.price().required().positive(),

  [REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT]: fieldValidators.price().required().positive(),

  [REAL_ESTATE_PURCHASE_PRICE]: fieldValidators.price().required().positive(),

  [REAL_ESTATE_RECONSTRUCTION_COST]: fieldValidators.price().required().positive(),

  [REAL_ESTATE_FOLLOW_UP_AMOUNT]: fieldValidators.price().required().positive(),

  [VEHICLE_PURCHASE_PRICE]: fieldValidators.price().required().positive(),

  [VEHICLE_COUNT]: fieldValidators
    .number()
    .required()
    .integer()
    .min(InquiryConstants.MIN_VEHICLE_COUNT)
    .max(InquiryConstants.MAX_VEHICLE_COUNT_LESS_THAN_1000),
  [OFFICE_EQUIPMENT_PRICE]: fieldValidators.price().required().positive(),

  [REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(MAX_DESCRIPTION_LENGTH),

  [MACHINE_PRICE]: fieldValidators.price().required().positive(),

  [FINANCING_AMOUNT]: fieldValidators
    .price()
    .minPrice(InquiryConstants.MIN__AMOUNT_5000)
    .maxPrice(MAX_AMOUNT_VALUE)
    .required(),
};

export const bfsServiceFinancingNeedFieldValidations = {
  ...financingNeedFieldValidations,
  [OTHER_PURPOSE_DESCRIPTION]: fieldValidators.string().max(MAX_DESCRIPTION_LENGTH),
  [BFS_SERVICE__COMPANY_OTHER_INDUSTRY]: fieldValidators.string().required().max(MAX_STRING_SIZE),
  [BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING]: fieldValidators
    .string()
    .required()
    .max(MAX_STRING_SIZE),
};
