import React from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import { StyledActions } from 'components/StyledActions';
import SuccessPageHeading from 'components/SuccessPageHeading';
import paths from 'constants/paths';
import FormPageLayout from 'modules/FormPage/FormPageLayout/FormPageLayout';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const RegistrationLinkExpired = () => {
  const t = useTranslations();
  const history = useHistory();

  const redirectToForm = () => history.push(paths.customer.passwordReset.request);

  return (
    <FormPageLayout withBadges={false}>
      <SuccessPageHeading
        success={false}
        iconType="close"
        heading={t('pages.registration.linkExpired')}
        subheading={t('pages.registration.linkExpiredDesc')}
      />

      <StyledActions>
        <ButtonComponent onClick={redirectToForm}>
          {t('pages.requestPasswordReset.heading')}
        </ButtonComponent>
      </StyledActions>
    </FormPageLayout>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_REGISTRATION_ENABLED]: RegistrationLinkExpired,
  default: () => <Redirect to={paths.customer.login} />,
});
