import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  PROPERTY_LOAN_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { FormSections } from 'schema/inquirySchema.models';
import LoanIcon from 'theme/components/Icon/ProductKind/LoanIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validatePropertyLoanSection = validateSectionFields([
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  PROPERTY_LOAN_DESCRIPTION,
]);

const PropertyLoanSection: React.FC = () => {
  const t = useTranslations();
  const options = useInquirySelectOptions(PROPERTY_LOAN_KIND);

  return (
    <FormSection
      name={FormSections.propertyLoan}
      sectionNumber={1}
      title={t('pages.requestDetails.sections.loan.title')}
    >
      <FormRow separator>
        <FinancingAmountSummary icon={<LoanIcon />} />
      </FormRow>
      <FormRow>
        <SelectWithField
          name={PROPERTY_LOAN_KIND}
          options={options}
          data-testid={'select-field-' + PROPERTY_LOAN_KIND}
        />
        <InputWithField name={PROPERTY_LOAN_TERM_IN_MONTHS} optional type="number" />
      </FormRow>

      <FormRow>
        <TextAreaWithField name={PROPERTY_LOAN_DESCRIPTION} />
      </FormRow>
    </FormSection>
  );
};

export default PropertyLoanSection;
