export const switchOptionChecked = (option, multiOptions) =>
  multiOptions.map((singleOption) => {
    if (singleOption.value === option.value) {
      return { ...singleOption, checked: !singleOption.checked };
    }
    return singleOption;
  });

export const markSelectedValuesAsChecked = (options, selectedValues) => {
  if (!selectedValues || !selectedValues.length) {
    return options.map((option) => ({ ...option, checked: false }));
  }

  const values = selectedValues.split(',');

  return options.map((option) =>
    values.some((value) => value === option.value) ? { ...option, checked: true } : option,
  );
};
