import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useTranslations } from 'utils/hooks/useTranslations';

export const bfsValidateProjectDevelopmentSection = validateSectionFields([
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
]);

const BFSProjectDevelopment = () => {
  const t = useTranslations();
  return (
    <FormSection title="" showSectionTitle={false}>
      <FormRow>
        <AmountWithField
          name={REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT}
          placeholder={t(
            'pages.financingNeed.sections.realEstate.fields.realEstateDevelopmentProjectAmount.placeholder',
          )}
        />
      </FormRow>

      <FormRow>
        <TextAreaWithField
          name={REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION}
          placeholder={t(
            `pages.financingNeed.sections.detailsOfBuilding.fields.financialObjectDescription.placeholder.${REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT}`,
          )}
        />
      </FormRow>
    </FormSection>
  );
};

export default BFSProjectDevelopment;
