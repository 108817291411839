import React from 'react';

import { chakra } from '@chakra-ui/react';

import { transition, zIndex } from 'theme/themeConstants';

import type { TableStyleProps } from '../../types';

interface TableRowProps extends TableStyleProps {
  children: React.ReactNode;
  onRowClick: React.MouseEventHandler<HTMLTableRowElement>;
  isVisible: boolean;
}

export const TableRow = ({ isVisible, onRowClick, children }: TableRowProps) => (
  <chakra.tr
    opacity={isVisible ? 1 : 0}
    position="relative"
    zIndex={zIndex.default}
    transition={`color ${transition.default}, background-color ${transition.default}`}
    cursor="pointer"
    borderBottom="1px"
    borderBottomColor="border.lightGrey"
    onClick={onRowClick}
    data-testid="table-row"
    data-group
  >
    {children}
  </chakra.tr>
);

export default TableRow;
