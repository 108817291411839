import React from 'react';

import Separator from 'components/FormRow/Separator';
import FormSection from 'components/FormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldIsValid, fieldIsNotEmpty } from 'modules/Inquiry/Form/formConditions';
import { COMPANY_DETAILS_COMPANY } from 'modules/Inquiry/Form/formFields';
import CompanyNoteSection from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyNoteSection/CompanyNoteSection';
import { useFetchCompanySuggestions } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { SelectPossibleCompanies } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/fields/SelectPossibleCompanies';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateSsoCompanySuggestion = (form: any) => {
  return fieldIsValid(COMPANY_DETAILS_COMPANY)({ form });
};

const Company: React.FC = () => {
  const { companies, changeQuery } = useFetchCompanySuggestions();

  const handleInputChange = (newValue: string) => {
    changeQuery(newValue);
  };
  return <SelectPossibleCompanies companies={companies} onChangeClick={handleInputChange} />;
};

export const SsoCompanySection = () => {
  const t = useTranslations('pages.companyDetails.sections.company');

  return (
    <FormSection sectionNumber={1} title={t('title')}>
      <Company />
      <Separator />
      <Condition condition={fieldIsNotEmpty(COMPANY_DETAILS_COMPANY)}>
        <CompanyNoteSection />
      </Condition>
    </FormSection>
  );
};
