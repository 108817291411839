import React from 'react';

import { Field } from 'react-final-form';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import {
  LOAN_CONSIDER_SUBSIDIES,
  FINANCING_NEED,
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
} from 'modules/Inquiry/Form/formFields';
import { chooseFormValueSpecificComponent } from 'shared/chooseFormValueSpecific';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const LoanConsiderSubsidies: React.FC = () => {
  const t = useTranslations('pages.requestDetails.sections.loan.fields.loanConsiderSubsidies');

  return <YesNoRadioGroupWithField name={LOAN_CONSIDER_SUBSIDIES} tooltip={t('tooltip')} />;
};

const CoronaLoanConsiderSubsidies = () => {
  const { optional } = useFieldValidators();
  return (
    <Field
      validate={optional}
      name={LOAN_CONSIDER_SUBSIDIES}
      render={({ input }) => {
        input.onChange(false);
        return null;
      }}
    />
  );
};

export default chooseFormValueSpecificComponent(
  [
    {
      step: FINANCING_NEED,
      field: PURPOSE_KIND,
      value: PURPOSE_KIND__CORONA,
      component: CoronaLoanConsiderSubsidies,
    },
  ],
  LoanConsiderSubsidies,
);
