import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { hausbankFields, HausbankSections } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { PasswordFields } from 'new/passwordSection/PasswordFields';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const Container = ({ children }: { children: React.ReactNode }) => {
  const t = useTranslations();
  const { title } = translations.inquiryType.hausbank.pages.personalData.sections.password;

  return (
    <Condition<HausbankSections>
      validation={'contactPersonSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={2}
          placeholder={t(translations.inquiryType.hausbank.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={2}>
        {children}
      </FormSection>
    </Condition>
  );
};

export const HausbankPasswordSection = () => {
  const t = useTranslations();
  const { additionalInformation } =
    translations.inquiryType.hausbank.pages.personalData.sections.password.subheadings;
  const { password, passwordConfirmation } = hausbankFields.personalDataPage.passwordSection;

  return (
    <Container>
      <TextComponent color={'text.tertiary'} mb={12}>
        {t(additionalInformation)}
      </TextComponent>
      <PasswordFields passwordName={password} passwordConfirmationName={passwordConfirmation} />
    </Container>
  );
};

export const HausbankLoggedInPasswordSection = () => {
  const t = useTranslations();

  return (
    <Container>
      <FormRow>
        <TextComponent color={'text.tertiary'}>
          {t(translations.inquiryType.hausbank.pages.personalData.sections.password.loggedInText)}
        </TextComponent>
      </FormRow>
    </Container>
  );
};
