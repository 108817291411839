import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { IAssociatedPerson } from 'models/CompanyDetails.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import { getCompanyAssociatedPersons } from '../store/actions';
import { getCompanyAssociatedPersonsSelector } from '../store/selectors';

type PersonType = IAssociatedPerson['type'];

export const useFetchAssociatedPersons = (id?: string, associatedPersonType?: PersonType) => {
  const { makeCall, isPending: isLoading } = useDispatchApiCall({ isPendingInitially: true });
  const data = useSelector(getCompanyAssociatedPersonsSelector);

  useEffect(() => {
    if (id) {
      const fetchAssociatedPersons = async (companyId: string, personType?: PersonType) => {
        await makeCall(getCompanyAssociatedPersons(companyId, personType));
      };
      fetchAssociatedPersons(id, associatedPersonType);
    }
  }, [makeCall, id, associatedPersonType]);

  return { data, isLoading: id ? isLoading : false };
};
