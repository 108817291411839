import React from 'react';

import { useFormState } from 'react-final-form';

import { YearPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import { NewStaticFieldWithField } from 'components/StaticField';
import { HausbankFieldTypes, hausbankFields } from 'hausbank/inquiry/fields';
import {
  MAX_BUILDING_YEARS_SPAN_SHORT,
  MIN_BUILDING_YEAR_DATE,
} from 'modules/Inquiry/inquiry.constants';
import { translations } from 'new/form/common/types';
import { getMaxBuildingYearDate } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getLastModernizationMin } from './helpers';

const {
  wegName,
  street,
  zipCode,
  city,
  country,
  livingSpace,
  commercialUsage,
  propertySize,
  constructionYear,
  lastModernization,
} = hausbankFields.objectInformationPage.objectInformationSection;

export const ObjectInformationSection = () => {
  const t = useTranslations();
  const { values } = useFormState<HausbankFieldTypes>();
  const lastModernizationMin = getLastModernizationMin(values);
  const maxBuildingYearDate = getMaxBuildingYearDate(MAX_BUILDING_YEARS_SPAN_SHORT);

  const {
    title,
    fields: {
      country: { tooltip: countryTooltip },
    },
  } = translations.inquiryType.hausbank.pages.objectInformation.sections.objectInformation;

  return (
    <FormSection title={t(title)} sectionNumber={1}>
      <FormRow>
        <InputWithField name={wegName} />
      </FormRow>
      <FormRow>
        <InputWithField name={street} />
      </FormRow>
      <FormRow>
        <InputWithField name={zipCode} />
        <InputWithField name={city} />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField name={country} tooltip={t(countryTooltip)} />
      </FormRow>
      <FormRow>
        <InputWithField
          name={livingSpace}
          type={INPUT_TYPE_NUMBER}
          sideSymbol={() => t(translations.other.m2)}
        />
        <InputWithField
          name={commercialUsage}
          type={INPUT_TYPE_NUMBER}
          sideSymbol={() => t(translations.other.m2)}
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name={propertySize}
          type={INPUT_TYPE_NUMBER}
          sideSymbol={() => t(translations.other.m2)}
        />
      </FormRow>
      <FormRow>
        <YearPickerWithField
          name={constructionYear}
          maxDate={maxBuildingYearDate}
          minDate={MIN_BUILDING_YEAR_DATE}
        />
        <YearPickerWithField
          name={lastModernization}
          minDate={lastModernizationMin}
          maxDate={maxBuildingYearDate}
          optional
        />
      </FormRow>
    </FormSection>
  );
};
