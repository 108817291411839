import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  beneficialOwners: {
    birthDate,
    birthPlace,
    placeOfResidence,
    countryOfResidence,
    capitalOrVotingRightsPercentage,
    nationality,
    lastName,
    firstName,
    fictional,
  },
  allInformationAvailable,
} = dzbFields.personalDataPage.beneficialOwnersSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.personalData.sections.beneficialOwners;
const beneficialOwnerFields =
  translationObject.pages.companiesDetails.associatedPerson.new.beneficialOwners.fields;

export const beneficialOwnersSectionLabelsMap: LabelsMap = {
  [allInformationAvailable]: fieldTranslations.allInformationAvailable.caption,
  [firstName]: beneficialOwnerFields.firstName.caption,
  [lastName]: beneficialOwnerFields.lastName.caption,
  [nationality]: beneficialOwnerFields.nationality.caption,
  [capitalOrVotingRightsPercentage]: beneficialOwnerFields.capitalOrVotingRightsPercentage.caption,
  [countryOfResidence]: beneficialOwnerFields.countryOfResidence.caption,
  [placeOfResidence]: beneficialOwnerFields.placeOfResidence.caption,
  [birthPlace]: beneficialOwnerFields.birthPlace.caption,
  [birthDate]: beneficialOwnerFields.birthDate.caption,
  [fictional]: beneficialOwnerFields.fictional.caption,
};
