import { feToBeMappingKeys } from 'mmv/inquiry/config/feToBeMappingKeys';
import { stepData } from 'mmv/inquiry/config/stepData';
import { stepSequence } from 'mmv/inquiry/config/stepSequence';
import { summaryFields } from 'mmv/inquiry/config/summaryFields';
import { PortalConfig } from 'new/portals/state/portalConfigContext/types';

import { FieldTypes, mmvFields } from '../inquiry/fields';

export const portalConfig: PortalConfig<FieldTypes> = {
  stepSequence: stepSequence,
  stepData: stepData,
  summaryFields: summaryFields,
  feToBeMappingKeys: feToBeMappingKeys,
  fields: mmvFields,
};
