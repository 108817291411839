import React from 'react';

import PropTypes from 'prop-types';

import { FILE_BLOCK_COLORS } from 'components/FileBlock/constants';
import {
  StyledFileBlock as Block,
  StyleFileBlockCircle as CircleBorder,
  StyleFileBlockTitle as Title,
  StyledOptional as Optional,
} from 'components/FileBlock/styles';
import intlShape from 'shared/intlShape';
import { withIntl } from 'utils/intl';

const FileBlock = ({
  title,
  icon,
  color,
  children,
  dashed,
  spinningBorder,
  intl: { formatMessage },
  required,
}) => (
  <Block>
    {!required && <Optional>{formatMessage({ id: 'other.optional' })}</Optional>}
    <Title>{title}</Title>
    <CircleBorder spinning={spinningBorder} dashed={dashed} className={color}>
      {icon}
    </CircleBorder>
    {children}
  </Block>
);

FileBlock.defaultProps = {
  color: null,
  dashed: false,
  children: null,
  spinningBorder: false,
  required: true,
};

FileBlock.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.oneOf(FILE_BLOCK_COLORS),
  children: PropTypes.node,
  dashed: PropTypes.bool,
  spinningBorder: PropTypes.bool,
  required: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default withIntl(FileBlock);
