import React, { forwardRef } from 'react';

import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';

export const SelectInput = forwardRef<HTMLInputElement, { toggleButton: React.ReactNode }>(
  ({ toggleButton, ...props }, ref) => (
    <InputGroup>
      <Input
        {...props}
        ref={ref}
        py={8}
        px={4}
        border="2px solid"
        borderColor="border.lightGrey"
        bg="background.white"
        focusBorderColor="brand.default"
      />
      <InputRightElement
        children={toggleButton}
        pt={8}
        pr={4}
        cursor="pointer"
        color="text.tertiary"
      />
    </InputGroup>
  ),
);
