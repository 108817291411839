import { AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { RESET_FORM } from 'store/progress/actionTypes';
import { KEY_INQUIRY_PROCESS } from 'utils/sessionStorage/keys';
import { getUpdateState } from 'utils/storeHelpers';

import {
  SET_REFERRAL_WIDGET_CODE,
  SET_LEAD_ID,
  SET_INQUIRY_TYPE,
  SET_PROCESS_LANE,
  CLEAR_LEAD_ID,
  SET_CAN_SEND_LEAD,
  SET_MULTIPLIER_ORGANIZATION_ID,
  SET_EXTERNAL_REFERENCE_ID,
  CREATE_INQUIRY_DRAFT_SUCCESS,
  SET_INQUIRY_DRAFT,
  GET_INQUIRY_FILES_SUCCESS,
  CLEAR_INQUIRY_DRAFT,
} from './actionTypes';

export interface IInquiryProcess {
  referralWidgetCode?: string;
  leadId?: string;
  canSendLead: boolean;
  multiplierOrganizationId?: string;
  externalReferenceId?: string;
  inquiryDraftId?: string;
  inquiryType?: InquiryType;
  processLane?: InquiryLane;
  requiredFiles: string[];
}

const INITIAL_STATE: IInquiryProcess = {
  canSendLead: true,
  requiredFiles: [],
};

const reducer = (state: IInquiryProcess = INITIAL_STATE, action: AnyAction) => {
  const updateState = getUpdateState<IInquiryProcess>(state);
  switch (action.type) {
    case SET_REFERRAL_WIDGET_CODE: {
      return { ...state, referralWidgetCode: action.code };
    }
    case SET_LEAD_ID: {
      return {
        ...state,
        leadId: action.leadId,
      };
    }
    case SET_INQUIRY_TYPE: {
      return {
        ...state,
        inquiryType: action.inquiryType,
      };
    }
    case SET_PROCESS_LANE: {
      return {
        ...state,
        processLane: action.processLane ?? InquiryLane.lead,
      };
    }
    case CLEAR_LEAD_ID: {
      return {
        ...state,
        leadId: null,
      };
    }
    case SET_CAN_SEND_LEAD: {
      return {
        ...state,
        canSendLead: action.canSendLead,
      };
    }

    case SET_MULTIPLIER_ORGANIZATION_ID: {
      return {
        ...state,
        multiplierOrganizationId: action.midId,
      };
    }

    case SET_EXTERNAL_REFERENCE_ID: {
      return {
        ...state,
        externalReferenceId: action.externalReferenceId,
      };
    }

    case CREATE_INQUIRY_DRAFT_SUCCESS:
      return {
        ...state,
        inquiryDraftId: action.payload.data.data.id,
      };

    case SET_INQUIRY_DRAFT:
      return {
        ...state,
        inquiryDraftId: action.inquiryDraftId,
      };

    case CLEAR_INQUIRY_DRAFT:
      return {
        ...state,
        inquiryDraftId: null,
      };

    case RESET_FORM:
      return updateState(INITIAL_STATE);

    case GET_INQUIRY_FILES_SUCCESS:
      return {
        ...state,
        requiredFiles: action.payload.data.data,
      };

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: KEY_INQUIRY_PROCESS,
  storage,
};

export default persistReducer(persistConfig, reducer);
