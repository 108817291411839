import React from 'react';

import PropTypes from 'prop-types';

import { StyledInputWrapper as InputWrapper } from 'components/PortalFormFields/InputFields/styles';

const StringInput = ({ value, name, placeholder, onChange, hasError }) => {
  const handleChange = (ev) => onChange(ev.target.value);
  return (
    <InputWrapper hasError={hasError}>
      <input
        type="text"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </InputWrapper>
  );
};

StringInput.defaultProps = {
  value: '',
  placeholder: null,
  hasError: false,
  onChange: () => null,
};

StringInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
};

export default StringInput;
