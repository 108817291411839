import normalize from 'json-api-normalizer';
import _values from 'lodash/values';

import { removeNullValuesRecursively } from 'utils/removeNullValuesRecursively';

import { HausbankFieldTypes } from '../fields';

/**
 * Using any for the types here as the type from the API does not match the type from the FE. Additionally, some numbers
 * are strings or boolean are strings so these types do not help anyway. Internally everything is converted to the
 * correct type.
 */

interface HomeownerAssociation {
  name: any;
  street: any;
  zipCode: any;
  city: any;
  country: any;
  livingSpace: any;
  commercialUsage: any;
  propertySize: any;
  constructionYear: any;
  lastModernization: any;
  residentialNumberOfUnits: any;
  commercialNumberOfUnits: any;
  moreThanOneThirdOfShares: any;
  propertyDeveloper: any;
  ownerShares: any;
  leaseholdProperty: any;
  majorityDecision: any;
  subcommunity: any;
}

interface InquiryDetails {
  formType: any;
  loanTerm: any;
  purposeKind: any;
  financingAmount: any;
  investmentAmount: any;
  projectDescription: any;
  reservedForMaintenance: any;
  homeownerAssociation: HomeownerAssociation;
  constructionSubsidies: any;
  dateOfOwnersMeeting: any;
  contributionOfHomeAssociation: any;
}

interface Inquiry {
  id: any;
  type: any;
  attributes: {
    compeonStatus: any;
    createdAt: any;
    updatedAt: any;
    formType: any;
    welcomeCallPerformedAt: any;
    insuranceOfferRequestedAt: any;
    sentToCompeonAt: any;
    externalReference: any;
    expirationInDays: any;
    videoIdentUrl: any;
    customerLegalRepresentativeId: any;
    status: any;
    substatus: any;
    externalId: any;
    details: InquiryDetails;
    videoIdentStatus: any;
    customerIdentificationType: any;
    legalRepresentativesRequireManualMerging: any;
    customersSoleSignatureAuthorized: any;
    allRequiredFilesUploaded: any;
    collaterals: any;
    completedSignsCount: any;
    currentTypeOfUsage: any;
    futureTypeOfUsage: any;
    isQuickCheckStarted: any;
  };
  relationships: {
    customer: {
      data: {
        id: any;
        type: any;
      };
    };
    endCustomer: {
      data: any;
    };
    company: {
      data: {
        id: any;
        type: any;
      };
    };
    bankAdvisor: {
      data: any;
    };
    inquiryManager: {
      data: any;
    };
    realEstateExpert: {
      data: any;
    };
    offers: {
      data: any[];
    };
    legalRepresentatives: {
      data: {
        id: any;
        type: any;
      }[];
    };
    videoIdentifications: {
      data: any[];
    };
    indicativeCondition: {
      data: any;
    };
    transparencyRegister: {
      data: any;
    };
  };
}

interface Customer {
  id: any;
  type: any;
  attributes: {
    adpRootNumber: any;
    archived: any;
    birthDate: any;
    email: any;
    existsInCompeon: any;
    firstName: any;
    lastName: any;
    locale: any;
    phoneNumber: any;
    salutation: any;
    structureNumber: any;
    vbNumber: any;
    zipCode: any;
    invitationAccepted: any;
    invitationSent: any;
  };
}

interface Company {
  id: any;
  type: any;
  attributes: {
    street: any;
    city: any;
    crefoId: any;
    legalFormDescription: any;
    name: any;
    zipCode: any;
    foundingYear: any;
    alreadyCustomer: any;
    compeonId: any;
    revenueSurplusAccount: any;
    kycReportsRequestedAt: any;
    turnoverClass: any;
    legalForm: any;
    industry: any[];
    accountIban: any;
    additionalDetails: {
      unitCountUnderControl: any;
    };
    countOfEmployees: any;
    detailedBalanceSheet: any;
    detailedAnnualTurnover: any;
  };
  relationships: {
    kycCompanies: {
      data: any[];
    };
  };
}

interface LegalRepresentative {
  id: any;
  type: any;
  attributes: {
    additionalInfo: {
      soleSignatureAuthorized: any;
    };
    address: any;
    birthDate: any;
    birthName: any;
    birthPlace: any;
    city: any;
    country: any;
    email: any;
    firstName: any;
    lastName: any;
    function: any;
    phoneNumber: any;
    powerOfRepresentation: any;
    salutation: any;
    source: any;
    successfullyMerged: any;
    zipCode: any;
    createdAt: any;
    potentiallyManipulated: any;
  };
}

interface NormalizedResponse {
  companies: Record<string, Company>;
  customers: Record<string, Customer>;
  inquiries: Record<string, Inquiry>;
  legalRepresentatives: Record<string, LegalRepresentative>;
}

interface HausbankMappingFieldTypes {
  objectInformationPage: HausbankFieldTypes['objectInformationPage'];
  companyDataPage: Omit<
    HausbankFieldTypes['companyDataPage'],
    'wegSearchSection' | 'termsAndConditionsSection'
  >;
  financingNeedPage: HausbankFieldTypes['financingNeedPage'];
  personalDataPage: Omit<HausbankFieldTypes['personalDataPage'], 'passwordSection'>;
}

export const hausbankFeTobeMapping = (beResponse: any): HausbankFieldTypes => {
  const normalizedResponse: NormalizedResponse = normalize(beResponse, {
    camelizeKeys: true,
  });

  const company = _values(normalizedResponse.companies)[0];
  const customer = _values(normalizedResponse.customers)[0];
  const inquiry = _values(normalizedResponse.inquiries)[0];
  const inquiryDetails = inquiry.attributes.details;
  const companyAdditionalDetails = company.attributes.additionalDetails;
  const legalRepresentatives = _values(normalizedResponse.legalRepresentatives);

  const mappedObject: HausbankMappingFieldTypes = {
    objectInformationPage: {
      objectInformationSection: {
        wegName: inquiryDetails.homeownerAssociation.name,
        street: inquiryDetails.homeownerAssociation.street,
        zipCode: inquiryDetails.homeownerAssociation.zipCode,
        city: inquiryDetails.homeownerAssociation.city,
        country: inquiryDetails.homeownerAssociation.country,
        livingSpace: inquiryDetails.homeownerAssociation.livingSpace,
        commercialUsage: inquiryDetails.homeownerAssociation.commercialUsage,
        propertySize: inquiryDetails.homeownerAssociation.propertySize,
        constructionYear: inquiryDetails.homeownerAssociation.constructionYear,
        lastModernization: inquiryDetails.homeownerAssociation.lastModernization,
      },
      projectInformationSection: {
        residentialUnitsCount: inquiryDetails.homeownerAssociation.residentialNumberOfUnits,
        commercialUnitsCount: inquiryDetails.homeownerAssociation.commercialNumberOfUnits,
        hasOwnerWithMoreThanThirdOfShares:
          inquiryDetails.homeownerAssociation.moreThanOneThirdOfShares,
        isContractor: inquiryDetails.homeownerAssociation.propertyDeveloper,
        majorityOwnerShares: inquiryDetails.homeownerAssociation.ownerShares,
        wegIsSubCommunity: inquiryDetails.homeownerAssociation.subcommunity,
        propertyIsLeasehold: inquiryDetails.homeownerAssociation.leaseholdProperty,
        hasMajorityDecision: inquiryDetails.homeownerAssociation.majorityDecision,
      },
    },
    companyDataPage: {
      wegDataCompletionSection: {
        name: company.attributes.name,
        street: company.attributes.street,
        zipCode: company.attributes.zipCode,
        city: company.attributes.city,
        legalForm: company.attributes.legalForm,
        managedUnits: companyAdditionalDetails.unitCountUnderControl,
        foundingYear: company.attributes.foundingYear,
        isExistingCustomer: company.attributes.alreadyCustomer,
      },
    },
    financingNeedPage: {
      financingNeedSection: {
        investmentAmount: inquiryDetails.investmentAmount,
        maintenanceReserve: inquiryDetails.reservedForMaintenance,
        wegContribution: inquiryDetails.contributionOfHomeAssociation,
        constructionSubsidies: inquiryDetails.constructionSubsidies,
        financingAmount: inquiryDetails.financingAmount,
        loanTermInYears: inquiryDetails.loanTerm,
        ownersMeetingDate: inquiryDetails.dateOfOwnersMeeting,
      },
      purposeSection: {
        purposeKind: inquiryDetails.purposeKind,
        projectDescription: inquiryDetails.projectDescription,
      },
    },
    personalDataPage: {
      contactPersonSection: {
        salutation: customer.attributes.salutation,
        firstName: customer.attributes.firstName,
        lastName: customer.attributes.lastName,
        email: customer.attributes.email,
        phoneNumber: customer.attributes.phoneNumber,
      },
      legalRepresentativesSection: {
        legalRepresentatives: legalRepresentatives.map((legalRep) => {
          const attributes = legalRep.attributes;

          return {
            id: legalRep.id,
            salutation: attributes.salutation,
            firstName: attributes.firstName,
            lastName: attributes.lastName,
            birthDate: attributes.birthDate,
            email: attributes.email,
            phoneNumber: attributes.phoneNumber,
            soleSignatureAuthorized: attributes.additionalInfo.soleSignatureAuthorized,
          };
        }),
      },
    },
  };

  return removeNullValuesRecursively(mappedObject) as HausbankFieldTypes;
};
