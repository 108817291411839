import {
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
  USER_SOLE_REPRESENTATION,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_BIRTHDATE,
  USER_EXISTING_REPRESENTATIVE,
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  BENEFICIARY_OWNER,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_ZIP_CODE,
  EXISTING_BENEFICIARY,
  REPRESENTATIVE_PHONE_NUMBER,
  BENEFICIARY_TAX_ID,
  SELF_BENEFICIARY_OWNER,
} from 'modules/Inquiry/Form/formFields';
import {
  IBeneficiaryOwnersTypes,
  ILeaseplanBeneficiaryOwners,
  ILeaseplanLegalRepresentative,
  ILeaseplanLegalRepresentativeObjTypes,
} from 'pages/operationPortal/InquiryDetails/Dashboard/LegalRepresentativeCorrection/types';

import { MultiSelectOption } from '../../Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { fieldParsers } from '../fieldParsers';

const { toBoolean } = fieldParsers;

export const mapBFSServicePeopleDetailsToApi = (allFields: any) => {
  // Legal representative values defination
  const userLegalRepresentation = toBoolean(allFields[USER_SOLE_REPRESENTATION]);
  let legalRepresentatives: Array<ILeaseplanLegalRepresentative> = [];

  const userRepresentatives = allFields[USER_REPRESENTATIVE];
  const existingUserRepresentatives = allFields[USER_EXISTING_REPRESENTATIVE];

  // Beneficiary Owners values defination
  let beneficiaryOwnersList: Array<ILeaseplanBeneficiaryOwners> = [];
  const beneficiaryOwners = allFields[BENEFICIARY_OWNER];
  const beneficiaryOwnership = toBoolean(allFields[HAS_BENEFICIARY_OWNER]);
  const userBeneficiaryOwnership = toBoolean(allFields[IS_BENEFICIARY_OWNER]);
  // Passed when user is beneficiary
  const existingBeneficiary = allFields[EXISTING_BENEFICIARY];

  // Map existing and new legal representative
  if ((userRepresentatives || existingUserRepresentatives) && !userLegalRepresentation) {
    legalRepresentatives = userRepresentatives
      ? userRepresentatives.map((people: ILeaseplanLegalRepresentativeObjTypes) => {
          const phoneNumber =
            people[REPRESENTATIVE_PHONE_NUMBER] === '' ? null : people[REPRESENTATIVE_PHONE_NUMBER];
          if (
            existingUserRepresentatives &&
            existingUserRepresentatives.some(
              (existingUserRepresentative: MultiSelectOption) =>
                existingUserRepresentative.key === people.id,
            )
          )
            return {
              id: people.id,
              phone_number: phoneNumber,
            };
          else {
            return {
              salutation: people[REPRESENTATIVE_GENDER],
              first_name: people[REPRESENTATIVE_FIRST_NAME],
              last_name: people[REPRESENTATIVE_LAST_NAME],
              email: people[REPRESENTATIVE_EMAIL],
              birth_date: people[REPRESENTATIVE_BIRTHDATE],
              phone_number: phoneNumber,
            };
          }
        })
      : [];

    legalRepresentatives = [...legalRepresentatives];
  }

  const mapCompleteBeneficiary = (
    beneficiary: IBeneficiaryOwnersTypes,
    isSelf: boolean = false,
  ) => ({
    ...(isSelf
      ? {
          id: beneficiary.id,
        }
      : {
          salutation: beneficiary[BENEFICIARY_GENDER],
          first_name: beneficiary[BENEFICIARY_FIRST_NAME],
          last_name: beneficiary[BENEFICIARY_LAST_NAME],
        }),
    birth_date: beneficiary[BENEFICIARY_BIRTHDAY],
    tax_id: beneficiary[BENEFICIARY_TAX_ID],
    address: beneficiary[BENEFICIARY_PRIVATE_ADDRESS],
    zip_code: beneficiary[BENEFICIARY_ZIP_CODE],
    city: beneficiary[BENEFICIARY_PRIVATE_CITY],
    birth_place: beneficiary[BENEFICIARY_BIRTH_COUNTRY],
    company_share: beneficiary[BENEFICIARY_COMPANY_SHARE],
    country: beneficiary[BENEFICIARY_PRIVATE_COUNTRY],
  });

  // Map new beneficiary owners
  if (beneficiaryOwnership) {
    if (beneficiaryOwners && (beneficiaryOwners || existingBeneficiary)) {
      beneficiaryOwnersList = beneficiaryOwners.map(
        (beneficiary: IBeneficiaryOwnersTypes, index: number) => {
          if (
            existingBeneficiary &&
            existingBeneficiary.some(
              (existingBeneficiaryUser: IBeneficiaryOwnersTypes) =>
                existingBeneficiaryUser.id === beneficiary.id,
            )
          ) {
            return {
              id: beneficiary.id,
              company_share: beneficiary[BENEFICIARY_COMPANY_SHARE],
            };
          } else {
            return mapCompleteBeneficiary(beneficiary);
          }
        },
      );
    }

    if (userBeneficiaryOwnership) {
      let selfBeneficiaries = [];
      selfBeneficiaries = allFields[SELF_BENEFICIARY_OWNER].map(
        (beneficiary: IBeneficiaryOwnersTypes) =>
          mapCompleteBeneficiary({ ...beneficiary, id: allFields.customerId }, true),
      );
      beneficiaryOwnersList = [...selfBeneficiaries, ...beneficiaryOwnersList];
    }
  }

  return {
    'legal-representation': allFields[USER_REPRESENTATION],
    user_legal_representation: userLegalRepresentation ? userLegalRepresentation : 'false',
    legal_representatives: legalRepresentatives,
    beneficiary_ownership: beneficiaryOwnership,
    user_beneficiary_ownership: userBeneficiaryOwnership,
    'beneficiary-owners': beneficiaryOwnersList,
  };
};
