import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const OfficeEquipmentIcon = (props: IconProps) => {
  return (
    <Icon
      width="86"
      height="71"
      viewBox="0 0 86 71"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M7.75 24h4.5c.41 0 .75-.45.75-1s-.34-1-.75-1h-4.5c-.41 0-.75.45-.75 1s.34 1 .75 1ZM7.75 30h4.5c.41 0 .75-.45.75-1s-.34-1-.75-1h-4.5c-.41 0-.75.45-.75 1s.34 1 .75 1ZM7.75 37h4.5c.41 0 .75-.45.75-1s-.34-1-.75-1h-4.5c-.41 0-.75.45-.75 1s.34 1 .75 1ZM33.25 22h-4.5c-.41 0-.75.45-.75 1s.34 1 .75 1h4.5c.41 0 .75-.45.75-1s-.34-1-.75-1Z" />
      <path d="M76.27 55.31v-5.7h1.88a.69.69 0 0 0 .7-.69V35.06a2.7 2.7 0 0 0-5.3-.69h-6.34a.69.69 0 1 0 0 1.38h6.24v12.48h-19v-9.67a2.84 2.84 0 0 1 2.86-2.81H64a.69.69 0 1 0 0-1.38h-6.7a4.23 4.23 0 0 0-4.3 4.19v.19h-7v-21.5a.75.75 0 0 0-.74-.75h-9.81l-.37-1.5h5.36A2.61 2.61 0 0 0 43 12.38V2.62A2.61 2.61 0 0 0 40.44 0H21.89a2.61 2.61 0 0 0-2.6 2.62v9.76a2.61 2.61 0 0 0 2.6 2.62h5.35l-.37 1.5H13.35V6.75a.75.75 0 0 0-.74-.75h-8.9a.75.75 0 0 0-.71.75v9.75H.74a.75.75 0 0 0-.74.75v24a.75.75 0 0 0 .74.75H3a.75.75 0 0 0 .74-.75V39h11.84v2.25a.75.75 0 0 0 .74.75h2.23a.75.75 0 0 0 .74-.75V27h23v9l-5.89-1.78v-3.56a2.7 2.7 0 0 0-5.4 0v6.47a.69.69 0 0 0 .7.69.7.7 0 0 0 .71-.69v-6.47a1.29 1.29 0 0 1 2.58 0V45a.7.7 0 0 0 .71.69H53v2.55H32.41v-7.9a.7.7 0 0 0-.71-.7.7.7 0 0 0-.7.7v8.59a.69.69 0 0 0 .7.69h2.61v5.7a3.44 3.44 0 1 0 1.41 0v-2.2h39.14v2.2a3.44 3.44 0 1 0 1.41 0Zm-1.41-20.25a1.3 1.3 0 0 1 2.59 0v13.17h-2.59ZM44.52 38a2.87 2.87 0 0 0-.75-1.22V21h.75Zm-.75 2.1h.75v.37h-.75Zm-23-37.51a1.12 1.12 0 0 1 1.12-1.09h18.55a1.12 1.12 0 0 1 1.11 1.12v8.63H20.77Zm1.12 10.91a1.12 1.12 0 0 1-1.05-.75h20.64a1.1 1.1 0 0 1-1 .75Zm12 3H28.4l.37-1.5h4.78Zm-25-9h3v9h-3Zm-4.45 0h3v9h-3Zm-2.22 33h-.74V21h.75Zm13.35-3H3.71V33h11.87Zm0-6H3.71V27h11.87Zm0-6H3.71V21h11.87Zm2.23 15h-.75V21h.75Zm-16.32-21V18h43v1.5Zm17.81 6V21h23v4.5ZM36.4 35.67l5.89 1.81v1.27H36.4Zm0 8.63v-4.17h5.89v1.12A.75.75 0 0 0 43 42h2.23a.75.75 0 0 0 .74-.75v-1.12h7v4.17Zm.6 14.32a2 2 0 1 1-2-2 2 2 0 0 1 2 2Zm-1.33-6.89v-2.12h39.19v2.12Zm39.85 8.89a2 2 0 1 1 2-2 2 2 0 0 1-1.96 2Z" />
    </Icon>
  );
};

export default OfficeEquipmentIcon;
