import { Dropdowns } from 'config/formConfig/types';
import { FormatMessage, decorateLocalizationDropdownOptions } from 'utils/intl';

export const getRealEstateFinancingObjectsOptions = (
  formatMessage: FormatMessage,
  dropdowns: Dropdowns,
) => decorateLocalizationDropdownOptions(dropdowns.financingObjects.realEstate, formatMessage);

export const getPurchaseFinancingObjectsOptions = (
  formatMessage: FormatMessage,
  dropdowns: Dropdowns,
) => decorateLocalizationDropdownOptions(dropdowns.financingObjects.purchase, formatMessage);

export const getMachineCategoriesOptions = (formatMessage: FormatMessage, dropdowns: Dropdowns) =>
  decorateLocalizationDropdownOptions(dropdowns.machineCategories, formatMessage);
