import React, { useMemo } from 'react';

import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';

import FormRowWrapper from 'components/FormRow/FormRowWrapper';
import HiddenInput from 'components/HiddenInput';
import StaticField from 'components/StaticField';
import { USER_PROFILE_INDUSTRY } from 'modules/Inquiry/Form/formFields';
import { getSelectTranslations } from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/service';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { useTranslations } from 'utils/hooks/useTranslations';

const Static = () => {
  const { formatMessage } = useIntl();
  const t = useTranslations(
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileIndustry',
  );
  const industries = useGetSelectedCompanyParam(COMPANY_FIELDS.industry) || [];
  const industryTranslations = useMemo(() => getSelectTranslations(formatMessage), [formatMessage]);
  const mappedIndustries = industries.map((key) => industryTranslations[key] || key);

  const industriesAsString = mappedIndustries.join(', ');

  return (
    <FormRowWrapper>
      <StaticField caption={t('caption')} text={industriesAsString} />
      <Field
        name={USER_PROFILE_INDUSTRY}
        render={({ input, meta }) => {
          const value = Array.isArray(input.value) ? input.value[0] : input.value;
          return <HiddenInput {...input} {...meta} value={value} />;
        }}
      />
    </FormRowWrapper>
  );
};

export default Static;
