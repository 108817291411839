import {
  OFFICE_EQUIPMENT_PRICE,
  OFFICE_EQUIPMENT_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger } = fieldParsers;
export const mapOfficeEquipmentToApi: InquiryFormMapper = (allFields: any) => {
  return {
    'office-equipment-purchase-price': toInteger(allFields[OFFICE_EQUIPMENT_PRICE]),
    'office-equipment-description': allFields[OFFICE_EQUIPMENT_DESCRIPTION],
  };
};
