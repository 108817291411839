import { mittweidaFields } from 'mittweida/inquiry/fields';
import {
  customValidations,
  fieldValidators,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { isOnlyLettersRegex } from 'utils/regexes';

const { gender, firstName, lastName, email, phone } =
  mittweidaFields.userProfilePage.contactPersonSection;

export const contactPersonSectionValidationMap = {
  [gender]: fieldValidators.string().required(),
  [firstName]: fieldValidators.string().regex(isOnlyLettersRegex).required(),
  [lastName]: fieldValidators.string().regex(isOnlyLettersRegex).required(),
  [email]: fieldValidators.string().required().custom(customValidations.isEmail),
  [phone]: fieldValidators
    .phoneNumber()
    .required()
    .hasPhonePrefix()
    .isPhoneNumber()
    .maxPhoneNumberLength()
    .minPhoneNumberLength(),
};
