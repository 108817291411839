import React from 'react';

import { FieldInputProps } from 'react-final-form';

import FormSection from 'components/FormSection';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  CLIENT_ROLE,
  CLIENT_ROLE__COMPANY,
  CLIENT_ROLE__CONTRACTOR,
} from 'modules/Inquiry/Form/formFields';
import PurposeKindList from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/PurposeKindList/PurposeKindList';
import DebtReschedulingIcon from 'theme/components/Icon/Purposes/DebtReschedulingIcon';
import OverdraftLiquidityIcon from 'theme/components/Icon/Purposes/OverdraftLiquidityIcon';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateClientRoleSection = validateSectionFields([CLIENT_ROLE]);

interface Role {
  key: typeof CLIENT_ROLE__COMPANY | typeof CLIENT_ROLE__CONTRACTOR;
  title: string;
  icon: JSX.Element;
}

const ClientRoleSectionAdapter = ({ input, onChange }: FieldInputProps<HTMLInputElement>) => {
  const t = useTranslations('inquiryType.energyEfficiency.steps.purpose.sections.clientRole');

  const roles: Role[] = [
    {
      key: CLIENT_ROLE__COMPANY,
      title: t('company'),
      icon: <OverdraftLiquidityIcon />,
    },
    {
      key: CLIENT_ROLE__CONTRACTOR,
      title: t('contractor'),
      icon: <DebtReschedulingIcon />,
    },
  ];

  const handlePurposeSelect = (purposeKey: string) => {
    onChange(purposeKey);
  };

  return (
    <FormSection title={t('caption')} sectionNumber={1}>
      <PurposeKindList
        name={CLIENT_ROLE}
        purposes={roles}
        onSelectedPurpose={handlePurposeSelect}
        selectedPurpose={input.value}
      />
    </FormSection>
  );
};

export const ClientRoleSection = withFormField(ClientRoleSectionAdapter);
