import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Page from 'components/Page/Page';
import PageHeading from 'components/PageHeading';
import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS } from 'constants/partner';
import paths from 'constants/paths';
import { clearFormData } from 'shared/inquiry/inquirySessionStorage.helpers';
import { clearStages } from 'store/progress/actions';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';
import { webTrekkPushUpdate } from 'utils/tracker/webtrekk';

export interface ErrorPageProps {
  status: 403 | 404 | 500 | 'default';
}

const ErrorPage = ({ status }: ErrorPageProps) => {
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const history = useHistory();
  const t = useTranslations();
  const errorText = t('pages.errorPage.error');
  const wasPartnerLoaded = Boolean(partnerId);

  useEffect(() => {
    webTrekkPushUpdate();
  }, []);

  const handleClick = () => {
    clearFormData();
    clearStages();
    history.push(paths.financingNeed);
  };

  switch (status) {
    case 404:
      return (
        <Page>
          <PageHeading
            error
            heading={`${errorText} 404`}
            subheading={t('pages.errorPage.statuses.status404')}
          />
          {wasPartnerLoaded &&
            partnerId !== PARTNERS.RENTENBANK &&
            partnerId !== PARTNERS.AGRICULTURAL && (
              <ButtonComponent
                display="flex"
                leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                m="0 auto"
                onClick={handleClick}
              >
                {t('pages.errorPage.buttons.startNewInquiry')}
              </ButtonComponent>
            )}
        </Page>
      );
    case 500:
      return (
        <Page>
          <PageHeading
            error
            heading={`${errorText} 500`}
            subheading={t('pages.errorPage.statuses.status500')}
          />
          {wasPartnerLoaded && (
            <ButtonComponent
              display="flex"
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              m="0 auto"
              onClick={handleClick}
            >
              {t('pages.errorPage.buttons.startNewInquiry')}
            </ButtonComponent>
          )}
        </Page>
      );
    case 403:
      return (
        <Page>
          <PageHeading
            error
            heading={`${errorText} 403`}
            subheading={t('pages.errorPage.statuses.status403')}
          />
          {wasPartnerLoaded && (
            <ButtonComponent
              display="flex"
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              m="0 auto"
              onClick={handleClick}
            >
              {t('pages.errorPage.buttons.startNewInquiry')}
            </ButtonComponent>
          )}
        </Page>
      );
    case 'default':
      return (
        <Page>
          <PageHeading error heading={t('pages.errorPage.statuses.statusDefault')} />
          {wasPartnerLoaded && (
            <ButtonComponent
              display="flex"
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              m="0 auto"
              onClick={history.goBack}
            >
              {t('pages.errorPage.buttons.continueForm')}
            </ButtonComponent>
          )}
        </Page>
      );
    default: {
      const assertion: never = status;
      return assertion;
    }
  }
};

export default ErrorPage;
