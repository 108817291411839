import { dzbFields } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const validateOnlineQuestionnaireSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: onlineQuestionnaireSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  shareOfOnlineSalesPercentage,
  othersPercentage,
  zalandoPercentage,
  foreignLogisticsServiceProviderPercentage,
  foreignWarehousePercentage,
  ownWarehousePercentage,
  foreignFulfillmentByAmazonPercentage,
  amazonPercentage,
  ownOnlineShopPercentage,
  ebayPercentage,
  returnRatePercentage,
  averageShoppingBasketAmount,
  shareOfForeignLogistics,
  shareOfDistributionChannels,
  shareOfWarehouse,
} = dzbFields.retailerDataPage.onlineQuestionnaireSection;

export const onlineQuestionnaireSectionValidationMap = {
  [shareOfOnlineSalesPercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [othersPercentage]: fieldValidators.number().required().max(InquiryConstants.MAX_PERCENT_VALUE),
  [zalandoPercentage]: fieldValidators.number().required().max(InquiryConstants.MAX_PERCENT_VALUE),
  [foreignLogisticsServiceProviderPercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [foreignWarehousePercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [ownWarehousePercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [foreignFulfillmentByAmazonPercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [amazonPercentage]: fieldValidators.number().required().max(InquiryConstants.MAX_PERCENT_VALUE),
  [ownOnlineShopPercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [ebayPercentage]: fieldValidators.number().required().max(InquiryConstants.MAX_PERCENT_VALUE),
  [returnRatePercentage]: fieldValidators
    .number()
    .required()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [averageShoppingBasketAmount]: fieldValidators.number().required(),
  [shareOfDistributionChannels]: fieldValidators
    .number()
    .min(InquiryConstants.MAX_PERCENT_VALUE)
    .max(InquiryConstants.MAX_PERCENT_VALUE)
    .required(),
  [shareOfForeignLogistics]: fieldValidators
    .number()
    .min(InquiryConstants.MAX_PERCENT_VALUE)
    .max(InquiryConstants.MAX_PERCENT_VALUE)
    .required(),
  [shareOfWarehouse]: fieldValidators
    .number()
    .min(InquiryConstants.MAX_PERCENT_VALUE)
    .max(InquiryConstants.MAX_PERCENT_VALUE)
    .required(),
};
