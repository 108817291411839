import { useTranslations } from 'utils/hooks/useTranslations';

import { CustomerConfirmationStatus } from './constants';

export const useCustomerConfirmationStatus = (status: string) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.summary.customerConfirmationStatus');

  const statusTextMap: Record<string, string> = {
    [CustomerConfirmationStatus.CONFIRMED]: t('confirmed'),
    [CustomerConfirmationStatus.CONFIRMATION_PENDING]: t('confirmationPending'),
  };

  return statusTextMap[status];
};
