import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import Spinner from 'components/Spinner';
import { IInternalFile } from 'models/File.model';
import { useFetchInquiryInternalFiles } from 'shared/documentExchange/useFetchInquiryFiles';
import { getInternalFiles } from 'store/documentExchange/documentExchange.selectors';
import { TableComponent } from 'theme/components/Table';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getDocumentsTable } from './helpers';

export const DocumentsTable = () => {
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection.reviewDocuments',
  );
  const { isLoading } = useFetchInquiryInternalFiles();
  const internalFiles: IInternalFile[] = useSelector(getInternalFiles);

  const documents = getDocumentsTable(internalFiles);

  return (
    <FormSectionLayout title={t('title')} sectionNumber={1}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box mt={-5} maxWidth={580}>
          <TableComponent
            colHeaders={[t('providedDocument'), t('actions')]}
            tbodyRows={documents}
            hasActions
          />
        </Box>
      )}
    </FormSectionLayout>
  );
};
