import {
  PROJECT_FINANCING__QUALITY_CONDITION__SIMPLE,
  PROJECT_FINANCING__QUALITY_CONDITION__MEDIUM,
  PROJECT_FINANCING__QUALITY_CONDITION__GOOD,
} from 'modules/Inquiry/Form/formFields';

const getProjectFinancingConditionQualityOptions = (formatMessage) =>
  [
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.simple.caption',
      value: PROJECT_FINANCING__QUALITY_CONDITION__SIMPLE,
    },
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.medium.caption',
      value: PROJECT_FINANCING__QUALITY_CONDITION__MEDIUM,
    },
    {
      id: 'pages.financingNeed.sections.objectInformation.fields.options.good.caption',
      value: PROJECT_FINANCING__QUALITY_CONDITION__GOOD,
    },
  ].map(({ value, id }) => ({ value, label: formatMessage({ id }) }));

export default getProjectFinancingConditionQualityOptions;
