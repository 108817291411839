import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useInquiryStepData } from 'modules/Inquiry/inquiryNavigation/useInquiryStepData';
import { setCurrentStage as setCurrentStageAction } from 'store/progress/actions';

export const useUpdateCurrentStageNumber = (stageId) => {
  const dispatch = useDispatch();
  const { stepList } = useInquiryStepData();
  const currentStepNum = stepList.indexOf(stageId);
  useEffect(() => {
    dispatch(setCurrentStageAction(currentStepNum));
  }, [currentStepNum, dispatch]);
};
