import {
  userIsBankAdvisor,
  userIsInquiryManager,
  userIsRealEstateExpert,
} from 'utils/user/conditionals';

export const useRoles = () => {
  const hasRoleBankAdvisor = userIsBankAdvisor();
  const hasRoleRealEstateExpert = userIsRealEstateExpert();
  const hasRoleInquiryManager = userIsInquiryManager();
  const hasRoleBankAdvisorOrRealEstateExpert = hasRoleBankAdvisor || hasRoleRealEstateExpert;
  return {
    hasRoleBankAdvisor,
    hasRoleBankAdvisorOrRealEstateExpert,
    hasRoleInquiryManager,
    hasRoleRealEstateExpert,
  };
};
