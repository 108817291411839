import _isNil from 'lodash/isNil';

import {
  COMPEON_PARTNER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  VM_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { mapCompanyDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/companyMappers/mapCompanyDetailsToApi';
import { mapEndUserToApi } from 'modules/Inquiry/mapInquiryToApi/endUserMapper/mapEndUserToApi';
import { mapFinancingNeedPurposeFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapFinancingNeedPurposeFieldsToApi';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { mapSubsidiesToApi } from 'modules/Inquiry/mapInquiryToApi/mapSubsidiesToApi';
import { mapProductBasedFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/productKindMappers/mapProductBasedFields';
import { mapUserDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/userMappers/mapUserDetailsToApi';
import { removeObjectValues, isEmptyObject } from 'utils/helpers';

export const mapInquiryToApi: InquiryFormMapper = (allFields) => {
  const mappedData = {
    data: {
      attributes: {
        'compeon-partner': allFields[COMPEON_PARTNER],
        'additional-association-company': allFields[USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY],
        'vm-number': allFields[VM_NUMBER],
        ...mapFinancingNeedPurposeFieldsToApi(allFields),
        ...mapCompanyDetailsToApi(allFields),
        ...mapProductBasedFieldsToApi(allFields),
        ...mapUserDetailsToApi(allFields),
        ...mapEndUserToApi(allFields),
        ...mapSubsidiesToApi(allFields),
      },
    },
  };

  return removeObjectValues(
    mappedData,
    (value: any) => _isNil(value) || Number.isNaN(value) || isEmptyObject(value),
  );
};
