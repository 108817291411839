import * as React from 'react';

import { Flex } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import ButtonComponent from '../../../theme/components/Button';
import ArrowLeftIcon from '../../../theme/components/Icon/ArrowLeftIcon';
import ArrowRightIcon from '../../../theme/components/Icon/ArrowRightIcon';
import { useTranslations } from '../../../utils/hooks/useTranslations';
import { StepActions } from '../../form/ChangeStep';
import { translations } from '../../form/common/types';
import { useInquiryProcessConfig } from '../../form/state/inquiryProcessConfig/hooks';

export const SummaryActions = () => {
  const t = useTranslations();
  const { submitting } = useFormState();
  const history = useHistory();
  const {
    formStateData: { formName },
  } = useInquiryProcessConfig();

  const finalizeButtonLabel = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t(translations.buttons.send),
    [InquiryType.hausbank]: t(translations.buttons.requestOffer),
  });

  return (
    <StepActions>
      <Flex w={'full'} justifyContent={'flex-end'} gap={4}>
        <ButtonComponent
          onClick={() => history.goBack()}
          leftIcon={<ArrowLeftIcon boxSize={6} />}
          variant={'tertiary'}
          testId="back-button"
        >
          {t(translations.buttons.back)}
        </ButtonComponent>
        <ButtonComponent
          form={formName}
          type={'submit'}
          leftIcon={<ArrowRightIcon boxSize={6} />}
          isLoading={submitting}
          testId="finalize-inquiry-button"
        >
          {finalizeButtonLabel}
        </ButtonComponent>
      </Flex>
    </StepActions>
  );
};
