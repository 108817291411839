import React from 'react';

import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-final-form';

import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { INPUT_TYPE_DECIMAL, INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import Notice from 'components/Notice';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useHausbankConditions } from 'hausbank/inquiry/config/conditions';
import { hausbankFields } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { StringBoolean } from 'types/General';
import { parseStringBoolean } from 'utils/helpers';
import { useTranslations } from 'utils/hooks/useTranslations';

const {
  residentialUnitsCount,
  commercialUnitsCount,
  hasOwnerWithMoreThanThirdOfShares,
  isContractor,
  majorityOwnerShares,
  wegIsSubCommunity,
  propertyIsLeasehold,
  hasMajorityDecision,
} = hausbankFields.objectInformationPage.projectInformationSection;

export const ProjectInformationSection = () => {
  const t = useTranslations();
  const {
    title,
    fields: {
      isContractor: { notice },
      wegIsSubCommunity: { tooltip },
    },
  } = translations.inquiryType.hausbank.pages.objectInformation.sections.projectInformation;
  const conditionValues = useHausbankConditions();
  const { change } = useForm();

  const handleMajorityOwnerChange = (value: StringBoolean) => {
    if (!parseStringBoolean(value)) {
      change(isContractor, undefined);
      change(majorityOwnerShares, undefined);
    }
  };

  return (
    <FormSection title={t(title)} sectionNumber={2}>
      <FormRow>
        <InputWithField name={residentialUnitsCount} type={INPUT_TYPE_NUMBER} />
        <InputWithField name={commercialUnitsCount} type={INPUT_TYPE_NUMBER} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={hasOwnerWithMoreThanThirdOfShares}
          onChange={handleMajorityOwnerChange}
        />
      </FormRow>
      <Condition condition={conditionValues[isContractor]}>
        <FormRow>
          <Flex direction={'column'} gap={'2rem'}>
            <YesNoRadioGroupWithField name={isContractor} />
            <Condition condition={conditionValues.showContractorNotice}>
              <Notice text={t(notice)} />
            </Condition>
          </Flex>
        </FormRow>
      </Condition>
      <Condition condition={conditionValues[majorityOwnerShares]}>
        <FormRow>
          <InputWithField
            name={majorityOwnerShares}
            type={INPUT_TYPE_DECIMAL}
            sideSymbol={() => t('other.percent')}
          />
        </FormRow>
      </Condition>
      <FormRow>
        <YesNoRadioGroupWithField name={wegIsSubCommunity} tooltip={t(tooltip)} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={propertyIsLeasehold} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={hasMajorityDecision} />
      </FormRow>
    </FormSection>
  );
};
