import React from 'react';

import { HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import PortalTable from '../../../components/PortalTable';
import usePortalTable from '../../../hooks/usePortalTable';
import type { MappingProps } from '../../../types';
import { getRowStylesMap, useUnitFormatter } from '../../../utils';

import type { LendingValueItem } from '.';

const developerMapping: MappingProps<LendingValueItem> = [
  { key: 'base_value', unit: 'currency', styles: { bold: true } },
  { key: 'cost_transfer', unit: 'currency', styles: { bold: true } },
  { key: 'adjusted_base_value', unit: 'currency', styles: { italic: true } },
  { key: 'risk_deduction', unit: 'percent', styles: { italic: true } },
  { key: 'lending_value', unit: 'currency', styles: { bold: true } },
  { key: 'lending_limit_percent', unit: 'percent', styles: { italic: true } },
  { key: 'lending_limit_euro', unit: 'currency', styles: { bold: true } },
  { key: 'unsecured_portion', unit: 'currency', styles: { bold: true } },
  { key: 'loan_value_ratio', unit: 'percent', styles: { bold: true } },
];

export const LendingValueDeveloper = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.tables.lendingValue.developer',
  );
  const { data, isLoading } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      lendingValue: response.data.attributes.computed_details.lending_value,
    }),
  });
  const formatter = useUnitFormatter();

  const { usageType } = useSelector(getMarketDataFilterLegacy);
  const lendingValueBuyingPhaseDeveloperTable = usePortalTable({
    data: developerMapping.map(({ key, unit }) => ({
      id: key,
      position: t(`buyingPhase.rows.${key}`),
      value: formatter[unit](data?.lendingValue.buying_phase[key]),
    })),
    headers: [
      { key: 'position', text: t('buyingPhase.headers.position') },
      { key: 'value', text: t('buyingPhase.headers.value') },
    ],
  });
  const lendingValueWholeProjectDeveloperTable = usePortalTable({
    data: developerMapping.map(({ key, unit }) => ({
      id: key,
      position: t(`wholeProject.rows.${key}`),
      value: formatter[unit](data?.lendingValue[usageType].whole_project[key]),
    })),
    headers: [
      { key: 'position', text: t('wholeProject.headers.position') },
      { key: 'value', text: t('wholeProject.headers.value') },
    ],
  });
  const rowStyles = getRowStylesMap(developerMapping);
  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <HStack width={'full'}>
      <PortalTable {...lendingValueBuyingPhaseDeveloperTable} rowStylesMap={{ ...rowStyles }} />;
      <PortalTable {...lendingValueWholeProjectDeveloperTable} rowStylesMap={{ ...rowStyles }} />;
    </HStack>
  );
};
