import React, { ReactNode } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { IMessageEvent } from 'websocket';

import { useInquirySignees } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import useCurrentInquiryDetails from 'shared/hooks/inquiry/useCurrentInquiryDetails';
import { Channel, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';

import EmptyProgressSectionsPlaceholder from './EmptyProgressSectionsPlaceholder';

interface ProgressSectionsWrapperProps {
  header: ReactNode;
  progressSections: ReactNode;
  isLoading?: boolean;
}

const ProgressSectionsWrapper: React.FC<ProgressSectionsWrapperProps> = ({
  header,
  progressSections,
  isLoading = false,
}) => {
  const { isLoading: inquiryIsLoading, status, inquiryId } = useCurrentInquiryDetails();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  useWebSocket({
    channel: Channel.INQUIRY,
    params: {
      inquiry_id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
        queryClient.invalidateQueries(useInquirySignees.getKey({ id: inquiryId }));
      }
    },
  });

  if (isLoading || inquiryIsLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const showProgressSections = status !== INQUIRY_STATUSES.ARCHIVED;
  return (
    <article>
      {header}
      {showProgressSections ? progressSections : <EmptyProgressSectionsPlaceholder />}
    </article>
  );
};

export default ProgressSectionsWrapper;
