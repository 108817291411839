import { CurrentFutureUsageType } from 'modules/Inquiry/CurrentFutureUsageType';
import {
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_ROLE,
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
  PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_HAS_BUILD_PERMIT,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
  ROLES__FILE_HALTER,
  ROLES__PARTITION,
  ROLES__PROPERTY_BUYER,
  ROLES__PROPERTY_DEVELOPER,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  PROJECT_FINANCING_RENT_FUTURE,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL,
  PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  PROJECT_FINANCING_TYPE_OF_USAGE__NURSING,
  PROJECT_FINANCING_TYPE_OF_USAGE__OTHER,
  PROJECT_FINANCING_TYPE_OF_USAGE__PARKING,
  PROJECT_FINANCING__OBJECT_FUTURE_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
  PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';

const { toBoolean, toFloatPrecision2, toInteger, mapOptions } = fieldParsers;

interface TypeUsagesType {
  [PROJECT_FINANCING_TYPE_OF_USAGE]?: string;
  [PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT]?: string;
  [PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE]?: string;
  [PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS]: string;
  [PROJECT_FINANCING_USAGE_SPACE]: string;
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]: string;
  [PROJECT_FINANCING_RENT_NOW]: string;
  [PROJECT_FINANCING_RENT_FUTURE]: string;
  [PROJECT_FINANCING__NUMBER_OF_UNITS]?: number;
  [PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT]: number;
}

const mapUsageTypes = (usageTypes: any): Array<CurrentFutureUsageType> | undefined => {
  if (!Array.isArray(usageTypes)) {
    return undefined;
  }
  if (usageTypes[0]) {
    return usageTypes.map((usageType: TypeUsagesType) => {
      return {
        [PROJECT_FINANCING_TYPE_OF_USAGE]: mapOptions(
          usageType[PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT] ||
            usageType[PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE] ||
            usageType[PROJECT_FINANCING_TYPE_OF_USAGE] ||
            '',
          {
            [PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY]: 'residential',
            [PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL]: 'commercial_office',
            [PROJECT_FINANCING_TYPE_OF_USAGE__NURSING]: 'care_social',
            [PROJECT_FINANCING_TYPE_OF_USAGE__PARKING]: 'retail_parking_garage',
            [PROJECT_FINANCING_TYPE_OF_USAGE__OTHER]: 'other',
          },
        ),
        'type-of-use-description': usageType[PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS],
        'usage-space': toInteger(usageType[PROJECT_FINANCING_USAGE_SPACE]) || 0,
        'percentage-of-rent': toInteger(usageType[PROJECT_FINANCING_PERCENTAGE_OF_RENT]),
        'rent-now': toFloatPrecision2(usageType[PROJECT_FINANCING_RENT_NOW]),
        'rent-future': toFloatPrecision2(usageType[PROJECT_FINANCING_RENT_FUTURE]),
        'number-of-units': usageType[PROJECT_FINANCING__NUMBER_OF_UNITS],
        'planned-sales-price': usageType[PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT],
      };
    });
  } else {
    return [];
  }
};

const getProjectFinancingObjectUsages = (
  currentUsages?: Array<CurrentFutureUsageType>,
  futureUsages?: Array<CurrentFutureUsageType>,
) => {
  const currentUsageObjectArray =
    currentUsages?.map((current_usage, index: number) => {
      return {
        ...current_usage,
        [PROJECT_FINANCING_USAGE_KIND_TYPE]: PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
        id: index,
      };
    }) || [];
  const futureUsageObjectArray =
    futureUsages?.map((future_usage, index: number) => {
      return {
        ...future_usage,
        [PROJECT_FINANCING_USAGE_KIND_TYPE]: PROJECT_FINANCING_USAGE_KIND_TYPE_FUTURE,
        id: index,
      };
    }) || [];
  return [...currentUsageObjectArray, ...futureUsageObjectArray];
};

const { isPlanningRenovation, hasListOfRenters, isPartitionOrFileHalter } = fieldBusinessConditions;

export const mapProjectFinancingToApi: InquiryFormMapper = (allFields) => ({
  'project-financing-role': mapOptions(allFields[PROJECT_FINANCING_ROLE], {
    [ROLES__PROPERTY_DEVELOPER]: 'developer',
    [ROLES__FILE_HALTER]: 'stockholder',
    [ROLES__PARTITION]: 'partition',
    [ROLES__PROPERTY_BUYER]: 'land_buyer',
  }),
  'project-financing-investment-amount': toFloatPrecision2(
    allFields[PROJECT_FINANCING_INVESTMENT_AMOUNT],
  ),
  'project-financing-own-funds': toFloatPrecision2(allFields[PROJECT_FINANCING_OWN_FUNDS]),
  'project-financing-mezzanine': toFloatPrecision2(allFields[PROJECT_FINANCING_MEZZANINE]),
  project_financing_own_work: toFloatPrecision2(allFields[PROJECT_FINANCING_OWN_WORK]),
  'project-financing-subordinated-capital': toFloatPrecision2(
    allFields[PROJECT_FINANCING_SUBORDINATED_CAPITAL],
  ),
  'project-financing-object-address': allFields[PROJECT_FINANCING_OBJECT_ADDRESS],
  'project-financing-object-zip-code': allFields[PROJECT_FINANCING_OBJECT_ZIP_CODE],
  'project-financing-object-city': allFields[PROJECT_FINANCING_OBJECT_CITY],
  'project-financing-object-description': allFields[PROJECT_FINANCING_OBJECT_DESCRIPTION],
  'project-financing-lot-size': toInteger(allFields[PROJECT_FINANCING_LOT_SIZE]),
  'project-financing-planning-documents-available': toBoolean(
    allFields[PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE],
  ),
  'project-financing-contaminated-sites-register': toBoolean(
    allFields[PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST],
  ),
  'project-financing-received-building-permission': toBoolean(
    allFields[PROJECT_FINANCING_HAS_BUILD_PERMIT],
  ),
  project_financing_year_of_building: allFields[PROJECT_FINANCING__BUILDING_YEAR],
  project_financing_year_of_modernization: allFields[PROJECT_FINANCING__MODERNIZATION_YEAR],
  project_financing_furnishing_quality: allFields[PROJECT_FINANCING__FURNISHING_QUALITY],
  project_financing_quality_condition: allFields[PROJECT_FINANCING__QUALITY_CONDITION],
  project_financing_additional_bathroom: toBoolean(allFields[PROJECT_FINANCING__GUEST_BATHROOM]),
  project_financing_balkony: toBoolean(allFields[PROJECT_FINANCING__BALKONY]),
  project_financing_lift: toBoolean(allFields[PROJECT_FINANCING__LIFT]),
  project_financing_cellar: toBoolean(allFields[PROJECT_FINANCING__CELLAR]),
  project_financing_parking_lots_count: allFields[PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS],
  project_financing_garages_count: allFields[PROJECT_FINANCING__NUMBER_OF_GARAGES],
  project_financing_garages_planned_sales_price:
    allFields[PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT],
  project_financing_garages_rent_now: allFields[PROJECT_FINANCING__GARAGES_RENT_NOW],
  project_financing_garages_rent_future: allFields[PROJECT_FINANCING__GARAGES_RENT_FUTURE],
  project_financing_parking_lots_planned_sales_price:
    allFields[PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT],
  project_financing_parking_lots_rent_now: allFields[PROJECT_FINANCING__PARKING_LOTS_RENT_NOW],
  project_financing_parking_lots_rent_future:
    allFields[PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE],
  project_financing_building_encumbrances_list: toBoolean(
    allFields[PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES],
  ),
  project_financing_energy_certificate: toBoolean(
    allFields[PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE],
  ),
  ...conditionalObject(isPartitionOrFileHalter({ formValues: allFields }), {
    'project-financing-list-of-renters': toBoolean(allFields[PROJECT_FINANCING_LIST_OF_RENTERS]),
    'project-financing-rental-contracts': toBoolean(allFields[PROJECT_FINANCING_RENTAL_CONTRACTS]),
    'project-financing-renovation-planned': toBoolean(
      allFields[PROJECT_FINANCING_RENOVATION_PLANNED],
    ),
    'project-financing-anchor-tenant': hasListOfRenters({ formValues: allFields })
      ? allFields[PROJECT_FINANCING_ANCHOR_TENANT]
      : undefined,
    'project-financing-renovation-description': isPlanningRenovation({ formValues: allFields })
      ? allFields[PROJECT_FINANCING_RENOVATION_DESCRIPTION]
      : undefined,
  }),
  project_financing_object_usages: getProjectFinancingObjectUsages(
    mapUsageTypes(allFields[PROJECT_FINANCING__OBJECT_USAGE]),
    mapUsageTypes(allFields[PROJECT_FINANCING__OBJECT_FUTURE_USAGE]),
  ),
  project_financing_usage_deviation: toBoolean(
    allFields[PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE],
  ),
});
