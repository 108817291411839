import {
  INDUSTRY__BUILDING,
  INDUSTRY__ENERGY_AND_WATER_SUPPLY,
  INDUSTRY__MANUFACTURING,
  INDUSTRY__MINING,
  INDUSTRY__SERVICE,
  INDUSTRY__FINANCE_AND_INSURANCE,
  INDUSTRY__HOSPITALITY,
  INDUSTRY__HEALTH_AND_SOCIAL,
  INDUSTRY__TRADE,
  INDUSTRY__AGRICULTURE,
  INDUSTRY__CRAFT,
  INDUSTRY__OTHER,
  INDUSTRY__PROCESSING,
  INDUSTRY__REAL_ESTATE,
  INDUSTRY__TRANSPORT,
  INDUSTRY__MAINTENANCE,
  INDUSTRY__SOCIAL,
  INDUSTRY__HEALTH,
  INDUSTRY__RESIDE,
  INDUSTRY__FUNDRAISER,
  INDUSTRY__FOUNDATION,
  INDUSTRY__RELIGIOUS,
  INDUSTRY__UPBRINGING,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

export const getTranslationPath = (id) =>
  `pages.userProfile.sections.completingCompanyDetails.fields.userProfileIndustry.selectOptions.${id}`;

export const bfsIndustryOptions = {
  [INDUSTRY__MAINTENANCE]: getTranslationPath(INDUSTRY__MAINTENANCE),
  [INDUSTRY__SOCIAL]: getTranslationPath(INDUSTRY__SOCIAL),
  [INDUSTRY__HEALTH]: getTranslationPath(INDUSTRY__HEALTH),
  [INDUSTRY__RESIDE]: getTranslationPath(INDUSTRY__RESIDE),
  [INDUSTRY__FUNDRAISER]: getTranslationPath(INDUSTRY__FUNDRAISER),
  [INDUSTRY__UPBRINGING]: getTranslationPath(INDUSTRY__UPBRINGING),
  [INDUSTRY__OTHER]: getTranslationPath(INDUSTRY__OTHER),
  [INDUSTRY__RELIGIOUS]: getTranslationPath(INDUSTRY__RELIGIOUS),
  [INDUSTRY__FOUNDATION]: getTranslationPath(INDUSTRY__FOUNDATION),
};

export const profileIndustryOptions = {
  [INDUSTRY__BUILDING]: getTranslationPath('building'),
  [INDUSTRY__MINING]: getTranslationPath('mining'),
  [INDUSTRY__ENERGY_AND_WATER_SUPPLY]: getTranslationPath('energy_and_water_supply'),
  [INDUSTRY__SERVICE]: getTranslationPath('service'),
  [INDUSTRY__FINANCE_AND_INSURANCE]: getTranslationPath('finance_and_insurance'),
  [INDUSTRY__HOSPITALITY]: getTranslationPath('hospitality'),
  [INDUSTRY__HEALTH_AND_SOCIAL]: getTranslationPath('health_and_social'),
  [INDUSTRY__TRADE]: getTranslationPath('trade'),
  [INDUSTRY__CRAFT]: getTranslationPath('craft'),
  [INDUSTRY__REAL_ESTATE]: getTranslationPath('real_estate'),
  [INDUSTRY__AGRICULTURE]: getTranslationPath('agriculture'),
  [INDUSTRY__MANUFACTURING]: getTranslationPath('manufacturing'),
  [INDUSTRY__PROCESSING]: getTranslationPath('processing'),
  [INDUSTRY__TRANSPORT]: getTranslationPath('transport'),
  [INDUSTRY__OTHER]: getTranslationPath('other'),
};

export const getSelectTranslations = (formatMessage) => {
  const t = (id) => id && formatMessage({ id });
  return {
    [INDUSTRY__BUILDING]: t(profileIndustryOptions[INDUSTRY__BUILDING]),
    [INDUSTRY__MINING]: t(profileIndustryOptions[INDUSTRY__MINING]),
    [INDUSTRY__ENERGY_AND_WATER_SUPPLY]: t(
      profileIndustryOptions[INDUSTRY__ENERGY_AND_WATER_SUPPLY],
    ),
    [INDUSTRY__SERVICE]: t(profileIndustryOptions[INDUSTRY__SERVICE]),
    [INDUSTRY__FINANCE_AND_INSURANCE]: t(profileIndustryOptions[INDUSTRY__FINANCE_AND_INSURANCE]),
    [INDUSTRY__HOSPITALITY]: t(profileIndustryOptions[INDUSTRY__HOSPITALITY]),
    [INDUSTRY__HEALTH_AND_SOCIAL]: t(profileIndustryOptions[INDUSTRY__HEALTH_AND_SOCIAL]),
    [INDUSTRY__TRADE]: t(profileIndustryOptions[INDUSTRY__TRADE]),
    [INDUSTRY__CRAFT]: t(profileIndustryOptions[INDUSTRY__CRAFT]),
    [INDUSTRY__REAL_ESTATE]: t(profileIndustryOptions[INDUSTRY__REAL_ESTATE]),
    [INDUSTRY__AGRICULTURE]: t(profileIndustryOptions[INDUSTRY__AGRICULTURE]),
    [INDUSTRY__MANUFACTURING]: t(profileIndustryOptions[INDUSTRY__MANUFACTURING]),
    [INDUSTRY__PROCESSING]: t(profileIndustryOptions[INDUSTRY__PROCESSING]),
    [INDUSTRY__TRANSPORT]: t(profileIndustryOptions[INDUSTRY__TRANSPORT]),
    [INDUSTRY__OTHER]: t(profileIndustryOptions[INDUSTRY__OTHER]),
    [INDUSTRY__MAINTENANCE]: t(bfsIndustryOptions[INDUSTRY__MAINTENANCE]),
    [INDUSTRY__SOCIAL]: t(bfsIndustryOptions[INDUSTRY__SOCIAL]),
    [INDUSTRY__HEALTH]: t(bfsIndustryOptions[INDUSTRY__HEALTH]),
    [INDUSTRY__RESIDE]: t(bfsIndustryOptions[INDUSTRY__RESIDE]),
    [INDUSTRY__FUNDRAISER]: t(bfsIndustryOptions[INDUSTRY__FUNDRAISER]),
    [INDUSTRY__FOUNDATION]: t(bfsIndustryOptions[INDUSTRY__FOUNDATION]),
    [INDUSTRY__RELIGIOUS]: t(bfsIndustryOptions[INDUSTRY__RELIGIOUS]),
    [INDUSTRY__UPBRINGING]: t(bfsIndustryOptions[INDUSTRY__UPBRINGING]),
  };
};

export const getSelectValues = (formatMessage) => {
  const translations = getSelectTranslations(formatMessage);

  return Object.keys(translations).map((key) => ({
    label: translations[key],
    value: key,
  }));
};

// Values from DKB
export const MUTLISELECT_VALUES = {
  Ackerbau: 'Ackerbau',
  Milcherzeugung: 'Milcherzeugung',
  Rindermast: 'Rindermast',
  Schweinehaltung: 'Schweinehaltung',
  Geflügelhaltung: 'Geflügelhaltung',
  Biogas: 'Biogas',
  Sonderkulturen: 'Sonderkulturen',
  'Lohn - Dienstleistungen': 'Lohn - Dienstleistungen',
  Handel: 'Handel',
  Sonstiges: 'Sonstiges',
};

export const useMultiSelectValues = () => {
  const t = useTranslations();
  return chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: Object.entries(bfsIndustryOptions).map(([key, val]) => ({
      label: t(val),
      value: key,
    })),
    default: () => Object.keys(MUTLISELECT_VALUES).map((key) => ({ label: key, value: key })),
  });
};
