import paths from 'constants/paths';
import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { RouteToPageNameMap } from 'new/form/model';

export const mittweidaRouteToPageNameMap: RouteToPageNameMap<MittweidaFieldTypes> = {
  financingNeedPage: {
    create: paths.financingNeed,
    editOperation: paths.operation.inquiryDetails.edit.financingNeed,
    editCustomer: paths.customer.inquiryDetails.edit.financingNeed,
  },
  companyDetailPage: {
    create: paths.companyDetails,
    editOperation: paths.operation.inquiryDetails.edit.companyDetails,
    editCustomer: paths.customer.inquiryDetails.edit.companyDetails,
  },
  fundingDetailsPage: {
    create: paths.requestDetails,
    editOperation: paths.operation.inquiryDetails.edit.requestDetails,
    editCustomer: paths.customer.inquiryDetails.edit.requestDetails,
  },
  userProfilePage: {
    create: paths.userProfile,
    editOperation: paths.operation.inquiryDetails.edit.userProfile,
    editCustomer: paths.customer.inquiryDetails.edit.userProfile,
  },
};
