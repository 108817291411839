import _get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { pathContainsInquiryId, pathsPortal } from 'utils/paths';

export const useLoginRedirect = (defaultRedirect: string) => {
  const location = useLocation();
  const redirectTo = _get(location, 'state.redirectTo') as string | undefined;
  // Checking if the path has an inquiry ID and it would redirect to the other portal (for example OP user switches to CP)
  if (
    redirectTo &&
    !(
      pathContainsInquiryId(redirectTo) &&
      pathsPortal(location.pathname) !== pathsPortal(redirectTo)
    )
  ) {
    return redirectTo;
  }
  return defaultRedirect;
};
