import React from 'react';

import { Flex, Spacer } from '@chakra-ui/react';

import Condition from 'components/Condition';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import SalesTypeSelect from './SalesTypeSelect';
import UsageTypeRadio from './UsageTypeRadio';

interface FilterProps {
  hideLabel?: boolean;
  disableCurrentUsage?: boolean;
}

const ProjectCostingFilters: React.FC<FilterProps> = ({
  hideLabel,
  disableCurrentUsage,
}: FilterProps) => {
  const t = useTranslations('pages.planningEvaluation.marketComparison');
  return (
    <>
      <Condition condition={!hideLabel}>
        <TextComponent
          as="span"
          display="block"
          fontWeight={FONT_WEIGHT_REGULAR}
          color="text.tertiary"
          mb={8}
        >
          {t('usageRadio.label')}
        </TextComponent>
      </Condition>
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={disableCurrentUsage} />
        <Spacer />
        <SalesTypeSelect />
      </Flex>
    </>
  );
};

export default ProjectCostingFilters;
