import React from 'react';

import FormRow from 'components/FormRow';
import MachineInterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance/MachineInterestedInInsurance';
import VehicleInterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance/VehicleInterestedInInsurance';

const InterestedInInsurance = () => {
  return (
    <FormRow>
      <VehicleInterestedInInsurance />
      <MachineInterestedInInsurance />
    </FormRow>
  );
};

export default InterestedInInsurance;
