import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { useFormConfig } from 'config/formConfig/hooks';
import { FormValidator } from 'models/types/FormValidator.type';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  FINANCING_AMOUNT,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import ProjectFinancingAmount from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ProjectComposition/ProjectFinancingAmount';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProjectCompositionSection: React.FC = () => {
  const t = useTranslations('pages.financingNeed.sections.projectComposition');
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;

  return (
    <FormSection
      name={FormSections.projectComposition}
      sectionNumber={isProfiInquiry ? 2 : 3}
      title={t('title')}
    >
      <FormRow>
        <AmountWithField
          name={PROJECT_FINANCING_INVESTMENT_AMOUNT}
          tooltip={t('fields.investmentAmount.info')}
        />

        <AmountWithField name={PROJECT_FINANCING_OWN_FUNDS} />
      </FormRow>

      <FormRow>
        <AmountWithField name={PROJECT_FINANCING_MEZZANINE} />
        <AmountWithField name={PROJECT_FINANCING_SUBORDINATED_CAPITAL} />
      </FormRow>

      <FormRow>
        <AmountWithField name={PROJECT_FINANCING_OWN_WORK} tooltip={t('fields.ownWork.info')} />
      </FormRow>

      <FormRow>
        <ProjectFinancingAmount />
      </FormRow>
    </FormSection>
  );
};

export default React.memo(ProjectCompositionSection);

export const validateProjectCompositionSection: FormValidator = validateSectionFields([
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  FINANCING_AMOUNT,
]);
