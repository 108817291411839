import React from 'react';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyDetailsHeader = () => {
  const t = useTranslations('pages.companyDetails.heading');

  return (
    <PageHeading
      heading={t('header', { roleSpecific: true })}
      subheading={t('subheader', { roleSpecific: true })}
    />
  );
};

const OnlinefactoringCompanyDetailsHeader = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.companyDetails');

  return <PageHeading heading={t('heading')} />;
};

const DisplayConditions = () => {
  return (
    <InquiryTypeCondition
      cases={{
        onlinefactoring: <OnlinefactoringCompanyDetailsHeader />,
        default: <CompanyDetailsHeader />,
      }}
    />
  );
};

export default DisplayConditions;
