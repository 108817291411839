import { AllFieldTypes, mmvFields } from 'mmv/inquiry/fields';

export const initialFormValues: AllFieldTypes = {
  contractDetails: {
    dealerSection: { supplier: undefined, agent: undefined },
    financingObjectSection: {
      objectAlreadyDelivered: undefined,
      objectDescription: undefined,
      state: undefined,
      serialNumber: undefined,
    },
    financingConditionsSection: {
      runtime: undefined,
      objectValue: undefined,
      downPayment: undefined,
      ratePerMonth: undefined,
      remainingValue: undefined,
    },
    bankDetailsSection: { accountHolder: undefined, iban: undefined },
  },
  [mmvFields['user-representation']]: undefined,
  [mmvFields['user-shared-representation']]: undefined,
  [mmvFields['user-representative']]: [],

  [mmvFields['existing-beneficiary']]: undefined,
  [mmvFields['self-beneficiary-owner']]: [],
  [mmvFields['no-beneficiary-checkbox']]: undefined,
  [mmvFields['beneficiary-owner']]: [],

  customerDataPage: {
    companySection: { companyName: undefined, address: undefined },
    contactPersonSection: {
      title: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
    authorizationOfRepresentationSection: {},
    beneficialOwnerSection: {
      isFictitiousBeneficialOwner: undefined,
    },
  },
};
