export default {
  users: {
    me: 'users/me',
  },
  auth: {
    login: 'auth/login',
    refresh: 'auth/refresh',
    logout: 'auth/logout',
  },
  inquiries: {
    dashboardActions: 'inquiries/dasboardActions',
    processSpecificUploadedFiles: 'inquiries/processSpecificUploadedFiles',
    details: 'inquiries/details',
    signees: 'inquiries/signees',
  },
  file: {
    download: 'file/download',
  },
  assessmentFile: {
    download: 'download',
  },
  privateFile: {
    download: 'download',
  },
  operation: {
    profitabilityData: 'operation/profitabilityData',
    marketData: 'operation/marketData',
    processLog: 'operation/processLog',
    marketDataRee: 'operation/marketDataRee',
    internalComments: 'operation/internalComments',
    inquiryRevision: 'operation/inquiryRevision',
    inquiryRevisions: 'operation/inquiryRevisions',
    inquiriesList: 'operation/inquiriesList',
    inquiriesStatuses: 'operation/inquiriesStatuses',
    predefinedRequests: 'operation/predefinedRequests',
  },
  partners: {
    mmv: {
      objectConfirmationFilledDocument: 'partners/mmv/objectConfirmationFilledDocument',
    },
  },
};
