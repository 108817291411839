import paths from 'constants/paths';
import { RouteToPageNameMap } from 'new/form/model';

import { HausbankFieldTypes } from '../fields';

export const hausbankRouteToPageNameMap: RouteToPageNameMap<HausbankFieldTypes> = {
  objectInformationPage: {
    create: paths.contractDetails,
    editOperation: paths.operation.inquiryDetails.edit.contractDetails,
    editCustomer: paths.customer.inquiryDetails.edit.contractDetails,
  },
  companyDataPage: {
    create: paths.companyDetails,
    editOperation: paths.operation.inquiryDetails.edit.companyDetails,
    editCustomer: paths.customer.inquiryDetails.edit.companyDetails,
  },
  financingNeedPage: {
    create: paths.financingNeed,
    editOperation: paths.operation.inquiryDetails.edit.financingNeed,
    editCustomer: paths.customer.inquiryDetails.edit.financingNeed,
  },
  personalDataPage: {
    create: paths.peopleDetails,
    editOperation: paths.operation.inquiryDetails.edit.peopleDetails,
    editCustomer: paths.customer.inquiryDetails.edit.peopleDetails,
  },
};
