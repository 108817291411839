import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const getRealEstateExpertsAction = () =>
  callReverseApi({
    url: endpoints.INQUIRIES.REAL_ESTATE_EXPERTS.LIST.compose(),
    method: API_METHODS.GET,
  });

export const assignRealEstateExpertInquiry = (inquiryId: string, realEstateExpertId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.REAL_ESTATE_EXPERTS.ASSIGN_REE_TO_INQUIRY.compose({
      params: { inquiryId },
    }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: {
          real_estate_expert_id: realEstateExpertId,
        },
      },
    },
  });
