import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import BusinessAddressStaticField from './BusinessAddressStatic';
import BusinessOwnerNameStaticField from './BusinessOwnerNameStatic';

interface Props {
  sectionNumber?: number;
}

const BusinessDetails = ({ sectionNumber }: Props) => {
  const t = useTranslations('pages.customerData.sections.businessDetails');

  return (
    <FormSection
      name={FormSections.businessDetails}
      sectionNumber={sectionNumber ?? 1}
      title={t('title')}
    >
      <FormRow alignItems="flex-start">
        <BusinessOwnerNameStaticField />
        <BusinessAddressStaticField />
      </FormRow>
    </FormSection>
  );
};

export default BusinessDetails;
