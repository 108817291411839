import React from 'react';

import { Box, Container, Flex, Heading as ChakraHeading, Stack } from '@chakra-ui/react';

import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';

interface FormCompoundWithChildren {
  children: React.ReactNode;
}
const FormHeading = ({ children }: FormCompoundWithChildren) => (
  <ChakraHeading fontSize="3xl">{children}</ChakraHeading>
);

const FormSubHeading = ({ children }: FormCompoundWithChildren) => (
  <ChakraHeading variant="secondary" fontSize="md">
    {children}
  </ChakraHeading>
);

const FormBody = ({ children }: FormCompoundWithChildren) => <>{children}</>;

const HeadingContainer = ({ children }: FormCompoundWithChildren) => (
  <Stack spacing={6} pb={6}>
    <Stack spacing={{ base: 4, md: 6 }}>{children}</Stack>
  </Stack>
);

const FormContainer = ({ children }: FormCompoundWithChildren) => {
  const isScrolledToTop = useIsScrolledToTop();

  return (
    <Flex
      backgroundColor="background.lightGrey"
      flexGrow={1}
      alignItems="center"
      pt={isScrolledToTop ? 24 : 36}
    >
      <Container maxW="2xl" py={{ base: 12, md: 24 }} px={{ base: 0, sm: 8 }}>
        <Stack spacing={8}>
          <Box
            py={{ base: 4, sm: 8 }}
            px={{ base: 4, sm: 10 }}
            boxShadow={{ base: 'none', sm: 'md' }}
            borderRadius={{ base: 'none', sm: 'xl' }}
            backgroundColor="background.white"
          >
            {children}
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
};

FormContainer.Heading = FormHeading;
FormContainer.SubHeading = FormSubHeading;
FormContainer.Body = FormBody;
FormContainer.HeadingContainer = HeadingContainer;

export { FormContainer };
