import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const SearchIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M13.5 17C9.9 17 7 14.1 7 10.5S9.9 4 13.5 4 20 6.9 20 10.5 17.1 17 13.5 17m0-15C8.8 2 5 5.8 5 10.5c0 1.9.6 3.8 1.8 5.3l-4.5 4.5c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l4.5-4.5c3.7 2.9 9 2.3 11.9-1.4s2.3-9-1.4-11.9C17.3 2.6 15.4 2 13.5 2"
      />
    </Icon>
  );
};

export default SearchIcon;
