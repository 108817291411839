import React from 'react';

import { useSelector } from 'react-redux';
import { compose } from 'redux';

import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import InquiryDraftModeSidebar from 'modules/Inquiry/DraftMode/Sidebar/InquiryDraftModeSidebar';
import { saveInquiryDraftAction } from 'modules/Inquiry/DraftMode/Sidebar/service';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { onlyForRoutesGuard } from 'shared/guard/onlyForRoutesGuard';
import withConfigFlagGuard from 'shared/guard/withConfigFlagGuard';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { getRawFormDataFromSessionStorage } from 'shared/inquiry/inquirySessionStorage.helpers';
import { getInquiryIdSelector, getInquiryStatusSelector } from 'store/inquiryDetails/selectors';
import { getInquiryDraftIdSelector } from 'store/inquiryProcess/selectors';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const SaveInquiryDraftButton = () => {
  const draftId = useSelector(getInquiryDraftIdSelector);
  const inquiryId = useSelector(getInquiryIdSelector);
  const inquiryStatus = useSelector(getInquiryStatusSelector);
  const { isPending, makeCall } = useDispatchApiCall();
  const { error, success } = useToasts();
  const t = useTranslations('inquiry.draftMode');
  const { selectedInquiryType } = useFormConfig();

  const getInquiryDraftId = () => {
    if (draftId) {
      return draftId;
    }
    return inquiryId || null;
  };

  const saveInquiryDraft = async () => {
    const rawInquiry = getRawFormDataFromSessionStorage();
    const { error: hasError } = await makeCall(
      saveInquiryDraftAction(getInquiryDraftId(), rawInquiry, selectedInquiryType),
    );
    if (hasError) {
      error({ description: t('notification.error') });
    } else {
      success({ description: t('notification.saved') });
    }
  };

  // draft mode is available for new or draft
  const canSaveDraft = inquiryStatus === null || inquiryStatus === INQUIRY_STATUSES.DRAFT;

  if (!canSaveDraft) {
    return null;
  }

  return (
    <InquiryDraftModeSidebar
      icon={<SaveIcon boxSize={6} mt={1} mb={1} />}
      onClick={saveInquiryDraft}
      isLoading={isPending}
      text={t('sidebar.caption')}
    />
  );
};

export default compose(
  withConfigFlagGuard(CONFIG.IS_INQUIRY_DRAFT_MODE_ENABLED),
  withRolesGuard(PERMISSIONS.INQUIRY.SAVE_DRAFT),
  onlyForRoutesGuard([
    paths.financingNeed,
    paths.companyDetails,
    paths.requestDetails,
    paths.userProfile,
    paths.summaryPage,
    paths.contactPerson,
    paths.peopleDetails,
  ]),
)(SaveInquiryDraftButton);
