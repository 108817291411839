import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

/* eslint-disable camelcase */
export interface IBankAdvisor {
  id: string;
  type: 'portal_users';
  attributes: {
    email: string;
    salutation: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  relationships: {
    organizational_unit: {
      data: {
        type: string;
        id: string;
      };
    };
  };
}
/* eslint-enable camelcase */

export const loadAvailableBankAdvisorsAction = (organizationUnitId: string | undefined) => {
  return callReverseApi({
    url: endpoints.BANK_ADVISORS.compose({
      query: {
        filter: {
          organizational_unit_id: organizationUnitId,
        },
      },
    }),
    method: API_METHODS.GET,
  });
};
