import { API_METHODS, callCompeonApi, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonApi/endpoints';
import reverseEndpoints from 'api/CompeonReverseApi/endpoints';
import { FIELD_KIND_TYPE, FIELD_VALUE_TYPES } from 'modules/FormGenerator/constants';
import { success } from 'utils/actionTypesHelpers';

export const sortFieldsByPosition = (prev, next) => prev.position - next.position;

export const mapValueByType = (value) => {
  if (value === null) {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const mapFieldFromApi = ({ id, attributes }) => ({
  id,
  type: attributes['value-type'],
  name: id,
  label: attributes.label,
  placeholder: attributes.hint,
  position: attributes.position,
  validation: attributes.validation,
  validationMessage: attributes['validation-message'],
  options: attributes.options || [],
  kind: attributes.kind,
  value: mapValueByType(attributes.value),
});

export const removeNotRecognizedTypes = (field) => {
  // Since we cannot trust API, we are cutting those fields we have not knowledge of
  // ex. kind: field, value-type: text
  const isFieldKind = field.kind === FIELD_KIND_TYPE.FIELD;
  const isRecognizedType = FIELD_VALUE_TYPES.includes(field.type);
  return !(isFieldKind && !isRecognizedType);
};

export const mapFromApi = (response) =>
  response.data.map(mapFieldFromApi).filter(removeNotRecognizedTypes).sort(sortFieldsByPosition);

export const fetchFastlaneQuestionnaireItems = (questionnaireId) =>
  callCompeonApi({
    url: endpoints.QUESTIONNAIRES.ITEMS.LIST.compose({
      params: { questionnaireId },
      query: { page: { size: 1000 } }, // page size to return all questionnaire items as compeon paginate them
    }),
    method: 'get',
  });

export const updateCompeonQuestionnaireItem = (questionnaireId, itemId, fieldValue) =>
  callCompeonApi({
    url: endpoints.QUESTIONNAIRES.ITEMS.DETAILS.compose({ params: { questionnaireId, itemId } }),
    method: 'patch',
    data: {
      data: {
        type: 'questionnaire-items',
        id: itemId,
        attributes: {
          value: fieldValue.toString(),
        },
      },
    },
  });

export const finishQuestionnaireCall = (questionnaireId) =>
  callCompeonApi({
    url: endpoints.QUESTIONNAIRES.DETAILS.compose({ params: { id: questionnaireId } }),
    method: 'patch',
    data: {
      data: {
        type: 'questionnaires',
        attributes: {
          completed: true,
        },
      },
    },
  });

export const acceptOffer = (offerId) =>
  callReverseApi({
    url: reverseEndpoints.OFFERS.ACCEPT.compose({ params: { id: offerId } }),
    method: 'patch',
  });

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = success(UPLOAD_FILES);
export const uploadFiles = (inquiryId, payload) =>
  callCompeonApi({
    url: endpoints.INQUIRY.ATTACHMENTS.compose({ params: { id: inquiryId } }),
    method: API_METHODS.POST,
    actionName: UPLOAD_FILES,
    data: payload,
  });
