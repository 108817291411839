import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { FACTORING_BILLS_RANGE } from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';

const FactoringBillsRange = () => {
  const options = useInquirySelectOptions(FACTORING_BILLS_RANGE);
  return (
    <SelectWithField
      name={FACTORING_BILLS_RANGE}
      options={options}
      data-testid={'select-field-' + FACTORING_BILLS_RANGE}
    />
  );
};

export default FactoringBillsRange;
