import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import StaticField from 'components/StaticField';
import { USER_PROFILE_FOUNDING_YEAR } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const StaticFoundingYearField = ({ foundingYear }) => {
  const t = useTranslations(
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileFoundingYear',
  );

  const { optional } = useFieldValidators();
  return (
    <>
      <Field
        name={USER_PROFILE_FOUNDING_YEAR}
        validate={optional}
        render={({ input }) => {
          input.onChange(foundingYear);
          return null;
        }}
      />
      <StaticField caption={t('caption')} text={foundingYear} />
    </>
  );
};

StaticFoundingYearField.propTypes = {
  foundingYear: PropTypes.number.isRequired,
};

export default StaticFoundingYearField;
