import React from 'react';

import { useIsUnderPaths } from 'utils/hooks/isUnderPath';

export const onlyForRoutesGuard = (routes: string[]) => (Component: React.FC) => (props: any) => {
  const correctPath = useIsUnderPaths(routes);
  if (!correctPath) {
    return null;
  }

  return <Component {...props} />;
};
