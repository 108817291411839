import { useMemo } from 'react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { IFileRequest } from 'models/FileRequest.model';
import {
  CustomerIdentificationType,
  FESStatus,
  IInquiryDetails,
  VideoIdentStatus,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  USER_PROFILE_BALANCE_SHEET,
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
  USER_PROFILE_EMPLOYEE_COUNT,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { INQUIRY_STATUSES, INQUIRY_SUBSTATUSES } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import {
  getFileRequestsSelector,
  getRequiredFileRequestsSelector,
} from 'store/documentExchange/documentExchange.selectors';
import {
  getAllowDigitalProcessingSelector,
  getCustomerLegalRepresentativeId,
  getIndicativeConditionCompleted,
  getInquiryAllRequiredFilesUploaded,
  getInquiryDetailsSelector,
  getInquiryLane,
  getInquiryStatusSelector,
  getLegalRepresentatives,
} from 'store/inquiryDetails/selectors';

import { ProgressSectionType } from './types';
import { useInquirySignees } from '../../../../api/CompeonReverseApi/operation/queryHooks/inquiries';
import { mmvBusinessConstraints } from '../../../../mmv/utilities/businessConstraints';
import { SIGNEE_STATUS } from '../../../../models/SigneeStatus.type';
import useCurrentInquiryDetails from '../../../../shared/hooks/inquiry/useCurrentInquiryDetails';

export interface IProgressSection {
  data?: any;
  isPending?: boolean;
  visible: boolean;
  increment: number;
  total: number;
  completedValue: number;
  type: ProgressSectionType;
}

interface SectionMeta {
  sectionsDone: number;
  sectionsOpen: number;
  firstOpenTask?: ProgressSectionType;
  completedValue: number;
  lastItemIndex?: number;
}
export const useSectionMeta = (sections: { [key: string]: IProgressSection }): SectionMeta => {
  const inquiryDetails = useCurrentInquiryDetails();

  const sectionsDone = sections
    ? Object.values(sections)
        .filter((section) => section.visible)
        .map((section) => (section.increment === section.total ? 1 : 0))
        .reduce((a: number, b: number) => a + b, 0)
    : 0;

  let sectionsOpen = sections
    ? Object.values(sections).filter((section) => section.visible).length - sectionsDone
    : 0;

  const firstOpenTask =
    sections &&
    (Object.values(sections)
      .filter((section) => section.visible && section.total - section.increment !== 0)
      .map((section) => section.type)[0] ||
      'openTask');

  const completedValue = (sectionsDone / (sectionsDone + sectionsOpen)) * 100 || 0;
  const lastItemIndex =
    sections && Object.values(sections).filter((item) => item.visible).length - 1;

  const defaultMeta = {
    sectionsDone,
    sectionsOpen,
    firstOpenTask,
    completedValue,
    lastItemIndex,
  };

  return useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]:
      inquiryDetails.status === INQUIRY_STATUSES.ARCHIVED
        ? { sectionsDone: 1, sectionsOpen: 0, completedValue: 1 }
        : defaultMeta,
    default: defaultMeta,
  });
};
export const useDigitalLeadProtect = (): ((visible: boolean) => boolean) => {
  // isLead && isDigitalProcessingEnabled && allowDigitalProcessing
  const lane = useSelector(getInquiryLane);
  // allowDigitalProcessing -> lead && isDigitalProcessingEnabled
  const allowDigitalProcessing = useSelector(getAllowDigitalProcessingSelector);
  const isDigitalProcessingEnabled = useConfig(CONFIG.IS_DIGITAL_PROCESSING_ENABLED);
  const digitalProcessingActive = !!allowDigitalProcessing && !!isDigitalProcessingEnabled;

  return (visible: boolean) =>
    (lane === InquiryLane.lead ? digitalProcessingActive : !!lane) && visible;
};

const useAllRequestedFilesUploadedLeasePlan = (): IProgressSection => {
  const fileRequests: IFileRequest[] = useSelector(getFileRequestsSelector);
  const numOfFilledRequests = fileRequests.filter((req) => !!req.files?.length).length;
  const numOfRequests = fileRequests.length;
  const inquiryStatus = useSelector(getInquiryStatusSelector);
  const total = numOfRequests;
  const allFilesUploaded = useSelector(getInquiryAllRequiredFilesUploaded);
  const visible = true;
  const increment = !allFilesUploaded ? numOfFilledRequests : numOfRequests;

  const disableButton = inquiryStatus === INQUIRY_STATUSES.FAILED ? true : allFilesUploaded;

  return {
    type: ProgressSectionType.DOCUMENT_UPLOAD,
    increment,
    total,
    data: {
      disableButton,
    },
    completedValue: total ? (increment / total) * 100 : 100,
    visible,
  };
};

const useAllRequestedFilesUploaded = (): IProgressSection => {
  const fileRequests: IFileRequest[] = useSelector(getRequiredFileRequestsSelector);
  const numOfFilledRequests = fileRequests.filter((req) => !!req.files?.length).length;
  const numOfRequests = fileRequests.length;

  const allRequiredFilesUploaded = useSelector(getInquiryAllRequiredFilesUploaded);

  const fileUploadedValue = numOfFilledRequests === numOfRequests;
  const total = numOfRequests;
  const increment = numOfFilledRequests;
  const visible = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: true,
    default: fileUploadedValue ? !fileUploadedValue : !allRequiredFilesUploaded,
  });

  return {
    type: ProgressSectionType.DOCUMENT_UPLOAD,
    increment,
    total,
    completedValue: allRequiredFilesUploaded ? 100 : (increment / total) * 100,
    visible,
  };
};

/**
 * Lane:
 *  - contract: conditions from inquiry flow  | visible: always
 *  - offer: conditions from inquiry flow     | visible: CONFIG.IS_SHOW_INDICATIVE_FOR_OFFER
 *  - lead: conditions from OP input          | visible: CONFIG.IS_SHOW_INDICATIVE_FOR_LEAD
 */
const useIndicativeOfferSection = (): IProgressSection => {
  const increment = useSelector(getIndicativeConditionCompleted) ? 1 : 0;
  const total = 1;
  const isPending = false;
  const lane = useSelector(getInquiryLane);
  const isLead = lane === InquiryLane.lead;
  const isOffer = lane === InquiryLane.offer;
  const isContract = lane === InquiryLane.contract;
  const isIndicativeOfferEnabledForLead = useConfig(CONFIG.IS_SHOW_INDICATIVE_FOR_LEAD);
  const isIndicativeOfferEnabledForOffer = useConfig(CONFIG.IS_SHOW_INDICATIVE_FOR_OFFER);
  const canShowOffer =
    (isIndicativeOfferEnabledForLead && isLead) ||
    (isIndicativeOfferEnabledForOffer && isOffer) ||
    isContract;

  return {
    type: ProgressSectionType.INDICATIVE_OFFER,
    isPending,
    visible: canShowOffer,
    data: {
      url: false,
    },
    increment,
    total,
    completedValue: (increment / total) * 100,
  };
};

/**
 * Lane:
 *  - contract: -> visible: always (URL exists || PENDING state)
 *  - offer: -> visible: always (URL exists || PENDING state)
 *  - lead: -> visible: CONFIG.IS_DIGITAL_PROCESSING_ENABLED && allowDigitalProcessing
 */
const useVideoIdentSectionLeasePlan = (): IProgressSection => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const videoIdentUrl = (inquiryDetails as IInquiryDetails)?.videoIdentUrl;
  const videoIdentStatus = (inquiryDetails as IInquiryDetails)
    ?.videoIdentStatus as VideoIdentStatus;
  const fesUrl = (inquiryDetails as IInquiryDetails)?.fesUrl;
  const fesStatus = (inquiryDetails as IInquiryDetails)?.fesStatus;
  const customerLegalRepresentativeId = useSelector(getCustomerLegalRepresentativeId);
  const legalRepresentatives = useSelector(getLegalRepresentatives);

  const increment = inquiryDetails.completedSignsCount || 0;

  const customerIdentificationType = inquiryDetails.customerIdentificationType;

  let actionUrl;
  let disableButton = false;

  switch (customerIdentificationType) {
    case CustomerIdentificationType.FES:
      actionUrl = fesStatus === FESStatus.NOT_COMPLETED && fesUrl;
      if (fesStatus !== FESStatus.NOT_COMPLETED) {
        disableButton = true;
      }
      break;
    case CustomerIdentificationType.QES:
      actionUrl = videoIdentStatus === VideoIdentStatus.WAITING && videoIdentUrl;
      if (videoIdentStatus === VideoIdentStatus.SUCCESS) {
        disableButton = true;
      }
      break;
    default:
      actionUrl = undefined;
  }

  actionUrl = customerLegalRepresentativeId && actionUrl;

  const total = legalRepresentatives ? legalRepresentatives.length : 0;

  const isPending = true;
  const visible = true;

  return {
    type: ProgressSectionType.VIDEO_IDENT,
    isPending,
    visible,
    data: {
      url: actionUrl,
      disableButton,
    },
    increment,
    total,
    completedValue: (increment / total) * 100,
  };
};

const useVideoIdentSection = (): IProgressSection => {
  const protectLead = useDigitalLeadProtect();
  const { selectedInquiryType } = useFormConfig();
  const isLeaseplan = selectedInquiryType === InquiryType.leaseplan;
  const isOnlinefactoring = selectedInquiryType === InquiryType.onlinefactoring;
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const url = (inquiryDetails as IInquiryDetails)?.videoIdentUrl;
  const videoIdentStatus = (inquiryDetails as IInquiryDetails)
    ?.videoIdentStatus as VideoIdentStatus;

  const completed = videoIdentStatus === VideoIdentStatus.SUCCESS;
  const isPending = videoIdentStatus === VideoIdentStatus.WAITING;
  const increment = completed ? 1 : 0;
  const total = 1;
  const showAction = !!url || !!isPending;
  const visible = protectLead(showAction) && (isLeaseplan || isOnlinefactoring);

  return {
    type: ProgressSectionType.VIDEO_IDENT,
    isPending,
    visible,
    data: {
      url,
      disableButton: completed,
    },
    increment,
    total,
    completedValue: (increment / total) * 100,
  };
};

const useAdditionalCompanyFields = (): IProgressSection => {
  const protectLead = useDigitalLeadProtect();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const company = inquiryDetails && _get(inquiryDetails, [INQUIRY_SECTIONS.COMPANY]);
  const inquiryId = inquiryDetails?.inquiryId ?? '';
  const requireFinalInfo = useConfig(CONFIG.REQUIRE_FINAL_INFO);

  const dataProvided =
    !!company[USER_PROFILE_DETAILED_ANNUAL_TURNOVER] &&
    !!company[USER_PROFILE_BALANCE_SHEET] &&
    !!company[USER_PROFILE_EMPLOYEE_COUNT];

  const increment = !dataProvided ? 0 : 1;
  const total = 1;
  const isPending = false;
  const visible = requireFinalInfo && protectLead(!dataProvided);

  return {
    type: ProgressSectionType.ADDITIONAL_FIELDS,
    isPending,
    visible,
    data: {
      url: paths.customer.inquiryDetails.additionalFields.replace(':id', inquiryId),
    },
    increment,
    total,
    completedValue: (increment / total) * 100,
  };
};

const useFactoringOffer = () => {
  const increment = useSelector(getIndicativeConditionCompleted) ? 1 : 0;
  const total = 1;

  return {
    type: ProgressSectionType.FACTORING_OFFER,
    isPending: false,
    visible: true,
    increment,
    total,
    completedValue: (increment / total) * 100,
  };
};

const useConfirmObjectTakeOver = (): IProgressSection => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector);

  if (!inquiryDetails || !inquiryDetails.inquiryId || !inquiryDetails.substatus) {
    return {
      type: ProgressSectionType.CONFIRM_OBJECT_TAKEOVER,
      increment: 0,
      total: 1,
      completedValue: 0,
      visible: true,
    };
  }

  const inquiryId = inquiryDetails.inquiryId;

  const isCompleted = mmvBusinessConstraints.isCompleted(inquiryDetails.substatus);
  const isPending = inquiryDetails.substatus === INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_PENDING;
  const requireUserAction =
    inquiryDetails.substatus === INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_DATA_RECEIVED;

  const increment = isCompleted ? 1 : 0;
  const total = 1;

  return {
    type: ProgressSectionType.CONFIRM_OBJECT_TAKEOVER,
    increment,
    total,
    completedValue: (increment / total) * 100,
    visible: isCompleted || isPending || requireUserAction,
    data: {
      inquiryId: inquiryId,
      isPending,
      requireUserAction,
    },
  };
};

export const useSignContract = (): IProgressSection => {
  const isEnabled = useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]: true,
    default: false,
  });

  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const { data } = useInquirySignees({
    variables: { id: inquiryDetails?.inquiryId || '' },
    enabled: !!inquiryDetails?.inquiryId && isEnabled,
    select: (data) => [
      ...Object.values(data.data.customer || {}),
      ...Object.values(data.data.legalRepresentative || {}),
    ],
    // caught by DataProvider
    suspense: true,
  });

  const increment = useMemo(() => {
    const filteredRepresentatives = data?.filter(
      (signee) => signee.attributes.status === SIGNEE_STATUS.SUCCESSFULLY_SIGNED,
    );

    return filteredRepresentatives?.length || 0;
  }, [data]);

  const total = data?.length || 0;
  const completedValue = (increment / total) * 100;

  const hasInvalidStatus =
    inquiryDetails?.substatus &&
    [INQUIRY_SUBSTATUSES.INITIATED_EXTERNALLY].includes(inquiryDetails?.substatus);

  if (hasInvalidStatus) {
    return {
      type: ProgressSectionType.SIGN_CONTRACT,
      increment: 0,
      total: 1,
      completedValue: 0,
      visible: true,
      isPending: true,
      data: [],
    };
  }

  return {
    type: ProgressSectionType.SIGN_CONTRACT,
    increment,
    total,
    completedValue,
    visible: true,
    data: data || [],
  };
};

const useObjectData = (): IProgressSection => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector);

  const hasRequiredStatus =
    inquiryDetails?.substatus &&
    [
      INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_DONE,
      INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_PENDING,
      INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_DATA_RECEIVED,
      INQUIRY_SUBSTATUSES.PAYMENT_PLAN_RECEIVED,
    ].includes(inquiryDetails?.substatus);

  if (!hasRequiredStatus) {
    return {
      type: ProgressSectionType.OBJECT_DATA,
      increment: 0,
      total: 0,
      completedValue: 0,
      visible: false,
    };
  }

  const increment =
    inquiryDetails.substatus === INQUIRY_SUBSTATUSES.OBJECT_CONFIRMATION_PENDING ? 0 : 1;
  const total = 1;
  const completedValue = (increment / total) * 100;

  return {
    increment,
    total,
    completedValue,
    type: ProgressSectionType.OBJECT_DATA,
    visible: true,
    data: {
      inquiryId: inquiryDetails.inquiryId,
    },
  };
};

export type typeSpecificMapType = {
  [key in InquiryType]?: {
    [key: string]: IProgressSection;
  };
} & {
  default: {
    [key: string]: IProgressSection;
  };
};

export const useProgressSections = () => {
  const { selectedInquiryType } = useFormConfig();
  const indicativeOffer = useIndicativeOfferSection();
  const videoIdent = useVideoIdentSection();
  const additionalFields = useAdditionalCompanyFields();
  const requestedFiles = useAllRequestedFilesUploaded();

  const videoIdentLeasePlan = useVideoIdentSectionLeasePlan();
  const requestedFilesLeaseplan = useAllRequestedFilesUploadedLeasePlan();

  const factoringOffer = useFactoringOffer();
  const confirmObjectTakeOver = useConfirmObjectTakeOver();
  const signContract = useSignContract();
  const objectData = useObjectData();

  const lane = useSelector(getInquiryLane);
  const isContract = lane === InquiryLane.contract;

  const typeSpecificSection: typeSpecificMapType = {
    [InquiryType.leaseplan]: {
      videoIdentLeasePlan,
      requestedFilesLeaseplan,
    },
    [InquiryType.onlinefactoring]: {
      indicativeOffer,
      additionalFields,
      requestedFiles,
      videoIdent,
    },
    [InquiryType.bfsService]: {
      factoringOffer,
      requestedFiles,
    },
    [InquiryType.mmv]: {
      signContract,
      objectData,
      confirmObjectTakeOver,
    },
    default: isContract
      ? { indicativeOffer, additionalFields, requestedFiles, videoIdent }
      : { indicativeOffer, requestedFiles, videoIdent, additionalFields },
  };
  // the order here is important as it decides on the order of the tiles in the UI
  const sections = typeSpecificSection[selectedInquiryType] || typeSpecificSection.default;

  const { firstOpenTask, completedValue, sectionsOpen, sectionsDone, lastItemIndex } =
    useSectionMeta(sections);

  return {
    firstOpenTask,
    sections,
    completedValue,
    sectionsDone,
    sectionsOpen,
    lastItemIndex,
  };
};
