import { API_METHODS, callReverseApi } from '../../../../api/apiService';
import endpoints from '../../../../api/CompeonReverseApi/endpoints';
import { AppActionThunk } from '../../../../models/types/AppThunk.type';
import { ObjectImage } from '../pages/PropertyProfile/UploadObjectImages';

export const SET_MARKET_DATA = 'SET_MARKETDATA';
export const SET_PLANNING_EVALUATION_PROFITABILITY = 'SET_PROFITABILITY';
export const setPlanningEvaluationProfitability =
  (data: any): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_PLANNING_EVALUATION_PROFITABILITY,
      payload: data,
    });
export const getPlanningEvaluationProfitability = (inquiryId: string | undefined | null) => {
  if (inquiryId) {
    return callReverseApi({
      url: endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
        params: { inquiryId },
      }),
      method: API_METHODS.GET,
    });
  }
};

export const setMarketData =
  (data: any): AppActionThunk =>
  (dispatch) =>
    dispatch({
      type: SET_MARKET_DATA,
      payload: data,
    });

export const SET_OBJECT_IMAGES = 'SET_OBJECT_IMAGES';
export const setObjectImages =
  (objectImages: Array<ObjectImage>): AppActionThunk =>
  (dispatch) => {
    dispatch({
      type: SET_OBJECT_IMAGES,
      payload: objectImages,
    });
  };
