import {
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT__15_TO_30_DAYS,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT__31_TO_45_DAYS,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT__46_TO_60_DAYS,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT__MORE_THAN_60_DAYS,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT__UP_TO_14_DAYS,
} from 'modules/Inquiry/Form/formFields';

const getFactoringAveragePeriodOfPaymentOptions = (formatMessage) => [
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringAveragePeriodOfPayment.options.to',
      },
      { to: 14 },
    ),
    value: FACTORING_AVERAGE_PERIOD_OF_PAYMENT__UP_TO_14_DAYS,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringAveragePeriodOfPayment.options.fromTo',
      },
      { from: 15, to: 30 },
    ),
    value: FACTORING_AVERAGE_PERIOD_OF_PAYMENT__15_TO_30_DAYS,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringAveragePeriodOfPayment.options.fromTo',
      },
      { from: 31, to: 45 },
    ),
    value: FACTORING_AVERAGE_PERIOD_OF_PAYMENT__31_TO_45_DAYS,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringAveragePeriodOfPayment.options.fromTo',
      },
      { from: 46, to: 60 },
    ),
    value: FACTORING_AVERAGE_PERIOD_OF_PAYMENT__46_TO_60_DAYS,
  },
  {
    label: formatMessage(
      {
        id: 'pages.requestDetails.sections.factoring.fields.factoringAveragePeriodOfPayment.options.above',
      },
      { days: 60 },
    ),
    value: FACTORING_AVERAGE_PERIOD_OF_PAYMENT__MORE_THAN_60_DAYS,
  },
];

export default getFactoringAveragePeriodOfPaymentOptions;
