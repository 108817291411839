import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { iban, bic, bankName } = dzbFields.companyDataPage.directDebitMandateSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.companyData.sections.directDebitMandate;

export const directDebitMandateSectionLabelsMap: LabelsMap = {
  [iban]: fieldTranslations.iban.caption,
  [bic]: fieldTranslations.bic.caption,
  [bankName]: fieldTranslations.bankName.caption,
};
