import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { OTHER_PRODUCT_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const OtherProductDescription = () => {
  const t = useTranslations();

  return (
    <TextAreaWithField
      name={OTHER_PRODUCT_DESCRIPTION}
      captionTooltip={t(
        'pages.requestDetails.sections.other.fields.otherProductDescription.tooltip',
      )}
    />
  );
};

export default OtherProductDescription;
