import { useState, useCallback, useMemo } from 'react';

import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { ConfigController } from 'config/ConfigController';
import { usePartnerConfig } from 'config/partner/hooks';
import {
  END_USER_CONDITION_AMOUNT,
  END_USER_CONDITION_INTEREST_RATE,
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS,
  USER_PROFILE_PHONE_NUMBER,
  FINANCING_NEED,
  FINANCING_AMOUNT,
  LOAN_TERM,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  USER_PROFILE_COMPANY_INTERNAL_RATING,
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useToasts } from 'shared/hooks/useToasts';
import { useFieldValue } from 'store/hooks/useFormValues';
import { getIndicativeOfferCalculations } from 'store/inquiryDetails/selectors';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { getCreatedInquiryId, getStoredValueSelector } from 'store/progress/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  getParsedItemFromSessionStorage,
  saveObjectToSessionStorage,
  removeObjectFromSessionStorage,
} from 'utils/sessionStorage/helpers';
import { INSTALMENT_PROCESS } from 'utils/sessionStorage/keys';
import { loadFormStepStateFromStorage } from 'utils/sessionStorage/loadFormState';
import { userIsBankAdvisor } from 'utils/user/conditionals';

import { useConfirmPhoneNumber } from './useConfirmPhoneCall';
import { useRequestIndicativeConditions } from './useRequestIndicativeConditions';
import { useRequestInterestRateAndInstallment } from './useRequestInterestRateAndInstalment';

export const useInstalmentHandler = () => {
  let crefoId: string | undefined;
  if (loadFormStepStateFromStorage(COMPANY_DETAILS)[COMPANY_DETAILS_COMPANY]) {
    crefoId = loadFormStepStateFromStorage(COMPANY_DETAILS)[COMPANY_DETAILS_COMPANY]['crefo-id'];
  } else if (loadFormStepStateFromStorage(COMPANY_DETAILS)[COMPANY_DETAILS_EXISTING_USER_COMPANY]) {
    crefoId =
      loadFormStepStateFromStorage(COMPANY_DETAILS)[COMPANY_DETAILS_EXISTING_USER_COMPANY][
        'crefo-id'
      ];
  }

  const t = useTranslations();
  const phoneNumber = useFieldValue<string>(USER_PROFILE_PHONE_NUMBER, '');
  const financingAmount = loadFormStepStateFromStorage(FINANCING_NEED)[FINANCING_AMOUNT];
  const loanTermInMonth = useFieldValue<string>(LOAN_TERM, '');
  const processLane = useSelector(getProcessLane);
  const internalRatingValue = useSelector(
    getStoredValueSelector([COMPANY_DETAILS, USER_PROFILE_COMPANY_INTERNAL_RATING]),
  );
  const amount = useFieldValue<string>(END_USER_CONDITION_AMOUNT, '');
  const rate = useFieldValue<string>(END_USER_CONDITION_INTEREST_RATE, '');
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const inquiryId = useSelector(getCreatedInquiryId);
  const calculations = useSelector(getIndicativeOfferCalculations);
  const [phoneSend, setPhoneSend] = useState(false);
  const [codeSend, setCodeSend] = useState(false);
  const [unavailable, setUnavailable] = useState(false);
  const {
    isPending: isPendingPhoneNumberConfirmation,
    confirmPhone,
    confirmToken,
  } = useConfirmPhoneNumber();
  const { isPending: isPendingValues, requestInterestRateAndInstallment } =
    useRequestInterestRateAndInstallment();
  const { requestIndicativeConditions } = useRequestIndicativeConditions();
  const { change } = useForm();
  const hasBankAdvisorRole = userIsBankAdvisor();
  const inquiryType = ConfigController.form.getSelectedInquiryType();

  const { error } = useToasts();

  const getInstalmentAccess = useCallback(
    () => getParsedItemFromSessionStorage(INSTALMENT_PROCESS) ?? {},
    [],
  );

  const dropInstalment = useCallback(() => {
    if (getInstalmentAccess() && getInstalmentAccess().crefoId !== crefoId) {
      change(END_USER_CONDITION_AMOUNT, undefined);
      change(END_USER_CONDITION_INTEREST_RATE, undefined);

      removeObjectFromSessionStorage(INSTALMENT_PROCESS);
      setCodeSend(false);
      setPhoneSend(false);
    }
  }, [change, crefoId, getInstalmentAccess]);

  const setInstalmentAccess = ({ accessToken }: { accessToken: string }) =>
    saveObjectToSessionStorage(INSTALMENT_PROCESS, { accessToken, crefoId });

  const submitPhoneNumber = async () => {
    const res = await confirmPhone({
      phone_number: phoneNumber,
      partner_name: partnerId,
      inquiry_id: inquiryId,
    });
    // Remove res.status === 200 after backend changes
    if (res.status === 204 || res.status === 200) {
      setPhoneSend(true);
    } else if (res.response.status === 429) {
      error({ description: t('components.smsVerification.errorAttempts') });
    } else if (res.response.status === 422) {
      error({ description: t('components.smsVerification.errorPhoneNumber') });
    } else {
      setPhoneSend(false);
    }
  };

  const internalRating = useMemo(
    () => (internalRatingValue ? { internal_rating: internalRatingValue } : null),
    [internalRatingValue],
  );

  // if the user chose external rating then we need another possibility of authorizing them to get the calculation
  // as in case of internal rating, the rating value itself allows it. crefo_id and internal_rating values are
  // mutually exclusive for backend
  const noInternalRating = useMemo(
    () => (!internalRatingValue && hasBankAdvisorRole ? { crefo_id: crefoId } : null),
    [crefoId, internalRatingValue, hasBankAdvisorRole],
  );

  const requestValues = useCallback(async () => {
    // bank advisor does not have the access token because they do not send the SMS token
    const accessValue = hasBankAdvisorRole
      ? null
      : { access_token: getInstalmentAccess().accessToken };

    if (inquiryType === InquiryType.onlinefactoring || inquiryType === InquiryType.bfsService) {
      const res = await requestIndicativeConditions(getInstalmentAccess().accessToken);

      change(OFFER_FACTORING_FEE, parseFloat(res.data.factoring_fee));
      change(OFFER_FACTORING_LINE, parseFloat(res.data.factoring_line));
      if (inquiryType === InquiryType.bfsService) {
        change(OFFER_PAYOUT_RATIO, parseFloat(res.data.payout_ratio));
      } else {
        change(OFFER_PAYOUT_RATIO, parseFloat(res.data.payout_rate));
      }

      return res;
    }

    // avoid unnecessary BE call with empty value
    if (loanTermInMonth) {
      const res = await requestInterestRateAndInstallment({
        amount: financingAmount,
        duration: loanTermInMonth,
        process_lane: processLane,
        ...accessValue,
        ...internalRating,
        ...noInternalRating,
      });
      if (res.status === 201) {
        change(END_USER_CONDITION_AMOUNT, parseFloat(res.data.data.instalment_rate));
        change(END_USER_CONDITION_INTEREST_RATE, parseFloat(res.data.data.interest_rate));
      } else if (res.response.status === 403) {
        error({
          description: t(
            `components.smsVerification.${hasBankAdvisorRole ? 'errorRating' : 'errorToken'}`,
          ),
        });
      } else if (res.response.status === 422) {
        setUnavailable(true);
        setCodeSend(false);
        setPhoneSend(false);
      } else {
        setCodeSend(false);
        setPhoneSend(false);
      }
    }
  }, [
    getInstalmentAccess,
    processLane,
    change,
    financingAmount,
    loanTermInMonth,
    error,
    requestInterestRateAndInstallment,
    internalRating,
    hasBankAdvisorRole,
    noInternalRating,
    t,
    inquiryType,
    requestIndicativeConditions,
  ]);

  const resetPhoneNumber = () => {
    setPhoneSend(false);
    setCodeSend(false);
  };

  const submitPinNumber = async (pin: string) => {
    const res = await confirmToken({ phone_number: phoneNumber, token: pin });
    if (res.status === 200) {
      setCodeSend(true);
      setInstalmentAccess({ accessToken: res.data.access_token });
      requestValues();
    } else if (res.response.status === 403) {
      error({ description: t('components.smsVerification.errorToken') });
    }
    setCodeSend(false);
  };

  return {
    dropInstalment,
    getInstalmentAccess,
    setInstalmentAccess,
    resetPhoneNumber,
    requestValues,
    submitPinNumber,
    submitPhoneNumber,
    isPendingValues,
    isPendingPhoneNumberConfirmation,
    data: {
      amount,
      rate,
      loanTermInMonth,
      phoneNumber,
      calculations,
    },
    state: {
      phoneSend,
      codeSend,
      unavailable,
    },
  };
};
