import * as React from 'react';

import { Grid, GridItem, useStyleConfig } from '@chakra-ui/react';

import { HeadingComponent } from '../Heading';

interface Props {
  label: string;
  children: React.ReactNode;
}

export const HorizontalSection = ({ label, children }: Props) => {
  const styles = useStyleConfig('HorizontalSection');
  return (
    <Grid sx={styles}>
      <GridItem>
        <HeadingComponent
          as="h4"
          variant="secondary"
          color="brand.default"
          data-testid={'DB-Section-Labels'}
        >
          {label}
        </HeadingComponent>
      </GridItem>

      <GridItem>{children}</GridItem>
    </Grid>
  );
};
