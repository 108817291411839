import { beneficialOwnersSectionLabelsMap } from 'dzb/inquiry/steps/personalData/sections/beneficialOwnersSection/labels';
import { contactPersonSectionLabelsMap } from 'dzb/inquiry/steps/personalData/sections/contactPersonSection/labels';
import { legalRepresentativesSectionLabelsMap } from 'dzb/inquiry/steps/personalData/sections/legalRepresentativesSection/labels';
import { LabelsMap } from 'new/form/common/types';

export const dzbPersonalDataLabelsMap: LabelsMap = {
  ...contactPersonSectionLabelsMap,
  ...legalRepresentativesSectionLabelsMap,
  ...beneficialOwnersSectionLabelsMap,
};
