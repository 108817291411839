import {
  ADP_NUMBER,
  VB_NUMBER,
  VM_NUMBER,
  VB_ZIP_CODE,
  STRUCTURE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

import { InformationField } from './InformationRows';

export const useFilterDetailViewFields = () => {
  const t = useTranslations();

  // hide following fields with empty values (valueToDrop)
  const fieldsForEmptyDrop = [VB_NUMBER, VM_NUMBER, VB_ZIP_CODE, ADP_NUMBER, STRUCTURE_NUMBER];
  const valueToDrop = ['', '-', t('other.notAvailable')];

  const filterEmptyField = (row: InformationField) =>
    !fieldsForEmptyDrop.includes(row.key) || !valueToDrop.includes(row.value);

  const filterFieldsInRow = (row: InformationField[]) => row.filter(filterEmptyField);

  return { filterEmptyField, filterFieldsInRow };
};
