import { MarketData } from 'models/types/MarketData.type';
import {
  CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM,
  CURRENT_USAGE_EXISTING_RENT_PER_SQM,
  CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM,
  FUTURE_USAGE_EXISTING_RENT_PER_SQM,
  FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT,
  FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K,
} from 'modules/Inquiry/Form/formFields';

const mapMarketValueFromApi = (response: MarketData) => ({
  [CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM]: response.current.unit.residentialRentPerSqm || 0,
  [CURRENT_USAGE_EXISTING_RENT_PER_SQM]: response.current.unit.existingRentPerSqm || 0,
  [CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM]: response.current.building.commercialRentPerSqm || 0,
  [CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING]:
    response.current.building.comparativeValuePerSqm || 0,
  [CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT]: response.current.unit.comparativeValuePerSqm || 0,
  [FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM]: response.future.unit.residentialRentPerSqm || 0,
  [FUTURE_USAGE_EXISTING_RENT_PER_SQM]: response.future.unit.existingRentPerSqm || 0,
  [FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM]: response.future.building.commercialRentPerSqm || 0,
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_WHG_K]: response.future.unit.comparativeValuePerSqm || 0,
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING]:
    response.future.building.comparativeValuePerSqm || 0,
  [FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT]: response.future.unit.comparativeValuePerSqm || 0,
});

export default mapMarketValueFromApi;
