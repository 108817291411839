import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { DzbCompanyDataPage } from 'dzb/inquiry/steps/companyData';
import { DzbFinalPage } from 'dzb/inquiry/steps/final';
import { DzbPersonalDataPage } from 'dzb/inquiry/steps/personalData';
import { isValidLegalRepresentatives } from 'dzb/inquiry/steps/personalData/sections/legalRepresentativesSection/validations';
import { DzbRetailerDataPage } from 'dzb/inquiry/steps/retailerData';
import { DzbSummaryStep } from 'dzb/inquiry/steps/summary';
import { SendDzbInquiryPayload, useSendDzbInquiry } from 'dzb/inquiry/useSendDzbInquiry';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { InquiryProcessForm } from 'new/form';
import { translations } from 'new/form/common/types';
import { useGetInitialValuesForEditMode } from 'new/form/persist';
import { LEGAL_REPRESENTATIVE_ERROR } from 'new/legalRepresentatives/LegalRepresentativeCards';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

export const DZB_FORM = 'DZB_FORM';

const EditCustomerRoutes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={paths.customer.inquiryDetails.edit.root}
        to={paths.customer.inquiryDetails.edit.financingNeed}
      />
      <Redirect
        exact
        from={paths.customer.inquiryDetails.edit.contractDetails}
        to={paths.customer.inquiryDetails.edit.financingNeed}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.financingNeed}
        component={DzbCompanyDataPage}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.companyDetails}
        component={DzbRetailerDataPage}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.contactPersonDetails}
        component={DzbPersonalDataPage}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.summaryPage}
        component={DzbSummaryStep}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.finalPage}
        component={DzbFinalPage}
      />
    </Switch>
  );
};

export const DzbEditCustomerPortalForm = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const initialValues = useGetInitialValuesForEditMode();
  const sendInquiry = useSendDzbInquiry();
  const history = useHistory();
  const t = useTranslations();
  const { error: errorToast } = useToasts();
  const { refetchInquiry } = useFetchInquiryData();

  const handleSubmit = async (values: DzbFieldTypes) => {
    const result = await sendInquiry(values);
    const success = !result.error && result.payload;
    if (success) {
      await refetchInquiry();
      const payload: SendDzbInquiryPayload = result?.payload;
      const wasPotentiallyManipulated =
        payload?.data?.included
          .filter((i) => i.type === 'legal_representatives' || i.type === 'beneficiary_owners')
          .filter((i) => i.attributes.potentially_manipulated).length > 0;
      const pathname = paths.customer.inquiryDetails.edit.finalPage.replace(':id', inquiryId!!);
      history.push({
        pathname,
        state: { status: wasPotentiallyManipulated ? 'error' : 'success' },
      });
    } else {
      console.error(result.error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
    return success;
  };

  return (
    <InquiryProcessForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validate={(values: DzbFieldTypes) => {
        const errors: any = {};

        if (!isValidLegalRepresentatives(values)) {
          errors[LEGAL_REPRESENTATIVE_ERROR] = t(
            translations.inquiryType.dzb.pages.personalData.sections.legalRepresentatives.errors
              .atLeastOneSoleRepresentative,
          );
        }

        return errors;
      }}
    >
      <EditCustomerRoutes />
    </InquiryProcessForm>
  );
};
