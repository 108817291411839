import { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { USER_PROFILE, SUMMARY } from 'modules/Inquiry/Form/formFields';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import { scrollTop } from 'utils/scroll';

export const useRedirectUserOnRefresh = () => {
  const history = useHistory();

  const { getPathToStep } = useInquiryNavigation(SUMMARY);

  const handleRedirection = useCallback(() => {
    const pathToUserProfile = getPathToStep(USER_PROFILE);
    if (history.action === 'POP' && pathToUserProfile) {
      history.push(pathToUserProfile);
      scrollTop();
    }
  }, [getPathToStep, history]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  return history.action === 'POP';
};
