import { useInquiriesListContext } from 'modules/InquiriesListProvider';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { useInquiryTableHeaders } from 'pages/operationPortal/OperationInquiryList/useInquiryTableHeaders';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import usePortalPermissions from 'shared/hooks/usePortalPermissions';

const DEFAULT_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
  INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT,
  INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.SUB_STATUS,
];

const MITTWEIDA_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
  INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.SUB_STATUS,
];

const BFSS_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT,
  INQUIRY_TABLE_HEADERS.FACTORING_LINE,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.SUB_STATUS,
];

const PROFI_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
  INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.SUB_STATUS,
];

const MMV_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.VPP_ID,
  INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION,
  INQUIRY_TABLE_HEADERS.SUPPLIER,
  INQUIRY_TABLE_HEADERS.AGENT,
  INQUIRY_TABLE_HEADERS.ZIP_CODE_AREA,
  INQUIRY_TABLE_HEADERS.STATUS,
];

const DZB_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.PARTNER_REFERENCE_ID,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.ASSOCIATION_NAME,
  INQUIRY_TABLE_HEADERS.COMPANY_COUNTRY,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.STATUS,
];

const HAUSBANK_TABLE_HEADERS = [
  INQUIRY_TABLE_HEADERS.ID,
  INQUIRY_TABLE_HEADERS.CREATED_AT,
  INQUIRY_TABLE_HEADERS.COMPANY_NAME,
  INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_NAME,
  INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_ZIPCODE,
  INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_CITY,
  INQUIRY_TABLE_HEADERS.FINANCING_NEED,
  INQUIRY_TABLE_HEADERS.PROCESSOR,
  INQUIRY_TABLE_HEADERS.STATUS,
];

export const useOperationInquiryTableHeaders = () => {
  const tableHeaders = useInquiryTableHeaders();
  const { tableOptions } = useInquiriesListContext();

  const shouldShowProcessor = usePortalPermissions().canShowProcessorColumn(
    tableOptions.filterBy.status,
  );

  const visibilityRules = {
    [INQUIRY_TABLE_HEADERS.PROCESSOR]: shouldShowProcessor,
  };

  const headersForPartner = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: BFSS_TABLE_HEADERS,
    [InquiryType.profiRLL]: PROFI_TABLE_HEADERS,
    [InquiryType.profiMittweida]: MITTWEIDA_TABLE_HEADERS,
    [InquiryType.mmv]: MMV_TABLE_HEADERS,
    [InquiryType.dzb]: DZB_TABLE_HEADERS,
    [InquiryType.hausbank]: HAUSBANK_TABLE_HEADERS,
    default: DEFAULT_TABLE_HEADERS,
  }) as Array<InquiryType>;

  return headersForPartner
    .filter((header: InquiryType) => {
      const hasRule = typeof visibilityRules[header] !== 'undefined';
      return hasRule ? visibilityRules[header] : true;
    })
    .map((header: InquiryType) => tableHeaders[header]);
};
