import React from 'react';

import FormRow from 'components/FormRow';
import { useGenerateLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/formFields/useGenerateLegalRepresentativeFields';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

type Props = {
  mode: AssociatedPersonMode;
  prefix?: string;
};

export const HausbankLegalRepresentativeFields = ({ mode, prefix }: Props) => {
  const fields = useGenerateLegalRepresentativeFields(mode, prefix);

  return (
    <>
      <FormRow>{fields.salutation}</FormRow>
      <FormRow>
        {fields.firstName}
        {fields.lastName}
      </FormRow>
      <FormRow>{fields.birthDate}</FormRow>
      <FormRow>{fields.email}</FormRow>
      <FormRow>{fields.phoneNumber}</FormRow>
      <FormRow space={'1.5rem'}>{fields.soleSignatureAuthorized}</FormRow>
    </>
  );
};
