import React from 'react';

import PropTypes from 'prop-types';

import RadioGroup from 'components/RadioGroup';
import intlShape from 'shared/intlShape';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { withIntl } from 'utils/intl';

export const OnlineFactoringRadioGroupUnwrapped = ({ intl: { formatMessage }, ...props }) => {
  const handleChange = (ev) => props.onChange(ev.target.value);

  return (
    <RadioGroup
      options={[
        {
          caption: formatMessage({ id: 'components.onlineFactoringRadioGroup.days45' }),
          value: '45',
        },
        {
          caption: formatMessage({ id: 'components.onlineFactoringRadioGroup.days60' }),
          value: '60',
        },
      ]}
      {...props}
      onChange={handleChange}
    />
  );
};

OnlineFactoringRadioGroupUnwrapped.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
};

const OnlineFactoringRadioGroup = withIntl(OnlineFactoringRadioGroupUnwrapped);

const OnlineFactoringRadioGroupAdapter = ({ input, meta, ...rest }) => {
  return (
    <OnlineFactoringRadioGroup
      {...input}
      value={input.value}
      onChange={(value) => {
        input.onChange(value);
      }}
      {...meta}
      {...rest}
    />
  );
};
OnlineFactoringRadioGroupAdapter.propTypes = FinalFormFieldPropTypes;

const OnlineFactoringRadioGroupWithField = withFormField(OnlineFactoringRadioGroupAdapter);

export default OnlineFactoringRadioGroup;

export { OnlineFactoringRadioGroupWithField };
