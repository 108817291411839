import * as React from 'react';
import { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { NewStaticFieldWithField } from 'components/StaticField';
import { mittweidaFields, MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { replaceUndefinedWithZero } from 'new/form/common/replaceUndefinedWithNumber';
import { formatPrice } from 'utils/valueFormats';

const { financingAmount } = mittweidaFields.financingNeedPage.projectCompositionSection;

function useCalculateFinancingAmount() {
  const { change } = useForm();
  const { values } = useFormState<MittweidaFieldTypes>();

  const { projectFinancingRole } = values.financingNeedPage.roleSection;
  const {
    totalInvestment,
    totalInvestmentInBuyingPhase,
    ownFunds,
    ownFundsInBuyingPhase,
    mezzanineCapital,
    subordinatedCapital,
    ownWork,
    financeWholeProject: financeWholeProjectStringRepresentation,
  } = values.financingNeedPage.projectCompositionSection;

  useEffect(() => {
    const financeWholeProject =
      financeWholeProjectStringRepresentation &&
      JSON.parse(financeWholeProjectStringRepresentation as any);

    if (projectFinancingRole === 'developer' && !financeWholeProject) {
      const calculation = replaceUndefinedWithZero({
        totalInvestmentInBuyingPhase,
        ownFundsInBuyingPhase,
      });

      const result = calculation.totalInvestmentInBuyingPhase - calculation.ownFundsInBuyingPhase;

      change(financingAmount, result);
    } else {
      const calculation = replaceUndefinedWithZero({
        totalInvestment,
        ownFunds,
        mezzanineCapital,
        subordinatedCapital,
        ownWork,
      });

      const result =
        calculation.totalInvestment -
        calculation.ownFunds -
        calculation.mezzanineCapital -
        calculation.subordinatedCapital -
        calculation.ownWork;

      change(financingAmount, result);
    }
  }, [
    projectFinancingRole,
    totalInvestment,
    totalInvestmentInBuyingPhase,
    ownFunds,
    ownFundsInBuyingPhase,
    mezzanineCapital,
    subordinatedCapital,
    ownWork,
    financeWholeProjectStringRepresentation,
    change,
  ]);
}

export const ProjectFinancingAmount = () => {
  useCalculateFinancingAmount();

  return (
    <NewStaticFieldWithField
      name={mittweidaFields.financingNeedPage.projectCompositionSection.financingAmount}
      parseValue={(value: number) => formatPrice(value, true)}
    />
  );
};
