import React from 'react';

import PropTypes from 'prop-types';

import FileBlock from 'components/FileBlock';
import LoaderVertical from 'components/LoaderVertical/styles';
import Spinner from 'components/Spinner';
import { StyledUploadWrapperCommon } from 'components/UploadBlock/UploadedFileBlock/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

const UploadUploadingState = ({ uploadProgress }) => {
  const t = useTranslations('components.upload');
  return (
    <StyledUploadWrapperCommon>
      <FileBlock title={t('uploading')} icon={<Spinner />} color={null}>
        <LoaderVertical progress={uploadProgress} />
      </FileBlock>
    </StyledUploadWrapperCommon>
  );
};

UploadUploadingState.defaultProps = {
  uploadProgress: 100,
};

UploadUploadingState.propTypes = {
  uploadProgress: PropTypes.number,
};

export default UploadUploadingState;
