import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import { REAL_ESTATE_KIND, REAL_ESTATE_PURCHASE_PRICE } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import RealEstateKind from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/RealEstate/fields/RealEstateKind';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import BFSPurchase from './BFSRealEstateFields/BFSPurchase';

export const validatePurchaseSection = (form) =>
  fieldsAreValid([REAL_ESTATE_KIND, REAL_ESTATE_PURCHASE_PRICE])({ form });

const Purchase = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.realEstatePurchase}
      sectionNumber={4}
      title={t('pages.financingNeed.sections.purchase.title')}
    >
      <FormRow>
        <RealEstateKind />
      </FormRow>
      <FormRow>
        <AmountWithField name={REAL_ESTATE_PURCHASE_PRICE} />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BFSPurchase,
  default: Purchase,
});
