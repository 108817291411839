// Some fields have nested names like root_field[0].nested_field
// this method extract last part (ex. nested_field) for some map and configurations

export const extractNestedFieldName = (name: string) => {
  if (typeof name === 'string') {
    return name.split('.').pop() || '';
  }
  return name;
};

export const removeBracketsFromArrayFields = (name: string) => {
  return name.replace(/\[.*?]/g, '');
};
