import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { useFormConfig } from 'config/formConfig/hooks';
import Condition from 'modules/ConditionalSubform/Condition';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  BUILDING_TYPE,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_COOWNERSHIP,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_TYPE_OF_USE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_CONSTRUCTION_YEAR,
  BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
  BUILDING_STREET_AND_HOUSE_NUMBER,
  BUILDING_POSTAL_CODE,
  BUILDING_PLACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_TYPE__CONDOMINIUM,
  BUILDING_TYPE__OTHER,
  BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
  BUILDING_TYPE__APARTMENT,
} from 'modules/Inquiry/Form/formFields';
import BuildingShareOfCommercialSpace from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/BuildingShareOfCommercialSpace';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { fieldBusinessConditions as conditions } from 'schema/inquirySchema.conditions';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateBuildingInformationSection = validateSectionFields([
  BUILDING_TYPE,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_COOWNERSHIP,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_TYPE_OF_USE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_CONSTRUCTION_YEAR,
  BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
  BUILDING_STREET_AND_HOUSE_NUMBER,
  BUILDING_POSTAL_CODE,
  BUILDING_PLACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
]);

const shouldShowCondomiumFields = ({ form }: any): boolean => {
  const formValues = form.getState().values;

  return conditions.hasSelectedBuildingType(BUILDING_TYPE__CONDOMINIUM)({ formValues });
};

const shouldShowCommercialAndLivingSpace = ({ form }: any): boolean => {
  const formValues = form.getState().values;

  return (
    conditions.hasSelectedBuildingType(BUILDING_TYPE__OTHER)({ formValues }) ||
    conditions.hasSelectedBuildingType(BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL)({ formValues })
  );
};

const shouldShowNumberOfResidentialUnits = ({ form }: any): boolean => {
  const formValues = form.getState().values;

  return conditions.hasSelectedBuildingType(BUILDING_TYPE__APARTMENT)({ formValues });
};

const shouldShowShareOfUsage = ({ form }: any): boolean => {
  const formValues = form.getState().values;
  return conditions.hasSelectedBuildingType(BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL)({
    formValues,
  });
};

const shouldShowCommercialRentAndSpaceUsage = ({ form }: any): boolean => {
  const formValues = form.getState().values;
  return (
    conditions.isBuildingWithThirdParty({ formValues }) &&
    conditions.isBuildingCommercialRentableType({ formValues })
  );
};

const shouldShowResidentialRentAndSpaceUsage = ({ form }: any): boolean => {
  const formValues = form.getState().values;

  return (
    conditions.isBuildingWithThirdParty({ formValues }) &&
    conditions.isBuildingLivingRentableType({ formValues })
  );
};

const BuildingInformationSection: React.FC = () => {
  const t = useTranslations();

  const translatedBuildingTypes = useInquirySelectOptions(BUILDING_TYPE);
  const translatedBuildTypeOfUse = useInquirySelectOptions(BUILDING_TYPE_OF_USE);

  const { selectedInquiryType } = useFormConfig();
  const isDefaultTypeInquiry = selectedInquiryType === InquiryType.default;

  return (
    <FormSection
      name={FormSections.buildingInformation}
      sectionNumber={isDefaultTypeInquiry ? 2 : 3}
      title={t('pages.financingNeed.sections.building.title')}
    >
      <FormRow>
        <SelectWithField
          name={BUILDING_TYPE}
          options={translatedBuildingTypes}
          data-testid={'select-field-' + BUILDING_TYPE}
        />
        <InputWithField name={BUILDING_TYPE_DESCRIPTION} />
      </FormRow>
      <Condition condition={shouldShowCondomiumFields}>
        <FormRow>
          <InputWithField optional type="number" name={BUILDING_COOWNERSHIP} />
          <InputWithField optional type="number" name={BUILDING_CONDOMINIUM_NUM} />
        </FormRow>
      </Condition>

      <Condition condition={shouldShowNumberOfResidentialUnits}>
        <FormRow>
          <InputWithField type="number" name={BUILDING_NUMBER_OF_RESIDENTIAL_UNITS} />
        </FormRow>
      </Condition>

      <FormRow>
        <InputWithField type="number" name={BUILDING_TOTAL_USABLE_SPACE} />
      </FormRow>

      <Condition condition={shouldShowCommercialAndLivingSpace}>
        <FormRow>
          <InputWithField type="number" name={BUILDING_LIVING_SPACE} />
          <InputWithField type="number" name={BUILDING_COMMERCIAL_SPACE} />
        </FormRow>
      </Condition>

      <Condition condition={shouldShowShareOfUsage}>
        <FormRow>
          <BuildingShareOfCommercialSpace />
        </FormRow>
      </Condition>

      <FormRow>
        <SelectWithField
          name={BUILDING_TYPE_OF_USE}
          options={translatedBuildTypeOfUse}
          data-testid={'select-field-' + BUILDING_TYPE_OF_USE}
        />
      </FormRow>

      <Condition condition={shouldShowCommercialRentAndSpaceUsage}>
        <FormRow>
          <InputWithField type="number" name={BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL} />
          <InputWithField type="number" name={BUILDING_RENTED_SPACE_COMMERCIAL} />
        </FormRow>
      </Condition>

      <Condition condition={shouldShowResidentialRentAndSpaceUsage}>
        <FormRow>
          <InputWithField type="number" name={BUILDING_COLD_RENT_PER_YEAR_LIVING} />
          <InputWithField type="number" name={BUILDING_RENTED_SPACE_LIVING} />
        </FormRow>
      </Condition>

      <FormRow>
        <InputWithField name={BUILDING_CONSTRUCTION_YEAR} />
        <InputWithField name={BUILDING_LATEST_SUBSTENCIAL_MODERNISATION} optional />
      </FormRow>

      <FormRow>
        <InputWithField name={BUILDING_STREET_AND_HOUSE_NUMBER} />
      </FormRow>

      <FormRow>
        <InputWithField name={BUILDING_POSTAL_CODE} />
        <InputWithField name={BUILDING_PLACE} />
      </FormRow>
    </FormSection>
  );
};

export default React.memo(BuildingInformationSection);
