import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import type { ApiFile } from 'models/FileApi.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import { addFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAdditionalCustomerFilesWithoutFileRequest } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { chooseSelectedInquiryTypeSpecificComponent } from '../../../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import FileSectionTitle from '../FileSectionTitle';

const { otherFilesForBank, uploadOtherFilesDesc } =
  translations.pages.inquiryDetails.documentExchange;
const { title, description } = translations.components.upload.otherDocuments;

const FilesToBank = () => {
  const files = useSelector(getAdditionalCustomerFilesWithoutFileRequest);
  const inquiryId = useSelector(getInquiryIdSelector);

  const t = useTranslations();
  const dispatch = useDispatch();

  const addFileToList = (file: ApiFile) => dispatch(addFileAction(mapApiFile(file)));

  return (
    <section data-testid="files-to-bank">
      <FileSectionTitle>{t(otherFilesForBank)}</FileSectionTitle>
      {files.length ? (
        <MultipleFilesBlock
          title={t(title)}
          description={t(description)}
          files={files}
          isFileUploadingEnabled
        />
      ) : (
        <UploadAnyFile
          description={t(uploadOtherFilesDesc)}
          onUpload={addFileToList}
          url={endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } })}
        />
      )}
    </section>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.mmv]: () => null,
  [InquiryType.default]: FilesToBank,
});
