import { dzbCompanyDataLabelsMap } from 'dzb/inquiry/steps/companyData/labels';
import { dzbPersonalDataLabelsMap } from 'dzb/inquiry/steps/personalData/labels';
import { dzbRetailerDataLabelsMap } from 'dzb/inquiry/steps/retailerData/labels';
import { LabelsMap } from 'new/form/common/types';

export const dzbLabelsMap: LabelsMap = {
  ...dzbCompanyDataLabelsMap,
  ...dzbRetailerDataLabelsMap,
  ...dzbPersonalDataLabelsMap,
};
