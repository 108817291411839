import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import { MARKET_DATA_ADDRESS_SELECTION } from 'modules/Inquiry/Form/formFields';
import { useUpdateMarketData } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import { useToasts } from 'shared/hooks/useToasts';
import { marketDataSentAction } from 'store/inquiryDetails/actions';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';

import { translations } from '../../../../../../new/form/common/types';
import MarketInfoAddressSelectionHeader from '../../../../../../pages/customerPortal/MarketInformationPage/AmbiguousAddressSelectionHeader';
import { useTranslations } from '../../../../../../utils/hooks/useTranslations';

export type IAddress = {
  address: string;
  display_name: string;
  city: string;
  zip_code: string;
};

type IAddressList = {
  value: string;
  label: string;
};

const MittweidaMarketInfoAddressSelectionPage = ({
  ambiguousAddresses,
}: {
  ambiguousAddresses: IAddress[];
}) => {
  const [addressList, setAddressList] = useState<IAddressList[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const t = useTranslations();
  const translationString = translations.pages.marketInformationInquiryPage.marketInfoAddressPage;
  const marketAddressValues = {};
  const inquiryId = useSelector(getCreatedInquiryId);

  const { updatedMarketData } = useUpdateMarketData(inquiryId!);
  const { success } = useToasts();
  const toastLabels = translations.pages.inquiryDetails.dashboard.actions.triggerMarketResearch;

  const dispatch = useDispatch();

  useEffect(() => {
    const dropDownAddress = ambiguousAddresses.map((address: IAddress) => {
      return {
        value: `${address.address};${address.city};${address.zip_code}`,
        label: address.display_name,
      };
    });
    setAddressList(dropDownAddress);
  }, [ambiguousAddresses]);

  const history = useHistory();
  const path = paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId!);
  const redirectToDashboard = () => history.push(path);

  const handleSubmit = async () => {
    if (selectedAddress) {
      const res = await updatedMarketData(selectedAddress);
      if (res) {
        dispatch(marketDataSentAction());
        success({ description: t(toastLabels.success) });
        redirectToDashboard();
      }
    }
  };

  return (
    <>
      <Form
        initialValues={marketAddressValues}
        onSubmit={() => {}}
        render={() => (
          <form>
            <MarketInfoAddressSelectionHeader />
            <FormSection sectionNumber={1} title={t(translationString.addressInput.label)}>
              <SelectWithField
                name={MARKET_DATA_ADDRESS_SELECTION}
                options={addressList}
                onChange={(value: string) => {
                  setSelectedAddress(value);
                }}
                data-testid={'select-field-' + MARKET_DATA_ADDRESS_SELECTION}
              />
            </FormSection>
          </form>
        )}
      />
      <StyledActions>
        <ButtonComponent mr={4} onClick={redirectToDashboard} variant="tertiary">
          {t(translations.buttons.cancel)}
        </ButtonComponent>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
          type="submit"
          onClick={handleSubmit}
        >
          {t(translations.pages.finishRegistration.confirmBtn)}
        </ButtonComponent>
      </StyledActions>
    </>
  );
};

export default MittweidaMarketInfoAddressSelectionPage;
