import React from 'react';

import { INPUT_TYPE_DECIMAL, InputWithField } from 'components/Input';
import { LEASING_RESIDUAL_VALUE } from 'modules/Inquiry/Form/formFields';

const LeasingResidualValue = () => {
  return <InputWithField name={LEASING_RESIDUAL_VALUE} type={INPUT_TYPE_DECIMAL} />;
};

export default LeasingResidualValue;
