import React, { ReactNode } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import PageTitle from 'components/PageTitle/PageTitle';
import { useShowProgressBar } from 'utils/hooks/useShowProgressBar';

interface IPage {
  children: ReactNode;
  hero?: ReactNode;
  title?: string;
}

export const InnerPageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box width={'full'} maxWidth={'69rem'} margin={'0 auto'}>
      {children}
    </Box>
  );
};

const Page = ({ children, hero, title }: IPage) => {
  const progressBarVisible = useShowProgressBar();
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={`${progressBarVisible ? '18rem' : '14rem'} 4rem 4rem`}
    >
      <PageTitle title={title} />
      {hero}
      <InnerPageContainer>{children}</InnerPageContainer>
    </Flex>
  );
};

Page.defaultProps = {
  hero: null,
};

export default Page;
