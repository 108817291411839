import React from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { StyledInputWrapper } from 'components/PortalFormFields/InputFields/styles';
import CurrencySideSymbol from 'components/SideSymbol/CurrencySideSymbol';

const DECIMAL_PRECISION = 2;

const PriceInput = ({ value, hasError, onChange, onBlur, onFocus, name, label, placeholder }) => {
  const handleChange = ({ floatValue }) => onChange(floatValue);

  return (
    <StyledInputWrapper hasError={hasError}>
      <NumberFormat
        onBlur={onBlur}
        name={name}
        onValueChange={handleChange}
        value={value}
        placeholder={placeholder}
        decimalScale={DECIMAL_PRECISION}
        thousandSeparator=" "
        onFocus={onFocus}
        data-testid={name}
        aria-label={label}
        decimalSeparator=","
        autoComplete="off"
      />
      <CurrencySideSymbol />
    </StyledInputWrapper>
  );
};

PriceInput.defaultProps = {
  name: null,
  label: null,
  placeholder: null,
  value: null,
  hasError: false,
  onChange: () => null,
  onFocus: () => null,
};

PriceInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  hasError: PropTypes.bool,
};

export default PriceInput;
