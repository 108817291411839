import React, { useMemo } from 'react';

import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Page from 'components/Page/Page';
import { useFormConfig } from 'config/formConfig/hooks';
import FormPage from 'modules/FormPage';
import { StepNameContext } from 'modules/FormPage/StepNameContext';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryNavigation } from 'modules/Inquiry/inquiryNavigation/useInquiryNavigation';
import { useUpdateCurrentStageNumber } from 'modules/InquiryFormNew/hooks/useUpdateCurrentStageNumber';
import {
  defaultParentFieldContext,
  ParentFieldContext,
} from 'modules/InquiryFormNew/ParentField.context';
import { areSectionsValid, focusDecorator } from 'modules/InquiryFormNew/service';
import { useFormCleaner } from 'pages/inquiryFlow/FinancingNeed/hooks/useFormCleaner';
import { useInquiryFormQueryParams } from 'pages/inquiryFlow/FinancingNeed/hooks/useInquiryFormQueryParams';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { isFormFilledSelector, shouldResetFormSelector } from 'store/progress/selectors';
import { useScrollTopOnLoad } from 'utils/hooks/useScrollTopOnLoad';
import { useTranslations } from 'utils/hooks/useTranslations';
import loadFormState, { loadFormStepStateFromStorage } from 'utils/sessionStorage/loadFormState';
import { isLoggedInUser } from 'utils/user/conditionals';

import { useInquiryValuesContext } from '../../components/App/InquiryValuesProvider';
import { useStepSegmentByInquiryType } from '../Inquiry/inquiryNavigation/stepSegments';

const InquiryFormNew = ({
  headerComp,
  isLoading,
  mutators,
  children,
  currentStepName,
  getSectionValidators,
  valuesSavedToStore,
  initialValues,
  ignoreRedirection,
  onSubmit,
  continueButtonText,
  validateOnNext = false,
}) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const shouldResetForm = useSelector(shouldResetFormSelector);
  const isLoggedIn = isLoggedInUser();
  const { selectedInquiryType } = useFormConfig();
  const canShowCancelButton = selectedInquiryType !== InquiryType.leaseplan;
  useInquiryFormQueryParams();
  useScrollTopOnLoad();
  useUpdateCurrentStageNumber(currentStepName);

  const { pathToNextStep, pathToPreviousStep, cancelPath } = useInquiryNavigation(currentStepName);

  const history = useHistory();
  const validateForm = (form) => areSectionsValid(getSectionValidators(form), form);

  const redirectToNextStep = (form) => {
    if (validateOnNext) {
      if (validateForm(form) && pathToNextStep) {
        history.push(pathToNextStep);
      }
    } else {
      history.push(pathToNextStep);
    }
  };

  const handleSubmit = (values, form) => {
    if (onSubmit) {
      onSubmit(values);
    } else {
      redirectToNextStep(form);
    }
  };

  // clear form values if flag is passed into redux
  const clearFormValues = useFormCleaner(isLoggedIn);
  const isFormFilled = useSelector(isFormFilledSelector(currentStepName));
  const formInitialValues = useMemo(() => {
    if (shouldResetForm) {
      return { ...initialValues };
    }

    return { ...initialValues, ...loadFormStepStateFromStorage(currentStepName) };
  }, [currentStepName, initialValues, shouldResetForm]);

  const pageTitle = useStepSegmentByInquiryType()[currentStepName].progressBarTitle;
  const t = useTranslations();
  const { setInquiryValues } = useInquiryValuesContext();

  return (
    <Page hero={headerComp} title={t(pageTitle)}>
      <Form
        decorators={[focusDecorator]}
        mutators={mutators}
        onSubmit={handleSubmit}
        autocomplete="off"
        initialValues={formInitialValues}
        render={({ form }) => (
          <ParentFieldContext.Provider value={defaultParentFieldContext}>
            <StepNameContext.Provider value={currentStepName}>
              <FormPage
                sections={getSectionValidators}
                form={form}
                onFormLoad={clearFormValues}
                filled={isFormFilled}
                isLoading={isLoading}
                backLinkUrl={pathToPreviousStep}
                stageName={currentStepName}
                valuesToStore={valuesSavedToStore}
                validateForm={validateForm}
                ignoreRedirection={ignoreRedirection || Boolean(inquiryId)}
                cancelLink={cancelPath}
                continueButtonText={continueButtonText}
                showCancelButton={canShowCancelButton}
                setInquiryValues={setInquiryValues}
              >
                {children(form)}
              </FormPage>
            </StepNameContext.Provider>
          </ParentFieldContext.Provider>
        )}
      />
    </Page>
  );
};

InquiryFormNew.defaultProps = {
  mutators: { ...arrayMutators, loadFormState, setFieldData },
  headerComp: null,
  getSectionValidators: () => [],
  onSubmit: null,
  isLoading: false,
  valuesSavedToStore: [],
  initialValues: {},
  ignoreRedirection: false,
  continueButtonText: 'buttons.continue',
};

InquiryFormNew.propTypes = {
  children: PropTypes.func.isRequired,
  currentStepName: PropTypes.string.isRequired,
  getSectionValidators: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  valuesSavedToStore: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  mutators: PropTypes.object,
  headerComp: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  ignoreRedirection: PropTypes.bool,
  continueButtonText: PropTypes.string,
};

export default InquiryFormNew;
