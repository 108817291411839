import { hausbankFields, HausbankFieldTypes } from 'hausbank/inquiry/fields';
import {
  MAX_BUILDING_YEARS_SPAN_SHORT,
  MIN_BUILDING_YEAR_DATE,
} from 'modules/Inquiry/inquiry.constants';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { getMaxBuildingYearDate } from 'utils/date';

import { getLastModernizationMin } from './helpers';

const maxBuildingYearDate = getMaxBuildingYearDate(MAX_BUILDING_YEARS_SPAN_SHORT);

export const validateObjectInformationSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.hausbank>({
    validationMap: objectInformationSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  wegName,
  street,
  zipCode,
  city,
  livingSpace,
  commercialUsage,
  propertySize,
  constructionYear,
  lastModernization,
} = hausbankFields.objectInformationPage.objectInformationSection;

export const objectInformationSectionValidationMap = {
  [wegName]: fieldValidators.string().required(),
  [street]: fieldValidators.string().required(),
  [zipCode]: fieldValidators.postalCode().isValid().required(),
  [city]: fieldValidators.string().required(),
  [livingSpace]: fieldValidators.number().required(),
  [commercialUsage]: fieldValidators.number().required(),
  [propertySize]: fieldValidators.number().required(),
  [constructionYear]: fieldValidators
    .date()
    .max(maxBuildingYearDate)
    .min(MIN_BUILDING_YEAR_DATE)
    .required(),
  [lastModernization]: (value: string | undefined, values: HausbankFieldTypes) => {
    // UTC representation
    const lastModernizationMin = getLastModernizationMin(values);
    return fieldValidators.date().max(maxBuildingYearDate).min(lastModernizationMin).optional();
  },
};
