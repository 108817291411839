import { hausbankFields } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validatePurposeSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: purposeSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { purposeKind, projectDescription } = hausbankFields.financingNeedPage.purposeSection;

export const purposeSectionValidationMap = {
  [purposeKind]: fieldValidators.string().min(1).required(),
  [projectDescription]: fieldValidators.string().max(2000),
};
