import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNER,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  EXISTING_BENEFICIARY,
} from '../../Form/formFields';

export const mapDefaultBeneficiaryOwner = (allFields: any, beneficiary: any) => {
  return {
    first_name: beneficiary[BENEFICIARY_FIRST_NAME],
    last_name: beneficiary[BENEFICIARY_LAST_NAME],
    birth_date: beneficiary[BENEFICIARY_BIRTHDAY],
    city: beneficiary[BENEFICIARY_PRIVATE_CITY],
    country: beneficiary[BENEFICIARY_PRIVATE_COUNTRY],
  };
};

export interface MapBeneficiaryOwnersOptions {
  mapBeneficiaryOwner: (allFields: any, beneficiary: any) => any;
}

export const mmvMapBeneficiaryOwners = (allFields: any, options: MapBeneficiaryOwnersOptions) => {
  const beneficiaryOwners = allFields[BENEFICIARY_OWNER];
  const existingBeneficiary = allFields[EXISTING_BENEFICIARY];
  const beneficiaryOwnersList = [...(existingBeneficiary || []), ...(beneficiaryOwners || [])]
    .map((beneficiary: any) => {
      return options.mapBeneficiaryOwner(allFields, beneficiary);
    })
    // remove duplicates
    .filter((owner, index, array) => {
      if (!owner.id) {
        return true;
      }
      const isDuplicate = array.findIndex((item) => item.id === owner.id) !== index;
      return !isDuplicate;
    })
    // remove empty values
    .filter((owner) => owner.id || owner.first_name || owner.last_name);

  return {
    beneficiary_ownership: beneficiaryOwnersList.length > 0,
    beneficiary_owners: beneficiaryOwnersList,
  };
};
