import { IState } from 'store';

export const canSendLeadsSelector = (store: IState) => store.inquiryProcess.canSendLead;

export const getInquiryDraftIdSelector = (store: IState) => store.inquiryProcess.inquiryDraftId;
export const getInquiryProcess = (store: IState) => store.inquiryProcess;

export const getProcessLane = (store: IState) => store.inquiryProcess.processLane;

export const isLeadProcessLane = (store: IState) => store.inquiryProcess.processLane === 'lead';

export const getInquiryFiles = (store: IState) => store.inquiryProcess.requiredFiles;
