import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { StyledOptional as Optional } from 'components/FileBlock/styles';
import { UPLOAD_STATUSES } from 'components/UploadBlock/constants';
import { useUploadStatusText } from 'components/UploadBlock/hooks/useUploadStatusText';
import { StyledErrors as Errors } from 'components/UploadBlock/styles';
import {
  StyledUploaderContent as Content,
  StyledUploaderPlaceholder as Placeholder,
  StyledUploadedInfo,
} from 'components/UploadBlock/UploadedFileBlock/styles';
import UploadFileBlock from 'components/UploadBlock/UploadFileBlock';
import { StyledIdleStateWrapper as Wrapper } from 'components/UploadBlock/UploadIdleState/styles';
import UploadDropzone from 'components/UploadBlock/UploadIdleState/UploadDropzone';
import { ButtonComponent } from 'theme/components/Button';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';

const UploadIdleState = ({
  title,
  status,
  onFileSelected,
  optional,
  disabled,
  description,
  errors,
}) => {
  const t = useTranslations();
  const statusText = useUploadStatusText(status);
  const [isDropping, setIsDropping] = useState(false);
  const onDropping = (dropping) => setIsDropping(dropping);

  return (
    <UploadDropzone onFileDropping={onDropping} onFileDropped={onFileSelected} disabled={disabled}>
      <Wrapper className={isDropping && 'dropping'}>
        <UploadFileBlock title={title} status={status}>
          {description && (
            <PopoverComponent
              position="absolute"
              zIndex="above"
              top="0"
              right="0"
              transform="translate(-50%, -50%)"
              text={description}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} display="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          )}
          {optional && <Optional>{t('components.upload.optional')}</Optional>}
          <Content>
            {!disabled && <ButtonComponent>{t('components.upload.sendNow')}</ButtonComponent>}
            {!!statusText && <StyledUploadedInfo>{statusText}</StyledUploadedInfo>}
          </Content>
          <Placeholder>{t('components.upload.dropFilesHere')}</Placeholder>
        </UploadFileBlock>
      </Wrapper>
      <Errors show={errors}>{errors}</Errors>
    </UploadDropzone>
  );
};

UploadIdleState.defaultProps = {
  description: '',
  errors: '',
  optional: false,
  disabled: false,
};

UploadIdleState.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.oneOf(UPLOAD_STATUSES).isRequired,
  onFileSelected: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  errors: PropTypes.string,
};

export default UploadIdleState;
