import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getInquiryFlowStartUrl } from 'store/config/selectors';
import { shouldResetFormSelector } from 'store/progress/selectors';

export function useRedirectToStartPage() {
  const history = useHistory();
  const shouldResetForm = useSelector(shouldResetFormSelector);
  const startRoute = useSelector(getInquiryFlowStartUrl);

  if (shouldResetForm) {
    history.push(startRoute);
  }
}
