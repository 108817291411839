import { useCallback } from 'react';

import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const confirmPhoneNumber = (request: any) =>
  callReverseApi({
    url: endpoints.SMS_TOKEN.LIST.compose(),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          ...request,
        },
      },
    },
  });

export const confirmTokenNumber = (request: any) =>
  callReverseApi({
    url: endpoints.SMS_TOKEN.EXCHANGE.compose(),
    method: API_METHODS.POST,
    data: {
      data: {
        attributes: {
          ...request,
        },
      },
    },
  });

export const useConfirmPhoneNumber = () => {
  const { makeCall, isPending } = useDispatchApiCall({
    errorMessage: '',
    isPendingInitially: false,
    showErrorNotification: false,
  });
  const confirmPhone = useCallback(
    async (request: any) => {
      const { payload, error } = await makeCall(confirmPhoneNumber(request));
      if (!error) {
        return payload;
      }
      return error;
    },
    [makeCall],
  );

  const confirmToken = useCallback(
    async (request: any) => {
      const { payload, error } = await makeCall(confirmTokenNumber(request));
      if (!error) {
        return payload;
      }
      return error;
    },
    [makeCall],
  );
  return { isPending, confirmPhone, confirmToken };
};
