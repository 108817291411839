import _get from 'lodash/get';

export const UPLOADING_STATE = {
  IDLE: 'idle',
  UPLOADING: 'uploading',
  SCANNING: 'scanning',
  FINISHED: 'finished',
  ERROR: 'error',
};

// todo: get type for respose?
export const mapErrorsFromApi = (response: any) => {
  if (typeof response !== 'object') {
    return response;
  }

  const knownError = _get(response, 'response.data.errors');
  if (knownError) {
    return knownError
      .map(
        (erObj: { title: string; description: string }) => `${erObj.title}: ${erObj.description}`,
      )
      .join(', ');
  }
  if (response.message) {
    return response.message;
  }
  return JSON.stringify(response);
};
