import styled from 'styled-components/macro';

const TagListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  list-style: none;

  :not(:empty) {
    padding-top: 1rem;
  }
`;

export default TagListWrapper;
