import { useCallback, useState } from 'react';

import {
  useAssessmentFileDownload,
  useFileDownload,
  usePrivateFileDownload,
} from 'api/CompeonReverseApi/operation/queryHooks/files';
import { FileType } from 'models/File.model';

import { FileTypeMap } from './useDownloadFile';
import { useObjectConfirmationFilledDocumentMutation } from '../../api/CompeonReverseApi/operation/queryHooks/inquiriesSpecializedMmv';
import { translations } from '../../new/form/common/types';
import { useToasts } from '../../shared/hooks/useToasts';
import { useTranslations } from '../../utils/hooks/useTranslations';

interface UsePreviewFileArgs {
  fileId: string;
  type?: FileType;
}

export const usePreviewFile = (props: UsePreviewFileArgs | File) => {
  const t = useTranslations();
  const { error: errorToast } = useToasts();

  const fileDownloadQuery = useFileDownload();
  const assessmentQuery = useAssessmentFileDownload();
  const privateQuery = usePrivateFileDownload();
  const filledDocumentQuery = useObjectConfirmationFilledDocumentMutation();

  const [isLoading, setLoading] = useState(false);

  const callback = useCallback(async () => {
    // Muatation map for different file types
    const fileTypeMap = {
      [FileType.FILE]: fileDownloadQuery.mutateAsync,
      [FileType.PRIVATE_FILE]: privateQuery.mutateAsync,
      [FileType.ASSESSMENT_FILE]: assessmentQuery.mutateAsync,
      [FileType.MMV_OBJECT_CONFIRMATION_FILLED_DOCUMENT]: filledDocumentQuery.mutateAsync,
    } satisfies FileTypeMap;

    setLoading(true);
    try {
      // Check if props is of type File or UsePreviewFileArgs and either set the file or fetch it
      let data;
      if (props instanceof File) {
        // Set data to file
        data = props;
      } else {
        // Fetch file data
        const fileType = props.type || FileType.FILE;
        const { data: fileData } = await fileTypeMap[fileType]({
          fileId: props.fileId,
          inquiryId: props.fileId,
        });
        data = fileData as Blob;
      }
      if (!data) {
        throw new Error();
      }
      // Create download URL and open (download) it
      const blobURL = URL.createObjectURL(data);
      window.open(blobURL);
    } catch {
      errorToast({ description: t(translations.pages.errorPage.statuses.statusDefault) });
    } finally {
      setLoading(false);
    }
  }, [
    assessmentQuery.mutateAsync,
    errorToast,
    fileDownloadQuery.mutateAsync,
    filledDocumentQuery.mutateAsync,
    privateQuery.mutateAsync,
    props,
    t,
  ]);

  return [callback, isLoading] as const;
};
