import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const InfoIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 2A10 10 0 112 12 10 10 0 0112 2zm0 2a8 8 0 108 8 8 8 0 00-8-8z" />
      <path d="M12 11a1 1 0 011 .88V17a1 1 0 01-2 .12V12a1 1 0 011-1z" />
      <circle cx="12" cy="8" r="1" />
    </Icon>
  );
};

export default InfoIcon;
