import styled from 'styled-components/macro';

const CompanyLabel = styled.div`
  div {
    margin-bottom: 1rem;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

export default CompanyLabel;
