import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { FINANCING_AMOUNT } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import useAmountTranslations from './useAmountTranslations';

export const validateAmountSection = (form) => fieldIsValid(FINANCING_AMOUNT)({ form });

const AmountSection = () => {
  const t = useTranslations();

  const { formSectionTitle, financingAmountCaption } = useAmountTranslations();

  return (
    <FormSection title={t(formSectionTitle)} name={FormSections.amount} sectionNumber={2}>
      <FormRow>
        <AmountWithField name={FINANCING_AMOUNT} caption={t(financingAmountCaption)} />
      </FormRow>
    </FormSection>
  );
};

export default AmountSection;
