import {
  PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
  PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
  PROFITABILITY_SALES_SALES_PRICES_CHECKS,
} from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

const LENGTH = 30;

export const mittweidaEditModeFieldValidation = {
  [PROFITABILITY_SALES_BUYING_COSTS_CHECKED]: fieldValidators.string().max(LENGTH),
  [PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED]: fieldValidators.string().max(LENGTH),
  [PROFITABILITY_SALES_SALES_PRICES_CHECKS]: fieldValidators.string().max(LENGTH),
};
