import React from 'react';

import { Flex, Grid, useMultiStyleConfig, VStack } from '@chakra-ui/react';

import Condition from 'components/Condition';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface SummaryCardsProps {
  children: React.ReactNode;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
}

const SummaryCards = ({ children, onEdit }: SummaryCardsProps) => {
  const t = useTranslations();
  const styles = useMultiStyleConfig('SummaryCards', {});

  const editButton = React.useMemo(() => {
    return (
      <Flex justifyContent="flex-end">
        <ButtonComponent
          onClick={onEdit}
          leftIcon={<EditIcon boxSize={6} display="block" />}
          variant="tertiary"
        >
          {t('buttons.edit')}
        </ButtonComponent>
      </Flex>
    );
  }, [onEdit, t]);

  return (
    <VStack spacing={4} align={'stretch'}>
      <Grid __css={styles}>{children}</Grid>
      <Condition condition={Boolean(onEdit)} children={editButton} />
    </VStack>
  );
};

export default SummaryCards;
