export interface DocumentProps {
  createdAt: string;
  digitizationRequestedAt?: string;
  fileName: string;
  id: string;
  scanningStatus: string;
  uploadedBy: string;
  wasDigitizedAt?: string;
  markedForKpiCalculationAt?: string;
}

interface UseDocumentValidationProps {
  files: DocumentProps[];
}

const useDocumentValidation = ({ files }: UseDocumentValidationProps) => {
  let validation = true;

  files.forEach((file) => {
    if (file.digitizationRequestedAt === null) {
      validation = false;
    }
  });

  return validation;
};

export const useAnalysisValidation = ({ files }: UseDocumentValidationProps) => {
  let validation = true;

  files.forEach((file) => {
    if (file.wasDigitizedAt !== null && file.markedForKpiCalculationAt === null) {
      validation = false;
    }
  });

  return validation;
};

export default useDocumentValidation;
