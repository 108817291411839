import React from 'react';

import PropTypes from 'prop-types';

import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

const AcceptOfferButton = ({ onClick, disabled }) => {
  const t = useTranslations('pages.inquiryDetails.offers');
  if (disabled) {
    return null;
  }

  return (
    <ButtonComponent onClick={onClick} data-testid="accept-offer-btn" w="100%" mt={4}>
      {t('acceptOffer')}
    </ButtonComponent>
  );
};

AcceptOfferButton.defaultProps = {
  disabled: false,
};

AcceptOfferButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default AcceptOfferButton;
