import { useState, useCallback } from 'react';

import _get from 'lodash/get';

import { getUserDataAction } from 'api/CompeonReverseApi/operation/userData/actions';
import { ApiActionResponseModel } from 'models/ApiActionResponse.model';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const useAssignedOrganizationalUnit = () => {
  const [assignedOrganizationalUnit, setAssignedOrganizationalUnit] = useState<string | undefined>(
    undefined,
  );
  const { makeCall } = useDispatchApiCall();

  const fetchAssigendOrganizationalUnit = () => {
    makeCall(getUserDataAction(), ({ payload }: ApiActionResponseModel) => {
      setAssignedOrganizationalUnit(
        _get(payload, 'data.data.relationships.organizational_unit.data.id', undefined),
      );
    });
  };

  return {
    setAssignedOrganizationalUnit,
    assignedOrganizationalUnit,
    fetchAssignedOrganizationalUnit: useCallback(fetchAssigendOrganizationalUnit, [makeCall]),
  };
};
export default useAssignedOrganizationalUnit;
