import React from 'react';

import {
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_COMPANY,
  VB_NUMBER,
  VB_ZIP_CODE,
  VM_NUMBER,
  USER_PROFILE_PHONE_NUMBER,
  STRUCTURE_NUMBER,
  ADP_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useFilterDetailViewFields } from '../InformationRows/useFilterDetailViewFields';

const VbProfileSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.USER,
    excludedFields: [COMPANY_DETAILS_EMAIL, COMPANY_DETAILS_COMPANY],
    includeFields: [
      VB_NUMBER,
      VM_NUMBER,
      VB_ZIP_CODE,
      ADP_NUMBER,
      STRUCTURE_NUMBER,
      USER_PROFILE_PHONE_NUMBER,
    ],
  });
  const { filterEmptyField } = useFilterDetailViewFields();
  const filteredFields = fields.filter(filterEmptyField);

  if (!filteredFields.length) {
    return null;
  }

  return (
    <Section title={t('vbSection')}>
      <InformationRows fields={filteredFields} />
    </Section>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_SALES_PERSONS_ENABLED]: VbProfileSection,
  default: () => null,
});
