import {
  INPUT_TYPE_DECICMAL_WITH_ZERO,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_DECIMAL_WITH_THREE_PLACES,
} from 'components/Input';
import {
  PROFITABILITY_FINANCING_DURATION_BUILDING,
  PROFITABILITY_FINANCING_DURATION_RENT,
  PROFITABILITY_FINANCING_DURATION_UNIT,
  PROFITABILITY_FINANCING_ADDITIONAL_NOTE,
  FUTURE_USAGE_REMAINING_USAGE_DURATION,
  FUTURE_USAGE_VISITED_BY,
  PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
  PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
  PROFITABILITY_SALES_SALES_PRICES_CHECKS,
  FINANCING_DETAILS_FEE_DURATION,
  FINANCING_DETAILS_FINANCING_DURATION,
  FINANCING_DETAILS_INTEREST_RATE,
  FINANCING_DETAILS_REPAYMENT_RATE,
  FINANCING_DETAILS_FEE_RATE,
} from 'modules/Inquiry/Form/formFields';

export const PROFITABILITY_FIELDS_TYPE_MAP: {
  [key: string]: string;
} = {
  [PROFITABILITY_FINANCING_DURATION_BUILDING]: INPUT_TYPE_NUMBER,
  [PROFITABILITY_FINANCING_DURATION_RENT]: INPUT_TYPE_NUMBER,
  [PROFITABILITY_FINANCING_DURATION_UNIT]: INPUT_TYPE_NUMBER,
  [PROFITABILITY_FINANCING_ADDITIONAL_NOTE]: 'textField',
  [FUTURE_USAGE_REMAINING_USAGE_DURATION]: INPUT_TYPE_NUMBER,
  [FUTURE_USAGE_VISITED_BY]: 'textField',
  [PROFITABILITY_SALES_BUYING_COSTS_CHECKED]: 'textField',
  [PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED]: 'textField',
  [PROFITABILITY_SALES_SALES_PRICES_CHECKS]: 'textField',
  [FINANCING_DETAILS_FEE_DURATION]: INPUT_TYPE_NUMBER,
  [FINANCING_DETAILS_FINANCING_DURATION]: INPUT_TYPE_NUMBER,
  [FINANCING_DETAILS_INTEREST_RATE]: INPUT_TYPE_DECIMAL_WITH_THREE_PLACES,
  [FINANCING_DETAILS_REPAYMENT_RATE]: INPUT_TYPE_DECIMAL_WITH_THREE_PLACES,
  [FINANCING_DETAILS_FEE_RATE]: INPUT_TYPE_DECIMAL_WITH_THREE_PLACES,
  default: INPUT_TYPE_DECICMAL_WITH_ZERO,
};

const getProfitabilityFieldsType = (field: string) =>
  PROFITABILITY_FIELDS_TYPE_MAP[field] || PROFITABILITY_FIELDS_TYPE_MAP.default;

export default getProfitabilityFieldsType;
