import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  OVERDRAFT_END_AT,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
  OVERDRAFT_TERMINABILITY,
} from 'modules/Inquiry/Form/formFields';

export const validateOverdraftSection = validateSectionFields([
  OVERDRAFT_TERMINABILITY,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
  OVERDRAFT_END_AT,
]);
