import { callReverseApi, API_METHODS } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { IAssociatedPerson } from 'models/CompanyDetails.model';

import { ACTION } from './actionTypes';

export const getCompanyDetailsAction = (companyId: string) =>
  callReverseApi({
    url: endpoints.COMPANIES.SPECIALIZED.DETAILS.compose({ params: { id: companyId } }),
    method: API_METHODS.GET,
    actionName: ACTION.GET_COMPANY_DETAILS,
  });

export const getCompanyRiskAnalysis = (companyId: string) =>
  callReverseApi({
    url: endpoints.COMPANIES.RISK.compose({ params: { id: companyId } }),
    method: API_METHODS.GET,
    actionName: ACTION.GET_RISK_ANALYSIS,
  });

export const getCompanyAssociatedPersons = (
  companyId: string,
  personType?: IAssociatedPerson['type'],
) =>
  callReverseApi({
    url: endpoints.COMPANIES.SPECIALIZED.ASSOCIATES.compose(
      {
        params: { id: companyId },
        query: { filters: { type: { '': personType } } },
      },
      { qs: { encode: false } },
    ),
    method: API_METHODS.GET,
    actionName: ACTION.GET_ASSOCIATED_PERSONS,
  });
