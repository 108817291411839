import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import StaticField from 'components/StaticField';
import { USER_PROFILE_FOUNDING_MONTH } from 'modules/Inquiry/Form/formFields';
import { getMonthsOptions } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const StaticFoundingMonthField = ({ foundingMonth }) => {
  const t = useTranslations(
    'pages.userProfile.sections.completingCompanyDetails.fields.userProfileFoundingMonth',
  );
  const { optional } = useFieldValidators();
  const tOrig = useTranslations();

  const translateMonth = (monthNumber) =>
    getMonthsOptions(tOrig).reduce((combined, { label, value }) => {
      return { ...combined, [value]: label };
    }, {})[monthNumber];

  return (
    <>
      <Field
        name={USER_PROFILE_FOUNDING_MONTH}
        validate={optional}
        render={({ input }) => {
          input.onChange(foundingMonth);
          return null;
        }}
      />
      <StaticField caption={t('caption')} text={translateMonth(foundingMonth)} />
    </>
  );
};

StaticFoundingMonthField.propTypes = {
  foundingMonth: PropTypes.number.isRequired,
};

export default StaticFoundingMonthField;
