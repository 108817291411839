import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CONFIG from '../../../../shared/featureFlagConfig/configFromAdmin';
import { useConfig } from '../../../../shared/featureFlagConfig/useConfig';
import { getCompanyId, getInquiryIdSelector } from '../../../../store/inquiryDetails/selectors';
import { getFullKpis, setKpis } from '../../../../store/kpi/kpis.actions';
import useDispatchApiCall from '../../../../utils/hooks/useDispatchApiCallHook';

const mapFromApi = (rawData: any) => {
  return {
    kpis: rawData.data.kpis,
    kpiDateRange: rawData.data.kpiDateRange,
    fiscalYearStartsAt: rawData.data.fiscalYearStartsAt,
  };
};

export const useFetchKpis = () => {
  const companyId = useSelector(getCompanyId);
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const { makeCall, error, isPending } = useDispatchApiCall();
  const isEvalueinEnabled = useConfig(CONFIG.IS_EVALUEIN_ENABLED);

  const fetchKpis = useCallback(
    // FIXME
    // @ts-ignore
    (companyId, inquiryId) =>
      makeCall(getFullKpis(companyId, inquiryId), ({ payload }: any) => {
        const mappedResponse = mapFromApi(payload.data);
        dispatch(setKpis(mappedResponse));
      }),
    [dispatch, makeCall],
  );

  useEffect(() => {
    if (isEvalueinEnabled) {
      fetchKpis(companyId, inquiryId);
    }
  }, [fetchKpis, companyId, isEvalueinEnabled, inquiryId]);

  if (error) {
    throw error;
  }

  return { isLoading: isPending };
};
