import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { firstName, lastName, phoneNumber, email } = dzbFields.personalDataPage.contactPersonSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.personalData.sections.contactPerson;

export const contactPersonSectionLabelsMap: LabelsMap = {
  [firstName]: fieldTranslations.firstName.caption,
  [lastName]: fieldTranslations.lastName.caption,
  [phoneNumber]: fieldTranslations.phoneNumber.caption,
  [email]: fieldTranslations.email.caption,
};
