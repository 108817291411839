import { PurposeKind } from 'models/PurposeKind.model';
import {
  PURPOSE_KIND,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__DEBT_RESCHEDULING,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  OTHER_PURPOSE_DESCRIPTION,
  DEBT_RESCHEDULING_DESCRIPTION,
  FINANCING_AMOUNT,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__CREFO_FACTORING,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { mapBuildingPurposeToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapBuildingPurposeToApi';
import { mapCoronaKfwProgramToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapCoronaPurposeToApi';
import { mapGoodsToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapGoodsToApi';
import { mapMachineToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapMachineToApi';
import { mapProjectFinancingToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapProjectFinancingToApi';
import { mapRealEstateToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapRealEstateToApi';
import { mapVehicleToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapVehicleToApi';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { mapPurposeToApi } from 'onlinefactoring/purpose/mapPurposeToApi';

import { mapFactomatToApi } from './mapFactomatPurposeToApi';
import { mapGuarantyToApi } from './mapGuarantyToApi';
import { mapLiquidityToApi } from './mapLiquidityToApi';
import { mapOfficeEquipmentToApi } from './mapOfficeEquipmentToApi';

const mapOtherToApi: InquiryFormMapper = (allFields: any) => ({
  'other-description': allFields[OTHER_PURPOSE_DESCRIPTION],
});
const mapDebtReschedulingToApi: InquiryFormMapper = (allFields: any) => ({
  'debt-rescheduling-description': allFields[DEBT_RESCHEDULING_DESCRIPTION],
});

const { toFloatPrecision2 } = fieldParsers;

export const mapFinancingNeedPurposeFieldsToApi = (allFields: any) => {
  const selectedPurpose: PurposeKind = allFields[PURPOSE_KIND];

  const purposeMap: { [key in PurposeKind]: InquiryFormMapper } = {
    [PURPOSE_KIND__CORONA]: mapCoronaKfwProgramToApi,
    [PURPOSE_KIND__GOODS]: mapGoodsToApi,
    [PURPOSE_KIND__DEBT_RESCHEDULING]: mapDebtReschedulingToApi,
    [PURPOSE_KIND__VEHICLE]: mapVehicleToApi,
    [PURPOSE_KIND__MACHINE]: mapMachineToApi,
    [PURPOSE_KIND__OVERDRAFT_LIQUIDITY]: mapLiquidityToApi,
    [PURPOSE_KIND__REAL_ESTATE]: mapRealEstateToApi,
    [PURPOSE_KIND__OTHER]: mapOtherToApi,
    [PURPOSE_KIND__GUARANTY]: mapGuarantyToApi,
    [PURPOSE_KIND__CREFO_FACTORING]: () => ({}),
    [PURPOSE_KIND__PROJECT_FINANCING]: mapProjectFinancingToApi,
    [PURPOSE_KIND__BUILDING]: mapBuildingPurposeToApi,
    [PURPOSE_KIND__KFINANZ]: mapPurposeToApi,
    [PURPOSE_KIND__ENERGY_EFFICIENCY]: mapPurposeToApi,
    [PURPOSE_KIND__OFFICE_EQUIPMENT]: mapOfficeEquipmentToApi,
    [PURPOSE_KIND__FACTOMAT]: mapFactomatToApi,
    [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: mapFactomatToApi,
  };

  const purposeMapper: Function | undefined = purposeMap[selectedPurpose];

  if (!purposeMapper) {
    throw new Error(`Not known purpose kind ${selectedPurpose} on api mapping`);
  }

  return {
    amount: toFloatPrecision2(allFields[FINANCING_AMOUNT]),
    'purpose-kind': allFields[PURPOSE_KIND],
    ...purposeMapper(allFields),
  };
};
