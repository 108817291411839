import React, { Component, Fragment } from 'react';

import { bool, func, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Separator from 'components/FormRow/Separator';
import Page from 'components/Page/Page';
import PageTitle from 'components/PageTitle/PageTitle';
import SuccessPageHeading from 'components/SuccessPageHeading';
import SuccessPageStep from 'components/SuccessPageStep';
import { Heading, Subheading } from 'components/Text';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  PRODUCT_KIND,
  PURPOSE_KIND,
  USER_PROFILE_FOUNDING_YEAR,
} from 'modules/Inquiry/Form/formFields';
import { productDocuments, purposeDocuments } from 'pages/inquiryFlow/FinalPage/documentsList';
import stepsList from 'pages/inquiryFlow/FinalPage/stepsList';
import {
  StyledList,
  StyledBlueTextBox,
  StyledDocumentText,
} from 'pages/inquiryFlow/FinalPage/styles';
import SuccessButtons from 'pages/inquiryFlow/FinalPage/SuccessButtons';
import { resetInquiryProcess } from 'shared/inquiry/inquiryProcess';
import intlShape from 'shared/intlShape';
import {
  clearStages as clearStagesAction,
  resetForm as resetFormAction,
  resetProgress as resetProgressAction,
  setCurrentStage as setCurrentStageAction,
  setProgressCounter as setProgressCounterAction,
} from 'store/progress/actions';
import { allObjectsContainValues } from 'utils/helpers';
import { withIntl } from 'utils/intl';
import {
  getParsedItemFromSessionStorage,
  saveObjectToSessionStorage,
} from 'utils/sessionStorage/helpers';
import {
  COMPANY_DETAILS,
  FINAL_PAGE_STATE,
  FINANCING_NEED,
  REQUEST_DETAILS,
  USER_PROFILE,
} from 'utils/sessionStorage/keys';
import { webTrekkPushUpdate } from 'utils/tracker/webtrekk';

class FinalPageUnwrapped extends Component {
  state = {
    requestDetails: {},
    financingNeed: {},
    companyDetails: {},
    userDetails: {},
  };

  componentDidMount() {
    const { setCurrentStage, resetForm, dispatchResetProgress, shouldRedirectToFirstStage } =
      this.props;

    if (shouldRedirectToFirstStage) {
      this.redirectToFirstStage();
    }

    webTrekkPushUpdate();

    setCurrentStage(4);
    this.storeFinalPageDataBeforeReset();
    this.loadFinalPageData();
    resetInquiryProcess(undefined, dispatchResetProgress, resetForm);
  }

  componentWillUnmount() {
    const { history, resetForm, clearStages } = this.props;
    resetInquiryProcess(history, clearStages, resetForm);
  }

  storeFinalPageDataBeforeReset = () => {
    /*
    We want to store data needed for this page and clear data from inquiry process
    ex. if user moves to first page
    Therefore, we store data under separate sessionStorage key special for finalForm,
    so user can reload page and still see valid data.
    */
    const requestDetails = getParsedItemFromSessionStorage(REQUEST_DETAILS);
    const financingNeed = getParsedItemFromSessionStorage(FINANCING_NEED);
    const companyDetails = getParsedItemFromSessionStorage(COMPANY_DETAILS);
    const userDetails = getParsedItemFromSessionStorage(USER_PROFILE);

    if (allObjectsContainValues([requestDetails, financingNeed, companyDetails, userDetails])) {
      saveObjectToSessionStorage(FINAL_PAGE_STATE, {
        requestDetails,
        financingNeed,
        companyDetails,
        userDetails,
      });
    }
  };

  loadFinalPageData = () => {
    const storedState = getParsedItemFromSessionStorage(FINAL_PAGE_STATE);
    if (!storedState) {
      this.redirectToFirstStage();
    }
    this.setState({ ...storedState });
  };

  getProperDocumentList = () => {
    const { requestDetails, financingNeed, companyDetails, userDetails } = this.state;

    const productKind = requestDetails[PRODUCT_KIND];
    const purposeKind = financingNeed[PURPOSE_KIND];

    const getFoundingYear = () => {
      if (companyDetails[COMPANY_DETAILS_EXISTING_USER_COMPANY]) {
        return companyDetails[COMPANY_DETAILS_EXISTING_USER_COMPANY][USER_PROFILE_FOUNDING_YEAR];
      }
      return userDetails[USER_PROFILE_FOUNDING_YEAR];
    };

    const foundingYear = getFoundingYear();
    const currentYear = new Date().getFullYear();
    const companyAge = currentYear - foundingYear;

    const documents = [
      ...(productDocuments[productKind] || []),
      ...(purposeDocuments[purposeKind] || []),
    ];

    return documents
      .filter((doc) => !doc.condition || doc.condition({ companyAge }))
      .map((doc) => doc.id);
  };

  redirectToFirstStage = () => {
    const { history } = this.props;
    history.push(paths.financingNeed);
  };

  renderDocuments = () => (
    <StyledList>
      {this.getProperDocumentList().map((item) => (
        <StyledDocumentText key={item}>
          <FormattedMessage id={item} />
        </StyledDocumentText>
      ))}
    </StyledList>
  );

  renderSteps = () =>
    stepsList.map(({ icon, id }, index) => (
      <Fragment key={id}>
        <Separator />
        <SuccessPageStep src={icon} id={id} indexNumber={index + 1} />
      </Fragment>
    ));

  render() {
    const { history, shouldRedirectToFirstStage, intl, partnerFullName } = this.props;

    if (shouldRedirectToFirstStage) {
      return null;
    }

    return (
      <Page>
        <PageTitle title={intl.formatMessage({ id: 'other.success' })} />
        <SuccessPageHeading
          iconType="checkmark"
          heading={
            <FormattedMessage
              values={{
                partnerFullName: partnerFullName,
              }}
              id="pages.finalPage.heading.header"
            />
          }
          subheading={<FormattedMessage id="pages.finalPage.heading.subheader" />}
        />
        {this.renderSteps()}
        <StyledBlueTextBox>
          <Heading as="h2" isWhite>
            <FormattedMessage id="pages.finalPage.box.header" />
          </Heading>
          <Subheading as="p" isWhite>
            <FormattedMessage id="pages.finalPage.box.description" />
          </Subheading>
        </StyledBlueTextBox>
        {this.renderDocuments()}
        <Separator />
        <SuccessButtons history={history} />
      </Page>
    );
  }
}

const finalPageWIthParnerName = (FinalPageUnwrapped) => {
  const WrappedComponent = (props) => {
    const {
      details: { fullName: partnerFullName },
    } = usePartnerConfig();
    return <FinalPageUnwrapped {...props} partnerFullName={partnerFullName} />;
  };
  return WrappedComponent;
};

FinalPageUnwrapped.propTypes = {
  history: shape({ push: func.isRequired }).isRequired,
  setCurrentStage: func.isRequired,
  dispatchResetProgress: func.isRequired,
  clearStages: func.isRequired,
  resetForm: func.isRequired,
  shouldRedirectToFirstStage: bool.isRequired,
  intl: intlShape.isRequired,
  partnerFullName: string.isRequired,
};

export default compose(
  withRouter,
  withIntl,
  connect(
    (store) => ({
      shouldRedirectToFirstStage: store.progress.shouldRedirectToFirstStage,
    }),
    {
      setCurrentStage: setCurrentStageAction,
      dispatchResetProgress: resetProgressAction,
      resetForm: resetFormAction,
      clearStages: clearStagesAction,
      setProgress: setProgressCounterAction,
    },
  ),
)(finalPageWIthParnerName(FinalPageUnwrapped));
