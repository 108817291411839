import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledDetailedSection as SectionWrapper,
  StyledDetailedSectionTitle as SectionTitle,
} from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection/styles';

const Section = ({ title, children }) => {
  return (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </SectionWrapper>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Section;
