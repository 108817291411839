export enum CompeonStatus {
  stale = 'stale',
  new = 'new',
  discarded = 'discarded',
  canceled = 'canceled',
  pending = 'pending',
  awaitingReview = 'awaiting_review',
  awaitingRelease = 'awaiting_release',
  open = 'open',
  awaitingDecision = 'awaiting_decision',
  completed = 'completed',
  expiredWithoutOffers = 'expired_without_offers',
  expiredWithoutDecision = 'expired_without_decision',
  fulfilled = 'fulfilled',
  unfulfilled = 'unfulfilled',
  convertedToManualInquiry = 'converted_to_manual_inquiry',
}
