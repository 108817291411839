import React from 'react';

import PropTypes from 'prop-types';

import SingleInformation from 'modules/InquiryDetails/DetailedInformation/InformationRows/SingleInformation';

const InformationRow = ({ row }) =>
  row.map(({ value, label, key }) => <SingleInformation value={value} label={label} key={key} />);

InformationRow.propTypes = {
  row: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ),
};

export default InformationRow;
