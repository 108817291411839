import { textStyle } from 'theme/themeConstants';

const SummaryCard = {
  parts: ['card', 'contentContainer', 'caption', 'value'],
  baseStyle: {
    card: {
      bg: 'brand.default',
    },
    contentContainer: {
      padding: '25px',
      color: 'white',
    },
    caption: {
      ...textStyle.sizeM,
      paddingBottom: 1,
    },
    value: {
      ...textStyle.sizeXxl,
    },
  },
};

export default SummaryCard;
