import React from 'react';

import { Redirect } from 'react-router-dom';

import paths from 'constants/paths';
import {
  AccountUnlockStatus,
  ACCOUNT_UNLOCK_QUERY_KEY,
  ACCOUNT_UNLOCK_SCOPE_QUERY_KEY,
  UnlockScope,
  Scope,
} from 'pages/customerPortal/AccountUnlock/AcountUnlock.model';
import { useQueryParams } from 'utils/hooks/useQueryParams';

const AccountUnlockSuccess: React.FC = () => {
  const scope = useQueryParams(ACCOUNT_UNLOCK_SCOPE_QUERY_KEY) as Scope;
  const unlockScope = UnlockScope[scope];

  return (
    <Redirect
      to={`${paths[unlockScope].login}?${ACCOUNT_UNLOCK_QUERY_KEY}=${AccountUnlockStatus.success}`}
    />
  );
};

export default AccountUnlockSuccess;
