import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['th', 'tr', 'td', 'trHeadline']);

const FinancialsGuV = helpers.defineMultiStyleConfig({
  baseStyle: {
    th: {
      p: '4',
      borderBottom: '2px solid',
      borderColor: 'border.darkGrey',
      textStyle: 'sizeM',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      letterSpacing: 'inherit',
      textTransform: 'inherit',
      color: 'text.tertiary',
    },
    tr: {
      _hover: {
        backgroundColor: 'brand.default_L93',
      },
    },
    td: {
      borderColor: 'border.lightGrey',
      borderBottomWidth: '1px',
      textStyle: 'sizeM',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      color: 'text.secondary',
      p: 4,

      _first: {
        paddingLeft: '30px',
      },
    },
    trHeadline: {
      backgroundColor: 'background.lightGrey',

      _hover: {
        backgroundColor: 'brand.default_L93',
      },

      _last: {
        backgroundColor: 'background.white',
        borderBottom: '2px solid',
        borderTop: '2px solid',
        borderColor: 'border.darkGrey',

        _hover: {
          backgroundColor: 'brand.default_L93',
        },
      },

      td: {
        _first: {
          paddingLeft: '16px',
        },
      },
    },
  },
});

export default FinancialsGuV;
