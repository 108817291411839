import React, { useCallback, useState } from 'react';

import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import GeneratedField from 'modules/FormGenerator/GeneratedField';
import { fieldPropType } from 'modules/FormGenerator/propTypes';
import { getUpdatedFieldState } from 'modules/FormGenerator/service';

const FormGenerator = ({ fields, onFormChange }) => {
  const [fieldsState, setFieldsState] = useState(fields);

  const updateForm = useCallback(
    (changes) => {
      setFieldsState((state) => {
        const newState = getUpdatedFieldState(changes, state);
        onFormChange(changes, newState);
        return newState;
      });
    },
    [onFormChange],
  );

  return fieldsState.map((field) => (
    <GeneratedField field={field} key={field.id} onChange={updateForm} />
  ));
};

FormGenerator.defaultProps = {
  onFormChange: () => null,
};

FormGenerator.propTypes = {
  fields: PropTypes.arrayOf(fieldPropType).isRequired,
  onFormChange: PropTypes.func,
};

const arePropsEqual = (prevProps, nextProps) => _isEqual(prevProps.fields, nextProps.fields);

export default React.memo(FormGenerator, arePropsEqual);
