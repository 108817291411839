import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CalendarIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M18.9 19H5.1c-.6 0-1.1-.5-1.1-1.1v-7.4h16v7.4c0 .6-.5 1.1-1.1 1.1M5.1 6H8c0 .6.4 1 1 1s1-.4 1-1h4c0 .6.4 1 1 1s1-.4 1-1h2.9c.6 0 1.1.5 1.1 1.1v1.4H4V7.1C4 6.5 4.5 6 5.1 6m13.8-2H16c0-.6-.4-1-1-1s-1 .4-1 1h-4c0-.6-.4-1-1-1s-1 .4-1 1H5.1C3.4 4 2 5.4 2 7.1v10.7C2 19.6 3.4 21 5.1 21h13.7c1.7 0 3.1-1.4 3.1-3.1V7.1c.1-1.7-1.3-3.1-3-3.1"
      />
    </Icon>
  );
};

export default CalendarIcon;
