export const KB_SIZE = 1024;
export const MB_SIZE = KB_SIZE * 1024;

export const MAX_FILE_SIZE = 15 * MB_SIZE;
export const MAX_FILES = 99;
export const MAX_CONCURRENT_UPLOADS = 3;

export const ALLOWED_FILE_EXTENSIONS = ['pdf', 'jpg', 'jpeg', 'png', 'tiff', 'bmp'];
export const ALLOWED_PDF_ONLY = ['pdf'];
export const ALLOWED_MIME_TYPES = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/tiff': ['.tif', '.tiff'],
  'image/bmp': ['.bmp'],
};
