import { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

class RouteWatcher extends Component {
  listener = null;

  componentDidMount() {
    const { history, onRouteChange } = this.props;
    this.listener = history.listen(onRouteChange);
  }

  componentWillUnmount() {
    if (this.listener) {
      this.listener();
    }
  }

  render() {
    return this.props.children;
  }
}

RouteWatcher.propTypes = {
  children: PropTypes.node.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  onRouteChange: PropTypes.func.isRequired,
};

export default withRouter(RouteWatcher);
