import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const MrMaleIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M13.08 16.41l-.08.09a1.67 1.67 0 01-1.89-.07A1 1 0 009.87 18a3.68 3.68 0 004.44 0 1 1 0 10-1.23-1.58z" />
      <path d="M21 10.27c0-.24 0-.46-.07-.69V5.73A4.94 4.94 0 0016 1H2.14A1.14 1.14 0 001 2.14v.4a2 2 0 00.47 1.29L3 5.64v4.63A2 2 0 002 12v2a2 2 0 001.1 1.79A8.85 8.85 0 0012 23a8.85 8.85 0 008.89-7.21A2 2 0 0022 14v-2a2 2 0 00-1-1.73zM16 3h.17a2.93 2.93 0 012.77 3v3.05h-6.69a8.84 8.84 0 01-6.46-3.21L3.39 3zm4 11l-.15.08a2 2 0 00-.93 1.39A6.87 6.87 0 0112 21a6.87 6.87 0 01-6.93-5.53 2 2 0 00-.94-1.39L4 14v-2l.14-.09A2 2 0 005 10.27V7.91A10.88 10.88 0 0012.56 11h6.56l.05.13A2 2 0 0020 12z" />
      <circle cx="9" cy="13" r="1" />
      <circle cx="15" cy="13" r="1" />
    </Icon>
  );
};

export default MrMaleIcon;
