import {
  LOAN_TYPE__AMORTISABLE,
  LOAN_TYPE__PAYABLE_AT_TERM,
} from 'modules/Inquiry/Form/formFields';

export const coronaLoanTypeOptions = (formatMessage) =>
  [
    {
      value: LOAN_TYPE__AMORTISABLE,
      id: 'pages.requestDetails.sections.loan.fields.loanType.options.amortisable',
    },
    {
      value: LOAN_TYPE__PAYABLE_AT_TERM,
      id: 'pages.requestDetails.sections.loan.fields.loanType.options.payableAtTerm',
    },
  ].map(({ value, id }) => ({ value, label: formatMessage({ id }) }));
