import {
  HIRE_PURCHASE_AMORTISATION_KIND__FULL_AMORTISATION,
  HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
} from 'modules/Inquiry/Form/formFields';

export const getHirePurchaseAmmortisationKindOptions = (formatMessage) =>
  [
    {
      id: 'pages.requestDetails.sections.hirePurchase.fields.hirePurchaseAmortisationKind.options.partialAmortisation',
      value: HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
    },
    {
      id: 'pages.requestDetails.sections.hirePurchase.fields.hirePurchaseAmortisationKind.options.fullAmortisation',
      value: HIRE_PURCHASE_AMORTISATION_KIND__FULL_AMORTISATION,
    },
  ].map(({ id, value }) => ({ value, label: formatMessage({ id }) }));
