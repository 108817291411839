import { LabelsMap } from 'new/form/common/types';

import { termsAndConditionsSectionLabelsMap } from './sections/termsAndConditionsSection/labels';
import { wegDataCompletionSectionLabelsMap } from './sections/wegDataCompletionSection/labels';
import { wegSearchSectionLabelsMap } from './sections/wegSearchSection/labels';

export const hausbankCompanyDataLabelsMap: LabelsMap = {
  ...wegSearchSectionLabelsMap,
  ...wegDataCompletionSectionLabelsMap,
  ...termsAndConditionsSectionLabelsMap,
};
