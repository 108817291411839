import React from 'react';

import SelectField from 'components/PortalFormFields/SelectField';
import { FieldTypeComponentProps } from 'modules/FormGenerator/propTypes';

const SelectTypeField = ({ field: { options, placeholder, hasError, value }, onChange }) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <SelectField
      options={options}
      placeholder={placeholder}
      onSelect={onChange}
      optionOnSelect={(item) => item.value}
      hasError={hasError}
      value={value}
    />
  );
};

SelectTypeField.propTypes = FieldTypeComponentProps;

export default SelectTypeField;
