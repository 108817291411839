import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import {
  PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL,
  PROJECT_FINANCING_TYPE_OF_USAGE__NURSING,
  PROJECT_FINANCING_TYPE_OF_USAGE__OTHER,
  PROJECT_FINANCING_TYPE_OF_USAGE__PARKING,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const usageOptions = [
  {
    label:
      'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.options.housingIndustry',
    value: PROJECT_FINANCING_TYPE_OF_USAGE__HOUSING_INDUSTRY,
  },
  {
    label: 'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.options.commercial',
    value: PROJECT_FINANCING_TYPE_OF_USAGE__COMMERCIAL,
  },
  {
    label: 'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.options.nursing',
    value: PROJECT_FINANCING_TYPE_OF_USAGE__NURSING,
  },
  {
    label: 'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.options.parking',
    value: PROJECT_FINANCING_TYPE_OF_USAGE__PARKING,
  },
  {
    label: 'pages.financingNeed.sections.objectInformation.fields.typeOfUsage.options.other',
    value: PROJECT_FINANCING_TYPE_OF_USAGE__OTHER,
  },
];

const ObjectTypeOfUsage = ({ baseName, labelName }: { baseName: string; labelName: string }) => {
  const t = useTranslations();

  const options = usageOptions.map(({ label, value }) => ({ value, label: t(label) }));
  return (
    <SelectWithField
      name={`${baseName}.${labelName}`}
      options={options}
      data-testid={'select-field-' + labelName}
    />
  );
};

export default ObjectTypeOfUsage;
