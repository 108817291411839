import React from 'react';

import { GENDER_MAN } from 'modules/Inquiry/Form/formFields';
import { UserPerson } from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import { useTranslations } from 'utils/hooks/useTranslations';

// Different keys are used in API and translations file
export const getSalutationKey = (salutation: string) => {
  if (salutation === GENDER_MAN) {
    return 'sir';
  }
  return 'lady';
};

const InquiryRevisionUser = ({ user }: { user: UserPerson }) => {
  const t = useTranslations('pages.userData.fields.salutation');
  const salutationKey = getSalutationKey(user.salutation);

  return (
    <>
      {t(salutationKey)} {user.firstName} {user.lastName}
    </>
  );
};

export default InquiryRevisionUser;
