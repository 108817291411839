import React from 'react';

import PropTypes from 'prop-types';

import CompanyLabel from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyLabel';

const CompanyElement = ({ name, zipCode, city }) => {
  return (
    <CompanyLabel>
      <div>{name}</div>
      <div>{`${zipCode} ${city}`}</div>
    </CompanyLabel>
  );
};

CompanyElement.propTypes = {
  name: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

export default CompanyElement;
