import React from 'react';

import { Asset } from 'components/Asset';
import { translations } from 'new/form/common/types';
import { FinalPageAction } from 'pages/inquiryFlow/FinalPage/actions/FinalPageAction';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const DzbSignContractAction = () => {
  const t = useTranslations();
  const { heading, description, buttonDescription } = translations.pages.finalPage.signContract;

  return (
    <FinalPageAction asset={<Asset type={'inquiry'} value={InquiryAssetKeys.SIGN_CONTRACT} />}>
      <HeadingComponent as={'h3'} variant={'secondary'} mb={4}>
        {t(heading)}
      </HeadingComponent>
      <TextComponent mb={6}>{t(description)}</TextComponent>
      <ButtonComponent
        as={'a'}
        href={'#'}
        leftIcon={<ArrowRightIcon boxSize={6} display={'block'} />}
      >
        {t(buttonDescription)}
      </ButtonComponent>
    </FinalPageAction>
  );
};
