import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormSection from 'components/FormSection';
import { mittweidaFields, MittweidaSections } from 'mittweida/inquiry/fields';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { TermsAndConditionsField } from 'ui/contactPerson/termsAndConditionsField';
import { useTranslations } from 'utils/hooks/useTranslations';

export const TermsAndConditionsSection = () => {
  const { termsAndConditionsAccepted } = mittweidaFields.userProfilePage.termsAndConditionsSection;
  const t = useTranslations();
  const { title, fallback } = translations.inquiryType.mittweida.pages.userDetails.sections.tos;

  return (
    <Condition<MittweidaSections>
      validation={'passwordSection'}
      fallback={
        <DisabledFormSection sectionNumber={3} placeholder={t(fallback)} title={t(title)} />
      }
    >
      <FormSection title={t(title)} name={'termsAndConditions'} sectionNumber={3}>
        <TermsAndConditionsField name={termsAndConditionsAccepted} />
      </FormSection>
    </Condition>
  );
};
