export const FIELD_VALUE_TYPE = {
  BOOLEAN: 'boolean',
  DECIMAL: 'decimal',
  INTEGER: 'integer',
  MULTI_SELECT: 'multi_select',
  SELECT: 'select',
  STRING: 'string',
  DOCUMENT: 'documents',
  TEXT: 'text',
};

export const FIELD_VALUE_TYPES = Object.values(FIELD_VALUE_TYPE);

export const FIELD_KIND_TYPE = {
  FIELD: 'field',
  TITLE: 'title',
  DESCRIPTION: 'description',
};
