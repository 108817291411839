import styled, { css } from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';

export const StyledPair = styled.dl`
  width: 100%;
  margin: 0;

  ${mqMedium`
    width: 33.333333%;
    padding-right: 2rem;
  `}

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;

      ${mqMedium`
        width: 66.666666%;
        padding-right: 33.333333%;
      `}
    `}

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledKey = styled.dt`
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledValue = styled.dd`
  margin: 0;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
