import normalize from 'json-api-normalizer';
import _values from 'lodash/values';

import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { getPassedMonths } from 'utils/date';
import { removeNullValuesRecursively } from 'utils/removeNullValuesRecursively';

import { DZB_YOUNG_COMPANY_MONTHS_THRESHOLD } from './constants';

/**
 * Using any for the types here as the type from the API does not match the type from the FE. Additionally, some numbers
 * are strings or boolean are strings so these types do not help anyway. Internally everything is converted to the
 * correct type.
 */

type BeneficiaryOwner = {
  attributes: {
    additionalInfo: {
      nationality: any;
    };
    address: any;
    birtDate: any;
    birthPlace: any;
    companyShare: any;
    country: any;
    firstName: any;
    lastName: any;
    city: any;
    fictional: any;
  };
  id: any;
  type: any;
};

type Company = {
  attributes: {
    additionalDetails: {
      country: any;
      financialStatementType: any;
      lastAnnualFinancialStatementAvailableAt: any;
      sepa?: {
        bic: any;
        iban: any;
        bankName: any;
      };
      founder?: {
        education: any;
        industryExperienceYears: any;
        leadershipExperience: any;
        leadershipExperienceForm: any;
        leadershipExperienceYears: any;
        professionalBackground: any;
      };
      taxVatNumber: any;
    };
    companyOlderThan3Years: any;
    city: any;
    foundingMonth: any;
    foundingYear: any;
    name: any;
    street: any;
    zipCode: any;
  };
  id: any;
  type: any;
};

type Customer = {
  id: any;
  type: any;
  attributes: {
    email: any;
    firstName: any;
    lastName: any;
    phoneNumber: any;
  };
};

type Inquiry = {
  attributes: {
    details: {
      agreementDate: any;
      annualRevenueForecast: any;
      association: {
        contactPerson: any;
        name: any;
      };
      grossTotalPurchases: any;
      retailerAttributes: {
        employeesCount: any;
        erpName: any;
        isUsingErp: any;
        retailSpace: any;
        shareOfOnlineSales: any;
        storesCount: any;
        amazonFulfillmentShare: any;
        logisticsServiceProviderShare: any;
        foreignWarehouseShare: any;
        ownWarehouseShare: any;
        amazonSalesShare: any;
        avgShoppingBasketTotal: any;
        ebaySalesShare: any;
        otherSalesShare: any;
        ownOnlineStoreSalesShare: any;
        returnRate: any;
        zalandoSalesShare: any;
      };
      userBeneficiaryOwnership: any;
    };
  };
  id: any;
  type: any;
};

type LegalRepresentative = {
  attributes: {
    additionalInfo: {
      soleSignatureAuthorized: any;
    };
    birthDate: any;
    email: any;
    firstName: any;
    lastName: any;
    phoneNumber: any;
    salutation: any;
    taxNumber: any;
  };
  id: any;
  type: any;
};

type NormalizedResponse = {
  beneficiaryOwners: Record<string, BeneficiaryOwner>;
  companies: Record<string, Company>;
  customers: Record<string, Customer>;
  inquiries: Record<string, Inquiry>;
  legalRepresentatives: Record<string, LegalRepresentative>;
};

export const dzbFeTobeMapping = (beResponse: any): DzbFieldTypes => {
  const normalizedResponse: NormalizedResponse = normalize(beResponse, {
    camelizeKeys: true,
  });

  const company = _values(normalizedResponse.companies)[0];
  const customer = _values(normalizedResponse.customers)[0];
  const inquiryAttributes = _values(normalizedResponse.inquiries)[0];
  const inquiryDetails = inquiryAttributes.attributes.details;
  const companyAdditionalDetails = company.attributes.additionalDetails;
  const legalRepresentatives = _values(normalizedResponse.legalRepresentatives);
  const beneficialOwners = _values(normalizedResponse.beneficiaryOwners);

  const dzbCheckCompanyOlderThan3Years = () => {
    if (company.attributes.foundingMonth && company.attributes.foundingYear) {
      const passedMonths = getPassedMonths(
        `${company.attributes.foundingMonth}-${company.attributes.foundingYear}`,
        'mm-yyyy',
      );
      return passedMonths > DZB_YOUNG_COMPANY_MONTHS_THRESHOLD;
    }
    return null;
  };

  const mappedObject = {
    companyDataPage: {
      associationInformationSection: {
        name: inquiryDetails.association.name,
        annualSalesForecast: inquiryDetails.annualRevenueForecast,
        contactPerson: inquiryDetails.association.contactPerson || '-',
        contractDate: inquiryDetails.agreementDate,
        totalPurchaseOfGoods: inquiryDetails.grossTotalPurchases,
      },
      companyInformationSection: {
        companyName: company.attributes.name,
        companyOlderThan3Years:
          company.attributes.companyOlderThan3Years ?? dzbCheckCompanyOlderThan3Years(),
        foundingDate: `${company.attributes.foundingMonth} / ${company.attributes.foundingYear}`,
        address: company.attributes.street,
        city: company.attributes.city,
        country: companyAdditionalDetails.country,
        zipCode: company.attributes.zipCode,
        lastAnnualFinancialStatementReportingDate:
          companyAdditionalDetails.lastAnnualFinancialStatementAvailableAt,
        typeOfFinancialStatements: companyAdditionalDetails.financialStatementType,
        salesTaxID: companyAdditionalDetails.taxVatNumber,
      },
      directDebitMandateSection: {
        bic: companyAdditionalDetails.sepa?.bic,
        iban: companyAdditionalDetails.sepa?.iban,
        bankName: companyAdditionalDetails.sepa?.bankName,
      },
      startUpQuestionnaireSection: {
        alreadyGainedLeadershipExperience: companyAdditionalDetails.founder?.leadershipExperience,
        education: companyAdditionalDetails.founder?.education,
        industryExperienceYears: companyAdditionalDetails.founder?.industryExperienceYears,
        formOfLeadershipExperience: companyAdditionalDetails.founder?.leadershipExperienceForm,
        leadershipExperienceYears: companyAdditionalDetails.founder?.leadershipExperienceYears,
        professionalCareer: companyAdditionalDetails.founder?.professionalBackground,
      },
    },
    retailerDataPage: {
      retailerInformationSection: {
        retailSpace: inquiryDetails.retailerAttributes.retailSpace,
        erpProvider: inquiryDetails.retailerAttributes.erpName,
        enterpriseResourcePlanningSystemInUse: inquiryDetails.retailerAttributes.isUsingErp,
        numberOfEmployees: inquiryDetails.retailerAttributes.employeesCount,
        numberOfStores: inquiryDetails.retailerAttributes.storesCount,
      },
      onlineQuestionnaireSection: {
        shareOfOnlineSalesPercentage: inquiryDetails.retailerAttributes.shareOfOnlineSales,
        foreignFulfillmentByAmazonPercentage:
          inquiryDetails.retailerAttributes.amazonFulfillmentShare,
        shareOfForeignLogistics: inquiryDetails.retailerAttributes.logisticsServiceProviderShare,
        foreignLogisticsServiceProviderPercentage: undefined,
        foreignWarehousePercentage: inquiryDetails.retailerAttributes.foreignWarehouseShare,
        shareOfWarehouse: inquiryDetails.retailerAttributes.ownWarehouseShare,
        amazonPercentage: inquiryDetails.retailerAttributes.amazonSalesShare,
        averageShoppingBasketAmount: inquiryDetails.retailerAttributes.avgShoppingBasketTotal,
        ebayPercentage: inquiryDetails.retailerAttributes.ebaySalesShare,
        othersPercentage: inquiryDetails.retailerAttributes.otherSalesShare,
        ownOnlineShopPercentage: inquiryDetails.retailerAttributes.ownOnlineStoreSalesShare,
        ownWarehousePercentage: inquiryDetails.retailerAttributes.ownWarehouseShare,
        returnRatePercentage: inquiryDetails.retailerAttributes.returnRate,
        shareOfDistributionChannels: undefined,
        zalandoPercentage: inquiryDetails.retailerAttributes.zalandoSalesShare,
      },
    },
    personalDataPage: {
      contactPersonSection: {
        email: customer.attributes.email,
        phoneNumber: customer.attributes.phoneNumber,
        firstName: customer.attributes.firstName,
        lastName: customer.attributes.lastName,
      },
      legalRepresentativesSection: {
        legalRepresentatives: legalRepresentatives.map((legalRepresentative) => {
          const attributes = legalRepresentative.attributes;

          return {
            id: legalRepresentative.id,
            email: attributes.email,
            birthDate: attributes.birthDate,
            phoneNumber: attributes.phoneNumber,
            firstName: attributes.firstName,
            lastName: attributes.lastName,
            taxIdentificationNumber: attributes.taxNumber,
            soleSignatureAuthorized: attributes.additionalInfo.soleSignatureAuthorized,
            salutation: attributes.salutation,
          };
        }),
      },
      beneficialOwnersSection: {
        beneficialOwners: beneficialOwners.map((beneficialOwner) => {
          const attributes = beneficialOwner.attributes;

          return {
            id: beneficialOwner.id,
            lastName: attributes.lastName,
            firstName: attributes.firstName,
            birthDate: attributes.birtDate,
            birthPlace: attributes.birthPlace,
            capitalOrVotingRightsPercentage: attributes.companyShare,
            countryOfResidence: attributes.country,
            nationality: attributes.additionalInfo?.nationality,
            placeOfResidence: attributes.city,
            fictional: attributes.fictional,
          };
        }),
        allInformationAvailable: inquiryDetails.userBeneficiaryOwnership,
      },
    },
  };

  return removeNullValuesRecursively(mappedObject) as DzbFieldTypes;
};
