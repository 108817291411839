interface unitIndicatorProps {
  value: number | undefined;
  unit: string | undefined;
}

export const useUnitIndicator = ({ value, unit }: unitIndicatorProps) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  if (unit === '%') {
    return value.toFixed(1).toString() + ' %';
  }
  if (unit === 'EUR') {
    if (value < 1000 && value >= 0) {
      return value.toFixed(2) + ' €';
    }
    if (value >= 1000000 || value <= -1000000) {
      return (value / 1000000).toFixed(0) + ' M€';
    }
    return (value / 1000).toFixed(0) + ' T€';
  } else {
    return value.toFixed(2);
  }
};
