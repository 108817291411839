import styled from 'styled-components/macro';

import Spinner from 'components/Spinner';

export const StyledSpinner = styled(Spinner)`
  width: 1.4rem;
  height: 1.4rem;
`;

export const StyledIcon = styled.span`
  color: ${({ theme, isSuccess }) =>
    isSuccess ? theme.colors.status.success : theme.colors.status.error};
`;
