import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';

import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from 'theme/components/DescriptionList';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ProgressItem } from './SummaryCard/ProgressItem';
import { SummaryCardItem } from './SummaryCard/SummaryCardItem';
import { useSummaryCardData } from './useSummaryCardData';
import { mmvBusinessConstraints } from '../../../../mmv/utilities/businessConstraints';

export interface MmvSummaryCardProps {
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  openTaskId?: string;
  canShowProgress?: boolean;
}

export const MmvSummaryCard = (props: MmvSummaryCardProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.summary');
  const { product, deadline, contractNumber, supplier, status, substatus } = useSummaryCardData();

  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)']}
      gap={12}
      as="section"
      border="1px"
      borderColor="border.lightGrey"
      p={8}
      boxShadow="default"
      bgColor="background.white"
    >
      <GridItem>
        <DescriptionList size="large" mb={4}>
          <DescriptionListTerm>{t('object')}</DescriptionListTerm>
          <DescriptionListDescription>{product}</DescriptionListDescription>
        </DescriptionList>
        {substatus && mmvBusinessConstraints.showInquiryDeadline(substatus) ? (
          <DescriptionList mb={4}>
            <DescriptionListTerm>{t('remainingTime')}</DescriptionListTerm>
            <DescriptionListDescription>{deadline}</DescriptionListDescription>
          </DescriptionList>
        ) : null}
        <DescriptionList>
          <DescriptionListTerm>{t('contractNumber')}</DescriptionListTerm>
          <DescriptionListDescription>{contractNumber}</DescriptionListDescription>
        </DescriptionList>
      </GridItem>
      <GridItem>
        <SummaryCardItem>
          <DescriptionList mb={4}>
            <DescriptionListTerm>{t('supplier')}</DescriptionListTerm>
            <DescriptionListDescription>{supplier}</DescriptionListDescription>
          </DescriptionList>
          <DescriptionList mb={4}>
            <DescriptionListTerm>{t('status')}</DescriptionListTerm>
            <DescriptionListDescription>{status}</DescriptionListDescription>
          </DescriptionList>
        </SummaryCardItem>
      </GridItem>
      <ProgressItem {...props} />
    </Grid>
  );
};
