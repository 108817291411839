import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

const RootEditRedirect: React.FC = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();

  if (!inquiryId) {
    history.push(paths.customer.inquiryList);
    return <Spinner />;
  }

  return (
    <Redirect to={paths.operation.inquiryDetails.edit.financingNeed.replace(':id', inquiryId)} />
  );
};

export default RootEditRedirect;
