import React from 'react';

import FormSection from 'components/FormSection';
import { hausbankFields } from 'hausbank/inquiry/fields';
import { ContactPersonSection } from 'new/contactPerson';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

export const HausbankContactPersonSection = () => {
  const t = useTranslations();
  const { email, lastName, firstName, phoneNumber, salutation } =
    hausbankFields.personalDataPage.contactPersonSection;

  return (
    <FormSection
      title={t(translations.inquiryType.hausbank.pages.personalData.sections.contactPerson.title)}
      name={'contactPerson'}
      sectionNumber={1}
    >
      <ContactPersonSection
        fieldNames={{
          email,
          lastName,
          firstName,
          gender: salutation,
          phone: phoneNumber,
        }}
      />
    </FormSection>
  );
};
