import {
  CURRENT_USAGE_SQM_PRICE_RESIDENTIAL,
  CURRENT_USAGE_SQM_PRICE_COMMERCIAL,
  CURRENT_USAGE_PRICE_GARAGE_PARKING,
  CURRENT_USAGE_PRICE_OUTSIDE_PARKING,
  CURRENT_USAGE_SQM_PRICE_PROPERTY,
  CURRENT_USAGE_FAIR_MARKET_VALUE,
  CURRENT_USAGE_LOAN_VALUE,
  CURRENT_USAGE_SQM_RENT_RESIDENTIAL,
  CURRENT_USAGE_SQM_RENT_COMMERCIAL,
  CURRENT_USAGE_RENT_GARAGE_PARKING,
  CURRENT_USAGE_RENT_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_RESIDENTIAL,
  FUTURE_USAGE_SQM_PRICE_COMMERCIAL,
  FUTURE_USAGE_PRICE_GARAGE_PARKING,
  FUTURE_USAGE_PRICE_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_PROPERTY,
  FUTURE_USAGE_FAIR_MARKET_VALUE,
  FUTURE_USAGE_LOAN_VALUE,
  FUTURE_USAGE_SQM_RENT_RESIDENTIAL,
  FUTURE_USAGE_SQM_RENT_COMMERCIAL,
  FUTURE_USAGE_RENT_GARAGE_PARKING,
  FUTURE_USAGE_RENT_OUTSIDE_PARKING,
  CURRENT_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_REMAINING_USAGE_DURATION,
  FUTURE_USAGE_PRESALES_RATE,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const bankValuesUnits: Record<string, FIELD_UNIT_TYPE> = {
  [CURRENT_USAGE_SQM_PRICE_RESIDENTIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_SQM_PRICE_COMMERCIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_PRICE_GARAGE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_PRICE_OUTSIDE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_SQM_PRICE_PROPERTY]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_SQM_PRICE_RESIDENTIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_FAIR_MARKET_VALUE]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_LOAN_VALUE]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_SQM_RENT_RESIDENTIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_SQM_RENT_COMMERCIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_RENT_GARAGE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_RENT_OUTSIDE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_SQM_PRICE_RESIDENTIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_SQM_PRICE_COMMERCIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_PRICE_GARAGE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_PRICE_OUTSIDE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_SQM_PRICE_PROPERTY]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_SQM_PRICE_RESIDENTIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_FAIR_MARKET_VALUE]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_LOAN_VALUE]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_SQM_RENT_RESIDENTIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_SQM_RENT_COMMERCIAL]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_RENT_GARAGE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_RENT_OUTSIDE_PARKING]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [CURRENT_USAGE_OPERATING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_OPERATING_COSTS]: FIELD_UNIT_TYPE.DEFAULT_CURRENCY,
  [FUTURE_USAGE_REMAINING_USAGE_DURATION]: FIELD_UNIT_TYPE.YEARS,
  [FUTURE_USAGE_PRESALES_RATE]: FIELD_UNIT_TYPE.PERCENTAGE,
};
