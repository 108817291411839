/*
 * Key under which the progress state is getting stored in local storage
 */
export const PROGRESS_STATE_ITEM_KEY = 'progress';
export const DEFAULT_LANGUAGE = 'de';
export const DEFAULT_CURRENCY = '€';
export const PERCENTAGE = '%';

export const MIN_PHONE_NUMBER_LENGTH = 6;
export const MAX_PHONE_NUMBER_LENGTH = 20;

export const BFS_MIN_PHONE_NUMBER_LENGTH = 3;

export const HEADER_HEIGHT = 96;
export const HEADER_HEIGHT_CLOSED = 72;
export const EXTENDED_HEADER_HEIGHT = 144;
export const PROGRESS_BAR_HEIGHT = 104;
export const TOP_BAR_HEIGHT = HEADER_HEIGHT + PROGRESS_BAR_HEIGHT;

export const PRIMARY_FONT = 'Ubuntu, sans-serif';

export const FONT_WEIGHT_LIGHT = 300;
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_BOLD = 700;

export const MAX_ZIP_CODE_LENGTH = 5;
export const MAX_INTERNATIONAL_ZIP_CODE_LENGTH = 12;
