import React from 'react';

import { ITableHeader } from 'components/PortalTable/TableHeader.model';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export enum COMPANIES_TABLE_HEADERS {
  id = 'id',
  createdAt = 'createdAt',
  name = 'name',
  zipCodeAndCity = 'zipCodeAndCity',
  numOfInquiries = 'numOfInquiries',
  bankAdvisor = 'bankAdvisor',
}

const ID_LENGTH = 8;
const tableHeaders: ITableHeader[] = [
  {
    key: COMPANIES_TABLE_HEADERS.id,
    text: 'pages.companiesList.table.headers.id',
    renderRow: (id: string) => id.slice(0, ID_LENGTH),
  },
  {
    key: COMPANIES_TABLE_HEADERS.createdAt,
    text: 'pages.companiesList.table.headers.createdAt',
    renderRow: (date: Date) => formatDateDays(date),
  },
  {
    key: COMPANIES_TABLE_HEADERS.name,
    text: 'pages.companiesList.table.headers.name',
    renderRow: (name: string) => <strong>{name}</strong>,
  },
  {
    key: COMPANIES_TABLE_HEADERS.zipCodeAndCity,
    text: 'pages.companiesList.table.headers.zipAndCity',
    renderRow: ({ zipCode, city }: { zipCode: number; city: string }) => (
      <p>
        {zipCode}, {city}
      </p>
    ),
  },
  {
    key: COMPANIES_TABLE_HEADERS.numOfInquiries,
    text: 'pages.companiesList.table.headers.numOfInquiries',
  },
  {
    key: COMPANIES_TABLE_HEADERS.bankAdvisor,
    text: 'pages.companiesList.table.headers.bankAdvisor',
    renderRow: ({ firstName, lastName }: { firstName: string; lastName: string }) => (
      <p>
        {firstName} {lastName}
      </p>
    ),
  },
];

export const useCompaniesListTableHeaders = () => {
  const t = useTranslations();
  return tableHeaders.map((header) => ({ ...header, text: t(header.text) }));
};
