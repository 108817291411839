import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { StaticFieldWithField } from 'components/StaticField';
import {
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_PHONE,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_LAST_NAME,
} from 'modules/Inquiry/Form/formFields';
import EmailStaticField from 'modules/Inquiry/Form/Steps/UserProfile/Email/EmailStatic';
import GenderStaticField from 'modules/Inquiry/Form/Steps/UserProfile/Gender/GenderStatic';
import NameStaticField from 'modules/Inquiry/Form/Steps/UserProfile/Name/NameStatic';
import PhoneNumberStaticField from 'modules/Inquiry/Form/Steps/UserProfile/PhoneNumber/PhoneNumberStatic';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const ContactPersonDetailsSection = ({ sectionNumber }: { sectionNumber: number }) => {
  const t = useTranslations('pages.companyDetails.sections.contactPerson');

  return (
    <div>
      <FormSection
        name={FormSections.contactPerson}
        sectionNumber={sectionNumber}
        title={t('title')}
      >
        <FormRow alignItems="flex-start">
          <GenderStaticField />
          <NameStaticField />
        </FormRow>
        <FormRow>
          <EmailStaticField />
        </FormRow>
        <FormRow>
          <PhoneNumberStaticField />
        </FormRow>
      </FormSection>
    </div>
  );
};

const BFSContactPersonDetailsSection = () => {
  const t = useTranslations('pages.companyDetails.sections.contactPerson');

  return (
    <FormSection name={FormSections.contactPerson} sectionNumber={1} title={t('title')}>
      <FormRow>
        <StaticFieldWithField
          caption={t('fields.companyDetailsFirstName.caption')}
          name={CONTACT_PERSON_FIRST_NAME}
        />
        <StaticFieldWithField
          caption={t('fields.companyDetailsLastName.caption')}
          name={CONTACT_PERSON_LAST_NAME}
        />
      </FormRow>
      <FormRow>
        <StaticFieldWithField
          caption={t('fields.companyDetailsPhoneNumber.caption')}
          name={CONTACT_PERSON_PHONE}
        />
        <StaticFieldWithField
          caption={t('fields.companyDetailsEmail.caption')}
          name={CONTACT_PERSON_EMAIL}
        />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BFSContactPersonDetailsSection,
  [InquiryType.profiRLL]: BFSContactPersonDetailsSection,
  default: ContactPersonDetailsSection,
});
