import styled from 'styled-components/macro';

import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { mqMedium, mqLarge } from 'styles/breakpoints';

export const OffersWrapper = styled.div`
  display: block;
  margin-top: 2rem;

  ${mqMedium`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  `}

  ${mqLarge`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};

  :not(:last-child) {
    margin-bottom: 2em;
  }

  ${mqMedium`
    :not(:last-child) {
      margin-bottom: 0;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
  padding: 2rem;
`;

export const Heading = styled.h5`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledPropertiesWrapper = styled.div`
  flex: 1 1 auto;

  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const InformationGroup = styled.dl`
  :not(:last-child) {
    margin-bottom: 2rem;
  }

  dt {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  dd {
    font-size: 1.25rem;
  }

  ul {
    list-style: disc outside;
    padding-left: 1.25rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  ${mqLarge`
    flex-direction: row;
  `}
`;

export const ActionsItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${mqLarge`
    &:not(:last-child) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
