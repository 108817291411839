import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  FINANCING_NEED,
  COMPANY_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
  CONTACT_PERSON_DETAILS,
  CORONA_STEP,
  BUILDING_BORROWED_CAPITAL,
  FINANCING_AMOUNT,
} from 'modules/Inquiry/Form/formFields';
import { getInquiryDetailsEditSelector } from 'store/inquiryDetails/selectors';
import saveFormStateToSessionStorage from 'utils/sessionStorage/saveFormStateToSessionStorage';

// Some fields exists only on FE and are mapped as other fields on BE
// Therefore we need to map data back ex. for editing inquiry
// Please briefly explain each custom condition in comment
const passValuesBetweenFields = (values) => {
  if (!values) {
    return undefined;
  }
  return {
    ...values,
    [BUILDING_BORROWED_CAPITAL]: values[FINANCING_AMOUNT] || 0,
  };
};

const useInquiryEditFormPopulator = () => {
  const inquiryDetailsDividedBySteps = useSelector(getInquiryDetailsEditSelector);
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isInitialized) {
      const populateSection = (sectionID) => {
        const values = inquiryDetailsDividedBySteps[sectionID];
        saveFormStateToSessionStorage(sectionID)({ values: passValuesBetweenFields(values) });
      };
      populateSection(FINANCING_NEED);
      populateSection(COMPANY_DETAILS);
      populateSection(REQUEST_DETAILS);
      populateSection(USER_PROFILE);
      populateSection(CONTACT_PERSON_DETAILS);
      populateSection(CORONA_STEP);

      setIsInitialized(true);
    }
  }, [dispatch, inquiryDetailsDividedBySteps, isInitialized]);

  return isInitialized;
};

export default useInquiryEditFormPopulator;
