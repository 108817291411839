import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  averageShoppingBasketAmount,
  ownOnlineShopPercentage,
  ebayPercentage,
  ownWarehousePercentage,
  foreignWarehousePercentage,
  foreignFulfillmentByAmazonPercentage,
  amazonPercentage,
  foreignLogisticsServiceProviderPercentage,
  shareOfOnlineSalesPercentage,
  zalandoPercentage,
  othersPercentage,
  returnRatePercentage,
  shareOfForeignLogistics,
  shareOfDistributionChannels,
  shareOfWarehouse,
} = dzbFields.retailerDataPage.onlineQuestionnaireSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.retailerData.sections.onlineQuestionnaire;

export const onlineQuestionnaireSectionLabelsMap: LabelsMap = {
  [averageShoppingBasketAmount]: fieldTranslations.averageShoppingBasketAmount.caption,
  [ownOnlineShopPercentage]: fieldTranslations.ownOnlineShopPercentage.caption,
  [ebayPercentage]: fieldTranslations.ebayPercentage.caption,
  [ownWarehousePercentage]: fieldTranslations.ownWarehousePercentage.caption,
  [foreignWarehousePercentage]: fieldTranslations.foreignWarehousePercentage.caption,
  [foreignFulfillmentByAmazonPercentage]:
    fieldTranslations.foreignFulfillmentByAmazonPercentage.caption,
  [amazonPercentage]: fieldTranslations.amazonPercentage.caption,
  [foreignLogisticsServiceProviderPercentage]:
    fieldTranslations.foreignLogisticsServiceProviderPercentage.caption,
  [shareOfOnlineSalesPercentage]: fieldTranslations.shareOfOnlineSalesPercentage.caption,
  [zalandoPercentage]: fieldTranslations.zalandoPercentage.caption,
  [othersPercentage]: fieldTranslations.othersPercentage.caption,
  [returnRatePercentage]: fieldTranslations.returnRatePercentage.caption,
  [shareOfForeignLogistics]: fieldTranslations.shareOfForeignLogistics.caption,
  [shareOfDistributionChannels]: fieldTranslations.shareOfDistributionChannels.caption,
  [shareOfWarehouse]: fieldTranslations.shareOfWarehouse.caption,
};
