import { Language } from 'components/App/LanguageProvider/useLanguages';
import { Gender } from 'mmv/inquiry/fields';
import { UserRole, UserRolesFromApi } from 'models/UserData.model';

export type RawUserData = {
  salutation: unknown;
  roles?: UserRolesFromApi;
};

export interface UserDetailsApiResponse {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  birth_date?: string;
  phone_number: string;
  salutation: Gender;
  vb_number?: string;
  zip_code?: string;
  locale: Language;
}

export interface UserDetails {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  birthDate?: string;
  phoneNumber: string;
  salutation: Gender;
  vbNumber?: string;
  zipCode?: string;
  locale: Language;
}

export interface UserAttributes {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  birthDate?: string;
  phoneNumber: string;
  gender: string;
  vbNumber?: string;
  zipCode?: string;
  language: Language;
  roles?: UserRole[];
}

interface IncompleteUserAttributes {
  roles?: UserRole[];
}

export interface UserState {
  token?: string;
  refreshToken?: string;
  expiresAt?: string;
  scope?: string;
  attributes?: IncompleteUserAttributes | UserAttributes;
}

export const USER_KEY = 'user';
