import React from 'react';

import { usePartnerConfig } from 'config/partner/hooks';

export const choosePartnerSpecificComponent = (componentMap) => (props) => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();

  if (componentMap[partnerName] === null) {
    return null;
  }
  const SelectedComp = componentMap[partnerName] || componentMap.default;
  return <SelectedComp {...props} />;
};

export const usePartnerSpecificValue = (valueMap) => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();

  if (valueMap[partnerName] === null) {
    return null;
  }
  const SelectedValue = valueMap[partnerName] || valueMap.default;
  return SelectedValue;
};
