import React from 'react';

import FormRowBase from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { StyledHalfRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const AmountFilters = () => {
  const t = useTranslations();
  const { isInteger } = useFieldValidators();

  return (
    <FormRowBase>
      <StyledHalfRow>
        <InputWithField
          name={INQUIRY_FILTERS.AMOUNT_FROM}
          validate={isInteger}
          caption={t('pages.inquiryList.filtersDrawer.fields.amountFrom.caption')}
          isClearable
        />
      </StyledHalfRow>

      <StyledHalfRow>
        <InputWithField
          name={INQUIRY_FILTERS.AMOUNT_TO}
          validate={isInteger}
          caption={t('pages.inquiryList.filtersDrawer.fields.amountTo.caption')}
          isClearable
        />
      </StyledHalfRow>
    </FormRowBase>
  );
};

export default AmountFilters;
