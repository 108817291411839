import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';
import { formatDateAndNull } from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';

export const mapLegalRepresentatives = (data: any) => {
  const inquiry: any = Object.values(data.inquiries)[0];

  const legalRepresentatives = Object.values(data.legal_representatives || {});
  const otherRepresentatives = legalRepresentatives.filter(
    (representative: any) =>
      representative.id !== inquiry?.attributes.customer_legal_representative_id,
  );

  return {
    [USER_REPRESENTATIVE]: otherRepresentatives.map((legalRepresentative) =>
      mapLegalRepresentative(legalRepresentative),
    ),
  };
};

const mapLegalRepresentative = ({ id, attributes }: any) => ({
  isAssociatedPerson: true,
  id,
  key: id,
  value: id,
  [REPRESENTATIVE_GENDER]: attributes.salutation,
  [REPRESENTATIVE_FIRST_NAME]: attributes['first_name'],
  [REPRESENTATIVE_LAST_NAME]: attributes['last_name'],
  [REPRESENTATIVE_EMAIL]: attributes['email'],
  [REPRESENTATIVE_PHONE_NUMBER]: attributes['phone_number'],
  [REPRESENTATIVE_BIRTHDATE]: formatDateAndNull(attributes['birth_date']),
});
