import styled from 'styled-components/macro';

import { boxShadow } from 'theme/themeConstants';

export const StyledSummaryBlock = styled.dl`
  box-shadow: ${boxShadow.default};
  padding: 1.75rem 2rem 1.5rem;
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

export const StyledSummaryBlockValue = styled.dd`
  margin: 0;
  font-size: 1.5rem;
`;
