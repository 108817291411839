import every from 'lodash/every';
import _get from 'lodash/get';
import some from 'lodash/some';

import {
  businessConditionMap,
  shouldShowField,
} from 'pages/inquiryFlow/businessConditions/useCanShowField';

import {
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_TAKEOVER,
  MACHINE_INTERESTED_IN_INSURANCE,
  VEHICLE_INTERESTED_IN_INSURANCE,
} from './formFields';

const hiddenFields = [
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_TAKEOVER,
];

/*
 * Some legacy helpers
 * We would like to remove those over time in favor of some other unificated mechanism
 */
export const fieldIsValid =
  (fieldName) =>
  ({ form }) => {
    /*
     * Unfortunately, there is a lot of legacy code that does not always send the form directly but the whole formState where the form is nested. This is a workaround to make it work for both cases. Fixing it "the correct way" would require a lot of refactoring and is not a priority at the moment.
     */
    let validationForm = undefined;
    if (form.getFieldState) {
      validationForm = form;
    } else {
      validationForm = form.form;
    }

    const field = validationForm && validationForm.getFieldState(fieldName);
    if (!field?.valid && hiddenFields.includes(fieldName)) {
      return true;
    }
    return !!(field && field.valid);
  };

export const fieldIsNotEmpty =
  (fieldName) =>
  ({ form }) => {
    return form && !!_get(form.getState().values, fieldName);
  };

export const fieldHasTruthyValue =
  (fieldName) =>
  ({ form }) => {
    if (form && form.getFieldState(fieldName)) {
      return Boolean(form && form.getFieldState(fieldName).value);
    }

    return false;
  };

export const fieldHasValue =
  (fieldName, value) =>
  ({ form }) => {
    const fieldValue = form && _get(form.getState().values, fieldName);
    return fieldValue === value;
  };

export const fieldHasAnyValue =
  (fieldName, valuesArray = []) =>
  ({ form }) => {
    const fieldValue = form && _get(form.getState().values, fieldName);
    return valuesArray.includes(fieldValue);
  };

export const all =
  (...conditions) =>
  (formSpyRenderProps) =>
    every(conditions, (condition) => condition(formSpyRenderProps));

export const not = (condition) => (formSpyRenderProps) => !condition(formSpyRenderProps);

export const atLeastOne =
  (...conditions) =>
  (formSpyRenderProps) => {
    return some(conditions, (condition) => condition(formSpyRenderProps));
  };

export const fieldsAreValid =
  (fields) =>
  ({ form }) =>
    fields.every((field) => fieldIsValid(field)({ form }));

export const getFieldValue = (fieldName, form) => {
  const field = form.getFieldState(fieldName);
  if (field) {
    try {
      return JSON.parse(field.value);
    } catch (e) {
      return field.value;
    }
  }
  return null;
};

export const combineSectionValidations =
  (...sectionValidations) =>
  (form) =>
    sectionValidations.every((validator) => validator(form));

export const isSectionValid =
  (sectionValidator) =>
  ({ form }) =>
    sectionValidator(form);

export const validateSectionFields = (fields) => (form, formType) => {
  /*
   * Unfortunately, there is a lot of legacy code that does not always send the form directly but the whole formState where the form is nested. This is a workaround to make it work for both cases. Fixing it "the correct way" would require a lot of refactoring and is not a priority at the moment.
   */
  let validationForm = undefined;
  if (form.getFieldState) {
    validationForm = form;
  } else {
    validationForm = form.form;
  }
  const formValues = validationForm.getState().values;
  const fieldsToValid = fields.filter((fieldName) =>
    shouldShowField(fieldName, { formValues, formType }, businessConditionMap),
  );
  return fieldsAreValid(fieldsToValid)({ form: validationForm });
};

export const canShowSection =
  (...fields) =>
  (data) =>
    fields.some((fieldName) => shouldShowField(fieldName, data, businessConditionMap));

/* some fields hides and shows upon some conditions
 * this method return a validation method that upon populating with ({ form }) will return validity for field if it exists
 * example:
 * const isForRental = fieldHasValueIn(REAL_ESTATE_USAGE_KIND, [
     REAL_ESTATE_USAGE_KIND__THIRD_PARTY,
     REAL_ESTATE_USAGE_KIND__BOTH,
   ]);
 * const validator = getFieldValidatorIfExist(REAL_ESTATE_RENTED_OUT_AREA, isForRental);
 *
 * validator({ form }) // returns true if REAL_ESTATE_RENTED_OUT_AREA is valid or is not for rental (to ignore validation if field doesn't exist)
 *
 * this is one of our steps to refactor and separate business logic from implementation
 */

export const getFieldValidatorIfExist =
  (fieldName, condition) =>
  ({ form }) => {
    return condition({ form }) ? fieldIsValid(fieldName)({ form }) : true;
  };
