import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const archiveInquiryAction = (inquiryId: string, archivingReason: string) =>
  callReverseApi({
    method: API_METHODS.PATCH,
    url: endpoints.INQUIRIES.ARCHIVE.compose({ params: { id: inquiryId } }),
    data: {
      reason: archivingReason,
    },
  });

export default archiveInquiryAction;
