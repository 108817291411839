import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { NewStaticFieldWithField } from 'components/StaticField';
import { dzbFields } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { TextComponent } from 'theme/components/Text';
import { formatInquiryDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const { name, contactPerson, totalPurchaseOfGoods, annualSalesForecast, contractDate } =
  dzbFields.companyDataPage.associationInformationSection;

export const AssociationInformationSection = () => {
  const t = useTranslations();
  const {
    title,
    subheadings: { discrepancies },
    fields: {
      contractDate: { tooltip },
    },
  } = translations.inquiryType.dzb.pages.companyData.sections.associationInformation;
  return (
    <FormSection title={t(title)} sectionNumber={1}>
      <FormRow>
        <NewStaticFieldWithField name={name} />
        <NewStaticFieldWithField name={contactPerson} />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          name={totalPurchaseOfGoods}
          parseValue={(value: number) => formatPrice(value, true)}
        />
        <NewStaticFieldWithField
          name={annualSalesForecast}
          parseValue={(value: number) => formatPrice(value, true)}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          name={contractDate}
          tooltip={t(tooltip)}
          parseValue={(value: Date) => formatInquiryDateDays(value)}
        />
      </FormRow>
      <TextComponent color={'text.tertiary'}>{t(discrepancies)}</TextComponent>
    </FormSection>
  );
};
