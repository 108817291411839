import _get from 'lodash/get';

import {
  CHECK_EMAIL_AVAILABILITY_SUCCESS,
  CHECK_EMAIL_AVAILABILITY_FAILURE,
  CHECK_EMAIL_AVAILABILITY,
} from 'api/CompeonReverseApi/customer/actionTypes';

import { SET_LOADING, SET_EMAIL_AVAILABLE } from './actionTypes';

const emailAvailable = (
  state = {
    isLoading: false,
    isEmailAvailable: true,
  },
  action,
) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    case SET_EMAIL_AVAILABLE: {
      return {
        ...state,
        isEmailAvailable: action.payload.isEmailAvailable,
      };
    }
    case CHECK_EMAIL_AVAILABILITY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CHECK_EMAIL_AVAILABILITY_SUCCESS:
    case CHECK_EMAIL_AVAILABILITY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isEmailAvailable:
          !_get(action.payload, 'data.data.allowed_to_inquiry') ||
          !!_get(action.payload, 'data.data.attributes.email'),
      };
    }
    default: {
      return state;
    }
  }
};

export default emailAvailable;
