import React, { useCallback } from 'react';
import { useMemo } from 'react';

import { Asset } from 'components/Asset';
import { useFormConfigRepo } from 'config/formConfig/hooks';
import {
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__GUARANTY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getSvgValue } from 'store/partner/config/icons';
import { useTranslations } from 'utils/hooks/useTranslations';

const moveOtherElementAsLast = (prev, next) => {
  const lastElements = [PURPOSE_KIND__OTHER];
  if (lastElements.includes(prev.key)) {
    return 1;
  }

  if (!lastElements.includes(next.key)) {
    return -1;
  }
  return 0;
};

const sortAlphabetically = (prev, next) => {
  const prevTitle = prev.title.replace('(', '');
  const nextTitle = next.title.replace('(', '');

  return prevTitle.localeCompare(nextTitle);
};

const filterAvailablePurpose = (availablePurpose) => (purposeIcon) =>
  availablePurpose.some((purpose) => purpose === purposeIcon.key);

const mapAndTranslatePurposeIcons =
  (translator) =>
  ({ id, titleId, icon }) => ({
    key: id,
    title: translator(titleId),
    icon: <Asset type="purpose" value={getSvgValue(icon)} />,
  });

const coronaPurpose = {
  id: PURPOSE_KIND__CORONA,
  titleId: 'data.purposeKind.corona',
  icon: 'coronaIcon',
};

const projectFinancingOption = {
  id: PURPOSE_KIND__PROJECT_FINANCING,
  titleId: 'data.purposeKind.project_financing',
  icon: 'projectFinancing',
};

const buildingSocietyOption = {
  id: PURPOSE_KIND__BUILDING,
  titleId: 'data.purposeKind.property',
  icon: 'realEstateIcon',
};

const usePurposeKindOptions = () => {
  const t = useTranslations();
  const formConfig = useFormConfigRepo();
  const availablePurposes = formConfig.getCurrentPurposes();
  const purpose = formConfig.getAssetByName('purpose');

  const sortingOptionsForPurpose = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: [
      PURPOSE_KIND__REAL_ESTATE,
      PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
      PURPOSE_KIND__OFFICE_EQUIPMENT,
      PURPOSE_KIND__VEHICLE,
      PURPOSE_KIND__MACHINE,
      PURPOSE_KIND__GUARANTY,
    ],
    default: [],
  });

  const sortInquiryTypeBased = useCallback(
    (prev, next) => {
      const A = prev['key'],
        B = next['key'];

      if (sortingOptionsForPurpose.indexOf(A) > sortingOptionsForPurpose.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    },
    [sortingOptionsForPurpose],
  );

  const listOfPurposes = useMemo(
    () =>
      [coronaPurpose, ...purpose, projectFinancingOption, buildingSocietyOption]
        .map(mapAndTranslatePurposeIcons(t))
        .filter(filterAvailablePurpose(availablePurposes))
        .sort(sortAlphabetically)
        .sort(moveOtherElementAsLast)
        .sort(sortInquiryTypeBased),
    [availablePurposes, purpose, t, sortInquiryTypeBased],
  );

  return listOfPurposes;
};

export default usePurposeKindOptions;
