import { ReactNode } from 'react';

import _get from 'lodash/get';

import { dataListItemToOptions } from 'pages/inquiryFlow/Rentenbank/SubsidyForestryCompanies/SubjectList/SubjectList';
import { pipe } from 'utils/helpers';

export const mapApiDataListItemSuggestions = (payload: any): IDataListItem[] =>
  payload.map((data: any) => ({
    uid: data.id,
    lrnr: data.attributes.lrnr || undefined,
    vendor: data.attributes.hersteller || undefined,
    vendorname: data.attributes.herstellerbezeichnung || undefined,
    type: data.attributes.typ || undefined,
    refcost: data.attributes.referenzkosten || undefined,
    companies: data.attributes.unternehmen
      ? data.attributes.unternehmen.split('+').map((s: any) => s.trim())
      : undefined,
    labelDe: data.attributes.labelDe || undefined,
    labelEn: data.attributes.labelEn || undefined,
  }));

export interface IDataListItem {
  uid: string;
  value: string;
  lrnr: string;
  vendor: string;
  name: string;
  vendorname: string;
  type: string;
  refcost: string;
  companies: string[];
  labelDe: string;
  labelEn: string;
}

export interface DataListOption {
  label: string;
  value: IDataListItem;
  component: ReactNode;
  hidden?: boolean;
}

export const mapSuggestionsFromApiToOptions = (payload: unknown) => {
  const data = _get(payload, 'data.data');
  if (!data) {
    return [];
  }
  return pipe(mapApiDataListItemSuggestions, dataListItemToOptions)(data);
};
