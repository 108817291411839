import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { NewStaticFieldWithField } from 'components/StaticField';
import { mmvFields } from 'mmv/inquiry/fields';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const { title, firstName, lastName, email } = mmvFields.customerDataPage.contactPersonSection;

interface ContactPersonSectionProps {
  sectionNumber?: number;
}

export const ContactPersonSection = (props: ContactPersonSectionProps) => {
  const t = useTranslations();

  return (
    <FormSection
      name="contactPerson"
      title={t(
        translations.inquiryType.mmv.steps.customerDataPage.sections.contactPersonSection.title,
      )}
      {...props}
    >
      <FormRow>
        <NewStaticFieldWithField name={title} />
        <NewStaticFieldWithField name={firstName} />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField name={lastName} />
        <NewStaticFieldWithField name={email} />
      </FormRow>
    </FormSection>
  );
};
