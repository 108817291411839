import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';

export const StyledDetailsHeader = styled.header`
  ${mqMedium`
    display: flex;
    align-items: flex-start;
    width: 100%;
  `}
`;

export const StyledDetailsHeaderContent = styled.div`
  flex: 1 1 0%;
`;

export const StyledDetailedHeaderTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.colors.text.secondary};

  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.secondary};

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledDetailedHeaderSubtitle = styled.div`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
