import {
  FINANCING_AMOUNT,
  GOODS__ESTIMATED_DELIVERY_DATE,
  GOODS__PURCHASE_PRICE,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  MACHINE_AGE,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_PRICE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_FOLLOW_UP_AMOUNT,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_RECONSTRUCTION_COST,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_RESIDUAL_PERCENTAGE,
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_YEARLY_MILEAGE,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  PROJECT_FINANCING_ANNUAL_RENT_AMOUNT,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_RENT_FUTURE,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_COOWNERSHIP,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_USE_OF_FUNDS_SUM,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_SOURCE_OF_FUNDS_SUM,
  OFFER_GROSS_PRICE,
  OFFER_NET_PRICE,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_SCHEDULED_DATE,
  CORONA_FINANCING_AMOUNT,
  CORONA_OTHER_KFW_FINANCING_AMOUNT,
  OFFICE_EQUIPMENT_PRICE,
  REAL_ESTATE_TOTAL_COST,
  PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL,
  PROJECT_USAGE_SPACE_SUM_RESIDENTIAL,
} from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';

export const financingNeedUnits = {
  [FINANCING_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,
  [CORONA_FINANCING_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,
  [CORONA_OTHER_KFW_FINANCING_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,

  [REAL_ESTATE_TOTAL_COST]: FIELD_UNIT_TYPE.CURRENCY,
  [REAL_ESTATE_LAND_SIZE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [REAL_ESTATE_BUILDING_SIZE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION]: FIELD_UNIT_TYPE.THOUSANDS_CURRENCY,
  [REAL_ESTATE_PURCHASE_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [REAL_ESTATE_FOLLOW_UP_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,
  [REAL_ESTATE_RECONSTRUCTION_COST]: FIELD_UNIT_TYPE.CURRENCY,
  [REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,
  [REAL_ESTATE_RESIDUAL_PERCENTAGE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [REAL_ESTATE_COLD_RENT]: FIELD_UNIT_TYPE.CURRENCY,
  [REAL_ESTATE_LAND_SIZE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [REAL_ESTATE_RENTED_OUT_AREA]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [REAL_ESTATE_CONSTRUCTION_DATE]: FIELD_UNIT_TYPE.MONTH_AND_YEAR,
  [REAL_ESTATE_SCHEDULED_DATE]: FIELD_UNIT_TYPE.MONTH_AND_YEAR,

  [INVESTMENT_LOAN_RESIDUAL_VALUE]: FIELD_UNIT_TYPE.PERCENTAGE,

  [MACHINE_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [MACHINE_PERIOD_OF_USE]: FIELD_UNIT_TYPE.YEARS,
  [MACHINE_AGE]: FIELD_UNIT_TYPE.YEARS,
  [MACHINE_ORIGINAL_PRICE]: FIELD_UNIT_TYPE.CURRENCY,

  [VEHICLE_PURCHASE_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [VEHICLE_YEARLY_MILEAGE]: FIELD_UNIT_TYPE.KILOMETERS,

  [GOODS__PURCHASE_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [GOODS__ESTIMATED_DELIVERY_DATE]: FIELD_UNIT_TYPE.DATE,

  [PROJECT_FINANCING_INVESTMENT_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_OWN_FUNDS]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_MEZZANINE]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_OWN_WORK]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_SUBORDINATED_CAPITAL]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_ANNUAL_RENT_AMOUNT]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_LOT_SIZE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]: FIELD_UNIT_TYPE.PERCENTAGE,
  [PROJECT_FINANCING_USAGE_SPACE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [PROJECT_FINANCING_RENT_NOW]: FIELD_UNIT_TYPE.CURRENCY,
  [PROJECT_FINANCING_RENT_FUTURE]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_COOWNERSHIP]: FIELD_UNIT_TYPE.from1000,
  [BUILDING_TOTAL_USABLE_SPACE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [BUILDING_LIVING_SPACE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [BUILDING_COMMERCIAL_SPACE]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [BUILDING_SHARE_OF_COMMERCIAL_SPACE]: FIELD_UNIT_TYPE.PERCENTAGE,
  [BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_COLD_RENT_PER_YEAR_LIVING]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_RENTED_SPACE_COMMERCIAL]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [BUILDING_RENTED_SPACE_LIVING]: FIELD_UNIT_TYPE.SQUARE_METERS,

  [BUILDING_PURCHASE_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_PLOT_PRICE]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_ADDITIONAL_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_CONSTRUCTION_MANUFACTURE_COST]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_MODERNISATION_COSTS]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_DEBT_REDEMPTION]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_USE_OF_FUNDS_SUM]: FIELD_UNIT_TYPE.CURRENCY,

  [BUILDING_EQUITY]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_PERSONAL_CONTRIBUTION]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_PROPERTY]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_BORROWED_CAPITAL]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_DEBT_CAPITAL_OTHER]: FIELD_UNIT_TYPE.CURRENCY,
  [BUILDING_SOURCE_OF_FUNDS_SUM]: FIELD_UNIT_TYPE.CURRENCY,

  [OFFER_NET_PRICE]: FIELD_UNIT_TYPE.THOUSANDS_CURRENCY,
  [OFFER_GROSS_PRICE]: FIELD_UNIT_TYPE.THOUSANDS_CURRENCY,
  [OFFICE_EQUIPMENT_PRICE]: FIELD_UNIT_TYPE.THOUSANDS_CURRENCY,
  [PROJECT_USAGE_SPACE_SUM_NOT_RESIDENTIAL]: FIELD_UNIT_TYPE.SQUARE_METERS,
  [PROJECT_USAGE_SPACE_SUM_RESIDENTIAL]: FIELD_UNIT_TYPE.SQUARE_METERS,
};
