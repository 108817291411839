import React, { useEffect } from 'react';

import { Spinner } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { getCompeonInquiryExternalStateAction } from 'store/inquiryDetails/actions';
import { getCompeonInquiryId } from 'store/inquiryDetails/selectors';
import { DescriptionList } from 'theme/components/DescriptionList';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

import ProcessingStatus from './ProcessingStatus';

const CompeonInquiryState: React.FC = () => {
  const compeonInquiryId = useSelector(getCompeonInquiryId) || '';
  const { isPending, makeCall } = useDispatchApiCall();

  useEffect(() => {
    if (compeonInquiryId) makeCall(getCompeonInquiryExternalStateAction(compeonInquiryId));
  }, [compeonInquiryId, makeCall]);

  if (!compeonInquiryId) return null;

  return (
    <DescriptionList>
      {compeonInquiryId && !isPending ? <ProcessingStatus /> : <Spinner />}
    </DescriptionList>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_COMPEON_EXTERNAL_STATUS_ENABLED]: CompeonInquiryState,
  default: () => null,
});
