import _merge from 'lodash/merge';
import { AnyAction } from 'redux';

import { IInquiryListElement, IInquiryListOptions } from 'models/InquiryList.model';
import { INQUIRY_STATUSES, INQUIRY_SUBSTATUSES } from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { mapInquiriesResponse } from 'modules/InquiryTable/mapFromApi';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';
import {
  FETCH_INQUIRIES_LIST_OPTIONS_SUCCESS,
  FETCH_INQUIRIES_LIST_OPTIONS,
  FETCH_INQUIRIES_LIST_OPTIONS_FAIL,
  SET_INQUIRIES_LIST_STATUSES,
  CHANGE_INQUIRIES_LIST_OPTIONS,
} from 'store/inquiryList/actions';
import { getUpdateState } from 'utils/storeHelpers';

export interface IInquiriesListStateStatuses {
  status: INQUIRY_STATUSES | INQUIRY_SUBSTATUSES;
  counter: number;
}

export interface IInquiriesListStateInquiryManager {
  id: string;
  name: string;
  hasAssignedZipCodes: boolean;
}

export interface IInquiriesListState {
  inquiries: IInquiryListElement[];
  options: IInquiryListOptions;
  isLoading: boolean;
  isInitialized: boolean;
  statuses: Array<IInquiriesListStateStatuses>;
  inquiryManagers: Array<IInquiriesListStateInquiryManager>;
}

export const initialState: IInquiriesListState = {
  inquiries: [],
  options: {
    currentPage: 1,
    pageCount: 1,
    pageSize: 5,
    sortDirection: SORT_DIRECTION.DESC,
    sortBy: INQUIRY_TABLE_HEADERS.CREATED_AT,
    touched: false,
    filterBy: {
      status: 'all',
      amountFrom: undefined,
      amountTo: undefined,
      companyName: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
      inquiryType: undefined,
      purposeKind: undefined,
      productKind: undefined,
      id: undefined,
      inquiryManagerId: undefined,
      customerVBNumber: undefined,
      zipCodeArea: undefined,
      vppId: undefined,
    },
  },
  isLoading: false,
  isInitialized: false,
  statuses: [],
  inquiryManagers: [],
};

export const inquiryListReducer = (
  state: IInquiriesListState = initialState,
  action: AnyAction,
): IInquiriesListState => {
  const updateState = getUpdateState<IInquiriesListState>(state);

  switch (action.type) {
    case FETCH_INQUIRIES_LIST_OPTIONS:
      return updateState({ isLoading: true, isInitialized: true });
    case FETCH_INQUIRIES_LIST_OPTIONS_FAIL:
      return updateState({ isLoading: false });
    case FETCH_INQUIRIES_LIST_OPTIONS_SUCCESS:
      return updateState({
        ...(mapInquiriesResponse(action.payload) as any),
        isInitialized: true,
        isLoading: false,
      });
    case SET_INQUIRIES_LIST_STATUSES:
      return updateState({ statuses: action.payload });
    case CHANGE_INQUIRIES_LIST_OPTIONS:
      return updateState({ options: _merge(state.options, action.payload) });
    default:
      return state;
  }
};
