import * as React from 'react';

import { range } from 'lodash';

import FormRow from 'components/FormRow';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { usePartnerConfig } from 'config/partner/hooks';
import {
  EXCLUDING_QUESTION_FIFTH,
  EXCLUDING_QUESTION_FOURTH,
  EXCLUDING_QUESTION_THIRD,
  EXCLUDING_QUESTION_FIRST,
  EXCLUDING_QUESTION_SECOND,
} from 'modules/Inquiry/Form/formFields';

const numberToFieldNameMap: Record<number, string> = {
  0: EXCLUDING_QUESTION_FIRST,
  1: EXCLUDING_QUESTION_SECOND,
  2: EXCLUDING_QUESTION_THIRD,
  3: EXCLUDING_QUESTION_FOURTH,
  4: EXCLUDING_QUESTION_FIFTH,
};

export const getExcludingQuestionFields = (excludingQuestionsCount: number): string[] =>
  range(0, excludingQuestionsCount).map((e: number) => numberToFieldNameMap[e]);

export const ExcludingQuestions = () => {
  const {
    meta: { excludingQuestionCount },
  } = usePartnerConfig();
  const excludingQuestions = getExcludingQuestionFields(excludingQuestionCount!);

  return (
    <div data-testid="excluding-questions">
      {excludingQuestions.map((excludingQuestion) => (
        <FormRow key={excludingQuestion}>
          <YesNoRadioGroupWithField name={excludingQuestion} />
        </FormRow>
      ))}
    </div>
  );
};
