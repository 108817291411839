import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import { mmvFields } from '../../../../fields';

const fields = mmvFields.customerDataPage.companySection;
const translations =
  translationsObject.inquiryType.mmv.steps.customerDataPage.sections.companySection.fields;

export const companySectionLabelsMap: LabelsMap = {
  [fields.companyName]: translations.companyName.caption,
  [fields.address]: translations.address.caption,
};
