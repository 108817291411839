import {
  USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND,
  USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION,
  USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION,
  USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION,
  USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION,
  USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION,
  USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__10_TO_20_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__20_TO_30_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__30_TO_40_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__40_TO_50_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__50_TO_75_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__75_TO_100_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__MORE_THAN_10_MILLION,
  USER_PROFILE_TURNOVER_CLASS__MORE_THAN_5_MILLION_TO_10_MILLION,
  USER_PROFILE_TURNOVER_CLASS__MORE_THAN_1_MILLION_TO_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__250_THOUSAND_TO_1_MILLION,
  USER_PROFILE_TURNOVER_CLASS__0_TO_250_THOUSAND,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const getTurnoverClassOptions = (formatMessage) => {
  const euroCurrencyText = formatMessage({ id: 'currency.euro' });
  const thousandEuroCurrencyText = formatMessage({ id: 'currency.thousandEuro' });
  const milionText = formatMessage({
    id: 'other.milion',
  });
  const aboveText = formatMessage({
    id: 'other.above',
  });

  const defaultOptions = [
    {
      value: USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND,
      label: `0 - 10 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__10_TO_20_THOUSAND,
      label: `10 - 20 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__20_TO_30_THOUSAND,
      label: `20 - 30 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__30_TO_40_THOUSAND,
      label: `30 - 40 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__40_TO_50_THOUSAND,
      label: `40 - 50 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__50_TO_75_THOUSAND,
      label: `50 - 75 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__75_TO_100_THOUSAND,
      label: `75 - 100 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND,
      label: `100 - 250 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND,
      label: `250 - 500 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND,
      label: `500 ${thousandEuroCurrencyText} - 1 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION,
      label: `1 ${milionText} - 2,5 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION,
      label: `2,5 - 5 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION,
      label: `5 - 10 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION,
      label: `10 - 25 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION,
      label: `25 - 50 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION,
      label: `50 - 100 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION,
      label: `${aboveText} 100 ${milionText} ${euroCurrencyText}`,
    },
  ];

  const bfsOptions = [
    {
      value: USER_PROFILE_TURNOVER_CLASS__0_TO_250_THOUSAND,
      label: `0 - 250 ${thousandEuroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__250_THOUSAND_TO_1_MILLION,
      label: `250 ${thousandEuroCurrencyText} - 1 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__MORE_THAN_1_MILLION_TO_5_MILLION,
      label: `> 1 ${milionText} ${euroCurrencyText} - 5 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__MORE_THAN_5_MILLION_TO_10_MILLION,
      label: `> 5 ${milionText} ${euroCurrencyText} - 10 ${milionText} ${euroCurrencyText}`,
    },
    {
      value: USER_PROFILE_TURNOVER_CLASS__MORE_THAN_10_MILLION,
      label: `> 10 ${milionText} ${euroCurrencyText}`,
    },
  ];
  return chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: bfsOptions,
    default: defaultOptions,
  });
};

export default getTurnoverClassOptions;
