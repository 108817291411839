import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import paths from 'constants/paths';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getData, getHeaders } from './module';
import { FinancingDetailsData } from './types';
import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import useEditFormConfig from '../../../../useEditFormConfig';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';

interface FinancingDetailsProps {
  financingDetails: FinancingDetailsData;
  inquiryId: string;
}

export const FinancingDetails: React.FC<FinancingDetailsProps> = ({
  financingDetails,
  inquiryId,
}) => {
  const { financingParameters } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations();
  const { hasRoleBankAdvisor, hasRoleInquiryManager } = useRoles();

  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(() => getData(t, financingDetails), [t, financingDetails]);

  const { onEdit } = useEditFormConfig({
    pathToEdit: financingParameters(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS](),
  });

  return (
    <Box
      border="1px"
      borderColor="border.lightGrey"
      p="8"
      bgColor="background.white"
      boxShadow="default"
      h="100%"
    >
      <TableCardWithEdit
        canEdit={hasRoleBankAdvisor || hasRoleInquiryManager}
        componentName={PROFITABILITY_RECORD_TYPE.FINANCING_PARAMETERS}
        onEdit={onEdit}
        tableData={data}
        tableHeaders={headers}
        title={t(
          'pages.planningEvaluation.profitabilityCalculation.sections.financingParameters.title',
        )}
      />
    </Box>
  );
};
