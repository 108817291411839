import React from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import kindOptions from 'modules/Inquiry/Form/Steps/RequestDetails/Securities/securityTypes';
import SingleInformation from 'modules/InquiryDetails/DetailedInformation/InformationRows/SingleInformation';
import { StyledColleteral } from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection/CollateralsInformation/styles';
import { StyledKeyValueSection as Row } from 'modules/InquiryDetails/DetailedInformation/styles';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const Colleteral = ({ kind, value, description }) => {
  const t = useTranslations('pages.requestDetails.sections.securities.fields');
  const { formatMessage } = useIntl();
  const translatedKind = kindOptions(formatMessage).find((option) => option.value === kind).label;
  return (
    <StyledColleteral>
      <Row>
        <SingleInformation label={t('securitiesType.caption')} value={translatedKind} />
        <SingleInformation
          label={t('securitiesValueEstimation.caption')}
          value={formatPrice(value)}
        />
      </Row>
      {description && (
        <Row>
          <SingleInformation
            isWide
            label={t('securitiesDescription.caption')}
            value={description}
          />
        </Row>
      )}
    </StyledColleteral>
  );
};

Colleteral.defaultProps = {
  description: null,
};

Colleteral.propTypes = {
  kind: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  description: PropTypes.string,
};

export default Colleteral;
