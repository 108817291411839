import React, { useContext } from 'react';

import { useFieldArray } from 'react-final-form-arrays';

import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { REPRESENTATIVE_GENDER, USER_REPRESENTATIVE } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';

import { FieldValue } from '../LegalRepresentatives/LegalRepresentatives';

const RepresentativeFirstName: React.FC = () => {
  const { fieldName, userIndex } = useContext(LegalRepresentativesContext);
  const { fields: fieldsUserRepresentative } = useFieldArray<FieldValue>(USER_REPRESENTATIVE);
  const userRepresentative = fieldsUserRepresentative.value[userIndex];

  return (
    <GenderWithField
      name={`${fieldName}.${REPRESENTATIVE_GENDER}`}
      disabled={!!userRepresentative.isAssociatedPerson}
    />
  );
};

export default RepresentativeFirstName;
