import { useState, useEffect } from 'react';

const useIsScrolledToTop = () => {
  const [isScrolled, setIsScrolled] = useState(true);

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.pageYOffset === 0);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return isScrolled;
};
export default useIsScrolledToTop;
