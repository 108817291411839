import { useSelector } from 'react-redux';

import { useFormConfig } from 'config/formConfig/hooks';
import { all, getFieldValidatorIfExist, getFieldValue } from 'modules/Inquiry/Form/formConditions';
import {
  MACHINE_INTERESTED_IN_INSURANCE,
  PRODUCT_KIND,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__PROJECT_FINANCING,
  VEHICLE_INTERESTED_IN_INSURANCE,
  FINANCING_NEED,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  PRODUCT_KIND__PROPERTY_LOAN,
  PRODUCT_KIND__AVAL,
  PRODUCT_KIND__LIQUIDITY,
} from 'modules/Inquiry/Form/formFields';
import { validateDecisionDateSection } from 'modules/Inquiry/Form/Steps/RequestDetails/DecisionDate/DecisionDateSection';
import { validateFinancingProductSection } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKind';
import { validatePropertyLoanSection } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/PropertyLoan/PropertyLoanSection';
import { purposeConditions } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/conditions';
import { validateBfsProductKindSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/bfsProductKindSection.validator';
import { validateFactoringSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/factoringSection.validator';
import { validateFurtherNeedSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/furtherneedSection.validator';
import { validateHirePurchaseSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/hirePurchaseSection.validator';
import { validateInvestmentLoanSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/investmentLoanSection.validator';
import { validateLeasingSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/leasingSection.validator';
import {
  validateLoanSection,
  validateCoronaLoanSection,
} from 'modules/Inquiry/Form/Validations/default/RequestDetails/loanSection.validator';
import { validateMezzanineSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/mezzanineSection.validator';
import { validateOtherProductSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/otherProductSection.validator';
import { validateOverdraftSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/overdraftSection.validator';
import { validateProjectFinancingSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/projectFinancingSection.validator';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getIsCoronaSelected, getStoredValueSelector } from 'store/progress/selectors';

export const insuranceValidator = (form) => {
  const vehicleInsurance = getFieldValidatorIfExist(
    VEHICLE_INTERESTED_IN_INSURANCE,
    purposeConditions.hasVehiclePurpose,
  );
  const machineInsurance = getFieldValidatorIfExist(
    MACHINE_INTERESTED_IN_INSURANCE,
    purposeConditions.hasMachinePurpose,
  );
  return all(vehicleInsurance, machineInsurance)({ form });
};

const getDefaultProductSectionValidator = (form) => {
  const selectedProduct = getFieldValue(PRODUCT_KIND, form);

  const productValidatorsMap = {
    [PRODUCT_KIND__FACTORING]: validateFactoringSection,
    [PRODUCT_KIND__HIRE_PURCHASE]: validateHirePurchaseSection,
    [PRODUCT_KIND__INVESTMENT_LOAN]: validateInvestmentLoanSection,
    [PRODUCT_KIND__LOAN]: validateLoanSection,
    [PRODUCT_KIND__LEASING]: validateLeasingSection,
    [PRODUCT_KIND__OVERDRAFT]: validateOverdraftSection,
    [PRODUCT_KIND__OTHER]: validateOtherProductSection,
    [PRODUCT_KIND__PROJECT_FINANCING]: validateProjectFinancingSection,
    [PRODUCT_KIND__MEZZANINE]: validateMezzanineSection,
    [PRODUCT_KIND__PROPERTY_LOAN]: validatePropertyLoanSection,
    [PRODUCT_KIND__AVAL]: validateBfsProductKindSection,
    [PRODUCT_KIND__LIQUIDITY]: validateBfsProductKindSection,
  };

  const productValidator = productValidatorsMap[selectedProduct];
  // every field has insurance question for machine/vehicle purpose kind
  // so it's easier to add validation for those fields here then on each validation method
  if (productValidator) {
    return (formState) => productValidator(formState) && insuranceValidator(formState);
  }
  return insuranceValidator;
};

const getBfsProductSectionValidator = (form) => {
  const selectedProduct = getFieldValue(PRODUCT_KIND, form);
  const productValidatorsMap = {
    [PRODUCT_KIND__INVESTMENT_LOAN]: validateBfsProductKindSection,
    [PRODUCT_KIND__LOAN]: validateBfsProductKindSection,
    [PRODUCT_KIND__AVAL]: validateBfsProductKindSection,
    [PRODUCT_KIND__LIQUIDITY]: validateBfsProductKindSection,
  };
  return productValidatorsMap[selectedProduct];
};
export const useRequestDetailsSectionValidators = () => {
  const { selectedInquiryType } = useFormConfig();
  const isCoronaSelected = useSelector(getIsCoronaSelected);
  const hasSelectedQuickcredit =
    useSelector(getStoredValueSelector([FINANCING_NEED, CORONA_FINANCING_OPTION])) ===
    CORONA_FINANCING_OPTION__QUICK_CREDIT;

  const getProductSectionValidator = isCoronaSelected
    ? () => validateCoronaLoanSection(hasSelectedQuickcredit)
    : getDefaultProductSectionValidator;

  return (form) => {
    const defaultCommonSections = [
      validateFinancingProductSection(form),
      validateDecisionDateSection,
    ];

    const inquiryTypeValidationMap = {
      [InquiryType.bfs]: [validateFurtherNeedSection],
      [InquiryType.profiRLL]: [validateDecisionDateSection],
      default: defaultCommonSections,
    };

    const productSectionValidatorsByInquiryType = {
      [InquiryType.bfs]: getBfsProductSectionValidator(form),
      default: getProductSectionValidator(form),
    };
    const commonSections =
      inquiryTypeValidationMap[selectedInquiryType] || inquiryTypeValidationMap.default;
    const productValidator =
      productSectionValidatorsByInquiryType[selectedInquiryType] ||
      productSectionValidatorsByInquiryType.default;

    return [...commonSections, productValidator].filter((v) => !!v);
  };
};
