import React from 'react';

import { useIntl } from 'react-intl';

import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useFormConfig } from 'config/formConfig/hooks';
import Condition from 'modules/ConditionalSubform/Condition';
import { canShowSection, validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  MACHINE_AGE,
  MACHINE_CATEGORY,
  MACHINE_DELIVERY_DATE,
  MACHINE_IS_ORDERED,
  MACHINE_MANUFACTURER,
  MACHINE_NEW,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_PRICE,
  MACHINE_KIND,
  MACHINE_COUNT,
  MACHINE_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { FormSections } from 'schema/inquirySchema.models';
import { getMachineCategoriesOptions } from 'store/partner/config/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateMachineSection = validateSectionFields([
  MACHINE_CATEGORY,
  MACHINE_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_MANUFACTURER,
  MACHINE_NEW,
  MACHINE_IS_ORDERED,
  MACHINE_ORIGINAL_PRICE,
  MACHINE_AGE,
  MACHINE_DELIVERY_DATE,
  MACHINE_COUNT,
  MACHINE_DESCRIPTION,
]);

const Machine = () => {
  const t = useTranslations();
  const { formatMessage } = useIntl();
  const { dropdowns } = useFormConfig();

  const sectionNumber = 3;

  return (
    <FormSection
      title={t('pages.financingNeed.sections.machine.title')}
      name={FormSections.machine}
      sectionNumber={sectionNumber}
    >
      <Condition condition={canShowSection(MACHINE_CATEGORY)} isBusinessCondition>
        <FormRow>
          <SelectWithField
            name={MACHINE_CATEGORY}
            placeholder={t('placeholders.pleaseChoose')}
            options={getMachineCategoriesOptions(formatMessage, dropdowns)}
            data-testid={'select-field-' + MACHINE_CATEGORY}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(MACHINE_PRICE, MACHINE_COUNT)} isBusinessCondition>
        <FormRow>
          <AmountWithField name={MACHINE_PRICE} />
          <InputWithField name={MACHINE_COUNT} />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(MACHINE_DESCRIPTION)} isBusinessCondition>
        <FormRow>
          <TextAreaWithField
            name={MACHINE_DESCRIPTION}
            placeholder={t(
              'pages.financingNeed.sections.machine.fields.machineDescription.placeholder',
            )}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(MACHINE_PERIOD_OF_USE)} isBusinessCondition>
        <FormRow>
          <InputWithField
            name={MACHINE_PERIOD_OF_USE}
            type="number"
            separateThousands
            optional
            captionTooltip={t(
              'pages.financingNeed.sections.machine.fields.machinePeriodOfUse.captionTooltip',
            )}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(MACHINE_MANUFACTURER)} isBusinessCondition>
        <FormRow>
          <InputWithField name={MACHINE_MANUFACTURER} />
          <InputWithField
            name={MACHINE_KIND}
            placeholder={t('pages.financingNeed.sections.machine.fields.machineKind.placeholder')}
          />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(MACHINE_NEW)} isBusinessCondition>
        <FormRow>
          <YesNoRadioGroupWithField name={MACHINE_NEW} />
          <div />
        </FormRow>
      </Condition>

      <Condition
        condition={canShowSection(MACHINE_AGE, MACHINE_ORIGINAL_PRICE)}
        isBusinessCondition
      >
        <FormRow separator>
          <InputWithField name={MACHINE_AGE} type="number" separateThousands />
          <AmountWithField name={MACHINE_ORIGINAL_PRICE} />
        </FormRow>
      </Condition>
      <Condition condition={canShowSection(MACHINE_IS_ORDERED)} isBusinessCondition>
        <FormRow>
          <YesNoRadioGroupWithField name={MACHINE_IS_ORDERED} />
          <DayPickerWithField
            name={MACHINE_DELIVERY_DATE}
            placeholder={t('placeholders.pleaseChoose')}
          />
        </FormRow>
      </Condition>
    </FormSection>
  );
};

export default Machine;
