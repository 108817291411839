import {
  CORONA_VIABILTIY,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_REVENUE_OF_2019,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  CORONA_VIABILTIY_2020,
  CORONA_TOTAL_ASSETS,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_LIQUIDITY_NEED,
  OTHER_PURPOSE_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

const { toBoolean, toInteger, toFloatPrecision2 } = fieldParsers;
export const mapCoronaKfwProgramToApi = (allFields: any): object => ({
  'corona-package-attributes': {
    'viability-2019': toBoolean(allFields[CORONA_VIABILTIY]),
    'count-of-employees': toInteger(allFields[CORONA_NUMBER_OF_EMPLOYEES]),
    'revenue-2019': toFloatPrecision2(allFields[CORONA_REVENUE_OF_2019]),
    'use-quick-credit':
      allFields[CORONA_FINANCING_OPTION] === CORONA_FINANCING_OPTION__QUICK_CREDIT,
    'viability-2020': toBoolean(allFields[CORONA_VIABILTIY_2020]),
    'total-assets-greater-than-43-mil': toBoolean(allFields[CORONA_TOTAL_ASSETS]),
    'expenditure-for-employees': toFloatPrecision2(allFields[CORONA_EXPENDITURE_OF_EMPLOYEES]),
    'liquidity-need': toFloatPrecision2(allFields[CORONA_LIQUIDITY_NEED]),
  },
  'other-description': allFields[OTHER_PURPOSE_DESCRIPTION],
});
