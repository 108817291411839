import { dzbFields } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const validateStartUpQuestionnaireSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: startUpQuestionnaireSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  formOfLeadershipExperience,
  leadershipExperienceYears,
  industryExperienceYears,
  alreadyGainedLeadershipExperience,
  professionalCareer,
  education,
} = dzbFields.companyDataPage.startUpQuestionnaireSection;

export const startUpQuestionnaireSectionValidationMap = {
  [formOfLeadershipExperience]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_DZB_FORM_OF_LEADERSHIP_EXPERIENCE_LENGTH),
  [leadershipExperienceYears]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_DZB_EXPERIENCE_IN_YEARS_LENGTH),
  [industryExperienceYears]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_DZB_EXPERIENCE_IN_YEARS_LENGTH),
  [alreadyGainedLeadershipExperience]: fieldValidators.boolean().required(),
  [professionalCareer]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_DZB_PROFESSIONAL_CAREER_BACKGROUND_LENGTH),
  [education]: fieldValidators.string().required().max(InquiryConstants.MAX_DZB_EDUCATION_LENGTH),
};
