import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import PERMISSIONS from 'constants/user/permissions';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { doArraysHaveCommonPart } from 'utils/helpers';
import { getUserRoles } from 'utils/user/getters';

export const canForwardToBankAdvisor = (inquiry, userRoles, isBankAdvisorEnabled) =>
  isBankAdvisorEnabled &&
  doArraysHaveCommonPart(PERMISSIONS.INQUIRY.FORWARD_TO_BANK_ADVISOR, userRoles);

export const useCanForwardToBankAdvisor = (inquiry) => {
  const userRoles = getUserRoles();
  const isBankAdvisorEnabled = useConfig(CONFIG.IS_BANK_ADVISOR_ENABLED);
  return canForwardToBankAdvisor(inquiry, userRoles, isBankAdvisorEnabled);
};

export const forwardToBankAdvisorAction = (inquiryId, bankAdvisorId) =>
  callReverseApi({
    url: endpoints.INQUIRIES.FORWARD.BANK_ADVISOR.compose({ params: { id: inquiryId } }),
    method: API_METHODS.PATCH,
    data: {
      data: {
        attributes: {
          bank_advisor_id: bankAdvisorId,
        },
      },
    },
  });
