import * as React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

export const TriggerQuickCheckStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.triggerQuickCheck');
  const history = useHistory();
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const onClick = () => {
    history.push(paths.operation.inquiryDetails.planningEvaluation.quickCheck(inquiryId));
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{t('description')}</TextComponent>
          <ButtonComponent
            data-testid={id}
            leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
            variant="primary"
            onClick={onClick}
          >
            {t('action')}
          </ButtonComponent>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
