import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const UmbauIcon = (props: IconProps) => {
  return (
    <Icon
      width="48"
      height="47"
      viewBox="0 0 47.96 46.46"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M39.74 30.53A3.88 3.88 0 0137 29.4L17.06 9.46a3.87 3.87 0 010-5.49l2.84-2.83a3.87 3.87 0 015.49 0l19.93 19.93a3.89 3.89 0 010 5.49l-2.84 2.84a3.84 3.84 0 01-2.74 1.13zM22.64 1.05a2.78 2.78 0 00-2 .83L17.8 4.71a2.84 2.84 0 000 4l19.93 19.95a2.91 2.91 0 004 0l2.84-2.84a2.83 2.83 0 000-4L24.65 1.88a2.81 2.81 0 00-2.01-.83z" />
      <path d="M36.76 41.49L21.23 25.96 12.8 34.4l-.74-.74 9.17-9.18 15.53 15.53 9.22-9.22-1.26-1.26.73-.74 2.01 2-10.7 10.7z" />
      <path d="M42.91 32.08l-2.3-2.29 5.06-5.06L48 27zm-.82-2.29l.82.81 3.57-3.6-.81-.81zM4.15 46.46L0 42.3l10.72-10.72 4.15 4.16zM1.48 42.3L4.15 45l9.24-9.24-2.67-2.68zM24.118 4.658l.743-.742 9.765 9.765-.743.742z" />
    </Icon>
  );
};

export default UmbauIcon;
