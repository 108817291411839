import { formatPrice } from 'utils/valueFormats';

const replaceDotWithComma = (value) => value.replace(/\./g, ',');

export const compeonOfferUnitFormatters = {
  monthsUnits: (translator) => (value) => `${value} ${translator('properties.units.months')}`,
  percent: () => (value) => `${replaceDotWithComma(value)}%`,
  percentPerYear: (translator) => (value) =>
    `${replaceDotWithComma(value)}% ${translator('properties.units.perYear')}`,
  pricePerMonth: (translator) => (value) =>
    `${formatPrice(value)} ${translator('properties.units.perMonth')}`,
  roundPricePerMonth: (translator) => (value) =>
    `${formatPrice(Math.round(value))} ${translator('properties.units.perMonth')}`,
  price: () => formatPrice,
};
