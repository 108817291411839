type RevisionInitiator = 'background_job' | 'admin' | 'api';

export enum RevisionModificationType {
  add = 'add',
  remove = 'remove',
  change = 'change',
}

export enum RevisionAttributeType {
  attribute = 'attribute',
  field = 'field',
}

export interface RevisionNewModification {
  attributeType: RevisionAttributeType;
  attribute: string;
  type: RevisionModificationType.add;
  current: any;
}

export interface RevisionRemoveModification {
  attributeType: RevisionAttributeType;
  attribute: string;
  type: RevisionModificationType.remove;
  previous: any;
}

export interface RevisionChangeModification {
  attributeType: RevisionAttributeType;
  attribute: string;
  type: RevisionModificationType.change;
  previous: any;
  current: any;
}

export interface UserPerson {
  salutation: string;
  firstName: string;
  lastName: string;
}

export type RevisionModification =
  | RevisionNewModification
  | RevisionRemoveModification
  | RevisionChangeModification;

export interface InquiryRevision {
  id: string;
  initiator: RevisionInitiator;
  modification: RevisionModification[];
  occurredAt: Date;
  createdAt: Date;
  updatedAt: Date;
  owner: UserPerson | null;
}

export interface InquiryRevisionListElement {
  id: string;
  createdAt: Date;
}
