import React from 'react';

import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useLoadMarketData, MAX_RETRIES } from 'api/CompeonReverseApi/operation/queryHooks';
import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import ProjectCostingHeader from 'pages/operationPortal/PlanningEvaluation/SharedComponent/ProjectCostingHeader';
import SalesTypeSelect from 'pages/operationPortal/PlanningEvaluation/SharedComponent/SalesTypeSelect';
import UsageTypeRadio from 'pages/operationPortal/PlanningEvaluation/SharedComponent/UsageTypeRadio';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector, getInquiryCreatedAtSelector } from 'store/inquiryDetails/selectors';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import LocationAnalysis from './MarketComparisonLocationAnalysis';
import ObjectEvaluation from './MarketComparisonObjectEvaluation';
import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';

interface MarketComparisonTopBox {
  living_space: number;
  commercial_space: number;
  potential_sales_revenue: string;
  possible_rental_income_per_year: string;
}

export interface MarketComparisonTopBoxValues {
  current: {
    unit: MarketComparisonTopBox;
    building: MarketComparisonTopBox;
  };
  future: {
    unit: MarketComparisonTopBox;
    building: MarketComparisonTopBox;
  };
}

const MittweidaMarketComparison = () => {
  const { error } = useToasts();
  const t = useTranslations('pages.planningEvaluation.mittweida.pages.marketComparison');
  const errorsT = useTranslations('errors');
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const { data, isLoading } = useLoadMarketData({
    variables: { inquiryId },
    select: (response) => ({
      topBoxes: response.data.attributes.computed_details.top_boxes,
    }),
    retry: (failureCount, responseError) => {
      if (responseError && failureCount === MAX_RETRIES) {
        error({ description: errorsT('requestFailed') });
        return false;
      }
      return true;
    },
  });

  const inquiryCreatedAt = useSelector(getInquiryCreatedAtSelector);
  const filterData = useSelector(getMarketDataFilterLegacy);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const topBoxValues: MarketComparisonTopBox | undefined =
    data?.topBoxes?.[filterData?.usageType]?.[
      filterData?.saleType === 'building' ? 'building' : 'unit'
    ];

  return (
    <>
      <ProjectCostingHeader
        heading={t('title')}
        subheading={`${t('createdAt')} ${formatDateWithTime(inquiryCreatedAt)}`}
      />
      <Flex mb={10} align={'center'}>
        <UsageTypeRadio disableCurrentUsage={false} />
        <SalesTypeSelect />
      </Flex>
      <SummaryCards>
        <SummaryCard
          caption={t('summaryCards.potentialSalesRevenue')}
          value={`${formatPrice(topBoxValues?.potential_sales_revenue, true)}`}
        />
        <SummaryCard
          caption={t('summaryCards.possibleRentalIncomePerYear')}
          value={`${formatPrice(topBoxValues?.possible_rental_income_per_year, true)}`}
        />
        <SummaryCard
          caption={t('summaryCards.livingSpace')}
          value={`${topBoxValues?.living_space} m²`}
        />
        <SummaryCard
          caption={t('summaryCards.commercialSpace')}
          value={`${topBoxValues?.commercial_space} m²`}
        />
      </SummaryCards>
      <ObjectEvaluation />
      <LocationAnalysis />
    </>
  );
};

export default MittweidaMarketComparison;
