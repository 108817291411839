import React from 'react';

import PageHeading from 'components/PageHeading';
import { usePartnerConfig } from 'config/partner/hooks';
import { useTranslations } from 'utils/hooks/useTranslations';

const RegistrationHeader = () => {
  const t = useTranslations('pages.registration');
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  return (
    <PageHeading heading={t('title')} subheading={t('heading.header', { partner: partnerName })} />
  );
};

export default RegistrationHeader;
