import styled from 'styled-components/macro';

import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';
import { mqSmall, mqMedium } from 'styles/breakpoints';
import { borderRadius, transition } from 'theme/themeConstants';

export const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: -0.75rem -0.5rem;
  list-style: none;

  ${mqMedium`
    flex-wrap: nowrap;
  `}
`;

export const StyledListItem = styled.li`
  flex: 0 1 auto;
  width: 50%;
  padding: 0.75rem 0.5rem;

  ${mqSmall`
    width: 33.33333333%;
  `};

  ${mqMedium`
    width: 33.33333333%;
  `};
`;

export const StyledIconWrapper = styled.span`
  display: block;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-radius: ${borderRadius.default};
  padding: 1.75rem 0;
  background-color: transparent;
  transition: border-color ${transition.default}, background-color ${transition.default};

  /* sadly react-svg lib needs two wrapper elements */
  /* spans are needed because the SVG is included inside a button element */
  > span {
    display: block;

    > span {
      display: block;
    }
  }

  svg {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.colors.border.darkGrey};
    transition: fill ${transition.default};

    [stroke] {
      fill: none;
      stroke: ${({ theme }) => theme.colors.border.darkGrey};
      transition: stroke ${transition.default};
    }
  }
`;

export const StyledTitle = styled.span`
  display: block;
  width: 100%;
  height: auto;
  min-height: 2rem;
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_LIGHT};
  text-align: center;
  color: ${({ theme }) => theme.colors.text.tertiary};
  transition: color ${transition.default};
`;

export const StyledListElement = styled.button`
  width: 100%;

  &.active {
    ${StyledIconWrapper} {
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.primary};

      svg {
        fill: ${({ theme }) => theme.colors.border.white};

        [stroke] {
          stroke: ${({ theme }) => theme.colors.border.white};
        }
      }
    }

    ${StyledTitle} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:focus&:not(.active),
  &:hover&:not(.active) {
    ${StyledTitle} {
      color: ${({ theme }) => theme.colors.primary};
    }

    ${StyledIconWrapper} {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    svg {
      fill: ${({ theme }) => theme.colors.primary};

      [stroke] {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
