import { useDispatch } from 'react-redux';

import { saveInquiriesAction } from 'pages/inquiryFlow/SummaryPage/summaryPage.service';
import { useGetRedirectUrlAfterInquirySending } from 'pages/inquiryFlow/SummaryPage/useGetRedirectUrlAfterInquirySending';
import {
  clearInquiryDraftId,
  clearLeadId as clearLeadIdAction,
} from 'store/inquiryProcess/actions';
import { setCreatedInquiryId } from 'store/progress/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const usePostNewInquiryToApi = () => {
  const { makeCall } = useDispatchApiCall();
  const dispatch = useDispatch();
  const redirectAfterSending = useGetRedirectUrlAfterInquirySending();

  return async (data: any) => {
    const response = await makeCall(saveInquiriesAction({ data }));

    if (!response.error) {
      dispatch(setCreatedInquiryId(response?.payload?.data?.data?.id));
      dispatch(clearLeadIdAction());
      dispatch(clearInquiryDraftId());
    }
    redirectAfterSending(response);
  };
};
