import styled from 'styled-components/macro';

import { mqSmall, mqLarge, mqExtraLarge } from 'styles/breakpoints';

const NameWrapper = styled.div`
  display: ${(props) => (props.isCurrentStage ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 0.5rem;
  font-size: 0.75rem;

  ${mqSmall`
    padding: 0 0.75rem;
  `};

  ${mqLarge`
    display: flex;
  `};

  ${mqExtraLarge`
    font-size: 1rem;
  `};
`;

export default NameWrapper;
