import React, { useEffect, useCallback, ReactNode } from 'react';

import RouteWatcher from 'components/App/RouteWatcher';
import { useToasts } from 'shared/hooks/useToasts';
import { useTranslations } from 'utils/hooks/useTranslations';
import { handleDataExpiration } from 'utils/sessionStorage/expiration';
import { isLoggedInUser } from 'utils/user/conditionals';
import { resetUser } from 'utils/user/setters';

const TokenExpirationWrapper = ({ children }: { children: ReactNode }) => {
  const { warning } = useToasts();
  const t = useTranslations();
  const isLoggedIn = isLoggedInUser();

  const redirectUserOnTokenExpiration = useCallback(() => {
    if (isLoggedIn) {
      const isExpired = handleDataExpiration();
      if (isExpired) {
        resetUser();
        warning({ description: t('pages.loginPage.sessionExpired') });
      }
    }
  }, [isLoggedIn, warning, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(redirectUserOnTokenExpiration, []);

  return <RouteWatcher onRouteChange={redirectUserOnTokenExpiration}>{children}</RouteWatcher>;
};

export default TokenExpirationWrapper;
