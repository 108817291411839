import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

export const StyledBlueTextBox = styled.div`
  width: 100%;
  margin: 3.5rem 0;
  padding: 3.5rem 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  align-items: flex-start;
`;

export const StyledList = styled.ol`
  padding-left: 1.5rem;
`;

export const StyledDocumentText = styled.li`
  margin: 1rem 0;
  font-size: 1.33rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.5;
  color: ${(props) => props.theme.colors.primary};
  ${(props) => props.align && `text-align: ${props.align};`};
`;

export const StyledUnorderedList = styled.ul`
  margin: -0.5rem 1.5rem 2rem;
`;
