import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['content', 'header']);

const Drawer = helpers.defineMultiStyleConfig({
  baseStyle: {
    content: {
      boxShadow: 'boxShadow.default',
      backgroundColor: 'background.white',
    },
    header: {
      paddingTop: '3rem',
    },
  },
});

export default Drawer;
