import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { YearPickerUnwrapped } from 'components/DatePicker';
import { getKpiDateRange } from 'store/kpi/kpis.selectors';
import { useDatePickerContext } from 'theme/components/FinancialInformations/context/DatePickerContext';
import { FinancialBalanceSheet } from 'theme/components/FinancialInformations/FinancialBalanceSheet';
import { FinancialIncomeStatement } from 'theme/components/FinancialInformations/FinancialIncomeStatement';

export const FinancialInformationAllInfo = () => {
  const { date, setDate } = useDatePickerContext();

  const kpisDateRange = useSelector(getKpiDateRange);

  const handleOnChange = (date: Date | undefined) => {
    setDate(date);
  };

  // This need to be done because if we would use the default firstKpi the first year wouldn't be accessible
  const firstKpi = new Date(
    new Date(kpisDateRange.firstKpi).setFullYear(
      new Date(kpisDateRange.firstKpi).getFullYear() - 1,
    ),
  );

  return (
    <>
      <Box width={'30%'} mb={4}>
        <YearPickerUnwrapped
          caption={''}
          name={'FinancialInformationDatePicker'}
          placeholder={''}
          errorMessage={''}
          value={date.toString()}
          onChange={handleOnChange}
          optional={false}
          tooltip={''}
          isClearable={false}
          maxDate={new Date(kpisDateRange.lastKpi)}
          minDate={firstKpi}
        />
      </Box>
      <FinancialIncomeStatement />
      <FinancialBalanceSheet />
    </>
  );
};
