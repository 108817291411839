import React, { useEffect } from 'react';

import {
  USER_PROFILE,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_LAST_NAME,
} from 'modules/Inquiry/Form/formFields';
import ContactPerson from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import PasswordSection from 'pages/inquiryFlow/CompanyDetails/PasswordSection/PasswordSection';
import TermsAndConditions from 'pages/inquiryFlow/CompanyDetails/sections/TermsAndConditions';
import { useCompanyDetailsSectionValidators } from 'pages/inquiryFlow/CompanyDetails/useCompanyDetailsSectionValidators';
import UserProfileHeading from 'pages/inquiryFlow/UserProfile/Heading/UserProfileHeading';
import { store } from 'store';
import { removeQueryParamsFromUrl } from 'utils/paths';
import { isLoggedInUser } from 'utils/user/conditionals';
import { getCurrentUser } from 'utils/user/getters';

export const UserProfileUnwrapped = () => {
  const getSectionsValidators = useCompanyDetailsSectionValidators();
  useEffect(() => {
    // Clearing out query params to not pass token in URL
    // See more at CRV-540
    removeQueryParamsFromUrl();
  }, []);

  // if user is loggedIn, set values from store
  const isLoggedIn = isLoggedInUser();
  let initialValues = undefined;
  if (isLoggedIn) {
    const customer = getCurrentUser(store.getState());
    initialValues = {
      [COMPANY_DETAILS_EMAIL]: customer?.email,
      [COMPANY_DETAILS_FIRST_NAME]: customer?.firstName,
      [COMPANY_DETAILS_LAST_NAME]: customer?.lastName,
      [COMPANY_DETAILS_GENDER]: customer?.gender,
    };
  }

  return (
    <InquiryFormNew
      initialValues={initialValues}
      currentStepName={USER_PROFILE}
      headerComp={<UserProfileHeading />}
      getSectionValidators={getSectionsValidators}
    >
      {() => (
        <>
          <ContactPerson />
          {isLoggedIn ? (
            <TermsAndConditions sectionNumber={2} />
          ) : (
            <>
              <PasswordSection sectionNumber={2} />
              <TermsAndConditions sectionNumber={3} />
            </>
          )}
        </>
      )}
    </InquiryFormNew>
  );
};

export default UserProfileUnwrapped;
