import React from 'react';

import Condition from 'components/Condition';

import FileReclassificationButton from './FileReclassificationButton';
import { useFileReclassificationButton } from './useFileReclassificationButton';

export interface FileReclassificationButtonProps {
  fileId: string;
  fileRequestId?: string;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}

const FileReclassificationButtonContainer = (props: FileReclassificationButtonProps) => {
  const {
    isFileReclassificationEnabled,
    initialClassification,
    handleReclassification,
    isPotentiallyIncorrectlyClassified,
    isReclassifiable,
    options,
  } = useFileReclassificationButton(props);

  if (!isReclassifiable) return null;

  return (
    <Condition condition={isFileReclassificationEnabled}>
      <FileReclassificationButton
        isPotentiallyIncorrectlyClassified={isPotentiallyIncorrectlyClassified}
        initialClassification={initialClassification}
        onReclassify={handleReclassification}
        options={options}
      />
    </Condition>
  );
};

export default FileReclassificationButtonContainer;
