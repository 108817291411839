import { useDispatchQueryParam } from 'pages/inquiryFlow/FinancingNeed/hooks/useQueryParamToStore';
import {
  setExternalReferenceIdAction,
  setMultiplierOrgIdAction,
  setReferralWidgetCode,
} from 'store/inquiryProcess/actions';
import { KEY_REFERRAL_WIDGET_CODE } from 'utils/sessionStorage/keys';

const MULTI_ORG_PARAM = 'mid';
const EXTERNAL_REFERENCE_PARAM = 'external-reference';

export const useInquiryFormQueryParams = () => {
  // see more under CRV-792
  useDispatchQueryParam({
    queryParamName: MULTI_ORG_PARAM,
    action: setMultiplierOrgIdAction,
  });

  // see more under CRV-1002
  useDispatchQueryParam({
    queryParamName: EXTERNAL_REFERENCE_PARAM,
    action: setExternalReferenceIdAction,
  });

  // Referral from widget
  useDispatchQueryParam({
    queryParamName: KEY_REFERRAL_WIDGET_CODE,
    action: setReferralWidgetCode,
  });
};
