import {
  VEHICLE_NEW,
  VEHICLE_KIND,
  VEHICLE_KIND__OTHER,
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_COUNT,
  VEHICLE_YEAR,
  VEHICLE_OTHER_DESCRIPTION,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_YEARLY_MILEAGE,
  VEHICLE_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toBoolean, toInteger, toFloatPrecision2, getYearFromDate } = fieldParsers;
export const mapVehicleToApi: InquiryFormMapper = (allFields: any) => {
  const isOtherType = allFields[VEHICLE_KIND] === VEHICLE_KIND__OTHER;

  return {
    'vehicle-price': toFloatPrecision2(allFields[VEHICLE_PURCHASE_PRICE]),
    'vehicle-count': toInteger(allFields[VEHICLE_COUNT]),
    'vehicle-new': toBoolean(allFields[VEHICLE_NEW]),
    'vehicle-year': toBoolean(allFields[VEHICLE_NEW])
      ? undefined
      : getYearFromDate(allFields[VEHICLE_YEAR]),
    'vehicle-kind': allFields[VEHICLE_KIND],
    'vehicle-other-description': isOtherType ? allFields[VEHICLE_OTHER_DESCRIPTION] : undefined,
    'vehicle-manufacturer': allFields[VEHICLE_MANUFACTURER],
    'vehicle-model': allFields[VEHICLE_MODEL],
    'vehicle-yearly-mileage': toInteger(allFields[VEHICLE_YEARLY_MILEAGE]),
    'vehicle-description': allFields[VEHICLE_DESCRIPTION],
  };
};
