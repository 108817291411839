import { IState } from '../../../../store';

export const getObjectData = (store: IState) =>
  store.profitability?.mittweidaTableData?.bankValues.objectData;
export const getUpdatedDate = (store: IState) =>
  store.profitability?.mittweidaTableData?.bankValues.lastUpdatedAt;
export const getFurtherObjectData = (store: IState) =>
  store.profitability?.mittweidaTableData?.bankValues.furtherObjectDetails;
export const getBankValuesTopBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.bankValues.topBoxes;
export const getFinancialCriteriaData = (store: IState) =>
  store.profitability?.mittweidaTableData?.financingCriteria.financingCriteriaData;
export const getFinanacialCriteriaDataDeveloper = (store: IState) =>
  store.profitability?.mittweidaTableData?.financingCriteria.financingCriteriaData.developer;
export const getFinanacialCriteriaDataStockholder = (store: IState) =>
  store.profitability?.mittweidaTableData?.financingCriteria.financingCriteriaData.stockholder;
export const getFinanacialCriteriaDataPartition = (store: IState) =>
  store.profitability?.mittweidaTableData?.financingCriteria.financingCriteriaData.partition;
export const getFinanacialCriteriaTopBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.financingCriteria.topBoxes;
export const getProfitabilityDataTopBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.boxes.topBoxes;
export const getProfitabilityDataRentBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.boxes.rentBoxes;
export const getProfitabilityDataProfitabilitySalesBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.boxes.profitabilitySalesBoxes;
export const getProfitabilityDataRiskBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.boxes.riskBoxes;
export const getProfitabilityFinancingDetailsTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.financingDetails;
export const getProfitabilityPropertyDetailsTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.propertyDetails;
export const getProfitabilitySourceOfFundsTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.sourceOfFunds;
export const getProfitabilityInvestmentPlanTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.investmentPlan;
export const getProfitabilityProfitabilitySalesTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.profitabilitySales;
export const getProfitabilityLendingValueTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.lendingValue;
export const getProfitabilityRentTable = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.rent;
export const getMarketComparisonTopBoxes = (store: IState) =>
  store.profitability?.mittweidaTableData?.marketComparison?.topBoxes;
export const getMarketComparisonObjectEvaluation = (store: IState) =>
  store.profitability?.mittweidaTableData?.marketComparison.evaluation;
export const getMarketComparisonLocationAnalysis = (store: IState) =>
  store.profitability?.mittweidaTableData?.marketComparison.location;
export const getPropertyDetails = (store: IState) => store.profitability?.propertyDetails;
export const getPropertyProfileNotes = (store: IState) => ({
  notesOnCustomer:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.notes.notes_on_customer,
  descriptionOfFinancingProject:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.notes
      .description_of_financing_project,
  notesOnObject:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.notes.notes_on_object,
  notesOnFinancingPlan:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.sourceOfFunds
      .notes_on_financing_plan,
  notesOnFinancingParameters:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.financingDetails
      .notes_on_financing_parameters,
  notesOnDebtService:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.rent
      .notes_on_debt_service,
  notesOnFinancingCriteria: store.profitability?.mittweidaTableData?.financingCriteria.notes,
  notesOnLendingValueRemainingRisk:
    store.profitability?.mittweidaTableData?.profitabilityCalculation.tables.lendingValue
      .notes_on_remaining_risk,
});
export const getPropertyProfileObjectImages = (store: IState) =>
  store.profitability?.mittweidaTableData?.profitabilityCalculation.objectImages;
