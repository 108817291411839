import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { StaticFieldWithField } from 'components/StaticField';
import {
  BUILDING_SOURCE_OF_FUNDS_SUM,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_EQUITY,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
  BUILDING_USE_OF_FUNDS_SUM,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { useSumOfFields } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useSumOfFields';
import { StyledSumValues } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/styles';
import { formatPrice } from 'utils/valueFormats';

const fieldsToSum = [
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
];

const BuildingSourceOfFundsSum: React.FC = () => {
  const sumOfFunds = useSumOfFields(fieldsToSum);
  const { change } = useForm();

  const useFundsSum = useGetFieldNumericValue(BUILDING_USE_OF_FUNDS_SUM);

  useEffect(() => {
    change(BUILDING_SOURCE_OF_FUNDS_SUM, sumOfFunds);
  }, [change, sumOfFunds]);

  return (
    <StyledSumValues hasBiggerSum={useFundsSum > sumOfFunds}>
      <StaticFieldWithField
        isHorizontal
        name={BUILDING_SOURCE_OF_FUNDS_SUM}
        parseValue={(value: number) => formatPrice(value, false)}
      />
    </StyledSumValues>
  );
};

export default BuildingSourceOfFundsSum;
