import { LabelsMap } from 'new/form/common/types';

import { authorizationOfRepresentationSectionLabelsMap } from './sections/authorizationOfRepresentationSection/labels';
import { beneficialOwnerSectionLabelsMap } from './sections/beneficialOwnerSection/labels';
import { companySectionLabelsMap } from './sections/companySection/labels';
import { contactPersonSectionLabelsMap } from './sections/contactPersonSection/labels';

export const mmvCustomerDataLabelsMap: LabelsMap = {
  ...companySectionLabelsMap,
  ...contactPersonSectionLabelsMap,
  ...authorizationOfRepresentationSectionLabelsMap,
  ...beneficialOwnerSectionLabelsMap,
};
