import React from 'react';

import { useSelector } from 'react-redux';

import { StyledFastlaneResultInfo } from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/styles';
import { getFastLane } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const FastlaneFailInfo: React.FC = () => {
  const t = useTranslations();
  const state = useSelector(getFastLane);

  return (
    <StyledFastlaneResultInfo isQualified={state.isQualifiedForFastlane}>
      {t('pages.inquiryDetails.offers.fastlane.summary.isNotQualified')}
    </StyledFastlaneResultInfo>
  );
};

export default FastlaneFailInfo;
