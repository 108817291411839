import { useFormConfig } from 'config/formConfig/hooks';
import { validateNotLoggedInUserDetails } from 'modules/Inquiry/Form/Validations/default/CompanyDetails/notLoggedInUserDetails.validator';
import { validatePasswordSection } from 'pages/inquiryFlow/CompanyDetails/PasswordSection/PasswordSection';
import { validateConsentSection } from 'pages/inquiryFlow/CompanyDetails/sections/TermsAndConditions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { isLoggedInUser } from 'utils/user/conditionals';

export const useCompanyDetailsSectionValidators = () => {
  const isLoggedIn = isLoggedInUser();
  const { selectedInquiryType } = useFormConfig();

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  return () =>
    isLoggedIn
      ? [validateConsentSection(selectedInquiryType)]
      : [
          validateNotLoggedInUserDetails(makeCall),
          validatePasswordSection,
          validateConsentSection(selectedInquiryType),
        ];
};
