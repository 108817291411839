import { useDisclosure } from '@chakra-ui/hooks';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { userIsCustomer } from 'utils/user/conditionals';

export const useArchiveDocumentsButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isCustomer = userIsCustomer();
  const shouldShowArchiveDocumentsButton = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiMittweida]: !isCustomer,
    [InquiryType.default]: false,
  });
  return {
    isOpen,
    onClose,
    onOpen,
    shouldShowArchiveDocumentsButton,
  };
};
