import React from 'react';

import { usePreviewFile } from 'components/DownloadBlock/usePreviewFile';
import { FileType, IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { ButtonComponent } from 'theme/components/Button';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export const previewFileInRequestIcon = <ViewIcon boxSize={6} display="block" />;

interface IPreviewFileInRequest {
  file: IFile | CompeonDocument;
  type?: FileType;
}

const PreviewFileInRequest = ({ file, type }: IPreviewFileInRequest) => {
  const t = useTranslations('components.upload');
  const [previewFile, isLoading] = usePreviewFile({
    fileId: file.id,
    type,
  });

  return (
    <ButtonComponent
      data-testid="preview-file-btn"
      leftIcon={previewFileInRequestIcon}
      isLoading={isLoading}
      onClick={previewFile}
    >
      {t('preview')}
    </ButtonComponent>
  );
};

export default PreviewFileInRequest;
