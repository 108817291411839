import PERMISSIONS from 'constants/user/permissions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';
import { hasPermission } from 'utils/user/conditionals';

const withCompanies = getEnvironmentFeatureFlag(EnvironmentVariable.COMPANIES_LIST);

const useShowCompanyDetails = () => {
  return useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: false,
    [InquiryType.mmv]: false,
    [InquiryType.default]:
      withCompanies && hasPermission(InquiryType.default)(PERMISSIONS.COMPANY_DETAILS.DISPLAY),
  });
};

export default useShowCompanyDetails;
