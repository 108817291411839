import * as React from 'react';

import { Badge, BadgeProps } from '@chakra-ui/react';

export enum BadgeStatus {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
interface CustomBadgeProps extends BadgeProps {
  status?: BadgeStatus;
}

export const BadgeComponent = ({ status, ...props }: CustomBadgeProps) => {
  return <Badge variant={status} {...props} />;
};
