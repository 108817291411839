import React from 'react';

import { useSelector } from 'react-redux';

import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { getInquiryStatusSelector } from 'store/inquiryDetails/selectors';

const disableInquiryDraft = (Component) => (props) => {
  const inquiryStatus = useSelector(getInquiryStatusSelector);
  return inquiryStatus !== INQUIRY_STATUSES.DRAFT ? <Component {...props} /> : null;
};

export default disableInquiryDraft;
