import React from 'react';

import { chakra } from '@chakra-ui/react';
import type { SortDirection } from '@tanstack/react-table';

import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';
import CaretUpIcon from 'theme/components/Icon/CaretUpIcon';

const SortingArrowIconWrapper = ({ children }: { children: React.ReactNode }) => (
  <chakra.span display="flex" flexDirection="column" marginLeft={1} color="text.tertiary">
    {children}
  </chakra.span>
);
interface SortingArrowProps {
  isSorted?: boolean | SortDirection;
}
export const SortingArrows = ({ isSorted = false }: SortingArrowProps) => {
  if (!isSorted) {
    return (
      <SortingArrowIconWrapper>
        <CaretUpIcon boxSize={6} marginBottom="-3" />
        <CaretDownIcon boxSize={6} />
      </SortingArrowIconWrapper>
    );
  }

  return (
    <SortingArrowIconWrapper>
      <CaretUpIcon
        boxSize={6}
        marginBottom="-3"
        color="brand.default"
        visibility={isSorted === 'asc' ? 'visible' : 'hidden'}
      />
      <CaretDownIcon
        boxSize={6}
        color="brand.default"
        visibility={isSorted === 'desc' ? 'visible' : 'hidden'}
      />
    </SortingArrowIconWrapper>
  );
};
export default SortingArrows;
