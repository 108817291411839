import React from 'react';

import { Box } from '@chakra-ui/react';

interface LightProps {
  // TODO(types): use theme type here once we setup chakra-ui/tokens
  color: string | undefined;
}

export const Light = ({ color }: LightProps) => {
  const bgColor = color || 'trafficLight.none';
  return <Box bgColor={bgColor} />;
};
