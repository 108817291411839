import { rgba } from 'polished';
import styled from 'styled-components/macro';

export const StyledOfferAcceptMsg = styled.div`
  padding: 1rem;
  font-size: 1.2em;
  text-align: center;
  color: ${({ theme }) => theme.colors.status.success};
  background-color: ${({ theme }) => rgba(theme.colors.status.success, 0.2)};

  &.neutral {
    color: ${({ theme }) => theme.colors.text.tertiary};
    background-color: ${({ theme }) => theme.colors.background.darkGrey};
  }
`;
