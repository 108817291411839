import styled from 'styled-components';

export const StyledFastlaneWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: auto;
`;

export const StyledDescription = styled.p`
  margin-bottom: 2rem;
  line-height: 1.25;
`;
