import React from 'react';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import NewInquiryButton from 'pages/customerPortal/CustomerInquiryList/NewInquiryButton';
import LeasePlanNoInquiry from 'pages/customerPortal/CustomerInquiryList/NoInquiry/LeasePlanNoInquiry';
import {
  StyledEmptyLabel as Label,
  StyledEmptyWrapper as Wrapper,
} from 'pages/customerPortal/CustomerInquiryList/NoInquiry/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import intlShape from 'shared/intlShape';
import { useTranslations } from 'utils/hooks/useTranslations';

const NoInquiry = () => {
  const t = useTranslations();
  return (
    <Wrapper>
      <Label>{t('pages.inquiryList.noInquiries')}</Label>
      <NewInquiryButton />
    </Wrapper>
  );
};

NoInquiry.propTypes = {
  intl: intlShape.isRequired,
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.leaseplan]: LeasePlanNoInquiry,
  [InquiryType.mmv]: LeasePlanNoInquiry,
  default: NoInquiry,
});
