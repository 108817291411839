import React from 'react';

import { Box } from '@chakra-ui/react';

import BalanceSheetTable from './components/BalanceSheetTable';
import { useTranslations } from '../../../../utils/hooks/useTranslations';
import { AccordionComponent } from '../../Accordion';
import { Kpi } from '../hooks/useKpiFilter';

export interface GetTableDataProps {
  currentYearData: Kpi[] | undefined;
  previousYearData: Kpi[] | undefined;
}

export const FinancialBalanceSheet = () => {
  const t = useTranslations('pages.companiesDetails.financialInformation.balanceSheet');

  return (
    <AccordionComponent headingLevel={'h2'} title={t('headline')} text={t('subline')}>
      <Box
        overflowX={'auto'}
        overflowY={'hidden'}
        maxWidth={'100%'}
        sx={{ '-webkit-overflow-scrolling': 'touch' }}
        display={'flex'}
        flexDirection={'row'}
      >
        <BalanceSheetTable />
      </Box>
    </AccordionComponent>
  );
};
