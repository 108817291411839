import React from 'react';

import { useSelector } from 'react-redux';

import Spinner from 'components/Spinner';
import FastlaneErrorDescription from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/FastlaneErrorDescription';
import FastlaneFailInfo from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/FastlaneFailInfo';
import { StyledLoadingText } from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/styles';
import FastlaneSuccessInfo from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/SuccessInfo';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getFastLane } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const FastlaneInformationHeading: React.FC = () => {
  const t = useTranslations();
  const state = useSelector(getFastLane);

  const isFastlaneEnabled = useConfig(CONFIG.IS_FASTLANE_ENABLED);
  if (!isFastlaneEnabled) {
    return null;
  }

  if (state.isFastlaneLoading) {
    return (
      <StyledLoadingText>
        <p>{t('pages.inquiryDetails.offers.fastlane.summary.checkingIfEligible')}</p>
        <Spinner />
      </StyledLoadingText>
    );
  }
  return (
    <div>
      {state.isQualifiedForFastlane ? <FastlaneSuccessInfo /> : <FastlaneFailInfo />}
      {!state.isQualifiedForFastlane && <FastlaneErrorDescription />}
    </div>
  );
};

export default FastlaneInformationHeading;
