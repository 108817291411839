import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { fieldsAreValid } from 'modules/Inquiry/Form/formConditions';
import {
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import BFSProjectDevelopment from './BFSRealEstateFields/BFSProjectDevelopment';

export const validateProjectDevelopmentSection = (form) =>
  fieldsAreValid([
    REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
    REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  ])({ form });

const ProjectDevelopment = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.realEstateProjectDevelopment}
      sectionNumber={4}
      title={t('pages.financingNeed.sections.projectDevelopment.title')}
    >
      <FormRow>
        <AmountWithField name={REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT} />
      </FormRow>
      <FormRow>
        <TextAreaWithField name={REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION} />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BFSProjectDevelopment,
  default: ProjectDevelopment,
});
