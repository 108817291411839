import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export const WindowIcon = (props: IconProps) => {
  return (
    <Icon
      width={'128'}
      height={'128'}
      viewBox={'0 0 128 128'}
      aria-hidden={'true'}
      fill={'white'}
      fillOpacity={0}
      {...props}
    >
      <rect x="28.8735" y="9.50537" width="64.2632" height="103.989" stroke-width="2.08989" />
      <rect x="33.5474" y="15.3472" width="54.9158" height="92.3053" stroke-width="2.08989" />
      <line x1="61.4209" y1="15.3472" x2="61.4209" y2="108.821" stroke-width="2.08989" />
      <line x1="88.4634" y1="42.0527" x2="33.5476" y2="42.0527" stroke-width="2.08989" />
      <path
        d="M17.021 36.3788C17.021 35.6405 17.6195 35.042 18.3578 35.042H27.8736V42.3894H18.3578C17.6195 42.3894 17.021 41.7908 17.021 41.0525V36.3788Z"
        stroke-width="2.08989"
      />
      <path
        d="M17.021 72.6C17.021 71.8617 17.6195 71.2632 18.3578 71.2632H27.8736V78.6106H18.3578C17.6195 78.6106 17.021 78.012 17.021 77.2737V72.6Z"
        stroke-width="2.08989"
      />
      <path
        d="M10.0107 27.0317C10.0107 26.2933 10.6093 25.6948 11.3476 25.6948H27.8739V33.0422H11.3476C10.6093 33.0422 10.0107 32.4437 10.0107 31.7053V27.0317Z"
        stroke-width="2.08989"
      />
      <path
        d="M10.0107 63.2529C10.0107 62.5145 10.6093 61.916 11.3476 61.916H27.8739V69.2634H11.3476C10.6093 69.2634 10.0107 68.6649 10.0107 67.9265V63.2529Z"
        stroke-width="2.08989"
      />
      <path
        d="M10.0107 45.7265C10.0107 44.9882 10.6093 44.3896 11.3476 44.3896H27.8739V51.737H11.3476C10.6093 51.737 10.0107 51.1385 10.0107 50.4002V45.7265Z"
        stroke-width="2.08989"
      />
      <path
        d="M10.0107 81.9472C10.0107 81.2089 10.6093 80.6104 11.3476 80.6104H27.8739V87.9577H11.3476C10.6093 87.9577 10.0107 87.3592 10.0107 86.6209V81.9472Z"
        stroke-width="2.08989"
      />
      <path
        d="M16.1895 41.0528C16.1895 41.7911 15.5909 42.3896 14.8526 42.3896H2.99998V35.0423H14.8526C15.5909 35.0423 16.1895 35.6408 16.1895 36.3791V41.0528Z"
        stroke-width="2.08989"
      />
      <path
        d="M104.989 24.6948C104.989 23.9564 104.391 23.3579 103.652 23.3579H94.1366V30.7053H103.652C104.391 30.7053 104.989 30.1068 104.989 29.3684V24.6948Z"
        stroke-width="2.08989"
      />
      <path
        d="M112 15.3476C112 14.6093 111.401 14.0107 110.663 14.0107H94.1368V21.3581H110.663C111.401 21.3581 112 20.7596 112 20.0213V15.3476Z"
        stroke-width="2.08989"
      />
      <path
        d="M112 34.0419C112 33.3036 111.401 32.7051 110.663 32.7051H94.1368V40.0524H110.663C111.401 40.0524 112 39.4539 112 38.7156V34.0419Z"
        stroke-width="2.08989"
      />
      <path
        d="M17.021 53.9052C17.021 53.1669 17.6195 52.5684 18.3578 52.5684H27.8736V59.9157H18.3578C17.6195 59.9157 17.021 59.3172 17.021 58.5789V53.9052Z"
        stroke-width="2.08989"
      />
      <path
        d="M101.987 63.1158L105.941 63.7191C106.487 63.8025 106.862 64.3128 106.779 64.8585L106.628 65.847L108.605 66.1487L108.756 65.1601C108.892 64.2684 109.599 63.29 112.197 63.6863C116.625 64.3618 125.332 68.6218 125.7 68.8033C126.027 68.9634 126.239 69.2872 126.258 69.6501C126.276 70.0121 126.096 70.3569 125.789 70.5487C124.974 71.0576 123.956 71.7713 123.742 72.0229C123.07 72.8136 121.716 75.7367 121.02 77.3927C120.843 77.8149 120.401 78.063 119.947 77.9938L116.981 77.5413L115.749 85.6189C116.837 86.2027 117.496 87.4281 117.299 88.7172L113.224 115.433C112.974 117.068 111.441 118.196 109.805 117.946L105.851 117.343C104.216 117.094 103.089 115.56 103.338 113.925L107.414 87.2099C107.61 85.9209 108.605 84.9477 109.818 84.7149L111.05 76.6374L108.084 76.185C107.538 76.1015 107.163 75.5913 107.246 75.0456L107.397 74.057L105.42 73.7554L105.269 74.7439C105.186 75.2896 104.677 75.6651 104.13 75.5817L100.176 74.9784C99.629 74.895 99.2547 74.3847 99.338 73.839L100.846 63.9534C100.931 63.4069 101.44 63.0324 101.987 63.1158ZM110.531 86.674C109.987 86.5909 109.475 86.967 109.392 87.5117L105.316 114.227C105.233 114.772 105.609 115.283 106.154 115.366L110.108 115.969C110.653 116.052 111.164 115.677 111.247 115.132L115.323 88.4167C115.406 87.872 115.03 87.3604 114.485 87.2773C113.94 87.194 113.564 86.6836 113.648 86.1379L115.005 77.2408L113.028 76.9392L111.671 85.8363C111.587 86.3819 111.077 86.7572 110.531 86.674ZM109.375 74.3588L112.341 74.8113L116.295 75.4145L119.485 75.9012C120.079 74.5413 121.356 71.7438 122.22 70.7285C122.436 70.4751 122.827 70.1565 123.244 69.8519C120.524 68.5902 114.951 66.1296 111.896 65.6634C111.21 65.5588 110.862 65.5907 110.708 65.6229L110.431 67.4379L109.526 73.3693L109.375 74.3588ZM107.7 72.08L108.303 68.1258L106.326 67.8242L105.723 71.7784L107.7 72.08ZM101.467 73.1522L103.444 73.4539L103.595 72.4653L104.5 66.534L104.651 65.5454L102.673 65.2437L101.467 73.1522Z"
        stroke-width="2.08989"
      />
    </Icon>
  );
};
