import React from 'react';

import { Parser as HtmlToReactParser } from 'html-to-react';

import { usePartnerConfig } from 'config/partner/hooks';
import { Footer, Copyright } from 'modules/Footer/FooterContent/Container';
import LinkList from 'modules/Footer/FooterContent/FooterLinkList';

const htmlParser = new HtmlToReactParser();

const FooterContent = () => {
  const {
    copy: { copyright },
  } = usePartnerConfig();

  return (
    <Footer>
      <Copyright>{htmlParser.parse(copyright)}</Copyright>
      <LinkList />
    </Footer>
  );
};

export default FooterContent;
