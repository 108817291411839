import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { DEBT_RESCHEDULING_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateDebtReschedulingSection = (form: any) =>
  fieldIsValid(DEBT_RESCHEDULING_DESCRIPTION)({ form });

const DebtRescheduling = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.debtRescheduling}
      sectionNumber={3}
      title={t('pages.financingNeed.sections.purpose.fields.purposeKind.options.debtRescheduling')}
    >
      <FormRow>
        <TextAreaWithField name={DEBT_RESCHEDULING_DESCRIPTION} />
      </FormRow>
    </FormSection>
  );
};

export default DebtRescheduling;
