import React from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import {
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_RENT_FUTURE,
  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE,
  PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import SingleInformation from 'modules/InquiryDetails/DetailedInformation/InformationRows/SingleInformation';
import { StyledColleteral } from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection/CollateralsInformation/styles';
import { StyledKeyValueSection as Row } from 'modules/InquiryDetails/DetailedInformation/styles';
import { useInquiryValueParserForDisplay } from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';

export interface ProjectFinancingUsageType {
  [PROJECT_FINANCING_TYPE_OF_USAGE]: string;
  [PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS]: string;
  [PROJECT_FINANCING_USAGE_SPACE]: number;
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]: number;
  [PROJECT_FINANCING_RENT_NOW]: number;
  [PROJECT_FINANCING_RENT_FUTURE]: number;
  [PROJECT_FINANCING__NUMBER_OF_UNITS]: number;
  [PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE]: string;
  [PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT]: string;
  [PROJECT_FINANCING_USAGE_KIND_TYPE]: string;
  [PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT]: number;
}

const UsageTypeDetails = ({
  usageType,
  isLastItem,
}: {
  usageType: ProjectFinancingUsageType;
  isLastItem: boolean;
}) => {
  const translateLabel = useInquiryLabelTranslator();
  const parseFieldValue = useInquiryValueParserForDisplay();
  const { selectedInquiryType } = useFormConfig();

  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const hasDescription = !!usageType[PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS];
  const hasRentNow = !!usageType[PROJECT_FINANCING_RENT_NOW];
  const hasRentFuture = !!usageType[PROJECT_FINANCING_RENT_FUTURE];
  const hasTypeOfUsageCurrent =
    usageType[PROJECT_FINANCING_USAGE_KIND_TYPE] === PROJECT_FINANCING_USAGE_KIND_TYPE_CURRENT;
  const hasNumberOfUnits = !!usageType[PROJECT_FINANCING__NUMBER_OF_UNITS];
  const hasPlannedSalesPrice = !!usageType[PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT];

  return (
    <div>
      <StyledColleteral isLastItem={isLastItem}>
        <Row>
          <SingleInformation
            label={
              hasTypeOfUsageCurrent
                ? translateLabel(PROJECT_FINANCING_TYPE_OF_USAGE_CURRENT)
                : translateLabel(PROJECT_FINANCING_TYPE_OF_USAGE_FUTURE)
            }
            value={parseFieldValue(
              usageType[PROJECT_FINANCING_TYPE_OF_USAGE],
              PROJECT_FINANCING_TYPE_OF_USAGE,
            )}
          />
          <SingleInformation
            label={translateLabel(PROJECT_FINANCING_USAGE_SPACE)}
            value={parseFieldValue(
              usageType[PROJECT_FINANCING_USAGE_SPACE],
              PROJECT_FINANCING_USAGE_SPACE,
            )}
          />
          {!isProfiInquiry && (
            <SingleInformation
              label={translateLabel(PROJECT_FINANCING_PERCENTAGE_OF_RENT)}
              value={parseFieldValue(
                usageType[PROJECT_FINANCING_PERCENTAGE_OF_RENT],
                PROJECT_FINANCING_PERCENTAGE_OF_RENT,
              )}
            />
          )}
        </Row>
        {(hasRentNow || hasRentFuture || hasPlannedSalesPrice) && (
          <Row>
            {hasRentNow && (
              <SingleInformation
                label={translateLabel(PROJECT_FINANCING_RENT_NOW)}
                value={parseFieldValue(
                  usageType[PROJECT_FINANCING_RENT_NOW],
                  PROJECT_FINANCING_RENT_NOW,
                )}
              />
            )}
            {hasRentFuture && (
              <SingleInformation
                label={translateLabel(PROJECT_FINANCING_RENT_FUTURE)}
                value={parseFieldValue(
                  usageType[PROJECT_FINANCING_RENT_FUTURE],
                  PROJECT_FINANCING_RENT_FUTURE,
                )}
              />
            )}
            {hasPlannedSalesPrice && (
              <SingleInformation
                label={translateLabel(PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT)}
                value={parseFieldValue(
                  usageType[PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT],
                  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
                )}
              />
            )}
          </Row>
        )}
        {hasDescription && (
          <Row>
            <SingleInformation
              isWide
              label={translateLabel(PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS)}
              value={parseFieldValue(
                usageType[PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS],
                PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
              )}
            />
          </Row>
        )}
        {hasNumberOfUnits && (
          <Row>
            <SingleInformation
              label={translateLabel(PROJECT_FINANCING__NUMBER_OF_UNITS)}
              value={parseFieldValue(
                usageType[PROJECT_FINANCING__NUMBER_OF_UNITS],
                PROJECT_FINANCING__NUMBER_OF_UNITS,
              )}
            />
          </Row>
        )}
      </StyledColleteral>
    </div>
  );
};

export default UsageTypeDetails;
