import * as fields from 'modules/Inquiry/Form/formFields';

import { formatDecimalForAPI } from './mapProfitabilitySectionsToApi/utils';

const mapMarketValueToApi = (data: Record<string, any>) => ({
  current: {
    building: {
      commercial_rent_per_sqm: formatDecimalForAPI(
        data[fields.CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM],
      ),
      comparative_value_per_sqm: formatDecimalForAPI(
        data[fields.CURRENT_USAGE_MARKET_VALUE_PER_SQM_BUILDING],
      ),
      existing_rent_per_sqm: formatDecimalForAPI(data[fields.CURRENT_USAGE_EXISTING_RENT_PER_SQM]),
      residential_rent_per_sqm: formatDecimalForAPI(
        data[fields.CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM],
      ),
    },
    unit: {
      commercial_rent_per_sqm: formatDecimalForAPI(
        data[fields.CURRENT_USAGE_COMMERCIAL_RENT_PER_SQM],
      ),
      comparative_value_per_sqm: formatDecimalForAPI(
        data[fields.CURRENT_USAGE_MARKET_VALUE_PER_SQM_UNIT],
      ),
      existing_rent_per_sqm: formatDecimalForAPI(data[fields.CURRENT_USAGE_EXISTING_RENT_PER_SQM]),
      residential_rent_per_sqm: formatDecimalForAPI(
        data[fields.CURRENT_USAGE_RESIDENTIAL_RENT_PER_SQM],
      ),
    },
  },
  future: {
    building: {
      commercial_rent_per_sqm: formatDecimalForAPI(
        data[fields.FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM],
      ),
      comparative_value_per_sqm: formatDecimalForAPI(
        data[fields.FUTURE_USAGE_MARKET_VALUE_PER_SQM_BUILDING],
      ),
      existing_rent_per_sqm: formatDecimalForAPI(data[fields.FUTURE_USAGE_EXISTING_RENT_PER_SQM]),
      residential_rent_per_sqm: formatDecimalForAPI(
        data[fields.FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM],
      ),
    },
    unit: {
      commercial_rent_per_sqm: formatDecimalForAPI(
        data[fields.FUTURE_USAGE_COMMERCIAL_RENT_PER_SQM],
      ),
      comparative_value_per_sqm: formatDecimalForAPI(
        data[fields.FUTURE_USAGE_MARKET_VALUE_PER_SQM_UNIT],
      ),
      existing_rent_per_sqm: formatDecimalForAPI(data[fields.FUTURE_USAGE_EXISTING_RENT_PER_SQM]),
      residential_rent_per_sqm: formatDecimalForAPI(
        data[fields.FUTURE_USAGE_RESIDENTIAL_RENT_PER_SQM],
      ),
    },
  },
});

export default mapMarketValueToApi;
