import styled from 'styled-components/macro';

import { borderRadius, transition, zIndex } from 'theme/themeConstants';

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  position: relative;
  width: 100%;
  height: 3.5rem;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-radius: ${borderRadius.default};
  padding: 0.5rem 3.25rem 0.5rem 0.625rem;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.colors.background.white};
  transition: border ${transition.default};

  &:focus,
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledSearchIcon = styled.span`
  display: flex;
  position: absolute;
  z-index: ${zIndex.above};
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
`;
