import React from 'react';

import { ViewOffIcon as ChakraViewOffIcon } from '@chakra-ui/icons';
import { IconProps } from '@chakra-ui/react';

const ViewOffIcon = (props: IconProps) => {
  return (
    <ChakraViewOffIcon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props} />
  );
};

export default ViewOffIcon;
