import styled from 'styled-components/macro';

import { StyledFileBlock } from 'components/FileBlock/styles';
import {
  StyledUploaderPlaceholder,
  StyledUploaderContent,
} from 'components/UploadBlock/UploadedFileBlock/styles';
import { transition, zIndex } from 'theme/themeConstants';

export const StyledIdleStateWrapper = styled.div`
  height: 22.5rem;

  &::before {
    content: '';
    opacity: 0;
    position: absolute;
    z-index: ${zIndex.default};
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    border: 2px dotted ${({ theme }) => theme.colors.primary};
    transform: scale(1.2);
    transition: opacity ${transition.default}, transform ${transition.default};
  }

  &.dropping {
    &::before {
      opacity: 1;
      transform: scale(1);
    }

    ${StyledFileBlock} {
      > * {
        opacity: 0;
      }
    }

    ${StyledUploaderContent} {
      opacity: 0;
    }

    ${StyledUploaderPlaceholder} {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) scale(1);
    }
  }
`;
