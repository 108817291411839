import { useEffect, useRef } from 'react';

import { ConfigController } from 'config/ConfigController';
import { useForceRender } from 'shared/hooks/useForceRender';

import { FeatureFlagsRecord, FeatureFlagName, IFeatureFlagsRepo } from './types';

export function useFeatureFlags(): FeatureFlagsRecord;
// eslint-disable-next-line no-redeclare
export function useFeatureFlags(flagName: FeatureFlagName): boolean;
// eslint-disable-next-line no-redeclare
export function useFeatureFlags(flagName?: FeatureFlagName): FeatureFlagsRecord | boolean {
  const featureFlagsRepo = useRef<IFeatureFlagsRepo>(ConfigController.featureFlags);
  const forceRender = useForceRender();

  useEffect(() => {
    const subscription = ConfigController.featureFlags.subscribe(forceRender);
    return () => ConfigController.featureFlags.unsubscribe(subscription);
  }, [forceRender]);

  if (flagName) {
    return featureFlagsRepo.current.getFlagByName(flagName);
  } else {
    return featureFlagsRepo.current.getFlags();
  }
}
