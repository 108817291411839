import React from 'react';

import { Box } from '@chakra-ui/react';

import { borderRadius } from 'theme/themeConstants';

interface NoticeProps {
  text: string;
}

function Notice({ text }: NoticeProps) {
  return (
    <Box
      backgroundColor={'brand.default_L93'}
      padding={'1rem'}
      color={'text.secondary'}
      borderRadius={borderRadius.default}
      outline={'2px solid'}
      outlineColor={'brand.default_L83'}
      whiteSpace={'pre-line'}
    >
      {text}
    </Box>
  );
}

export default Notice;
