import { concatenateKeys } from 'new/form/common/concatenateKeys';
import { getKeyObject, PropertiesToString, SecondLevelKeys } from 'new/form/common/types';
import {
  BeneficialOwner,
  beneficialOwnerFields,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import {
  DzbLegalRepresentative,
  dzbLegalRepresentativeFields,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { StringBoolean } from 'types/General';

export type TypeOfFinancialStatements = 'balance' | 'income_surplus_account' | undefined;
export type FormOfLeadershipExperience = 'practical' | 'theoretical' | undefined;

type FieldTypes = {
  companyDataPage: {
    associationInformationSection: {
      name: string | undefined;
      contactPerson: string | undefined;
      totalPurchaseOfGoods: number | undefined;
      annualSalesForecast: number | undefined;
      contractDate: Date | undefined;
    };
    companyInformationSection: {
      companyName: string | undefined;
      address: string | undefined;
      zipCode: string | undefined;
      city: string | undefined;
      country: string | undefined;
      salesTaxID: string | undefined;
      typeOfFinancialStatements: TypeOfFinancialStatements;
      lastAnnualFinancialStatementReportingDate: Date | undefined;
      companyOlderThan3Years: boolean | undefined;
    };
    directDebitMandateSection: {
      iban: string | undefined;
      bic: string | undefined;
      bankName: string | undefined;
    };
    startUpQuestionnaireSection: {
      industryExperienceYears: string | undefined;
      education: string | undefined;
      professionalCareer: string | undefined;
      alreadyGainedLeadershipExperience: boolean | undefined;
      leadershipExperienceYears: string | undefined;
      formOfLeadershipExperience: FormOfLeadershipExperience;
    };
  };
  retailerDataPage: {
    retailerInformationSection: {
      numberOfStores: number | undefined;
      retailSpace: number | undefined;
      numberOfEmployees: number | undefined;
      enterpriseResourcePlanningSystemInUse: boolean | undefined;
      erpProvider: string | undefined;
    };
    onlineQuestionnaireSection: {
      shareOfOnlineSalesPercentage: number | undefined;
      averageShoppingBasketAmount: number | undefined;
      returnRatePercentage: number | undefined;
      ownOnlineShopPercentage: number | undefined;
      amazonPercentage: number | undefined;
      ebayPercentage: number | undefined;
      zalandoPercentage: number | undefined;
      othersPercentage: number | undefined;
      shareOfDistributionChannels: number | undefined;
      ownWarehousePercentage: number | undefined;
      foreignWarehousePercentage: number | undefined;
      shareOfWarehouse: number | undefined;
      foreignLogisticsServiceProviderPercentage: number | undefined;
      foreignFulfillmentByAmazonPercentage: number | undefined;
      shareOfForeignLogistics: number | undefined;
    };
  };
  personalDataPage: {
    contactPersonSection: {
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
      phoneNumber: string | undefined;
    };
    legalRepresentativesSection: {
      legalRepresentatives: Array<DzbLegalRepresentative>;
    };
    beneficialOwnersSection: {
      allInformationAvailable: StringBoolean | undefined;
      beneficialOwners: Array<BeneficialOwner>;
    };
  };
};

export type DzbFieldTypes = FieldTypes;
type DzbStringFieldTypes = PropertiesToString<FieldTypes>;

export const dzbFieldsWithoutParents: DzbStringFieldTypes = {
  companyDataPage: {
    associationInformationSection: {
      name: 'name',
      contactPerson: 'contactPerson',
      totalPurchaseOfGoods: 'totalPurchaseOfGoods',
      annualSalesForecast: 'annualSalesForecast',
      contractDate: 'contractDate',
    },
    companyInformationSection: {
      companyName: 'companyName',
      address: 'address',
      zipCode: 'zipCode',
      city: 'city',
      country: 'country',
      salesTaxID: 'salesTaxID',
      typeOfFinancialStatements: 'typeOfFinancialStatements',
      lastAnnualFinancialStatementReportingDate: 'lastAnnualFinancialStatementReportingDate',
      companyOlderThan3Years: 'companyOlderThan3Years',
    },
    directDebitMandateSection: {
      iban: 'iban',
      bic: 'bic',
      bankName: 'bankName',
    },
    startUpQuestionnaireSection: {
      industryExperienceYears: 'industryExperienceYears',
      education: 'education',
      professionalCareer: 'professionalCareer',
      alreadyGainedLeadershipExperience: 'alreadyGainedLeadershipExperience',
      leadershipExperienceYears: 'leadershipExperienceYears',
      formOfLeadershipExperience: 'formOfLeadershipExperience',
    },
  },
  retailerDataPage: {
    retailerInformationSection: {
      numberOfEmployees: 'numberOfEmployees',
      enterpriseResourcePlanningSystemInUse: 'enterpriseResourcePlanningSystemInUse',
      erpProvider: 'erpProvider',
      numberOfStores: 'numberOfStores',
      retailSpace: 'retailSpace',
    },
    onlineQuestionnaireSection: {
      shareOfOnlineSalesPercentage: 'shareOfOnlineSalesPercentage',
      averageShoppingBasketAmount: 'averageShoppingBasketAmount',
      returnRatePercentage: 'returnRatePercentage',
      ownOnlineShopPercentage: 'ownOnlineShopPercentage',
      amazonPercentage: 'amazonPercentage',
      ebayPercentage: 'ebayPercentage',
      zalandoPercentage: 'zalandoPercentage',
      othersPercentage: 'othersPercentage',
      shareOfDistributionChannels: 'shareOfDistributionChannels',
      ownWarehousePercentage: 'ownWarehousePercentage',
      foreignWarehousePercentage: 'foreignWarehousePercentage',
      foreignLogisticsServiceProviderPercentage: 'foreignLogisticsServiceProviderPercentage',
      shareOfWarehouse: 'shareOfWarehouse',
      foreignFulfillmentByAmazonPercentage: 'foreignFullfilmentByAmazonPercentage',
      shareOfForeignLogistics: 'shareOfForeignLogistics',
    },
  },
  personalDataPage: {
    contactPersonSection: {
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phoneNumber: 'phoneNumber',
    },
    legalRepresentativesSection: {
      legalRepresentatives: dzbLegalRepresentativeFields,
    },
    beneficialOwnersSection: {
      allInformationAvailable: 'allInformationAvailable',
      beneficialOwners: beneficialOwnerFields,
    },
  },
};

export const dzbFields: DzbStringFieldTypes = concatenateKeys(dzbFieldsWithoutParents);
export const dzbFieldsWithSectionString = getKeyObject<DzbFieldTypes>(dzbFieldsWithoutParents);

export type DzbPages = keyof DzbFieldTypes;
export type DzbSections = SecondLevelKeys<typeof dzbFieldsWithoutParents>;
