import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledFormRow } from './styles';
import { useCompanyCountries } from './useCompanyCountries';

const CompanyCountryFilter = () => {
  const fields = translations.pages.inquiryList.filtersDrawer.fields;
  const t = useTranslations();
  const { optional } = useFieldValidators();
  const { countriesOptions } = useCompanyCountries();

  return (
    <StyledFormRow>
      <SelectWithField
        name={INQUIRY_FILTERS.COMPANY_COUNTRY}
        validate={optional}
        caption={t(fields.country.caption)}
        placeholder={t('placeholders.pleaseChoose')}
        options={countriesOptions}
        isClearable
        data-testid={'select-field-' + INQUIRY_FILTERS.COMPANY_COUNTRY}
      />
    </StyledFormRow>
  );
};

export default CompanyCountryFilter;
