import React from 'react';

import paths from 'constants/paths';
import NavigationLink from 'modules/Header/NavigationLink';
import PortalLogoutBtn from 'modules/Header/PortalLogoutBtn';
import NewInquiryButton from 'modules/Header/PortalNavigation/NewInquiryButton';
import {
  StyledNavElement as Element,
  StyledNavList as List,
} from 'modules/Header/PortalNavigation/styles';
import { COMPANIES_LIST, INQUIRY_LIST, USER_PROFILE } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

import useShowCompanyDetails from './useShowCompanyDetails';
import useShowNewInquiryButton from './useShowNewInquiryButton';

const OperationNavigation = () => {
  const t = useTranslations('components.header');

  const showNewInquiryButton = useShowNewInquiryButton();
  const showCompanyDetailLink = useShowCompanyDetails();

  return (
    <List>
      {showNewInquiryButton && (
        <Element>
          <NewInquiryButton />
        </Element>
      )}
      <Element data-testid={INQUIRY_LIST}>
        <NavigationLink url={paths.operation.inquiryList} label={t('inquiries')} />
      </Element>
      {showCompanyDetailLink && (
        <Element data-testid={COMPANIES_LIST}>
          <NavigationLink url={paths.operation.companies.root} label={t('companies')} />
        </Element>
      )}
      <Element data-testid={USER_PROFILE}>
        <NavigationLink url={paths.operation.userData} label={t('userProfile')} />
      </Element>
      <Element>
        <PortalLogoutBtn />
      </Element>
    </List>
  );
};

export default OperationNavigation;
