/**
 * Updates an existing record with new keys, each of which will be assigned the same value.
 *
 * @param record - The record to update. This record will not be modified; a new record will be returned.
 * @param entries - An array of entries to add to the record. Each entry should be a tuple where the first element is an array of keys and the second element is the value to be assigned to each key.
 *
 * If a key in the entries array already exists in the record, the new value will overwrite the old one.
 *
 * @returns A new record that includes all the key-value pairs from the original record plus the new ones.
 *
 * @example
 *
 * let myRecord: Record<string, string> = {
 *   "test": "it"
 * };
 *
 * myRecord = setMultipleKeys<string>(myRecord, [[['test2', 'test3'], 'it2']]);
 * console.log(myRecord); // { test: 'it', test2: 'it2', test3: 'it2' }
 */
type RecordType<R> = Record<string, R>;

export function setMultipleKeys<RecordValue>(
  record: RecordType<RecordValue>,
  entries: [string[], RecordValue][],
): RecordType<RecordValue> {
  const result: RecordType<RecordValue> = { ...record };
  for (let [keys, value] of entries) {
    for (let key of keys) {
      result[key] = value;
    }
  }
  return result;
}
