import paths from 'constants/paths';
import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { RouteToPageNameMap } from 'new/form/model';

export const dzbRouteToPageNameMap: RouteToPageNameMap<DzbFieldTypes> = {
  companyDataPage: {
    create: paths.financingNeed,
    editOperation: paths.operation.inquiryDetails.edit.financingNeed,
    editCustomer: paths.customer.inquiryDetails.edit.financingNeed,
  },
  retailerDataPage: {
    create: paths.companyDetails,
    editOperation: paths.operation.inquiryDetails.edit.companyDetails,
    editCustomer: paths.customer.inquiryDetails.edit.companyDetails,
  },
  personalDataPage: {
    create: paths.contactPerson,
    editOperation: paths.operation.inquiryDetails.edit.contactPersonDetails,
    editCustomer: paths.customer.inquiryDetails.edit.contactPersonDetails,
  },
};
