import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const DownloadIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M20 19H4a1 1 0 000 2h16a1 1 0 000-2zM11.29 16.26a1 1 0 001.41 0l4.5-4.5a1 1 0 00-1.41-1.41L13 13.14V3a1 1 0 00-2 0v10.15l-2.79-2.79a1 1 0 00-1.41 1.41z" />
    </Icon>
  );
};

export default DownloadIcon;
