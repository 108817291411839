import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import paths from 'constants/paths';
import useInquiryReset from 'modules/InquiryFlowReset/useInquiryReset';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

const InquiryCancelEdit = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isReset } = useInquiryReset(true);

  return isReset ? (
    <Redirect to={paths.customer.inquiryDetails.details.replace(':id', inquiryId)} />
  ) : null;
};

export default InquiryCancelEdit;
