import React from 'react';

import { StyledFixedOptionBar } from 'components/FixedSidebar/styles';
import ContactSidebar from 'modules/ContactSidebar';
import RemoveInquiryDraftButton from 'modules/Inquiry/DraftMode/Sidebar/RemoveInquiryDraftButton';
import SaveDraftModeButton from 'modules/Inquiry/DraftMode/Sidebar/SaveInquiryDraftButton';

const FixedSidebar: React.FC = () => {
  return (
    <StyledFixedOptionBar>
      <ContactSidebar />
      <SaveDraftModeButton />
      <RemoveInquiryDraftButton />
    </StyledFixedOptionBar>
  );
};

export default FixedSidebar;
