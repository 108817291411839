import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { StepData } from 'new/form/model';

const companyData = translations.inquiryType.dzb.pages.companyData;
const retailerData = translations.inquiryType.dzb.pages.retailerData;
const personalData = translations.inquiryType.dzb.pages.personalData;

export const dzbStepData: StepData<DzbFieldTypes> = {
  companyDataPage: {
    progressBarTitle: companyData.progressBarTitle,
    nextStep: 'retailerDataPage',
    sectionTitles: {
      associationInformationSection: companyData.sections.associationInformation.title,
      companyInformationSection: companyData.sections.companyInformation.title,
      directDebitMandateSection: companyData.sections.directDebitMandate.title,
      startUpQuestionnaireSection: companyData.sections.startUpQuestionnaire.title,
    },
  },
  retailerDataPage: {
    progressBarTitle: translations.inquiryType.dzb.pages.retailerData.progressBarTitle,
    previousStep: 'companyDataPage',
    nextStep: 'personalDataPage',
    sectionTitles: {
      retailerInformationSection: retailerData.sections.retailerInformation.title,
      onlineQuestionnaireSection: retailerData.sections.onlineQuestionnaire.title,
    },
  },
  personalDataPage: {
    progressBarTitle: translations.inquiryType.dzb.pages.personalData.progressBarTitle,
    previousStep: 'retailerDataPage',
    sectionTitles: {
      contactPersonSection: personalData.sections.contactPerson.title,
      legalRepresentativesSection: personalData.sections.legalRepresentatives.title,
      beneficialOwnersSection: personalData.sections.beneficialOwners.title,
    },
  },
};
