import { PropertiesToString } from 'new/form/common/types';
import { AssociatedPersonSource } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

export type BeneficialOwner = {
  id: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  nationality: string | undefined;
  capitalOrVotingRightsPercentage: number | undefined;
  placeOfResidence: string | undefined;
  countryOfResidence: string | undefined;
  birthPlace: string | undefined;
  birthDate: Date | undefined;
  fictional: boolean | undefined;
};

export type PortalBeneficialOwner = BeneficialOwner & {
  source: AssociatedPersonSource;
  potentiallyManipulated: boolean;
  scope: string;
};

export const beneficialOwnerFields: PropertiesToString<BeneficialOwner> = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  nationality: 'nationality',
  capitalOrVotingRightsPercentage: 'capitalOrVotingRightsPercentage',
  placeOfResidence: 'placeOfResidence',
  countryOfResidence: 'countryOfResidence',
  birthPlace: 'birthPlace',
  birthDate: 'birthDate',
  fictional: 'fictional',
};
