import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

export const useScrollToTop = () => {
  const {
    location: { pathname },
    action,
  } = useHistory();

  useEffect(() => {
    if (action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);
};
