import { get as _get } from 'lodash';
import { useForm, useFormState } from 'react-final-form';

import { ConditionFunction, ConditionsType } from 'new/form/model';
import { parseStringBoolean } from 'utils/helpers';

import { HausbankFieldTypes, hausbankFields } from '../fields';

const {
  objectInformationPage: {
    projectInformationSection: { isContractor, majorityOwnerShares },
  },
} = hausbankFields;

type HausbankConditionFunction = ConditionFunction<HausbankFieldTypes>;

const hasOwnerWithMoreThanThirdOfShares: HausbankConditionFunction = ({ values, form }) => {
  const {
    objectInformationPage: {
      projectInformationSection: { hasOwnerWithMoreThanThirdOfShares },
    },
  } = values;

  return parseStringBoolean(hasOwnerWithMoreThanThirdOfShares);
};

const majorityOwnerIsContractor: HausbankConditionFunction = ({ values, form }) => {
  return parseStringBoolean(_get(values, isContractor));
};

export const hausbankConditions: ConditionsType<HausbankFieldTypes> = {
  [isContractor]: hasOwnerWithMoreThanThirdOfShares,
  showContractorNotice: majorityOwnerIsContractor,
  [majorityOwnerShares]: hasOwnerWithMoreThanThirdOfShares,
};

export const useHausbankConditions = () => {
  const { values } = useFormState<HausbankFieldTypes>();
  const form = useForm();

  const conditionValues = {} as Record<string, boolean>;
  Object.entries(hausbankConditions).forEach(([fieldName, conditionFunction]) => {
    if (conditionFunction) {
      conditionValues[fieldName] = conditionFunction({ values, form });
    } else {
      conditionValues[fieldName] = true;
    }
  });

  return conditionValues;
};
