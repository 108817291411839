import React from 'react';

import { Divider } from '@chakra-ui/react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FileRequestsFromBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileRequestsFromBank/FileRequestsFromBank';

import {
  useCustomerDocumentExchange,
  useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket,
} from './useCustomerDocumentExchange';
import FilesFromBank from '../FilesFromBank/FilesFromBank';
import { MultipleFilesToBank } from '../MultipleFilesToBank/MultipleFilesToBank';
import { HausbankContractFiles } from '../ProcessSpecificFiles/HausbankContractFiles';

export const HausbankCustomerDocumentExchange = () => {
  const { isLoading } = useCustomerDocumentExchange();
  useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <article>
      <InquiryDetailsHeader />
      <Divider opacity="1" my={12} borderColor="border.lightGrey" />
      <HausbankContractFiles />
      <FilesFromBank />
      <FileRequestsFromBank />
      <MultipleFilesToBank />
    </article>
  );
};
