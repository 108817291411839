import { useField } from 'react-final-form';

import { translations } from 'new/form/common/types';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, maxYear, yearGreaterThan } from 'utils/validators';

export const useYearValidation = (yearField: string, yearOfModernizationField: string) => {
  const t = useTranslations();
  const maxBuildingYear = InquiryConstants.MAX_BUILDING_YEAR;
  const {
    input: { value: yearValue },
    input: { onChange: onYearChange },
  } = useField(yearField);
  const {
    input: { value: yearOfModernizationValue },
    input: { onChange: onYearOfModernizationChange },
  } = useField(yearOfModernizationField);

  const handleYearChange = (newValue: number) => {
    onYearChange(newValue);
    onYearOfModernizationChange(yearOfModernizationValue);
  };

  const validateYearOfModernization = (value: number) => {
    if (!value) return undefined;
    return combineValidators(
      yearGreaterThan(yearValue, t(translations.errors.modernizationAfterYear)),
      maxYear(maxBuildingYear, t(translations.errors.isMaxYear, { year: maxBuildingYear })),
    )(value);
  };

  return {
    handleYearChange,
    validateYearOfModernization,
  };
};
