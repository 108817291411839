import { get as _get } from 'lodash';

import { hausbankFields } from 'hausbank/inquiry/fields';
import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { ValidateSectionFunction, validateFields } from 'new/form/useValidateFields';
import { isLoggedInUser } from 'utils/user/conditionals';

const {
  selectedCompanyName: selectedCompanyNameFieldName,
  loggedInUserCompanyName: loggedInUserCompanyNameFieldName,
} = hausbankFields.companyDataPage.wegSearchSection;

export const validateWegSerchSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  const isLoggedIn = isLoggedInUser();
  const loggedInUserCompanyName = _get(values, loggedInUserCompanyNameFieldName);
  const selectedCompanyName = _get(values, selectedCompanyNameFieldName);

  if (!isLoggedIn && selectedCompanyName !== COMPANY_DETAILS_COMPANY__NOT_FOUND) {
    return validateFields({
      fields: [selectedCompanyNameFieldName],
      values,
      form,
      conditions,
    });
  } else if (
    isLoggedIn &&
    loggedInUserCompanyName !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  ) {
    return validateFields({
      fields: [loggedInUserCompanyNameFieldName],
      values,
      form,
      conditions,
    });
  } else {
    return true;
  }
};

export const wegSearchSectionValidationMap = {
  [selectedCompanyNameFieldName]: fieldValidators.string().required(),
  [loggedInUserCompanyNameFieldName]: fieldValidators.string().required(),
};
