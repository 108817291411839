import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['title', 'lead']);

const CustomerLoginHeading = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    primary: {
      title: {
        color: 'brand.default',
        fontSize: 'xl',
        mb: 4,
      },
      lead: {
        color: 'text.tertiary',
        mb: 16,
      },
    },
    caption: {
      title: {
        fontSize: 'md',
        color: 'brand.default',
        mb: 8,
        fontWeight: 400,
      },
      lead: {
        display: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'caption',
  },
});

export default CustomerLoginHeading;
