import React from 'react';

import FormSection from 'components/FormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldsAreValid, fieldHasValue, all } from 'modules/Inquiry/Form/formConditions';
import {
  CORONA_REVENUE_OF_2019,
  CORONA_VIABILTIY,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_FINANCING_OPTION,
  CORONA_FOUNDING_YEAR,
  CORONA_FOUNDING_MONTH,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
} from 'modules/Inquiry/Form/formFields';
import CoronaDescription from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/CoronaDescription';
import OtherKfwProgram, {
  validateOtherKfwProgramSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/OtherKfwProgram';
import QuickCreditSection, {
  validateQuickCreditSection,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/QuickCreditSection';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const shouldShowOtherKfwProgram = ({ form }: any) => {
  const formValues = form.getState().values;

  return (
    fieldBusinessConditions.hasSelectedOtherFinancingOption({ formValues }) &&
    fieldsAreValid([
      CORONA_FINANCING_OPTION,
      CORONA_FOUNDING_YEAR,
      CORONA_FOUNDING_MONTH,
      CORONA_REVENUE_OF_2019,
      CORONA_VIABILTIY,
      CORONA_NUMBER_OF_EMPLOYEES,
    ])({ form })
  );
};

const shouldShowOtherDescription = ({ form }: any): boolean => validateOtherKfwProgramSection(form);

const shouldShowOtherDescriptionQuickcredit = all(
  fieldHasValue(CORONA_FINANCING_OPTION, CORONA_FINANCING_OPTION__QUICK_CREDIT),
  ({ form }: any) => validateQuickCreditSection(form),
);

const CoronaSection = () => {
  const t = useTranslations();
  return (
    <>
      <QuickCreditSection />

      <Condition condition={shouldShowOtherDescriptionQuickcredit}>
        <FormSection
          title={t('pages.financingNeed.sections.other.fields.otherPurposeDescription.caption')}
          name={FormSections.coronaDescription}
          sectionNumber={3}
        >
          <CoronaDescription />
        </FormSection>
      </Condition>

      <Condition condition={shouldShowOtherKfwProgram}>
        <OtherKfwProgram />

        <Condition condition={shouldShowOtherDescription}>
          <FormSection
            title={t('pages.financingNeed.sections.other.fields.otherPurposeDescription.caption')}
            name={FormSections.coronaDescription}
            sectionNumber={4}
          >
            <CoronaDescription />
          </FormSection>
        </Condition>
      </Condition>
    </>
  );
};

export default CoronaSection;
