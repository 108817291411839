import React from 'react';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import { COMPANY_DETAILS_CONSENT } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';

import { TermsAndConditionsField } from '../../../../ui/contactPerson/termsAndConditionsField';

type Props = {
  sectionNumber?: number;
};

const DisplayConditions = ({ sectionNumber }: Props) => {
  return (
    <InquiryTypeCondition
      cases={{
        [InquiryType.bfs]: null,
        [InquiryType.default]: <TermsAndConditionsField name={COMPANY_DETAILS_CONSENT} />,
      }}
    />
  );
};

export default DisplayConditions;
