import React, { useCallback } from 'react';

import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import {
  RevisionNewModification,
  RevisionAttributeType,
  RevisionModification,
  RevisionRemoveModification,
  RevisionChangeModification,
  RevisionModificationType,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import InquiryRevisionValue from 'modules/InquiryDetails/InquiryRevision/InquiryRevisionValue';
import {
  StyledValueComparision,
  StyledNewValue,
  StyledRevisionChange,
  StyledOldValue,
} from 'modules/InquiryDetails/InquiryRevision/styles';
import { INQUIRY_ATTRIBUTES } from 'shared/inquiry/inquiryFieldsTranslations/inquiryAttributesTranslations/InquiryAttributes.model';
import { useInquiryAttributesTranslations } from 'shared/inquiry/inquiryFieldsTranslations/inquiryAttributesTranslations/inquiryAttributesLabels';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';

const useTranslateModificationLabel = () => {
  const labelTranslator = useInquiryLabelTranslator();
  const attrTranslator = useInquiryAttributesTranslations();
  return useCallback(
    (modification: RevisionModification) => {
      return modification.attributeType === RevisionAttributeType.attribute
        ? attrTranslator(modification.attribute as INQUIRY_ATTRIBUTES) || modification.attribute
        : labelTranslator(modification.attribute) || modification.attribute;
    },
    [attrTranslator, labelTranslator],
  );
};

const AddModification = ({ modification }: { modification: RevisionNewModification }) => {
  const translateLabel = useTranslateModificationLabel();
  const { current, attribute } = modification;
  return (
    <StyledRevisionChange
      label={translateLabel(modification)}
      value={
        <StyledValueComparision>
          <StyledNewValue>
            <InquiryRevisionValue value={current} attribute={attribute} />
          </StyledNewValue>
        </StyledValueComparision>
      }
    />
  );
};

const RemoveModification = ({ modification }: { modification: RevisionRemoveModification }) => {
  const translateLabel = useTranslateModificationLabel();
  const { previous, attribute } = modification;
  return (
    <StyledRevisionChange
      label={translateLabel(modification)}
      value={
        <StyledValueComparision>
          <StyledOldValue>
            <InquiryRevisionValue value={previous} attribute={attribute} />
          </StyledOldValue>
        </StyledValueComparision>
      }
    />
  );
};

const ChangeModification = ({ modification }: { modification: RevisionChangeModification }) => {
  const translateLabel = useTranslateModificationLabel();
  const { current, previous, attribute } = modification;
  return (
    <StyledRevisionChange
      label={translateLabel(modification)}
      value={
        <StyledValueComparision>
          <StyledOldValue>
            <InquiryRevisionValue value={previous} attribute={attribute} />
          </StyledOldValue>
          <ArrowRightIcon boxSize={8} />
          <StyledNewValue>
            <InquiryRevisionValue value={current} attribute={attribute} />
          </StyledNewValue>
        </StyledValueComparision>
      }
    />
  );
};

const RevisionSingleModification = ({ modification }: { modification: RevisionModification }) => {
  const typeMap = {
    [RevisionModificationType.add]: (
      <AddModification modification={modification as RevisionNewModification} />
    ),
    [RevisionModificationType.remove]: (
      <RemoveModification modification={modification as RevisionRemoveModification} />
    ),
    [RevisionModificationType.change]: (
      <ChangeModification modification={modification as RevisionChangeModification} />
    ),
  };

  return typeMap[modification.type] || null;
};

export default RevisionSingleModification;
