import React from 'react';

import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ObjectInformationSection } from './sections/objectInformationSection';
import { OtherProjectInformationSection } from './sections/otherProjectInformationSection';
import { ProjectCompositionSection } from './sections/projectCompositionSection';
import { RoleSection } from './sections/roleSection';

export const MittweidaFinancingNeedStep = () => {
  const t = useTranslations();
  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.mittweida.pages.financingNeed;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <RoleSection />
      <ProjectCompositionSection />
      <ObjectInformationSection />
      <OtherProjectInformationSection />
    </Page>
  );
};
