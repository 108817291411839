import React from 'react';

import BfsServiceBusinessSection from './BfsServiceBusinessSection';
import FactoringNeedSection from './FactoringNeedSection';
import OtherSection from '../OtherSection';

const BfsServiceDetailSection = () => (
  <>
    <FactoringNeedSection />
    <BfsServiceBusinessSection />
    <OtherSection />
  </>
);

export default BfsServiceDetailSection;
