import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const SuccessIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 3.9a8.1 8.1 0 108.1 8.1A8.12 8.12 0 0012 3.9M12 22a10 10 0 1110-10 10 10 0 01-10 10" />
      <path d="M11.48 15.38a1 1 0 01-.67-.28l-2.43-2.43a1 1 0 010-1.34 1 1 0 011.35 0l1.75 1.75L15.61 9A1 1 0 0117 10.29l-4.85 4.81a1 1 0 01-.67.28" />
    </Icon>
  );
};

export default SuccessIcon;
