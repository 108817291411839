import React from 'react';

import { useSelector } from 'react-redux';

import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { LegalRepresentative } from 'pages/operationPortal/InquiryDetails/Dashboard/LegalRepresentativeCorrection/types';
import { getLegalRepresentatives } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../DetailedInformationSection';
import InformationRows from '../InformationRows/InformationRows';
import { useInquiryValueParserForDisplay } from '../useInquiryDetailsFields';

interface IMappedLegalRepresentative {
  [REPRESENTATIVE_GENDER]: string;
  [REPRESENTATIVE_FIRST_NAME]: string;
  [REPRESENTATIVE_LAST_NAME]: string;
  [REPRESENTATIVE_EMAIL]: string;
  [REPRESENTATIVE_PHONE_NUMBER]: string;
  [REPRESENTATIVE_BIRTHDATE]: string | Date;
}

const mapLegalRepresentativesToFieldLabels = (
  legalRep: Omit<LegalRepresentative, 'isModified' | 'isComplete' | 'isCustomer'>,
) => ({
  [REPRESENTATIVE_GENDER]: legalRep.salutation,
  [REPRESENTATIVE_FIRST_NAME]: legalRep.firstName,
  [REPRESENTATIVE_LAST_NAME]: legalRep.lastName,
  [REPRESENTATIVE_EMAIL]: legalRep.email,
  [REPRESENTATIVE_PHONE_NUMBER]: legalRep.phoneNumber,
  [REPRESENTATIVE_BIRTHDATE]: legalRep.birthDate,
});

const LegalRepresentativesSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');

  const legalReps = useSelector(getLegalRepresentatives);
  const labelTranslator = useInquiryLabelTranslator();
  const formatValue = useInquiryValueParserForDisplay();

  if (!legalReps || legalReps.length < 1) {
    return null;
  }

  const fieldsToShow = Object.values(legalReps || {}).map((legalRepresentative) => {
    const mappedLegalRepresentative: IMappedLegalRepresentative =
      mapLegalRepresentativesToFieldLabels(legalRepresentative);
    return Object.entries(mappedLegalRepresentative)
      .filter(([, fieldValue]) => fieldValue != null)
      .map(([fieldKey, fieldValue]) => ({
        label: labelTranslator(fieldKey),
        value: formatValue(fieldValue, fieldKey),
        key: fieldKey,
      }));
  });

  return (
    <Section title={t('legalRepresentativeDetails')}>
      {fieldsToShow.map((fields) => (
        <InformationRows key={fields[0].key} fields={fields} />
      ))}
    </Section>
  );
};

export default LegalRepresentativesSection;
