import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const MailIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M19.94 4H4.06A3.06 3.06 0 001 7.06v9.88A3.06 3.06 0 004.06 20h15.88A3.06 3.06 0 0023 16.94V7.06A3.06 3.06 0 0019.94 4zM4.06 6h15.88A1.07 1.07 0 0121 7.06v1.26l-8.55 3.42a1.17 1.17 0 01-.9 0L3 8.32V7.06A1.07 1.07 0 014.06 6zm15.88 12H4.06A1.07 1.07 0 013 16.94v-6.46l7.81 3.12a3.19 3.19 0 002.38 0L21 10.48v6.46A1.07 1.07 0 0119.94 18z" />
    </Icon>
  );
};

export default MailIcon;
