import React from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import type { IPrivateFile } from 'models/File.model';
import type { ApiPrivateFile } from 'models/FileApi.model';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import { mapApiPrivateFile } from 'shared/documentExchange/documentExchange.service';
import { addPrivateFileAction } from 'store/documentExchange/documentExchange.actions';
import { getPrivateFiles } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const PrivateFilesForCustomer: React.FC = () => {
  const files: IPrivateFile[] = useSelector(getPrivateFiles);
  const inquiryId = useSelector(getInquiryIdSelector);
  const t = useTranslations('pages.inquiryDetails.documentExchange');

  const dispatch = useDispatch();

  const addFileToList = (file: ApiPrivateFile) =>
    dispatch(addPrivateFileAction(mapApiPrivateFile(file)));

  return (
    <section>
      <FileSectionTitle>{t('privateDocuments')}</FileSectionTitle>
      <Box mb={8}>
        {files.length ? (
          <MultipleFilesBlock
            title={t('filesInternal')}
            files={files}
            isFileUploadingEnabled
            isPrivateFile
          />
        ) : (
          <UploadAnyFile
            description={t('uploadOtherFilesDesc')}
            onUpload={addFileToList}
            url={endpoints.INQUIRIES.PRIVATE_FILES.LIST.compose({
              params: { id: String(inquiryId) },
            })}
            isPrivateFile
          />
        )}
      </Box>
    </section>
  );
};

export default PrivateFilesForCustomer;
