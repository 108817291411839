import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CaretDownIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M12 15a1 1 0 01-.71-.29l-4-4a1 1 0 111.42-1.42l3.29 3.3 3.29-3.3a1 1 0 011.42 1.42l-4 4A1 1 0 0112 15z" />
    </Icon>
  );
};

export default CaretDownIcon;
