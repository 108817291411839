import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import type { IMessageEvent } from 'websocket';

import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';
import {
  useFetchInquiryFiles,
  useFetchInquiryInternalFiles,
  useFetchPrivateUpdatedFile,
  useFetchUpdatedFile,
} from 'shared/documentExchange/useFetchInquiryFiles';
import { useToasts } from 'shared/hooks/useToasts';
import {
  Channel,
  PrivateUploadedFilesMessage,
  UploadedFilesMessage,
  useWebSocket,
  WebSocketMessage,
} from 'shared/useWebsocket';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useOperationDocumentExchange = () => {
  const { isLoading: isFilesLoading } = useFetchInquiryFiles({});
  const { isLoading: isInternalFilesLoading } = useFetchInquiryInternalFiles();
  const { selectedLanguage } = useLanguages();
  const { isLoading: isFileRequestsLoading } = useFetchInquiryFileRequests({
    selectedLanguage,
  });

  return {
    isLoading: isFilesLoading || isFileRequestsLoading || isInternalFilesLoading,
  };
};

export const useOperationDocumentExchangeWebSocket = () => {
  const { fetchUpdatedFile } = useFetchUpdatedFile();
  const { fetchPrivateUpdatedFile } = useFetchPrivateUpdatedFile();
  const inquiryId = useSelector(getInquiryIdSelector);
  const { selectedLanguage } = useLanguages();
  const { error } = useToasts();
  const { fetchData: fetchFileRequests } = useFetchInquiryFileRequests({
    selectedLanguage,
  });
  const t = useTranslations();
  const handleWebsocketMessage = useCallback(
    (messageEvent: IMessageEvent) => {
      const message: WebSocketMessage = JSON.parse(messageEvent.data as string);
      if ('type' in message && ['welcome', 'ping', 'confirm_subscription'].includes(message.type))
        return;
      try {
        const {
          message: { data },
        } = message as UploadedFilesMessage | PrivateUploadedFilesMessage;
        switch (data.type) {
          case 'uploaded_files':
            fetchUpdatedFile(data.id);
            fetchFileRequests(inquiryId);
            return;
          case 'private_uploaded_files':
            fetchPrivateUpdatedFile(data.id);
            return;
          default:
            return;
        }
      } catch (err) {
        error({ description: t('errors.requestFailed') });
      }
    },
    [fetchUpdatedFile, fetchFileRequests, inquiryId, fetchPrivateUpdatedFile, error, t],
  );

  useWebSocket({
    channel: Channel.UPLOADABLE_FILE,
    params: {
      inquiry_id: inquiryId,
    },
    onMessage: handleWebsocketMessage,
  });
};
