import antrag from 'assets/specific-icons/file-attachment.svg';
import finanzinstitute from 'assets/specific-icons/spread.svg';

export const nextStepsList = [
  {
    icon: antrag,
    id: 'pages.finalPage.steps.step1',
  },

  {
    icon: finanzinstitute,
    id: 'pages.finalPage.steps.step2',
  },
];

export default nextStepsList;
