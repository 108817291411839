import React from 'react';

import { Grid } from '@chakra-ui/react';
import { WrappedComponentProps } from 'react-intl';
import { compose } from 'redux';

import PERMISSIONS from 'constants/user/permissions';
import disableInquiryDraft from 'modules/Inquiry/DraftMode/disableInquiryDraft';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { HorizontalSection } from 'theme/components/HorizontalSection';
import { withIntl } from 'utils/intl';

import ArchiveInquiry from './ArchiveInquiry';
import ArchiveUser from './ArchiveUser';

const Actions = ({ intl: { formatMessage } }: WrappedComponentProps) => {
  const sectionTitle = formatMessage({ id: 'pages.inquiryDetails.dashboard.closeInquiry.title' });

  return (
    <HorizontalSection label={sectionTitle}>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: '1fr 1fr' }} gap={12}>
        <ArchiveInquiry />
        <ArchiveUser />
      </Grid>
    </HorizontalSection>
  );
};

export default compose<React.ComponentType>(
  withIntl,
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.CLOSE_INQUIRY.DISPLAY),
  disableInquiryDraft,
)(Actions);
