import React from 'react';

import FormRow from 'components/FormRow';
import { PasswordInputWithField } from 'components/PasswordInput';
import { usePartnerSpecificPasswordValidators } from 'utils/hooks/usePartnerSpecificPasswordValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, equalTo, required } from 'utils/validators';

type Props = {
  passwordName: string;
  passwordConfirmationName: string;
};

export const PasswordFields = ({ passwordName, passwordConfirmationName }: Props) => {
  const tMain = useTranslations();
  const t = useTranslations('pages.userProfile.sections.completingContactData.fields');
  const passwordValidator = usePartnerSpecificPasswordValidators();

  return (
    <FormRow>
      <PasswordInputWithField
        name={passwordName}
        validate={passwordValidator}
        caption={t('userProfilePassword.caption')}
      />
      <PasswordInputWithField
        name={passwordConfirmationName}
        validate={combineValidators(
          required(tMain('errors.required')),
          equalTo(passwordName, t('userProfilePasswordRepeated.errors.equalTo')),
        )}
        caption={t('userProfilePasswordRepeated.caption')}
      />
    </FormRow>
  );
};
