import { useEffect, useState } from 'react';

import makeCancellableCallback from 'utils/makeCancellableCallback';

const DEFAULT_DELAY = 500;

export const useDebounceWithCallback = (value, delay = DEFAULT_DELAY) => {
  const [debouncedValue, setState] = useState(value);
  const { cancel, callback } = makeCancellableCallback(() => {
    setState(false);
  });

  const setValue = (newValue, block = false) => {
    if (block) cancel();
    setState(newValue);
  };

  const setValueDebounced = () => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  };

  return { debouncedValue, setValueDebounced, setValue };
};

export const useDebounce = (value, delay = DEFAULT_DELAY) => {
  const [debounced, setDebounced] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(value);
    }, delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debounced;
};

export const useDebounceWithSetter = (delay = DEFAULT_DELAY) => {
  const [debounced, setDebounced] = useState();
  const [notDebounced, setNonDebounce] = useState();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(notDebounced);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay, notDebounced]);

  return [debounced, setNonDebounce];
};
