import * as React from 'react';

import FormRow from 'components/FormRow';
import { INPUT_TYPE_DECIMAL, InputWithField } from 'components/Input';
import Unit from 'components/Unit/Unit';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { useIsPartitionOrStockholder, useShowRentalIntends } from 'mittweida/config/conditions';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { mittweidaFields } from '../../../../fields';

const {
  currentTotalRentResidential,
  currentTotalRentCommercial,
  vacancyResidential,
  vacancyCommercial,
  futureTotalRentResidential,
  futureTotalRentCommercial,
  rentalContracts,
  rentalIntends,
} = mittweidaFields.financingNeedPage.objectInformationSection;

export const RentalData = () => {
  const t = useTranslations();
  const isPartitionOrStockholder = useIsPartitionOrStockholder();
  const showRentalIntends = useShowRentalIntends();
  const {
    currentTotalRentResidential: currentTotalRentResidentialTranslations,
    currentTotalRentCommercial: currentTotalRentCommercialTranslations,
  } = translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields;

  return (
    <Condition validation={isPartitionOrStockholder}>
      <TextComponent>
        {t(
          translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation
            .subheadings.rentalIncome,
        )}
      </TextComponent>
      <FormRow>
        <AmountWithField
          name={currentTotalRentResidential}
          optional
          tooltip={t(currentTotalRentResidentialTranslations.tooltip)}
        />
        <AmountWithField
          name={currentTotalRentCommercial}
          optional
          tooltip={t(currentTotalRentCommercialTranslations.tooltip)}
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name={vacancyResidential}
          type={INPUT_TYPE_DECIMAL}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
          optional
        />
        <InputWithField
          name={vacancyCommercial}
          type={INPUT_TYPE_DECIMAL}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
          optional
        />
      </FormRow>
      <FormRow>
        <AmountWithField name={futureTotalRentResidential} optional />
        <AmountWithField name={futureTotalRentCommercial} optional />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={rentalContracts} />
      </FormRow>
      <Condition validation={showRentalIntends}>
        <YesNoRadioGroupWithField name={rentalIntends} />
      </Condition>
    </Condition>
  );
};
