import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import Condition from 'modules/ConditionalSubform/Condition';
import { not } from 'modules/Inquiry/Form/formConditions';
import { USER_PROFILE_LEGAL_FORM_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import ProfileIndustry from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import AdditionalAssociationCompany from './AdditionalAssociationCompany';
import AlreadyCustomer from './AlreadyCustomer/AlreadyCustomer';
import CompanyInformationForm from './CompanyInformationForm';
import ConsiderSubsidies from './ConsiderSubsidies';
import { ExcludingQuestions } from './ExcludingQuestions';
import FoundingYearQuestions from './FoundingYearQuestions';
import LegalForm from './LegalForm/LegalForm';
import TurnoverClass from './TurnoverClass/TurnoverClass';

export const CrefoCompletingCompanyDetails = () => {
  const t = useTranslations();

  return (
    <FormSection
      name={FormSections.companyDetails}
      sectionNumber={2}
      title={t('pages.userProfile.sections.completingCompanyDetails.title')}
    >
      <CompanyInformationForm />

      <FormRow>
        <LegalForm />
      </FormRow>

      <Condition condition={fieldBusinessConditions.hasSelectedOtherLegalForm} isBusinessCondition>
        <FormRow>
          <InputWithField name={USER_PROFILE_LEGAL_FORM_DESCRIPTION} />
        </FormRow>
      </Condition>

      <Condition condition={not(fieldBusinessConditions.hasKfinanzPurpose)} isBusinessCondition>
        <ProfileIndustry />
        <FormRow>
          <TurnoverClass />
        </FormRow>
      </Condition>

      <FoundingYearQuestions />

      <ExcludingQuestions />
      <Condition condition={not(fieldBusinessConditions.hasKfinanzPurpose)} isBusinessCondition>
        <FormRow>
          <AlreadyCustomer />
        </FormRow>
      </Condition>
      <FormRow>
        <AdditionalAssociationCompany />
      </FormRow>

      <ConsiderSubsidies />
    </FormSection>
  );
};
