import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { InputWithField } from 'components/Input';
import { BUILDING_BORROWED_CAPITAL, FINANCING_AMOUNT } from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';

const BorrowedCapital: React.FC = () => {
  const { change } = useForm();

  const buildingBorrowedCapital = useGetFieldNumericValue(BUILDING_BORROWED_CAPITAL);
  const financingAmount = useGetFieldNumericValue(FINANCING_AMOUNT);
  const borrowedCapital = buildingBorrowedCapital || financingAmount;

  useEffect(() => {
    change(FINANCING_AMOUNT, borrowedCapital);
  }, [borrowedCapital, change]);

  return <InputWithField type="number" name={BUILDING_BORROWED_CAPITAL} />;
};

export default BorrowedCapital;
