import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';

export const isNewCompanyModeOn = (form: any) =>
  fieldHasValue(
    COMPANY_DETAILS_EXISTING_USER_COMPANY,
    COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  )({ form });

export const shouldShowCompanySuggestionsSelect = ({
  isLoggedIn,
  form,
}: {
  isLoggedIn: boolean;
  form: any;
}) => !isLoggedIn || isNewCompanyModeOn(form);
