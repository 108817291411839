import React from 'react';

import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

export const FinancingDetailsHeader = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.financingDetails');

  return <PageHeading heading={t('heading')} />;
};
