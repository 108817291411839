import React, { useMemo } from 'react';

import { RLLComputedProfitabilityRealEstateExpertTopBoxes } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

import ProjectCostingBoxes from '../SharedComponent/ProjectCostingBoxes';

const getData = (
  t: ReturnType<typeof useTranslations>,
  topBoxes: RLLComputedProfitabilityRealEstateExpertTopBoxes,
) => {
  const translate = (key: string) =>
    t(`pages.planningEvaluation.marketComparison.clientBoxes.${key}`);
  const translateUnit = (unit: string) => t(`other.${unit}`);

  return [
    {
      name: translate('potentialSalesRevenue'),
      value: formatDecimal(topBoxes.potentialSalesRevenue, {
        unit: translateUnit('defaultCurrency'),
      }),
      testId: 'potential-sales-revenue-box',
    },
    {
      name: translate('possibleRentalIncomePerYear'),
      value: formatDecimal(topBoxes.possibleRentalIncomePerYear, {
        unit: translateUnit('defaultCurrency'),
      }),
      testId: 'possible-rental-income-box',
    },
    {
      name: translate('livingSpace'),
      value: formatDecimal(topBoxes.livingSpace, { unit: translateUnit('m2') }),
      testId: 'living-space-box',
    },
    {
      name: translate('commercialSpace'),
      value: formatDecimal(topBoxes.commercialSpace, { unit: translateUnit('m2') }),
      testId: 'commercial-space-box',
    },
  ];
};

interface AppraiserTopBoxesProps {
  topBoxes: RLLComputedProfitabilityRealEstateExpertTopBoxes;
}

const AppraiserTopBoxes: React.FC<AppraiserTopBoxesProps> = ({ topBoxes }) => {
  const t = useTranslations();
  const data = useMemo(() => getData(t, topBoxes), [t, topBoxes]);

  return <ProjectCostingBoxes values={data} />;
};

export default AppraiserTopBoxes;
