import {
  PARTNER_SPECIFIC_IBAN,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_GENDER,
  CONTACT_PERSON_LAST_NAME,
} from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const bfsContactPersonfieldValidation = {
  [CONTACT_PERSON_GENDER]: fieldValidators.string().required(),
  [CONTACT_PERSON_FIRST_NAME]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_END_USER_FIRST_NAME),
  [CONTACT_PERSON_LAST_NAME]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_END_USER_LAST_NAME),
};

export const bfsCompanyDetailsFieldValidation = {
  [PARTNER_SPECIFIC_IBAN]: fieldValidators.bank().isValidIban().isGermanIban(),
};
