import React from 'react';

import { ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import Condition from 'components/Condition';
import { persistDecorator, resetPersist } from 'new/form/persist';
import { NewProgressBar } from 'new/form/progress';
import {
  useInquiryProcessConfig,
  useResetInquiryProgress,
} from 'new/form/state/inquiryProcessConfig/hooks';
import { useShowProgressBar } from 'utils/hooks/useShowProgressBar';

import { revalidateFields } from './mutators';

type Props<FieldTypes> = {
  children: React.ReactNode;
  initialValues: FieldTypes | null;
  handleSubmit: (values: FieldTypes) => Promise<boolean>;
  // Setting the values to FieldTypes leads to a strange type error for the persistDecorarator. Therefore, we use any here.
  validate?: (values: any) => ValidationErrors | Promise<ValidationErrors>;
};

const useHandleSubmit = <FieldTypes,>(handleSubmit: Props<FieldTypes>['handleSubmit']) => {
  const resetInquiryProgress = useResetInquiryProgress();
  return async (values: FieldTypes) => {
    const submitSuccessful = await handleSubmit(values);
    if (submitSuccessful) {
      resetInquiryProgress();
      resetPersist();
    }
  };
};

export function InquiryProcessForm<FieldTypes>({
  children,
  initialValues,
  handleSubmit,
  validate,
}: Props<FieldTypes>) {
  const {
    formStateData: { initialFormValues, formName },
  } = useInquiryProcessConfig();
  const showProgressBar = useShowProgressBar();

  const submitHandler = useHandleSubmit<FieldTypes>(handleSubmit);

  return (
    <Form
      decorators={[persistDecorator]}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
      initialValues={initialValues || initialFormValues}
      mutators={{ ...arrayMutators, revalidateFields }}
      onSubmit={submitHandler}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} id={formName}>
          {children}
          <Condition condition={showProgressBar}>
            <NewProgressBar />
          </Condition>
        </form>
      )}
    />
  );
}
