import React from 'react';

import FormSection from 'components/FormSection';
import SectionHeading from 'components/Text/SectionHeading';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  USER_PROFILE_PASSWORD,
  USER_PROFILE_PASSWORD_REPEATED,
} from 'modules/Inquiry/Form/formFields';
import PasswordFields from 'modules/Inquiry/Form/Steps/UserProfile/Password/Password';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validatePasswordSection = validateSectionFields([
  USER_PROFILE_PASSWORD,
  USER_PROFILE_PASSWORD_REPEATED,
]);

const PasswordSection = ({ sectionNumber }: { sectionNumber: number }) => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.password}
      sectionNumber={sectionNumber}
      title={t('pages.companyDetails.sections.passwordSection.title')}
    >
      <SectionHeading>
        {t('pages.userProfile.sections.completingContactData.heading')}
      </SectionHeading>

      <PasswordFields />
    </FormSection>
  );
};

export default PasswordSection;
