import React from 'react';

import { func, node } from 'prop-types';

class CallOnMount extends React.Component {
  componentDidMount() {
    if (this.props.callback) {
      setTimeout(this.props.callback, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

CallOnMount.defaultProps = {
  children: null,
};

CallOnMount.propTypes = {
  callback: func.isRequired,
  children: node,
};

export default CallOnMount;
