import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const LoanIcon = (props: IconProps) => {
  return (
    <Icon
      width="40"
      height="35"
      viewBox="0 0 39.65 34.97"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path d="M8.51 20.5h4.09a5.46 5.46 0 012.08.4l4.22 1.69a4.48 4.48 0 001.7.33h5.47a2.55 2.55 0 110 5.1H19a1.13 1.13 0 00-1.13 1.12" />
        <path d="M8.51 30.71h1.28a7 7 0 012.41.43l8.38 3.08a4.11 4.11 0 003.31-.2l14.48-7.39a1.42 1.42 0 00.72-.87 1.4 1.4 0 00-.09-1.11 1.43 1.43 0 00-1.71-.65l-9.35 3M0 18.58h7a1.35 1.35 0 011.35 1.34V32A1.35 1.35 0 017 33.39H0m28.12-13.18a6.9 6.9 0 116.9-6.9 6.91 6.91 0 01-6.9 6.9z" />
        <path d="M21.35 13.36a6.78 6.78 0 01-3.41.93 6.9 6.9 0 116.89-6.89m-5.32 2.68a3.16 3.16 0 11.14-5.55m-5.44 2.11h3.33m-3.33 1.25h3.33m12.61 8.96a3.79 3.79 0 01-1.74.42 3.83 3.83 0 111.9-7.16m-6.59 2.57h4.04m-4.04 1.51h4.04" />
      </g>
    </Icon>
  );
};

export default LoanIcon;
