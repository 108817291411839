import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { hausbankFields } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { PrivacyPolicyField } from 'pages/inquiryFlow/legal/termsAndConditions/PrivacyPolicyField';
import { TermsOfServiceField } from 'pages/inquiryFlow/legal/termsAndConditions/TermsOfServiceField';
import { useTranslations } from 'utils/hooks/useTranslations';

export const TermsAndConditionsSection = () => {
  const t = useTranslations();
  const { termsAndConditionsAccepted, privacyPolicyAccepted } =
    hausbankFields.companyDataPage.termsAndConditionsSection;

  const {
    termsAndConditionsAccepted: {
      caption: termsAndConditionsLabel,
      links: { termsAndConditions: termsAndConditionsText },
    },
    privacyPolicyAccepted: {
      caption: privacyPolicyLabel,
      links: { privacyPolicy: privacyPolicyText },
    },
  } = translations.inquiryType.hausbank.pages.companyData.sections.termsAndConditions.fields;

  return (
    <FormSection
      title={t(
        translations.inquiryType.hausbank.pages.companyData.sections.termsAndConditions.title,
      )}
      sectionNumber={3}
    >
      <FormRow>
        <TermsOfServiceField
          fieldName={termsAndConditionsAccepted}
          labelTranslationKey={termsAndConditionsLabel}
          tosTextTranslationKey={termsAndConditionsText}
        />
      </FormRow>
      <FormRow>
        <PrivacyPolicyField
          fieldName={privacyPolicyAccepted}
          labelTranslationKey={privacyPolicyLabel}
          privacyPolicyTextTranslationKey={privacyPolicyText}
        />
      </FormRow>
    </FormSection>
  );
};
