import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const StarIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M12 17.3c.3 0 .7.1 1 .3l4.5 2.6-1-5.1c-.2-.7.1-1.4.6-1.9L21 9.6 15.8 9c-.7-.1-1.3-.5-1.6-1.2L12 3 9.8 7.8c-.2.6-.9 1.1-1.6 1.2L3 9.5l3.9 3.6c.5.5.8 1.2.6 1.9l-1.1 5.1 4.6-2.6c.3-.1.7-.2 1-.2m5.5 4.8c-.4 0-.7-.1-1-.3L12 19.3l-4.5 2.6c-.9.6-2.2.3-2.8-.6-.3-.5-.4-1-.2-1.5l1.1-5.1L1.7 11c-.8-.7-.9-2-.1-2.8.3-.4.8-.6 1.3-.6L8 7l2.2-4.8c.4-1 1.6-1.5 2.6-1.1.5.2.9.6 1.1 1.1L16 7l5.2.6c1.1.1 1.9 1.2 1.7 2.3-.1.4-.3.8-.6 1.2l-3.9 3.5 1.1 5.1c.1.8-.2 1.5-.8 2-.3.2-.7.4-1.2.4"
      />
    </Icon>
  );
};

export default StarIcon;
