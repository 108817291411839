import { AxiosResponse } from 'axios';
import { createMutation, createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';
import { UserAttributes, UserDetails } from 'types/User';
import { deepCamelcaseKeys } from 'utils/object';

import endpoints from '../../endpoints';

export type UseMeResponse = AxiosResponse<{
  data: {
    id: string;
    type: string;
    attributes: UserDetails;
  };
}>;

export const useMe = createQuery<UseMeResponse>({
  primaryKey: queryKeys.users.me,
  queryFn: async () => {
    const response = await EcoBankingAxiosClientAuthedInstance.get(endpoints.USERS.ME.compose());
    return {
      ...response,
      data: deepCamelcaseKeys(response.data),
    };
  },
});

export const updateOperationUserData = async (
  apiValues: UserAttributes,
): Promise<UseMeResponse> => {
  return EcoBankingAxiosClientAuthedInstance.patch(endpoints.OP_USERS.LIST.compose(), {
    data: {
      attributes: apiValues,
    },
  });
};

export const updateCustomerData = async (apiValues: UserAttributes): Promise<UseMeResponse> => {
  return EcoBankingAxiosClientAuthedInstance.patch(endpoints.CUSTOMERS.LIST.compose(), {
    data: {
      attributes: apiValues,
    },
  });
};

export const useUpdateCustomerData = createMutation({
  mutationFn: async (variables: { apiValues: UserAttributes }) =>
    updateCustomerData(variables.apiValues),
});

export const useUpdateOperationUserData = createMutation({
  mutationFn: async (variables: { apiValues: UserAttributes }) =>
    updateOperationUserData(variables.apiValues),
});
