import * as React from 'react';

import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

type Props = {
  colHeaders: Array<string>;
  tbodyRows: Array<{ id: string; cols: Array<React.ReactNode> }>;
  hasActions?: boolean;
};

export const TableComponent = ({ colHeaders, tbodyRows, hasActions }: Props) => (
  <Box
    overflowX="auto"
    overflowY="hidden"
    maxWidth="100%"
    sx={{ '-webkit-overflow-scrolling': 'touch' }}
  >
    <Table variant="unstyled">
      <Thead>
        <tr>
          {colHeaders.map((title) => (
            <TableHeadItem key={title} colTitle={title} hasActions={hasActions} />
          ))}
        </tr>
      </Thead>

      <Tbody>
        {tbodyRows.map((row) => (
          <TableBodyRow key={row.id} data={row.cols} hasActions={hasActions} />
        ))}
      </Tbody>
    </Table>
  </Box>
);

type TableHeadItemProps = {
  colTitle: string;
  hasActions?: boolean;
};

export const TableHeadItem = ({ colTitle, hasActions }: TableHeadItemProps) => {
  return <Th _last={{ textAlign: hasActions && 'center' }}>{colTitle}</Th>;
};

type TableBodyRowProps = {
  data: Array<React.ReactNode>;
  hasActions?: boolean;
};

export const TableBodyRow = ({ data, hasActions }: TableBodyRowProps) => {
  return (
    <Tr>
      {data.map((content, index) => (
        <Td
          // since this array won't change we can use index as key
          key={index} // eslint-disable-line react/no-array-index-key
          py={hasActions ? 2 : 4}
        >
          {content}
        </Td>
      ))}
    </Tr>
  );
};
