import React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import {
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
} from 'modules/Inquiry/Form/formFields';
import {
  StyledTypesLead as Lead,
  StyledTypeTitle as Title,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/styles';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { useTranslations } from 'utils/hooks/useTranslations';

import { AmountWithField } from '../../../Amount/Amount';

const ObjectInformationSection: React.FC = () => {
  const tObjectInformation = useTranslations('pages.financingNeed.sections.objectInformation');
  const t = useTranslations();
  const projectFinancingFurnishingQualityOptions = useInquirySelectOptions(
    PROJECT_FINANCING__FURNISHING_QUALITY,
  );
  const projectFinancingConditionQualityOptions = useInquirySelectOptions(
    PROJECT_FINANCING__QUALITY_CONDITION,
  );

  return (
    <>
      <FormRow>
        <InputWithField
          name={PROJECT_FINANCING__BUILDING_YEAR}
          type="number"
          separateThousands={false}
        />
        <InputWithField
          name={PROJECT_FINANCING__MODERNIZATION_YEAR}
          type="number"
          separateThousands={false}
          optional
        />
      </FormRow>
      <FormRow>
        <Lead>{tObjectInformation('usageInfoSpecialInformationSection')}</Lead>
      </FormRow>
      <FormRow>
        <SelectWithField
          name={PROJECT_FINANCING__FURNISHING_QUALITY}
          placeholder={t('placeholders.pleaseChoose')}
          options={projectFinancingFurnishingQualityOptions}
          data-testid="project-financing-furnishing-select-field"
          tooltip={tObjectInformation('fields.furnishingsQuality.tooltip')}
        />
        <SelectWithField
          name={PROJECT_FINANCING__QUALITY_CONDITION}
          placeholder={t('placeholders.pleaseChoose')}
          options={projectFinancingConditionQualityOptions}
          data-testid="project-financing-condition-select-field"
          tooltip={tObjectInformation('fields.conditionQuality.tooltip')}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING__GUEST_BATHROOM} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING__BALKONY} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING__LIFT} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={PROJECT_FINANCING__CELLAR} />
      </FormRow>
      <FormRow>
        <Lead>{tObjectInformation('fields.parkingLotsAndGaragesDetails.caption')}</Lead>
      </FormRow>
      <Title>{tObjectInformation('fields.parkingLots.caption')}</Title>
      <FormRow>
        <InputWithField name={PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS} type="number" />
      </FormRow>
      <FormRow>
        <AmountWithField name={PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT} optional />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={PROJECT_FINANCING__PARKING_LOTS_RENT_NOW}
          tooltip={tObjectInformation('fields.parkingLots.rentNow.tooltip')}
          optional
        />
        <AmountWithField
          name={PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE}
          tooltip={tObjectInformation('fields.parkingLots.rentFuture.tooltip')}
          optional
        />
      </FormRow>
      <Title>{tObjectInformation('fields.garages.caption')}</Title>
      <FormRow>
        <InputWithField name={PROJECT_FINANCING__NUMBER_OF_GARAGES} type="number" />
      </FormRow>
      <FormRow>
        <AmountWithField name={PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT} optional />
      </FormRow>
      <FormRow>
        <AmountWithField
          name={PROJECT_FINANCING__GARAGES_RENT_NOW}
          tooltip={tObjectInformation('fields.garages.rentNow.tooltip')}
          optional
        />
        <AmountWithField
          name={PROJECT_FINANCING__GARAGES_RENT_FUTURE}
          tooltip={tObjectInformation('fields.garages.rentFuture.tooltip')}
          optional
        />
      </FormRow>
    </>
  );
};

export default React.memo(ObjectInformationSection);
