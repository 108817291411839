import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { useIsDeveloper } from 'mittweida/config/conditions';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinanceWholeProjectRadio } from './FinanceWholeProjectRadio';
import { ProjectFinancingAmount } from './ProjectFinancingAmount';

const {
  totalInvestment,
  totalInvestmentInBuyingPhase,
  ownFunds,
  ownFundsInBuyingPhase,
  mezzanineCapital,
  subordinatedCapital,
  ownWork,
} = mittweidaFields.financingNeedPage.projectCompositionSection;

export const ProjectCompositionSection = () => {
  const t = useTranslations();
  const { title, fallback } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition;
  const { totalInvestment: totalInvestmentTranslation } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.projectComposition.fields;
  const isDeveloper = useIsDeveloper();

  return (
    <Condition
      validation={'roleSection'}
      fallback={
        <DisabledFormSection sectionNumber={2} placeholder={t(fallback)} title={t(title)} />
      }
    >
      <FormSection title={t(title)} name={'projectComposition'} sectionNumber={2}>
        <FormRow>
          <AmountWithField name={totalInvestment} tooltip={t(totalInvestmentTranslation.tooltip)} />
          <Condition validation={isDeveloper}>
            <AmountWithField name={totalInvestmentInBuyingPhase} />
          </Condition>
        </FormRow>
        <FormRow>
          <AmountWithField name={ownFunds} />
          <Condition validation={isDeveloper}>
            <AmountWithField name={ownFundsInBuyingPhase} />
          </Condition>
        </FormRow>
        <FormRow>
          <AmountWithField name={mezzanineCapital} />
          <AmountWithField name={subordinatedCapital} />
        </FormRow>
        <FormRow>
          <AmountWithField name={ownWork} />
        </FormRow>
        <FinanceWholeProjectRadio />
        <FormRow>
          <ProjectFinancingAmount />
        </FormRow>
      </FormSection>
    </Condition>
  );
};
