import { translations } from 'new/form/common/types';
import { StepData } from 'new/form/model';

import { HausbankFieldTypes } from '../fields';
import { hausbankSteps } from '../steps';

const objectInformation = translations.inquiryType.hausbank.pages.objectInformation;
const companyData = translations.inquiryType.hausbank.pages.companyData;
const financingNeed = translations.inquiryType.hausbank.pages.financingNeed;
const personalData = translations.inquiryType.hausbank.pages.personalData;

export const hausbankStepData: StepData<HausbankFieldTypes> = {
  objectInformationPage: {
    progressBarTitle: objectInformation.progressBarTitle,
    nextStep: hausbankSteps.companyDataPage,
    sectionTitles: {
      objectInformationSection: objectInformation.sections.objectInformation.title,
      projectInformationSection: objectInformation.sections.projectInformation.title,
    },
  },
  companyDataPage: {
    progressBarTitle: companyData.progressBarTitle,
    previousStep: hausbankSteps.objectInformationPage,
    nextStep: hausbankSteps.financingNeedPage,
    sectionTitles: {
      wegSearchSection: companyData.sections.wegSearch.title,
      wegDataCompletionSection: companyData.sections.wegDataCompletionSection.summaryTitle,
      termsAndConditionsSection: companyData.sections.termsAndConditions.title,
    },
  },
  financingNeedPage: {
    progressBarTitle: financingNeed.progressBarTitle,
    previousStep: hausbankSteps.companyDataPage,
    nextStep: hausbankSteps.personalDataPage,
    sectionTitles: {
      purposeSection: financingNeed.sections.purpose.title,
      financingNeedSection: financingNeed.sections.financingNeed.summaryTitle,
    },
  },
  personalDataPage: {
    progressBarTitle: personalData.progressBarTitle,
    previousStep: hausbankSteps.financingNeedPage,
    sectionTitles: {
      contactPersonSection: personalData.sections.contactPerson.title,
      passwordSection: personalData.sections.password.title,
      legalRepresentativesSection: personalData.sections.legalRepresentatives.title,
    },
  },
};
