import React from 'react';

import { useSelector } from 'react-redux';

import {
  StyledErrorDescription,
  StyledAdditionalErrorText,
} from 'pages/inquiryFlow/SummaryPage/SummaryHeading/FastlaneHeadline/styles';
import { getFastLane } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const errorTranslations: { [error: string]: string } = {
  amount_too_high: 'pages.inquiryDetails.offers.fastlane.summary.errors.amountTooHeight',
  amount_too_low: 'pages.inquiryDetails.offers.fastlane.summary.errors.amountTooLow',
  purpose_unsupported: 'pages.inquiryDetails.offers.fastlane.summary.errors.purposeUnsupported',
  product_unsupported: 'pages.inquiryDetails.offers.fastlane.summary.errors.productUnsupported',
  term_too_long: 'pages.inquiryDetails.offers.fastlane.summary.errors.termTooLong',
  term_too_short: 'pages.inquiryDetails.offers.fastlane.summary.errors.termTooShort',
  no_matching_criteria: 'pages.inquiryDetails.offers.fastlane.summary.errors.noMatchingCriteria',
  credit_rating_insufficient:
    'pages.inquiryDetails.offers.fastlane.summary.errors.creditRatingInsufficient',
  legal_form_unsupported:
    'pages.inquiryDetails.offers.fastlane.summary.errors.legalFormUnsupported',
  company_too_young: 'pages.inquiryDetails.offers.fastlane.summary.errors.companyTooYoung',
  turnover_class_unsupported:
    'pages.inquiryDetails.offers.fastlane.summary.errors.turnoverClassUnsupported',
};

const FastlaneErrorDescription: React.FC = () => {
  const t = useTranslations();
  const state = useSelector(getFastLane);

  const translateError = (errorKey: string) => {
    const errorTranslation = errorTranslations[errorKey];
    return errorTranslation ? t(errorTranslation) : '';
  };

  const errors = Object.entries(state.fastlaneErrors || {})
    .map(([errorKey, numOfBanks]) => ({ error: translateError(errorKey), numOfBanks }))
    .filter((e) => e.error);

  if (state.errorCheckingFastlane) {
    return <p>{t('pages.inquiryDetails.offers.fastlane.summary.fastlaneCheckError')}</p>;
  }
  const partnerText = t('pages.inquiryDetails.offers.fastlane.summary.partner');
  return (
    <div>
      <StyledAdditionalErrorText>
        {t('pages.inquiryDetails.offers.fastlane.summary.introNotQualified')}
      </StyledAdditionalErrorText>
      <StyledErrorDescription>
        {errors.map(({ error, numOfBanks }) => (
          <p key={error}>
            <span>
              {numOfBanks} {partnerText}:
            </span>{' '}
            {error}
          </p>
        ))}
      </StyledErrorDescription>
      <StyledAdditionalErrorText>
        {t('pages.inquiryDetails.offers.fastlane.summary.errorInstructionsFirst')}
      </StyledAdditionalErrorText>
      <StyledAdditionalErrorText>
        {t('pages.inquiryDetails.offers.fastlane.summary.errorInstructionsWithLink')}
      </StyledAdditionalErrorText>
    </div>
  );
};

export default FastlaneErrorDescription;
