import {
  END_USER_EMAIL,
  END_USER_PHONE_NUMBER,
  END_USER_GENDER,
  END_USER_LAST_NAME,
  END_USER_FIRST_NAME,
  END_USER_ID,
  USER_PROFILE_BIRTHDATE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

export const mapEndUserToApi: InquiryFormMapper = (allFields) => {
  const hasEndUserData = !!allFields[END_USER_EMAIL];
  if (!hasEndUserData) {
    return {};
  }
  return {
    'end-customer-attributes': {
      email: allFields[END_USER_EMAIL],
      'first-name': allFields[END_USER_FIRST_NAME],
      'last-name': allFields[END_USER_LAST_NAME],
      birth_date: allFields[USER_PROFILE_BIRTHDATE],
      id: allFields[END_USER_ID],
      salutation: allFields[END_USER_GENDER],
      'phone-number': allFields[END_USER_PHONE_NUMBER],
    },
  };
};
