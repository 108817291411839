import { AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import {
  FILTERS_FINANCING_ROLE,
  FILTERS_SALE_TYPES,
} from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { IState } from 'store';
import { getUpdateState } from 'utils/storeHelpers';

import { FILTERS_USAGE_TYPES } from './constants';

export const SAVE_MARKETDATA_FILTER = 'SAVE_MARKETDATA_FILTER';
export const RESET_MARKETDATA_FILTER = 'RESET_MARKETDATA_FILTER';

export interface IMarketDataFilterState {
  usageType: FILTERS_USAGE_TYPES;
  saleType: FILTERS_SALE_TYPES;
  financingRole: FILTERS_FINANCING_ROLE;
}

export type NormalizedMarketDataFilterState = IMarketDataFilterState & {
  saleType: FILTERS_SALE_TYPES.BUILDING | FILTERS_SALE_TYPES.UNIT;
};

// action
export const setMarketDataFilter = (payload: any) => (dispatch: any) =>
  dispatch({
    type: SAVE_MARKETDATA_FILTER,
    payload,
  });

export const getMarketDataFilterLegacy = (store: IState): IMarketDataFilterState =>
  store?.marketDataFilter || {
    saleType: FILTERS_SALE_TYPES.BUILDING,
    usageType: FILTERS_USAGE_TYPES.CURRENT,
    financingRole: FILTERS_FINANCING_ROLE.STOCKHOLDER,
  };

export const getMarketDataFilter = (store: IState): NormalizedMarketDataFilterState => {
  const filters = store.marketDataFilter;
  const saleTypeWithDefault = filters?.saleType || FILTERS_SALE_TYPES.UNIT;

  return {
    usageType: filters?.usageType || FILTERS_USAGE_TYPES.CURRENT,
    // rent and unit are the same data and thus the backend only supports 'unit'.
    saleType:
      saleTypeWithDefault === FILTERS_SALE_TYPES.RENT
        ? FILTERS_SALE_TYPES.UNIT
        : saleTypeWithDefault,
    financingRole: filters?.financingRole || FILTERS_FINANCING_ROLE.STOCKHOLDER,
  };
};

// reducer
const reducer = (
  state = {
    usageType: FILTERS_USAGE_TYPES.FUTURE,
    saleType: FILTERS_SALE_TYPES.UNIT,
    financingRole: FILTERS_FINANCING_ROLE.DEVELOPER,
  },
  { type, payload }: AnyAction,
) => {
  const updateState = getUpdateState(state);

  switch (type) {
    case SAVE_MARKETDATA_FILTER: {
      return {
        ...state,
        ...payload,
      };
    }
    case RESET_MARKETDATA_FILTER:
      return updateState({
        usageType: FILTERS_USAGE_TYPES.FUTURE,
        saleType: FILTERS_SALE_TYPES.UNIT,
        financingRole: FILTERS_FINANCING_ROLE.DEVELOPER,
      });
    default:
      return state;
  }
};

const persistConfig = {
  key: 'marketdata',
  storage,
};

export default persistReducer(persistConfig, reducer);
