import React from 'react';

import { Box } from '@chakra-ui/react';

import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { DocumentsTable } from './DocumentsTable';
import { LegalRepresentativesTable } from './LegalRepresentativesTable';

export const LegalRepresentativeCorrection = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection');

  return (
    <Box maxWidth="900px" mx="auto">
      <FormSectionLayout title="">
        <HeadingComponent as="h2" variant="secondary" mb={3}>
          {t('headline')}
        </HeadingComponent>
        <TextComponent color="text.tertiary">{t('summary')}</TextComponent>
      </FormSectionLayout>
      <DocumentsTable />
      <LegalRepresentativesTable />
    </Box>
  );
};
