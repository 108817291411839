import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { dzbFields, DzbSections } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, isValidBicDeep, required } from 'utils/validators';

const { iban, bic, bankName } = dzbFields.companyDataPage.directDebitMandateSection;

export const DirectDebitMandateSection = () => {
  const t = useTranslations();
  const { title } = translations.inquiryType.dzb.pages.companyData.sections.directDebitMandate;

  return (
    <Condition<DzbSections>
      validation={'companyInformationSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={3}
          placeholder={t(translations.inquiryType.dzb.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={3}>
        <FormRow>
          <InputWithField name={iban} />
        </FormRow>
        <FormRow>
          <InputWithField
            name={bic}
            validate={combineValidators(
              required(t('errors.required')),
              isValidBicDeep(iban, t('errors.bicDeep')),
            )}
          />
        </FormRow>
        <FormRow>
          <InputWithField name={bankName} />
        </FormRow>
      </FormSection>
    </Condition>
  );
};
