import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const forwardToCustomerAction = (inquiryId: string) =>
  callReverseApi({
    url: endpoints.INQUIRIES.FORWARD.CUSTOMER.compose({ params: { id: inquiryId } }),
    method: API_METHODS.PATCH,
  });

export default forwardToCustomerAction;
