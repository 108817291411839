import { API_METHODS, callReverseApi } from 'api/apiService';
import { LOAD_PARTNER_SETTINGS } from 'api/CompeonReverseApi/actionTypes';
import endpoints from 'api/CompeonReverseApi/endpoints';

export const loadPartnerSettingsAction = (partnerId: string, url: string) =>
  callReverseApi({
    url: endpoints.PARTNERS.SETTINGS.compose({ params: { id: partnerId }, query: { url } }),
    method: API_METHODS.GET,
    actionName: LOAD_PARTNER_SETTINGS,
  });
