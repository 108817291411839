import styled from 'styled-components/macro';

import { mqSmall, mqMedium, mqLarge } from 'styles/breakpoints';

const BadgeLogo = styled.img`
  ${mqSmall`
    height: 2.5rem;
  `};

  ${mqMedium`
    height: 4rem;
  `};

  ${mqLarge`
    height: 6rem;
  `};
`;

export default BadgeLogo;
