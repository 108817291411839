import React from 'react';

import Condition from 'components/Condition';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';

interface ProjectCostingHeaderProps {
  heading: string;
  subheading?: string;
  subheadingDate?: string;
}

const ProjectCostingHeader: React.FC<ProjectCostingHeaderProps> = (
  props: ProjectCostingHeaderProps,
) => {
  return (
    <HeadingComponent as="h2" variant="primary" mb={8}>
      {props.heading}

      <TextComponent
        as="span"
        display="block"
        fontWeight={FONT_WEIGHT_REGULAR}
        color="text.tertiary"
      >
        <Condition condition={!!props.subheadingDate} fallback={props.subheading}>
          {`${props.subheading}: ${props.subheadingDate}`}
        </Condition>
      </TextComponent>
    </HeadingComponent>
  );
};

export default ProjectCostingHeader;
