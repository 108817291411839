import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import { checkEmailAvailabilityAction } from 'api/CompeonReverseApi/customer/actions';
import { fieldsAreValid, getFieldValue } from 'modules/Inquiry/Form/formConditions';
import { COMPANY_DETAILS_EMAIL } from 'modules/Inquiry/Form/formFields';
import { isEmailRegex } from 'utils/regexes';

let lastArg;
let lastResult;
const simpleMemoize = (fn) => {
  return (email, makeCall) => {
    if (email !== lastArg) {
      lastArg = email;
      lastResult = fn(email, makeCall);
    }
    return lastResult;
  };
};

const checkIsEmailAvailable = _debounce(
  simpleMemoize(async (email, makeCall) => {
    const { payload } = await makeCall(checkEmailAvailabilityAction(email));
    const emailAlreadyExist = _get(payload, 'data.data.allowed_to_inquiry');

    return !emailAlreadyExist;
  }),
  300,
);

export const validateNotLoggedInUserDetails = (makeCall) => async (form) => {
  const emailValue = getFieldValue(COMPANY_DETAILS_EMAIL, form);
  const validEmail = isEmailRegex.test(emailValue);
  const isEmailAvailable = await checkIsEmailAvailable(emailValue, makeCall);
  return fieldsAreValid([COMPANY_DETAILS_EMAIL])({ form }) && validEmail && isEmailAvailable;
};
