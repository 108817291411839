import env from '@beam-australia/react-env';

import { API_METHODS, callCompeonOAuthApi } from 'api/apiService';
import endpoints from 'api/CompeonOAuthApi/endpoints';
import { EnvironmentVariable } from 'utils/environment';

import { EXCHANGE_TOKEN } from './actionTypes';

export const exchangeTokenAction = (code: string, redirectUri: string) => {
  return callCompeonOAuthApi({
    url: endpoints.EXCHANGE_TOKEN.compose(),
    method: API_METHODS.POST,
    actionName: EXCHANGE_TOKEN,
    data: {
      grant_type: 'authorization_code',
      client_id: env(EnvironmentVariable.COMPEON_OAUTH_CLIENT_ID),
      code,
      redirect_uri: redirectUri,
    },
  });
};
