import { LIQUIDITY_DESCRIPTION, FINANCING_AMOUNT } from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

export const mapLiquidityToApi: InquiryFormMapper = (allFields: any) => {
  const { toInteger } = fieldParsers;
  return {
    'liquidity-description': allFields[LIQUIDITY_DESCRIPTION],
    'liquidity-need': toInteger(allFields[FINANCING_AMOUNT]),
  };
};
