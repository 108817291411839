import { success } from 'utils/actionTypesHelpers';

export const SET_REFERRAL_WIDGET_CODE = 'SET_REFERRAL_WIDGET_CODE';

export const SET_LEAD_ID = 'SET_LEAD_ID';
export const SET_INQUIRY_TYPE = 'SET_INQUIRY_TYPE';
export const SET_PROCESS_LANE = 'SET_PROCESS_LANE';
export const CLEAR_LEAD_ID = 'CLEAR_LEAD_ID';

export const SET_CAN_SEND_LEAD = 'SET_CAN_SEND_LEAD';

export const SET_MULTIPLIER_ORGANIZATION_ID = 'SET_MULTIPLIER_ORGANIZATION_ID';

export const SET_EXTERNAL_REFERENCE_ID = 'SET_EXTERNAL_REFERENCE_ID';

export const CREATE_INQUIRY_DRAFT = 'CREATE_INQUIRY_DRAFT';
export const CREATE_INQUIRY_DRAFT_SUCCESS = 'CREATE_INQUIRY_DRAFT_SUCCESS';

export const REMOVE_INQUIRY_DRAFT = 'REMOVE_INQUIRY_DRAFT';

export const SET_INQUIRY_DRAFT = 'SET_INQUIRY_DRAFT';
export const CLEAR_INQUIRY_DRAFT = 'CLEAR_INQUIRY_DRAFT';

export const GET_INQUIRY_FILES = 'GET_INQUIRY_FILES';
export const GET_INQUIRY_FILES_SUCCESS = success(GET_INQUIRY_FILES);
