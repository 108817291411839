import React from 'react';

import type { PortalTableProps } from '../components/PortalTable';
import type { TableHeaderProps } from '../types';

export const usePortalTable = <T>({
  headers,
  data,
}: {
  headers: Array<TableHeaderProps>;
  data: Array<T>;
}): Pick<PortalTableProps<T>, 'columns' | 'data'> =>
  React.useMemo(
    () => ({
      columns: headers.map(({ key, text }) => ({
        accessorKey: key,
        header: () => text,
        cell: ({ getValue }) => getValue() ?? null,
      })),
      data: data,
    }),

    [data, headers],
  );

export default usePortalTable;
