import React from 'react';

import { RouteProps } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import paths from 'constants/paths';
import { userIsCustomer } from 'utils/user/conditionals';

const { customer: customerPaths } = paths;

interface CustomerPrivateRouteProps extends RouteProps {}

const CustomerPrivateRoute = (props: CustomerPrivateRouteProps) => {
  const isAuthorized = userIsCustomer();
  return <PrivateRoute isAuthorized={isAuthorized} authPath={customerPaths.login} {...props} />;
};
export default CustomerPrivateRoute;
