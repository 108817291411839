import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const AnschlussfinanzierungIcon = (props: IconProps) => {
  return (
    <Icon
      width="46"
      height="40"
      viewBox="0 0 45.86 39.92"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M29.88 28.74h-8.42v-1h8.42a2.21 2.21 0 100-4.41v-1a3.21 3.21 0 110 6.41z" />
      <path d="M7 37.64V24.93l2.1-2.36a7.54 7.54 0 017.23-2.46l7.78 1.8a12.2 12.2 0 002.32.31l3.46.11v1l-3.46-.11a13.28 13.28 0 01-2.51-.33l-7.74-1.79a6.54 6.54 0 00-6.32 2.14L8 25.31V36l1.12-.58a10.5 10.5 0 016.83-1l5.51 1.08a11.27 11.27 0 007.39-1.09l14-7.42a3.72 3.72 0 002-3.42 1.26 1.26 0 00-.41-.89 1.22 1.22 0 00-.88-.31l-10.83 4-.35-.94 11-4.09a2.18 2.18 0 011.72.66 2.23 2.23 0 01.75 1.6 4.73 4.73 0 01-2.51 4.34l-14 7.41a12.28 12.28 0 01-8 1.19l-5.51-1.08a9.57 9.57 0 00-6.18.87z" />
      <path d="M5.07 39.92H2.93A2.94 2.94 0 010 37V23.31a2.94 2.94 0 012.93-2.93h2.14A2.94 2.94 0 018 23.31V37a2.94 2.94 0 01-2.93 2.92zM2.93 21.38A1.94 1.94 0 001 23.31V37a1.94 1.94 0 001.93 1.93h2.14A1.94 1.94 0 007 37V23.31a1.94 1.94 0 00-1.94-1.93zM30.9 19.2c-4.85 0-8.79-4.31-8.79-9.6S26.05 0 30.9 0s8.78 4.31 8.78 9.6-3.94 9.6-8.78 9.6zm0-18.2c-4.3 0-7.79 3.86-7.79 8.6s3.49 8.6 7.79 8.6 7.78-3.86 7.78-8.6S35.19 1 30.9 1z" />
      <path d="M30.9 14.09A2.71 2.71 0 0128 11.6h1a1.72 1.72 0 001.89 1.49 1.72 1.72 0 001.88-1.49c0-.91-.74-1.5-1.88-1.5-2 0-2.89-1.25-2.89-2.5a2.71 2.71 0 012.9-2.49 2.71 2.71 0 012.88 2.49h-1a1.72 1.72 0 00-1.88-1.49A1.72 1.72 0 0029 7.6c0 .94.7 1.5 1.89 1.5a2.59 2.59 0 012.88 2.5 2.71 2.71 0 01-2.87 2.49z" />
      <path d="M30.39 3.84h1v1.37h-1zm0 10.15h1v1.37h-1z" />
    </Icon>
  );
};

export default AnschlussfinanzierungIcon;
