import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
// If needed in future we can modify steps for partner based on business needs
import { dzbSteps } from 'dzb/inquiry/steps';
import { hausbankStepSequence } from 'hausbank/inquiry/config/stepSequence';
import { mittweidaSteps } from 'mittweida/inquiry/steps';
import { mmvSteps } from 'mmv/inquiry/steps';
import {
  FINANCING_NEED,
  COMPANY_DETAILS,
  REQUEST_DETAILS,
  USER_PROFILE,
  SUMMARY,
  FINAL_PAGE,
  PEOPLE_DETAILS,
  CONTRACT_DETAILS,
  CUSTOMER_DATA,
  LEASEPLAN_FINAL_PAGE,
  CONTACT_PERSON_DETAILS,
} from 'modules/Inquiry/Form/formFields';
import {
  IInquiryPath,
  getStepSegmentByInquiryType,
} from 'modules/Inquiry/inquiryNavigation/stepSegments';

import { InquiryType } from '../Inquiry.type';

const defaultStepsWithForm: string[] = [
  FINANCING_NEED,
  REQUEST_DETAILS,
  USER_PROFILE,
  COMPANY_DETAILS,
];

const defaultInquirySteps: string[] = [...defaultStepsWithForm, SUMMARY, FINAL_PAGE];

const inquiryTypeSpecificStepsWithForms = {
  [InquiryType.onlinefactoring]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.profiMittweida]: [
    mittweidaSteps.financingNeedPage,
    mittweidaSteps.companyDetailPage,
    mittweidaSteps.userProfilePage,
    mittweidaSteps.fundingDetailsPage,
  ],
  [InquiryType.dzb]: [dzbSteps.companyDataPage],
  [InquiryType.mmv]: [mmvSteps.contractDetails, mmvSteps.customerDataPage],

  [InquiryType.default]: defaultStepsWithForm,

  [InquiryType.profiRLL]: [
    FINANCING_NEED,
    REQUEST_DETAILS,
    USER_PROFILE,
    CONTACT_PERSON_DETAILS,
    COMPANY_DETAILS,
  ],

  [InquiryType.leaseplan]: [CONTRACT_DETAILS, CUSTOMER_DATA],
  [InquiryType.bfs]: [FINANCING_NEED, REQUEST_DETAILS, CONTACT_PERSON_DETAILS, COMPANY_DETAILS],
  [InquiryType.bfsService]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.hausbank]: hausbankStepSequence,
};

const inquiryTypeSpecificSummarySteps = {
  ...inquiryTypeSpecificStepsWithForms,
  [InquiryType.onlinefactoring]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.bfsService]: [FINANCING_NEED, COMPANY_DETAILS, REQUEST_DETAILS, PEOPLE_DETAILS],
  [InquiryType.hausbank]: [SUMMARY, FINAL_PAGE],
};

const inquiryTypeSpecificSteps = {
  [InquiryType.onlinefactoring]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.onlinefactoring],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.default]: defaultInquirySteps,
  [InquiryType.profiRLL]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.profiRLL],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.profiMittweida]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.profiMittweida],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.dzb]: [...inquiryTypeSpecificStepsWithForms[InquiryType.dzb]],
  [InquiryType.leaseplan]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.leaseplan],
    LEASEPLAN_FINAL_PAGE,
  ],
  [InquiryType.bfs]: [...inquiryTypeSpecificStepsWithForms[InquiryType.bfs], SUMMARY, FINAL_PAGE],
  [InquiryType.bfsService]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.bfsService],
    SUMMARY,
    FINAL_PAGE,
  ],
  [InquiryType.mmv]: [...inquiryTypeSpecificStepsWithForms[InquiryType.mmv]],
  [InquiryType.hausbank]: [
    ...inquiryTypeSpecificStepsWithForms[InquiryType.hausbank],
    ...inquiryTypeSpecificSummarySteps[InquiryType.hausbank],
  ],
};

const getStepsForInquiryType = (type: InquiryType, partner: string) => {
  return {
    steps: inquiryTypeSpecificSteps[type] ? inquiryTypeSpecificSteps[type] : defaultInquirySteps,
    stepsWithForms: inquiryTypeSpecificStepsWithForms[type] || defaultStepsWithForm,
    stepsForSummary: inquiryTypeSpecificSummarySteps[type] || defaultStepsWithForm,
  };
};

export const useInquiryStepData = () => {
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const { selectedInquiryType } = useFormConfig();
  const stepsForPartner = getStepsForInquiryType(selectedInquiryType, partnerName!);

  const transformPathsToSegments = (paths: string[]): IInquiryPath[] => {
    return paths
      .map((stepName) => getStepSegmentByInquiryType(selectedInquiryType)[stepName])
      .filter(Boolean);
  };

  return {
    stepsWithForm: stepsForPartner.stepsWithForms,
    stepList: stepsForPartner.steps,
    stepsForSummary: stepsForPartner.stepsForSummary,
    stepSegments: transformPathsToSegments(stepsForPartner.steps),
  };
};
