import { useState } from 'react';

import { some as _some, every as _every } from 'lodash';

import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  useFileValidator,
  DEFAULT_VALIDATORS,
  PDF_VALIDATORS,
} from 'components/UploadBlock/hooks/useFileValidator';
import useNewFileStatusListener from 'components/UploadBlock/hooks/useNewFileStatusListener';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import {
  mapErrorsFromApi,
  UPLOADING_STATE,
} from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import type { UploadedFile } from 'models/FileApi.model';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import { useTimeout } from 'utils/hooks/useTimeout';
import { useTranslations } from 'utils/hooks/useTranslations';

interface UseSelectFileOptions {
  onSuccess: (uploadedFile: UploadedFile, selectedFile: File) => void;
  url: string;
  fileRequestId?: string;
  isREE?: boolean;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
}

export const useSelectFile = ({
  onSuccess,
  url,
  fileRequestId,
  isREE,
  isPrivateFile,
  isAssessmentFile,
}: UseSelectFileOptions) => {
  const t = useTranslations('components.upload');
  const [isDropping, setIsDropping] = useState(false);
  const [uploadingState, setUploadingState] = useState(UPLOADING_STATE.IDLE);
  const resetUploadStateAfterDelay = useTimeout(() => setUploadingState(UPLOADING_STATE.IDLE));
  const validators = isAssessmentFile ? PDF_VALIDATORS : DEFAULT_VALIDATORS;
  const validateFile = useFileValidator(validators);
  const { startUpload, startContractUpload } = useNewUploadFile(url);
  const startScanningFile = useNewFileStatusListener({ isPrivateFile, isAssessmentFile });
  const { id: inquiryId } = useParamsSafe(['id']);

  const [errorMsg, setErrorMsg] = useState('');
  const showError = (err: string | object) => {
    setErrorMsg(mapErrorsFromApi(err));
    resetUploadStateAfterDelay();
    setUploadingState(UPLOADING_STATE.ERROR);
  };

  // Higher order function that acts as universal interface between the different upload functions
  const startUploadFn = async (data: File | File[], ...args: any[]) => {
    const hausbankContractsUploadUrl =
      endpoints.INQUIRIES.SPECIALIZED.HAUSBANK.CONTRACTS.UPLOAD.compose({
        params: { inquiryId },
      });

    if (url === hausbankContractsUploadUrl) {
      if (Array.isArray(data)) {
        return startContractUpload(data, args[0]);
      }
      // Wrap in new Promise to satisfy startUploadFn interface but return only a file if a single file was passed to the function
      return new Promise<File>((resolve) => {
        startContractUpload([data], args[0]).then((uploadedFiles) => {
          resolve(uploadedFiles[0]);
        });
      });
    }
    const file = Array.isArray(data) ? data[0] : data;
    return startUpload(file, args[0]);
  };

  const onFilesSelected = (selectedFiles: File[]) => {
    // Validate all files and upload them
    Promise.all(selectedFiles.map(validateFile))
      .then((files) => {
        // check if all files are valid
        if (_some(files, (file) => file === null || typeof file === 'string'))
          throw new Error(t('someValidationFailed'));

        // Start upload
        setUploadingState(UPLOADING_STATE.UPLOADING);
        return startUploadFn(
          files as File[],
          fileRequestId || isREE
            ? {
                ...(fileRequestId && { file_upload_request_id: fileRequestId }),
                ...(isREE && { is_real_estate_expert: true }),
              }
            : null,
        );
      })
      .then((files) => {
        // Start scanning the uploaded files
        setUploadingState(UPLOADING_STATE.SCANNING);
        return Promise.all(files.map(startScanningFile));
      })
      .then((uploadedFiles) => {
        // Check if all files have passed the scanning
        const hasPassedScanning = _every(
          uploadedFiles,
          (uploadedFile) => uploadedFile.attributes.status !== 'rejected',
        );
        if (!hasPassedScanning) {
          throw new Error(t('someVirusScanFailed'));
        }

        // On successful scan, reset upload state and call onSuccess callback
        setUploadingState(UPLOADING_STATE.FINISHED);
        resetUploadStateAfterDelay();
        uploadedFiles.forEach((uploadedFile, index) =>
          onSuccess(uploadedFile, selectedFiles[index]),
        );
      })
      .catch(showError);
  };

  const onFileSelected = (selectedFile: File) => {
    // Validate file and upload it
    validateFile(selectedFile)
      .then((file) => {
        // Check if file is valid
        if (file === null || typeof file === 'string') throw new Error(t('validationFailed'));

        // Start upload
        setUploadingState(UPLOADING_STATE.UPLOADING);
        return startUploadFn(
          file,
          fileRequestId || isREE
            ? {
                ...(fileRequestId && { file_upload_request_id: fileRequestId }),
                ...(isREE && { is_real_estate_expert: true }),
              }
            : null,
        );
      })
      .then((file) => {
        // Start scanning the uploaded file
        setUploadingState(UPLOADING_STATE.SCANNING);
        return startScanningFile(file);
      })
      .then((uploadedFile) => {
        // Check if file has passed the scanning
        const hasPassedScanning = uploadedFile.attributes.status !== 'rejected';
        if (!hasPassedScanning) {
          throw new Error(t('virusScanFailed'));
        }

        // On successful scan, reset upload state and call onSuccess callback
        setUploadingState(UPLOADING_STATE.FINISHED);
        resetUploadStateAfterDelay();
        onSuccess(uploadedFile, selectedFile);
      })
      .catch(showError);
  };

  return { onFileSelected, onFilesSelected, errorMsg, isDropping, uploadingState, setIsDropping };
};
