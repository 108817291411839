import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { IS_BENEFICIARY_OWNER } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const IsBeneficiaryOwnerRadio = () => {
  const t = useTranslations(
    'pages.peopleDetails.sections.beneficiaryOwners.fields.isBeneficiaryOwnerRadio',
  );
  const tooltipTranslation = t('tooltip', undefined, { defaultMessage: ' ' });
  const showTooltip = tooltipTranslation !== ' ';

  return (
    <YesNoRadioGroupWithField
      name={IS_BENEFICIARY_OWNER}
      tooltip={showTooltip ? tooltipTranslation : undefined}
    />
  );
};

export default IsBeneficiaryOwnerRadio;
