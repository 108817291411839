import React from 'react';

import { get as _get } from 'lodash';
import { useForm, useFormState } from 'react-final-form';

import EmptyField from 'components/EmptyField';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import {
  HausbankFieldTypes,
  hausbankFields,
  hausbankFieldsWithSectionString,
} from 'hausbank/inquiry/fields';
import CompanySuggestion from 'mittweida/inquiry/steps/company/sections/companyDetails/components/companySuggestion';
import { shouldShowCompanySuggestion } from 'mittweida/inquiry/steps/company/sections/companyDetails/shouldShowCompanySuggestion';
import { translations } from 'new/form/common/types';
import CompanyNoteSection from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanyNoteSection/CompanyNoteSection';
import { UseCompanySuggestionConfig } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useCompanySuggestion';
import { useCompanySuggestion } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useCompanySuggestion';
import { SuggestionOption } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import ExistingUserCompanySelect from 'pages/inquiryFlow/CompanyDetails/sections/Company/ExistingUserCompanySelect';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

export const WegSearchSection = () => {
  const t = useTranslations();
  const { values } = useFormState<HausbankFieldTypes>();
  const { change } = useForm();

  const {
    companyDataPage: {
      wegSearchSection: {
        crefoId,
        selectedCompanyName: selectedCompanyNameFieldName,
        loggedInUserCompanyName: loggedInUserCompanyNameFieldName,
      },
      wegDataCompletionSection: { name, street, zipCode, city },
    },
  } = hausbankFields;

  const config: UseCompanySuggestionConfig = {
    companySearchSectionString:
      hausbankFieldsWithSectionString.companyDataPage.wegSearchSection.sectionString,
    companySearchFieldNames: {
      selectedCompanyNameFieldName,
      loggedInUserCompanyNameFieldName,
    },
    companyCompletionFieldNames: {
      name,
      address: street,
      street,
      zipCode,
      city,
    },
  };
  const {
    handleSelect,
    handleExistingUserSelect,
    handleClearCompanyForm,
    handleClearExistingUserCompanyForm,
  } = useCompanySuggestion(config);

  const loggedInUserCompanyName = _get(values, loggedInUserCompanyNameFieldName);

  const isLoggedIn = isLoggedInUser();
  const showCompanySuggestions = shouldShowCompanySuggestion(isLoggedIn, loggedInUserCompanyName);

  const crefoIdChangeHandler = (value: string, companies: SuggestionOption[]) => {
    const company = companies.find((company) => company.label === value);
    if (company) {
      change(crefoId, company.value['crefo-id']);
    }
  };

  const existingUserSelectHandler = (value: any, autocompleteOptions: SuggestionOption[]) => {
    crefoIdChangeHandler(value, autocompleteOptions);
    handleExistingUserSelect(value, autocompleteOptions);
  };

  const selectHandler = (value: any, companies: SuggestionOption[]) => {
    crefoIdChangeHandler(value, companies);
    handleSelect(value, companies);
  };

  return (
    <>
      <EmptyField name={crefoId} />
      <FormSection
        title={t(translations.inquiryType.hausbank.pages.companyData.sections.wegSearch.title)}
        sectionNumber={1}
      >
        {isLoggedIn && (
          <FormRow>
            <ExistingUserCompanySelect
              fieldName={loggedInUserCompanyNameFieldName}
              onClearCompanyForm={handleClearExistingUserCompanyForm!}
              onSelect={existingUserSelectHandler}
              menuShowThreshold={0}
            />
          </FormRow>
        )}
        {showCompanySuggestions && (
          <FormRow>
            <CompanySuggestion
              fieldName={selectedCompanyNameFieldName}
              onClearCompanyForm={handleClearCompanyForm}
              onSelect={selectHandler}
            />
          </FormRow>
        )}
        <CompanyNoteSection />
      </FormSection>
    </>
  );
};
