import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import type { IAssessmentFile } from 'models/File.model';
import type { ApiAssessmentFile } from 'models/FileApi.model';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import { mapApiAssessmentFile } from 'shared/documentExchange/documentExchange.service';
import { addAssessmentFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAssessmentFiles } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const AssessmentFiles: React.FC = () => {
  const files: IAssessmentFile[] = useSelector(getAssessmentFiles);
  const inquiryId = useSelector(getInquiryIdSelector);
  const t = useTranslations('pages.inquiryDetails.documentExchange');

  const dispatch = useDispatch();

  const addFileToList = (file: ApiAssessmentFile) =>
    dispatch(addAssessmentFileAction(mapApiAssessmentFile(file)));

  return (
    <section>
      <FileSectionTitle>{t('assessmentDocuments')}</FileSectionTitle>
      {files.length ? (
        <MultipleFilesBlock
          title={t('assessmentDocuments')}
          description=""
          files={files}
          isAssessmentFile
        />
      ) : (
        <UploadAnyFile
          description={t('uploadOtherFilesDesc')}
          onUpload={addFileToList}
          url={endpoints.INQUIRIES.ASSESSMENT_FILES.LIST.compose({
            params: { id: String(inquiryId) },
          })}
          isAssessmentFile
        />
      )}
    </section>
  );
};

export default AssessmentFiles;
