import moment from 'moment';

import { HausbankFieldTypes } from 'hausbank/inquiry/fields';

type HausbankObjectInformationFieldTypes =
  HausbankFieldTypes['objectInformationPage']['objectInformationSection'];

export const mapHausbankObjectInformation = (values: HausbankObjectInformationFieldTypes) => ({
  name: values.wegName,
  street: values.street,
  zip_code: values.zipCode,
  city: values.city,
  country: values.country,
  living_space: values.livingSpace,
  commercial_usage: values.commercialUsage,
  property_size: values.propertySize,
  construction_year: moment(values.constructionYear).year(),
  last_modernization: moment(values.lastModernization).year(),
});
