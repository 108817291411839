import React from 'react';

import { useSelector } from 'react-redux';

import { FINANCING_AMOUNT, PRODUCT_KIND, PURPOSE_KIND } from 'modules/Inquiry/Form/formFields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import { StyledSummaryList as Blocks } from 'modules/InquiryDetails/DetailedInformation/SummarySection/styles';
import SummaryBlock from 'modules/InquiryDetails/DetailedInformation/SummarySection/SummaryBlock';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const SummarySection = () => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector);

  const fundingRequirements = inquiryDetails[INQUIRY_SECTIONS.FINANCING][FINANCING_AMOUNT];
  const usage = inquiryDetails[INQUIRY_SECTIONS.FINANCING][PURPOSE_KIND];
  const financingProduct = inquiryDetails[INQUIRY_SECTIONS.DETAILS][PRODUCT_KIND];

  const t = useTranslations('pages.inquiryDetails.detailed.summary');
  const financingProductT = useTranslations('data.financingProduct');
  const purposeKindT = useTranslations('data.purposeKind');

  if (!fundingRequirements || !usage || !financingProduct) {
    return null;
  }

  return (
    <Section title={t('title')}>
      <Blocks>
        <SummaryBlock name={t('financingRequirement')} value={formatPrice(fundingRequirements)} />
        <SummaryBlock name={t('usage')} value={purposeKindT(usage)} />
        <SummaryBlock name={t('financingProduct')} value={financingProductT(financingProduct)} />
      </Blocks>
    </Section>
  );
};

export default SummarySection;
