import { useMemo } from 'react';

import { useValueTranslationMap } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/useValueTranslationMap';

export const useInquiryRadioValues = (fieldName: string) => {
  const translationMap = useValueTranslationMap();
  const fieldTranslationOptions = translationMap[fieldName];

  return useMemo(() => {
    if (!fieldTranslationOptions) {
      return [];
    }
    return Object.entries(fieldTranslationOptions).map(([value, label]) => ({
      caption: label,
      value,
    }));
  }, [fieldTranslationOptions]);
};
