import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const OtherIcon = (props: IconProps) => {
  return (
    <Icon
      width="39"
      height="30"
      viewBox="0 0 38.12 29.57"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g fill="#e4e4e4">
        <path d="M33.36 11.54l-.98-.17.35-2.12H6.42l.34 2.12-.98.17-.54-3.29H33.9l-.54 3.29z" />
        <path d="M30.77 8.81l-.99-.13.3-2.33H9.06l.3 2.33-.99.13-.45-3.46h23.3l-.45 3.46z" />
        <path d="M28.16 6.25l-1-.1.24-2.53H11.74l.24 2.53-1 .1-.34-3.63H28.5l-.34 3.63zM.89 11.81l-.78-.63L8.94.19A.51.51 0 019.33 0h1.86v1H9.57zm36.33-.02L29.55 1H28V0h1.86a.52.52 0 01.41.21l7.81 11z" />
        <path d="M34.62 29.57H3.5a.5.5 0 01-.5-.42L0 11.58a.46.46 0 01.11-.4A.46.46 0 01.5 11h12.21a.5.5 0 01.45.28l1.4 2.83h9L25 11.28a.51.51 0 01.45-.28h12.17a.5.5 0 01.39.18.52.52 0 01.11.4l-3 17.57a.51.51 0 01-.5.42zm-30.7-1H34.2L37 12H25.72l-1.4 2.84a.5.5 0 01-.45.27h-9.62a.48.48 0 01-.44-.27L12.4 12H1.09z" />
        <circle cx="13.71" cy="22.33" r="1.61" />
        <circle cx="19.06" cy="22.33" r="1.61" />
        <circle cx="24.42" cy="22.33" r="1.61" />
      </g>
    </Icon>
  );
};

export default OtherIcon;
