import { FIELD_KIND_TYPE, FIELD_VALUE_TYPE } from 'modules/FormGenerator/constants';

export const getUpdatedFieldState = (changes, currentState) =>
  currentState.map((field) => {
    if (field.name === changes.name) {
      return { ...field, value: changes.value, hasError: changes.hasError };
    }
    return field;
  });

export const isFormValid = (fields) => !fields.find((field) => field.hasError);

// directly defined because false is acceptable
const invalidValues = ['', null, undefined];

export const doesFormContainsAllValues = (formElements) => {
  const fields = formElements.filter((formElement) => formElement.kind === FIELD_KIND_TYPE.FIELD);
  const hasEmptyRegularFields = fields.some(
    (field) => field.type !== FIELD_VALUE_TYPE.DOCUMENT && invalidValues.includes(field.value),
  );
  const hasEmptyDocumentFields = fields.some(
    (field) => field.type === FIELD_VALUE_TYPE.DOCUMENT && invalidValues.includes(field.value),
  );

  return !hasEmptyRegularFields && !hasEmptyDocumentFields;
};
