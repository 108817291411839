import React from 'react';

import { Box } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import FormSection from 'components/FormSection';
import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { StyledActions } from 'components/StyledActions';
import paths from 'constants/paths';
import {
  COMPANY_DETAILS_COMPANY_KYC,
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
} from 'modules/Inquiry/Form/formFields';
import { useFetchKycCompaniesData } from 'pages/customerPortal/InquiryDetails/UsageList/helpers/useFetchKycCompaniesData';
import { SelectKycPossibleCompanies } from 'pages/inquiryFlow/CompanyDetails/sections/Company/SsoCompanySection/SelectKycPossibleCompanies';
import { useToasts } from 'shared/hooks/useToasts';
import { updateKycCompanyDataAction } from 'store/inquiryDetails/actions';
import { getCompanyData } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { KycCompany } from '../LegalRepresentativeCorrection/types';

export const KycDataCorrection = () => {
  const history = useHistory();
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.resolveKycDataBank');
  const { id } = useParams<{ id: string }>();

  const { onClose, isOpen, onOpen } = useDisclosure();

  const companyData = useSelector(getCompanyData);
  const kycCompaniesData = useFetchKycCompaniesData(companyData.id);

  const navigateToDashboard = () => {
    history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id));
  };

  const handleLegalRepresentativesCancel = () => navigateToDashboard();
  const { makeCall } = useDispatchApiCall();
  const { error: notifyError, success } = useToasts();

  const handleUpdateKycCompanyData = async (form: FormApi) => {
    const selectedCompany: typeof COMPANY_DETAILS_COMPANY__NOT_FOUND | KycCompany =
      form.getFieldState(COMPANY_DETAILS_COMPANY_KYC)?.value;
    if (selectedCompany === COMPANY_DETAILS_COMPANY__NOT_FOUND) {
      notifyError({
        description: t('error'),
      });
    } else {
      const { error } = await makeCall(updateKycCompanyDataAction(selectedCompany.id, id));
      if (error) {
        notifyError({
          description: t('error'),
        });
      } else {
        success({ description: t('success') });
      }
    }
    navigateToDashboard();
    onClose();
  };

  return (
    <FinalForm
      onSubmit={() => {}}
      render={({ valid, form }) => (
        <form>
          <Box maxWidth="900px" mx="auto">
            <FormSectionLayout title="">
              <HeadingComponent as="h2" variant="secondary" mb={3}>
                {t('headline')}
              </HeadingComponent>
              <TextComponent color="text.tertiary">{t('summary')}</TextComponent>
            </FormSectionLayout>
            <FormSectionLayout
              title={t('companyToBeSearched.title')}
              sectionNumber={1}
              showSectionTitle={true}
            >
              <TextComponent color="text.tertiary">
                {`${companyData.name} - ${companyData.street}, ${companyData.zip_code} ${companyData.city}`}
              </TextComponent>
            </FormSectionLayout>

            <FormSection title={t('companySelection.title')} sectionNumber={2}>
              <SelectKycPossibleCompanies companies={kycCompaniesData} />
            </FormSection>
            <StyledActions>
              <ButtonComponent mr={4} onClick={handleLegalRepresentativesCancel} variant="tertiary">
                {t('companySelection.actions.cancel')}
              </ButtonComponent>
              <ButtonComponent
                leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                disabled={!valid}
                onClick={onOpen}
              >
                {t('companySelection.actions.submit')}
              </ButtonComponent>
            </StyledActions>
            <ModalComponent isOpen={isOpen} onClose={onClose} title={t('submitModal.heading')}>
              <TextComponent mb="2rem" mt="2rem" color="text.tertiary">
                {t('submitModal.subdescription')}
              </TextComponent>
              <StyledActions>
                <ButtonComponent mr={4} onClick={onClose} variant="tertiary">
                  {t('submitModal.actions.cancel')}
                </ButtonComponent>
                <ButtonComponent onClick={() => handleUpdateKycCompanyData(form)}>
                  {t('submitModal.actions.submit')}
                </ButtonComponent>
              </StyledActions>
            </ModalComponent>
          </Box>
        </form>
      )}
    />
  );
};
