import React, { useContext } from 'react';
import { createContext, ReactNode, useState } from 'react';

type SetDate = (action: any) => void;
type DatePickerProvider = { children: ReactNode; initialValue: Date };

const DatePickerContext = createContext<{ date: Date; setDate: SetDate } | undefined>(undefined);

export const InformationDatePickerProvider = ({ children, initialValue }: DatePickerProvider) => {
  const [date, setDate] = useState(initialValue);

  const value = { date, setDate };
  return <DatePickerContext.Provider value={value}>{children}</DatePickerContext.Provider>;
};

export const useDatePickerContext = () => {
  const context = useContext(DatePickerContext);
  if (context === undefined) {
    throw new Error('useDatePickerContext must be used within a DatePickerProvider');
  }
  return context;
};
