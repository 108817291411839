import React from 'react';

import { InputWithField } from 'components/Input';
import { MAX_INTERNATIONAL_ZIP_CODE_LENGTH } from 'constants/globalConstants';

const InternationalZipCodeWithField = ({ name }: { name: string }) => (
  <InputWithField
    name={name}
    type="text"
    pattern="[a-zA-Z0-9\s\-/,]{3,12}"
    inputMaxLimit={MAX_INTERNATIONAL_ZIP_CODE_LENGTH}
    separateThousands={false}
  />
);

export default InternationalZipCodeWithField;
