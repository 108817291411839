import React from 'react';

import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

const UserProfileHeading = () => {
  const t = useTranslations('pages.userProfile.heading');

  return <PageHeading heading={t('header')} subheading={t('subheader', { roleSpecific: true })} />;
};

export default UserProfileHeading;
