import {
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_TYPE,
  LOAN_TYPE__AMORTISABLE,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_ENERGY_EFFICIENT,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toBoolean } = fieldParsers;
export const mapSubsidiesToApi: InquiryFormMapper = (allFields) => {
  const canShowSubsidiesFields =
    toBoolean(allFields[LOAN_CONSIDER_SUBSIDIES]) &&
    allFields[LOAN_TYPE] === LOAN_TYPE__AMORTISABLE;
  if (!canShowSubsidiesFields) {
    return {};
  }

  return {
    'annual-net-profit-or-loss-class': allFields[USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS],
    'negative-equity-capital': toBoolean(allFields[USER_PROFILE_NEGATIVE_EQUITY_CAPITAL]),
    'innovative-company': toBoolean(allFields[USER_PROFILE_INNOVATIVE_COMPANY]),
    'energy-efficient': toBoolean(allFields[USER_PROFILE_ENERGY_EFFICIENT]),
  };
};
