import { UPLOAD_STATUS } from 'components/UploadBlock/constants';
import type { UploadStatusType } from 'models/types/UploadStatus.type';
import { useTranslations } from 'utils/hooks/useTranslations';

export const useUploadStatusText = (status: UploadStatusType) => {
  const t = useTranslations('components.upload');

  const statusTextMap = {
    [UPLOAD_STATUS.UPLOADED]: t('fileUploaded'),
    [UPLOAD_STATUS.NOT_UPLOADED]: t('notUploaded'),
    [UPLOAD_STATUS.UPLOADED_AND_DOWNLOADED]: t('fileUploadedDownloaded'),
  };

  return statusTextMap[status];
};
