import React from 'react';

import _get from 'lodash/get';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { checkEmailAvailabilityAction } from 'api/CompeonReverseApi/customer/actions';
import { InputWithField } from 'components/Input';
import AvailableEmailSymbol from 'modules/AvailableEmail/AvailableEmailSymbol';
import EmailNotAvailable from 'modules/AvailableEmail/EmailNotAvailable';
import { CONTACT_PERSON_EMAIL } from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import {
  isEmailAvailableSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import { useFieldValue } from 'store/hooks/useFormValues';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { combineValidators } from 'utils/validators';

export const EMAIL_FIELD_DEBOUNCE = 300;
let lastArg: any;
let lastResult: any;
export const simpleMemoize = (fn: Function) => {
  return (arg: any) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

const BfsPersonEmail: React.FC = () => {
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail',
  );

  const { meta } = useField(CONTACT_PERSON_EMAIL);

  const companyDetailsEmail = useFieldValue<string>(CONTACT_PERSON_EMAIL, '');
  const { required, isEmail } = useFieldValidators();

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  const validateEmailAvailability = simpleMemoize(async (email: string) => {
    const { payload } = await makeCall(checkEmailAvailabilityAction(email));
    const data = _get(payload, 'data.data');

    return data
      ? data.invitation_accepted
        ? () => false
        : t('errors.invitationNotAccepted')
      : null;
  });

  const isLoadingEmailAvailability = useSelector(isLoadingEmailAvailabilitySelector);
  const noAccountForEmail = useSelector(isEmailAvailableSelector);

  const shouldShowEmailIcon = !!companyDetailsEmail && isEmailRegex.test(companyDetailsEmail);

  const shouldShowLoginLink =
    !isLoadingEmailAvailability &&
    !meta.valid &&
    typeof meta.error !== 'string' &&
    !noAccountForEmail &&
    shouldShowEmailIcon;

  return (
    <div>
      <InputWithField
        name={CONTACT_PERSON_EMAIL}
        validate={combineValidators(required, isEmail, validateEmailAvailability)}
        type="email"
        caption={t('caption')}
        sideSymbol={() =>
          shouldShowEmailIcon && (
            <AvailableEmailSymbol
              isLoading={isLoadingEmailAvailability}
              isAllowedToInquiry={meta.valid}
            />
          )
        }
      />
      {shouldShowLoginLink && <EmailNotAvailable email={companyDetailsEmail} />}
    </div>
  );
};

export default BfsPersonEmail;
