import React, { useContext } from 'react';

import { useFieldArray } from 'react-final-form-arrays';

import { InputWithField } from 'components/Input';
import { BENEFICIARY_FIRST_NAME, BENEFICIARY_OWNER } from 'modules/Inquiry/Form/formFields';

import { FieldValue } from '../BeneficiaryOwners';
import { BeneficiaryOwnersContext } from '../BeneficiaryOwners.context';

const BeneficiaryFirstName: React.FC = () => {
  const { fieldName, userIndex } = useContext(BeneficiaryOwnersContext);
  const { fields } = useFieldArray<FieldValue>(BENEFICIARY_OWNER);
  const shouldBeDisabled = fields.value[userIndex]?.isAssociatedPerson;

  return (
    <InputWithField
      name={`${fieldName}.${BENEFICIARY_FIRST_NAME}`}
      trim={true}
      disabled={shouldBeDisabled}
    />
  );
};

export default BeneficiaryFirstName;
