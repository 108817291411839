import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const AddIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M18 11h-5V6a1 1 0 00-2 0v5H6a1 1 0 000 2h5v5a1 1 0 002 0v-5h5a1 1 0 000-2"
      />
    </Icon>
  );
};

export default AddIcon;
