import React from 'react';

import env from '@beam-australia/react-env';
import { Helmet } from 'react-helmet';

import { usePartnerConfig } from 'config/partner/hooks';
import {
  FreshChat,
  FreshWidget,
} from 'modules/ContactSidebar/ContactFooter/ContactDetails/FreshWorks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { EnvironmentVariable } from 'utils/environment';
import { CSP_NONCE } from 'utils/sessionStorage/keys';
import ETracker from 'utils/tracker/Etracker';
import GoogleTagManager from 'utils/tracker/GoogleTagManager';
import { TrackingTool } from 'utils/tracker/TrackingTool';
import WebtrekkTracker from 'utils/tracker/webtrekk';

import defaultFavicon from './favicon.png';

type UserCentricsData = {
  dataSettingsId: string;
  id: string;
  src: string;
};

export const Head = () => {
  const {
    meta: {
      gtmId,
      widgetId,
      chatToken,
      pageTitle,
      faviconUrl,
      webtrekkDomain,
      webtrekkId,
      etrackerId,
      trackingTool,
    },
  } = usePartnerConfig();

  const cspNonce = sessionStorage.getItem(CSP_NONCE) as string;

  const icon = faviconUrl
    ? `${env(EnvironmentVariable.LOGO_STORAGE_URL)}${faviconUrl}`
    : defaultFavicon;

  const usercentricsData = chooseSelectedInquiryTypeSpecificValue<UserCentricsData | undefined>({
    [InquiryType.default]: undefined,
    [InquiryType.bfsService]: env(EnvironmentVariable.BFSS_USERCENTRICS_DATA_SETTINGS_ID)
      ? {
          dataSettingsId: env(EnvironmentVariable.BFSS_USERCENTRICS_DATA_SETTINGS_ID),
          id: 'usercentrics-cmp',
          src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
        }
      : undefined,
  });

  return (
    <>
      {gtmId && <GoogleTagManager gtmId={gtmId} />}
      {webtrekkId && <WebtrekkTracker webtrekkId={webtrekkId} webtrekkDomain={webtrekkDomain} />}
      {widgetId && <FreshWidget widgetId={widgetId} />}
      {etrackerId && <ETracker etrekkId={etrackerId} />}
      {chatToken && <FreshChat chatToken={chatToken} />}
      {trackingTool ? <TrackingTool config={trackingTool} /> : null}
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        <link rel="shortcut icon" type="image/png" href={icon} />
        {usercentricsData && (
          <script
            nonce={cspNonce}
            id={usercentricsData.id}
            src={usercentricsData.src}
            data-settings-id={usercentricsData.dataSettingsId}
            async
          ></script>
        )}
      </Helmet>
    </>
  );
};
