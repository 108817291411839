import React from 'react';

import { FieldRenderProps } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import withFormField from 'utils/form/withFormField';
import { withIntl } from 'utils/intl';

import { Option, Select as Combobox } from './Select';

const Select = compose(withIntl, withRouter)(Combobox);
export default Select;

const SelectAdapter = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<Option> & {
  isClearable?: boolean;
}) => <Combobox {...input} {...meta} {...rest} />;

const SelectWithField = withFormField(SelectAdapter);

export { SelectWithField };
