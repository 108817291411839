import { COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFields, ValidateSectionFunction } from 'new/form/useValidateFields';
import { isLoggedInUser } from 'utils/user/conditionals';

import { mittweidaFields } from '../../../../fields';

const {
  selectedCompanyName: selectedCompanyNameFieldName,
  loggedInUserCompanyName: loggedInUserCompanyNameFieldName,
} = mittweidaFields.companyDetailPage.companyDetailsSection;

export const validateCompanyDetailsSection: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  const isLoggedIn = isLoggedInUser();
  const { loggedInUserCompanyName } = values.companyDetailPage.companyDetailsSection;

  if (!isLoggedIn) {
    return validateFields({
      fields: [selectedCompanyNameFieldName],
      values,
      form,
      conditions,
    });
  } else if (
    isLoggedIn &&
    loggedInUserCompanyName !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  ) {
    return validateFields({
      fields: [loggedInUserCompanyNameFieldName],
      values,
      form,
      conditions,
    });
  } else {
    return validateFields({
      fields: [loggedInUserCompanyNameFieldName, selectedCompanyNameFieldName],
      values,
      form,
      conditions,
    });
  }
};
