import { useEffect, useRef } from 'react';

import { ConfigController } from 'config/ConfigController';
import { useForceRender } from 'shared/hooks/useForceRender';

import { IPartnerRepo, PartnerConfig } from './types';

export const usePartnerConfig = (): PartnerConfig => {
  const partnerConfigRepo = useRef<IPartnerRepo>(ConfigController.partner);
  const forceRender = useForceRender();

  useEffect(() => {
    const subscription = ConfigController.partner.subscribe(forceRender);
    return () => ConfigController.partner.unsubscribe(subscription);
  }, [forceRender]);

  return partnerConfigRepo.current.getConfig();
};
