import {
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__DEBT_RESCHEDULING,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__OTHER,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__CREFO_FACTORING,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
} from 'modules/Inquiry/Form/formFields';

export type PurposeKind =
  | typeof PURPOSE_KIND__GOODS
  | typeof PURPOSE_KIND__CORONA
  | typeof PURPOSE_KIND__GUARANTY
  | typeof PURPOSE_KIND__DEBT_RESCHEDULING
  | typeof PURPOSE_KIND__VEHICLE
  | typeof PURPOSE_KIND__MACHINE
  | typeof PURPOSE_KIND__OFFICE_EQUIPMENT
  | typeof PURPOSE_KIND__OVERDRAFT_LIQUIDITY
  | typeof PURPOSE_KIND__REAL_ESTATE
  | typeof PURPOSE_KIND__OTHER
  | typeof PURPOSE_KIND__BUILDING
  | typeof PURPOSE_KIND__PROJECT_FINANCING
  | typeof PURPOSE_KIND__KFINANZ
  | typeof PURPOSE_KIND__CREFO_FACTORING
  | typeof PURPOSE_KIND__ENERGY_EFFICIENCY
  | typeof PURPOSE_KIND__FACTOMAT
  | typeof PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING;

export const isPurposeKind = (item: string): item is PurposeKind => {
  return [
    PURPOSE_KIND__GOODS,
    PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
    PURPOSE_KIND__CORONA,
    PURPOSE_KIND__DEBT_RESCHEDULING,
    PURPOSE_KIND__VEHICLE,
    PURPOSE_KIND__MACHINE,
    PURPOSE_KIND__OFFICE_EQUIPMENT,
    PURPOSE_KIND__REAL_ESTATE,
    PURPOSE_KIND__OTHER,
    PURPOSE_KIND__GUARANTY,
    PURPOSE_KIND__PROJECT_FINANCING,
    PURPOSE_KIND__BUILDING,
    PURPOSE_KIND__KFINANZ,
    PURPOSE_KIND__CREFO_FACTORING,
    PURPOSE_KIND__ENERGY_EFFICIENCY,
  ].includes(item);
};
