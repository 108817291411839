import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import { mmvFields } from '../../../../fields';

const fields = mmvFields.customerDataPage.contactPersonSection;
const translations =
  translationsObject.inquiryType.mmv.steps.customerDataPage.sections.contactPersonSection.fields;

export const contactPersonSectionLabelsMap: LabelsMap = {
  [fields.title]: translations.title.caption,
  [fields.firstName]: translations.firstName.caption,
  [fields.lastName]: translations.lastName.caption,
  [fields.email]: translations.email.caption,
};
