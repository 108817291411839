import { AxiosResponse } from 'axios';
import { createMutation } from 'react-query-kit';

import { AuthClientInstance } from 'api';
import endpoints from 'api/AuthApi/endpoints';
import { useMe } from 'api/CompeonReverseApi/customer/queryHooks/users';
import { ConfigController } from 'config/ConfigController';
import queryKeys from 'constants/queryKeys';
import { ApiAuthTokenReponse } from 'models/ApiAuthTokenResponse.model';
import { useAuthErrorHandler } from 'shared/hooks/useAuthErrorHandler';
import {
  AccessTokenScope,
  MutationErrorCallback,
  MutationSuccessCallback,
  QueryErrorCallback,
  QuerySuccessCallback,
} from 'types/Auth';
import { getAccessToken, getUserTokens } from 'utils/auth';
import { getExpirationDate } from 'utils/auth';
import { trackUserId, trackUserLogout } from 'utils/tracker/actions/trackUserId';
import { getAttributesFromToken, getUserAttributes } from 'utils/user/getters';
import { resetUser, setUser, updateUserAttributes } from 'utils/user/setters';

interface LoginVariables {
  email: string;
  password: string;
  scope: AccessTokenScope;
}

export interface TokenRefreshVariables {
  refreshToken: string;
}

export const updateUserData = (
  { data }: AxiosResponse<ApiAuthTokenReponse>,
  refresh: boolean = false,
) => {
  // update user with new data
  const oldAttributes = getUserAttributes();
  const newAttributes = getAttributesFromToken(data.access_token);
  const newUser = {
    ...getUserTokens(data),
    expiresAt: getExpirationDate(data.created_at, data.expires_in),
    scope: data.scope,
    attributes: refresh ? oldAttributes : newAttributes,
  };
  setUser(newUser);
};

const logIn = createMutation<AxiosResponse<ApiAuthTokenReponse>, LoginVariables>({
  mutationKey: [queryKeys.auth.login],
  mutationFn: async ({ email, password, scope }) => {
    const {
      details: { id: partnerId },
    } = ConfigController.partner.getConfig();

    return await AuthClientInstance.post(endpoints.LOG_IN.compose(), {
      username: email,
      password,
      grant_type: 'password',
      scope,
      partner: partnerId,
    });
  },
});

export const useLogin = (
  successCallback?: QuerySuccessCallback<AxiosResponse<ApiAuthTokenReponse>, LoginVariables>,
  errorCallback?: QueryErrorCallback<LoginVariables>,
) => {
  const { refetch: refetchUserDetails } = useMe({ enabled: false });
  const authErrorHandler = useAuthErrorHandler();
  const onSuccess: MutationSuccessCallback<
    AxiosResponse<ApiAuthTokenReponse>,
    LoginVariables
  > = async (data, variables, context) => {
    updateUserData(data);
    const { data: response } = await refetchUserDetails();
    if (response) {
      trackUserId({ id: response.data.data.id });
      updateUserAttributes(response.data.data.attributes);
    }
    if (successCallback) {
      successCallback(data, variables, context);
    }
  };
  const onError: MutationErrorCallback<LoginVariables> = async (error, variables, context) => {
    authErrorHandler(error);
    if (errorCallback) {
      errorCallback(error, variables, context);
    }
  };
  return logIn({ onSuccess, onError });
};

const logOut = createMutation<AxiosResponse<{}>, undefined>({
  mutationKey: [queryKeys.auth.logout],
  mutationFn: async () => {
    const token = getAccessToken();
    return await AuthClientInstance.post(endpoints.LOG_OUT.compose(), { token });
  },
});

export const useLogout = (
  successCallback?: QuerySuccessCallback<AxiosResponse<{}>, undefined>,
  errorCallback?: QueryErrorCallback<undefined>,
) => {
  const onSuccess: MutationSuccessCallback<AxiosResponse<{}>, undefined> = (
    data,
    variables,
    context,
  ) => {
    resetUser();
    trackUserLogout();
    if (successCallback) {
      successCallback(data, variables, context);
    }
  };
  const onError: MutationErrorCallback<undefined> = errorCallback;
  return logOut({ onSuccess, onError });
};
