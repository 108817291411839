import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const DeleteIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M9.14 4.5h5.72a1.08 1.08 0 001.14-1 1.08 1.08 0 00-1.14-1H9.14A1.08 1.08 0 008 3.5a1.08 1.08 0 001.14 1zM20 5.5H4a1 1 0 000 2h1v11.38a2.62 2.62 0 002.62 2.62h8.76A2.63 2.63 0 0019 18.88V7.5h1a1 1 0 000-2zm-3 13.38a.62.62 0 01-.62.62H7.62a.62.62 0 01-.62-.62V7.5h10z" />
      <path d="M10 17.5a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 17.5a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z" />
    </Icon>
  );
};

export default DeleteIcon;
