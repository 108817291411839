import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { LEASING_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const LeasingDescription = () => {
  const t = useTranslations();

  return (
    <TextAreaWithField
      name={LEASING_DESCRIPTION}
      captionTooltip={t('pages.requestDetails.sections.leasing.fields.leasingDescription.tooltip')}
      optional
    />
  );
};

export default LeasingDescription;
