export const OFFER_STATUS = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  DOWNLOADED: 'downloaded',
  PENDING: 'pending',
  DISABLED: 'disabled',
  SHOWN_INTEREST: 'shownInterest',
};

export const OFFER_STATE = {
  UNAVAILABLE: 'unavailable',
  RELEASED: 'released',
};

export const OFFER_STATUSES = Object.values(OFFER_STATUS);

export const OFFER_CREATED_AT_FORMAT = 'DD.MM.yyyy HH:mm';
