import React, { useEffect } from 'react';

import { CSP_NONCE } from 'utils/sessionStorage/keys';

export const useAppendScript = (resourceUrl: string, defer?: boolean, crossOrigin?: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    const cspNonce = sessionStorage.getItem(CSP_NONCE) as string;
    script.setAttribute('nonce', cspNonce);
    script.src = resourceUrl;
    script.async = true;
    if (defer) script.defer = defer;
    if (crossOrigin) script.crossOrigin = crossOrigin;
    document.body.appendChild(script);
  }, [crossOrigin, defer, resourceUrl]);
};

const fcSettings = (TOKEN: string) => `
  window.fcSettings = {
    token: "${TOKEN}",
    host: "https://wchat.eu.freshchat.com",
    config: {
      headerProperty: {
        hideChatButton: true
      }
    },
    onInit: function() {
      window.fcWidget.on("widget:closed", function() {
        document.getElementById('fc_frame').style.display = 'none';
      });
    }
  };
`;

const fwSettings = (ID: string) => `
  window.fwSettings={
    'widget_id':${ID},
  }
  !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
`;

export const FreshChat = ({ chatToken }: { chatToken: string }) => {
  useAppendScript('https://wchat.freshchat.com/js/widget.js');

  const hideChat = () => {
    const frame = document.getElementById('fc_frame');
    if (frame) frame.style.display = 'none';
  };

  useEffect(() => {
    const scriptSettings = document.createElement('script');
    const cspNonce = sessionStorage.getItem(CSP_NONCE) as string;
    scriptSettings.setAttribute('nonce', cspNonce);
    const scriptText = document.createTextNode(fcSettings(chatToken));
    scriptSettings.appendChild(scriptText);
    document.body.appendChild(scriptSettings);

    return () => {
      hideChat();
      document.body.removeChild(scriptSettings);
    };
  }, [chatToken]);

  return <div id="fc_frame" />;
};

export const FreshWidget = ({ widgetId }: { widgetId: string }) => {
  useAppendScript(`https://euc-widget.freshworks.com/widgets/${widgetId}.js`, true, 'anonymous');

  useEffect(() => {
    const fwScriptSettings = document.createElement('script');
    const cspNonce = sessionStorage.getItem(CSP_NONCE) as string;
    fwScriptSettings.setAttribute('nonce', cspNonce);
    const fwScriptText = document.createTextNode(fwSettings(widgetId));
    fwScriptSettings.appendChild(fwScriptText);
    document.body.appendChild(fwScriptSettings);

    (window as any).FreshworksWidget('hide', 'launcher');

    return () => {
      document.body.removeChild(fwScriptSettings);
    };
  }, [widgetId]);

  return null;
};
