import _merge from 'lodash/merge';
import _pickBy from 'lodash/pickBy';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInquiryValues } from 'components/App/InquiryValuesProvider';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { useCheckCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { typeSpecificMapping } from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { usePostNewInquiryToApi } from 'pages/inquiryFlow/SummaryPage/usePostNewInquiryToApi';
import { useSendInquiryUpdateToApi } from 'pages/inquiryFlow/SummaryPage/useSendInquiryUpdate';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { pipe } from 'utils/helpers';
import { scrollTop } from 'utils/scroll';

import { ACTIONS } from './Fastlane/actions';

const useGetBuildInquiryPayload = () => {
  const {
    details: { id: partnerShortName },
  } = usePartnerConfig();
  const inquiryId = useSelector(getInquiryIdSelector);
  const formData = useInquiryValues(partnerShortName);
  const { selectedInquiryType } = useFormConfig();

  const checkCanShowField = useCheckCanShowField();
  const filterDataByBusinessConditions = (formValues: any) =>
    _pickBy(formValues, (_value, fieldName) => checkCanShowField(fieldName));

  return (documentUploadsFileIds?: string[]) => {
    const inquiryForApi = pipe(
      filterDataByBusinessConditions,
      typeSpecificMapping[selectedInquiryType],
    )(formData);
    const additionalData = {
      data: {
        attributes: {
          'inquiry-id': inquiryId, // Passed if editing inquiry
          'file-upload-request-ids': documentUploadsFileIds || [],
        },
      },
    };
    return _merge(inquiryForApi, additionalData);
  };
};

export const useSendInquiryToApi = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const buildPayload = useGetBuildInquiryPayload();
  const history = useHistory();
  const dispatch = useDispatch();

  const postNewInquiry = usePostNewInquiryToApi();
  const updateInquiry = useSendInquiryUpdateToApi();

  return async (documentUploadsFileIds?: string[]) => {
    const rawInquiry = buildPayload(documentUploadsFileIds);

    try {
      if (inquiryId) {
        await updateInquiry(rawInquiry);
      } else {
        await postNewInquiry(rawInquiry.data);
      }
    } catch (e) {
      history.push(paths.errorDefault);
    }

    dispatch({ type: ACTIONS.RESET_STATE });
    scrollTop();
  };
};
