import {
  all,
  fieldHasValue,
  fieldsAreValid,
  getFieldValidatorIfExist,
} from 'modules/Inquiry/Form/formConditions';
import {
  INVESTMENT_LOAN_ADVANCE_PAYMENT,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  INVESTMENT_LOAN_DESCRIPTION,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  INVESTMENT_LOAN_TERM_IN_MONTHS,
} from 'modules/Inquiry/Form/formFields';

export const validateInvestmentLoanSection = (form) => {
  const alwaysAvailableFields = fieldsAreValid([
    INVESTMENT_LOAN_ADVANCE_PAYMENT,
    INVESTMENT_LOAN_TERM_IN_MONTHS,
    INVESTMENT_LOAN_AMORTISATION_KIND,
    INVESTMENT_LOAN_DESCRIPTION,
    INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  ]);

  const residentialValue = getFieldValidatorIfExist(
    INVESTMENT_LOAN_RESIDUAL_VALUE,
    fieldHasValue(
      INVESTMENT_LOAN_AMORTISATION_KIND,
      INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
    ),
  );

  return all(alwaysAvailableFields, residentialValue)({ form });
};
