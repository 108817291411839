import * as React from 'react';

import { Grid } from '@chakra-ui/react';

import { choosePartnerSpecificComponent } from 'shared/choosePartnerSpecificComponent';

import { AdditionalInformationItem } from './AdditionalInformationItem';
import { FinancingProductItem } from './FinancingProductItem';
import { ProgressItem } from './ProgressItem';
import { InquiryType } from '../../../../../modules/Inquiry/Inquiry.type';
import { DzbSummaryCard } from '../DzbSummaryCard';
import { HausbankSummaryCard } from '../HausbankSummaryCard';
import { MmvSummaryCard } from '../MmvSummaryCard';

export type SummaryCardProps = {
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  openTaskId?: string;
  canShowProgress?: boolean;
};

const SummaryCard = ({
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  openTaskId,
  canShowProgress = true,
}: SummaryCardProps) => {
  return (
    <Grid
      templateColumns={['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)']}
      gap={12}
      as="section"
      border="1px"
      borderColor="border.lightGrey"
      p={8}
      boxShadow="default"
      bgColor="background.white"
    >
      <FinancingProductItem />
      <AdditionalInformationItem />
      <ProgressItem
        isCompleted={isCompleted}
        completedValue={completedValue}
        progressIncrement={progressIncrement}
        progressTotal={progressTotal}
        openTaskId={openTaskId}
        canShowProgress={canShowProgress}
      />
    </Grid>
  );
};

export default choosePartnerSpecificComponent({
  [InquiryType.mmv]: MmvSummaryCard,
  [InquiryType.dzb]: DzbSummaryCard,
  [InquiryType.hausbank]: HausbankSummaryCard,
  default: SummaryCard,
});
