import React from 'react';

import PropTypes from 'prop-types';

import DownloadLink from './DownloadLink';
import { StyledDownloadList } from './styles';

const OffersList = ({ offers }) => (
  <StyledDownloadList>
    {offers.map(({ id, bankName, fileId, createdAt }) => (
      <li key={id}>
        <DownloadLink fileId={fileId} fileName={bankName} date={createdAt} />
      </li>
    ))}
  </StyledDownloadList>
);

OffersList.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OffersList;
