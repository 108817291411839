import Joi from '@hapi/joi';

import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { beneficialOwnerFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { beneficialOwnerValidations } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/validations';

const {
  firstName,
  lastName,
  nationality,
  capitalOrVotingRightsPercentage,
  countryOfResidence,
  placeOfResidence,
  birthPlace,
  birthDate,
} = beneficialOwnerFields;

export const beneficialOwnerSchema: Joi.ObjectSchema<any> = fieldValidators
  .object()
  .keys({
    [firstName]: beneficialOwnerValidations.firstName.required(),
    [lastName]: beneficialOwnerValidations.lastName.required(),
    [nationality]: beneficialOwnerValidations.nationality.optional().allow(null).allow(''),
    [capitalOrVotingRightsPercentage]: beneficialOwnerValidations.capitalOrVotingRightsPercentage
      .optional()
      .allow(null)
      .allow(''),
    [countryOfResidence]: beneficialOwnerValidations.countryOfResidence
      .optional()
      .allow(null)
      .allow(''),
    [placeOfResidence]: beneficialOwnerValidations.placeOfResidence
      .optional()
      .allow(null)
      .allow(''),
    [birthPlace]: beneficialOwnerValidations.birthPlace.optional().allow(null).allow(''),
    [birthDate]: beneficialOwnerValidations.birthDate.optional().allow(null).allow(''),
  })
  .unknown(true)
  .strip();
