import { useFormState } from 'react-final-form';

import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';

export const useGetSelectedCompany = () => {
  const { values } = useFormState<MittweidaFieldTypes>();
  const companyDetails = values.companyDetailPage.completingCompanyDetailsSection?.company;

  return companyDetails;
};
