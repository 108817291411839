import { PROGRESS_STATE_ITEM_KEY } from 'constants/globalConstants';
import { saveObjectToSessionStorage } from 'utils/sessionStorage/helpers';

import {
  SET_PROGRESS_COUNTER,
  SET_CURRENT_STAGE,
  STORE_VALUES,
  DISABLE_REDIRECTING_TO_FIRST_PAGE,
  CLEAR_STAGES,
  RESET_FORM,
  RESET_STAGE_PROGRESS,
  SAVE_CREATED_INQUIRY_ID,
} from './actionTypes';

export const setProgressCounter =
  ({ stage, current, total }) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_PROGRESS_COUNTER,
      payload: {
        stage,
        current,
        total,
      },
    });
    saveObjectToSessionStorage(PROGRESS_STATE_ITEM_KEY, getState().progress.stages);
  };

export const SET_PROGRESS_COUNTER_TO_MAX = 'SET_PROGRESS_COUNTER_TO_MAX';
export const setProgressCounterToMax = (stageName) => (dispatch) => {
  dispatch({
    type: SET_PROGRESS_COUNTER_TO_MAX,
    payload: stageName,
  });
};

export const setCurrentStage = (currentStage) => (dispatch) =>
  dispatch({
    type: SET_CURRENT_STAGE,
    payload: {
      currentStage,
    },
  });

export const storeValues = (values) => (dispatch) => {
  const payload = { ...values };

  return dispatch({
    type: STORE_VALUES,
    payload,
  });
};

export const initStoredValues = (payload) => (dispatch) =>
  dispatch({
    type: STORE_VALUES,
    payload,
  });

export const clearStages = () => (dispatch) =>
  dispatch({
    type: CLEAR_STAGES,
  });

export const resetProgress = () => (dispatch) =>
  dispatch({
    type: RESET_STAGE_PROGRESS,
  });

export const resetForm = (shouldResetForm) => (dispatch) =>
  dispatch({
    type: RESET_FORM,
    payload: {
      shouldResetForm,
    },
  });

export const disableRedirectingToFirstPage = () => (dispatch) =>
  dispatch({ type: DISABLE_REDIRECTING_TO_FIRST_PAGE });

export const setCreatedInquiryId = (inquiryId) => (dispatch) => {
  dispatch({
    type: SAVE_CREATED_INQUIRY_ID,
    payload: inquiryId,
  });
};

export const SET_SECTION_VALIDATION = 'SET_SECTION_VALIDATION';
export const RESET_SECTION_VALIDATION = 'RESET_SECTION_VALIDATION';
export const updateSectionValidationProgress = (changes) => (dispatch) =>
  dispatch({
    type: SET_SECTION_VALIDATION,
    payload: changes,
  });
