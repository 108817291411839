import { addDays, subDays, subYears, startOfYear } from 'date-fns';
import moment from 'moment';

export const InquiryConstants = {
  MIN_FINANCING_AMOUNT: 1000,
  MIN__AMOUNT_5000: 5000,
  MIN__AMOUNT_10000: 10000,
  MIN__AMOUNT_100000: 100000,
  GOODS_SUPPLIER_MAX_LENGTH: 100,
  GOODS_DELIVERY_MIN_DATE: new Date(1000, 0, 1),
  GOODS_DELIVERY_MAX_DATE: new Date(3000, 0, 1),
  MAX_PERIOD_OF_USE_LENGTH: 9,
  MAX_MACHINE_MANUFACTURER_LENGTH: 100,
  MAX_MACHINE_KIND_LENGTH: 100,
  MAX_MACHINE_COUNT: 999,
  MIN_VEHICLE_COUNT: 1,
  MAX_VEHICLE_COUNT: 250,
  MAX_VEHICLE_COUNT_LESS_THAN_1000: 1000,
  MIN_VEHICLE_YEAR: 1950,
  CURRENT_MOMENT_YEAR: moment().year(),
  CURRENT_YEAR: new Date(),
  YESTERDAY: subDays(new Date(), 1),
  TOMORROW: addDays(new Date(), 1),
  TEN_DAY_FROM_NOW: addDays(new Date(), 10),
  VEHICLE_MAX_DESCRIPTION: 100,
  VEHICLE_MANUFACTURER_MAX_LENGTH: 100,
  VEHICLE_MODEL_MAX_LENGTH: 100,
  QUICKCREDIT_MINIMUM_EMPLOYEES_NUMBER: 10,
  MIN_REAL_ESTATE_SCHEDULE_DATE: new Date(1000, 0),
  MIN_NUMBER_OF_LIFTS: 0,
  MAX_REAL_ESTATE_SCHEDULE_DATE: new Date(3000, 0),
  MIN_REAL_ESTATE_CONSTRUCTION_DATE: new Date(1000, 0),
  MAX_REAL_ESTATE_CONSTRUCTION_DATE: new Date(3000, 0),
  MAX_OBJECT_MEASURE_DESCRIPTION_LENGTH: 500,
  MAX_OBJECT_ADDRESS_LENGTH: 100,
  MAX_OBJECT_CITY_LENGTH: 100,
  MAX_OBJECT_ANCHOR_TENANT_LENGTH: 100,
  MAX_POSTAL_LENGTH: 100,
  MAX_PERCENT_VALUE: 100,
  MAX_BUILDING_TYPE_DESCRIPTION: 100,
  MAX_BUILDING_USAGE_SPACE: 999999999,
  MAX_BUILDING_LIVING_SPACE: 999999999,
  MAX_NUMBER_OF_LIFTS: 999999999,
  MAX_BUILDING_COMMERCIAL_SPACE: 999999999,
  MAX_BUILDING_STREET_LENGTH: 100,
  MAX_BUILDING_PLACE_LENGTH: 100,
  MAX_LOAN_DURATION: 120,
  CONTRACT_AND_OFFER_MIN_LOAN_DURATION: 12,
  CONTRACT_AND_OFFER_MAX_LOAN_DURATION: 84,
  PROPERTY_LOAN_TERM_IN_MONTHS_MAX_LENGTH: 360,
  MAX_INFORMATION_FOR_COMPEON_LENGTH: 500,
  MAX_ADDITIONAL_INFO_LENGTH: 500,
  MAX_LEASING_TERM_IN_MONTHS: 999,
  INVESTMENT_LOAN_MAX_TERM_IN_MONTHS: 999,
  HIRE_PURCHASE_MAX_TERMS_IN_MONTHS: 999,
  COMPANY_NAME_MAX_LENGTH: 100,
  COMPANY_STREET_MAX_LENGTH: 100,
  COMPANY_CONTACT_PERSON_MAX_LENGTH: 100,
  COMPANY_CITY_MAX_LENGTH: 100,
  MIN_FOUNDING_YEAR: 1900,
  MAX_USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY: 100,
  CORONA_FOUNDING_YEAR_THRESHOLD: 2019,
  CORONA_FOUNDING_MONTH_THRESHOLD: 1,
  COMPANY_YOUNG_THRESHOLD: 3,
  MAX_NUMBER_OF_EMPLOYEES: 999999,
  MAX_VM_NUMBER: 9999999,
  MAX_END_USER_FIRST_NAME: 100,
  MAX_END_USER_LAST_NAME: 100,
  MIN_BENEFICIARY_COMPANY_PERCENTAGE: 0,
  MIN_BFS_SERVICE_BENEFICIARY_COMPANY_PERCENTAGE: 25.01,
  MAX_BENEFICIARY_COMPANY_PERCENTAGE: 100,
  MAX_AGRICULTURAL_AMOUNT: 99999999,
  MAX_AGRICULTURAL_MACHINES: 9999,
  MAX_AGRICULTURAL_IT_EQUIPMENT: 9999,
  MAX_AGRICULTURAL_DESCRIPTION_LENGTH: 1000,
  MAX_AGRICULTURAL_OFFER_PROVIDER_NAME: 100,
  MAX_AGRICULTURAL_LOCATION_LENGTH: 50,
  MAX_AGRICULTURAL_PARCEL_LENGTH: 20,
  MAX_AGRICULTURAL_DISTRICT_LENGTH: 20,
  MAX_AGRICULTURAL_MAX_SUBJECT_CAPTION: 30,
  MAX_AGRICULTURAL_IT_EQUPIMENT_PERIOD_END: new Date('2023-01-01'),
  MAX_AGRICULTURAL_REALESTATE_PERIOD_END: new Date('2023-01-01'),
  MAX_AGRICULTURAL_CONSULTING_PERIOD_END: new Date('2023-01-01'),
  MAX_COMPANY_REGISTRATION_NUMBER: 20,
  SUBJECT_STATIC_INTENSITY: 40,
  MAX_LEGAL_FORM_DESCRIPTION: 50,
  MAX_SUM_EXPECTED_GRANT_AMOUNT: 400000,
  MAX_ASSOZIATION_PRIMARY_AGRICULTURAL_PRODUCTION: 250000,
  MAX_AGRICULTURAL_MACHINERY_GRANT_AMOUNT: 100000,
  MIN_SUM_EXPECTED_ELIGIBLE_INVEST: 10000,
  MAX_EXECUTION_DATE: new Date(2022, 12, 1),
  MAX_FORESTRY_COMPANY_NUMBER_LENGTH: 20,
  MAX_FORESTRY_USAGE_LIST_REPORT_LENGTH: 1000,
  MAX_FORESTRY_USAGE_LIST_INVOICE_NUMBER_LENGTH: 30,
  MAX_FORESTRY_USAGE_LIST_NAME_OF_PROVIDER_LENGTH: 50,
  MAX_FORESTRY_USAGE_LIST_ADDRESS_OF_PROVIDER_LENGTH: 50,
  MAX_FORESTRY_USAGE_LIST_CITY_OF_PROVIDER_LENGTH: 50,
  MAX_FORESTRY_USAGE_LIST_AMOUNT: 999999999.99,
  MIN_FORESTRY_USAGE_LIST_NON_SUBSIDABLE_COSTS: 0,
  FORESTRY_USAGE_LIST_REQUESTED_PAYMENT_THRESHOLD: 0,
  MIN_BUILDING_BORROWED_CAPITAL: 1000,
  MIN_BIRTHDATE: startOfYear(subYears(new Date(), 100)),
  MIN_SAFETY_MARKDOWN: 0,
  MAX_SAFETY_MARKDOWN: 100,
  MIN_PROJECT_COSTING_CURRENCY_VALUE: 0,
  MAX_PROJECT_COSTING_CURRENCY_VALUE: 99999999.99,
  PROJECT_COSTING_VALUE_CURRENCY_DECIMALS: 2,
  MIN_EXTRA_MARKDOWN: 0,
  MAX_EXTRA_MARKDOWN: 99999999,
  MAX_MARKDOWN_DECIMALS: 2,
  MIN_PLANNED_SALES_PRICE: 0,
  MAX_PLANNED_SALES_PRICE: 99999999.99,
  MIN_BUILDING_YEAR: 1950,
  MITTWEIDA_MIN_BUILDING_YEAR: 1000,
  MAX_BUILDING_YEAR: new Date().getFullYear() + 5,
  MIN_PLANNED_DATE: new Date('1950-01-01'),
  MAX_PLANNED_DATE: new Date(`${new Date().getFullYear() + 5}-01-01`),
  MAX_PARKING_LOTS: 1000,
  MAX_PARKING_LOT_PRICE: 1000000000,
  MAX_USAGE_SPACE: 1000000000,
  MAX_MITTWEIDA_SALES_PRICE: 1000000000,
  MAX_MITTWEIDA_NUMBER_OF_UNITS: 1000,
  MAX_MITTWEIDA_RENT: 1000000000,
  MAX_MITTWEIDA_NOTES_ON_PERMIT_LENGTH: 2000,
  MAX_DZB_EDUCATION_LENGTH: 255,
  MAX_DZB_PROFESSIONAL_CAREER_BACKGROUND_LENGTH: 255,
  MAX_DZB_NUMBER_OF_STORES: 999,
  MAX_DZB_NUMBER_OF_EMPLOYEES: 99999,
  MAX_YOUNG_COMPANY_AGE_MONTHS: 36,
  MAX_DZB_EXPERIENCE_IN_YEARS_LENGTH: 255,
  MIN_HAUSBANK_COMPANY_FOUNDING_YEAR_DATE: new Date('1900-01-01'),
  MAX_DZB_FORM_OF_LEADERSHIP_EXPERIENCE_LENGTH: 255,
};
