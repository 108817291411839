import styled from 'styled-components/macro';

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  > main {
    flex: 1 1 0%;
  }
`;
