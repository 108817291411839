import styled, { keyframes } from 'styled-components/macro';

import { StyleFileBlockCircle } from 'components/FileBlock/styles';
import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { borderRadius } from 'theme/themeConstants';

export const StyledUploaderContent = styled.div`
  opacity: 1;
  width: 100%;
  text-align: center;
`;

export const StyledUploadedInfo = styled.div`
  opacity: 0.5;
  margin-top: 0.5rem;
  font-style: italic;
`;

export const StyledUploaderPlaceholder = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
  font-weight: ${FONT_WEIGHT_BOLD};
  text-align: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledUploadWrapperCommon = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 22.5rem;
`;

export const StyledUploadScanningWrapper = styled(StyledUploadWrapperCommon)`
  transform: scale(1);
  text-align: center;
`;

const rotate = keyframes`
  0% {
    opacity: 0.5;
    transform: rotate(0deg);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
    transform: rotate(360deg);
  }
`;

export const StyledUploadFinishWrapper = styled(StyledUploadWrapperCommon)`
  &.error {
    ${StyleFileBlockCircle} {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 4px solid ${({ theme }) => theme.colors.status.error};
        border-radius: ${borderRadius.circle};
        animation: ${rotate} 3s ease-in-out;
      }
    }
  }

  p {
    text-align: center;
  }
`;
