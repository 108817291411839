import React from 'react';

import { bool, string } from 'prop-types';
import ReactDOM from 'react-dom';
import gtmParts from 'react-google-tag-manager';

import { CSP_NONCE } from 'utils/sessionStorage/keys';

// How GoogleTagManager works:
// 1. GoogleTagManager component is rendered, a noscript tag and a span are created which holds the textContent of the script tag that will be created in the header
// 2. componentDidMount runs and if the data layer is not present in window the createGtmScriptTagInHead function is called
// 3. The span is used to generate a textNode which holds the textContent
// 4. A new script tag is created and the textNode (script content) is appended to it
// 5. The script tag is appended to the head of the document and will load

class GoogleTagManager extends React.Component {
  componentDidMount() {
    const { dataLayerName } = this.props;

    if (!window[dataLayerName]) {
      this.createGtmScriptTagInHead();
    }
  }

  createGtmScriptTagInHead = () => {
    const nonceAwareGtmScriptText = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
    n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${this.props.gtmId}');
    `;
    const scriptText = document.createTextNode(nonceAwareGtmScriptText);

    const script = document.createElement('script');
    script.appendChild(scriptText);
    const cspNonce = sessionStorage.getItem(CSP_NONCE);
    script.setAttribute('nonce', cspNonce);
    script.setAttribute('data-nonce', cspNonce);
    script.setAttribute('id', 'gtmScript');

    if (document.head.prepend) {
      document.head.prepend(script);
    } else {
      document.head.appendChild(script);
    }
  };

  render() {
    const { dataLayerName, gtmId, previewVariables } = this.props;
    const { body } = document;
    const gtm = gtmParts({
      id: gtmId,
      dataLayerName,
      previewVariables,
    });

    return ReactDOM.createPortal(gtm.noScriptAsReact(), body);
  }
}

GoogleTagManager.propTypes = {
  gtmId: string.isRequired,
  dataLayerName: string,
  previewVariables: bool,
};

GoogleTagManager.defaultProps = {
  dataLayerName: 'dataLayer',
  previewVariables: false,
};

export default GoogleTagManager;
