import * as React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGetIndustryOptions } from './useGetIndustryOptions';

type Props = {
  name: string;
};

export const ProfileIndustrySelect = ({ name }: Props) => {
  const partnerIndustryOptions = useGetIndustryOptions();
  const t = useTranslations();

  return (
    <SelectWithField
      name={name}
      placeholder={t(translations.placeholders.pleaseChoose)}
      options={partnerIndustryOptions || []}
      data-testid={'industry-select-field'}
    />
  );
};
