import React from 'react';

import Spinner from 'components/Spinner';

interface LoadingComponentProps {
  isLoading: boolean;
  children: React.ReactNode;
}
export const LoadingComponent = ({ isLoading, children }: LoadingComponentProps) =>
  isLoading ? <Spinner /> : <>{children}</>;
