/**
 * Recursively removes all null values from a given object and its nested objects.
 *
 * @param obj - The input object that may contain null values.
 * @returns { [key: string]: any } - A new object with the same structure as the input object,
 *                                   but with all null values removed from itself and its nested objects.
 *
 * @example
 * const input = {
 *   key1: "test",
 *   key2: null,
 *   key3: {
 *     key4: null,
 *     key5: "nested",
 *     key6: {
 *       key7: null,
 *       key8: "deeplyNested"
 *     }
 *   }
 * }
 *
 * const output = removeNullValuesRecursively(input);
 *
 * console.log(output);
 * // Output:
 * // {
 * //   key1: "test",
 * //   key3: {
 * //     key5: "nested",
 * //     key6: {
 * //       key8: "deeplyNested"
 * //     }
 * //   }
 * // }
 */
export function removeNullValuesRecursively(obj: { [key: string]: any }): { [key: string]: any } {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNullValuesRecursively(item));
  }

  const newObj = { ...obj };
  for (const key in newObj) {
    if (newObj[key] === null) {
      delete newObj[key];
    } else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      newObj[key] = removeNullValuesRecursively(newObj[key]);
    }
  }
  return newObj;
}
