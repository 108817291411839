import {
  FACTORING_TURNOVER_CLASS,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_COMMERCIAL_CREDIT_INSURANCE,
  FACTORING_ACTIVE_CUSTOMERS,
  FACTORING_BILLS_RANGE,
  FACTORING_ALREADY_IN_USE,
  FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
  FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON,
  FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toInteger, toBoolean } = fieldParsers;
export const mapFactoringToApi: InquiryFormMapper = (allFields) => ({
  'factoring-turnover-class': allFields[FACTORING_TURNOVER_CLASS],
  'factoring-annual-net-profit-or-loss-class': allFields[FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS],
  'factoring-foreign-share': toInteger(allFields[FACTORING_FOREIGN_SHARE]),
  'factoring-turnover-share-with-largest-customer': toInteger(
    allFields[FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER],
  ),
  'factoring-average-period-of-payment': allFields[FACTORING_AVERAGE_PERIOD_OF_PAYMENT],
  'factoring-commercial-credit-insurance': toBoolean(
    allFields[FACTORING_COMMERCIAL_CREDIT_INSURANCE],
  ),
  'factoring-active-customers': toInteger(allFields[FACTORING_ACTIVE_CUSTOMERS]),
  'factoring-bills-range': allFields[FACTORING_BILLS_RANGE],
  'factoring-already-in-use': toBoolean(allFields[FACTORING_ALREADY_IN_USE]),
  'factoring-already-on-hand-at-factoring-company': toBoolean(
    allFields[FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY],
  ),
  'factoring-additional-information-for-compeon':
    allFields[FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON],
  'factoring-additional-information-for-banks':
    allFields[FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS],
});
