import React from 'react';

import { TextAreaWithField } from 'components/TextArea';
import { HIRE_PURCHASE_DESCRIPTION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const HirePurchaseDescription = () => {
  const t = useTranslations();

  return (
    <TextAreaWithField
      name={HIRE_PURCHASE_DESCRIPTION}
      captionTooltip={t(
        'pages.requestDetails.sections.hirePurchase.fields.hirePurchaseDescription.tooltip',
      )}
      optional
    />
  );
};

export default HirePurchaseDescription;
