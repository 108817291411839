import { dzbFields } from 'dzb/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  professionalCareer,
  alreadyGainedLeadershipExperience,
  leadershipExperienceYears,
  industryExperienceYears,
  formOfLeadershipExperience,
  education,
} = dzbFields.companyDataPage.startUpQuestionnaireSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.dzb.pages.companyData.sections.startUpQuestionnaire;

export const startUpQuestionnaireSectionLabelsMap: LabelsMap = {
  [professionalCareer]: fieldTranslations.professionalCareer.caption,
  [alreadyGainedLeadershipExperience]: fieldTranslations.alreadyGainedLeadershipExperience.caption,
  [leadershipExperienceYears]: fieldTranslations.leadershipExperienceYears.caption,
  [industryExperienceYears]: fieldTranslations.industryExperienceYears.caption,
  [formOfLeadershipExperience]: fieldTranslations.formOfLeadershipExperience.caption,
  [education]: fieldTranslations.education.caption,
};
