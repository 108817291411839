import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { TextAreaWithField } from 'components/TextArea';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  OFFICE_EQUIPMENT_PRICE,
  OFFICE_EQUIPMENT_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

export const validateOfficeEquipmentSection = validateSectionFields([
  OFFICE_EQUIPMENT_PRICE,
  OFFICE_EQUIPMENT_DESCRIPTION,
]);

const OfficeEquipment = () => {
  const t = useTranslations();
  return (
    <FormSection
      title={t('pages.financingNeed.sections.officeEqiupment.sections.operatingAndOfficeEquipment')}
      name={FormSections.machine}
      sectionNumber={3}
    >
      <FormRow>
        <AmountWithField
          name={OFFICE_EQUIPMENT_PRICE}
          caption={t('pages.financingNeed.sections.officeEqiupment.fields.purchasePrice.caption')}
        />
      </FormRow>
      <FormRow>
        <TextAreaWithField
          name={OFFICE_EQUIPMENT_DESCRIPTION}
          caption={t('pages.financingNeed.sections.officeEqiupment.fields.description.caption')}
          placeholder={t(
            'pages.financingNeed.sections.officeEqiupment.fields.description.placeholder',
          )}
        />
      </FormRow>
    </FormSection>
  );
};

export default OfficeEquipment;
