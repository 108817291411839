import { AxiosResponse } from 'axios/index';
import { createMutation } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';

import endpoints from '../../endpoints';

interface UseFinishObjectConfirmationMutationVariables {
  inquiryId: string;
}

export const useFinishObjectConfirmationMutation = createMutation<
  unknown,
  UseFinishObjectConfirmationMutationVariables
>({
  mutationFn: (variables) =>
    EcoBankingAxiosClientAuthedInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.MMV.OBJECT_CONFIRMATION.FINISH.compose({
        params: { inquiryId: variables.inquiryId },
      }),
    ),
});

export interface UseObjectConfirmationFilledDocumentMutationVariables {
  inquiryId: string;
}

// Here mutations are used for a get request as a conscious decision because the query variables need to be passed for every request to get the file download
export const useObjectConfirmationFilledDocumentMutation = createMutation<
  AxiosResponse<Blob>,
  UseObjectConfirmationFilledDocumentMutationVariables
>({
  mutationKey: [queryKeys.partners.mmv.objectConfirmationFilledDocument],
  mutationFn: ({ inquiryId }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.SPECIALIZED.MMV.OBJECT_CONFIRMATION.FILLED_DOCUMENT.compose({
        params: { inquiryId },
      }),
      { responseType: 'blob' },
    ),
});
