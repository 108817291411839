import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const FactoringIcon = (props: IconProps) => {
  return (
    <Icon
      width="50"
      height="35"
      viewBox="0 0 49.39 34.62"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path
          stroke-miterlimit="10"
          d="M18.22 26.26c1.33 0 1.17-2 1.92-1.91s.25 1.58 1.33 1.66.58-1.41 1.33-1.41.42 1 1.92 1 3.67-2.34 3.67-2.34"
        ></path>
      </g>
      <g fill="#e4e4e4">
        <path d="M32 34.62H12a4.33 4.33 0 0 1-4.33-4.33v-26A3.33 3.33 0 0 0 1 4.33v1.55h5v1H0V4.33a4.33 4.33 0 0 1 8.66 0v26a3.33 3.33 0 0 0 6.66 0v-.5h21v.5A4.33 4.33 0 0 1 32 34.62zm-17.19-1H32a3.34 3.34 0 0 0 3.3-2.83h-19a4.36 4.36 0 0 1-1.54 2.83zm-.95-27.39h7.78v1h-7.78z"></path>
        <path d="M13.86 8.64H25.7v1H13.86zm0 4.82H25.7v1H13.86zm0 2.41H25.7v1H13.86zm0-4.82h10.25v1H13.86zm0 7.24h10.25v1H13.86zm23.76-1.99l-2.22-2.22a.5.5 0 0 1 0-.71L46.31 2.45a.51.51 0 0 1 .71 0l2.23 2.22a.51.51 0 0 1 0 .71L38.33 16.3a.5.5 0 0 1-.71 0zm-1.16-2.58L38 15.24 48.19 5l-1.52-1.49z"></path>
        <path d="M31.9 22l-2.23-2.2a.51.51 0 0 1 0-.71l5.73-5.72a.48.48 0 0 1 .7 0l2.23 2.22a.51.51 0 0 1 0 .71L32.61 22a.5.5 0 0 1-.71 0zm-1.17-2.58L32.25 21l5-5-1.52-1.52zM37.78 8l.71.71 4.78-4.79 3.4 3.4.71-.7-4.11-4.11L37.78 8z"></path>
        <path d="M28.6 23.1a.48.48 0 0 1-.12-.51l1.07-3.3a.5.5 0 0 1 .36-.33.5.5 0 0 1 .47.13l2.23 2.23a.49.49 0 0 1-.2.82l-3.3 1.08a.5.5 0 0 1-.51-.12zm1.65-2.73L29.73 22l1.59-.52z"></path>
        <path d="M27.85 23.85a.51.51 0 0 1 0-.71l.75-.75a.5.5 0 0 1 .7 0 .5.5 0 0 1 0 .71l-.74.75a.51.51 0 0 1-.71 0zM30.77 18.13v.25l1-.8V4.33A4.33 4.33 0 0 0 27.44 0H4.33v1h23.11a3.33 3.33 0 0 1 3.33 3.33zm0 4.23v7.93h1v-8.43l-1 .5z"></path>
      </g>
    </Icon>
  );
};

export default FactoringIcon;
