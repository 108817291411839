import styled from 'styled-components/macro';

import { inputStyles, fixInputWidthAndHeight, inputPaddingRight } from 'styles/form';

const StyledInput = styled.input`
  ${inputStyles()};
  ${fixInputWidthAndHeight()};
  ${inputPaddingRight()};
  ${(props) => props.height && 'height: '.concat(props.height)}
`;

export default StyledInput;
