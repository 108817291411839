import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Form, useForm } from 'react-final-form';

import FormRow from 'components/FormRow';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  REPRESENTATIVE_PHONE_NUMBER,
  REPRESENTATIVE_EMAIL,
  USER_REPRESENTATIVES,
  REPRESENTATIVE_GENDER,
} from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { AddFormValues } from './types';

interface AddLegalRepresentativeFormProps {
  handleAddFormCancel: () => void;
  handleAddFormSubmit: (
    formValues: AddFormValues,
    fetchedValues?: Partial<IAssociatedPerson>,
  ) => void;
  associatedPersons: IAssociatedPerson[];
}

export const AddLegalRepresentativeForm = ({
  handleAddFormCancel,
  handleAddFormSubmit,
  associatedPersons,
}: AddLegalRepresentativeFormProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection');
  const [person, setPerson] = React.useState<Partial<IAssociatedPerson> | undefined>();

  return (
    <Flex justifyContent="center">
      <Box maxWidth="500px">
        <TextComponent mb="2rem" color="text.tertiary">
          {t('newLegalForm.summary')}
        </TextComponent>
        <Form
          onSubmit={(values: AddFormValues) => handleAddFormSubmit(values, person)}
          render={({ handleSubmit, valid }) => (
            <RenderedForm
              onSubmit={handleSubmit}
              onClose={handleAddFormCancel}
              associatedPersons={associatedPersons}
              valid={valid}
              person={person}
              setPerson={setPerson}
            />
          )}
        />
      </Box>
    </Flex>
  );
};

interface RenderedFormProps {
  onSubmit: (event: React.FormEvent) => void;
  onClose: () => void;
  valid: boolean;
  associatedPersons: IAssociatedPerson[];
  person?: Partial<IAssociatedPerson>;
  setPerson: React.Dispatch<React.SetStateAction<Partial<IAssociatedPerson> | undefined>>;
}

const RenderedForm = ({
  onSubmit,
  onClose,
  valid,
  associatedPersons,
  person,
  setPerson,
}: RenderedFormProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentativeCorrection');
  const { change } = useForm();

  const { required } = useFieldValidators();

  const handleSelectChange = (id: string) => {
    setPerson(() => associatedPersons.find((person) => person.id === id));
  };

  React.useEffect(() => {
    change(REPRESENTATIVE_EMAIL, person?.email || '');
    change(REPRESENTATIVE_PHONE_NUMBER, person?.phoneNumber || '');
    change(REPRESENTATIVE_GENDER, person?.salutation || '');
  }, [change, person]);

  return (
    <form onSubmit={onSubmit}>
      <FormRow space="2rem">
        <SelectWithField
          onChange={handleSelectChange}
          name={USER_REPRESENTATIVES}
          caption={t('fieldLabels.kycRepresentatives')}
          validate={required}
          options={associatedPersons.map(({ id, firstName, lastName, birthDate }) => ({
            value: id,
            label: `${firstName} ${lastName}${birthDate ? `, geb. ${birthDate}` : ''}`,
          }))}
          data-testid={'select-field-' + USER_REPRESENTATIVES}
        />
      </FormRow>
      <FormRow space="2rem">
        <GenderWithField name={REPRESENTATIVE_GENDER} caption={t('fieldLabels.gender')} />
      </FormRow>
      <FormRow space="2rem">
        <InputWithField name={REPRESENTATIVE_EMAIL} />
      </FormRow>
      <FormRow space="2rem">
        <InputWithField name={REPRESENTATIVE_PHONE_NUMBER} />
      </FormRow>

      <Flex justifyContent="flex-end" mt="2rem">
        <ButtonComponent mr={4} onClick={onClose} variant="tertiary">
          {t('actions.cancel')}
        </ButtonComponent>
        <ButtonComponent type="submit" disabled={!valid}>
          {t('actions.submitChanges')}
        </ButtonComponent>
      </Flex>
    </form>
  );
};
