import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { setCurrentStage as setCurrentStageAction } from 'store/progress/actions';

// Some legacy function to update progress bar to step 5
export const useSetProgressBarStage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStageAction(5));
  }, [dispatch]);
};
