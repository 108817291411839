import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { getKfwProgram } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const PresentKfwProgram: React.FC = () => {
  const { kfwProgram } = useSelector(getKfwProgram);
  const t = useTranslations('pages.coronaStep.fields.kfwProgram');
  return <StaticField caption={t('caption')} text={kfwProgram} />;
};

export default PresentKfwProgram;
