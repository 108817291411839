import React, { useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';

import submitBankOfferAction from 'api/CompeonReverseApi/operation/submitBankOffer/actions';
import PERMISSIONS from 'constants/user/permissions';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import Section from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/Section/index';
import UploadModalContent from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/SubmitOffer/UploadModalContent';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import OffersList from './OffersList';

const SubmitOffer = () => {
  const { makeCall, isPending, error } = useDispatchApiCall();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.submitOffer');
  const { success } = useToasts();
  const { inquiryId, bankOffers, status } = useSelector(getInquiryDetailsSelector);
  const dispatch = useDispatch();

  async function handleSubmit(fileId) {
    await makeCall(submitBankOfferAction({ inquiryId, fileId, type: 'single' }), ({ payload }) => {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({ description: t('messageAfterAction') });
      onClose();
    });
  }

  const [uploadedFile, setUploadedFile] = useState();

  const haveOffersUploaded = Array.isArray(bankOffers) && Boolean(bankOffers.length);
  const statusIsNotInProgress = status !== INQUIRY_STATUSES.IN_PROGRESS;

  const Button = (
    <ButtonComponent
      data-testid="bank-advisor-post-offer"
      disabled={haveOffersUploaded || statusIsNotInProgress}
      isLoading={isPending}
      leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
      onClick={onOpen}
    >
      {t('buttonLabel')}
    </ButtonComponent>
  );

  return (
    <Section button={Button} description={t('description')} hasError={error} title={t('title')}>
      {haveOffersUploaded && <OffersList offers={bankOffers} />}
      <ModalComponent
        testId="submit-offer-modal"
        isOpen={isOpen}
        onClose={onClose}
        title={t('modalUpload.header')}
        footer={
          <>
            <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
              {t('modalUpload.buttonCancel')}
            </ButtonComponent>
            <ButtonComponent
              form="form-submit-offer"
              disabled={isPending || !uploadedFile}
              isLoading={isPending}
              type="submit"
            >
              {t('modalUpload.buttonConfirm')}
            </ButtonComponent>
          </>
        }
      >
        <UploadModalContent
          onSubmit={handleSubmit}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      </ModalComponent>
    </Section>
  );
};

export default compose(withRolesGuard(PERMISSIONS.INQUIRY.SUBMIT_OFFER))(SubmitOffer);
