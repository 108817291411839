import React from 'react';

import { useSelector } from 'react-redux';

import Colleteral from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection/CollateralsInformation/Colleteral';
import { getInquiryCollateralsSelector } from 'store/inquiryDetails/selectors';

const Collaterals = () => {
  const collaterals = useSelector(getInquiryCollateralsSelector);
  if (!collaterals || !collaterals.length) {
    return null;
  }

  return (
    <div>
      {collaterals.map(({ kind, value, description }) => (
        <Colleteral
          key={kind + value.toString()}
          value={value}
          kind={kind}
          description={description}
        />
      ))}
    </div>
  );
};

export default Collaterals;
