import {
  BFS_SERVICE__FACTORING_ASSOCIATION,
  BFS_SERVICE__FACTORING_OFFER_BFS,
  BFS_SERVICE__FACTORING_OFFER_GOOGLE_ADVERTISING,
  BFS_SERVICE__FACTORING_OFFER_OTHER,
  BFS_SERVICE__FACTORING_OFFER_RECOMMENDATION,
  BFS_SERVICE__FACTORING_OFFER_SOFTWARE_PARTNER,
} from 'modules/Inquiry/Form/formFields';

const getFactoringOfferOptions = (formatMessage: any) =>
  [
    {
      id: 'pages.bfsService.sections.notes.fields.factoringOffer.options.googleAdvertising',
      value: BFS_SERVICE__FACTORING_OFFER_GOOGLE_ADVERTISING,
    },
    {
      id: 'pages.bfsService.sections.notes.fields.factoringOffer.options.recommendation',
      value: BFS_SERVICE__FACTORING_OFFER_RECOMMENDATION,
    },
    {
      id: 'pages.bfsService.sections.notes.fields.factoringOffer.options.bfs',
      value: BFS_SERVICE__FACTORING_OFFER_BFS,
    },
    {
      id: 'pages.bfsService.sections.notes.fields.factoringOffer.options.association',
      value: BFS_SERVICE__FACTORING_ASSOCIATION,
    },
    {
      id: 'pages.bfsService.sections.notes.fields.factoringOffer.options.softwarePartner',
      value: BFS_SERVICE__FACTORING_OFFER_SOFTWARE_PARTNER,
    },
    {
      id: 'pages.bfsService.sections.notes.fields.factoringOffer.options.other',
      value: BFS_SERVICE__FACTORING_OFFER_OTHER,
    },
  ].map(({ value, id }) => ({ value, label: formatMessage({ id }) }));

export default getFactoringOfferOptions;
