import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const GuarantyIcon = (props: IconProps) => {
  return (
    <Icon
      width="42"
      height="44"
      viewBox="0 0 42 44"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M10.214 34.436l.372.928c-.71.284-1.292.436-1.886.436-2.355 0-4.3-1.803-4.3-4s1.945-4 4.3-4c.729 0 1.386.184 2.124.553l-.448.894c-.608-.304-1.119-.447-1.676-.447-1.82 0-3.3 1.373-3.3 3s1.48 3 3.3 3c.44 0 .912-.123 1.514-.364z" />
      <path d="M4.1 31.8v-1H8v1zm0 1.5v-1H8v1z" />
      <path d="M8.3 40.4a8.3 8.3 0 110-16.6 8.3 8.3 0 010 16.6zm0-1a7.3 7.3 0 100-14.6 7.3 7.3 0 000 14.6zM21.4 4.1c-8.804 0-16.005 5.92-16.905 13.495C5.678 16.286 7.488 15.5 9.5 15.5c2.545 0 4.816 1.38 5.891 3.399C16.45 16.849 18.71 15.5 21.3 15.5c2.582 0 5.035 1.405 6.168 3.4 1.002-2.082 3.078-3.4 5.632-3.4 2.13 0 4.043.927 5.238 2.404C37.6 10.182 30.324 4.1 21.4 4.1zm0-1c9.946 0 18 7.194 18 16.1v.8c0 .575-.811.695-.978.146-.65-2.137-2.795-3.646-5.322-3.646-2.582 0-4.547 1.557-5.114 3.917-.122.508-.844.512-.971.004-.544-2.177-3.048-3.921-5.715-3.921-2.65 0-4.864 1.627-5.414 3.917-.122.508-.844.512-.971.004-.56-2.239-2.812-3.921-5.415-3.921-2.386 0-4.405 1.268-5.133 3.178-.197.519-.967.377-.967-.178v-.3c0-8.906 8.054-16.1 18-16.1z" />
      <path d="M20.9.5a.5.5 0 111 0v3a.5.5 0 11-1 0v-3zm0 15.5a.5.5 0 111 0v23.6c0 2.344-1.891 4.2-4.2 4.2-2.317 0-3.8-1.747-3.8-4.2a.5.5 0 111 0c0 1.934 1.075 3.2 2.8 3.2 1.76 0 3.2-1.413 3.2-3.2V16zm13.4 17.2a6.8 6.8 0 110-13.6 6.8 6.8 0 010 13.6zm0-1a5.8 5.8 0 100-11.6 5.8 5.8 0 000 11.6z" />
      <path d="M34.7 29.2c-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9.5 0 1 .1 1.4.4l-.5.8c-.3-.2-.6-.3-.9-.3-1.1 0-1.9.9-1.9 1.9 0 1 .9 1.9 1.9 1.9.3 0 .6-.1.9-.2l.4.8c-.4.4-.9.5-1.3.5z" />
      <path d="M31.5 26.3v-1h2.8v1zm-.1 1.4v-1h2.9v1z" />
    </Icon>
  );
};

export default GuarantyIcon;
