import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const LeasingIcon = (props: IconProps) => {
  return (
    <Icon
      width="33"
      height="33"
      viewBox="0 0 32.48 33"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path d="M23.35 25.86a3.79 3.79 0 01-1.74.42 3.83 3.83 0 110-7.66 3.73 3.73 0 011.9.51m-6.59 2.57h4.04m-4.04 1.51h4.04" />
        <circle cx="21.21" cy="22.56" r="7.92" />
        <path d="M13.63 20.34a7.5 7.5 0 01-1.34.12 7.92 7.92 0 117.92-7.92 8 8 0 01-.28 2.09" />
        <path d="M12.3 6.49v6.06l3.06 2.04" />
      </g>
      <g fill="#e4e4e4">
        <path d="M32.12 14.86l-1-.22.11-.49a9.24 9.24 0 00-7-11L23.75 3l.21-1 .49.1a10.25 10.25 0 017.78 12.21z" />
        <path d="M22.067 2.585l.25-.968 2.73.703-.249.969z" />
        <path d="M23.22 5L21.6 2.28a.49.49 0 01-.05-.39.49.49 0 01.24-.31L24.67 0l.48.88-2.43 1.33 1.37 2.31zM.39 18l1 .23-.11.49a9.24 9.24 0 006.87 11.1l.45.18-.22 1-.49-.11A10.26 10.26 0 01.27 18.53z" />
        <path d="M7.291 30.686l.263-.965 2.72.74-.262.965z" />
        <path d="M9.15 28l1.59 2.77a.49.49 0 010 .39.5.5 0 01-.25.3L7.64 33l-.47-.89 2.45-1.3-1.33-2.33z" />
      </g>
    </Icon>
  );
};

export default LeasingIcon;
