import React from 'react';

import { useFormState } from 'react-final-form';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import SectionHeading from 'components/Text/SectionHeading';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  EXPERT_ADVICE_ON_ALL,
  FUNDRAISING,
  FURTHER_NEED,
  INVESTMENT_AND_SECURITIES,
  OTHER_FINANCING_SOLUTIONS,
  PAYMENT_TRANSACTIONS,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__LEASING,
  PUBLIC_FUNDING,
} from 'modules/Inquiry/Form/formFields';
import { validateFurtherNeedSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/furtherneedSection.validator';
import { FormSections } from 'schema/inquirySchema.models';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import { FurtherNeedRadioWrapper } from './styles';
interface FurtherNeedProps {
  sectionNumber: number;
}

const FurtherNeed = ({ sectionNumber }: FurtherNeedProps) => {
  const t = useTranslations('pages.requestDetails.sections.furtherNeed');
  const { required, selectAtLeastOne } = useFieldValidators();
  const { values } = useFormState();
  const hasFurtherNeed = values[FURTHER_NEED];

  return (
    <FormSection name={FormSections.furtherNeed} sectionNumber={sectionNumber} title={t('title')}>
      <FormRow space="20px">
        <YesNoRadioGroupWithField
          name={FURTHER_NEED}
          caption={t('fields.needAdviceRadio.caption')}
          validate={combineValidators(required)}
          fontCase="none"
        />
      </FormRow>
      <Condition condition={fieldHasValue(FURTHER_NEED, 'true')}>
        <SectionHeading>{t('fields.adviceTopics.caption')}</SectionHeading>
        <FurtherNeedRadioWrapper showDefault={!hasFurtherNeed}>
          <FormRow space="2px">
            <CheckboxWithField
              name={OTHER_FINANCING_SOLUTIONS}
              text={<span>{t('fields.adviceTopics.otherFinancingSolutions.label')}</span>}
              validate={selectAtLeastOne(validateFurtherNeedSection)}
            />
            <CheckboxWithField
              name={PUBLIC_FUNDING}
              text={<span>{t('fields.adviceTopics.publicFunding.label')}</span>}
            />
          </FormRow>

          <FormRow space="-15px" separator smallSeparatorSpace>
            <CheckboxWithField
              name={EXPERT_ADVICE_ON_ALL}
              text={<span>{t('fields.adviceTopics.expertAdviceOnAll.label')}</span>}
            />
          </FormRow>

          <FormRow space="-5px" separator smallSeparatorSpace>
            <CheckboxWithField
              name={PRODUCT_KIND__LEASING}
              text={<span>{t('fields.adviceTopics.leasing.label')}</span>}
            />
            <CheckboxWithField
              name={PRODUCT_KIND__FACTORING}
              text={<span>{t('fields.adviceTopics.factoring.label')}</span>}
            />
          </FormRow>
          <FormRow space="1px">
            <CheckboxWithField
              name={PAYMENT_TRANSACTIONS}
              text={<span>{t('fields.adviceTopics.paymentTransactions.label')}</span>}
            />
            <CheckboxWithField
              name={INVESTMENT_AND_SECURITIES}
              text={<span>{t('fields.adviceTopics.investmentAndSecurities.label')}</span>}
            />
          </FormRow>
          <FormRow>
            <CheckboxWithField
              name={FUNDRAISING}
              text={<span>{t('fields.adviceTopics.fundraising.label')}</span>}
            />
          </FormRow>
        </FurtherNeedRadioWrapper>
      </Condition>
    </FormSection>
  );
};

export default FurtherNeed;
