import React from 'react';

import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { USER_REPRESENTATION } from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const UserRepresentationRadio = () => {
  const t = useTranslations(
    'pages.customerData.sections.legalRepresentation.fields.isUserRepresentationRadio',
  );

  const tooltipTranslation = t('tooltip', undefined, { defaultMessage: ' ' });
  const showTooltip = tooltipTranslation !== ' ';

  return (
    <YesNoRadioGroupWithField
      name={USER_REPRESENTATION}
      caption={t('caption')}
      tooltip={showTooltip ? tooltipTranslation : undefined}
    />
  );
};

export default UserRepresentationRadio;
