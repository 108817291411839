import { ProductKindType } from 'models/ProductKind.model';
import { isPurposeKind, PurposeKind } from 'models/PurposeKind.model';
import { isRealEstateProject } from 'models/RealEstateProject.model';
import { PURPOSE_KIND__REAL_ESTATE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { defaultPartnerConfig } from 'store/partner/config/defaultPartnerConfig';
import { Store } from 'types/Store';
import { Observable } from 'utils/Observable';
import { SessionStorageStore } from 'utils/sessionStorage/SessionStorageStore';

import {
  InquiryConfig,
  IFormConfigRepo,
  AllFormsConfig,
  FormConfig,
  PurposeWithProduct,
  AssetConfig,
  Assets,
  Dropdowns,
  FormConfigSaveOptions,
} from './types';

const initialConfig: InquiryConfig = {
  selectedInquiryType: InquiryType.default,
  availableInquiryTypes: [InquiryType.default],
  formsConfig: {},
  assets: defaultPartnerConfig.assets,
  dropdowns: defaultPartnerConfig.dropdowns,
};

export class FormConfigRepo extends Observable<InquiryConfig> implements IFormConfigRepo {
  private _config: Store<InquiryConfig> = new SessionStorageStore<InquiryConfig>(
    'formConfigStore',
    initialConfig,
  );

  public save(
    { selectedInquiryType, ...config }: Partial<InquiryConfig>,
    { force = false }: FormConfigSaveOptions = {},
  ): void {
    if (force) {
      this._config.saveBulk(
        {
          selectedInquiryType: this.getSelectedInquiryType(),
          availableInquiryTypes: config.availableInquiryTypes || this.getAvailableInquiryTypes(),
          formsConfig: config.formsConfig || this.getAllFormsConfig(),
          assets: config.assets || this.getAssets(),
          dropdowns: config.dropdowns || this.getDropdowns(),
        },
        true,
      );
    } else {
      this._config.save(config);
    }
    this.emit(this._config.getAllValues());
  }

  public clear(): void {
    this._config.saveBulk(initialConfig, true);
    this.emit(this._config.getAllValues());
  }

  public getConfig(): InquiryConfig {
    return this._config.getAllValues();
  }

  public selectInquiryType(inquiryType: InquiryType, force: boolean = false): InquiryType {
    const { availableInquiryTypes, selectedInquiryType } = this._config.getAllValues();
    if (!availableInquiryTypes.includes(inquiryType)) {
      throw new Error(`Inquiry type ${inquiryType} is not one of the available inquiry types`);
    }
    if (
      !force &&
      availableInquiryTypes.length > 1 &&
      availableInquiryTypes.includes(selectedInquiryType)
    ) {
      return selectedInquiryType;
    } else {
      this._config.save({ selectedInquiryType: inquiryType });
      this.emit(this._config.getAllValues());
      return inquiryType;
    }
  }

  public getSelectedInquiryType(): InquiryType {
    return this._config.getAllValues().selectedInquiryType;
  }

  public getAvailableInquiryTypes(): InquiryType[] {
    return this._config.getAllValues().availableInquiryTypes;
  }

  public getAllFormsConfig(): AllFormsConfig {
    return this._config.getAllValues().formsConfig;
  }

  public getCurrentFormConfig(): FormConfig {
    const selectedInquiryType = this.getSelectedInquiryType();
    const formsConfig = this.getAllFormsConfig();
    return formsConfig[selectedInquiryType] || {};
  }

  public getCurrentPurposes(): PurposeKind[] {
    return Object.keys(this.getCurrentFormConfig()) as PurposeKind[];
  }

  public getCurrentProducts(purpose: PurposeWithProduct): ProductKindType[] {
    const formConfig = this.getCurrentFormConfig();
    if (isRealEstateProject(purpose)) {
      return formConfig[PURPOSE_KIND__REAL_ESTATE]?.[purpose] || [];
    } else if (isPurposeKind(purpose) && purpose !== PURPOSE_KIND__REAL_ESTATE) {
      return formConfig[purpose] || [];
    } else {
      return [];
    }
  }

  public getAssets(): Assets {
    return this._config.getSingleValue('assets');
  }

  public getAssetByName(assetName: keyof Assets): AssetConfig[] {
    return this.getAssets()[assetName];
  }

  public getDropdowns(): Dropdowns {
    return this._config.getSingleValue('dropdowns');
  }
}
