import React from 'react';

import { Text } from '@chakra-ui/react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { translations } from 'new/form/common/types';
import IsFictitiousBeneficialOwner from 'pages/inquiryFlow/PeopleDetails/BeneficiaryOwnersSection/fields/IsFictitiousBeneficialOwner';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import BeneficiaryOwners from './BeneficiaryOwners/BeneficiaryOwners';
import { mmvFields } from '../../../../fields';

const { title, header, fields } =
  translations.inquiryType.mmv.steps.customerDataPage.sections.beneficialOwnerSection;

const { isFictitiousBeneficialOwner } = mmvFields.customerDataPage.beneficialOwnerSection;

interface BeneficialOwnerSectionSectionProps {
  sectionNumber: number;
}

export const BeneficialOwnerSection = (props: BeneficialOwnerSectionSectionProps) => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.beneficiaryOwners}
      title={t(title)}
      sectionNumber={props.sectionNumber}
    >
      <Text mb={4} color="text.tertiary">
        {t(header)}
      </Text>
      <FormRow>
        <IsFictitiousBeneficialOwner
          name={isFictitiousBeneficialOwner}
          tooltip={t(fields.isFictitiousBeneficialOwner.tooltip)}
        />
      </FormRow>
      <BeneficiaryOwners />
    </FormSection>
  );
};
