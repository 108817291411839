import _get from 'lodash/get';

import {
  LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION,
  LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION,
  LENDING_VALUE_NOTES_ON_REMAINING_RISK,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './utils';

const mapLendingValueToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const lendingValue = {
    lending_value: {
      notes_on_remaining_risk: _get(data, LENDING_VALUE_NOTES_ON_REMAINING_RISK),
      buying_phase: {
        lending_limit_percent: toFloatPrecision2(
          unknownToString(_get(data, LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT)),
        ),
        risk_deduction: toFloatPrecision2(
          unknownToString(_get(data, LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION)),
        ),
      },
      indication: {
        lending_limit_percent: toFloatPrecision2(
          unknownToString(_get(data, LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT)),
        ),
      },
      whole_project: {
        lending_limit_percent: toFloatPrecision2(
          unknownToString(_get(data, LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT)),
        ),
        risk_deduction: _get(data, LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION),
      },
    },
  };

  return lendingValue;
};

export default mapLendingValueToApi;
