import React from 'react';

import { HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getCompeonInquiryState } from 'store/inquiryDetails/selectors';
import { DescriptionListTerm, DescriptionListDescription } from 'theme/components/DescriptionList';
import { useTranslations } from 'utils/hooks/useTranslations';

import ProcessingStatusModal from './ProcessingStatusModal';
import { compeonExternalStateStepKeys, compeonExternalStateSteps } from './types';

const { COMPEON_EXTERNAL_STATE_STEP__DEFAULT } = compeonExternalStateStepKeys;

const ProcessingStatus: React.FC = () => {
  const compeonState = useSelector(getCompeonInquiryState);

  const t = useTranslations();
  const { step, languageKey } =
    compeonExternalStateSteps[compeonState || COMPEON_EXTERNAL_STATE_STEP__DEFAULT];
  return (
    <>
      <DescriptionListTerm>
        {t('pages.inquiryDetails.dashboard.summary.processingStatus')}
      </DescriptionListTerm>
      <DescriptionListDescription>
        <HStack>
          <span>{t(languageKey)}</span>
          {step && <ProcessingStatusModal />}
        </HStack>
      </DescriptionListDescription>
    </>
  );
};

export default ProcessingStatus;
