import { all, not, fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__BUILDING,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__CREFO_FACTORING,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PURPOSE_KIND__FACTOMAT,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

const DefaultPurposeWithAmount = () => {
  return all(
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__CORONA)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__BUILDING)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__PROJECT_FINANCING)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__KFINANZ)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__CREFO_FACTORING)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__ENERGY_EFFICIENCY)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__FACTOMAT)),
  );
};

const BfsPurposeWithAmount = () => {
  return all(
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__BUILDING)),
    not(fieldHasValue(PURPOSE_KIND, PURPOSE_KIND__GUARANTY)),
  );
};

const BfsServicePurposeWithAmount = () => {
  return () => false;
};

const PurposeWithAmount = () =>
  useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: BfsPurposeWithAmount(),
    [InquiryType.bfsService]: BfsServicePurposeWithAmount(),
    default: DefaultPurposeWithAmount(),
  });

export default PurposeWithAmount;
