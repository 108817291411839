import React from 'react';

import CloseIcon from 'theme/components/Icon/CloseIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import CloseButton from './CloseButton';
import TagLabel from './TagLabel';
import TagWrapper from './TagWrapper';

interface TagProps {
  label: string;
  children: React.ReactNode;
  onRemove?: () => void;
  testId?: string;
}

const Tag = ({ onRemove, children, label, testId = 'remove-icon' }: TagProps) => {
  const t = useTranslations();

  const handleRemove = () => {
    if (onRemove) onRemove();
  };

  return (
    <TagWrapper data-testid="selected-option-tag">
      <TagLabel>{children}</TagLabel>
      <CloseButton
        onClick={handleRemove}
        type="button"
        aria-label={t('other.remove', { bank: label })}
        data-testid={testId}
      >
        <CloseIcon boxSize={4} />
      </CloseButton>
    </TagWrapper>
  );
};

export default Tag;
