import { useCallback, useState, useEffect } from 'react';

import _get from 'lodash/get';
import { useDispatch } from 'react-redux';

import { callReverseApi, API_METHODS } from 'api/apiService';
import { FILE_SCAN_STATUS } from 'components/UploadBlock/constants';

import { useFilesEndpoint } from './useFilesEndpoint';

// Although antivirus check is on backend side, this hook helps to check current status of this backend async task
// This is not the best solution but a valid one for this moment.

const API_REQUEST_INTERVAL = 1000;

const updateUploadedFileStatus = (file, newScanStatus) => ({
  ...file,
  attributes: {
    ...file.attributes,
    status: newScanStatus,
  },
});

const fetchFileStatus = ({ fileId, endpoint }) => {
  const url = endpoint.compose({ params: { id: fileId } });
  return callReverseApi({
    url,
    method: API_METHODS.GET,
  });
};

const useNewFileStatusListener = ({ isPrivateFile, isAssessmentFile }) => {
  const [cancelFlag, setCancelFlag] = useState(false);
  const dispatch = useDispatch();
  const route = isPrivateFile || isAssessmentFile ? 'DETAILS' : 'STATUS';
  const endpoint = useFilesEndpoint({ isPrivateFile, isAssessmentFile, route });

  const stopScanning = useCallback(() => {
    setCancelFlag(true);
  }, []);

  useEffect(() => {
    return () => stopScanning();
  }, [stopScanning]);

  const checkStatus = useCallback(
    async (id) => {
      const { errors, payload } = await dispatch(fetchFileStatus({ fileId: id, endpoint }));
      if (!errors) {
        return _get(payload, 'data.data.attributes.status');
      }
      return FILE_SCAN_STATUS.REJECTED;
    },
    [dispatch, endpoint],
  );

  return (file) =>
    new Promise((resolve, reject) => {
      const intervalID = setInterval(async () => {
        if (cancelFlag) {
          clearInterval(intervalID);
          reject(updateUploadedFileStatus(file, FILE_SCAN_STATUS.REJECTED));
        }

        const status = await checkStatus(file.id);

        if (status === FILE_SCAN_STATUS.ACCEPTED) {
          clearInterval(intervalID);
          resolve(updateUploadedFileStatus(file, FILE_SCAN_STATUS.ACCEPTED));
        }

        if (status === FILE_SCAN_STATUS.REJECTED) {
          clearInterval(intervalID);
          reject(updateUploadedFileStatus(file, FILE_SCAN_STATUS.REJECTED));
        }
      }, API_REQUEST_INTERVAL);
    });
};

export default useNewFileStatusListener;
