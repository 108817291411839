import React from 'react';

import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { OtherFundingSection } from './sections/otherFundingSection';

export const MittweidaFundingDetailsStep = () => {
  const t = useTranslations();
  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.mittweida.pages.fundingDetails;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <OtherFundingSection />
    </Page>
  );
};
