import React from 'react';

import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import flags from 'assets/images/flags';
import {
  getDisplayLanguage,
  useLanguages,
  type Language,
} from 'components/App/LanguageProvider/useLanguages';
import Condition from 'components/Condition';
import { useUpdateUserData } from 'modules/UserData/helpers/useUpdateUserData';
import { translations } from 'new/form/common/types';
import { EnsureDefined } from 'types/General';
import { UserAttributes } from 'types/User';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getUserAttributes } from 'utils/user/getters';

const Flag = ({ language }: { language: Language }) => (
  <Image boxSize={4} borderRadius="full" src={flags[language]} alt={`language-${language}`} />
);

const { selectLanguage } = translations.components.languageSelector;

export const LanguageSelector = () => {
  const t = useTranslations();
  const updateUserData = useUpdateUserData();
  const { language: userLanguage, ...userData } =
    getUserAttributes() as EnsureDefined<UserAttributes>;
  const { isMultipleLanguagesEnabled, changeLanguage, availableLanguages, selectedLanguage } =
    useLanguages();

  const sortedLanguages = [
    selectedLanguage,
    ...availableLanguages.filter((lang) => lang !== selectedLanguage),
  ];

  const handleChangeLanguage = (language: Language) => {
    changeLanguage(language);
    if (userData && userLanguage && language !== userLanguage) {
      updateUserData({ ...userData, language });
    }
  };

  return (
    <Condition condition={isMultipleLanguagesEnabled}>
      <Menu isLazy>
        <MenuButton
          as={Button}
          variant="link"
          fontSize="lg"
          color="colors.primary"
          leftIcon={<Flag language={selectedLanguage} />}
        >
          {selectedLanguage.toUpperCase()}
        </MenuButton>
        <MenuList>
          <MenuGroup title={t(selectLanguage)}>
            {sortedLanguages.map((lang) => (
              <MenuItem
                key={lang}
                onClick={() => handleChangeLanguage(lang)}
                data-testid={`language-button-${lang}`}
              >
                <Flag language={lang} />
                <Box paddingLeft={2}>{`${getDisplayLanguage(lang)} - ${lang.toUpperCase()}`}</Box>
              </MenuItem>
            ))}
          </MenuGroup>
        </MenuList>
      </Menu>
    </Condition>
  );
};
