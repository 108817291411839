import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Asset } from 'components/Asset';
import paths from 'constants/paths';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ProgressSectionType } from '../types';

interface ObjectDataStepData {
  inquiryId?: string;
}

interface ObjectDataStepProps {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  data: ObjectDataStepData;
}

export const ObjectDataStep = ({
  isFirst,
  isLast,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  data,
}: ObjectDataStepProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.objectData');
  // isCompleted prop tell whenever the whole inquiry is completed
  // not a specific section, so we need to check for it manually
  const isCompleted = completedValue === 100;
  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['1fr', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb="xl">{t('description')}</TextComponent>
          {!isCompleted ? (
            <ButtonComponent
              to={paths.customer.inquiryDetails.objectData.replace(':id', data.inquiryId || '')}
              as={Link}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
            >
              {t('action')}
            </ButtonComponent>
          ) : null}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset htmlHeight="200" type="dashboard" value={ProgressSectionType.OBJECT_DATA} />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
