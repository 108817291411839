import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useLogin } from 'api/AuthApi/queryHooks/auth';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { LoginForm, type LoginFormValues } from 'modules/Login/LoginForm';
import { useLoginRedirect } from 'modules/Login/useLoginRedirect';
import { translations } from 'new/form/common/types';
import { useHandleAccountUnlock } from 'pages/customerPortal/AccountUnlock/useHandleAccountUnlock';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { AccessTokenScope } from 'types/Auth';
import { useTranslations } from 'utils/hooks/useTranslations';
import { setStorageExpiration } from 'utils/sessionStorage/expiration';
import { isLoggedInUser, userIsCustomer } from 'utils/user/conditionals';
const { inquiryList, passwordReset } = paths.customer;
const { customerHeading, subheading } = translations.pages.loginPage;

export const CustomerLogin = () => {
  useHandleAccountUnlock();
  const { mutate: logIn, isLoading } = useLogin(loginSuccessHandler);

  const afterLoginUrl = useLoginRedirect(inquiryList);
  const t = useTranslations();
  const history = useHistory();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();

  const isLoggedIn = isLoggedInUser();
  const ssoEnabled = useConfig(CONFIG.IS_SSO_ENABLED);

  if (isLoggedIn) {
    if (ssoEnabled) {
      history.push(inquiryList);
    } else {
      history.push(afterLoginUrl);
    }
  }

  async function loginSuccessHandler() {
    if (userIsCustomer()) {
      history.push(afterLoginUrl);
    }
  }

  const handleLogin = async ({ email, password }: LoginFormValues) => {
    setStorageExpiration();
    logIn({ email, password, scope: AccessTokenScope.customer });
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (ssoEnabled) {
        history.push(inquiryList);
      } else {
        history.push(afterLoginUrl);
      }
    }
  }, [afterLoginUrl, history, isLoggedIn, ssoEnabled]);

  return (
    <LoginForm
      onSubmit={handleLogin}
      heading={t(customerHeading, { partnerName })}
      subheading={t(subheading)}
      isLoading={isLoading}
      forgotPasswordLink={passwordReset.request}
      showRegistrationLink
    />
  );
};
