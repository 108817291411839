import React, { useMemo, useState } from 'react';

import { Form } from 'react-final-form';

import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { StyledActions } from 'components/StyledActions';
import { AmbiguousAddress } from 'models/types/MarketData.type';
import { MARKET_DATA_ADDRESS_SELECTION } from 'modules/Inquiry/Form/formFields';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import AmbiguousAddressSelectionHeader from './AmbiguousAddressSelectionHeader';

interface AmbiguousAddressSelectionProps {
  ambiguousAddresses: AmbiguousAddress[];
  onCancel: () => void;
  onSubmit: (address: AmbiguousAddress) => Promise<void>;
}

const INITIAL_FORM_STATE = {
  selectedIndex: '0',
};

const AmbiguousAddressSelection: React.FC<AmbiguousAddressSelectionProps> = ({
  ambiguousAddresses,
  onCancel,
  onSubmit,
}) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const addressOptions = useMemo(
    () =>
      ambiguousAddresses.map(({ displayName }, index) => ({
        label: displayName,
        value: index.toString(),
      })),
    [ambiguousAddresses],
  );

  const handleSubmit = async ({ selectedIndex }: typeof INITIAL_FORM_STATE) => {
    setIsLoading(true);

    try {
      await onSubmit(ambiguousAddresses[selectedIndex as any as number]);
      // If this line passes, we assume the component will be unmounted anyway and thus we do not reset the loading state.
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form
        initialValues={INITIAL_FORM_STATE}
        onSubmit={handleSubmit}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <AmbiguousAddressSelectionHeader />
            <FormSection
              sectionNumber={1}
              title={t(
                'pages.marketInformationInquiryPage.marketInfoAddressPage.addressInput.label',
              )}
            >
              <SelectWithField
                name="selectedIndex"
                options={addressOptions}
                data-testid={'select-field-' + MARKET_DATA_ADDRESS_SELECTION}
              />
            </FormSection>
            <StyledActions>
              <ButtonComponent mr={4} onClick={onCancel} variant="tertiary">
                {t('buttons.cancel')}
              </ButtonComponent>
              <ButtonComponent
                leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                type="submit"
                disabled={isLoading}
              >
                {t('pages.finishRegistration.confirmBtn')}
              </ButtonComponent>
            </StyledActions>
          </form>
        )}
      />
    </>
  );
};

export default AmbiguousAddressSelection;
