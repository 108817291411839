import styled from 'styled-components/macro';

export const StyledEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9rem 0;
  text-align: center;
`;

export const StyledEmptyLabel = styled.p`
  margin-bottom: 2rem;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;
