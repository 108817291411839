import styled from 'styled-components/macro';

import Text from 'components/Text/Text';
import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

interface HeadingProps {
  error?: boolean;
  success?: boolean;
  isWhite?: boolean;
}

const Heading = styled(Text)<HeadingProps>`
  margin: 0 0 1.5rem;
  font-size: 1.75rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  text-align: center;
  color: ${({ error, success, isWhite, theme }) => {
    switch (true) {
      case error:
        return theme.colors.primary;
      case success:
        return theme.colors.status.success;
      case isWhite:
        return theme.colors.text.white;
      default:
        return theme.colors.text.primary;
    }
  }};
`;

export default Heading;
