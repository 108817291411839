import React from 'react';

import { OnlineQuestionnaireSection } from 'dzb/inquiry/steps/retailerData/sections/onlineQuestionnaireSection';
import { RetailerInformationSection } from 'dzb/inquiry/steps/retailerData/sections/retailerInformationSection';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';

export const DzbRetailerDataPage = () => {
  return (
    <Page
      translationStringForTitle={translations.inquiryType.dzb.pages.retailerData.progressBarTitle}
    >
      <RetailerInformationSection />
      <OnlineQuestionnaireSection />
    </Page>
  );
};
