import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

interface File {
  contentType: string;
  data: any;
  name: string;
}

interface AxiosDownloadOptions {
  defaultFilename?: string;
  filename?: string;
}

const DEFAULT_AXIOS_DOWNLOAD_OPTIONS = {
  defaultFilename: 'file_download',
};

export const downloadFileFromAxiosResponse = (
  response: AxiosResponse,
  options: AxiosDownloadOptions = {},
) => {
  const { defaultFilename, filename } = { ...DEFAULT_AXIOS_DOWNLOAD_OPTIONS, ...options };
  const filenameForDownload =
    filename ||
    parseFilenameFromContentDisposition(response.headers['content-disposition']) ||
    defaultFilename;

  downloadFile({
    contentType: response.headers['content-type'],
    data: response.data,
    name: filenameForDownload,
  });
};

export const downloadFile = ({ contentType, data, name }: File) => {
  saveAs(new Blob([data], { type: contentType }), name);
};

// Our Content-Disposition header has the format "attachment; filename*=UTF-8''<encodedFilename>"
const parseFilenameFromContentDisposition = (contentDisposition: string | undefined) => {
  const filename = contentDisposition?.trim() ? decodeURIComponent(contentDisposition).trim() : '';
  return filename.split("''")[1] || null;
};
