import { useCallback } from 'react';

import { kebabCase } from 'lodash';
import { get as _get } from 'lodash';
import { useForm, useFormState } from 'react-final-form';

import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';
import {
  CompanySuggestion,
  SuggestionOption,
} from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { extractNestedFieldName } from 'utils/form/getFieldName';

// Used to make the hook usable by different partners
export interface UseCompanySuggestionConfig {
  companySearchSectionString: string;
  companySearchFieldNames: {
    selectedCompanyNameFieldName: string;
    loggedInUserCompanyNameFieldName?: string;
  };
  companyCompletionFieldNames: {
    name?: string;
    street?: string;
    address?: string;
    city?: string;
    country?: string;
    state?: string;
    zipCode?: string;
    industry?: string;
    crefoId?: string;
    tradeName?: string;
  };
}

export const useCompanySuggestion = <FieldTypes>(config: UseCompanySuggestionConfig) => {
  const { change, batch } = useForm();
  const { values } = useFormState<FieldTypes>();
  const {
    companySearchSectionString,
    companySearchFieldNames: { selectedCompanyNameFieldName, loggedInUserCompanyNameFieldName },
    companyCompletionFieldNames,
  } = config;
  const extractedSelectedCompanyNameFieldName = extractNestedFieldName(
    selectedCompanyNameFieldName,
  );

  // Gets the last element of the nested field name
  const extractedLoggedInUserCompanyNameFieldName = loggedInUserCompanyNameFieldName
    ? extractNestedFieldName(loggedInUserCompanyNameFieldName)
    : undefined;

  // Extract the company names from the form values
  const selectedCompanyName = _get(values, selectedCompanyNameFieldName);
  const loggedInUserCompanyName =
    loggedInUserCompanyNameFieldName && _get(values, loggedInUserCompanyNameFieldName);

  // Resets the company completion section fields to undefined
  const setFieldsToUndefined = useCallback(() => {
    batch(() => {
      Object.values(companyCompletionFieldNames).forEach((fieldName) => {
        change(fieldName, undefined);
      });
    });
  }, [batch, change, companyCompletionFieldNames]);

  // Sets the company completion section fields to the values of the selected company
  const setFieldValues = useCallback(
    (company: SuggestionOption) => {
      Object.entries(companyCompletionFieldNames).forEach(([key, fieldName]) => {
        const kebabKey = kebabCase(key) as keyof CompanySuggestion;
        const newValue = company.value[kebabKey];
        if (newValue) change(fieldName, newValue);
      });
    },
    [change, companyCompletionFieldNames],
  );

  // Handles the selection of a company from the suggestion list for a new user
  const handleSelect = useCallback(
    (value: string, companies: SuggestionOption[]) => {
      if (value === COMPANY_DETAILS_COMPANY__NOT_FOUND) {
        // Searched company not found in the list
        batch(() => {
          change(selectedCompanyNameFieldName, value);
          setFieldsToUndefined();
        });
      } else {
        // Company found in the list
        const company = companies.filter((company) => company.value.name === value)[0];
        if (company) {
          batch(() => {
            change(selectedCompanyNameFieldName, value);
            setFieldValues(company);
          });
        }
      }
    },
    [batch, change, selectedCompanyNameFieldName, setFieldValues, setFieldsToUndefined],
  );

  // Handles the selection of a company from the suggestion list for an existing user
  const handleExistingUserSelect = useCallback(
    (value: string, companies: SuggestionOption[]) => {
      if (loggedInUserCompanyNameFieldName && extractedLoggedInUserCompanyNameFieldName) {
        if (value === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY) {
          // User wants to create a new company
          batch(() => {
            change(companySearchSectionString, {
              [extractedLoggedInUserCompanyNameFieldName]: value,
            });
            setFieldsToUndefined();
          });
        } else {
          // Existing company was found in the list
          const company = companies.filter((company) => company.value.name === value)[0];
          if (company) {
            batch(() => {
              change(loggedInUserCompanyNameFieldName, value);
              setFieldValues(company);
            });
          }
        }
      }
    },
    [
      batch,
      change,
      companySearchSectionString,
      extractedLoggedInUserCompanyNameFieldName,
      loggedInUserCompanyNameFieldName,
      setFieldValues,
      setFieldsToUndefined,
    ],
  );

  /**
   * We cannot just change the single fields here like "address" or "name" and change them to undefined. Doing it this
   * way would dismount the files from the form and the whole page / section would be removed from the values.
   * We want to keep the structure of the form values and just change the values of the fields to undefined.
   */
  const handleClearCompanyForm = useCallback(() => {
    batch(() => {
      change(companySearchSectionString, {
        [selectedCompanyNameFieldName]: undefined,
      });
      if (loggedInUserCompanyNameFieldName) {
        change(loggedInUserCompanyNameFieldName, loggedInUserCompanyName);
      }
      setFieldsToUndefined();
    });
  }, [
    batch,
    change,
    companySearchSectionString,
    loggedInUserCompanyName,
    loggedInUserCompanyNameFieldName,
    selectedCompanyNameFieldName,
    setFieldsToUndefined,
  ]);

  const handleClearExistingUserCompanyForm = useCallback(() => {
    if (extractedLoggedInUserCompanyNameFieldName && extractedSelectedCompanyNameFieldName) {
      batch(() => {
        change(companySearchSectionString, {
          [extractedSelectedCompanyNameFieldName]: selectedCompanyName,
          [extractedLoggedInUserCompanyNameFieldName]: undefined,
        });
        setFieldsToUndefined();
      });
    }
  }, [
    batch,
    change,
    companySearchSectionString,
    extractedLoggedInUserCompanyNameFieldName,
    extractedSelectedCompanyNameFieldName,
    selectedCompanyName,
    setFieldsToUndefined,
  ]);

  return {
    handleSelect,
    handleClearCompanyForm,
    handleExistingUserSelect: loggedInUserCompanyNameFieldName
      ? handleExistingUserSelect
      : () => {},
    handleClearExistingUserCompanyForm: loggedInUserCompanyNameFieldName
      ? handleClearExistingUserCompanyForm
      : () => {},
  };
};
