import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { dzbFields, DzbSections } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const {
  formOfLeadershipExperience,
  leadershipExperienceYears,
  industryExperienceYears,
  alreadyGainedLeadershipExperience,
  professionalCareer,
  education,
} = dzbFields.companyDataPage.startUpQuestionnaireSection;

export const StartUpQuestionnaireSection = () => {
  const t = useTranslations();
  const {
    title,
    caption,
    fields: {
      education: educationTranslations,
      professionalCareer: professionalCareerTranslations,
    },
  } = translations.inquiryType.dzb.pages.companyData.sections.startUpQuestionnaire;

  return (
    <Condition<DzbSections>
      validation={'directDebitMandateSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={4}
          placeholder={t(translations.inquiryType.dzb.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={4}>
        <TextComponent mb={6} color={'text.tertiary'}>
          {t(caption)}
        </TextComponent>
        <FormRow>
          <InputWithField name={industryExperienceYears} />
        </FormRow>
        <FormRow>
          <TextAreaWithField name={education} placeholder={t(educationTranslations.placeholder)} />
        </FormRow>
        <FormRow>
          <TextAreaWithField
            name={professionalCareer}
            placeholder={t(professionalCareerTranslations.placeholder)}
          />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={alreadyGainedLeadershipExperience} />
        </FormRow>
        <FormRow alignItems={'flex-end'}>
          <InputWithField name={leadershipExperienceYears} />
          <InputWithField name={formOfLeadershipExperience} />
        </FormRow>
      </FormSection>
    </Condition>
  );
};
