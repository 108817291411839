import React, { useState } from 'react';

import Condition from 'components/Condition';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ReadMoreBtn } from './styles';

const DEFAULT_MAX_TEXT_LENGTH = 300;
interface TextExpanderProps {
  maxTextLength?: number;
  text: string;
  renderExpanded?: JSX.Element;
  expandable?: boolean;
}

export default function TextExpander({
  maxTextLength = DEFAULT_MAX_TEXT_LENGTH,
  text,
  renderExpanded,
  expandable = true,
}: TextExpanderProps) {
  const t = useTranslations('');
  const hasLongText = text.length > maxTextLength;
  const [showAll, setShowAll] = useState(false);
  const brief = text.slice(0, maxTextLength);
  const handleReadMoreClick = () => {
    setShowAll(true);
  };

  if (hasLongText) {
    if (showAll) {
      return renderExpanded || <>{text}</>;
    }
    return (
      <>
        <Condition condition={!!brief}>{brief}... </Condition>
        {expandable && (
          <ReadMoreBtn type="button" onClick={handleReadMoreClick}>
            {t('other.readMore')}
          </ReadMoreBtn>
        )}
      </>
    );
  }

  return <>{renderExpanded ?? text}</>;
}
