import { BANK_DETAILS_IBAN } from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

import {
  ALREADY_FACTORING,
  BUSINESS_SPECIFICS,
  DOWN_PAYMENT,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  PAYMENT_TERM,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  TRADE_CREDIT_INSURANCE,
  VOLUME_OF_SALES,
} from '../formFields';

const PURPOSE_SECTION_MAX_PRICE = 999999999;
const MAX_NUMBER_OF_CLIENTS_AND_INVOICES = 999999;
const MAX_PAYMENT_TERM = 400;
const MAX_BUSINESS_SPECIFICS_LENGTH = 2000;

export const purposeSectionFieldValidations = {
  [VOLUME_OF_SALES]: fieldValidators
    .price()
    .minPrice(0)
    .maxPrice(PURPOSE_SECTION_MAX_PRICE)
    .integer()
    .required(),
  [SHARE_OF_SALES_BUSINESS_CUSTOMERS]: (_fieldValue: any, allFields: any) =>
    fieldValidators.price().minPrice(0).maxPrice(allFields[VOLUME_OF_SALES]).integer().required(),
  [SHARE_OF_SALES_MAIN_CUSTOMERS]: (_fieldValue: any, allFields: any) =>
    fieldValidators.price().minPrice(0).maxPrice(allFields[VOLUME_OF_SALES]).integer().required(),
  [SHARE_OF_SALES_FOREIGN_CUSTOMERS]: (_fieldValue: any, allFields: any) =>
    fieldValidators.price().minPrice(0).maxPrice(allFields[VOLUME_OF_SALES]).integer().required(),
  [NUMBER_OF_CUSTOMERS]: fieldValidators
    .number()
    .integer()
    .min(0)
    .max(MAX_NUMBER_OF_CLIENTS_AND_INVOICES)
    .required(),
  [NUMBER_OF_INVOICES]: fieldValidators
    .number()
    .integer()
    .min(0)
    .max(MAX_NUMBER_OF_CLIENTS_AND_INVOICES)
    .required(),
  [PAYMENT_TERM]: fieldValidators.number().integer().min(0).max(MAX_PAYMENT_TERM).required(),
  [ALREADY_FACTORING]: fieldValidators.boolean().required(),
  [RECEIVABLES_ASSIGNED_ELSEWHERE]: fieldValidators.boolean().required(),
  [DOWN_PAYMENT]: fieldValidators.boolean().required(),
  [TRADE_CREDIT_INSURANCE]: fieldValidators.boolean().required(),
  [BUSINESS_SPECIFICS]: fieldValidators
    .maxLength()
    .string(MAX_BUSINESS_SPECIFICS_LENGTH)
    .optional(),
  [BANK_DETAILS_IBAN]: fieldValidators.bank().isValidIban().required(),
};
