import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import { usePartnerConfig } from 'config/partner/hooks';
import useAssignedOrganizationalUnit from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor/useAssignedOrganizationalUnitHook';
import useAvailableBankAdvisors from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor/useAvailableBankAdvisorsHook';
import useAvailableOrganizationUnits from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor/useAvailableOrganizationUnitsHook';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { inquiryDetailsBankAdvisorSelector } from 'store/inquiryDetails/selectors';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { required } from 'utils/validators';

const ORGANIZATION_UNIT_FIELD = 'organizationUnit';
const BANK_ADVISOR_FIELD = 'bankAdvisor';

interface Props {
  onSubmit: (bankAdvisorId: string) => void;
}

interface FormValues {
  [BANK_ADVISOR_FIELD]: string | null | undefined;
  [ORGANIZATION_UNIT_FIELD]: string | undefined;
}

const ModalContentWithOrganizationalUnit = ({ onSubmit }: Props) => {
  const isOrganizationalUnitEnabled = useConfig(CONFIG.IS_ORGANIZATIONAL_UNIT_ENABLED);
  const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] = useState<string | undefined>(
    undefined,
  );
  const bankAdvisor = useSelector(inquiryDetailsBankAdvisorSelector);
  const t = useTranslations();
  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const { availableBankAdvisors, fetchAvailableBankAdvisors } = useAvailableBankAdvisors();
  const { availableOrganizationalUnits, fetchAvailableOrganizationUnits } =
    useAvailableOrganizationUnits(partnerId, true);
  const {
    setAssignedOrganizationalUnit,
    assignedOrganizationalUnit,
    fetchAssignedOrganizationalUnit: fetchOwnOrganizationalUnit,
  } = useAssignedOrganizationalUnit();

  const initialOrganizationalUnitValue =
    !bankAdvisor && assignedOrganizationalUnit
      ? assignedOrganizationalUnit
      : selectedOrganizationalUnit;

  const allBankAdvisorsValue = {
    label: t(
      'pages.inquiryDetails.dashboard.actions.forwardToBankAdvisor.allBankAdvisorsPlaceholder',
    ),
    value: undefined,
  };

  const formattedAvailableBankAdvisors = availableBankAdvisors.map((item) => ({
    label: `${item.attributes.first_name} ${item.attributes.last_name}`,
    value: item.id,
  }));

  const formattedOrganizationalUnits = [
    allBankAdvisorsValue,
    ...availableOrganizationalUnits.map((ou) => ({
      label: ou.attributes.name,
      value: ou.id,
    })),
  ];

  useEffect(() => {
    if (isOrganizationalUnitEnabled) {
      if (!bankAdvisor) fetchOwnOrganizationalUnit();
      fetchAvailableOrganizationUnits();
    }
  }, [
    fetchOwnOrganizationalUnit,
    isOrganizationalUnitEnabled,
    fetchAvailableOrganizationUnits,
    bankAdvisor,
  ]);

  useEffect(() => {
    fetchAvailableBankAdvisors(assignedOrganizationalUnit ?? selectedOrganizationalUnit);
  }, [fetchAvailableBankAdvisors, selectedOrganizationalUnit, assignedOrganizationalUnit]);

  const handleSubmitForm = (formValues: FormValues) => {
    const { [BANK_ADVISOR_FIELD]: bankAdvisorId } = formValues;
    if (bankAdvisorId) {
      onSubmit(bankAdvisorId);
    }
  };

  return (
    <>
      <TextComponent mb={8}>
        {t(
          'pages.inquiryDetails.dashboard.actions.forwardToBankAdvisor.modalDescriptionOrganizationalUnit',
        )}
      </TextComponent>
      <Form
        onSubmit={handleSubmitForm}
        initialValues={{
          [BANK_ADVISOR_FIELD]: bankAdvisor?.id,
          [ORGANIZATION_UNIT_FIELD]: initialOrganizationalUnitValue,
        }}
        render={({ handleSubmit, form: { change } }) => (
          <form id="form-forward-to-bank-advisor" onSubmit={handleSubmit}>
            {isOrganizationalUnitEnabled && (
              <FormRow>
                <SelectWithField
                  name={ORGANIZATION_UNIT_FIELD}
                  placeholder={t('placeholders.pleaseChoose')}
                  options={formattedOrganizationalUnits}
                  onChange={(value: string) => {
                    setAssignedOrganizationalUnit(undefined);
                    setSelectedOrganizationalUnit(value);
                    change(BANK_ADVISOR_FIELD, undefined);
                  }}
                  caption={t(
                    'pages.inquiryDetails.dashboard.actions.forwardToBankAdvisor.captionOrganizationalUnit',
                  )}
                  disabled={formattedOrganizationalUnits.length === 0}
                  data-testid={'select-field-' + ORGANIZATION_UNIT_FIELD}
                />
              </FormRow>
            )}
            <FormRow>
              <SelectWithField
                name={BANK_ADVISOR_FIELD}
                placeholder={t('placeholders.pleaseChoose')}
                validate={required(t('errors.required'))}
                options={formattedAvailableBankAdvisors}
                caption={t(
                  'pages.inquiryDetails.dashboard.actions.forwardToBankAdvisor.captionBankadvisorOrganizationalUnit',
                )}
                data-testid={'select-field-' + BANK_ADVISOR_FIELD}
              />
            </FormRow>
          </form>
        )}
      />
    </>
  );
};

export default ModalContentWithOrganizationalUnit;
