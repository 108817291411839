import { useEffect } from 'react';

export const useScript = (resourceUrl: string, defer?: boolean, crossOrigin?: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    if (defer) script.defer = defer;
    if (crossOrigin) script.crossOrigin = crossOrigin;
    document.body.appendChild(script);
  }, [crossOrigin, defer, resourceUrl]);
};
