import styled from 'styled-components/macro';

import { StyledLabelAndFieldWrapper } from 'components/ControlWrapper/styles';
import { InputCaption } from 'components/Text';
import { marginBottom } from 'components/Text/InputCaption';
import { mqMedium } from 'styles/breakpoints';

const RadioGroupFullWidthWrapper = styled.div`
  ${StyledLabelAndFieldWrapper} {
    flex-direction: column;
    justify-content: flex-start;
  }

  ${InputCaption} {
    margin-bottom: ${marginBottom};
  }

  ${mqMedium`
    ${StyledLabelAndFieldWrapper} {
      flex-direction: row;
      justify-content: space-between;
    }

    ${InputCaption} {
      margin-bottom: 0;
    }
  `};
`;

export default RadioGroupFullWidthWrapper;
