import styled from 'styled-components/macro';

import { zIndex } from 'theme/themeConstants';

export const StyledPeopleSection = styled.div`
  margin-bottom: 4rem;
`;

export const StyledPeopleInfo = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const StyledSinglePerson = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;
`;

export const StyledSinglePersonFields = styled.div`
  position: relative;
  flex: 1 1 0%;
`;

export const StyledRemoveButton = styled.button`
  position: absolute;
  z-index: ${zIndex.default};
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};

  :focus,
  :hover {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  svg {
    display: block;
  }
`;
