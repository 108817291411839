import _get from 'lodash/get';

import {
  LOAD_INQUIRY_SETTINGS_SUCCESS,
  LOAD_PARTNER_SETTINGS_SUCCESS,
} from 'api/CompeonReverseApi/actionTypes';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';

import { mergePartnerConfig } from './config';
import { defaultPartnerConfig } from './config/defaultPartnerConfig';

interface IDropdownOverrideConfig {
  id: string;
  value: string;
  translatedLabel?: string;
}
interface IIconOverrideConfig {
  id: string;
  titleId?: string; // translation for icon
  icon: string;
  useStroke?: boolean;
}

interface IFormConfig {
  [formType: string]: {
    [purpose: string]: string[];
  };
}

export interface IAssets {
  purpose: IIconOverrideConfig[];
  realEstate: IIconOverrideConfig[];
  vehicle: IIconOverrideConfig[];
  gender: IIconOverrideConfig[];
  dashboard: IIconOverrideConfig[];
  inquiry: IIconOverrideConfig[];
}

export interface IPartnerState {
  details: {
    id: string;
    fullName: string;
    contactPerson: string;
    phoneNumber: string;
    city: string;
    postalCode: string;
    street: string;
    email: string;
  };
  bankCodes: Array<string>;
  chatToken: string;
  widgetId: string;
  copyCopyright: string;
  copyTermsAndConditions: string;
  copyImprint: string;
  copyDataProtection: string;
  pageTitle: string;
  faviconUrl: string;
  formsConfig: IFormConfig;
  selectedFormType: InquiryType;
  availableFormTypes: InquiryType[];
  gtmId: string;
  webtrekkId: string;
  webtrekkDomain: string;
  internalRatings: string[];
  excludingQuestionCount: number | undefined;
  partnerConfig: {
    dropdowns: {
      financingObjects: {
        realEstate: IDropdownOverrideConfig[];
        purchase: IDropdownOverrideConfig[];
      };
      machineCategories: IDropdownOverrideConfig[];
    };
    assets: IAssets;
  };
  themeOverrides: {
    colors: {
      primary: string | null;
    };
  };
}

const initialState: IPartnerState = {
  // partner details
  details: {
    id: '',
    fullName: '',
    contactPerson: '',
    phoneNumber: '',
    city: '',
    postalCode: '',
    street: '',
    email: '',
  },
  // partner meta
  pageTitle: '',
  faviconUrl: '',
  bankCodes: [],
  chatToken: '',
  widgetId: '',
  gtmId: '',
  webtrekkId: '',
  webtrekkDomain: '',
  internalRatings: [],
  excludingQuestionCount: undefined,
  themeOverrides: {
    colors: {
      primary: null,
    },
  },
  // partner copy
  copyCopyright: '',
  copyTermsAndConditions: '',
  copyImprint: '',
  copyDataProtection: '',

  // inquiry config not directly connected with partner
  partnerConfig: defaultPartnerConfig, // Dropdowns and assets
  formsConfig: {},
  selectedFormType: InquiryType.default,
  availableFormTypes: [InquiryType.default],
};

// Do not use values from this reducer. If you need some config related stuff see src/config
export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case LOAD_PARTNER_SETTINGS_SUCCESS: {
      const rawApiResponse = payload.data.data;
      const processConfig = rawApiResponse.attributes['process-config'];
      const partnerConfig = mergePartnerConfig(state.partnerConfig, processConfig);
      const formsConfig = processConfig['forms-config'];
      const availableFormTypes = Object.keys(formsConfig) || InquiryType.default;

      return {
        ...state,
        formsConfig,
        themeOverrides: {
          colors: {
            primary: _get(rawApiResponse, ['attributes', 'base-color']),
          },
        },
        details: {
          id: _get(rawApiResponse, 'id'),
          fullName: _get(rawApiResponse, ['attributes', 'full-name']),
          contactPerson: _get(rawApiResponse, ['attributes', 'contact-person']),
          phoneNumber: _get(rawApiResponse, ['attributes', 'phone-number']),
          city: _get(rawApiResponse, ['attributes', 'city']),
          postalCode: _get(rawApiResponse, ['attributes', 'postal-code']),
          street: _get(rawApiResponse, ['attributes', 'street']),
          email: _get(rawApiResponse, ['attributes', 'email']),
        },
        bankCodes: _get(rawApiResponse, ['attributes', 'bank-codes']),
        chatToken: _get(rawApiResponse, ['attributes', 'chat-token']),
        widgetId: _get(rawApiResponse, ['attributes', 'widget-id']),
        copyCopyright: _get(rawApiResponse, ['attributes', 'copy-copyright']),
        copyDataProtection: _get(rawApiResponse, ['attributes', 'copy-data-protection']),
        copyImprint: _get(rawApiResponse, ['attributes', 'copy-imprint']),
        copyTermsAndConditions: _get(rawApiResponse, ['attributes', 'copy-terms-and-conditions']),
        gtmId: _get(rawApiResponse, ['attributes', 'gtm-id']),
        webtrekkId: _get(rawApiResponse, ['attributes', 'webtrekk-id']),
        webtrekkDomain: _get(rawApiResponse, ['attributes', 'webtrekk-domain']),
        internalRatings: _get(rawApiResponse, ['attributes', 'internal-ratings']),
        pageTitle: _get(rawApiResponse, ['attributes', 'page-title']),
        translation: _get(rawApiResponse, ['attributes', 'translation']),
        excludingQuestionCount: _get(rawApiResponse, ['attributes', 'excluding-questions-count']),
        selectedFormType: availableFormTypes[0],
        availableFormTypes,
        logoUrl: _get(rawApiResponse, ['attributes', 'logo-url']),
        faviconUrl: _get(rawApiResponse, ['attributes', 'favicon-url']),
        partnerConfig,
      };
    }
    case LOAD_INQUIRY_SETTINGS_SUCCESS: {
      const rawApiResponse = payload.data.data;
      const processConfig = rawApiResponse.attributes['process-config'];
      const partnerConfig = mergePartnerConfig(state.partnerConfig, processConfig);

      return {
        ...state,
        financialProducts: _get(rawApiResponse, ['attributes', 'financial-products']),
        purposeKinds: _get(rawApiResponse, ['attributes', 'purpose-kinds']),
        partnerConfig,
      };
    }
    default:
      return state;
  }
};
