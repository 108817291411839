import { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import type { AppActionThunk } from 'models/types/AppThunk.type';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { getObjectFromQueryParamsString } from 'utils/paths';

type TokenValidationActionProps = (token: string) => AppActionThunk;

export const useQueryParamsToken = (tokenValidationAction: TokenValidationActionProps) => {
  const { makeCall, isPending } = useDispatchApiCall();
  const [token, setToken] = useState<string | null>(null);

  const { search } = useLocation();

  const validateToken = useCallback(
    async (token: string) => {
      const response = await makeCall(tokenValidationAction(token));

      const isTokenValid = response?.payload?.data?.data?.valid ?? false;

      setToken(isTokenValid ? token : null);
    },
    [makeCall, tokenValidationAction],
  );

  useEffect(() => {
    if (search) {
      const { token: queryToken } = getObjectFromQueryParamsString(search);
      if (typeof queryToken === 'string' && queryToken !== token) {
        validateToken(queryToken);
      }
    }
  }, [validateToken, search, token]);

  return { isTokenValidationPending: isPending, token };
};
