import { fieldHasTruthyValue, fieldHasValue, all, not } from 'modules/Inquiry/Form/formConditions';
import {
  INVESTMENT_LOAN_TERM_IN_YEARS,
  PRODUCT_KIND,
  PRODUCT_KIND__AVAL,
  PRODUCT_KIND__LIQUIDITY,
} from 'modules/Inquiry/Form/formFields';

export const productKindFieldConditions = () => {
  return all(
    not(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__AVAL)),
    not(fieldHasValue(PRODUCT_KIND, PRODUCT_KIND__LIQUIDITY)),
  );
};

export const validateBfsProductKindSection = (form) => {
  if (productKindFieldConditions()({ form })) {
    return fieldHasTruthyValue(INVESTMENT_LOAN_TERM_IN_YEARS)({ form });
  } else {
    return true;
  }
};
