import React, { useContext } from 'react';

import { useFieldArray } from 'react-final-form-arrays';

import { InputWithField } from 'components/Input';
import { REPRESENTATIVE_FIRST_NAME, USER_REPRESENTATIVE } from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';

import { FieldValue } from '../LegalRepresentatives/LegalRepresentatives';

const RepresentativeFirstName: React.FC = () => {
  const { fieldName, userIndex } = useContext(LegalRepresentativesContext);
  const { fields: fieldsUserRepresentative } = useFieldArray<FieldValue>(USER_REPRESENTATIVE);
  const userRepresentative = fieldsUserRepresentative.value[userIndex];

  return (
    <InputWithField
      name={`${fieldName}.${REPRESENTATIVE_FIRST_NAME}`}
      disabled={!!userRepresentative.isAssociatedPerson}
    />
  );
};

export default RepresentativeFirstName;
