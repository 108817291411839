import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { Store } from 'types/Store';
import { Observable } from 'utils/Observable';
import { SessionStorageStore } from 'utils/sessionStorage/SessionStorageStore';

import {
  IFeatureFlagsRepo,
  FeatureFlagsRecord,
  FeatureFlagName,
  FeatureFlagsSaveOptions,
} from './types';

const initialState = {
  [CONFIG.IS_BANK_ADVISOR_ENABLED]: false,
  [CONFIG.IS_FASTLANE_ENABLED]: false,
  [CONFIG.IS_INQUIRY_DRAFT_MODE_ENABLED]: false,
  [CONFIG.IS_INQUIRY_EDIT_ALLOWED_FOR_CUSTOMER]: true,
  [CONFIG.IS_SSO_ENABLED]: false,
  [CONFIG.IS_COMPEON_MANAGED]: false,
  [CONFIG.IS_CHANGELOG_ON]: false,
  [CONFIG.IS_SALES_PERSONS_ENABLED]: false,
  [CONFIG.IS_ORGANIZATIONAL_UNIT_ENABLED]: false,
  [CONFIG.IS_REGISTRATION_ENABLED]: false,
  [CONFIG.IS_AGRICULTURAL_INQUIRY_PROCESS_ENABLED]: true,
  [CONFIG.IS_FORESTRY_INQUIRY_PROCESS_ENABLED]: true,
  [CONFIG.IS_COLLECTING_LEADS_ENABLED]: false,
  [CONFIG.IS_SHOW_INDICATIVE_FOR_LEAD]: false,
  [CONFIG.IS_SHOW_INDICATIVE_FOR_OFFER]: false,
  [CONFIG.IS_PREMIUM_PARTNER_ENABLED]: false,
  [CONFIG.IS_DIGITAL_PROCESSING_ENABLED]: true,
  [CONFIG.IS_CREFO_MANUAL_REPORT_ENABLED]: true,
  [CONFIG.REQUIRE_FINAL_INFO]: false,
  [CONFIG.CUSTOMER_ACTIVATION_ON_INVITATION]: false,
  [CONFIG.IS_CANCEL_INQUIRY_IN_COMPEON_ENABLED]: false,
  [CONFIG.IS_EVALUEIN_ENABLED]: false,
  [CONFIG.SHOW_CUSTOMER_CONFIRMATION_STATUS]: false,
  [CONFIG.ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST]: false,
};

export class FeatureFlagsRepo extends Observable<FeatureFlagsRecord> implements IFeatureFlagsRepo {
  private _flags: Store<FeatureFlagsRecord> = new SessionStorageStore<FeatureFlagsRecord>(
    'featureFlagsStore',
    initialState,
  );

  public save(flags: FeatureFlagsRecord, { force = false }: FeatureFlagsSaveOptions = {}): void {
    if (force) {
      this._flags.saveBulk(flags, true);
    } else {
      this._flags.save(flags);
    }
    this.emit(this._flags.getAllValues());
  }

  public getFlags(): FeatureFlagsRecord {
    return this._flags.getAllValues();
  }

  public getFlagByName(flagName: FeatureFlagName): boolean {
    return this._flags.getSingleValue(flagName) || false;
  }

  public clear(): void {
    this._flags.saveBulk(initialState, true);
    this.emit(this._flags.getAllValues());
  }
}
