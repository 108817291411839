import { useEffect, useRef } from 'react';

import { useForm } from 'react-final-form';

import { useMe } from 'api/CompeonReverseApi/customer/queryHooks/users';
import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
  SELF_USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';
import { formatDateAndNull } from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';
import { UserAttributes } from 'types/User';

export const useAutoFillRepresentativeData = (isUserAllowedToRepresent: boolean) => {
  const form = useForm();
  const { data } = useMe({
    select: (data) => ({
      phoneNumber: data.data.data.attributes.phoneNumber,
      birthDate: data.data.data.attributes.birthDate,
    }),
  });

  const dataRef = useRef<Pick<UserAttributes, 'phoneNumber' | 'birthDate'> | null>(null);

  useEffect(() => {
    dataRef.current = data || null;
  }, [data]);

  useEffect(() => {
    if (isUserAllowedToRepresent) {
      const key = `${SELF_USER_REPRESENTATIVE}`;
      const data = dataRef.current;
      form.batch(() => {
        const phoneNumber = data?.phoneNumber;
        if (phoneNumber) {
          const number =
            data.phoneNumber[0] === '0' ? data?.phoneNumber.replace('0', '+49') : data?.phoneNumber;
          form.change(`${key}.${REPRESENTATIVE_PHONE_NUMBER}`, number);
        }
        if (data?.birthDate) {
          form.change(`${key}.${REPRESENTATIVE_BIRTHDATE}`, formatDateAndNull(data.birthDate));
        }
      });
    } else {
      form.change(`${SELF_USER_REPRESENTATIVE}`, null);
    }
  }, [form, isUserAllowedToRepresent]);
};
