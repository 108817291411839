import {
  REAL_ESTATE_KIND__OFFICE,
  REAL_ESTATE_KIND__INDUSTRIAL,
  REAL_ESTATE_KIND__RESIDENCE,
  REAL_ESTATE_KIND__FAMILY_HOUSE,
  REAL_ESTATE_KIND__PROPERTY_DEVELOPMENT,
  REAL_ESTATE_KIND__BUSINESS,
  REAL_ESTATE_KIND__SHOPPING_CENTER,
  REAL_ESTATE_KIND__HOTEL,
  REAL_ESTATE_KIND__OTHER,
} from 'modules/Inquiry/Form/formFields';

export default [
  {
    value: REAL_ESTATE_KIND__OFFICE,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.officeBuilding',
  },
  {
    value: REAL_ESTATE_KIND__INDUSTRIAL,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.warehouseOrProductionHall',
  },
  {
    value: REAL_ESTATE_KIND__RESIDENCE,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.residence',
  },
  {
    value: REAL_ESTATE_KIND__FAMILY_HOUSE,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.singleOrMultiFamilyHouse',
  },
  {
    value: REAL_ESTATE_KIND__PROPERTY_DEVELOPMENT,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.propertyDevelopment',
  },
  {
    value: REAL_ESTATE_KIND__BUSINESS,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.businessBuilding',
  },
  {
    value: REAL_ESTATE_KIND__SHOPPING_CENTER,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.specialtyShoppingCenter',
  },
  {
    value: REAL_ESTATE_KIND__HOTEL,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.hotel',
  },
  {
    value: REAL_ESTATE_KIND__OTHER,
    id: 'pages.financingNeed.sections.newBuilding.fields.realEstateKind.options.other',
  },
];
