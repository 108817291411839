import {
  atLeastOne,
  fieldHasTruthyValue,
  fieldHasValue,
} from 'modules/Inquiry/Form/formConditions';
import {
  EXPERT_ADVICE_ON_ALL,
  FUNDRAISING,
  INVESTMENT_AND_SECURITIES,
  OTHER_FINANCING_SOLUTIONS,
  PAYMENT_TRANSACTIONS,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__LEASING,
  PUBLIC_FUNDING,
  FURTHER_NEED,
} from 'modules/Inquiry/Form/formFields';

export const furtherNeedFields = [
  fieldHasTruthyValue(EXPERT_ADVICE_ON_ALL),
  fieldHasTruthyValue(FUNDRAISING),
  fieldHasTruthyValue(INVESTMENT_AND_SECURITIES),
  fieldHasTruthyValue(OTHER_FINANCING_SOLUTIONS),
  fieldHasTruthyValue(PAYMENT_TRANSACTIONS),
  fieldHasTruthyValue(PRODUCT_KIND__FACTORING),
  fieldHasTruthyValue(PRODUCT_KIND__LEASING),
  fieldHasTruthyValue(PUBLIC_FUNDING),
];

export const validateFurtherNeedSection = (form) => {
  if (!form) {
    return true;
  }

  if (fieldHasValue(FURTHER_NEED, 'false')({ form })) {
    return true;
  } else return atLeastOne(...furtherNeedFields)({ form });
};
