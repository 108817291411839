import { saveAs } from 'file-saver';

import { getCompeonDocumentDownloadAction } from 'store/inquiryDetails/actions';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

export const useDownloadCompeonFile = ({ fileId, fileName }) => {
  const { makeCall } = useDispatchApiCall();

  return async () => {
    const { error, payload } = await makeCall(getCompeonDocumentDownloadAction(fileId));
    if (error) return;

    saveAs(payload.data.data.attributes.location, fileName);
  };
};
