import React from 'react';

import { string } from 'prop-types';

const WindowProperty = '_tiConfig';
const ScriptPath = '/tiLoader.min.js';

export const webTrekkPushUpdate = () => {
  if (window.wts) {
    window.wts.push(['send', 'pageupdate']);
  }
};

class WebtrekkTracker extends React.Component {
  componentDidMount() {
    const { webtrekkDomain, webtrekkId } = this.props;
    if (webtrekkId && webtrekkDomain) {
      window[WindowProperty] = window[WindowProperty] || {
        tiDomain: webtrekkDomain,
        tiId: webtrekkId,
        option: {},
      };
      this.createWebtrekkTag();
    }
  }

  createWebtrekkTag = () => {
    const script = document.createElement('script');
    script.src = ScriptPath;

    if (document.head.prepend) {
      document.head.prepend(script);
    } else {
      document.head.appendChild(script);
    }
  };

  render() {
    return null;
  }
}

WebtrekkTracker.propTypes = {
  webtrekkDomain: string.isRequired,
  webtrekkId: string.isRequired,
};

export default WebtrekkTracker;
