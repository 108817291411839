import {
  INVESTMENT_LOAN_AMORTISATION_KIND__FULL_AMORTISATION,
  INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
} from 'modules/Inquiry/Form/formFields';

const getInvestmentLoanAmmortisationKindOptions = (formatMessage) =>
  [
    {
      id: 'pages.requestDetails.sections.investmentLoan.fields.investmentLoanAmortisationKind.options.partialAmortisation',
      value: INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
    },
    {
      id: 'pages.requestDetails.sections.investmentLoan.fields.investmentLoanAmortisationKind.options.fullAmortisation',
      value: INVESTMENT_LOAN_AMORTISATION_KIND__FULL_AMORTISATION,
    },
  ].map(({ id, value }) => ({ value, label: formatMessage({ id }) }));

export default getInvestmentLoanAmmortisationKindOptions;
