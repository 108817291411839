import React from 'react';

import { useSelector } from 'react-redux';

import { useLoadProfitabilityData } from 'api/CompeonReverseApi/operation/queryHooks';
import { getMarketDataFilterLegacy } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/filterManagementReducer';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { HeadingComponent } from 'theme/components/Heading';
import { useTranslations } from 'utils/hooks/useTranslations';

import SummaryCards from '../../components/SummaryCards';
import SummaryCard from '../../components/SummaryCards/SummaryCard';
import { useUnitFormatter } from '../../utils';

interface RiskBoxItem {
  remaining_risk_sqm: string;
  remaining_risk_percent: string;
  profit_risk_ratio_after_operating_costs: string;
  profit_risk_ratio_before_operating_costs: string;
}

export interface RiskBoxes {
  current: RiskBoxItem;
  future: RiskBoxItem;
}

export const Risk = () => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const t = useTranslations(
    'pages.planningEvaluation.mittweida.pages.profitabilityCalculation.boxes.risk',
  );
  const { data } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      riskBoxes: response.data.attributes.computed_details.remaining_risk_boxes,
    }),
  });
  const formatter = useUnitFormatter();
  const { usageType } = useSelector(getMarketDataFilterLegacy);

  return (
    <>
      <HeadingComponent size="sm" color="text.tertiary">
        {t('title')}
      </HeadingComponent>
      <SummaryCards>
        <SummaryCard
          caption={t('remainingRiskSqm')}
          value={formatter.currency(data?.riskBoxes[usageType]?.remaining_risk_sqm)}
        />
        <SummaryCard
          caption={t('remainingRiskPercent')}
          value={formatter.percent(data?.riskBoxes[usageType]?.remaining_risk_percent)}
        />
        <SummaryCard
          caption={t('profitRiskRatioBeforeOperatingCosts')}
          value={formatter.percent(
            data?.riskBoxes[usageType]?.profit_risk_ratio_before_operating_costs,
          )}
        />
        <SummaryCard
          caption={t('profitRiskRatioAfterOperatingCosts')}
          value={formatter.percent(
            data?.riskBoxes[usageType]?.profit_risk_ratio_after_operating_costs,
          )}
        />
      </SummaryCards>
    </>
  );
};
