import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const ArrowLeftIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M5 11.3l4.5-4.5c.4-.4 1-.4 1.5 0s.4 1 0 1.5L8.2 11h10.1c.6 0 1 .4 1 1s-.4 1-1 1H8.2l2.8 2.8c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L5 12.7c-.4-.4-.4-1 0-1.4"
      />
    </Icon>
  );
};

export default ArrowLeftIcon;
