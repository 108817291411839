import {
  OVERDRAFT_TERMINABILITY,
  OVERDRAFT_END_AT,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers, conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toBoolean, toDate } = fieldParsers;
export const mapOverdraftToApi: InquiryFormMapper = (allFields) => {
  const isOverdraftTerminability = !!toBoolean(allFields[OVERDRAFT_TERMINABILITY]);

  return {
    'overdraft-terminability': toBoolean(allFields[OVERDRAFT_TERMINABILITY]),
    'overdraft-interested-in-alternative-financing': toBoolean(
      allFields[OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING],
    ),
    ...conditionalObject(isOverdraftTerminability, {
      'overdraft-end-at': toDate(allFields[OVERDRAFT_END_AT]),
    }),
  };
};
