import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  CONTACT_PERSON_PHONE,
  CONTACT_PERSON_GENDER,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_LAST_NAME,
  CONTACT_PERSON_EMAIL,
} from 'modules/Inquiry/Form/formFields';
import { FormSections } from 'schema/inquirySchema.models';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import BfsPersonEmail from './BfsPersonEmail/BfsPersonEmail';

interface Props {
  sectionNumber?: number;
}
export const validatebfsPeopleDetailsSection = () =>
  validateSectionFields([
    CONTACT_PERSON_EMAIL,
    CONTACT_PERSON_PHONE,
    CONTACT_PERSON_GENDER,
    CONTACT_PERSON_FIRST_NAME,
    CONTACT_PERSON_LAST_NAME,
  ]);

const BfsPersonDetails = ({ sectionNumber }: Props) => {
  const t = useTranslations('pages.companyDetails.sections.contactPerson');
  const { required, minPhoneLength, maxPhoneLength, isPhoneNumber, hasPhonePrefix } =
    useFieldValidators();

  return (
    <FormSection
      name={FormSections.companyContact}
      sectionNumber={sectionNumber ?? 1}
      title={t('title')}
    >
      <FormRow>
        <GenderWithField name={CONTACT_PERSON_GENDER} />
      </FormRow>
      <FormRow>
        <InputWithField name={CONTACT_PERSON_FIRST_NAME} />
        <InputWithField name={CONTACT_PERSON_LAST_NAME} />
      </FormRow>
      <FormRow>
        <InputWithField
          name={CONTACT_PERSON_PHONE}
          validate={combineValidators(
            required,
            hasPhonePrefix,
            minPhoneLength,
            maxPhoneLength,
            isPhoneNumber,
          )}
        />
      </FormRow>
      <FormRow>
        <BfsPersonEmail />
      </FormRow>
    </FormSection>
  );
};

export default BfsPersonDetails;
