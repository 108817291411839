import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export const HeatingIcon = (props: IconProps) => {
  return (
    <Icon
      width={'128'}
      height={'128'}
      viewBox={'0 0 128 128'}
      aria-hidden={'true'}
      fill={'white'}
      fillOpacity={0}
      {...props}
    >
      <path
        d="M80.9703 117.22H23.9703C22.6618 117.22 21.4068 116.701 20.4807 115.777C19.5546 114.852 19.0329 113.598 19.0303 112.29V31.94C19.0302 30.635 19.5466 29.383 20.4665 28.4575C21.3865 27.5319 22.6353 27.0079 23.9403 27H80.9403C82.2487 27.0026 83.5026 27.5243 84.4269 28.4504C85.3512 29.3766 85.8703 30.6316 85.8703 31.94V112.29C85.8703 113.592 85.355 114.842 84.4369 115.765C83.5189 116.689 82.2726 117.212 80.9703 117.22ZM23.9403 29C23.1605 29 22.4127 29.3097 21.8614 29.8611C21.31 30.4125 21.0003 31.1603 21.0003 31.94V112.29C21.0003 112.676 21.0764 113.057 21.2243 113.414C21.3721 113.77 21.5888 114.093 21.862 114.365C22.1351 114.638 22.4593 114.853 22.8159 115C23.1726 115.147 23.5547 115.221 23.9403 115.22H80.9403C81.7174 115.22 82.4626 114.911 83.0121 114.362C83.5616 113.812 83.8703 113.067 83.8703 112.29V31.94C83.8703 31.1671 83.5661 30.4253 83.0234 29.8751C82.4806 29.3249 81.7431 29.0105 80.9703 29H23.9403Z"
        stroke-width="2.08989"
      />
      <path
        d="M52.4504 70.5903C49.9077 70.5877 47.47 69.5765 45.6721 67.7786C43.8742 65.9807 42.863 63.5429 42.8604 61.0003C42.8604 56.1103 50.7004 43.2103 51.6004 41.7603C51.69 41.6156 51.8152 41.4962 51.9639 41.4134C52.1127 41.3306 52.2801 41.2871 52.4504 41.2871C52.6206 41.2871 52.788 41.3306 52.9368 41.4134C53.0855 41.4962 53.2107 41.6156 53.3004 41.7603C54.2004 43.2103 62.0404 56.1103 62.0404 61.0003C62.0377 63.5429 61.0265 65.9807 59.2286 67.7786C57.4307 69.5765 54.993 70.5877 52.4504 70.5903ZM52.4504 44.2203C49.7404 48.8003 44.8604 57.7203 44.8604 61.0003C44.8604 63.0133 45.66 64.9439 47.0834 66.3673C48.5068 67.7907 50.4374 68.5903 52.4504 68.5903C54.4633 68.5903 56.3939 67.7907 57.8173 66.3673C59.2407 64.9439 60.0404 63.0133 60.0404 61.0003C60.0404 57.7203 55.1604 48.8003 52.4504 44.2203Z"
        stroke-width="2.08989"
      />
      <path
        d="M52.4504 70.5903C51.0447 70.5903 49.6967 70.0319 48.7027 69.038C47.7088 68.044 47.1504 66.696 47.1504 65.2903C47.1504 62.8003 50.8304 55.6303 51.5604 54.2203C51.6443 54.0565 51.7718 53.919 51.9288 53.8231C52.0859 53.7271 52.2663 53.6763 52.4504 53.6763C52.6344 53.6763 52.8149 53.7271 52.9719 53.8231C53.129 53.919 53.2565 54.0565 53.3404 54.2203C54.0704 55.6303 57.7504 62.8003 57.7504 65.2903C57.7504 66.696 57.192 68.044 56.1981 69.038C55.2041 70.0319 53.856 70.5903 52.4504 70.5903ZM52.4504 56.8903C50.9204 60.0003 49.1504 64.0003 49.1504 65.2903C49.1504 66.1655 49.4981 67.0049 50.1169 67.6238C50.7358 68.2426 51.5752 68.5903 52.4504 68.5903C53.3256 68.5903 54.165 68.2426 54.7838 67.6238C55.4027 67.0049 55.7504 66.1655 55.7504 65.2903C55.7504 64.0003 53.9804 60.0003 52.4504 56.8903Z"
        stroke-width="2.08989"
      />
      <path
        d="M84.9 87.9199H20C19.7348 87.9199 19.4804 87.8146 19.2929 87.627C19.1054 87.4395 19 87.1851 19 86.9199C19 86.6547 19.1054 86.4004 19.2929 86.2128C19.4804 86.0253 19.7348 85.9199 20 85.9199H84.9C85.1652 85.9199 85.4196 86.0253 85.6071 86.2128C85.7946 86.4004 85.9 86.6547 85.9 86.9199C85.9 87.1851 85.7946 87.4395 85.6071 87.627C85.4196 87.8146 85.1652 87.9199 84.9 87.9199Z"
        stroke-width="2.08989"
      />
      <path
        d="M76.0803 117.22H28.8203C28.6766 117.221 28.5344 117.192 28.4032 117.133C28.2721 117.074 28.1551 116.988 28.0603 116.88C27.9654 116.77 27.8956 116.641 27.8559 116.501C27.8162 116.361 27.8074 116.214 27.8303 116.07L30.6203 98.0002C30.6586 97.7636 30.7807 97.5486 30.9642 97.3944C31.1478 97.2403 31.3806 97.1572 31.6203 97.1602H73.2903C73.53 97.1572 73.7628 97.2403 73.9464 97.3944C74.1299 97.5486 74.252 97.7636 74.2903 98.0002L77.0803 116.12C77.1032 116.264 77.0944 116.411 77.0547 116.551C77.015 116.691 76.9452 116.82 76.8503 116.93C76.7499 117.03 76.6293 117.108 76.4965 117.158C76.3638 117.208 76.2219 117.229 76.0803 117.22ZM29.9903 115.22H74.9203L72.4503 99.1102H32.4503L29.9903 115.22Z"
        stroke-width="2.08989"
      />
      <path
        d="M63.3602 111.76C62.45 111.76 61.5603 111.49 60.8036 110.984C60.0469 110.478 59.4573 109.759 59.1095 108.918C58.7616 108.077 58.6711 107.152 58.8494 106.259C59.0277 105.366 59.4668 104.547 60.1111 103.904C60.7554 103.261 61.576 102.824 62.469 102.647C63.3619 102.471 64.2872 102.563 65.1275 102.913C65.9679 103.263 66.6856 103.854 67.1899 104.612C67.6941 105.369 67.9622 106.26 67.9602 107.17C67.9576 108.388 67.4718 109.556 66.6094 110.416C65.747 111.277 64.5785 111.76 63.3602 111.76ZM63.3602 104.57C62.846 104.57 62.3433 104.723 61.9158 105.008C61.4882 105.294 61.1549 105.7 60.9582 106.175C60.7614 106.65 60.7099 107.173 60.8102 107.677C60.9105 108.182 61.1582 108.645 61.5218 109.009C61.8854 109.372 62.3487 109.62 62.853 109.72C63.3574 109.82 63.8801 109.769 64.3552 109.572C64.8303 109.375 65.2364 109.042 65.5221 108.615C65.8078 108.187 65.9602 107.684 65.9602 107.17C65.9616 106.828 65.8952 106.49 65.765 106.174C65.6348 105.857 65.4434 105.57 65.2017 105.329C64.96 105.087 64.6728 104.895 64.3568 104.765C64.0407 104.635 63.7021 104.569 63.3602 104.57Z"
        stroke-width="2.08989"
      />
      <path
        d="M63.3604 106.88C63.0951 106.88 62.8408 106.775 62.6532 106.587C62.4657 106.4 62.3604 106.146 62.3604 105.88V103.57C62.3604 103.305 62.4657 103.051 62.6532 102.863C62.8408 102.676 63.0951 102.57 63.3604 102.57C63.6256 102.57 63.8799 102.676 64.0675 102.863C64.255 103.051 64.3604 103.305 64.3604 103.57V105.88C64.3604 106.146 64.255 106.4 64.0675 106.587C63.8799 106.775 63.6256 106.88 63.3604 106.88Z"
        stroke-width="2.08989"
      />
      <path
        d="M41.5402 111.76C40.6295 111.762 39.7388 111.494 38.9808 110.989C38.2228 110.484 37.6316 109.766 37.2822 108.925C36.9328 108.084 36.8409 107.158 37.0181 106.265C37.1953 105.372 37.6336 104.551 38.2775 103.907C38.9214 103.263 39.742 102.825 40.6352 102.648C41.5285 102.471 42.4542 102.563 43.2951 102.912C44.1361 103.262 44.8544 103.853 45.3591 104.611C45.8638 105.369 46.1321 106.259 46.1302 107.17C46.1275 108.387 45.6431 109.553 44.7829 110.413C43.9226 111.273 42.7567 111.757 41.5402 111.76ZM41.5402 104.57C41.0255 104.568 40.5219 104.719 40.093 105.003C39.6641 105.288 39.3293 105.693 39.131 106.168C38.9326 106.643 38.8797 107.166 38.9789 107.671C39.0781 108.176 39.3249 108.64 39.6882 109.005C40.0514 109.37 40.5146 109.618 41.0192 109.719C41.5239 109.82 42.0472 109.77 42.5228 109.573C42.9985 109.377 43.4051 109.043 43.6913 108.616C43.9774 108.188 44.1302 107.685 44.1302 107.17C44.1302 106.482 43.8576 105.822 43.3722 105.335C42.8867 104.848 42.228 104.573 41.5402 104.57Z"
        stroke-width="2.08989"
      />
      <path
        d="M41.54 106.88C41.2748 106.88 41.0205 106.775 40.8329 106.587C40.6454 106.4 40.54 106.146 40.54 105.88V103.57C40.54 103.305 40.6454 103.051 40.8329 102.863C41.0205 102.676 41.2748 102.57 41.54 102.57C41.8053 102.57 42.0596 102.676 42.2471 102.863C42.4347 103.051 42.54 103.305 42.54 103.57V105.88C42.54 106.146 42.4347 106.4 42.2471 106.587C42.0596 106.775 41.8053 106.88 41.54 106.88Z"
        stroke-width="2.08989"
      />
      <path
        d="M79.0701 123H70.3701C70.1049 123 69.8505 122.895 69.663 122.707C69.4755 122.52 69.3701 122.265 69.3701 122V116.22C69.3701 115.955 69.4755 115.701 69.663 115.513C69.8505 115.326 70.1049 115.22 70.3701 115.22H79.0701C79.3353 115.22 79.5897 115.326 79.7772 115.513C79.9648 115.701 80.0701 115.955 80.0701 116.22V122C80.0701 122.265 79.9648 122.52 79.7772 122.707C79.5897 122.895 79.3353 123 79.0701 123ZM71.3701 121H78.0701V117.22H71.3701V121Z"
        stroke-width="2.08989"
      />
      <path
        d="M34.5301 123H25.8301C25.5649 123 25.3105 122.895 25.123 122.707C24.9354 122.52 24.8301 122.265 24.8301 122V116.22C24.8301 115.955 24.9354 115.701 25.123 115.513C25.3105 115.326 25.5649 115.22 25.8301 115.22H34.5301C34.7953 115.22 35.0496 115.326 35.2372 115.513C35.4247 115.701 35.5301 115.955 35.5301 116.22V122C35.5301 122.265 35.4247 122.52 35.2372 122.707C35.0496 122.895 34.7953 123 34.5301 123ZM26.8301 121H33.5301V117.22H26.8301V121Z"
        stroke-width="2.08989"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.8571 22H42V29H64V22H61.1429V10H58.8571V22H47.1429V10H44.8571V22ZM44.2857 24.2857H61.7143V26.7143H44.2857V24.2857Z"
        stroke-width="2.08989"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M101.493 10H116V15.9733H113.56C113.48 24.9141 110.879 31.9481 106.596 36.7802C102.333 41.5905 96.4648 44.1446 89.9733 44.2517V47.12H84V32.6133H89.9733V35.1677C92.2222 35.3476 95.6432 34.2398 98.6288 31.4113C101.862 28.3485 104.508 23.3176 104.358 15.9932L105.333 15.9733H101.493V10ZM103.444 11.9505H114.05V14.0229H103.444V11.9505ZM89.9733 42.3009C95.94 42.1946 101.266 39.8538 105.137 35.4864C109.041 31.0813 111.529 24.5398 111.609 15.9733H106.309C106.464 23.8156 103.614 29.3753 99.9703 32.8272C96.7795 35.8501 92.9149 37.3004 89.9733 37.1233V42.3009ZM88.0229 34.5638V45.1695H85.9505V34.5638H88.0229Z"
        stroke-width="2.08989"
      />
    </Icon>
  );
};
