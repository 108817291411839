import React from 'react';

import { Form as FinalForm } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import { PasswordInputWithField } from 'components/PasswordInput';
import { ErrorText, Text } from 'components/Text';
import { usePartnerConfig } from 'config/partner/hooks';
import { ApiActionResponseModel } from 'models/ApiActionResponse.model';
import {
  forwardToCompeonAction,
  abortForwardingToCompeonAction,
} from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/service';
import {
  StyledConsentDescription as Description,
  StyledButtonsWrapper as Actions,
  StyledSection as Section,
} from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/styles';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const ForwardToCompeonNewCustomer: React.FC = () => {
  const inquiryId = useSelector(getInquiryIdSelector);

  const dispatch = useDispatch();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  const { error, isPending, makeCall } = useDispatchApiCall();

  const { required, isValidPassword, equalTo } = useFieldValidators();
  const t = useTranslations('pages.inquiryDetails.additionalFinancingPartners');

  const mapAndSaveResponse = ({ payload }: ApiActionResponseModel) =>
    dispatch(mapInquiryDetailsApiResponseAction(payload.data));

  const forwardToCompeon = (values: { password: string; confirmPassword: string }) =>
    makeCall(forwardToCompeonAction(inquiryId, values), mapAndSaveResponse);
  const abortForwarding = () =>
    makeCall(abortForwardingToCompeonAction(inquiryId), mapAndSaveResponse);

  return (
    <Section>
      <Text>{t('heading')}</Text>
      <Description>
        <p>{t('newUserInformation', { bankName: partnerName })}</p>
      </Description>
      <FinalForm
        onSubmit={forwardToCompeon}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CheckboxWithField
              name="consentCheck"
              validate={required}
              small
              text={
                <Text inactive>
                  {t('label', {
                    termsAndConditions: <b>{t('consent.termsAndConditions')}</b>,
                    privacyPolicy: <b>{t('consent.privacyPolicy')}</b>,
                  })}
                </Text>
              }
            />
            <Description>
              <p>{t('addPassword')}</p>
            </Description>

            <FormRow>
              <PasswordInputWithField
                caption={t('password.caption')}
                name="password"
                validate={combineValidators(required, isValidPassword)}
              />

              <PasswordInputWithField
                caption={t('password.repeatCaption')}
                name="confirmPassword"
                validate={combineValidators(required, equalTo('password'))}
              />
            </FormRow>

            <Actions>
              <ButtonComponent
                data-testid="offer-accept"
                isLoading={isPending}
                mr={4}
                type="submit"
              >
                {t('offerAccept')}
              </ButtonComponent>

              <ButtonComponent isLoading={isPending} onClick={abortForwarding} variant="tertiary">
                {t('offerDecline')}
              </ButtonComponent>
            </Actions>
            {error && <ErrorText>{error.message}</ErrorText>}
          </form>
        )}
      />
    </Section>
  );
};

export default ForwardToCompeonNewCustomer;
