import { PurposeKind } from 'models/PurposeKind.model';
import { RealEstateProject } from 'models/RealEstateProject.model';
import {
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND,
  REAL_ESTATE_PROJECT,
  PURPOSE_KIND__BUILDING,
} from 'modules/Inquiry/Form/formFields';
import {
  BusinessCondition,
  BusinessDataPopulators,
  FormSections,
} from 'schema/inquirySchema.models';

const combineConditions =
  (...conditions: BusinessCondition[]) =>
  (data: BusinessDataPopulators): boolean =>
    conditions.every((condition) => condition(data));

const isNotPurposeWithoutAmountSection: BusinessCondition = ({ formValues }) =>
  ![PURPOSE_KIND__CORONA, PURPOSE_KIND__PROJECT_FINANCING, PURPOSE_KIND__BUILDING].includes(
    formValues[PURPOSE_KIND],
  );

const hasSelectedPurpose =
  (purposeKind: PurposeKind): BusinessCondition =>
  ({ formValues }) =>
    formValues[PURPOSE_KIND] === purposeKind;

const hasSelectedRealEstateProject =
  (realEstateProject: RealEstateProject): BusinessCondition =>
  ({ formValues }) =>
    formValues[REAL_ESTATE_PROJECT] === realEstateProject;

const sectionsAreValid =
  (sectionsName: FormSections[]): BusinessCondition =>
  ({ sectionValidations }) =>
    sectionsName.every((section) => sectionValidations && sectionValidations[section]);

const oneOfConditions =
  (...conditions: BusinessCondition[]) =>
  (data: BusinessDataPopulators): boolean =>
    conditions.some((condition) => condition(data));

const projectIsSelectedAndSectionIsValid = (
  project: RealEstateProject,
  section: FormSections,
): BusinessCondition =>
  combineConditions(hasSelectedRealEstateProject(project), sectionsAreValid([section]));

const negation = (condition: BusinessCondition) => (data: BusinessDataPopulators) =>
  !condition(data);

export const schemaHelpers = {
  negation,
  combineConditions,
  oneOfConditions,
  isNotPurposeWithoutAmountSection,
  hasSelectedPurpose,
  sectionsAreValid,
  hasSelectedRealEstateProject,
  projectIsSelectedAndSectionIsValid,
};
