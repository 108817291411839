import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { borderRadius, transition } from 'theme/themeConstants';

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  ${mqMedium`
    margin-bottom: 0;
  `}
`;

export const StyledPaginationButton = styled.button`
  width: 3.25rem;
  height: 3.25rem;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  color: ${({ theme }) => theme.colors.text.tertiary};
  background-color: ${(props) => props.theme.colors.background.white};
  transition: border-color ${transition.default}, color ${transition.default};

  &.left {
    border-radius: ${borderRadius.default} 0 0 ${borderRadius.default};
  }

  &.right {
    border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
  }

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    opacity: 1;
    color: ${({ theme }) => theme.colors.text.tertiary};

    &:focus,
    &:hover {
      border-color: ${({ theme }) => theme.colors.border.lightGrey};
      color: ${({ theme }) => theme.colors.text.tertiary};
    }
  }
`;

export const StyledPaginationNumber = styled(StyledPaginationButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  border-left: none;
  border-radius: 0;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.lightGrey};
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`;
