import { useEffect } from 'react';

import { createQuery } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { useAssociatedPeopleMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/AssociatedPeopleModeProvider';
import { PortalBeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { transformBeneficialOwners } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/transformers';
import {
  transformDzbLegalRepresentative,
  transformLegalRepresentatives,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/transformers';
import { getInquirySubStatusSelector } from 'store/inquiryDetails/selectors';

import {
  DzbPortalLegalRepresentative,
  PortalLegalRepresentative,
} from '../components/legalRepresentatives/fields';

const EDIT_MODE_SUBSTATUS = 'audit_representatives';

type FetchAssociatedPeopleVariables = {
  companyId: string;
};

export const ASSOCIATED_PEOPLE_QUERY_KEY = 'company/associated_people';

const useFetchAssociatedPeople = createQuery<any, FetchAssociatedPeopleVariables>({
  primaryKey: ASSOCIATED_PEOPLE_QUERY_KEY,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.COMPANIES.SPECIALIZED.ASSOCIATES.compose({ params: { id: variables.companyId } }),
    ),
});

type AssociatedPeople<LegalRepresentative, BeneficialOwner> = {
  legalRepresentatives: Array<LegalRepresentative>;
  beneficiaryOwners: Array<BeneficialOwner>;
  isLoading: boolean;
};

const useGetBackendResponse = ({ companyId }: FetchAssociatedPeopleVariables) => {
  const { data, isLoading } = useFetchAssociatedPeople({
    variables: {
      companyId,
    },
  });

  if (!data) {
    // If there's no data yet, you can return early with the isLoading state.
    return { legalRepresentatives: [], beneficiaryOwners: [], isLoading };
  }

  const includedData = data?.data?.included ?? []; // Using optional chaining and nullish coalescing
  const legalRepresentatives = includedData.filter(
    (item: any) => item.type === 'legal_representatives',
  );

  const beneficiaryOwners = includedData.filter((item: any) => item.type === 'beneficiary_owners');

  return {
    legalRepresentatives,
    beneficiaryOwners,
    isLoading,
  };
};

export const useGetAssociatedPeople = ({
  companyId,
}: FetchAssociatedPeopleVariables): AssociatedPeople<
  PortalLegalRepresentative,
  PortalBeneficialOwner
> => {
  const { legalRepresentatives, beneficiaryOwners, isLoading } = useGetBackendResponse({
    companyId,
  });

  // Use the mapping functions directly
  const transformedLegalRepresentatives = transformLegalRepresentatives(legalRepresentatives);
  const transformedBeneficialOwners = transformBeneficialOwners(beneficiaryOwners);

  // Return the mapped data along with the loading state
  return {
    legalRepresentatives: transformedLegalRepresentatives,
    beneficiaryOwners: transformedBeneficialOwners,
    isLoading,
  };
};

const useSetAssociatedPeopleMode = () => {
  const { setMode } = useAssociatedPeopleMode();
  const substatus = useSelector(getInquirySubStatusSelector);

  useEffect(() => {
    if (substatus === EDIT_MODE_SUBSTATUS) {
      setMode('edit');
    } else {
      setMode('view');
    }
  }, [setMode, substatus]);
};

export const useGetDzbAssociatedPeople = ({
  companyId,
}: FetchAssociatedPeopleVariables): AssociatedPeople<
  DzbPortalLegalRepresentative,
  PortalBeneficialOwner
> => {
  const { legalRepresentatives, beneficiaryOwners, isLoading } = useGetBackendResponse({
    companyId,
  });
  useSetAssociatedPeopleMode();

  const transformedLegalRepresentatives = transformDzbLegalRepresentative(legalRepresentatives);
  const transformedBeneficialOwners = transformBeneficialOwners(beneficiaryOwners);

  return {
    legalRepresentatives: transformedLegalRepresentatives,
    beneficiaryOwners: transformedBeneficialOwners,
    isLoading,
  };
};
