import React from 'react';

import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxWithField } from 'components/Checkbox';
import { ErrorText, Text } from 'components/Text';
import { usePartnerConfig } from 'config/partner/hooks';
import { ApiActionResponseModel } from 'models/ApiActionResponse.model';
import ForwardToCompeonExistingCustomer from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/ForwardToCompeonExistingCustomer';
import ForwardToCompeonNewCustomer from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/ForwardToCompeonNewCustomer';
import {
  abortForwardingToCompeonAction,
  canConfirmForwardingToCompeon,
  forwardToCompeonAction,
} from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import {
  getInquiryIdSelector,
  inquiryDetailsCustomerSelector,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledButtonsWrapper as Actions, StyledConsentDescription, StyledSection } from './styles';

const ForwardToCompeonAcceptance: React.FC = () => {
  const inquiryId = useSelector(getInquiryIdSelector);

  const dispatch = useDispatch();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  const { error, isPending, makeCall } = useDispatchApiCall();

  const { required } = useFieldValidators();
  const t = useTranslations('pages.inquiryDetails.additionalFinancingPartners');

  const mapAndSaveResponse = ({ payload }: ApiActionResponseModel) =>
    dispatch(mapInquiryDetailsApiResponseAction(payload.data));

  const forwardToCompeon = () => makeCall(forwardToCompeonAction(inquiryId), mapAndSaveResponse);
  const abortForwarding = () =>
    makeCall(abortForwardingToCompeonAction(inquiryId), mapAndSaveResponse);

  return (
    <StyledSection>
      <Text>{t('heading')}</Text>
      <StyledConsentDescription>
        <p>{t('information', { bankName: partnerName })}</p>
      </StyledConsentDescription>
      <FinalForm
        onSubmit={forwardToCompeon}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CheckboxWithField
              name="consentCheck"
              data-testid="give-consent"
              validate={required}
              small
              text={
                <Text inactive>
                  {t('label', {
                    termsAndConditions: <b>{t('consent.termsAndConditions')}</b>,
                    privacyPolicy: <b>{t('consent.privacyPolicy')}</b>,
                  })}
                </Text>
              }
            />

            <Actions>
              <ButtonComponent
                data-testid="offer-accept"
                isLoading={isPending}
                mr={4}
                type="submit"
              >
                {t('offerAccept')}
              </ButtonComponent>

              <ButtonComponent isLoading={isPending} onClick={abortForwarding} variant="tertiary">
                {t('offerDecline')}
              </ButtonComponent>
            </Actions>
            {error && <ErrorText>{error.message}</ErrorText>}
          </form>
        )}
      />
    </StyledSection>
  );
};

export default canConfirmForwardingToCompeon(() => {
  const isCompeonManaged = useConfig(CONFIG.IS_COMPEON_MANAGED);
  const { existsInCompeon } = useSelector(inquiryDetailsCustomerSelector) || {};

  if (isCompeonManaged) {
    if (existsInCompeon) {
      return <ForwardToCompeonExistingCustomer />;
    }
    return <ForwardToCompeonNewCustomer />;
  }

  return <ForwardToCompeonAcceptance />;
});
