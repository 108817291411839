import _get from 'lodash/get';

import {
  CURRENT_USAGE_SQM_PRICE_RESIDENTIAL,
  CURRENT_USAGE_SQM_PRICE_COMMERCIAL,
  CURRENT_USAGE_PRICE_GARAGE_PARKING,
  CURRENT_USAGE_PRICE_OUTSIDE_PARKING,
  CURRENT_USAGE_SQM_PRICE_PROPERTY,
  CURRENT_USAGE_FAIR_MARKET_VALUE,
  CURRENT_USAGE_LOAN_VALUE,
  CURRENT_USAGE_SQM_RENT_RESIDENTIAL,
  CURRENT_USAGE_SQM_RENT_COMMERCIAL,
  CURRENT_USAGE_RENT_GARAGE_PARKING,
  CURRENT_USAGE_RENT_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_RESIDENTIAL,
  FUTURE_USAGE_SQM_PRICE_COMMERCIAL,
  FUTURE_USAGE_PRICE_GARAGE_PARKING,
  FUTURE_USAGE_PRICE_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_PROPERTY,
  FUTURE_USAGE_FAIR_MARKET_VALUE,
  FUTURE_USAGE_LOAN_VALUE,
  FUTURE_USAGE_SQM_RENT_RESIDENTIAL,
  FUTURE_USAGE_SQM_RENT_COMMERCIAL,
  FUTURE_USAGE_RENT_GARAGE_PARKING,
  FUTURE_USAGE_RENT_OUTSIDE_PARKING,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';

import { unknownToString } from './mapProfitabilitySectionsToApi/utils';

const mapBankValuesObjectDataToApi = (data: Record<string, unknown>) => {
  const { toFloatPrecision2 } = fieldParsers;
  const initialValue = toFloatPrecision2('0,00');

  const bankValueData = {
    bank_values_object_data: {
      current: {
        sqm_price_residential:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_SQM_PRICE_RESIDENTIAL))) ||
          initialValue,
        sqm_price_commercial:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_SQM_PRICE_COMMERCIAL))) ||
          initialValue,
        price_garage_parking:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_PRICE_GARAGE_PARKING))) ||
          initialValue,
        price_outside_parking:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_PRICE_OUTSIDE_PARKING))) ||
          initialValue,
        sqm_price_property:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_SQM_PRICE_PROPERTY))) ||
          initialValue,
        fair_market_value:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_FAIR_MARKET_VALUE))) ||
          initialValue,
        loan_value:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_LOAN_VALUE))) || initialValue,
        sqm_rent_residential:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_SQM_RENT_RESIDENTIAL))) ||
          initialValue,
        sqm_rent_commercial:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_SQM_RENT_COMMERCIAL))) ||
          initialValue,
        rent_garage_parking:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_RENT_GARAGE_PARKING))) ||
          initialValue,
        rent_outside_parking:
          toFloatPrecision2(unknownToString(_get(data, CURRENT_USAGE_RENT_OUTSIDE_PARKING))) ||
          initialValue,
      },
      future: {
        sqm_price_residential:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_SQM_PRICE_RESIDENTIAL))) ||
          initialValue,
        sqm_price_commercial:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_SQM_PRICE_COMMERCIAL))) ||
          initialValue,
        price_garage_parking:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_PRICE_GARAGE_PARKING))) ||
          initialValue,
        price_outside_parking:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_PRICE_OUTSIDE_PARKING))) ||
          initialValue,
        sqm_price_property:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_SQM_PRICE_PROPERTY))) ||
          initialValue,
        fair_market_value:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_FAIR_MARKET_VALUE))) ||
          initialValue,
        loan_value:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_LOAN_VALUE))) || initialValue,
        sqm_rent_residential:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_SQM_RENT_RESIDENTIAL))) ||
          initialValue,
        sqm_rent_commercial:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_SQM_RENT_COMMERCIAL))) ||
          initialValue,
        rent_garage_parking:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_RENT_GARAGE_PARKING))) ||
          initialValue,
        rent_outside_parking:
          toFloatPrecision2(unknownToString(_get(data, FUTURE_USAGE_RENT_OUTSIDE_PARKING))) ||
          initialValue,
      },
    },
  };

  return bankValueData;
};

export default mapBankValuesObjectDataToApi;
