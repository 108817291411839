import React from 'react';

import FormSection from 'components/FormSection';
import ForLoggedInUser from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson/ForLoggedInUser/ForLoggedInUser';
import ForNotLoggedInUser from 'modules/Inquiry/Form/Steps/CompanyDetails/ContactPerson/ForNotLoggedInUser/ForNotLoggedInUser';
import { CustomerDetails } from 'pages/inquiryFlow/CustomerDetails';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser, userIsBankAdvisor, userIsInquiryManager } from 'utils/user/conditionals';

interface Props {
  sectionNumber?: number;
}

const ContactPerson = ({ sectionNumber }: Props) => {
  const t = useTranslations('pages.companyDetails.sections.contactPerson');
  const isLoggedIn = isLoggedInUser();
  const isBA = userIsBankAdvisor();
  const isIM = userIsInquiryManager();

  return (
    <FormSection
      name={FormSections.companyContact}
      sectionNumber={sectionNumber ?? 1}
      title={t('title')}
    >
      {isBA || isIM ? (
        <CustomerDetails />
      ) : isLoggedIn ? (
        <ForLoggedInUser />
      ) : (
        <ForNotLoggedInUser />
      )}
    </FormSection>
  );
};

export default ContactPerson;
