import _isNil from 'lodash/isNil';

export const translatePropertyName = (property, translator, productName) => {
  return translator(`properties.${productName}.${property.name}.caption`);
};

const translateBooleanValue = (value, translator) =>
  value ? translator('properties.trueValue') : translator('properties.negativeValue');
const translateOneOfType = (property, productName, translator) =>
  translator(`properties.${productName}.${property.name}.options.${property.value}`);

export const translatePropertyValue = (property, translator, productName) => {
  const isBoolean = typeof property.value === 'boolean';
  if (isBoolean) {
    return translateBooleanValue(property.value, translator);
  }

  const isOneOfType = ['amortisationType'].includes(property.name);
  if (isOneOfType) {
    return translateOneOfType(property, productName, translator);
  }

  return property.value;
};

export const translateCompeonOfferProperty = (translator, productName) => (property) => ({
  name: property.name,
  value: translatePropertyValue(property, translator, productName),
  label: translatePropertyName(property, translator, productName),
});

export const translateCompeonEmptyProperties = (translator) => (property) => {
  const { value } = property;
  if (!_isNil(value)) {
    return property;
  }
  return { ...property, value: translator('properties.notAvailable') };
};
