import React from 'react';

import env from '@beam-australia/react-env';
import { Helmet } from 'react-helmet';

import { EnvironmentVariable } from 'utils/environment';
import { generateNonce } from 'utils/security';
import { CSP_NONCE } from 'utils/sessionStorage/keys';

/*
Explanation of the CSP policy:

style-src:
Currently style-src is set to unsafe-inline which means inline styles can be loaded. Inline styles can be dangerous when they are being sourced from user input (DB, query params, …) or other non-sanitised sources.
Since third party packages such as Styled Components or Emotion Sheet are using inline styles, and it is neither possible/feasible to extract these packages in a separate policy source (using nonce or hash) nor
feasible to replace them we have to include them as source using unsafe-inline. This is a theoretical risk but if we are using third party packages this risk is always given. Regarding the inline styles we
are using in our codebase, we have implemented linting rules to avoid inline styles as much as possible.
*/

function CspMeta() {
  const cspNonce = generateNonce();
  sessionStorage.setItem(CSP_NONCE, cspNonce);

  const whiteLabelApiUrl = env(EnvironmentVariable.WHITE_LABEL_API_URL);
  const compeonApiUrl = env(EnvironmentVariable.COMPEON_API_URL);
  const authApiUrl = env(EnvironmentVariable.AUTH_API_URL);
  const wsUrl = env(EnvironmentVariable.WS_URL);
  const compeonOAuthApiUrl = env(EnvironmentVariable.COMPEON_OAUTH_API_URL);
  const logoStorageUrl = env(EnvironmentVariable.LOGO_STORAGE_URL);
  const backendUrl = 'https://be.eco-banking.dev.fnstrt.de';
  const googleStorageApi = 'https://storage.googleapis.com';
  const matomoUrl = 'https://statistik.elp.de';
  const countryFlagsUrl = 'https://purecatamphetamine.github.io';
  const freshChatUrl = 'https://wchat.freshchat.com';
  const freshChatEuUrl = 'https://wchat.eu.freshchat.com';
  const freshChatWebpushUrl = 'https://*.eu.webpush.freshchat.com';
  const freshChatWidgetUrl = 'https://euc-widget.freshworks.com';
  // Not using the protocol for etracker and GTM because it will be chosen based on the current page protocol and etracker is doing the same
  const etrackerCodeUrl = 'code.etracker.com';
  const etrackerUrl = 'www.etracker.de';
  const googleTagManagerUrl = 'www.googletagmanager.com';
  const fontsGoogleApiUrl = 'fonts.googleapis.com';
  const googleAnalyticsUrl = '*.google-analytics.com';
  const googleAnalyticsUrl2 = '*.analytics.google.com';
  const googleUrls = '*.google.com *.google.de';
  const bingUrl = '*.bing.com';
  const usercentricsUrl = '*.usercentrics.eu';
  const gstaticUrl = 'fonts.gstatic.com';
  const sozialfactoringUrl = '*.sozialfactoring.de https://sozialfactoring.de';
  const clarityUrl = '*.clarity.ms';
  const outbrainUrl = '*.outbrain.com';
  const doubleclickUrl = '*.doubleclick.net';
  const taboolaUrl = '*.taboola.com';
  const linkedinUrl = '*.linkedin.com';
  const self = "'self'";

  const defaultHosts = [
    whiteLabelApiUrl,
    compeonApiUrl,
    authApiUrl,
    wsUrl,
    compeonOAuthApiUrl,
    logoStorageUrl,
    backendUrl,
    sozialfactoringUrl,
    self,
  ].join(' ');

  const allFreshChatHosts = [
    freshChatUrl,
    freshChatEuUrl,
    freshChatWebpushUrl,
    freshChatWidgetUrl,
  ].join(' ');

  const allEtrackerHosts = [etrackerCodeUrl, etrackerUrl].join(' ');

  let content = `default-src ${defaultHosts};
  style-src 'unsafe-inline' 'self' ${freshChatEuUrl} ${googleTagManagerUrl} ${fontsGoogleApiUrl};
  script-src 'self' 'unsafe-eval' 'nonce-${cspNonce}' ${usercentricsUrl};
  img-src blob: data: ${countryFlagsUrl} ${googleStorageApi} ${bingUrl} ${defaultHosts} ${googleTagManagerUrl} ${gstaticUrl} ${usercentricsUrl} ${linkedinUrl} ${googleUrls} ${clarityUrl};
  script-src-elem 'nonce-${cspNonce}' blob: ${allFreshChatHosts} ${matomoUrl} ${allEtrackerHosts} ${googleTagManagerUrl} ${bingUrl} ${usercentricsUrl} ${defaultHosts} ${taboolaUrl} ${outbrainUrl} ${clarityUrl};
  connect-src ${matomoUrl} ${allEtrackerHosts} ${googleTagManagerUrl} ${usercentricsUrl} ${defaultHosts} ${googleAnalyticsUrl} ${googleAnalyticsUrl2} ${taboolaUrl} ${outbrainUrl} ${googleUrls} ${linkedinUrl} ${clarityUrl} ${doubleclickUrl};
  frame-src ${freshChatEuUrl} ${freshChatWebpushUrl} ${defaultHosts} ${usercentricsUrl} ${doubleclickUrl};
  font-src ${gstaticUrl};
  base-uri 'self';`;

  return (
    <Helmet>
      <meta http-equiv="Content-Security-Policy" content={content} />
    </Helmet>
  );
}

export default CspMeta;
