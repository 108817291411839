import { AxiosRequestHeaders } from 'axios';
import { get as _get } from 'lodash';

import { API_HEADERS } from 'api/apiService';
import { AUTH_EXPIRATION_KEY, AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from 'types/Auth';
import { UserState } from 'types/User';

import { getUser } from './user/getters';
import { setUser } from './user/setters';

type Token = {
  /* eslint-disable camelcase */
  access_token: string;
  refresh_token: string;
  identity_token?: string;
  /* eslint-enable camelcase */
};

// Getters
export const getAccessToken = () => _get(getUser(), 'token');
export const getRefreshToken = () => _get(getUser(), 'refreshToken');

export const getUserTokens = ({ access_token: token, refresh_token: refreshToken }: Token) => ({
  token,
  refreshToken,
});

export const getAccessTokenData = () => ({
  accessToken: sessionStorage.getItem(AUTH_TOKEN_KEY) || '',
  refreshToken: sessionStorage.getItem(AUTH_REFRESH_TOKEN_KEY) || '',
  expiryDate: sessionStorage.getItem(AUTH_EXPIRATION_KEY) || '',
});

export const getAuthorizationToken = () => {
  const token = getAccessToken();
  if (!token) {
    return {};
  }
  return API_HEADERS.bearerToken(token);
};

export const getHeadersWithAuthToken = (headers: AxiosRequestHeaders) => ({
  ...headers,
  ...getAuthorizationToken(),
});

export const getExpirationDate = (issuedAt: number, secondsToExpire: number) => {
  const expiresAt = new Date((issuedAt + secondsToExpire) * 1000);
  return expiresAt.toString();
};

// Setters
export const setToken = (newTokenData: Partial<Omit<UserState, 'attributes'>>) => {
  const newUser = {
    ...getUser(),
    ...newTokenData,
  };
  setUser(newUser);
};
