import React from 'react';

import { bool, number } from 'prop-types';
import styled, { css } from 'styled-components/macro';

const Svg = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
`;

const Background = styled.circle`
  ${(props) =>
    props.active
      ? css`
          fill: none;
          stroke: ${props.theme.colors.border.lightGrey};
        `
      : css`
          fill: ${props.theme.colors.background.lightGrey};
          stroke: none;
        `}
`;

const calculateArcPath = (diameter, thickness, angle) => {
  const startX = diameter / 2;
  const startY = thickness / 2;
  const startingPoint = `${startX}, ${startY}`;

  const centerX = startX;
  const centerY = startX;

  const radius = (diameter - thickness) / 2;

  const endX = centerX + Math.sin(angle) * radius;
  const endY = centerY - Math.cos(angle) * radius;
  const endPoint = `${endX} ${endY}`;

  return `M ${startingPoint} A ${radius} ${radius} 0 ${angle > Math.PI ? 1 : 0} 1 ${endPoint}`;
};

const ProgressIndicator = ({ active, diameter, progress, thickness }) => (
  <Svg width={diameter} height={diameter}>
    <Background
      active={active}
      strokeWidth={thickness}
      r={(diameter - thickness) / 2}
      cx={diameter / 2}
      cy={diameter / 2}
    />
    {progress < 1 ? (
      <path
        strokeWidth={thickness}
        d={calculateArcPath(diameter, thickness, 2 * Math.PI * progress)}
      />
    ) : (
      <circle
        strokeWidth={thickness}
        r={(diameter - thickness) / 2}
        cx={diameter / 2}
        cy={diameter / 2}
      />
    )}
  </Svg>
);

ProgressIndicator.propTypes = {
  active: bool.isRequired,
  diameter: number.isRequired,
  progress: number.isRequired,
  thickness: number.isRequired,
};

export default ProgressIndicator;
