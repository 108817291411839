import { PropertiesToString } from 'new/form/common/types';

import { AssociatedPersonSource } from '../../types';

export type LegalRepresentative = {
  id: string | undefined;
  salutation: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthDate: Date | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  soleSignatureAuthorized: boolean | undefined;
};

export type DzbLegalRepresentative = LegalRepresentative & {
  taxIdentificationNumber: string | undefined;
};

export type PortalLegalRepresentative = LegalRepresentative & {
  source: AssociatedPersonSource;
  potentiallyManipulated: boolean;
};

export type DzbPortalLegalRepresentative = DzbLegalRepresentative & PortalLegalRepresentative;

export const legalRepresentativeFields: PropertiesToString<LegalRepresentative> = {
  id: 'id',
  salutation: 'salutation',
  firstName: 'firstName',
  lastName: 'lastName',
  birthDate: 'birthDate',
  email: 'email',
  phoneNumber: 'phoneNumber',
  soleSignatureAuthorized: 'soleSignatureAuthorized',
} as const;

export const dzbLegalRepresentativeFields: PropertiesToString<DzbLegalRepresentative> = {
  ...legalRepresentativeFields,
  taxIdentificationNumber: 'taxIdentificationNumber',
};
