import { LabelsMap, translations as translationsObject } from 'new/form/common/types';

import { mmvFields } from '../../../../fields';

const fields = mmvFields.contractDetails.financingObjectSection;

const translations =
  translationsObject.inquiryType.mmv.steps.contractDetails.sections.financingObject.fields;

export const financingObjectLabelsMap: LabelsMap = {
  [fields.objectDescription]: translations.objectDescription.caption,
  [fields.state]: translations.state.caption,
  [fields.objectAlreadyDelivered]: translations.objectAlreadyDelivered.caption,
  [fields.serialNumber]: translations.serialNumber.caption,
};
