import { useContext } from 'react';

import _get from 'lodash/get';

import { useInquiryValuesContext } from 'components/App/InquiryValuesProvider';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';

export const useFormValues = (): Record<string, unknown> => {
  const { inquiryValues } = useInquiryValuesContext();

  return inquiryValues;
};

export const useFieldValue = <T = unknown>(name: string, defaultValue: T): T => {
  const { inquiryValues: values } = useInquiryValuesContext();
  const { parentFieldName } = useContext(ParentFieldContext);
  const fieldName =
    parentFieldName && !name.includes(parentFieldName) ? `${parentFieldName}.${name}` : name;

  const value = _get(values, fieldName) as T;

  return value || defaultValue;
};
