import { createContext } from 'react';

import { FINANCING_NEED } from 'modules/Inquiry/Form/formFields';
export interface IDefaultContext {
  stepName: string;
}

export const defaultContext = {
  stepName: FINANCING_NEED,
};

export const StepNameContext = createContext<IDefaultContext>(defaultContext);
