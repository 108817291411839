import {
  GUARANTY_GUARANTY_REQUIREMENT,
  GUARANTY_RENTAL_DEPOSIT,
  GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
  GUARANTY_PERFORMANCE_OF_CONTRACT,
  GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
  GUARANTY_WARRANTY,
  GUARANTY_LITIGATION_BOND,
  GUARANTY_OTHER_TYPE,
  GUARANTY_DESCRIPTION,
} from 'modules/Inquiry/Form/formFields';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';

const { toBoolean, toInteger } = fieldParsers;
const mapguarantyType: InquiryFormMapper = (allFields: any) => {
  let guarantyType = allFields[GUARANTY_OTHER_TYPE];

  switch (true) {
    case toBoolean(allFields[GUARANTY_RENTAL_DEPOSIT]):
      guarantyType = GUARANTY_RENTAL_DEPOSIT;
      break;
    case toBoolean(allFields[GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS]):
      guarantyType = GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS;
      break;
    case toBoolean(allFields[GUARANTY_PERFORMANCE_OF_CONTRACT]):
      guarantyType = GUARANTY_PERFORMANCE_OF_CONTRACT;
      break;
    case toBoolean(allFields[GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS]):
      guarantyType = GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS;
      break;
    case toBoolean(allFields[GUARANTY_WARRANTY]):
      guarantyType = GUARANTY_WARRANTY;
      break;
    case toBoolean(allFields[GUARANTY_LITIGATION_BOND]):
      guarantyType = GUARANTY_LITIGATION_BOND;
      break;
    default:
      break;
  }

  return {
    'guaranty-type': guarantyType,
  };
};

export const mapGuarantyToApi: InquiryFormMapper = (allFields: any) => {
  return {
    'guaranty-Requirement': toInteger(allFields[GUARANTY_GUARANTY_REQUIREMENT]),
    ...mapguarantyType(allFields),
    'guaranty-other-type': allFields[GUARANTY_OTHER_TYPE],
    'guaranty-description': allFields[GUARANTY_DESCRIPTION],
  };
};
