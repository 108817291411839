import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

type Props = {
  pathToEdit?: string;
  recordType: string;
  editFormStructure: EditMarketValue[];
};

const useEditFormConfig = ({ pathToEdit, recordType, editFormStructure }: Props) => {
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const history = useHistory();

  const onEdit = () => {
    history.push({
      pathname: pathToEdit,
      state: {
        editFormStructure,
        recordType,
        inquiryId,
      },
    });
  };
  return { onEdit };
};

export default useEditFormConfig;
