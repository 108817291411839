import React from 'react';

import { FINANCING_AMOUNT } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useIsCompanyYoung } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/quickCredit/fields/MaxFinancingAmountFields/isCompanyYoung';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, upperBound } from 'utils/validators';

/*
 * Max value error logic from ticket CRV-1533
 */

const NeededFinancing = ({ maxFinancing }: { maxFinancing: number }) => {
  const t = useTranslations('pages.coronaStep.fields.neededFinancing');
  const { required, maxLength, minPrice } = useFieldValidators();

  const isYoungCompany = useIsCompanyYoung();

  const validatorAmount = { amount: maxFinancing };
  const maxValueValidator = upperBound(
    maxFinancing,
    isYoungCompany
      ? t('errorYoungCompany', validatorAmount)
      : t('errorOldCompany', validatorAmount),
  );
  return (
    <AmountWithField
      caption={t('caption')}
      name={FINANCING_AMOUNT}
      validate={combineValidators(required, maxLength(9), minPrice(1000), maxValueValidator)}
    />
  );
};

export default NeededFinancing;
