import React from 'react';

import { Center } from '@chakra-ui/react';

import { SignedContract } from 'components/UploadBlock/NewFileRequestBlock/SignedContract';
import { translations } from 'new/form/common/types';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useLatestSignedContractDetails } from './useLatestSignedContractDetails';
import FileSectionTitle from '../FileSectionTitle';

const { signedContractFromBank, signedContractUnavailable } =
  translations.pages.inquiryDetails.documentExchange;

const LatestSignedContractFromBank = () => {
  const t = useTranslations();

  const latestSignedContractDetails = useLatestSignedContractDetails();

  const canDisplaySignedContract = Boolean(latestSignedContractDetails?.url);

  return (
    <section>
      <FileSectionTitle>{t(signedContractFromBank)}</FileSectionTitle>
      {canDisplaySignedContract ? (
        <SignedContract {...latestSignedContractDetails} />
      ) : (
        <Center paddingTop={6} paddingBottom={12}>
          <TextComponent color="text.tertiary" textAlign="center">
            {t(signedContractUnavailable)}
          </TextComponent>
        </Center>
      )}
    </section>
  );
};

export default LatestSignedContractFromBank;
