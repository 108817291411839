import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { removeFileAction } from 'store/documentExchange/documentExchange.actions';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { ButtonComponent } from 'theme/components/Button';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IRemoveFileInRequestButton {
  file: IFile | CompeonDocument;
  onRemove?: Function;
}

const RemoveFileInRequestButton = ({ file, onRemove }: IRemoveFileInRequestButton) => {
  const t = useTranslations('components.upload');

  const { makeCall, isPending } = useDispatchApiCall();
  const removeFile = async () => {
    await makeCall(removeFileAction(file.id));
    if (onRemove) {
      onRemove(file.id);
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ButtonComponent
        data-testid="remove-file-btn"
        isLoading={isPending}
        leftIcon={<DeleteIcon boxSize={6} display="block" />}
        onClick={onOpen}
        variant="tertiary"
      >
        {t('remove')}
      </ButtonComponent>

      <AlertDialogComponent
        cancelText={t('cancelText')}
        confirmText={t('confirmText')}
        testId="remove-file-modal"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={removeFile}
        title={t('remove')}
      >
        {t('doYouWantToRemove', { filename: file.fileName })}
      </AlertDialogComponent>
    </>
  );
};

export default RemoveFileInRequestButton;
