import React from 'react';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { useFormConfig } from 'config/formConfig/hooks';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import {
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useTranslations } from 'utils/hooks/useTranslations';

const FillCompanyInformationForm = () => {
  const { selectedInquiryType } = useFormConfig();
  const isBfsService = selectedInquiryType === InquiryType.bfsService;
  const t = useTranslations('inquiryType.bfsService.steps.companyDetails.sections.fields');
  const caption = isBfsService ? t('userProfileCompanyStreet.caption') : '';

  return (
    <>
      <FormRow>
        <InputWithField name={USER_PROFILE_COMPANY_NAME} />
      </FormRow>
      <FormRow>
        <InputWithField name={USER_PROFILE_COMPANY_STREET} caption={caption} />
      </FormRow>
      {isBfsService ? (
        <FormRow>
          <InputWithField
            name={USER_PROFILE_COMPANY_ZIP_CODE}
            type="tel"
            pattern="[0-9]*"
            inputMaxLimit={MAX_ZIP_CODE_LENGTH}
            separateThousands={false}
          />
          <InputWithField name={USER_PROFILE_COMPANY_CITY} />
        </FormRow>
      ) : (
        <FormRow separator>
          <InputWithField
            name={USER_PROFILE_COMPANY_ZIP_CODE}
            type="tel"
            pattern="[0-9]*"
            inputMaxLimit={MAX_ZIP_CODE_LENGTH}
            separateThousands={false}
          />
          <InputWithField name={USER_PROFILE_COMPANY_CITY} />
        </FormRow>
      )}
    </>
  );
};

export default FillCompanyInformationForm;
