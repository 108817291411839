import React from 'react';

import { useIntl } from 'react-intl';

import { SelectWithField } from 'components/Selects/Select';
import { useFormConfig } from 'config/formConfig/hooks';
import { REAL_ESTATE_KIND } from 'modules/Inquiry/Form/formFields';
import { getRealEstateFinancingObjectsOptions } from 'store/partner/config/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const RealEstateKind: React.FC = () => {
  const t = useTranslations();
  const { formatMessage } = useIntl();
  const { dropdowns } = useFormConfig();
  const options = getRealEstateFinancingObjectsOptions(formatMessage, dropdowns);
  return (
    <SelectWithField
      name={REAL_ESTATE_KIND}
      placeholder={t('placeholders.pleaseChoose')}
      options={options}
      data-testid={'select-field-' + REAL_ESTATE_KIND}
    />
  );
};

export default RealEstateKind;
