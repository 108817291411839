import styled from 'styled-components/macro';

import { borderRadius, transition } from 'theme/themeConstants';

export const StyledBooleanFields = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 3rem;
  }
`;

export const StyledBooleanInput = styled.div`
  input[type='radio'] {
    opacity: 0;

    & + label {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        border: 2px solid ${({ theme }) => theme.colors.primary};
        border-radius: ${borderRadius.circle};
        width: 2.5rem;
        height: 2.5rem;
        display: block;
        margin-right: 0.5rem;
      }

      &::after {
        content: '';
        border-radius: ${borderRadius.circle};
        width: 0;
        height: 0;
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme.colors.primary};
        transition: opacity ${transition.default}, left ${transition.default},
          width ${transition.default}, height ${transition.default};
      }
    }

    &:focus,
    &:hover {
      & + label {
        &::after {
          opacity: 0.3;
          left: 0.75rem;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }

    &:checked,
    &:checked:focus,
    &:checked:hover {
      & + label {
        &::after {
          opacity: 1;
          left: 0.75rem;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }
`;
