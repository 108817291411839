import { useEffect } from 'react';

import { FormApi } from 'final-form';
import { useForm, useFormState } from 'react-final-form';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { AnyConditions } from 'new/form/state/types';

import {
  useFormValidations,
  useMode,
  useInquiryProcessConfig,
} from '../state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from '../state/pageStateReducer';
import { ValidateSectionFunction } from '../useValidateFields';

/**
 * Retrieves the visible sections of a form based on the provided conditions and validations.
 *
 * @template Type - The type of inquiry for which to retrieve the visible sections.
 * @param {Record<any, ValidateSectionFunction<Type>>} validationsForCurrentStep - The validations for the current step of the form.
 * @param {FormApi} form - The form API object.
 * @param {any} values - The current form values.
 * @param {AnyConditions} conditions - The conditions to evaluate for each section.
 * @param {string} currentStep - The current step of the form.
 * @returns {Record<any, ValidateSectionFunction<any>>} - The visible sections of the form.
 */
function getVisibleSections<Type extends InquiryType>(
  validationsForCurrentStep: Record<any, ValidateSectionFunction<Type>>,
  form: FormApi,
  values: any,
  conditions: AnyConditions,
  currentStep: string,
): Record<any, ValidateSectionFunction<any>> {
  return Object.entries(validationsForCurrentStep).reduce((acc, [sectionName, validation]) => {
    const sectionString = `${currentStep}.${sectionName}`;
    const condition = conditions[sectionString];
    let isVisible = true;
    if (condition) {
      isVisible = condition({ values, form });
    }

    if (isVisible) {
      return {
        ...acc,
        [sectionName]: validation,
      };
    }

    return acc;
  }, {});
}

/**
 * Returns an object containing validation results for each section based on the given parameters.
 *
 * @param {Record<any, ValidateSectionFunction<Type>>} visibleSections - The sections that are currently visible.
 * @param {FormApi} form - The form API object.
 * @param {any} values - The current form values.
 * @param {any} store - The store object.
 * @param {AnyConditions} conditions - The conditions object.
 * @return {Record<any, boolean>} - An object containing validation results for each section,
 * where each key is the section key and each value is a boolean indicating if the section is valid.
 */
function getSectionValidationsObject<Type extends InquiryType>(
  visibleSections: Record<any, ValidateSectionFunction<Type>>,
  form: FormApi,
  values: any,
  store: any,
  conditions: AnyConditions,
): Record<any, boolean> {
  return Object.entries(visibleSections).reduce((acc, [key, currentSectionValidation]) => {
    const isCurrentSectionValid = currentSectionValidation(form, values, store, conditions);
    return {
      ...acc,
      [key]: isCurrentSectionValid,
    };
  }, {});
}

/**
 * Retrieves the section validations for the given step.
 *
 * @param {Record<any, ValidateSectionFunction<Type>>} validationsForCurrentStep - The validations for the current step.
 * @param {FormApi} form - The form API.
 * @param {any} values - The form values.
 * @param {any} store - The form store.
 * @param {AnyConditions} conditions - The form conditions.
 * @param {string} currentStep - The current step.
 * @returns {Object} - The section validations and progress information.
 */
export function getSectionValidations<Type extends InquiryType>(
  validationsForCurrentStep: Record<any, ValidateSectionFunction<Type>>,
  form: FormApi,
  values: any,
  store: any,
  conditions: AnyConditions,
  currentStep: string,
) {
  const visibleSections = getVisibleSections(
    validationsForCurrentStep,
    form,
    values,
    conditions,
    currentStep,
  );

  const numberOfSections = Object.keys(visibleSections).length;

  const sectionValidations = getSectionValidationsObject(
    visibleSections,
    form,
    values,
    store,
    conditions,
  );

  const numberOfValidSections = Object.values(sectionValidations).filter(Boolean).length;

  return {
    progress: {
      numberOfSections,
      numberOfValidSections,
      isSectionValid: numberOfSections === numberOfValidSections,
    },
    validations: sectionValidations,
  };
}

function useIsCalculationStep() {
  const {
    formStateData: { routeToPageNameMap },
  } = useInquiryProcessConfig();

  const {
    location: { pathname },
  } = useHistory();
  const mode = useMode();
  const pageRoutes = Object.values(routeToPageNameMap).map((record) => record[mode]);

  if (mode === 'editCustomer' || mode === 'editOperation') {
    return (
      pageRoutes.filter((pageRoute) => {
        const compare = pageRoute.split('/').pop();
        return compare === pathname.split('/').pop();
      }).length > 0
    );
  }

  return pageRoutes.includes(pathname);
}

export function useCalculateProgress() {
  const {
    state,
    dispatch,
    formStateData: { conditions },
  } = useInquiryProcessConfig();
  const validations = useFormValidations();
  const validationsForCurrentStep = validations[state.currentStep];
  const { values } = useFormState<MittweidaFieldTypes>();
  const form = useForm();
  const store = useStore();
  const isCalculationStep = useIsCalculationStep();
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (isCalculationStep) {
      const sectionValidations = getSectionValidations(
        validationsForCurrentStep,
        form,
        values,
        store,
        conditions,
        state.currentStep as string,
      );

      dispatch({
        type: PageStateActionTypes.SET_PROGRESS,
        payload: {
          ...state.pageValidations,
          [state.currentStep]: {
            ...sectionValidations,
          },
        },
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, dispatch, pathname]);
}
