import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { SummaryFieldsType } from 'new/form/model';

export const dzbSummaryFields: SummaryFieldsType<DzbFieldTypes> = {
  companyDataPage: {
    associationInformationSection: {
      name: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      contactPerson: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      totalPurchaseOfGoods: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      annualSalesForecast: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      contractDate: {
        unit: 'date',
        showOnSummaryPage: true,
      },
    },
    companyInformationSection: {
      companyName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      companyOlderThan3Years: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      address: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      zipCode: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      city: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      country: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      salesTaxID: {
        unit: 'none',
        showOnSummaryPage: true,
        showEmpty: true,
      },
      typeOfFinancialStatements: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lastAnnualFinancialStatementReportingDate: {
        unit: 'date',
        showOnSummaryPage: true,
      },
    },
    directDebitMandateSection: {
      iban: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      bic: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      bankName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
    startUpQuestionnaireSection: {
      industryExperienceYears: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      education: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      professionalCareer: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      alreadyGainedLeadershipExperience: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      leadershipExperienceYears: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      formOfLeadershipExperience: {
        unit: 'none',
        showOnSummaryPage: true,
      },
    },
  },
  retailerDataPage: {
    retailerInformationSection: {
      numberOfStores: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 1,
      },
      retailSpace: {
        unit: 'squareMeters',
        showOnSummaryPage: true,
        order: 2,
        showEmpty: true,
      },
      numberOfEmployees: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 3,
      },
      enterpriseResourcePlanningSystemInUse: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 4,
      },
      erpProvider: {
        unit: 'none',
        showOnSummaryPage: true,
        order: 5,
      },
    },
    onlineQuestionnaireSection: {
      shareOfOnlineSalesPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      averageShoppingBasketAmount: {
        unit: 'defaultCurrency',
        showOnSummaryPage: true,
      },
      returnRatePercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      ownOnlineShopPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      amazonPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      ebayPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      zalandoPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      othersPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      shareOfDistributionChannels: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      ownWarehousePercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      foreignWarehousePercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      shareOfWarehouse: {
        unit: 'none',
        showOnSummaryPage: false,
      },
      foreignLogisticsServiceProviderPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      foreignFulfillmentByAmazonPercentage: {
        unit: 'percentageDecimalString',
        showOnSummaryPage: true,
      },
      shareOfForeignLogistics: {
        unit: 'none',
        showOnSummaryPage: false,
      },
    },
  },
  personalDataPage: {
    contactPersonSection: {
      firstName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      lastName: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      email: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      phoneNumber: {
        unit: 'none',
        showOnSummaryPage: true,
        showEmpty: true,
      },
    },
    legalRepresentativesSection: {
      legalRepresentatives: {
        id: {
          unit: 'none',
          showOnSummaryPage: false,
        },
        salutation: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        firstName: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        lastName: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        birthDate: {
          unit: 'date',
          showOnSummaryPage: true,
        },
        email: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        phoneNumber: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        soleSignatureAuthorized: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        taxIdentificationNumber: {
          unit: 'none',
          showOnSummaryPage: true,
          showEmpty: true,
        },
      },
    },
    beneficialOwnersSection: {
      allInformationAvailable: {
        unit: 'none',
        showOnSummaryPage: true,
      },
      beneficialOwners: {
        id: {
          unit: 'none',
          showOnSummaryPage: false,
        },
        firstName: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        lastName: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        nationality: {
          unit: 'none',
          showOnSummaryPage: true,
        },
        capitalOrVotingRightsPercentage: {
          unit: 'percentageDecimalString',
          showOnSummaryPage: true,
          showEmpty: true,
        },
        placeOfResidence: {
          unit: 'none',
          showOnSummaryPage: true,
          showEmpty: true,
        },
        countryOfResidence: {
          unit: 'none',
          showOnSummaryPage: true,
          showEmpty: true,
        },
        birthPlace: {
          unit: 'none',
          showOnSummaryPage: true,
          showEmpty: true,
        },
        birthDate: {
          unit: 'date',
          showOnSummaryPage: true,
          showEmpty: true,
        },
        fictional: {
          unit: 'none',
          showOnSummaryPage: true,
          showEmpty: true,
        },
      },
    },
  },
};
