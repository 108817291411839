import React from 'react';

import DisabledFormSection from 'components/DisabledFormSection';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

const DisableDetailSection = () => {
  const t = useTranslations('pages.financingNeed');
  return (
    <DisabledFormSection sectionNumber={3} placeholder={t('disabledSections.amountAndPurpose')} />
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: () => null,
  default: DisableDetailSection,
});
