import styled, { css } from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { borderRadius, boxShadow, transition, zIndex } from 'theme/themeConstants';

export const StyledSelectWrapper = styled.div`
  position: relative;

  input {
    &:focus {
      border-radius: ${borderRadius.default} ${borderRadius.default} 0 0;
    }
  }
`;

const highlightElementState = css`
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const StyledSelectElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  line-height: 1;
  color: ${(props) => props.theme.colors.text.tertiary};
  transition: color ${transition.default}, background-color ${transition.default};

  ${({ highlighted }) => highlighted && highlightElementState}

  ${mqMedium`
    padding: 1.5rem;
    line-height: 1.25;
  `}
`;

export const StyledSelectList = styled.div`
  display: none;
  position: absolute;
  z-index: ${zIndex.select};
  top: 100%;
  left: 0;
  width: 100%;
  min-height: 100%;
  list-style: none;
  border-right: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  box-shadow: ${boxShadow.default};
  background-color: ${(props) => props.theme.colors.background.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
      max-height: ${({ height }) => `${height}px`};
    `}
`;

export const StyledFixedElements = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
`;

export const StyledMultiselectList = styled.ul`
  display: flex;
  margin: 2rem 0 0;

  li {
    position: relative;
    margin: 0.5rem;
    border-radius: ${borderRadius.default};
    padding: 1rem 2rem;
    list-style: none;
    color: ${({ theme }) => theme.colors.text.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledMultiselectUnselectBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: ${zIndex.default};
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${borderRadius.circle};
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.background.white};

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.text.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
