import React from 'react';

import PropTypes from 'prop-types';

import { SelectOptionShape } from 'components/PortalFormFields/SelectField';
import { StyledSelectElement } from 'components/PortalFormFields/SelectField/styles';
import CheckmarkIcon from 'theme/components/Icon/CheckmarkIcon';

const Element = ({ highlighted, itemProps, option: { label, component, checked }, ...rest }) => (
  <StyledSelectElement
    data-testid={`option:${label}`}
    highlighted={highlighted}
    {...itemProps}
    {...rest}
  >
    {component || label}
    {checked && <CheckmarkIcon boxSize={12} />}
  </StyledSelectElement>
);

Element.propTypes = {
  option: SelectOptionShape.isRequired,
  itemProps: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  highlighted: PropTypes.bool.isRequired,
};

export default Element;
