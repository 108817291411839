import React from 'react';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import PageHeading from 'components/PageHeading';
import { useTranslations } from 'utils/hooks/useTranslations';

const PeopleDetailsHeading = () => {
  const t = useTranslations('pages.peopleDetails.heading');

  return (
    <PageHeading
      heading={t('header', { roleSpecific: true })}
      subheading={t('subheader', { roleSpecific: true })}
    />
  );
};

const OnlineFactoringPeopleDetailsHeading = () => {
  const t = useTranslations('inquiryType.onlinefactoring.steps.peopleDetails');

  return <PageHeading heading={t('heading')} />;
};

const DisplayConditions = () => {
  return (
    <InquiryTypeCondition
      cases={{
        onlinefactoring: <OnlineFactoringPeopleDetailsHeading />,
        bfs_service: <OnlineFactoringPeopleDetailsHeading />,
        default: <PeopleDetailsHeading />,
      }}
    />
  );
};

export default DisplayConditions;
