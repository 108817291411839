import React, { useMemo } from 'react';

import paths from 'constants/paths';
import {
  RLLComputedProfitabilityEvaluationRentBaseValues,
  RLLComputedProfitabilityEvaluationRentCustomer,
  RLLComputedProfitabilityEvaluationRentMarketData,
  RLLComputedProfitabilityEvaluationRentRealEstateExpert,
} from 'models/types/Profitability.type';
import { PROFITABILITY_RECORD_TYPE } from 'pages/operationPortal/PlanningEvaluation/SharedComponent/constants';
import { useTranslations } from 'utils/hooks/useTranslations';

import { getData, getHeaders } from './module';
import { AccordionComponent } from '../../../../../../../theme/components/Accordion';
import mapProfitabilityToEdit from '../../../../EditMarketValuesReeProfitability/mapProfitabilityToEdit';
import useEditFormConfig from '../../../../useEditFormConfig';
import { useRoles } from '../../hooks';
import TableCardWithEdit from '../../TableCardWithEdit';

interface EvaluationRentProps {
  baseValues: RLLComputedProfitabilityEvaluationRentBaseValues;
  customerNow: RLLComputedProfitabilityEvaluationRentCustomer;
  customerPlan: RLLComputedProfitabilityEvaluationRentCustomer;
  inquiryId: string;
  marketData: RLLComputedProfitabilityEvaluationRentMarketData;
  realEstateExpert: RLLComputedProfitabilityEvaluationRentRealEstateExpert;
}

export const EvaluationRent: React.FC<EvaluationRentProps> = ({
  baseValues,
  customerNow,
  customerPlan,
  inquiryId,
  marketData,
  realEstateExpert,
}) => {
  const { rentalParameters } =
    paths.operation.inquiryDetails.planningEvaluation.profitabilityCalculationEdit;
  const t = useTranslations();
  const tPage = useTranslations('pages.planningEvaluation.profitabilityCalculation.sections.rent');
  const { hasRoleBankAdvisor, hasRoleRealEstateExpert } = useRoles();

  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(
    () => getData(t, { baseValues, customerNow, customerPlan, marketData, realEstateExpert }),
    [t, baseValues, customerNow, customerPlan, marketData, realEstateExpert],
  );

  const { onEdit } = useEditFormConfig({
    pathToEdit: rentalParameters(inquiryId),
    recordType: PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS,
    editFormStructure: mapProfitabilityToEdit[PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS](),
  });

  return (
    <AccordionComponent headingLevel="h3" mt={8} title={tPage('title')}>
      <TableCardWithEdit
        canEdit={hasRoleBankAdvisor || hasRoleRealEstateExpert}
        onEdit={onEdit}
        tableHeaders={headers}
        tableData={data}
        componentName={PROFITABILITY_RECORD_TYPE.RENTAL_PARAMETERS}
      />
    </AccordionComponent>
  );
};
