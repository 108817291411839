import styled from 'styled-components/macro';

import { StyledPageHeading as PortalPageHeading } from 'components/PortalPage/styles';

export const StyledPageHeading = styled(PortalPageHeading)`
  line-height: 1;
`;

export const StyledFilterWrapper = styled.div`
  margin-top: 2.75rem;
`;
