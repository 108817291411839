import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { transition } from 'theme/themeConstants';

export const StyledElementsPerPageList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  ${mqMedium`
    margin-top: 0;
  `}
`;

export const StyledElementsPerPageButton = styled.button<{ active: boolean }>`
  border-left: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  padding: 0 1rem;
  color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.text.tertiary)};
  transition: color ${transition.default};

  &:focus,
  &:hover {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary : rgba(theme.colors.primary, 0.5)};
  }
`;

export const StyledElementsPerPageOption = styled.li`
  list-style: none;

  &:first-child {
    ${StyledElementsPerPageButton} {
      border: 0;
    }
  }
`;
