import { mittweidaFields } from 'mittweida/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validateFields, ValidateSectionFunction } from 'new/form/useValidateFields';
import { isLoggedInUser } from 'utils/user/conditionals';

const { password, passwordConfirmation } = mittweidaFields.userProfilePage.passwordSection;

// Validations that are dependent on other fields do not work with the current validation map flow, therefore
// we added the fieldValidators to the field directly and do not export a fieldValidationMap here
export const validatePasswordSection: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  const isLoggedIn = isLoggedInUser();

  if (isLoggedIn) {
    return true;
  }

  return validateFields({
    fields: [password, passwordConfirmation],
    form,
    values,
    conditions,
  });
};
