import styled from 'styled-components/macro';

import { mqLarge } from 'styles/breakpoints';

export const StyledFilesSection = styled.ul.attrs({
  'data-testid': 'files-for-customer',
})`
  display: block;
  list-style: none;

  ${mqLarge`
    display: flex;
    flex-wrap: wrap;
    margin: 1rem -1rem 1rem;

    > li {
      flex: 0 0 auto;
      width: 50%;
      padding: 1rem;
    }
  `}
`;
