import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const GoodsIcon = (props: IconProps) => {
  return (
    <Icon
      width="38"
      height="48"
      viewBox="0 0 37.61 47.7"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M37.38 45.81a.62.62 0 00.12-.81l-3.06-3.67a.63.63 0 00-.39-.17H13.52l.05.72-.16-1.14a5.86 5.86 0 00-.41-1.48l-.34-.79h22.45a.61.61 0 00.58-.58v-20.6a.61.61 0 00-.58-.58h-5.53V.57A.61.61 0 0029 0H11.45a.61.61 0 00-.57.57V16.7l.12.3-.11.29a.32.32 0 000 .12v19.43l-.83-.42A4.45 4.45 0 008.7 36h-.49V8.43a.75.75 0 00-.41-.57l-6.95-3a.69.69 0 00-.24 0 .62.62 0 00-.55.39.45.45 0 000 .35.57.57 0 00.29.32L7 8.75v27.11l-.47.08a5.93 5.93 0 106.89 6.82l.08-.48h.5c.15 0-.5 0-.44.08H33.7l2.92 3.4A.41.41 0 0037 46a.53.53 0 00.38-.19zM12 1.14h16.44v15.65H12zm0 16.8h22.54V37.4H12zM7.64 46.56a4.77 4.77 0 110-9.54 4.77 4.77 0 010 9.54z" />
      <path d="M14.39 3.54h7.06v.76h-7.06zm0 2.67h8.89v.76h-8.89zm0 13.93h10.6v.76h-10.6zm0 2.67h13.36v.76H14.39z" />
    </Icon>
  );
};

export default GoodsIcon;
