import { LabelsMap } from 'new/form/common/types';

import { contactPersonSectionLabelsMap } from './sections/contactPersonSection/labels';
import { legalRepresentativesSectionLabelsMap } from './sections/legalRepresentativesSection/labels';
import { passwordSectionLabelsMap } from './sections/passwordSection/labels';

export const hausbankPersonalDataLabelsMap: LabelsMap = {
  ...contactPersonSectionLabelsMap,
  ...passwordSectionLabelsMap,
  ...legalRepresentativesSectionLabelsMap,
};
