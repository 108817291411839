import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { transition, zIndex } from 'theme/themeConstants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: ${zIndex.horizontalProgressBar};
  top: 0;
  width: 100%;
  transform: translateY(6rem);
  transition: transform ${transition.default};
  will-change: transform;

  ${mqMedium`
    transform: translateY(${(props) => (props.scrollToTop ? '9rem' : '6rem')});
  `}
`;

export default Wrapper;
