import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  LEASING_ADVANCE_PAYMENT,
  LEASING_AMORTISATION_KIND,
  LEASING_DESCRIPTION,
  LEASING_INSTRESTED_IN_INSURANCE_HELPER,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_RESIDUAL_VALUE,
  LEASING_TAKEOVER,
  LEASING_TERM_IN_MONTHS,
} from 'modules/Inquiry/Form/formFields';

export const validateLeasingSection = validateSectionFields([
  LEASING_ADVANCE_PAYMENT,
  LEASING_TERM_IN_MONTHS,
  LEASING_AMORTISATION_KIND,
  LEASING_TAKEOVER,
  LEASING_DESCRIPTION,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_INSTRESTED_IN_INSURANCE_HELPER,
  LEASING_RESIDUAL_VALUE,
]);
