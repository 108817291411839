import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

export const phoneNumberUtils = PhoneNumberUtil.getInstance();
export const isMobilePhoneNumberValid = (phoneNumber: string = '') => {
  try {
    const parsedPhoneNumber = phoneNumberUtils.parse(phoneNumber);
    return (
      phoneNumberUtils.isValidNumber(parsedPhoneNumber) &&
      phoneNumberUtils.getNumberType(parsedPhoneNumber) === PhoneNumberType.MOBILE
    );
  } catch {
    return false;
  }
};
