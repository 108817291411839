import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

const DEFAULT_SUBJECT_ITEMS_PER_PAGE = 50;

const getFilter = (searchQuery?: string, companyKind?: string, pageNumber = 1) => {
  if (searchQuery && companyKind && pageNumber) {
    return {
      filters: {
        unternehmen_not_i_cont_or_null: companyKind,
        /*
          We can have multiple filters for same key like
          ```
            filters[g][0][filter1]=foo
            filters[g][1][filter1]=bar
          ```
          The "g" part is plugin related on the BE side. The index part is related to each filtering group
          filter is about filtering field. This way you can have multiple filters to same column
        */
        g: searchQuery
          .split(' ')
          .filter(Boolean)
          .map((word) => ({
            hersteller_or_herstellerbezeichnung_or_typ_i_cont: word,
          })),
      },
      page: {
        number: pageNumber,
        size: DEFAULT_SUBJECT_ITEMS_PER_PAGE,
      },
    };
  }
  return {};
};

export const fetchDataListItems = (
  partnerName: string,
  listName: string,
  searchQuery?: string,
  companyKind?: string,
  pageNumber?: number,
) =>
  callReverseApi({
    url: endpoints.PARTNERS.DATA_LIST.compose({
      params: { id: partnerName, listName },
      query: getFilter(searchQuery, companyKind, pageNumber),
    }),
    method: API_METHODS.GET,
  });
