import EditMarketValue from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import {
  CURRENT_USAGE_SQM_PRICE_RESIDENTIAL,
  CURRENT_USAGE_SQM_PRICE_COMMERCIAL,
  CURRENT_USAGE_PRICE_GARAGE_PARKING,
  CURRENT_USAGE_PRICE_OUTSIDE_PARKING,
  CURRENT_USAGE_SQM_PRICE_PROPERTY,
  CURRENT_USAGE_FAIR_MARKET_VALUE,
  CURRENT_USAGE_LOAN_VALUE,
  CURRENT_USAGE_SQM_RENT_RESIDENTIAL,
  CURRENT_USAGE_SQM_RENT_COMMERCIAL,
  CURRENT_USAGE_RENT_GARAGE_PARKING,
  CURRENT_USAGE_RENT_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_RESIDENTIAL,
  FUTURE_USAGE_SQM_PRICE_COMMERCIAL,
  FUTURE_USAGE_PRICE_GARAGE_PARKING,
  FUTURE_USAGE_PRICE_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_PROPERTY,
  FUTURE_USAGE_FAIR_MARKET_VALUE,
  FUTURE_USAGE_LOAN_VALUE,
  FUTURE_USAGE_SQM_RENT_RESIDENTIAL,
  FUTURE_USAGE_SQM_RENT_COMMERCIAL,
  FUTURE_USAGE_RENT_GARAGE_PARKING,
  FUTURE_USAGE_RENT_OUTSIDE_PARKING,
} from 'modules/Inquiry/Form/formFields';

const mapBankValuesObjectDataToEdit = () => {
  const commonAttributes: Record<string, string> = {
    realEstateAppraisal: 'pages.editBankValuesObjectData.sections.realEstateAppraisal.title',
    rentPrices: 'pages.editBankValuesObjectData.sections.rentPrices.title',
  };

  const usageSectionsMap = {
    current: [
      {
        title: commonAttributes['realEstateAppraisal'],
        fields: [
          CURRENT_USAGE_SQM_PRICE_RESIDENTIAL,
          CURRENT_USAGE_SQM_PRICE_COMMERCIAL,
          CURRENT_USAGE_PRICE_GARAGE_PARKING,
          CURRENT_USAGE_PRICE_OUTSIDE_PARKING,
          CURRENT_USAGE_SQM_PRICE_PROPERTY,
          CURRENT_USAGE_FAIR_MARKET_VALUE,
          CURRENT_USAGE_LOAN_VALUE,
        ],
      },
      {
        title: commonAttributes['rentPrices'],
        fields: [
          CURRENT_USAGE_SQM_RENT_RESIDENTIAL,
          CURRENT_USAGE_SQM_RENT_COMMERCIAL,
          CURRENT_USAGE_RENT_GARAGE_PARKING,
          CURRENT_USAGE_RENT_OUTSIDE_PARKING,
        ],
      },
    ],
    future: [
      {
        title: commonAttributes['realEstateAppraisal'],
        fields: [
          FUTURE_USAGE_SQM_PRICE_RESIDENTIAL,
          FUTURE_USAGE_SQM_PRICE_COMMERCIAL,
          FUTURE_USAGE_PRICE_GARAGE_PARKING,
          FUTURE_USAGE_PRICE_OUTSIDE_PARKING,
          FUTURE_USAGE_SQM_PRICE_PROPERTY,
          FUTURE_USAGE_FAIR_MARKET_VALUE,
          FUTURE_USAGE_LOAN_VALUE,
        ],
      },
      {
        title: commonAttributes['rentPrices'],
        fields: [
          FUTURE_USAGE_SQM_RENT_RESIDENTIAL,
          FUTURE_USAGE_SQM_RENT_COMMERCIAL,
          FUTURE_USAGE_RENT_GARAGE_PARKING,
          FUTURE_USAGE_RENT_OUTSIDE_PARKING,
        ],
      },
    ],
  };

  const editValuesForm: Array<EditMarketValue> = [
    {
      heading: 'pages.editBankValuesObjectData.futureUsage.heading',
      subHeading: 'pages.editBankValuesObjectData.futureUsage.subHeading',
      sections: usageSectionsMap['future'],
    },
    {
      heading: 'pages.editBankValuesObjectData.currentUsage.heading',
      subHeading: 'pages.editBankValuesObjectData.currentUsage.subHeading',
      sections: usageSectionsMap['current'],
    },
  ];

  return editValuesForm;
};

export default mapBankValuesObjectDataToEdit;
