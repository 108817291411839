import {
  CURRENT_USAGE_SQM_PRICE_RESIDENTIAL,
  CURRENT_USAGE_SQM_PRICE_COMMERCIAL,
  CURRENT_USAGE_PRICE_GARAGE_PARKING,
  CURRENT_USAGE_PRICE_OUTSIDE_PARKING,
  CURRENT_USAGE_SQM_PRICE_PROPERTY,
  CURRENT_USAGE_FAIR_MARKET_VALUE,
  CURRENT_USAGE_LOAN_VALUE,
  CURRENT_USAGE_SQM_RENT_RESIDENTIAL,
  CURRENT_USAGE_SQM_RENT_COMMERCIAL,
  CURRENT_USAGE_RENT_GARAGE_PARKING,
  CURRENT_USAGE_RENT_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_RESIDENTIAL,
  FUTURE_USAGE_SQM_PRICE_COMMERCIAL,
  FUTURE_USAGE_PRICE_GARAGE_PARKING,
  FUTURE_USAGE_PRICE_OUTSIDE_PARKING,
  FUTURE_USAGE_SQM_PRICE_PROPERTY,
  FUTURE_USAGE_FAIR_MARKET_VALUE,
  FUTURE_USAGE_LOAN_VALUE,
  FUTURE_USAGE_SQM_RENT_RESIDENTIAL,
  FUTURE_USAGE_SQM_RENT_COMMERCIAL,
  FUTURE_USAGE_RENT_GARAGE_PARKING,
  FUTURE_USAGE_RENT_OUTSIDE_PARKING,
  CURRENT_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_OPERATING_COSTS,
  FUTURE_USAGE_REMAINING_USAGE_DURATION,
  FUTURE_USAGE_PRESALES_RATE,
  FUTURE_USAGE_VISITED_BY,
} from 'modules/Inquiry/Form/formFields';

export const editBankValuesLabelsMap: { [key: string]: string } = {
  [CURRENT_USAGE_SQM_PRICE_RESIDENTIAL]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.sqmPriceResidential.caption',
  [CURRENT_USAGE_SQM_PRICE_COMMERCIAL]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.sqmPriceCommercial.caption',
  [CURRENT_USAGE_PRICE_GARAGE_PARKING]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.priceGarageParking.caption',
  [CURRENT_USAGE_PRICE_OUTSIDE_PARKING]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.priceOutsideParking.caption',
  [CURRENT_USAGE_SQM_PRICE_PROPERTY]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.sqmPriceProperty.caption',
  [CURRENT_USAGE_FAIR_MARKET_VALUE]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.fairMarketValue.caption',
  [CURRENT_USAGE_LOAN_VALUE]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.loanValue.caption',
  [CURRENT_USAGE_SQM_RENT_RESIDENTIAL]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.sqmRentResidential.caption',
  [CURRENT_USAGE_SQM_RENT_COMMERCIAL]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.sqmRentCommercial.caption',
  [CURRENT_USAGE_RENT_GARAGE_PARKING]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.rentGarageParking.caption',
  [CURRENT_USAGE_RENT_OUTSIDE_PARKING]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.rentOutsideParking.caption',
  [FUTURE_USAGE_SQM_PRICE_RESIDENTIAL]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.sqmPriceResidential.caption',
  [FUTURE_USAGE_SQM_PRICE_COMMERCIAL]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.sqmPriceCommercial.caption',
  [FUTURE_USAGE_PRICE_GARAGE_PARKING]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.priceGarageParking.caption',
  [FUTURE_USAGE_PRICE_OUTSIDE_PARKING]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.priceOutsideParking.caption',
  [FUTURE_USAGE_SQM_PRICE_PROPERTY]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.sqmPriceProperty.caption',
  [FUTURE_USAGE_FAIR_MARKET_VALUE]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.fairMarketValue.caption',
  [FUTURE_USAGE_LOAN_VALUE]:
    'pages.editBankValuesObjectData.sections.realEstateAppraisal.fields.loanValue.caption',
  [FUTURE_USAGE_SQM_RENT_RESIDENTIAL]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.sqmRentResidential.caption',
  [FUTURE_USAGE_SQM_RENT_COMMERCIAL]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.sqmRentCommercial.caption',
  [FUTURE_USAGE_RENT_GARAGE_PARKING]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.rentGarageParking.caption',
  [FUTURE_USAGE_RENT_OUTSIDE_PARKING]:
    'pages.editBankValuesObjectData.sections.rentPrices.fields.rentOutsideParking.caption',
  [CURRENT_USAGE_OPERATING_COSTS]:
    'pages.editBankValuesAdditionalInformation.sections.fields.currentOperatingCosts.caption',
  [FUTURE_USAGE_OPERATING_COSTS]:
    'pages.editBankValuesAdditionalInformation.sections.fields.futureOperatingCosts.caption',
  [FUTURE_USAGE_REMAINING_USAGE_DURATION]:
    'pages.editBankValuesAdditionalInformation.sections.fields.remainingUsageDuration.caption',
  [FUTURE_USAGE_PRESALES_RATE]:
    'pages.editBankValuesAdditionalInformation.sections.fields.presalesRate.caption',
  [FUTURE_USAGE_VISITED_BY]:
    'pages.editBankValuesAdditionalInformation.sections.fields.visitedBy.caption',
};
