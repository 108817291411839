import * as React from 'react';

import { BoxProps, Box, useMultiStyleConfig, VisuallyHidden } from '@chakra-ui/react';

type SizeType = 'small' | 'medium' | 'large';
type ContextType = { size: SizeType };

const DescriptionListContext = React.createContext<ContextType>({
  size: 'medium',
});

function useDescriptionList() {
  return React.useContext(DescriptionListContext);
}

// Description List
type DescriptionListProps = {
  size?: SizeType;
  children: React.ReactNode;
} & BoxProps;

export const DescriptionList = ({ size = 'medium', children, ...props }: DescriptionListProps) => {
  const { descriptionList } = useMultiStyleConfig('DescriptionList', { size });
  return (
    <DescriptionListContext.Provider value={{ size }}>
      <Box as="dl" sx={descriptionList} {...props}>
        {children}
      </Box>
    </DescriptionListContext.Provider>
  );
};

// Description List Term
type DescriptionListTermProps = {
  isHidden?: boolean;
  children: React.ReactNode;
} & BoxProps;

export const DescriptionListTerm = ({
  isHidden = false,
  children,
  ...props
}: DescriptionListTermProps) => {
  const { size } = useDescriptionList();
  const { term } = useMultiStyleConfig('DescriptionList', { size });

  const component = (
    <Box as="dt" sx={term} {...props}>
      {children}
    </Box>
  );

  if (isHidden) {
    return <VisuallyHidden>{component}</VisuallyHidden>;
  }

  return <>{component}</>;
};

// Description List Description
type DescriptionListDescriptionProps = {
  children: React.ReactNode;
} & BoxProps;

export const DescriptionListDescription = ({
  children,
  ...props
}: DescriptionListDescriptionProps) => {
  const { size } = useDescriptionList();
  const { description } = useMultiStyleConfig('DescriptionList', { size });

  return (
    <Box as="dd" sx={description} {...props}>
      {children}
    </Box>
  );
};
