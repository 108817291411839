import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { textStyle } from '../../../themeConstants';

const helpers = createMultiStyleConfigHelpers(['container', 'title', 'description']);

const Alert = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      alignItems: 'top',
      border: '1px',
      borderRadius: 'default',
      p: 4,
    },
    title: {
      marginRight: 0,
      ...textStyle.sizeM,
    },
    description: {
      display: 'block',
      ...textStyle.sizeM,
    },
  },
});

export default Alert;
