import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';

export const mittweidaInitialFormValues: MittweidaFieldTypes = {
  financingNeedPage: {
    roleSection: {
      projectFinancingRole: undefined,
    },
    projectCompositionSection: {
      totalInvestment: undefined,
      totalInvestmentInBuyingPhase: undefined,
      ownFunds: undefined,
      ownFundsInBuyingPhase: undefined,
      mezzanineCapital: undefined,
      subordinatedCapital: undefined,
      ownWork: undefined,
      financeWholeProject: undefined,
      financingAmount: undefined,
    },
    objectInformationSection: {
      address: undefined,
      city: undefined,
      description: undefined,
      lotSize: undefined,
      plannedProjectEnd: undefined,
      plannedProjectStart: undefined,
      year: undefined,
      yearOfModernization: undefined,
      zipCode: undefined,
      furnishingQuality: undefined,
      qualityCondition: undefined,
      additionalBathroom: undefined,
      balcony: undefined,
      lift: undefined,
      cellar: undefined,
      parkingLotsCount: undefined,
      parkingLotPrice: undefined,
      parkingLotRentNow: undefined,
      parkingLotRentFuture: undefined,
      garagesCount: undefined,
      garagesPrice: undefined,
      garageRentNow: undefined,
      garageRentFuture: undefined,
      futureObjectType: undefined,
      futureUsage: [
        {
          typeOfUse: undefined,
          usageSpace: undefined,
          plannedSalesPrice: undefined,
          numberOfUnits: undefined,
        },
      ],
      usageDeviation: undefined,
      currentObjectType: undefined,
      currentUsage: [
        {
          typeOfUse: undefined,
          usageSpace: undefined,
          numberOfUnits: undefined,
        },
      ],
      currentTotalRentResidential: undefined,
      currentTotalRentCommercial: undefined,
      vacancyResidential: undefined,
      vacancyCommercial: undefined,
      futureTotalRentResidential: undefined,
      futureTotalRentCommercial: undefined,
      rentalContracts: undefined,
      rentalIntends: undefined,
    },
    otherProjectInformation: {
      demolitionNecessary: undefined,
      heritageProtected: undefined,
      developmentNecessary: undefined,
      ownSalesPlanned: undefined,
      energyPass: undefined,
      buildingPermit: undefined,
      permitBasis: undefined,
      permitStatus: undefined,
      notesOnPermit: undefined,
    },
  },
  companyDetailPage: {
    companyDetailsSection: {
      loggedInUserCompanyName: undefined,
      selectedCompanyName: undefined,
    },
    completingCompanyDetailsSection: {
      company: {
        name: undefined,
        address: undefined,
        city: undefined,
        country: undefined,
        state: undefined,
        street: undefined,
        tradeName: undefined,
        zipCode: undefined,
        legalForm: undefined,
        industry: undefined,
        turnoverClass: undefined,
        foundingYear: undefined,
        foundingMonth: undefined,
        alreadyCustomer: undefined,
        alreadyContactWithBank: undefined,
        nameOfContactPerson: undefined,
      },
    },
  },
  userProfilePage: {
    contactPersonSection: {
      gender: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
    },
    passwordSection: {
      password: undefined,
      passwordConfirmation: undefined,
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: undefined,
    },
  },
  fundingDetailsPage: {
    otherFundingSection: {
      description: undefined,
    },
  },
};
