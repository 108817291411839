import styled from 'styled-components/macro';

const Outer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.background.lightGrey};

  :not(:first-child) {
    border-left: 2px solid ${(props) => props.theme.colors.border.white};
  }

  :first-child {
    text-align: right;
  }

  :first-child,
  :last-child {
    flex-grow: 1;
  }
`;

export default Outer;
