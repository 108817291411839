import { showBeneficialOwnersSection } from 'dzb/inquiry/config/conditions';
import { dzbFields, dzbFieldsWithoutParents, dzbFieldsWithSectionString } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {
  validateArrayFields,
  validateField,
  ValidateSectionFunction,
} from 'new/form/useValidateFields';
import { dzbBeneficialOwnerValidations } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/validations';

export const validateBeneficialOwnersSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  if (showBeneficialOwnersSection(values)) {
    const {
      firstName,
      lastName,
      nationality,
      capitalOrVotingRightsPercentage,
      countryOfResidence,
      placeOfResidence,
      birthPlace,
      birthDate,
    } = dzbFieldsWithoutParents.personalDataPage.beneficialOwnersSection.beneficialOwners;

    const normalFieldsValid = validateField({
      field: allInformationAvailable,
      values,
      form,
      conditions,
    });

    const arrayFieldsValid = validateArrayFields({
      arrayValues: values.personalDataPage.beneficialOwnersSection.beneficialOwners || [],
      fieldsWithoutParent: [
        firstName,
        lastName,
        nationality,
        capitalOrVotingRightsPercentage,
        countryOfResidence,
        placeOfResidence,
        birthPlace,
        birthDate,
      ],
      parentName:
        dzbFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
          .sectionString,
      form,
      values,
      conditions,
    });

    return normalFieldsValid && arrayFieldsValid;
  }

  return validateField({
    field: allInformationAvailable,
    values,
    form,
    conditions,
  });
};

const {
  firstName,
  lastName,
  nationality,
  capitalOrVotingRightsPercentage,
  countryOfResidence,
  placeOfResidence,
  birthPlace,
  birthDate,
} = dzbFields.personalDataPage.beneficialOwnersSection.beneficialOwners;

const { allInformationAvailable } = dzbFields.personalDataPage.beneficialOwnersSection;

export const beneficialOwnersSectionValidationMap = {
  allInformationAvailable: fieldValidators.boolean().required(),
  [firstName]: dzbBeneficialOwnerValidations.firstName,
  [lastName]: dzbBeneficialOwnerValidations.lastName,
  [nationality]: dzbBeneficialOwnerValidations.nationality,
  [capitalOrVotingRightsPercentage]: dzbBeneficialOwnerValidations.capitalOrVotingRightsPercentage,
  [countryOfResidence]: dzbBeneficialOwnerValidations.countryOfResidence,
  [placeOfResidence]: dzbBeneficialOwnerValidations.placeOfResidence,
  [birthPlace]: dzbBeneficialOwnerValidations.birthPlace,
  [birthDate]: dzbBeneficialOwnerValidations.birthDate,
};
