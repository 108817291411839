import React, { ReactElement } from 'react';

import { useDownloadFile } from 'components/DownloadBlock/useDownloadFile';
import { FileType, IFile } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { ButtonComponent } from 'theme/components/Button';
import DownloadIcon from 'theme/components/Icon/DownloadIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export interface DownloadFileInRequestProps {
  file: IFile | CompeonDocument;
  type?: FileType;
  label?: string;
  icon?: ReactElement;
}

const DownloadFileInRequest = ({
  file,
  type,
  label = 'download',
  icon = <DownloadIcon boxSize={6} display="block" />,
}: DownloadFileInRequestProps) => {
  const t = useTranslations('components.upload');
  const { downloadFile, isLoading } = useDownloadFile({
    fileId: file.id,
    fileName: file.fileName,
    type,
  });

  return (
    <ButtonComponent
      isLoading={isLoading}
      data-testid="download-file-btn"
      leftIcon={icon}
      onClick={downloadFile}
    >
      {t(label)}
    </ButtonComponent>
  );
};

export default DownloadFileInRequest;
