import { useSelector } from 'react-redux';

import type { IVideoIdentificationDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  getInquiryDetailsSelector,
  getLegalRepresentatives,
  getVideoIdentificationsDetails,
} from 'store/inquiryDetails/selectors';

const getSignedContractDetails = (
  videoIdentificationsDetails: Array<IVideoIdentificationDetails>,
  suffix: string,
) => {
  if (videoIdentificationsDetails.length > 1) {
    return (
      videoIdentificationsDetails.find(
        ({ success, signedContractUrl }) => success && signedContractUrl.includes(suffix),
      ) ?? null
    );
  }
  return videoIdentificationsDetails.find(({ success }) => success) ?? null;
};

export const useLatestSignedContractDetails = () => {
  const videoIdentificationsDetails = useSelector(getVideoIdentificationsDetails) ?? [];
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const legalRepresentatives = useSelector(getLegalRepresentatives) ?? [];

  const suffix = `${inquiryDetails?.inquiryId}-${legalRepresentatives.length - 1}`;
  const contractDetails = getSignedContractDetails(videoIdentificationsDetails, suffix);

  return {
    url: chooseSelectedInquiryTypeSpecificValue({
      [InquiryType.leaseplan]: inquiryDetails?.finalSignedContract,
      [InquiryType.default]: contractDetails?.signedContractUrl,
    }),
  };
};
