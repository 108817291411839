import React from 'react';

import FinancingAmountSummary from 'components/FinancingAmountSummary/FinancingAmountSummary';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import InvesmentLoanInterestedInOtherProducts from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvesmentLoanInterestedInOtherProducts';
import InvestmentLoanAdvancePayment from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvestmentLoanAdvancePayment';
import InvestmentLoanAmortisationKind from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvestmentLoanAmortisationKind';
import InvestmentLoanDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvestmentLoanDescription';
import InvestmentLoanResidualValue from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvestmentLoanResidualValue';
import InvestmentLoanTermInMonths from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/InvestmentLoan/InvestmentLoanTermInMonths';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import InvestionsIcon from 'theme/components/Icon/ProductKind/InvestionsIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const InvestmentLoan = () => {
  const t = useTranslations('pages.requestDetails.sections.investmentLoan');
  return (
    <FormSection name={FormSections.investmentLoan} title={t('title.investment_loan')}>
      <FormRow separator>
        <FinancingAmountSummary icon={<InvestionsIcon />} />
      </FormRow>
      <FormRow separator>
        <InvestmentLoanAdvancePayment />
        <InvestmentLoanTermInMonths />
      </FormRow>
      <FormRow>
        <InvestmentLoanAmortisationKind />
        <InvestmentLoanResidualValue />
      </FormRow>
      <FormRow>
        <InvestmentLoanDescription />
      </FormRow>
      <FormRow>
        <InvesmentLoanInterestedInOtherProducts />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default InvestmentLoan;
