import { orderBy } from 'lodash';

import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatInteger } from 'utils/valueFormats';

import { FinancingDetailsData, FinancingDetailsDataUnion } from './types';

const HIGHLIGHTED_ATTRIBUTES = new Set<keyof FinancingDetailsDataUnion>([
  'financingAmount',
  'financingCosts',
]);
const ATTRIBUTE_ORDER: ReadonlyArray<keyof FinancingDetailsDataUnion> = Object.freeze([
  'financingAmount',
  'duration',
  'financingFee',
  'feeRate',
  'interestRate',
  'sentenceCosts',
  'repaymentRate',
  'financingCosts',
  'interestRateShortTerm',
]);

const getUnitMapping = (
  t: ReturnType<typeof useTranslations>,
): Record<keyof FinancingDetailsDataUnion, string> => {
  const translateUnit = (unit: string) => t(`other.${unit}`);

  return {
    duration: translateUnit('months'),
    feeRate: translateUnit('defaultCurrency'),
    financingAmount: translateUnit('defaultCurrency'),
    financingCosts: translateUnit('defaultCurrency'),
    financingFee: translateUnit('percent'),
    interestRate: translateUnit('percent'),
    interestRateShortTerm: translateUnit('percent'),
    repaymentRate: translateUnit('percent'),
    sentenceCosts: translateUnit('defaultCurrency'),
  };
};

const getFormatter = (t: ReturnType<typeof useTranslations>) => {
  const unitMapping = getUnitMapping(t);

  return (key: keyof FinancingDetailsDataUnion, value: any) => {
    if (key === 'duration') return formatInteger(value, { unit: unitMapping[key] });
    return formatDecimal(value, { unit: unitMapping[key] });
  };
};

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.financingParameters.tableHeaders.${key}`,
    );

  return [
    {
      key: 'label',
      text: translate('parameter'),
    },
    {
      key: 'value',
      text: translate('value'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  financingDetails: FinancingDetailsData,
) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.financingParameters.tableRows.${key}`,
    );
  const format = getFormatter(t);
  const data = Object.entries(financingDetails).map(([key, value]) => ({
    key,
    label: translate(key),
    rowHighlight: HIGHLIGHTED_ATTRIBUTES.has(key as keyof FinancingDetailsDataUnion),
    value: format(key as keyof FinancingDetailsDataUnion, value),
  }));

  return orderBy(data, ({ key }) =>
    ATTRIBUTE_ORDER.indexOf(key as keyof FinancingDetailsDataUnion),
  );
};
