import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isLoggedInUser } from 'utils/user/conditionals';

interface PrivateRouteProps extends RouteProps {
  isAuthorized: boolean;
  authPath: string;
}

const PrivateRoute = ({ isAuthorized, authPath, ...routeProps }: PrivateRouteProps) => {
  const isLoggedIn = isLoggedInUser();

  const isAllowed = isLoggedIn && isAuthorized;

  return (
    <Route
      {...routeProps}
      render={
        !isAllowed
          ? () => (
              <Redirect
                to={{ pathname: authPath, state: { redirectTo: routeProps.location?.pathname } }}
              />
            )
          : routeProps.render
      }
      component={!isAllowed ? undefined : routeProps.component}
    />
  );
};

export default PrivateRoute;
