import React from 'react';

import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import { RadioGroupWithField } from 'components/RadioGroup';
import { InputCaption } from 'components/Text';
import {
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
  QUICK_CHECK_SELECTING_PROFITABILITY_TYPE,
} from 'modules/Inquiry/Form/formFields';
import { validateProjectCalculationSection } from 'modules/Inquiry/Form/Validations/default/RequestDetails/projectFinancingSection.validator';
import { Text } from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/styles';
import { hasOnlyFutureUsage } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FILTERS_USAGE_TYPES } from '../SharedComponent/constants';

const ProjectCalculationSection = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck.sections.projectCalculation');
  const tRole = useTranslations(
    'pages.planningEvaluation.quickCheck.sections.role.fields.role.options',
  );
  const tError = useTranslations('errors');
  const disableCurrentUsage = useSelector(hasOnlyFutureUsage);
  const form = useForm();

  const validateRoleSelect = () =>
    validateProjectCalculationSection(form, tError('selectOneField'));

  return (
    <>
      <FormRow>
        <RadioGroupWithField
          name={QUICK_CHECK_SELECTING_PROFITABILITY_TYPE}
          caption={t('fields.selectingProfitabilityType.label')}
          options={[
            {
              caption: t('fields.selectingProfitabilityType.options.currentUsageType.label'),
              value: FILTERS_USAGE_TYPES.CURRENT,
              // FIXME
              // @ts-ignore
              isDisabled: disableCurrentUsage,
            },
            {
              caption: t('fields.selectingProfitabilityType.options.futureUsageType.label'),
              value: FILTERS_USAGE_TYPES.FUTURE,
            },
          ]}
          data-testid={QUICK_CHECK_SELECTING_PROFITABILITY_TYPE}
        />
      </FormRow>
      <InputCaption>{t('fields.selectingProfitabilityRole.label')}</InputCaption>
      <FormRow>
        <CheckboxWithField
          name={QUICK_CHECK_ROLES_PROPERTY_DEVELOPER}
          text={<Text>{tRole('propertyDeveloper')}</Text>}
          size="sm"
          data-testid={QUICK_CHECK_ROLES_PROPERTY_DEVELOPER}
          validate={validateRoleSelect}
        />
        <CheckboxWithField
          name={QUICK_CHECK_ROLES_PARTITION}
          text={<Text>{tRole('allocationTable')}</Text>}
          size="sm"
          data-testid={QUICK_CHECK_ROLES_PARTITION}
        />
        <CheckboxWithField
          name={QUICK_CHECK_ROLES_FILE_HALTER}
          text={<Text>{tRole('fileHalter')}</Text>}
          size="sm"
          data-testid={QUICK_CHECK_ROLES_FILE_HALTER}
        />
      </FormRow>
    </>
  );
};

export default ProjectCalculationSection;
