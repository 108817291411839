import React from 'react';

import Condition from 'components/Condition';
import { SelectWithField } from 'components/Selects/Select';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledFormRow } from './styles';
import { useBankAdvisorData } from './useBankAdvisorData';

const BankAdvisorFilter = () => {
  const fields = translations.pages.inquiryList.filtersDrawer.fields;
  const t = useTranslations();
  const { optional } = useFieldValidators();
  const { bankAdvisorOptions } = useBankAdvisorData();

  return (
    <Condition condition={bankAdvisorOptions.length}>
      <StyledFormRow>
        <SelectWithField
          name={INQUIRY_FILTERS.BANK_ADVISOR_FULL_NAME}
          validate={optional}
          caption={t(fields.processor.caption)}
          placeholder={t('placeholders.pleaseChoose')}
          options={bankAdvisorOptions}
          isClearable
          data-testid={'select-field-' + INQUIRY_FILTERS.BANK_ADVISOR_FULL_NAME}
        />
      </StyledFormRow>
    </Condition>
  );
};

export default BankAdvisorFilter;
