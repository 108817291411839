import * as React from 'react';

import { Box, VStack, Flex, Grid, Divider } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { PropertiesToString, translations } from 'new/form/common/types';
import { StepData, StepSequence, SummaryFieldsType } from 'new/form/model';
import {
  useInquiryProcessConfig,
  useInquiryPathForMode,
} from 'new/form/state/inquiryProcessConfig/hooks';
import { useRequirePortalConfig } from 'new/portals/state/portalConfigContext/hooks';
import { FieldGroup, useGroupFieldsByRow } from 'new/summary/hooks/useGroupFieldsByRow';
import Button from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SummaryField } from './SummaryField';

export const InquirySummarySections = () => {
  const {
    formStateData: { stepData, fields, summaryFields, stepSequence },
  } = useInquiryProcessConfig();

  return (
    <SummarySections
      stepData={stepData}
      stepSequence={stepSequence}
      summaryFields={summaryFields}
      fields={fields}
      showEditButton={true}
    />
  );
};

export const PortalInquiryDetails = () => {
  const {
    portalConfig: { stepData, fields, summaryFields, stepSequence },
  } = useRequirePortalConfig();

  return (
    <SummarySections
      stepData={stepData}
      stepSequence={stepSequence}
      summaryFields={summaryFields}
      fields={fields}
      showEditButton={false}
    />
  );
};

export type SummaryProps = {
  stepData: StepData<any>;
  stepSequence: StepSequence<any>;
  summaryFields: SummaryFieldsType<any> | undefined;
  fields: PropertiesToString<any>;
};

const SummarySections = ({
  stepData,
  stepSequence,
  summaryFields,
  fields,
  showEditButton,
}: SummaryProps & { showEditButton: boolean }) => {
  const t = useTranslations();
  const groupedFields = useGroupFieldsByRow({ summaryFields, stepSequence, fields });
  const allPages = Object.entries(groupedFields);

  return (
    <VStack spacing={8} align="stretch">
      {Object.entries(groupedFields).map(([pageName, sections], pageIndex) => (
        <Box key={pageName}>
          {Object.entries(sections).map(
            ([sectionName, sectionFieldGroup], sectionIndex, sectionArr) => {
              const sectionTitles = stepData[pageName].sectionTitles;
              const sectionTitle = sectionTitles ? t(sectionTitles[sectionName]) : '';

              return (
                <Box key={sectionName} py={4}>
                  <HeadingComponent variant={'tertiary'} color={'brand.default'} mb={6}>
                    {sectionTitle}
                  </HeadingComponent>
                  {sectionFieldGroup.map((fieldGroup, index, arr) => {
                    return (
                      <React.Fragment key={index}>
                        <FieldRow fields={fieldGroup} />
                        {index < arr.length - 1 && <Divider color={'gray.400'} my={6} />}
                      </React.Fragment>
                    );
                  })}
                  {showEditButton ? <EditButton pageName={pageName} /> : null}
                  {!(
                    pageIndex === allPages.length - 1 && sectionIndex === sectionArr.length - 1
                  ) && <Divider mt={8} color={'brand.default'} />}
                </Box>
              );
            },
          )}
        </Box>
      ))}
    </VStack>
  );
};

type EditButtonProps = {
  pageName: string;
};

const EditButton = ({ pageName }: EditButtonProps) => {
  const t = useTranslations();
  const { edit } = translations.pages.summaryPage.buttons;
  const history = useHistory();
  const editLink = useInquiryPathForMode(pageName);

  return (
    <Flex justifyContent="flex-end">
      <Button
        variant={'tertiary'}
        leftIcon={<EditIcon boxSize={6} />}
        onClick={() => history.push(editLink)}
      >
        {t(edit)}
      </Button>
    </Flex>
  );
};

type FieldRowProps = {
  fields: FieldGroup;
};

const FieldRow: React.FC<FieldRowProps> = ({ fields }) => {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6} gridRowGap={6}>
      {fields.map((field, index) => (
        <SummaryField summaryPageField={field} key={index} />
      ))}
    </Grid>
  );
};
