import React from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import { CheckboxWithField } from 'components/Checkbox';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import PERMISSIONS from 'constants/user/permissions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  chooseSelectedInquiryTypeSpecificComponent,
  chooseSelectedInquiryTypeSpecificValue,
} from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { createFileRequestAction } from 'store/documentExchange/documentExchange.actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import DzbFormContent from './DzbFormContent';
import { Text } from './styles';

const DefaultFormContent = () => {
  const { required, optional } = useFieldValidators();
  const t = useTranslations('pages.inquiryDetails.documentExchange.requestDocuments');

  return (
    <>
      <Box mb="2rem">
        <InputWithField validate={required} name="title" caption={t('name.caption')} />
      </Box>

      <Box mb="2rem">
        <TextAreaWithField
          validate={optional}
          name="description"
          caption={t('description.caption')}
        />
      </Box>
    </>
  );
};

const AddNewRequest: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inquiryId = useSelector(getInquiryIdSelector);

  const { selectedLanguage } = useLanguages();

  const { makeCall, isPending } = useDispatchApiCall();
  const t = useTranslations('pages.inquiryDetails.documentExchange.requestDocuments');
  const createNewRequest = async (values: {
    title: string;
    description: string;
    customerNotification: boolean;
    documentType: string | undefined;
  }) => {
    await makeCall(
      createFileRequestAction(
        inquiryId,
        values.title,
        values.description,
        !values.customerNotification,
        selectedLanguage,
        values.documentType,
      ),
    );
    onClose();
  };

  const FormMainContent = chooseSelectedInquiryTypeSpecificComponent({
    [InquiryType.dzb]: DzbFormContent,
    [InquiryType.default]: DefaultFormContent,
  });

  const leadText = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.dzb]: t('leadDzb'),
    [InquiryType.default]: t('lead'),
  });

  return (
    <>
      <ButtonComponent data-testid="add-new-request" onClick={onOpen}>
        {t('title')}
      </ButtonComponent>
      <ModalComponent
        testId="add-new-request-modal"
        isOpen={isOpen}
        onClose={onClose}
        title={t('title')}
        footer={
          <>
            <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
              {t('buttonCancel')}
            </ButtonComponent>
            <ButtonComponent form="form-add-new-request" type="submit" isLoading={isPending}>
              {t('buttonConfirm')}
            </ButtonComponent>
          </>
        }
      >
        <TextComponent mb={8}>{leadText}</TextComponent>
        <Form
          onSubmit={createNewRequest}
          initialValues={{
            customerNotification: true,
          }}
          render={({ handleSubmit }) => (
            <form id="form-add-new-request" onSubmit={handleSubmit}>
              <FormMainContent />
              <Box mb="2rem">
                <CheckboxWithField
                  name="customerNotification"
                  text={<Text>{t('customerNotification')}</Text>}
                  small
                />
              </Box>
            </form>
          )}
        />
      </ModalComponent>
    </>
  );
};

export default withRolesGuard(
  PERMISSIONS.INQUIRY.DETAILS.DOCUMENT_EXCHANGE.CREATE_DOCUMENT_REQUEST,
)(AddNewRequest);
