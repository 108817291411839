import React from 'react';

import { COMPANY_DETAILS_COMPANY } from 'modules/Inquiry/Form/formFields';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { useTranslations } from 'utils/hooks/useTranslations';

const OnlineFactoringUserProfileSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.USER,
    excludedFields: [COMPANY_DETAILS_COMPANY],
  });

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('userDetails')}>
      <InformationRows fields={fields} />
    </Section>
  );
};

export default OnlineFactoringUserProfileSection;
