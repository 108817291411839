import styled from 'styled-components/macro';

import { transition } from 'theme/themeConstants';

const percentageFromFraction = (fraction) => `${(fraction * 100).toFixed(0)}%`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ ratio }) => percentageFromFraction(ratio)};
  background-color: ${(props) => props.theme.colors.primary};
  transition: width ${transition.default};
`;

export default Fill;
