import React from 'react';

import { HStack, Icon, Stack, Text } from '@chakra-ui/react';

import { ReactComponent as DocumentIcon } from 'assets/specific-icons/file.svg';
import { translations } from 'new/form/common/types';
import { BadgeComponent, BadgeStatus } from 'theme/components/Badge';
import { useTranslations } from 'utils/hooks/useTranslations';

import { DownloadSignedContractButton } from './DownloadSignedContractButton';

const { signedContractFileName } = translations.pages.inquiryDetails.documentExchange;
const { newFile } = translations.components.upload;

export interface SignedContractDetails {
  url?: string;
}

export const SignedContract = ({ url }: SignedContractDetails) => {
  const t = useTranslations();

  if (!url) return null;

  return (
    <Stack
      padding={8}
      marginBottom={8}
      spacing={8}
      boxShadow="md"
      borderRadius="lg"
      direction={{ base: 'column', md: 'row' }}
      data-testid="file-block"
    >
      <HStack spacing={{ base: 4, md: 8 }} flexGrow={1}>
        <Icon as={DocumentIcon} boxSize={16} />
        <Text as="h5" fontSize="xl">
          {t(signedContractFileName)}
        </Text>
        <BadgeComponent status={BadgeStatus.INFO} fontSize="sm">
          {t(newFile)}
        </BadgeComponent>
      </HStack>
      <DownloadSignedContractButton actionUrl={url} testId="download-signed-contract-button" />
    </Stack>
  );
};
