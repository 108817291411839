import React from 'react';

import { Box } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';

const factoringCommercialCreditInsuranceOptions = [
  {
    caption: (
      <>
        <Box
          marginBottom={'0.5rem'}
          fontSize={'1.125rem'}
          fontWeight={FONT_WEIGHT_MEDIUM}
          color={'inherit'}
        >
          <FormattedMessage id="pages.requestDetails.sections.factoring.fields.factoringCommercialCreditInsurance.options.yes" />
        </Box>
        <FormattedMessage id="pages.requestDetails.sections.factoring.fields.factoringCommercialCreditInsurance.options.insured" />
      </>
    ),
    value: 'true',
  },
  {
    caption: (
      <>
        <Box
          marginBottom={'0.5rem'}
          fontSize={'1.125rem'}
          fontWeight={FONT_WEIGHT_MEDIUM}
          color={'inherit'}
        >
          <FormattedMessage id="pages.requestDetails.sections.factoring.fields.factoringCommercialCreditInsurance.options.no" />
        </Box>
        <FormattedMessage id="pages.requestDetails.sections.factoring.fields.factoringCommercialCreditInsurance.options.notInsured" />
      </>
    ),
    value: 'false',
  },
];

export default factoringCommercialCreditInsuranceOptions;
