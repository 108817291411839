import React, { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';

import { NewStaticFieldWithField } from 'components/StaticField';
import { dzbFields, DzbFieldTypes } from 'dzb/inquiry/fields';
import { replaceUndefinedWithZero } from 'new/form/common/replaceUndefinedWithNumber';
import { formatDecimal } from 'utils/valueFormats';

const { shareOfDistributionChannels } = dzbFields.retailerDataPage.onlineQuestionnaireSection;

function useShareOfDistributionChannels() {
  const { change } = useForm();
  const {
    values: {
      retailerDataPage: {
        onlineQuestionnaireSection: {
          ownOnlineShopPercentage: formOwnOnlineShopPercentage,
          amazonPercentage: formAmazonPercentage,
          ebayPercentage: formEbayPercentage,
          zalandoPercentage: formZalandoPercentage,
          othersPercentage: formOthersPercentage,
        },
      },
    },
  } = useFormState<DzbFieldTypes>();

  useEffect(() => {
    const {
      ownOnlineShopPercentage,
      amazonPercentage,
      ebayPercentage,
      zalandoPercentage,
      othersPercentage,
    } = replaceUndefinedWithZero({
      ownOnlineShopPercentage: formOwnOnlineShopPercentage,
      amazonPercentage: formAmazonPercentage,
      ebayPercentage: formEbayPercentage,
      zalandoPercentage: formZalandoPercentage,
      othersPercentage: formOthersPercentage,
    });

    const total =
      ownOnlineShopPercentage +
      amazonPercentage +
      ebayPercentage +
      zalandoPercentage +
      othersPercentage;

    change(shareOfDistributionChannels, total);
  }, [
    change,
    formOwnOnlineShopPercentage,
    formAmazonPercentage,
    formEbayPercentage,
    formZalandoPercentage,
    formOthersPercentage,
  ]);
}

export const ShareOfDistributionChannels = () => {
  useShareOfDistributionChannels();

  return (
    <NewStaticFieldWithField
      name={shareOfDistributionChannels}
      parseValue={(value: number) => formatDecimal(value, { digits: 2, unit: '%', locale: 'de' })}
    />
  );
};
