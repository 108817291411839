import React from 'react';

import { Redirect } from 'react-router-dom';

import useInquiryEditGate from 'shared/hooks/inquiry/useInquiryEditGate';

import CustomerInquiryEdit from './CustomerInquiryEdit';

const CustomerInquiryEditGate = () => {
  const { allowed, path } = useInquiryEditGate();

  if (!allowed && path) {
    return <Redirect to={path} />;
  }
  return <CustomerInquiryEdit />;
};

export default CustomerInquiryEditGate;
