import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { hausbankFields } from 'hausbank/inquiry/fields';
import { FinancingAmount } from 'hausbank/inquiry/steps/financingNeed/sections/financingNeedSection/components/FinancingAmount';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const {
  investmentAmount,
  maintenanceReserve,
  wegContribution,
  constructionSubsidies,
  loanTermInYears,
  ownersMeetingDate,
} = hausbankFields.financingNeedPage.financingNeedSection;

export const FinancingNeedSection = () => {
  const t = useTranslations();
  const { title } = translations.inquiryType.hausbank.pages.financingNeed.sections.financingNeed;

  const loanTermInYearsOptions = useInquirySelectOptions(loanTermInYears);

  return (
    <FormSection title={t(title)} sectionNumber={1}>
      <FormRow>
        <AmountWithField name={investmentAmount} />
      </FormRow>
      <FormRow>
        <AmountWithField name={maintenanceReserve} optional={true} />
      </FormRow>
      <FormRow>
        <AmountWithField name={wegContribution} optional={true} />
      </FormRow>
      <FormRow>
        <AmountWithField name={constructionSubsidies} optional={true} />
      </FormRow>
      <FormRow>
        <FinancingAmount />
      </FormRow>
      <FormRow>
        <SelectWithField
          name={loanTermInYears}
          options={loanTermInYearsOptions}
          placeholder={t(translations.placeholders.pleaseChoose)}
          data-testid={'hausbank-loan-term-in-years-field'}
        />
      </FormRow>
      <FormRow>
        <DayPickerWithField name={ownersMeetingDate} />
      </FormRow>
    </FormSection>
  );
};
