import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'new/form/common/types';
import { forwardToCompeonAction } from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/service';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ForwardingBlockedReasons } from './ForwardingBlockedReasons';
import { useInquiryForwarding } from '../../ForwardInquiry/useInquiryForwarding';
import Section from '../Section';
import Button from '../Section/Button';

const { buttonLabel, description, messageAfterAction, title } =
  translations.pages.inquiryDetails.dashboard.actions.forwardToCompeon;

const ForwardToCompeonWithoutConfirmation = () => {
  const t = useTranslations();

  const { error, isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();
  const dispatch = useDispatch();
  const inquiryId = useSelector(getInquiryIdSelector);
  const { isForwardableToCompeon } = useInquiryForwarding();

  const forward = () =>
    makeCall(forwardToCompeonAction(inquiryId), ({ payload }) => {
      dispatch(mapInquiryDetailsApiResponseAction(payload.data));
      success({ description: t(messageAfterAction) });
    });

  const button = (
    <Button
      isLoading={isPending}
      disabled={!isForwardableToCompeon}
      onClick={forward}
      label={t(buttonLabel)}
    />
  );

  const sectionDescription = isForwardableToCompeon ? t(description) : <ForwardingBlockedReasons />;

  return (
    <Section
      title={t(title)}
      description={sectionDescription}
      hasError={Boolean(error)}
      button={button}
    />
  );
};

export default ForwardToCompeonWithoutConfirmation;
