import React from 'react';

import { InputWithField, INPUT_TYPE_DECIMAL } from 'components/Input';
import { INVESTMENT_LOAN_RESIDUAL_VALUE } from 'modules/Inquiry/Form/formFields';

const InvestmentLoanResidualValue = () => {
  return <InputWithField name={INVESTMENT_LOAN_RESIDUAL_VALUE} type={INPUT_TYPE_DECIMAL} />;
};

export default InvestmentLoanResidualValue;
