import styled from 'styled-components/macro';

import { TextAreaWithField } from 'components/TextArea';

export const StyledWrapper = styled.div`
  margin-bottom: 1.875rem;
  padding: 1.875rem;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};
`;

export const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledColumn = styled.div`
  flex: 1 1 0%;

  &:first-child {
    padding-right: 1rem;
  }

  &:last-child {
    padding-left: 1rem;
    color: ${(props) => props.theme.colors.text.tertiary};
  }
`;

export const StyledComment = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  overflow-wrap: break-word;
  word-break: break-all;
`;

export const StyledTextArea = styled(TextAreaWithField)`
  height: 7.5rem;
`;
