export const USER_ROLE_CUSTOMER = 'USER_ROLE_CUSTOMER';
export const USER_ROLE_CALL_CENTER_AGENT = 'USER_ROLE_CALL_CENTER_AGENT';
export const USER_ROLE_BANK_ADVISOR = 'USER_ROLE_BANK_ADVISOR';
export const USER_ROLE_INQUIRY_MANAGER = 'USER_ROLE_INQUIRY_MANAGER';
export const USER_ROLE_REAL_ESTATE_EXPERT = 'USER_ROLE_REAL_ESTATE_EXPERT';
export const USER_ANONYMOUS = 'USER_ANONYMOUS';

export const ALL_USERS = [
  USER_ROLE_CUSTOMER,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
  USER_ANONYMOUS,
] satisfies UserRole[];

export type UserRole =
  | typeof USER_ROLE_CUSTOMER
  | typeof USER_ROLE_BANK_ADVISOR
  | typeof USER_ROLE_INQUIRY_MANAGER
  | typeof USER_ROLE_CALL_CENTER_AGENT
  | typeof USER_ROLE_REAL_ESTATE_EXPERT
  | typeof USER_ANONYMOUS;
