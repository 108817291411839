interface Map {
  [key: string]: number;
}

const map: Map = {
  I: 1,
  II: 2,
  III: 3,
  IV: 4,
  V: 5,
  VI: 6,
};

export const mapToNumber = (riskClass?: string): number => (riskClass ? map[riskClass] : 0);
