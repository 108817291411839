import React from 'react';

import { bool, string } from 'prop-types';

import { dotClass, dots, StyledSpinnerWrapper } from 'components/Spinner/styles';

const Spinner = ({ className, inverted, visible }) => (
  <StyledSpinnerWrapper
    className={className}
    inverted={inverted}
    data-testid="spinner"
    visible={visible}
  >
    {dots.map((index) => {
      const specificClass = dotClass + index;
      const combinedClasses = [specificClass, dotClass].join(' ');

      return <span key={specificClass} className={combinedClasses} />;
    })}
  </StyledSpinnerWrapper>
);

Spinner.propTypes = {
  className: string,
  inverted: bool,
  visible: bool,
};

Spinner.defaultProps = {
  className: null,
  inverted: false,
  visible: true,
};

export default Spinner;
