import {
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__NEW_BUILDING,
} from 'modules/Inquiry/Form/formFields';

export type RealEstateProject =
  | typeof REAL_ESTATE_PROJECT__NEW_BUILDING
  | typeof REAL_ESTATE_PROJECT__PURCHASE
  | typeof REAL_ESTATE_PROJECT__FOLLOW_UP
  | typeof REAL_ESTATE_PROJECT__RECONSTRUCTION
  | typeof REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT;

export const isRealEstateProject = (item: string): item is RealEstateProject => {
  return [
    REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
    REAL_ESTATE_PROJECT__RECONSTRUCTION,
    REAL_ESTATE_PROJECT__FOLLOW_UP,
    REAL_ESTATE_PROJECT__PURCHASE,
    REAL_ESTATE_PROJECT__NEW_BUILDING,
  ].includes(item);
};
