import { isPartitionOrStockholder, isUsageDeviation } from 'mittweida/config/conditions';
import {
  mittweidaFields,
  mittweidaFieldsWithoutParents,
  mittweidaFieldsWithSectionString,
  MittweidaFieldTypes,
} from 'mittweida/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  validateArrayFields,
  validateFields,
  ValidateSectionFunction,
} from 'new/form/useValidateFields';

const {
  address,
  zipCode,
  city,
  description,
  lotSize,
  year,
  yearOfModernization,
  plannedProjectStart,
  plannedProjectEnd,
  furnishingQuality,
  qualityCondition,
  additionalBathroom,
  balcony,
  lift,
  cellar,
  parkingLotsCount,
  parkingLotPrice,
  parkingLotRentNow,
  parkingLotRentFuture,
  garagesCount,
  garagesPrice,
  garageRentNow,
  garageRentFuture,
  futureObjectType,
  usageDeviation,
  currentObjectType,
  currentTotalRentResidential,
  currentTotalRentCommercial,
  vacancyResidential,
  vacancyCommercial,
  futureTotalRentResidential,
  futureTotalRentCommercial,
  rentalContracts,
  rentalIntends,
} = mittweidaFields.financingNeedPage.objectInformationSection;

const {
  futureUsage: {
    typeOfUse: typeOfUseWithoutParent,
    usageSpace: usageSpaceWithoutParent,
    plannedSalesPrice: plannedSalesPriceWithoutParent,
    numberOfUnits: numberOfUnitsWithoutParent,
  },
  currentUsage: {
    typeOfUse: currentTypeOfUseWithoutParent,
    usageSpace: currentUsageSpaceWithoutParent,
    numberOfUnits: currentNumberOfUnitsWithoutParent,
  },
} = mittweidaFieldsWithoutParents.financingNeedPage.objectInformationSection;

const validateGeneralData: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFields({
    fields: [
      address,
      zipCode,
      city,
      description,
      lotSize,
      year,
      yearOfModernization,
      plannedProjectStart,
      plannedProjectEnd,
    ],
    form,
    values,
    conditions,
  });
};

const validateQualityData: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFields({
    fields: [furnishingQuality, qualityCondition, additionalBathroom, balcony, lift, cellar],
    form,
    values,
    conditions,
  });
};

const validateParkingData: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFields({
    fields: [
      parkingLotsCount,
      parkingLotPrice,
      parkingLotRentNow,
      parkingLotRentFuture,
      garagesCount,
      garagesPrice,
      garageRentNow,
      garageRentFuture,
    ],
    form,
    values,
    conditions,
  });
};

const validateFutureObjectUsage: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  const normalFieldsValid = validateFields({
    fields: [futureObjectType],
    form,
    values,
    conditions,
  });
  const arrayFieldsValid = validateArrayFields({
    arrayValues: values.financingNeedPage.objectInformationSection.futureUsage,
    fieldsWithoutParent: [
      typeOfUseWithoutParent,
      usageSpaceWithoutParent,
      plannedSalesPriceWithoutParent,
      numberOfUnitsWithoutParent,
    ],
    parentName:
      mittweidaFieldsWithSectionString.financingNeedPage.objectInformationSection.futureUsage
        .sectionString,
    form,
    values,
    conditions,
  });

  return [normalFieldsValid, arrayFieldsValid].every(Boolean);
};

const validateCurrentObjectUsage: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  if (isUsageDeviation(values)) {
    const normalFieldsValid = validateFields({
      fields: [usageDeviation, currentObjectType],
      form,
      values,
      conditions,
    });
    const arrayFieldsValid = validateArrayFields({
      arrayValues: values.financingNeedPage.objectInformationSection.currentUsage,
      fieldsWithoutParent: [
        currentTypeOfUseWithoutParent,
        currentUsageSpaceWithoutParent,
        currentNumberOfUnitsWithoutParent,
      ],
      parentName:
        mittweidaFieldsWithSectionString.financingNeedPage.objectInformationSection.currentUsage
          .sectionString,
      form,
      values,
      conditions,
    });

    return [normalFieldsValid, arrayFieldsValid].every(Boolean);
  }

  return validateFields({
    fields: [usageDeviation],
    form,
    values,
    conditions,
  });
};

const validateRentalData: ValidateSectionFunction<InquiryType.profiMittweida> = (
  form,
  values,
  store,
  conditions,
) => {
  if (isPartitionOrStockholder(values)) {
    return validateFields({
      fields: [
        currentTotalRentResidential,
        currentTotalRentCommercial,
        vacancyResidential,
        vacancyCommercial,
        futureTotalRentResidential,
        futureTotalRentCommercial,
        rentalContracts,
        rentalIntends,
      ],
      form,
      values,
      conditions,
    });
  }

  return true;
};

export const validateObjectInformationSection: ValidateSectionFunction<
  InquiryType.profiMittweida
> = (form, values, store, conditions) => {
  const isGeneralDataValid = validateGeneralData(form, values, store, conditions);
  const isQualityDataValid = validateQualityData(form, values, store, conditions);
  const isParkingDataValid = validateParkingData(form, values, store, conditions);
  const isFutureObjectUsageValid = validateFutureObjectUsage(form, values, store, conditions);
  const isCurrentObjectUsageValid = validateCurrentObjectUsage(form, values, store, conditions);
  const isRentalDataValid = validateRentalData(form, values, store, conditions);

  const toValidate: Array<boolean | ((value: any, values: MittweidaFieldTypes) => boolean)> = [
    isGeneralDataValid,
    isQualityDataValid,
    isParkingDataValid,
    isFutureObjectUsageValid,
    isCurrentObjectUsageValid,
    isRentalDataValid,
  ];

  return toValidate.every(Boolean);
};
