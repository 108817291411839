import React from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { CUSTOMER_SALUTATION } from 'modules/Inquiry/Form/formFields';
import { getCustomerData } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const SalutationStaticField = () => {
  const customerData = useSelector(getCustomerData);
  const t = useTranslations('pages.customerData.sections.contactPerson.fields.salutation');

  return <StaticField caption={t('caption')} text={t(customerData[CUSTOMER_SALUTATION])} />;
};

export default SalutationStaticField;
