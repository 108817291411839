import { usePartnerConfig } from 'config/partner/hooks';
import { ReportType } from 'models/InquiryDetails/DefaultInquiryDetails.model';

const reformProductsMap: Record<ReportType, boolean> = {
  [ReportType.TRAFFIC_LIGHT]: false,
  [ReportType.SHORT]: false,
  [ReportType.COMPACT]: false,
  [ReportType.RATING]: false,
  [ReportType.BLACK_WHITE]: false,
};

export const useCreditReformProducts = () => {
  const { meta } = usePartnerConfig();
  const creditReformProductsArray = meta.creditReformProducts || [];

  for (let product of creditReformProductsArray) {
    reformProductsMap[product] = true;
  }

  const enabledReports = Object.entries(reformProductsMap)
    .filter((value) => value[1])
    .map((value) => value[0]);
  return { reformProductsMap, enabledReports };
};
