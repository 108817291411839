import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { REAL_ESTATE_FOLLOW_UP_AMOUNT } from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import BFSFollowUpFinancing from './BFSRealEstateFields/BFSFollowUpFinancing';

export const validateFollowUpFinancingSection = (form) =>
  fieldIsValid(REAL_ESTATE_FOLLOW_UP_AMOUNT)({ form });

const FollowUpFinancing = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.realEstateFollowUpFinancing}
      sectionNumber={4}
      title={t('pages.financingNeed.sections.followUpFinancing.title')}
    >
      <FormRow>
        <AmountWithField name={REAL_ESTATE_FOLLOW_UP_AMOUNT} />
      </FormRow>
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfs]: BFSFollowUpFinancing,
  default: FollowUpFinancing,
});
