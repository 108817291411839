import React, { createContext, ReactNode, useContext, useState } from 'react';

type AssociatedPeopleMode = 'view' | 'edit';

type ModeContextType = {
  mode: AssociatedPeopleMode;
  setMode: (mode: AssociatedPeopleMode) => void;
};

const ModeContext = createContext<ModeContextType>({
  mode: 'view', // Default mode
  setMode: (mode) => {},
});

export const useAssociatedPeopleMode = () => {
  const context = useContext(ModeContext);
  if (context === undefined) {
    throw new Error('useMode must be used within a ModeProvider');
  }
  return context;
};

type ModeProviderProps = {
  children: ReactNode;
};

export const AssociatedPeopleModeProvider: React.FC<ModeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<AssociatedPeopleMode>('view');
  return <ModeContext.Provider value={{ mode, setMode }}>{children}</ModeContext.Provider>;
};
