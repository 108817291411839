import React from 'react';

import PropTypes from 'prop-types';

import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';

const Button = ({ onClick, disabled, isLoading, label, ...restProps }) => {
  return (
    <ButtonComponent
      disabled={disabled}
      isLoading={isLoading}
      leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
      onClick={onClick}
      {...restProps}
    >
      {label}
    </ButtonComponent>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
