import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import PortalTable from 'components/PortalTable/PortalTable';
import { DEFAULT_CURRENCY, FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { ComputedMarketDataEvaluation } from 'models/types/MarketData.type';
import { TextComponent } from 'theme/components/Text';
import { textStyle } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatInteger } from 'utils/valueFormats';

const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translateRow = (name: string) =>
    t(`pages.planningEvaluation.marketComparison.marketObjEvaluation.headers.${name}`);

  return [
    {
      key: 'id',
      text: '',
      renderRow: (data: string) => (
        <TextComponent {...textStyle.sizeM} fontWeight={FONT_WEIGHT_BOLD}>
          {data}
        </TextComponent>
      ),
    },
    {
      key: 'unit',
      text: translateRow('unit'),
    },
    {
      key: 'marketPrice',
      text: translateRow('marketPrice'),
      renderRow: (data: number) => (
        <TextComponent color={'brand.default'} {...textStyle.sizeM} fontWeight={FONT_WEIGHT_MEDIUM}>
          {data}
        </TextComponent>
      ),
      headerColor: 'default',
    },
    {
      key: 'marketPriceMin',
      text: translateRow('marketPriceMin'),
    },
    {
      key: 'marketPriceMax',
      text: translateRow('marketPriceMax'),
    },
  ];
};

const getData = (
  evaluation: ComputedMarketDataEvaluation,
  t: ReturnType<typeof useTranslations>,
) => {
  const translateRow = (name: string) =>
    t(`pages.planningEvaluation.marketComparison.marketObjEvaluation.rows.${name}`);
  const translateUnit = (name: string) => t(`other.${name}`);
  const unit = `${DEFAULT_CURRENCY} / ${translateUnit('m2')}`;

  return [
    {
      id: translateRow('market_sales_price'),
      marketPrice: formatDecimal(evaluation.salesPrice, { unit: '€' }),
      marketPriceMax: formatDecimal(evaluation.salesPriceMax, { unit: '€' }),
      marketPriceMin: formatDecimal(evaluation.salesPriceMin, { unit: '€' }),
      unit,
    },
    {
      id: translateRow('residential_rent'),
      marketPrice: formatDecimal(evaluation.residentialRent, { unit: '€' }),
      marketPriceMax: formatDecimal(evaluation.residentialRentMax, { unit: '€' }),
      marketPriceMin: formatDecimal(evaluation.residentialRentMin, { unit: '€' }),
      unit,
    },
    {
      id: translateRow('existing_residential_rent'),
      marketPrice: formatDecimal(evaluation.existingResidentialRent, { unit: '€' }),
      marketPriceMax: formatDecimal(evaluation.existingResidentialRentMax, { unit: '€' }),
      marketPriceMin: formatDecimal(evaluation.existingResidentialRentMax, { unit: '€' }),
      unit,
    },
    {
      id: translateRow('commercial_rent'),
      marketPrice: formatDecimal(evaluation.commercialRent, { unit: '€' }),
      marketPriceMax: '-',
      marketPriceMin: '-',
      unit,
    },
    {
      id: translateRow('sales_duration'),
      marketPrice: formatInteger(evaluation.duration),
      marketPriceMax: formatInteger(evaluation.durationMax),
      marketPriceMin: formatInteger(evaluation.durationMin),
      unit: translateUnit('days'),
    },
  ];
};

interface MarketDataEvaluationTableProps {
  evaluation: ComputedMarketDataEvaluation;
}

const MarketDataEvaluationTable: React.FC<MarketDataEvaluationTableProps> = ({ evaluation }) => {
  const t = useTranslations();
  const headers = useMemo(() => getHeaders(t), [t]);
  const data = useMemo(() => getData(evaluation, t), [evaluation, t]);

  return (
    <Box>
      <PortalTable
        onRowClick={() => {}}
        onSortChange={() => {}}
        sortBy="id"
        isLoading={false}
        isInitialized={true}
        headers={headers}
        tableData={data}
      />
    </Box>
  );
};

export default MarketDataEvaluationTable;
