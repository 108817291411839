import React from 'react';

import Condition from 'components/Condition';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';

import ForwardToCompeonWithConfirmation from './ForwardToCompeonWithConfirmation';
import ForwardToCompeonWithoutConfirmation from './ForwardToCompeonWithoutConfirmation';
import { useInquiryForwarding } from '../../ForwardInquiry/useInquiryForwarding';

const ForwardToCompeonManager = () => {
  const isConfirmationNecessary = useConfig(CONFIG.IS_INQUIRY_CUSTOMER_CONFIRMATION_ENABLED);
  const { forwardToCompeonVisible } = useInquiryForwarding();

  return (
    <Condition condition={forwardToCompeonVisible}>
      {isConfirmationNecessary ? (
        <ForwardToCompeonWithConfirmation />
      ) : (
        <ForwardToCompeonWithoutConfirmation />
      )}
    </Condition>
  );
};

export default ForwardToCompeonManager;
