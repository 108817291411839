import React, { ReactNode } from 'react';

import _get from 'lodash/get';
import { Transition } from 'react-transition-group';

import Overlay from 'components/Overlay';
import LoadingRows from 'components/PortalTable/LoadingRows';
import {
  StyledTableScrollWrapper as ScrollWrapper,
  StyledTable as Table,
  StyledTableFooter as Footer,
  StyledTableWrapper as TableWrapper,
  StyledTableRow as Row,
  StyledTableCell as Cell,
} from 'components/PortalTable/styles';
import { ITableHeader } from 'components/PortalTable/TableHeader.model';
import TableHeader from 'components/PortalTable/TableHeaders/InquiryTableHeaders';
import Spinner from 'components/Spinner';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';

interface IPortalTable {
  headers: ITableHeader[];
  tableData: any[];
  children?: ReactNode;
  onRowClick: Function;
  isLoading?: boolean;
  isInitialized?: boolean;
  onSortChange: (columnKey: string) => void;
  sortBy: string;
  sortDirection?: SORT_DIRECTION;
}

const TABLE_ROW_SHOW_DELAY = 30;

const PortalTable: React.FC<IPortalTable> = ({
  children,
  tableData = [],
  onRowClick,
  headers,
  isLoading = false,
  isInitialized = false,
  onSortChange,
  sortBy,
  sortDirection,
}: IPortalTable) => {
  let tableHeaders = headers.filter((header) => header !== undefined);
  return (
    <TableWrapper>
      <Overlay show={isLoading && isInitialized} dimmed overlayComponent={<Spinner />}>
        <ScrollWrapper>
          <Table>
            <thead>
              <tr>
                {tableHeaders?.map(
                  ({ key, text, renderHeader, notSortable, headerColor = 'primary' }) => (
                    <TableHeader
                      sortBy={sortBy}
                      sortDirection={!text ? undefined : sortDirection}
                      onSortChange={onSortChange}
                      key={text}
                      columnId={key}
                      headerColor={headerColor}
                      notSortable={notSortable}
                    >
                      {renderHeader ? renderHeader(text) : text}
                    </TableHeader>
                  ),
                )}
              </tr>
            </thead>

            {!isInitialized && <LoadingRows numOfColumns={headers.length} />}
            <tbody>
              {tableData &&
                tableData.map((row, index) => (
                  <Transition
                    in
                    timeout={index * TABLE_ROW_SHOW_DELAY}
                    appear
                    mountOnEnter
                    unmountOnExit
                    key={row.id}
                  >
                    {(transitionState) => (
                      <Row
                        className={transitionState}
                        onClick={() => onRowClick(row)}
                        data-testid="table-row"
                        // data-testid={row.id} // set inquiry id as data-testid
                        heading={row.rowHighlight}
                      >
                        {tableHeaders.map(({ renderRow, key }) => {
                          const value = _get(row, key);
                          const uniqueKey = row.id + key;
                          return renderRow ? (
                            <Cell key={uniqueKey} heading={row.rowHighlight}>
                              {renderRow(value)}
                            </Cell>
                          ) : (
                            <Cell
                              key={uniqueKey}
                              heading={row.rowHighlight}
                              valueBold={row.valueBold}
                            >
                              {value}
                            </Cell>
                          );
                        })}
                      </Row>
                    )}
                  </Transition>
                ))}
            </tbody>
          </Table>
        </ScrollWrapper>

        <Footer>{children}</Footer>
      </Overlay>
    </TableWrapper>
  );
};

export default PortalTable;
