import styled from 'styled-components/macro';

import { mqSmall } from 'styles/breakpoints';

const BottomBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 3.5rem;

  ${mqSmall`
    justify-content: space-between;
  `};
`;

export default BottomBar;
