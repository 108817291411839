import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import SelectField, { SelectOptionShape } from 'components/PortalFormFields/SelectField';
import {
  markSelectedValuesAsChecked,
  switchOptionChecked,
} from 'components/PortalFormFields/SelectField/service';
import {
  StyledMultiselectList as MultiselectList,
  StyledMultiselectUnselectBtn as UnselectBtn,
} from 'components/PortalFormFields/SelectField/styles';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const MultiselectField = ({ options, onSelect, optionOnSelect, value, ...restProps }) => {
  const [multiOptions, setMultiOptions] = useState(options);

  useEffect(() => {
    setMultiOptions((op) => markSelectedValuesAsChecked(op, value));
  }, [value]);

  const toggleOption = (option) => {
    const switchedOptions = switchOptionChecked(option, multiOptions);
    setMultiOptions(switchedOptions);
    onSelect(
      switchedOptions
        .filter((o) => o.checked)
        .map((o) => optionOnSelect(o))
        .join(','),
    );
  };

  const unselectedOptions = multiOptions.filter((option) => !option.checked);
  const selectedOptions = multiOptions.filter((option) => option.checked);

  const handleUnselectClick = (option) => () => toggleOption(option);

  const t = useTranslations();
  return (
    <div>
      <SelectField
        {...restProps}
        options={unselectedOptions}
        optionOnSelect={(o) => o}
        shouldCloseOnSelect={false}
        onSelect={toggleOption}
      />

      <MultiselectList>
        {selectedOptions.map((option) => (
          <li key={option.value}>
            <UnselectBtn
              onClick={handleUnselectClick(option)}
              type="button"
              aria-label={t('buttons.delete')}
            >
              <CloseIcon boxSize={6} />
            </UnselectBtn>
            {option.label}
          </li>
        ))}
      </MultiselectList>
    </div>
  );
};

MultiselectField.defaultProps = {
  onSelect: () => null,
  hasError: false,
  placeholder: '',
  optionOnSelect: (o) => o,
  value: [],
};

MultiselectField.propTypes = {
  options: PropTypes.arrayOf(SelectOptionShape).isRequired,
  onSelect: PropTypes.func,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  optionOnSelect: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default MultiselectField;
