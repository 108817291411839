import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledPurposeKindList,
  StyledPurposeKindListItem,
  StyledIcon,
  StyledTitle,
  StyledPurposeKind,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/PurposeKindList/styles';

const PurposeKindList = ({ purposes, selectedPurpose, onSelectedPurpose, name }) => {
  const handleSelectedPurpose = (key) => () => onSelectedPurpose(key);

  return (
    <StyledPurposeKindList>
      {purposes.map(({ icon, title, key }) => (
        <StyledPurposeKindListItem key={key}>
          <StyledPurposeKind
            name={name}
            data-testid={key}
            onClick={handleSelectedPurpose(key)}
            className={selectedPurpose === key && 'active'}
            type="button"
          >
            <StyledIcon>{icon}</StyledIcon>
            <StyledTitle>{title}</StyledTitle>
          </StyledPurposeKind>
        </StyledPurposeKindListItem>
      ))}
    </StyledPurposeKindList>
  );
};

PurposeKindList.defaultProps = {
  selectedPurpose: '',
};

PurposeKindList.propTypes = {
  purposes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onSelectedPurpose: PropTypes.func.isRequired,
  selectedPurpose: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default PurposeKindList;
