interface TrackableUser {
  id?: string;
}

export const trackUserId = (user: TrackableUser) => {
  window._paq = window._paq || [];
  window._paq.push(['setUserId', user.id]);
};

export const trackUserLogout = () => {
  // https://developer.matomo.org/guides/tracking-javascript-guide#user-id
  window._paq = window._paq || [];
  window._paq.push(['resetUserId']);
  window._paq.push(['appendToTrackingUrl', 'new_visit=1']);
  window._paq.push(['trackPageView']);
  window._paq.push(['appendToTrackingUrl', '']);
};
