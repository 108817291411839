import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';

interface Single {
  type: 'single';
  inquiryId: string;
  fileId: string;
}

interface Multiple {
  type: 'multiple';
  inquiryId: string;
  fileIds: string[];
}

type Props = Single | Multiple;

const submitBankOfferAction = (props: Props) =>
  callReverseApi({
    url: endpoints.INQUIRIES.BANK_OFFERS.compose({ params: { id: props.inquiryId } }),
    method: API_METHODS.POST,
    data:
      props.type === 'single'
        ? { uploaded_file_id: props.fileId }
        : { uploaded_file_ids: props.fileIds },
  });

export default submitBankOfferAction;
