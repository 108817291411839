import {
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNER,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  SELF_BENEFICIARY_OWNER,
} from 'modules/Inquiry/Form/formFields';
import { formatDateAndNull } from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';

export const mapBeneficiaryOwners = (data: any) => {
  const beneficiaryOwners = Object.values(data.beneficiary_owners || {});

  // If at least one beneficiary owner is fictitious, then all are fictitious
  const fictitiousBeneficialOwner = beneficiaryOwners.find(
    (owner: any) => owner?.wb_fictitious === true,
  );

  return {
    [SELF_BENEFICIARY_OWNER]: [],
    [BENEFICIARY_OWNER]: beneficiaryOwners.map((beneficiaryOwner) =>
      mapBeneficiaryOwner(beneficiaryOwner),
    ),
    customerDataPage: {
      beneficialOwnerSection: {
        isFictitiousBeneficialOwner: !!fictitiousBeneficialOwner,
      },
    },
  };
};

const mapBeneficiaryOwner = ({ id, attributes }: any) => ({
  isAssociatedPerson: true,
  id,
  [BENEFICIARY_GENDER]: attributes.salutation,
  [BENEFICIARY_FIRST_NAME]: attributes['first_name'],
  [BENEFICIARY_LAST_NAME]: attributes['last_name'],
  [BENEFICIARY_COMPANY_SHARE]: attributes['company_share'],
  [BENEFICIARY_TAX_ID]: attributes['tax_id'],
  [BENEFICIARY_PRIVATE_ADDRESS]: attributes['address'],
  [BENEFICIARY_ZIP_CODE]: attributes['zip_code'],
  [BENEFICIARY_PRIVATE_CITY]: attributes['city'],
  [BENEFICIARY_PRIVATE_COUNTRY]: attributes['country'],
  [BENEFICIARY_BIRTH_COUNTRY]: attributes['birth_place'],
  [BENEFICIARY_BIRTHDAY]: formatDateAndNull(attributes['birth_date']),
});
