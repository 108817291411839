import { hausbankFields } from 'hausbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { name, street, city, zipCode, legalForm, managedUnits, foundingYear, isExistingCustomer } =
  hausbankFields.companyDataPage.wegDataCompletionSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.hausbank.pages.companyData.sections.wegDataCompletionSection;

export const wegDataCompletionSectionLabelsMap: LabelsMap = {
  [name]: fieldTranslations.name.caption,
  [street]: fieldTranslations.street.caption,
  [city]: fieldTranslations.city.caption,
  [zipCode]: fieldTranslations.zipCode.caption,
  address: fieldTranslations.address.caption,
  [legalForm]: fieldTranslations.legalForm.caption,
  [managedUnits]: fieldTranslations.managedUnits.caption,
  [foundingYear]: fieldTranslations.foundingYear.caption,
  [isExistingCustomer]: fieldTranslations.isExistingCustomer.caption,
};
