import React from 'react';

import FormRow from 'components/FormRow';
import { TextAreaWithField } from 'components/TextArea';
import {
  QUICK_CHECK_JUSTIFICATION_TEMPLATE,
  QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION,
} from 'modules/Inquiry/Form/formFields';
import { useTranslations } from 'utils/hooks/useTranslations';

const OtherInformationSection = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck.sections.otherInformation');

  const fields = [
    {
      name: QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION,
      placeholder: t('fields.otherNoticesAndInformation.placeholder'),
    },
    {
      name: QUICK_CHECK_JUSTIFICATION_TEMPLATE,
      placeholder: t('fields.justificationTemplate.placeholder'),
    },
  ];

  return (
    <>
      {fields.map(({ name, placeholder }) => (
        <FormRow key={name}>
          <TextAreaWithField name={name} placeholder={placeholder} data-testid={name} />
        </FormRow>
      ))}
    </>
  );
};

export default OtherInformationSection;
