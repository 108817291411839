import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqSmall } from 'styles/breakpoints';
import { boxShadow, transition, zIndex } from 'theme/themeConstants';

const sideBarWidth = '24rem';
const smallSideBarWidth = '18rem';

export const Container = styled.div`
  position: relative;
  z-index: ${zIndex.sideBar.drawer};
  transform: translateX(${({ isOpen }) => (isOpen ? `-${smallSideBarWidth}` : '0')});
  transition: transform ${transition.default};

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  ${mqSmall`
    transform: translateX(${({ isOpen }) => (isOpen ? `-${sideBarWidth}` : '0')});
  `}
`;

export const BodyWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  width: ${smallSideBarWidth};
  transform: translateY(-50%);
  box-shadow: ${boxShadow.default};
  background-color: ${(props) => props.theme.colors.background.white};

  ${mqSmall`
    width: ${sideBarWidth};
  `}
`;

export const SectionWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.border.lightGrey};
  padding: 2rem 4rem 2rem 2rem;
`;

export const Heading = styled.h2`
  margin-bottom: 2rem;
  font-size: 1.33rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  padding: 0.25rem;
  transition: transform 0.2s ease;

  &:focus,
  &:hover {
    transform: rotate(90deg);
  }

  img {
    display: block;
  }
`;
