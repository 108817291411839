import React, { useCallback, useMemo } from 'react';

import { SelectWithField } from 'components/Selects/Select';
import {
  USER_PROFILE_FOUNDING_MONTH,
  USER_PROFILE_FOUNDING_YEAR,
  REQUEST_DETAILS,
  PRODUCT_KIND,
} from 'modules/Inquiry/Form/formFields';
import { useGetFieldNumericValue } from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BuildingSection/fields/useGetFieldNumericValue';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { getMonthsOptions } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/FoundingMonth/service';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useFieldValue } from 'store/hooks/useFormValues';
import { useTranslations } from 'utils/hooks/useTranslations';
import { loadFormStepStateFromStorage } from 'utils/sessionStorage/loadFormState';
import { combineValidators } from 'utils/validators';

const currentYear = +new Date().getUTCFullYear();
const currentMonth = new Date().getUTCMonth() + 1;

const useFoundingMonthOptions = () => {
  const t = useTranslations();

  const selectedYear = useGetFieldNumericValue(USER_PROFILE_FOUNDING_YEAR);

  const filterFutureMonths = useCallback(
    ({ value }) => !(currentYear === selectedYear && value > currentMonth),
    [selectedYear],
  );

  return useMemo(() => getMonthsOptions(t).filter(filterFutureMonths), [filterFutureMonths, t]);
};

const FoundingMonthField = () => {
  const options = useFoundingMonthOptions();
  const { under4Months } = useFieldValidators();
  const foundingYear = useFieldValue(USER_PROFILE_FOUNDING_YEAR, null);
  const product = loadFormStepStateFromStorage(REQUEST_DETAILS)[PRODUCT_KIND];

  const validate = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: false,
    default: combineValidators(under4Months({ foundingYear, product })),
  });

  return (
    <SelectWithField
      name={USER_PROFILE_FOUNDING_MONTH}
      options={options}
      validate={validate}
      data-testid={'select-field-' + USER_PROFILE_FOUNDING_MONTH}
    />
  );
};

export default FoundingMonthField;
