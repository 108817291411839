import styled from 'styled-components/macro';

import { inputStyles } from 'styles/form';

const StyledTextArea = styled.textarea`
  ${inputStyles()};
  display: block;
  height: 10rem;
  outline: 0;
  resize: none;
`;

export default StyledTextArea;
