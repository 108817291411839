import React from 'react';

import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import FormRow from 'components/FormRow';
import Separator from 'components/FormRow/Separator';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import Unit from 'components/Unit/Unit';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  PROJECT_FINANCING_TYPE_OF_USAGE__OTHER,
  PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_RENT_FUTURE,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING_ADDITIONAL_DETAILS,
  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
} from 'modules/Inquiry/Form/formFields';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import ObjectTypeOfUsage from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/ObjectTypeOfUsage';
import {
  StyledUsageActions as Actions,
  StyledTypesLead as Lead,
  StyledTypeTitle as Title,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/styles';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { useCanShowField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

type PropType = {
  formSectionName: string;
  usageFieldLabel: string;
  isFutureUsage?: boolean;
};

// final form array has a format of $group[$index].$subjectFieldName
// this is helper to clear code
const getFieldName = (subfieldName: string, index: number, objectUsageType: string) =>
  `${objectUsageType}[${index}].${subfieldName}`;

const hasOtherUsageType = (typeOfUsageField: string, index: number, objectUsageType: string) =>
  fieldHasValue(
    getFieldName(typeOfUsageField, index, objectUsageType),
    PROJECT_FINANCING_TYPE_OF_USAGE__OTHER,
  );

const ObjectUsageFields = (props: PropType) => {
  const { formSectionName, usageFieldLabel, isFutureUsage } = props;
  const t = useTranslations('');
  const { mutators } = useForm();
  const showFields = useCanShowField(PROJECT_FINANCING_ADDITIONAL_DETAILS);

  const handleRemoveTypeOfUse = (fields: any, index: number, formSectionName: string) => {
    fields.remove(index);
  };

  return (
    <div data-name={formSectionName}>
      <Lead>{t('pages.financingNeed.sections.objectInformation.usageInfo')}</Lead>
      <FieldArray name={formSectionName}>
        {({ fields }) =>
          fields.map((name, index) => {
            return (
              <div key={name}>
                <Title>
                  #{index + 1} {t('pages.financingNeed.sections.objectInformation.heading')}
                </Title>

                <FormRow>
                  <ObjectTypeOfUsage baseName={name} labelName={usageFieldLabel} />
                </FormRow>

                <Condition condition={hasOtherUsageType(usageFieldLabel, index, formSectionName)}>
                  <FormRow>
                    <TextAreaWithField
                      name={`${name}.${PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS}`}
                    />
                  </FormRow>
                </Condition>

                <FormRow>
                  <InputWithField
                    name={`${name}.${PROJECT_FINANCING_USAGE_SPACE}`}
                    type="number"
                    sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
                  />

                  <InputWithField
                    name={`${name}.${PROJECT_FINANCING_PERCENTAGE_OF_RENT}`}
                    type="number"
                    sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.PERCENTAGE} />}
                  />
                </FormRow>

                <FormRow>
                  <AmountWithField
                    name={`${name}.${PROJECT_FINANCING_RENT_NOW}`}
                    tooltip={t(
                      'pages.financingNeed.sections.objectInformation.fields.rentNow.tooltip',
                    )}
                    separateThousands
                    optional
                  />
                  <AmountWithField
                    name={`${name}.${PROJECT_FINANCING_RENT_FUTURE}`}
                    tooltip={t(
                      'pages.financingNeed.sections.objectInformation.fields.rentFuture.tooltip',
                    )}
                    separateThousands
                    optional
                  />
                </FormRow>
                <Condition condition={() => showFields}>
                  <FormRow>
                    {isFutureUsage && (
                      <AmountWithField
                        name={`${name}.${PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT}`}
                        tooltip={t(
                          'pages.financingNeed.sections.objectInformation.fields.futureUsageType.plannedSalesPrice.tooltip',
                        )}
                      />
                    )}
                    <InputWithField
                      name={`${name}.${PROJECT_FINANCING__NUMBER_OF_UNITS}`}
                      type="number"
                    />
                  </FormRow>
                </Condition>
                <Actions>
                  <ButtonComponent
                    leftIcon={<DeleteIcon boxSize={6} display="block" />}
                    onClick={() => handleRemoveTypeOfUse(fields, index, formSectionName)}
                    variant="secondary"
                  >
                    {t('pages.financingNeed.sections.objectInformation.removeTypeOfUse')}
                  </ButtonComponent>
                </Actions>
                <Separator />
              </div>
            );
          })
        }
      </FieldArray>

      <ButtonComponent
        leftIcon={<AddIcon boxSize={6} display="block" />}
        onClick={() => mutators.push(formSectionName)}
        variant="secondary"
        w="100%"
      >
        {t('pages.financingNeed.sections.objectInformation.addTypeOfUse')}
      </ButtonComponent>
      <Separator />
    </div>
  );
};

ObjectUsageFields.defaultProps = {
  isFutureUsage: false,
};

export default ObjectUsageFields;
