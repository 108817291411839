import _get from 'lodash/get';

import {
  PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
  PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
  PROFITABILITY_SALES_SALES_PRICES_CHECKS,
} from 'modules/Inquiry/Form/formFields';

const mapProfitabilitySalesToApi = (data: Record<string, unknown>) => {
  const profitabilitySales = {
    profitability_sales: {
      buying_costs_checked: _get(data, PROFITABILITY_SALES_BUYING_COSTS_CHECKED),
      construction_costs_checked: _get(data, PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED),
      sales_prices_checks: _get(data, PROFITABILITY_SALES_SALES_PRICES_CHECKS),
    },
  };

  return profitabilitySales;
};

export default mapProfitabilitySalesToApi;
