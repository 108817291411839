import normalize from 'json-api-normalizer';

import { mapBeneficiaryOwners } from './mapBeneficiaryOwners';
import { mapLegalRepresentatives } from './mapLegalRepresentatives';

export const mapMMVInquiryFromApi = (data: any) => {
  const normalized = normalize(data, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  });

  return {
    ...mapBeneficiaryOwners(normalized),
    ...mapLegalRepresentatives(normalized),
  };
};
