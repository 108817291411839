import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { getInquiryLane } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  id?: string;
  actionUrl: string;
}
export const InputConditionsAction = ({ id, actionUrl }: Props) => {
  const t = useTranslations();
  const history = useHistory();
  const lane = useSelector(getInquiryLane);

  return (
    <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
      <GridItem>
        <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
          {t(`pages.inquiryDetails.dashboard.actions.nonBindingOffer.title.${lane}`)}
        </HeadingComponent>
        <TextComponent mb={3}>
          {t('pages.inquiryDetails.dashboard.actions.nonBindingOffer.description.portal')}
        </TextComponent>
        {actionUrl && (
          <ButtonComponent
            leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
            onClick={() => history.push(actionUrl)}
            variant="primary"
            data-testid="offer-page-button"
          >
            {t(`pages.inquiryDetails.dashboard.actions.nonBindingOffer.action.${lane}`)}
          </ButtonComponent>
        )}
      </GridItem>

      <GridItem>
        <Center height="100%">
          <Asset type="dashboard" value={ProgressSectionType.NON_BINDING_OFFER} htmlHeight="200" />
        </Center>
      </GridItem>
    </Grid>
  );
};
