import * as React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import ViewIcon from 'theme/components/Icon/ViewIcon';
import ViewOffIcon from 'theme/components/Icon/ViewOffIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface VisibilityButtonProps extends BoxProps {
  isVisible: boolean;
}

const VisibilityButton = ({ isVisible, ...props }: VisibilityButtonProps) => {
  const t = useTranslations();

  return (
    <Box
      type="button"
      p={2}
      _hover={{ color: 'brand.default', cursor: 'pointer' }}
      _focus={{ color: 'brand.default', cursor: 'pointer' }}
      aria-label={t('buttons.showPassword')}
      {...props}
    >
      {isVisible ? <ViewIcon /> : <ViewOffIcon />}
    </Box>
  );
};

export default VisibilityButton;
