import { createContext } from 'react';

export interface IParentFieldContext {
  parentFieldName: string;
  parentIndex: number;
}

export const defaultParentFieldContext = {
  parentFieldName: '',
  parentIndex: 0,
};

export const ParentFieldContext = createContext<IParentFieldContext>(defaultParentFieldContext);
