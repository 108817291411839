import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const MezzanineIcon = (props: IconProps) => {
  return (
    <Icon
      width="53"
      height="53"
      viewBox="0 0 53 53"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <g stroke="#e4e4e4">
        <path d="M43.5 17c-.5.2-1.1.4-1.7.4-2 0-3.7-1.5-3.7-3.4s1.7-3.4 3.7-3.4c.7 0 1.3.2 1.8.5m-6.3 2.4h3.9M37.3 15h3.9M11.1 14.9c.3-.5 1.1-1.4 1.3-2.1.8-2.4-3.4-.2-5.9-.7-.2 0 1.1 1.5 1.7 2.9m0 .2h3c1 0 2.5 1.2 3.6 2.6 1.7 2 3.7 7 .5 8.6-1.1.6-2.9.4-5.5.4-2.7 0-4.5.1-5.6-.5-3-1.7-1.2-6.6.5-8.7 1.2-1.3 2.5-2.4 3.5-2.4z" />
        <path d="M43.1 5.3c.4-.8 1.6-2.1 2-3.2 1.1-3.5-5.1-.3-8.6-1-.3-.1 1.6 2.2 2.5 4.2m-.2.4h4.4c1.5 0 3.6 1.7 5.4 3.8 2.5 3 5.5 10.3.7 12.6-1.7.8-4.3.6-8.1.6-4 0-6.6.2-8.3-.7-4.4-2.4-1.8-9.8.7-12.8 1.7-1.9 3.7-3.5 5.2-3.5zM23.3 32.9c0-3.4-1.5-6.1-3.2-6.1M33.1 32.7c0-4.6 2.2-8.4 4.9-8.4M10.2 15l.9-2.1M9.3 15l-.1-1.2M41.7 5.5L43 2.4m-2.6 3.1l-.2-1.7M7.2 20.8h2.9m-2.9 1.3h2.9" />
      </g>
      <g fill="#e4e4e4">
        <path d="M10.2 15l.9-2.1M9.3 15l-.1-1.2M29.9 41.9h-8.4v-1h8.4c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2v-1c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2zM41.7 5.5L43 2.4m-2.6 3.1l-.2-1.7M20.6 31.9l2.7 1.7c.1.1.3.1.4.1.1 0 .2-.1.3-.2l1.7-2.8-.9-.5-1.4 2.4-2.3-1.5-.5.8zM7.2 20.8h2.9m-2.9 1.3h2.9M5.1 53.1H2.9c-1.6 0-2.9-1.3-2.9-2.9V36.5c0-1.6 1.3-2.9 2.9-2.9H5c1.6 0 2.9 1.3 2.9 2.9v13.7c.1 1.6-1.2 2.9-2.8 2.9zM2.9 34.5c-1.1 0-1.9.9-1.9 1.9v13.7c0 1.1.9 1.9 1.9 1.9H5c1.1 0 1.9-.9 1.9-1.9V36.5c0-1.1-.9-1.9-1.9-1.9H2.9z" />
        <path d="M7 50.8V38.1l2.1-2.4c1.9-2.1 4.6-3 7.2-2.5l7.8 1.8c.8.2 1.5.3 2.3.3l3.5.1v1l-3.5-.1c-.8 0-1.7-.1-2.5-.3l-7.7-1.8c-2.3-.5-4.7.3-6.3 2.1L8 38.5v10.7l1.1-.6c2.1-1.1 4.5-1.4 6.8-1l5.5 1.1c2.5.5 5.1.1 7.4-1.1l14-7.4c1.3-.7 2-2 2-3.4 0-.3-.2-.7-.4-.9-.2-.2-.5-.3-.9-.3l-10.8 4-.3-.9 11-4.1c.6-.1 1.2.1 1.7.6.5.4.7 1 .8 1.6.1 1.8-.9 3.5-2.5 4.3l-14 7.4c-2.5 1.3-5.3 1.7-8 1.2l-5.5-1.1c-2.1-.4-4.3-.1-6.2.9L7 50.8zM35.7 31.9L33 33.6c-.1.1-.3.1-.4.1-.1 0-.2-.1-.3-.2l-1.7-2.8.9-.5 1.4 2.4 2.3-1.5.5.8zM10.5 24.2c-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9.5 0 1 .1 1.4.4l-.5.8c-.3-.2-.6-.3-.9-.3-1.1 0-1.9.9-1.9 1.9s.9 1.9 1.9 1.9c.3 0 .6-.1.9-.2l.4.8c-.4.4-.9.5-1.3.5z" />
      </g>
    </Icon>
  );
};

export default MezzanineIcon;
