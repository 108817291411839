import { mittweidaFields } from 'mittweida/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const { gender, firstName, lastName, email, phone } =
  mittweidaFields.userProfilePage.contactPersonSection;
const { contactPerson } = translations.inquiryType.mittweida.pages.userDetails.sections;

export const contactPersonSectionLabels: LabelsMap = {
  [gender]: contactPerson.gender.caption,
  [firstName]: contactPerson.firstName.caption,
  [lastName]: contactPerson.lastName.caption,
  [email]: contactPerson.email.caption,
  [phone]: contactPerson.phone.caption,
};
