import React from 'react';

import { SectionWrapper, Heading } from 'modules/ContactSidebar/styles';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const ContactContent = () => {
  const t = useTranslations('components.contactSidebar');

  return (
    <SectionWrapper>
      <Heading>{t('contact')}</Heading>
      <TextComponent>{t('contactText')}</TextComponent>
    </SectionWrapper>
  );
};

export default ContactContent;
