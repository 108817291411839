import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { ToMapFrontendToBackend } from 'new/form/common/types';

export const mittweidaFeToBeMapping: ToMapFrontendToBackend<MittweidaFieldTypes> = {
  financingNeedPage: {
    roleSection: {
      projectFinancingRole: 'project-financing-role',
    },
    projectCompositionSection: {
      totalInvestment: 'project-financing-total-investment',
      financingAmount: 'amount',
      financeWholeProject: 'project-financing-buying-phase-only',
      ownFunds: 'project-financing-own-funds',
      ownFundsInBuyingPhase: 'project-financing-own-funds-share-in-buying-phase',
      mezzanineCapital: 'project-financing-mezzanine-capital',
      subordinatedCapital: 'project-financing-subordinated-capital',
      totalInvestmentInBuyingPhase: 'project-financing-total-investment-share-in-buying-phase',
      ownWork: 'project-financing-own-work',
    },
    objectInformationSection: {
      address: 'project-financing-object-address',
      vacancyCommercial: 'project-financing-vacancy-commercial-in-sqm',
      furnishingQuality: 'project-financing-quality-furnishing',
      currentUsage: {
        mappingKey: 'project-financing-object-usages-current',
        keys: {
          typeOfUse: 'type-of-use',
          usageSpace: 'usage-space',
          numberOfUnits: 'number-of-units',
        },
      },
      futureUsage: {
        mappingKey: 'project-financing-object-usages-future',
        keys: {
          typeOfUse: 'type-of-use',
          usageSpace: 'usage-space',
          numberOfUnits: 'number-of-units',
          plannedSalesPrice: 'planned-sales-price',
        },
      },
      rentalIntends: 'project-financing-rental-intents',
      rentalContracts: 'project-financing-rental-contracts',
      currentTotalRentResidential: 'project-financing-total-rent-residential-current',
      futureObjectType: 'project-financing-object-type-future',
      garageRentNow: 'project-financing-garage-rent-current',
      garageRentFuture: 'project-financing-garage-rent-future',
      garagesCount: 'project-financing-garages-count',
      garagesPrice: 'project-financing-garage-planned-sales-price',
      parkingLotRentFuture: 'project-financing-parking-lot-rent-future',
      parkingLotsCount: 'project-financing-parking-lots-count',
      parkingLotRentNow: 'project-financing-parking-lot-rent-current',
      parkingLotPrice: 'project-financing-parking-lot-planned-sales-price',
      lift: 'project-financing-lift',
      description: 'project-financing-object-description',
      currentObjectType: 'project-financing-object-type-current',
      usageDeviation: 'project-financing-usage-deviation',
      plannedProjectEnd: 'project-financing-planned-project-end',
      plannedProjectStart: 'project-financing-planned-project-start',
      zipCode: 'project-financing-object-zip-code',
      lotSize: 'project-financing-lot-size',
      yearOfModernization: 'project-financing-year-of-modernization',
      year: 'project-financing-year-of-building',
      futureTotalRentCommercial: 'project-financing-total-rent-commercial-future',
      futureTotalRentResidential: 'project-financing-total-rent-residential-future',
      currentTotalRentCommercial: 'project-financing-total-rent-commercial-current',
      vacancyResidential: 'project-financing-vacancy-residential-in-sqm',
      balcony: 'project-financing-balcony',
      city: 'project-financing-object-city',
      cellar: 'project-financing-cellar',
      additionalBathroom: 'project-financing-additional-bathroom',
      qualityCondition: 'project-financing-quality-condition',
    },
    otherProjectInformation: {
      demolitionNecessary: 'project-financing-demolition-necessary',
      permitStatus: 'project-financing-permit-status',
      permitBasis: 'project-financing-permit-basis',
      notesOnPermit: 'project-financing-notes-on-permit',
      buildingPermit: 'project-financing-building-permit',
      energyPass: 'project-financing-energy-pass',
      ownSalesPlanned: 'project-financing-own-sales-planned',
      developmentNecessary: 'project-financing-development-necessary',
      heritageProtected: 'project-financing-heritage-protected',
    },
  },
  companyDetailPage: {
    companyDetailsSection: {
      loggedInUserCompanyName: 'name',
      selectedCompanyName: '',
    },
    completingCompanyDetailsSection: {
      company: {
        name: 'name',
        city: 'city',
        zipCode: 'zip_code',
        address: '',
        alreadyContactWithBank: 'contact_to_bank_advisor',
        nameOfContactPerson: 'bank_advisor_name',
        industry: 'industry',
        tradeName: '',
        country: '',
        street: 'street',
        foundingYear: 'founding_year',
        alreadyCustomer: 'already_customer',
        foundingMonth: 'founding_month',
        turnoverClass: 'turnover_class',
        legalForm: 'legal_form',
        state: '',
      },
    },
  },
  fundingDetailsPage: {
    otherFundingSection: {
      description: 'other-product-description',
    },
  },
  userProfilePage: {
    contactPersonSection: {
      gender: 'salutation',
      phone: 'phone_number',
      email: 'email',
      lastName: 'last_name',
      firstName: 'first_name',
    },
    passwordSection: {
      password: '',
      passwordConfirmation: '',
    },
    termsAndConditionsSection: {
      termsAndConditionsAccepted: '',
    },
  },
};
