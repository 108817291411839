import { useSelector } from 'react-redux';

import { getAllKpis, getFiscalYearStartsAt } from '../../../../../store/kpi/kpis.selectors';
import { numberWithCommas } from '../../../../../utils/valueFormats';
import { useDatePickerContext } from '../../context/DatePickerContext';
import { useKpiFilterWithOperator } from '../../hooks/useKpiFilter';

interface TabelDataRow {
  col1: string;
  col2: string;
  col3: number | string;
  col4: string;
  col5: number | string;
}

const selectedKpis = [
  'NUMS',
  'BV',
  'AAE',
  'GL1',
  'SONSTBETRERTR',
  'GL2',
  'MATAUFW',
  'ROHE',
  'PERSAUFW',
  'ABSCHR',
  'SONSTBETRAUFW',
  'EBIT',
  'NERT',
  'AOERT',
  'ERTSOPO',
  'NAUFW',
  'AOAUFW',
  'NERG',
  'EBT',
  'STEUE',
  'ERGNST',
  'SONSTST',
  'JUE',
];
const kpisWithEqual = ['GL1', 'GL2', 'ROHE', 'NERG', 'EBT', 'ERGNST', 'JUE'];
const ignoredKpis = ['NUMS', 'AOERT', 'ERTSOPO', 'AOAUFW'];

export const useGetTableData = () => {
  const information = useSelector(getAllKpis);
  const { date } = useDatePickerContext();
  const selectedYear = date.getFullYear().toString();
  const fiscalYearStartsAt = useSelector(getFiscalYearStartsAt);
  const month = new Date(fiscalYearStartsAt).getMonth();
  let selectedMonth = '12';

  if (month !== 0) {
    selectedMonth = month.toString();
  }

  const currentYearData = useKpiFilterWithOperator({
    information,
    selectedMonth,
    selectedYear,
    selectedKpis,
    ignoredKpis,
    kpisWithEqual,
  });

  const previousYearData = useKpiFilterWithOperator({
    information,
    selectedMonth,
    selectedYear: (parseInt(selectedYear) - 1).toString(),
    selectedKpis,
    ignoredKpis,
    kpisWithEqual,
  });

  const longestDataArray =
    Object.keys(currentYearData).length > Object.keys(previousYearData).length
      ? currentYearData
      : previousYearData;
  const tableData: TabelDataRow[] = [];

  if (currentYearData.length !== 0 || previousYearData.length !== 0) {
    longestDataArray.forEach((currentElement: any, index: number) => {
      const previousElement = previousYearData.find(
        (previousElement: any) => previousElement.name === currentElement.name,
      );
      tableData.push({
        col1: longestDataArray[index].description,
        col2: currentYearData?.[index]?.operator ? currentYearData[index].operator : '',
        col3:
          currentYearData?.[index]?.value !== undefined
            ? numberWithCommas(Math.round(currentYearData[index].value / 1000))
            : 'N/A',
        col4: previousElement ? previousElement.operator : '',
        col5:
          previousElement !== undefined
            ? numberWithCommas(Math.round(previousElement.value / 1000))
            : 'N/A',
      });
    });
  }

  return { tableData, selectedYear };
};
