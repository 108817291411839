import React from 'react';

import _get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';

const withInquiryHeaderInfo = (Comp) => (props) => {
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const { formatMessage } = useIntl();

  const companyName = _get(
    inquiryDetails,
    'company.name',
    formatMessage({ id: 'other.notAvailable' }),
  );
  return (
    <Comp
      companyName={companyName}
      id={inquiryDetails.company.id}
      dateCreated={inquiryDetails.createdAt}
      {...props}
    />
  );
};

export default withInquiryHeaderInfo;
