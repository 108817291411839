import React from 'react';

import { PortalConfigContext } from 'new/portals/state/portalConfigContext/index';

import { AnyPortalConfig } from './types';

export function useRequirePortalConfig() {
  const context = React.useContext(PortalConfigContext);

  if (context === undefined) {
    throw new Error('usePortalConfig must be used within a PortalConfigProvider');
  }

  if (context.portalConfig === undefined) {
    throw new Error(
      'portalConfig is undefined. Make sure PortalConfigProvider provides a valid config',
    );
  }

  return { portalConfig: context.portalConfig as AnyPortalConfig };
}

export function usePortalConfig() {
  const context = React.useContext(PortalConfigContext);

  if (context === undefined) {
    throw new Error('usePortalConfig must be used within a PortalConfigProvider');
  }

  return context;
}

export const useOptionalPortalConfig = () => {
  const context = React.useContext(PortalConfigContext);

  return context;
};
