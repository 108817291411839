import React, { useCallback } from 'react';

import debounce from 'debounce-promise';
import _get from 'lodash/get';
import { useFormState } from 'react-final-form';
import { createMutation } from 'react-query-kit';
import { useDispatch, useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InputWithField } from 'components/Input';
import AvailableEmailSymbol from 'modules/AvailableEmail/AvailableEmailSymbol';
import EmailLoginLink from 'modules/AvailableEmail/EmailLoginLink';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { setIsEmailAvailable, setIsEmailLoading } from 'store/emailAvailable/actions';
import {
  isEmailAvailableSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { combineValidators } from 'utils/validators';

const useCombinedValidator = (syncValidate: any) => {
  // lastEmailValidation is used to keep the last validation result (undefined or syncErrors) in state to avoid calling the validation and endpoint again for the same value
  const [lastEmailValidation, setLastEmailValidation] = React.useState<{
    value: undefined | string;
    lastReturn: any;
  }>({ value: undefined, lastReturn: undefined });
  const dispatch = useDispatch();
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail.errors.emailTaken',
  );
  const checkEmailMutation = useCheckEmailMutation();

  const checkEmailAvailability = async (email: string | undefined) => {
    if (!email) {
      dispatch(setIsEmailLoading(false));
      dispatch(setIsEmailAvailable(true));
      return true;
    }

    try {
      const response = await checkEmailMutation.mutateAsync({ email });
      dispatch(setIsEmailLoading(false));
      if (response.status === 200) {
        dispatch(setIsEmailAvailable(false));
        return false;
      }
    } catch (error: any) {
      dispatch(setIsEmailLoading(false));
      if (error.response.status === 404) {
        dispatch(setIsEmailAvailable(true));
        return true;
      }
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = useCallback(
    debounce(async (value: any, allValues: any) => {
      // If the value is the same as the last one, return the last result to avoid calling the endpoint again
      if (value === lastEmailValidation.value && value !== undefined) {
        return lastEmailValidation.lastReturn;
      }
      dispatch(setIsEmailLoading(true));

      // Validates the field with synchronous validators
      const syncErrors = syncValidate(value, allValues);
      if (syncErrors) {
        dispatch(setIsEmailLoading(false));
        setLastEmailValidation({ value, lastReturn: syncErrors });
        return syncErrors;
      }

      // Call the endpoint to check the email availability
      const available = await checkEmailAvailability(value);
      const emailExistsError = t('haveAccount');

      if (available) {
        setLastEmailValidation({ value, lastReturn: undefined });
        return undefined;
      }
      setLastEmailValidation({ value, lastReturn: emailExistsError });
      return emailExistsError;
    }, 200),
    [checkEmailMutation.mutateAsync, setIsEmailAvailable, setIsEmailLoading, lastEmailValidation],
  );

  return { validate };
};

const useCheckEmailMutation = createMutation({
  mutationFn: async (variables: { email: string | undefined }) => {
    return EcoBankingAxiosClientAuthedInstance.post(endpoints.USERS.EMAIL_AVAILABILITY.compose(), {
      email: variables.email,
    });
  },
});

type Props = {
  name: string;
  shouldShowEmailIcon?: boolean;
};

export const ContactPersonEmail = ({ name, shouldShowEmailIcon = false }: Props) => {
  const { required, isEmail } = useFieldValidators();
  const t = useTranslations();
  const { values } = useFormState();
  const emailValue = values && _get(values, name);
  const isLoading = useSelector(isLoadingEmailAvailabilitySelector);
  const isEmailAvailable = useSelector(isEmailAvailableSelector);

  const { validate: combinedValidator } = useCombinedValidator(
    combineValidators(required, isEmail),
  );

  const shouldShowLoginLink =
    !isLoading && !isEmailAvailable && emailValue && isEmailRegex.test(emailValue);

  const showEmailIcon = !!emailValue && isEmailRegex.test(emailValue) && shouldShowEmailIcon;

  return (
    <div>
      <InputWithField
        name={name}
        validate={combinedValidator}
        validateFields={[name]}
        type={'email'}
        errorSuffix={shouldShowLoginLink ? <EmailLoginLink email={emailValue} /> : null}
        caption={t(
          translations.pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail
            .caption,
        )}
        sideSymbol={() =>
          showEmailIcon && (
            <AvailableEmailSymbol
              isLoading={isLoading}
              isAllowedToInquiry={combinedValidator(emailValue, values)}
            />
          )
        }
      />
    </div>
  );
};
