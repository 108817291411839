import React from 'react';

import { Field, useForm } from 'react-final-form';

import FieldGroup from 'components/PortalFormFields/FieldGroup';
import Autocomplete from 'components/PortalFormFields/SelectField/Autocomplete/Autocomplete';
import { useFormConfig } from 'config/formConfig/hooks';
import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import NoCompanyElement from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/NoCompanyElement/NoCompanyElement';
import {
  useFetchCompanySuggestions,
  MIN_COMPANY_SUGGESTION_QUERY,
} from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators, required } from 'utils/validators';

export type CompanySuggestItem = {
  address: string | undefined;
  city: string | undefined;
  country: string | undefined;
  'crefo-id': string | undefined;
  name: string | undefined;
  state: string | undefined;
  street: string | undefined;
  'trade-name': string | undefined;
  'zip-code': string | undefined;
};

type Props = {
  fieldName: string;
};

const CompanySuggestion = ({ fieldName }: Props) => {
  const { selectedInquiryType } = useFormConfig(); // Use this inside the inquiry process
  const t = useTranslations('pages.companyDetails.sections.company.fields.companyDetailsCompany');
  const tooltipT = useTranslations(
    'pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany',
  );

  const tErrors = useTranslations('errors');
  const { isLoading, companies, changeQuery, nextPage } = useFetchCompanySuggestions();
  const { change } = useForm();
  const isBfsInquiry = selectedInquiryType === InquiryType.bfs;

  const handleInputChange = (newValue: string) => {
    changeQuery(newValue);
  };

  const notFoundOption = {
    label: t('additionalOption.labelOnSelected'),
    value: COMPANY_DETAILS_COMPANY__NOT_FOUND,
    component: <NoCompanyElement />,
  };
  const clearCompanyForm = () => {
    change(USER_PROFILE_COMPANY_ALREADY_CUSTOMER, null);
    change(USER_PROFILE_FOUNDING_YEAR, null);
    change(USER_PROFILE_INDUSTRY, null);
    change(USER_PROFILE_LEGAL_FORM, null);
    change(USER_PROFILE_TURNOVER_CLASS, null);
    change(USER_PROFILE_COMPANY_NAME, null);
    change(USER_PROFILE_COMPANY_STREET, null);
    change(USER_PROFILE_COMPANY_ZIP_CODE, null);
    change(USER_PROFILE_COMPANY_CITY, null);
  };

  const props = {
    ...(isBfsInquiry && {
      tooltip: tooltipT('tooltip'),
      tooltipTransform: 'translateY(-11px)',
    }),
  };

  return (
    <Field
      name={fieldName}
      validate={combineValidators(required(tErrors('required')))}
      render={({ meta, input }) => (
        <FieldGroup
          label={t('caption')}
          error={meta.touched && meta.error ? meta.error : null}
          {...props}
        >
          <Autocomplete
            name={input.name}
            options={companies}
            onFocus={input.onFocus}
            onChange={handleInputChange}
            onSelect={input.onChange}
            placeholder={t('placeholder', { roleSpecific: true })}
            value={input.value}
            inputInitialValue={input.value && input.value.name}
            loading={isLoading}
            menuShowThreshold={MIN_COMPANY_SUGGESTION_QUERY}
            onScrollEnd={nextPage}
            fixedOptions={[notFoundOption]}
            onBlur={input.onBlur}
            clearCompanyForm={clearCompanyForm}
            testId={fieldName}
          />
        </FieldGroup>
      )}
    />
  );
};

export default CompanySuggestion;
