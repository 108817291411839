import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { HeadingComponent } from 'theme/components/Heading';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  state?: string;
}

export const StartSignatureProcessStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  state,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.signatureProcess');

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{t('description')}</TextComponent>
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset
              htmlHeight="200"
              type="dashboard"
              value={ProgressSectionType.START_SIGNATURE_PROCESS}
            />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
