import { LabelsMap } from 'new/form/common/types';

import { objectSectionLabelsMap } from './sections/objectInformationSection/labels';
import { otherProjectInformationSectionLabelsMap } from './sections/otherProjectInformationSection/labels';
import { projectCompositionSectionLabels } from './sections/projectCompositionSection/labels';
import { roleSectionLabels } from './sections/roleSection/labels';

export const mittweidaFinancingNeedLabelsMap: LabelsMap = {
  ...roleSectionLabels,
  ...projectCompositionSectionLabels,
  ...objectSectionLabelsMap,
  ...otherProjectInformationSectionLabelsMap,
};
