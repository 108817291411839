import { RLLComputedProfitabilityInvestment } from 'models/types/Profitability.type';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.investmentCalculation.tableHeaders.${key}`,
    );

  return [
    {
      key: 'position',
      text: translate('position'),
    },
    {
      key: 'baseValues',
      text: translate('baseValues'),
    },
    {
      key: 'sqmCosts',
      text: translate('sqmCosts'),
    },
    {
      key: 'percentCosts',
      text: translate('percentCosts'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  investment: RLLComputedProfitabilityInvestment,
) => {
  const translate = (key: string) =>
    t(
      `pages.planningEvaluation.profitabilityCalculation.sections.investmentCalculation.tableRows.${key}`,
    );
  const translateUnit = (unit: string) => t(`other.${unit}`);
  const translateCombinedUnit = (units: string[]) => units.map(translateUnit).join(' ');

  return [
    {
      baseValues: formatDecimal(investment.baseValues.purchasePrice, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('purchasePrice'),
      sqmCosts: formatDecimal(investment.sqmCosts.future.purchasePrice, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      baseValues: formatDecimal(investment.baseValues.legalFee, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.legalFee, {
        unit: translateCombinedUnit(['percent', 'purchasePrice']),
      }),
      position: translate('legalFee'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.agentFee, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.agentFee, {
        unit: translateCombinedUnit(['percent', 'purchasePrice']),
      }),
      position: translate('agentFee'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.realEstateTransferTax, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.realEstateTransferTax, {
        unit: translateCombinedUnit(['percent', 'purchasePrice']),
      }),
      position: translate('realEstateTransferTax'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.measurementCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('measurementCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.otherFees, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('otherFees'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.otherPurchaseCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('otherPurchaseCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.otherCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('otherCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.compensations, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('compensations'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.landDevelopmentCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('landDevelopmentCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.publicDisposal, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('publicDisposal'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.nonPublicDisposal, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('nonPublicDisposal'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.otherDevelopmentCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('otherDevelopmentCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.developmentCostsTotal, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('developmentCostsTotal'),
      rowHighlight: true,
      sqmCosts: formatDecimal(investment.sqmCosts.future.developmentCostsTotal, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      baseValues: formatDecimal(investment.baseValues.constructionCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('constructionCosts'),
      sqmCosts: formatDecimal(investment.sqmCosts.future.constructionCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      baseValues: formatDecimal(investment.baseValues.outdoorFacilitiesCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.outdoorFacilitiesCosts, {
        unit: translateCombinedUnit(['percent', 'buildingCost']),
      }),
      position: translate('outdoorFacilitiesCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.otherConstructionCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('otherConstructionCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.constructionCostsTotal, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('constructionCostsTotal'),
      rowHighlight: true,
      sqmCosts: formatDecimal(investment.sqmCosts.future.constructionCostsTotal, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      baseValues: formatDecimal(investment.baseValues.architectCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.architectCosts, {
        unit: translateCombinedUnit(['percent', 'buildingCost']),
      }),
      position: translate('architectCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.additionalCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('additionalCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.additionalCostsTotal, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('additionalCostsTotal'),
      rowHighlight: true,
      sqmCosts: formatDecimal(investment.sqmCosts.future.additionalCostsTotal, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      baseValues: formatDecimal(investment.baseValues.thirdPartyFinancingCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('thirdPartyFinancingCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.financingCostsInterestsManual, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('financingCostsInterestsManual'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.financingCostsFeesManual, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('financingCostsFeesManual'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.financingCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('financingCosts'),
      rowHighlight: true,
    },
    {
      baseValues: formatDecimal(investment.baseValues.buffer, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.buffer, {
        unit: translateCombinedUnit(['percent', 'buildingCost']),
      }),
      position: translate('buffer'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.marketingSalesCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('marketingSalesCosts'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.totalCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('totalCosts'),
      rowHighlight: true,
      sqmCosts: formatDecimal(investment.sqmCosts.future.totalCosts, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
    {
      baseValues: formatDecimal(investment.baseValues.projectFinancingOwnFunds, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.projectFinancingOwnFunds, {
        unit: translateCombinedUnit(['percent', 'totalCost']),
      }),
      position: translate('projectFinancingOwnFunds'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.projectFinancingOwnWork, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.projectFinancingOwnWork, {
        unit: translateCombinedUnit(['percent', 'totalCost']),
      }),
      position: translate('projectFinancingOwnWork'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.projectFinancingMezzanine, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.projectFinancingMezzanine, {
        unit: translateCombinedUnit(['percent', 'totalCost']),
      }),
      position: translate('projectFinancingMezzanine'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.projectFinancingSubordinatedCapital, {
        unit: translateUnit('defaultCurrency'),
      }),
      percentCosts: formatDecimal(investment.percentCosts.projectFinancingSubordinatedCapital, {
        unit: translateCombinedUnit(['percent', 'totalCost']),
      }),
      position: translate('projectFinancingSubordinatedCapital'),
    },
    {
      baseValues: formatDecimal(investment.baseValues.calculatedFinancingNeed, {
        unit: translateUnit('defaultCurrency'),
      }),
      position: translate('calculatedFinancingNeed'),
      rowHighlight: true,
      sqmCosts: formatDecimal(investment.sqmCosts.future.calculatedFinancingNeed, {
        unit: translateUnit('defaultCurrency'),
      }),
    },
  ];
};
