import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const PkwIcon = (props: IconProps) => {
  return (
    <Icon
      width="55"
      height="30"
      viewBox="0 0 54.17 29.66"
      aria-hidden="true"
      fill="currentColor"
      {...props}
    >
      <path d="M12 21.72a2.19 2.19 0 102.19 2.19A2.2 2.2 0 0012 21.72zm0 3.34a1.15 1.15 0 111.14-1.15A1.15 1.15 0 0112 25.06zm27.4-3.34a2.19 2.19 0 102.2 2.19 2.19 2.19 0 00-2.2-2.19zm0 3.34a1.15 1.15 0 111.16-1.15 1.15 1.15 0 01-1.16 1.15z" />
      <path d="M17.72 24.5h16l.05.32a5.74 5.74 0 0011.34 0l.05-.31h7.09A1.95 1.95 0 0053.41 21l-.15-.11v-6.02a1.42 1.42 0 000-.27l-.13-.75-.13-.15a4.7 4.7 0 00-1.09-1.77C50.67 10.65 48.73 10 46.17 10h-6.52l-4.39-7.06a7 7 0 00-6-2.94H15.15c-.25 0-6.22.11-8.36 10.05l-.06.3H4.89a4.38 4.38 0 00-4 3.33l-.2.7a3.61 3.61 0 00-.08.75v6l-.09.1A1.94 1.94 0 002 24.5h4.27l.05.31a5.75 5.75 0 0011.35 0zm26.38-.45a4.7 4.7 0 01-9.4-.05v-.11a6 6 0 01.08-.75c0-.11 0-.21.07-.32s.08-.28.13-.41a4.37 4.37 0 01.46-1l.11-.18a4.69 4.69 0 017.71 0l.11.18a3.14 3.14 0 01.18.31l.07.13.08.15c0 .06 0 .12.07.19v.08a5.06 5.06 0 01.21.66 5.19 5.19 0 01.1 1zm9-1.51a.91.91 0 01-.91.91h-7.03v-.31a8 8 0 00-.24-1l-.16-.49h7.51a.91.91 0 01.86.89zm-.91-4.8h-1.52a1.37 1.37 0 010-2.74h1.55zM24.44 1h4.83a6 6 0 015.1 2.44L38.43 10l-14 .15zM12.52 2l.18-.11A5.32 5.32 0 0115.14 1h8.26v9.14l-10.88.11zm-4.6 7.88a14.79 14.79 0 012.9-6.37l.66-.77v7.56H7.81zm-6.29 5.94v-.74L2 15h1.19a1.37 1.37 0 010 2.74H1.63zm0 3h1.56a2.37 2.37 0 001.7-.71 2.37 2.37 0 00.71-1.7A2.42 2.42 0 003.19 14H1.84l.22-.52a3.22 3.22 0 012.83-2h3l31.45-.33h6.81c2.25 0 3.92.53 5 1.58a3.72 3.72 0 01.58.76L52 14h-1.33a2.42 2.42 0 100 4.83h1.55v1.77H44.1l-.1-.15a5.73 5.73 0 00-9.16 0l-.12.15h-18l-.11-.15a5.74 5.74 0 00-9.17 0l-.11.15h-5.7zm4.9 3.35a6.09 6.09 0 00-.23 1v.32H2a.91.91 0 110-1.81h4.69zm10.15 2a4.7 4.7 0 01-9.39-.23 4.42 4.42 0 01.08-.81c0-.11 0-.21.07-.32a4.14 4.14 0 01.13-.43 4.66 4.66 0 01.46-1l.11-.17a4.68 4.68 0 017.72 0l.09.15a4.35 4.35 0 01.29.52 3.34 3.34 0 01.17.44 2.25 2.25 0 01.14.44c0 .11.05.22.07.32a4.9 4.9 0 01.07.67.82.82 0 010 .14zm.62-2.5h16.8l-.16.49a5.21 5.21 0 00-.22 1v.32H17.74v-.31a6.37 6.37 0 00-.23-1z" />
      <path d="M17.6 13.02h3.09v1.04H17.6zm9.34 0h3.09v1.04h-3.09z" />
    </Icon>
  );
};

export default PkwIcon;
