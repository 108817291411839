import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledNoFileSection as Wrapper,
  StyledInnerNoFileBorder as Bordered,
  StyledNoFilesTitle as Title,
} from 'components/EmptySection/styles';
import CloseIcon from 'theme/components/Icon/CloseIcon';

const EmptySection = ({ title, desc }) => (
  <Wrapper>
    <Bordered>
      <CloseIcon boxSize={8} mb={2} />
      <Title>{title}</Title>
      <p>{desc}</p>
    </Bordered>
  </Wrapper>
);

EmptySection.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default EmptySection;
