import {
  FINANCING_DETAILS_FEE_DURATION,
  FINANCING_DETAILS_FEE_RATE,
  FINANCING_DETAILS_FINANCING_DURATION,
  FINANCING_DETAILS_INTEREST_RATE,
  FINANCING_DETAILS_REPAYMENT_RATE,
  INVESTMENT_PLAN_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_CONSTRUCTION_COSTS,
  INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS,
  INVESTMENT_PLAN_GAK_BUILDING_COSTS,
  INVESTMENT_PLAN_GAK_BUYING_COSTS,
  INVESTMENT_PLAN_GAK_INTEREST_COSTS,
  INVESTMENT_PLAN_GAK_OTHER,
  INVESTMENT_PLAN_GAK_SELLING_COSTS,
  INVESTMENT_PLAN_INTEREST_COSTS,
  INVESTMENT_PLAN_OTHER_COSTS,
  INVESTMENT_PLAN_SELLING_COSTS,
  LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION,
  LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT,
  LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION,
  PROFITABILITY_SALES_BUYING_COSTS_CHECKED,
  PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED,
  PROFITABILITY_SALES_SALES_PRICES_CHECKS,
  RENT_CUSTOMER_NOW_ASSET_SURPLUS,
  RENT_CUSTOMER_NOW_EQUITY_PROOF,
  RENT_CUSTOMER_NOW_FREE_INCOME,
  RENT_CUSTOMER_NOW_OPERATING_COSTS,
  RENT_CUSTOMER_PLAN_OPERATING_COSTS,
  SOURCE_OF_FUNDS_EQUITY_GRANTS,
  SOURCE_OF_FUNDS_EQUITY_RELEASE,
  SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS,
  SOURCE_OF_FUNDS_GAK_LOAN,
  SOURCE_OF_FUNDS_LOAN_PROJECT,
} from 'modules/Inquiry/Form/formFields';

export const editMittweidaProfitabilityLabelMap: { [key: string]: string } = {
  [FINANCING_DETAILS_FEE_DURATION]:
    'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.feeDuration.caption',
  [FINANCING_DETAILS_FEE_RATE]:
    'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.feeRate.caption',
  [FINANCING_DETAILS_FINANCING_DURATION]:
    'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.financingDuration.caption',
  [FINANCING_DETAILS_INTEREST_RATE]:
    'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.interestRate.caption',
  [FINANCING_DETAILS_REPAYMENT_RATE]:
    'pages.editMittweidaProfitabilityPages.financingDetails.sections.fields.repaymentRate.caption',

  [INVESTMENT_PLAN_ADDITIONAL_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.project.fields.additionalCosts.caption',
  [INVESTMENT_PLAN_CONSTRUCTION_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.project.fields.constructionCosts.caption',
  [INVESTMENT_PLAN_GAK_ADDITIONAL_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.fields.gakAdditionalCosts.caption',
  [INVESTMENT_PLAN_GAK_BUILDING_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.fields.gakBuildingCosts.caption',
  [INVESTMENT_PLAN_GAK_BUYING_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.fields.gakBuyingCosts.caption',
  [INVESTMENT_PLAN_GAK_INTEREST_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.fields.gakInterestCosts.caption',
  [INVESTMENT_PLAN_GAK_OTHER]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.fields.gakOther.caption',
  [INVESTMENT_PLAN_GAK_SELLING_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.buying.fields.gakSellingCosts.caption',
  [INVESTMENT_PLAN_INTEREST_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.project.fields.interestCosts.caption',
  [INVESTMENT_PLAN_OTHER_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.project.fields.otherCosts.caption',
  [INVESTMENT_PLAN_SELLING_COSTS]:
    'pages.editMittweidaProfitabilityPages.investmentPlan.sections.project.fields.sellingCosts.caption',

  [LENDING_VALUE_BUYING_PHASE_LENDING_LIMIT_PERCENT]:
    'pages.editMittweidaProfitabilityPages.lendingValue.sections.buyingPhase.fields.lendingLimitPercent.caption',
  [LENDING_VALUE_BUYING_PHASE_RISK_REDUCTION]:
    'pages.editMittweidaProfitabilityPages.lendingValue.sections.buyingPhase.fields.riskDeduction.caption',
  [LENDING_VALUE_INDICATION_LENDING_LIMIT_PERCENT]:
    'pages.editMittweidaProfitabilityPages.lendingValue.sections.indication.fields.indicationLendingLimitPercent.caption',
  [LENDING_VALUE_WHOLE_PROJECT_LENDING_LIMIT_PERCENT]:
    'pages.editMittweidaProfitabilityPages.lendingValue.sections.wholeProject.fields.lendingLimitPercent.caption',
  [LENDING_VALUE_WHOLE_PROJECT_RISC_DEDUCTION]:
    'pages.editMittweidaProfitabilityPages.lendingValue.sections.wholeProject.fields.riskDeduction.caption',

  [PROFITABILITY_SALES_BUYING_COSTS_CHECKED]:
    'pages.editMittweidaProfitabilityPages.profitabilitySales.sections.fields.buyingCostsChecked.caption',
  [PROFITABILITY_SALES_CONSTRUCTION_COSTS_CHECKED]:
    'pages.editMittweidaProfitabilityPages.profitabilitySales.sections.fields.constructionCostsChecked.caption',
  [PROFITABILITY_SALES_SALES_PRICES_CHECKS]:
    'pages.editMittweidaProfitabilityPages.profitabilitySales.sections.fields.salesPricesChecks.caption',

  [RENT_CUSTOMER_NOW_ASSET_SURPLUS]:
    'pages.editMittweidaProfitabilityPages.rent.sections.fields.assetSurplus.caption',
  [RENT_CUSTOMER_NOW_EQUITY_PROOF]:
    'pages.editMittweidaProfitabilityPages.rent.sections.fields.equityProof.caption',
  [RENT_CUSTOMER_NOW_FREE_INCOME]:
    'pages.editMittweidaProfitabilityPages.rent.sections.fields.freeIncome.caption',
  [RENT_CUSTOMER_NOW_OPERATING_COSTS]:
    'pages.editMittweidaProfitabilityPages.rent.sections.fields.nowOperatingCosts.caption',
  [RENT_CUSTOMER_PLAN_OPERATING_COSTS]:
    'pages.editMittweidaProfitabilityPages.rent.sections.fields.planOperatingCosts.caption',

  [SOURCE_OF_FUNDS_EQUITY_GRANTS]:
    'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.project.fields.equityGrants.caption',
  [SOURCE_OF_FUNDS_EQUITY_RELEASE]:
    'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.project.fields.equityRelease.caption',
  [SOURCE_OF_FUNDS_GAK_EQUITY_SHARE_BUYING_COSTS]:
    'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.buying.fields.gakEquityShareBuyingCosts.caption',
  [SOURCE_OF_FUNDS_GAK_LOAN]:
    'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.buying.fields.gakLoan.caption',
  [SOURCE_OF_FUNDS_LOAN_PROJECT]:
    'pages.editMittweidaProfitabilityPages.sourceOfFunds.sections.project.fields.loanProject.caption',
};
