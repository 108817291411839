import _reject from 'lodash/reject';
import type { AnyAction } from 'redux';

import type { IFile, IPrivateFile, IInternalFile, IAssessmentFile } from 'models/File.model';
import type { IFileRequest } from 'models/FileRequest.model';
import {
  changeFileStatusToDownloaded,
  mapApiFileRequest,
  removeFile,
  removePrivateFile,
  removeAssessmentFile,
  removeFileFromRequest,
  updateFileClassification,
  updatePrivateFileClassification,
  updateFileRequestFileClassification,
  updateRequestWithNewFile,
} from 'shared/documentExchange/documentExchange.service';
import {
  ADD_FILE,
  ADD_PRIVATE_FILE,
  ADD_ASSESSMENT_FILE,
  ADD_OR_UPDATE_FILE,
  ADD_OR_UPDATE_PRIVATE_FILE,
  CREATE_FILE_REQUEST_SUCCESS,
  DOWNLOAD_FILE,
  REMOVE_FILE,
  REMOVE_PRIVATE_FILE,
  REMOVE_ASSESSMENT_FILE,
  SET_FILES,
  SET_PRIVATE_FILES,
  SET_ASSESSMENT_FILES,
  SET_FILE_REQUESTS,
  SET_INTERNAL_FILES,
  UPDATE_FILE_CLASSIFICATION,
  UPDATE_PRIVATE_FILE_CLASSIFICATION,
  UPLOAD_FILE_TO_FILE_REQUEST,
} from 'store/documentExchange/documentExchange.actions';
import { getUpdateState } from 'utils/storeHelpers';

const initialState: {
  fileRequests: Array<IFileRequest>;
  files: Array<IFile>;
  internalFiles: Array<IInternalFile>;
  privateFiles: Array<IPrivateFile>;
  assessmentFiles: Array<IAssessmentFile>;
} = {
  fileRequests: [],
  files: [],
  internalFiles: [],
  privateFiles: [],
  assessmentFiles: [],
};

export const documentExchangeReducer = (state = initialState, action: AnyAction) => {
  const updateState = getUpdateState(state);

  switch (action.type) {
    case SET_FILE_REQUESTS:
      return updateState({ fileRequests: action.payload });
    case SET_FILES:
      return updateState({ files: action.payload });
    case SET_PRIVATE_FILES:
      return updateState({ privateFiles: action.payload });
    case SET_ASSESSMENT_FILES:
      return updateState({ assessmentFiles: action.payload });
    case SET_INTERNAL_FILES:
      return updateState({ internalFiles: action.payload });
    case UPDATE_FILE_CLASSIFICATION:
      return updateState({
        files: updateFileClassification(state.files, action.payload.file),
        fileRequests: updateFileRequestFileClassification(
          state.fileRequests,
          action.payload.file,
          action.payload.requestId,
        ),
      });
    case UPDATE_PRIVATE_FILE_CLASSIFICATION:
      return updateState({
        privateFiles: updatePrivateFileClassification(state.privateFiles, action.payload.file),
      });
    case ADD_FILE:
      return updateState({ files: [...state.files, action.payload] });
    case ADD_PRIVATE_FILE:
      return updateState({ privateFiles: [...state.privateFiles, action.payload] });
    case ADD_ASSESSMENT_FILE:
      return updateState({ assessmentFiles: [...state.assessmentFiles, action.payload] });
    case ADD_OR_UPDATE_FILE: {
      const updatedFile = action.payload;
      const updatedFiles = _reject(state.files, ({ id }) => updatedFile.id === id);
      updatedFiles.push(updatedFile);
      return updateState({ files: updatedFiles });
    }
    case ADD_OR_UPDATE_PRIVATE_FILE: {
      const updatedFile = action.payload;
      const updatedFiles = _reject(state.privateFiles, ({ id }) => updatedFile.id === id);
      updatedFiles.push(updatedFile);
      return updateState({ privateFiles: updatedFiles });
    }
    case DOWNLOAD_FILE:
      return updateState({
        files: changeFileStatusToDownloaded(state.files, action.payload.fileId),
      });
    case UPLOAD_FILE_TO_FILE_REQUEST:
      return updateState({
        files: [...state.files, action.payload.file],
        fileRequests: state.fileRequests.map(
          updateRequestWithNewFile(action.payload.requestId, action.payload.file),
        ),
      });
    case CREATE_FILE_REQUEST_SUCCESS:
      return updateState({
        fileRequests: [...state.fileRequests, mapApiFileRequest(action.payload.data.data)],
      });
    case REMOVE_FILE:
      return updateState({
        files: removeFile(state.files, action.payload.meta),
        fileRequests: removeFileFromRequest(state.fileRequests, action.payload.meta),
      });
    case REMOVE_PRIVATE_FILE:
      return updateState({
        privateFiles: removePrivateFile(state.privateFiles, action.payload.meta),
      });
    case REMOVE_ASSESSMENT_FILE:
      return updateState({
        assessmentFiles: removeAssessmentFile(state.assessmentFiles, action.payload.meta),
      });
    default:
      return state;
  }
};
